import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { EFM } from '../../api';
import { Translation } from 'react-i18next';
import { ButtonWithLoader } from '../Layout';
import { withSnackbar } from 'notistack';
import { AjaxForm } from '../Ajax';

const styles = theme => ({});

class FeedbackDeleteDialog extends React.PureComponent {
	state = {};

	handleClose = () => {
		this.props.close();
	};

	render() {
		const { classes, feedback_ids } = this.props;

		return (
			<Translation>
				{t => (
					<AjaxForm
						url="/dashboard/ajax/delete-feedback"
						data={{ feedback_ids: feedback_ids }}
						onSuccess={r => {
							this.props.enqueueSnackbar(t`Feedback deleted`);
							this.props.onSuccess(r);
							this.props.close();
						}}
					>
						{({ clicked, loading, postForm }) => (
							<Dialog
								open={!!this.props.open}
								onClose={this.handleClose}
								maxWidth="sm"
							>
								<DialogTitle>{t('Delete feedback')}</DialogTitle>
								<DialogContent>
									<DialogContentText>
										{t('Delete selected feedback items?')}
									</DialogContentText>
								</DialogContent>
								<DialogActions>
									<Button
										onClick={this.handleClose}
										color="default"
									>
										{t('Cancel')}
									</Button>
									<ButtonWithLoader
										onClick={postForm}
										color="primary"
										variant="contained"
										loading={loading}
										dataTrackEvent="inbox_feedback_removed"
										data-test-element="delete_feedback_confirm_dialog_button"
									>
										{t('Delete')}
									</ButtonWithLoader>
								</DialogActions>
							</Dialog>
						)}
					</AjaxForm>
				)}
			</Translation>
		);
	}
}

export default withStyles(styles, { withTheme: true })(
	withSnackbar(FeedbackDeleteDialog)
);
