export const formBorderRadius = {
	squared: 0,
	semirounded: 3, //default
	round: 12,
};

export const buttonBorderRadius = {
	squared: 0,
	semirounded: 3, //default
	rounded: 8,
	round: 20,
};

export const editorTypes = {
	web: 'web',
	email: 'email',
	sdk: 'sdk',
};

export const surveyTypes = [
	{
		type: 'web',
		img: '/assets/img/r/product-web-icon.svg',
	},
	{
		type: 'email',
		img: '/assets/img/r/product-email-icon.svg',
	},
	{
		type: 'sdk',
		img: '/assets/img/r/product-app-icon.svg',
	},
];

export const surveyFormatTypes = [
	{
		type: 'form',
		img: '/assets/img/surveytypes/survey-standard.svg',
	},
	{
		type: 'conversational',
		subtitle:
			'data_collection-feedback_forms-editor-new_survey-step_formtype-conversational-native_subtitle',
		img: '/assets/img/surveytypes/survey-conversational.svg',
	},
];

export const sdkFormTypes = [
	{
		type: 'native',
		img: '/assets/img/surveytypes/native_form.svg',
	},
	{
		type: 'webview',
		img: '/assets/img/surveytypes/webview_form.svg',
	},
];

export const defaultBlocks = {
	header: {
		typeName: 'header',
		properties: {},
		rules: [],
	},
	footer: {
		typeName: 'footer',
		properties: {},
		rules: [],
	},
	intro: {
		typeName: 'intro',
		properties: {},
		rules: [],
	},
	thanks: {
		typeName: 'thanks',
		properties: {},
		rules: [],
	},
};

export const displayOptions = [
	{
		label: 'Modal',
		tooltip: 'Opens in an overlay covering the page',
		img: '/assets/img/display-types/modal.svg',
		value: 'modal',
		availableInEditors: [editorTypes.web, editorTypes.email, editorTypes.sdk],
	},
	{
		label: 'Slide-in',
		tooltip: 'Slides out of the windows side or bottom',
		img: '/assets/img/display-types/slide.svg',
		value: 'slide',
		availableInEditors: [editorTypes.web, editorTypes.email, editorTypes.sdk],
	},
	{
		label: 'Embedded',
		tooltip: 'Embedded in the content of your page',
		img: '/assets/img/display-types/embed.svg',
		value: 'embed',
		availableInEditors: [editorTypes.web, editorTypes.email],
	},
];

export const noInputBlocks = [
	'website_data',
	'section_break',
	'icon',
	'multimedia',
	'img',
	'text',
	'link',
	'page_break',
	'header',
	'footer',
	'ending',
];

export const surveyBlocks = [
	{
		label: 'Most used',
		editorType: 'web',
		types: [
			{ label: 'Textarea', name: 'textarea' },
			{ label: 'Customer Effort Score (CES)', name: 'ces' },
			{ label: 'Smiley rating', name: 'rating_emoji' },
			{ label: 'Radio button', name: 'radio' },
			{ label: 'Goal Completion Rate (GCR)', name: 'gcr' },
		],
	},
	{
		label: 'Most used',
		editorType: 'sdk',
		types: [
			{ label: 'Textarea', name: 'textarea' },
			{ label: 'Customer Effort Score (CES)', name: 'ces' },
			{ label: 'Smiley rating', name: 'rating_emoji' },
			{ label: 'Radio button', name: 'radio' },
			{ label: 'Goal Completion Rate (GCR)', name: 'gcr' },
		],
	},
	{
		label: 'Most used',
		editorType: 'email',
		types: [
			{ label: 'Thumbs', name: 'thumbs' },
			{ label: 'Textarea', name: 'textarea' },
			{ label: 'Customer Effort Score (CES)', name: 'ces' },
			{ label: 'Smiley rating', name: 'rating_emoji' },
			{ label: 'Radio button', name: 'radio' },
		],
	},
	{
		label: 'Scores',
		types: [
			{ label: 'Numeric rating', name: 'rating_numeric' },
			{ label: 'Star rating', name: 'rating_stars' },
			{ label: 'Smiley rating', name: 'rating_emoji' },
			{ label: 'Bar rating', name: 'rating_bar' },
			{ label: 'Net Promotor Score (NPS)', name: 'nps' },
			{ label: 'Customer Effort Score (CES)', name: 'ces' },
			{ label: 'Goal Completion Rate (GCR)', name: 'gcr' },
			{ label: 'Thumbs', name: 'thumbs' },
		],
	},
	{
		label: 'Question',
		types: [
			{ label: 'Single line input', name: 'input' },
			{ label: 'Textarea', name: 'textarea' },
			{ label: 'Radio button', name: 'radio' },
			{ label: 'Checkbox', name: 'checkbox' },
			{ label: 'Contact details', name: 'contact' },
			{ label: 'Dropdown', name: 'select' },
			{ label: 'Categories', name: 'category' },
			{ label: 'Screenshot', name: 'screenshot' },
			{ label: 'Website data', name: 'website_data', hideSdk: true },
			{ label: 'Likert', name: 'likert', hideSdk: true },
			{ label: 'Matrix', name: 'matrix', hideSdk: true },
		],
	},
	{
		label: 'Display',
		types: [
			{ label: 'Link', name: 'link', availableSdks: ['android', 'react'] },
			{ label: 'Multimedia', name: 'multimedia', availableSdks: ['android', 'react'] },
			{ label: 'Section break', name: 'section_break' },
			{ label: 'Page break', name: 'page_break' },
			{ label: 'Custom front page', name: 'intro', hideSdk: true },
		],
	},
];

export const conversationalSurveyBlocks = [
	{
		label: 'Most used',
		types: [
			{ label: 'Text bubble', name: 'section_break' },
			{ label: 'Multiple choice buttons', name: 'category' },
			{ label: 'Text input', name: 'input' },
			{ label: 'Numeric rating', name: 'rating_numeric' },
			{ label: 'Ending', name: 'ending' },
		],
	},
	{
		label: 'Scores',
		types: [
			{ label: 'Numeric rating', name: 'rating_numeric' },
			{ label: 'Star rating', name: 'rating_stars' },
			{ label: 'Smiley rating', name: 'rating_emoji' },
			{ label: 'Bar rating', name: 'rating_bar' },
			{ label: 'Net Promotor Score (NPS)', name: 'nps' },
			{ label: 'Customer Effort Score (CES)', name: 'ces' },
			{ label: 'Goal Completion Rate (GCR)', name: 'gcr' },
			{ label: 'Thumbs', name: 'thumbs' },
		],
	},
	{
		label: 'Question',
		types: [
			{ label: 'Text input', name: 'input' },
			{ label: 'Multiple choice buttons', name: 'category' },
			{ label: 'Screenshot', name: 'screenshot' },
			{ label: 'Website data', name: 'website_data' },
		],
	},
	{
		label: 'Display',
		types: [
			{ label: 'Text', name: 'section_break' },
			{ label: 'Icon', name: 'icon' },
			{ label: 'Link', name: 'link' },
			{ label: 'Multimedia', name: 'multimedia' },
			{ label: 'Ending', name: 'ending' },
		],
	},
	{
		label: 'Contact',
		types: [
			{ label: 'Name', name: 'name' },
			{ label: 'Email', name: 'email' },
			{ label: 'Phone number', name: 'phone' },
		],
	},
];

export const openInputBlocks = ['input', 'textarea', 'email', 'name', 'phone'];

export const maxOneOfTypePerSurvey = ['contact', 'screenshot', 'name', 'email', 'phone'];

export const noLogicBlocks = [
	'matrix',
	'likert',
	'page_break',
	'section_break',
	'multimedia',
	'contact',
	'website_data',
	'link',
	'header',
	'footer',
	'intro',
	'thanks',
	'icon',
	'ending',
];

export const noLogicBlocksConversational = ['header', 'website_data', 'ending'];

export const mainMetricOptions = [
	'nps',
	'rating_numeric',
	'rating_stars',
	'rating_emoji',
	'rating_bar',
	'ces',
	'gcr',
	'thumbs',
];

export const emailPreselectMetrics = [
	'thumbs',
	'rating', //legacy for old ratings in email snippet
	'rating_numeric',
	'rating_stars',
	'rating_emoji',
	'rating_bar',
	'nps',
	'ces',
	'gcr',
	'category',
];
