import React, { useEffect, Fragment } from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';

import TagEditorCards from './TagEditorCards';
import TagsInProjectManager from './TagsInProjectManager';
import { TagsEditorProvider } from './TagsEditorContext';

import { AppBarLeftContent, PageHeader } from '../App';
import { useTagContext } from '../Tags';

import { useImmerReducer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	editorAreaWrap: {
		minWidth: '80%',
		maxWidth: '80%',
		minHeight: '85%',
		height: '100%',
		overflow: 'visible',
		display: 'flex',
		flexDirection: 'column',
	},
	editorAreaWrapSm: {
		maxWidth: '90%',
	},
	editorAreaWrapXs: {
		maxWidth: '95%',
	},
	editorArea: {
		position: 'relative',
		flexGrow: 1,
		borderRadius: theme.shape.borderRadius,
		border: `3px dashed ${theme.palette.grey[300]}`,
		padding: theme.spacing(1),
	},
	centerContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

function simpleReducer(state, action) {
	switch (action.type) {
		case 'set_selected':
			state.selectedRule = action.payload.id;
			state.selectedRuleIndex = action.payload.index;
			state.currentTab = 1;
			return;

		case 'set_step':
			state.currentStep = action.payload;
			return;

		case 'set_tab':
			state.currentTab = action.payload;
			return;

		case 'config_loaded':
			state.configLoaded = action.payload;
			return;

		default:
			return;
	}
}

export default function TagManagement({ ...props }) {
	const { tab = 'automated' } = useParams();
	const history = useHistory();

	const { t } = useTranslation();

	const {
		tags: {
			automated: { config, dispatch, loading, doUndo, doRedo, saveConfig },
		},
	} = useTagContext();

	const [simpleState, dispatchSimple] = useImmerReducer(simpleReducer, {
		currentStep: 0,
		currentTab: 0,
		configLoaded: false,
	});

	useEffect(() => {
		if (tab) {
			dispatchSimple({
				type: 'set_step',
				payload: tab !== 'manage' ? 0 : 1,
			});
		}
	}, [tab]);

	return (
		<Fragment>
			<PageHeader
				title={t`Tags`}
				prominent
				tooltip={t`Tagging lets you keep track of your feedback items, making segmenting and the management of your feedback even easier.`}
			/>
			<AppBarLeftContent tabs>
				<Tabs
					value={simpleState.currentStep}
					onChange={(e, value) =>
						history.push(`/application/tags/${value === 0 ? 'automated' : 'manage'}`)
					}
				>
					<Tab label={t`Auto-tags`} />
					<Tab label={t('Manage tags')} />
				</Tabs>
			</AppBarLeftContent>
			<TagsEditorProvider
				value={{
					state: {
						config: { ...config },
						...simpleState,
						configLoading: loading,
					},
					api: {
						dispatch,
						dispatchSimple,
						doUndo,
						doRedo,
						saveConfig,
					},
				}}
			>
				{simpleState.currentStep === 0 && <TagEditorCards />}
				{simpleState.currentStep === 1 && <TagsInProjectManager />}
			</TagsEditorProvider>
		</Fragment>
	);
}
