import React, { useContext, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';

import { useTranslation } from 'react-i18next';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';

import { DataSourceContext, DataFieldIcon } from '../DataSources';

const styles = theme => ({
	popoverContent: {
		padding: theme.spacing(1),
	},
	filterOverview: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	chip: {
		margin: theme.spacing(0.25),
	},
});

function CustomFilterPopover({
	anchor,
	onClose,
	onApply = () => {},
	onEdit = () => {},
	onDelete = () => {},
	viewOnly,
	filter,
	classes,
	...props
}) {
	const { name, rules, userId } = filter;
	const description = JSON.parse(filter.description);
	const { t } = useTranslation();
	const { datasource } = useContext(DataSourceContext);

	const filterSource = datasource.api.getField(
		filter.rules?.[0]?.identifier,
		true
	).source;

	return (
		<Popover
			open={Boolean(anchor)}
			anchorEl={anchor}
			onClose={onClose}
		>
			<div className={classes.popoverContent}>
				<Typography variant="h6">{name}</Typography>
				<Typography
					variant="caption"
					color="textSecondary"
				>
					{t('Form')}: {filterSource?.name}
				</Typography>

				<div className={classes.filterOverview}>
					<Typography variant="subtitle2">{t('Filters in view')}</Typography>
					{filter.rules.map(rule => {
						const field = datasource.api.getField(rule.identifier);
						const label = `${field.title}: ${rule.value.join(', ')}`;
						return (
							<Chip
								key={label}
								className={classes.chip}
								label={label}
								icon={<DataFieldIcon {...field} />}
							/>
						);
					})}
				</div>
			</div>

			<List disablePadding>
				<ListItem
					button
					onClick={onApply}
				>
					<ListItemIcon>
						<CheckIcon />
					</ListItemIcon>
					<ListItemText primary={t('Apply view')} />
				</ListItem>
				{!viewOnly && (
					<Fragment>
						<ListItem
							button
							onClick={onEdit}
						>
							<ListItemIcon>
								<EditIcon />
							</ListItemIcon>
							<ListItemText primary={t('Edit view')} />
						</ListItem>

						<ListItem
							button
							onClick={onDelete}
						>
							<ListItemIcon>
								<DeleteIcon />
							</ListItemIcon>
							<ListItemText primary={t('Delete view')} />
						</ListItem>
					</Fragment>
				)}
			</List>
		</Popover>
	);
}

export default withStyles(styles)(CustomFilterPopover);
