import React, { Fragment, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { SimpleDialog } from '../Layout';
import { TextEditor } from '../TextEditor';
import { ValidatedField } from '../Validation';
import { useImmer } from 'use-immer';
import { useAjaxForm } from '../Ajax';

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
	grid: {
		'& > div': {
			width: '100%',
		},
	},
	subjectEditor: {
		minHeight: 32,
	},
}));

function htmlToState(html = '') {
	const { contentBlocks, entityMap } = htmlToDraft(html);
	const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
	return EditorState.createWithContent(contentState);
}

function toHtml(draft) {
	try {
		return draftToHtml(convertToRaw(draft.getCurrentContent()));
	} catch (e) {
		return '';
	}
}

export default function AddEditStandardAnswerDialog({
	answer,
	loadData = () => {},
	...props
}) {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const classes = useStyles();
	const [state, setState] = useImmer({
		name: '',
		answer: EditorState.createEmpty(),
		subject: EditorState.createEmpty(),
	});

	useEffect(() => {
		if (typeof answer === 'object') {
			setValue('id', answer.id);
			setValue('name', answer.name);
			setValue('subject', htmlToState(answer.subject));
			setValue('answer', htmlToState(answer.answer));
		}
	}, [answer]);

	function setValue(key, value) {
		setState(draft => {
			draft[key] = value;
		});
	}

	const { clicked, loading, postForm } = useAjaxForm({
		url: answer ? '/application/email/edit' : '/application/email/add',
		data: {
			...(answer && { id: answer.id }),
			name: state.name,
			message: toHtml(state.answer),
			subject: toHtml(state.subject),
		},
		valid: () => state.nameValid && state.answerValid,
		onSuccess: () => {
			enqueueSnackbar(answer ? `Answer updated` : `Answer created`);
			setState(draft => {
				draft.name = '';
				draft.answer = EditorState.createEmpty();
				draft.subject = EditorState.createEmpty();
			});
			loadData();
			props.onClose();
		},
	});

	return (
		<SimpleDialog
			{...props}
			title={answer ? t`Edit answer` : t`New answer`}
			submit={answer ? t`Edit answer` : t`Create answer`}
			onSubmit={postForm}
			loading={loading}
			dataTestElement={answer ? 'standardAnswerEditAnswer' : 'standardAnswerCreateAnswer'}
			dataTrackEvent={answer ? 'settings_answer_edited' : 'settings_answer_created'}
		>
			<Fragment>
				<Grid
					container
					spacing={3}
					direction="column"
					className={classes.grid}
				>
					<Grid
						item
						xs
					>
						<ValidatedField
							component={TextField}
							label={t`Name`}
							fullWidth
							value={state.name}
							onChange={e => setValue('name', e.currentTarget.value)}
							validateValue={state.name}
							onValidChange={valid => setValue('nameValid', valid)}
							rules={[['required', true]]}
							showErrors={clicked}
							dataTestElement="standardAnswerNewName"
						/>
					</Grid>

					<Grid
						item
						xs
					>
						<FormControl
							fullWidth
							data-test-element="standardAnswerNewSubject"
						>
							<FormLabel>{t`Subject`}</FormLabel>
							<TextEditor
								options={[]}
								editorClassName={classes.subjectEditor}
								editorState={state.subject}
								onChange={editorState => setValue('subject', editorState)}
							/>
						</FormControl>
					</Grid>
					<Grid
						item
						xs
					>
						<FormControl
							fullWidth
							data-test-element="standardAnswerNewMessage"
						>
							<FormLabel error={clicked && !state.answerValid}>{t`Message`}</FormLabel>
							<ValidatedField
								component={TextEditor}
								editorState={state.answer}
								onChange={editorState => setValue('answer', editorState)}
								seperateError
								validateValue={toHtml(state.answer)}
								onValidChange={valid => setValue('answerValid', valid)}
								rules={[
									['custom', value => String(value).length > 0 && value !== '<p></p>\n'],
								]}
								showErrors={clicked}
							/>
						</FormControl>
					</Grid>
				</Grid>
			</Fragment>
		</SimpleDialog>
	);
}
