import React from 'react';

import { useEFM } from '@/components/Ajax';

export default function useGetProjectLanguageExpiration({
	surveyId,
	language,
	onSuccess = () => {},
}) {
	return useEFM(
		'/api/allow-od-language-change',
		{ surveyId: surveyId, language: language },
		false,
		false,
		false,
		{ disableCache: true, onSuccess: onSuccess }
	);
}
