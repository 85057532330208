import React from 'react';

import { projectIrrelevantEvents } from './constants';
import { useAppContext } from '@/components/AppContext';

export default function useNotificationProject(notification, includeIgnored = false) {
	const { app } = useAppContext();
	const { project_id, event } = notification;

	// ignore irrelevant and single projects
	if (
		(projectIrrelevantEvents.includes(event.type) || app.projects.asArray.length <= 1) &&
		!includeIgnored
	) {
		return null;
	}

	if (event.type.includes('access_request')) {
		return app.api.getProjectByUuid(event.request.project);
	}

	return app.projects.byKey[project_id];
}
