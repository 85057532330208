import React, { useState, useContext, Fragment, useRef, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Avatar from '@material-ui/core/Avatar';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';

import { AppContext } from '../AppContext';
import { useTranslation } from 'react-i18next';

import AccountIcon from '@material-ui/icons/AccountCircle';
import LanguageIcon from '@material-ui/icons/Translate';
import OrganisationIcon from '@material-ui/icons/Business';
import EmailAlertIcon from '@material-ui/icons/Email';
import NotificationsIcon from '@material-ui/icons/Notifications';
import BillingIcon from '@material-ui/icons/CreditCard';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import ProfileIcon from '@material-ui/icons/ContactMail';

import { NestedMenuItem } from '@/components/Layout';
import { UserAvatar } from '@/components/Users';
import {
	useHasPermission,
	permissionModules,
	permissionSubjects,
	permissionTypes,
} from '../Permission';

import { Link } from 'react-router-dom';

const languages = [
	{ lang: 'nl', flag: '/assets/img/countryflags/nl.svg', label: 'Dutch', id: '1' },
	{ lang: 'en', flag: '/assets/img/countryflags/gb.svg', label: 'English', id: '2' },
];

const useStyles = makeStyles(theme => ({
	pointer: {
		cursor: 'pointer',
	},
}));

export default function UserMenu() {
	const classes = useStyles();
	const [anchor, setAnchor] = useState(null);
	const [showLanguageMenu, setshowLanguageMenu] = useState(false);
	const { app } = useContext(AppContext);
	const updatePosition = useRef(() => {});
	const hasBillingViewPermission = useHasPermission({
		module: permissionModules.billing,
		subject: permissionSubjects.billing_management,
		permission: permissionTypes.view,
	});
	const hasAlertViewPermission = useHasPermission({
		module: permissionModules.settings,
		subject: permissionSubjects.alerts,
		permission: permissionTypes.view,
	});

	useLayoutEffect(() => {
		if (typeof updatePosition.current === 'function') updatePosition.current();
	}, [showLanguageMenu]);

	const { t, i18n } = useTranslation();
	const setLanguage = language => {
		app.api.changeLanguage(language);
		setAnchor(null);
		//close();
	};

	const currentLanguage =
		languages.find(lang => String(lang.id) === String(app.language.id)) || {};

	return (
		<Fragment>
			<UserAvatar
				user_id={app.users.current.id}
				onClick={e => setAnchor(e.currentTarget)}
				className={classes.pointer}
			/>
			<Popover
				anchorEl={anchor}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchor)}
				onClose={e => {
					setAnchor(null);
					setshowLanguageMenu(false);
				}}
				action={actions => {
					updatePosition.current =
						actions && actions.updatePosition ? actions.updatePosition : () => {};
				}}
			>
				<List disablePadding>
					<ListItem
						onClick={() => setAnchor(null)}
						button
						component={Link}
						disabled={app.session.onboarding_trial}
						to="/account/me"
						divider
					>
						<ListItemAvatar>
							<UserAvatar user_id={app.users.current.id} />
						</ListItemAvatar>
						<ListItemText
							primary={app.users.current.firstname + ' ' + app.users.current.lastname}
							secondary={app.users.current.email}
							primaryTypographyProps={{
								variant: 'subtitle2',
							}}
						/>
					</ListItem>

					<NestedMenuItem
						leftIcon={<LanguageIcon />}
						primary={t`app_general-user_menu-language`}
						secondary={t(currentLanguage.label)}
						parentOpen={Boolean(anchor)}
						anchorOrigin="left"
						transformOrigin="right"
					>
						<ListSubheader>{t`app_general-user_menu-select_language`}</ListSubheader>

						{languages.map(language => (
							<ListItem
								selected={app.language.id === language.id}
								button
								onClick={e => setLanguage(language)}
								key={'lang-' + language.lang}
							>
								<ListItemAvatar>
									<Avatar>
										<img
											style={{ height: '100%' }}
											src={language.flag}
										/>
									</Avatar>
								</ListItemAvatar>

								<ListItemText primary={t(language.label)} />
							</ListItem>
						))}
					</NestedMenuItem>

					<ListItem
						onClick={() => setAnchor(null)}
						button
						component={Link}
						to="/account/me"
						disabled={app.session.onboarding_trial}
					>
						<ListItemIcon>
							<AccountIcon />
						</ListItemIcon>
						<ListItemText primary={t`app_general-user_menu-account`} />
					</ListItem>

					<ListItem
						onClick={() => setAnchor(null)}
						button
						component={Link}
						disabled={app.session.onboarding_trial}
						to={`/application/users/profile/${app.users.current.id}`}
					>
						<ListItemIcon>
							<ProfileIcon />
						</ListItemIcon>
						<ListItemText primary={t`app_general-user_menu-profile`} />
					</ListItem>

					<ListItem
						onClick={() => setAnchor(null)}
						button
						component={Link}
						disabled={app.session.onboarding_trial}
						to="/application/alerts"
					>
						<ListItemIcon>
							<EmailAlertIcon />
						</ListItemIcon>
						<ListItemText
							primary={t`app_general-user_menu-email_alerts`}
							primaryTypographyProps={
								!hasAlertViewPermission
									? {
											color: 'textSecondary',
									  }
									: null
							}
						/>
					</ListItem>
					<ListItem
						onClick={() => setAnchor(null)}
						button
						component={Link}
						disabled={app.session.onboarding_trial}
						to="/application/notifications"
					>
						<ListItemIcon>
							<NotificationsIcon />
						</ListItemIcon>
						<ListItemText primary={t`app_general-user_menu-notifications`} />
					</ListItem>

					{hasBillingViewPermission && (
						<>
							<ListItem
								onClick={() => setAnchor(null)}
								button
								component={Link}
								disabled={app.session.onboarding_trial}
								to={app.package.trial ? '/account/billing/plans' : '/account/billing'}
							>
								<ListItemIcon>
									<BillingIcon />
								</ListItemIcon>
								<ListItemText primary={t`app_general-user_menu-billing`} />
							</ListItem>

							<ListItem
								onClick={() => setAnchor(null)}
								button
								component={Link}
								disabled={app.session.onboarding_trial}
								to="/account/organisation"
							>
								<ListItemIcon>
									<OrganisationIcon />
								</ListItemIcon>
								<ListItemText primary={t`app_general-user_menu-organisation`} />
							</ListItem>
						</>
					)}

					<ListItem
						button
						component="a"
						href="/auth/ajax/logout"
					>
						<ListItemIcon>
							<LogoutIcon />
						</ListItemIcon>
						<ListItemText primary={t`app_general-user_menu-signout`} />
					</ListItem>
				</List>
			</Popover>
		</Fragment>
	);
}
