import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CesIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 24 24`}
			style={{ transform: 'rotate(-90deg)' }}
			{...props}
		>
			<path
				fill="currentColor"
				d="M5,2V8H3V2H5M3,22H5V16H3V22M6,12A2,2 0 0,0 4,10A2,2 0 0,0 2,12A2,2 0 0,0 4,14A2,2 0 0,0 6,12M22,7V17A2,2 0 0,1 20,19H11A2,2 0 0,1 9,17V14L7,12L9,10V7A2,2 0 0,1 11,5H20A2,2 0 0,1 22,7M17,13H12V15H17V13M19,9H12V11H19V9Z"
			/>
		</SvgIcon>
	);
}
