import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import MailIcon from '@material-ui/icons/Mail';

import { useTranslation } from 'react-i18next';

import { PageHeader } from '@/components/App';
import { Grid, AddCard, EmptyState, ActionButton, Alert } from '@/components/Layout';
import StandardAnswerCard from './StandardAnswerCard';
import useStandardAnswers from './useStandardAnswers';
import AddEditStandardAnswerDialog from './AddEditStandardAnswerDialog';
import { useHasPermission, NoPermissionTooltip } from '@/components/Permission';

const useStyles = makeStyles(theme => ({
	alert: {
		marginTop: theme.spacing(2),
	},
}));

export default function StandardAnswerList({}) {
	const [addOpen, setAddOpen] = useState(false);
	const { t } = useTranslation();
	const [data, loading, error, loadData] = useStandardAnswers();
	const classes = useStyles();
	const hasEditPermission = useHasPermission();
	return (
		<Fragment>
			<PageHeader title={t`Standard answers`} />
			{data?.answers?.length > 0 ? (
				<Fragment>
					<Grid
						container
						spacing={3}
					>
						<Grid
							item
							md={4}
							sm={6}
							xs={12}
						>
							<NoPermissionTooltip>
								<AddCard
									label={t`New answer`}
									onClick={() => setAddOpen(true)}
									dataTestElement="standardAnswerNewAnswer"
									disabled={!hasEditPermission}
								/>
							</NoPermissionTooltip>
						</Grid>
						{data.answers.map(answer => (
							<Grid
								item
								md={4}
								sm={6}
								xs={12}
								key={answer.id}
							>
								<StandardAnswerCard
									answer={answer}
									loadData={loadData}
								/>
							</Grid>
						))}
					</Grid>

					<Alert
						className={classes.alert}
						variant="default"
					>{t`You can select standard answers when replying to a feedback item from the inbox`}</Alert>
				</Fragment>
			) : (
				<EmptyState
					image={<img src={`/assets/img/r/emptystate/standard_answers.gif`} />}
					primary={t`settings-standard_answers-emptystate_default-title`}
					secondary={t`settings-standard_answers-emptystate_default-text`}
					action={
						<NoPermissionTooltip>
							<ActionButton
								onClick={() => setAddOpen(true)}
								variant="contained"
								color="primary"
								label={t`settings-standard_answers-emptystate_default-button_create_answer`}
								disabled={!hasEditPermission}
							/>
						</NoPermissionTooltip>
					}
				/>
			)}
			<AddEditStandardAnswerDialog
				open={addOpen}
				onClose={() => setAddOpen(false)}
				loadData={loadData}
			/>
		</Fragment>
	);
}
