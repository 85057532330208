import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function NumericRatingIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<path
				d="M19.1,13.7v12.4H6.7V13.7H19.1 M20,11.9H5.8c-0.5,0-0.9,0.4-0.9,0.9v14.2c0,0.5,0.4,0.9,0.9,0.9H20
				c0.5,0,0.9-0.4,0.9-0.9V12.8C20.9,12.3,20.5,11.9,20,11.9L20,11.9z M13.2,16.2c0.8,0,1.4,0.2,1.9,0.5s0.7,0.9,0.7,1.5
				c0,0.4-0.1,0.8-0.4,1.1c-0.2,0.3-0.6,0.6-1.1,0.9c0.6,0.3,1.1,0.7,1.3,1c0.3,0.4,0.4,0.8,0.4,1.2c0,0.7-0.3,1.2-0.8,1.7
				c-0.5,0.4-1.2,0.6-2.1,0.6c-0.9,0-1.6-0.2-2.2-0.6c-0.5-0.4-0.8-0.9-0.8-1.7c0-0.5,0.1-0.9,0.4-1.3c0.3-0.4,0.7-0.7,1.2-1
				c-0.5-0.3-0.8-0.6-1-1c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.6,0.2-1.1,0.7-1.5C11.8,16.4,12.4,16.2,13.2,16.2z M11.9,22.5
				c0,0.3,0.1,0.6,0.3,0.8s0.5,0.3,0.9,0.3c0.4,0,0.8-0.1,1-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.6-0.5-1-0.7
				C12.3,21.4,11.9,21.9,11.9,22.5z M13.2,17.5c-0.3,0-0.5,0.1-0.7,0.2s-0.3,0.4-0.3,0.6c0,0.2,0.1,0.4,0.2,0.6s0.4,0.4,0.8,0.6
				c0.4-0.2,0.6-0.4,0.8-0.5c0.1-0.2,0.2-0.4,0.2-0.6c0-0.3-0.1-0.5-0.3-0.6S13.5,17.5,13.2,17.5z M4.9,13.7v12.4H-7.5V13.7H4.9
				 M5.8,11.9H-8.3c-0.5,0-0.9,0.4-0.9,0.9v14.2c0,0.5,0.4,0.9,0.9,0.9H5.8c0.5,0,0.9-0.4,0.9-0.9V12.8C6.7,12.3,6.3,11.9,5.8,11.9
				L5.8,11.9z M-3,24.7l3.1-6.9H-4v-1.5h6v1.1l-3.2,7.2H-3z M33.3,13.7v12.4H20.9V13.7H33.3 M34.2,11.9H20c-0.5,0-0.9,0.4-0.9,0.9v14.2
				c0,0.5,0.4,0.9,0.9,0.9h14.2c0.5,0,0.9-0.4,0.9-0.9V12.8C35.1,12.3,34.7,11.9,34.2,11.9L34.2,11.9z M30.3,19.4c0,1.6-0.3,2.9-1,3.7
				c-0.7,0.8-1.7,1.2-3.1,1.2c-0.5,0-0.9,0-1.1-0.1v-1.4c0.3,0.1,0.7,0.1,1,0.1c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.7-0.4,0.9-0.8
				c0.2-0.4,0.3-0.9,0.3-1.5h-0.1c-0.2,0.4-0.5,0.6-0.8,0.8s-0.7,0.2-1.1,0.2c-0.7,0-1.3-0.2-1.7-0.7c-0.4-0.5-0.6-1.1-0.6-1.9
				c0-0.9,0.3-1.6,0.8-2.1c0.5-0.5,1.2-0.8,2.1-0.8c0.6,0,1.2,0.1,1.6,0.4c0.5,0.3,0.8,0.7,1.1,1.3C30.2,18,30.3,18.6,30.3,19.4z
				 M27.3,17.2c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.3-0.3,0.6-0.3,1.1c0,0.4,0.1,0.7,0.3,1s0.5,0.4,0.9,0.4c0.4,0,0.7-0.1,0.9-0.4
				c0.3-0.2,0.4-0.5,0.4-0.8c0-0.5-0.1-0.8-0.4-1.1C27.9,17.3,27.6,17.2,27.3,17.2z M47.5,13.7v12.4H35.1V13.7H47.5 M48.3,11.9H34.2
				c-0.5,0-0.9,0.4-0.9,0.9v14.2c0,0.5,0.4,0.9,0.9,0.9h14.2c0.5,0,0.9-0.4,0.9-0.9V12.8C49.2,12.3,48.8,11.9,48.3,11.9L48.3,11.9z
				 M39.4,24.2h-1.8v-4.8l0-0.8l0-0.9c-0.3,0.3-0.5,0.5-0.6,0.6l-1,0.8L35.3,18l2.7-2.1h1.5V24.2z M47.5,20c0,1.5-0.2,2.5-0.7,3.2
				c-0.5,0.7-1.2,1.1-2.2,1.1c-1,0-1.7-0.4-2.2-1.1s-0.7-1.8-0.7-3.2c0-1.5,0.2-2.6,0.7-3.3c0.5-0.7,1.2-1,2.2-1c1,0,1.7,0.4,2.2,1.1
				C47.3,17.5,47.5,18.6,47.5,20z M43.5,20c0,1,0.1,1.8,0.3,2.2s0.5,0.7,0.9,0.7c0.4,0,0.7-0.2,0.9-0.7c0.2-0.4,0.3-1.2,0.3-2.2
				c0-1-0.1-1.8-0.3-2.2s-0.5-0.7-0.9-0.7c-0.4,0-0.7,0.2-0.9,0.7S43.5,19,43.5,20z"
			/>
		</SvgIcon>
	);
}
