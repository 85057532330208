import React, { forwardRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { SystemElementCardBase } from '../Layout';
import DataFieldItem from './DataFieldItem';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	headerBg: {
		backgroundColor: props =>
			props.survey_type === 'import'
				? theme.palette.mopinion['dataset']
				: theme.palette.mopinion[props.campaign]
				? theme.palette.mopinion[props.campaign]
				: theme.palette.mopinion.web,
	},
}));

function surveyFormatIcon(format) {
	switch (format) {
		case 'conversational':
			return '/assets/img/surveytypes/survey-conversational.svg';
		case 'form':
		default:
			return '/assets/img/surveytypes/survey-standard.svg';
	}
}

const DataSourceCard = forwardRef(function DataSourceCard(
	{
		children,
		variant,
		onClick,
		actions,
		title,
		survey_type,
		campaign,
		survey_format,
		withDataField,
		dataTestElement = '',
		field = {},
		...props
	},
	ref
) {
	const { t } = useTranslation();
	const classes = useStyles({ campaign, survey_type });

	const typeImage =
		survey_type === 'builder'
			? surveyFormatIcon(survey_format)
			: survey_type === 'import'
			? '/assets/img/surveytypes/dataset.svg'
			: '';

	return (
		<SystemElementCardBase
			onClick={onClick}
			img={typeImage || ''}
			title={title}
			dataTestElement={dataTestElement}
			outerContent={
				withDataField ? (
					<DataFieldItem
						{...field}
						xs={variant === 'small'}
					/>
				) : null
			}
			actions={actions}
			ref={ref}
			CardMediaClassName={
				['builder', 'import'].includes(survey_type) ? classes.headerBg : null
			}
			{...props}
		>
			{children}
		</SystemElementCardBase>
	);
});

export default DataSourceCard;
