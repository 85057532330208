import React from 'react';
import WebHookContext from './WebHookContext';

const withWebHookContext = Component => props =>
	(
		<WebHookContext.Consumer>
			{value => (
				<Component
					{...props}
					{...value}
				/>
			)}
		</WebHookContext.Consumer>
	);

export default withWebHookContext;
