import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { VariableSizeList } from 'react-window';
import { Typography } from '@material-ui/core';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
	const { data, index, style } = props;
	return React.cloneElement(data[index], {
		style: {
			...style,
			top: style.top + LISTBOX_PADDING,
		},
	});
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return (
		<div
			ref={ref}
			{...props}
			{...outerProps}
		/>
	);
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
	const { children, ...other } = props;
	const itemData = React.Children.toArray(children);
	const theme = useTheme();

	const itemCount = itemData.length;
	const itemSize = 48;

	const getChildSize = child => {
		if (React.isValidElement(child) && child.type === ListSubheader) {
			return 48;
		}

		return itemSize;
	};

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize;
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};

	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					itemData={itemData}
					height={getHeight() + 2 * LISTBOX_PADDING}
					width="100%"
					key={itemCount}
					outerElementType={OuterElementType}
					innerElementType="ul"
					itemSize={index => getChildSize(itemData[index])}
					overscanCount={5}
					itemCount={itemCount}
				>
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});

const useStyles = makeStyles({
	listbox: {
		'& ul': {
			padding: 0,
			margin: 0,
		},
	},
});

const renderGroup = params => [
	params.group ? (
		<ListSubheader
			key={params.key}
			component="div"
		>
			{params.group}
		</ListSubheader>
	) : null,
	params.children,
];

export default function VirtualizedAutocomplete({
	label,
	options,
	variant = 'outlined',
	TextFieldProps = {},
	...props
}) {
	const { renderOption: renderOptionProp, ...rest } = props;

	const renderOption = typeof renderOptionProp === 'function' ? renderOptionProp : null;

	const classes = useStyles();

	return (
		<Autocomplete
			disableListWrap
			classes={classes}
			ListboxComponent={ListboxComponent}
			renderGroup={renderGroup}
			options={options}
			groupBy={option => option.category}
			renderInput={params => (
				<TextField
					{...params}
					variant={variant}
					label={label}
					fullWidth
					{...TextFieldProps}
				/>
			)}
			renderOption={renderOption}
			{...rest}
		/>
	);
}
