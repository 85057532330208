import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function RatingIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<path
				id="XMLID_2_"
				d="M23.4,19.1l1.5-6.3L20,8.5L26.5,8L29,2l2.5,6L38,8.5l-4.9,4.3l1.5,6.3L29,15.7L23.4,19.1z M12.8,8.8
	c0,0.7,0.6,1.4,1.3,1.4s1.4-0.6,1.4-1.4s-0.6-1.3-1.4-1.3S12.8,8,12.8,8.8z M6.5,8.8c0,0.7,0.6,1.4,1.3,1.4s1.3-0.6,1.3-1.4
	S8.6,7.4,7.8,7.4S6.5,8,6.5,8.8z M20,11c0,5-4,9-9,9c-5,0-9-4-9-9s4-9,9-9C16,2,20,6,20,11z M18.2,11c0-4-3.2-7.2-7.2-7.2
	S3.8,7,3.8,11S7,18.2,11,18.2S18.2,15,18.2,11z M12.8,14.1c0.9,1,0,0.1,0.9,1c-0.8,0.5-1.7,0.8-2.7,0.8c-1,0-1.9-0.3-2.7-0.8
	c0.1-0.2,0.8-0.9,0.9-1c0.5,0.3,1.1,0.5,1.8,0.5C11.7,14.6,12.2,14.4,12.8,14.1z M13.5,25H3.4V35h10.1V25 M14.2,23.5
	c0.4,0,0.7,0.3,0.7,0.7v11.5c0,0.4-0.3,0.7-0.7,0.7H2.7c-0.4,0-0.7-0.3-0.7-0.7V24.2c0-0.4,0.3-0.7,0.7-0.7H14.2L14.2,23.5z
	 M9.7,27.1H8.5l-2.2,1.7L7,29.7l0.8-0.6c0.1-0.1,0.3-0.2,0.5-0.5l0,0.7l0,0.6v3.9h1.4V27.1z M25,25H15V35H25V25 M25.8,23.5
	c0.4,0,0.7,0.3,0.7,0.7v11.5c0,0.4-0.3,0.7-0.7,0.7H14.2c-0.4,0-0.7-0.3-0.7-0.7V24.2c0-0.4,0.3-0.7,0.7-0.7H25.8L25.8,23.5z
	 M22.4,32.6h-2.9v-0.1l0.9-0.8c0.6-0.5,1-0.9,1.2-1.2s0.4-0.6,0.5-0.8s0.2-0.6,0.2-0.9c0-0.4-0.1-0.7-0.3-0.9s-0.4-0.5-0.8-0.6
	c-0.3-0.2-0.7-0.2-1.1-0.2c-0.3,0-0.6,0-0.9,0.1s-0.5,0.2-0.7,0.3c-0.2,0.1-0.5,0.3-0.8,0.6l0.8,0.9c0.3-0.2,0.5-0.4,0.8-0.5
	c0.2-0.1,0.5-0.2,0.8-0.2c0.2,0,0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.2,0.4-0.3,0.5s-0.5,0.6-1,1.1
	l-1.7,1.7v1h4.7V32.6z M36.6,25H26.5V35h10.1V25 M37.3,23.5c0.4,0,0.7,0.3,0.7,0.7v11.5c0,0.4-0.3,0.7-0.7,0.7H25.8
	c-0.4,0-0.7-0.3-0.7-0.7V24.2c0-0.4,0.3-0.7,0.7-0.7H37.3L37.3,23.5z M33,27.4c-0.4-0.3-1-0.4-1.6-0.4c-0.9,0-1.6,0.2-2.2,0.7l0.7,1
	c0.3-0.2,0.5-0.3,0.8-0.4c0.2-0.1,0.5-0.1,0.7-0.1c0.6,0,1,0.3,1,0.8c0,0.3-0.1,0.6-0.4,0.7c-0.2,0.1-0.6,0.2-1.1,0.2h-0.5v1.1h0.5
	c0.6,0,1,0.1,1.2,0.2c0.3,0.1,0.4,0.4,0.4,0.7c0,0.4-0.1,0.6-0.3,0.8c-0.2,0.2-0.6,0.2-1,0.2c-0.3,0-0.6,0-0.9-0.1
	c-0.3-0.1-0.6-0.2-0.9-0.3v1.2c0.6,0.2,1.2,0.4,1.9,0.4c0.9,0,1.5-0.2,2-0.5s0.7-0.9,0.7-1.5c0-0.4-0.1-0.8-0.4-1.1
	c-0.3-0.3-0.7-0.4-1.2-0.5v0c0.5-0.1,0.8-0.3,1.1-0.6c0.3-0.3,0.4-0.7,0.4-1.1C33.6,28.1,33.4,27.7,33,27.4z"
			/>
		</SvgIcon>
	);
}
