import React from 'react';
import MuiGrid from '@material-ui/core/Grid';

export default React.forwardRef(function Grid(props, ref) {
	return (
		<MuiGrid
			{...props}
			ref={ref}
		/>
	);
});
