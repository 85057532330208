import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';

import { useTranslation } from 'react-i18next';

import { ValidatedField } from '../Validation';
import { UserAutoComplete } from '../AutoComplete';
import { exportOptions } from './constants';
import { useHasPermission, NoPermissionTooltip } from '../Permission';

const useStyles = makeStyles(theme => ({
	grid: {
		'& > div': {
			width: '100%',
		},
	},
	bottomMargin: {
		marginBottom: theme.spacing(2),
	},
}));

export default function FeedbackExportSettings({
	exportConfig,
	setExportConfig,
	postTemplate,
	loadingTemplate,
}) {
	const { t } = useTranslation();
	const classes = useStyles();
	const hasEditPermission = useHasPermission();

	const {
		save_as_template,
		export_name,
		schedule_name,
		schedule_description,
		template_name,
		template_description,
		clicked,
	} = exportConfig;

	return (
		<Grid
			container
			spacing={2}
			className={classes.grid}
			direction="column"
		>
			{exportConfig.variant !== 'scheduled' &&
				exportConfig.variant !== 'template' &&
				!exportConfig.make_schedule && (
					<Grid
						item
						xs={12}
					>
						<ValidatedField
							component={TextField}
							id="export-name"
							fullWidth
							label={t`Export name`}
							value={export_name}
							rules={[['required', true]]}
							showErrors={clicked}
							errorMessage={t`Please fill in an export name`}
							onChange={e => {
								const exportName = e.currentTarget.value;
								setExportConfig(draft => {
									draft.export_name = exportName;
								});
							}}
						/>
					</Grid>
				)}
			{(exportConfig.variant === 'scheduled' || exportConfig.make_schedule) && (
				<Fragment>
					<Grid
						item
						xs={12}
					>
						<ValidatedField
							component={TextField}
							id="schedule-name"
							fullWidth
							label={t`Schedule name`}
							value={schedule_name}
							rules={[['required', true]]}
							showErrors={clicked}
							errorMessage={t`Please fill in an export name`}
							onChange={e => {
								const exportName = e.currentTarget.value;
								setExportConfig(draft => {
									draft.schedule_name = exportName;
								});
							}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<ValidatedField
							component={TextField}
							id="export-name"
							fullWidth
							label={t`Schedule description`}
							value={schedule_description}
							onChange={e => {
								const schedule_description = e.currentTarget.value;
								setExportConfig(draft => {
									draft.schedule_description = schedule_description;
								});
							}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<ValidatedField
							component={UserAutoComplete}
							multiple
							label={t`Send scheduled export to`}
							placeholder={t`Search users`}
							selectedItem={
								exportConfig.notify_to.length
									? exportConfig.notify_to.map(user_id => {
											return { id: Number(user_id) };
									  })
									: []
							}
							onChange={userArray => {
								setExportConfig(draft => {
									draft.notify_to = userArray.map(user => user.id);
								});
							}}
							renderChips
							validateValue={exportConfig.notify_to}
							rules={[
								[
									'custom',
									value => {
										return value ? value.length > 0 : false;
									},
								],
							]}
							errorMessage={t`Please add at least one user`}
							showErrors={clicked}
						/>
					</Grid>
				</Fragment>
			)}
			{exportConfig.variant !== 'scheduled' &&
				exportConfig.variant !== 'template' &&
				!exportConfig.make_schedule &&
				!exportConfig.use_template &&
				!exportConfig.maxSetupsReached && (
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="body1"
							color="textSecondary"
							gutterBottom
						>{t`Template setting`}</Typography>
						<NoPermissionTooltip>
							<FormControl
								component="fieldset"
								disabled={!hasEditPermission}
							>
								<FormControlLabel
									control={
										<Checkbox
											checked={save_as_template ? true : false}
											onChange={e => {
												setExportConfig(draft => {
													draft.save_as_template = e.target.checked;
												});
											}}
											name="save_as_template"
											color="secondary"
										/>
									}
									label={t`Save settings as template`}
								/>
							</FormControl>
						</NoPermissionTooltip>
					</Grid>
				)}
			{(exportConfig.variant === 'template' ||
				save_as_template ||
				exportConfig.use_template) && (
				<Fragment>
					<Grid
						item
						xs={12}
					>
						<ValidatedField
							component={TextField}
							id="template-name"
							fullWidth
							label={t`Template name`}
							value={template_name}
							rules={[['required', true]]}
							showErrors={clicked}
							errorMessage={t`Please fill in an template name`}
							onChange={e => {
								const template_name = e.currentTarget.value;
								setExportConfig(draft => {
									draft.template_name = template_name;
								});
							}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<ValidatedField
							component={TextField}
							id="template-description"
							fullWidth
							label={t`Template Description`}
							value={template_description}
							onChange={e => {
								const template_description = e.currentTarget.value;
								setExportConfig(draft => {
									draft.template_description = template_description;
								});
							}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<FormControl component="fieldset">
							<RadioGroup
								aria-label="template_acces"
								name="template-acces"
								value={exportConfig.template_access}
								onChange={e => {
									setExportConfig(draft => {
										draft.template_access = e.target.value;
									});
								}}
							>
								<FormControlLabel
									value="public"
									control={<Radio />}
									label={t`Make template available for all users within the report`}
								/>
								<FormControlLabel
									value="private"
									control={<Radio />}
									label={t`Make template only available to me`}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
				</Fragment>
			)}
			{exportConfig.variant !== 'quick' && (
				<Fragment>
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="body1"
							color="textSecondary"
							gutterBottom
						>{t`Export as`}</Typography>
						<FormControl
							className={classes.formControl}
							fullWidth
						>
							<Select
								labelId="export-options"
								value={exportConfig.format}
								onChange={e => {
									setExportConfig(draft => {
										draft.format = e.target.value;
									});
								}}
							>
								{exportOptions.map((option, index) => {
									return (
										<MenuItem
											value={option.value}
											key={index}
										>
											{option.label}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>
					{exportConfig.format === 'xlsx' && exportConfig.survey_ids.length !== 1 && (
						<Grid
							item
							xs={12}
						>
							<Typography
								variant="body1"
								color="textSecondary"
								gutterBottom
							>{t`Export sheet settings`}</Typography>
							<FormControl component="fieldset">
								<RadioGroup
									aria-label="export_sheets"
									name="export_sheets"
									value={exportConfig.split_into_sheets ? 'sheets' : 'single'}
									onChange={e => {
										setExportConfig(draft => {
											draft.split_into_sheets =
												e.target.value === 'sheets' ? true : false;
										});
									}}
								>
									<FormControlLabel
										value="single"
										control={<Radio />}
										label={t`Export all data sources into a single sheet`}
									/>
									<FormControlLabel
										value="sheets"
										control={<Radio />}
										label={t`Export data sources into separate sheets`}
									/>
								</RadioGroup>
							</FormControl>
						</Grid>
					)}
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="body1"
							color="textSecondary"
							gutterBottom
						>{t`Column names`}</Typography>
						<FormControl component="fieldset">
							<RadioGroup
								aria-label="rename_labels"
								name="rename_labels"
								value={
									exportConfig.prettify_labels
										? 'pretty_question_titles'
										: exportConfig.rename_labels
										? 'question_titles'
										: 'data_fields'
								}
								onChange={e => {
									setExportConfig(draft => {
										draft.rename_labels = e.target.value === 'data_fields' ? false : true;
										draft.prettify_labels =
											e.target.value === 'pretty_question_titles' ? true : false;
									});
								}}
							>
								<FormControlLabel
									value="question_titles"
									control={<Radio />}
									label={t`Use question titles as column name`}
								/>
								<FormControlLabel
									value="pretty_question_titles"
									control={<Radio />}
									label={t`Use form id, question titles and datafield id combined as column name`}
								/>
								<FormControlLabel
									value="data_fields"
									control={<Radio />}
									label={t`Use datafield name as column name`}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
				</Fragment>
			)}
		</Grid>
	);
}
