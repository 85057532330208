import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FormSquaredIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			{props.selected ? (
				<g>
					<polygon points="38,2 38,12 40,12 40,0 28,0 28,2 " />
					<polygon points="2,2 12,2 12,0 0,0 0,12 2,12 " />
					<polygon points="38,38 28,38 28,40 40,40 40,28 38,28 " />
					<polygon points="2,38 2,28 0,28 0,40 12,40 12,38 " />
				</g>
			) : (
				<g>
					<polygon points="39,39 28,39 28,40 40,40 40,28 39,28 " />
					<polygon points="0,12 1,12 1,1 12,1 12,0 0,0 " />
					<polygon points="28,0 28,1 39,1 39,12 40,12 40,0 " />
					<polygon points="1,28 0,28 0,40 12,40 12,39 1,39 " />
				</g>
			)}
		</SvgIcon>
	);
}
