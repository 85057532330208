import React, { Fragment, useEffect, useMemo } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles';

import { EFM, Mjolnir } from '../../api';
import { withAppContext, AppContext } from '../AppContext';
import DataSourceItem from './DataSourceItem';
import DataFieldItem from './DataFieldItem';
import { useDataSourceContext } from './DataSourceContext';
import DataFieldList from './DataFieldList';
import { OptionalDroppable } from '../Layout';
import { debounce, strComp } from '../../utils';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import DataSourceAvatar from './DataSourceAvatar';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import produce from 'immer';
import { useImmer } from 'use-immer';
import { Translation, useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	title: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(2),
		marginBottom: theme.spacing(1),
	},
	subtitle: {
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(2),
	},
	dataSourceItem: {
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	divWrapper: {
		borderTop: `1px solid ${theme.palette.divider}`,
	},
	inset: {
		paddingLeft: theme.spacing(2),
	},
	collapse: {
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	listBg: {
		background: '#fff',
	},
	isDragging: {
		boxShadow: theme.shadows[5],
	},
	selectedText: {
		//color:theme.palette.primary.main,
		fontWeight: theme.typography.fontWeightMedium,
	},
	selectedAvatar: {
		//background:theme.palette.primary.main
	},
}));

function DataSourceCheckboxItem({
	survey_type = 'builder',
	title = '',
	subtitle = '',
	fields = [],
	isOpen,
	children,
	selected,
	survey_format,
	campaign,
	...props
}) {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Fragment>
			<List
				className={classes.listBg}
				{...props}
			>
				<ListItem
					selected={isOpen}
					button
				>
					<ListItemAvatar>
						<DataSourceAvatar
							survey_format={survey_format}
							survey_type={survey_type}
							campaign={campaign}
						/>
					</ListItemAvatar>
					<ListItemText
						primary={title ? title : null}
						secondary={subtitle ? subtitle : ''}
						classes={{
							primary: `${isOpen ? classes.selectedText : ''}`,
						}}
					/>
					<ListItemSecondaryAction>
						<IconButton>{isOpen ? <ExpandLess /> : <ExpandMore />}</IconButton>
					</ListItemSecondaryAction>
				</ListItem>
			</List>
			{children}
		</Fragment>
	);
}

function DataFieldCheckBoxList({
	open,
	datasource,
	sourceId,
	config,
	updateDatafields,
	dataTestElement = '',
	hideVarTypes = [],
	...props
}) {
	const classes = useStyles();
	const { t } = useTranslation();

	useEffect(() => {
		datasource.api.loadSurveyFields(sourceId);
	}, [sourceId]);

	const formattedFields = datasource.api.getFieldsFormattedByVarType(sourceId);

	const datafields = useMemo(
		() =>
			Object.keys(formattedFields).map((key, i) => {
				const category = formattedFields[key];

				return category.fields.length > 0 ? (
					<Fragment>
						<ListSubheader
							disableSticky
							key={category.label}
						>
							{category.label}
						</ListSubheader>
						{category.fields
							.filter(field => hideVarTypes.indexOf(field.var_type) === -1)
							.map((field, i) => {
								const { title, survey_block, system_var_simple, niceType, system_var } =
									field;

								return (
									<DataFieldItem
										key={field.id + field.system_var_simple}
										title={title ?? system_var}
										type={niceType}
										id={field.id}
										index={i}
										button
										sourceId={sourceId}
										field={Object.assign({}, field, { surveyId: sourceId })}
										className={props.inset ? classes.inset : ''}
										dataTestElement={dataTestElement}
										onSelect={e => {
											updateDatafields(
												'datafield_ids',
												field.id,
												config.datafield_ids.indexOf(field.id) > -1 ? false : true
											);
										}}
										action={
											<Checkbox
												checked={config.datafield_ids.indexOf(field.id) > -1}
												onClick={e => {
													updateDatafields('datafield_ids', field.id, e.target.checked);
												}}
											/>
										}
										{...props}
									/>
								);
							})}
					</Fragment>
				) : (
					[]
				);
			}),
		[formattedFields]
	);

	return <Collapse in={open}>{datafields}</Collapse>;
}

function DataSourceCheckboxList({
	dataSources,
	config,
	updateDatafields,
	surveyId,
	hideVarTypes = [],
	...props
}) {
	const {
		datasource,
		datasource: { forms, datasets, integrations },
	} = useDataSourceContext();
	const [open, setOpen] = useImmer({});
	const classes = useStyles();
	const { t } = useTranslation();

	function toggleOpen(id) {
		setOpen(draft => {
			draft[id] = !!!draft[id];
		});
	}

	const dataSourceArray = dataSources
		? dataSources
		: [
				{ name: t('Forms'), arr: forms.asArray },
				{ name: t('Datasets'), arr: datasets.asArray },
				{ name: t('Integrations'), arr: integrations.asArray },
		  ];

	const singleDataSource =
		forms.byKey[surveyId] ||
		datasets.byKey[surveyId] ||
		integrations.byKey[surveyId] ||
		{};

	return (
		<Fragment>
			{surveyId ? (
				<Fragment key={singleDataSource.id}>
					<DataSourceCheckboxItem
						{...singleDataSource}
						title={singleDataSource.name}
						subtitle={singleDataSource.description}
						isOpen
						dataSource={singleDataSource}
					>
						<DataFieldCheckBoxList
							open
							sourceId={singleDataSource.id}
							config={config}
							datasource={datasource}
							updateDatafields={updateDatafields}
							hideVarTypes={hideVarTypes}
						/>
					</DataSourceCheckboxItem>
				</Fragment>
			) : (
				dataSourceArray
					.filter(obj => obj.arr && obj.arr.length)
					.map((obj, index) => {
						return (
							<div key={obj.name || obj.subtitle}>
								{obj.name && (
									<Typography
										variant="subtitle2"
										className={classes.title}
									>
										{obj.name}
									</Typography>
								)}
								{obj.subtitle && (
									<Typography
										variant="body2"
										color="textSecondary"
										className={classes.subtitle}
									>
										{obj.subtitle}
									</Typography>
								)}
								{obj.arr.map(dataSource => (
									<Fragment key={dataSource.id}>
										<DataSourceCheckboxItem
											{...dataSource}
											title={dataSource.name}
											subtitle={dataSource.description}
											onClick={e => toggleOpen(dataSource.id)}
											isOpen={open[dataSource.id]}
											dataSource={dataSource}
										>
											<DataFieldCheckBoxList
												open={open[dataSource.id]}
												datasource={datasource}
												sourceId={dataSource.id}
												config={config}
												updateDatafields={updateDatafields}
												hideVarTypes={hideVarTypes}
											/>
										</DataSourceCheckboxItem>
									</Fragment>
								))}
							</div>
						);
					})
			)}
		</Fragment>
	);
}

export default DataSourceCheckboxList;
