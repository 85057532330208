import React, { useContext } from 'react';
import { useEFM } from '@/components/Ajax';
const FeatureToggleContext = React.createContext({});

export function FeatureToggleContextProvider({ children }) {
	const [{ features = [] }, loading] = useEFM('/application/ajax/features');
	return (
		<FeatureToggleContext.Provider value={features}>
			{children}
		</FeatureToggleContext.Provider>
	);
}

export function useFeatureToggleContext() {
	return useContext(FeatureToggleContext);
}
