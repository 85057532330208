import React, { useState, useEffect, memo, useRef } from 'react';

import { useAppContext } from '@/components/AppContext';
import { useAjaxForm } from '@/components/Ajax';
import { SimpleDialog } from '@/components/Layout';

export default memo(function IntroductionDialog({ seenKey, title, content, ...props }) {
	if (!seenKey) {
		throw new Error('IntroductionDialog requires seenKey');
	}

	const [open, setOpen] = useState(false);
	const { app } = useAppContext();
	const active = useRef(false);
	const { postForm } = useAjaxForm({
		url: '/application/ajax/set-meta',
		data: {
			update: {
				[seenKey]: true,
			},
		},
		onSuccess: () => app.api.getUsers(),
	});

	const meta = app.api.getUserMeta();
	useEffect(() => {
		if (!meta[seenKey] && !active.current) {
			active.current = true;
			setOpen(true);
			postForm();
		}
	}, [meta, seenKey]);

	return (
		<SimpleDialog
			open={open}
			title={title}
			onClose={() => setOpen(false)}
			{...props}
		>
			{content}
		</SimpleDialog>
	);
});
