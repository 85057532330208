import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import {
	Card,
	Grid,
	CardContent,
	CardActions,
	Typography,
	ActionButton,
	ContactDialog,
} from '@/components/Layout';
import { useAppContext } from '@/components/AppContext';
import TextAnalyticsLanguageList from './TextAnalyticsLanguageList';

const useStyles = makeStyles(theme => ({
	gridContainer: {
		'& > div:not(:last-child)': {
			marginBottom: theme.spacing(2),
		},
	},
	titleMargin: {
		marginBottom: theme.spacing(2),
	},
}));

export default function TextAnalyticsExclusionList() {
	const classes = useStyles();
	const { t } = useTranslation();
	const { app } = useAppContext();

	const [contactDialogOpen, setContactDialogOpen] = useState(false);

	return (
		<>
			<div className={classes.gridContainer}>
				<Grid
					container
					spacing={3}
				>
					<Grid
						item
						sm={12}
						md={9}
						xl={10}
					>
						<TextAnalyticsLanguageList />
					</Grid>
					<Grid
						item
						sm={12}
						md={3}
						xl={2}
					>
						<Card>
							<CardContent>
								<Typography
									component="p"
									className={classes.titleMargin}
									variant="subtitle1"
									fontWeight="medium"
								>
									{t`reporting-text_analytics-card_translation_upgrade-title`}
								</Typography>
								<Typography
									component="p"
									variant="body1"
									gutterBottom
								>
									{t`reporting-text_analytics-card_translation_upgrade-text`}
								</Typography>
							</CardContent>
							<CardActions>
								<ActionButton
									variant="outlined"
									color="primary"
									onClick={() => setContactDialogOpen(true)}
								>
									{t`reporting-text_analytics-card_translation_upgrade-button`}
								</ActionButton>
							</CardActions>
						</Card>
					</Grid>
				</Grid>
			</div>

			<ContactDialog
				open={contactDialogOpen}
				onClose={() => setContactDialogOpen(false)}
				title={t`reporting-text_analytics-contact-title`}
				subject={
					app.package.enterprise
						? t`reporting-text_analytics-contact-translation_feature`
						: t`reporting-text_analytics-contact-enterprise_translation_feature`
				}
				mailTo="success@mopinion.com"
			/>
		</>
	);
}
