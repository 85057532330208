import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function InputIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<path
				fill="currentColor"
				id="XMLID_1_"
				d="M36,13.1v13.9H4V13.1H36 M36,11.1H4c-1.1,0-2,0.9-2,2v13.9c0,1.1,0.9,2,2,2h32c1.1,0,2-0.9,2-2V13.1
	C38,12,37.1,11.1,36,11.1L36,11.1z M6,15.3v1.9h3.2v7.6h1.9v-7.6h3.2v-1.9H6z M18,18.4h-5.7v1.9h1.9v4.4h1.9v-4.4H18V18.4z"
			/>
		</SvgIcon>
	);
}
