import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';

import DeadlineIcon from '@material-ui/icons/Alarm';
import UpdateIcon from '@material-ui/icons/Update';

import { useTranslation } from 'react-i18next';

import { format, differenceInDays, addDays } from '../../utils/date';
import { useAppContext } from '../AppContext';
import { Typography } from '../Layout';
import { formatISOdate, formatMonthNumber, formatDayName } from './exportUtils';

const useStyles = makeStyles(theme => ({
	deadlineIcon: {
		verticalAlign: 'middle',
		marginRight: theme.spacing(0.5),
	},
	deadlineText: {
		verticalAlign: 'middle',
	},
}));

export default function ExportDates({
	dateCreated,
	interval,
	nextInterval,
	expiration = 7,
	variant = 'body2',
	color = 'textSecondary',
	...props
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	const { app } = useAppContext();

	function calculateDays(dateCreated, expiration) {
		const deadline = format(addDays(new Date(dateCreated), expiration), app.date.format);

		return t(`Available until {{date}}`, { date: deadline });
	}

	function daysTooltip(dateCreated, expiration) {
		const historyDeadline = Math.abs(differenceInDays(new Date(dateCreated), new Date()));
		const daysAvailable = expiration - historyDeadline;

		return daysAvailable === 0
			? t`Last day available`
			: t('Available for {{daysAvailable}} {{day}}', {
					daysAvailable: daysAvailable,
					day: daysAvailable === 1 ? t`day` : t`days`,
			  });
	}

	function makeInterval(interval) {
		const hour = app.date.separator === '/' ? 'h' : 'u';

		switch (interval.every) {
			case 'Day':
				return t(`Export every day at {{interval}}{{hour}}`, {
					interval: interval.at,
					hour: hour,
				});
			case 'Week':
				return t(`Export every week on {{weekNr}} at {{at}}{{hour}}`, {
					weekNr: t(formatDayName(interval.on)),
					at: interval.at,
					hour: hour,
				});
			case 'Month':
				if (interval.on === 'L') {
					return t(`Export every last day of the month at {{interval}}{{hour}}`, {
						interval: interval.at,
						hour: hour,
					});
				}
				return t(`Export every month on the {{monthNr}} at {{interval}}{{hour}}`, {
					monthNr: formatMonthNumber(interval.on),
					interval: interval.at,
					hour: hour,
				});
			case 'Quarter':
				return t`Export quarterly`;
			case 'Year':
				return t`Export Yearly`;
			default:
				return '';
		}
	}

	const deadlineDate =
		nextInterval && nextInterval !== 'inactive'
			? `${t`The next export will be sent on`} ${formatISOdate(
					nextInterval,
					app.date.format
			  )}`
			: nextInterval === 'inactive'
			? t`Schedule inactive`
			: dateCreated
			? calculateDays(dateCreated, expiration)
			: interval
			? makeInterval(interval)
			: '';

	return (
		<Fragment>
			<Tooltip
				title={
					dateCreated ? <Fragment>{daysTooltip(dateCreated, expiration)}</Fragment> : ''
				}
			>
				<Box
					display="inline"
					{...props}
				>
					{nextInterval ? (
						<UpdateIcon
							color="action"
							fontSize="small"
							className={classes.deadlineIcon}
						/>
					) : (
						<DeadlineIcon
							color="action"
							fontSize="small"
							className={classes.deadlineIcon}
						/>
					)}
					<Typography
						variant={variant}
						color={color}
						display="inline"
						className={classes.deadlineText}
					>
						{deadlineDate}
					</Typography>
				</Box>
			</Tooltip>
		</Fragment>
	);
}
