import React from 'react';
import { useAppContext } from '@/components/AppContext';

import { useEFM } from '@/components/Ajax';

export default function useGetDashboards() {
	const { app } = useAppContext();
	return useEFM(
		'/dashboard/ajax/get-dashboards',
		{},
		!(app.auth.loggedIn && app.projects.current.id)
	);
}
