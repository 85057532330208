import React, { useState, useEffect } from 'react';
import {
	CardCvcElement,
	CardNumberElement,
	CardExpiryElement,
} from '@stripe/react-stripe-js';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import StripeInput from './StripeInput';
import CCIcon from './CCIcon';

const useStyles = makeStyles(theme => ({
	smallInput: {
		minWidth: 100,
	},
}));

// const CardNumberElement = stripes.CardNumberElement;
// const CardExpiryElement = stripes.CardExpiryElement;
// const CardCVCElement = stripes.CardCVCElement;

//console.log(rest);

function StripeTextField(props) {
	const {
		InputLabelProps,
		stripeElement,
		InputProps,
		onChange = () => {},
		error: errorFromProps,
		errorMessage,
		...other
	} = props;
	const [error, setError] = useState('');

	return (
		<TextField
			fullWidth
			InputLabelProps={{
				...InputLabelProps,
				shrink: true,
			}}
			InputProps={{
				...InputProps,
				inputProps: {
					component: stripeElement,
				},
				inputComponent: StripeInput,
			}}
			onChange={e => {
				if (e.error && e.error.message) {
					setError(e.error.message);
				} else if (error) {
					setError('');
				}
				onChange(e);
			}}
			error={Boolean(errorFromProps) || Boolean(error)}
			helperText={Boolean(errorFromProps) || Boolean(error) ? error || errorMessage : ''}
			{...other}
		/>
	);
}

export function StripeTextFieldNumber({ onChange = () => {}, ...props }) {
	const [brand, setBrand] = useState('unknown');
	return (
		<StripeTextField
			label="Credit Card Number"
			stripeElement={CardNumberElement}
			onChange={e => {
				setBrand(e.brand);
				onChange(e);
			}}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<CCIcon brand={brand} />
					</InputAdornment>
				),
			}}
			{...props}
		/>
	);
}

export function StripeTextFieldExpiry(props) {
	const classes = useStyles();
	return (
		<StripeTextField
			label="Expires"
			stripeElement={CardExpiryElement}
			className={classes.smallInput}
			{...props}
		/>
	);
}

export function StripeTextFieldCVC(props) {
	const classes = useStyles();
	return (
		<StripeTextField
			label="CVC Code"
			stripeElement={CardCvcElement}
			className={classes.smallInput}
			{...props}
		/>
	);
}
