import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import * as deepmerge from 'deepmerge';

import { useDataSourceContext } from '@/components/DataSources';
import { defaultInsightsChartOptions } from './defaultChartOptions';
import { formatChart, serieBase } from './chartUtils';
import {
	getTitle,
	getSubtitle,
	getAxisField,
	createAxisWithDimensions,
} from '@/components/DirectInsights/directInsightsUtils';
import { directInsightChartOptions } from './constants';
import { getDirectInsightSubColors, getChartSentimentColor } from '@/styles';

function getSubColor(colorSet, index) {
	const colorIndex = index - Math.floor(index / colorSet.length) * colorSet.length;

	return colorSet[colorIndex];
}

export default function useDirectInsightChart({
	datasourceId,
	datafieldIds,
	chartSettings,
	periodFilter,
}) {
	const { datasource } = useDataSourceContext();
	const { t } = useTranslation();

	return useMemo(() => {
		const datafields = datasource.api.getFieldArray(datasourceId, true);
		const currentDatafield = datasource.api.getField(datafieldIds[0]);

		const chartOptions = directInsightChartOptions[chartSettings.type];

		const xAxis = createAxisWithDimensions('x', chartOptions, currentDatafield);
		const yAxis = createAxisWithDimensions('y', chartOptions, currentDatafield);

		const chartUsesDateTime =
			chartOptions.serieOptions?.x === 'datetime' ||
			chartOptions.serieOptions?.y === 'datetime';

		const sentiment =
			chartOptions.customSeries?.reduce((series, serie) => {
				if (serie.sentiment) {
					series.push(serie.sentiment);
				}
				return series;
			}, []) ??
			chartOptions.sentiment ??
			[];

		const colors = sentiment.length
			? sentiment.map(color => getChartSentimentColor(color))
			: getDirectInsightSubColors(chartSettings.defaultChartColor);

		const makeSerie = (datafieldId, index) => {
			return serieBase(
				{
					id: getAxisField(datafieldId, chartOptions.serieOptions?.y, datafields),
					surveyId: datasourceId,
					survey_block: {
						type: currentDatafield.survey_block?.type,
					},
					title: datafields.find(field => field.id === datafieldId)?.title ?? '',
				},
				{
					x: getAxisField(datafieldId, chartOptions.serieOptions?.x, datafields),
					chartType: chartOptions.chartType === 'score' ? 2 : 1,
					average: chartOptions.serieOptions?.average ?? 0,
					...{
						color: chartSettings.defaultSubColorSet
							? getSubColor(chartSettings?.defaultSubColorSet, index)
							: chartSettings.defaultChartColor,
						decimal: chartOptions.decimal ?? 0,
						custom: {
							date_group: chartUsesDateTime ? periodFilter : '',
							...(chartOptions.serieOptions?.limit && {
								limit: chartOptions.serieOptions.limit,
							}),
							...(chartOptions?.serieOptions?.order && {
								order: chartOptions.serieOptions.order,
							}),
						},
					},
				}
			);
		};

		const getSeries = () => {
			if (datafieldIds.length > 1) {
				return datafieldIds.map((datafieldId, index) => {
					return makeSerie(datafieldId, index);
				});
			}
			if (chartOptions.customSeries) {
				return chartOptions.customSeries.map((customSerie, index) => {
					return deepmerge(
						makeSerie(currentDatafield.id),
						sentiment
							? {
									...customSerie,
									color: customSerie.sentiment
										? getChartSentimentColor(customSerie.sentiment)
										: colors[index],
							  }
							: customSerie
					);
				});
			}
			return [makeSerie(currentDatafield.id)];
		};

		const series = getSeries();

		const chart = formatChart({
			...defaultInsightsChartOptions,
			chart: {
				type: chartOptions.type,
				seriesData: series,
			},
			title: {
				text: chartSettings.customTitle
					? t(chartSettings.customTitle)
					: getTitle(currentDatafield),
				style: {
					fontStyle: 'normal',
					fontSize: '18px',
				},
			},
			subtitle: {
				text: t(
					getSubtitle(
						chartSettings.customSubTitle ?? chartOptions.subTitleKey,
						chartUsesDateTime ? periodFilter : null
					)
				),
				style: {
					fontStyle: 'normal',
					fontSize: '14px',
				},
			},
			...(chartOptions.plotOptions && { plotOptions: chartOptions.plotOptions }),
			...(chartOptions.legend && { legend: chartOptions.legend }),
			...(chartSettings.customLegend &&
				chartUsesDateTime && {
					legend: {
						labelFormatter: function () {
							return t(chartSettings.customLegend);
						},
					},
				}),
			...(Object.keys(xAxis).length > 0 && { xAxis: xAxis }),
			...(Object.keys(yAxis).length > 0 && { yAxis: yAxis }),
		});

		return {
			chart: {
				...chart,
				colors: colors,
			},
			chartType: chartOptions.type === 'score' ? 2 : 1,
			series,
		};
	}, [datasource, chartSettings, datafieldIds, periodFilter, datasourceId, t]);
}
