import React, { useEffect, useRef } from 'react';

export default function useEffectSkipInitialRender(fn, dependencies) {
	const initialRenderDone = useRef(false);
	useEffect(() => {
		if (initialRenderDone.current) {
			fn();
		}
		initialRenderDone.current = true;
	}, [dependencies]);
}
