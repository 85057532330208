import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { IntroductionTour } from '@/components/Onboarding';
import { Typography } from '@/components/Layout';

export default function DeploymentVersioningIntroductionTour() {
	const steps = useMemo(() => {
		return [
			{
				target: '[data-onboarding="version-toggle"]',
				placement: 'bottom',
				title: <Trans i18nKey="deployment_versioning_introduction_tour-step1-title" />,
				content: (
					<Trans
						i18nKey="deployment_versioning_introduction_tour-step1-content"
						components={{
							strong: <strong />,
							row: (
								<Typography
									display="block"
									variant="body2"
									mb={2}
								/>
							),
						}}
					/>
				),
				dataTrackEvent: 'deployment_versioning_introduction_tour-step1',
				disableOverlay: false,
				disableScrolling: true,
			},
		];
	}, []);

	return (
		<IntroductionTour
			seenKey="seen_deployment_versioning_introduction_tour"
			steps={steps}
			disableScrolling
		/>
	);
}
