import React, { useContext, useState, memo, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { DataSourceContext } from '../DataSources';
import { AutoComplete } from '../AutoComplete';

import { useTranslation } from 'react-i18next';
import get from 'lodash.get';

export default function PossibleValues(props) {
	const { datafieldId } = props;
	const { datasource } = useContext(DataSourceContext);

	const datafield = useMemo(() => datasource.api.getField(datafieldId), [datafieldId]);
	const useContains =
		['6', '13', '14', '35', '36', '37'].indexOf(String(datafield.var_type)) > -1;
	const hasAbsoluteValues =
		(!useContains && datafield.block_id) ||
		(!useContains && ['12', '16', '24', '27'].indexOf(String(datafield.var_type)) > -1) ||
		get(datafield, 'import_var', '').toLowerCase().indexOf('checkbox') > -1;

	return hasAbsoluteValues ? (
		<PossibleValueSelect {...props} />
	) : (
		<PossibleValueAutoComplete {...props} />
	);
}

export function PossibleValueAutoComplete({
	datafieldId,
	label,
	value = [],
	onChange = () => {},
	multiple = true,
	dataTestElement = null,
	...props
}) {
	const { t } = useTranslation();
	const { datasource } = useContext(DataSourceContext);

	const autoCompleteLabel = label || null;
	const possibles = datafieldId ? datasource.api.getPossibleValue(datafieldId) : [];
	const options = Array.isArray(possibles) ? possibles.map(v => ({ label: v })) : [];
	const selectedItem = Array.isArray(value)
		? value.map(v => ({ label: v }))
		: value
		? [{ label: value }]
		: [];

	const handleChange = changeValue => {
		onChange(
			Array.isArray(changeValue)
				? changeValue.map(item => item.label)
				: get(changeValue, 'label')
		);
	};

	return (
		<AutoComplete
			options={options}
			selectedItem={selectedItem}
			label={autoCompleteLabel}
			onChange={handleChange}
			multiple={multiple}
			chipSeparator={t`or`}
			dataTestElement={dataTestElement}
			{...props}
		/>
	);
}

function parseValue(value, multiple) {
	if (Array.isArray(value)) {
		return [
			...new Set(
				value.map(v => {
					return String(v);
				})
			),
		];
	}

	if (multiple) {
		return [String(value)];
	}

	return String(value);
}

export const PossibleValueSelect = ({
	datafieldId,
	label,
	value = [],
	valueIsObject,
	valueKey = 'value',
	returnKey,
	onChange = () => {},
	multiple = true,
	dataTestElement = null,
	helperText,
	...props
}) => {
	const { t } = useTranslation();
	const { datasource } = useContext(DataSourceContext);
	const { source, ...field } = datasource.api.getField(datafieldId, true);
	const block = datasource.api.getBlock(field.block_id);

	const parsedValue = parseValue(value, multiple);

	const absolutes =
		['12', '16', '24', '27'].indexOf(String(field.var_type)) > -1 && !field.block_id
			? generateValues(field)
			: extractValues(block, t, field);

	return (
		<FormControl fullWidth>
			{label && <InputLabel>{label}</InputLabel>}
			<Select
				multiple={multiple}
				value={parsedValue}
				input={<Input fullWidth />}
				data-test-element={dataTestElement}
				renderValue={selected => {
					return multiple
						? selected
								?.map(itemValue =>
									get(
										absolutes.find(abs => abs.value == itemValue),
										'label',
										''
									)
								)
								.join(', ')
						: get(
								absolutes.find(abs => abs.value == selected),
								'label',
								''
						  );
				}}
				onChange={e => {
					onChange(e.target.value);
				}}
			>
				{absolutes.map(item => {
					return (
						<MenuItem
							data-test-element={dataTestElement ? dataTestElement + 'Option' : null}
							key={item.label + item.value}
							value={String(item.value)}
						>
							{multiple && (
								<Checkbox
									checked={parsedValue?.find(v => v == item.value) !== undefined}
								/>
							)}
							<ListItemText primary={item.label} />
						</MenuItem>
					);
				})}
			</Select>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};

//Extract the possible values from the block or generated them because they are hardcoded values
function extractValues(block, t, field) {
	const {
		typeName,
		properties,
		properties: {
			elements,
			labelsAsValue,
			includeZero,
			scale = 10,
			emoji,
			showCaptions,
			elements_extra = {},
		} = {},
	} = block;
	const { import_var = '' } = field;

	if (import_var.toLowerCase().indexOf('checkbox') > -1) {
		return [
			{ value: '____true', label: t('selected') },
			{ value: '____false', label: t('not selected') },
		];
	}
	switch (typeName) {
		case 'nps':
			return [...Array(11)].map((x, i) => ({ value: i, label: i }));

		case 'ces':
			return Object.keys(elements).map(key => ({
				value: key,
				label: elements[key].label,
			}));

		case 'rating':
			switch (properties.type) {
				case 'stars':
					return [...Array(scale)].map((x, i) => ({
						value: labelsAsValue ? elements[i + 1].label : i + 1,
						label: showCaptions ? elements[i + 1].label : i + 1,
					}));

				case 'numeric':
					return [...Array(scale)].map((x, i) => ({
						value: includeZero ? i : i + 1,
						label: includeZero ? i : i + 1,
					}));

				case 'bar':
					return [...Array(5)].map((x, i) => ({ value: i + 1, label: i + 1 }));

				case 'emoji':
					return Object.keys(emoji).map(key => ({
						value: labelsAsValue ? emoji[key].label : key,
						label: showCaptions ? emoji[key].label : key,
					}));
			}

		case 'gcr':
		case 'thumbs':
		case 'category':
		case 'select':
		case 'radio':
			const mappedElements = Object.keys(elements).map(key => ({
				value: elements[key].value ? elements[key].value : elements[key].label,
				label: elements[key].label,
			}));

			const mappedElementsExtra = Object.keys(elements_extra)
				.filter(key => !elements[key].linkedLabel)
				.map(key => ({
					value: elements_extra[key].value
						? elements_extra[key].value
						: elements_extra[key].label,
					label: elements_extra[key].label,
				}));

			return [...mappedElements, ...mappedElementsExtra];

		case 'checkbox':
			return [
				{ value: '____true', label: t('selected') },
				{ value: '____false', label: t('not selected') },
			];

		default:
			return [];
	}
}

function generateValues(datafield) {
	const { var_type } = datafield;
	switch (String(var_type)) {
		//nps
		case '12':
			return [...Array(11)].map((x, i) => ({ value: i, label: i }));

		//ces 1-5
		case '16':
		case '27':
			return [...Array(5)].map((x, i) => ({ value: i + 1, label: i + 1 }));

		//ces 5-1
		case '24':
			return [...Array(5)].map((x, i) => ({ value: i + 1, label: i + 1 })).reverse();

		default:
			return [];
	}
}
