import React, { useState, useEffect } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useImmer } from 'use-immer';

import { makeStyles } from '@/styles';
import { EFM, Mjolnir } from '@/api';
import { useAppContext } from '@/components/AppContext';
import {
	AjaxFormDialog,
	Grid,
	Alert,
	TextField,
	MenuItem,
	Box,
	Typography,
	Link,
	FormControlLabel,
	Checkbox,
} from '@/components/Layout';
import { ValidatedField } from '@/components/Validation';
import {
	PermissionGroupAutocomplete,
	useGetPermissionGroups,
	isAdminOwnerGroup,
} from '@/components/Permission';
import { getDaysInMonth, getDaysInYear } from '@/utils/date';
import AnonymizeList from './AnonymizeList';

const useStyles = makeStyles(theme => ({
	fillWidth: {
		width: '100%',
	},
	withoutLabel: {
		marginTop: theme.spacing(2),
	},
	info: {
		backgroundColor: theme.palette.primary.main,
	},
	hidden: {
		display: 'none', //in development, hide for now
	},
}));

function ProjectAdditDialog({ project, additOpen, setAddit, anonymise }) {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const timezones = app.timezones.asArray;
	const [surveylist, updateSurveylist] = useState({
		surveys: {},
		surveyfields: {},
		possibles: {},
	});
	const [{ groups = [] }, permissionGroupsLoading, _err, loadPermissionGroups] =
		useGetPermissionGroups();

	const [state, setState] = useImmer({
		id: project ? project.id : null,
		name: project ? project.name : '',
		description: project ? project.description : '',
		language: project ? project.language : '',
		timezone_iso: project ? project.timezone_iso : '',
		permission_groups: project?.permission_groups ?? [],
		anon_days: project ? project.anon_days : 0,
		converted_anon_days: project ? project.anon_days : 0,
		anon_period: 'days',
		anon_checked: project && project.anon_days > 0 ? true : false,
		anon_params: Array.isArray(project?.anon_params) ? {} : project?.anon_params ?? {},
		anon_datafields: {},
		re_anonymize: [],
	});

	useEffect(() => {
		if (!project && groups.length > 0) {
			setState(draft => {
				draft.permission_groups = groups
					.filter(group => isAdminOwnerGroup(group))
					.map(group => group.uuid);
			});
		}
	}, [project, groups]);

	useEffect(() => {
		if (project && groups.length > 0) {
			setState(draft => {
				draft.permission_groups = groups
					.filter(group => group.projects.includes(project.uuid))
					.map(group => group.uuid);
			});
		}
	}, [project, groups]);

	async function loadSurveys() {
		const response = await EFM.post('/survey/ajax/get-surveys-from-project', {
			id: project.id,
		});
		if (response.forms) {
			updateSurveylist(list => ({ ...list, surveys: response.forms }));
		}
	}

	async function loadSurveyFields(surveyId) {
		const response = await Mjolnir.get(`fields/${app.domain}/${project.id}/${surveyId}`);
		if (response.fields) {
			response.fields.map(field => {
				if (field.var_type === 29) {
					setState(draft => {
						draft.anon_datafields[field.id] = 29;
					});
				}
				if (field.var_type === 35) {
					loadPossibleValues(surveyId, field.id);
				}
			});

			updateSurveylist(list => ({
				...list,
				surveyfields: {
					...list.surveyfields,
					[surveyId]: response.fields,
				},
			}));
		}
	}

	async function loadPossibleValues(surveyId, fieldId) {
		const response = await Mjolnir.get(`possiblevalues/${app.domain}/${fieldId}`);
		if (response.values) {
			updateSurveylist(list => ({
				...list,
				possibles: {
					...list.possibles,
					[surveyId]: fetchParams(response.values),
				},
			}));
		}
	}

	useEffect(() => {
		if (additOpen) {
			loadSurveys();
		}
	}, [additOpen]);

	useEffect(() => {
		function shouldLoadSurveyFields(dataSource) {
			if (Array.isArray(dataSource)) {
				dataSource.forEach(source => {
					if (!surveylist.surveyfields[source.id]) {
						loadSurveyFields(source.id);
					}
				});
			}
		}
		[surveylist.surveys].forEach(x => shouldLoadSurveyFields(x));
	}, [surveylist.surveys]);

	function updateImmer(value, target) {
		setState(draft => {
			draft[target] = value;
		});
	}

	function calculateDate(value, action) {
		const daysInMonth = getDaysInMonth(new Date());
		const daysInYear = getDaysInYear(new Date());

		let actualDays = value > 0 ? value : 1;
		let calculatedDays = value > 0 ? value : 1;

		let convertedDays =
			value == state.anon_days && state.anon_days !== state.converted_anon_days
				? state.converted_anon_days
				: actualDays;

		function monthlyDays(daysPerMonth, amount) {
			let monthlyDays = daysPerMonth;
			let correction = Math.floor(amount / 12);
			let totalDays = 0 - correction;

			for (var i = 0; i < amount; i++) {
				totalDays += monthlyDays;
				monthlyDays = monthlyDays == 30 ? 31 : 30;
			}

			return totalDays;
		}

		if (action === 'days') {
			calculatedDays =
				state.anon_period === 'months'
					? monthlyDays(daysInMonth, convertedDays)
					: state.anon_period === 'years'
					? daysInYear * convertedDays
					: convertedDays;
		} else if (action === 'months') {
			calculatedDays =
				state.anon_period === 'days'
					? Math.round(convertedDays / daysInMonth)
					: state.anon_period === 'years'
					? convertedDays * 12
					: convertedDays;

			actualDays = calculatedDays.length
				? monthlyDays(daysInMonth, calculatedDays)
				: daysInMonth;
		} else if (action === 'years') {
			calculatedDays =
				state.anon_period === 'days'
					? Math.round(convertedDays / daysInYear)
					: state.anon_period === 'months'
					? Math.round(convertedDays / 12)
					: convertedDays;

			actualDays = calculatedDays.length ? calculatedDays * daysInYear : daysInYear;
		}

		setState(draft => {
			draft.anon_days = actualDays > 0 ? actualDays : 1;
			draft.converted_anon_days = calculatedDays > 0 ? calculatedDays : 1;
			draft.anon_period = action;
		});
	}

	function updateAnonParams(obj) {
		if (!state.anon_params[obj.id]) {
			setState(draft => {
				draft.anon_params[obj.id] = { params: [], initialVartype: {} };
			});
		}

		const isChecked =
			state.anon_datafields[obj.field.id] && state.anon_datafields[obj.field.id] == 29
				? state.anon_params[obj.id] &&
				  state.anon_params[obj.id].initialVartype[obj.field.id]
					? state.anon_params[obj.id].initialVartype[obj.field.id]
					: 1
				: 29;
		const wasChecked = obj.field.var_type == 29 ? true : false;

		setState(draft => {
			draft.anon_datafields[obj.field.id] = isChecked;
		});

		//only update initialVartype when not 29
		if (!wasChecked) {
			setState(draft => {
				draft.anon_params[obj.id].initialVartype[obj.field.id] = obj.field.var_type;
			});
		}

		if (state.re_anonymize.indexOf(obj.id) < 0) {
			setState(draft => {
				draft.re_anonymize.push(obj.id);
			});

			enqueueSnackbar(`Notice: Anonymised data cannot be recovered!`);
		}
	}

	function updateUrlParams(obj) {
		if (!state.anon_params[obj.id]) {
			setState(draft => {
				draft.anon_params[obj.id] = { params: [], initialVartype: {} };
			});
		}

		setState(draft => {
			draft.anon_params[obj.id].params = obj.params.map(value => {
				return value.label;
			});
		});
	}

	function fetchParams(urls) {
		const params = [];

		for (const i in urls) {
			if (urls[i].match(/\?/gi) !== null) {
				const fetchparams = urls[i].split('?')[1];
				const searchParams = new URLSearchParams(fetchparams);

				for (const pair of searchParams.entries()) {
					if (params.indexOf(pair[0]) < 0) {
						params.push(pair[0]);
					}
				}
			}
		}

		return params;
	}

	return (
		<AjaxFormDialog
			title={!project ? t`Add report` : t`Edit report`}
			open={additOpen}
			onClose={() => setAddit(false)}
			dataTestElement="projects-addit-dialog-submit-button"
			dataTrackEvent="settings_report_edited"
			AjaxFormProps={{
				url: !project ? '/application/projects/add' : '/application/projects/edit',
				data: {
					id: state.id,
					name: state.name,
					description: state.description,
					language: state.language,
					timezone_iso: state.timezone_iso,
					permission_groups: state.permission_groups,
					anon_days: state.anon_days,
					anon_params: JSON.stringify(state.anon_params),
					anonymizedDf: JSON.stringify(state.anon_datafields),
					reanonymize: JSON.stringify(state.re_anonymize),
					state: 'send',
				},
				valid: () => state.name && state.description && state.language,
				onSuccess: r => {
					setAddit(false);
					enqueueSnackbar(!project ? `Report added` : `Report edited`);
					loadPermissionGroups();
					app.api.getProjects();
				},
				onFail: r => {
					enqueueSnackbar(`Something went wrong`);
				},
			}}
			render={({ clicked }) => (
				<Grid
					container
					direction="column"
				>
					<Grid
						container
						spacing={3}
					>
						<Grid
							item
							xs={12}
							className={classes.fillWidth}
						>
							<ValidatedField
								component={TextField}
								id="project-name"
								fullWidth
								rules={[['required', true]]}
								showErrors={clicked}
								label={t`Report name`}
								value={state.name}
								onChange={e => {
									updateImmer(e.currentTarget.value, 'name');
								}}
								data-test-element="projects-addit-dialog-name"
							/>
						</Grid>
						<Grid
							item
							xs={12}
							className={classes.fillWidth}
						>
							<ValidatedField
								component={TextField}
								id="project-description"
								fullWidth
								rules={[['required', true]]}
								showErrors={clicked}
								label={t`Report description`}
								value={state.description}
								onChange={e => {
									updateImmer(e.currentTarget.value, 'description');
								}}
								data-test-element="projects-addit-dialog-description"
							/>
						</Grid>
						<Grid
							item
							xs={12}
							className={classes.fillWidth}
						>
							<ValidatedField
								component={TextField}
								id="project-language"
								select
								rules={[['required', true]]}
								showErrors={clicked}
								fullWidth
								label={t`Language`}
								value={state.language}
								onChange={e => {
									updateImmer(e.target.value, 'language');
								}}
								data-test-element="projects-addit-dialog-language"
							>
								<MenuItem
									key={0}
									value={2}
									data-test-element="projects-addit-dialog-language-english"
								>{t`English`}</MenuItem>
								<MenuItem
									key={1}
									value={1}
								>{t`Dutch`}</MenuItem>
							</ValidatedField>
						</Grid>
						<Grid
							item
							xs={12}
							className={`${classes.fillWidth} ${classes.hidden}`}
						>
							<TextField
								id="project-timezone"
								select
								fullWidth
								label={t`UTC timezone`}
								value={state.timezone_iso}
								onChange={e => {
									updateImmer(e.target.value, 'timezone_iso');
								}}
							>
								{timezones.map(option => (
									<MenuItem
										key={option.id}
										value={option.iso3166}
									>
										{option.utc} - {option.country} - ({option.timezone})
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid
							item
							xs={12}
							className={classes.fillWidth}
						>
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								mt={2}
							>
								<Typography variant="subtitle">{t`settings-reports-add_edit_dialog-access_groups-title`}</Typography>
								<Link
									tooltip={t`settings-reports-add_edit_dialog-access_groups-helper-tooltip`}
								>
									{t`settings-reports-add_edit_dialog-access_groups-helper-label`}
								</Link>
							</Box>

							<PermissionGroupAutocomplete
								label={t`settings-reports-add_edit_dialog-access_groups-label`}
								multiple
								value={state.permission_groups}
								onChange={(e, value) =>
									updateImmer(
										value.map(group => group.uuid),
										'permission_groups'
									)
								}
								fixedAdminOwner
							/>
						</Grid>
						<Grid
							item
							xs={12}
							className={classes.fillWidth}
						>
							<FormControlLabel
								control={
									<Checkbox
										checked={state.anon_checked}
										onChange={e => {
											const checked = e.target.checked;
											setState(draft => {
												draft.anon_checked = checked;
												draft.anon_days = checked ? 1 : 0;
												draft.converted_anon_days = checked ? 1 : 0;
												draft.anon_period = 'days';
											});
										}}
									/>
								}
								label={t`Anonymize customer data after period`}
							/>
						</Grid>
					</Grid>
					{state.anon_checked && (
						<Grid
							container
							spacing={3}
						>
							<Grid
								item
								xs={12}
							>
								<Alert
									severity="warning"
									title={t`settings-reports-dialog_addit_project-anonymize_warning-title`}
								>
									<Trans
										i18nKey="settings-reports-dialog-addit_project-anonymize_warning-content"
										components={{
											strong: <strong />,
										}}
									/>
								</Alert>
							</Grid>
							<Grid
								item
								xs={9}
								className={classes.fillWidth}
							>
								<TextField
									id="project-anondays"
									fullWidth
									type="number"
									label={t`Customer data will be anonymized after`}
									value={state.converted_anon_days}
									onChange={e => {
										calculateDate(e.target.value, state.anon_period);
									}}
								/>
							</Grid>
							<Grid
								item
								xs={3}
								className={`${classes.fillWidth} ${classes.withoutLabel}`}
							>
								<TextField
									id="project-anonperiod"
									select
									fullWidth
									value={state.anon_period}
									onChange={e => {
										calculateDate(state.anon_days, e.target.value);
									}}
								>
									<MenuItem
										key={0}
										value="days"
									>{t`days`}</MenuItem>
									<MenuItem
										key={1}
										value="months"
									>{t`months`}</MenuItem>
									<MenuItem
										key={2}
										value="years"
									>{t`years`}</MenuItem>
								</TextField>
							</Grid>
							<Grid
								item
								xs={12}
								className={classes.fillWidth}
							>
								{anonymise && (
									<AnonymizeList
										surveylist={surveylist}
										updateAnonymized={e => updateAnonParams(e)}
										isAnonymized={state.anon_datafields}
										urlParams={state.anon_params}
										updateUrlParams={e => updateUrlParams(e)}
									/>
								)}
							</Grid>
						</Grid>
					)}
				</Grid>
			)}
			submit={!project ? t`Create report` : t`Edit report`}
		/>
	);
}

export default ProjectAdditDialog;
