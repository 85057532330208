import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ApiIcon from '@material-ui/icons/AccountTree';

import { useSnackbar } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';

import ApiCard from './ApiCard';
import { useAppContext } from '@/components/AppContext';
import { PageHeader } from '@/components/App';
import {
	SimpleDialog,
	UpgradeHelper,
	Grid,
	AddCard,
	EmptyState,
	ActionButton,
	Link,
} from '@/components/Layout';
import { ValidatedField } from '@/components/Validation';
import { useEFM, useAjaxForm } from '@/components/Ajax';
import { useHasPermission, NoPermissionTooltip } from '@/components/Permission';

const useStyles = makeStyles(theme => ({
	emptyState: {
		whiteSpace: 'pre-line',
	},
}));

export default function FeedbackAPIList({ ...props }) {
	const { app } = useAppContext();
	const hasEditPermission = useHasPermission();
	const classes = useStyles();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const [additOpen, setAdditOpen] = useState(false);
	const [data, EFMloading, error, loadData] = useEFM('/integrations/api/list');
	const [name, setName] = useState('');
	const { postForm, loading, clicked, response } = useAjaxForm({
		url: '/integrations/api/add',
		data: {
			name,

			role: 'admin',
		},
		valid: () => name,
		onSuccess: r => {
			if (r.code == 403) {
				enqueueSnackbar(t`You need to upgrade your package to add more integrations`);
			} else {
				setAdditOpen(false);
				loadData();
				enqueueSnackbar(`${t`Integration`} ${name} ${t`created`}`);
			}
		},
		onFail: r => {
			enqueueSnackbar(t`Something went wrong`);
		},
	});

	return (
		<>
			<PageHeader title={t`Feedback API`} />
			{data.applications && data.applications.length > 0 ? (
				<Grid
					container
					spacing={3}
				>
					<Grid
						item
						md={3}
						sm={4}
						xs={6}
					>
						<NoPermissionTooltip>
							<AddCard
								onClick={() => setAdditOpen(true)}
								label={t`New API integration`}
								maxReached={
									data.applications &&
									data.applications.length >= app.package.integrations
								}
								subject={t`integrations`}
								dataTestElement="apiNewApi"
								disabled={!hasEditPermission}
							/>
						</NoPermissionTooltip>
					</Grid>
					{data.applications &&
						data.applications.map(integration => (
							<Grid
								item
								md={3}
								sm={4}
								xs={6}
								key={integration.public_key}
							>
								<ApiCard
									integration={integration}
									loadData={() => loadData()}
								/>
							</Grid>
						))}
				</Grid>
			) : (
				<EmptyState
					className={classes.emptyState}
					image={<img src={`/assets/img/r/emptystate/feedback_api.gif`} />}
					primary={t`integrations-feedback_api-emptystate_default-title`}
					secondary={
						<>
							<Trans i18nKey="integrations-feedback_api-emptystate_default-text">
								<Link
									href="https://developer.mopinion.com/api/"
									target="_blank"
								>
									link
								</Link>
							</Trans>
						</>
					}
					action={
						app.api.packageIsAllowed('view-feedback-api') ? (
							<NoPermissionTooltip>
								<ActionButton
									variant="contained"
									color="primary"
									onClick={() => setAdditOpen(true)}
									disabled={!hasEditPermission}
								>
									{t`integrations-feedback_api-emptystate_default-button_create_api`}
								</ActionButton>
							</NoPermissionTooltip>
						) : (
							<UpgradeHelper
								text={t`integrations-feedback_api-upgrade_helper-text`}
								textVariant={'body1'}
								useButtons
								subject={t`integrations-feedback_api-upgrade_helper-subject`}
							/>
						)
					}
				/>
			)}

			<SimpleDialog
				open={additOpen}
				onClose={() => {
					setAdditOpen(false);
					setName('');
				}}
				title={t`New integration`}
				submit={t`Create integration`}
				onSubmit={postForm}
				loading={loading}
				dataTestElement="apiCreateIntegration"
				dataTrackEvent="integrations_api_created"
			>
				{additOpen !== 'delete' && (
					<ValidatedField
						label={t`Integration name`}
						placeholder={t`E.g. CRM integration`}
						value={name}
						onChange={e => setName(e.target.value)}
						fullWidth
						showErrors={clicked}
						rules={[['required', true]]}
						dataTestElement="apiAddName"
					/>
				)}
			</SimpleDialog>
		</>
	);
}
