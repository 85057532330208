export const WEBHOOK_TYPES = [
	{
		category: ['webhook', 'all_round'],
		label: 'JSON hook',
		value: 'general',
		img: '/assets/img/general-icon.png',
		icon: '/assets/img/r/webhook-icon.svg',
		subtitle: 'Send your feedback to any API endpoint you would like using webhooks',
	},
	{
		category: ['messaging', 'team'],
		label: 'Slack',
		value: 'slack',
		img: '/assets/img/slack-icon.png',
		icon: '/assets/img/r/slack-icon.svg',
		subtitle: 'Notify a Slack channel of incoming feedback',
	},
	{
		category: ['messaging', 'team'],
		label: 'Rocket.Chat',
		value: 'rocketchat',
		img: '/assets/img/rocketchat-icon.png',
		icon: '/assets/img/r/rocketchat-icon.svg',
		subtitle: 'Connect feedback to your Rocket.Chat channels',
	},
	{
		category: ['project'],
		label: 'Trello',
		value: 'trello',
		img: '/assets/img/trello-icon.png',
		icon: '/assets/img/r/trello-icon.svg',
		subtitle: 'Send your feedback straight into your Trello boards',
	},
	{
		category: ['project'],
		label: 'Asana',
		value: 'asana',
		img: '/assets/img/asana-icon.png',
		icon: '/assets/img/r/asana-icon.svg',
		subtitle: 'Turn feedback into Asana tasks in the right projects',
	},
	{
		category: ['messaging', 'team'],
		label: 'Google Chat',
		value: 'googlechat',
		img: '/assets/img/googlechat-icon.png',
		icon: '/assets/img/r/google-chat-icon.svg',
		subtitle: 'Send feedback into specific Google Chat channels',
	},
	{
		category: ['project'],
		label: 'Jira',
		value: 'jira',
		img: '/assets/img/jira-icon.svg',
		icon: '/assets/img/r/jira-icon.svg',
		subtitle: 'Automatically turn feedback into Jira issues and optionally add labels',
	},
	{
		category: ['project'],
		label: 'Basecamp',
		value: 'basecamp',
		img: '/assets/img/basecamp-icon.png',
		icon: '/assets/img/r/basecamp-icon.svg',
		subtitle: 'Create tasks from feedback and optionally assign a user to the task',
	},
	{
		category: ['team'],
		label: 'MS Teams',
		value: 'ms_teams',
		icon: '/assets/img/r/ms-teams-icon.svg',
		img: '/assets/img/ms_teams-icon.png',
		subtitle: 'Send feedback into selected MS Teams channels',
	},
	{
		category: ['crm'],
		label: 'Salesforce',
		value: 'salesforce-form',
		icon: '/assets/img/r/salesforce-icon.svg',
		img: '/assets/img/salesforce-icon.png',
		subtitle: 'Send feedback to a Salesforce Web-to-Case or Web-to-Lead form',
		hideUserInit: true,
	},
	{
		category: ['crm'],
		label: 'HTML form',
		value: 'html-form',
		icon: '/assets/img/r/htmlform-icon.svg',
		img: '/assets/img/htmlform-icon.png',
		subtitle: 'Send feedback to a custom HTML form',
		hideUserInit: true,
	},
];

export const SLACK_ENDPOINT = 'https://hooks.slack.com/services/';

export const ASANA_ENDPOINT = 'https://app.asana.com/api/1.0/';
export const ASANA_API_TOKEN_URL =
	'https://app.asana.com/-/oauth_authorize?response_type=code&client_id=1110681104029566&redirect_uri=urn%3Aietf%3Awg%3Aoauth%3A2.0%3Aoob&state=<STATE_PARAM>';

export const TRELLO_ENDPOINT = 'https://api.trello.com/1/cards';
export const TRELLO_AUTH_URL = 'https://trello.com/1/authorize';
export const TRELLO_QUERY =
	'?expiration=never&name=MopinionWebhook&scope=read,write&response_type=token&key=';

export const JIRA_ENDPOINT = 'https://api.atlassian.com/ex/jira';

export const SALESFORCE_ENDPOINTS = [
	'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8',
	'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
];
