import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { SimpleDialog, ContactDialog, Typography } from '../Layout';

export default function ContactEnterpriseDialog({ open = false, onClose = () => {} }) {
	const [showContact, setShowContact] = useState(false);
	const { t } = useTranslation();

	function handleClose() {
		onClose();
		setTimeout(() => {
			setShowContact(false);
		});
	}

	return !showContact ? (
		<SimpleDialog
			open={open}
			onClose={handleClose}
			title={t`contact_enterprise_dialog-title`}
			submit={t('contact_enterprise_dialog-submit_button')}
			onSubmit={() => setShowContact(true)}
		>
			<Typography>{t('contact_enterprise_dialog-content')}</Typography>
		</SimpleDialog>
	) : (
		<ContactDialog
			open={open}
			onClose={handleClose}
			mailTo="sales@mopinion.com"
			subject={t`contact_enterprise_dialog-subject`}
		/>
	);
}
