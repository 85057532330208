import React, { useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';

import { useAppContext } from '@/components/AppContext';
import {
	permissionModules,
	permissionSubjects,
	permissionTypes,
	usePermissionContext,
	useHasPermission,
} from '@/components/Permission';

const landingsPages = {
	[permissionModules.reporting]: {
		[permissionSubjects.dashboard]: '/reporting/dashboard',
		[permissionSubjects.feedback_inbox]: '/reporting/inbox',
	},
	[permissionModules.actions]: {
		[permissionSubjects.action_management]: '/actions/inbox',
	},
	[permissionModules.data_collection]: {
		[permissionSubjects.feedback_forms_build]: '/data-collection/survey',
		[permissionSubjects.feedback_forms_design]: '/data-collection/survey',
	},
};
export default function useHandleRedirectOnce({ module, subject }) {
	const { permissions } = usePermissionContext();
	const didRedirect = useRef(false);
	const history = useHistory();

	const hasViewPermission = useHasPermission({
		module,
		subject,
		permission: permissionTypes.view,
	});

	useEffect(() => {
		if (!permissions || Object.keys(permissions).length === 0 || didRedirect.current) {
			return;
		}

		didRedirect.current = true;

		//do nothing if the user has view permission for the current module/subject
		if (hasViewPermission) {
			return;
		}

		const isAllowedToView = right =>
			[permissionTypes.view, permissionTypes.edit].includes(right);

		Object.keys(landingsPages).forEach(module => {
			Object.keys(landingsPages[module]).forEach(subject => {
				if (isAllowedToView(permissions?.[module]?.[subject])) {
					history.push(landingsPages[module][subject]);
				}
			});
		});
	}, [hasViewPermission, permissions, history]);
}
