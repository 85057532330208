import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Portal from '@material-ui/core/Portal';

import { Draggable } from 'react-beautiful-dnd';

const useStyles = makeStyles(theme => ({
	cancelTransform: {
		'&~ *': {
			transform: 'none!important',
		},
	},
	// isDragging: {
	// 	boxShadow:theme.shadows[5]
	// }
}));

//Adjust animation
const getStyle = (style, snapshot) => {
	//console.log(style, snapshot);
	if (!snapshot.isDropAnimating) {
		return style;
	}
	const { moveTo, curve, duration } = snapshot.dropAnimation;
	// move to the right spot
	const translate = `translate(${moveTo.x}px, ${moveTo.y + 150}px)`;
	// add a bit of turn for fun
	const scale = 'scale(0)';
	// patching the existing style
	return {
		...style,
		transform: `${translate} ${scale}`,
		// slowing down the drop because we can
		transition: `all ${curve} ${duration + 1}s`,
	};
};

export default function OptionalDraggable({
	draggable,
	disablePortal,
	children,
	index,
	draggableId,
	cancelTransform,
	draggableDivProps = {},
}) {
	const classes = useStyles();
	return !draggable ? (
		typeof children === 'function' ? (
			children({ snapshot: {}, provided: {} })
		) : (
			children
		)
	) : (
		<Draggable
			draggableId={draggableId}
			index={index}
		>
			{(provided, snapshot) => {
				// const innerDivProps = {
				// 	...(scaleInAnimation && {
				// 		style:getStyle(provided.draggableProps.style, snapshot)
				// 	})
				// }
				return (
					<Fragment>
						<Portal disablePortal={disablePortal || !snapshot.isDragging}>
							<div
								ref={provided.innerRef}
								{...provided.draggableProps}
								{...provided.dragHandleProps}
								{...draggableDivProps}
								className={snapshot.isDragging ? classes.isDragging : ''}
							>
								{typeof children === 'function'
									? children({ provided, snapshot })
									: children}
							</div>
						</Portal>
						{cancelTransform && snapshot.isDragging && (
							<div className={classes.cancelTransform}>
								{typeof children === 'function'
									? children({ provided, snapshot })
									: children}
							</div>
						)}
					</Fragment>
				);
			}}
		</Draggable>
	);
}
