import React from 'react';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { makeStyles } from '@/styles';
import { useLocalStorage } from '@/hooks';
import { SmartRecapsExplainer } from '@/components/Explainer';

import { Box, Grid, Typography, Paper } from '@/components/Layout';
import { useDataSourceContext } from '@/components/DataSources';
import { useAppContext } from '@/components/AppContext';
import { DataSourceSelect } from '@/components/Select';

import useGetActiveSmartRecapsProjects from './useGetActiveSmartRecapsProjects';
import useGetPrimarySurvey from './useGetPrimarySurvey';
import useGetOptInOut from './useGetOptInOut';
import useGetSummary from './useGetSummary';
import RecapSummary from './RecapSummary';
import RecapTopics from './RecapTopics';
import SmartRecapsIntroductionDialog from './SmartRecapsIntroductionDialog';
import {
	TextAnalyticsLanguageSelect,
	useGetTextAnalyticsLanguages,
	useGetTextAnalyticsProjectLanguages,
} from '@/components/TextAnalytics';

const useStyles = makeStyles(theme => ({
	recapHeader: {
		background: theme.palette.primary.dark,
		color: theme.palette.getContrastText(theme.palette.primary.dark),
		margin: theme.spacing(-3, -3, -5, -3),
		padding: theme.spacing(6, 8, 10, 8),
	},
	recapBody: {
		background: theme.palette.background.light,
		minHeight: '60vh',
		margin: theme.spacing(0, 4),
		padding: theme.spacing(2),
		display: 'flex',
	},
	formSelection: {
		color: theme.palette.getContrastText(theme.palette.primary.dark),
		'& div:first-of-type': {
			paddingTop: 0,
			paddingBottom: 2,
		},
		'& p': {
			display: 'none',
		},
		'&:before': {
			borderBottomColor: theme.palette.getContrastText(theme.palette.primary.dark),
		},
		'&:after': {
			borderBottomColor: theme.palette.getContrastText(theme.palette.primary.dark),
		},
		'&:hover:before': {
			borderBottomColor: `${theme.palette.getContrastText(
				theme.palette.primary.dark
			)}!important`,
		},
		'&:focus:before': {
			borderBottomColor: `${theme.palette.getContrastText(
				theme.palette.primary.dark
			)}!important`,
		},
		'& svg': {
			color: theme.palette.getContrastText(theme.palette.primary.dark),
		},
	},
	avatar: {
		minWidth: theme.spacing(4),
		'& div': {
			height: theme.spacing(3),
			width: theme.spacing(3),
		},
	},
	loader: {
		color: theme.palette.background.light,
	},
	alignRight: {
		textAlign: 'right',
	},
	languageSelect: {
		'& svg': {
			color: theme.palette.getContrastText(theme.palette.primary.dark),
		},
		'& :before': {
			borderBottom: `1px solid ${theme.palette.getContrastText(
				theme.palette.primary.dark
			)}!important`,
		},
		'& :after': {
			borderBottom: `1px solid ${theme.palette.getContrastText(
				theme.palette.primary.dark
			)}!important`,
		},
		'& :hover :after': {
			borderBottom: `2px solid ${theme.palette.getContrastText(
				theme.palette.primary.dark
			)}!important`,
		},
	},
}));

export default function SmartRecaps() {
	const classes = useStyles();
	const { app } = useAppContext();
	const { datasource } = useDataSourceContext();
	const location = useLocation();
	const history = useHistory();
	const { surveyUuidParam } = useParams();

	const [selectedSurveyUuid, setSelectedSurveyUuid] = useLocalStorage(
		app.projects.current.id + 'smart-recap-uuid',
		null
	);

	//handle route syncing
	if (
		!surveyUuidParam &&
		location.pathname.includes('smart-recaps') &&
		selectedSurveyUuid
	) {
		history.push(`/reporting/smart-recaps/${selectedSurveyUuid}`);
	}

	if (
		surveyUuidParam &&
		selectedSurveyUuid !== surveyUuidParam &&
		location.pathname.includes('smart-recaps')
	) {
		setSelectedSurveyUuid(surveyUuidParam);
	}

	const selectedSurvey =
		datasource.forms.asArray.find(survey => survey.uuid === surveyUuidParam) ??
		datasource.forms.asArray[0];

	const [optedInData, optedInLoading, optedInError, optedInLoadData] = useGetOptInOut({
		wait: false,
	});

	const smartRecapsProjectActive =
		optedInData?.response?.code === 201 ? !optedInData.response.opt_out : false;

	const [primarySurvey, primarySurveyloading, primarySurveyError, loadPrimarySurvey] =
		useGetPrimarySurvey({
			wait:
				!smartRecapsProjectActive ||
				selectedSurveyUuid !== null ||
				Object.keys(datasource.forms.byKey).length === 0,
			onSuccess: response => {
				if (selectedSurveyUuid) {
					return;
				}
				const surveyUuidFromResponse = response.response.survey_id
					? datasource.forms.byKey?.[response.response.survey_id]?.uuid
					: datasource.forms.asArray?.[0]?.uuid;
				if (surveyUuidFromResponse) {
					history.push(`/reporting/smart-recaps/${surveyUuidFromResponse}`);
				}
			},
		});

	const [data, loading, error, loadData] = useGetSummary({
		wait:
			!selectedSurvey?.id || Number(selectedSurvey.id) === 0 || !smartRecapsProjectActive,
		surveyId: selectedSurvey?.id,
	});

	//dirty state sync to apply filter on feedback inbox
	if (selectedSurvey?.id) {
		try {
			const inboxSurveyIdFilterKey = `/reporting/inbox${app.projects.current.id}other`;
			sessionStorage.setItem(
				inboxSurveyIdFilterKey,
				JSON.stringify({
					survey_id: selectedSurvey.id,
				})
			);
		} catch (e) {}
	}
	//end dirty state sync

	return (
		<>
			<div className={classes.recapHeader}>
				{smartRecapsProjectActive && (
					<Grid
						container
						spacing={2}
					>
						{datasource.forms.asArray.length === 0 && (
							<Grid
								item
								xs={12}
								className={classes.alignRight}
							>
								<SmartRecapsExplainer
									smartRecapsProjectActive={smartRecapsProjectActive}
									loadOptInData={optedInLoadData}
								/>
							</Grid>
						)}
						{datasource.forms.asArray.length > 0 && (
							<>
								<Grid
									item
									xs
								>
									<FormSelection selectedSurvey={selectedSurvey} />
								</Grid>

								<Grid item>
									<SmartRecapsExplainer
										smartRecapsProjectActive={smartRecapsProjectActive}
										loadOptInData={optedInLoadData}
									/>
								</Grid>

								<Grid
									item
									xs={12}
								>
									<RecapSummary
										data={data}
										smartRecapsProjectActive={smartRecapsProjectActive}
										loading={optedInLoading || loading}
										surveyId={selectedSurvey.id}
										variant="body1"
									/>
								</Grid>
							</>
						)}
					</Grid>
				)}
			</div>
			<Paper
				square
				className={classes.recapBody}
			>
				<RecapTopics
					selectedSurvey={selectedSurvey}
					smartRecapsProjectActive={smartRecapsProjectActive}
					optedInData={optedInData}
					loadOptInData={optedInLoadData}
					optedInLoading={optedInLoading}
				/>
			</Paper>
			<SmartRecapsIntroductionDialog />
		</>
	);
}

export function FormSelection({ selectedSurvey }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const { datasource } = useDataSourceContext();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	const {
		app: {
			date,
			projects: {
				current: { id },
			},
		},
	} = useAppContext();

	const [languages, languagesLoading] = useGetTextAnalyticsLanguages();
	const [languagePerForm, languagePerFormLoading, languagePerFormError, loadLanguages] =
		useGetTextAnalyticsProjectLanguages(id);

	const [
		formsWithRecaps,
		formsWithRecapsLoading,
		formsWithRecapsError,
		loadFormsWithRecaps,
	] = useGetActiveSmartRecapsProjects({
		from: date.fromDate,
		to: date.toDate,
	});

	//survey_id 'undefined' is 'ALL DATA' TAproject
	const languagePerActiveForm = languagePerForm.results
		? languagePerForm.results.filter(project => {
				if (
					datasource.forms.asArray.find(
						form => Number(form.id) === Number(project.survey_id)
					)
				) {
					return project;
				}
		  })
		: [];

	const activeForms = datasource.forms.asArray
		.map(form => {
			const inactive =
				(formsWithRecaps?.response?.survey_ids ?? []).indexOf(form.id) === -1;
			return {
				...form,
				inactive: inactive,
				...(inactive && {
					description: t`reporting-feedback_inbox-smart_recaps-datasource_select-no_recaps`,
				}),
			};
		})
		.sort((a, b) => a.inactive - b.inactive);

	return (
		<Grid
			container
			alignItems="center"
			spacing={1}
		>
			{datasource.forms.asArray.length === 0 && (
				<Grid item>
					<Typography
						variant="subtitle1"
						fontWeight="medium"
						gutterBottom
					>
						{t`reporting-feedback_inbox-smart_recaps-summary-no_surveys`}
					</Typography>
				</Grid>
			)}
			{datasource.forms.asArray.length > 0 && Number(selectedSurvey.id) > 0 && (
				<>
					<Grid item>
						<Typography
							variant="subtitle"
							fontWeight="medium"
							gutterBottom
						>
							{t`reporting-feedback_inbox-smart_recaps-summary-title`}
						</Typography>
					</Grid>
					<Grid item>
						<DataSourceSelect
							className={classes.formSelection}
							dataSourceTypes={['forms']}
							customDataSourceItems={{ forms: activeForms }}
							value={selectedSurvey.id.toString()}
							ListItemAvatarProps={{
								className: classes.avatar,
							}}
							onChange={e => {
								const surveyUuid = datasource.forms.byKey[e.target.value].uuid;
								history.push(`/reporting/smart-recaps/${surveyUuid}`);
								enqueueSnackbar(
									t('reporting-insights-dashboard_quick_selection-snackbar_switched', {
										source: datasource.api.getSource(e.target.value)?.name ?? '',
									})
								);
							}}
						/>
					</Grid>
					<Grid item>
						<Typography
							variant="subtitle1"
							fontWeight="medium"
							gutterBottom
							ml={5}
						>
							{t`reporting-feedback_inbox-smart_recaps-summary-language_title`}
						</Typography>
					</Grid>
					<Grid item>
						<Box className={classes.languageSelect}>
							<TextAnalyticsLanguageSelect
								languages={languages}
								languagePerActiveForm={languagePerActiveForm}
								loadLanguages={loadLanguages}
								surveyId={selectedSurvey.id}
								successMessage={t(
									'reporting-feedback_inbox-smart_recaps-summary-language_switched-success',
									{
										form: selectedSurvey?.name ?? '',
									}
								)}
								dataTrackEvent={'smartrecaps_change_survey_language'}
							/>
						</Box>
					</Grid>
				</>
			)}
		</Grid>
	);
}
