import React from 'react';

import LoginIcon from '@material-ui/icons/ExitToApp';
import SurveyIcon from '@material-ui/icons/Assignment';
import ActionIcon from '@material-ui/icons/AssignmentTurnedIn';
import ChartIcon from '@material-ui/icons/ShowChart';
import NoteIcon from '@material-ui/icons/Note';
import UserIcon from '@material-ui/icons/Person';
import WhatsHotIcon from '@material-ui/icons/Whatshot';
import WebhookIcon from '@material-ui/icons/CallMerge';
import ApiIcon from '@material-ui/icons/AccountTree';
import FeedbackIcon from '@material-ui/icons/Chat';
import TagsIcon from '@material-ui/icons/Label';
import EmailIcon from '@material-ui/icons/Email';
import AlertIcon from '@material-ui/icons/NotificationImportant';
import DataSetIcon from '@material-ui/icons/ViewList';
import ExportIcon from '@material-ui/icons/AssignmentReturned';
import { TrialIcon } from '../Icons';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';

export default function ActivityIcon({ type, ...props }) {
	switch (type) {
		case 'login':
			return <LoginIcon {...props} />;
		case 'add_survey':
		case 'remove_survey':
		case 'save_survey':
			return <SurveyIcon {...props} />;
		case 'add_chart':
		case 'delete_chart':
			return <ChartIcon {...props} />;
		case 'add_note':
			return <NoteIcon {...props} />;
		case 'efm_action':
			return <ActionIcon {...props} />;
		case 'add_user':
			return <UserIcon {...props} />;
		case 'create_export':
		case 'export_create':
			return <ExportIcon {...props} />;

		case 'system_update':
			return <WhatsHotIcon {...props} />;

		case 'save_deployment':
		case 'deployment_deleted':
			return <TrialIcon {...props} />;

		case 'add_tag':
		case 'add_auto_tags':
		case 'edit_auto_tags':
		case 'delete_auto_tags':
			return <TagsIcon {...props} />;

		case 'add_webhook_integration':
		case 'edit_webhook_integration':
		case 'deleted_webhook_integration':
			return <WebhookIcon {...props} />;

		case 'add_api_integration':
		case 'edit_api_integration':
		case 'delete_api_integration':
			return <ApiIcon {...props} />;

		case 'alert_addit':
		case 'alert_deleted':
			return <AlertIcon {...props} />;

		case 'add_dataset':
		case 'update_dataset':
		case 'remove_dataset':
			return <DataSetIcon {...props} />;

		case 'efm_email':
			return <EmailIcon {...props} />;

		case 'deleted_feedback':
			return <FeedbackIcon {...props} />;

		case 'access_request.added':
		case 'access_request.denied':
		case 'project_access_request.added':
		case 'project_access_request.denied':
			return <LockIcon {...props} />;

		case 'access_request.granted':
		case 'project_access_request.granted':
			return <LockOpenIcon {...props} />;

		default:
			return null;
	}
}
