import React, { useEffect } from 'react';
import Portal from '@material-ui/core/Portal';
import { useAppContext } from '../AppContext';

export default function AppBarLeftContent({ children, tabs }) {
	const { app } = useAppContext();

	useEffect(() => {
		app.dispatch({ type: 'app_bar_left_content_tabs', payload: Boolean(tabs) });

		return () => app.dispatch({ type: 'app_bar_left_content_tabs', payload: false });
	}, [tabs]);

	return <Portal container={app.layout.appBarLeftContentRef.current}>{children}</Portal>;
}
