import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';

import { makeStyles } from '@/styles';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
} from '@material-ui/core';

import PhonelinkLockIcon from '@material-ui/icons/PhonelinkLock';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';

import { useAppContext } from '@/components/AppContext';
import {
	ActionButton,
	Alert,
	Card,
	CardActions,
	CardContent,
	ContactDialog,
	Link,
	Typography,
} from '@/components/Layout';
import { useAjaxForm } from '@/components/Ajax';
import { rnd } from '../../utils';
import ReauthDialog from './ReauthDialog';
import RegisterMfaDialog from './RegisterMfaDialog';
import NewPassword from './NewPassword';

const useStyles = makeStyles(theme => ({
	input: {
		height: theme.spacing(8.5),
	},
}));

function SecurityCard({ user = {}, allow_mfa, enforceMfa }) {
	const { t } = useTranslation();
	const classes = useStyles();

	const { app } = useAppContext();
	const { mfa_enabled, mfa_phonenumber = null, mfa_id } = user;

	const { enqueueSnackbar } = useSnackbar();
	const passwordKey = useRef(rnd());
	const [saveButton, setSaveButton] = useState(false);
	const [requestAuthCode, setRequestAuthCode] = useState(false);
	const [registerMfa, setRegisterMfa] = useState(false);
	const [contactDialogOpen, setContactDialogOpen] = useState(false);
	const [updateMfaStatus, setUpdateMfaStatus] = useState(false);

	const [state, setState] = useImmer({
		mfa_enabled: mfa_enabled === 'yes' || enforceMfa,
		password: '',
		reauth_security: '',
	});

	const { postForm: savePassword, loading: passwordLoading } = useAjaxForm({
		url: '/account/ajax/update-password',
		data: {
			...(state.password &&
				state.reauth_security && {
					password: state.password,
					reauth_security: state.reauth_security,
				}),
		},
		onSuccess: response => {
			setSaveButton(false);
			passwordKey.current = rnd();
			setState(draft => {
				draft.password = '';
				draft.reauth_security = '';
			});
			if (response.code == 200) {
				enqueueSnackbar(t('my_account-security_settings-password_updated'));
			}
		},
	});

	const { postForm: updateMfa, loading: MfaLoading } = useAjaxForm({
		url: '/account/ajax/update-mfa',
		data: {
			mfa_enabled: !state.mfa_enabled || enforceMfa ? 'yes' : 'no',
		},
		onSuccess: response => {
			setSaveButton(false);
			updateImmer(response.mfa_enabled, 'mfa_enabled');
			app.api.updateSessionData();
			if (response.mfa_updated) {
				enqueueSnackbar(t`Multifactor authentication settings updated successfully`);
			}

			if (!response.mfa_enabled) {
				return;
			}

			if (!mfa_id) {
				setRegisterMfa(true);
			}
		},
	});

	if (enforceMfa && mfa_enabled !== 'yes' && !registerMfa) {
		setRegisterMfa(true);
	}

	function validAuth() {
		return Math.floor(Date.now() / 1000) <= app.session.authenticated_until;
	}

	function updateImmer(value, target) {
		setState(draft => {
			draft[target] = value;
		});
	}

	function reauthCallback() {
		if (!updateMfaStatus) {
			updateMfa();
		} else {
			setRegisterMfa(true);
			app.api.updateSessionData();
		}
	}

	const handleMfa = () => {
		if (!allow_mfa) {
			return enqueueSnackbar(t('my_account-security_settings-upgrade_to_enable_mfa'));
		}
		if (enforceMfa) {
			if (state.mfa_enabled) {
				return enqueueSnackbar(
					t('my_account-security_settings-mfa_required_by_organisation')
				);
			}
			return setRegisterMfa(true);
		}

		if (!validAuth()) {
			setUpdateMfaStatus(false);
			setRequestAuthCode(true);
		} else {
			updateImmer(!state.mfa_enabled, 'mfa_enabled');
			updateMfa();
		}
	};

	return (
		<Fragment>
			<Card>
				<CardContent>
					<Typography
						variant="subtitle1"
						component="h2"
						gutterBottom
					>
						{t('my_account-security_settings-title')}
					</Typography>
					<List>
						<NewPassword
							key={passwordKey.current}
							onChange={({ password, token }) => {
								setState(draft => {
									draft.password = password;
									draft.reauth_security = token;
								});

								setSaveButton(password && token);
							}}
						/>
						{saveButton && (
							<CardActions>
								<ActionButton
									onClick={savePassword}
									dataTrackEvent="account_settings_security_edited"
									action="save"
									loading={passwordLoading}
								>
									{t('my_account-security_settings-save-changes')}
								</ActionButton>
							</CardActions>
						)}
						<ListItem
							button
							onClick={handleMfa}
							className={classes.marginTop}
						>
							<ListItemIcon>
								<PhonelinkLockIcon />
							</ListItemIcon>
							<ListItemText
								primary={t('my_account-security_settings-use_mfa_label')}
								primaryTypographyProps={{ variant: 'caption' }}
								secondaryTypographyProps={{ variant: 'body1' }}
								secondary={state.mfa_enabled ? t('Yes') : t('No')}
							/>
							<ListItemSecondaryAction>
								<Switch
									edge="end"
									checked={allow_mfa ? state.mfa_enabled : enforceMfa}
									disabled={!allow_mfa}
									onChange={handleMfa}
								/>
							</ListItemSecondaryAction>
						</ListItem>
						{state.mfa_enabled && (
							<ListItem>
								<ListItemIcon>
									<PhoneAndroidIcon />
								</ListItemIcon>
								<ListItemText
									primary={t('my_account-security_settings-registered_number')}
									secondary={
										mfa_phonenumber
											? mfa_phonenumber
											: t('my_account-security_settings-no_number')
									}
									primaryTypographyProps={{ variant: 'caption' }}
									secondaryTypographyProps={{ variant: 'body1' }}
								/>
								<ListItemSecondaryAction>
									<Button
										color="primary"
										onClick={() => {
											if (!validAuth()) {
												setUpdateMfaStatus(true);
												setRequestAuthCode(true);
											} else {
												updateImmer(true, 'mfa_enabled');
												setRegisterMfa(true);
											}
										}}
									>
										{mfa_phonenumber
											? t('my_account-security_settings-change')
											: t('my_account-security_settings-register')}
									</Button>
								</ListItemSecondaryAction>
							</ListItem>
						)}
						{enforceMfa && mfa_enabled !== 'yes' && (
							<Alert severity="info">
								{t('my_account-security_settings-mfa_required_by_organisation_alert')}
							</Alert>
						)}
					</List>
					<ReauthDialog
						open={requestAuthCode}
						title={
							state.mfa_enabled && mfa_id
								? t('my_account-security_settings-reauth_dialog-to_phone_title')
								: t('my_account-security_settings-reauth_dialog-to_email_title')
						}
						onClose={() => setRequestAuthCode(false)}
						onSave={() => reauthCallback()}
					/>
					<RegisterMfaDialog
						open={registerMfa}
						mfa_id={mfa_id}
						force_registration={enforceMfa}
						onClose={() => setRegisterMfa(false)}
					/>
				</CardContent>
			</Card>
			{!enforceMfa && ['4', '7', '8'].indexOf(app.users.current.level) === -1 && (
				<Fragment>
					<Typography
						color="textSecondary"
						variant="caption"
					>
						{t('my_account-security_settings-want_to_require_mfa-question')}{' '}
						<Link onClick={() => setContactDialogOpen(true)}>
							{app.package.name !== 'Enterprise'
								? t('my_account-security_settings-want_to_require_mfa-talk_enterprise')
								: t('my_account-security_settings-want_to_require_mfa-contact_us')}
						</Link>
					</Typography>
					<ContactDialog
						open={contactDialogOpen}
						onClose={() => setContactDialogOpen(false)}
						mailTo="success@mopinion.com"
						title={t('my_account-security_settings-want_to_require_mfa-contact_us')}
						subject={
							app.package.name === 'Enterprise'
								? t(
										'my_account-security_settings-want_to_require_mfa-dialog_subject_force_mfa'
								  )
								: t(
										'my_account-security_settings-want_to_require_mfa-dialog_subject_enterprise_mfa'
								  )
						}
						dataTrackEvent="account_settings_upgrade_authentication"
					/>
				</Fragment>
			)}
		</Fragment>
	);
}

export default SecurityCard;
