import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ButtonBottomLeftIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<path
				id="XMLID_4_"
				d="M40,40h-4v-1h4V40z M39,36h1v-4h-1V36z M39,28h1v-4h-1V28z M32,1h4V0l-4,0V1z M32,39h-4v1h4V39z M39,12h1V8
				h-1V12z M39,4h1V0l-1,0V4z M39,20h1v-4h-1V20z M8,1h4V0H8V1z M1,28H0l0,4h1V28z M1,20H0l0,4h1V20z M1,4H0v4h1V4z M24,1h4V0l-4,0V1z
				 M0,0v1h4V0H0z M1,12H0v4h1V12z M24,39h-4v1h4V39z M16,1h4V0l-4,0V1z M1,36H0l0,4h1V36z M3,35v5h1h4h4h4h1v-5c0-0.5-0.5-1-1-1H4
				C3.5,34,3,34.5,3,35z"
			/>
		</SvgIcon>
	);
}
