import React, { forwardRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Done';
import SaveIcon from '@material-ui/icons/Save';
import CopyIcon from '@material-ui/icons/FileCopy';
import AddListIcon from '@material-ui/icons/AddCircleOutline';
import AddIcon from '@material-ui/icons/Add';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import EditIcon from '@material-ui/icons/Edit';
import GetIcon from '@material-ui/icons/GetApp';
import LinkIcon from '@material-ui/icons/Link';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CodeIcon from '@material-ui/icons/Code';
import CouponIcon from '@material-ui/icons/LocalOffer';
import UpgradeIcon from '@material-ui/icons/TrendingUp';
import PreviewIcon from '@material-ui/icons/Visibility';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import HelpIcon from '@material-ui/icons/HelpOutline';
import HideIcon from '@material-ui/icons/VisibilityOff';
import KnowledgeBaseIcon from '@material-ui/icons/ContactSupport';
import LockIcon from '@material-ui/icons/Lock';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import WhatsHotIcon from '@material-ui/icons/Whatshot';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import NewWindowIcon from '@material-ui/icons/OpenInNew';
import PublishIcon from '@material-ui/icons/Publish';

import { Link } from 'react-router-dom';

import ButtonWithLoader from './ButtonWithLoader';
import RenderConditional from './RenderConditional';
import Tooltip from './Tooltip';
import ButtonGroup from './ButtonGroup';
import { TrialIcon, DeploymentAssistantIcon } from '@/components/Icons';

const useStyles = makeStyles(theme => ({
	spaceIcon: {
		marginRight: theme.spacing(1),
	},
	disableTransform: {
		textTransform: 'none',
	},
	root: {
		marginTop: props => (props.mt ? theme.spacing(props.mt) : null),
		marginBottom: props => (props.mb ? theme.spacing(props.mb) : null),
		marginLeft: props => (props.ml ? theme.spacing(props.ml) : null),
		marginRight: props => (props.mr ? theme.spacing(props.mr) : null),
	},
	fontWeight: props => {
		switch (props.fontWeight) {
			case 'regular':
				return { fontWeight: theme.typography.fontWeightRegular };
			case 'bold':
				return { fontWeight: theme.typography.fontWeightBold };
			case 'light':
				return { fontWeight: theme.typography.fontWeightLight };
			default:
				return { fontWeight: theme.typography.fontWeightMedium };
		}
	},
}));

function getDefaultPropsFromAction(action) {
	switch (action) {
		case 'add_list':
			return {
				size: 'small',
				color: 'primary',
				startIcon: <AddListIcon />,
			};

		case 'add':
			return {
				variant: 'contained',
				color: 'primary',
				startIcon: <AddIcon />,
			};
		case 'copy':
			return {
				//size:'small',
				variant: 'contained',
				color: 'primary',
				startIcon: <CopyIcon />,
			};
		case 'nav_to':
			return {
				size: 'small',
				color: 'primary',
				endIcon: <ArrowIcon />,
			};
		case 'save':
			return {
				color: 'secondary',
				startIcon: <SaveIcon />,
			};

		case 'check':
			return {
				color: 'secondary',
				variant: 'contained',
				startIcon: <CheckIcon />,
			};
		case 'cancel':
			return {
				color: 'default',
				startIcon: <CloseIcon />,
			};
		case 'edit':
			return {
				variant: 'text',
				color: 'primary',
				startIcon: <EditIcon />,
			};
		case 'locked':
			return {
				variant: 'text',
				color: 'primary',
				startIcon: <LockIcon />,
			};
		case 'get':
		case 'download':
			return {
				color: 'primary',
				startIcon: <GetIcon />,
			};

		case 'connect':
		case 'link':
			return {
				color: 'primary',
				variant: 'contained',
				startIcon: <LinkIcon />,
			};

		case 'export':
			return {
				color: 'default',
				variant: 'outlined',
				size: 'small',
			};

		case 'renew':
		case 'billing':
		case 'payment':
			return {
				color: 'primary',
				variant: 'contained',
				startIcon: <CreditCardIcon />,
			};

		case 'code':
			return {
				//color:'primary',
				variant: 'contained',
				startIcon: <CodeIcon />,
			};

		case 'dialog_next':
		case 'dialog_submit':
			return {
				color: 'primary',
				variant: 'contained',
			};

		case 'coupon':
			return {
				startIcon: <CouponIcon />,
			};

		case 'upgrade':
			return {
				startIcon: <UpgradeIcon />,
				variant: 'contained',
				color: 'secondary',
			};

		case 'preview':
			return {
				startIcon: <PreviewIcon />,
				variant: 'contained',
				color: 'primary',
			};

		case 'view':
			return {
				startIcon: <PreviewIcon />,
				color: 'primary',
			};

		case 'importVar':
			return {
				startIcon: <DeviceHubIcon />,
				size: 'small',
				color: 'primary',
			};

		case 'trial':
			return {
				startIcon: <TrialIcon />,
			};

		case 'help':
			return {
				startIcon: <HelpIcon />,
				variant: 'text',
			};

		case 'knowledge_base':
			return {
				startIcon: <KnowledgeBaseIcon />,
				variant: 'text',
			};

		case 'hide':
			return {
				startIcon: <HideIcon />,
				variant: 'text',
				color: 'default',
			};
		case 'mobile':
			return {
				startIcon: <PhoneAndroidIcon />,
				variant: 'outlined',
				color: 'default',
			};

		case 'desktop':
			return {
				startIcon: <DesktopMacIcon />,
				variant: 'outlined',
				color: 'default',
			};

		case 'expand_more':
			return {
				endIcon: <ExpandMoreIcon />,
				variant: 'text',
				color: 'default',
			};
		case 'expand_less':
			return {
				endIcon: <ExpandLessIcon />,
				variant: 'text',
				color: 'default',
			};

		case 'fire':
			return {
				startIcon: <WhatsHotIcon />,
				variant: 'text',
			};

		case 'play':
			return {
				startIcon: <PlayCircleFilledIcon />,
				variant: 'text',
			};

		case 'dropdown':
			return {
				endIcon: <ArrowDropDownIcon />,
				variant: 'text',
			};
		case 'delete':
			return {
				color: 'primary',
				startIcon: <DeleteIcon />,
				variant: 'text',
			};
		case 'close':
			return {
				size: 'small',
				color: 'primary',
				endIcon: <CloseIcon />,
			};
		case 'refresh':
			return {
				color: 'primary',
				endIcon: <RefreshIcon />,
			};
		case 'publish':
			return {
				color: 'primary',
				variant: 'contained',
				startIcon: <PublishIcon />,
			};
		case 'assistant':
			return {
				color: 'primary',
				variant: 'outlined',
				startIcon: <DeploymentAssistantIcon />,
			};
		case 'new_window':
		case 'ext_link':
			return {
				color: 'primary',
				variant: 'contained',
				startIcon: <NewWindowIcon />,
			};

		default:
			return {};
	}
}

export default forwardRef(function ActionButton(
	{
		onClick = () => {},
		onClickMore,
		label,
		action,
		children,
		link,
		to,
		tooltip,
		disableTextTransform,
		dataTestElement = null,
		dataTrackEvent,
		className = '',
		fontWeight,
		TooltipProps = {},
		...props
	},
	ref
) {
	const classes = useStyles({ fontWeight, ...props });
	const defaultPropsFromAction = getDefaultPropsFromAction(action);
	const isGroup = typeof onClickMore === 'function';

	//pattern not preferred but placed in render so we don't have to duplicate content and props because of the required ternary below
	const buttonContent = (
		<ButtonWithLoader
			component={Button}
			{...defaultPropsFromAction}
			ref={ref}
			onClick={onClick}
			data-test-element={dataTestElement}
			dataTrackEvent={dataTrackEvent}
			{...(link &&
				to && {
					ButtonProps: {
						component: Link,
						to,
					},
				})}
			className={`${classes.root} ${className} ${
				disableTextTransform ? classes.disableTransform : ''
			} ${classes.fontWeight}`}
			{...props}
		>
			{props.fab && defaultPropsFromAction.startIcon && (
				<>
					{props.variant !== 'round'
						? React.cloneElement(defaultPropsFromAction.startIcon, {
								className: classes.spaceIcon,
						  })
						: defaultPropsFromAction.startIcon}
				</>
			)}
			{label}
			{children}
		</ButtonWithLoader>
	);

	return (
		<RenderConditional
			component={ButtonGroup}
			condition={isGroup}
			variant={defaultPropsFromAction.variant}
			color={defaultPropsFromAction.color}
		>
			{isGroup ? (
				buttonContent
			) : (
				<RenderConditional
					component={Tooltip}
					condition={tooltip}
					title={tooltip}
					wrapDisabled={props.disabled}
					{...TooltipProps}
				>
					{buttonContent}
				</RenderConditional>
			)}

			{isGroup && (
				<Button
					onClick={onClickMore}
					variant={defaultPropsFromAction.variant}
					color={defaultPropsFromAction.color}
					size="small"
				>
					<ArrowDropDownIcon />
				</Button>
			)}
		</RenderConditional>
	);
});
