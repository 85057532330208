import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useAppContext } from '@/components/AppContext';
import { useDataSourceContext } from '@/components/DataSources';
import { SimpleDialog, Box, FormControlLabel, Checkbox } from '@/components/Layout';
import { ProjectsAutocomplete } from '@/components/Projects';
import { ValidatedField } from '@/components/Validation';

import useCopySurvey from './useCopySurvey';

export default function SurveyCopyDialog({
	open,
	surveyId,
	surveyName,
	onClose = () => {},
}) {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const history = useHistory();
	const [newName, setNewName] = useState(
		t(`data_collection-survey_copy_dialog-name_prefix`, {
			name: surveyName,
		})
	);
	const [project, setProject] = useState(app.projects.current.uuid);
	const [openEditor, setOpenEditor] = useState(false);

	const { datasource } = useDataSourceContext();
	const copyForm = useCopySurvey({
		data: {
			survey: surveyId,
			name: newName,
			project: project ?? app.projects.current.uuid,
		},
		onSuccess: response => {
			onClose();
			clearForm();
			if (openEditor) {
				return editSurvey(response.survey, response.project);
			}

			datasource.api.loadSurveys(0);
		},
	});

	function clearForm() {
		setProject(app.projects.current.uuid);
		setOpenEditor(false);
		setNewName(
			t(`data_collection-survey_copy_dialog-name_prefix`, {
				name: surveyName,
			})
		);
	}

	function editSurvey(survey, project) {
		const url = `/data-collection/survey/edit/${survey.id}/${survey.campaign}/detail`;
		if (project.uuid !== app.projects.current.uuid) {
			window.location.href = `/r${url}`;
			return;
		}
		history.push(url);
	}

	return (
		<SimpleDialog
			open={Boolean(open)}
			title={t`data_collection-survey_copy_dialog-title`}
			cancel={t`data_collection-survey_copy_dialog-cancel`}
			submit={t`data_collection-survey_copy_dialog-submit`}
			onClose={onClose}
			onSubmit={copyForm.postForm}
			loading={copyForm.loading}
			dataTrackEvent="copy_form_submit"
		>
			<Box mb={4}>
				<ValidatedField
					label={t`data_collection-survey_copy_dialog-name_label`}
					value={newName}
					onChange={(e, value) => setNewName(e.target.value)}
					errorMessage={t`data_collection-survey_copy_dialog-name_error`}
					fullWidth
					rules={[['required', true]]}
					showErrorsAfterBlur
				/>
			</Box>
			<Box mb={3}>
				<ProjectsAutocomplete
					label={t`data_collection-survey_copy_dialog-change_report`}
					value={project}
					onChange={(e, value) => setProject(value.uuid)}
				/>
			</Box>
			<FormControlLabel
				control={
					<Checkbox
						checked={openEditor}
						onChange={e => {
							setOpenEditor(e.target.checked);
						}}
					/>
				}
				label={t`data_collection-survey_copy_dialog-open_editor`}
			/>
		</SimpleDialog>
	);
}
