import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Avatar from '@material-ui/core/Avatar';
import { getInitials } from '../../helpers';
import { DataSourceAvatar, DataFieldIcon } from '../DataSources';

import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
	inactive: {
		opacity: 0.5,
	},
}));

function Suggestion({
	suggestion,
	index,
	itemProps,
	highlightedIndex,
	selectedItem,
	isUserSearch,
	isDataFieldPicker,
	isGoogleFonts,
	popperNode,
	dataTestElement,
}) {
	const classes = useStyles();
	const isHighlighted = highlightedIndex === index;
	const isSelected = (selectedItem.label || '').indexOf(suggestion.label) > -1;

	if (suggestion.isCategory) {
		return <ListSubheader disableSticky>{suggestion.label}</ListSubheader>;
	}

	if (suggestion.isSurvey) {
		return (
			<ListItem
				selected={isHighlighted}
				data-test-element={dataTestElement ? dataTestElement + 'Option' : null}
			>
				<ListItemAvatar>
					<DataSourceAvatar {...suggestion} />
				</ListItemAvatar>
				<ListItemText
					primary={suggestion.label}
					secondary={suggestion.description}
				/>
			</ListItem>
		);
	}

	return (
		<ListItem
			{...itemProps}
			key={suggestion.key || suggestion.label}
			selected={isHighlighted}
			component="div"
			style={{
				fontWeight: isSelected ? 600 : 400,
			}}
			button
			disabled={suggestion.disabled}
			data-test-element={dataTestElement ? dataTestElement + 'Option' : null}
			className={isDataFieldPicker && !suggestion.active ? classes.inactive : ''}
		>
			{suggestion.avatar && <ListItemAvatar>{suggestion.avatar}</ListItemAvatar>}
			{(() => {
				if (isUserSearch && suggestion.key !== 'typing') {
					return (
						<Fragment>
							<ListItemAvatar>
								<Avatar style={{ backgroundColor: suggestion.color || '#aaa' }}>
									{getInitials(suggestion.firstname, suggestion.lastname)}
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary={suggestion.firstname + ' ' + suggestion.lastname}
								secondary={suggestion.email}
								primaryTypographyProps={{
									style: { fontWeight: isSelected ? 600 : 400 },
								}}
								secondaryTypographyProps={{
									style: { fontWeight: isSelected ? 600 : 400 },
								}}
							/>
						</Fragment>
					);
				} else if (isDataFieldPicker) {
					return (
						<Fragment>
							<ListItemIcon>
								<DataFieldIcon {...suggestion} />
							</ListItemIcon>
							<ListItemText
								primary={suggestion.label}
								secondary={suggestion.type}
							/>
						</Fragment>
					);
				} else {
					return <ListItemText primary={suggestion.label} />;
				}
			})()}
		</ListItem>
	);
}

export default Suggestion;
