import React from 'react';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useAjaxForm } from '../Ajax';

export default function useDeleteDashboard({ id, onSuccess = () => {} }) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const action = useAjaxForm({
		url: '/dashboard/ajax/delete-dashboard',
		data: {
			id: id,
		},
		onSuccess: r => {
			enqueueSnackbar(
				t`reporting-dashboard_overview-dialog_delete_dashboard-snackbar_success`
			);
			onSuccess(r);
		},
	});

	return action;
}
