import React from 'react';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import Popover from '@material-ui/core/Popover';

import { Conversation } from '../Conversations';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

function MoBotPopover({
	anchorEl = null,
	open,
	conversation,
	approve,
	decline,
	actions,
	onClose = () => {},
	...props
}) {
	return (
		<Popover
			open={open}
			anchorEl={anchorEl.current}
			onClose={onClose}
		>
			<Box p={2}>
				<Conversation conversation={conversation} />
			</Box>
		</Popover>
	);
}

export default MoBotPopover;
