export function strComp(a, b) {
	return String(a) === String(b);
}

export function debounce(fn, time) {
	let timeout;

	return function () {
		const functionCall = () => fn.apply(this, arguments);

		clearTimeout(timeout);
		timeout = setTimeout(functionCall, time);
	};
}

export function ucFirst(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function rnd(len = 32, bits = 36) {
	let outStr = '',
		newStr;
	while (outStr.length < len) {
		newStr = Math.random().toString(bits).slice(2);
		outStr += newStr.slice(0, Math.min(newStr.length, len - outStr.length));
	}
	return outStr;
}
// export function rnd() {
//   return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
// }

export function getRandomIndexFromArray(arr) {
	return Math.floor(Math.random() * arr.length);
}

export function copyToClipboard(str) {
	return new Promise(function (resolve, reject) {
		if (!navigator.clipboard) {
			let success = false;
			function listener(e) {
				e.clipboardData.setData('text/plain', str);
				e.preventDefault();
				success = true;
			}
			document.addEventListener('copy', listener);
			document.execCommand('copy');
			document.removeEventListener('copy', listener);
			return success ? resolve() : reject();
		}
		navigator.clipboard.writeText(str).then(
			function () {
				resolve();
			},
			function () {
				reject();
			}
		);
	});
}

export function isWholeNumber(n) {
	return n - Math.floor(n) !== 0;
}

export const triggerEvent = (element = document, event = 'click') => {
	element.dispatchEvent(new Event(event));
};

export function sortByAlphabet(array, key, desc) {
	const asc = [...array].sort((a, b) => {
		return String(a[key])
			.toLocaleLowerCase()
			.localeCompare(String(b[key]).toLocaleLowerCase());
	});

	return desc ? asc.reverse() : asc;
}

export function sortByNum(array, key, desc) {
	const asc = [...array].sort((a, b) => {
		return Number(a[key]) - Number(b[key]);
	});

	return desc ? asc.reverse() : asc;
}

export function rangeArray(start, end, reverse) {
	const arr = new Array(end - start + 1).fill(undefined).map((_, i) => i + start);

	return reverse ? arr.reverse() : arr;
}

export function isStringWithMinLength(input, minLength = 1) {
	return typeof input === 'string' && input.length >= minLength;
}

export function tryParse(v, fallbackValue = {}) {
	if (!v) return fallbackValue;

	try {
		const parsed = JSON.parse(v);
		return typeof parsed !== 'undefined' && parsed !== null ? parsed : fallbackValue;
	} catch (e) {
		return fallbackValue;
	}
}

export function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function matchesOneOf(str, arr = []) {
	return arr.some(arrValue => {
		if (typeof arrValue === 'object') {
			const { value, regex, exactMatch } = arrValue;
			return exactMatch
				? value === str
				: regex
				? new RegExp(value, 'gi').test(str)
				: str.indexOf(value) > -1;
		} else {
			return str.indexOf(arrValue) > -1;
		}
	});
}

export function removeNestedObjKeys(obj = {}, removeKey) {
	Object.keys(obj).forEach(key => {
		if (key === removeKey) {
			delete obj[key];
		} else if (
			obj[key] &&
			typeof obj[key] === 'object' &&
			Object.keys(obj[key]).length > 0
		) {
			removeNestedObjKeys(obj[key], removeKey);
		}
	});

	return obj;
}

export function nl2br(str = '') {
	return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

export function br2nl(str = '') {
	return str.replace(/<br\s*\/?>/gm, '\n');
}

export function removeBr(str = '') {
	return str.replace(/<br\s*\/?>/gm, ' ');
}

export function compare(a, b) {
	if (a > b) return 1;
	if (a < b) return -1;
	return 0;
}

export function removeArrayDuplicatesFromShallowClone(...args) {
	const merged = args.flatMap(arr => arr);
	return [...new Set(merged)];
}

export function isObjectWithKeys(obj) {
	return typeof obj === 'object' && obj && Object.keys(obj).length > 0;
}

export function triggerDownload(url = '') {
	const a = document.createElement('a');
	a.href = url;
	a.download = url.split('/').pop();
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
}

export function scrollToNode(node, yOffset = 0, scroller = window) {
	try {
		const y =
			scroller === window
				? node.getBoundingClientRect().top + window.scrollY + yOffset
				: node.getBoundingClientRect().top + scroller.scrollTop + yOffset;
		scroller.scrollTo({ top: y, behavior: 'smooth' });
	} catch (e) {}
}

export const getCookie = cookieKey => {
	for (let cookie of document.cookie.split('; ')) {
		let [key, val] = cookie.split('=');
		if (cookieKey === key) {
			return decodeURIComponent(val);
		}
	}
};

export function setGlobalSessionData(obj = {}, pushToDataLayer) {
	if (!window.__sessionData) {
		window.__sessionData = {};
	}

	Object.assign(window.__sessionData, obj);
	window.temporaryDataLayer = window.__sessionData;
	if (pushToDataLayer && Array.isArray(window.dataLayer)) {
		window.dataLayer.push({
			...window.__sessionData,
			event: 'user_info',
		});
	}
}

export function randomUuid() {
	try {
		const randomUuid = window.crypto.randomUUID();
		return randomUuid;
	} catch (e) {
		// Public Domain/MIT
		const d = new Date().getTime(); //Timestamp
		const d2 =
			(typeof performance !== 'undefined' &&
				performance.now &&
				performance.now() * 1000) ||
			0;
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * 16;
			if (d > 0) {
				r = (d + r) % 16 | 0;
				d = Math.floor(d / 16);
			} else {
				r = (d2 + r) % 16 | 0;
				d2 = Math.floor(d2 / 16);
			}
			return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
		});
	}
}
