import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Select from '@material-ui/core/Select';
import Avatar from '@material-ui/core/Avatar';

import { Trans, useTranslation } from 'react-i18next';

import {
	CodeTextArea,
	ActionButton,
	SimpleDialog,
	Alert,
	Loader,
	Grid,
	Typography,
	Link,
	Card,
	CardContent,
	Drawer,
	MenuItem,
	FormHelperText,
	FormControl,
	Tab,
	Tabs,
} from '@/components/Layout';

import { useEFM } from '@/components/Ajax';
import { useAppContext } from '@/components/AppContext';

import { SdkList, EmailListSnippet, EmailList, WebList } from './InfoList';
import useEmailSnippetWithoutContext from '@/components/Survey/useEmailSnippet';

import { DisplayTypeWeb } from './DisplayTypeWeb';
import { DisplayTypeSdk } from './DisplayTypeSdk';
import { ButtonTypeWeb } from './ButtonTypeWeb';
import { ButtonTypeSdk } from './ButtonTypeSdk';

import { previewDrawerWidth, previewDrawerWidthSm } from './constants';

import { tryParse } from '@/utils';

const useStyles = makeStyles(theme => ({
	drawerPaper: {
		width: props => props.drawerSize,
		zIndex: theme.zIndex.appBar - 1,
		transition: theme.transitions.create('top'),
	},
	persistentDrawerPaper: {
		...theme.mixins.toolbarNormal('top'),
		position: 'relative',
		height: `calc(100% - 64px)`,
	},
	panePadding: {
		padding: theme.spacing(2),
	},
	titleSpace: {
		marginBottom: theme.spacing(1),
	},
	bottomMargin: {
		marginBottom: theme.spacing(2),
	},
	topMargin: {
		marginTop: theme.spacing(1),
	},
	avatar: {
		width: theme.spacing(3),
		height: theme.spacing(3),
		display: 'inline-block',
		marginRight: theme.spacing(1),
	},
	align: {
		verticalAlign: 'super',
	},
	italic: {
		fontStyle: 'italic',
	},
	spaceTop: {
		marginTop: theme.spacing(1),
	},
}));

export default function PreviewSidebar({
	surveyEditor,
	surveySettings,
	viewType,
	selectedLanguage,
	setSelectedLanguage = () => {},
	updateSurveySettings = () => {},
}) {
	const { t } = useTranslation();
	const { app } = useAppContext();

	const drawerSize = app?.layout?.persistentDrawer
		? previewDrawerWidth
		: previewDrawerWidthSm;
	const classes = useStyles({ drawerSize });

	const { dataLoaded, formType, surveyKey, campaign, languages, survey, blocks } =
		surveySettings;

	const [design] = useEFM(
		'/survey/ajax/get-design',
		{ designId: survey?.design ?? 0 },
		!dataLoaded
	);

	const [theme] = useEFM(
		'/survey/ajax/get-themes',
		{ themeId: design?.design?.theme },
		!design.design
	);
	const widgetDesign = tryParse(design?.design?.email_widgets, {});

	const [previewOpen, setPreviewOpen] = useState(false);

	const convertArrayToObject = (array, key) => {
		const initialValue = {};
		return array.reduce((obj, item) => {
			return {
				...obj,
				[item[key]]: item,
			};
		}, initialValue);
	};

	const editor = {
		blocks: blocks ? convertArrayToObject(blocks, 'id') : {},
		text: tryParse(survey?.text),
		map: tryParse(survey?.map)?.layout ?? [],
		properties: {
			survey_key: surveyKey,
		},
	};

	const reactDesign = tryParse(design?.design?.custom, {});
	const customStyles = reactDesign?.reactDesignState ?? {};
	const fullDesign = {
		...design,
		...customStyles,
		currentTheme: theme,
		widget: widgetDesign,
	};

	const [widgetTabActive, setWidgetTabActive] = useState(0);

	const { css, html, preview, full, useTabs } = useEmailSnippetWithoutContext(
		editor,
		fullDesign
	);

	const hasPreselectedBlock =
		Object.values(editor.blocks).find(block => block?.properties?.preselectScore) ??
		false;

	if (!dataLoaded) {
		return (
			<Drawer
				variant="permanent"
				anchor="left"
				classes={{
					paper: `${classes.drawerPaper} ${classes.persistentDrawerPaper}`,
				}}
				open
			>
				<Loader
					circular
					empty
				/>
			</Drawer>
		);
	}

	return (
		<Drawer
			variant="permanent"
			anchor="left"
			classes={{
				paper: `${classes.drawerPaper} ${classes.persistentDrawerPaper}`,
			}}
			open
		>
			<Grid
				container
				className={classes.bottomMargin}
			>
				<>
					{Object.keys(languages?.toggleOptions ?? {}).length > 0 && (
						<Grid
							item
							xs={12}
							className={`${classes.panePadding}`}
						>
							<Typography
								variant="subtitle2"
								className={classes.titleSpace}
							>
								{t`data_collection-preview-switch_form_language-title`}
							</Typography>
							<FormControl
								className={classes.formControl}
								fullWidth
							>
								<Select
									value={selectedLanguage}
									onChange={e => {
										setSelectedLanguage(e.target.value);
									}}
									fullWidth
								>
									<MenuItem
										value="default"
										className={classes.italic}
									>
										{t`data_collection-preview-switch_form_language-default`}
									</MenuItem>
									{Object.keys(languages.toggleOptions).map(toggleOptionKey => {
										const option = languages.toggleOptions[toggleOptionKey];
										return (
											<MenuItem
												value={option.value}
												key={toggleOptionKey}
											>
												<Avatar
													className={classes.avatar}
													src={`/assets/img/countryflags/${option.countrycode}.svg`}
												/>
												<span className={classes.align}>{option.label}</span>
											</MenuItem>
										);
									})}
								</Select>
								<FormHelperText>{t`data_collection-preview-switch_form_language-helper`}</FormHelperText>
							</FormControl>
						</Grid>
					)}

					{campaign === 'web' && (
						<>
							{surveyEditor && (
								<Grid
									item
									xs={12}
									className={classes.panePadding}
								>
									<DisplayTypeWeb
										surveySettings={surveySettings}
										updateSurveySettings={updateSurveySettings}
									/>
								</Grid>
							)}

							{surveyEditor && formType !== 'embed' && (
								<Grid
									item
									xs={12}
									className={classes.panePadding}
								>
									<ButtonTypeWeb
										viewType={viewType}
										surveySettings={surveySettings}
										updateSurveySettings={updateSurveySettings}
									/>
								</Grid>
							)}

							<Grid
								item
								xs={12}
							>
								<WebList />
							</Grid>
						</>
					)}

					{campaign === 'sdk' && (
						<>
							{surveyEditor && (
								<>
									<Grid
										item
										xs={12}
										className={classes.panePadding}
									>
										<DisplayTypeSdk
											surveySettings={surveySettings}
											updateSurveySettings={updateSurveySettings}
										/>
									</Grid>

									{surveySettings.formType === 'slide' && (
										<Grid
											item
											xs={12}
											className={classes.panePadding}
										>
											<ButtonTypeSdk
												surveySettings={surveySettings}
												updateSurveySettings={updateSurveySettings}
											/>
										</Grid>
									)}
								</>
							)}

							<Grid
								item
								xs={12}
							>
								<SdkList />
							</Grid>

							<Grid
								item
								xs={12}
								className={classes.panePadding}
							>
								<Alert severity="info">
									<Trans
										i18nKey={t`data_collection-preview-list_sdk-info_message`}
										components={{
											boldGroup: (
												<Typography
													display="inline"
													fontWeight="medium"
												/>
											),
											paragraph: <p />,
											linkAndroid: (
												<Link
													href="https://play.google.com/store/apps/details?id=com.mopinion.mopinion"
													target="_blank"
													rel="noopener"
												/>
											),
											linkIos: (
												<Link
													href="https://apps.apple.com/app/mopinion-forms/id1376756796"
													target="_blank"
													rel="noopener"
												/>
											),
										}}
									/>
								</Alert>
							</Grid>
						</>
					)}

					{campaign === 'email' && (
						<>
							<Grid
								item
								xs={12}
							>
								<EmailListSnippet />
							</Grid>

							<Grid
								item
								xs={12}
								className={classes.panePadding}
							>
								<Card>
									<CardContent>
										{useTabs && (
											<>
												<Tabs
													value={widgetTabActive}
													onChange={(e, val) => {
														setWidgetTabActive(val);
													}}
													centered
												>
													<Tab
														label={t`data_collection-preview-email_snippet-tab-html`}
													></Tab>
													<Tab
														label={t`data_collection-preview-email_snippet-tab-css`}
													></Tab>
													<Tab
														label={t`data_collection-preview-email_snippet-tab-both`}
													></Tab>
												</Tabs>
												<Grid
													container
													className={classes.spaceTop}
													spacing={1}
												>
													<Grid
														item
														xs={12}
														hidden={widgetTabActive !== 0}
													>
														<Alert severity="info">
															{t`data_collection-preview-email_snippet-info_placement-html`}
														</Alert>
													</Grid>
													<Grid
														item
														xs={12}
														hidden={widgetTabActive !== 0}
													>
														<CodeTextArea
															value={html}
															copyMessage={t`data_collection-preview-email_snippet-alert-html_copied`}
															rowsMax={10}
															dataTrackEvent="form_widget_html_snippet_copied"
															readonly
															extraButtons={
																<ActionButton
																	action="preview"
																	variant="text"
																	onClick={() => setPreviewOpen(true)}
																>
																	{t`data_collection-preview-email_snippet-button_preview-html`}
																</ActionButton>
															}
														/>
													</Grid>
													<Grid
														item
														xs={12}
														hidden={widgetTabActive !== 1}
													>
														<Alert severity="info">
															{t`data_collection-preview-email_snippet-info_placement-css`}
														</Alert>
													</Grid>
													<Grid
														item
														xs={12}
														hidden={widgetTabActive !== 1}
													>
														<CodeTextArea
															value={css}
															copyMessage={t`data_collection-preview-email_snippet-alert-css_copied`}
															rowsMax={10}
															readonly
															dataTrackEvent="form_widget_css_snippet_copied"
															extraButtons={
																<ActionButton
																	action="preview"
																	variant="text"
																	onClick={() => setPreviewOpen(true)}
																>
																	{t`data_collection-preview-email_snippet-button_preview-css`}
																</ActionButton>
															}
														/>
													</Grid>
													<Grid
														item
														xs={12}
														hidden={widgetTabActive !== 2}
													>
														<Alert severity="info">
															{t`data_collection-preview-email_snippet-info_placement-both`}
														</Alert>
													</Grid>
													<Grid
														item
														xs={12}
														hidden={widgetTabActive !== 2}
													>
														<CodeTextArea
															value={full}
															copyMessage={t`data_collection-preview-email_snippet-alert-both_copied`}
															rowsMax={10}
															readonly
															dataTrackEvent="form_widget_snippet_copied"
															extraButtons={
																<ActionButton
																	action="preview"
																	variant="text"
																	onClick={() => setPreviewOpen(true)}
																>
																	{t`Preview`}
																</ActionButton>
															}
														/>
													</Grid>
												</Grid>
											</>
										)}
										{!useTabs && (
											<CodeTextArea
												value={html}
												copyMessage={t`data_collection-preview-email_snippet-alert-simple_copied`}
												rowsMax={10}
												dataTrackEvent="form_widget_snippet_copied"
												readonly
												extraButtons={
													<ActionButton
														action="preview"
														variant="text"
														onClick={() => setPreviewOpen(true)}
													>
														{t`data_collection-preview-email_snippet-button_preview-simple`}
													</ActionButton>
												}
											/>
										)}
										{!hasPreselectedBlock && (
											<Alert
												severity="info"
												className={classes.topMargin}
											>
												{t`data_collection-preview-email_snippet-info-no_element_selected`}
											</Alert>
										)}
									</CardContent>
								</Card>
							</Grid>

							<Grid
								item
								xs={12}
							>
								<EmailList />
							</Grid>

							<SimpleDialog
								open={previewOpen}
								onClose={() => setPreviewOpen(false)}
								cancel={t`data_collection-preview-email_snippet-dialog-widget_preview-close`}
								title={t`data_collection-preview-email_snippet-dialog-widget_preview-title`}
							>
								<div dangerouslySetInnerHTML={{ __html: preview }} />
							</SimpleDialog>
						</>
					)}
				</>
			</Grid>
		</Drawer>
	);
}
