import React, { Fragment, useState, useRef, useEffect } from 'react';

import {
	Container,
	Card,
	Switch,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	makeStyles,
} from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { UserAutoComplete } from '../AutoComplete';
import { PageHeader } from '../App';
import { EditorOptionList, Typography, Grid } from '../Layout';
import { useAjaxForm, useEFM } from '../Ajax';
import { channelNames, eventNames, channelEvents } from './constants';

const useStyles = makeStyles(theme => ({
	details: {
		padding: theme.spacing(2, 0),
	},
	panels: {
		marginTop: theme.spacing(3),
	},
}));

export default function NotificationSettings() {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Fragment>
			<PageHeader
				title={t`Notifications`}
				showViewPermissionIndicator={false}
			/>

			<Container maxWidth="md">
				<div className={classes.panels}>
					<NotificationSettingsPanel />
				</div>
			</Container>
		</Fragment>
	);
}

function stringListToArray(str = '') {
	return (str ?? '')
		.split(',')
		.filter(Boolean)
		.map(name => String(name).trim());
}

function shouldUpdateSubscription(serverState = {}, state = {}) {
	if (!serverState.id) return false;

	let diff = false;
	for (let key in state) {
		const stateValue = Array.isArray(state[key]) ? state[key].join(',') : state[key];
		const serverValue = serverState[key];
		if (stateValue !== serverValue) {
			diff = true;
		}
	}

	return diff;
}

function NotificationSettingsPanel({ ...props }) {
	const classes = useStyles();
	const [open, setOpen] = useState(true);
	const { t } = useTranslation();

	const {
		subscription = {},
		loading,
		setActive,
		setEvents,
		setIgnoreUsers,
	} = useSubscriptions();

	const name = channelNames[subscription.event_channel] ?? {};
	const events = channelEvents[subscription.event_channel] ?? [];

	function toggleOpen(force) {
		setOpen(prev => force ?? !prev);
	}

	return (
		<ExpansionPanel expanded={true}>
			<ExpansionPanelSummary
				//expandIcon={<ExpandMoreIcon />}
				IconButtonProps={{
					onClick: e => {
						e.stopPropagation();
						toggleOpen();
					},
				}}
				disableRipple={false}
				onClick={setActive}
				data-track-event={
					Boolean(subscription.active)
						? 'notification_setting_off'
						: 'notification_setting_on'
				}
			>
				<Grid container>
					<Grid
						item
						xs
					>
						<Typography
							variant="subtitle1"
							fontWeight="medium"
						>
							{t(name.primary)}
						</Typography>
						<Typography
							variant="body1"
							color="textSecondary"
						>
							{t(name.secondary)}
						</Typography>
					</Grid>
					<Grid item>
						<Switch checked={Boolean(subscription.active)} />
					</Grid>
				</Grid>
			</ExpansionPanelSummary>

			<ExpansionPanelDetails className={classes.details}>
				<EditorOptionList
					startDivider
					options={[
						...events.map(ev => ({
							primary: t(eventNames[ev] ?? ev),
							onClick: () => setEvents(ev),
							action: (
								<Switch
									checked={Boolean(
										subscription.active &&
											!subscription.excluded_event_names?.includes(ev)
									)}
								/>
							),
							lessenTextOpacity: subscription.excluded_event_names?.includes(ev),
							divider: true,
							'data-track-event': `notification_setting_changed_${ev}`,
						})),
						{
							custom: (
								<UserAutoComplete
									label={t`Do not receive notifications from changes by`}
									multiple
									disableCustomEntry
									onlyVerifiedUsers
									fullWidth
									placeholder={t`Type to search user`}
									selectedItem={
										!subscription.active
											? []
											: subscription.excluded_user_ids?.map(id => ({ id }))
									}
									onChange={users => setIgnoreUsers(users.map(user => user.id))}
								/>
							),
						},
					]}
				/>
			</ExpansionPanelDetails>
		</ExpansionPanel>
	);
}

function useSubscriptions() {
	const [subscription, loadingFetch, error, loadData, setState] = useEFM(
		'/application/ajax/get-subscriptions',
		{},
		false,
		false,
		response => {
			//for now we're only working with the 'snitch' channel
			const sub =
				response?.subscriptions?.find(sub => sub.event_channel === 'snitch') ?? {};

			sub.excluded_event_names = stringListToArray(sub.excluded_event_names);
			sub.excluded_user_ids = stringListToArray(sub.excluded_user_ids);

			return sub;
		},
		{ disableCache: true }
	);

	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const clicked = useRef(false);

	const { postForm, loading } = useAjaxForm({
		url: '/application/ajax/update-subscription',
		data: {
			subscription_id: subscription.id,
			active: subscription.active ? 1 : 0,
			excluded_event_names: (subscription.excluded_event_names ?? []).join(','),
			excluded_user_ids: (subscription.excluded_user_ids ?? []).join(','),
		},
		onSuccess: () => {
			enqueueSnackbar(t`Notification settings updated`);
		},
	});

	useEffect(() => {
		if (clicked.current) {
			postForm();
		}
	}, [subscription, clicked.current]);

	const events = channelEvents[subscription.event_channel] ?? [];

	function setActive() {
		clicked.current = true;
		setState(prev => {
			return {
				...prev,
				excluded_event_names:
					!prev.active && prev.excluded_event_names?.length === events.length
						? []
						: prev?.excluded_event_names,
				active: prev.active ? 0 : 1,
			};
		});
	}

	function setEvents(event) {
		clicked.current = true;
		setState(prev => {
			//if not active: set only clicked event to active
			if (!prev.active) {
				return {
					...prev,
					active: 1,
					excluded_event_names: events.filter(ev => ev !== event),
				};
			}

			if (prev.active) {
				return {
					...prev,
					excluded_event_names: prev.excluded_event_names.includes(event)
						? prev.excluded_event_names.filter(ev => ev !== event)
						: [...prev.excluded_event_names, event],
				};
			}
		});
	}

	function setIgnoreUsers(ids) {
		clicked.current = true;
		setState(prev => {
			return {
				...prev,
				active: 1,
				excluded_user_ids: ids,
			};
		});
	}

	return {
		subscription,
		setActive,
		setEvents,
		setIgnoreUsers,
		postForm,
		loading: loadingFetch || loading,
	};
}
