import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Portal from '@material-ui/core/Portal';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import ChevronRight from '@material-ui/icons/ChevronRight';

function ConditionalPortal({ use, children, ...props }) {
	return use ? <Portal {...props}>{children}</Portal> : children;
}

function FilterDrawer({
	children,
	classes,
	title,
	closeIcon,
	PortalProps = {},
	TypographyProps = {},
	onClose = () => {},
	...props
}) {
	return (
		<ConditionalPortal {...PortalProps}>
			<Drawer
				anchor="bottom"
				variant="persistent"
				classes={{
					paper: classes.paper,
				}}
				PaperProps={{
					elevation: 4,
				}}
				onClose={onClose}
				SlideProps={{
					unmountOnExit: true,
				}}
				{...props}
			>
				<Typography
					variant="h6"
					{...TypographyProps}
				>
					<IconButton onClick={onClose}>
						{closeIcon ? (
							closeIcon
						) : (
							<ChevronRight style={{ transform: 'rotate(90deg)' }} />
						)}
					</IconButton>
					{title ? title : ''}
				</Typography>
				{children}
			</Drawer>
		</ConditionalPortal>
	);
}

const styles = theme => ({
	paper: {
		borderTopLeftRadius: theme.spacing(4),
		borderTopRightRadius: theme.spacing(4),
		height: '100%',
		width: '100%',
		position: 'absolute',
		padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
	},
});

export default withStyles(styles)(FilterDrawer);
