import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { SimpleDialog, CopyCodeTextField } from '../Layout';
import { useQuery } from '../../hooks';
import { useTranslation } from 'react-i18next';

export default function TokenDialog() {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const query = useQuery();

	useEffect(() => {
		if (query.get('auth-code') || query.get('basecamp-code')) {
			setOpen(true);

			const value = query.get('auth-code') ?? query.get('basecamp-code') ?? '';

			window.opener.postMessage(
				{
					type: 'auth',
					token: value,
				},
				window.location.origin
			);
		}
	}, [query.get('auth-code'), query.get('basecamp-code')]);

	const value = query.get('auth-code') ?? query.get('basecamp-code') ?? '';

	return (
		<SimpleDialog
			title={
				query.get('hook-type') === 'jira'
					? t`Your Jira API token`
					: query.get('hook-type') === 'basecamp'
					? t`Your Basecamp API token`
					: 'Your API token'
			}
			open={open}
			onClose={() => setOpen(false)}
			cancel={t`Close`}
		>
			<Typography
				variant="body1"
				gutterBottom
			>{t`Please copy the following token and paste it inside the other window.`}</Typography>
			<CopyCodeTextField
				value={value}
				message={t`Token copied`}
			/>
		</SimpleDialog>
	);
}
