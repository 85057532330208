import React, { useCallback, useEffect, useState } from 'react';

import { getDateLocale, setDateLocale } from '../../utils/date';

const countriesUseUSLocale = [
	'us',
	'ca',
	'br',
	'hk',
	'co',
	'ar',
	'il',
	'mx',
	'za',
	'kr',
	'tw',
	'jp',
	'bo',
	'pe',
	'ph',
	'pr',
	'dr',
	'cr',
	'cl',
	'bz',
];

const countriesUseOwnLocale = ['cn'];

export default function useLocale() {
	const [locale, setLocale] = useState();

	const setLocaleCallBack = useCallback(
		loc => {
			if (countriesUseUSLocale.includes(loc)) {
				setLocale(loc);
				setDateLocale('us');
				return;
			}

			if (countriesUseOwnLocale.includes(loc)) {
				setLocale(loc);
				setDateLocale(loc);
				return;
			}

			setLocale(loc);
			setDateLocale('gb');
		},
		[locale]
	);

	useEffect(() => {
		const [navigatorLang, navigatorLocale] = navigator.language
			.toLocaleLowerCase()
			.split('-');
		setLocaleCallBack(navigatorLocale);
	}, []);

	const usedLocale = getDateLocale(locale);

	return [locale, setLocaleCallBack, usedLocale];
}
