import React from 'react';

import { usePermissionContext } from './PermissionContext';
import { usePermissionModuleContext } from './PermissionModuleContext';
import { usePermissionSubjectContext } from './PermissionSubjectContext';
import { permissionTypes } from './constants';

function hasPermission(allowedPermission, requestedPermission = permissionTypes.edit) {
	if (allowedPermission === permissionTypes.edit) {
		return true;
	}

	return allowedPermission && allowedPermission === requestedPermission;
}

export default function useHasPermission({
	permission = permissionTypes.edit,
	module: moduleFromProps,
	subject: subjectFromProps,
} = {}) {
	const { permissions = {} } = usePermissionContext();
	const moduleFromContext = usePermissionModuleContext();
	const subjectFromContext = usePermissionSubjectContext();

	const module = moduleFromProps ?? moduleFromContext;
	const subject = subjectFromProps ?? subjectFromContext;

	//subject may be an array in this case test if one of the subjects match
	if (Array.isArray(subject)) {
		return subject.some(allowedSubject =>
			hasPermission(permissions[module]?.[allowedSubject], permission)
		);
	}

	return hasPermission(permissions[module]?.[subject], permission);
}
