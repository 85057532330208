import React, { Component, Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
	fab: {
		position: 'fixed',
		bottom: theme.spacing(3),
		right: theme.spacing(3),
	},
});

class MenuFab extends Component {
	static defaultProps = {
		icon: <AddIcon style={{ marginRight: 8 }} />,
		label: 'Options',
		items: [],
		onClick: () => {},
		show: true,
	};

	state = { menuAnchor: null };

	showMenu = e => {
		this.setState({
			menuAnchor: e.currentTarget,
		});
	};

	hideMenu = e => {
		this.setState({
			menuAnchor: null,
		});
	};

	render() {
		let { label, icon, classes, items, show } = this.props;
		const { menuAnchor } = this.state;

		return (
			<Fragment>
				<Menu
					anchorEl={menuAnchor}
					open={Boolean(menuAnchor)}
					onClose={this.hideMenu}
				>
					{items.map(o => (
						<MenuItem
							key={o.label}
							onClick={e => {
								o.onClick(e);
								this.hideMenu();
							}}
						>
							<ListItemText>{o.label}</ListItemText>
						</MenuItem>
					))}
				</Menu>
				<Zoom in={show}>
					<Fab
						variant="extended"
						onClick={this.showMenu}
						color="primary"
						className={classes.fab}
					>
						{icon}
						{label}
					</Fab>
				</Zoom>
			</Fragment>
		);
	}
}

export default withStyles(styles)(MenuFab);
