import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Loader from './Loader';
import Logo from './Logo';

const useStyles = makeStyles(theme => ({
	root: {
		minHeight: '100vh',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	logo: {
		maxWidth: 180,
		marginBottom: theme.spacing(10),
	},
	logoWrap: {
		width: 'auto',
	},
}));

export default function MopinionLoader() {
	const classes = useStyles();
	return (
		<div
			className={classes.root}
			data-testid="root"
		>
			<Logo
				withText
				raspberry={false}
				classes={{ wrapper: classes.logoWrap }}
				className={classes.logo}
				onBg="light"
			/>
			<Loader circular />
		</div>
	);
}
