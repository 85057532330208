import React from 'react';

import { useTranslation, Trans } from 'react-i18next';

import Explainer from './Explainer';
import { Typography, KnowledgeBaseLink } from '@/components/Layout';

export default function DynamicDashboardsExplainer() {
	const { t } = useTranslation();
	return (
		<Explainer
			fontWeight="regular"
			title={t`reporting-insights-explainer-title`}
			content={
				<>
					<Typography>
						<Trans
							i18nKey="reporting-insights-explainer-text"
							components={{
								p1: <Typography mb={3} />,
								p2: <Typography mb={3} />,
								p3: <Typography />,
								br: <br />,
								i: <i />,
								knowledgeBaseLink: (
									<KnowledgeBaseLink
										dataTrackEvent="direct_insights_explainer_knowledgebase"
										color="inherit"
										page="direct_insights"
										verticalAlign="baseline"
									>
										{t`reporting-insights-explainer-link`}
									</KnowledgeBaseLink>
								),
							}}
						/>
					</Typography>
				</>
			}
		>
			{t`reporting-insights-explainer-label`}
		</Explainer>
	);
}
