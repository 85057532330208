import React, { useState, useRef, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { previewDrawerWidth, previewDrawerWidthSm } from './constants';
import { useAppContext } from '@/components/AppContext';
import PreviewIframe from './PreviewIframe';
import PreviewIframeContent from './PreviewIframeContent';
import PreviewSettings from './PreviewSettings';
import { useLocalStorage } from '@/hooks';

const useStyles = makeStyles(theme => ({
	content: {
		width: props => `calc(100% - ${props.drawerSize}px)`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		...theme.mixins.mainPadding,
		...theme.mixins.toolbarNormal('top'),
		height: `calc(100% - 64px)`,
		textAlign: 'center',
		position: 'relative',
		padding: '0!important',
	},
	overflowY: {
		overflowY: 'auto',
	},
	mobileView: {
		position: 'absolute',
		height: '100%',
		minHeight: 850,
		width: '100%',
		backgroundPosition: 'center 20px',
		backgroundRepeat: 'no-repeat',
		left: 0,
	},
	web: {
		backgroundImage: 'url(/assets/img/r/preview_mobile.svg)',
	},
	sdk: {
		backgroundImage: 'url(/assets/img/r/preview_app.svg)',
	},
}));

export default function PreviewContent({
	viewType,
	setViewType,
	surveySettings,
	selectedLanguage,
}) {
	const { app } = useAppContext();

	const drawerSize = app?.layout?.persistentDrawer
		? previewDrawerWidth
		: previewDrawerWidthSm;
	const classes = useStyles({ drawerSize });

	const { campaign, dataLoaded, surveyKey } = surveySettings;

	const [anchorEl, setAnchorEl] = useState(null);
	const [dismissedHelper, setDismissedHelper] = useLocalStorage(
		'previewhelper-dismissed',
		false,
		false
	);

	const container = useRef(null);

	useEffect(() => {
		if (dataLoaded && selectedLanguage) {
			try {
				container.current.contentWindow.srv.setLanguage(surveyKey, selectedLanguage);
			} catch (x) {}
		}
	}, [dataLoaded, selectedLanguage]);

	return (
		<main
			className={`${classes.content} ${viewType === 'mobile' ? classes.overflowY : ''}`}
		>
			{viewType === 'mobile' && (
				<div
					className={`${classes.mobileView} ${
						campaign === 'sdk' ? classes.sdk : classes.web
					}`}
				></div>
			)}
			{campaign !== 'sdk' && (
				<PreviewSettings
					viewType={viewType}
					setViewType={setViewType}
				/>
			)}
			<PreviewIframe
				id="preview-iframe"
				iFrame={container}
				viewType={viewType}
				campaign={surveySettings.campaign}
				formType={surveySettings.formType}
				slideHeight={surveySettings.slidePercentage}
			>
				<PreviewIframeContent
					iFrame={container}
					viewType={viewType}
					surveySettings={surveySettings}
					selectedLanguage={selectedLanguage}
					dismissedHelper={dismissedHelper}
					setDismissedHelper={setDismissedHelper}
					anchorEl={anchorEl}
					setAnchorEl={setAnchorEl}
				/>
			</PreviewIframe>
		</main>
	);
}
