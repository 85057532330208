import React, { useState } from 'react';

import PausedIcon from '@material-ui/icons/Pause';

import { useTranslation } from 'react-i18next';
import { ContainerQuery } from 'react-container-query';

import { makeStyles } from '@/styles';
import { DataSourceAvatar } from '@/components/DataSources';
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	OptionalDraggable,
	ActionButton,
	ActionIconButton,
	Card,
	CardContent,
	Box,
	Typography,
	Grid,
	Collapse,
	Divider,
} from '@/components/Layout';
import {
	useHasPermission,
	permissionSubjects,
	useHasProjectPermission,
} from '@/components/Permission';
import { tryParse } from '@/utils';
import DeploymentIcon from './DeploymentIcon';
import { hasDeploymentValue, conditionTypeLabel } from './deploymentUtils';

const useStyles = makeStyles(theme => ({
	spacer: {
		paddingBottom: theme.spacing(3),
	},
	chip: {
		margin: theme.spacing(0.25),
	},
	condition: {
		position: 'relative',
		'&:not(:first-child)': {
			marginTop: theme.spacing(2),
		},
	},
	card: props => ({
		position: 'relative',
		...(!props.hasProjectPermission && {
			cursor: 'not-allowed',
			opacity: 0.7,
		}),
	}),
	paused: {
		position: 'absolute',
		backgroundColor: 'rgba(255,255,255,.9)',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		zIndex: 10,
	},
	deleted: {
		zIndex: 11,
	},
	extraDense: {
		paddingTop: theme.spacing(0.25),
		paddingBottom: theme.spacing(0.25),
	},
	conditionListText: {
		//	color:theme.palette.text.secondary
	},
	conditionListIcon: {
		//color:theme.palette.secondary.light
	},
	alignMiddleInline: {
		verticalAlign: 'middle',
	},
}));

function determineDisplayType({ survey_type, modal, trigger = '' }) {
	if (survey_type !== 'builder') {
		return null;
	}

	if (!modal) {
		return 'embed';
	}
	const parsed = tryParse(trigger);
	return parsed?.slider?.type || 'modal';
}

function determineSdkFormType({ campaign, advanced, sdk_type, survey_format }) {
	if (campaign === 'sdk' || tryParse(advanced).sdk_survey) {
		return sdk_type === 'webview' || survey_format === 'conversational'
			? 'webview'
			: 'native';
	}
	return 'webview';
}

export default function DeploymentEditorItem({
	selected,
	onClick = () => {},
	onDelete = () => {},
	form = {},
	rule = {},
	index,
	loading,
	startOpen = false,
	editorType,
	...props
}) {
	const { t } = useTranslation();
	const hasEditPermission = useHasPermission({
		subject: permissionSubjects.deployments,
	});
	const hasProjectPermission = useHasProjectPermission({
		projectId: form.project_id,
	});
	const classes = useStyles({
		hasProjectPermission,
	});
	const [open, setOpen] = useState(startOpen);

	const displayType = determineDisplayType(form);
	const formType = determineSdkFormType(form);
	const renderingOptions = rule.then[0] ?? {};

	const parseSelector = (selector = '') => {
		if (/^[a-zA-Z]/.test(selector)) {
			return '#' + selector;
		}
		return selector;
	};

	return (
		<OptionalDraggable
			disablePortal
			draggable={hasEditPermission && hasProjectPermission}
			draggableId={'draggable' + rule.id}
			index={index}
		>
			<div className={classes.spacer}>
				<Card
					elevation={selected ? 6 : 1}
					className={classes.card}
					onClick={() => {
						if (hasProjectPermission) {
							onClick();
						}
					}}
					data-onboarding="deployment-editor-item"
					data-test-element="deployment-editor-item"
				>
					{Object.keys(form).length === 0 && !loading && (
						<div className={`${classes.paused} ${classes.deleted}`}>
							<div>
								<div>
									<Typography
										variant="subtitle2"
										display="inline"
										className={classes.alignMiddleInline}
										color="textSecondary"
									>{t`data_collection-deployment_item-form_removed_overlay-title`}</Typography>
								</div>
								<Typography
									variant="caption"
									color="textSecondary"
								>
									{t`data_collection-deployment_item-form_removed_overlay-text`}
								</Typography>
								<Box mt={1}>
									<ActionButton
										onClick={onDelete}
										action="delete"
										label={t`data_collection-deployment_item-form_removed_overlay-button`}
									/>
								</Box>
							</div>
						</div>
					)}
					<CardContent>
						<ContainerQuery
							query={{
								twoCol: { minWidth: 500 },
							}}
						>
							{matches => (
								<>
									<Grid
										container
										alignItems="center"
										spacing={3}
									>
										<Grid item>
											<DataSourceAvatar
												survey_format={form.survey_format}
												survey_type={form.survey_type}
												campaign={form.campaign}
											/>
										</Grid>
										<Grid
											item
											xs
										>
											<Typography
												variant="h6"
												display="block"
												gutterBottom
											>
												{form.name}
											</Typography>
											<Typography
												variant="subtitle1"
												display="block"
												color="textSecondary"
											>
												{editorType === 'sdk'
													? t(
															`data_collection-deployment_item-display_type-${displayType}-${formType}`
													  )
													: t(
															`data_collection-deployment_item-display_type-${displayType}`
													  )}
											</Typography>
										</Grid>
										<Grid item>
											{hasEditPermission && hasProjectPermission && (
												<ActionIconButton
													onClick={onDelete}
													tooltip={t`data_collection-deployment_item-remove_form-tooltip`}
													data-test-element="deployment_editor_remove_deployment_button"
													action="remove_list"
												/>
											)}

											<ActionIconButton
												action={open ? 'expand_less' : 'expand_more'}
												onClick={e => {
													e.stopPropagation();
													setOpen(prev => !prev);
												}}
											/>
										</Grid>
									</Grid>
									<Collapse in={open}>
										<Box mt={2}>
											<Grid
												container
												spacing={rule.if.length > 1 ? 1 : 0}
											>
												{rule.if.map((conditionSet, conditionIndex) => {
													const conditionsObject = {
														...conditionSet,
														...(editorType === 'sdk' && {
															...conditionSet.mobile,
															mobile: null,
														}),
													};

													return (
														<Grid
															item
															key={'griditem' + rule.id + conditionIndex}
															xs={matches.twoCol && rule.if.length > 1 ? 6 : 12}
														>
															<div
																className={classes.condition}
																key={rule.id}
															>
																{conditionSet.pause && (
																	<div className={classes.paused}>
																		<div>
																			<div>
																				<PausedIcon
																					color="action"
																					className={classes.alignMiddleInline}
																				/>
																				<Typography
																					variant="subtitle2"
																					display="inline"
																					className={classes.alignMiddleInline}
																					color="textSecondary"
																				>{t`data_collection-deployment_item-form_paused_overlay-title`}</Typography>
																			</div>
																			<Typography
																				variant="caption"
																				color="textSecondary"
																			>
																				{t`data_collection-deployment_item-form_paused_overlay-text`}
																			</Typography>
																		</div>
																	</div>
																)}
																<Typography
																	variant="subtitle2"
																	color="textSecondary"
																>
																	{t(
																		`data_collection-deployment_item-condition_set-label`,
																		{ number: conditionIndex + 1 }
																	)}
																</Typography>
																<List
																	dense
																	disablePadding
																>
																	{Object.entries(conditionsObject).map(conditionArr => {
																		const [key, value] = conditionArr;
																		return hasDeploymentValue(value) ? (
																			<ListItem
																				key={rule.id + conditionIndex + key}
																				disableGutters
																				className={classes.extraDense}
																			>
																				<ListItemIcon
																					className={classes.conditionListIcon}
																				>
																					<DeploymentIcon
																						type={key}
																						value={value}
																					/>
																				</ListItemIcon>
																				<ListItemText
																					className={classes.conditionListText}
																					primary={conditionTypeLabel(key, value)
																						.map(v => t(v))
																						.join(' ')}
																				/>
																			</ListItem>
																		) : null;
																	})}
																</List>
															</div>
														</Grid>
													);
												})}
											</Grid>
										</Box>
										{editorType !== 'sdk' && (
											<Box mt={2}>
												<Box mb={2}>
													<Divider />
												</Box>
												<Box>
													<Typography
														mb={1}
														display="block"
														variant="subtitle2"
														color="textSecondary"
													>
														{t(`data_collection-deployment_item-form_placement_header`)}
													</Typography>
													<Box
														display="flex"
														alignItems="center"
													>
														<Box mr={4}>
															<DeploymentIcon
																color="action"
																type="form_placement"
															/>
														</Box>
														<Box>
															<Typography
																variant="body2"
																display="block"
															>
																{t(`data_collection-deployment_item-render_into`, {
																	selector: parseSelector(renderingOptions.args?.[2]),
																})}
															</Typography>
															<Typography
																variant="caption"
																color="textSecondary"
																display="block"
															>
																{renderingOptions.disableMakeDiv
																	? t('data_collection-deployment_item-render_manual')
																	: t(
																			'data_collection-deployment_item-render_by_deployment'
																	  )}
															</Typography>
															{renderingOptions.useQuerySelectorAll && (
																<Typography
																	variant="caption"
																	color="textSecondary"
																	display="block"
																>
																	{t`data_collection-deployment_item-render_into_all_of_selector`}
																</Typography>
															)}
														</Box>
													</Box>
												</Box>
											</Box>
										)}
									</Collapse>
								</>
							)}
						</ContainerQuery>
					</CardContent>
				</Card>
			</div>
		</OptionalDraggable>
	);
}
