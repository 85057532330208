export const permissionModules = {
	reporting: 'reporting',
	actions: 'actions',
	data_collection: 'data_collection',
	settings: 'settings',
	integrations: 'integrations',
	billing: 'billing',
};

export const permissionSubjects = {
	dashboard: 'dashboard',
	feedback_inbox: 'feedback_inbox',
	text_analytics: 'text_analytics',
	exports: 'exports',
	action_management: 'action_management',
	feedback_forms_build: 'feedback_forms_build',
	feedback_forms_design: 'feedback_forms_design',
	datasets: 'datasets',
	deployments: 'deployments',
	user_management: 'user_management',
	reports: 'reports',
	tag_management: 'tag_management',
	standard_answers: 'standard_answers',
	alerts: 'alerts',
	billing_management: 'billing_management',
	webhooks_apps: 'webhooks_apps',
	api: 'api',
};

export const permissionTypes = {
	edit: 'edit',
	view: 'view',
	no_access: 'no_access',
};

export const defaultPermissions = {
	[permissionModules.reporting]: {
		[permissionSubjects.dashboard]: permissionTypes.view,
		[permissionSubjects.feedback_inbox]: permissionTypes.view,
		[permissionSubjects.text_analytics]: permissionTypes.view,
		[permissionSubjects.exports]: permissionTypes.view,
	},
	[permissionModules.actions]: {
		[permissionSubjects.action_management]: permissionTypes.view,
	},
	[permissionModules.data_collection]: {
		[permissionSubjects.feedback_forms_build]: permissionTypes.view,
		[permissionSubjects.feedback_forms_design]: permissionTypes.view,
		[permissionSubjects.datasets]: permissionTypes.view,
		[permissionSubjects.deployments]: permissionTypes.view,
	},
	[permissionModules.settings]: {
		[permissionSubjects.user_management]: permissionTypes.view,
		[permissionSubjects.reports]: permissionTypes.view,
		[permissionSubjects.tag_management]: permissionTypes.view,
		[permissionSubjects.standard_answers]: permissionTypes.view,
		[permissionSubjects.alerts]: permissionTypes.view,
	},
	[permissionModules.billing]: {
		[permissionSubjects.billing_management]: permissionTypes.no_access,
	},
	[permissionModules.integrations]: {
		[permissionSubjects.webhooks_apps]: permissionTypes.view,
		[permissionSubjects.api]: permissionTypes.view,
	},
};

export const permissionGroupBase = {
	name: '',
	rights: { ...defaultPermissions },
	projects: [],
	members: [],
};

export const nonEditablePermissionModules = [permissionModules.billing];

export const nonEditablePermissionSubjects = [
	permissionSubjects.user_management,
	permissionSubjects.reports,
	permissionSubjects.billing_management,
];

export const permissionGroupTypes = {
	owner: 'owner',
	admin: 'admin',
	viewer: 'normal',
	restricted: 'restricted',
};

export const requiresAtLeastViewOnLinkedSubject = {
	//[permissionSubjects.feedback_forms_build]: permissionSubjects.feedback_forms_design,
	// [permissionSubjects.feedback_forms_design]: permissionSubjects.feedback_forms_build
};
