import React from 'react';

import { makeStyles, useTheme } from '@/styles';
import Slide from '@material-ui/core/Slide';

import { useAppContext } from '@/components/AppContext';
import { Box, Typography, Grid } from '@/components/Layout';

import SelectionCard from './SelectionCard';
import { UpsellCard, ProductTrialChip } from '@/components/Billing';

const useStyles = makeStyles(theme => ({
	wrap: {
		height: '100%',
		width: '100%',
		minHeight: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	heading: {
		marginBottom: theme.spacing(8),
		maxWidth: 800,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	actions: {
		marginTop: theme.spacing(8),
	},
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(1),
	},
}));

export default function SelectionFlow({
	title,
	subtitle,
	options = [],
	spacing = 3,
	actions,
	alignItems = 'center',
	titleTypographyProps = {},
	subtitleTypographyProps = {},
}) {
	const classes = useStyles();
	const theme = useTheme();
	const { app } = useAppContext();

	return (
		<div className={classes.wrap}>
			<div className={classes.heading}>
				<Typography
					variant="h5"
					align="center"
					{...titleTypographyProps}
				>
					{title}
				</Typography>
				<Typography
					variant="subtitle1"
					color="textSecondary"
					align="center"
					{...subtitleTypographyProps}
				>
					{subtitle}
				</Typography>
			</div>

			<Grid
				container
				spacing={spacing}
				justify="center"
				alignItems={alignItems}
			>
				{options.map((option, index) => {
					const {
						GridItemProps = {},
						onClick,
						primary,
						secondary,
						img,
						src,
						product,
						primaryTypographyProps = {},
						secondaryTypographyProps = {},
						...rest
					} = option;

					const IconComponent = option.icon ?? null;
					const isFirstStepInSelectionFlow = ['web', 'email', 'sdk'].includes(product);
					const productInPackage = app.api.packageProductAllowed(product);
					const daysLeftInProductTrial = app.api.packageProductTrialDaysLeft(product);

					return (
						<Grid
							item
							md={4}
							xs={10}
							{...GridItemProps}
							key={index}
						>
							<Slide
								in
								direction="up"
								timeout={theme.transitions.duration.enteringScreen * (index + 1)}
							>
								<Box height="100%">
									{(!isFirstStepInSelectionFlow || productInPackage) && (
										<SelectionCard
											onClick={onClick}
											bordered
											{...rest}
										>
											{img && img}
											{src && <img src={src} />}
											{IconComponent && (
												<IconComponent
													fontSize="large"
													className={classes.icon}
												/>
											)}
											{daysLeftInProductTrial > 0 && (
												<ProductTrialChip
													daysLeftInProductTrial={daysLeftInProductTrial}
												/>
											)}
											<div>
												<Typography
													gutterBottom={Boolean(secondary)}
													variant="subtitle2"
													{...primaryTypographyProps}
												>
													{primary}
												</Typography>

												<Typography
													color="textSecondary"
													variant="body2"
													{...secondaryTypographyProps}
												>
													{secondary}
												</Typography>
											</div>
										</SelectionCard>
									)}
									{isFirstStepInSelectionFlow && !productInPackage && (
										<UpsellCard product={product} />
									)}
								</Box>
							</Slide>
						</Grid>
					);
				})}
			</Grid>

			{actions && <div className={classes.actions}>{actions}</div>}
		</div>
	);
}
