import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import CheckIcon from '@material-ui/icons/Check';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '../AppContext';
import { Card, CardContent, Grid, Typography, Chip, ActionButton } from '../Layout';
import ParentProductIcon from './ParentProductIcon';
import { products, subProducts, enterpriseServices } from './constants';

const useStyles = makeStyles(theme => ({
	card: {
		height: '100%',
	},
	cardContent: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'space-between',
	},
	titleGrid: {
		height: 60,
	},
	list: {
		padding: 0,
		marginBottom: theme.spacing(2),
	},
	parentProductListItem: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	subProductListItem: {
		padding: 0,
	},
	extraServicesContainer: {
		backgroundColor: theme.palette.grey[100],
		padding: theme.spacing(1, 2),
		margin: theme.spacing(2, 0),
	},
	extraServiceListItem: {
		padding: 0,
		margin: 0,
	},
	button: {
		marginTop: theme.spacing(2),
	},
	tagline: {
		marginBottom: theme.spacing(3),
	},
}));

export default function PlanCard({ title = '', plan = '', ButtonProps = {} }) {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<Card className={classes.card}>
			<CardContent className={classes.cardContent}>
				<div>
					<Grid
						container
						className={classes.titleGrid}
					>
						<Grid
							item
							xs
						>
							<Typography
								variant="h5"
								component="h2"
							>
								{title}
							</Typography>
						</Grid>
						{plan === 'enterprise' && (
							<Grid item>
								<Chip
									label={t`plan_card_most_popular`}
									color="primary"
								/>
							</Grid>
						)}
					</Grid>

					<Typography className={classes.tagline}>
						{t(`plan_card-tagline-${plan}`)}
					</Typography>

					{products.map(product => {
						return (
							<List
								key={product}
								className={classes.list}
							>
								<ListItem
									disableGutters
									inset
									className={classes.parentProductListItem}
								>
									<ListItemAvatar>
										<ParentProductIcon product={product} />
									</ListItemAvatar>
									<ListItemText
										primary={
											<Typography fontWeight="medium">
												{t(`plan_card-products-${product}`)}
											</Typography>
										}
									/>
								</ListItem>
								{subProducts[product]?.map(subProduct => {
									return (
										<ListItem
											disableGutters
											key={subProduct}
											className={classes.subProductListItem}
										>
											<ListItemIcon />
											<ListItemText
												primary={
													<Typography
														tooltip={t(`plan_card-products-${subProduct}_tooltip`)}
														display="inline"
													>
														{t(`plan_card-products-${subProduct}`, {
															count: app.package[subProduct],
														})}
													</Typography>
												}
											/>
										</ListItem>
									);
								})}
							</List>
						);
					})}
				</div>

				{plan === 'enterprise' && (
					<div className={classes.extraServicesContainer}>
						<Typography variant="subtitle">{t`plan_card-extra_services-title`}</Typography>

						<List>
							{enterpriseServices.map(service => (
								<ListItem
									key={service}
									className={classes.extraServiceListItem}
								>
									<ListItemIcon>
										<CheckIcon color="secondary" />
									</ListItemIcon>
									<ListItemText primary={t(`plan_card-extra_services-${service}`)} />
								</ListItem>
							))}
						</List>
					</div>
				)}

				<ActionButton
					variant="outlined"
					color="primary"
					fullWidth={true}
					className={classes.button}
					{...ButtonProps}
				/>
			</CardContent>
		</Card>
	);
}
