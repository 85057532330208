import React from 'react';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import FaIcon from './FaIcon';

export default function AppleIcon(props) {
	return (
		<FaIcon
			icon={faApple}
			{...props}
		/>
	);
}
