import React, { Fragment } from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import Search from '@material-ui/icons/Search';

import { useTranslation } from 'react-i18next';

import { useListFilterContext, FilterGroup } from '../FilteredLists';

function RecentListFilters() {
	const { setSingleFilter, setMultiFilter, filters } = useListFilterContext();

	const { t } = useTranslation();

	return (
		<Fragment>
			<div>
				<TextField
					variant="filled"
					fullWidth
					placeholder={t('Search')}
					label={t('Search')}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search />
							</InputAdornment>
						),
					}}
					onChange={e => setSingleFilter('search', e.target.value)}
					value={filters.search ?? ''}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Owner')}
					filterKey={'export_owner'}
					filters={[
						{ label: t('Exported by me'), value: 'me' },
						{ label: t('Exported by others'), value: 'other' },
					].map(filter => {
						if (filter.value === filters.export_owner) {
							filter.selected = true;
						}
						filter.key = 'export_owner' + filter.value;
						return filter;
					})}
					onChange={({ key, value }) => setSingleFilter(key, value)}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Type')}
					filterKey={'export_type'}
					filters={[
						{ label: t('Single exports'), value: 'single' },
						{ label: t('Scheduled exports'), value: 'scheduled' },
					].map(filter => {
						if (filter.value === filters.export_type) {
							filter.selected = true;
						}
						filter.key = 'export_type' + filter.value;
						return filter;
					})}
					onChange={({ key, value }) => setSingleFilter(key, value)}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Export data')}
					filterKey={'export_data'}
					filters={[
						{ label: t('With export data'), value: 'withdata' },
						{ label: t('Without export data'), value: 'withoutdata' },
					].map(filter => {
						if (filter.value === filters.export_data) {
							filter.selected = true;
						}
						filter.key = 'export_data' + filter.value;
						return filter;
					})}
					onChange={({ key, value }) => setSingleFilter(key, value)}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Status')}
					filterKey={'export_status'}
					filters={[
						{ value: 'SCHEDULED', label: t('Scheduled') },
						{ value: 'PENDING', label: t('Pending') },
						{ value: 'STARTED', label: t('Started') },
						{ value: 'RETRY', label: t('Retry') },
						{ value: 'FINISHED', label: t('Finished') },
						{ value: 'FAIL', label: t('Failed') },
						{ value: 'REVOKED', label: t('Revoked') },
					].map(status => {
						if ((filters.export_status || []).includes(status.value)) {
							status.selected = true;
						}
						return status;
					})}
					multiple
					onChange={({ key, value }) => {
						setMultiFilter(key, value);
					}}
				/>
			</div>
		</Fragment>
	);
}

export default RecentListFilters;
