import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, TextField, MenuItem } from '@/components/Layout';
import { useAppContext } from '@/components/AppContext';
import SsoLogin from './SsoLogin';

export default function Sso({ ssoLoginOnly }) {
	const { t } = useTranslation();
	const { app } = useAppContext();
	const [uuid, setUuid] = useState(
		app.auth.sso?.organisations.length > 1 ? app.auth.sso.organisations[0]?.uuid : ''
	);

	return (
		<Box mt={ssoLoginOnly ? 8 : 0}>
			{app.auth.sso?.organisations.length > 1 && (
				<Box mb={1}>
					<TextField
						variant="outlined"
						label={t`login-sso_org_select-label`}
						onChange={e => setUuid(e.target.value)}
						value={uuid}
						fullWidth
						select
					>
						{app.auth.sso.organisations.map(org => (
							<MenuItem
								key={org.uuid}
								value={org.uuid}
							>
								{org.name}
							</MenuItem>
						))}
					</TextField>
				</Box>
			)}

			{app.auth.sso.enabled && <SsoLogin organisationUuid={uuid} />}
		</Box>
	);
}
