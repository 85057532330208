import React from 'react';

import { useAppContext } from '@/components/AppContext';
import { usePermissionContext } from './PermissionContext';

function hasPermission(projects = [], project) {
	return projects.includes(project?.uuid);
}

function determineProject({ projectId, projectUuid, project, projects = [] }) {
	if (project) {
		return project;
	}

	if (projectId) {
		return projects.find(project => project.id === Number(projectId));
	}

	if (projectUuid) {
		return projects.find(project => project.uuid === projectUuid);
	}

	return null;
}

export default function useHasProjectPermission({ projectId, projectUuid, project }) {
	const { projects = [] } = usePermissionContext();
	const { app } = useAppContext();

	return hasPermission(
		projects,
		determineProject({
			projectId,
			projectUuid,
			project,
			projects: app.projects.asArray,
		})
	);
}
