import React, { useEffect, useMemo } from 'react';

import { useHistory } from 'react-router-dom';

import { useAppContext } from '@/components/AppContext';
import { analyticsEvent } from './trackingUtils';

function isGa4Id(id = '') {
	return /^G-/i.test(id);
}

function getFromGoogleTagManager() {
	for (const item of Object.keys(window.google_tag_manager ?? {})) {
		if (isGa4Id(item)) {
			return item;
		}
	}
}

function getFromDataLayer() {
	if (isGa4Id(window.dataLayer)) {
		for (const item of window.dataLayer) {
			if (item[0] === 'config' && isGa4Id(item[1])) {
				return item[1];
			}
		}
	}
}

function getGa4Id() {
	if (getFromGoogleTagManager()) {
		return getFromGoogleTagManager();
	}

	if (getFromDataLayer()) {
		return getFromDataLayer();
	}
}

export default function useAnalytics(trackingId) {
	const {
		app: { domain },
	} = useAppContext();
	const { listen } = useHistory();

	const ga4Id = useMemo(() => {
		if (isGa4Id(trackingId)) return trackingId;

		return getGa4Id();
	}, [trackingId]);

	useEffect(() => {
		function pageview() {
			if (!ga4Id) {
				return;
			}

			if (ga4Id) {
				analyticsEvent('event', 'page_view', { page_path: document.location.pathname });
			}
		}

		const unlisten = listen(location => {
			pageview();
		});

		return unlisten;
	}, [listen, ga4Id, domain]);
}
