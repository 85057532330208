import React, { useState } from 'react';

import CardActionArea from '@material-ui/core/CardActionArea';

import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@/styles';
import {
	ActionIconButton,
	Typography,
	SimpleDialog,
	Grid,
	Card,
} from '@/components/Layout';
import { useAppContext } from '@/components/AppContext';
import DashboardAdditDialog from './DashboardAdditDialog';
import useDashboard from './useDashboard';
import { useHasPermission } from '@/components/Permission';
import useDeleteDashboard from './useDeleteDashboard';

const useStyles = makeStyles(theme => ({
	demoData: {
		opacity: 0,
	},
	paperRow: {
		cursor: 'pointer',
		'& button': {
			opacity: 0,
		},
		'&:hover button': {
			opacity: 1,
		},
		'& a': {
			opacity: 0,
		},
		'&:hover a': {
			opacity: 1,
		},
		'&:hover $demoData': {
			opacity: 1,
		},
	},
	open: {
		'& button': {
			opacity: 1,
		},
		'& a': {
			opacity: 1,
		},
	},
	paper: {
		padding: theme.spacing(2),
	},
	chip: {
		marginLeft: theme.spacing(2),
	},
	marginRight: {
		marginRight: theme.spacing(1),
	},
	marginBottom: {
		marginBottom: theme.spacing(2),
	},
}));

export default function DashboardOverviewRow({ dashboard = {} }) {
	const { t } = useTranslation();
	const classes = useStyles();
	const { app } = useAppContext();
	const history = useHistory();
	const hasEditPermission = useHasPermission();

	const {
		dashboardApi: { loadDashboards },
	} = useDashboard();

	const [dialogOpen, setDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const deleteDashboard = useDeleteDashboard({
		id: dashboard.id,
		onSuccess: () => {
			setDeleteDialogOpen(false);
			loadDashboards();
		},
	});

	return (
		<>
			<Card data-test-element="dashboard-overview-row">
				<CardActionArea
					className={`${classes.paperRow} ${
						dialogOpen || deleteDialogOpen ? classes.open : ''
					}`}
				>
					<Grid
						container
						direction="row"
						alignItems="center"
					>
						<Grid
							item
							xs
							className={classes.paper}
							onClick={() => history.push(`/reporting/dashboard/${dashboard.id}`)}
						>
							<Typography
								fontWeight="medium"
								component="span"
								data-test-element="dashboard-overview-row-name"
							>
								{dashboard.name ??
									t('reporting-dashboard_overview-card-default_name', {
										name: app.projects.current.name,
									})}
							</Typography>
						</Grid>

						<Grid
							item
							className={classes.paper}
						>
							<ActionIconButton
								action="view"
								link
								to={`/reporting/dashboard/${dashboard.id}`}
								tooltip={t`reporting-dashboard_overview-dashboard_row-button_view`}
								data-test-element="dashboard-overview-row-view-button"
							/>

							<ActionIconButton
								action="edit"
								onClick={() => {
									setDialogOpen(true);
								}}
								disabled={!hasEditPermission}
								tooltip={t`reporting-dashboard_overview-dashboard_row-button_edit`}
							/>

							<ActionIconButton
								action="delete"
								onClick={() => {
									setDeleteDialogOpen(true);
								}}
								tooltip={t`reporting-dashboard_overview-dashboard_row-button_delete`}
								disabled={!hasEditPermission}
							/>
						</Grid>
					</Grid>
				</CardActionArea>
			</Card>

			<DashboardAdditDialog
				open={dialogOpen}
				dashboard={dashboard}
				onClose={() => setDialogOpen(false)}
				loadDashboards={() => loadDashboards()}
			/>

			<SimpleDialog
				title={t`reporting-dashboard_overview-dialog_delete_dashboard-title`}
				open={deleteDialogOpen}
				loading={deleteDashboard.loading}
				submit={t`reporting-dashboard_overview-dialog_delete_dashboard-button_submit`}
				cancel={t`reporting-dashboard_overview-dialog_delete_dashboard-button_cancel`}
				dataTrackEvent="dashboard_overview_delete_dashboard"
				onSubmit={() => {
					deleteDashboard.postForm();
				}}
				onClose={() => setDeleteDialogOpen(false)}
			>
				<Trans
					i18nKey="reporting-dashboard_overview-dialog_delete_dashboard-content"
					components={{
						strong: <strong />,
						p1: <Typography className={classes.marginBottom} />,
						p2: <Typography />,
					}}
					values={{ name: dashboard.name }}
				/>
			</SimpleDialog>
		</>
	);
}
