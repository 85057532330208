import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';
import { usePermissionContext } from '@/components/Permission';
import { Autocomplete, TextField } from '@/components/Layout';

export default function ProjectsAutocomplete({
	label,
	multiple,
	value,
	exclude = [],
	helperText,
	...props
}) {
	const { app } = useAppContext();

	const permission = usePermissionContext();

	const { t } = useTranslation();

	const selectedProjects = multiple
		? value.map(uuid => app.projects.asArray.find(project => project.uuid === uuid))
		: app.projects.asArray.find(project => project.uuid === value);

	const options = useMemo(() => {
		return app.projects.asArray.filter(
			project =>
				permission.projects?.includes(project.uuid) &&
				exclude.indexOf(project.uuid) === -1
		);
	}, [exclude, app.projects.asArray, permission]);

	function getOptionLabel(option) {
		if (!multiple && option?.uuid === app.projects.current.uuid) {
			return option?.name + t`settings-projects_autocomplete-current_suffix`;
		}
		return option?.name;
	}

	return (
		<Autocomplete
			options={options}
			multiple={multiple}
			value={selectedProjects}
			getOptionLabel={option => getOptionLabel(option)}
			renderInput={params => (
				<TextField
					helperText={helperText}
					{...params}
					label={label ?? t`settings-projects_autocomplete-label`}
				/>
			)}
			{...props}
		/>
	);
}
