import React, { useState, useEffect } from 'react';

import { useAppContext } from '../components/AppContext';
import { tryParse } from '../utils';
import usePrevious from './usePrevious';

import { useLocation } from 'react-router-dom';

function getStorageSafe(key) {
	let value = null;

	try {
		value = window.sessionStorage.getItem(key);
	} catch (e) {}

	if (typeof value === 'string') {
		return tryParse(value, value);
	}
}

function setStorageSafe(key, value) {
	if (value !== undefined && value !== null) {
		try {
			const stringValue = typeof value === 'object' ? JSON.stringify(value) : value;
			//prevent overloading localstorage
			if (typeof value !== 'string' || stringValue?.length < 20000) {
				window.sessionStorage.setItem(key, stringValue);
			}
		} catch (e) {}
	}
}

export default function useSessionStorage(key, val, prependPath = true, disableCache) {
	const { app } = useAppContext();
	const { pathname } = useLocation();

	const storageKey = `${prependPath ? pathname : ''}${app.projects.current.id}${key}`;

	const initialValue = disableCache ? val : getStorageSafe(storageKey) || val;

	const [value, setValue] = useState(initialValue);

	const prevProject = usePrevious(app.projects.current.id);
	useEffect(() => {
		if (!prevProject && app.projects.current.id && typeof value === 'undefined') {
			setValue(getStorageSafe(storageKey) || val);
		}
	}, [app.projects.current.id, value, val]);

	useEffect(() => {
		if (!disableCache) {
			setStorageSafe(storageKey, value);
		}
	}, [storageKey, value, disableCache]);

	return [value, setValue];
}
