import React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useAppContext } from '@/components/AppContext';
import { Typography, FormattedDate, ActionButton, Divider } from '@/components/Layout';
import { DataSourceSelect } from '@/components/Select';
import { FilterGroup } from '@/components/FilteredLists';
import { useTextAnalyticsContext } from './TextAnalyticsContext';
import useRefreshTextAnalyticsProject from './useRefreshTextAnalyticsProject';
import useGetProjectRefreshExpiration from './useGetProjectRefreshExpiration';

export default function FeedbackListFilters() {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { app } = useAppContext();
	const {
		textAnalytics: {
			groups,
			lastUpdated,
			noProject,
			survey_id,
			api: { toggleGroup, setDataSourceFilter },
		},
	} = useTextAnalyticsContext();

	const [{ update_allowed }, expirationLoading, expirationError, loadExpiration] =
		useGetProjectRefreshExpiration(survey_id);

	const { postForm: refreshProject, loading: refreshLoading } =
		useRefreshTextAnalyticsProject({
			onSuccess: response => {
				if (response.code >= 200 && response.code < 300) {
					loadExpiration();
					enqueueSnackbar(
						t`reporting-text_analytics-exclusion_list-list_refresh_message`
					);
				} else {
					enqueueSnackbar(t`reporting-text_analytics-snackbar-language_error`);
				}
			},
		});

	return (
		<>
			<div>
				<FilterGroup
					filterName={t`reporting-text_analytics-dashboard-filter-label_show`}
					noCollapse
					filterKey={'datasource'}
				>
					<DataSourceSelect
						value={survey_id ?? 'ALL_DATA'}
						withAllData
						onChange={e => {
							setDataSourceFilter(e.target.value);
						}}
					/>
				</FilterGroup>
			</div>

			<div>
				<FilterGroup
					filterName={t`reporting-text_analytics-dashboard-filter-label_use`}
					filterKey="word_group"
					noCollapse
					filters={[
						{
							label: t('reporting-text_analytics-dashboard-filter-option_words'),
							value: '0',
						},
						{
							label: t('reporting-text_analytics-dashboard-filter-option_wordgroup'),
							value: '1',
						},
					].map(filter => {
						if (Number(filter.value) === Number(groups)) {
							filter.selected = true;
						}
						return filter;
					})}
					onChange={({ key, value }) => {
						toggleGroup(Number(value));
					}}
				/>
			</div>
			{!noProject && (
				<>
					<Divider component="div" />
					<div>
						<Typography
							variant="body2"
							fontWeight="medium"
							gutterBottom
						>
							{t`reporting-text_analytics-dashboard-filter-refresh-label`}
						</Typography>
						<ActionButton
							fullWidth
							disabled={noProject || !update_allowed}
							loading={expirationLoading || refreshLoading}
							onClick={() => {
								refreshProject({
									post: {
										data: {
											project_id: app.projects.current.id,
											domain: app.domain,
											...(survey_id && { survey_id }),
										},
									},
								});
							}}
							action="refresh"
							variant="outlined"
							color="primary"
							size="small"
							label={t`reporting-text_analytics-dashboard-filter-refresh-button`}
							data-track-event="text_analytics-manual_update"
						/>
						{lastUpdated && (
							<Typography
								variant="caption"
								color="textSecondary"
							>
								<Trans
									i18nKey="reporting-text_analytics-dashboard-filter-last_updated"
									components={{
										lastUpdated: (
											<FormattedDate
												date={lastUpdated}
												withTime
											/>
										),
									}}
								/>
							</Typography>
						)}
						{!update_allowed && (
							<Typography
								variant="caption"
								color="textSecondary"
							>
								{t`reporting-text_analytics-exclusion_list-list_refresh_message_helper`}
							</Typography>
						)}
					</div>
				</>
			)}
		</>
	);
}
