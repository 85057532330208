import React from 'react';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '../AppContext';
import { SimpleDialog, Typography } from '../Layout';

function messages(type) {
	switch (type) {
		case 'security':
			return 'Because of security reasons you have to login again.';
		case 'inactivity':
			return 'You have been logged out due to inactivity.';

		default:
			return 'You have been logged out.';
	}
}
export default function LoggedOutDialog() {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const message = `${t(
		messages(app.layout.loggedOutReason) ?? ''
	)} ${t`Please return to the login page and login to continue.`}`;

	function reLogin() {
		if (app.auth.sso.enabled) {
			return (document.location.href = app.auth.sso.login_path);
		}
		return (document.location.href = '/');
	}

	return (
		<SimpleDialog
			open={app.layout.loggedOut}
			title={t`You have been logged out`}
			submit={t`Log back in`}
			onSubmit={() => reLogin()}
			blurBackdrop
		>
			<Typography>{message}</Typography>
		</SimpleDialog>
	);
}
