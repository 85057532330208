import React, { createContext, useContext } from 'react';

export const PermissionModuleContext = createContext(null);

export function PermissionModuleContextProvider({ children, module }) {
	if (!module) {
		throw new Error('PermissionModuleContextProvider requires a module');
	}

	return (
		<PermissionModuleContext.Provider value={module}>
			{children}
		</PermissionModuleContext.Provider>
	);
}

export function usePermissionModuleContext() {
	return useContext(PermissionModuleContext);
}
