import React, { useState } from 'react';

import GroupAddIcon from '@material-ui/icons/GroupAdd';
import BarChartIcon from '@material-ui/icons/BarChart';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CallMergeIcon from '@material-ui/icons/CallMerge';

import { makeStyles } from '@/styles';

import { useDataSourceContext } from '@/components/DataSources';
import useOnboardingContext from './useOnboardingContext';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
	ActionButton,
	Typography,
	CardContent,
	CardActions,
	Grid,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
} from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	cardActions: {
		padding: theme.spacing(2),
	},
	marginLeft: {
		marginLeft: 'auto',
	},
	marginRight: {
		marginRight: 'auto',
	},
	italic: {
		fontStyle: 'italic',
	},
	image: {
		textAlign: 'center',
		'& img': {
			margin: 'auto',
			maxWidth: '100%',
		},
	},
}));

export default function TrialFlowFeedbackInbox() {
	const onboarding = useOnboardingContext();

	if (onboarding.trialStep === 4) {
		return <InboxPreviewContent />;
	}
	return <EndOfTrialList />;
}

function InboxPreviewContent() {
	const onboarding = useOnboardingContext();
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<>
			<CardContent>
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						xs={12}
						className={classes.image}
					>
						<img src={`/assets/img/r/onboarding/inbox_preview.gif`} />
					</Grid>
					<Grid
						item
						xs={12}
					>
						<Trans
							i18nKey="onboarding-tiral_menu-step4-list-text"
							components={{
								h6: (
									<Typography
										variant="h6"
										gutterBottom
									/>
								),
								p1: (
									<Typography
										variant="body1"
										paragraph
									/>
								),
								p2: <Typography variant="body1" />,
							}}
						/>
					</Grid>
				</Grid>
			</CardContent>

			<CardActions className={classes.cardActions}>
				<ActionButton
					color="primary"
					className={classes.marginLeft}
					onClick={() => {
						onboarding.dispatch({
							type: 'set_trial_step',
							payload: {
								step: 5,
							},
						});
					}}
					variant="contained"
					data-track-event="onboarding_trial_step_feedback_inbox_preview"
					data-test-element="onboarding_trial_step_feedback_inbox_preview_button"
				>
					{t`onboarding-trial_menu-step4-list-button_check_it_out`}
				</ActionButton>
			</CardActions>
		</>
	);
}

function EndOfTrialList({ setTrialSeen = () => {} }) {
	const { datasource } = useDataSourceContext();
	const onboarding = useOnboardingContext();
	const { t } = useTranslation();
	const classes = useStyles();

	const surveyId = datasource.forms.asArray[0]?.id;

	const listOptions = [
		{
			subject: 'direct_insights',
			to: `/reporting/direct-insights/${surveyId}`,
			dataTrackEvent: 'onboarding_trial_step_feedback_inbox_insights',
			icon: <BarChartIcon />,
		},
		{
			subject: 'colleague',
			to: `/application/users`,
			dataTrackEvent: 'onboarding_trial_step_feedback_inbox_users',
			icon: <GroupAddIcon />,
		},
		{
			subject: 'integrations',
			to: `/integrations/webhooks`,
			dataTrackEvent: 'onboarding_trial_step_feedback_inbox_integrations',
			icon: <CallMergeIcon />,
		},
		{
			subject: 'dashboards',
			to: `/reporting/dashboard/list`,
			dataTrackEvent: 'onboarding_trial_step_feedback_inbox_dashboard',
			icon: <DashboardIcon />,
		},
	];

	return (
		<>
			<CardContent>
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						xs={12}
					>
						<Trans
							i18nKey="onboarding-trial_menu-step5-list-text"
							components={{
								h6: (
									<Typography
										variant="h6"
										gutterBottom
									/>
								),
								p: <Typography variant="body1" />,
							}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<List disablePadding>
							{listOptions.map((option, index) => {
								return (
									<ListOption
										key={index}
										subject={option.subject}
										to={option.to}
										dataTrackEvent={option.dataTrackEvent}
										icon={option.icon}
									/>
								);
							})}
						</List>
					</Grid>
				</Grid>
			</CardContent>

			<CardActions className={classes.cardActions}>
				<ActionButton
					color="primary"
					className={classes.marginLeft}
					onClick={() => {
						onboarding.dispatch({
							type: 'set_trial_step',
							payload: {
								step: -1,
								url: '',
							},
						});
					}}
					data-track-event="onboarding_trial_step_feedback_inbox_done"
					data-test-element="onboarding_trial_step_feedback_inbox_done_button"
				>
					{t`onboarding-trial_menu-step5-list-button_own_path`}
				</ActionButton>
			</CardActions>
		</>
	);
}

function ListOption({ subject, to, dataTrackEvent, icon }) {
	const onboarding = useOnboardingContext();
	const history = useHistory();
	const { t } = useTranslation();

	return (
		<ListItem
			button
			onClick={() => {
				onboarding.dispatch({
					type: 'set_trial_step',
					payload: {
						step: -1,
						url: '',
					},
				});
				history.push(to);
			}}
			data-track-event={dataTrackEvent}
		>
			<ListItemIcon>{icon}</ListItemIcon>
			<ListItemText
				primary={t(`onboarding-trial_menu-step5-list-button_${subject}`)}
				secondary={t(`onboarding-trial_menu-step5-list-button_subtitle_${subject}`)}
				primaryTypographyProps={{ variant: 'body1' }}
				secondaryTypographyProps={{ variant: 'body2' }}
			/>
		</ListItem>
	);
}
