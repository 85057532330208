import React, { Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';

import SurveyIcon from '@material-ui/icons/Assignment';
import DataSetIcon from '@material-ui/icons/ViewComfy';
import IntegrationIcon from '@material-ui/icons/Input';

import DataFieldIcon from './DataFieldIcon';
import { OptionalDraggable } from '../Layout';

import { useTranslation } from 'react-i18next';

const isFeedbackType = [9, 31, 32, 35, 36, 37];

const styles = theme => ({
	indicator: {
		position: 'absolute',
		right: 8,
		bottom: 8,
		width: 6,
		height: 6,
		borderRadius: 3,
	},
	dragging: {
		backgroundColor: '#fff',
		boxShadow: theme.shadows[7],
	},
	inactive: {
		opacity: 0.5,
	},
});

const DataFieldItem = ({
	inset,
	button,
	type = '',
	title = '',
	isDrag,
	id = 0,
	index = 0,
	field = {},
	sourceId = '',
	children,
	selected,
	indicators = [],
	scaleInAnimation,
	onSelect = () => {},
	classes,
	xs,
	action,
	dataTestElement,
	allowedVarTypes,
	...props
}) => {
	const { t } = useTranslation();

	const textProps = {
		primary: title ? title : null,
		secondary: type ? t('Type: ') + type : null,
		...(xs && {
			primaryTypographyProps: {
				variant: 'caption',
			},
			secondaryTypographyProps: {
				variant: 'caption',
			},
		}),
	};

	const fieldIsAllowedVarType =
		!allowedVarTypes || allowedVarTypes.indexOf(field.var_type) > -1;

	return (
		<React.Fragment>
			<OptionalDraggable
				draggable={isDrag && fieldIsAllowedVarType}
				draggableId={JSON.stringify(field)}
				index={index}
				cancelTransform
			>
				{({ snapshot, provided }) => (
					<List
						{...props}
						disablePadding={true}
					>
						<ListItem
							button={button}
							disabled={!fieldIsAllowedVarType}
							onClick={e => onSelect(field, e)}
							dense
							data-test-element={dataTestElement}
							selected={selected}
							className={`${snapshot.isDragging ? classes.dragging : ''} ${
								!field.active ? classes.inactive : ''
							}`}
						>
							{indicators.map((serie, index) => {
								return (
									<div
										className={classes.indicator}
										style={{ backgroundColor: serie.color, right: 16 + index * 8 }}
										key={serie.color}
									/>
								);
							})}
							<ListItemIcon>
								<DataFieldIcon {...field} />
							</ListItemIcon>
							<ListItemText
								primary={title ? title : null}
								secondary={
									!field.active ? (
										<Fragment>
											{t`This field is no longer active.`}{' '}
											{type ? `(${t`Type:`} ${type})` : ''}
										</Fragment>
									) : type ? (
										t('Type: ') + type
									) : null
								}
							/>
							{action && <ListItemSecondaryAction>{action}</ListItemSecondaryAction>}
						</ListItem>
					</List>
				)}
			</OptionalDraggable>
		</React.Fragment>
	);
};

export default withStyles(styles)(DataFieldItem);
