import React from 'react';

import { Trans } from 'react-i18next';

import { ReactIcon, AndroidIcon, AppleIcon } from '@/components/Icons';
import { Box, Tooltip, Link, Chip } from '@/components/Layout';

const sdkComponents = {
	react: ReactSdk,
	android: AndroidSdk,
	ios: IosSdk,
};

function ReactSdk() {
	return (
		<Tooltip
			interactive
			title={
				<Trans
					i18nKey="data_collection-survey_builder-available_sdks-react_native_tooltip"
					components={{
						getitLink: (
							<Link
								color="inherit"
								href="https://www.npmjs.com/package/mopinion-react-native-sdk"
								target="_blank"
							/>
						),
					}}
				/>
			}
		>
			<span>
				<ReactIcon fontSize="small" />
			</span>
		</Tooltip>
	);
}

function AndroidSdk() {
	return (
		<Tooltip
			interactive
			title={
				<Trans
					i18nKey="data_collection-survey_builder-available_sdks-android_tooltip"
					components={{
						getitLink: (
							<Link
								color="inherit"
								href="https://github.com/Mopinion-com/mopinion-sdk-android"
								target="_blank"
							/>
						),
					}}
				/>
			}
		>
			<span>
				<AndroidIcon fontSize="small" />
			</span>
		</Tooltip>
	);
}

function IosSdk() {
	return (
		<Tooltip
			interactive
			title={
				<Trans
					i18nKey="data_collection-survey_builder-available_sdks-ios_tooltip"
					components={{
						getitLink: (
							<Link
								color="inherit"
								href="https://github.com/Mopinion-com/mopinion-sdk-ios"
								target="_blank"
							/>
						),
					}}
				/>
			}
		>
			<span>
				<AppleIcon fontSize="small" />
			</span>
		</Tooltip>
	);
}

export default function AvailableSdks({ className = '', sdks = [] }) {
	return (
		<Chip
			className={className}
			size="small"
			variant="outlined"
			label={
				<Box
					display="flex"
					alignItems="center"
				>
					{sdks.map((sdk, index) => {
						const Component = sdkComponents[sdk];

						if (!Component) {
							return null;
						}

						return (
							<Box
								key={sdk}
								display="inline-block"
								ml={index !== 0 ? 1 : 0}
							>
								<Component />
							</Box>
						);
					})}
				</Box>
			}
		/>
	);
}
