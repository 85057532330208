import React from 'react';
import { faReact } from '@fortawesome/free-brands-svg-icons';
import FaIcon from './FaIcon';

export default function ReactIcon(props) {
	return (
		<FaIcon
			icon={faReact}
			{...props}
		/>
	);
}
