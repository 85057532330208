import React, { useState } from 'react';

import { useTranslation, Trans } from 'react-i18next';

import { makeStyles } from '@/styles';
import { useAppContext } from '@/components/AppContext';
import { Explainer } from '@/components/Explainer';
import {
	ActionButton,
	Alert,
	Box,
	Card,
	CardContent,
	ContactDialog,
	Grid,
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	ListItemIcon,
	Typography,
} from '@/components/Layout';

import {
	products,
	subProducts,
	registerDomainProducts,
	perMonthProducts,
} from './constants';

import ParentProductIcon from './ParentProductIcon';
import ProductIcon from './ProductIcon';
import RemainingDaysProgress from './RemainingDaysProgress';
import AllowedDomainsDialog from './AllowedDomainsDialog';
import ProductTrialChip from './ProductTrialChip';

const useStyles = makeStyles(theme => ({
	progressWrap: {
		position: 'relative',
	},
	bgProgress: {
		color: theme.palette.grey[300],
		position: 'absolute',
		top: 0,
	},
	frontProgress: {
		zIndex: 1,
	},
	explainerLink: {
		marginTop: theme.spacing(2),
	},
	alignRight: {
		textAlign: 'right',
	},
	productGrid: {
		maxWidth: 1040,
	},
	registerNewButton: {
		display: 'block',
		marginTop: theme.spacing(1),
	},
	viewDomainButton: {
		marginLeft: theme.spacing(1),
	},
	parentProductListItemText: {
		fontWeight: theme.typography.fontWeightMedium,
	},
}));

export default function PackageOverviewCard({ setUpgrade = () => {} }) {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const classes = useStyles();
	const [contactForUpgrade, setContactForUpgrade] = useState(false);
	const [allowedDomainDialog, setAllowedDomainDialog] = useState('');

	return (
		<>
			{app.package.is_stripe_customer && !app.package.tiered_pricing && (
				<Alert title={t`package_overview_card-alert-title`}>
					{t`package_overview_card-alert-content`}
				</Alert>
			)}
			<Card>
				<CardContent>
					<Grid container>
						<Grid
							item
							md={6}
							sm={6}
							xs={12}
						>
							<Typography variant="h5">
								{t(`package_overview_card-title`, { package: app.package.name })}
							</Typography>

							<Box
								mt={3}
								display="flex"
								alignItems="center"
								mb={3}
							>
								<RemainingDaysProgress
									start={app.package.period.start}
									end={app.package.period.end}
									totalRemaining={app.package.period.totalRemaining}
								/>
								<Box ml={2}>
									<Typography>
										<Trans
											i18nKey="package_overview_card-days_remaining"
											values={{
												remaining: app.package.period.totalRemaining,
											}}
											components={{
												strong: <strong />,
											}}
										/>
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid
							item
							md={6}
							sm={6}
							xs={12}
							className={classes.alignRight}
						>
							<div>
								{app.package.is_stripe_customer && (
									<ActionButton
										action="upgrade"
										color="primary"
										onClick={app.package.trial ? null : () => setUpgrade(true)}
										to={app.package.trial ? '/account/billing/plans' : null}
									>
										{t`package_overview_card-upgrade_button`}
									</ActionButton>
								)}
								{!app.package.is_stripe_customer && (
									<ActionButton
										action="upgrade"
										color="primary"
										onClick={() => setContactForUpgrade(true)}
									>
										{t`package_overview_card-upgrade_button`}
									</ActionButton>
								)}
							</div>

							<Explainer
								title={t`package_overview_card-explainer-title`}
								content={
									<>
										<Typography
											gutterBottom
										>{t`package_overview_card-explainer-content1`}</Typography>
										<Typography>{t`package_overview_card-explainer-content2`}</Typography>
									</>
								}
								className={classes.explainerLink}
							>
								{t`package_overview_card-explainer-link`}
							</Explainer>
						</Grid>
					</Grid>

					<Grid
						container
						className={classes.productGrid}
					>
						{products.map(product => {
							const productInPlan = subProducts[product]?.every(
								subProduct => app.package[subProduct] > 0
							);
							const daysLeftInProductTrial = app.api.packageProductTrialDaysLeft(product);

							return (
								<Grid
									item
									xs={12}
									sm={4}
									key={product}
								>
									<List>
										<ListItem>
											<ListItemAvatar>
												<ParentProductIcon product={product} />
											</ListItemAvatar>
											<ListItemText
												primary={
													<>
														{t(`package_overview_card-products_${product}`)}
														{daysLeftInProductTrial > 0 && (
															<ProductTrialChip
																daysLeftInProductTrial={daysLeftInProductTrial}
																inline
															/>
														)}
													</>
												}
												primaryTypographyProps={{
													className: classes.parentProductListItemText,
												}}
											/>
										</ListItem>
										{!productInPlan && (
											<Box
												textAlign="center"
												p={2}
											>
												<Box
													mb={2}
													clone
												>
													<Typography>
														{t(`package_overview_card-product_not_in_package_${product}`)}
													</Typography>
												</Box>
												<ActionButton
													variant="outlined"
													color="primary"
													onClick={() => {
														if (app.package.is_stripe_customer) {
															setUpgrade(true);
														} else {
															setContactForUpgrade(true);
														}
													}}
													fullWidth
												>
													{t`package_overview_card-products_product_not_in_package_add_button`}
												</ActionButton>
											</Box>
										)}
										{productInPlan &&
											subProducts[product].map(subProduct => {
												const registerDomainProduct =
													registerDomainProducts.includes(subProduct);
												const needsToRegisterDomains =
													app.package.allowed_domains?.filter(
														domain => domain.type === subProduct
													).length < app.package[subProduct];

												return (
													<ListItem key={subProduct}>
														<ListItemIcon>
															<ProductIcon product={subProduct} />
														</ListItemIcon>
														<ListItemText
															primary={
																!registerDomainProduct ? (
																	t(`package_overview_card-products_${subProduct}`, {
																		count: Number(app.package[subProduct]),
																		value: app.package[subProduct].toLocaleString(),
																	})
																) : (
																	<>
																		{t(`package_overview_card-products_${subProduct}`, {
																			count: Number(app.package[subProduct]),
																			value: app.package[subProduct].toLocaleString(),
																		})}
																		{needsToRegisterDomains && (
																			<ActionButton
																				variant="contained"
																				color="primary"
																				className={classes.registerNewButton}
																				onClick={() => setAllowedDomainDialog(subProduct)}
																			>
																				{t(
																					`package_overview_card-products-register_button_${subProduct}`
																				)}
																			</ActionButton>
																		)}
																		{!needsToRegisterDomains && (
																			<ActionButton
																				color="primary"
																				className={classes.viewDomainButton}
																				onClick={() => setAllowedDomainDialog(subProduct)}
																			>
																				{t(
																					`package_overview_card-products-view_button_${subProduct}`
																				)}
																			</ActionButton>
																		)}
																	</>
																)
															}
															secondary={
																perMonthProducts.includes(subProduct)
																	? t`package_overview_card-per_month`
																	: null
															}
														/>
													</ListItem>
												);
											})}
									</List>
								</Grid>
							);
						})}
					</Grid>
				</CardContent>
			</Card>

			<ContactDialog
				open={contactForUpgrade}
				onClose={() => setContactForUpgrade(false)}
				title={t`package_overview_card-contact_dialog-title`}
				text={t`package_overview_card-contact_dialog-text`}
				subject={t`package_overview_card-contact_dialog-subject`}
				mailTo="success@mopinion.com"
			/>

			<AllowedDomainsDialog
				open={Boolean(allowedDomainDialog)}
				onClose={() => setAllowedDomainDialog('')}
				product={allowedDomainDialog}
			/>
		</>
	);
}
