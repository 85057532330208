import React, { Fragment, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import RenderChart from './RenderChart';
import Grid from '@material-ui/core/Grid';
import { chartWidget } from './chartUtils';
import { defaultSimpleChartOptions, allowedChartTypes } from './defaultChartOptions';
import { ucFirst } from '../../utils';
import { SelectionCard } from '../Layout';
import { useTranslation } from 'react-i18next';
import get from 'lodash.get';

const useStyles = makeStyles(theme => ({
	wrapperDiv: {
		//height:'100%'
		flexGrow: 1,
		padding: theme.spacing(1),
	},
	seriesBlock: {
		// borderTop: '1px solid '+theme.palette.divider,
		// borderBottom: '1px solid '+theme.palette.divider,
		marginBottom: theme.spacing(3),
	},
	titleSpacing: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	chartWrap: {
		overflow: 'hidden',
		padding: theme.spacing(1),
	},
}));

export default function ChartEditorChartTypes({
	children,
	setChartType = () => {},
	currentChartType,
	...props
}) {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<div className={classes.wrapperDiv}>
			<Typography
				variant="subtitle2"
				className={classes.titleSpacing}
			>
				{t('Chart types')}
			</Typography>
			<Fragment>
				<Grid
					container
					spacing={2}
				>
					{allowedChartTypes.map((chartType, chartIndex) => {
						let options = {
							...defaultSimpleChartOptions,
							chart: {
								...defaultSimpleChartOptions.chart,
								type: chartType,
							},
						};
						if (chartType === 'score') {
							options = chartWidget(
								options,
								options.series,
								{},
								get(options.series, '[0].data[0]')
							);
						}

						return (
							<Grid
								item
								xs={6}
								key={chartType}
							>
								<SelectionCard
									onClick={e => setChartType(chartType)}
									selected={chartType === currentChartType}
									outerContent={
										<div className={classes.chartWrap}>
											<RenderChart
												options={options}
												chartType={chartType !== 'score' ? 1 : 2}
											/>
										</div>
									}
								>
									<Typography variant="subtitle2">{t(ucFirst(chartType))}</Typography>
								</SelectionCard>
							</Grid>
						);
					})}
				</Grid>
			</Fragment>
		</div>
	);
}
