import React, { useState } from 'react';

import { useTranslation, Trans } from 'react-i18next';

import { ucFirst } from '@/utils';

import { useAppContext } from '@/components/AppContext';
import { SimpleDialog, Typography } from '@/components/Layout';
import usePostStartChannelTrial from './usePostStartChannelTrial';

export default function UpsellDialog({ open, onClose, product, ...props }) {
	const { t } = useTranslation();
	const { app } = useAppContext();

	const [requested, setRequested] = useState(false);

	const { postForm, loading } = usePostStartChannelTrial({
		data: {
			product,
		},
		onSuccess: r => {
			setRequested(true);
		},
	});

	return (
		<SimpleDialog
			open={open}
			onClose={() => {
				if (requested) {
					app.api.getPackage();
				}
				onClose();
			}}
			title={
				!requested
					? t(
							`data_collection-feedback_forms-editor-channel_upgrade-dialog-title_${product}`
					  )
					: t`data_collection-feedback_forms-editor-channel_upgrade-dialog-title_thanks`
			}
			submit={
				!requested
					? t`data_collection-feedback_forms-editor-channel_upgrade-dialog-button_submit`
					: null
			}
			cancel={
				requested
					? t`data_collection-feedback_forms-editor-channel_upgrade-dialog-button_close`
					: t`data_collection-feedback_forms-editor-channel_upgrade-dialog-button_cancel`
			}
			onSubmit={postForm}
			loading={loading}
			dataTrackEvent={`channel_trial_${product}_requested`}
		>
			{!requested && (
				<Typography>
					<Trans
						i18nKey={`data_collection-feedback_forms-editor-channel_upgrade-dialog-content_${product}`}
						components={{
							strong: <strong />,
							p1: <Typography mb={2} />,
							p2: <Typography />,
						}}
					/>
				</Typography>
			)}

			{requested && (
				<Typography>
					{t(
						`data_collection-feedback_forms-editor-channel_upgrade-dialog-content_thanks`,
						{ product: product === 'sdk' ? 'App' : ucFirst(product) }
					)}
				</Typography>
			)}
		</SimpleDialog>
	);
}
