import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CheckboxIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<path
				fill="currentColor"
				id="XMLID_12_"
				d="M17,4.4h20v1.7H17V4.4z M17,7.9h12.5v1.7H17V7.9z M17,17.3h20V19H17V17.3z M17,20.7h12.5v1.7H17V20.7z
				 M17,30.4h20v1.7H17V30.4z M17,33.9h12.5v1.7H17V33.9z M12.9,29.1v7.8H5.1v-7.8H12.9 M12.9,28H5.1C4.5,28,4,28.5,4,29.1v7.8
				C4,37.5,4.5,38,5.1,38h7.8c0.6,0,1.1-0.5,1.1-1.1v-7.8C14,28.5,13.5,28,12.9,28z M12.9,15H5.1C4.5,15,4,15.5,4,16.1v7.8
				C4,24.5,4.5,25,5.1,25h7.8c0.6,0,1.1-0.5,1.1-1.1v-7.8C14,15.5,13.5,15,12.9,15z M7.9,22.8L5.1,20l0.8-0.8l2,2l4.2-4.2l0.8,0.8
				L7.9,22.8z M12.9,2H5.1C4.5,2,4,2.5,4,3.1v7.8C4,11.5,4.5,12,5.1,12h7.8c0.6,0,1.1-0.5,1.1-1.1V3.1C14,2.5,13.5,2,12.9,2z M7.9,9.8
				L5.1,7l0.8-0.8l2,2L12.1,4l0.8,0.8L7.9,9.8z"
			/>
		</SvgIcon>
	);
}
