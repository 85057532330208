import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { DataFieldIcon, useDataSourceContext } from '../DataSources';

const useStyles = makeStyles(theme => ({
	avatar: {
		backgroundColor: props => props.color,
	},
	icon: {
		color: props => {
			try {
				return theme.palette.getContrastText(props.color);
			} catch (e) {
				return '';
			}
		},
	},
}));

function ActiveSeriesIcon({ serie, forceColor, ...props }) {
	const classes = useStyles(forceColor ? { color: forceColor } : serie);
	const { datasource } = useDataSourceContext();
	const field = datasource.api.getField(serie.y);
	return (
		<Avatar className={`${classes.avatar} ${props.classes}`}>
			<DataFieldIcon
				{...field}
				className={classes.icon}
			/>
		</Avatar>
	);
}

export default ActiveSeriesIcon;
