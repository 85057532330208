import get from 'lodash.get';

export function shouldComponentReload(
	oldApp = {},
	newApp = {},
	testArray = [
		'date.fromDate',
		'date.toDate',
		'projects.current.id',
		'organisations.current.org_id',
		'roles.current.id',
	]
) {
	return testArray.some(item => get(oldApp, item) !== get(newApp, item));
}

export function getInitials(firstname = '', lastname = '') {
	const letter = (str, atIndex = 0) =>
		typeof str === 'string' && str.length >= atIndex + 1
			? str.charAt(atIndex).toUpperCase()
			: '';
	return `${letter(firstname)}${lastname ? letter(lastname) : letter(firstname, 1)}`;
}

export function makeKeyedObject(array, key = 'id') {
	return array.reduce((all, current) => {
		all[current[key]] = current;
		return all;
	}, {});
}

export function objectToArray(obj) {
	return Object.keys(obj).reduce((all, key) => {
		all.push(obj[key]);
		return all;
	}, []);
}
//system calls require a string formatted as yyyy mm dd
export function reverseDate(date = '', separator = '', joiner) {
	return date
		.split(separator)
		.reverse()
		.join(joiner ? joiner : separator);
}

//New date requires an array formatted in 'reverse' yyyy mm dd
export function makeDateObject(dateStr = '', separator = '') {
	const dateArr = dateStr.split(separator).reverse();
	dateArr[1] = Number(dateArr[1]) - 1;
	return new Date(...dateArr);
}

export function operators({ var_type, withoutContains, withNot } = {}) {
	const numericTypes = [4, 12, 16, 24, 25, 27, 30, 38];
	const numericOperators = ['l', 'le', 'e', 'ge', 'g'];

	const generalOperators = [
		'e',
		...(!withoutContains ? ['contains'] : []),
		...(withNot ? ['not'] : []),
	];

	const textTypes = [13];
	const textOperators = ['empty', 'filled'];

	if (numericTypes.indexOf(Number(var_type)) > -1) {
		return numericOperators;
	} else {
		return textTypes.indexOf(Number(var_type)) > -1
			? [...generalOperators, ...textOperators]
			: withoutContains
			? generalOperators.slice(1, 2)
			: generalOperators;
	}
}
