import { theme, getDefaultChartColors } from '@/styles';

export const allowedChartTypes = [
	'score',
	'line',
	'area',
	'areaspline',
	'bar',
	'column',
	'pie',
];

export const defaultChartOptions = {
	colors: getDefaultChartColors(),
	chart: {
		style: {
			fontFamily: [
				'Open Sans',
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(', '),
		},
	},
	credits: {
		enabled: false,
	},
	title: {
		align: 'left',
		verticalAlign: 'top',
		style: {
			fontWeight: '400',
			color: theme.palette.grey[900],
			display: 'none',
		},
	},
	subtitle: {
		align: 'left',
		verticalAlign: 'top',
		style: {
			fontWeight: '600',
			color: theme.palette.grey[600],
			textTransform: 'uppercase',
			fontSize: '11px',
			display: 'none',
		},
	},
	legend: {
		borderWidth: 0,
		marginTop: 30,
		symbolHeight: 12,
		symbolWidth: 12,
		symbolRadius: 6,
		itemMarginBottom: 5,
		itemStyle: {
			fontWeight: '600',
			fontSize: '11px',
			color: theme.palette.grey[800],
		},
	},
	xAxis: {
		lineColor: theme.palette.grey[200],
		gridLineColor: theme.palette.grey[100],
		labels: {
			style: {
				fontWeight: '600',
				wordBreak: 'break-all',
			},
			formatter() {
				return this.value.length > 50 ? `${this.value.substring(0, 50)}...` : this.value;
			},
		},
	},
	yAxis: {
		lineColor: theme.palette.grey[200],
		gridLineColor: theme.palette.grey[100],
		labels: {
			style: {
				fontWeight: '600',
			},
		},
	},
	plotOptions: {
		series: {
			stickyTracking: false,
			dataLabels: {
				style: {
					fontWeight: '600',
					textShadow: 'none',
				},
			},
		},
		pie: {
			tooltip: {
				followPointer: false,
			},
		},
	},
	tooltip: {
		backgroundColor: '#ffffff',
		borderColor: theme.palette.grey[50],
		borderWidth: 1,
		borderRadius: 2,
		shadow: true,
		style: {
			fontFamily: [
				'Open Sans',
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(', '),
			padding: '10px',
			fontWeight: 300,
			color: theme.palette.grey[900],
			fontSize: '12px',
			lineHeight: '20px',
			pointerEvents: 'auto',
			zIndex: 9999,
			stickOnContact: true,
			snap: 5,
			stickyTracking: false,
		},
		hideDelay: 225,
		stickOnContact: true,
		useHTML: true,
	},
	exporting: {
		chartOptions: {
			title: {
				style: {
					display: 'block',
				},
			},
			subtitle: {
				style: {
					display: 'block',
				},
			},
		},
		enabled: false,
		fallbackToExportServer: false,
	},
	//['#62c462', '#b94a48', '#2f96b4', '#0044cc', '#f89406', '#51a351', '#ee5f5b', '#3a87ad',  '#5bc0de', '#999999']
};

export const defaultSimpleChartOptions = {
	chart: {
		margin: [0, 0, 0, 0],
		backgroundColor: 'transparent',
		height: 150,
	},
	title: {
		style: {
			display: 'none',
		},
	},
	subtitle: {
		style: {
			display: 'none',
		},
	},
	credits: {
		enabled: false,
	},
	xAxis: {
		visible: false,
		labels: {
			enabled: false,
		},
		crosshair: false,
	},
	yAxis: {
		visible: false,
		labels: {
			enabled: false,
		},
	},
	legend: {
		enabled: false,
	},
	tooltip: {
		enabled: false,
	},
	colors: ['#00838f', '#1976d2', '#263238'],
	plotOptions: {
		series: {
			enableMouseTracking: false,
			stickyTracking: false,
			lineWidth: 1,
			shadow: false,
			dataLabels: {
				enabled: false,
			},
		},
		line: {
			color: '#00BCD4',
		},
		area: {
			color: '#78909c',
		},
		areaspline: {
			color: '#d81b60',
		},
		bar: {
			color: '#ef6c00',
		},
		column: {
			color: '#673ab7',
		},
		pie: {
			colorByPoint: true,
		},
	},
	series: [{ data: [3, 8, 10, 15, 4, 8] }],
};

export const defaultDataExplorerChartOptions = {
	subtitle: {
		text: 'Chart subtitle',
		style: {},
	},
	title: {
		text: 'Chart title',
		style: {},
	},
	series: [],
	yAxis: {
		labels: {
			enabled: true,
		},
		type: 'linear',
		title: {
			text: '',
			style: {},
		},
	},
	xAxis: {
		labels: {
			enabled: true,
		},
		title: {
			text: '',
			style: {},
		},
	},
	colors: [],
	tooltip: {
		enabled: true,
	},
	chart: {
		animation: true,
		type: 'line',
	},
	credits: {
		enabled: false,
	},
	plotOptions: {
		series: {
			dataLabels: {
				enabled: false,
			},
			animation: true,
			showInLegend: true,
			allowPointSelect: false,
		},
	},
	exporting: {
		enabled: false,
	},
	legend: {
		enabled: true,
		align: 'center',
		verticalAlign: 'bottom',
	},
};

export const defaultInsightsChartOptions = {
	xAxis: {
		labels: {
			enabled: true,
		},
		allowDecimals: false,
		title: {
			text: '',
			style: [],
		},
		categories: [],
	},
	yAxis: {
		labels: {
			enabled: true,
		},
		allowDecimals: false,
		type: 'line',
		title: {
			text: '',
			style: {},
		},
	},
	exporting: {
		enabled: false,
	},
	legend: {
		enabled: true,
		align: 'center',
		verticalAlign: 'bottom',
	},
	tooltip: {
		backgroundColor: '#ffffff',
		borderColor: theme.palette.grey[50],
		borderWidth: 1,
		borderRadius: 2,
		shadow: true,
		style: {
			padding: '10px',
			fontWeight: 300,
			color: theme.palette.grey[900],
			fontSize: '12px',
			lineHeight: '20px',
			pointerEvents: 'auto',
			zIndex: 9999,
			stickOnContact: true,
			snap: 5,
			stickyTracking: false,
		},
		hideDelay: 225,
		stickOnContact: true,
		useHTML: true,
	},
	plotOptions: {
		series: {
			dataLabels: {
				enabled: true,
			},
			animation: true,
			showInLegend: true,
			allowPointSelect: false,
			stacking: '',
			colorByPoint: false,
		},
	},
};
