import React, { Fragment, useEffect } from 'react';

import Button from '@material-ui/core/Button';

import { withAppContext } from '../AppContext';
import { EmptyState } from '../Layout';
import { EFM } from '../../api';
import { useEffectSkipInitialRender } from '../../hooks';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import ErrorIcon from '@material-ui/icons/SentimentVeryDissatisfied';

function giveFeedback() {
	try {
		window.srv.openModal();
	} catch (e) {}
}

function ErrorContent({ error, componentStack, resetError }) {
	const { t } = useTranslation();
	const location = useLocation();

	//log error inside snitch
	// useEffect(() => {
	//   let errorString = '';
	//   try {
	//     errorString = error.toString();
	//   } catch(e) {}

	//   if (errorString) {
	//     EFM.post('/application/ajax/snitch', {
	//       type:'raspberry_error',
	//       data: {
	//         error:errorString,
	//         componentStack
	//       }
	//     })
	//   }
	// }, [error, componentStack]);

	//Reset error when changing routes
	useEffectSkipInitialRender(() => {
		try {
			resetError();
		} catch (e) {}
	}, [location.pathname]);

	return (
		<EmptyState
			icon={ErrorIcon}
			primary={t`Something went wrong`}
			secondary={t`We've logged your error and will look into what caused it. If you'd like fill in our feedback form to provide extra context into what you were doing.`}
			action={
				<Fragment>
					<Button
						variant="contained"
						onClick={resetError}
					>
						{t`Retry page`}
					</Button>

					<Button onClick={giveFeedback}>{t`Open feedback form`}</Button>
				</Fragment>
			}
		/>
	);
}

export default function ErrorBoundary({ children, returnNullOnError, ...props }) {
	return (
		<Sentry.ErrorBoundary
			fallback={fallbackProps =>
				returnNullOnError ? null : <ErrorContent {...fallbackProps} />
			}
		>
			{children}
		</Sentry.ErrorBoundary>
	);
}
