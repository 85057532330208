import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Grid, ActionIconButton, Typography, RenderConditional } from '../Layout';

const useStyles = makeStyles(theme => ({
	base: {
		minWidth: 160,
	},
}));

export default function PriceTierPicker({
	selected: selectedFromProps,
	options = [0],
	min = 0,
	onChange = () => {},
	onMinMoreThan0Reached = () => {},
	onMaxReached = () => {},
}) {
	const { t } = useTranslation();
	const classes = useStyles();
	const [selected, setSelected] = useState(0);
	const isControlled = selectedFromProps !== undefined;
	const selectedValue = isControlled ? selectedFromProps : selected;

	function handleChange(value) {
		if (isControlled) {
			setSelected(value);
		}
		onChange(value);
	}

	const currentIndex = options.indexOf(selectedValue);
	const lessOption = options[currentIndex - 1] ?? 0;
	const moreOption = options[currentIndex + 1];

	function less() {
		if (lessOption !== undefined && selectedValue !== min) {
			handleChange(lessOption);
		}
	}

	function more() {
		if (moreOption !== undefined) {
			handleChange(moreOption);
		}

		if (moreOption === options.slice(-1)[0]) {
			onMaxReached();
		}
	}

	function handleMinMoreThan0() {
		if (min !== 0 && selectedValue === min) {
			onMinMoreThan0Reached(true);
		}
	}

	return (
		<Grid
			container
			spacing={1}
			alignItems="center"
			wrap="nowrap"
			className={classes.base}
		>
			<Grid
				item
				onClick={handleMinMoreThan0}
			>
				<RenderConditional
					title={t`price_tier_picker-min_reached_tooltip`}
					condition={min !== 0 && selectedValue === min}
				>
					<div>
						<ActionIconButton
							disabled={selectedValue === min}
							action="minus"
							onClick={less}
							size="small"
						/>
					</div>
				</RenderConditional>
			</Grid>
			<Grid
				item
				xs
			>
				<Typography
					fontWeight="medium"
					align="center"
				>
					{Number(selectedValue).toLocaleString()}
				</Typography>
			</Grid>

			<Grid item>
				<RenderConditional
					title={t`price_tier_picker-max_reached_tooltip`}
					condition={moreOption === undefined}
				>
					<div>
						<ActionIconButton
							disabled={moreOption === undefined}
							action="plus"
							onClick={more}
							size="small"
						/>
					</div>
				</RenderConditional>
			</Grid>
		</Grid>
	);
}
