import React from 'react';

import { useTranslation } from 'react-i18next';

import { useDataSourceContext } from '@/components/DataSources';
import { EmptyState, ActionButton } from '@/components/Layout';
import { useEFM } from '@/components/Ajax';
import {
	useHasPermission,
	NoPermissionTooltip,
	permissionSubjects,
	permissionModules,
} from '@/components/Permission';

export default function EmptyStateHandleHasFeedback({
	primary,
	secondary,
	action = null,
	primaryNoSurvey,
	secondaryNoSurvey,
	actionNoSurvey = null,
	primaryNoFeedback,
	secondaryNoFeedback,
	actionNoFeedback = null,
	noSurveyTrackEvent,
	...props
}) {
	const { t } = useTranslation();
	const [checkedProject] = useEFM(`/application/ajax/target-has-feedback`);
	const { datasource } = useDataSourceContext();
	const hasSurveyEditPermission = useHasPermission({
		module: permissionModules.data_collection,
		subject: permissionSubjects.feedback_forms_build,
	});

	const noSurveys = datasource.forms.asArray.length === 0;
	const noFeedback = !checkedProject.hasFeedback;
	const { image, ...filteredProps } = props;

	return (
		<EmptyState
			primary={
				noSurveys
					? primaryNoSurvey
					: actionNoFeedback && noFeedback
					? primaryNoFeedback
					: primary
			}
			secondary={
				noSurveys
					? secondaryNoSurvey
					: actionNoFeedback && noFeedback
					? secondaryNoFeedback
					: secondary
			}
			action={
				noSurveys ? (
					<NoPermissionTooltip
						module={permissionModules.data_collection}
						subject={permissionSubjects.feedback_forms_build}
					>
						<ActionButton
							variant="contained"
							color="primary"
							dataTrackEvent={noSurveyTrackEvent}
							link
							to="/data-collection/survey/edit/new"
							disabled={!hasSurveyEditPermission}
						>
							{t`app_general-empty_state-no_survey_button`}
						</ActionButton>
					</NoPermissionTooltip>
				) : actionNoFeedback && noFeedback ? (
					actionNoFeedback
				) : (
					action
				)
			}
			image={image ? image : null}
			{...filteredProps}
		/>
	);
}
