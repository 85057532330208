import React, { Fragment } from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';

import { useTranslation } from 'react-i18next';

import { useListFilterContext, FilterGroup, filterUtils } from '../FilteredLists';
import { DataSourceSelect } from '../Select';
import { useTagContext } from './TagContext';

export default function TagListFilters({ filteredTags }) {
	const { tags } = useTagContext();

	const mappedTags = [...new Set(filteredTags.tagList.concat(tags.tagList))].map(tag => {
		return {
			value: tag,
			label: tag,
		};
	});

	const { setSingleFilter, setMultiFilter, other, filters } = useListFilterContext();

	const { t } = useTranslation();

	return (
		<Fragment>
			<div>
				<TextField
					variant="filled"
					fullWidth
					placeholder={t('Search')}
					label={t('Search')}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search />
							</InputAdornment>
						),
					}}
					onChange={e => setSingleFilter('search', e.target.value)}
					value={filters.search ?? ''}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t`Feedback source`}
					noCollapse
					filterKey={'datasource'}
				>
					<DataSourceSelect
						value={other.survey_id ?? ''}
						emptyLabel={t`All sources`}
						onChange={e => setSingleFilter('survey_id', e.target.value, true)}
					/>
				</FilterGroup>
			</div>

			<div>
				<FilterGroup
					filterName={t('Tags')}
					filterKey={'tags'}
					customLabel={'tags'} //label in autocomplete, overwrite default filterKey
					filters={[...mappedTags].map(filter => {
						if ((filters.tags || []).indexOf(filter.value) > -1) {
							filter.selected = true;
						}
						return filter;
					})}
					multiple
					autoComplete={mappedTags}
					favorites={filteredTags.tagCount ?? {}}
					onChange={({ key, value }) => {
						setMultiFilter(key, value);
					}}
				/>
			</div>

			{/* 'created by' gives issue with user_id vs str_user_id, so we leave it out for now.
			<div>
				<FilterGroup
					filterName={t('Created by')}
					filterKey={'owner'}
					filters={[
						{label:t('Created by me'), value:'me'},
						{label:t('Created by others'), value:'other'}
					].map(filter => {
						if (filter.value === filters.owner) {
							filter.selected = true;
						}
						filter.key = 'owner'+filter.value;
						return filter
					})}
					onChange={({key,value}) => setSingleFilter(key, value)}
				/>
			</div> */}

			<div>
				<FilterGroup
					filterName={t('Channel')}
					filterKey={'channel'}
					filters={[
						{ label: t('Web'), value: 'web' },
						{ label: t('Email'), value: 'email' },
						{ label: t('In-app'), value: 'sdk' },
					].map(filter => {
						if ((filters.channel || []).indexOf(filter.value) > -1) {
							filter.selected = true;
						}
						filter.key = 'channel' + filter.value;
						return filter;
					})}
					multiple
					onChange={({ key, value }) => setMultiFilter(key, value)}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Type')}
					filterKey={'survey_format'}
					filters={[
						{ label: t('Standard'), value: 'form' },
						{ label: t('Conversational'), value: 'conversational' },
					].map(filter => {
						if (filter.value === filters.survey_format) {
							filter.selected = true;
						}
						filter.key = 'survey_format' + filter.value;
						return filter;
					})}
					onChange={({ key, value }) => setSingleFilter(key, value)}
				/>
			</div>
		</Fragment>
	);
}
