import React, { useEffect, useState, useRef } from 'react';

import { useAppContext } from '../AppContext';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';

//import { StripeProvider, Elements } from 'react-stripe-elements';
//const stripePromise = key => loadStripe(key);

export default function Stripe({ children, init, ...props }) {
	const [stripe, setStripe] = useState(null);
	const { app } = useAppContext();
	const didInit = useRef(false);

	useEffect(() => {
		if (app.tokens.payment && init && !didInit.current) {
			setStripe(loadStripe(app.tokens.payment));
			didInit.current = true;
		}
	}, [app.tokens.payment, init]);

	return (
		<Elements
			stripe={stripe}
			fonts={[
				{
					cssSrc:
						'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap',
				},
			]}
			locale={app.organisations.current.country_code}
		>
			{children}
		</Elements>
	);
}
