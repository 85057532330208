import { rnd } from '../../utils';

export function ruleBase({ survey_key = '', survey_id = '', user_id = '' } = {}) {
	return {
		survey_key,
		survey_id,
		id: rnd(),
		add: [addBase(user_id)],
	};
}

export function addBase({ user_id = '' }) {
	return {
		tags: [],
		conditions: [conditionBase()],
		user_id,
		retroactive: false,
	};
}

export function conditionBase() {
	return {
		datafield_id: '',
		datafield_label: '',
		operator: 'e',
		value: [],
		value_label: '',
	};
}

export function operators(field = {}) {
	const numericTypes = [4, 12, 16, 24, 25, 27, 30, 38];
	const numericOperators = ['l', 'le', 'e', 'ge', 'g', 'contains', 'not'];

	const generalOperators = ['e', 'contains', 'not'];

	const textTypes = [13];
	const textOperators = ['empty', 'filled'];

	if (numericTypes.indexOf(Number(field.var_type)) > -1) {
		return numericOperators;
	} else {
		return textTypes.indexOf(Number(field.var_type)) > -1
			? [...generalOperators, ...textOperators]
			: generalOperators;
	}
}

function addOr(arr = [], index = 0, replaceWith) {
	if (arr.length === index + 2) {
		return ` ${replaceWith} `;
	}
	if (arr.length > index + 2) {
		return ', ';
	}
	return '';
}

function maxStringLength(value, length) {
	return value.length > length ? `${value.slice(0, length)}...` : value;
}

export function parseConditionValues(field, value, replaceWith = 'or') {
	if (!Array.isArray(value) || !field) {
		return value;
	}

	const { properties } = field?.survey_block ?? {};

	//Checkbox var type (map __true/__false)
	if (String(field.niceType) === 'Checkbox') {
		return [...value]
			.map(
				(char, index) =>
					`${char === '____true' ? 'selected' : 'not selected'}${addOr(
						value,
						index,
						replaceWith
					)}`
			)
			.join('');
	}
	//CES var type
	if (String(field.var_type) === '16' && properties.elements) {
		return [...value]
			.map(
				(char, index) =>
					`${properties.elements?.[char]?.label}${addOr(value, index, replaceWith)}`
			)
			.join('');
	}
	//GCR var type
	if (String(field.var_type) === '30' && properties.elements) {
		return [...value]
			.map(
				(char, index) =>
					`${
						Object.values(properties.elements).find(el => el.value === char)?.label
					}${addOr(value, index, replaceWith)}`
			)
			.join('');
	}
	return [...value]
		.map(
			(char, index) => `${maxStringLength(char, 50)}${addOr(value, index, replaceWith)}`
		)
		.join('');
}
