export const drawerWidth = 240;

export const drawerWidthClosed = 72;

export const filterDrawerWidth = 280;

export const builderDrawerWidth = 480;

export const surveyBuilderDrawerWidth = 480;

export const deploymentEditorDrawerWidth = 560;

export const deploymentEditorDrawerWidthSm = 480;

export const filterDrawerBreakpoint = 960;

export const loginBrandingFooter = 450;

export const onboardingLeftMargin = 950;

export const onboardingLeftMarginSm = 560;
