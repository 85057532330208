import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ColorPicker from './ColorPicker';

const useStyles = makeStyles(theme => ({
	popper: {
		zIndex: theme.zIndex.modal + 1,
	},
	colorPicker: {
		boxShadow: 'none!important',
	},
}));

export default function ColorPickerWithPopper({
	open,
	color,
	anchorEl,
	onClose,
	onChange,
}) {
	const classes = useStyles();
	return (
		<Popper
			open={open}
			anchorEl={anchorEl}
			transition
			className={classes.popper}
		>
			{({ TransitionProps }) => (
				<Fade
					{...TransitionProps}
					timeout={350}
				>
					<ClickAwayListener onClickAway={onClose}>
						<Paper elevation={8}>
							<ColorPicker
								color={color}
								onChangeComplete={onChange}
								disableAlpha
								className={classes.colorPicker}
							/>
							<Button onClick={onClose}>OK</Button>
						</Paper>
					</ClickAwayListener>
				</Fade>
			)}
		</Popper>
	);
}
