import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import MoBotIcon from './MoBotIcon';

const useStyles = makeStyles(theme => ({
	avatar: {
		backgroundColor: theme.palette.mopinion.main,
	},
}));

export default function MoBotAvatar({ iconProps = {}, className = '', ...props }) {
	const classes = useStyles();
	return (
		<Avatar
			{...props}
			className={`${classes.avatar} ${className}`}
		>
			<MoBotIcon {...iconProps} />
		</Avatar>
	);
}
