import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	flexGrow: {
		display: 'flex',
		flexGrow: 1,
	},
	grow: {
		flexGrow: 1,
	},
	smallControl: {
		padding: theme.spacing(0.5),
	},
	smallLabel: {
		fontSize: theme.typography.pxToRem(13),
	},
	largeIcon: {
		'& svg': {
			height: '2em',
			width: '2em',
		},
	},
	smallDrawer: {
		width: '50%',
		marginRight: 0,
		marginLeft: 0,
		marginTop: theme.spacing(1),
	},
}));

export default function EditorListRadioGroup({
	grow,
	size = 'medium',
	fullWidth = true,
	name,
	title,
	value,
	onChange,
	options = [],
	labelPlacement = 'bottom',
	helperText,
	error,
	largeIcons,
	smallDrawer,
	disabled,
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<FormControl
			disabled={disabled}
			fullWidth={fullWidth}
			className={`${grow ? classes.flexGrow : ''}`}
		>
			{title && (
				<Typography
					variant="body1"
					display="block"
				>
					{title}
				</Typography>
			)}
			<RadioGroup
				name={name ? name : title}
				value={value}
				onChange={onChange}
				row
			>
				{options.map(option => {
					const RadioProps = {
						...(option.icon && { icon: option.icon }),
						...(option.checkedIcon && { checkedIcon: option.checkedIcon }),
						...(!option.checkedIcon && option.icon && { checkedIcon: option.icon }),
						...option.RadioProps,
					};

					return (
						<FormControlLabel
							key={option.value}
							value={option.value}
							control={
								<Radio
									className={size === 'small' ? classes.smallControl : ''}
									{...RadioProps}
								/>
							}
							label={t(option.label)}
							labelPlacement={labelPlacement}
							className={`${fullWidth || grow ? classes.grow : ''} ${
								largeIcons ? classes.largeIcon : ''
							} ${smallDrawer ? classes.smallDrawer : ''}`}
							classes={{
								label: `${size === 'small' ? classes.smallLabel : ''} `,
							}}
						/>
					);
				})}
			</RadioGroup>
			{helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
		</FormControl>
	);
}
