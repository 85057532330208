import React, { PureComponent, Fragment } from 'react';
/* 
Todo:
set Correct email in dialog "aan:"
Set correct avatars in dialog and list -> for this we probably need to fetch the agent details instead of just ids
*/
import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
//import ListItemIcon from '@material-ui/core/ListItemIcon';

import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { Translation } from 'react-i18next';

import ForwardIcon from '@material-ui/icons/Forward';
import ReplyIcon from '@material-ui/icons/Reply';

import { HTML } from '../Layout';

const styles = theme => ({
	dialogSubject: {
		marginBottom: theme.spacing(2),
	},
	ellipsis: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
});

class ContactWithCustomerCard extends PureComponent {
	static defaultProps = {
		isElevated: true,
		allowContact: true,
		customer: {
			private: false,
			anonymous: true,
			unknown: false,
			id: 86433,
			created: '',
			link: '',
			firstname: 'anonymous',
			lastname: 'anonymous',
			full_name: 'anonymous',
			email: 'test@test.com',
			details: [],
		},
		emails: [],
		feedbackId: false,
	};

	state = {
		mailObj: {},
		showMailDialog: false,
	};

	render() {
		const {
			classes,
			isElevated,
			customer: { firstname, lastname, full_name, email, details, created },
			emails,
			feedbackId,
		} = this.props;
		const { mailObj, showMailDialog } = this.state;

		let cardProps = !isElevated ? { elevation: 0, className: classes.borderedCard } : {};
		return (
			<Translation>
				{t => (
					<Fragment>
						<Card {...cardProps}>
							<CardHeader
								title={t('Email conversations')}
								titleTypographyProps={{ variant: 'subtitle2' }}
							/>
							<List>
								{emails.length === 0 ? (
									<ListItem>
										<ListItemText primary={t('No conversations')} />
									</ListItem>
								) : (
									emails
										.filter(mail => {
											if (feedbackId) {
												return mail.feedback_id == feedbackId;
											} else {
												return mail;
											}
										})
										.map(mail => {
											let sent = mail.comment;
											try {
												sent = JSON.parse(sent);
											} catch (e) {}
											let mailObj = Object.assign({}, mail, { comment: sent });

											return (
												<ListItem
													key={mail.comment}
													onClick={() => {
														this.setState(
															{
																mailObj: mailObj,
															},
															() => {
																this.setState({ showMailDialog: true });
															}
														);
													}}
													button
												>
													<ListItemAvatar>
														<Avatar>{mailObj?.email?.charAt(0)}</Avatar>
													</ListItemAvatar>
													<ListItemText
														primary={
															<Grid container>
																<Grid item>
																	{mailObj.name === 'Email reply' ? (
																		<ReplyIcon fontSize="small" />
																	) : (
																		<ForwardIcon fontSize="small" />
																	)}
																</Grid>
																<Grid
																	item
																	xs
																	className={classes.ellipsis}
																>
																	{t('To')}: {sent.to ? sent.to : email}
																</Grid>
																<Grid item>
																	<Typography
																		variant="caption"
																		color="textSecondary"
																	>
																		{mailObj.created}
																	</Typography>
																</Grid>
															</Grid>
														}
														primaryTypographyProps={{
															noWrap: true,
														}}
														secondary={sent.subject}
													/>
												</ListItem>
											);
										})
								)}
							</List>
						</Card>

						<Dialog
							open={showMailDialog}
							onClose={evt => this.setState({ showMailDialog: false })}
							maxWidth="sm"
							fullWidth
						>
							{Object.keys(mailObj).length ? (
								<Fragment>
									<List>
										<ListItem>
											<ListItemAvatar>
												<Avatar>{mailObj?.email?.charAt(0)}</Avatar>
											</ListItemAvatar>
											<ListItemText
												primary={mailObj.email}
												secondary={`${t('Aan')}: ${
													mailObj?.comment?.to ? mailObj.comment.to : email
												}${mailObj?.comment?.cc ? ', ' + mailObj.comment.cc : ''}`}
											/>
										</ListItem>
									</List>
									<DialogContent>
										<Typography
											variant="subtitle2"
											tag="div"
											className={classes.dialogSubject}
										>
											{mailObj.comment.subject}
										</Typography>
										<Typography
											variant="body1"
											component="div"
										>
											<HTML html={mailObj.comment.message} />
										</Typography>
									</DialogContent>
								</Fragment>
							) : null}
							<DialogActions>
								<Button
									onClick={e => this.setState({ showMailDialog: false })}
								>{t`Close`}</Button>
							</DialogActions>
						</Dialog>
					</Fragment>
				)}
			</Translation>
		);
	}
}

export default withStyles(styles)(ContactWithCustomerCard);
