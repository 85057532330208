import { differenceInDays } from '../../utils/date';
//import { make}

export function isExpired(deadlineDate) {
	const diff = differenceInDays(new Date(deadlineDate), new Date());
	return Math.sign(diff) === -1;
}

export function actionDue(deadlineDate) {
	const diff = differenceInDays(new Date(deadlineDate), new Date());
	return Number(String(diff).replace('-', ''));
}

export function getProgressColor(status_id, theme) {
	switch (Number(status_id)) {
		case 1:
			return theme.palette.grey[400];
		case 2:
			return theme.palette.primary.light;
		case 3:
			return theme.palette.secondary.light;
		case 4:
			return theme.palette.secondary.dark;
		case 5:
			return theme.palette.error.light;

		default:
			return theme.palette.grey[400];
	}
}

export function getStatusMessage(status_id) {
	switch (Number(status_id)) {
		case 1:
			return 'Action status changed to assigned';
		case 2:
			return 'Action status changed to accepted';
		case 3:
			return 'Action status changed to done';
		case 4:
			return 'Action status changed to completed';
		case 5:
			return 'Action status changed to declined';

		default:
			return '';
	}
}
