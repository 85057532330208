import React, { useState, useEffect, Fragment } from 'react';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CustomViewChip from './CustomViewChip';
import ListFilterContext from './ListFilterContext';
import { useListFilterContext, filterUtils } from '../FilteredLists';
import { showFilterAsAutoComplete, maxFilterFavorites } from './constants';
import { useSessionStorage } from '../../hooks';

import { withSnackbar } from 'notistack';

import { useTranslation } from 'react-i18next';

const styles = theme => ({
	chip: {
		margin: theme.spacing(0.25),
	},
	center: {
		display: 'flex',
		alignItems: 'center',
	},
	expandArea: {
		cursor: 'pointer',
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	marginBottom: {
		marginBottom: theme.spacing(1),
	},
	marginTop: {
		marginTop: theme.spacing(0.5),
	},
});

function FilterGroup({
	classes,
	filters = [],
	filterName = '',
	customFilterName,
	filterKey = '',
	custom,
	openFilterDrawer = () => {},
	enqueueSnackbar,
	listFilterContext,
	updateFilters,
	viewOnly = false,
	multiple = false,
	autoComplete = [],
	favorites = {},
	children,
	noCollapse,
	expandSelected,
	onChange = () => {},
}) {
	const { t } = useTranslation();
	const { customView } = useListFilterContext();

	const [filterCollapse, setFilterCollapse] = useSessionStorage(
		'/collapsed-' + filterKey,
		false,
		true
	);

	const filtersSelected = filters.filter(filter => filter.selected);
	//autocollapse based on selected filter ( feedback exports )
	const [collapse, setCollapse] = useState(filtersSelected.length);

	const isExpanded = expandSelected
		? collapse
		: noCollapse
		? true
		: filterCollapse == true;

	const [customLoaded, setCustomLoaded] = useState(false);

	const { sortPropsByValue } = filterUtils;
	const mappedFavorites = sortPropsByValue(favorites).slice(0, maxFilterFavorites);

	useEffect(() => {
		//load custom filters from custom view on pageload
		if (!filterKey && customView) {
			let filterValue = filters.find(filter => filter.selected);
			if (filterValue && !customLoaded) {
				setCustomLoaded(true);
				onChange(filterValue.rules, filterValue.id);
			}
		}
	}, [filters]);

	function handleSelected(key, value) {
		if (!value) {
			onChange({ key: key, value: [] });
		} else {
			const selectedValues = filters.reduce(
				(filters, filter) => {
					if (filter.selected) {
						if (filter.value === value) {
							filters.shift();
						} else {
							filters.push(filter.value);
						}
					}
					return filters;
				},
				[value]
			);

			onChange({ key: key, value: multiple ? selectedValues : selectedValues[0] });
		}
	}

	function handleCustom(filter, index, viewOnly) {
		if (viewOnly) {
			//return custom filter value as if it is a normal filter
			onChange({
				key: 'custom',
				selected: !filter.selected,
				value: {
					id: filter.id,
					rules: filter.rules,
				},
			});
		} else {
			//standard
			onChange(
				!filters[index].selected ? filter.rules : [],
				!filters[index].selected ? filters[index].id : ''
			);
		}
	}

	function handleCollapse() {
		expandSelected ? setCollapse(!collapse) : setFilterCollapse(!isExpanded);
	}

	return (
		<Grid
			container
			direction="column"
			wrap="wrap"
		>
			<Grid
				item
				xs={12}
			>
				<Grid
					container
					onClick={handleCollapse}
					className={classes.expandArea}
				>
					<Grid
						item
						xs
						className={classes.center}
					>
						{customFilterName && customFilterName}
						{!customFilterName && (
							<Typography variant="subtitle2">{filterName}</Typography>
						)}
					</Grid>

					{!noCollapse && (
						<Grid
							item
							className={classes.center}
						>
							{!isExpanded && filtersSelected.length > 0 && (
								<Chip
									size="small"
									color="primary"
									label={t('{{amount}} selected', { amount: filtersSelected.length })}
								/>
							)}
							<div
								className={`${classes.expand} ${isExpanded ? classes.expandOpen : ''}`}
							>
								<ExpandMoreIcon />
							</div>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Collapse in={isExpanded}>
				<Grid
					item
					xs={12}
				>
					{autoComplete.length >= showFilterAsAutoComplete && mappedFavorites.length > 0 && (
						<Fragment>
							<Typography
								component="div"
								variant="caption"
								fontWeight="medium"
								className={classes.marginTop}
							>{t`Most used`}</Typography>
							<div className={classes.marginBottom}>
								{filters
									.filter(option => mappedFavorites.indexOf(option.label) > -1)
									.map((filter, index) => {
										const chipProps = {
											clickable: true,
											label: filter.label,
											variant: filter.selected ? 'default' : 'outlined',
											color: filter.selected ? 'primary' : 'default',
											className: classes.chip,
											onClick: () => {
												handleSelected(filterKey, filter.value);
											},
											...(filter.testEl && {
												'data-test-element': filter.testEl,
											}),
											...(filter.attr && {
												'data-track-event': filter.attr,
											}),
										};

										return (
											<Fragment key={filter.label + filter.value}>
												<Chip {...chipProps} />
											</Fragment>
										);
									})}
							</div>
							<Typography
								component="div"
								variant="caption"
								fontWeight="medium"
							>{`${t`All`} ${filterName.toLowerCase()}`}</Typography>
						</Fragment>
					)}
					{autoComplete.length >= showFilterAsAutoComplete && (
						<Autocomplete
							multiple
							filterSelectedOptions
							blurOnSelect
							options={filters}
							getOptionLabel={option => option.label}
							getOptionSelected={(option, value) => option.label === value.label}
							value={filters.filter(option => option.selected)}
							renderInput={params => (
								<TextField
									{...params}
									variant="standard"
									//label="Multiple values"
									placeholder={`${t`Select `} ${filterKey}`}
								/>
							)}
							onChange={(e, value) => {
								onChange({ key: filterKey, value: value.map(option => option.value) });
							}}
						/>
					)}
					{autoComplete.length < showFilterAsAutoComplete &&
						filters.map((filter, index) => {
							const chipProps = {
								clickable: true,
								label: custom ? filter.name : filter.label,
								variant: filter.selected ? 'default' : 'outlined',
								color: filter.selected ? 'primary' : 'default',
								className: classes.chip,
								onClick: e =>
									custom
										? handleCustom(filter, index, viewOnly)
										: handleSelected(filterKey, filter.value),
								...(custom && {
									filter: filter,
									viewOnly: viewOnly,
									updateFilters: updateFilters,
									onApply: () => {
										if (viewOnly) {
											handleCustom(filter, index, viewOnly);
										} else {
											listFilterContext.setCustomFilter({
												action: 'set',
												custom: filter.rules,
											});
											listFilterContext.setCustomView(filter.id);
										}
									},
									onEdit: () => {
										listFilterContext.setCustomFilter({
											action: 'set',
											custom: filter.rules,
										});
										listFilterContext.setCustomView(filter.id);
										openFilterDrawer();
									},
								}),
								...(filter.testEl && {
									'data-test-element': filter.testEl,
								}),
								...(filter.attr && {
									'data-track-event': filter.attr,
								}),
								key: filter.value + filter.label,
							};

							return custom ? <CustomViewChip {...chipProps} /> : <Chip {...chipProps} />;
						})}
					{children}
				</Grid>
			</Collapse>
		</Grid>
	);
}

//We dont use the normal withListFilterContext function here because the props will conflict with the already used props in the component
const withListFilterContextCustom = Component => props =>
	(
		<ListFilterContext.Consumer>
			{value => (
				<Component
					{...props}
					listFilterContext={value}
				/>
			)}
		</ListFilterContext.Consumer>
	);

export default withStyles(styles)(withListFilterContextCustom(withSnackbar(FilterGroup)));
