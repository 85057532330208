import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '../AppContext';
import DateRangeFilterPicker from '../AppFilters/DateRangeFilterPicker';

import { isValid, format, dateFilters, dateFilterReducer } from '../../utils/date';

const useStyles = makeStyles(theme => ({
	avatar: {
		background: 'transparent',
	},
	padding: {
		padding: theme.spacing(1),
	},
	actions: {
		padding: theme.spacing(1),
	},
	chip: {
		margin: theme.spacing(0.5),
	},
	label: {
		fontWeight: 400,
	},
	button: {
		justifyContent: 'flex-start',
	},
	quickFilters: {
		overflowX: 'scroll',
	},
	quickFiltersHeader: {
		marginBottom: theme.spacing(2),
	},
}));

export default function ExportDatePicker({
	anchor,
	resetAnchor,
	fromDate,
	toDate,
	setFromDate,
	setToDate,
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	const {
		app,
		app: { date },
	} = useAppContext();

	const [datePicker, setDatePicker] = useState({
		anchor: null,
		tempFromDate: '',
		tempToDate: '',
	});

	useEffect(() => {
		setDatePicker({
			...datePicker,
			anchor: anchor,
			tempFromDate: fromDate,
			tempToDate: toDate,
		});
	}, [anchor]);

	function setTempFromDate(date) {
		if (isValid(date)) {
			setDatePicker({
				...datePicker,
				tempFromDate: format(date, app.date.format),
			});
		}
	}

	function setTempToDate(date) {
		if (isValid(date)) {
			setDatePicker({
				...datePicker,
				tempToDate: format(date, app.date.format),
			});
		}
	}

	return (
		<Popover
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'center',
				horizontal: 'center',
			}}
			anchorEl={datePicker.anchor}
			open={Boolean(datePicker.anchor)}
			onClose={() => {
				setFromDate(datePicker.tempFromDate);
				setToDate(datePicker.tempToDate);
				resetAnchor();
			}}
		>
			<div className={classes.padding}>
				<Grid
					container
					direction="row"
					spacing={1}
				>
					<Grid item>
						<Grid
							container
							className={classes.quickFilters}
							wrap="wrap"
							direction="column"
						>
							<Typography
								variant="subtitle2"
								className={classes.quickFiltersHeader}
							>{t`Quick select`}</Typography>
							{dateFilters.map(option => {
								return (
									<Grid
										item
										key={option.label}
									>
										<Button
											style={{ textTransform: 'none' }}
											color="default"
											onClick={() => {
												const { fromDate, toDate } = dateFilterReducer(
													option.action,
													date.format
												);
												setDatePicker({
													...datePicker,
													tempFromDate: fromDate,
													tempToDate: toDate,
												});
											}}
											fullWidth
											classes={{
												root: classes.button,
												label: classes.label,
											}}
										>
											{t(option.label)}
										</Button>
									</Grid>
								);
							})}
						</Grid>
					</Grid>

					<Grid
						item
						xs
					>
						<div>
							<DateRangeFilterPicker
								fromDate={datePicker.tempFromDate || fromDate}
								toDate={datePicker.tempToDate || toDate}
								onFromDateChange={date => setTempFromDate(date)}
								onToDateChange={date => setTempToDate(date)}
								format={date.format}
								separator={date.separator}
							/>
						</div>
					</Grid>
				</Grid>
			</div>

			<div>
				<Grid
					container
					className={classes.actions}
					justify="flex-end"
				>
					<Grid item>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								setFromDate(datePicker.tempFromDate);
								setToDate(datePicker.tempToDate);
								resetAnchor();
							}}
						>
							{t`Set range`}
						</Button>
					</Grid>
				</Grid>
			</div>
		</Popover>
	);
}
