import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import ButtonAddList from './ButtonAddList';

import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
	card: {
		'&:not(:first-child)': {
			marginTop: theme.spacing(1),
		},
	},
}));

export default function EditorNestedOrAndArrayConditions({
	children = () => {},
	orArray,
	conditionName,
	onAddAnd = () => {},
	onAddOr = () => {},
	onRemoveAnd = () => {},
	hintText,
	...props
}) {
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<Box width="100%">
			{orArray.map((andArr, orIndex) => {
				return (
					<Card
						className={classes.card}
						key={orIndex}
					>
						<Box
							pb={0}
							clone
						>
							<CardContent>
								{Array.isArray(andArr) &&
									andArr.map((conditionObj, andIndex) => {
										return (
											<Grid
												container
												key={andIndex}
											>
												<Grid
													item
													xs
												>
													{children({ conditionObj, andIndex, andArr, orIndex })}
												</Grid>

												<Grid item>
													<IconButton
														onClick={() =>
															onRemoveAnd({ andArr, orIndex, andIndex, conditionName })
														}
													>
														<RemoveIcon />
													</IconButton>
												</Grid>
											</Grid>
										);
									})}
								{hintText && (
									<Typography
										variant="caption"
										color="textSecondary"
									>
										{hintText}
									</Typography>
								)}
							</CardContent>
						</Box>
						<CardActions>
							<ButtonAddList
								label={t`And`}
								onClick={() => onAddAnd({ orIndex, conditionName })}
							/>
						</CardActions>
					</Card>
				);
			})}
			<Box
				mt={1}
				width="100%"
				display="flex"
				flexDirection="row"
				justifyContent="flex-end"
			>
				<ButtonAddList
					label={t`Or`}
					onClick={() => onAddOr({ conditionName })}
				/>
			</Box>
		</Box>
	);
}
