import React from 'react';

import useGetPendingPermissionRequests from './useGetPendingPermissionRequests';
import HandlePermissionRequestAlert from './HandlePermissionRequestAlert';

export default function PendingPermissionRequests({ getPermissionGroups, addNewGroup }) {
	const [data, loading, _err, loadData] = useGetPendingPermissionRequests();

	return (
		<>
			{data.pending_requests?.map(permissionRequest => {
				return (
					<HandlePermissionRequestAlert
						request={permissionRequest}
						key={permissionRequest.created}
						refreshRequests={() => {
							loadData();
							getPermissionGroups();
						}}
						addNewGroup={addNewGroup}
					/>
				);
			})}
		</>
	);
}
