import React, { useState, useRef } from 'react';

import useEventListener from './useEventListener';

export default function useHover(node, delay = 0) {
	const [hover, setHover] = useState(false);

	const timeout = useRef(null);

	useEventListener(
		'mouseenter',
		e => {
			timeout.current = setTimeout(() => setHover(true), delay);
		},
		node
	);

	useEventListener(
		'mouseleave',
		e => {
			clearTimeout(timeout.current);
			setHover(false);
		},
		node
	);

	return hover;
}
