import React, { useMemo, useEffect } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ListSubheader from '@material-ui/core/ListSubheader';

import { useTranslation } from 'react-i18next';

import DataFieldItem from './DataFieldItem';
import { useDataSourceContext } from './DataSourceContext';
import { OptionalDroppable } from '@/components/Layout';
import { sortByActive, sortByGroupId, isMergeField } from './dataSourceUtils';

const styles = theme => ({
	inset: {
		paddingLeft: theme.spacing(2),
	},
	collapse: {
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
});

function DataFieldList({
	open,
	sourceId,
	dragFields,
	classes,
	addIndicators = [],
	selectedDataFields = [],
	scaleInAnimation,
	dataTestElement = '',
	hideVarTypes = [],
	...props
}) {
	const { t } = useTranslation();
	const { datasource } = useDataSourceContext();

	useEffect(() => {
		datasource.api.loadSurveyFields(sourceId);
	}, [sourceId]);

	const formattedFields = datasource.api.getFieldsFormattedByVarType(sourceId);

	const datafields = useMemo(() => {
		//merge checkbox likert and matrix fields (type = 4,11,12) into 1
		const mergedFields = formattedFields.category?.fields?.reduce((acc, field) => {
			if (isMergeField(field)) {
				if (!acc[field.group_id]) {
					acc[field.group_id] = {
						title: field?.survey_block?.title,
						system_var: field?.survey_block?.title,
						system_var_simple: field?.survey_block?.title,
						var_type: field.var_type,
						active: field.active,
						niceType: field.niceType,
						mergedFields: [],
						group_id: field.group_id,
						block_id: field.survey_block?.id,
						block_type: Number(field.survey_block?.type),
					};
				}

				if (!acc[field.group_id] && field.active) {
					acc[field.group_id].active = true;
				}
				acc[field.group_id].mergedFields.push({ ...field, surveyId: sourceId });
				//sort by active
				acc[field.group_id].mergedFields.sort((a, b) => b.active - a.active);
			}
			return acc;
		}, {});

		return Object.keys(formattedFields).map((key, i) => {
			const category = formattedFields[key];

			const fields =
				key === 'category'
					? sortByGroupId(
							sortByActive([
								//filter out existing checkbox, likert, matrix fields and sort
								...category.fields.filter(field => !isMergeField(field)),
								...Object.values(mergedFields),
							])
					  )
					: category.fields;

			if (fields.length === 0) return [];

			return (
				<OptionalDroppable
					droppable={dragFields}
					droppableId={sourceId + category.label}
					isDropDisabled
					key={category.label + '-' + i}
				>
					<ListSubheader
						disableSticky
						key={category.label}
					>
						{category.label}
					</ListSubheader>
					{fields
						.filter(field => hideVarTypes.indexOf(field.var_type) === -1)
						.map((field, i) => {
							const indicators = addIndicators.filter(df => df.id === String(field.id));
							const isSelected = selectedDataFields.find(
								id => String(id) === String(field.id)
							);

							const { title, survey_block, system_var_simple, niceType, system_var } =
								field;

							return (
								<DataFieldItem
									key={field.id + field.system_var_simple + i}
									title={title ?? system_var}
									type={niceType}
									id={field.id}
									index={i}
									isDrag={dragFields}
									sourceId={sourceId}
									field={Object.assign({}, field, { surveyId: sourceId })}
									className={props.inset ? classes.inset : ''}
									indicators={indicators}
									selected={isSelected}
									scaleInAnimation={scaleInAnimation}
									dataTestElement={dataTestElement}
									{...props}
								/>
							);
						})}
				</OptionalDroppable>
			);
		});
	}, [formattedFields, selectedDataFields]);

	return <Collapse in={open}>{datafields}</Collapse>;
}

export default withStyles(styles)(DataFieldList);
