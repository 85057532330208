import React from 'react';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';
import { FilterGroup, useListFilterContext } from '@/components/FilteredLists';
import { SearchField } from '@/components/Layout';
import SsoPromo from './SsoPromo';

export default function UserListFilters({ groups = [] }) {
	const { t } = useTranslation();
	const { app } = useAppContext();
	const { filters, setSingleFilter, setMultiFilter } = useListFilterContext();

	return (
		<>
			<div>
				<SearchField
					value={filters.search ?? ''}
					onChange={event => setSingleFilter('search', event.target.value)}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('settings-users-list-filters-groups')}
					filterKey="groups"
					filters={groups.map(group => ({
						label: group.name,
						value: group.uuid,
						selected: filters.groups?.includes(group.uuid),
					}))}
					multiple
					onChange={({ key, value }) => setMultiFilter(key, value)}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('settings-users-list-filters-reports')}
					filterKey="projects"
					filters={app.projects.asArray.map(project => ({
						label: project.name,
						value: project.uuid,
						selected: filters.projects?.includes(project.uuid),
					}))}
					multiple
					onChange={({ key, value }) => setMultiFilter(key, value)}
				/>
			</div>

			{!app.auth.options.includes('sso') && <SsoPromo />}
		</>
	);
}
