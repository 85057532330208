import React, { useContext, useMemo, forwardRef } from 'react';

import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { DataSourceContext } from '../DataSources';
import { ListFilterContext } from '../FilteredLists';

const useStyles = makeStyles(theme => ({
	chip: {
		maxWidth: '100%',
		margin: theme.spacing(0.25),
	},
	label: {
		display: 'block',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
}));

function ConditionalTooltip({ children, title }) {
	return title ? <Tooltip title={title}>{children}</Tooltip> : children;
}

export default function CustomFilterSidebarChip({
	filter = {},
	index,
	customLabel,
	color,
	onDelete,
	tooltip,
	...props
}) {
	const classes = useStyles();
	const { datasource } = useContext(DataSourceContext);
	const { setCustomFilter } = useContext(ListFilterContext);
	const handleDelete = () =>
		typeof onDelete === 'function'
			? onDelete()
			: setCustomFilter({ action: 'remove', index });

	const field = datasource.api.getField(filter.identifier);
	let value = Array.isArray(filter.value) ? filter.value.join(', ') : filter.value;
	let label = customLabel ? customLabel : field.system_var || '...';

	//specific for filter inside charteditor -> pre and appending _ causes a contains search
	if (value?.startsWith('_') && value?.endsWith('_')) {
		value = value.substr(1, value.length - 2);
	}

	const chipProps = {
		...props,
		color: color ? color : 'primary',
		label: `${label}: ${value}`,
		onDelete: handleDelete,
		classes: {
			root: classes.chip,
			label: classes.label,
		},
	};

	return (
		<ConditionalTooltip title={tooltip}>
			<Chip {...chipProps} />
		</ConditionalTooltip>
	);
}
