import React, { useState, Fragment, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ListItemIcon, ListItemText, Avatar } from '@material-ui/core';

import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import AdjustIcon from '@material-ui/icons/Adjust';

import { useTranslation, Trans } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { useAppContext } from '../AppContext';
import { UserName } from '../Users';
import { Grid, Typography, Link, ActionIconButton, FormattedDate } from '../Layout';
import { useDataSourceContext } from '../DataSources';
import { useNotificationContext } from './NotificationsContext';
import ActivityIcon from './ActivityIcon';
import useNotificationProject from './useNotificationProject';
import useNotificationUser from './useNotificationUser';

const useStyles = makeStyles(theme => ({
	item: {
		padding: theme.spacing(3, 1),
		display: 'block',
		textAlign: 'left',
		borderRadius: theme.shape.borderRadius,
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
		},
		'&:hover $removeButton': {
			opacity: 1,
			visibility: 'visible',
		},
	},
	selected: {
		backgroundColor: theme.palette.action.selected,
	},
	grid: {
		'& > div:not(:last-child)': {
			marginRight: theme.spacing(1),
		},
	},
	selfCenter: {
		alignSelf: 'center',
	},
	readIndicator: {
		backgroundColor: theme.palette.primary.main,
		borderRadius: '100%',
		width: 8,
		height: 8,
		margin: theme.spacing(0.5),
		display: 'inline-flex',
	},
	spaceChildren: {
		'& > *:not(:last-child)': {
			marginRight: theme.spacing(0.5),
		},
	},
	removeButton: {
		opacity: 0,
		visibility: 'hidden',
		transition: theme.transitions.create(['opacity', 'visibility']),
	},
	fwMedium: {
		fontWeight: theme.typography.fontWeightMedium,
	},
	date: {
		marginTop: theme.spacing(0.5),
		color: theme.palette.text.hint,
	},
	project: {
		marginTop: theme.spacing(0.5),
	},
	projectIcon: {
		verticalAlign: 'middle',
	},
	dateProjectWrap: {
		marginTop: theme.spacing(0.5),
		fontWeight: theme.typography.fontWeightMedium,
	},
	spacer: {
		margin: theme.spacing(0, 0.5),
	},
	avatar: {
		backgroundColor: theme.palette.action.selected,
		color: theme.palette.text.secondary,
	},
}));

function MessageContent({ notification = {} }) {
	const classes = useStyles();
	const { event = {} } = notification;

	const {
		new_user_firstname,
		new_user_lastname,
		new_user_id,
		survey_name,
		survey_id,
		chart_name,
		status_id,
		action_status,
		action_name,
		assigned_user_id,
		is_comment,
		payload,
		request,
	} = event;
	const { app } = useAppContext();
	const { datasource } = useDataSourceContext();
	const { t } = useTranslation();

	const surveyName = survey_name
		? survey_name
		: survey_id
		? datasource.api.getSource(survey_id).name
		: '';

	const newUserName =
		new_user_firstname && new_user_lastname
			? `${new_user_firstname} ${new_user_lastname}`
			: new_user_id
			? app.api.getUserName(new_user_id)
			: '';

	const project = useNotificationProject(notification, true);

	switch (event.type) {
		case 'add_survey':
			return (
				<Trans>
					<span>created a new form</span>
					<Link
						component="span"
						fontWeight="medium"
					>
						{{ surveyName }}
					</Link>
				</Trans>
			);

		case 'remove_survey':
			return (
				<Trans>
					<span>
						deleted a{' '}
						{{ survey_type: event.survey_type === 'import' ? t`dataset` : t`form` }}
					</span>
					<span className={classes.fwMedium}>{{ surveyName }}</span>
				</Trans>
			);

		case 'add_user':
			return (
				<Trans>
					<span>added a new user</span>
					<Link
						component="span"
						fontWeight="medium"
					>
						{{ newUserName }}
					</Link>
				</Trans>
			);

		case 'add_note':
			return (
				<Trans>
					<span>added a note to</span>
					<Link
						component="span"
						fontWeight="medium"
					>
						a feedback item
					</Link>
				</Trans>
			);

		case 'efm_action':
			for (const statusType of [status_id, action_status]) {
				const status = parseInt(statusType) || action_status;

				switch (status) {
					case 1:
					case 'assigned':
						return (
							<Trans>
								<span>assigned action</span>
								<Link
									component="span"
									fontWeight="medium"
								>
									{{ action_name }}
								</Link>
								<span>to</span>
								<UserName
									user_id={assigned_user_id}
									profileLink
								/>
							</Trans>
						);

					case 2:
					case 'accepted':
						return (
							<Trans>
								<span>accepted action</span>
								<Link
									component="span"
									fontWeight="medium"
								>
									{{ action_name }}
								</Link>
							</Trans>
						);

					case 3:
					case 'done':
						return (
							<Trans>
								<span>set action</span>
								<Link
									component="span"
									fontWeight="medium"
								>
									{{ action_name }}
								</Link>
								<span>to done</span>
							</Trans>
						);

					case 4:
					case 'completed':
						return (
							<Trans>
								<span>set action</span>
								<Link
									component="span"
									fontWeight="medium"
								>
									{{ action_name }}
								</Link>
								<span>to completed</span>
							</Trans>
						);

					case 5:
					case 'declined':
						return (
							<Trans>
								<span>declined action</span>
								<Link
									component="span"
									fontWeight="medium"
								>
									{{ action_name }}
								</Link>
							</Trans>
						);
				}
			}

			if (is_comment) {
				return (
					<Trans>
						<span>added a comment to action</span>
						<Link
							component="span"
							fontWeight="medium"
						>
							{{ action_name }}
						</Link>
					</Trans>
				);
			}

			//fallback in the case of missing information
			return (
				<Trans>
					<span>updated action</span>
					<Link
						component="span"
						fontWeight="medium"
					>
						{{ action_name }}
					</Link>
				</Trans>
			);

		case 'create_chart':
			return (
				<Trans>
					<span>created chart</span>
					<Link
						component="span"
						fontWeight="medium"
					>
						{{ chart_name }}
					</Link>
				</Trans>
			);

		case 'delete_chart':
			return (
				<Trans>
					<span>deleted chart</span>
					<span>{{ chart_name }}</span>
				</Trans>
			);

		case 'export_create':
			return (
				<Trans>
					<span>created a feedback export</span>
					<Link
						component="span"
						fontWeight="medium"
					>
						{{ exportName: event.export_name }}
					</Link>
				</Trans>
			);

		case 'login':
			return <span>{t('logged in')}</span>;

		case 'save_survey':
			return (
				<Trans>
					<span>updated a form</span>
					<Link
						component="span"
						fontWeight="medium"
					>
						{{ surveyName }}
					</Link>
				</Trans>
			);

		case 'edit_deployment':
			return (
				<Trans>
					<span>updated a deployment</span>
					<Link
						component="span"
						fontWeight="medium"
					>
						{{ deploymentName: payload?.name }}
					</Link>
				</Trans>
			);

		case 'deployment_deleted':
			return (
				<Trans>
					<span>deleted a deployment</span>
				</Trans>
			);

		case 'deleted_feedback':
			return (
				<Trans>
					<span>deleted a feedback item</span>
				</Trans>
			);

		case 'efm_email':
			if (event.email_type === 'reply' && event.feedback_id) {
				return (
					<Trans>
						<span>replied to a</span>
						<Link
							component="span"
							fontWeight="medium"
						>
							feedback item
						</Link>
					</Trans>
				);
			}

		case 'add_dataset':
			return (
				<Trans>
					<span>created a new dataset</span>
					<Link
						fontWeight="medium"
						component="span"
					>
						{{ surveyName }}
					</Link>
				</Trans>
			);

		case 'update_dataset':
			return (
				<Trans>
					<span>updated a dataset</span>
					<Link
						fontWeight="medium"
						component="span"
					>
						{{ surveyName }}
					</Link>
				</Trans>
			);

		case 'stripe.invoice.payment_succeeded':
			return (
				<Trans>
					<span>'re latest payment was successfully processed.</span>
					<Link
						fontWeight="medium"
						component="span"
					>
						View your invoice on the billing page.
					</Link>
				</Trans>
			);

		case 'stripe.invoice.payment_failed':
			return (
				<Trans>
					<span>'re latest payment failed!</span>
					<Link
						fontWeight="medium"
						component="span"
					>
						View your payment details on the billing page.
					</Link>
				</Trans>
			);

		case 'stripe.customer.subscription.trial_will_end':
			return (
				<Trans>
					<span>'re trial is set to end at</span>
					<FormattedDate date={app.package?.period?.end} />.
					<Link
						fontWeight="medium"
						component="span"
					>
						Select a package to continue using Mopinion!
					</Link>
				</Trans>
			);

		case 'access_request.added':
			return (
				<Trans
					i18nKey={`notifications-access_groups-request_added`}
					components={{
						permission: t(`settings-access_groups-access_types-${request.permission}`),
						subject: t(`settings-access_groups-access_subjects-${request.subject}`),
					}}
				/>
			);
		case 'access_request.granted':
		case 'access_request.denied':
			return (
				<Trans>
					<span>
						{t(`notifications-access_groups-request_${request.status}`, {
							permission: t(`settings-access_groups-access_types-${request.permission}`),
							subject: t(`settings-access_groups-access_subjects-${request.subject}`),
						})}
					</span>
				</Trans>
			);
		case 'project_access_request.added':
			return (
				<Trans
					i18nKey={`notifications-access_groups-project_request_added`}
					components={{
						permission: t(`settings-access_groups-access_types-${request.permission}`),
						subject: t(`settings-access_groups-access_subjects-${request.subject}`),
						report: project.name,
					}}
				/>
			);
		case 'project_access_request.granted':
		case 'project_access_request.denied':
			return (
				<Trans>
					<span>
						{t(`notifications-access_groups-project_request_${request.status}`, {
							permission: t(`settings-access_groups-access_types-${request.permission}`),
							subject: t(`settings-access_groups-access_subjects-${request.subject}`),
							report: project.name,
						})}
					</span>
				</Trans>
			);

		//TODO: finish up add_tag event
		//case 'add_tag':
		//return <TagMessageContent tags={event.tags} feedbackIds={event.feedbackIds} actionIds={event.actionIds} />;

		default:
			return '';
	}
}

//To be continued
function TagMessageContent({ tags = [], feedbackIds = [], actionIds = [] }) {
	const classes = useStyles();
	const multipleTags = tags.length > 1;
	const showTags = tags.length < 4;
	const tagsStr = tags.length < 4 ? tags.join(', ') : tags.length;

	if (feedbackIds.length === 1 && !multipleTags) {
		return (
			<Trans>
				<span>added tag</span>
				<span className={classes.fwMedium}>{{ tagsStr }}</span>
				<span>to a</span>
				<Link fontWeight="medium">feedback item</Link>
			</Trans>
		);
	}

	if (feedbackIds?.length > 1 && !multipleTags) {
		return (
			<Trans>
				<span>added tag</span>
				<span className={classes.fwMedium}>{{ tagsStr }}</span>
				<span>to</span>
				<span className={classes.fwMedium}>
					{{ feedbackItemCount: feedbackIds.length }}
				</span>
				<span className={classes.fwMedium}>feedback items</span>
			</Trans>
		);
	}
}

function Message({ notification = {}, variant, ...props }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const user = useNotificationUser(notification.event);
	const project = useNotificationProject(notification);

	return (
		<Typography
			variant="body1"
			className={classes.spaceChildren}
		>
			<UserName
				uuid={user.uuid}
				profileLink
				data-track-event="notification_screen_click_to_user"
				showSelfAsYou={variant === 'notification'}
			/>
			<MessageContent notification={notification} />
			{project && (
				<Fragment>
					<span> {t`in report`}</span>
					<span className={classes.fwMedium}>{project.name}</span>
				</Fragment>
			)}
		</Typography>
	);
}

function makeLink(item) {
	const { project_id, event = {} } = item;

	switch (event.type) {
		case 'add_survey':
		case 'save_survey':
			return `/data-collection/survey/edit/${event.survey_id}`;
		case 'remove_survey':
			return '/data-collection/survey';
		case 'add_user':
			return `/application/users/profile/${event.new_user_id}`;
		case 'add_note':
			return `/reporting/inbox/detail/${event.feedback_id}`;
		case 'efm_action':
			return `/actions/inbox/detail/${event.action_id}`;
		case 'create_chart':
			return `/reporting/chart/${event.chart_id}`;
		case 'delete_chart':
			return '/reporting/dashboard';
		case 'export_create':
			return event.s3_url;
		case 'deployment_saved':
			return `/data-collection/deployment/${event.payload?.id}`;
		case 'deployment_deleted':
			return '/data-collection/deployment';
		case 'efm_email':
			if (event.feedback_id) {
				return `/reporting/inbox/detail/${event.feedback_id}`;
			}
		case 'add_dataset':
		case 'update_dataset':
			return '/data-collection/dataset';

		case 'access_request.added':
		case 'project_access_request.added':
			return '/application/users/access';
		case 'access_request.granted':
		case 'project_access_request.added.granted':
		case 'access_request.denied':
		case 'project_access_request.added.denied':
		default:
			return '';
	}
}

export default function NotificationItem({ item = {}, variant = 'notification' }) {
	const classes = useStyles();
	const { app } = useAppContext();
	const { t } = useTranslation();
	const [showMoreAnchor, setShowMoreAnchor] = useState(null);
	const history = useHistory();
	const {
		markAsRead = () => {},
		clear,
		unsubscribeFromEvent = () => {},
		unsubscribeFromUser = () => {},
		closeMenu = () => {},
	} = useNotificationContext();

	const project = app.projects.byKey[item.project_id];

	const link = makeLink(item);

	function itemClick(e, fn) {
		e.stopPropagation();
		e.preventDefault();
		setShowMoreAnchor(null);
		fn();
	}

	async function linkClick(e) {
		e.stopPropagation();
		e.preventDefault();
		if (project && project.id != app.projects.current.id) {
			//we mut switch project to supply a working link
			await app.api.changeProject(project);
		}

		if (link) history.push(link);

		closeMenu();
		if (!item.seen) markAsRead([item.uuid]);
	}

	const ButtonBaseProps = {
		className: `${classes.item} ${Boolean(showMoreAnchor) ? classes.selected : ''}`,
		'data-track-event': `notification_screen_click_to_${item?.event?.type}`,
		...(item?.event?.type !== 'export_create' && {
			component: item?.event?.type !== 'login' ? RouterLink : 'div',
			to: link,
			onClick: linkClick,
		}),
		...(item?.event?.type === 'export_create' && {
			component: 'a',
			href: link,
		}),
	};

	return (
		<Fragment>
			<ButtonBase {...ButtonBaseProps}>
				<Grid
					container
					className={classes.grid}
					spacing={1}
				>
					<Grid item>
						<Avatar className={classes.avatar}>
							<ActivityIcon type={item?.event?.type} />
						</Avatar>
					</Grid>
					<Grid
						item
						xs
					>
						<Message
							notification={item}
							variant={variant}
						/>
						<div className={classes.dateProjectWrap}>
							<Typography
								variant="caption"
								color="textHint"
								fontWeight="medium"
							>
								<FormattedDate
									variant="distance"
									date={item.created}
								/>
							</Typography>
						</div>
					</Grid>
					{variant === 'notification' && (
						<Grid
							item
							className={`${classes.selfCenter} ${classes.spaceChildren}`}
						>
							{!item.seen && (
								<ActionIconButton
									size="small"
									tooltip={t`Mark as read`}
									data-track-event="notification_screen_note_marked_read"
								>
									<div
										className={classes.readIndicator}
										onClick={e => itemClick(e, () => markAsRead([item.uuid]))}
									/>
								</ActionIconButton>
							)}
							{item.seen && (
								<ActionIconButton
									action="close"
									onClick={e => itemClick(e, () => clear([item.uuid]))}
									IconProps={{
										fontSize: 'small',
									}}
									size="small"
									tooltip={t`Remove notification`}
									className={classes.removeButton}
									data-track-event="notification_screen_note_deleted"
								/>
							)}
							<ActionIconButton
								action="more"
								onClick={e => itemClick(e, () => setShowMoreAnchor(e.target))}
								IconProps={{
									fontSize: 'small',
								}}
								size="small"
								//  size="small"
							/>
						</Grid>
					)}
				</Grid>
			</ButtonBase>

			<Menu
				open={Boolean(showMoreAnchor)}
				anchorEl={showMoreAnchor}
				onClose={() => setShowMoreAnchor(null)}
			>
				{!item.seen && (
					<MenuItem
						onClick={e => itemClick(e, () => markAsRead([item.uuid]))}
						data-track-event="notification_screen_note_marked_read"
					>
						<ListItemIcon>
							<CheckIcon />
						</ListItemIcon>
						<ListItemText primary={t`Mark as read`} />
					</MenuItem>
				)}
				{item.seen && (
					<MenuItem
						onClick={e => itemClick(e, () => markAsRead([item.uuid], false))}
						data-track-event="notification_screen_note_marked_unread"
					>
						<ListItemIcon>
							<AdjustIcon />
						</ListItemIcon>
						<ListItemText primary={t`Mark as unread`} />
					</MenuItem>
				)}
				<MenuItem
					onClick={e => itemClick(e, () => clear([item.uuid]))}
					data-track-event="notification_screen_note_deleted"
				>
					<ListItemIcon>
						<CloseIcon />
					</ListItemIcon>
					<ListItemText primary={t`Remove notification`} />
				</MenuItem>
				<MenuItem
					onClick={e => itemClick(e, () => unsubscribeFromEvent(item?.event?.type))}
					data-track-event={`notification_screen_unsub_${item?.event?.type}`}
				>
					<ListItemIcon>
						<NotificationsOffIcon />
					</ListItemIcon>
					<ListItemText primary={t`Unsubscribe from this event`} />
				</MenuItem>
				<MenuItem
					onClick={e => itemClick(e, () => unsubscribeFromUser(item?.event?.user_id))}
					data-track-event="notification_screen_unsub_user"
				>
					<ListItemIcon>
						<NotificationsOffIcon />
					</ListItemIcon>
					<ListItemText primary={t`Unsubscribe from this user`} />
				</MenuItem>
			</Menu>
		</Fragment>
	);
}
