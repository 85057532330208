import React from 'react';
import { DashboardContext } from './DashboardContextProvider';

const withDashboardContext = Component => props =>
	(
		<DashboardContext.Consumer>
			{value => (
				<Component
					{...props}
					{...value}
				/>
			)}
		</DashboardContext.Consumer>
	);

export default withDashboardContext;
