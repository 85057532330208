import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@/styles';
import { useAppContext } from '@/components/AppContext';
import { PageHeader } from '@/components/App';
import { AccountHeader, Grid, Card } from '@/components/Layout';
import { useAjaxForm } from '@/components/Ajax';

import ListItemCard from './ListItemCard';
import SecurityCard from './SecurityCard';
import SocialCard from './SocialCard';

const useStyles = makeStyles(theme => ({
	username: {
		marginBottom: theme.spacing(2),
	},
	list: {
		padding: theme.spacing(0, 2, 2),
	},
	marginBottom: {
		marginBottom: theme.spacing(3),
	},
}));

function MyAccount() {
	const classes = useStyles();
	const { t } = useTranslation();
	const { app } = useAppContext();
	const { enqueueSnackbar } = useSnackbar();

	const { allow_mfa } = app.package;
	const { allow_social, upgrade_for_social } = app.social;

	const user = app.users.current;

	const ssoOnly =
		app.auth.options.includes('sso') && !app.auth.options.includes('user_creds');

	const [form, setForm] = useState({
		firstname: null,
		lastname: null,
		telephone: null,
		language: null,
		alerts: null,
	});

	useEffect(() => {
		if (form.alerts !== null) {
			postForm();
		}
	}, [form.alerts]);

	const postValues = Object.keys(form).reduce((update, key) => {
		if (user[key] !== form[key] && form[key] !== null) {
			update[key] = form[key];
		}

		return update;
	}, {});

	function setFormValue(key, value) {
		setForm(prev => ({
			...prev,
			[key]: value,
		}));
	}

	const { postForm } = useAjaxForm({
		url: '/account/ajax/edit-account',
		data: postValues,
		onSuccess: async r => {
			if (r.code === 200) {
				enqueueSnackbar(t`Account updated`);
				await app.api.getUsers();
				if (form.language !== null) {
					app.api.changeLanguage({ id: form.language });
				}
				setForm({
					firstname: null,
					lastname: null,
					telephone: null,
					language: null,
					alerts: null,
				});
			}
		},
	});

	function handlePost(key) {
		if (form[key] !== user[key]) {
			postForm();
		}
	}

	return (
		<div>
			<PageHeader title={t`Account`} />
			<AccountHeader
				userId={app.users.current.id}
				align="left"
			/>

			<Grid
				container
				spacing={3}
			>
				<Grid
					item
					xs={6}
				>
					<Card className={classes.marginBottom}>
						<ListItemCard
							header={t`Personal settings`}
							initial={user}
							handlePost={handlePost}
							setFormValue={setFormValue}
							dataTestElement="accountSaveChanges"
							listItems={[
								{
									target: 'firstname',
									icon: 'person',
									title: 'First name',
									value: form.firstname ?? user.firstname,
									dataTestElement: 'accountFirstname',
									rules: [['required', true]],
									showErrors: true,
									dataTrackEvent: 'account_settings_personal_firstname',
								},
								{
									target: 'lastname',
									icon: 'person',
									title: 'Last name',
									value: form.lastname ?? user.lastname,
									dataTestElement: 'accountLastname',
									rules: [['required', true]],
									showErrors: true,
									dataTrackEvent: 'account_settings_personal_lastname',
								},
								{
									icon: 'alternate_email',
									title: 'Email',
									value: user.email,
									editable: false,
								},
								{
									target: 'telephone',
									icon: 'phone',
									title: 'Phone',
									value: form.telephone ?? user.telephone,
									dataTestElement: 'accountPhone',
									rules: [
										['phone', true],
										['required', true],
									],
									messages: {
										required: t`A phonenumber is required`,
										phone: t`This is not a valid phonenumber`,
									},
									showErrors: true,
									dataTrackEvent: 'account_settings_personal_phone',
								},
							]}
						/>
					</Card>

					{!ssoOnly && (
						<AccountSettings
							user={user}
							form={form}
							handlePost={handlePost}
							setFormValue={setFormValue}
						/>
					)}
				</Grid>

				<Grid
					item
					xs={6}
				>
					{!ssoOnly && (
						<>
							<div className={classes.marginBottom}>
								<SecurityCard
									user={app.users.current}
									enforceMfa={app.enforceMfa}
									allow_mfa={allow_mfa}
								/>
							</div>
							{(allow_social || upgrade_for_social) && (
								<SocialCard
									user={app.users.current}
									socialSettings={app.social}
								/>
							)}
						</>
					)}

					{ssoOnly && (
						<AccountSettings
							user={user}
							form={form}
							handlePost={handlePost}
							setFormValue={setFormValue}
						/>
					)}
				</Grid>
			</Grid>
		</div>
	);
}

function AccountSettings({ user, handlePost, setFormValue, form }) {
	const { t } = useTranslation();

	const languages = {
		1: t`Dutch`,
		2: t`English`,
	};

	return (
		<Card>
			<ListItemCard
				header={t`Account settings`}
				initial={user}
				handlePost={handlePost}
				setFormValue={setFormValue}
				listItems={[
					{
						icon: 'event_available',
						title: 'Active since',
						value: user.created,
						editable: false,
					},
					{
						target: 'language',
						icon: 'language',
						title: 'Language',
						value: form.language ?? user.language,
						options: Object.keys(languages).map(lang => {
							return { value: lang, label: languages[lang] };
						}),
						dataTrackEvent: 'account_settings_account_language',
					},
					{
						target: 'alerts',
						type: 'switch',
						icon: 'drafts',
						title: 'Receive email alerts',
						value: form.alerts ?? user.alerts,
						checked: form.alerts
							? form.alerts === 'yes'
							: user.alerts
							? user.alerts === 'yes'
							: false,
						dataTrackEvent: 'account_settings_account_alerts',
					},
				]}
			/>
		</Card>
	);
}

export default MyAccount;
