import React, { useState, useMemo, useRef, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';

import { useExportContext } from './ExportContext';
import { EmptyStateHandleHasFeedback } from '@/components/App';
import { Loader, ActionButton, Grid } from '@/components/Layout';
import { useAppContext } from '@/components/AppContext';
import FeedbackExportDialog from './FeedbackExportDialog';
import ExportExpansionPanel from './ExportExpansionPanel';
import RecentListFilters from './RecentListFilters';
import { sortByNum, sortByAlphabet } from '@/utils';
import {
	ListWithSidebar,
	ListFilterProvider,
	ListControls,
	useFilteredList,
} from '@/components/FilteredLists';

export default function RecentExports({
	filtersOpen,
	setFiltersOpen,
	downloadUuid,
	startExportDownload = () => {},
}) {
	const { t } = useTranslation();

	const {
		state,
		state: { initialLoaded, loading, maxSetupsReached, recent_exports },
		api: { loadData },
	} = useExportContext();

	const [historyState, setHistoryState] = useImmer({
		selected: [],
		showExportDialog: false,
		expanded: '',
	});

	const {
		app,
		app: { layout, users, projects },
	} = useAppContext();

	const filterState = useFilteredList({
		filters: {},
		other: {},
	});

	const { filters, sort, order } = filterState;

	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogData, setDialogData] = useState({});
	const [withUuid, setWithUuid] = useState(false);

	const filtered_exports = useMemo(() => {
		const export_item =
			recent_exports.length > 0
				? recent_exports.filter(item => {
						const typeFilterPassed = filters.export_type
							? (filters.export_type === 'single' && !item.from_schedule) ||
							  (filters.export_type === 'scheduled' && item.from_schedule)
							: true;

						const ownerFilterPassed = filters.export_owner
							? (filters.export_owner === 'me' &&
									item.kwargs.notify_to.includes(Number(users.current.id))) ||
							  (filters.export_owner === 'other' &&
									!item.kwargs.notify_to.includes(Number(users.current.id)))
							: true;

						const dataFilterPassed = filters.export_data
							? (filters.export_data === 'withdata' && !item.result?.detail) ||
							  (filters.export_data === 'withoutdata' && item.result?.detail)
							: true;

						const statusFilterPassed = filters.export_status
							? filters.export_status.includes(item.status)
							: true;

						const searchFilterPassed = filters.search
							? item.kwargs.export_name.includes(filters.search)
							: true;

						return (
							typeFilterPassed &&
							ownerFilterPassed &&
							dataFilterPassed &&
							statusFilterPassed &&
							searchFilterPassed
						);
				  })
				: recent_exports;

		return sort === 'fname'
			? sortByAlphabet(export_item, 'name', order === 'desc')
			: sortByNum(export_item, 'timestamp', order === 'desc');
	}, [filters, sort, order, recent_exports]);

	function toggleExpand(id, expanded) {
		setHistoryState(draft => {
			if (expanded && draft.expanded !== id) {
				draft.expanded = id;
			}

			if (!expanded && draft.expanded === id) {
				draft.expanded = '';
			}
		});
	}

	const didExpandDownload = useRef(false);

	useEffect(() => {
		const expandExportDownload =
			downloadUuid && !didExpandDownload.current
				? recent_exports.find(exportItem => exportItem.uuid === downloadUuid)
				: null;

		if (expandExportDownload) {
			toggleExpand(expandExportDownload.uuid, true);
			didExpandDownload.current = true;
		}
	}, [downloadUuid, recent_exports, didExpandDownload.current]);

	return (
		<>
			<ListFilterProvider
				{...filterState}
				singleFilters={['export_owner', 'export_type', 'export_data', 'search']}
				multiFilters={['export_status']}
				filterLabels={{
					export_type: {
						label: t('Export type'),
						single: t('Single export'),
						scheduled: t('Scheduled export'),
					},
					export_owner: {
						label: t('Owner'),
						me: t('Exported by me'),
						other: t('Exported by others'),
					},
					export_data: {
						label: t('Export data'),
						withdata: t('With export data'),
						withoutdata: t('Without export data'),
					},
					export_status: {
						label: t('Export status'),
						SCHEDULED: t('Scheduled'),
						PENDING: t('Pending'),
						STARTED: t('Started'),
						RETRY: t('Retry'),
						FINISHED: t('Finished'),
						FAIL: t('Failed'),
						REVOKED: t('Revoked'),
					},
				}}
			>
				<ListWithSidebar
					drawerContent={<RecentListFilters />}
					open={filtersOpen}
					prominent={!layout.prominentHidden}
					onClose={() => setFiltersOpen(false)}
				>
					<ListControls
						onRefresh={loadData}
						sortOptions={[
							{ label: t('Export date: new to old'), value: 'created-asc' },
							{ label: t('Export date: old to new'), value: 'created-desc' },
						]}
						toggleFilterDrawer={() => setFiltersOpen(prev => !prev)}
					>
						<ActionButton
							action="export"
							onClick={() => setDialogOpen('single')}
							dataTrackEvent="export_new_export"
						>
							{t('New export')}
						</ActionButton>
					</ListControls>

					{!initialLoaded && loading && <Loader empty />}
					{initialLoaded && filtered_exports.length > 0 && (
						<Grid
							container
							direction="column"
							spacing={2}
						>
							<Grid item>
								{filtered_exports.map(exportItem => (
									<ExportExpansionPanel
										key={exportItem.uuid}
										exportData={exportItem}
										variant="recent"
										expanded={historyState.expanded === exportItem.uuid}
										toggleExpand={(e, expanded) =>
											toggleExpand(exportItem.uuid, expanded)
										}
										startExportDownload={startExportDownload}
										setDialogOpen={setDialogOpen}
										setDialogData={setDialogData}
										setWithUuid={setWithUuid}
									/>
								))}
							</Grid>
						</Grid>
					)}
					{initialLoaded && filtered_exports.length === 0 && (
						<EmptyStateHandleHasFeedback
							image={<img src={`/assets/img/r/emptystate/feedback_exports.gif`} />}
							primaryNoSurvey={t`reporting-feedback_exports-recent-emptystate_no_survey-title`}
							primary={
								recent_exports.length > 0
									? t`reporting-feedback_exports-recent-emptystate_filter-title`
									: t`reporting-feedback_exports-recent-emptystate-title`
							}
							primaryNoFeedback={
								recent_exports.length > 0
									? t`reporting-feedback_exports-recent-emptystate_filter-title`
									: t`reporting-feedback_exports-recent-emptystate-title`
							}
							secondaryNoSurvey={t`reporting-feedback_exports-recent-emptystate_no_survey-text`}
							secondary={
								recent_exports.length > 0
									? t`reporting-feedback_exports-recent-emptystate_filter-text`
									: t`reporting-feedback_exports-recent-emptystate-text`
							}
							secondaryNoFeedback={
								recent_exports.length > 0
									? t`reporting-feedback_exports-recent-emptystate_filter-text`
									: t`reporting-feedback_exports-recent-emptystate-text`
							}
							noSurveyTrackEvent="exports_emptystate_create_form"
							action={
								<ActionButton
									variant="contained"
									color="primary"
									dataTrackEvent="recent_exports_emptystate_create_export"
									onClick={() => {
										setDialogOpen(true);
									}}
								>
									{t`reporting-feedback_exports-recent-emptystate_button`}
								</ActionButton>
							}
						/>
					)}
				</ListWithSidebar>

				<FeedbackExportDialog
					open={dialogOpen}
					onClose={reload => {
						setDialogOpen(false);
						setDialogData({});
						if (reload) {
							loadData();
						}
					}}
					variant={dialogOpen}
					exportData={dialogData}
					withUuid={withUuid}
					maxSetupsReached={maxSetupsReached}
				/>
			</ListFilterProvider>
		</>
	);
}
