import React, { createContext, useContext } from 'react';

export const NotificationsContext = createContext({});

export function NotificationsContextProvider({ children, value = {}, ...props }) {
	return (
		<NotificationsContext.Provider value={value}>
			{children}
		</NotificationsContext.Provider>
	);
}

export function useNotificationContext() {
	return useContext(NotificationsContext);
}
