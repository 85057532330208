import React, { Fragment, useState, useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ActionIconButton from './ActionIconButton';

const useStyles = makeStyles(theme => ({
	fab: {
		position: 'fixed',
		right: 0,
		bottom: 0,
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		borderBottomLeftRadius: 0,
		zIndex: 10,
	},
	fabIcon: {
		marginRight: theme.spacing(1),
	},
	popper: {
		zIndex: 11,
		position: 'fixed',
	},
	closed: {
		zIndex: -1,
	},
	paper: {
		width: '35vw',
		height: '60vh',
		overflowX: 'hidden',
		overflowY: 'scroll!important',
		borderRadius: 0,
		[theme.breakpoints.down('sm')]: {
			width: '50vw',
		},
		[theme.breakpoints.down('xs')]: {
			width: '98vw',
		},
	},
	appBarSpacer: {
		...theme.mixins.toolbarNormal('height'),
	},
}));

export default function ExpandingBottomSheet({
	children,
	icon,
	label,
	onChange = () => {},
	ButtonProps = {},
	disabled,
	...props
}) {
	const { open: openProp } = props;

	const classes = useStyles();

	const isControlled = typeof openProp !== 'undefined';
	const [openState, setOpenState] = useState(false);
	const [hasBeenOpened, setHasBeenOpened] = useState(false);
	useEffect(() => {
		if (openState) {
			setHasBeenOpened(true);
		}
	}, [openState]);

	const FabRef = useRef(null);
	const FabIcon = icon ? icon : Fragment;

	const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

	return (
		<Fragment>
			<Fab
				color="primary"
				variant="extended"
				className={classes.fab}
				disabled={disabled}
				onClick={() => {
					setOpenState(true);
					onChange(true);
				}}
				ref={FabRef}
				{...ButtonProps}
			>
				<FabIcon className={classes.fabIcon} />
				{label}
			</Fab>

			<Popper
				open={isControlled ? openProp : openState}
				anchorEl={FabRef.current}
				transition
				className={`${classes.popper} ${!(openProp || openState) ? classes.closed : ''}`}
				modifiers={{
					offset: {
						offset: FabRef.current ? `5, -${FabRef.current.clientHeight}` : 0,
					},
					preventOverflow: {
						enabled: true,
						boundariesElement: 'scrollParent',
					},
				}}
				keepMounted={hasBeenOpened}
				{...props}
				//	placement="top-end"
			>
				{({ TransitionProps }) => (
					<Grow
						{...TransitionProps}
						timeout={350}
						style={{ transformOrigin: 'bottom right' }}
					>
						<Paper
							className={classes.paper}
							elevation={5}
						>
							<AppBar position="fixed">
								<Toolbar>
									<ActionIconButton
										color="inherit"
										action="down"
										onClick={() => {
											setOpenState(false);
											onChange(false);
										}}
									/>
									<Typography variant="h6">{label}</Typography>
								</Toolbar>
							</AppBar>
							<div className={classes.appBarSpacer} />
							{children}
						</Paper>
					</Grow>
				)}
			</Popper>
		</Fragment>
	);
}
