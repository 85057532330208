import React, { useState, Fragment, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useAppContext } from '../AppContext';
import { Drawer, AppBar, Portal, Zoom, ActionButton } from '../Layout';
import { SelectionToolbar } from '../SelectionToolbar';
import HelpMenu from './HelpMenu';

function determineMaxWidth(size) {
	switch (size) {
		case 'large':
			return '85%';
		case 'medium':
			return '75%';
		default:
			return 800;
	}
}

const useStyles = makeStyles(theme => ({
	drawer: {
		maxWidth: props => determineMaxWidth(props.size),
		width: '95%',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
		backgroundColor: theme.palette.background.default,
		overflowY: 'hidden',
	},
	// paper: {
	// 	height:'100%'
	// },
	root: {
		overflow: 'hidden',
		transform: 'translateZ(0)',
		height: '100%',
	},
	content: {
		flex: 1,
		maxWidth: '100%',
		height: '100%',
		padding: theme.spacing(1),
		overflowY: 'auto',
		overflowX: 'hidden',
	},
	appBar: {
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	appBarSpacer: {
		...theme.mixins.toolbar,
	},
	fab: {
		position: 'fixed',
		left: theme.spacing(2),
		bottom: theme.spacing(2),
		zIndex: theme.zIndex.modal + 1,
	},
}));

export default function DrawerWithAppBar({
	children,
	size,
	className,
	onClose = () => {},
	ToolbarProps = {},
	ToolbarContent,
	showHelp = true,
	...props
}) {
	const { app } = useAppContext();
	const classes = useStyles({ size });
	const [isEntered, setIsEntered] = useState(false);
	const [anchor, setAnchor] = useState(null);
	const drawerScrollerRef = useRef(null);

	useEffect(() => {
		if (props.open) {
			app.dispatch({
				type: 'set_drawer_scroller_ref',
				payload: drawerScrollerRef,
			});
		}

		return () => {
			app.dispatch({
				type: 'set_drawer_scroller_ref',
				payload: {},
			});
		};
	}, [drawerScrollerRef, props.open]);

	return (
		<Fragment>
			<Drawer
				{...props}
				onClose={onClose}
				anchor="right"
				classes={{ paper: `${classes.drawer}` }}
			>
				<div className={classes.root}>
					<AppBar
						color="default"
						position="fixed"
						elevation={0}
						className={classes.appBar}
					>
						<SelectionToolbar
							onClose={onClose}
							hideGlobals
							{...ToolbarProps}
						>
							{ToolbarContent}
						</SelectionToolbar>
					</AppBar>
					<div
						className={classes.content}
						ref={drawerScrollerRef}
						data-onboarding="drawer-with-appbar-scroller"
					>
						<div className={classes.appBarSpacer} />
						{children}
					</div>
				</div>
			</Drawer>
			{props.open && (
				<Portal>
					<Zoom in>
						<ActionButton
							fab
							action="help"
							color="default"
							className={classes.fab}
							variant="round"
							key="help-fab"
							onClick={e => setAnchor(e.target)}
						/>
					</Zoom>

					<HelpMenu
						withButtons={false}
						anchor={anchor}
						onClose={() => setAnchor(null)}
					/>
				</Portal>
			)}
		</Fragment>
	);
}
