import React, { Fragment, useState } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTranslation } from 'react-i18next';

import { UserAutoComplete } from '../AutoComplete';
import { EFM } from '../../api';
import { useSnackbar } from 'notistack';
import { ButtonWithLoader } from '../Layout';
import { AjaxForm } from '../Ajax';

function FollowersDialog({ open, close, update, feedback_id, onAdd = () => {} }) {
	const { t } = useTranslation();
	const [followers, setFollowers] = useState([]);
	const { enqueueSnackbar } = useSnackbar();

	const ids = followers.map(user => user.id);
	return (
		<AjaxForm
			url="/application/notes/add-follower"
			data={{
				user_id: ids.join(','),
				feedback_id: feedback_id,
			}}
			onSuccess={r => {
				enqueueSnackbar(t`Followers added`);
				onAdd(ids);
				close();
			}}
		>
			{({ clicked, loading, postForm }) => (
				<Dialog
					open={open}
					onClose={close}
					maxWidth={'sm'}
					fullWidth={true}
				>
					<DialogTitle>{t('Add followers')}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{t('Add users that follow notes on this feedback item')}
						</DialogContentText>

						<UserAutoComplete
							multiple
							placeholder={t('Search users')}
							selectedItem={followers}
							disableCustomEntry
							onChange={users => {
								setFollowers(users);
							}}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={close}
							color="default"
						>
							{t('Cancel')}
						</Button>
						<ButtonWithLoader
							onClick={postForm}
							color="primary"
							variant="contained"
							loading={loading}
							dataTrackEvent="inbox_feedback_note_follower"
						>
							{t('Add followers')}
						</ButtonWithLoader>
					</DialogActions>
				</Dialog>
			)}
		</AjaxForm>
	);
}

export default FollowersDialog;
