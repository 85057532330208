import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Popover } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import {
	Typography,
	Card,
	CardContent,
	CardActions,
	ActionButton,
} from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	buttonRight: {
		marginLeft: 'auto!important',
	},
	ignoreClick: {
		pointerEvents: 'none',
	},
	pointer: {
		height: 0,
		width: 0,
		minWidth: 0,
		minHeight: 0,
		borderRadius: 0,
		background: 'transparent',
	},
	bottomPointer: {
		borderLeft: '12px solid transparent',
		borderRight: '12px solid transparent',
		borderBottom: '12px solid transparent',
		borderTop: '12px solid #ffffff',
		bottom: props => (props.style === 'float' ? 76 : 36),
		top: 'auto!important',
		margin: 0,
	},
	bottomRightPointer: {
		right: 30,
		left: 'auto!important',
	},
	bottomLeftPointer: {
		left: '30px!important',
	},
	leftPointer: {
		borderTop: '12px solid transparent',
		borderBottom: '12px solid transparent',
		borderRight: '12px solid #ffffff',
		borderLeft: '12px solid transparent',
		marginTop: 10,
		left: props => (props.style === 'float' ? '76px!important' : '36px!important'),
	},
	rightPointer: {
		borderTop: '12px solid transparent',
		borderBottom: '12px solid transparent',
		borderLeft: '12px solid #ffffff',
		borderRight: '12px solid transparent',
		right: props => (props.style === 'float' ? 76 : 36),
		marginTop: 10,
		left: 'auto!important',
	},
	right: {
		right: props => (props.style === 'float' ? 100 : 60),
		left: 'auto!important',
	},
	left: {
		left: props => (props.style === 'float' ? '100px!important' : '60px!important'),
	},
	bottom: {
		bottom: props => (props.style === 'float' ? 100 : 60),
		top: 'auto!important',
	},
}));

export default function PreviewHelper({
	buttonSettings,
	formType,
	setDismissedHelper = () => {},
	anchorEl,
	setAnchorEl = () => {},
}) {
	const { t } = useTranslation();
	const classes = useStyles({ style: buttonSettings?.style ?? 'label', type: formType });

	const popperClass = buttonSettings?.position.includes('bottom')
		? 'bottom'
		: buttonSettings?.position ?? '';

	const pointerClass =
		buttonSettings?.position === 'bottom-right'
			? 'bottomRight'
			: buttonSettings?.position === 'bottom-left'
			? 'bottomLeft'
			: buttonSettings?.position ?? '';

	return (
		<>
			<Popover
				open={anchorEl !== null}
				classes={{
					paper: `${classes[popperClass]}`,
				}}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{
					vertical: 'top',
					horizontal: buttonSettings?.position?.includes('right') ? 'right' : 'left',
				}}
				transformOrigin={{
					vertical: buttonSettings?.position.includes('bottom') ? 'bottom' : 'top',
					horizontal: buttonSettings?.position?.includes('right') ? 'right' : 'left',
				}}
			>
				<Card className={classes.root}>
					<CardContent>
						<Typography
							gutterBottom
							variant="subtitle"
							component="h2"
						>
							{t`data_collection-preview-helper-title`}
						</Typography>
						<Typography
							variant="body2"
							color="textSecondary"
							component="p"
						>
							{t`data_collection-preview-helper-text`}
						</Typography>
					</CardContent>
					<CardActions>
						<ActionButton
							disableTextTransform
							color="primary"
							className={classes.buttonRight}
							variant="contained"
							onClick={() => setDismissedHelper(true)}
						>
							{t`data_collection-preview-helper-button`}
						</ActionButton>
					</CardActions>
				</Card>
			</Popover>
			<Popover
				open={anchorEl !== null}
				elevation={0}
				classes={{
					root: classes.ignoreClick,
					paper: `${classes.pointer} ${
						popperClass === 'bottom' ? classes.bottomPointer : ''
					} ${classes[`${pointerClass}Pointer`]}`,
				}}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: buttonSettings?.position?.includes('right') ? 'right' : 'left',
				}}
			></Popover>
		</>
	);
}
