import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ButtonFloatRightIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<path
				d="M40,40v-4h-1v4H40z M36,40v-1h-4v1H36z M28,40v-1h-4v1H28z M20,40v-1h-4v1H20z M12,40v-1H8v1H12z M4,40v-1H0
				v1H4z M1,36v-4H0v4H1z M40,32v-4h-1v4H40z M1.002,27.83v-4h-1v4H1.002z M40,24v-4h-1v4H40z M1.002,19.83v-4h-1v4H1.002z M40,16v-4
				h-1v4H40z M1,12V8H0v4H1z M29.623,20.294c0,2.036,1.65,3.687,3.687,3.688c2.037,0,3.688-1.65,3.689-3.688c0,0,0-0.001,0-0.002
				c0-2.037-1.652-3.688-3.689-3.688C31.273,16.605,29.622,18.257,29.623,20.294z M40,8V4h-1v4H40z M1,4V0H0v4H1z M40,1V0h-4v1H40z
				 M32,1V0h-4v1H32z M24,1V0h-4v1H24z M16,1V0h-4v1H16z M8,1V0H4v1H8z"
			/>
		</SvgIcon>
	);
}
