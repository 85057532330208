import React, { useState, Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '../AppContext';
import ActionButton from './ActionButton';
import Typography from './Typography';
import Link from './Link';
import { Explainer } from '../Explainer';
import ContactDialog from './ContactDialog';
import { UpgradeDialog } from '../Billing';

const useStyles = makeStyles(theme => ({
	helperText: {
		color: 'rgba(0, 0, 0, 0.54)',
	},
	margin: {
		marginBottom: theme.spacing(3),
	},
	marginLeft: {
		marginLeft: theme.spacing(1),
	},
	marginTop: {
		marginTop: theme.spacing(0.5),
	},
	pointer: {
		cursor: 'pointer',
	},
}));

function ButtonOrLink({ children, button, ...props }) {
	const classes = useStyles();
	return button ? (
		<ActionButton
			variant="outlined"
			color="primary"
			{...props}
		>
			{children}
		</ActionButton>
	) : (
		<Link
			className={`${classes.marginLeft} ${classes.pointer}`}
			{...props}
		>
			{children}
		</Link>
	);
}

function UpgradeHelper({
	text,
	textVariant,
	useStandardText,
	useButtons,
	ButtonProps = {},
	upgradeButtonText,
	upgradeButtonProps = {},
	subject,
	dataTrackEvent = '',
	ExplainerProps = {},
}) {
	const classes = useStyles();
	const { app } = useAppContext();
	const { t } = useTranslation();

	const [openContact, setOpenContact] = useState(false);
	const [openUpgrade, setOpenUpgrade] = useState(false);

	return (
		<Fragment>
			<Box
				{...(useButtons && {
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				})}
			>
				{text || useStandardText ? (
					<Typography
						className={`${classes.helperText} ${
							Object.keys(ExplainerProps).length === 0 ? classes.margin : ''
						}`}
						variant={textVariant ? textVariant : 'caption'}
						display={useButtons ? 'block' : 'inline'}
					>
						{text
							? text
							: app.api.packageIsAllowed('contact') && app.api.packageIsAllowed('upgrade')
							? t`Please upgrade your package or contact our support`
							: t`Please upgrade your package for additional features`}
					</Typography>
				) : null}
				{Object.keys(ExplainerProps).length > 0 && (
					<Box mb={3}>
						<Explainer {...ExplainerProps} />
					</Box>
				)}
				{app.package.is_stripe_customer && (
					<ButtonOrLink
						button={useButtons}
						onClick={e => setOpenUpgrade(true)}
						data-track-event={`${dataTrackEvent}_upgrade_package`}
						{...ButtonProps}
						{...upgradeButtonProps}
					>
						{upgradeButtonText || t`upgrade_helper-upgrade_button`}
					</ButtonOrLink>
				)}
				{!app.package.is_stripe_customer && (
					<ButtonOrLink
						onClick={() => setOpenContact(true)}
						button={useButtons}
						{...(useButtons && {
							variant: 'text',
							color: 'default',
							className: classes.marginTop,
						})}
						{...ButtonProps}
					>
						{t`Contact support`}
					</ButtonOrLink>
				)}
			</Box>
			<UpgradeDialog
				open={openUpgrade}
				onClose={() => setOpenUpgrade(false)}
			/>
			<ContactDialog
				open={openContact}
				onClose={() => setOpenContact(false)}
				subject={subject}
				dataTrackEvent={`${dataTrackEvent}_upgrade_contact`}
			/>
		</Fragment>
	);
}

export default UpgradeHelper;
