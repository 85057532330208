import React from 'react';

import IconButton from '@material-ui/core/IconButton';

import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { useAppContext } from '@/components/AppContext';
import {
	RenderConditional,
	ActionIconButton,
	Typography,
	Grid,
	Paper,
} from '@/components/Layout';

function pageRange(startPage, endPage) {
	return Array.from(Array(endPage + 1 - startPage).keys()).map(i => startPage + i);
}

const useStyles = makeStyles(theme => ({
	wrapper: {
		padding: theme.spacing(1.5, 1),
	},
	button: {
		fontSize: theme.typography.pxToRem(13),
		width: 24,
		height: 24,
		lineHeight: '24px',
		display: 'inline-block',
	},
	isSelected: {
		background: theme.palette.action.selected,
	},
}));

export default function Pagination({
	firstPageInRange = 1,
	pageCount = 1,
	current,
	base,
	lastPageInRange,
	firstItemNumber,
	lastItemNumber,
	totalItemCount,
	paper,
	pageId: pageIdFromProps,
	onChange = () => {},
	className = '',
}) {
	const classes = useStyles();
	const { pageId = 1 } = useParams();
	const { t } = useTranslation();
	const history = useHistory();
	const { app } = useAppContext();

	const whichPageId = Number(pageIdFromProps ?? pageId);

	function scrollToTop() {
		if (pageIdFromProps) {
			//pageIdFromProps is passed pagination is a in a drawer
			if (app.layout.drawerScrollerRef.current) {
				try {
					app.layout.drawerScrollerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
				} catch (e) {}
			}
		} else {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}

	function toPage(page) {
		if (pageIdFromProps) {
			onChange(page);
		} else {
			history.push(`${base}/${page}`);
		}
		scrollToTop();
	}

	//if (pageCount == 1 || pageCount == 0) return null;

	let pages = pageRange(firstPageInRange, lastPageInRange ? lastPageInRange : pageCount);

	if (firstPageInRange !== 1) {
		let extraButtons = pages[0] !== 2 ? [1, '...'] : [1];
		pages = [...extraButtons, ...pages];
	}

	if (lastPageInRange && lastPageInRange !== pageCount) {
		let extraButtons =
			pages[pages.length - 2] !== pageCount - 1 ? ['...', pageCount] : [pageCount];
		pages = [...pages, ...extraButtons];
	}

	return (
		<RenderConditional
			condition={paper}
			component={Paper}
			className={className}
		>
			<Grid
				container
				direction="row"
				className={classes.wrapper + (!paper ? className : '')}
				alignItems="center"
			>
				{[0, 1].indexOf(pageCount) === -1 && (
					<Grid
						item
						xs
					>
						{whichPageId !== 1 && (
							<ActionIconButton
								action="previous"
								onClick={() => toPage(whichPageId - 1)}
							/>
						)}
						{pages.map((page, i) => {
							const IconButtonProps = {
								...(page !== '...' && {
									key: page,
									onClick: () => toPage(page),
								}),
								...(page === '...' && {
									component: 'button',
									key: page + i,
									disabled: true,
								}),
								className: page === whichPageId ? classes.isSelected : '',
							};

							return (
								<IconButton {...IconButtonProps}>
									<span className={classes.button}>{page}</span>
								</IconButton>
							);
						})}
						{whichPageId !== pageCount && (
							<ActionIconButton
								action="next"
								onClick={() => toPage(whichPageId + 1)}
							/>
						)}
					</Grid>
				)}
				<Grid item>
					<Typography
						variant="body2"
						color="textSecondary"
					>
						{firstItemNumber} - {lastItemNumber} {t`of`} {totalItemCount}
					</Typography>
				</Grid>
			</Grid>
		</RenderConditional>
	);
}
