import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

import SimpleDialog from './SimpleDialog';
import Typography from './Typography';
import Link from './Link';
/*
	A dialog for informing the user about Mopinion Raspberry and its current status
*/

const useStyles = makeStyles(theme => ({
	img: {
		maxWidth: 400,
	},
	wrap: {
		textAlign: 'center',
	},
	spacer: {
		margin: theme.spacing(4, 0),
	},
	blog: {
		marginTop: theme.spacing(3),
	},
}));

export default function RaspberryInfoDialog(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<SimpleDialog
			cancel={t`Got it!`}
			{...props}
		>
			<div className={classes.wrap}>
				<img
					src="/assets/img/logo/raspberry-text.svg"
					className={classes.img}
				/>

				<div className={classes.spacer}>
					<Typography
						variant="h5"
						gutterBottom
					>{t`Welcome to Mopinion Raspberry!`}</Typography>
					<Typography>{t`You're now using Mopinion Raspberry, our all-new and improved interface. Currently the interface is still in the beta phase. This means you could run into the occasional bug. We're working hard on fixing all remaining issues and features and we'll be lauching the Mopinion Raspberry interface officially soon. In the mean time you can always switch back to our Classic interface.`}</Typography>

					<Typography className={classes.blog}>
						{t`Read more about Mopinion Raspberry`}{' '}
						<Link
							target="_blank"
							href="https://mopinion.com/blog/?s=raspberry"
						>{t`on our blog`}</Link>
						.
					</Typography>
				</div>
			</div>
		</SimpleDialog>
	);
}
