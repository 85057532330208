import React, { Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import PausedIcon from '@material-ui/icons/Pause';

import { DataSourceAvatar } from '../DataSources';
import { OptionalDraggable } from '../Layout';

import { useTranslation } from 'react-i18next';
import { ContainerQuery } from 'react-container-query';

const useStyles = makeStyles(theme => ({
	spacer: {
		paddingBottom: theme.spacing(3),
	},
	chip: {
		margin: theme.spacing(0.25),
	},
	condition: {
		position: 'relative',
		'&:not(:first-child)': {
			marginTop: theme.spacing(2),
		},
	},
	paused: {
		position: 'absolute',
		backgroundColor: 'rgba(255,255,255,.9)',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		zIndex: 10,
	},
	extraDense: {
		paddingTop: theme.spacing(0.25),
		paddingBottom: theme.spacing(0.25),
	},
	conditionListText: {
		//	color:theme.palette.text.secondary
	},
	conditionListIcon: {
		//color:theme.palette.secondary.light
	},
	alignMiddleInline: {
		verticalAlign: 'middle',
	},
}));

export default function TagsEditorItem({
	selected,
	onClick = () => {},
	onDelete = () => {},
	form = {},
	rule = {},
	index,
	...props
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	//return null;
	return (
		<OptionalDraggable
			disablePortal
			draggable
			draggableId={'draggable' + rule.id}
			index={index}
		>
			<div className={classes.spacer}>
				<Card
					elevation={selected ? 6 : 1}
					className={classes.card}
					onClick={onClick}
				>
					<CardContent>
						<ContainerQuery
							query={{
								twoCol: { minWidth: 500 },
							}}
						>
							{matches => (
								<Fragment>
									<Grid
										container
										alignItems="center"
										spacing={3}
									>
										<Grid item>
											<DataSourceAvatar
												survey_type={form.survey_type}
												survey_format={form.survey_format}
												campaign={form.campaign}
											/>
										</Grid>
										<Grid
											item
											xs
										>
											<Typography
												variant="h6"
												display="block"
												gutterBottom
											>
												{form.name}
											</Typography>
											<Typography
												variant="subtitle1"
												display="block"
												color="textSecondary"
											>
												{form.description}
											</Typography>
										</Grid>
										<Grid item>
											<IconButton onClick={onDelete}>
												<Tooltip title={t`Remove from configuration`}>
													<RemoveIcon />
												</Tooltip>
											</IconButton>
										</Grid>
									</Grid>

									<Box mt={2}>
										<Grid
											container
											spacing={rule.add.length > 1 ? 1 : 0}
										>
											{rule.add.map((conditionSet, conditionIndex) => {
												return (
													<Grid
														item
														key={'griditem' + rule.id}
														xs={matches.twoCol && rule.add.length > 1 ? 6 : 12}
													>
														<div
															className={classes.condition}
															key={rule.id}
														>
															{conditionSet.pause && (
																<div className={classes.paused}>
																	<div>
																		<div>
																			<PausedIcon
																				color="action"
																				className={classes.alignMiddleInline}
																			/>
																			<Typography
																				variant="subtitle2"
																				display="inline"
																				className={classes.alignMiddleInline}
																				color="textSecondary"
																			>{t`Paused`}</Typography>
																		</div>
																		<Typography
																			variant="caption"
																			color="textSecondary"
																		>
																			{t`This condition set is paused and will not be evaluated on your website.`}
																		</Typography>
																	</div>
																</div>
															)}
															<Typography
																variant="subtitle2"
																color="textSecondary"
															>{`${t`Condition set`} ${conditionIndex + 1}`}</Typography>
															<List
																dense
																disablePadding
															>
																{Object.entries(conditionSet).map(conditionArr => {
																	const [key, value] = conditionArr;

																	return null;
																	// hasDeploymentValue(value) ?
																	// 	<ListItem key={rule.id+conditionIndex+key} disableGutters className={classes.extraDense}>
																	// 		<ListItemIcon className={classes.conditionListIcon}>
																	// 			{
																	// 				//<DeploymentIcon type={key} value={value} />
																	// 			}
																	// 		</ListItemIcon>
																	// 		<ListItemText
																	// 			className={classes.conditionListText}
																	// 			primary={conditionTypeLabel(key, value).map(v => t(v)).join(' ')}
																	// 		/>
																	// 	</ListItem>
																	// :
																	//		null
																})}
															</List>
														</div>
													</Grid>
												);
											})}
										</Grid>
									</Box>
								</Fragment>
							)}
						</ContainerQuery>
					</CardContent>
				</Card>
			</div>
		</OptionalDraggable>
	);
}
