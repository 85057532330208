import { removeBr, tryParse } from '@/utils';
import { blockToCharts } from './constants';

export const findBlockTypeOrSystemVar = datafield => {
	if (!datafield) return null;
	const blockType =
		datafield?.survey_block?.type?.toString() ??
		datafield?.block_type?.toString() ??
		null;

	if (blockToCharts[blockType]) {
		return blockType;
	}
	return datafield.system_var ?? null;
};

export const getTitle = datafield => {
	const title = datafield.survey_block?.title ?? datafield.title ?? '';
	return removeBr(title);
};

export const getSubtitle = (subTitleKey, period) => {
	if (!subTitleKey) {
		return '';
	}
	if (!period) {
		return subTitleKey;
	}
	return `${subTitleKey}-${period}`;
};

export const getAxisField = (datafieldId, type, datafields) => {
	if (!type || !datafields) return datafieldId;

	switch (type) {
		case 'datetime':
			return datafields.find(field => field.var_type === 7)?.id ?? datafieldId;
		case 'survey':
			return (
				datafields.find(field => field.system_var.toLowerCase() === 'survey')?.id ??
				datafieldId
			);
		case 'all':
			return datafields.find(field => field.system_var.toLowerCase() === 'role')?.id ?? 0;
		default: // self
			return datafieldId;
	}
};

export const createAxisWithDimensions = (axis, chartOptions, datafield) => {
	const options = chartOptions.serieOptions;
	const axisType = chartOptions.serieOptions[axis];
	const baseAxis =
		axis === 'x'
			? chartOptions.xAxis ?? {}
			: axis === 'y'
			? chartOptions.yAxis ?? {}
			: {};

	if (!options || !datafield || !axisType) {
		return {};
	}

	if (axisType !== 'self') {
		return baseAxis;
	}

	const blockType = findBlockTypeOrSystemVar(datafield);
	const average = options.average;

	const custumSerieAverage = chartOptions.customSeries
		? chartOptions.customSeries
				.filter(options => options.average)
				.map(option => option.average)
		: [];

	const scale = datafield?.survey_block?.properties?.scale ?? 10;

	//1 average/mean
	if (average === 1 && Number(blockType) === 6) {
		return {
			...baseAxis,
			min: 0,
			max: scale,
		};
	}

	// average[2,3] blockType[10]: NPS
	// average[10,0] blockType[14]: CES
	// average[9,0] blockType[16]: GCR
	if ([2, 3, 9, 10].includes(average) && [10, 14, 16].includes(Number(blockType))) {
		return {
			...baseAxis,
			min: average === 2 ? -100 : 0,
			max: 100,
		};
	}

	//17, 18, 19 GCR
	if (
		(custumSerieAverage.includes(17) ||
			custumSerieAverage.includes(18) ||
			custumSerieAverage.includes(19)) &&
		Number(blockType) === 16
	) {
		return {
			...baseAxis,
			min: 0,
			max: 100,
		};
	}

	return baseAxis;
};

export function findMatrixOrLikert(datasource) {
	if (!datasource) {
		return false;
	}

	const matrixOrLikert = datasource.find(source =>
		[11, 12].includes(Number(source?.survey_block?.type))
	);

	return matrixOrLikert ? true : false;
}
