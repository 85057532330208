import React, { useState } from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import Box from './Box';
import Collapse from './Collapse';
import ActionButton from './ActionButton';
import Link from './Link';

const useStyles = makeStyles(theme => ({
	button: {
		justifyContent: 'space-between',
		// fontWeight: theme.typography.body1.fontWeight,
		fontSize: theme.typography.body1.fontSize,
		marginLeft: '-6px',
		width: 'calc(100% + 12px)',
	},
	minClosedHeight: {
		minHeight: props => `${props.minClosedHeight ?? 0}px!important`,
		visibility: 'visible',
	},
	collapse: {
		textDecoration: 'none',
	},
	expand: {
		verticalAlign: 'middle',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
}));

export default function CollapsibleBox({
	children,
	label,
	disabled,
	ActionButtonProps = {},
	startCollapsed = false,
	collapseButtonPosition = 'start',
	collapseButtonVariant = 'button',
	minClosedHeight,
	exposeCollapsed = () => {},
	...props
}) {
	const classes = useStyles({ minClosedHeight });
	const [collapsed, setCollapsed] = useState(startCollapsed);

	return (
		<Box
			width="100%"
			{...props}
		>
			{collapseButtonPosition === 'start' && (
				<CollapseToggle
					collapsed={collapsed}
					disabled={disabled}
					label={label}
					ActionButtonProps={ActionButtonProps}
					setCollapsed={setCollapsed}
					exposeCollapsed={exposeCollapsed}
					variant={collapseButtonVariant}
				/>
			)}
			<Collapse
				in={!collapsed}
				className={minClosedHeight ? classes.minClosedHeight : ''}
			>
				{typeof children === 'function' ? children({ collapsed }) : children}
			</Collapse>
			{collapseButtonPosition === 'end' && (
				<CollapseToggle
					collapsed={collapsed}
					disabled={disabled}
					label={label}
					ActionButtonProps={ActionButtonProps}
					setCollapsed={setCollapsed}
					exposeCollapsed={exposeCollapsed}
					variant={collapseButtonVariant}
				/>
			)}
		</Box>
	);
}

function CollapseToggle({
	collapsed,
	disabled,
	label,
	ActionButtonProps,
	setCollapsed,
	exposeCollapsed,
	variant,
}) {
	const classes = useStyles();
	const { t } = useTranslation();

	if (variant === 'link') {
		return (
			<Link
				color="textSecondary"
				variant="caption"
				onClick={() => {
					setCollapsed(prev => !prev);
					exposeCollapsed(!collapsed);
				}}
				disabled
				className={classes.collapse}
				fontWeight="medium"
			>
				{!collapsed
					? t`app_general-collapse-show_less`
					: t`app_general-collapse-show_more`}
				<ExpandMoreIcon
					fontSize="small"
					className={`${classes.expand} ${!collapsed ? classes.expandOpen : ''}`}
				/>
			</Link>
		);
	}

	return (
		<ActionButton
			action={collapsed ? 'expand_more' : 'expand_less'}
			fullWidth
			onClick={() => {
				setCollapsed(prev => !prev);
				exposeCollapsed(!collapsed);
			}}
			color="inherit"
			disableTextTransform
			className={`${classes.button} ${ActionButtonProps.classes ?? ''}`}
			disabled={disabled}
			{...ActionButtonProps}
		>
			{label}
		</ActionButton>
	);
}
