import React, { useState, useRef } from 'react';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useTranslation } from 'react-i18next';

import DashboardMenu from './DashboardMenu';

export default function DashboardAssignChart({
	title = '',
	onChange = () => {},
	disabled = false,
}) {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);

	const handleClose = event => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	return (
		<>
			<ButtonGroup
				variant="contained"
				color="primary"
				ref={anchorRef}
				disabled={disabled}
			>
				<Button onClick={() => setOpen(prevOpen => !prevOpen)}>
					{title ??
						t`reporting-dashboard-dashboard_quick_selection-button_select_dashboard`}
				</Button>
				<Button
					color="primary"
					size="small"
					onClick={() => setOpen(prevOpen => !prevOpen)}
				>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<DashboardMenu
				open={open}
				anchor={anchorRef}
				withCurrent
				onChange={onChange}
				handleClose={handleClose}
			/>
		</>
	);
}
