import React, { useState, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AngleRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles(theme => ({
	highlight: {
		backgroundColor: theme.palette.action.hover,
	},
	menu: {
		pointerEvents: 'none',
	},
	innerMenu: {
		pointerEvents: 'auto',
	},
}));

export default function NestedMenuItem({
	children,
	primary,
	secondary,
	parentOpen,
	disabled,
	forceOpen,
	leftIcon,
	rightIcon,
	anchorOrigin = 'right',
	transformOrigin = 'left',
}) {
	const subRef = useRef(null);
	const classes = useStyles();
	const [subOpen, setSubOpen] = useState(false);

	return (
		<>
			<MenuItem
				ref={subRef}
				onMouseEnter={e => {
					e.stopPropagation();
					setSubOpen(true);
				}}
				onMouseLeave={e => {
					//e.stopPropagation();
					if (!forceOpen) {
						setSubOpen(false);
					}
				}}
				onClick={e => {
					e.stopPropagation();
					if (!forceOpen) {
						setSubOpen(!subOpen);
					}
				}}
				disabled={disabled}
			>
				{leftIcon && <ListItemIcon>{leftIcon}</ListItemIcon>}
				<ListItemText
					primary={primary}
					secondary={secondary}
				/>
				{rightIcon ? rightIcon : <AngleRight />}

				<Menu
					className={classes.menu}
					PopoverClasses={{
						paper: classes.innerMenu,
					}}
					anchorEl={subRef.current}
					anchorOrigin={{
						vertical: 'top',
						horizontal: anchorOrigin,
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: transformOrigin,
					}}
					open={Boolean(subOpen && parentOpen) || Boolean(forceOpen)}
					onClose={() => setSubOpen(false)}
				>
					<div className={classes.innerMenu}>{children}</div>
				</Menu>
			</MenuItem>
		</>
	);
}
