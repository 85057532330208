import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LikertIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<path
				fill="currentColor"
				id="XMLID_663_"
				d="M31.2,15.4c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C32.7,16,32,15.4,31.2,15.4z
				 M31.2,18c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1C32.4,17.5,31.9,18,31.2,18z M31.2,21.1
				c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C32.7,21.7,32,21.1,31.2,21.1z M31.2,23.6c-0.6,0-1.1-0.5-1.1-1.1
				c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1C32.4,23.1,31.9,23.6,31.2,23.6z M31.2,27.5c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7
				s0.7-0.3,0.7-0.7S31.6,27.5,31.2,27.5z M31.2,26.7c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S32,26.7,31.2,26.7z
				 M31.2,29.3c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1S31.9,29.3,31.2,29.3z M31.2,32.7c-0.8,0-1.4,0.6-1.4,1.4
				c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C32.7,33.3,32,32.7,31.2,32.7z M31.2,35.2c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1
				s1.1,0.5,1.1,1.1C32.4,34.7,31.9,35.2,31.2,35.2z M20.7,15.4c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4
				C22.2,16,21.5,15.4,20.7,15.4z M20.7,18c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1C21.9,17.5,21.4,18,20.7,18z
				 M20.7,21.8c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7C21.5,22.1,21.1,21.8,20.7,21.8z M20.7,21.1
				c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C22.2,21.7,21.5,21.1,20.7,21.1z M20.7,23.6c-0.6,0-1.1-0.5-1.1-1.1
				c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1C21.9,23.1,21.4,23.6,20.7,23.6z M14,37.1h-2v-25h2V37.1z M38,13.1H12v-2h26V13.1z
				 M20.7,32.7c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C22.2,33.3,21.5,32.7,20.7,32.7z M20.7,35.2
				c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1C21.9,34.7,21.4,35.2,20.7,35.2z M20.7,26.7c-0.8,0-1.4,0.6-1.4,1.4
				s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S21.5,26.7,20.7,26.7z M20.7,29.3c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1
				S21.4,29.3,20.7,29.3z M2,16h7.2v1.7H2V16z M17.6,8l5.1-5.1l1.2,1.2l-5.1,5.1L17.6,8z M28,8.1L33.1,3l1.2,1.2l-5.1,5.1L28,8.1z
				 M2,21.6h7.2v1.7H2V21.6z M2,27.3h7.2V29H2V27.3z M2,33.2h7.2V35H2V33.2z"
			/>
		</SvgIcon>
	);
}
