import React from 'react';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useAjaxForm } from '@/components/Ajax';

export default function useCopySurvey({ data = {}, onSuccess = () => {} }) {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	return useAjaxForm({
		url: '/survey/ajax/duplicate-survey',
		data: {
			survey: data.survey,
			name: data.name,
			project: data.project,
		},
		onSuccess: response => {
			if (response.code === 200) {
				enqueueSnackbar(t`data_collection-survey_copy_dialog-snackbar-success`);
				onSuccess(response);
			}
		},
		onError: response => {
			enqueueSnackbar(t`data_collection-survey_copy_dialog-snackbar-error`);
		},
	});
}
