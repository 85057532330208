import React, { useEffect, useRef } from 'react';

import { useBackendBauer } from '@/components/Ajax';
import { Loader } from '@/components/Layout';
import RenderChart from './RenderChart';
import { chartWidget, dataLabelsFormatter } from './chartUtils';

function addPercToDataLabels(series = []) {
	const percCalc = series.every(
		serie => [3, 5, 9, 12, 17, 18, 19].indexOf(Number(serie.average)) > -1
	);
	const percSetting = series.every(serie => serie.custom?.perc == 1);

	return percCalc || percSetting;
}

function hasPointPercInDataLabels(chart = {}) {
	return chart?.plotOptions?.series?.stacking;
}

function isPercStacking(chart = {}) {
	return chart?.plotOptions?.series?.stacking === 'percent';
}

function getDecimal(series = []) {
	return Number(series[0]?.custom?.decimal ?? 0);
}

export default function LoadChartSeries({
	chart,
	useTooltip,
	backendBauerSeries,
	chartType = 1,
	chartRef = () => {},
	onLoad = () => {},
	onError = () => {},
	isLoading = () => {},
	forceCacheRefresh,
	fromChartEditor,
	shouldReload,
	wait,
}) {
	const notInitialRender = useRef(false);
	const [data, loading, error, loadData] = useBackendBauer({
		series: backendBauerSeries,
		chartType,
		chart,
		wait,
	});

	useEffect(() => {
		if (notInitialRender.current) {
			loadData();
		}
	}, [shouldReload]);

	useEffect(() => {
		if (notInitialRender.current) {
			loadData(true);
		}
		notInitialRender.current = true;
	}, [forceCacheRefresh]);

	const { series, categories } = data;

	if (loading === series.length) return <Loader circular />;

	const dataLabelFormatter = dataLabelsFormatter(
		chart?.plotOptions?.series?.dataLabels?.formatter,
		{
			addPercSeries: addPercToDataLabels(backendBauerSeries),
			hasPointPerc: hasPointPercInDataLabels(chart),
			decimal: getDecimal(backendBauerSeries),
			isPercStacked: isPercStacking(chart),
		}
	);

	const options =
		chartType === 1
			? {
					...chart,
					tooltip: {
						...(chart.tooltip ?? {}),
					},
					series: series.filter(Boolean),
					plotOptions: {
						...chart.plotOptions,
						series: {
							...(chart.plotOptions?.series ?? {}),
							dataLabels: {
								...(chart.plotOptions?.series?.dataLabels ?? {}),
								formatter: dataLabelFormatter,
							},
						},
					},
					xAxis: {
						...chart.xAxis,
						categories: categories,
					},
			  }
			: chartWidget(chart, series, backendBauerSeries);

	return (
		<>
			{loading !== 0 && <Loader absolute />}
			<RenderChart
				loading={loading}
				options={options}
				chartRef={chartRef}
				chartType={chartType}
				useTooltip={useTooltip}
				fromChartEditor={fromChartEditor}
			/>
		</>
	);
}
