import React from 'react';

import NoPermissionIcon from '@material-ui/icons/Block';

import { useTranslation } from 'react-i18next';

import { EmptyState, ActionButton, Loader } from '../Layout';
import { usePermissionModuleContext } from './PermissionModuleContext';
import { usePermissionSubjectContext } from './PermissionSubjectContext';
import useSendPermissionRequest from './useSendPermissionRequest';
import { permissionTypes } from './constants';

export default function NoPermissionEmptyState({
	image,
	primary: primaryFromProps,
	secondary: secondaryFromProps,
	permission = 'view',
	...props
}) {
	const { t } = useTranslation();
	const module = usePermissionModuleContext();
	const subject = usePermissionSubjectContext();
	const { postForm, response, loading } = useSendPermissionRequest({
		module,
		subject,
		permission,
	});

	const primary = primaryFromProps
		? primaryFromProps
		: permission === permissionTypes.edit
		? t('app_general-no_permission_empty_state-no_edit-title')
		: t('app_general-no_permission_empty_state-no_view-title');

	const secondary = secondaryFromProps
		? secondaryFromProps
		: permission === permissionTypes.edit
		? t('app_general-no_permission_empty_state-no_edit-subtitle')
		: t('app_general-no_permission_empty_state-no_view-subtitle');

	return (
		<EmptyState
			image={image}
			icon={image ? null : NoPermissionIcon}
			primary={primary}
			secondary={secondary}
			action={
				<ActionButton
					variant="contained"
					color="primary"
					disabled={response.code === 200}
					loading={loading}
					onClick={postForm}
					action={response.code === 200 ? 'check' : null}
					data-track-event="access_groups_request_access_via_empty_state_button"
					data-track-event-props={JSON.stringify({
						module,
						subject,
						permission,
					})}
				>
					{response.code === 200
						? t('app_general-no_permission_empty_state-permission_requested')
						: t('app_general-no_permission_empty_state-action')}
				</ActionButton>
			}
			{...props}
		/>
	);
}
