import React, { Fragment, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import get from 'lodash.get';

import {
	actionStatusAssigned,
	actionStatusAccepted,
	actionStatusDone,
	actionStatusCompleted,
	actionStatusDeclined,
} from './constants';
import { CustomerCard } from '../Customer';
import { useAppContext } from '../AppContext';
import { AppBarActions, PageHeader } from '../App';
import { UserName, UserAvatar } from '../Users';
import {
	RenderConditional,
	Loader,
	ActionButton,
	ActionIconButton,
	FormattedDate,
} from '../Layout';
import useUpdateActionStatus from './useUpdateActionStatus';
import useAddActionComment from './useAddActionComment';
import ActionActionDialogs from './ActionActionDialogs';
import ActionProgress from './ActionProgress';
import ActionProgressLabel from './ActionProgressLabel';
import { FeedbackHighlights } from '../Feedback';
import { useEFM, AjaxForm } from '../Ajax';
import { TagChip } from '../Tags';
import { Conversation, AddCommentBar } from '../Conversations';
import ActionDeadline from './ActionDeadline';
import { useHasPermission, NoPermissionTooltip } from '../Permission';

const useStyles = makeStyles(theme => ({
	statusSpace: {
		margin: theme.spacing(0, 0.5),
	},
	description: {
		fontSize: props =>
			props.isPage ? theme.typography.pxToRem(20) : theme.typography.pxToRem(18),
	},
	chipSpace: {
		'& > div': {
			marginRight: theme.spacing(0.5),
		},
	},
}));

export default function ActionDetails({
	id,
	loadParentData,
	isPage,
	onLoadingChange,
	listLoading,
}) {
	const { t } = useTranslation();
	const { app } = useAppContext();
	const classes = useStyles({ isPage });
	const [comment, setComment] = useState('');
	const [extraComments, setExtraComments] = useState([]);
	const [updateActionStatus, setUpdateActionStatus] = useState(null);
	const [actionDialogs, setActionDialogs] = useState('');
	const history = useHistory();
	const hasEditPermission = useHasPermission();

	const [data, loading, error, loadData] = useEFM(`/actions/inbox/detail/${id}`);
	useEffect(() => {
		if (listLoading) {
			loadData();
		}
	}, [listLoading, loadData]);
	useEffect(() => {
		if (typeof onLoadingChange === 'function') onLoadingChange({ loading });
	}, [loading, data]);

	const action = get(data, 'action', {});
	const userIsOwner = app.users.current.id == action.owner_user_id;
	const userIsAssigned = app.users.current.id == action.agent_user_id;

	useEffect(() => {
		setExtraComments([]);
	}, [JSON.stringify(get(data, 'actionHistory', {}))]);

	const { postForm: postUpdateActionStatus, loading: loadingActionStatus } =
		useUpdateActionStatus({
			status_id: updateActionStatus,
			id: action.id,
			loadData,
		});

	useEffect(() => {
		if (updateActionStatus && action.id) {
			postUpdateActionStatus();
		}
	}, [updateActionStatus, action.id]);

	const {
		postForm,
		clicked,
		loading: loadingPost,
	} = useAddActionComment({
		id,
		comment,
		onSuccess: () => {
			setComment('');
			loadData();
		},
	});

	function makeHistoryMessage(historyItem, id, comment) {
		//ASSIGNER = rolename, user_id
		//ASSIGNEE = agent_rolename, agent_user_id

		const owner = app.users.byKey[historyItem.owner_id];
		const assignee = app.users.byKey[historyItem.agent_user_id];

		const message = comment ? (
			comment
		) : (
			<Fragment>
				{Number(historyItem.status_id) === actionStatusAssigned && (
					<Fragment>
						<UserName
							user_id={owner?.id ?? null}
							colorUserName
						/>
						<span className={classes.statusSpace}>{t`assigned action to`}</span>
						<UserName
							user_id={assignee?.id ?? null}
							colorUserName
						/>
					</Fragment>
				)}
				{Number(historyItem.status_id) === actionStatusAccepted && (
					<Fragment>
						<UserName
							user_id={historyItem.owner_id}
							colorUserName
						/>
						<span
							className={classes.statusSpace}
						>{t`changed action status to accepted`}</span>
					</Fragment>
				)}
				{Number(historyItem.status_id) === actionStatusDone && (
					<Fragment>
						<UserName
							user_id={historyItem.owner_id}
							colorUserName
						/>
						<span
							className={classes.statusSpace}
						>{t`changed action status to done`}</span>
					</Fragment>
				)}
				{Number(historyItem.status_id) === actionStatusCompleted && (
					<Fragment>
						<UserName
							user_id={historyItem.owner_id}
							colorUserName
						/>
						<span className={classes.statusSpace}>{t`set action to completed`}</span>
					</Fragment>
				)}
				{Number(historyItem.status_id) === actionStatusDeclined && (
					<Fragment>
						<UserName
							user_id={historyItem.owner_id}
							colorUserName
						/>
						<span className={classes.statusSpace}>{t`declined the action`}</span>
					</Fragment>
				)}
			</Fragment>
		);

		return {
			id,
			message,
			start: true,
			type: 'message',
			...(comment
				? {
						name: <UserName user_id={owner?.id} />,
						avatar: <UserAvatar user_id={owner?.id} />,
						user: owner?.id,
				  }
				: {
						statusMessage: true,
				  }),
			time: historyItem.changed_on,
		};
	}

	const combinedConversation = [
		...Object.values(get(data, 'actionHistory', {})),
		...extraComments,
	];
	const historyConversation = combinedConversation.reduce(
		(conversation, historyItem, index) => {
			//const historyItem = data.actionHistory[messageKey];
			const prevItem = combinedConversation[index - 1] || {};
			const { comment } = historyItem;

			const showStatusMessage = () => {
				return (
					(historyItem.status_id &&
						Number(historyItem.status_id) !== 0 &&
						historyItem.status_id !== prevItem.status_id) ||
					(historyItem.status_id &&
						Number(historyItem.status_id) !== 0 &&
						Number(historyItem.agent_id) !== 0 &&
						historyItem.agent_id !== prevItem.agent_id)
				);
			};

			if (showStatusMessage()) {
				conversation.push(makeHistoryMessage(historyItem, index));
			}
			if (comment) {
				conversation.push(makeHistoryMessage(historyItem, 'comment' + index, comment));
			}
			return conversation;
		},
		[]
	);

	const lastHistoryItem = Object.values(get(data, 'actionHistory', {})).pop() || {};
	const hasFeedbackItem = Boolean(get(data, 'action.feedback_id', '0') !== '0');

	function getFeedbackHighLights() {
		if (Object.keys(get(data, 'feedbackVars', {})).length === 0) return [];

		return Object.keys(get(data, 'feedbackVars', {})).reduce(
			(highlightsArray, feedbackVarKey) => {
				const feedbackVar = data.feedbackVars[feedbackVarKey];

				Object.keys(feedbackVar).forEach(key => {
					const [label, var_type] = key.split('-');

					if (
						typeof feedbackVar[key] !== 'object' &&
						label.indexOf('INPUT') === -1 &&
						label.indexOf('TEXTAREA') === -1 &&
						label !== 'created' &&
						label !== 'email'
					) {
						highlightsArray.push({
							id: key,
							label,
							var_type,
							score: feedbackVar[key],
						});
					}
				});

				return highlightsArray;
			},
			[]
		);
	}

	if (loading && !data.action) {
		const BoxProps = {
			width: '100%',
			...(!isPage && {
				position: 'absolute',
				left: 0,
				mt: -1,
			}),
		};

		return (
			<Box {...BoxProps}>
				<Loader empty={isPage} />
			</Box>
		);
	}

	return (
		<Fragment>
			{isPage && (
				<PageHeader
					title={`${t`Action for`} ${get(
						app.users.byKey[action.agent_user_id],
						'firstname',
						''
					)} ${get(app.users.byKey[action.agent_user_id], 'lastname', '')}`}
					backTo="/actions/inbox"
					backTooltip={t`To actions overview`}
				/>
			)}
			{isPage && (
				<AppBarActions>
					{userIsOwner && action.status_id == 3 && (
						<ActionButton
							loading={loadingActionStatus}
							onClick={e => setUpdateActionStatus(4)}
							action="check"
							variant="outlined"
							color="secondary"
							label={t`Mark complete`}
							dataTrackEvent="action_action_completed"
						/>
					)}
					{userIsAssigned && action.status_id == 1 && (
						<ActionButton
							loading={loadingActionStatus}
							onClick={e => setUpdateActionStatus(2)}
							action="check"
							variant="outlined"
							color="primary"
							label={t`Mark accepted`}
							dataTrackEvent="action_action_accepted"
						/>
					)}
					{userIsAssigned && action.status_id == 2 && (
						<ActionButton
							loading={loadingActionStatus}
							onClick={e => setUpdateActionStatus(3)}
							action="check"
							variant="outlined"
							color="secondary"
							label={t`Mark done`}
							dataTrackEvent="action_action_done"
						/>
					)}
					{userIsAssigned && action.status_id != 4 && action.status_id != 5 && (
						<ActionIconButton
							tooltip={t`Decline action`}
							action="close"
							loading={loadingActionStatus}
							onClick={() => setUpdateActionStatus(5)}
							dataTrackEvent="action_action_declined"
						/>
					)}
					<NoPermissionTooltip>
						<ActionIconButton
							tooltip={t`Edit action`}
							action="edit"
							onClick={() => setActionDialogs('edit')}
							disabled={!hasEditPermission}
						/>
					</NoPermissionTooltip>
					<NoPermissionTooltip>
						<ActionIconButton
							tooltip={t`Reassign action`}
							action="reassign"
							onClick={() => setActionDialogs('reassign')}
							disabled={!hasEditPermission}
						/>
					</NoPermissionTooltip>
					<NoPermissionTooltip>
						<ActionIconButton
							tooltip={t`Add tags`}
							action="tags"
							onClick={() => setActionDialogs('tags')}
							disabled={!hasEditPermission}
						/>
					</NoPermissionTooltip>
					<NoPermissionTooltip>
						<ActionIconButton
							tooltip={t`Delete actions`}
							action="delete"
							onClick={() => setActionDialogs('delete')}
							disabled={!hasEditPermission}
						/>
					</NoPermissionTooltip>
					<ActionActionDialogs
						open={actionDialogs}
						onClose={() => setActionDialogs('')}
						onDelete={() => history.push('/actions/inbox')}
						ids={[action.id]}
						loadData={loadData}
					/>
				</AppBarActions>
			)}
			<Grid
				container
				spacing={hasFeedbackItem ? 6 : 8}
			>
				<Grid
					item
					xs={hasFeedbackItem ? 5 : 8}
				>
					{isPage && (
						<Box mb={3}>
							{isPage && (
								<Box mb={2}>
									<Typography variant="h4">{action.name}</Typography>
								</Box>
							)}
							<Box mb={2}>
								<ActionProgress status_id={action.status_id} />
							</Box>
							<Grid
								container
								alignItems="center"
							>
								<Grid
									item
									className={classes.chipSpace}
								>
									<ActionProgressLabel action={action} />
									<Chip
										label={t(action.action_type_name)}
										size="small"
									/>
								</Grid>
								<Grid
									item
									xs
									className={classes.chipSpace}
								>
									{data?.tags?.map(tag => (
										<AjaxForm
											url="/application/tags/delete-by-id"
											data={{
												ids: tag.id,
												actionId: action.id,
											}}
											onSuccess={loadData}
											key={'tag' + tag.id}
										>
											{({ postForm: deleteTag }) => (
												<TagChip
													{...tag}
													label={tag.name}
													small
													onDelete={deleteTag}
												/>
											)}
										</AjaxForm>
									))}
								</Grid>
							</Grid>
						</Box>
					)}
					<RenderConditional
						component={Card}
						condition={isPage}
					>
						<RenderConditional
							component={CardContent}
							condition={isPage}
						>
							<Typography variant="subtitle2">{t`Description`}</Typography>
							<Typography
								variant="subtitle1"
								display="block"
								className={classes.description}
							>
								{get(data, 'action.description')}
							</Typography>
						</RenderConditional>
					</RenderConditional>

					<Box mt={3}>
						<RenderConditional
							component={Card}
							condition={isPage}
						>
							<RenderConditional
								component={CardContent}
								condition={isPage}
							>
								<Typography variant="subtitle2">{t`Activity`}</Typography>
								<Conversation conversation={historyConversation} />
								<AddCommentBar
									bgcolor="palette.common.white"
									loading={loadingPost}
									value={comment}
									dataTrackEvent="action_action_add_note"
									onChange={e => setComment(e.currentTarget.value)}
									onSave={() => {
										setExtraComments(comments => [
											...comments,
											{
												owner_id: app.users.current.id,
												comment: comment,
												status_id: historyConversation.slice().pop()?.status_id,
												changed_on: new Date(),
											},
										]);
										postForm();
									}}
								/>
							</RenderConditional>
						</RenderConditional>
					</Box>
				</Grid>

				{hasFeedbackItem && (
					<Grid
						item
						xs={4}
					>
						<RenderConditional
							component={Card}
							condition={isPage}
						>
							<RenderConditional
								component={CardContent}
								condition={isPage}
							>
								<Typography variant="subtitle2">{t`Customer`}</Typography>
								{get(data, 'customer.id') && (
									<CustomerCard
										avatarSize="large"
										link
										isElevated={false}
										customer={data.customer}
									/>
								)}

								<Box mt={2}>
									<Typography variant="subtitle2">{t`Feedback`}</Typography>
									<FeedbackHighlights highlights={getFeedbackHighLights()} />
								</Box>

								<Box mt={2}>
									<ActionButton
										action="nav_to"
										link
										to={`/reporting/inbox/detail/${get(
											data,
											'feedback_info.feedback_id',
											''
										)}`}
									>
										{t`View feedback item`}
									</ActionButton>
								</Box>
							</RenderConditional>
						</RenderConditional>
					</Grid>
				)}

				<Grid
					item
					xs={hasFeedbackItem ? 3 : 4}
				>
					<RenderConditional
						component={Card}
						condition={isPage}
					>
						<RenderConditional
							component={CardContent}
							condition={isPage}
						>
							<Grid
								container
								spacing={2}
								direction="column"
							>
								{Number(data?.action?.status_id) !== actionStatusCompleted && (
									<Grid
										item
										xs
									>
										<Typography
											variant="subtitle2"
											display="block"
										>{t`Deadline`}</Typography>
										<ActionDeadline
											variant="body2"
											deadline={get(data, 'action.deadline')}
										/>
									</Grid>
								)}
								<Grid
									item
									xs
								>
									<Typography
										variant="subtitle2"
										display="block"
									>{t`Owner`}</Typography>
									<Box>
										<UserAvatar
											size="xsmall"
											user_id={get(data, 'action.owner_user_id')}
										/>{' '}
										<UserName
											fontWeight={400}
											user_id={get(data, 'action.owner_user_id')}
										/>
									</Box>
								</Grid>
								<Grid
									item
									xs
								>
									<Typography
										variant="subtitle2"
										display="block"
									>{t`Assigned to`}</Typography>
									<Box>
										<UserAvatar
											size="xsmall"
											user_id={get(data, 'action.agent_user_id')}
										/>{' '}
										<UserName
											fontWeight={400}
											user_id={get(data, 'action.agent_user_id')}
										/>
									</Box>
								</Grid>
							</Grid>
						</RenderConditional>
					</RenderConditional>
					<Box mt={2}>
						<Typography
							variant="caption"
							display="block"
							color="textSecondary"
						>
							{t`Created`}{' '}
							<FormattedDate
								date={data?.action?.created}
								withTime
							/>
						</Typography>
						<Typography
							variant="caption"
							display="block"
							color="textSecondary"
						>
							{t`Last updated`}{' '}
							<FormattedDate
								withTime
								date={lastHistoryItem.changed_on}
							/>
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</Fragment>
	);
}
