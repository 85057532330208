import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { CheckIcon } from '../Icons';
import { Tooltip, Link, RenderConditional, Loader } from '../Layout';
import { usePermissionModuleContext } from './PermissionModuleContext';
import { usePermissionSubjectContext } from './PermissionSubjectContext';
import useHasPermission from './useHasPermission';
import useSendPermissionRequest from './useSendPermissionRequest';
import { permissionTypes } from './constants';

const useStyles = makeStyles(theme => ({
	successIcon: {
		fontSize: theme.typography.pxToRem(16),
		marginRight: theme.spacing(1),
		verticalAlign: 'middle',
	},
}));

//@todo: add functionality for requesting permission -> endpoint? modal?
export default function NoPermissionTooltip({
	children,
	title,
	permission = permissionTypes.edit,
	module,
	subject,
	restricedPermission,
	...props
}) {
	const classes = useStyles();
	const moduleFromContext = usePermissionModuleContext();
	const subjectFromContext = usePermissionSubjectContext();
	const hasPermission = useHasPermission({ permission, module, subject });
	const { postForm, response, loading } = useSendPermissionRequest({
		module: module ?? moduleFromContext,
		subject: subject ?? subjectFromContext,
		permission,
	});

	const determineTooltipTitleKey = () => {
		if (restricedPermission) {
			return `app_general-restricted_permission_tooltip-default`;
		}

		if (response.code === 200) {
			return `app_general-no_${permission}_permission_tooltip-requested`;
		}

		return `app_general-no_${permission}_permission_tooltip-default`;
	};

	return (
		<>
			<RenderConditional
				component={Tooltip}
				condition={!hasPermission}
				interactive
				wrapDisabled
				title={
					loading ? (
						<Loader
							circular
							color="inherit"
							size={16}
						/>
					) : (
						title ?? (
							//default
							<>
								{response.code === 200 && <CheckIcon className={classes.successIcon} />}
								<Trans
									i18nKey={determineTooltipTitleKey()}
									components={{
										requestLink: (
											<Link
												color="inherit"
												display="block"
												onClick={postForm}
												data-track-event="access_groups_request_access_via_tooltip"
												data-track-event-props={JSON.stringify({
													module,
													subject,
													permission,
												})}
											/>
										),
									}}
								/>
							</>
						)
					)
				}
				{...props}
			>
				{children}
			</RenderConditional>
		</>
	);
}
