import React from 'react';

import { useEFM } from '@/components/Ajax';

export default function useGetPrimarySurvey({ wait, onSuccess }) {
	return useEFM(
		'/dashboard/ajax/smart-recaps',
		{
			path: '/trends/pick-form',
			method: 'get',
		},
		wait,
		false,
		null,
		{ onSuccess: onSuccess, disableCache: true }
	);
}
