import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import {
	Box,
	FormControl,
	FormLabel,
	RadioGroup,
	Radio,
	Alert,
	Collapse,
	FormControlLabel,
	Link,
} from '@/components/Layout';
import {
	PermissionGroupAutocomplete,
	useGetPermissionGroups,
	isAdminOwnerGroup,
} from '@/components/Permission';
import { removeArrayDuplicatesFromShallowClone } from '@/utils';

const useStyles = makeStyles(theme => ({
	fillWidth: {
		width: '100%',
	},
}));

export default function DeploymentAccess({
	initialPermissionGroups = [],
	permissionGroups = [],
	onChange = () => {},
	hasAccessPermission = true,
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	const [enableGroups, setEnableGroups] = useState([...initialPermissionGroups]);
	const [{ groups = [] }] = useGetPermissionGroups();

	const adminOwnerGroups = groups
		.filter(group => isAdminOwnerGroup(group))
		.map(group => group.uuid);

	const mergedGroups = removeArrayDuplicatesFromShallowClone(
		permissionGroups,
		adminOwnerGroups
	);

	return (
		<Box width="100%">
			<FormControl className={classes.fillWidth}>
				<FormLabel>
					<Box
						display="flex"
						justifyContent="space-between"
						color="text.primary"
					>
						{t`data_collection-deployment_access-label`}
						<Link tooltip={t`data_collection-deployment_access-tooltip`}>
							{t`data_collection-deployment_access-why`}
						</Link>
					</Box>
				</FormLabel>
				<RadioGroup
					value={enableGroups.length > 0 ? 'yes' : 'no'}
					onChange={e => {
						setEnableGroups(e.target.value === 'yes' ? mergedGroups : []);
						onChange(e.target.value === 'yes' ? mergedGroups : []);
					}}
				>
					<FormControlLabel
						value="no"
						control={<Radio />}
						label={t`data_collection-deployment_access-default`}
						data-track-event="deployment_access-default_access"
					/>
					<FormControlLabel
						value="yes"
						control={<Radio />}
						label={t`data_collection-deployment_access-restrict`}
						data-track-event="deployment_access-restrict_access"
					/>
				</RadioGroup>
			</FormControl>
			<Collapse in={enableGroups.length > 0}>
				<PermissionGroupAutocomplete
					label={t`data_collection-deployment_access-select_groups`}
					value={mergedGroups}
					fixedAdminOwner
					onChange={(e, value) => {
						onChange(value.map(group => group.uuid));
					}}
					error={!hasAccessPermission}
					helperText={
						!hasAccessPermission
							? t`data_collection-deployment_access-lock_self_error`
							: null
					}
				/>
				<Alert
					variant="default"
					mb={1}
				>{t`data_collection-deployment_access-alert`}</Alert>
			</Collapse>
		</Box>
	);
}
