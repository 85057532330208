import React from 'react';
import {
	faChrome,
	faSafari,
	faFirefox,
	faEdge,
	faInternetExplorer,
	faOpera,
	faLinux,
	faFacebookF,
	faAndroid,
	faApple,
	faWindows,
} from '@fortawesome/free-brands-svg-icons';

import { FaIcon } from '../Icons';

import Settings from '@material-ui/icons/Settings';

export default function getBrowserOsIcon(value, props = {}) {
	const matches = v => value.toLowerCase().indexOf(v) > -1;

	if (matches('chrome') || matches('chromium')) {
		return (
			<FaIcon
				icon={faChrome}
				{...props}
			/>
		);
	} else if (matches('firefox')) {
		return (
			<FaIcon
				icon={faFirefox}
				{...props}
			/>
		);
	} else if (matches('edge')) {
		return (
			<FaIcon
				icon={faEdge}
				{...props}
			/>
		);
	} else if (matches('explorer') || value === 'IE') {
		return (
			<FaIcon
				icon={faInternetExplorer}
				{...props}
			/>
		);
	} else if (matches('safari')) {
		return (
			<FaIcon
				icon={faSafari}
				{...props}
			/>
		);
	} else if (matches('opera')) {
		return (
			<FaIcon
				icon={faOpera}
				{...props}
			/>
		);
	} else if (matches('facebook')) {
		return (
			<FaIcon
				icon={faFacebookF}
				{...props}
			/>
		);
	} else if (matches('android')) {
		return (
			<FaIcon
				icon={faAndroid}
				{...props}
			/>
		);
	} else if (
		matches('ios') ||
		matches('macos') ||
		matches('os x') ||
		matches('iphone') ||
		matches('ipad')
	) {
		return (
			<FaIcon
				icon={faApple}
				{...props}
			/>
		);
	} else if (matches('windows')) {
		return (
			<FaIcon
				icon={faWindows}
				{...props}
			/>
		);
	} else if (matches('linux') || matches('debian') || matches('ubuntu')) {
		return (
			<FaIcon
				icon={faLinux}
				{...props}
			/>
		);
	}

	return <Settings {...props} />;
}
