import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
const styles = theme => ({
	wrap: {
		position: 'relative',
		display: 'inline-block',
	},
	bg: {
		position: 'absolute',
	},
	centerContent: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%,-50%)',
	},
});
function ProgressCircleWithBackground({
	progress,
	classes,
	front = 'secondary',
	background,
	theme,
	text,
	...props
}) {
	let bg = background ? background : theme.palette.grey[200];
	return (
		<div className={classes.wrap}>
			<CircularProgress
				variant="static"
				value={100}
				className={classes.bg}
				style={{ color: bg }}
				{...props}
			/>
			<CircularProgress
				color={front}
				variant="static"
				value={progress}
				{...props}
			/>
			{text && (
				<Typography
					variant="caption"
					color="textSecondary"
				>
					{text}
				</Typography>
			)}
		</div>
	);
}

export default withStyles(styles, { withTheme: true })(ProgressCircleWithBackground);
