import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import MenuItem from '@material-ui/core/MenuItem';
import { UserAutoComplete, TagsAutoComplete } from '../AutoComplete';

import { KeyboardDatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import get from 'lodash.get';
import { useSnackbar } from 'notistack';

import { format } from '@/utils/date';
import { ValidatedField, testValidObject } from '@/components/Validation';

import { makeDateObject } from '@/helpers';

import { Loader, SimpleDialog } from '@/components/Layout';

import { useImmer } from 'use-immer';
import { useEFM, useAjaxForm } from '@/components/Ajax';
import { useAppContext } from '@/components/AppContext';

const useStyles = makeStyles(theme => ({
	showOverflow: {
		overflowY: 'visible',
	},
	dialogWidth: {
		minWidth: '30vw',
	},
	dialogText: {
		marginBottom: theme.spacing(1),
	},
	fixColumn: {
		'& > div': {
			width: '100%',
		},
	},
}));

export default function ActionAddDialog({
	open,
	feedback_id,
	id,
	onClose = () => {},
	onSuccess = () => {},
}) {
	const classes = useStyles();
	const {
		app: { actions, date },
	} = useAppContext();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [state, setState] = useImmer({
		name: '',
		description: '',
		agent_user_id: '',
		feedback_id: '',
		action_type_id: '',
		deadline: '',
		alert: 'no',
		team_all: 'none',
		tags: '',
	});

	const [valid, setValid] = useImmer({
		name: false,
		description: true,
		agent_user_id: false,
	});

	const [actionData, actionDataLoading] = useEFM(
		`/actions/inbox/edit/${id}`,
		{},
		!open || !id,
		false,
		false,
		{ disableCache: true }
	);

	const { postForm, loading, clicked } = useAjaxForm({
		valid: () => testValidObject(valid),
		url: id
			? `/actions/inbox/edit/${id}`
			: `/actions/inbox/add${feedback_id ? '/' + feedback_id : ''}`,
		data: {
			...state,
			feedback_id,
			id,
		},
		onSuccess: r => {
			if (r.code === 200) {
				clearForm();
				onSuccess(r);
				enqueueSnackbar(t('Action added'));
				onClose();
			} else {
				enqueueSnackbar(t('Something went wrong'));
			}
		},
	});

	useEffect(() => {
		if (Object.keys(actionData).length > 0) {
			if (actionData.action) {
				setState(draft => {
					draft.name = actionData.action.name ?? '';
					draft.description = actionData.action.description ?? '';
					draft.agent_user_id = actionData.action.agent_user_id ?? '';
					draft.feedback_id = actionData.action.feedback_id ?? '';
					draft.action_type_id = actionData.action.action_type_id ?? '';
					draft.deadline = actionData.action.deadline ?? '';
					draft.alert = actionData.action.alert ?? '';
					draft.team_all = actionData.action.team_all ?? '';
					draft.tags = actionData.tags
						? actionData.tags.current.map(tag => tag.name).join(',')
						: '';
				});
			}
		}
	}, [actionData]);

	useEffect(() => {
		if (open) {
			setState(draft => {
				draft.deadline = format(new Date(), date.format);
			});
			if (!state.action_type_id) {
				setState(draft => {
					draft.action_type_id = actions.types.length ? actions.types[0].id : '';
				});
			}
		}
	}, [open, date.format, actions.types]);

	function clearForm() {
		setState(draft => {
			draft.name = '';
			draft.description = '';
			draft.agent_user_id = '';
			draft.feedback_id = '';
			draft.action_type_id = '';
			draft.deadline = format(new Date(), date.format);
			draft.alert = 'no';
			draft.team_all = 'none';
			draft.tags = '';
		});

		setValid(draft => {
			draft.name = false;
			draft.description = true;
			draft.agent_user_id = false;
		});
	}

	return (
		<SimpleDialog
			open={Boolean(open)}
			title={id ? t`Edit action` : t`Add action`}
			cancel={t`Cancel`}
			onClose={onClose}
			submit={id ? t`Edit action` : t`Add action`}
			onSubmit={() => {
				postForm();
			}}
			loading={loading}
			dataTrackEvent={id ? 'action_action_edited' : 'action_action_created'}
		>
			<Fragment>
				{actionDataLoading && <Loader overlay />}

				<Grid
					container
					direction="column"
					spacing={3}
					className={classes.fixColumn}
				>
					<Grid
						item
						xs
					>
						<ValidatedField
							label={t`Action name`}
							placeholder={t`'E.g. call customer regarding feedback'`}
							fullWidth
							value={state.name}
							onChange={e => {
								setState(draft => {
									draft.name = e.target.value;
								});
							}}
							messages={{
								required: t`This field is required`,
								alphaNumeric: t`actions-manage-add_edit_dialog-error_validation_alphanumeric`,
							}}
							onValidChange={valid => {
								setValid(draft => {
									draft.name = valid;
								});
							}}
							rules={[
								['required', true],
								['alphaNumeric', true],
							]}
							showErrors={clicked}
							dataTestElement="actionName"
						/>
					</Grid>
					<Grid
						item
						xs
					>
						<ValidatedField
							label={t('Description')}
							placeholder={t`Describe what action needs to be taken`}
							fullWidth
							value={state.description}
							onChange={e => {
								setState(draft => {
									draft.description = e.target.value;
								});
							}}
							errorMessage={t`actions-manage-add_edit_dialog-error_validation_alphanumeric`}
							onValidChange={valid => {
								setValid(draft => {
									draft.description = valid;
								});
							}}
							showErrors={clicked}
							rules={[['alphaNumeric', true]]}
							inputProps={{ 'data-test-element': 'actionDescription' }}
						/>
					</Grid>
					<Grid
						item
						xs
					>
						<ValidatedField
							component={UserAutoComplete}
							label={t('Assign to')}
							placeholder={t('Search for users')}
							renderChips
							multiple={false}
							selectedItem={[{ id: state.agent_user_id }]}
							onChange={user => {
								setState(draft => {
									draft.agent_user_id = get(user, 'id', '');
								});
							}}
							disableCustomEntry
							validateValue={state.agent_user_id}
							onValidChange={valid => {
								setValid(draft => {
									draft.agent_user_id = valid;
								});
							}}
							rules={[['required', true]]}
							showErrors={clicked}
							dataTestElement="actionAssignee"
						/>
					</Grid>
					<Grid
						item
						xs
					>
						<TextField
							select
							label={t('Action type')}
							fullWidth
							value={state.action_type_id}
							onChange={e => {
								setState(draft => {
									draft.action_type_id = e.target.value;
								});
							}}
						>
							{actions.types &&
								actions.types.map(type => (
									<MenuItem
										value={type.id}
										key={type.id}
									>
										{type.name}
									</MenuItem>
								))}
						</TextField>
					</Grid>
					<Grid
						item
						xs
					>
						{
							<KeyboardDatePicker
								label={t('Action deadline')}
								format={date.format}
								placeholder="01-01-2021"
								value={
									state.deadline
										? makeDateObject(state.deadline, date.separator)
										: new Date()
								}
								onChange={dateObject => {
									setState(draft => {
										draft.deadline = format(dateObject, date.format);
									});
								}}
								animateYearScrolling={false}
								fullWidth
							/>
						}
					</Grid>
					<Grid
						item
						xs
					>
						<TagsAutoComplete
							label={t`Add tags to action`}
							placeholder={t`Type to search tags`}
							value={state.tags}
							selectedItem={state.tags
								.split(',')
								.filter(Boolean)
								.map(tag => ({ label: tag }))}
							onChange={e => {
								setState(draft => {
									draft.tags = e.map(tag => tag.label).join(',');
								});
							}}
						/>
					</Grid>
					<Grid
						item
						xs
					>
						<FormControlLabel
							control={
								<Checkbox
									checked={state.alert === 'yes'}
									onClick={e => {
										setState(draft => {
											draft.alert = e.target.checked ? 'yes' : 'no';
										});
									}}
								/>
							}
							label={t('Notify me when the action status changes')}
						/>
					</Grid>
				</Grid>
			</Fragment>
		</SimpleDialog>
	);
}
