import React, { Fragment, useRef } from 'react';

import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';

import TagConditionSummary from './TagConditionSummary';
import { TagsAutoComplete } from '../AutoComplete';

import { useAppContext } from '../AppContext';
import {
	ActionIconButton,
	ActionButton,
	DataFieldConditions,
	Typography,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
} from '../Layout';
import { operators, conditionBase } from './tagUtils';

import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import get from 'lodash.get';

const useStyles = makeStyles(theme => ({
	outlined: {
		boxShadow: 'none',
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.palette.grey[300],
	},
	alignRight: {
		textAlign: 'right',
	},
	maxWidth: {
		maxWidth: '100%',
	},
}));

export default function TagEditoDrawerConditions({
	id,
	condition,
	newConditions = false,
	toggleExpand = () => {},
	setCondition = () => {},
	addToArray = () => {},
	removeFromArray = () => {},
	setConditionValue = () => {},
	handleSubmit = () => {},
}) {
	const classes = useStyles();
	const { app } = useAppContext();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const conditionSets = condition.condition.add ?? [];
	const cardAction = useRef(null);

	function scrollToNew() {
		cardAction.current.scrollIntoView({ behavior: 'smooth' });
	}

	return (
		<Fragment>
			{conditionSets.map((conditionSet, index) => {
				if (newConditions !== condition.new.includes(index)) {
					return null;
				}

				return (
					<ExpansionPanel
						key={'conditionSet' + index}
						className={newConditions ? classes.outlined : ''}
						expanded={condition.expanded.indexOf(index) > -1}
						onChange={() => toggleExpand(index)}
					>
						<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
							<Grid
								container
								alignItems="center"
							>
								<Grid
									item
									xs
								>
									{condition.expanded.indexOf(index) > -1 ? (
										<Typography
											variant="subtitle2"
											color="textSecondary"
										>
											{newConditions ? t`New condition set` : t`Condition set`}
										</Typography>
									) : (
										<TagConditionSummary
											conditionSet={conditionSet}
											conditionIndex={index}
											newSet={newConditions}
										/>
									)}
								</Grid>
								<Grid item>
									<ActionIconButton
										action="clone"
										tooltip={t`Clone condition`}
										size="small"
										onClick={e => {
											e.stopPropagation();
											enqueueSnackbar(t('Condition set succesfully cloned'));
											setCondition(draft => {
												draft.expanded = [
													...draft.expanded,
													condition.condition.add.length,
												];
												draft.new = [...draft.new, condition.condition.add.length];
											});
											addToArray('add', { ...conditionSet });
											scrollToNew();
										}}
									/>
									<ActionIconButton
										action="remove_list"
										tooltip={t`Remove condition`}
										size="small"
										onClick={e => {
											e.stopPropagation();
											removeFromArray('add', index, true);
										}}
									/>
								</Grid>
							</Grid>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>
							<Grid
								container
								direction="row"
								className={classes.grid}
								spacing={3}
							>
								<Grid
									item
									xs={12}
								>
									<Typography
										gutterBottom
										variant="subtitle"
									>{t`Add these tags`}</Typography>
									<TagsAutoComplete
										label={t`Select tags`}
										autoFocus
										selectedItem={conditionSet.tags.map(tag => ({ label: tag }))}
										onChange={tags => {
											setConditionValue(`add[${index}].user_id`, app.users.current.id);
											setConditionValue(
												`add[${index}].tags`,
												tags.map(tag => tag.label)
											);
										}}
										dataTestElement="tagSelectTag"
									/>
								</Grid>

								<Grid
									item
									xs={12}
								>
									<Typography
										gutterBottom
										variant="subtitle"
									>{t`When`}</Typography>

									<DataFieldConditions
										conditionArray={get(conditionSet, 'conditions', [])}
										surveyId={condition.condition.survey_id}
										operatorFn={operators}
										noWrap
										onDataFieldChange={(feedbackConditionIndex, e) => {
											setConditionValue(`add[${index}].user_id`, app.users.current.id);
											setConditionValue(
												`add[${index}].conditions[${feedbackConditionIndex}].datafield_id`,
												e.target.value
											);
											setConditionValue(
												`add[${index}].conditions[${feedbackConditionIndex}].value`,
												[]
											);
										}}
										onOperatorChange={(feedbackConditionIndex, e) => {
											setConditionValue(
												`add[${index}].conditions[${feedbackConditionIndex}].operator`,
												e.target.value
											);
											setConditionValue(`add[${index}].user_id`, app.users.current.id);
										}}
										onPossibleValueChange={(feedbackConditionIndex, valueArr) => {
											setConditionValue(
												`add[${index}].conditions[${feedbackConditionIndex}].value`,
												valueArr.map(value =>
													typeof value === 'object' ? value.value : value
												)
											);
											setConditionValue(`add[${index}].user_id`, app.users.current.id);
										}}
										onRemoveRow={feedbackConditionIndex =>
											removeFromArray(`add[${index}].conditions`, feedbackConditionIndex)
										}
									/>

									<Box
										mt={1}
										clone
									>
										<ActionButton
											onClick={() =>
												addToArray(`add[${index}].conditions`, conditionBase())
											}
											action="add_list"
											label={t`And when`}
										/>
									</Box>
								</Grid>

								<Grid
									item
									xs={12}
								>
									<Grid
										container
										alignItems="center"
										onClick={() =>
											setConditionValue(
												`add[${index}].retroactive`,
												!conditionSet.retroactive
											)
										}
										className={classes.pointer}
									>
										<Grid
											item
											xs
										>
											<Tooltip
												title={t`Select this option if you would like these conditions to apply to past feedback items`}
												placement="bottom-start"
											>
												<div className={classes.pointer}>
													<Typography
														variant="subtitle1"
														display="block"
													>{t`Add tags to already existing feedback items`}</Typography>
												</div>
											</Tooltip>
										</Grid>

										<Grid item>
											<Switch checked={conditionSet.retroactive} />
										</Grid>
									</Grid>
								</Grid>

								{!newConditions && (
									<Grid
										item
										xs
										className={classes.alignRight}
									>
										<ActionButton
											onClick={() => handleSubmit()}
											disabled={!condition?.condition?.survey_id}
											color="primary"
											action="save"
											variant="contained"
											data-test-element="tagAddCondition"
											dataTrackEvent="settings_automated_tag_edited"
										>
											{t`Save conditions`}
										</ActionButton>
									</Grid>
								)}
							</Grid>
						</ExpansionPanelDetails>
					</ExpansionPanel>
				);
			})}
		</Fragment>
	);
}
