import React from 'react';

import OnboardingContext from './OnboardingContext';
import useOnboarding from './useOnboarding';

export default function OnboardingProvider({ children, ...props }) {
	const onboarding = useOnboarding();

	return (
		<OnboardingContext.Provider value={onboarding}>{children}</OnboardingContext.Provider>
	);
}
