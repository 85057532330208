import React, { useMemo, memo } from 'react';

import { useDataSourceContext, DataFieldIcon } from '../DataSources';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import ListSubheader from '@material-ui/core/ListSubheader';
import Chip from '@material-ui/core/Chip';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	select: {
		display: 'flex',
		alignItems: 'center',
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: theme.spacing(0.25),
	},
}));

export default function VarTypeSelect({
	value,
	children,
	label,
	types = [],
	disabledTypes = [],
	renderValueTextOnly,
	renderChips,
	multiple,
	prependOption,
	...props
}) {
	const { datasource } = useDataSourceContext();
	const classes = useStyles();
	const { t } = useTranslation();

	const typeOptions = useMemo(() =>
		datasource.varTypes.allDataArray.filter(varType => {
			return types.length > 0 && types.find(type => String(type) === String(varType.id));
		})
	);

	return (
		<FormControl fullWidth>
			{label && <InputLabel>{label}</InputLabel>}
			<Select
				value={value}
				multiple={multiple}
				classes={{ select: classes.select }}
				{...(renderValueTextOnly && {
					renderValue: value =>
						Array.isArray(value)
							? value
									.map(v =>
										t(typeOptions.find(opt => String(opt.id) === String(v).label))
									)
									.join(', ')
							: t(typeOptions.find(opt => String(opt.id) === String(value)).label),
				})}
				{...(renderChips && {
					renderValue: selected => (
						<div className={classes.chips}>
							{selected.map(value => {
								const label = t(
									typeOptions.find(opt => String(opt.id) === String(value)).label
								);
								return (
									<Chip
										key={value}
										label={label}
										className={classes.chip}
									/>
								);
							})}
						</div>
					),
				})}
				{...props}
			>
				{prependOption && (
					<MenuItem value={prependOption.value}>
						<ListItemIcon>
							{prependOption.icon ? (
								prependOption.icon
							) : (
								<DataFieldIcon var_type={prependOption.value} />
							)}
						</ListItemIcon>
						<ListItemText primary={t(prependOption.label)} />
					</MenuItem>
				)}
				{typeOptions.map(varType => {
					return (
						<MenuItem
							value={varType.id}
							key={varType.id}
							disabled={disabledTypes.indexOf(varType.id) > -1}
						>
							<ListItemIcon>
								<DataFieldIcon var_type={Number(varType.id)} />
							</ListItemIcon>
							<ListItemText primary={t(varType.label)} />
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
}
