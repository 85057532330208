import React, { useMemo, memo } from 'react';
import AutoComplete from './AutoComplete';
import { useAppContext } from '../AppContext';
import { useDataSourceContext } from '../DataSources';
import { useTranslation } from 'react-i18next';

function makeOptions(formattedFields, value, survey, t) {
	let selectedItem = [];
	const options = [
		{
			label: survey.name,
			search: `${survey.name} ${survey.title}`,
			surveyId: survey.id,
			isSurvey: true,
			disabled: true,
			...survey,
		},
		...Object.keys(formattedFields).reduce((formattedFieldsArray, key, i) => {
			const category = formattedFields[key];
			const fieldsFromCat =
				category.fields.length > 0
					? [
							{
								label: category.label,
								category: category.type,
								surveyId: survey.id,
								isCategory: true,
								disabled: true,
							},
							...category.fields.map((field, i) => {
								const returnObject = {
									label: field.title ?? field.system_var,
									value: field.import_var.toLowerCase() == 'tags' ? 'tags' : field.id,
									import_var: field.import_var.toLowerCase(),
									type: field.active
										? field.niceType
										: `${t`This field is no longer active`} (${field.niceType})`,
									system_var_simple: field.system_var_simple,
									block_id: field.block_id,
									block_type: field.block_type,
									var_type: field.var_type,
									key: field.system_var + field.id + field.import_var,
									surveyId: survey.id,
									category: category.type,
									active: field.active,
									search: `${field.title} ${field.id} ${field.system_var} ${field.var_type} ${survey.name} ${survey.title} ${category.label} ${category.type}`,
								};

								if (value && returnObject.value == value) {
									selectedItem = [returnObject];
								}
								return returnObject;
							}),
					  ]
					: [];

			formattedFieldsArray = formattedFieldsArray.concat(fieldsFromCat);
			return formattedFieldsArray;
		}, []),
	];

	return {
		fields: options,
		selected: selectedItem.length ? selectedItem : false,
	};
}

function DataFieldAutoComplete({
	value,
	multiple,
	onChange = () => {},
	fromSurveyId,
	includeAllandTags = false,
	...props
}) {
	const { t } = useTranslation();
	const { app } = useAppContext();
	const {
		datasource: {
			surveyfields,
			api: { getSource, getFieldsFormattedByVarType },
		},
	} = useDataSourceContext();
	let selectedItem = [];

	const makeOptionsDefault = () => {
		return Object.keys(surveyfields).reduce((accumulator, surveyId) => {
			const formattedFields = getFieldsFormattedByVarType(surveyId, includeAllandTags);
			const survey = getSource(surveyId);
			const { fields, selected } = makeOptions(formattedFields, value, survey, t);

			if (selected) {
				selectedItem = selected;
			}
			if (fields && survey.project_id == app.projects.current.id)
				accumulator.push(...fields);
			return accumulator;
		}, []);
	};

	const makeOptionsSingle = () => {
		const { fields, selected } = makeOptions(
			getFieldsFormattedByVarType(fromSurveyId, includeAllandTags),
			value,
			getSource(fromSurveyId),
			t
		);
		if (selected) {
			selectedItem = selected;
		}
		return fields;
	};

	const options = fromSurveyId ? makeOptionsSingle() : makeOptionsDefault();

	// useMemo(() => {
	// 	if (fromSurveyId) {
	// 		return makeOptionsSingle();
	// 	} else {
	// 		return makeOptionsDefault();
	// 	}
	// }, [fromSurveyId, value, getSource(fromSurveyId)]);

	return (
		<AutoComplete
			{...props}
			options={options}
			selectedItem={selectedItem}
			isDataFieldPicker
			renderChips
			disableCustomEntry
			onChange={e => {
				onChange(e);
			}}
			multiple={multiple}
		/>
	);
}

export default memo(DataFieldAutoComplete);
