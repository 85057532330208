import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';

import { useTranslation } from 'react-i18next';

import { ActionButton, Tooltip } from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	position: {
		margin: theme.spacing(2),
		position: 'absolute',
		top: 0,
		right: 10,
	},
}));

export default function PreviewSettings({ viewType, setViewType }) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.position}>
			<Tooltip
				title={
					viewType === 'mobile'
						? t`data_collection-preview-viewtype-button-desktop`
						: t`data_collection-preview-viewtype-button-mobile`
				}
				placement="left"
			>
				<ActionButton
					aria-label="switch type"
					size="large"
					fab
					onClick={() => {
						setViewType(viewType === 'mobile' ? 'desktop' : 'mobile');
					}}
				>
					{viewType === 'mobile' ? <PhoneAndroidIcon /> : <DesktopMacIcon />}
				</ActionButton>
			</Tooltip>
		</div>
	);
}
