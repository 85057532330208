import React, { useMemo } from 'react';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { EditorState, Modifier } from 'draft-js';
import { useTranslation } from 'react-i18next';

import { useDataSourceContext, DataFieldIcon } from '@/components/DataSources';
import { useAppContext } from '@/components/AppContext';
import { ListItemText, ListItemIcon, ListSubheader, Divider } from '@/components/Layout';

const styles = theme => ({
	select: {
		minWidth: 160,
		// marginTop:'-8px!important'
	},
	subheader: {
		backgroundColor: '#fff',
	},
});

function replaceSpecialCharacters(value = '') {
	return value.toLowerCase().replace(/[^A-Z0-9-()]+/gi, '_');
}

function makeOptions(formattedFields, survey, t, fieldVars) {
	const options = [
		...Object.keys(formattedFields).reduce((formattedFieldsArray, key, i) => {
			const category = formattedFields[key];
			const fieldsFromCat =
				category.fields.length > 0
					? [
							{
								label: `${survey.name} - ${t(category.label)}`,
								id: survey.id + category.label,
								subheader: true,
							},
							...category.fields.map((field, i) => {
								const returnObject = {
									label: field.system_var,
									value: fieldVars //use fieldvars when directly inserting values
										? (
												Object.values(fieldVars).find(
													obj => obj.data_field_id == field.id
												) || {}
										  ).value
										: `{{${replaceSpecialCharacters(field.system_var)}:::${field.id}}}`, //for standard answer values
									...field,
								};

								return returnObject;
							}),
					  ]
					: [];

			formattedFieldsArray = formattedFieldsArray.concat(fieldsFromCat);
			return formattedFieldsArray;
		}, []),
	];

	return options;
}

function CustomVariables({
	editorState,
	onChange,
	classes,
	surveyId,
	fieldVars,
	user = {},
}) {
	const { datasource } = useDataSourceContext();
	const { app } = useAppContext();
	const { t } = useTranslation();
	const systemVariables = [
		{
			label: t`Sending user fullname`,
			value: surveyId ? `${user.firstname} ${user.lastname}` : '{{admin_fullname}}',
		},
		{
			label: t`Sending user firstname`,
			value: surveyId ? user.firstname : '{{admin_firstname}}',
		},
		{
			label: t`Sending user lastname`,
			value: surveyId ? user.lastname : '{{admin_lastname}}',
		},
		{ label: t`Sending user email`, value: surveyId ? user.email : '{{admin_email}}' },
		{
			label: t`Sending user phone number`,
			value: surveyId ? user.telephone : '{{admin_telephone}}',
		},
	];

	function addVariable(event) {
		const { value } = event.target;

		const contentState = Modifier.replaceText(
			editorState.getCurrentContent(),
			editorState.getSelection(),
			value,
			editorState.getCurrentInlineStyle()
		);
		onChange(EditorState.push(editorState, contentState, 'insert-characters'));
	}

	function makeOptionAllData() {
		return Object.keys(datasource.surveyfields).reduce((accumulator, _surveyId) => {
			const formattedFields = datasource.api.getFieldsFormattedByVarType(_surveyId);
			const survey = datasource.api.getSource(_surveyId);

			const fields = makeOptions(formattedFields, survey, t);
			if (fields) accumulator.push(...fields);
			return accumulator;
		}, []);
	}

	function makeOptionsSingle() {
		const fields = makeOptions(
			datasource.api.getFieldsFormattedByVarType(surveyId),
			datasource.api.getSource(surveyId),
			t,
			fieldVars
		);
		return fields;
	}

	const options = useMemo(
		() => (surveyId ? makeOptionsSingle() : makeOptionAllData()),
		[surveyId, fieldVars]
	);

	//const surveyFields = surveyId ? datasource.api.getFieldArray(surveyId) : [];
	return (
		<TextField
			select
			onChange={addVariable}
			label={t('Insert variable')}
			className={classes.select}
			value=""
			variant="outlined"
			size="small"
			inputProps={{ 'data-test-element': 'editorInsertVariable' }}
		>
			<ListSubheader className={classes.subheader}>{t`System variables`}</ListSubheader>
			{systemVariables.map((sysOption, i) => {
				return (
					<MenuItem
						key={i}
						value={sysOption.value}
						data-test-element="editorSystemVariable"
					>
						<ListItemIcon>
							<DataFieldIcon blockName="contact" />
						</ListItemIcon>
						<ListItemText primary={sysOption.label} />
					</MenuItem>
				);
			})}
			<Divider />
			{options.map(option => {
				if (option.subheader) {
					return (
						<ListSubheader
							className={classes.subheader}
							key={option.id}
						>
							{option.label}
						</ListSubheader>
					);
				} else {
					return (
						<MenuItem
							key={option.id}
							value={option.value}
							disabled={!option.value}
							data-test-element="editorSystemVariable"
						>
							<ListItemIcon>
								<DataFieldIcon {...option} />
							</ListItemIcon>
							<ListItemText
								primary={option.label}
								secondary={option.value ? '' : t`No value for this field`}
							/>
						</MenuItem>
					);
				}
			})}
		</TextField>
	);
}

export default withStyles(styles)(CustomVariables);
