import React, { useState, Fragment, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InputAdornment from '@material-ui/core/InputAdornment';

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { useAppContext } from '../AppContext';
import { useTranslation } from 'react-i18next';

import { ActionButton, ActionIconButton, Loader, Link, Alert } from '@/components/Layout';
import { ValidatedField } from '../Validation';
import { useEFM } from '../Ajax';

const useStyles = makeStyles(theme => ({
	backLink: {
		position: 'absolute',
	},
	backIcon: {
		verticalAlign: 'middle',
	},
}));

export default function NewPassword({ onChange = () => {} }) {
	const { app } = useAppContext();
	const classes = useStyles();
	const [changePass, setChangePass] = useState(false);
	const [pass, setPass] = useState('');
	const [newPass, setNewPass] = useState('');

	const [validatePass, setValidatePass] = useState('');
	const [validatePassValid, setValidatePassValid] = useState(null);

	const ssoIntermediary =
		app.auth.options.includes('sso') && app.auth.options.includes('user_creds');

	const [reauth = {}, loading] = useEFM(
		'/account/ajax/re-authenticate',
		{ password: pass },
		!changePass || pass.length < 3,
		false,
		false,
		{ disableCache: true }
	);
	const [newPassValid, newPassValidLoading] = useEFM(
		'/account/ajax/check-password',
		{ password: newPass },
		newPass.length < 3,
		false,
		false,
		{ disableCache: true }
	);

	let minLength = app.auth?.password?.min ?? 8;
	let maxLength = app.auth?.password?.max ?? 36;

	const { t } = useTranslation();

	function cancelChangePass() {
		setChangePass(false);
		setPass('');
		setNewPass('');
		setValidatePass('');
	}

	useEffect(() => {
		if (newPassValid.code == 200 && validatePassValid) {
			onChange({ token: reauth.token, password: newPass });
		} else {
			onChange({ token: '', password: '' });
		}
	}, [newPass, reauth, newPassValid, validatePassValid]);

	if (!changePass) {
		return (
			<ListItem
				button
				onClick={() => setChangePass(true)}
			>
				<ListItemIcon>
					<VpnKeyIcon />
				</ListItemIcon>
				<ListItemText
					primary={t`Password`}
					secondary={t`**********`}
					primaryTypographyProps={{ variant: 'caption' }}
					secondaryTypographyProps={{ variant: 'body1' }}
				/>
				<ListItemSecondaryAction>
					<ActionButton
						onClick={() => setChangePass(true)}
						variant="text"
						color="primary"
						label={t`Change`}
					/>
				</ListItemSecondaryAction>
			</ListItem>
		);
	}

	return (
		<Fragment>
			{ssoIntermediary && (
				<ListItem>
					<Alert
						severity="info"
						title={t`my_account-new_password-sso_alert-title`}
					>
						{t`my_account-new_password-sso_alert-content`}
					</Alert>
				</ListItem>
			)}
			<ListItem disableGutters>
				<Link
					onClick={cancelChangePass}
					color="textSecondary"
				>
					<ChevronLeftIcon className={classes.backIcon} />
					{t`Cancel`}
				</Link>
			</ListItem>
			<ListItem>
				<PasswordInput
					value={pass}
					onChange={e => setPass(e.target.value)}
					label={t`Current password`}
					loading={loading}
					success={reauth.token ? true : false}
					error={!loading && pass.length > 3 && !reauth.token}
				/>
			</ListItem>
			<ListItem>
				<PasswordInput
					value={newPass}
					onChange={e => setNewPass(e.target.value)}
					label={t`New password`}
					disabled={!reauth.token}
					rules={[
						['min', minLength],
						['max', maxLength],
					]}
					errorMessage={
						newPassValid.code != 200
							? t(newPassValid.message, { minlength: minLength, maxlength: maxLength })
							: ''
					}
					showErrors={newPassValid.code === 400}
					success={newPassValid.code == 200}
					error={newPassValid.code === 400}
					loading={newPassValidLoading}
				/>
			</ListItem>
			<ListItem>
				<PasswordInput
					value={validatePass}
					onChange={e => setValidatePass(e.target.value)}
					label={t`Repeat new password`}
					disabled={!reauth.token}
					rules={[['custom', v => v.length > 0 && v === newPass]]}
					errorMessage={t`Passwords don't match`}
					onValidChange={valid => setValidatePassValid(valid)}
					showErrors={newPassValid.code == 200 && validatePass.length > 3}
					success={validatePassValid ? true : false}
					error={validatePass.length > 0 && validatePassValid === false}
				/>
			</ListItem>
		</Fragment>
	);
}

function PasswordInput(props) {
	const [show, setShow] = useState(false);
	const { t } = useTranslation();

	return (
		<ValidatedField
			fullWidth
			type={show ? 'text' : 'password'}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<ActionIconButton
							onClick={() => setShow(show => !show)}
							action={!show ? 'view' : 'hide'}
							tooltip={!show ? t`Show password` : t`Hide password`}
						/>
						{props.loading ? (
							<Loader
								circular
								size={16}
							/>
						) : props.success ? (
							<CheckIcon color="secondary" />
						) : props.error ? (
							<CloseIcon color="error" />
						) : null}
					</InputAdornment>
				),
			}}
			{...props}
		/>
	);
}
