import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import DeploymentEditor from './DeploymentEditor';
import { DrawerWithAppBar } from '@/components/App';

const useStyles = makeStyles(theme => ({
	drawer: {
		width: '100%',
		maxWidth: '80%',
		[theme.breakpoints.down('md')]: {
			maxWidth: '90%',
		},
	},
}));

export default function DeploymentEditorDrawer({ deploymentId, editorType, ...props }) {
	const classes = useStyles();

	const [toolbarRefState, setToolbarRefState] = useState(null);
	const { t } = useTranslation();

	return (
		<DrawerWithAppBar
			size="large"
			ToolbarProps={{
				text: t`Edit deployment`,
			}}
			ToolbarContent={
				<div
					className={classes.row}
					ref={node => setToolbarRefState(node)}
				/>
			}
			{...props}
		>
			<DeploymentEditor
				editorType={editorType}
				deploymentId={deploymentId}
				AppBarActionsContainer={toolbarRefState}
				inDrawer
			/>
		</DrawerWithAppBar>
	);
}
