import React, { useContext, useMemo, memo, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import ListSubheader from '@material-ui/core/ListSubheader';
import Chip from '@material-ui/core/Chip';

import { useTranslation } from 'react-i18next';

import { DataSourceContext, DataFieldIcon } from '../DataSources';
import { chartConstants } from '../Charts';

const useStyles = makeStyles(theme => ({
	select: {
		display: 'flex',
		alignItems: 'center',
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: theme.spacing(0.25),
	},
	subheader: {
		backgroundColor: '#fff',
	},
	inactive: {
		opacity: 0.5,
	},
	placeholder: {
		color: theme.palette.text.hint,
	},
}));

export default function DataFieldSelect({
	value,
	children,
	label,
	sourceId,
	includeAllandTags,
	renderValueTextOnly,
	renderChips,
	multiple,
	dataTestElement = null,
	addXisSameAsYOption,
	...props
}) {
	const { datasource } = useContext(DataSourceContext);
	const classes = useStyles();
	const { t } = useTranslation();

	useEffect(() => {
		if (sourceId) datasource.api.loadSurveyFields(sourceId);
	}, [sourceId]);

	const formattedFields = datasource.api.getFieldsFormattedByVarType(
		sourceId,
		includeAllandTags
	);

	const options = useMemo(() =>
		Object.keys(formattedFields).map((key, i) => {
			const category = formattedFields[key];
			if (category.fields.length === 0) return [];

			return [
				<ListSubheader className={classes.subheader}>{category.label}</ListSubheader>,
				...category.fields.map((field, i) => {
					return (
						<MenuItem
							value={field.id}
							className={!field.active ? classes.inactive : ''}
							data-test-element={dataTestElement ? dataTestElement + 'Option' : null}
						>
							{multiple && (
								<Checkbox
									checked={Boolean(
										Array.isArray(value) && value.find(val => val === field.id)
									)}
								/>
							)}
							<ListItemIcon>
								<DataFieldIcon {...field} />
							</ListItemIcon>
							<ListItemText
								primary={field.title ?? field.system_var}
								secondary={!field.active ? t`This field is no longer active` : null}
							/>
						</MenuItem>
					);
				}),
				//chart builder only -> allow multiple data series to offset against themself
				...(addXisSameAsYOption && key === 'category'
					? [
							<MenuItem value={chartConstants.xIsSameAsYSerieValue}>
								<ListItemIcon>
									<DataFieldIcon label={chartConstants.xIsSameAsYSerieValue} />
								</ListItemIcon>
								<ListItemText primary={t`data_field_select-x_same_as_y`} />
							</MenuItem>,
					  ]
					: []),
			];
		})
	);

	return (
		<FormControl fullWidth>
			{label && <InputLabel>{label}</InputLabel>}
			<Select
				value={value}
				inputProps={{ 'data-test-element': dataTestElement }}
				multiple={multiple}
				classes={{ select: classes.select }}
				{...(renderValueTextOnly && {
					displayEmpty: !label,
					renderValue: value => {
						const renderValue = Array.isArray(value)
							? value.map(v => datasource.api.getField(v).system_var).join(', ')
							: datasource.api.getField(value).system_var;

						if (renderValue || label) return renderValue;

						return <span className={classes.placeholder}>{t`Select a field`}</span>;
					},
				})}
				{...(renderChips && {
					renderValue: selected => (
						<div className={classes.chips}>
							{selected.map(value => {
								const { system_var } = datasource.api.getField(value);
								return (
									<Chip
										key={value}
										label={system_var}
										className={classes.chip}
									/>
								);
							})}
						</div>
					),
				})}
				{...props}
			>
				{options}
			</Select>
		</FormControl>
	);
}
