import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import ListSubheader from '@material-ui/core/ListSubheader';

import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';

import SwipeableViews from 'react-swipeable-views';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';
import get from 'lodash.get';

import { DataSourceList } from '@/components/DataSources';
import { useAppContext } from '@/components/AppContext';
import DeploymentIcon from './DeploymentIcon';

import {
	valueLabel,
	innerConditionBase,
	operators,
	isMultipleCssSelector,
} from './deploymentUtils';
import {
	EditorNestedOrAndArrayConditions,
	SwitchWithLabel,
	ButtonAddList,
	EditorOptionList,
	EditorOptionExpansionPanel,
	EditorListRadioGroup,
	EmptyState,
	ActionIconButton,
	Card,
	Grid,
	Typography,
	TextField,
	Menu,
	MenuItem,
	Box,
	Tab,
	Tabs,
	ListItemText,
	FormControl,
	FormControlLabel,
	Checkbox,
} from '@/components/Layout';
import { tryParse } from '@/utils';
import { format, isValid } from '@/utils/date';
import {
	HasPermissionWithEmptyState,
	permissionModules,
	permissionSubjects,
	NoProjectPermissionTooltip,
	usePermissionContext,
} from '@/components/Permission';
import { useDeploymentStore } from './DeploymentStore';

const useStyles = makeStyles(theme => ({
	tabRow: {
		//borderBottom:`1px solid ${theme.palette.divider}`
	},
	tabRoot: {
		//minWidth:60
	},
	tab: {
		height: '100%',
	},
	tabPadding: {
		padding: theme.spacing(1),
	},
	tabSpaceTop: {
		paddingTop: theme.spacing(1),
	},
	alignTextWithInput: {
		verticalAlign: 'middle',
	},
	inputInText: {
		margin: theme.spacing(0, 1),
		width: 100,
	},
	selectInput: {
		width: 'auto',
	},
	datePickerInput: {
		width: 140,
	},
	numberInput: {
		width: 60,
	},
	addCondition: {
		margin: theme.spacing(2, 0),
	},
	thenMargin: {
		marginBottom: theme.spacing(2),
	},
	marginLeft: {
		marginLeft: theme.spacing(2),
	},
	caption: {
		color: theme.palette.grey[600],
		display: 'block',
	},
	renderInput: {
		width: 320,
	},
}));

const dateTimeOperators = [
	{ value: 'earlier', label: 'before' },
	{ value: 'exactly', label: 'exactly' },
	{ value: 'later', label: 'after' },
	{ value: 'between', label: 'between' },
];

export default function DeploymentEditorSidebarContent({ state, dispatch, editorType }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const { app } = useAppContext();
	const { projects } = usePermissionContext();

	const activeDeploymentVersion = useDeploymentStore(store => store.workingDraft);
	const surveysByKey = useDeploymentStore(store => store.surveysByKey);
	const surveysByProject = useDeploymentStore(store => store.surveysByProject);

	const addRule = useDeploymentStore(store => store.actions.addRule);
	const addCondition = useDeploymentStore(store => store.actions.addCondition);
	const removeCondition = useDeploymentStore(store => store.actions.removeCondition);
	const setConditionOption = useDeploymentStore(
		store => store.actions.setConditionOption
	);
	const setNestedConditionOption = useDeploymentStore(
		store => store.actions.setNestedConditionOption
	);
	const setMobileConditionOption = useDeploymentStore(
		store => store.actions.setMobileConditionOption
	);
	const setNestedMobileConditionOption = useDeploymentStore(
		store => store.actions.setNestedMobileConditionOption
	);
	const addToConditionArray = useDeploymentStore(
		store => store.actions.addToConditionArray
	);
	const removeFromConditionArray = useDeploymentStore(
		store => store.actions.removeFromConditionArray
	);
	const setRenderDiv = useDeploymentStore(store => store.actions.setRenderDiv);
	const setRenderOption = useDeploymentStore(store => store.actions.setRenderOption);

	const [sidebarState, setSidebarState] = useImmer({
		addMenu: {},
		collapsed: {},
	});

	const selectedRule =
		activeDeploymentVersion.rules?.find(rule => rule.id === state.selectedRule) || {};
	const selectedRuleIndex = activeDeploymentVersion.rules?.findIndex(
		rule => rule.id === state.selectedRule
	);
	const surveyInRule = surveysByKey[get(selectedRule, 'then[0].args[0]]', '')] || {};

	const sortedSurveys = useMemo(() => {
		return [...(surveysByProject ?? [])].sort((a, b) => {
			const project = app.projects.byKey[b.project_id];
			return projects.includes(project?.uuid) ? 1 : -1;
		});
	}, [projects, surveysByProject, app.projects.byKey]);

	return (
		<>
			<div>
				<Tabs
					value={state.activeTab}
					onChange={(e, index) => dispatch({ type: 'set_tab', payload: index })}
					//variant="fullWidth"
					centered
					className={classes.tabRow}
				>
					<Tab
						label={t('Forms')}
						classes={{ root: classes.tabRoot }}
						data-onboarding="forms-tab"
					/>
					<Tab
						label={t('Targeting')}
						classes={{ root: classes.tabRoot }}
						data-onboarding="targetting-tab"
					/>
				</Tabs>
			</div>
			<HasPermissionWithEmptyState
				module={permissionModules.data_collection}
				subject={permissionSubjects.deployments}
			>
				<SwipeableViews
					axis="x"
					index={state.activeTab}
					onChangeIndex={index => dispatch({ type: 'set_tab', payload: index })}
					containerStyle={{
						maxHeight: '100%',
					}}
				>
					<div
						className={`${classes.tab} ${classes.tabSpaceTop}`}
						data-test-element="deployment-editor-forms-tab"
					>
						{sortedSurveys.map(project => {
							return (
								<div key={project.project_id}>
									<NoProjectPermissionTooltip projectId={Number(project.project_id)}>
										<DataSourceList
											collapsible={app.projects.asArray.length > 1}
											dragSource
											dataSources={
												editorType === 'sdk'
													? [
															{
																name: project.project_name,
																arr: project.sdk_surveys,
															},
															{
																subtitle: t(
																	`data_collection-deployment_editor-project_name-web`,
																	{ projectName: project.project_name }
																),
																arr: project.web_surveys,
															},
													  ]
													: [{ name: project.project_name, arr: project.web_surveys }]
											}
											onSelectDataSource={(source, e) => {
												const { target } = e;
												setSidebarState(draft => {
													draft.addMenu = {
														target,
														source,
													};
												});
											}}
										/>
									</NoProjectPermissionTooltip>
								</div>
							);
						})}

						<Menu
							open={Boolean(sidebarState.addMenu.target)}
							anchorEl={sidebarState.addMenu.target}
							onClose={() =>
								setSidebarState(draft => {
									draft.addMenu = {};
								})
							}
						>
							<ListSubheader>{`${t`Add`} ${get(
								sidebarState.addMenu,
								'source.name'
							)} ${t`to deployment`}?`}</ListSubheader>
							<MenuItem
								data-test-element="deployment-editor-menu-item-add-to"
								onClick={() => {
									setSidebarState(draft => {
										draft.addMenu.target = null;
									});

									addRule({
										rule: {
											surveyKey: sidebarState.addMenu.source.survey_key,
											domain: app.domain,
											surveyType:
												sidebarState.addMenu.source.campaign === 'sdk' ||
												tryParse(sidebarState.addMenu.source.advanced).sdk_survey
													? 'sdk'
													: 'web',
											editorType: editorType,
										},
									});
								}}
							>
								{t`Add to deployment`}
							</MenuItem>
						</Menu>
					</div>

					<div className={`${classes.tab} ${classes.tabPadding}`}>
						{Object.keys(selectedRule).length > 0 ? (
							<>
								<Typography variant="subtitle2">{`${t`Condition sets for`} ${
									surveyInRule.name
								}`}</Typography>
								<Typography
									variant="body2"
									color="textSecondary"
									gutterBottom
								>
									{t(
										`data_collection-deployment_editor_sidebar_content-rule-helper-${editorType}`
									)}
								</Typography>

								<div>
									{get(selectedRule, 'if', []).map((conditionSet, conditionIndex) => {
										const setOption = (key, value) => {
											setConditionOption({
												ruleIndex: selectedRuleIndex,
												conditionIndex,
												condition: key,
												value,
											});
										};

										const setNestedOption = (key, value, nested, nested2, nested3) => {
											setNestedConditionOption({
												ruleIndex: selectedRuleIndex,
												conditionIndex,
												condition: key,
												value,
												nested,
												nested2,
												nested3,
											});
										};

										const setMobileOption = (key, value, nested) => {
											setMobileConditionOption({
												ruleIndex: selectedRuleIndex,
												conditionIndex,
												condition: key,
												nested,
												value,
											});
										};

										const setNestedMobileOption = ({
											condition,
											nested,
											nested2,
											nested3,
											value,
										}) => {
											setNestedMobileConditionOption({
												ruleIndex: selectedRuleIndex,
												conditionIndex,
												condition,
												nested,
												nested2,
												nested3,
												value,
											});
										};

										const addArrayOption = (key, value, conditionOrIndex) => {
											addToConditionArray({
												ruleIndex: selectedRuleIndex,
												conditionIndex,
												condition: key,
												conditionOrIndex,
												value,
											});
										};
										const removeArrayOption = (key, index, orIndex) => {
											removeFromConditionArray({
												ruleIndex: selectedRuleIndex,
												conditionIndex,
												condition: key,
												index,
												orIndex,
											});
										};

										//const ruleId =

										return (
											<EditorOptionExpansionPanel
												title={`${t`Condition set`} ${
													conditionIndex + 1
												} - ${t`triggered`} ${t(valueLabel(conditionSet.trigger))}`}
												subtitle={`${t`When triggered`} ${t(
													valueLabel(conditionSet.trigger)
												)}`}
												summaryActions={
													<>
														<ActionIconButton
															action="remove_list"
															onClick={() =>
																removeCondition({
																	ruleIndex: selectedRuleIndex,
																	conditionIndex,
																})
															}
														/>
													</>
												}
												key={selectedRule.id + conditionIndex}
												disablePadding
												expanded={
													!Boolean(
														sidebarState.collapsed[`${selectedRule.id + conditionIndex}`]
													)
												}
												onChange={(e, expanded) => {
													setSidebarState(draft => {
														if (expanded) {
															delete draft.collapsed[
																`${selectedRule.id + conditionIndex}`
															];
														} else {
															draft.collapsed[
																`${selectedRule.id + conditionIndex}`
															] = true;
														}
													});
												}}
												data-onboarding="condition-card"
											>
												<EditorOptionList
													startDivider
													options={
														editorType === 'sdk'
															? [
																	{
																		custom: (
																			<EditorListRadioGroup
																				title={t`How should the form be initialised`}
																				size="small"
																				grow
																				value={conditionSet.trigger}
																				onChange={(e, value) =>
																					setOption('trigger', value)
																				}
																				options={[
																					{
																						label: `${t`Triggered`} ${t(
																							valueLabel('passive')
																						)}`,
																						value: 'passive',
																						icon: <DeploymentIcon value="passive" />,
																					},
																					{
																						label: `${t`Triggered`} ${t(
																							valueLabel('proactive')
																						)}`,
																						value: 'proactive',
																						icon: <DeploymentIcon value="proactive" />,
																					},
																				]}
																				helperText={
																					conditionSet.trigger === 'passive'
																						? t`A form triggered by button will open everytime the event fires`
																						: t`A form triggered proactively will open once per session`
																				}
																			/>
																		),
																		divider: true,
																	},

																	{
																		custom: (
																			<ListItemText
																				primary={t`Show to users when one of these events fire`}
																			/>
																		),
																		divider: false,
																	},
																	{
																		custom: (
																			<>
																				<Box width="100%">
																					{conditionSet.mobile.events.map(
																						(event, eventIndex) => {
																							return (
																								<Grid
																									container
																									key={'event' + eventIndex}
																								>
																									<Grid
																										item
																										xs
																									>
																										<TextField
																											fullWidth
																											placeholder={
																												'For example: after_sign_up'
																											}
																											value={event}
																											onChange={e =>
																												setMobileOption(
																													'events',
																													e.target.value,
																													eventIndex
																												)
																											}
																										/>
																									</Grid>
																									<Grid item>
																										<ActionIconButton
																											action="remove_list"
																											onClick={() =>
																												removeArrayOption(
																													'mobile',
																													'events',
																													eventIndex
																												)
																											}
																										/>
																									</Grid>
																								</Grid>
																							);
																						}
																					)}

																					<Box
																						clone
																						mt={1}
																					>
																						<ButtonAddList
																							label={t`Or`}
																							onClick={() =>
																								addArrayOption('mobile', '', 'events')
																							}
																						/>
																					</Box>
																				</Box>
																			</>
																		),
																		divider: true,
																	},

																	{
																		custom: (
																			<ListItemText
																				primary={
																					<>
																						<span
																							className={classes.alignTextWithInput}
																						>{t`Refresh condition settings per visitor after`}</span>
																						<TextField
																							className={`${classes.inputInText} ${classes.numberInput}`}
																							value={conditionSet.session}
																							type="number"
																							inputProps={{ min: 0 }}
																							onChange={e =>
																								setOption('session', e.target.value)
																							}
																						/>
																						<span className={classes.alignTextWithInput}>
																							{t`days`}.
																						</span>
																					</>
																				}
																			/>
																		),
																		divider: true,
																	},

																	{
																		primary: t`Show only on specific OS (and OS version)`,
																		onClick: () =>
																			setMobileOption(
																				'target',
																				get(conditionSet, 'mobile.target', []).length ===
																					0
																					? [{ os: '', version: [''] }]
																					: []
																			),
																		action: (
																			<Switch
																				checked={
																					get(conditionSet, 'mobile.target', []).length >
																					0
																				}
																			/>
																		),
																		divider:
																			get(conditionSet, 'mobile.target', []).length === 0,
																		lessenTextOpacity:
																			get(conditionSet, 'mobile.target', []).length === 0,
																	},
																	{
																		show:
																			get(conditionSet, 'mobile.target', []).length > 0,
																		custom: (
																			<>
																				<Box width="100%">
																					{conditionSet.mobile.target.map(
																						(target, targetIndex) => {
																							return (
																								<Grid
																									container
																									key={'target' + targetIndex}
																									spacing={1}
																								>
																									<Grid
																										item
																										xs
																									>
																										<TextField
																											select
																											fullWidth
																											value={
																												conditionSet.mobile.target[
																													targetIndex
																												].os
																											}
																											onChange={e =>
																												setNestedMobileOption({
																													condition: 'target',
																													nested: targetIndex,
																													nested2: 'os',
																													value: e.target.value,
																												})
																											}
																											SelectProps={{
																												displayEmpty: true,
																											}}
																										>
																											<MenuItem
																												value=""
																												disabled
																											>{t`Select OS`}</MenuItem>
																											<MenuItem value="ios">{t`iOS`}</MenuItem>
																											<MenuItem value="android">{t`Android`}</MenuItem>
																										</TextField>
																									</Grid>
																									<Grid
																										item
																										xs
																									>
																										<TextField
																											fullWidth
																											value={get(
																												conditionSet,
																												`mobile.target[${targetIndex}].version`,
																												[]
																											).join(',')}
																											onChange={e =>
																												setNestedMobileOption({
																													condition: 'target',
																													nested: targetIndex,
																													nested2: 'version',
																													value:
																														e.currentTarget.value.split(
																															','
																														),
																												})
																											}
																											placeholder={t`E.g: 11.1, 11.2, 12`}
																										/>
																									</Grid>
																									<Grid item>
																										<ActionIconButton
																											action="remove_list"
																											onClick={() =>
																												removeArrayOption(
																													'mobile',
																													targetIndex,
																													'target'
																												)
																											}
																										/>
																									</Grid>
																								</Grid>
																							);
																						}
																					)}

																					<Box
																						clone
																						mt={1}
																					>
																						<ButtonAddList
																							label={t`Or`}
																							onClick={() =>
																								addArrayOption(
																									'mobile',
																									{ os: '', version: [''] },
																									'target'
																								)
																							}
																						/>
																					</Box>
																				</Box>
																			</>
																		),
																		divider: true,
																	},

																	{
																		primary: t`Show only to a percentage of users`,
																		onClick: () =>
																			setOption(
																				'percentage',
																				conditionSet.percentage === null ? 100 : null
																			),
																		action: (
																			<Switch
																				checked={conditionSet.percentage !== null}
																			/>
																		),
																		divider: conditionSet.percentage === null,
																		lessenTextOpacity: conditionSet.percentage === null,
																	},
																	{
																		show: conditionSet.percentage !== null,
																		custom: (
																			<>
																				<Typography
																					variant="body1"
																					display="inline"
																					className={classes.alignTextWithInput}
																				>{t`Show to`}</Typography>
																				<TextField
																					className={`${classes.inputInText} ${classes.numberInput}`}
																					value={conditionSet.percentage || ''}
																					type="number"
																					inputProps={{ min: 0, max: 100 }}
																					onChange={e =>
																						setOption('percentage', e.target.value)
																					}
																				/>
																				<Typography
																					variant="body1"
																					display="inline"
																					className={classes.alignTextWithInput}
																				>
																					% {t`of users`}.
																				</Typography>
																			</>
																		),
																		divider: true,
																	},
																	{
																		primary: t`Show on a specific date`,
																		onClick: () =>
																			setOption(
																				'date',
																				conditionSet.date?.hasOwnProperty('date')
																					? {}
																					: innerConditionBase('date')
																			),
																		action: (
																			<Switch
																				checked={conditionSet.date?.hasOwnProperty(
																					'date'
																				)}
																			/>
																		),
																		divider: !conditionSet.date?.hasOwnProperty('date'),
																		lessenTextOpacity:
																			!conditionSet.date?.hasOwnProperty('date'),
																	},
																	{
																		show: conditionSet.date?.hasOwnProperty('date'),
																		wrap: true,
																		custom: (
																			<>
																				<Typography
																					variant="body1"
																					display="inline"
																					className={classes.alignTextWithInput}
																				>{t`Show when date is`}</Typography>
																				<TextField
																					select
																					className={`${classes.inputInText} ${classes.selectInput}`}
																					value={conditionSet.date?.operator}
																					onChange={e =>
																						setNestedOption(
																							'date',
																							e.target.value,
																							'operator'
																						)
																					}
																				>
																					{dateTimeOperators.map(dtOperator => {
																						return (
																							<MenuItem
																								key={dtOperator.value}
																								value={dtOperator.value}
																							>
																								{t(dtOperator.label)}
																							</MenuItem>
																						);
																					})}
																				</TextField>

																				<KeyboardDatePicker
																					ampm={false}
																					variant="inline"
																					className={`${classes.inputInText} ${classes.datePickerInput}`}
																					value={
																						conditionSet.date?.date
																							? new Date(
																									conditionSet.date?.date
																										.split('/')
																										.reverse()
																							  )
																							: new Date()
																					}
																					onChange={date => {
																						if (isValid(date)) {
																							setNestedOption(
																								'date',
																								format(date, 'dd/MM/yyyy'),
																								'date'
																							);
																						}
																					}}
																					format="dd/MM/yyyy"
																				/>

																				{conditionSet.date?.operator === 'between' && (
																					<>
																						<Typography
																							variant="body1"
																							display="inline"
																							className={classes.alignTextWithInput}
																						>{t`and`}</Typography>
																						<KeyboardDatePicker
																							ampm={false}
																							variant="inline"
																							className={`${classes.inputInText} ${classes.datePickerInput}`}
																							value={
																								conditionSet.date?.date2
																									? new Date(
																											conditionSet.date?.date2
																												.split('/')
																												.reverse()
																									  )
																									: new Date()
																							}
																							onChange={date => {
																								if (isValid(date)) {
																									setNestedOption(
																										'date',
																										format(date, 'dd/MM/yyyy'),
																										'date2'
																									);
																								}
																							}}
																							format="dd/MM/yyyy"
																						/>
																					</>
																				)}
																			</>
																		),
																		divider: true,
																	},

																	{
																		primary: t`Show on a specific time`,
																		onClick: () =>
																			setOption(
																				'clock',
																				conditionSet.clock?.hasOwnProperty('time')
																					? {}
																					: innerConditionBase('clock')
																			),
																		action: (
																			<Switch
																				checked={conditionSet.clock?.hasOwnProperty(
																					'time'
																				)}
																			/>
																		),
																		divider: !conditionSet.clock?.hasOwnProperty('time'),
																		lessenTextOpacity:
																			!conditionSet.clock?.hasOwnProperty('time'),
																	},
																	{
																		show: conditionSet.clock?.hasOwnProperty('time'),
																		wrap: true,
																		custom: (
																			<>
																				<Typography
																					variant="body1"
																					display="inline"
																					className={classes.alignTextWithInput}
																				>{t`Show when time is`}</Typography>
																				<TextField
																					select
																					className={`${classes.inputInText} ${classes.selectInput}`}
																					value={conditionSet.clock?.operator}
																					onChange={e =>
																						setNestedOption(
																							'clock',
																							e.target.value,
																							'operator'
																						)
																					}
																				>
																					{dateTimeOperators.map(dtOperator => {
																						return (
																							<MenuItem
																								key={dtOperator.value}
																								value={dtOperator.value}
																							>
																								{t(dtOperator.label)}
																							</MenuItem>
																						);
																					})}
																				</TextField>

																				<KeyboardTimePicker
																					ampm={false}
																					variant="inline"
																					className={`${classes.inputInText}`}
																					value={
																						new Date([
																							'1970',
																							'1',
																							'1',
																							conditionSet.clock?.time || '12:00',
																						])
																					}
																					onChange={date => {
																						if (isValid(date)) {
																							setNestedOption(
																								'clock',
																								format(date, 'HH:mm'),
																								'time'
																							);
																						}
																					}}
																				/>

																				{conditionSet.clock?.operator === 'between' && (
																					<>
																						<Typography
																							variant="body1"
																							display="inline"
																							className={classes.alignTextWithInput}
																						>{t`and`}</Typography>
																						<KeyboardTimePicker
																							ampm={false}
																							variant="inline"
																							className={`${classes.inputInText}`}
																							value={
																								new Date([
																									'1970',
																									'1',
																									'1',
																									conditionSet.clock?.time2 || '23:59',
																								])
																							}
																							onChange={date => {
																								if (isValid(date)) {
																									setNestedOption(
																										'clock',
																										format(date, 'HH:mm'),
																										'time2'
																									);
																								}
																							}}
																						/>
																					</>
																				)}
																			</>
																		),
																		divider: true,
																	},
															  ]
															: [
																	{
																		custom: (
																			<EditorListRadioGroup
																				title={t`How should the form be initialised`}
																				size="small"
																				grow
																				value={conditionSet.trigger}
																				onChange={(e, value) =>
																					setOption('trigger', value)
																				}
																				options={[
																					{
																						label: `${t`Triggered`} ${t(
																							valueLabel('passive')
																						)}`,
																						value: 'passive',
																						icon: <DeploymentIcon value="passive" />,
																					},
																					{
																						label: `${t`Triggered`} ${t(
																							valueLabel('proactive')
																						)}`,
																						value: 'proactive',
																						icon: <DeploymentIcon value="proactive" />,
																					},
																					{
																						label: `${t`Triggered`} ${t(
																							valueLabel('exit')
																						)}`,
																						value: 'exit',
																						icon: <DeploymentIcon value="exit" />,
																					},
																				]}
																			/>
																		),
																		divider: true,
																	},

																	{
																		primary: t`Pause condition set`,
																		secondary: t`Turn on to temporarily disable this condition set`,
																		onClick: () =>
																			setOption('pause', !conditionSet.pause),
																		action: (
																			<Switch checked={Boolean(conditionSet.pause)} />
																		),
																		divider: true,
																		lessenTextOpacity: !Boolean(conditionSet.pause),
																	},
																	{
																		custom: (
																			<ListItemText
																				primary={
																					<>
																						<span
																							className={classes.alignTextWithInput}
																						>{t`Refresh condition settings per visitor after`}</span>
																						<TextField
																							className={`${classes.inputInText} ${classes.numberInput}`}
																							value={conditionSet.session}
																							type="number"
																							inputProps={{ min: 0 }}
																							onChange={e =>
																								setOption('session', e.target.value)
																							}
																						/>
																						<span className={classes.alignTextWithInput}>
																							{t`days`}.
																						</span>
																					</>
																				}
																			/>
																		),
																		divider: true,
																	},

																	{
																		primary: t`Show only to a percentage of users`,
																		onClick: () =>
																			setOption(
																				'percentage',
																				conditionSet.percentage === null ? 100 : null
																			),
																		action: (
																			<Switch
																				checked={conditionSet.percentage !== null}
																			/>
																		),
																		divider: conditionSet.percentage === null,
																		lessenTextOpacity: conditionSet.percentage === null,
																	},
																	{
																		show: conditionSet.percentage !== null,
																		custom: (
																			<>
																				<Typography
																					variant="body1"
																					display="inline"
																					className={classes.alignTextWithInput}
																				>{t`Show to`}</Typography>
																				<TextField
																					className={`${classes.inputInText} ${classes.numberInput}`}
																					value={conditionSet.percentage || ''}
																					type="number"
																					inputProps={{ min: 0, max: 100 }}
																					onChange={e =>
																						setOption('percentage', e.target.value)
																					}
																				/>
																				<Typography
																					variant="body1"
																					display="inline"
																					className={classes.alignTextWithInput}
																				>
																					% {t`of users`}.
																				</Typography>
																			</>
																		),
																		divider: true,
																	},

																	{
																		primary: t`Show based on URL conditions`,
																		secondary:
																			conditionSet.location.length > 0
																				? t`Your url's do not require https:// or www.`
																				: '',
																		onClick: () =>
																			setOption(
																				'location',
																				conditionSet.location.length === 0
																					? [[innerConditionBase('location')]]
																					: []
																			),
																		action: (
																			<Switch
																				checked={conditionSet.location.length > 0}
																			/>
																		),
																		divider: conditionSet.location.length === 0,
																		lessenTextOpacity: conditionSet.location.length === 0,
																	},
																	{
																		show: conditionSet.location.length > 0,
																		custom: (
																			<>
																				<EditorNestedOrAndArrayConditions
																					conditionName="location"
																					orArray={conditionSet.location}
																					onAddOr={({ conditionName }) =>
																						addArrayOption(conditionName, [
																							innerConditionBase(conditionName),
																						])
																					}
																					onAddAnd={({ conditionName, orIndex }) =>
																						addArrayOption(
																							'location',
																							innerConditionBase(conditionName),
																							orIndex
																						)
																					}
																					onRemoveAnd={({
																						andArr,
																						orIndex,
																						andIndex,
																						conditionName,
																					}) =>
																						andArr.length === 1
																							? removeArrayOption(conditionName, orIndex)
																							: removeArrayOption(
																									conditionName,
																									andIndex,
																									orIndex
																							  )
																					}
																				>
																					{({
																						conditionObj,
																						andIndex,
																						andArr,
																						orIndex,
																					}) => (
																						<Grid
																							container
																							spacing={2}
																							alignItems="center"
																						>
																							<Grid
																								item
																								xs={4}
																							>
																								<TextField
																									select
																									fullWidth
																									value={
																										conditionSet.location[orIndex][
																											andIndex
																										].operator
																									}
																									onChange={e =>
																										setNestedOption(
																											'location',
																											e.target.value,
																											orIndex,
																											andIndex,
																											'operator'
																										)
																									}
																								>
																									{operators('location').map(
																										operatorObj => (
																											<MenuItem
																												key={operatorObj.value}
																												value={operatorObj.value}
																											>
																												{operatorObj.label}
																											</MenuItem>
																										)
																									)}
																								</TextField>
																							</Grid>
																							<Grid
																								item
																								xs={5}
																							>
																								<TextField
																									fullWidth
																									placeholder="yourwebsite.com/path"
																									value={
																										conditionSet.location[orIndex][
																											andIndex
																										].value
																									}
																									onChange={e => {
																										const strippedValue =
																											e.target.value.replace(
																												/^(?:https?:\/\/)?(?:www\.)?/i,
																												''
																											);
																										setNestedOption(
																											'location',
																											strippedValue,
																											orIndex,
																											andIndex,
																											'value'
																										);
																									}}
																								/>
																							</Grid>

																							<Grid
																								item
																								xs={3}
																							>
																								<SwitchWithLabel
																									label={t`Regex`}
																									tooltip={t`Evaluates the value as a Regular Expression (advanced JavaScript knowledge recommended).`}
																									checked={
																										conditionSet.location[orIndex][
																											andIndex
																										].regex
																									}
																									onChange={e =>
																										setNestedOption(
																											'location',
																											e.target.checked,
																											orIndex,
																											andIndex,
																											'regex'
																										)
																									}
																								/>
																							</Grid>
																						</Grid>
																					)}
																				</EditorNestedOrAndArrayConditions>
																			</>
																		),

																		divider: true,
																	},

																	{
																		primary: t`Show to users coming from a specific page`,
																		secondary:
																			conditionSet.referrer.length > 0
																				? t`Your url's do not require https:// or www.`
																				: '',
																		onClick: () =>
																			setOption(
																				'referrer',
																				conditionSet.referrer.length === 0
																					? [innerConditionBase('referrer')]
																					: []
																			),
																		action: (
																			<Switch
																				checked={conditionSet.referrer.length > 0}
																			/>
																		),
																		divider: conditionSet.referrer.length === 0,
																		lessenTextOpacity: conditionSet.referrer.length === 0,
																	},
																	{
																		show: conditionSet.referrer.length > 0,
																		custom: (
																			<>
																				<Box width="100%">
																					{conditionSet.referrer.map(
																						(referrer, referrerIndex) => {
																							return (
																								<Grid
																									container
																									key={'referrer' + referrerIndex}
																									spacing={2}
																									alignItems="center"
																								>
																									<Grid
																										item
																										xs={4}
																									>
																										<TextField
																											select
																											fullWidth
																											value={
																												typeof conditionSet.referrer[
																													referrerIndex
																												] === 'object'
																													? conditionSet.referrer[
																															referrerIndex
																													  ].operator
																													: 'exists'
																											}
																											onChange={e =>
																												setNestedOption(
																													'referrer',
																													e.target.value,
																													referrerIndex,
																													'operator'
																												)
																											}
																										>
																											{operators('referrer').map(
																												operatorObj => (
																													<MenuItem
																														key={operatorObj.value}
																														value={operatorObj.value}
																													>
																														{operatorObj.label}
																													</MenuItem>
																												)
																											)}
																										</TextField>
																									</Grid>
																									<Grid
																										item
																										xs={4}
																									>
																										<TextField
																											fullWidth
																											placeholder={
																												'yourwebsite.com/previous-path'
																											}
																											value={
																												typeof conditionSet.referrer[
																													referrerIndex
																												] === 'object'
																													? conditionSet.referrer[
																															referrerIndex
																													  ].value
																													: conditionSet.referrer[
																															referrerIndex
																													  ]
																											}
																											onChange={e => {
																												const strippedValue =
																													e.target.value.replace(
																														/^(?:https?:\/\/)?(?:www\.)?/i,
																														''
																													);
																												setNestedOption(
																													'referrer',
																													strippedValue,
																													referrerIndex,
																													'value'
																												);
																											}}
																										/>
																									</Grid>

																									<Grid
																										item
																										xs={2}
																									>
																										<SwitchWithLabel
																											label={t`Regex`}
																											tooltip={t`Evaluates the value as a Regular Expression (advanced JavaScript knowledge recommended).`}
																											checked={
																												typeof conditionSet.referrer[
																													referrerIndex
																												] === 'object'
																													? conditionSet.referrer[
																															referrerIndex
																													  ].regex
																													: false
																											}
																											onChange={e =>
																												setNestedOption(
																													'referrer',
																													e.target.checked,
																													referrerIndex,
																													'regex'
																												)
																											}
																										/>
																									</Grid>
																									<Grid
																										item
																										xs={2}
																									>
																										<ActionIconButton
																											className={classes.marginLeft}
																											action="remove_list"
																											onClick={() =>
																												removeArrayOption(
																													'referrer',
																													referrerIndex
																												)
																											}
																										/>
																									</Grid>
																								</Grid>
																							);
																						}
																					)}

																					<Box
																						mt={1}
																						width="100%"
																						display="flex"
																						flexDirection="row"
																						justifyContent="flex-end"
																					>
																						<ButtonAddList
																							label={t`Or`}
																							onClick={() =>
																								addArrayOption(
																									'referrer',
																									innerConditionBase('referrer')
																								)
																							}
																						/>
																					</Box>
																				</Box>
																			</>
																		),
																		divider: true,
																	},

																	{
																		primary: t`Show form after a specific time on a page`,
																		onClick: () =>
																			setOption(
																				'time',
																				conditionSet.time === null ? 0 : null
																			),
																		action: (
																			<Switch checked={conditionSet.time !== null} />
																		),
																		divider: conditionSet.time === null,
																		lessenTextOpacity: conditionSet.time === null,
																	},
																	{
																		show: conditionSet.time !== null,
																		wrap: true,
																		custom: (
																			<>
																				<Typography
																					variant="body1"
																					display="inline"
																					className={classes.alignTextWithInput}
																				>{t`Initialize trigger after`}</Typography>
																				<TextField
																					className={`${classes.inputInText} ${classes.numberInput}`}
																					value={conditionSet.time || 0}
																					type="number"
																					inputProps={{ min: 0 }}
																					onChange={e =>
																						setOption('time', e.target.value)
																					}
																				/>
																				<Typography
																					variant="body1"
																					display="inline"
																					className={classes.alignTextWithInput}
																				>{t`seconds on a single page.`}</Typography>
																			</>
																		),
																		divider: true,
																	},

																	{
																		primary: t`Show on a specific date`,
																		onClick: () =>
																			setOption(
																				'date',
																				conditionSet.date?.hasOwnProperty('date')
																					? {}
																					: innerConditionBase('date')
																			),
																		action: (
																			<Switch
																				checked={conditionSet.date?.hasOwnProperty(
																					'date'
																				)}
																			/>
																		),
																		divider: !conditionSet.date?.hasOwnProperty('date'),
																		lessenTextOpacity:
																			!conditionSet.date?.hasOwnProperty('date'),
																	},
																	{
																		show: conditionSet.date?.hasOwnProperty('date'),
																		wrap: true,
																		custom: (
																			<>
																				<Typography
																					variant="body1"
																					display="inline"
																					className={classes.alignTextWithInput}
																				>{t`Show when date is`}</Typography>
																				<TextField
																					select
																					className={`${classes.inputInText} ${classes.selectInput}`}
																					value={conditionSet.date?.operator}
																					onChange={e =>
																						setNestedOption(
																							'date',
																							e.target.value,
																							'operator'
																						)
																					}
																				>
																					{dateTimeOperators.map(dtOperator => {
																						return (
																							<MenuItem
																								key={dtOperator.value}
																								value={dtOperator.value}
																							>
																								{t(dtOperator.label)}
																							</MenuItem>
																						);
																					})}
																				</TextField>

																				<KeyboardDatePicker
																					ampm={false}
																					variant="inline"
																					className={`${classes.inputInText} ${classes.datePickerInput}`}
																					value={
																						conditionSet.date?.date
																							? new Date(
																									conditionSet.date?.date
																										.split('/')
																										.reverse()
																							  )
																							: new Date()
																					}
																					onChange={date => {
																						if (isValid(date)) {
																							setNestedOption(
																								'date',
																								format(date, 'dd/MM/yyyy'),
																								'date'
																							);
																						}
																					}}
																					format="dd/MM/yyyy"
																				/>

																				{conditionSet.date?.operator === 'between' && (
																					<>
																						<Typography
																							variant="body1"
																							display="inline"
																							className={classes.alignTextWithInput}
																						>{t`and`}</Typography>
																						<KeyboardDatePicker
																							ampm={false}
																							variant="inline"
																							className={`${classes.inputInText} ${classes.datePickerInput}`}
																							value={
																								conditionSet.date?.date2
																									? new Date(
																											conditionSet.date?.date2
																												.split('/')
																												.reverse()
																									  )
																									: new Date()
																							}
																							onChange={date => {
																								if (isValid(date)) {
																									setNestedOption(
																										'date',
																										format(date, 'dd/MM/yyyy'),
																										'date2'
																									);
																								}
																							}}
																							format="dd/MM/yyyy"
																						/>
																					</>
																				)}
																			</>
																		),
																		divider: true,
																	},

																	{
																		primary: t`Show on a specific time`,
																		onClick: () =>
																			setOption(
																				'clock',
																				conditionSet.clock?.hasOwnProperty('time')
																					? {}
																					: innerConditionBase('clock')
																			),
																		action: (
																			<Switch
																				checked={conditionSet.clock?.hasOwnProperty(
																					'time'
																				)}
																			/>
																		),
																		divider: !conditionSet.clock?.hasOwnProperty('time'),
																		lessenTextOpacity:
																			!conditionSet.clock?.hasOwnProperty('time'),
																	},
																	{
																		show: conditionSet.clock?.hasOwnProperty('time'),
																		wrap: true,
																		custom: (
																			<>
																				<Typography
																					variant="body1"
																					display="inline"
																					className={classes.alignTextWithInput}
																				>{t`Show when time is`}</Typography>
																				<TextField
																					select
																					className={`${classes.inputInText} ${classes.selectInput}`}
																					value={conditionSet.clock?.operator}
																					onChange={e =>
																						setNestedOption(
																							'clock',
																							e.target.value,
																							'operator'
																						)
																					}
																				>
																					{dateTimeOperators.map(dtOperator => {
																						return (
																							<MenuItem
																								key={dtOperator.value}
																								value={dtOperator.value}
																							>
																								{t(dtOperator.label)}
																							</MenuItem>
																						);
																					})}
																				</TextField>

																				<KeyboardTimePicker
																					ampm={false}
																					variant="inline"
																					className={`${classes.inputInText}`}
																					value={
																						new Date([
																							'1970',
																							'1',
																							'1',
																							conditionSet.clock?.time || '12:00',
																						])
																					}
																					onChange={date => {
																						if (isValid(date)) {
																							setNestedOption(
																								'clock',
																								format(date, 'HH:mm'),
																								'time'
																							);
																						}
																					}}
																				/>

																				{conditionSet.clock?.operator === 'between' && (
																					<>
																						<Typography
																							variant="body1"
																							display="inline"
																							className={classes.alignTextWithInput}
																						>{t`and`}</Typography>
																						<KeyboardTimePicker
																							ampm={false}
																							variant="inline"
																							className={`${classes.inputInText}`}
																							value={
																								new Date([
																									'1970',
																									'1',
																									'1',
																									conditionSet.clock?.time2 || '23:59',
																								])
																							}
																							onChange={date => {
																								if (isValid(date)) {
																									setNestedOption(
																										'clock',
																										format(date, 'HH:mm'),
																										'time2'
																									);
																								}
																							}}
																						/>
																					</>
																				)}
																			</>
																		),
																		divider: true,
																	},

																	{
																		primary: t`Show only to visitors using a certain device`,
																		onClick: () =>
																			setOption(
																				'devices',
																				conditionSet.devices.length > 0 ? [] : ['mobile']
																			),
																		action: (
																			<Switch checked={conditionSet.devices.length > 0} />
																		),
																		divider: conditionSet.devices.length === 0,
																		lessenTextOpacity: conditionSet.devices.length === 0,
																	},
																	{
																		show: conditionSet.devices.length > 0,
																		custom: (
																			<>
																				<Box width="100%">
																					<Typography
																						variant="body1"
																						display="block"
																					>{t`Show only on`}</Typography>
																					<FormControl>
																						<FormGroup>
																							{[
																								{ label: t`Mobile`, value: 'mobile' },
																								{ label: t`Tablet`, value: 'tablet' },
																								{ label: t`Desktop`, value: 'desktop' },
																							].map(deviceType => (
																								<FormControlLabel
																									key={deviceType.value}
																									control={
																										<Checkbox
																											checked={
																												conditionSet.devices.indexOf(
																													deviceType.value
																												) > -1
																											}
																											onChange={e =>
																												e.target.checked
																													? addArrayOption(
																															'devices',
																															deviceType.value
																													  )
																													: removeArrayOption(
																															'devices',
																															conditionSet.devices.indexOf(
																																deviceType.value
																															)
																													  )
																											}
																											value={deviceType.value}
																										/>
																									}
																									label={deviceType.label}
																								/>
																							))}
																						</FormGroup>
																					</FormControl>
																				</Box>
																			</>
																		),
																		divider: true,
																	},

																	{
																		primary: t`Show form after a specific time on the entire website`,
																		onClick: () =>
																			setOption(
																				'time_in_session',
																				conditionSet.time_in_session === null ? 0 : null
																			),
																		action: (
																			<Switch
																				checked={conditionSet.time_in_session !== null}
																			/>
																		),
																		divider: conditionSet.time_in_session === null,
																		lessenTextOpacity:
																			conditionSet.time_in_session === null,
																	},
																	{
																		show: conditionSet.time_in_session !== null,
																		custom: (
																			<>
																				<Typography
																					variant="body1"
																					display="inline"
																					className={classes.alignTextWithInput}
																				>{t`Initialize trigger after`}</Typography>
																				<TextField
																					className={`${classes.inputInText} ${classes.numberInput}`}
																					value={conditionSet.time_in_session || 0}
																					type="number"
																					inputProps={{ min: 0 }}
																					onChange={e =>
																						setOption('time_in_session', e.target.value)
																					}
																				/>
																				<Typography
																					variant="body1"
																					display="inline"
																					className={classes.alignTextWithInput}
																				>{t`seconds on the entire website.`}</Typography>
																			</>
																		),
																		divider: true,
																	},

																	{
																		primary: t`Show to users after visiting a specific amount of pages`,
																		onClick: () =>
																			setOption(
																				'number_of_pages',
																				conditionSet.number_of_pages.hasOwnProperty(
																					'amount'
																				)
																					? {}
																					: innerConditionBase('number_of_pages')
																			),
																		action: (
																			<Switch
																				checked={conditionSet.number_of_pages.hasOwnProperty(
																					'amount'
																				)}
																			/>
																		),
																		divider:
																			!conditionSet.number_of_pages.hasOwnProperty(
																				'amount'
																			),
																		lessenTextOpacity:
																			!conditionSet.number_of_pages.hasOwnProperty(
																				'amount'
																			),
																	},
																	{
																		show: conditionSet.number_of_pages.hasOwnProperty(
																			'amount'
																		),
																		wrap: true,
																		custom: (
																			<>
																				<Typography
																					variant="body1"
																					display="inline"
																					className={classes.alignTextWithInput}
																				>{t`When the user has seen`}</Typography>
																				<TextField
																					select
																					className={`${classes.inputInText} ${classes.selectInput}`}
																					value={conditionSet.number_of_pages.check || ''}
																					onChange={e =>
																						setNestedOption(
																							'number_of_pages',
																							e.target.value,
																							'check'
																						)
																					}
																				>
																					{operators('number_of_pages').map(operator => (
																						<MenuItem
																							key={'numpages' + operator.value}
																							value={operator.value}
																						>
																							{operator.label}
																						</MenuItem>
																					))}
																				</TextField>
																				<TextField
																					className={`${classes.inputInText} ${classes.numberInput}`}
																					value={conditionSet.number_of_pages.amount || 0}
																					type="number"
																					inputProps={{ min: 0 }}
																					onChange={e =>
																						setNestedOption(
																							'number_of_pages',
																							e.target.value,
																							'amount'
																						)
																					}
																				/>
																				<Typography
																					variant="body1"
																					display="inline"
																					className={classes.alignTextWithInput}
																				>{t`pages.`}</Typography>
																			</>
																		),
																		divider: true,
																	},

																	{
																		primary: t`Show form after the user scrolls to a certain position`,
																		onClick: () =>
																			setOption(
																				'scrollpos',
																				Object.keys(conditionSet.scrollpos).length > 0
																					? {}
																					: innerConditionBase('scrollpos')
																			),
																		action: (
																			<Switch
																				checked={
																					Object.keys(conditionSet.scrollpos).length > 0
																				}
																			/>
																		),
																		divider:
																			Object.keys(conditionSet.scrollpos).length === 0,
																		lessenTextOpacity:
																			Object.keys(conditionSet.scrollpos).length === 0,
																	},
																	{
																		show: Object.keys(conditionSet.scrollpos).length > 0,
																		wrap: true,
																		custom: (
																			<>
																				<Typography
																					variant="body1"
																					display="inline"
																					className={classes.alignTextWithInput}
																				>{t`When the user scrolls`}</Typography>
																				<TextField
																					className={`${classes.inputInText} ${classes.numberInput}`}
																					value={
																						conditionSet.scrollpos.px
																							? String(conditionSet.scrollpos.px).replace(
																									'%',
																									''
																							  )
																							: 0
																					}
																					type="number"
																					inputProps={{ min: 0 }}
																					onChange={e =>
																						setNestedOption(
																							'scrollpos',
																							e.target.value,
																							'px'
																						)
																					}
																				/>

																				<TextField
																					select
																					className={`${classes.inputInText} ${classes.selectInput}`}
																					value={
																						conditionSet.scrollpos.px &&
																						conditionSet.scrollpos.px.indexOf('%') > -1
																							? '%'
																							: 'px'
																					}
																					onChange={e =>
																						setNestedOption(
																							'scrollpos',
																							e.target.value === '%'
																								? String(
																										conditionSet.scrollpos.px
																								  ).replace('%', '') + '%'
																								: String(
																										conditionSet.scrollpos.px
																								  ).replace('%', ''),
																							'px'
																						)
																					}
																				>
																					<MenuItem value="px">{t`pixels`}</MenuItem>
																					<MenuItem value="%">{t`percentage`}</MenuItem>
																				</TextField>
																				<Typography
																					variant="body1"
																					display="inline"
																					className={classes.alignTextWithInput}
																				>{t`from the`}</Typography>
																				<TextField
																					select
																					className={`${classes.inputInText} ${classes.selectInput}`}
																					value={conditionSet.scrollpos.from}
																					onChange={e =>
																						setNestedOption(
																							'scrollpos',
																							e.target.value,
																							'from'
																						)
																					}
																				>
																					{operators('scrollpos').map(operator => (
																						<MenuItem
																							key={operator.value}
																							value={operator.value}
																						>
																							{t(operator.label)}
																						</MenuItem>
																					))}
																				</TextField>
																			</>
																		),
																		divider: true,
																	},

																	...[
																		{
																			primary: t`Show based on the users cookies`,
																			hintText: '',
																			conditionName: 'cookie',
																			placeholderKey: t`yourCookie`,
																			placeholderValue: t`A cookie value`,
																		},
																		{
																			primary: t`Show based on JavaScript variables on the page`,
																			hintText: '',
																			conditionName: 'js',
																			placeholderKey: t`yourVar`,
																			placeholderValue: t`A variable value`,
																		},
																		{
																			primary: t`Show based on HTML elements on the page`,
																			hintText: '',
																			conditionName: 'css_selector',
																			placeholderKey: t`#your-element`,
																			placeholderValue: t`An element value`,
																		},
																	].flatMap(obj => {
																		return [
																			{
																				primary: obj.primary,
																				onClick: () =>
																					setOption(
																						obj.conditionName,
																						conditionSet[obj.conditionName].length === 0
																							? [[innerConditionBase(obj.conditionName)]]
																							: []
																					),
																				action: (
																					<Switch
																						checked={
																							conditionSet[obj.conditionName]?.length >
																								0 ?? false
																						}
																					/>
																				),
																				divider:
																					conditionSet[obj.conditionName].length === 0,
																				lessenTextOpacity:
																					conditionSet[obj.conditionName].length === 0,
																			},
																			{
																				show: conditionSet[obj.conditionName]?.length > 0,
																				custom: (
																					<>
																						<EditorNestedOrAndArrayConditions
																							conditionName={obj.conditionName}
																							orArray={conditionSet[obj.conditionName]}
																							onAddOr={({ conditionName }) =>
																								addArrayOption(conditionName, [
																									innerConditionBase(conditionName),
																								])
																							}
																							onAddAnd={({ conditionName, orIndex }) =>
																								addArrayOption(
																									conditionName,
																									innerConditionBase(conditionName),
																									orIndex
																								)
																							}
																							onRemoveAnd={({
																								andArr,
																								orIndex,
																								andIndex,
																								conditionName,
																							}) =>
																								andArr.length === 1
																									? removeArrayOption(
																											conditionName,
																											orIndex
																									  )
																									: removeArrayOption(
																											conditionName,
																											andIndex,
																											orIndex
																									  )
																							}
																							hintText={obj.hintText}
																						>
																							{({
																								conditionObj,
																								andIndex,
																								andArr,
																								orIndex,
																							}) => {
																								const showInput =
																									['exists', 'does not exist'].indexOf(
																										conditionSet[obj.conditionName][
																											orIndex
																										][andIndex].operator
																									) === -1;
																								return (
																									<Grid
																										container
																										spacing={2}
																										alignItems="center"
																									>
																										<Grid
																											item
																											xs={4}
																										>
																											<TextField
																												fullWidth
																												placeholder={obj.placeholderKey}
																												value={
																													conditionSet[obj.conditionName][
																														orIndex
																													][andIndex].name
																												}
																												onChange={e =>
																													setNestedOption(
																														obj.conditionName,
																														e.target.value,
																														orIndex,
																														andIndex,
																														'name'
																													)
																												}
																											/>
																										</Grid>
																										<Grid
																											item
																											xs={4}
																										>
																											<TextField
																												select
																												fullWidth
																												value={
																													conditionSet[obj.conditionName][
																														orIndex
																													][andIndex].operator
																												}
																												onChange={e => {
																													setNestedOption(
																														obj.conditionName,
																														e.target.value,
																														orIndex,
																														andIndex,
																														'operator'
																													);

																													const resetValue =
																														e.target.value ===
																														'is boolean'
																															? 'true'
																															: '';
																													setNestedOption(
																														obj.conditionName,
																														resetValue,
																														orIndex,
																														andIndex,
																														'value'
																													);
																												}}
																											>
																												{operators(obj.conditionName).map(
																													operatorObj => (
																														<MenuItem
																															key={operatorObj.value}
																															value={operatorObj.value}
																														>
																															{operatorObj.label}
																														</MenuItem>
																													)
																												)}
																											</TextField>
																										</Grid>
																										{showInput && (
																											<Grid
																												item
																												xs={4}
																											>
																												<TextField
																													fullWidth
																													placeholder={
																														obj.placeholderValue
																													}
																													value={
																														conditionSet[
																															obj.conditionName
																														][orIndex][andIndex].value
																													}
																													onChange={e =>
																														setNestedOption(
																															obj.conditionName,
																															e.target.value,
																															orIndex,
																															andIndex,
																															'value'
																														)
																													}
																													select={
																														conditionSet[
																															obj.conditionName
																														][orIndex][andIndex]
																															.operator === 'is boolean'
																													}
																												>
																													{conditionSet[
																														obj.conditionName
																													][orIndex][andIndex]
																														.operator === 'is boolean' &&
																														['true', 'false'].map(
																															booleanObj => (
																																<MenuItem
																																	key={booleanObj}
																																	value={booleanObj}
																																>
																																	{booleanObj}
																																</MenuItem>
																															)
																														)}
																												</TextField>
																											</Grid>
																										)}
																									</Grid>
																								);
																							}}
																						</EditorNestedOrAndArrayConditions>
																					</>
																				),
																				divider: true,
																			},
																		];
																	}),
															  ]
													}
												/>
											</EditorOptionExpansionPanel>
										);
									})}
								</div>

								<ButtonAddList
									label={t`Add condition set`}
									className={classes.addCondition}
									onClick={() =>
										addCondition({
											ruleId: state.selectedRule,
											ruleIndex: selectedRuleIndex,
											editorType,
										})
									}
									data-onboarding="add-condition"
								/>

								{editorType !== 'sdk' && (
									<>
										<Typography variant="subtitle2">{t`Form placement options`}</Typography>
										<Card
											className={classes.thenMargin}
											data-onboarding="form-placement"
										>
											<EditorOptionList
												options={[
													{
														wrap: true,
														custom: (
															<>
																<Box width="100%">
																	<Typography
																		variant="body1"
																		display="inline"
																	>{t`Render form into`}</Typography>
																	<TextField
																		value={selectedRule.then[0].args[2]}
																		onChange={e =>
																			setRenderDiv({
																				ruleIndex: selectedRuleIndex,
																				value: e.target.value,
																			})
																		}
																		className={`${classes.inputInText} ${classes.renderInput}`}
																	/>
																</Box>
																<Typography
																	variant="caption"
																	color="textSecondary"
																	display="block"
																>
																	{t`data_collection-deployment_editor_sidebar_content-render_options-div-helper`}
																</Typography>
															</>
														),
														divider: true,
													},
													{
														show: isMultipleCssSelector(selectedRule.then[0].args[2]),
														primary: t`Append form to all of selector`,
														secondary: t`data_collection-deployment_editor_sidebar_content-render_options-selector-helper`,
														onClick: e =>
															setRenderOption({
																ruleIndex: selectedRuleIndex,
																option: 'useQuerySelectorAll',
																value: !selectedRule.then[0].useQuerySelectorAll,
															}),
														action: (
															<Switch
																checked={selectedRule.then[0].useQuerySelectorAll}
															/>
														),
														divider: true,
														lessenTextOpacity: !selectedRule.then[0].useQuerySelectorAll,
													},
													{
														primary: t`Append div to body of the page if not found`,
														secondary: t`Switch off to manage div placement yourself`,
														onClick: e =>
															setRenderOption({
																ruleIndex: selectedRuleIndex,
																option: 'disableMakeDiv',
																value: !selectedRule.then[0].disableMakeDiv,
															}),
														action: (
															<Switch checked={!selectedRule.then[0].disableMakeDiv} />
														),
														divider: true,
														lessenTextOpacity: selectedRule.then[0].disableMakeDiv,
													},
												]}
											/>
										</Card>
									</>
								)}
							</>
						) : (
							<EmptyState
								primary={t`data_collection-deployments-emptystate_sidebar-title`}
								secondary={t`data_collection-deployments-emptystate_sidebar-text`}
							/>
						)}
					</div>
				</SwipeableViews>
			</HasPermissionWithEmptyState>
		</>
	);
}
