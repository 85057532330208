import React, { useState, useEffect } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import List from '@material-ui/icons/List';

import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

function ListToggles({ currentState, onChange, ...props }) {
	function handleChange(event, value) {
		if (value !== null) {
			onChange(value, true);
		} else {
			onChange(currentState.listType, false);
		}
	}

	const { t } = useTranslation();

	return (
		<ToggleButtonGroup
			size="small"
			exclusive
			value={currentState.listType}
			onChange={handleChange}
		>
			<ToggleButton value="unordered">
				<Tooltip title={t`Unordered list`}>
					<List />
				</Tooltip>
			</ToggleButton>

			<ToggleButton value="ordered">
				<Tooltip title={t`Ordered list`}>
					<FormatListNumbered />
				</Tooltip>
			</ToggleButton>
		</ToggleButtonGroup>
	);
}

export default ListToggles;
