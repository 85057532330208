import React from 'react';

import { useAjaxForm } from '@/components/Ajax';

export default function useRefreshTextAnalyticsProject({ onSuccess = () => {} }) {
	return useAjaxForm({
		url: '/api/officedrones',
		data: {
			path: '/textanalytics/project/rebuild',
			method: 'post',
		},
		onSuccess: response => {
			onSuccess(response);
		},
	});
}
