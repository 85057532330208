import React from 'react';

import { Switch } from 'react-router-dom';

import {
	LoginPage,
	LoginMfaPage,
	ForgotPasswordPage,
	SignUpPage,
	ValidateSignUpPage,
	ResetLoginPage,
	ValidateUserPage,
} from '@/pages/login';

import { useAppContext } from '../AppContext';
import Route from './Route';

export default function LoggedOutContent() {
	const { app } = useAppContext();
	return (
		<Switch>
			<Route path="/validate-sign-up/:signUpToken?">
				<ValidateSignUpPage />
			</Route>
			<Route path="/validate-user/:signUpToken?">
				<ValidateUserPage />
			</Route>
			{app.auth.options.includes('signup') && (
				<Route path="/sign-up">
					<SignUpPage />
				</Route>
			)}
			<Route path="/forgot-password">
				<ForgotPasswordPage />
			</Route>

			<Route path="/reset-login/:token?">
				<ResetLoginPage />
			</Route>

			<Route path="/login-mfa">
				<LoginMfaPage />
			</Route>

			<Route path={['/login?reset', '/login?cancelled', '/login', '/']}>
				<LoginPage />
			</Route>
		</Switch>
	);
}
