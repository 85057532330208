import React, { useContext, createContext } from 'react';

const AlertContext = createContext({});

export default AlertContext;

export function AlertContextProvider({ value, children }) {
	return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>;
}

export function useAlertContext() {
	return useContext(AlertContext);
}
