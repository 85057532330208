import React from 'react';

import Avatar from '@material-ui/core/Avatar';

const imageMap = {
	'conversational-web': '/assets/img/surveytypes/survey-conversational-web.svg',
	'conversational-sdk': '/assets/img/surveytypes/survey-conversational-sdk.svg',
	'conversational-email': '/assets/img/surveytypes/survey-conversational-email.svg',
	'form-web': '/assets/img/surveytypes/survey-standard-web.svg',
	'form-sdk': '/assets/img/surveytypes/survey-standard-sdk.svg',
	'form-email': '/assets/img/surveytypes/survey-standard-email.svg',
	default: '/assets/img/surveytypes/survey-standard.svg',
};

function getImage(campaign, format) {
	const path = `${format}-${campaign}`;
	return imageMap[path] ? imageMap[path] : imageMap.default;
}

function DataSourceAvatar({
	survey_type,
	survey_format,
	campaign = 'web',
	allData,
	...props
}) {
	const typeImage =
		survey_type === 'builder'
			? getImage(campaign, survey_format)
			: survey_type === 'import'
			? '/assets/img/surveytypes/dataset-bg.svg'
			: allData
			? '/assets/img/r/all-data.svg'
			: '';

	return (
		<Avatar
			src={typeImage}
			{...props}
		/>
	);
}

export default DataSourceAvatar;
