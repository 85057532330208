import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ButtonBottomRightIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<path
				id="XMLID_4_"
				d="M0,39h4v1H0V39z M1,32H0v4h1V32z M1,24H0v4h1V24z M8,0H4v1h4V0z M8,40h4v-1H8V40z M1,8H0v4h1V8z M1,0H0v4h1V0
		z M1,16H0v4h1V16z M32,0h-4v1h4V0z M39,32h1v-4h-1V32z M39,24h1v-4h-1V24z M39,8h1V4h-1V8z M16,0h-4v1h4V0z M36,0v1h4V0H36z M39,16
		h1v-4h-1V16z M16,40h4v-1h-4V40z M24,0h-4v1h4V0z M39,40h1v-4h-1V40z M36,34H24c-0.5,0-1,0.5-1,1v5h1h4h4h4h1v-5
		C37,34.5,36.5,34,36,34z"
			/>
		</SvgIcon>
	);
}
