import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';

import { Trans, useTranslation } from 'react-i18next';

import { useAppContext } from '../AppContext';
import { Typography, Grid, ActionButton, Link, ContactDialog, Alert } from '../Layout';
import { Explainer, ExplainerDrawer } from '../Explainer';
import BillingCardBase from './BillingCardBase';
import { products, nudgeEnterprisePoints, forceEnterprisePoints } from './constants';
import {
	calculatePriceTotal,
	isPackageIncrease,
	isIntervalIncrease,
} from './billingUtils';
import PriceTierPicker from './PriceTierPicker';
import Amount from './Amount';
import BillingToggle from './BillingToggle';
import ContactEnterpriseSnackbar from './ContactEnterpriseSnackbar';
import ContactEnterpriseDialog from './ContactEnterpriseDialog';
import {
	useHasPermission,
	permissionModules,
	permissionSubjects,
	permissionTypes,
} from '../Permission';

const useStyles = makeStyles(theme => ({
	card: {
		width: '100%',
	},
	title: {
		marginBottom: theme.spacing(5),
	},
	subtitle: {
		marginBottom: theme.spacing(5),
	},
	learnMore: {
		marginLeft: theme.spacing(1),
	},
	calculatorBlock: {
		marginBottom: theme.spacing(3),
	},
	priceBlock: {
		marginTop: theme.spacing(3),
	},
	switchLabelRoot: {
		marginLeft: 0,
		marginTop: theme.spacing(1),
	},
	switchLabel: {
		...theme.typography.body2,
	},
	errorMessage: {
		marginTop: theme.spacing(1),
	},
	total: {
		minWidth: 160,
	},
}));

function getInitialState(plan = {}, _package = {}) {
	return (
		plan?.possible_tiers?.reduce((acc, tier) => {
			return {
				...acc,
				[tier]: _package[tier] ?? 0,
			};
		}, {}) ?? {}
	);
}

export default function CalculatorCard({
	plan = {},
	vat = 0,
	billing = '',
	loading,
	selectedPackage = {},
	updateBilling = () => {},
	onValidSelect = () => {},
}) {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const classes = useStyles();
	const hasEditPermission = useHasPermission({
		module: permissionModules.billing,
		subject: permissionSubjects.billing_management,
		permission: permissionTypes.edit,
	});

	const [state, setState] = useState(getInitialState(plan, app.package));
	const [showUpgradeError, setShowUpgradeError] = useState(false);
	const [showExplainer, setShowExplainer] = useState(false);
	const [showContact, setShowContact] = useState(false);
	const [showEnterpriseTrigger, setShowEnterpriseTrigger] = useState(false);
	const [showEnterpriseDialog, setShowEnterpriseDialog] = useState(false);
	const [showEnterpriseDialogFromExplainer, setShowEnterpriseDialogFromExplainer] =
		useState(false);
	const [contactOwner, setContactOwner] = useState(false);
	const [disableUpgradeButton, setDisableUpgradeButton] = useState(false);

	useEffect(() => {
		setState(getInitialState(plan, app.package));
	}, [plan, app.package]);

	//set 'initial' state when editting selection again returning from the 2nd step
	useEffect(() => {
		setState(prevState => ({
			...prevState,
			...selectedPackage,
		}));
	}, [selectedPackage]);

	function setValue(key, value) {
		setShowUpgradeError(false);

		const product = key.split('_')[0];

		setState(prevState => {
			const otherProductProp = Object.keys(prevState).find(prevKey => {
				return prevKey.includes(product) && prevKey !== key;
			});

			//handle sibling product prop -> other products from the same parent product (eg web)
			//should always be at least on the first tier if the sibling product is
			//when decrementing to 0 the sibling product should be set to 0 as well
			const setOtherProductProp =
				prevState[otherProductProp] === 0
					? plan[otherProductProp]?.tiers?.[0]
					: value === 0 //user is decrementing one of the values to 0
					? 0
					: undefined;

			return {
				...prevState,
				...(setOtherProductProp !== undefined && {
					[otherProductProp]: setOtherProductProp,
				}),
				[key]: value,
			};
		});
	}

	function resetState() {
		setShowUpgradeError(false);
		setState(getInitialState(plan, app.package));
	}

	const totalPriceMonthly = calculatePriceTotal({ state, billing: 'monthly', plan });
	const totalPriceYearly = calculatePriceTotal({ state, billing: 'annual', plan });
	const saveWithYearly = totalPriceMonthly * 12 - totalPriceYearly;

	//nudge enterprise
	useEffect(() => {
		const compareAmount = billing === 'monthly' ? totalPriceMonthly : totalPriceYearly;

		const triggerEnterprise =
			compareAmount >= nudgeEnterprisePoints[billing] ||
			Object.keys(nudgeEnterprisePoints).some(
				nudgePoint => state[nudgePoint] >= nudgeEnterprisePoints[nudgePoint]
			);

		setShowEnterpriseTrigger(triggerEnterprise);
	}, [totalPriceMonthly, totalPriceYearly, billing, state]);

	//force enterprise
	useEffect(() => {
		const compareAmount = billing === 'monthly' ? totalPriceMonthly : totalPriceYearly;
		const forceEnterpriseFromPrice = compareAmount >= forceEnterprisePoints[billing];

		// if (!showEnterpriseDialog) {
		//   setShowEnterpriseDialog(forceEnterpriseFromPrice);
		// }
		setDisableUpgradeButton(forceEnterpriseFromPrice);
	}, [totalPriceMonthly, totalPriceYearly, billing]);

	return (
		<>
			<ContactEnterpriseSnackbar show={showEnterpriseTrigger} />

			<ContactEnterpriseDialog
				open={showEnterpriseDialog}
				onClose={() => {
					setShowEnterpriseDialog(false);
				}}
			/>

			{!hasEditPermission && (
				<Box mb={2}>
					<Alert
						variant="outlined"
						severity="info"
						title={t`calculator_card-billing_not_allowed_alert-title`}
						action={
							<ActionButton
								color="inherit"
								size="small"
								onClick={() => setContactOwner(true)}
							>
								{t`calculator_card-billing_not_allowed_alert-action`}
							</ActionButton>
						}
					>
						{t`calculator_card-billing_not_allowed_alert-content`}
					</Alert>
				</Box>
			)}

			<BillingCardBase
				title={
					<>
						<Box>{t`calculator_card-title`}</Box>
						{!app.package.trial && (
							<Box mt={1}>
								<Typography variant="body2">
									<Trans
										i18nKey="calculator_card-enterprise_explainer-label"
										components={{
											explainerLink: (
												<Explainer
													variant="inherit"
													title={t`calculator_card-enterprise_explainer-title`}
													content={
														<Trans
															i18nKey="calculator_card-enterprise_explainer-content"
															components={{
																p1: <Typography mb={2} />,
																ul: <ul />,
																liSso: <li />,
																liCsm: <li />,
																liOnboard: <li />,
																liDomain: <li />,
																liSla: <li />,
																liWhiteLabel: <li />,
																p2: <Typography mb={1} />,
																contactLink: (
																	<Link
																		color="inherit"
																		onClick={() =>
																			setShowEnterpriseDialogFromExplainer(true)
																		}
																	/>
																),
															}}
														/>
													}
												/>
											),
										}}
									/>
								</Typography>
							</Box>
						)}
					</>
				}
			>
				{products.map(product => {
					return (
						<div
							className={classes.calculatorBlock}
							key={product}
						>
							<Typography
								variant="subtitle"
								className={classes.blockTitle}
							>
								{t(`calculator_card-${product}_title`)}
							</Typography>
							{loading &&
								[1, 2].map(loadingKey => (
									<CalculatorRow
										text={<Skeleton width="50%" />}
										key={loadingKey}
									>
										<Skeleton width={160} />
									</CalculatorRow>
								))}

							{!loading &&
								plan.possible_tiers
									?.filter(tierKey => tierKey.includes(product))
									.map(tier => {
										const options = plan[tier]?.tiers;

										return (
											<CalculatorRow
												text={t(`calculator_card-${tier}`)}
												tooltip={t(`calculator_card-${tier}_tooltip`)}
												key={tier}
											>
												<PriceTierPicker
													options={options}
													selected={state[tier]}
													onChange={value => setValue(tier, value)}
													min={app.package[tier] ?? 0}
													onMinMoreThan0Reached={() => setShowExplainer(true)}
													onMaxReached={() => setShowEnterpriseDialog(true)}
												/>
											</CalculatorRow>
										);
									})}
						</div>
					);
				})}

				<Divider className={classes.calculatorBlock} />

				<div className={classes.calculatorBlock}>
					<Grid
						container
						alignItems="center"
					>
						<Grid
							item
							xs
						>
							<BillingToggle
								billing={billing}
								updateBilling={updateBilling}
								saveWithYearly={saveWithYearly}
								currency={plan.currency}
								loading={loading}
								disabled={app.package.payment_interval === 'annual'}
							/>
						</Grid>
						<Grid item>
							<Typography
								align="center"
								variant="h5"
								display="inline-block"
								className={classes.total}
							>
								{loading && <Skeleton />}
								{!loading && (
									<Amount
										amount={billing === 'monthly' ? totalPriceMonthly : totalPriceYearly}
										currency={plan.currency}
									/>
								)}
							</Typography>
							{Number(vat) !== 0 && (
								<Typography
									variant="body2"
									align="center"
								>
									{t(`calculator_card-vat_label`, { vat })}
								</Typography>
							)}
						</Grid>
					</Grid>
				</div>

				<div>
					<Grid
						container
						alignItems="center"
					>
						<Grid
							item
							xs={8}
						>
							<ActionButton
								color="primary"
								onClick={resetState}
							>
								{t`calculator_card-reset_button`}
							</ActionButton>
						</Grid>
						<Grid
							item
							xs={4}
						>
							<Box textAlign="right">
								<ActionButton
									variant="contained"
									color="primary"
									onClick={() => {
										if (
											isPackageIncrease(state, getInitialState(plan, app.package)) ||
											isIntervalIncrease(app.package.payment_interval, billing)
										) {
											onValidSelect(state);
											return;
										}

										setShowUpgradeError(true);
									}}
									disabled={disableUpgradeButton || !hasEditPermission}
								>
									{disableUpgradeButton
										? t`calculator_card-upgrade_button_enterprise_only`
										: t`calculator_card-upgrade_button`}
								</ActionButton>

								{disableUpgradeButton && (
									<Typography
										variant="caption"
										display="block"
										mt={1}
									>
										<Trans
											i18nKey="calculator_card-upgrade_disabled_enterprise_caption"
											components={{
												contactLink: (
													<Link
														onClick={() => setShowEnterpriseDialogFromExplainer(true)}
													/>
												),
											}}
										/>
									</Typography>
								)}
							</Box>
						</Grid>
					</Grid>
					{showUpgradeError && (
						<Typography
							variant="body2"
							className={classes.errorMessage}
							align="right"
							error
						>
							{t`calculator_card-upgrade_error_message`}
						</Typography>
					)}
				</div>

				<ExplainerDrawer
					open={showExplainer}
					onClose={() => setShowExplainer(false)}
					title={t`calculator_card-downgrade_explainer_title`}
					content={
						<Trans
							i18nKey={t`calculator_card-downgrade_explainer_content`}
							components={{
								getInTouchLink: (
									<Link
										color="inherit"
										fontWeight="medium"
										onClick={() => {
											setShowContact(true);
											setShowExplainer(false);
										}}
									/>
								),
							}}
						/>
					}
				/>

				<ContactDialog
					open={showEnterpriseDialogFromExplainer}
					onClose={() => setShowEnterpriseDialogFromExplainer(false)}
					mailTo="sales@mopinion.com"
					title={t`calculator_card-contact_enterprise-title`}
					subject={t`calculator_card-contact_enterprise-subject`}
				/>

				<ContactDialog
					open={showContact}
					onClose={() => setShowContact(false)}
					mailTo="success@mopinion.com"
					cc={['support@mopinion.com']}
					title={t`calculator_card-contact_decrease-title`}
					subject={t`calculator_card-contact_decrease-subject`}
				/>

				<ContactDialog
					contactOwner
					open={contactOwner}
					onClose={() => setContactOwner(false)}
					title={t`calculator_card-contact_owner-title`}
					text={t`calculator_card-contact_owner-text`}
					successMessage={t`calculator_card-contact_owner-success_message`}
				/>
			</BillingCardBase>
		</>
	);
}

function CalculatorRow({ children, text = '', tooltip = '' }) {
	return (
		<div>
			<Grid
				container
				alignItems="center"
				spacing={2}
			>
				<Grid
					item
					xs
				>
					<Typography
						display="inline"
						tooltip={tooltip}
					>
						{text}
					</Typography>
				</Grid>
				<Grid item>{children}</Grid>
			</Grid>
		</div>
	);
}
