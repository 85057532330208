import WebIcon from '@material-ui/icons/Web';
import AppIcon from '@material-ui/icons/Smartphone';
import EmailIcon from '@material-ui/icons/Email';
import AnalyzeIcon from '@material-ui/icons/InsertChart';

export const onboardingOptions = [
	{
		img: '/assets/img/r/onboarding/campaign-web.svg',
		title: 'Collect feedback on my (mobile) website',
		subtitle:
			'Find out how your customers are experiencing your website, use intelligent targetting to show different forms based on website data and customer behavior, easily implemented with a single Javascript tag',
		action: 'web',
		path: '/data-collection/survey/edit/new/web',
		onboardingStep: 'tour_create_form',
		dataTrackEvent: 'onboarding_productchoice_website',
		icon: WebIcon,
	},
	{
		img: '/assets/img/r/onboarding/campaign-sdk.svg',
		title: 'Collect feedback on my mobile app',
		subtitle:
			'Collect powerful feedback from your native apps with our flexible and easy-to-install SDKs for iOS and Android',
		action: 'sdk',
		path: '/data-collection/survey/edit/new/sdk',
		onboardingStep: 'tour_create_form',
		dataTrackEvent: 'onboarding_productchoice_mobile',
		icon: AppIcon,
	},
	{
		img: '/assets/img/r/onboarding/campaign-email.svg',
		title: 'Collect feedback on my email campaigns',
		subtitle:
			'Easily place feedback widgets in any of your emails and give your audience a voice, learn what works and what doesn’t',
		action: 'email',
		path: '/data-collection/survey/edit/new/email',
		onboardingStep: 'tour_create_form',
		dataTrackEvent: 'onboarding_productchoice_email',
		icon: EmailIcon,
	},
	// {
	// 	title:'Create a standalone survey',
	// 	action:'standalone',
	// 	path:'/data-collection/survey/edit/new/email',
	// 	onboardingStep:'tour_create_form',
	// 	icon:SurveyIcon
	// },
	{
		img: '/assets/img/r/onboarding/campaign-upload.svg',
		title: 'Analyse existing feedback data',
		subtitle:
			'Bring your feedback data into Mopinion and have the best data visualisation and action management tools at your fingertips',
		action: 'import',
		path: '/data-collection/dataset/import/new',
		onboardingStep: 'tour_upload_data',
		dataTrackEvent: 'onboarding_productchoice_analyse',
		icon: AnalyzeIcon,
	},
];

export const channelCardOptions = [
	{
		title: 'onboarding-getting_started-step_channel-button-web',
		type: 'web',
		image: '/assets/img/r/onboarding/onboarding_flow_web.svg',
	},
	{
		title: 'onboarding-getting_started-step_channel-button-sdk',
		type: 'sdk',
		image: '/assets/img/r/onboarding/onboarding_flow_app.svg',
	},
	{
		title: 'onboarding-getting_started-step_channel-button-email',
		type: 'email',
		image: '/assets/img/r/onboarding/onboarding_flow_email.svg',
	},
];

export const responseErrors = {
	Forbidden: {
		title: 'errormessage_unknown_error',
		message: 'errormessage_unknown_error_message',
		severity: 'error',
	},
};
