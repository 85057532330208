import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useVideoTracking } from '../Tracking';

const useStyles = makeStyles(theme => ({
	videoContainer: {
		marginTop: theme.spacing(5),
		position: 'relative',
		paddingBottom: '56.25%' /* 16:9 */,
		height: 0,
		width: '100%',
		borderRadius: props =>
			props.inTooltip
				? `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`
				: theme.shape.borderRadius,
		boxShadow: props => (props.inTooltip ? null : theme.shadows[12]),
		overflow: 'hidden',
		'& > iframe': {
			position: 'absolute',
			top: '0',
			left: '0',
			width: '100%',
			height: '100%',
		},
	},
}));

export default function OnboardingVideo({ className = '', start, ...props }) {
	const classes = useStyles(props);
	useVideoTracking();

	return (
		<Fragment>
			<div
				className={`${classes.videoContainer} ${className}`}
				{...props}
			>
				<iframe
					id="onboarding-video-iframe"
					width="560"
					height="315"
					src={`https://www.youtube.com/embed/lPA5j0rWgW8?enablejsapi=1${
						start ? `&autoplay=1&start=${start}` : ''
					}`}
					title="Mopinion Onboarding Video"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				/>
			</div>
		</Fragment>
	);
}
