import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function RadioIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<path
				fill="currentColor"
				id="XMLID_1_"
				d="M9,2C6.2,2,4,4.2,4,7s2.2,5,5,5s5-2.2,5-5S11.8,2,9,2z M9,11c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4
				S11.2,11,9,11z M9,17.5c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5S10.4,17.5,9,17.5z M9,15c-2.8,0-5,2.2-5,5
				s2.2,5,5,5s5-2.2,5-5S11.8,15,9,15z M9,24c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S11.2,24,9,24z M9,28c-2.8,0-5,2.2-5,5s2.2,5,5,5
				s5-2.2,5-5S11.8,28,9,28z M9,37c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S11.2,37,9,37z M17,4.4h20v1.7H17V4.4z M17,7.9h12.5v1.7H17
				V7.9z M17,17.3h20V19H17V17.3z M17,20.7h12.5v1.7H17V20.7z M17,30.4h20v1.7H17V30.4z M17,33.9h12.5v1.7H17V33.9z"
			/>
		</SvgIcon>
	);
}
