import React, { Component, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import { useListFilterContext, FilterGroup } from '../FilteredLists';
import { useTranslation } from 'react-i18next';

function CustomerListFilters({ ...props }) {
	const { setSingleFilter, filters, other } = useListFilterContext();

	const { t } = useTranslation();

	return (
		<Fragment>
			<div>
				<TextField
					variant="filled"
					fullWidth
					placeholder={t('Search')}
					label={t('Search')}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search />
							</InputAdornment>
						),
					}}
					onChange={e => {
						setSingleFilter('search', e.target.value);
					}}
					value={filters.search ?? ''}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Feedback')}
					filterKey={'survey_count'}
					filters={[
						{ label: t('With feedback'), value: '>' },
						{ label: t('Without feedback'), value: '=' },
					].map(filter => {
						if (filter.value === filters.survey_count) {
							filter.selected = true;
						}
						filter.key = 'survey_count' + filter.value;
						return filter;
					})}
					onChange={({ key, value }) => {
						setSingleFilter(key, value);
					}}
				/>
			</div>
		</Fragment>
	);
}

export default CustomerListFilters;
