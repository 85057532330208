import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

const useStyles = makeStyles(theme => ({
	smallButton: {
		height: 30,
	},
}));

export default function EditorListToggles({
	value,
	size = 'small',
	exclusive = true,
	onChange = () => {},
	options = [],
	disabled,
	...props
}) {
	const classes = useStyles();

	return (
		<ToggleButtonGroup
			size={size}
			value={value}
			exclusive={exclusive}
			onChange={onChange}
			{...props}
		>
			{options.map(option => (
				<ToggleButton
					disabled={disabled}
					className={size === 'small' ? classes.smallButton : ''}
					value={option.value}
					key={option.value}
				>
					{option.icon}
				</ToggleButton>
			))}
		</ToggleButtonGroup>
	);
}
