import React from 'react';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { Chip } from '@/components/Layout';
import NoPermissionTooltip from './NoPermissionTooltip';
import { permissionTypes } from './constants';

const useStyles = makeStyles(theme => ({
	chip: {
		marginLeft: theme.spacing(2),
	},
}));

export default function NoPermissionChip({
	module,
	subject,
	permission = permissionTypes.edit,
}) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<NoPermissionTooltip
			module={module}
			subject={subject}
			permission={permission}
		>
			<Chip
				icon={<InfoOutlinedIcon />}
				size="small"
				label={
					permission === permissionTypes.view
						? t`app_general-no_permission_chip-label_no_view`
						: t`app_general-no_permission_chip-label_no_edit`
				}
				className={classes.chip}
			/>
		</NoPermissionTooltip>
	);
}
