import React from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { Chip, Tooltip } from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	trialChip: {
		position: 'absolute',
		top: props => theme.spacing(props.offsetTop),
		left: props => (props.position === 'left' ? theme.spacing(props.offsetLeft) : 'auto'),
		right: props =>
			props.position === 'right' ? theme.spacing(props.offsetRight) : 'auto',
		zIndex: '1000',
	},
	trialChipInline: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
}));

export default function ProductTrialChip({
	daysLeftInProductTrial = 0,
	inline,
	position = 'left',
	offsetTop = 1.5,
	offsetLeft = 1.5,
	offsetRight = 1.5,
}) {
	const classes = useStyles({ position, offsetTop, offsetLeft, offsetRight });
	const { t } = useTranslation();

	return (
		<Tooltip
			title={t(
				'data_collection-feedback_forms-editor-channel_trial-chip_trial_active-tooltip'
			)}
		>
			<Chip
				label={t(
					'data_collection-feedback_forms-editor-channel_trial-chip_trial_active',
					{
						count: daysLeftInProductTrial,
					}
				)}
				color="primary"
				size="small"
				className={!inline ? classes.trialChip : classes.trialChipInline}
			/>
		</Tooltip>
	);
}
