import React, { useContext, useState, useEffect, Fragment } from 'react';
import AutoComplete from './AutoComplete';
import { useAppContext } from '../AppContext';
import { TagContext } from '../Tags';
import { UpgradeHelper } from '../Layout';

import { useTranslation } from 'react-i18next';

function tagValid(value) {
	return (
		!value ||
		/^[a-zA-Z0-9\s.:!@#$%^&*\-+=()|?;_\p{L}\p{M}\u{1F300}-\u{1F64F}]+$/u.test(value)
	);
}

function TagsAutoComplete({
	onChange,
	onBlur,
	selectedItem = [],
	multiple = true,
	skipMax,
	...props
}) {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const { tags } = useContext(TagContext);
	const [valid, setValid] = useState(true);

	const [isBlurred, setIsBlurred] = useState(false);

	const existingAndNewTags = [
		...new Set([
			...tags.uniqueTags,
			...selectedItem.map(e => {
				return e.label;
			}),
		]),
	];

	const options = tags.tagList.map(tag => {
		return {
			label: tag,
		};
	});

	const maxReached = existingAndNewTags.length > app.package.tags;
	const selectTagHelper = isBlurred && !Object.keys(selectedItem).length;

	const AutoCompleteProps = {
		...props,
		options,
		isValidTag: item => tagValid(item),
		onInputChange: (value, valid) => setValid(valid),
		onBlur: () => setIsBlurred(true),
		onChange: newTags => {
			if (skipMax) onChange(newTags);

			const lastAdded = newTags.length > 0 && newTags[newTags.length - 1].label;

			if (
				maxReached &&
				existingAndNewTags.indexOf(lastAdded) === -1 &&
				newTags.length >= selectedItem.length
			) {
				onChange(selectedItem);
			} else {
				onChange(newTags);
			}
		},
		isTags: true,
		multiple,
		helperText: (() => {
			if (!valid) {
				return t`You can only use the following special characters inside your tags .:!@#$%^&*-+=()|?;_`;
			}

			if (maxReached) {
				return (
					<UpgradeHelper
						text={t`You reached the max amount of unique tagnames within your package.`}
					/>
				);
			}

			if (selectTagHelper) {
				return t`Select your tag name in the dropdown to confirm your selection.`;
			}

			return props.helperText;
		})(),
		...(selectedItem.length > 0 && { selectedItem }),
		error: !valid,
	};

	return (
		<Fragment>
			<AutoComplete {...AutoCompleteProps} />
		</Fragment>
	);
}

export default TagsAutoComplete;
