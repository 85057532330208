import { axiosInstance } from '@/lib/axios';
import objectToFormData from 'object-to-formdata';

export const OfficeDrones = {
	pending: {},
	handlePending(path, data) {
		if (this.pending[path]) {
			return false;
		} else {
			this.pending[path] = {
				path: path,
				cancel: () => {},
				count: 0,
			};
			return true;
		}
	},
	cancelPending(path) {
		try {
			// this.pending[path].cancel();
			delete this.pending[path];
		} catch (e) {}
	},
	init(csrf) {
		this.token = csrf;
	},
	post(p, postData = {}, config = {}) {
		return axiosInstance
			.post(
				'/api/officedrones',
				objectToFormData(
					Object.assign(
						{},
						{ data: postData },
						{ path: p, token: this.token },
						{ method: 'post' }
					)
				),
				config
			)
			.then(r => r.data)
			.catch(e => e);
	},
};

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
	// dev code
	window.___OD = OfficeDrones;
}
