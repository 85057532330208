import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';

import { useTranslation } from 'react-i18next';

import { ButtonAddList } from '../Layout';
import { useTagContext } from '../Tags';
import {
	FilterGroup,
	CustomFilter,
	FilterDrawer,
	CustomFilterSidebarChip,
} from '../FilteredLists';
import { varTypesUsingContains } from '@/components/FilteredLists/constants';

export default function FeedbackExportFilters({
	exportConfig,
	setSingleFilter = () => {},
	setMultiFilter = () => {},
	setCustomFilter = () => {},
	setExportConfig = () => {},
	createExportFilter = () => {},
	customViews,
}) {
	const { t } = useTranslation();
	const { tags } = useTagContext();
	const [showCustom, setShowCustom] = useState(false);

	const { other, customFilters, customView } = exportConfig;

	const mappedTags = tags.tagList.map(tag => {
		return {
			value: tag,
			label: tag,
		};
	});

	function addFilter() {
		const lastInArr = customFilters[customFilters.length - 1];
		if (!lastInArr || (lastInArr.identifier && lastInArr.value.length > 0)) {
			setCustomFilter({
				action: 'add',
				custom: {
					type: 'datafield',
					identifier: '',
					value: [],
				},
			});
		}
		setShowCustom(true);
	}

	return (
		<Grid
			container
			spacing={3}
		>
			<Grid
				item
				xs={12}
			>
				<Typography
					variant="body1"
					color="textSecondary"
					gutterBottom
				>{t`Feedback order`}</Typography>
				<Select
					labelId="export-filter"
					value={exportConfig.sort.replace('lastname', 'name') + '-' + exportConfig.order}
					onChange={e => {
						const sort = e.target.value.split('-')[0];
						const order = e.target.value.split('-')[1];
						setSingleFilter('sort', sort);
						setSingleFilter('order', order);
					}}
				>
					<MenuItem value={'feedback_created-desc'}>{t('Date: new to old')}</MenuItem>
					<MenuItem value={'feedback_created-asc'}>{t('Date: old to new')}</MenuItem>
					<MenuItem value={'customer_name-desc'}>{t('Customer name: A to Z')}</MenuItem>
					<MenuItem value={'customer_name-asc'}>{t('Customer name: Z to A')}</MenuItem>
				</Select>
			</Grid>

			<Grid
				item
				xs={12}
			>
				<Grid
					container
					direction="column"
					wrap="wrap"
				>
					<Grid item>
						<Typography
							variant="body1"
							color="textSecondary"
						>
							{t('Custom filters')}
						</Typography>
					</Grid>
					<Grid
						item
						container
						wrap="wrap"
					>
						{customFilters &&
							customFilters
								.filter(
									filter => filter.identifier && filter.value && filter.value.length > 0
								)
								.map((filter, index) => (
									<CustomFilterSidebarChip
										onClick={() => setShowCustom(true)}
										onDelete={() => setCustomFilter({ action: 'remove', index })}
										filter={filter}
										index={index}
										key={'custom-' + index}
										tooltip={t`Click to edit`}
									/>
								))}
					</Grid>
					<Grid item>
						<ButtonAddList
							onClick={addFilter}
							label={t`Add filter`}
						/>
					</Grid>
				</Grid>
				<FilterDrawer
					open={showCustom}
					title={t`Custom filters`}
					onClose={() => setShowCustom(false)}
				>
					<Typography
						variant="subtitle1"
						color="textSecondary"
					>
						{t('Select datafields and values to filter on')}
					</Typography>
					<Box mb={2}>
						{customFilters &&
							customFilters.map((filter, index) => (
								<CustomFilter
									forceUseSurvey={other.survey_id ? true : false}
									{...filter}
									index={index}
									surveyId={other.survey_id || filter.surveyId || null}
									key={filter.identifier ? filter.identifier : index}
									onDelete={() => setCustomFilter({ action: 'remove', index })}
									filtersFromProps={customFilters}
									DataFieldAutoCompleteProps={{
										onChange: e => {
											setCustomFilter({
												action: 'update',
												index: index,
												custom: {
													identifier: e.value,
													value: [],
													surveyId: e.surveyId,
													operator:
														varTypesUsingContains.indexOf(String(e.var_type)) > -1
															? 'contains'
															: 'e',
												},
											});
										},
									}}
									PossibleValueProps={{
										onChange: e =>
											setCustomFilter({
												action: 'update',
												index: index,
												custom: { value: e },
											}),
									}}
								/>
							))}

						<Grid container>
							<Grid
								item
								xs
							>
								<ButtonAddList
									onClick={addFilter}
									label={t`Add filter`}
								/>
							</Grid>
							<Grid item>
								<Button
									color="secondary"
									size="small"
									onClick={() => setShowCustom(false)}
									startIcon={<SaveIcon />}
								>
									{t`Set filters`}
								</Button>
							</Grid>
						</Grid>
					</Box>
				</FilterDrawer>
			</Grid>

			<Grid
				item
				xs={12}
			>
				<FilterGroup
					filterName={t('Custom views')}
					customFilterName={
						<Typography
							variant="body1"
							color="textSecondary"
						>
							{t('Custom views')}
						</Typography>
					}
					filters={(Array.isArray(customViews) ? customViews : []).map(view => {
						view.selected = customView && view.id === customView;
						return view;
					})}
					onChange={({ key, value, selected }) => {
						if (value) {
							setCustomFilter({
								action: selected ? 'set' : 'reset',
								custom: value.rules,
								view: value.id,
							});
						}
					}}
					custom
					viewOnly
					expandSelected
					openFilterDrawer={() => setShowCustom(true)}
				>
					{customViews.length === 0 ? (
						<Typography
							variant="caption"
							color="textSecondary"
						>{t`No custom views available.`}</Typography>
					) : null}
				</FilterGroup>
			</Grid>

			<Grid
				item
				xs={12}
			>
				<FilterGroup
					customFilterName={
						<Typography
							variant="body1"
							color="textSecondary"
						>
							{t('Tags')}
						</Typography>
					}
					filterKey={'tags'}
					filters={[
						{ label: t('No tags'), value: 'withouttags' },
						{ label: t('All tags'), value: 'all_tags' },
						...mappedTags,
					].map(filter => {
						if ((exportConfig.filters.tags || []).includes(filter.value)) {
							filter.selected = true;
						}
						return filter;
					})}
					multiple
					expandSelected
					autoComplete={mappedTags}
					favorites={tags.tagCount ?? {}}
					onChange={({ key, value }) => {
						if (value.includes('all_tags')) {
							value = mappedTags.map(tag => tag.value);
						} else if (value.includes('withouttags')) {
							value = ['withouttags'];
						}

						setMultiFilter(key, value, 'filters');
					}}
				/>
			</Grid>

			<Grid
				item
				xs={12}
			>
				<FilterGroup
					customFilterName={
						<Typography
							variant="body1"
							color="textSecondary"
						>
							{t('Open comment')}
						</Typography>
					}
					filterKey={'comment'}
					filters={[
						{ label: t('With comment'), value: '>', attr: 'feedbackWithComment' },
						{ label: t('Without comment'), value: '=', attr: 'feedbackWithoutComment' },
					].map(filter => {
						if (filter.value === exportConfig.filters.comment) {
							filter.selected = true;
						}
						return filter;
					})}
					expandSelected
					onChange={({ key, value }) => setSingleFilter(key, value, 'filters')}
				/>
			</Grid>

			<Grid
				item
				xs={12}
			>
				<FilterGroup
					customFilterName={
						<Typography
							variant="body1"
							color="textSecondary"
						>
							{t('Device')}
						</Typography>
					}
					filterKey={'devices'}
					filters={[
						{ label: 'Desktop', value: 'Desktop', attr: 'inbox_filter_device_desktop' },
						{ label: 'Tablet', value: 'Tablet', attr: 'inbox_filter_device_tablet' },
						{ label: 'Mobile', value: 'Mobile', attr: 'inbox_filter_device_mobile' },
					].map(filter => {
						if ((exportConfig.filters.devices || []).indexOf(filter.value) > -1) {
							filter.selected = true;
						}
						return filter;
					})}
					expandSelected
					multiple
					onChange={({ key, value }) => {
						setMultiFilter(key, value, 'filters');
					}}
				/>
			</Grid>

			<Grid
				item
				xs={12}
			>
				<FilterGroup
					customFilterName={
						<Typography
							variant="body1"
							color="textSecondary"
						>
							{t('Browser')}
						</Typography>
					}
					filterKey={'browsers'}
					filters={[
						{ label: 'Chrome', value: 'Chrome' },
						{ label: 'Safari', value: 'Safari' },
						{ label: 'Firefox', value: 'Firefox' },
						{ label: 'Edge', value: 'Edge' },
						{ label: 'Internet Explorer', value: 'Explorer' },
						{ label: 'Opera', value: 'Opera' },
						{ label: 'Other', value: 'Other' },
					].map(filter => {
						if ((exportConfig.filters.browsers || []).includes(filter.value)) {
							filter.selected = true;
						}
						return filter;
					})}
					expandSelected
					multiple
					onChange={({ key, value }) => setMultiFilter(key, value, 'filters')}
				/>
			</Grid>

			<Grid
				item
				xs={12}
			>
				<FilterGroup
					customFilterName={
						<Typography
							variant="body1"
							color="textSecondary"
						>
							{t('Operating system')}
						</Typography>
					}
					filterKey={'os'}
					filters={[
						{ label: 'Windows', value: 'Windows' },
						{ label: 'macOS', value: 'Mac OS' },
						{ label: 'iOS', value: 'iOS' },
						{ label: 'Android', value: 'Android' },
						{ label: 'Chrome OS', value: 'Chrome OS' },
						{ label: 'Linux', value: 'Linux' },
						{ label: 'Other', value: 'Other' },
					].map(filter => {
						if ((exportConfig.filters.os || []).includes(filter.value)) {
							filter.selected = true;
						}
						return filter;
					})}
					expandSelected
					multiple
					onChange={({ key, value }) => setMultiFilter(key, value, 'filters')}
				/>
			</Grid>

			<Grid
				item
				xs={12}
			>
				<FilterGroup
					customFilterName={
						<Typography
							variant="body1"
							color="textSecondary"
						>
							{t('Sent email replies')}
						</Typography>
					}
					filterKey={'email'}
					filters={[
						{ label: t('With sent email replies'), value: '>' },
						{ label: t('Without sent email replies'), value: '=' },
					].map(filter => {
						if (filter.value === exportConfig.filters.email) {
							filter.selected = true;
						}
						return filter;
					})}
					expandSelected
					onChange={({ key, value }) => setSingleFilter(key, value, 'filters')}
				/>
			</Grid>

			<Grid
				item
				xs={12}
			>
				<FilterGroup
					customFilterName={
						<Typography
							variant="body1"
							color="textSecondary"
						>
							{t('Assigned actions')}
						</Typography>
					}
					filterKey={'actions'}
					filters={[
						{ label: t('With assigned actions'), value: '>' },
						{ label: t('Without assigned actions'), value: '=' },
					].map(filter => {
						if (exportConfig.filters.actions === filter.value) {
							filter.selected = true;
						}
						return filter;
					})}
					expandSelected
					onChange={({ key, value }) => setSingleFilter(key, value, 'filters')}
				/>
			</Grid>
		</Grid>
	);
}
