import React, { PureComponent, Fragment } from 'react';
import { debounce } from '../../utils';
import { withAppContext } from '../AppContext';
class Persist extends PureComponent {
	static defaultProps = {
		debounce: 500,
		name: '',
		onMount: () => {},
		data: {},
	};

	state = {
		isMounted: false,
	};

	componentDidMount() {
		const { app } = this.props;
		const name = app.projects.current.id + document.location.pathname;

		try {
			const data = JSON.parse(sessionStorage.getItem(this.getName()));
			if (data) this.props.onMount(data);
		} catch (e) {}
		this.setState({ isMounted: true });
	}

	componentDidUpdate() {
		this.debouncedUpdate();
	}

	debouncedUpdate = debounce(() => {
		if (this.state.isMounted) {
			try {
				sessionStorage.setItem(this.getName(), JSON.stringify(this.props.data));
			} catch (e) {}
		}
	}, this.props.debounce);

	getName = () => {
		const { app, name } = this.props;
		return name + app.projects.current.id + document.location.pathname;
	};

	render() {
		return <Fragment />;
	}
}
export default withAppContext(Persist);
