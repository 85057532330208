import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiCardHeader from '@material-ui/core/CardHeader';

const useStyles = makeStyles(theme => ({
	action: {
		alignSelf: 'center',
		marginTop: 0,
		marginRight: 0,
	},
}));
export default function CardHeader({ alignCenter, ...props }) {
	const classes = useStyles();
	return (
		<MuiCardHeader
			titleTypographyProps={{
				variant: 'h6',
			}}
			classes={{
				...(alignCenter && {
					action: classes.action,
				}),
			}}
			{...props}
		/>
	);
}
