import React, { useState } from 'react';

import { makeStyles } from '@/styles';
import { useTranslation } from 'react-i18next';
import { useDataSourceContext } from '@/components/DataSources';
import { EmptyStateHandleHasSurveys } from '@/components/App';
import {
	Card,
	Grid,
	CardContent,
	CardActions,
	Typography,
	ActionButton,
	ContactDialog,
} from '@/components/Layout';
import { useAppContext } from '@/components/AppContext';
import TextAnalyticsFormList from './TextAnalyticsFormList';

const useStyles = makeStyles(theme => ({
	gridContainer: {
		'& > div:not(:last-child)': {
			marginBottom: theme.spacing(2),
		},
	},
	emptyState: {
		whiteSpace: 'pre-line',
	},
	titleMargin: {
		marginBottom: theme.spacing(2),
	},
}));

export default function TextAnalyticsLanguageConfig() {
	const classes = useStyles();
	const { t } = useTranslation();
	const { app } = useAppContext();
	const {
		datasource: { forms },
	} = useDataSourceContext();

	const [contactDialogOpen, setContactDialogOpen] = useState(false);

	return (
		<>
			{forms.asArray.length > 0 && (
				<div className={classes.gridContainer}>
					<Grid
						container
						spacing={3}
					>
						<Grid
							item
							sm={12}
							md={9}
							xl={10}
						>
							<TextAnalyticsFormList />
						</Grid>
						<Grid
							item
							sm={12}
							md={3}
							xl={2}
						>
							<Card>
								<CardContent>
									<Typography
										component="p"
										className={classes.titleMargin}
										variant="subtitle1"
										fontWeight="medium"
									>
										{t`reporting-text_analytics-card_translation_upgrade-title`}
									</Typography>
									<Typography
										component="p"
										variant="body1"
										gutterBottom
									>
										{t`reporting-text_analytics-card_translation_upgrade-text`}
									</Typography>
								</CardContent>
								<CardActions>
									<ActionButton
										variant="outlined"
										color="primary"
										onClick={() => setContactDialogOpen(true)}
									>
										{t`reporting-text_analytics-card_translation_upgrade-button`}
									</ActionButton>
								</CardActions>
							</Card>
						</Grid>
					</Grid>
				</div>
			)}
			{forms.asArray.length === 0 && (
				<EmptyStateHandleHasSurveys
					className={classes.emptyState}
					image={<img src={`/assets/img/r/emptystate/text_analytics.gif`} />}
					primary={t`reporting-text_analytics-emptystate_default-title`}
					primaryNoSurvey={t`reporting-text_analytics-emptystate_no_survey-title`}
					secondary={t`reporting-text_analytics-emptystate_default-text`}
					secondaryNoSurvey={t`reporting-text_analytics-emptystate_no_survey-text`}
				/>
			)}
			<ContactDialog
				open={contactDialogOpen}
				onClose={() => setContactDialogOpen(false)}
				title={t`reporting-text_analytics-contact-title`}
				subject={
					app.package.enterprise
						? t`reporting-text_analytics-contact-translation_feature`
						: t`reporting-text_analytics-contact-enterprise_translation_feature`
				}
				mailTo="success@mopinion.com"
			/>
		</>
	);
}
