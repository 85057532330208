import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';
import { Typography, Grid, ActionButton } from '@/components/Layout';
import { ValidatedField } from '@/components/Validation';
import { parseUserName, organisationFromEmail } from './onboardingUtils';
import emailToName from '@/utils/emailToName';
import useSaveOnboardingAccountInfo from './useSaveOnboardingAccountInfo';
import { ruleTests } from '@/components/Validation';

export default function OnboardingAccountInfo({ onAccountInfoUpdated = () => {} }) {
	const { t } = useTranslation();
	const {
		app,
		app: { users },
	} = useAppContext();

	const nameSource = users?.current?.firstname
		? `${users?.current?.firstname} ${users?.current?.lastname ?? ''}`
		: users?.current?.email
		? users.current.email
		: '';

	const [state, setState] = useState({
		name: emailToName(nameSource).join(' '),
		company: organisationFromEmail(users?.current?.email),
		phone: '',
	});

	const valid =
		parseUserName(state.name, 'firstname').length > 0 &&
		parseUserName(state.name).length > 0 &&
		state.company.length > 0 &&
		(!state.phone || ruleTests.phone(state.phone));

	const {
		postForm: updateAccountInfo,
		loading,
		clicked,
	} = useSaveOnboardingAccountInfo({
		data: {
			firstname: parseUserName(state.name, 'firstname'),
			lastname: parseUserName(state.name),
			company: state.company,
			phone: state.phone,
		},
		valid: () => valid,
		onSuccess: response => {
			if (response.code === 200) {
				app.api.getUsers();
				onAccountInfoUpdated();
			} else {
				//enqueueSnackbar(t('reporting-text_analytics-snackbar-language_error'));
			}
		},
	});

	const updateState = (target, value) => {
		setState({ ...state, [target]: value });
	};

	return (
		<>
			<Grid item>
				<Typography variant="h4">{t`onboarding-getting_started-step_info-title`}</Typography>
			</Grid>

			<Grid item>
				<Typography variant="body1">
					{t`onboarding-getting_started-step_info-subtitle`}
				</Typography>
			</Grid>

			<Grid item>
				<Typography variant="subtitle">
					{t`onboarding-getting_started-step_info-account_details`}
				</Typography>
			</Grid>

			<Grid item>
				<ValidatedField
					data-test-element="onboarding-name"
					variant="outlined"
					fullWidth
					label={t`onboarding-getting_started-step_info-name_label`}
					value={state.name}
					showErrors={clicked}
					rules={{
						required: true,
						custom: () =>
							parseUserName(state.name, 'firstname').length > 0 &&
							parseUserName(state.name).length > 0,
					}}
					messages={{
						required: t`errormessage_general_required`,
						custom: t`errormessage_name_fullname`,
					}}
					onChange={e => {
						const { value } = e.currentTarget;
						updateState('name', value.trimStart());
					}}
				/>
			</Grid>

			<Grid item>
				<ValidatedField
					variant="outlined"
					fullWidth
					label={t`onboarding-getting_started-step_info-company_label`}
					value={state.company}
					showErrors={clicked}
					rules={[['required', true]]}
					errorMessage={t`errormessage_general_required`}
					onChange={e => {
						const { value } = e.currentTarget;
						updateState('company', value);
					}}
				/>
			</Grid>

			<Grid item>
				<ValidatedField
					variant="outlined"
					fullWidth
					label={t`onboarding-getting_started-step_info-phone_label`}
					value={state.phone}
					showErrors={clicked && state.phone.length > 0}
					rules={[['phone', true]]}
					errorMessage={t`errormessage_phone_invalid`}
					onChange={e => {
						const { value } = e.currentTarget;
						setState(draft => {
							draft.phone = value;
						});
						updateState('phone', value);
					}}
					helperText={t`onboarding-getting_started-step_info-phone_helper`}
				/>
			</Grid>

			<Grid
				item
				xs={6}
			>
				<ActionButton
					data-test-element="onboarding-next-button"
					variant="contained"
					color="primary"
					data-track-event="onboarding_account_name_updated"
					loading={loading}
					onClick={() => {
						updateAccountInfo();
					}}
				>
					{t`onboarding-getting_started-step_info-button_next`}
				</ActionButton>
			</Grid>
		</>
	);
}
