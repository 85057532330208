import React, { forwardRef } from 'react';
import ActionButton from './ActionButton';
const ButtonAddList = forwardRef(function ButtonAddList(
	{ label, children, ...props },
	ref
) {
	return (
		<ActionButton
			label={label}
			ref={ref}
			action="add_list"
			{...props}
		>
			{children}
		</ActionButton>
	);
});

export default ButtonAddList;
