import React from 'react';

import { useTranslation, Trans } from 'react-i18next';
import { makeStyles } from '@/styles';

import { useDashboardContext } from './DashboardContextProvider';
import { SimpleDialog, Typography, Box } from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	introImageWrapper: {
		textAlign: 'center',
	},
	introImage: {
		maxWidth: 150,
		alignSelf: 'center',
		marginBottom: theme.spacing(4),
	},
}));

export default function MaxChartsDialog({
	open,
	chartsInDashboard = 0,
	dashboardId,
	dashboardName = '',
	submit,
	onClose = () => {},
	onSubmit = () => {},
}) {
	const { t } = useTranslation();
	const classes = useStyles();

	const { updateMaxChartsMessage } = useDashboardContext();

	return (
		<SimpleDialog
			title={t`reporting-dashboard-dialog_max_charts-title`}
			open={open}
			submit={submit ? submit : t`reporting-dashboard-dialog_max_charts-submit`}
			cancel={t`reporting-dashboard-dialog_max_charts-cancel`}
			dataTrackEvent="dashboard_grid_surpass_max_charts"
			onSubmit={() => {
				updateMaxChartsMessage(dashboardId);
				onSubmit();
			}}
			onClose={onClose}
		>
			<Box
				display="flex"
				flexDirection="column"
			>
				<Trans
					i18nKey="reporting-dashboard-dialog_max_charts-content"
					components={{
						p1: <Typography className={classes.introImageWrapper} />,
						p2: <Typography mb={2} />,
						p3: <Typography mb={2} />,
						img: (
							<img
								className={classes.introImage}
								src="/assets/img/r/emptystate/dashboard_no_charts.gif"
							/>
						),
						strong: <strong />,
					}}
					values={{
						count: chartsInDashboard,
						dashboard: dashboardName,
					}}
				/>
			</Box>
		</SimpleDialog>
	);
}
