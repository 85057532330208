import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';

import { useTranslation } from 'react-i18next';
import { UserAvatarGroup } from '../Users';
import { Typography, Alert } from '../Layout';
import { getProgressColor } from './exportUtils';
import { ExportStatusChip } from './ExportStatusChip';
import ExportDates from './ExportDates';

const useStyles = makeStyles(theme => ({
	progress: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		background: 'transparent',
	},
	deadlineIcon: {
		verticalAlign: 'middle',
		marginRight: theme.spacing(0.5),
	},
	deadlineText: {
		verticalAlign: 'middle',
	},
	progressRowSpace: {
		margin: theme.spacing(0.5, 0),
	},
	activeChip: {
		backgroundColor: props =>
			props.enabled ? getProgressColor(4, theme) : theme.palette.error.light,
		color: props =>
			theme.palette.getContrastText(props =>
				props.enabled ? theme.palette.secondary.light : theme.palette.error.light
			),
	},
	chip: {
		margin: theme.spacing(0, 2, 0, 0.5),
	},
	item: {
		marginRight: theme.spacing(2),
	},
	marginBottom: {
		marginBottom: theme.spacing(1),
	},
	alignRight: {
		textAlign: 'right',
	},
	dateWidth: {
		minWidth: 250,
		textAlign: 'right',
	},
}));

export default function ExportSummary({ exportData, variant, expanded }) {
	const classes = useStyles(exportData);
	const { t } = useTranslation();

	const exportDetails = exportData.kwargs
		? exportData.kwargs
		: exportData.export
		? exportData.export
		: {};

	const stack = exportDetails?.notify_to?.map(user => ({
		user_id: user,
		tooltip: true,
		tooltipTitle: ({ firstname, lastname }) => `${firstname} ${lastname}`,
	})) ?? [
		{
			user_id: exportDetails.user_id,
			tooltip: true,
			tooltipTitle: ({ firstname, lastname }) => `${firstname} ${lastname}`,
		},
	];

	return (
		<Fragment>
			<Grid
				container
				direction="row"
				alignItems="center"
				justifyContent="space-between"
			>
				<Grid
					item
					xs
				>
					<Grid
						container
						direction="column"
					>
						<Grid item>
							<Typography variant={expanded ? 'h6' : 'subtitle2'}>
								{variant === 'template' || variant === 'scheduled'
									? exportData.name
									: exportDetails.export_name}
							</Typography>
						</Grid>
						{(exportData.description || exportDetails.description) && (
							<Grid item>
								<Typography variant={expanded ? 'body1' : 'body2'}>
									{exportData.description
										? exportData.description
										: exportDetails.description}
								</Typography>
							</Grid>
						)}
					</Grid>
				</Grid>

				{!expanded && (
					<Fragment>
						<Grid item>
							<Grid
								container
								direction="column"
								className={classes.marginBottom}
							>
								{variant === 'scheduled' && (
									<Grid item>
										<Chip
											size="small"
											label={exportData.enabled ? t`Active` : t`Inactive`}
											className={`${classes.activeChip} ${classes.chip}`}
										/>
									</Grid>
								)}
								{exportData.status === 'FINISHED' && !exportData.result?.export_url && (
									<Grid
										item
										className={classes.item}
									>
										<Alert
											severity="info"
											variant="default"
										>
											{t`No feedback found for this export`}
										</Alert>
									</Grid>
								)}
								{variant === 'template' && (
									<Grid
										item
										className={classes.alignRight}
									>
										<Chip
											size="small"
											label={
												exportData.template_access === 'private'
													? t`My template`
													: t`Public template`
											}
											className={classes.chip}
										/>
									</Grid>
								)}
							</Grid>
						</Grid>

						<Grid item>
							<Grid
								container
								direction="column"
								className={classes.marginBottom}
							>
								{variant !== 'recent' && (
									<Grid item>
										<UserAvatarGroup
											stack={stack}
											size="small"
											className={classes.chip}
										/>
									</Grid>
								)}
								{variant === 'recent' && (
									<div className={classes.alignRight}>
										<Grid item>
											<ExportStatusChip
												size="small"
												type={exportData.status}
											/>
										</Grid>
										<Grid item>
											<Chip
												size="small"
												label={
													exportData.from_schedule
														? t`Export from schedule`
														: t`Single export`
												}
												className={classes.chip}
											/>
										</Grid>
									</div>
								)}
							</Grid>
						</Grid>

						<Grid item>
							<Grid
								container
								direction="column"
								className={classes.marginBottom}
							>
								{variant === 'recent' && (
									<Grid item>
										<ExportDates
											variant="caption"
											expiration={exportDetails.expiration_days}
											dateCreated={exportData.created}
										/>
									</Grid>
								)}
								{variant === 'scheduled' && (
									<Fragment>
										<Grid
											item
											xs={12}
											className={classes.dateWidth}
										>
											<ExportDates
												variant="caption"
												interval={exportData.beat}
											/>
										</Grid>
										<Grid
											item
											xs={12}
											className={classes.dateWidth}
										>
											{exportData.next_run_at && (
												<ExportDates
													variant="caption"
													nextInterval={
														exportData.enabled ? exportData.next_run_at : 'inactive'
													}
												/>
											)}
										</Grid>
									</Fragment>
								)}
							</Grid>
						</Grid>
					</Fragment>
				)}
			</Grid>
		</Fragment>
	);
}
