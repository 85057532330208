import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useAppContext } from '../AppContext';
import { PageHeader } from '../App';
import { AccountHeader, Card, Grid } from '../Layout';
import ListItemCard from './ListItemCard';
import { useAjaxForm } from '../Ajax';
import { useHasPermission } from '../Permission';

function MyOrganisation() {
	const { t } = useTranslation();
	const { app } = useAppContext();
	const organisation = app.organisations.current;
	const hasEditPermission = useHasPermission();
	const { enqueueSnackbar } = useSnackbar();

	const [form, setForm] = useState({
		name: null,
		created: null,
		address_line1: null,
		address_line2: null,
		postal_code: null,
		city: null,
		vat_number: null,
	});

	const postValues = Object.keys(form).reduce((update, key) => {
		if (organisation[key] !== form[key] && form[key] !== null) {
			update[key] = form[key];
		}

		return update;
	}, {});

	function setFormValue(key, value) {
		setForm(prev => ({
			...prev,
			[key]: value,
		}));
	}

	const { postForm } = useAjaxForm({
		valid: () => hasEditPermission,
		url: '/account/ajax/edit-organisation',
		data: postValues,
		onSuccess: async r => {
			if (r.code === 200) {
				enqueueSnackbar(`Organisation updated`);
				await app.api.getOrganisations();
				setForm({
					name: null,
					created: null,
					address_line1: null,
					address_line2: null,
					postal_code: null,
					city: null,
					vat_number: null,
				});
			}
		},
	});

	function handlePost(key) {
		if (form[key] !== organisation[key]) {
			postForm();
		}
	}

	return (
		<div>
			<PageHeader title={t`Organisation`} />
			<AccountHeader
				userId={app.users.current.id}
				align="left"
			/>

			<Grid
				container
				spacing={3}
			>
				<Grid
					item
					xs={6}
				>
					<Card>
						<ListItemCard
							initial={organisation}
							handlePost={handlePost}
							setFormValue={setFormValue}
							dataTestElement="organisationSaveChanges"
							listItems={[
								{
									target: 'name',
									icon: 'apartment',
									title: 'Organisation name',
									value: form.name ?? organisation.name,
									rules: [['required', true]],
									showErrors: true,
									messages: { required: t`A field is required` },
									dataTestElement: 'organisationName',
									dataTrackEvent: 'organisation_settings_organisationname',
									editable: hasEditPermission,
								},
								{
									target: 'created',
									icon: 'description',
									title: 'Created',
									value: form.created ?? organisation.created,
									editable: false,
								},
								{
									target: 'address_line1',
									icon: 'location_on',
									title: 'Address 1',
									value: form.address_line1 ?? organisation.address_line1,
									dataTrackEvent: 'organisation_settings_address1',
									editable: hasEditPermission,
								},
								{
									target: 'address_line2',
									icon: 'location_on',
									title: 'Address 2',
									value: form.address_line2 ?? organisation.address_line2,
									dataTrackEvent: 'organisation_settings_address2',
									editable: hasEditPermission,
								},
								{
									target: 'postal_code',
									icon: 'my_location',
									title: 'Postal code',
									value: form.postal_code ?? organisation.postal_code,
									dataTrackEvent: 'organisation_settings_postalcode',
									editable: hasEditPermission,
								},
								{
									target: 'city',
									icon: 'location_city',
									title: 'City',
									value: form.city ?? organisation.city,
									dataTrackEvent: 'organisation_settings_city',
									editable: hasEditPermission,
								},
								{
									target: 'vat_number',
									icon: 'credit_card',
									title: 'VAT number',
									value: form.vat_number ?? organisation.vat_number,
									dataTrackEvent: 'organisation_settings_vat',
									editable: hasEditPermission,
								},
								{
									target: 'uuid',
									icon: 'description',
									title: 'Uuid',
									value: organisation.uuid,
									editable: false,
								},
							]}
						/>
					</Card>
				</Grid>
			</Grid>
		</div>
	);
}

export default MyOrganisation;
