import axios, { CancelToken } from 'axios';

const axiosInstance = axios.create({
	headers: {
		'X-Requested-With': 'XMLHttpRequest',
		'X-Requested-By-React': 'true',
	},
});

export { axios, axiosInstance, CancelToken };
