import React, { useState, useEffect } from 'react';

import useOnboardingContext from './useOnboardingContext';
import { MenuTour } from './GeneralTours';

export default function NewUserOnboardingTrial({ ...props }) {
	const onboarding = useOnboardingContext();
	const [start, setStart] = useState(false);

	useEffect(() => {
		if (onboarding.newUserTour) {
			setStart(true);
		}
	}, [onboarding.newUserTour]);

	const TourProps = {
		open: start,
		update: onboarding.update,
		rounded: 4,
		updateDelay: 400,
		disableOverlay: true,
		updateFn: () => {
			onboarding.dispatch({
				type: 'update_tour',
				payload: true,
			});
		},
		onClose: () => {
			setStart(false);
		},
		key: onboarding.newUserTour,
	};

	switch (onboarding.newUserTour) {
		case 'menu':
			return (
				<MenuTour
					newUserOnboardingTrial
					{...TourProps}
				/>
			);

		default:
			return null;
	}
}
