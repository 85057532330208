import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { useAppContext } from '@/components/AppContext';
import { Link, RenderConditional, Skeleton } from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	colorUserName: {
		color: props => props.color ?? '#eee',
	},
	userNameFontWeight: {
		fontWeight: props => props.fontWeight ?? 600,
	},
}));

function UserName({
	current = true,
	user_id,
	uuid,
	colorUserName,
	profileLink,
	fontWeight = 600,
	className = '',
	onClick = () => {},
	showSelfAsYou = false,
}) {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const user = user_id
		? app.api.getUserById(user_id)
		: uuid
		? app.api.getUserByUuid(uuid)
		: user_id === undefined && uuid === undefined
		? app.api.getCurrentUser()
		: {};

	const classes = useStyles({ color: user?.color ?? '#eee', fontWeight });

	const firstname = user?.firstname ?? t`app_general-user_deleted-firstname`;
	const lastname = user?.lastname ?? t`app_general-user_deleted-lastname`;

	const isCurrentUser = Number(user?.id) === Number(app.users.current.id);

	if (app.users.asArray.length === 0) {
		return <Skeleton variant="text" />;
	}

	return (
		<RenderConditional
			condition={profileLink}
			component={Link}
			componentProps={{
				component: RouterLink,
				to: `/application/users/profile/${user.id}`,
				onClick: e => {
					e.stopPropagation();
					onClick(e);
				},
			}}
		>
			<span
				className={`${classes.userNameFontWeight} ${
					colorUserName ? classes.colorUserName : ''
				} ${className}`}
			>
				{isCurrentUser && showSelfAsYou
					? t`app_general-username-you`
					: `${firstname ?? ''} ${lastname ?? ''}`}
			</span>
		</RenderConditional>
	);
}

export default UserName;
