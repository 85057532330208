import React, { useState } from 'react';

import { useTranslation, Trans } from 'react-i18next';

import { makeStyles } from '@/styles';

import Explainer from './Explainer';
import { OptInOutDialog } from '@/components/SmartRecaps';
import { Typography, ActionButton } from '@/components/Layout';
import { NoPermissionTooltip, useHasPermission } from '@/components/Permission';

const useStyles = makeStyles(theme => ({
	explainerLink: {
		color: theme.palette.getContrastText(theme.palette.primary.dark),
		borderBottomColor: theme.palette.getContrastText(theme.palette.primary.dark),
		'& svg': {
			color: theme.palette.getContrastText(theme.palette.primary.dark),
		},
	},
}));

export default function SmartRecapsExplainer({
	smartRecapsProjectActive,
	loadOptInData = () => {},
}) {
	const { t } = useTranslation();
	const classes = useStyles();

	const [optOutDialogOpen, setOptOutDialogOpen] = useState(false);
	const hasEditPermission = useHasPermission();

	return (
		<>
			<Explainer
				title={t`reporting-feedback_inbox-smart_recaps-explainer-title`}
				content={action => {
					return (
						<>
							<Trans
								i18nKey="reporting-feedback_inbox-smart_recaps-explainer-content"
								components={{
									paragraph: <Typography mb={3} />,
									header: <Typography fontWeight="medium" />,
									optOut: hasEditPermission ? (
										<ActionButton
											dataTrackEvent="smartrecaps_explainer_button_smartrecaps_opt_out"
											action="nav_to"
											onClick={() => {
												setOptOutDialogOpen(true);
												action.close();
											}}
											color="inherit"
										/>
									) : null,
								}}
							/>
						</>
					);
				}}
				className={classes.explainerLink}
			>
				{t`reporting-feedback_inbox-smart_recaps-explainer-label`}
			</Explainer>
			<OptInOutDialog
				open={optOutDialogOpen}
				onClose={() => setOptOutDialogOpen(false)}
				smartRecapsProjectActive={smartRecapsProjectActive}
				loadOptInData={loadOptInData}
			/>
		</>
	);
}
