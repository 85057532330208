import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BarRatingIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<path
				d="M19.1,13.7v12.4H6.7V13.7H19.1 M20,11.9H5.8c-0.5,0-0.9,0.4-0.9,0.9v14.2c0,0.5,0.4,0.9,0.9,0.9H20
				c0.5,0,0.9-0.4,0.9-0.9V12.8C20.9,12.3,20.5,11.9,20,11.9L20,11.9z M15.5,20.2c0,1.3-0.2,2.3-0.7,2.9c-0.4,0.6-1.1,1-2,1
				c-0.9,0-1.5-0.3-2-1s-0.7-1.6-0.7-2.9c0-1.3,0.2-2.3,0.6-3s1.1-1,2-1c0.9,0,1.5,0.3,2,1S15.5,18.9,15.5,20.2z M11.8,20.2
				c0,0.9,0.1,1.6,0.2,2s0.4,0.6,0.8,0.6c0.4,0,0.6-0.2,0.8-0.6c0.2-0.4,0.2-1.1,0.2-2c0-0.9-0.1-1.6-0.3-2s-0.4-0.6-0.8-0.6
				c-0.4,0-0.6,0.2-0.8,0.6C11.9,18.6,11.8,19.3,11.8,20.2z M4.9,13.7v12.4H-7.5V13.7H4.9 M5.8,11.9H-8.3c-0.5,0-0.9,0.4-0.9,0.9v14.2
				c0,0.5,0.4,0.9,0.9,0.9H5.8c0.5,0,0.9-0.4,0.9-0.9V12.8C6.7,12.3,6.3,11.9,5.8,11.9L5.8,11.9z M-2,21.3v-1.4h3.1v1.4H-2z M33.3,13.7
				v12.4H20.9V13.7H33.3 M34.2,11.9H20c-0.5,0-0.9,0.4-0.9,0.9v14.2c0,0.5,0.4,0.9,0.9,0.9h14.2c0.5,0,0.9-0.4,0.9-0.9V12.8
				C35.1,12.3,34.7,11.9,34.2,11.9L34.2,11.9z M26.7,20.7h-2.2v-1.2h2.2v-2.2H28v2.2h2.2v1.2H28v2.2h-1.3V20.7z M47.5,13.7v12.4H35.1
				V13.7H47.5 M48.3,11.9H34.2c-0.5,0-0.9,0.4-0.9,0.9v14.2c0,0.5,0.4,0.9,0.9,0.9h14.2c0.5,0,0.9-0.4,0.9-0.9V12.8
				C49.2,12.3,48.8,11.9,48.3,11.9L48.3,11.9z M39.4,20.7h-2.2v-1.2h2.2v-2.2h1.3v2.2h2.2v1.2h-2.2v2.2h-1.3V20.7z M46,20.7h-2.2v-1.2
				H46v-2.2h1.3v2.2h2.2v1.2h-2.2v2.2H46V20.7z"
			/>
		</SvgIcon>
	);
}
