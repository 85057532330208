import React from 'react';

import { useTranslation } from 'react-i18next';

import { Autocomplete, Chip } from '@/components/Layout';
import { isAdminOwnerGroup } from './utils';
import useGetPermissionGroups from './useGetPermissionGroups';

export default function PermissionGroupAutocomplete({
	value,
	multiple = true,
	label,
	fixedAdminOwner,
	...props
}) {
	const [{ groups = [] }] = useGetPermissionGroups();
	const { t } = useTranslation();

	const selected = multiple
		? groups.filter(group => {
				if (fixedAdminOwner) {
					return isAdminOwnerGroup(group) || value?.includes(group.uuid);
				}

				return value?.includes(group.uuid);
		  })
		: groups.find(group => group.uuid === value) ?? '';

	return (
		<Autocomplete
			value={selected}
			options={groups}
			multiple={multiple}
			getOptionLabel={option => option?.name ?? ''}
			data-test-element="permission-group-autocomplete"
			ListboxProps={{
				'data-test-element': 'permission-group-autocomplete-listbox',
			}}
			label={
				label ?? t`settings-user_management-access_groups-access_group_autocomplete-label`
			}
			{...(fixedAdminOwner && {
				renderTags: (tagValue, getTagProps) =>
					tagValue.map((option, index) => (
						<Chip
							label={option.name}
							{...getTagProps({ index })}
							{...(isAdminOwnerGroup(option) && {
								onDelete: null,
							})}
						/>
					)),
			})}
			disableClearable
			{...props}
		/>
	);
}
