import { useCallback, useState, useMemo, useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import { useAppContext } from '../AppContext';
import { tryParse, getCookie } from '../../utils';

function handleClickEvents(e) {
	const target = e.target.getAttribute('data-track-event')
		? e.target
		: e.target.closest('[data-track-event]');

	const event = target?.getAttribute('data-track-event');
	if (event) {
		//check if there are props to pass to the 'track' call
		//these will be inside a stringified object
		const eventProps = tryParse(target.getAttribute('data-track-event-props'), {});
		mixpanel.track(event, eventProps);
	}
}

function pageview(pathname) {
	mixpanel.track('pageview', { path: pathname });
}

export default function useTracking() {
	const { app } = useAppContext();

	const [didInit, setDidInit] = useState(false);
	const [didIdentify, setDidIdentify] = useState(false);
	const [disableEvents, setDisableEvents] = useState(false);
	const [trackingQueue, setTrackingQueue] = useState([]);
	const { listen } = useHistory();
	const location = useLocation();

	//handle events that are possible fired before init
	useEffect(() => {
		if (trackingQueue.length > 0) {
			if (disableEvents) {
				setTrackingQueue([]);
				return;
			}

			trackingQueue.forEach(trackingArgs => {
				try {
					mixpanel.track(...trackingArgs);
				} catch (e) {}
			});
			setTrackingQueue([]);
		}
	}, [trackingQueue, didInit, disableEvents]);

	function userData() {
		return {
			name: `${app.users.current.firstname ?? ''} ${app.users.current.lastname ?? ''}`,
			id: app.users.current.uuid,
			firstname: app.users.current.firstname,
			lastname: app.users.current.lastname,
			$email: app.users.current.email,
			email: app.users.current.email,
			organisation: app.organisations.current.name,
			organisation_id: app.organisations.current.uuid,
			package: app.package.name,
			package_id: app.organisations.current.package,
			base_package_id: app.package.id,
			created: app.users.current.created,
			domain: app.domain,
		};
	}

	function orgData() {
		return {
			id: app.organisations.current.uuid,
			name: app.organisations.current.name,
			$name: app.organisations.current.name,
			country_code: app.organisations.current.country_code,
			created: app.organisations.current.created,
			domain: app.domain,
		};
	}

	function packageData() {
		return {
			package: app.package.name,
			package_id: app.organisations.current.package,
			base_package_id: app.package.id,
			max_pageviews: app.package.pageviews,
			max_deployments: app.package.deployments,
			max_surveys: app.package.surveys,
			max_survey_blocks: app.package.survey_blocks,
			max_reports: app.package.projects,
			max_charts: app.package.charts,
			max_webhooks: app.package.webhooks,
			max_alerts: app.package.alerts,
			max_tags: app.package.tags,
			max_api_integrations: app.package.integrations,
			trial: app.package.trial,
		};
	}

	function superPropertyData() {
		return {
			project: app.projects.current.name,
			project_id: app.projects.current.uuid,
			organisation: app.organisations.current.name,
			organisation_id: app.organisations.current.uuid,
		};
	}

	const orgGroupData = useMemo(() => {
		if (!didIdentify) return {};

		return {
			...orgData(),
			...packageData(),
		};
	}, [JSON.stringify(orgData()), JSON.stringify(packageData()), didIdentify]);

	useEffect(() => {
		if (app.mixpanel && !didInit && location.pathname !== '/login') {
			mixpanel.init(app.mixpanel, {
				debug: getCookie('debugMixpanel') ? true : false,
			});

			setDidInit(true);
			pageview(location.pathname);
		}
	}, [app.mixpanel, didInit, location.pathname]);

	//setup super properties and initial events
	useEffect(() => {
		if (didIdentify) {
			mixpanel.register(superPropertyData());
			mixpanel.track('app_initialised');
		}
	}, [didIdentify, JSON.stringify(superPropertyData()), app.auth.loggedIn]);

	//setup click handlers
	useEffect(() => {
		function clickHandler(e) {
			if (!disableEvents) handleClickEvents(e);
		}

		if (didInit) {
			document.addEventListener('click', clickHandler);
			return () => {
				document.removeEventListener('click', clickHandler);
			};
		}
	}, [didInit, disableEvents]);

	//identify user
	const identifier = app.users.current.str_id ?? app.users.current.uuid;

	useEffect(() => {
		if (!app.auth.loggedIn) return;

		if (
			didInit &&
			identifier &&
			!didIdentify &&
			!app.users.current.impersonated_by?.uuid
		) {
			setDidIdentify(true);
			mixpanel.identify(identifier);
			mixpanel.people.set(userData());
		}

		if (didIdentify && !app.users.current.impersonated_by?.uuid) {
			mixpanel.people.set(userData());
		}

		//we don't want to track any events for impersonating users
		//we do want to know somebody is impersonating
		if (app.users.current.impersonated_by?.uuid) {
			track('impersonating_user', {
				impersonated_by: app.users.current.impersonated_by?.email,
				impersonating: app.users.current.email,
			});
			setDisableEvents(true);
		}
	}, [
		identifier,
		app.users.current.uuid,
		app.users.current.impersonated_by?.uuid,
		didInit,
		JSON.stringify(userData()),
		app.auth.loggedIn,
		didIdentify,
	]);

	//identify org group
	useEffect(() => {
		if (didIdentify) {
			try {
				//for custom subdomains -> subdom is the org key, for app use org uuid
				const multiDomains = [
					'app.mopinion.com',
					'beta.mopinion.com',
					'accept.mopinion.com',
					'demo.mopinion.com',
				];

				const groupKey = multiDomains.includes(app.domain)
					? `${app.domain.replace('.mopinion.com', '')}-${app.organisations.current.uuid}`
					: app.domain.replace('.mopinion.com', '');

				mixpanel.add_group('organisation_group', groupKey, () => {
					mixpanel.get_group('organisation_group', groupKey).set(orgGroupData);
				});
			} catch (e) {}
		}
	}, [didIdentify, app.domain, app.organisations.current.uuid, orgGroupData]);

	//identify package group
	useEffect(() => {
		if (didIdentify) {
			try {
				mixpanel.add_group('package_group', app.package.id, () => {
					mixpanel
						.get_group('package_group', app.package.id)
						.set({ $name: app.package.name });
				});
			} catch (e) {}
		}
	}, [didIdentify, app.package.name, app.package.id]);

	//identify subdom group
	useEffect(() => {
		if (didIdentify) {
			try {
				mixpanel.add_group('subdomain_group', app.domain, () => {
					mixpanel.get_group('subdomain_group', app.domain).set({ $name: app.domain });
				});
			} catch (e) {}
		}
	}, [didIdentify, app.domain]);

	useEffect(() => {
		if (!didInit) return;
		const unlisten = listen(location => {
			if (!disableEvents) {
				pageview(location.pathname);
			}
		});

		return unlisten;
	}, [listen, didInit, disableEvents]);

	const track = useCallback(
		(...args) => {
			if (didInit && !disableEvents) {
				mixpanel.track(...args);
			} else if (!didInit && !disableEvents) {
				//save events in tracking queue to track when mixpanel is initialised
				setTrackingQueue(prev => [...prev, [...args]]);
			}
		},
		[didInit, disableEvents]
	);

	const timeEvent = useCallback(
		(...args) => {
			if (didInit && !disableEvents) {
				mixpanel.time_event(...args);
			}
		},
		[didInit, disableEvents]
	);

	const setPeople = useCallback(
		(...args) => {
			if (didInit && !disableEvents) {
				mixpanel.people.set(...args);
			}
		},
		[didInit, disableEvents]
	);

	return {
		track,
		timeEvent,
		setPeople,
	};
}
