import { directInsightChartTypes } from '@/components/Charts/constants';

export const blockToCharts = {
	Survey: [
		directInsightChartTypes.score_count,
		directInsightChartTypes.line_total_over_time,
	],
	3: [directInsightChartTypes.bar_count],
	4: [directInsightChartTypes.column_count_checkbox],
	5: [directInsightChartTypes.column_count_sorted_desc],
	6: [
		directInsightChartTypes.line_average_over_time,
		directInsightChartTypes.column_count,
	],
	10: [
		directInsightChartTypes.line_nps_over_time,
		directInsightChartTypes.column_nps_stacked,
	],
	13: [directInsightChartTypes.bar_count],
	14: [directInsightChartTypes.line_ces_over_time, directInsightChartTypes.column_count],
	16: [
		directInsightChartTypes.line_gcr_over_time,
		directInsightChartTypes.column_gcr_stacked,
	],
	20: [directInsightChartTypes.column_count_thumbs],
	url: [directInsightChartTypes.bar_top_5],
	Device: [directInsightChartTypes.column_count_sorted_desc],
	OS: [directInsightChartTypes.column_count_sorted_desc],
};

export const customChartSettings = {
	Survey: {
		customTitle: 'reporting-insights-meta-title-survey',
		customSubTitle: 'reporting-insights-meta-subtitle-survey',
	},
	url: {
		customTitle: 'reporting-insights-meta-title-url',
		customSubTitle: 'reporting-insights-meta-subtitle-url',
	},
	Device: {
		customSubTitle: 'reporting-insights-meta-subtitle-device',
	},
	OS: {
		customSubTitle: 'reporting-insights-meta-subtitle-os',
	},
	4: {
		customSubTitle: 'reporting-insights-column-subtitle-answer',
	},
	10: {
		customLegend: 'reporting-insights-line-subtitle-nps-legend',
	},
	13: {
		customSubTitle: 'reporting-insights-column-subtitle-category',
	},
	14: {
		customSubTitle: 'reporting-insights-line-subtitle-ces',
		customLegend: 'reporting-insights-line-subtitle-ces-legend',
	},
	15: {
		customSubTitle: 'reporting-insights-column-subtitle-select',
	},
	16: {
		customLegend: 'reporting-insights-line-subtitle-gcr-legend',
	},
};
