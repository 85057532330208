import React, { useState, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';

import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';
import {
	ActionIconButton,
	ActionButton,
	Typography,
	Grid,
	Link,
	EmptyState,
	Loader,
} from '@/components/Layout';
import { ErrorBoundary } from '@/components/Utility';
import NotificationItem from './NotificationItem';
import useNotifications from './useNotifications';
import {
	NotificationsContextProvider,
	useNotificationContext,
} from './NotificationsContext';
import { allowedNotificationEvents } from './constants';

const useStyles = makeStyles(theme => ({
	popover: {
		width: 540,
		padding: theme.spacing(1, 2),
	},
	headerDivider: {
		margin: theme.spacing(1, 0),
	},
	menuSection: {
		padding: theme.spacing(2, 0),
	},
	emptyStateWrap: {
		padding: theme.spacing(4, 2, 4, 4),
	},
	loaderWrap: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		textAlign: 'center',
		paddingBottom: theme.spacing(2),
	},
	announcement: {
		padding: theme.spacing(4),
	},
	announceSpace: {
		marginBottom: theme.spacing(2),
	},
	announceButtonSpace: {
		marginTop: theme.spacing(4),
	},
	emph: {
		fontWeight: theme.typography.fontWeightMedium,
	},
	announceImg: {
		width: 90,
	},
	marginRight: {
		marginRight: theme.spacing(1),
	},
}));

function NotificationsMenuSection({ title, notifications = [], variant }) {
	const classes = useStyles();
	const { t } = useTranslation();

	const { markAllAsRead, clearAll } = useNotificationContext();

	return (
		<div className={classes.menuSection}>
			<Grid
				container
				alignItems="center"
			>
				<Grid
					item
					xs
				>
					<Typography
						variant="subtitle2"
						color="textSecondary"
					>
						{title}
					</Typography>
				</Grid>
				{variant === 'unread' && (
					<Grid item>
						<Link
							variant="caption"
							onClick={markAllAsRead}
							data-track-event="notification_screen_all_marked_read"
						>{t`Mark all as read`}</Link>
					</Grid>
				)}
				{variant === 'read' && (
					<Grid item>
						<Link
							variant="caption"
							onClick={clearAll}
							data-track-event="notification_screen_all_deleted"
						>{t`Clear all`}</Link>
					</Grid>
				)}
			</Grid>
			<div>
				{notifications
					.filter(item => allowedNotificationEvents.includes(item.event.type))
					.map(item => (
						<NotificationItem
							item={item}
							key={item.uuid}
						/>
					))}
			</div>
		</div>
	);
}

export default function NotificationsMenu({ ...props }) {
	const { t } = useTranslation();
	const classes = useStyles();
	const { app } = useAppContext();

	const anchor = useRef(null);
	const [open, setOpen] = useState(false);
	const [paginateBy, setPaginateBy] = useState(10);

	const [seenOpener, setSeenOpener] = useState(false);

	const notifications = useNotifications({ menuOpen: open, paginateBy });
	const { all, read, unread, today, older, badgeValue, showBadge, loading } =
		notifications;

	const seenNotificationsAnnouncement =
		seenOpener || app.api.getUserMeta()?.seen_notifier_opener;

	function openerSeen() {
		setSeenOpener(true);
		if (!app.api.getUserMeta().seen_notifier_opener) {
			app.api.setUserMeta({ seen_notifier_opener: true });
		}
	}

	function onScroll(e) {
		const scrolledToBottom =
			e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight;
		const moreNotificationsPossible = all.length >= 10 && paginateBy === all.length;

		if (scrolledToBottom && moreNotificationsPossible) {
			setPaginateBy(prev => prev + 10);
		}
	}

	return (
		<ErrorBoundary returnNullOnError>
			<>
				<ActionIconButton
					className={classes.marginRight}
					action="notification"
					ref={anchor}
					onClick={e => setOpen(true)}
					badge={
						!seenNotificationsAnnouncement
							? 1
							: showBadge && badgeValue !== 0
							? badgeValue > 9
								? `9+`
								: badgeValue
							: null
					}
					BadgeProps={{
						color: 'error',
					}}
					data-track-event="notification_screen_opened"
				/>
				<NotificationsContextProvider
					value={{
						...notifications,
						closeMenu: () => setOpen(false),
					}}
				>
					<Popover
						open={open}
						anchorEl={anchor.current}
						onClose={() => {
							setOpen(false);
							openerSeen();
						}}
						classes={{ paper: classes.popover }}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						PaperProps={{
							onScroll: onScroll,
						}}
					>
						{seenNotificationsAnnouncement && (
							<>
								<Grid
									container
									alignItems="center"
									className={classes.header}
								>
									<Grid
										item
										xs
									>
										<Typography variant="h6">{t`Notifications`}</Typography>
									</Grid>
									<Grid item>
										<ActionIconButton
											action="settings"
											tooltip={t`Notification settings`}
											link
											to={'/application/notifications'}
										/>
									</Grid>
								</Grid>
								{unread.length > 0 ? (
									<>
										<NotificationsMenuSection
											title={t`Unread`}
											notifications={unread}
											variant="unread"
										/>
										{read.length > 0 && (
											<NotificationsMenuSection
												title={t`Read`}
												notifications={read}
											/>
										)}
									</>
								) : all.length !== 0 ? (
									<>
										{today.length > 0 && (
											<>
												<NotificationsMenuSection
													title={t`Today`}
													notifications={today}
													variant="read"
												/>
												{older.length > 0 && (
													<NotificationsMenuSection
														title={t`Older`}
														notifications={older}
													/>
												)}
											</>
										)}
										{today.length === 0 && (
											<>
												<NotificationsMenuSection
													title={t`Most recent`}
													notifications={older}
													variant="read"
												/>
											</>
										)}
									</>
								) : null}
								{all.length === 0 && (
									<EmptyState
										icon={NotificationsNoneIcon}
										primary={t`app_general-notifications-title`}
										secondary={t`app_general-notifications-text`}
										className={classes.emptyStateWrap}
									/>
								)}
								{loading && (
									<div className={classes.loaderWrap}>
										<Loader
											circular
											size={24}
										/>
									</div>
								)}
							</>
						)}
						{!seenNotificationsAnnouncement && (
							<div className={classes.announcement}>
								<EmptyState
									image={
										<img
											src="/assets/img/r/notification.svg"
											className={classes.announceImg}
										/>
									}
									primary={t`app_general-notifications-title_new`}
									secondary={<>{t`app_general-notifications-text_new`}</>}
									action={
										<ActionButton
											action="nav_to"
											size="medium"
											color="primary"
											variant="contained"
											onClick={() => openerSeen()}
											dataTrackEvent="notification_notification_start"
										>
											{t`app_general-notifications-button_new`}
										</ActionButton>
									}
								/>
							</div>
						)}
					</Popover>
				</NotificationsContextProvider>
			</>
		</ErrorBoundary>
	);
}
