import React, { forwardRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
	formControlLabel: {
		marginRight: 0,
	},
	label: {
		fontSize: theme.typography.pxToRem(13),
	},
}));

const SwitchWithLabel = forwardRef(function SwitchWithLabel(
	{
		onChange = () => {},
		label,
		checked,
		SwitchProps = {},
		FormControlLabelProps = {},
		tooltip = false,
		...props
	},
	ref
) {
	const classes = useStyles();

	return (
		<FormControlLabel
			labelPlacement="start"
			ref={ref}
			control={
				tooltip ? (
					<Tooltip title={tooltip}>
						<Switch
							size="small"
							checked={checked}
							onChange={onChange}
							{...SwitchProps}
						/>
					</Tooltip>
				) : (
					<Switch
						size="small"
						checked={checked}
						onChange={onChange}
						{...SwitchProps}
					/>
				)
			}
			label={label}
			className={classes.formControlLabel}
			classes={{
				label: classes.label,
			}}
			{...FormControlLabelProps}
			{...props}
		/>
	);
});

export default SwitchWithLabel;
