import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAppContext } from '../AppContext';
import { parseAmount } from './billingUtils';

const useStyles = makeStyles(theme => ({
	discount: {
		color: theme.palette.secondary.main,
	},
}));

function applyDiscount(amount, discount, discountType, diff) {
	if (discount) {
		const discountedAmount =
			discountType === 'percentage' ? amount * (1 - discount / 100) : amount - discount;

		return diff ? discountedAmount - amount : discountedAmount;
	}

	return amount;
}

export default function Amount({
	amount,
	currency = 'eur',
	currencyOptions = {},
	noDecimals,
	discount,
	diff,
	discountType = 'percentage',
	...props
}) {
	const { app } = useAppContext();
	const classes = useStyles();
	const value = parseAmount({
		amount: applyDiscount(amount, discount, discountType, diff),
		currency,
		currencyOptions: {
			...(noDecimals && {
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			}),
			...currencyOptions,
		},
		locale: app.organisations.current.country_code,
	});

	return <span>{value}</span>;
}
