import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { useTranslation } from 'react-i18next';

import { Typography } from '../Layout';
import { useDataSourceContext } from '../DataSources';
import TagChip from './TagChip';
import { parseConditionValues } from './tagUtils';

const useStyles = makeStyles(theme => ({
	spaceChildren: {
		'& > *': {
			margin: theme.spacing(0.25),
		},
	},
	conditionInput: {
		marginRight: theme.spacing(0.25),
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	paperPadding: {
		padding: theme.spacing(1),
	},
}));

export default function TagConditionSummary({ conditionSet, conditionIndex, newSet }) {
	const { t } = useTranslation();
	const classes = useStyles();
	const { datasource } = useDataSourceContext();

	return (
		<Grid
			container
			direction="column"
		>
			<Grid item>
				<Typography
					variant="subtitle2"
					color="textSecondary"
					gutterBottom
				>
					{newSet ? t`New condition set` : t`Condition set`}
				</Typography>
			</Grid>
			<Grid item>
				<Grid
					container
					direction="row"
				>
					<Grid
						item
						xs={4}
					>
						<Typography
							variant="body2"
							color="textSecondary"
							display="inline"
						>{t`Add tag(s)`}</Typography>
						<span className={classes.spaceChildren}>
							{conditionSet.tags.map(tag => (
								<TagChip
									key={tag}
									small
									label={tag}
								/>
							))}
						</span>
					</Grid>
					<Grid
						item
						xs={8}
					>
						<Grid
							container
							spacing={1}
						>
							{conditionSet.conditions
								.filter(condition => condition.datafield_id)
								.map((condition, index) => {
									const field = datasource.api.getField(condition.datafield_id);

									const conditionValues = parseConditionValues(
										field,
										condition.value,
										t` or `
									);

									return (
										<Fragment key={`conditions-${index}`}>
											<Grid
												item
												xs={6}
											>
												<Typography
													variant="body2"
													color="textSecondary"
													display="block"
													className={classes.conditionInput}
												>
													{index ? t`And when` : t`When`}
												</Typography>
												<Typography
													variant="body2"
													display="block"
													className={classes.conditionInput}
												>
													{field.system_var}
												</Typography>
											</Grid>
											<Grid
												item
												xs={6}
												className={classes.overflow}
											>
												<Typography
													variant="body2"
													color="textSecondary"
													display="block"
													className={classes.conditionInput}
												>
													{t('operator.' + condition.operator)}
												</Typography>
												<Typography
													variant="body2"
													display="block"
													className={classes.conditionInput}
												>
													{conditionValues}
												</Typography>
											</Grid>
										</Fragment>
									);
								})}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
