import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';
import useDashboard from './useDashboard';
import { Menu, MenuItem } from '@/components/Layout';
import MaxChartsDialog from './MaxChartsDialog';

const useStyles = makeStyles(theme => ({
	subheader: {
		backgroundColor: '#fff',
		textAlign: 'left',
	},
}));

export default function DashboardMenu({
	open,
	anchor,
	withCurrent,
	onChange = () => {},
	handleClose = () => {},
}) {
	const { t } = useTranslation();
	const classes = useStyles();
	const { app } = useAppContext();

	const [pendingChartAction, setPendingChartAction] = useState({
		dialogOpen: false,
		dashboardName: null,
		dashboardId: null,
	});

	const {
		dashboards,
		currentDashboard,
		currentDashboardId,
		dashboardApi: { maxChartsReached, chartsOnDashboard },
	} = useDashboard();

	return (
		<>
			<Menu
				open={open}
				anchorEl={anchor.current}
				keepMounted
				onClose={handleClose}
			>
				{withCurrent && currentDashboardId > 0 && (
					<span>
						<ListSubheader
							className={classes.subheader}
						>{t`reporting-dashboard-dashboard_quick_selection-list_current`}</ListSubheader>
						<MenuItem
							selected
							value={currentDashboardId}
							onClick={e => {
								if (maxChartsReached(e.target.value)) {
									setPendingChartAction({
										dialogOpen: true,
										dashboardId: e.target.value,
										dashboardName:
											currentDashboard?.name ??
											t('reporting-dashboard-copy_chart_menu-default_dashboard', {
												name: app.projects.current.name,
											}),
									});
								} else {
									onChange(e.target.value);
								}
							}}
						>
							{currentDashboard?.name ??
								t('reporting-dashboard-copy_chart_menu-default_dashboard', {
									name: app.projects.current.name,
								})}
						</MenuItem>
					</span>
				)}
				<ListSubheader className={classes.subheader}>
					{withCurrent && currentDashboardId > 0
						? t`reporting-dashboard-dashboard_quick_selection-list_other`
						: t`reporting-dashboard-dashboard_quick_selection-list_dashboards`}
				</ListSubheader>
				{dashboards
					.filter(dashboard => !withCurrent || currentDashboardId != dashboard.id)
					.map((dashboard, index) => {
						return (
							<MenuItem
								selected={currentDashboardId == dashboard.id}
								key={index}
								value={dashboard.id}
								onClick={e => {
									if (maxChartsReached(e.target.value)) {
										setPendingChartAction({
											dialogOpen: true,
											dashboardId: e.target.value,
											dashboardName:
												dashboard.name ??
												t('reporting-dashboard-copy_chart_menu-default_dashboard', {
													name: app.projects.current.name,
												}),
										});
									} else {
										onChange(e.target.value);
									}
								}}
							>
								{dashboard.name ??
									t('reporting-dashboard-copy_chart_menu-default_dashboard', {
										name: app.projects.current.name,
									})}
							</MenuItem>
						);
					})}
			</Menu>
			<MaxChartsDialog
				open={pendingChartAction.dialogOpen}
				chartsInDashboard={chartsOnDashboard(pendingChartAction.dashboardId)}
				dashboardName={pendingChartAction.dashboardName}
				dashboardId={pendingChartAction.dashboardId}
				onSubmit={() => {
					onChange(pendingChartAction.dashboardId);
				}}
				onClose={e => {
					setPendingChartAction({
						dialogOpen: false,
						dashboardId: null,
						dashboardName: null,
					});
					handleClose(e);
				}}
			/>
		</>
	);
}
