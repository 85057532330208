import axios from 'axios';
import objectToFormData from 'object-to-formdata';
import { decodeCharacters } from '../components/Charts/chartUtils';

export const BackendBauer = {
	pending: {},
	handlePending(path, data) {
		if (this.pending[path]) {
			if (JSON.stringify(this.pending[path].data) === JSON.stringify(data)) {
				return false;
			} else {
				this.cancelPending(path);
				this.pending[path] = {
					data: data,
					cancel: () => {},
					count: 0,
				};
				return true;
			}
		} else {
			this.pending[path] = {
				data: data,
				cancel: () => {},
				count: 0,
			};
			return true;
		}
	},
	cancelPending(path) {
		try {
			if (this.pending[path]) {
				this.pending[path].cancel();
				delete this.pending[path];
			}
		} catch (e) {}
	},
	init(token) {
		//secret = token;
		this.token = token;
	},
	get(
		serie,
		vars = {
			domain: 'beta.mopinion.com',
			fromDate: '2018-1-1',
			toDate: '',
			chart_type: 1,
			cacheRefresh: false,
			serieIndex: 0,
		},
		config = {}
	) {
		let d = new Date(Date.now() - 3600 * 1000 * 24 * 30 * 2);
		let custom =
			typeof serie.custom === 'object'
				? serie.custom
				: typeof serie.custom === 'string'
				? getQueryParameters(serie.custom)
				: {};
		let request = {
			domain: vars.domain,
			y: 1, //?serie.y ||
			x: 1, //?serie.x ||
			from_date: vars.fromDate || d.getFullYear() + '-' + d.getMonth() + '-1',
			to_date: vars.toDate || '',
			avg: serie.average || '',
			filter: makeFilter(serie, vars.chart_type), //serie.filter || '',
			chart_type: 1, //always request chart_type 1, all the code is set to parse this response
			series: 1,
			jsonp: 0,
			order: custom.order || '',
			limit: custom.limit || '',
			role: vars.role_id || '',
			callback: serie.callback || '',
			combined: 1,
			name: encodeURI(serie.name) || '',
			debug: 0,
			benchmark: serie.benchmark || '',
			perc_cats: percCats(serie.perc_cats) || '',
			visible: serie.visible || '',
			datetime: 0,
			date_group: vars.chart_type == 1 ? custom.date_group || '' : '',
			color:
				serie.color && typeof serie.color === 'string'
					? serie.color.replace('#', '') || ''
					: '',
			delay: 0,
			decimal: custom.decimal || '',
			xname: '', //serie.xname || '',
			seriesOrder: serie.seriesOrder || '',
			widget: serie.is_widget || 0,
			distinct: custom.distinct || '',
			extra_filter: custom.extra_filter || '',
			//extra_filter: makeExtraFilter(serie),
			perc_cats_x: percCats(custom.perc_cats_x) || '',
			perc: custom.perc || '',
			bubble: custom.bubble || '',
			add_string: custom.add_string || '',
			pre_string: custom.pre_string || '',
			ratio: custom.ratio || '',
			x_numeric: custom.x_numeric || '',
			benchmark_role: custom.benchmark_role || '',
			custom_order: custom.custom_order || '',
			no_zero: custom.no_zero || '',
			n: custom.n || '',
			custom_role_id: custom.custom_role_id || '',
			stack: custom.stack || '',
			tooltip: custom.tooltip || '',
			project_id: custom.project_id || '',
			project_name: custom.project_name || '',
			date_format: vars.chart_type == 1 ? custom.date_format || '' : '',
		};

		if (custom.x_tags != '' && custom.x_tags != null) {
			request.x_tags = custom.x_tags;
		}
		if (custom.filter_tags != '' && custom.filter_tags != null) {
			request.filter_tags = Array.isArray(custom.filter_tags)
				? custom.filter_tags.join(',')
				: custom.filter_tags;
		}
		if (custom.survey_id != '' && custom.survey_id != null) {
			request.survey_id = custom.survey_id;
		}
		// timestamp
		request.timestamp = Date.now();
		//let signature = Object.keys(request).map(k => request[k]).join('|');

		// try {
		//     request.signature =  HmacSHA512(signature, secret);
		// } catch(e) {}

		//  if (vars.cacheRefresh) request.redis = 'false';

		return (
			axios
				.post(
					'/api/backendbauer',
					objectToFormData({
						token: this.token,
						useRedis: !vars.cacheRefresh,
						isAsync: vars.isAsync,
						data: request,
					}),
					config
				)
				// return axios.get('https://charts.mopinion.com/data?'+makeQueryString(request), {...config})
				//.then(r =>  r.data)
				.then(r => intoConsumableData(r.data, vars.serieIndex))
				.catch(e => e)
		);
	},
};

function getQueryParameters(str) {
	if (typeof str === 'undefined' ? 'undefined' : typeof str == 'object') {
		return str;
	} else if (typeof str !== 'undefined') {
		var obj = str
			.replace(/(^\?)/, '')
			.split('&')
			.map(
				function (n) {
					return (n = n.split('=')), (this[n[0]] = n[1]), this;
				}.bind({})
			)[0];
		delete obj[''];
		return obj;
	} else {
		return '';
	}
}

function makeQueryString(obj) {
	var str = [];
	for (var p in obj) {
		if (obj.hasOwnProperty(p)) {
			if (typeof obj[p] == 'undefined' || obj[p] == undefined) {
				obj[p] = '';
			}
			str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
		}
	}
	return str.join('&');
}

function percCats(perc_cats) {
	try {
		if (
			perc_cats != '' &&
			(typeof perc_cats === 'undefined' ? 'undefined' : typeof perc_cats) !== 'object'
		) {
			perc_cats = JSON.parse(perc_cats);
		}
		if ((typeof perc_cats === 'undefined' ? 'undefined' : typeof perc_cats) == 'object') {
			perc_cats = decodeCharacters(perc_cats.join('|'));
		}
	} catch (e) {}
	return perc_cats;
}
function makeFilter({ x = 0, y }) {
	return `fv.data_field_id:${x}|fv2.data_field_id:${y}`;
}

function intoConsumableData(response, serieIndex) {
	let serieData = response.data;
	let serieName = decodeURI(response.y_field_name);
	let categories = response.categories;

	if ('custom_series' in response) {
		serieData = response.custom_series.data;
		serieName = decodeURI(response.custom_series.name);
	}

	let newSeries = {
		data: serieData,
		name: serieName,
		series_order: serieIndex,
		color: response.color || '',
	};

	if (response.hasOwnProperty('series_order')) {
		newSeries.series_order = response.series_order;
	}
	if (
		response.hasOwnProperty('custom_series') &&
		response.custom_series.hasOwnProperty('stack')
	) {
		newSeries.stack = response.custom_series.stack;
	}
	if (
		response.hasOwnProperty('custom_series') &&
		response.custom_series.hasOwnProperty('link')
	) {
		newSeries.link = response.custom_series.link;
	}

	return { series: newSeries, categories: categories, response: response };
}
