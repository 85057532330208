import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CategoryIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<path
				fill="currentColor"
				id="XMLID_63_"
				d="M16.2,5.8v4.4H3.8V5.8H16.2 M17.1,4H2.9C2.4,4,2,4.4,2,4.9V11c0,0.5,0.4,0.9,0.9,0.9h14.2
				c0.5,0,0.9-0.4,0.9-0.9V4.9C18,4.4,17.6,4,17.1,4L17.1,4z M36.2,5.8v4.4H23.8V5.8H36.2 M37.1,4H22.9C22.4,4,22,4.4,22,4.9V11
				c0,0.5,0.4,0.9,0.9,0.9h14.2c0.5,0,0.9-0.4,0.9-0.9V4.9C38,4.4,37.6,4,37.1,4L37.1,4z M36.2,17.8v4.4H23.8v-4.4H36.2 M37.1,16H22.9
				c-0.5,0-0.9,0.4-0.9,0.9v6.2c0,0.5,0.4,0.9,0.9,0.9h14.2c0.5,0,0.9-0.4,0.9-0.9v-6.2C38,16.4,37.6,16,37.1,16L37.1,16z M16.2,29.8
				v4.4H3.8v-4.4H16.2 M17.1,28.1H2.9C2.4,28.1,2,28.5,2,29v6.2C2,35.6,2.4,36,2.9,36h14.2c0.5,0,0.9-0.4,0.9-0.9V29
				C18,28.5,17.6,28.1,17.1,28.1L17.1,28.1z M36.2,29.8v4.4H23.8v-4.4H36.2 M37.1,28.1H22.9c-0.5,0-0.9,0.4-0.9,0.9v6.2
				c0,0.5,0.4,0.9,0.9,0.9h14.2c0.5,0,0.9-0.4,0.9-0.9V29C38,28.5,37.6,28.1,37.1,28.1L37.1,28.1z M18,16.9c0-0.5-0.4-0.9-0.9-0.9H2.9
				C2.4,16,2,16.4,2,16.9v6.2C2,23.6,2.4,24,2.9,24h14.2c0.5,0,0.9-0.4,0.9-0.9V16.9z M9,22l-1.7-1.7l0.4-0.4L9,21.2l3.2-3.2l0.4,0.4
				L9,22z"
			/>
		</SvgIcon>
	);
}
