import React, { useState, useMemo, useEffect, useRef } from 'react';

import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@/styles';
import { useAppContext } from '@/components/AppContext';
import { useDataSourceContext } from '@/components/DataSources';
import {
	ListWithSidebar,
	ListFilterProvider,
	ListControls,
	useFilteredList,
} from '@/components/FilteredLists';
import { PageHeader } from '@/components/App';
import {
	AddCard,
	EmptyState,
	ActionButton,
	constants,
	Grid,
	Loader,
} from '@/components/Layout';
import { useMjolnir } from '@/components/Ajax';
import { sortByNum, sortByAlphabet } from '@/utils';
import { useHasPermission, NoPermissionTooltip } from '@/components/Permission';
import { useOnboardingContext } from '@/components/Onboarding';
import { Pagination, usePagination } from '@/components/Pagination';

import SurveyListCard from './SurveyListCard';
import SurveyListFilters from './SurveyListFilters';
import { getFormType } from './surveyUtils';

const useStyles = makeStyles(theme => ({
	pagination: {
		marginTop: theme.spacing(3),
	},
}));

export default function SurveyList() {
	const {
		app,
		app: { session },
	} = useAppContext();
	const hasEditPermission = useHasPermission();
	const onboarding = useOnboardingContext();
	const history = useHistory();
	const classes = useStyles();

	const filterState = useFilteredList({
		filters: {},
		other: {},
	});
	const { filters, sort, order, other, hasFilters } = filterState;

	const [deploymentsResponse, isLoading, error, loadData] = useMjolnir(
		`/api/1/pastease/${app.domain}/${app.organisations.current.org_id}`
	);

	const { t } = useTranslation();
	const { datasource } = useDataSourceContext();
	const [filtersOpen, setFiltersOpen] = useState(
		session.onboarding_trial
			? false
			: window.innerWidth > constants.filterDrawerBreakpoint
	);

	const surveysInDeployments = useMemo(() => {
		return datasource.forms.asArray.reduce((surveys, currentSurvey) => {
			const deploymentsWithSurvey = (deploymentsResponse?.deployments ?? []).filter(
				currentDeployment => {
					try {
						return JSON.stringify(currentDeployment).includes(currentSurvey.survey_key);
					} catch (e) {
						return false;
					}
				}
			);

			const deployments = deploymentsWithSurvey.map(currentDeployment => ({
				name: currentDeployment.name,
				id: currentDeployment.id,
			}));
			const triggers = deploymentsWithSurvey.flatMap(currentDeployment => {
				return currentDeployment.rules
					.filter(rule => rule.then?.[0]?.args.includes(currentSurvey.survey_key))
					.flatMap(rule => rule.if.map(ifRule => ifRule.trigger));
			});

			surveys[currentSurvey.survey_key] = {
				deployments,
				triggers,
			};

			return surveys;
		}, {});
	}, [deploymentsResponse, datasource.forms.asArray]);

	const filteredSurveys = useMemo(() => {
		const surveyConfig =
			datasource.forms.asArray.length > 0
				? datasource.forms.asArray.filter(item => {
						const map = JSON.parse(item.map) || {};
						const trigger = JSON.parse(item.trigger) || {};
						const filterSearch = filters.search ? filters.search.toLowerCase() : false;
						const sdkFormType =
							item.campaign === 'sdk'
								? item.survey_format === 'conversational' || item.sdk_type === 'webview'
									? 'sdk_webview'
									: 'sdk_native'
								: null;

						const searchFilterPassed = filterSearch
							? (item?.name.toLowerCase() ?? '').includes(filterSearch)
							: true;

						const surveyFormatPassed =
							filters.survey_format && item.survey_format
								? filters.survey_format === item.survey_format
								: true;

						const channelFilterPassed = filters.channel
							? filters.channel.includes(sdkFormType ?? item.campaign)
							: true;

						const displayTypePassed = filters.display_type
							? filters.display_type.includes(getFormType(trigger, item.modal).type)
							: true;

						const inDeploymentsPassed = filters.in_deployments
							? surveysInDeployments[item.survey_key]?.deployments?.find(deployment =>
									filters.in_deployments?.includes(deployment.id)
							  )
							: true;

						const formLockPassed = filters.editable
							? filters.editable === (map.locked ? 'no' : 'yes')
							: true;

						return (
							searchFilterPassed &&
							surveyFormatPassed &&
							channelFilterPassed &&
							displayTypePassed &&
							inDeploymentsPassed &&
							formLockPassed
						);
				  })
				: datasource.forms.asArray;

		return sort === 'name'
			? sortByAlphabet(surveyConfig, 'name', order === 'desc')
			: sortByNum(surveyConfig, 'id', order === 'desc');
	}, [filters, sort, order, datasource.forms.asArray, surveysInDeployments]);

	const { items, ...PaginationProps } = usePagination({
		items: filteredSurveys,
		base: '/data-collection/survey/list/page',
	});

	const getTrialAlert = () => {
		const webTrial = app.api.packageProductTrialDaysLeft('web');
		const emailTrial = app.api.packageProductTrialDaysLeft('email');
		const appTrial = app.api.packageProductTrialDaysLeft('sdk');

		const trialsActive = [webTrial, emailTrial, appTrial].filter(Boolean);

		const product1 = webTrial ? 'web' : emailTrial ? 'email' : 'sdk';
		const product2 = appTrial ? 'sdk' : emailTrial ? 'email' : 'web';

		if (trialsActive.length === 0) {
			return null;
		}

		if (trialsActive.length > 1) {
			return t(
				`data_collection-feedback_forms-new_form-trial_message_multiple-${product1}_${product2}`
			);
		}

		return t(`data_collection-feedback_forms-new_form-trial_message-${product1}`);
	};

	const ref = useRef(null);
	const rippleRef = useRef(null);

	useEffect(() => {
		//do a fresh fetch from the server for possible changes
		datasource.api.loadSurveys(0);
	}, []);

	useEffect(() => {
		//edgecase, prevent onboarding being stuck in trial if there is already a survey for some reason
		if (onboarding.trialStep === 1 && datasource.forms.asArray.length > 0) {
			const formId = datasource.forms.asArray[0].id;
			onboarding.dispatch({
				type: 'set_trial_step',
				payload: {
					step: 2,
					trialMenuOpen: true,
					url: `/data-collection/survey/edit/${formId}/${onboarding.channel}/detail`,
				},
			});
			history.push(`/data-collection/survey/edit/${formId}/${onboarding.channel}/detail`);
		}
	}, [onboarding.trialStep, datasource.forms.asArray]);

	useEffect(() => {
		setTimeout(() => {
			if (!onboarding.trialMenuOpen) {
				ref.current?.focus();
			}
		}, 500);
	}, [onboarding.trialMenuOpen]);

	return (
		<ListFilterProvider
			{...filterState}
			singleFilters={['survey_format', 'form_status', 'editable', 'search']}
			multiFilters={['channel', 'display_type', 'in_deployments']}
			filterLabels={{
				survey_format: {
					label: t('Type'),
					form: t('Standard'),
					conversational: t('Conversational'),
				},
				channel: {
					label: t('Channel'),
					web: t('Web'),
					email: t('Email'),
					sdk_native: t('In-app | native'),
					sdk_webview: t('In-app | webview'),
				},
				display_type: {
					label: t('Display type'),
					modal: t('Modal'),
					slide: t('Slide-in'),
					embed: t('Embedded'),
				},
				in_deployments: {
					label: t('data_collection-survey_list-in_deployments_filter-label'),
					...(deploymentsResponse?.deployments ?? []).reduce((acc, deployment) => {
						acc[deployment.id] = deployment.name;
						return acc;
					}, {}),
				},
				editable: {
					label: t('Editable'),
					yes: t('Is editable'),
					no: t('Is locked'),
				},
			}}
		>
			<PageHeader title={t`Feedback forms`} />
			<ListWithSidebar
				drawerContent={
					<SurveyListFilters deployments={deploymentsResponse.deployments} />
				}
				open={filtersOpen}
				onClose={() => setFiltersOpen(false)}
			>
				<ListControls
					sortOptions={[
						{ label: t('Form creation: first to last'), value: 'id-asc' },
						{ label: t('Form creation: last to first'), value: 'id-desc' },
						{ label: t('Form name: A to Z'), value: 'name-asc' },
						{ label: t('Form name: Z to A'), value: 'name-desc' },
					]}
					noFilters={session.onboarding_trial}
					toggleFilterDrawer={() => setFiltersOpen(prev => !prev)}
				/>
				{datasource.stale.surveys?.loading && items.length === 0 ? (
					<Loader empty />
				) : (
					<>
						{items.length > 0 && (
							<>
								<Grid
									container
									spacing={3}
								>
									<Grid
										item
										xs={6}
										sm={4}
										md={3}
									>
										<NoPermissionTooltip>
											<AddCard
												text={t`data_collection-feedback_forms-new_form`}
												to="/data-collection/survey/edit/new"
												maxReached={datasource.surveyCount >= app.package.surveys}
												subject={t`feedback forms`}
												disabled={!hasEditPermission}
												dataTestElement="survey-list-create-new"
												alert={getTrialAlert()}
											/>
										</NoPermissionTooltip>
									</Grid>
									{items.map((form, index) => {
										return (
											<Grid
												item
												xs={6}
												sm={4}
												md={3}
												key={form.id}
											>
												<SurveyListCard
													{...form}
													deployments={surveysInDeployments[form.survey_key]?.deployments}
													triggers={surveysInDeployments[form.survey_key]?.triggers}
												/>
											</Grid>
										);
									})}
								</Grid>
								<Pagination
									{...PaginationProps}
									className={classes.pagination}
									paper
								/>
							</>
						)}
						{items.length === 0 && (
							<EmptyState
								image={
									onboarding.trialStep > -1 ? (
										<img src="/assets/img/r/emptystate/feedback_forms_still.png" />
									) : (
										<img src="/assets/img/r/emptystate/feedback_forms.gif" />
									)
								}
								primary={
									hasFilters
										? t`data_collection-feedback_forms-emptystate_filter-title`
										: t`data_collection-feedback_forms-emptystate_default-title`
								}
								secondary={
									hasFilters
										? t`data_collection-feedback_forms-emptystate_filter-text`
										: t`data_collection-feedback_forms-emptystate_default-text`
								}
								action={
									!hasFilters && (
										<NoPermissionTooltip>
											<ActionButton
												id="create-survey"
												ref={ref}
												variant="contained"
												color="primary"
												to="/data-collection/survey/edit/new"
												link
												disabled={
													!hasEditPermission ||
													datasource.surveyCount >= app.package.surveys ||
													(onboarding.trialStep > -1 && onboarding.trialMenuOpen)
												}
												onFocus={() => {
													if (app.session.onboarding_trial) {
														rippleRef.current.pulsate();
													}
												}}
												onBlur={event => {
													if (app.session.onboarding_trial) {
														rippleRef.current?.stop(event);
													}
												}}
												data-test-element="survey-list-create-new"
												data-track-event={
													app.session.onboarding_trial
														? `onboarding_trial_step_create_${onboarding.channel}_form`
														: null
												}
											>
												{app.session.onboarding_trial && onboarding.channel === 'sdk'
													? t`data_collection-feedback_forms-emptystate_default-button_create_app_form`
													: t`data_collection-feedback_forms-emptystate_default-button_create_form`}
												<TouchRipple
													ref={rippleRef}
													center={true}
												/>
											</ActionButton>
										</NoPermissionTooltip>
									)
								}
							/>
						)}
					</>
				)}
			</ListWithSidebar>
		</ListFilterProvider>
	);
}
