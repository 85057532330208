import React from 'react';

import { makeStyles } from '@/styles';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useImmer } from 'use-immer';

import { useAppContext } from '@/components/AppContext';
import {
	TextField,
	Grid,
	AjaxFormDialog,
	Typography,
	Box,
	Link,
} from '@/components/Layout';
import { ValidatedField, ruleTests } from '@/components/Validation';
import { useEFM } from '@/components/Ajax';
import { PermissionGroupAutocomplete } from '@/components/Permission';
import DepartmentSelect from './DepartmentSelect';

const useStyles = makeStyles(theme => ({
	fillWidth: {
		width: '100%',
	},
	withoutLabel: {
		marginTop: theme.spacing(2),
	},
	info: {
		backgroundColor: theme.palette.primary.main,
	},
}));

export default function UserAdditDialog({
	user,
	additOpen,
	setAddit,
	onSuccess = () => {},
}) {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();

	const [state, setState] = useImmer({
		id: user ? user.id : '',
		firstname: user ? user.firstname : '',
		lastname: user ? user.lastname : '',
		level: user ? user.level : 7,
		email: user ? user.email : '',
		department: user ? user.department : '',
		firstname_valid: user ? user.firstname : false,
		lastname_valid: user ? user.lastname : false,
		email_valid: user ? user.email : false,
		permission_groups: user ? user.permission_groups : [],
		permission_groups_valid: user?.permission_groups?.length > 0 ?? false,
	});

	const waitCheckAllowed = !additOpen || !ruleTests.email(state.email);
	const [emailValid] = useEFM(
		'/account/ajax/check-allowed',
		{ email: state.email },
		waitCheckAllowed
	);

	function updateImmer(value, target) {
		setState(draft => {
			draft[target] = value;
		});
	}

	function resetDialogInput() {
		setState(draft => {
			draft.id = '';
			draft.firstname = '';
			draft.lastname = '';
			draft.email = '';
			draft.department = '';
			draft.firstname_valid = false;
			draft.lastname_valid = false;
			draft.permission_groups = [];
			draft.permission_groups_valid = false;
		});
	}

	return (
		<AjaxFormDialog
			title={
				user
					? t`settings-user_management-add_edit_dialog-title_user_editted`
					: t`settings-user_management-add_edit_dialog-title_user_added`
			}
			open={additOpen}
			onClose={() => {
				setAddit(false);
				resetDialogInput();
			}}
			dataTestElement="user-addit-dialog-submit-button"
			dataTrackEvent={user ? 'settings_user_edited' : 'settings_user_created'}
			dataTrackEventProps={{
				new_user_email: state.email,
			}}
			AjaxFormProps={{
				url: !user ? '/application/users/add' : '/application/users/edit',
				data: {
					id: state.id,
					firstname: state.firstname,
					lastname: state.lastname,
					email: state.email,
					state: 'send',
					department: state.department,
					permission_groups: state.permission_groups,
				},
				resetClickedAfterSubmit: true,
				valid: () =>
					state.firstname_valid &&
					state.lastname_valid &&
					emailValid.message &&
					state.permission_groups_valid,
				onSuccess: response => {
					if (response.code === 400) {
						enqueueSnackbar(
							t`settings-user_management-add_edit_dialog-snackbar_error_exists`
						);
					}
					if (response.code === 200) {
						setAddit(false);
						enqueueSnackbar(
							user
								? t`settings-user_management-add_edit_dialog-snackbar_user_edited`
								: t`settings-user_management-add_edit_dialog-snackbar_user_created`
						);
						app.api.getUsers();
						onSuccess();
						resetDialogInput();
					}
				},
				onFail: r => {
					enqueueSnackbar(t`settings-user_management-add_edit_dialog-snackbar error`);
				},
			}}
			render={({ clicked }) => (
				<Grid
					container
					direction="column"
				>
					<Grid
						container
						spacing={3}
					>
						<Grid
							item
							xs={6}
							className={classes.fillWidth}
						>
							<ValidatedField
								component={TextField}
								id="user-firstname"
								fullWidth
								label={t`settings-user_management-add_edit_dialog-input_label-first_name`}
								value={state.firstname}
								rules={[['required', true]]}
								showErrors={clicked}
								errorMessage={t`settings-user_management-add_edit_dialog-error_label-first_name`}
								onValidChange={e => updateImmer(e, 'firstname_valid')}
								onChange={e => {
									updateImmer(e.currentTarget.value, 'firstname');
								}}
								data-test-element="user-addit-dialog-firstname"
							/>
						</Grid>
						<Grid
							item
							xs={6}
							className={classes.fillWidth}
						>
							<ValidatedField
								component={TextField}
								id="user-lastname"
								fullWidth
								label={t`settings-user_management-add_edit_dialog-input_label-last_name`}
								value={state.lastname}
								rules={[['required', true]]}
								showErrors={clicked}
								errorMessage={t`settings-user_management-add_edit_dialog-error_label-last_name`}
								onValidChange={e => updateImmer(e, 'lastname_valid')}
								onChange={e => {
									updateImmer(e.currentTarget.value, 'lastname');
								}}
								data-test-element="user-addit-dialog-lastname"
							/>
						</Grid>

						<Grid
							item
							xs={12}
							className={classes.fillWidth}
						>
							<ValidatedField
								component={TextField}
								id="user-email"
								fullWidth
								label={t`settings-user_management-add_edit_dialog-input_label-email`}
								value={state.email}
								rules={{
									email: true,
									custom_exists: () => !emailValid.exists,
									custom_allowed: () => emailValid.message,
									custom_business: () => !emailValid.business,
								}}
								messages={{
									email: t`settings-user_management-add_edit_dialog-error_label-email_incorrect`,
									custom_exists: t`settings-user_management-add_edit_dialog-error_label-email_exists`,
									custom_allowed: t`settings-user_management-add_edit_dialog-error_label-email_not_allowed`,
									custom_business: t`settings-user_management-add_edit_dialog-error_label-email_business`,
								}}
								showErrors={clicked}
								onChange={e => updateImmer(e.currentTarget.value.trim(), 'email')}
								data-test-element="user-addit-dialog-email"
							/>
						</Grid>

						<Grid
							item
							xs={12}
							className={classes.fillWidth}
						>
							<DepartmentSelect
								label={t`settings-users-add_edit_dialog-department-label`}
								value={state.department}
								onChange={e => updateImmer(e.target.value, 'department')}
							/>
						</Grid>

						<Grid
							item
							xs={12}
							className={classes.fillWidth}
						>
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								mt={2}
							>
								<Typography variant="subtitle">{t`settings-users-add_edit_dialog-access_groups-title`}</Typography>
								<Link
									tooltip={t`settings-users-add_edit_dialog-access_groups-helper-tooltip`}
								>
									{t(`settings-users-add_edit_dialog-access_groups-helper-label`)}
								</Link>
							</Box>

							<ValidatedField
								component={PermissionGroupAutocomplete}
								label={t`settings-users-add_edit_dialog-access_groups-label`}
								value={state.permission_groups}
								multiple
								onChange={(e, value) =>
									updateImmer(
										value?.map(group => group.uuid),
										'permission_groups'
									)
								}
								showErrors={clicked}
								rules={{
									required: true,
								}}
								messagess={{
									required: t`settings-users-add_edit_dialog-access_groups-error`,
								}}
								onValidChange={valid => updateImmer(valid, 'permission_groups_valid')}
							/>
						</Grid>
					</Grid>
				</Grid>
			)}
			submit={
				user
					? t`settings-user_management-add_edit_dialog-submit_button_edit`
					: t`settings-user_management-add_edit_dialog-submit_button_create`
			}
		/>
	);
}
