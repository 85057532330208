import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '@/hooks';
import { makeStyles } from '@material-ui/core/styles';
import { useHasPermission, NoPermissionTooltip } from '@/components/Permission';

import {
	ActionButton,
	Box,
	Collapse,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	ListSubheader,
	Link,
	Tooltip,
} from '@/components/Layout';
import { useDataSourceContext, DataFieldIcon } from '../DataSources';
import { FormattedDate } from '../Layout';

const useStyles = makeStyles(theme => ({
	anchor: {
		wordBreak: 'break-word',
	},
}));

function Wrap({ var_type, system_var, value = '', survey_id, campaign, ...props }) {
	const { t } = useTranslation();
	const { datasource } = useDataSourceContext();
	const classes = useStyles();
	const hasEditPermission = useHasPermission({
		module: 'data_collection',
		subject: 'feedback_forms_build',
	});
	const surveyExists = datasource.forms.byKey.hasOwnProperty(survey_id);

	switch (true) {
		case var_type == 35:
		case system_var.toLowerCase() === 'url':
		case var_type == 40:
			return (
				<Link
					href={value}
					className={classes.anchor}
					target="_blank"
					rel="noopener"
				>
					{value.replace('?trial_demo_data', '')}
				</Link>
			);

		case var_type == 7:
			return (
				<FormattedDate
					date={value}
					withTime
				/>
			);

		case var_type == 43:
			try {
				const [width, height] = value.split('x');
				return `${Math.round(Number(width))}x${Math.round(Number(height))}`;
			} catch (e) {
				return value;
			}

		case system_var === 'Survey':
			return !surveyExists ? (
				<Tooltip title={t`reporting-feedback_inbox-edit_survey_link_error_text`}>
					<span>
						<Link
							className={classes.anchor}
							component="button"
							disabled
						>
							{value}
						</Link>
					</span>
				</Tooltip>
			) : (
				<NoPermissionTooltip>
					<Link
						href={getEditUrl(survey_id, campaign)}
						className={classes.anchor}
						target="_blank"
						rel="noopener"
						component={!hasEditPermission ? 'button' : 'a'}
						disabled={!hasEditPermission}
					>
						{value}
					</Link>
				</NoPermissionTooltip>
			);

		default:
			return value;
	}
}

const getEditUrl = (survey_id, campaign) =>
	`/r/data-collection/survey/edit/${survey_id}/${campaign ? campaign : 'web'}/detail`;

function findSortedFeedbackVars(sortedFields, feedbackVars) {
	return sortedFields
		.map(sortedField => {
			return Object.values(feedbackVars).find(
				feedbackVar => feedbackVar.system_var === sortedField
			);
		})
		.filter(Boolean);
}

function sortMetaVariables(feedbackVars = {}, campaign = 'web') {
	const sdkFields = [
		'Survey',
		'Datetime',
		'App name',
		'OS',
		'SDK version',
		'Form completion percentage',
		'Viewport',
		'Device',
	];
	const defaultFields = [
		'Survey',
		'Datetime',
		'url',
		'Page title',
		'Form completion percentage',
		'Device',
		'Form trigger',
		'Viewport',
		'OS',
		'Browser',
		'User Agent',
		'Survey ID',
	];

	if (campaign === 'sdk') {
		return findSortedFeedbackVars(sdkFields, feedbackVars);
	}
	return findSortedFeedbackVars(defaultFields, feedbackVars);
}

export default function FeedbackMeta({
	technical_vars = {},
	meta_vars = {},
	campaign,
	survey_id,
	...props
}) {
	const { t } = useTranslation();
	const [isExpanded, setIsExpanded] = useLocalStorage('feedbackMetaOpen', false);
	const preExpansionItemCount = 6;
	const sortedMetaVariables = sortMetaVariables(
		{ ...meta_vars, ...technical_vars },
		campaign
	);

	const displayedVariables = sortedMetaVariables.slice(0, preExpansionItemCount);
	const hiddenVariables = sortedMetaVariables.slice(preExpansionItemCount);

	const handleExpandClick = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<>
			<List dense>
				<ListSubheader color="inherit">
					{t`reporting-feedback_inbox-feedback_meta_data_title`}
				</ListSubheader>
				{displayedVariables.map(metaVar => {
					return (
						<ListItem
							alignItems="flex-start"
							key={`${metaVar.id}-${metaVar.system_var}`}
						>
							<ListItemIcon>
								<DataFieldIcon {...metaVar} />
							</ListItemIcon>
							<ListItemText
								primary={
									<Wrap
										survey_id={survey_id}
										campaign={campaign}
										{...metaVar}
									/>
								}
								secondary={
									metaVar.title === 'Survey'
										? t`reporting-feedback_inbox_label_survey`
										: metaVar.title
								}
							/>
						</ListItem>
					);
				})}
				<Collapse in={isExpanded}>
					{hiddenVariables.map(metaVar => {
						return (
							<ListItem
								alignItems="flex-start"
								key={`${metaVar.id}-${metaVar.system_var}`}
							>
								<ListItemIcon>
									<DataFieldIcon {...metaVar} />
								</ListItemIcon>
								<ListItemText
									primary={
										<Wrap
											survey_id={survey_id}
											campaign={campaign}
											{...metaVar}
										/>
									}
									secondary={
										metaVar.title === 'Survey'
											? t`reporting-feedback_inbox_label_survey`
											: metaVar.title
									}
								/>
							</ListItem>
						);
					})}
				</Collapse>
			</List>
			{sortedMetaVariables.length > preExpansionItemCount && (
				<Box p={2}>
					<ActionButton
						action={isExpanded ? 'expand_less' : 'expand_more'}
						dataTrackEvent={
							isExpanded
								? 'feedback_details_metadata_show_less'
								: 'feedback_details_metadata_show_more'
						}
						onClick={handleExpandClick}
						color="primary"
						size="small"
					>
						{isExpanded
							? t`reporting-feedback_inbox-feedback_details_metadata_show_less`
							: t`reporting-feedback_inbox-feedback_details_metadata_show_more`}
					</ActionButton>
				</Box>
			)}
		</>
	);
}
