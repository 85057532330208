import React from 'react';

import WelcomeIcon from '@material-ui/icons/EmojiPeople';

import { useTranslation } from 'react-i18next';

import { Route, PageHeader } from '@/components/App';
import { useAppContext } from '@/components/AppContext';
import { PermissionModuleContextProvider } from './PermissionModuleContext';
import { PermissionSubjectContextProvider } from './PermissionSubjectContext';
import HasPermission from './HasPermission';
import NoPermissionEmptyState from './NoPermissionEmptyState';
import useHandleRedirectOnce from './useHandleRedirectOnce';
import useIsRestricted from './useIsRestricted';

export default function PermissionProtectedRoute({
	children,
	permission = 'view',
	module,
	subject,
	...props
}) {
	const { t } = useTranslation();

	const { app } = useAppContext();
	const restricted = useIsRestricted();
	useHandleRedirectOnce({
		module,
		subject,
	});

	const ssoRestrictedInitialLogin =
		restricted &&
		app.users.current.meta?.created_from_saml &&
		app.session.initial_login_extra_user;

	const subjectForTitle = Array.isArray(subject) ? subject[0] : subject;

	return (
		<Route {...props}>
			<PermissionModuleContextProvider module={module}>
				<PermissionSubjectContextProvider subject={subject}>
					<HasPermission permission={permission}>
						{hasPermission =>
							hasPermission ? (
								children
							) : (
								<>
									<PageHeader
										onBack={history => history.goBack()}
										title={t(
											`app_general-no_permission_page_title-${module}-${subjectForTitle}`
										)}
										showViewPermissionIndicator={false}
									/>
									<NoPermissionEmptyState
										key={module + subject + permission}
										image={
											ssoRestrictedInitialLogin ? (
												<img src="/assets/img/r/emptystate/sso_restricted.gif" />
											) : null
										}
										primary={
											ssoRestrictedInitialLogin
												? t`app_general-sso_restricted_empty_state-title`
												: null
										}
										secondary={
											ssoRestrictedInitialLogin
												? t`app_general-sso_restricted_empty_state-subtitle`
												: null
										}
									/>
								</>
							)
						}
					</HasPermission>
				</PermissionSubjectContextProvider>
			</PermissionModuleContextProvider>
		</Route>
	);
}
