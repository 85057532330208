import React, { Fragment, useState, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { RenderConditional, ActionButton } from '../Layout';

function getSvgImg(svg) {
	const xml = new XMLSerializer().serializeToString(svg);
	return `data:image/svg+xml;base64,${btoa(xml)}`;
}

function downloadSvg(wrapper, fileName) {
	const evt = new MouseEvent('click', {
		view: window,
		bubbles: false,
		cancelable: true,
	});
	const a = document.createElement('a');
	a.setAttribute('download', fileName);
	a.setAttribute('href', getSvgImg(wrapper.querySelector('svg')));
	a.setAttribute('target', '_blank');
	a.dispatchEvent(evt);
}

export default function QrCodeButtonWithPopover({ value, tooltip, fileName }) {
	const { t } = useTranslation();
	const [target, setTarget] = useState(null);
	const wrappingRef = useRef(null);
	const theme = useTheme();
	return (
		<Fragment>
			<RenderConditional
				condition={tooltip}
				component={Tooltip}
				title={tooltip}
			>
				<IconButton onClick={e => setTarget(e.target)}>
					<QRCode
						size={18}
						fgColor={theme.palette.text.secondary}
						bgColor="transparent"
						renderAs="svg"
						value={value}
					/>
				</IconButton>
			</RenderConditional>

			<Popover
				open={Boolean(target)}
				anchorEl={target}
				onClose={() => setTarget(null)}
			>
				<Box
					p={2}
					ref={wrappingRef}
				>
					<QRCode
						size={128}
						bgColor="transparent"
						renderAs="svg"
						value={value}
					/>
					<Box mt={1}>
						<ActionButton
							action="download"
							label={t`Download`}
							onClick={() => downloadSvg(wrappingRef.current, fileName)}
						/>
					</Box>
				</Box>
			</Popover>
		</Fragment>
	);
}
