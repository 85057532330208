import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import ColorPickerWithPopper from './ColorPickerWithPopper';

const useStyles = makeStyles(theme => ({
	inherit: {
		color: props => (props.color ? theme.palette.getContrastText(props.color) : ''),
	},
	button: {
		backgroundColor: props => (props.color ? props.color : ''),
	},
}));

export default function ColorPickerIconButton({
	onChange,
	color,
	iconSize = 'small',
	...props
}) {
	const [anchor, setAnchor] = useState(null);
	const classes = useStyles({ color });
	return (
		<Fragment>
			<IconButton
				onClick={e => setAnchor(e.target)}
				className={classes.button}
				{...props}
			>
				<FormatColorFillIcon
					size={iconSize}
					className={classes.inherit}
				/>
			</IconButton>

			<ColorPickerWithPopper
				anchorEl={anchor}
				open={Boolean(anchor)}
				color={color}
				onChange={onChange}
				onClose={e => setAnchor(null)}
			/>
		</Fragment>
	);
}
