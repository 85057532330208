import { tryParse } from '@/utils';
import { getFormType } from '@/components/Survey/surveyUtils';

export function mapSurveySettings(surveyKey, survey = {}, blocks = []) {
	const dataLoaded = Object.keys(survey).length > 0;

	const surveyTrigger = tryParse(survey.trigger);
	const surveyMap = tryParse(survey.map);
	const languages = tryParse(survey.translation);

	const formType = getFormType(surveyTrigger ?? {}, survey.modal ?? 0).type;
	const buttonSettings = getButtonSettings(surveyTrigger);
	const buttonSettingsMobile = getButtonSettingsMobile(surveyTrigger);

	return {
		survey: survey,
		surveyId: survey.id,
		blocks: blocks,
		surveyKey: surveyKey,
		dataLoaded: dataLoaded,
		formLocked: surveyMap.locked == 'true',
		formType: formType,
		campaign: survey.campaign ?? 'web',
		languages: languages,
		buttonSettings: buttonSettings,
		buttonSettingsMobile: buttonSettingsMobile,
		slidePercentage: surveyTrigger?.slider?.max_height_perc ?? 100,
	};
}

export function getButtonSettings(trigger) {
	try {
		if (trigger.slider?.style === 'slide') {
			return {
				position: trigger.slider?.position ?? 'bottom-right',
				style: trigger.buttonStyleWeb ?? 'label',
			};
		}
		return {
			position: trigger?.button?.position ?? 'right',
			style: trigger.buttonStyleWeb ?? 'label',
		};
	} catch (e) {
		return {
			position: 'right',
			style: 'label',
		};
	}
}

export function getButtonSettingsMobile(trigger) {
	try {
		if (trigger.slider?.style === 'slide') {
			return {
				position: trigger.slider?.position_mobile ?? 'bottom-right',
				style: trigger.buttonStyleMobile ?? 'label',
			};
		}
		return {
			position: trigger?.button?.position_mobile ?? 'right',
			style: trigger.buttonStyleMobile ?? 'label',
		};
	} catch (e) {
		return {
			position: 'right',
			style: 'label',
		};
	}
}
