import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { useImmer } from 'use-immer';

import { useSurveyEditorContext } from '@/components/Survey/SurveyEditorContext';
import { useSurveyDesignContext } from '@/components/Survey/SurveyDesignContext';

import useGetSurveySettings from './useGetSurveySettings';
import SurveyPreview from './SurveyPreview';
import { mapSurveySettings } from './previewUtils';

const useStyles = makeStyles(theme => ({
	content: {
		backgroundColor: theme.palette.background.default,
	},
	noPadding: {
		padding: '0!important',
		height: '100vh',
	},
}));

export default function PreviewDialog({ open, onClose = () => {} }) {
	const classes = useStyles();

	const {
		editor: { state, api },
	} = useSurveyEditorContext();

	const {
		design: { designState },
	} = useSurveyDesignContext();

	const surveyKey = state.properties.survey_key;

	const [data] = useGetSurveySettings({
		surveyKey,
		wait: !open,
		onSuccess: response => {
			setSurveySettings(
				draft => (draft = mapSurveySettings(surveyKey, response.survey, response.blocks))
			);
		},
	});

	const [positionInTime, setPositionInTime] = useState(0);
	const [pendingChanges, setPendingChanges] = useState(false);

	const [surveySettings, setSurveySettings] = useImmer({});

	useEffect(() => {
		if (open) {
			setPositionInTime(state.timeline.past.length);
		}
	}, [open]);

	function updatePreviewSettings({ key, type }, value) {
		setPendingChanges(true);
		if (!type) {
			setSurveySettings(draft => {
				draft[key] = value;
			});
		} else {
			setSurveySettings(draft => {
				draft[key][type] = value;
			});
		}
	}

	function handleClose(save) {
		if (save) {
			onClose(true);
		} else {
			onClose(false, pendingChanges ? positionInTime : null);
		}
		setPendingChanges(false);
		setSurveySettings(draft => ({}));
	}

	function updateSurveySettings(value, update) {
		switch (value) {
			case 'modal':
				updatePreviewSettings({ key: 'formType' }, 'modal');
				api.updateSurvey('properties.modal', 1);
				api.updateSurvey('properties.trigger.slider.type', '');
				break;
			case 'slide':
				updatePreviewSettings({ key: 'formType' }, 'slide');
				api.updateSurvey('properties.modal', 1);
				api.updateSurvey('properties.trigger.slider.type', 'slide');

				if (typeof state.properties?.trigger?.slider?.position === 'undefined') {
					api.updateSurvey('properties.trigger.slider.position', 'bottom-right');
				}
				break;
			case 'embed':
				updatePreviewSettings({ key: 'formType' }, 'embed');
				api.updateSurvey('properties.modal', 0);
				break;
			case 'buttonStyleWeb':
				updatePreviewSettings({ key: 'buttonSettings', type: 'style' }, update);
				api.updateSurvey('properties.trigger.buttonStyleWeb', update);
				break;
			case 'buttonStyleMobile':
				updatePreviewSettings({ key: 'buttonSettingsMobile', type: 'style' }, update);
				api.updateSurvey('properties.trigger.buttonStyleMobile', update);
				break;
			case 'position':
				updatePreviewSettings({ key: 'buttonSettings', type: 'position' }, update);
				api.updateSurvey('properties.trigger.button.position', update);
				api.updateSurvey('properties.trigger.slider.position', update);
				break;
			case 'position_mobile':
				updatePreviewSettings({ key: 'buttonSettingsMobile', type: 'position' }, update);
				api.updateSurvey('properties.trigger.button.position_mobile', update);
				api.updateSurvey('properties.trigger.slider.position_mobile', update);
				break;
			case 'slidePercentage':
				updatePreviewSettings({ key: 'slidePercentage' }, update);
				api.updateSurvey('properties.trigger.slider.max_height_perc', update);
				api.updateSurvey('properties.trigger.slider.max_height_perc', update);
				break;
			default:
				break;
		}
	}

	return (
		<Dialog
			open={open}
			className={classes.noPadding}
			fullWidth
			maxWidth={false}
		>
			<DialogContent className={`${classes.content} ${classes.noPadding}`}>
				<SurveyPreview
					inDialog
					surveyKeyFromProps={surveyKey}
					pendingChanges={pendingChanges}
					surveySettingsFromProps={surveySettings}
					updateSurveySettings={updateSurveySettings}
					handleClose={handleClose}
					emailWidgetDesign={designState?.widget ?? designState}
				/>
			</DialogContent>
		</Dialog>
	);
}
