import React, { createContext, useContext } from 'react';

export const PermissionSubjectContext = createContext(null);

export function PermissionSubjectContextProvider({ children, subject }) {
	if (!subject) {
		throw new Error('PermissionSubjectContextProvider requires a subject');
	}

	return (
		<PermissionSubjectContext.Provider value={subject}>
			{children}
		</PermissionSubjectContext.Provider>
	);
}

export function usePermissionSubjectContext() {
	return useContext(PermissionSubjectContext);
}
