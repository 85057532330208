import React, { Fragment, useState } from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';

import ChartsIcon from '@material-ui/icons/MultilineChart';
import UsersIcon from '@material-ui/icons/People';
import ReportsIcon from '@material-ui/icons/Assessment';
import FormsIcon from '@material-ui/icons/Assignment';
import DeploymentsIcon from '@material-ui/icons/Publish';
import PageviewIcon from '@material-ui/icons/Pageview';

import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PackageOverviewCard from './PackageOverviewCard.js';
import HistoryCard from './HistoryCard';
import CreditCardCard from './CreditCardCard';
import { useAppContext } from '../AppContext';
import { PageHeader } from '../App';
import {
	Grid,
	Card,
	CardHeader,
	CardContent,
	Typography,
	ActionButton,
	ActionIconButton,
	ContactDialog,
	Link,
	Alert,
	KnowledgeBaseLink,
} from '../Layout';
import RemainingDaysProgress from './RemainingDaysProgress';
import UpgradeDialog from './UpgradeDialog';
import DeleteAccountDialog from './DeleteAccountDialog';
import { useHasPermission, NoPermissionTooltip } from '../Permission';

function DetailGridItem({ icon, text }) {
	return (
		<Grid
			item
			xs={4}
		>
			<Box
				mb={1}
				textAlign="center"
			>
				{icon}
			</Box>
			<Typography
				variant="body2"
				color="textSecondary"
				align="center"
			>
				{text}
			</Typography>
		</Grid>
	);
}

export default function BillingOverview() {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const { upgradeStatus } = useParams();
	const [upgrade, setUpgrade] = useState(false);
	const [contactForUpgrade, setContactForUpgrade] = useState(false);
	const [menuAnchor, setMenuAnchor] = useState(null);
	const [deleteAccount, setDeleteAccount] = useState(false);
	const [contactOwner, setContactOwner] = useState(false);
	const hasEditPermission = useHasPermission();

	return (
		<Fragment>
			<PageHeader title={t`billing_overview-page_header`} />

			{upgradeStatus === 'success' && (
				<Box mb={2}>
					<Alert
						title={t`billing_overview-upgrade_success_alert-title`}
						variant="outlined"
						severity="info"
						close
					>
						{t`billing_overview-upgrade_success_alert-content`}
					</Alert>
				</Box>
			)}
			{app.package.is_stripe_customer && !app.package.tiered_pricing && (
				<Box mb={2}>
					<Alert
						title={t`billing_overview-new_pricing_alert-title`}
						variant="outlined"
						severity="info"
					>
						<Trans
							i18nKey="billing_overview-new_pricing_alert-content"
							components={{
								readMoreLink: (
									<KnowledgeBaseLink
										variant="body2"
										verticalAlign="baseline"
									/>
								),
							}}
						/>
					</Alert>
				</Box>
			)}
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
				height="calc(100% - 64px)"
			>
				<Box>
					<Box mb={4}>
						{app.package.tiered_pricing && (
							<PackageOverviewCard setUpgrade={setUpgrade} />
						)}
						{!app.package.tiered_pricing && (
							<Card>
								<CardHeader
									title={app.package.name}
									subheader={t`Current package`}
									action={
										<Fragment>
											{app.package.is_stripe_customer && (
												<ActionButton
													action="upgrade"
													label={t`Upgrade`}
													onClick={() => setUpgrade(true)}
													dataTrackEvent="upgrade_from_billingpage"
												/>
											)}
											{!app.package.is_stripe_customer && (
												<ActionButton
													action="upgrade"
													label={t`Upgrade`}
													onClick={() => setContactForUpgrade(true)}
													dataTrackEvent="upgrade_via_contact_from_billingpage"
												/>
											)}
											{app.package.is_stripe_customer && (
												<>
													<ActionIconButton
														action="more_vert"
														tooltip={t`More options`}
														onClick={e => setMenuAnchor(e.target)}
													/>

													<Menu
														open={Boolean(menuAnchor)}
														anchorEl={menuAnchor}
														onClose={() => setMenuAnchor(null)}
														disabled={!hasEditPermission}
													>
														<MenuItem
															disabled={!hasEditPermission}
															onClick={() => setDeleteAccount(true)}
														>
															{t`Cancel account`}
														</MenuItem>
													</Menu>
												</>
											)}
										</Fragment>
									}
								/>
								<CardContent>
									<Grid
										container
										spacing={4}
									>
										<Grid
											item
											xs={12}
											sm={6}
										>
											<Grid
												container
												spacing={3}
											>
												<DetailGridItem
													icon={<ReportsIcon fontSize="large" />}
													text={`${app.package.projects} ${
														app.package.projects == 1 ? t`report` : t`reports`
													}`}
												/>
												<DetailGridItem
													icon={<UsersIcon fontSize="large" />}
													text={`${app.package.users} ${t`users`}`}
												/>
												<DetailGridItem
													icon={<ChartsIcon fontSize="large" />}
													text={`${app.package.charts} ${t`charts`}`}
												/>

												<DetailGridItem
													icon={<FormsIcon fontSize="large" />}
													text={`${app.package.surveys} ${t`feedback forms`}`}
												/>
												<DetailGridItem
													icon={<DeploymentsIcon fontSize="large" />}
													text={`${app.package.deployments} ${
														app.package.deployments == 1 ? t`deployment` : t`deployments`
													}`}
												/>
												<DetailGridItem
													icon={<PageviewIcon fontSize="large" />}
													text={`${app.package.pageviews} ${t`pageviews per month`}`}
												/>
											</Grid>
										</Grid>
										<Grid
											item
											xs={12}
											sm={6}
										>
											<Box
												display="flex"
												flexDirection="column"
												justifyContent="center"
												alignItems="center"
											>
												<RemainingDaysProgress
													start={app.package.period.start}
													end={app.package.period.end}
													totalRemaining={app.package.period.totalRemaining}
												/>
												<Typography
													align="center"
													variant="body2"
													color="textSecondary"
												>
													{' '}
													{t`remaining on your package`}
												</Typography>
											</Box>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						)}
					</Box>

					{app.package.payment_interval &&
						app.package.is_stripe_customer &&
						hasEditPermission && (
							<Grid
								container
								spacing={4}
							>
								<Grid
									item
									xs={12}
									sm={12}
									md={6}
								>
									<HistoryCard />
								</Grid>
								<Grid
									item
									xs={12}
									sm={12}
									md={6}
								>
									<CreditCardCard />
								</Grid>
							</Grid>
						)}
				</Box>

				{app.package.is_stripe_customer &&
					app.package.tiered_pricing &&
					hasEditPermission && (
						<Box mt={4}>
							<Typography>
								<Trans
									i18nKey="billing_overview-cancel_account"
									components={{
										cancelLink: <Link onClick={() => setDeleteAccount(true)} />,
									}}
								/>
							</Typography>
						</Box>
					)}
			</Box>

			<UpgradeDialog
				open={upgrade}
				onClose={() => setUpgrade(false)}
			/>
			<ContactDialog
				open={contactForUpgrade}
				onClose={() => setContactForUpgrade(false)}
				title={t`billing_overview-contact_dialog-title`}
				text={t`billing_overview-contact_dialog-text`}
				subject={t`billing_overview-contact_dialog-subject`}
				to="success@mopinion.com"
			/>
			<DeleteAccountDialog
				open={deleteAccount}
				onClose={() => setDeleteAccount(false)}
			/>
			<ContactDialog
				contactOwner
				open={contactOwner}
				onClose={() => setContactOwner(false)}
				title={t`package_overview_card-contact_owner-title`}
				text={t`package_overview_card-contact_owner-text`}
				successMessage={t`package_overview_card-contact_owner-success_message`}
			/>
		</Fragment>
	);
}
