import { useEFM } from '@/components/Ajax';

const transformHighlights = highlights => {
	const result = [];
	Object.keys(highlights).forEach(type => {
		const items = highlights[type];
		Object.keys(items).forEach(id => {
			const item = items[id];
			result.push({
				id: `${type}-${id}`,
				type,
				...item,
			});
		});
	});
	return result;
};

const transformFeedbackDetails = feedbackDetails =>
	Object.entries(feedbackDetails).map(([key, value]) => ({
		id: key,
		...value,
	}));

const splitWebsiteDataFromFeedback = feedbackDetails => {
	return feedbackDetails.reduce(
		(acc, feedback) => {
			const category =
				feedback.question_type === 'website_data' ? 'websiteData' : 'details';
			acc[category].push(feedback);

			return acc;
		},
		{ websiteData: [], details: [] }
	);
};

function useGetFeedbackDetails(feedbackId, expanded, onSuccess = () => {}) {
	const [data = {}, loading, error, loadData] = useEFM(
		`/dashboard/inbox/detail/${feedbackId}`,
		{},
		!expanded, //wait
		false,
		data => {
			if (data.highlights) {
				data.highlights = transformHighlights(data.highlights);
			}
			if (data.feedback_details) {
				const { websiteData, details } = splitWebsiteDataFromFeedback(
					transformFeedbackDetails(data.feedback_details)
				);
				data.websiteData = websiteData;
				data.feedback_details = details;
			}
			return data;
		},
		{
			onSuccess,
		}
	);
	return [data, loading, error, loadData];
}

export default useGetFeedbackDetails;
