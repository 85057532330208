import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from './Grid';
import ExpansionPanel from './ExpansionPanel';
import ExpansionPanelDetails from './ExpansionPanelDetails';
import ExpansionPanelSummary from './ExpansionPanelSummary';
import Typography from './Typography';

const useStyles = makeStyles(theme => ({
	details: {
		padding: 0,
	},
	smallMargin: {
		margin: theme.spacing(1, 0),
	},
	noMargin: {
		margin: 0,
	},
	content: {
		margin: theme.spacing(1, 0),
	},
	expanded: {
		'&$content': {
			margin: theme.spacing(2, 0),
		},
	},
}));

export default function EditorOptionExpansionPanel({
	children,
	title,
	expanded,
	onChange = () => {},
	summaryAvatar,
	summaryActions,
	disablePadding,
	...props
}) {
	const classes = useStyles();
	return (
		<ExpansionPanel
			expanded={expanded}
			onChange={onChange}
			{...props}
		>
			<ExpansionPanelSummary
				//expanded={expanded}
				expandIcon={<ExpandMoreIcon />}
				classes={{
					content: classes.content,
					expanded: classes.expanded,
					// content:summaryAvatar || summaryActions ? classes.smallMargin : '',
					// expanded:summaryAvatar || summaryActions ? classes.noMargin : ''
				}}
			>
				<Grid
					container
					alignItems="center"
				>
					{summaryAvatar && <Grid item>{summaryAvatar}</Grid>}
					<Grid
						item
						xs
					>
						<Typography variant="subtitle2">{title}</Typography>
					</Grid>
					{summaryActions && <Grid item>{summaryActions}</Grid>}
				</Grid>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails className={disablePadding ? classes.details : ''}>
				{children}
			</ExpansionPanelDetails>
		</ExpansionPanel>
	);
}
