import React, { useState, useRef, useEffect } from 'react';

export default function useTimeoutState(defaultState, time = 0) {
	const timeoutRef = useRef(null);
	const [state, setState] = useState(defaultState);

	useEffect(() => {
		timeoutRef.current = setTimeout(() => setState(defaultState), time);
		return () => clearTimeout(timeoutRef.current);
	}, [defaultState, state]);

	return [state, setState];
}
