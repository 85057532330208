import React, { createContext } from 'react';

import TrackingContext from './TrackingContext';
import useTracking from './useTracking';
import useAnalytics from './useAnalytics';

export default function TrackingProvider({ children, ...props }) {
	const trackingEvents = useTracking();
	useAnalytics();

	return (
		<TrackingContext.Provider value={trackingEvents}>{children}</TrackingContext.Provider>
	);
}
