import React, { useEffect, useState } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import QueueIcon from '@material-ui/icons/Queue';

import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router-dom';

import { NestedMenuItem, ActionButton, ActionIconButton } from '@/components/Layout';
import { useAppContext } from '@/components/AppContext';
import { useDashboard } from '@/components/Dashboards';
import { useHasPermission } from '@/components/Permission';
import { MaxChartsDialog } from '@/components/Dashboards';
import { makeStyles } from '@/styles';
import useCopyChart from './useCopyChart';
import useSaveInsightsChart from './useSaveInsightsChart';

const useStyles = makeStyles(theme => ({
	alert: {
		color: '#fff',
		marginLeft: theme.spacing(1),
	},
}));

const snackbarAutoHideDuration = 8000;

export default function CopyChartMenu({
	open,
	onClose,
	chartId,
	chart,
	series,
	chartType,
}) {
	const { t } = useTranslation();
	const classes = useStyles();
	const { app } = useAppContext();
	const hasEditPermission = useHasPermission();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const history = useHistory();
	const location = useLocation();

	const [selectedDashboard, setSelectedDashboard] = useState(null);

	const [pendingChartAction, setPendingChartAction] = useState({
		dialogOpen: false,
		dashboardName: null,
		dashboardId: null,
		chartId: null,
	});

	const {
		dashboards,
		currentDashboardId,
		dashboardApi: {
			loadDashboards,
			updateNewCharts,
			chartsOnDashboard,
			maxChartsReached,
		},
	} = useDashboard();

	const action = snackbarId => (
		<>
			<ActionButton
				onClick={() => {
					history.push(`/reporting/dashboard/${selectedDashboard}`);
					window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
					closeSnackbar(snackbarId);
				}}
				dataTrackEvent="dashboard_chart_view_copied_chart"
				className={classes.alert}
				action="nav_to"
			>
				{t`reporting-dashboard-snackbar-button_to_dashboard`}
			</ActionButton>
			<ActionIconButton
				onClick={() => closeSnackbar(snackbarId)}
				className={classes.alert}
				action="close"
			/>
		</>
	);

	const save = dashboardId => {
		if (chartId) {
			copyChart({
				post: {
					dashboardId,
					chartId,
				},
			});
		} else {
			saveChart({
				post: {
					dashboardId,
				},
			});
		}
	};

	useEffect(() => {
		if (selectedDashboard) {
			save(selectedDashboard);
		}
	}, [selectedDashboard]);

	const { postForm: copyChart } = useCopyChart({
		onSuccess: response => {
			updateNewCharts(Number(response.new_chart));

			if (currentDashboardId !== selectedDashboard) {
				enqueueSnackbar(
					t('reporting-dashboard-snackbar-chart_copied_to', {
						chartName: get(chart, 'title.text', ''),
						dashboard:
							dashboards.find(dashboard => dashboard.id == selectedDashboard)?.name ??
							`${app.projects.current.name} dashboard`,
					}),
					{
						variant: 'info',
						className: 'chartcopy',
						autoHideDuration: snackbarAutoHideDuration,
						anchorOrigin: {
							horizontal: 'center',
							vertical: 'top',
						},
						action,
					}
				);
			} else {
				enqueueSnackbar(
					t('reporting-dashboard-snackbar-chart_copied', {
						chart: get(chart, 'title.text', ''),
					}),
					{
						autoHideDuration: snackbarAutoHideDuration,
					}
				);
				window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
			}
			loadDashboards();
			onClose();
		},
	});

	const { postForm: saveChart } = useSaveInsightsChart({
		chart,
		series,
		chartType,
		dashboardId: selectedDashboard,
		onSuccess: response => {
			updateNewCharts(Number(response.chartId));

			enqueueSnackbar(
				t('reporting-dashboard-snackbar-chart_copied_to', {
					chartName: get(chart, 'title.text', ''),
					dashboard:
						dashboards.find(dashboard => dashboard.id == selectedDashboard)?.name ??
						`${app.projects.current.name} dashboard`,
				}),
				{
					variant: 'info',
					className: 'chartcopy',
					autoHideDuration: snackbarAutoHideDuration,
					anchorOrigin: {
						horizontal: 'center',
						vertical: 'top',
					},
					action,
				}
			);
			loadDashboards();
			onClose();
		},
	});

	if (dashboards.length === 0) {
		return null;
	}

	return (
		<>
			<NestedMenuItem
				leftIcon={<QueueIcon />}
				parentOpen={open}
				disabled={!hasEditPermission}
				primary={t`reporting-dashboard-copy_chart_menu-title`}
			>
				{dashboards.map((dashboard, index) => {
					return (
						<MenuItem
							key={index}
							selected={dashboard.id == currentDashboardId}
							data-track-event={
								!chartId
									? 'direct_insights_charts_copy_to_dashboard'
									: dashboard.id == currentDashboardId
									? 'dashboard_chart_copy_to_same_dashboard'
									: 'dashboard_chart_copy_to_other_dashboard'
							}
							onClick={() => {
								if (maxChartsReached(dashboard.id)) {
									setPendingChartAction({
										dialogOpen: true,
										dashboardId: dashboard.id,
										dashboardName:
											dashboard.name ??
											t('reporting-dashboard-copy_chart_menu-default_dashboard', {
												name: app.projects.current.name,
											}),
										chartId: chartId,
									});
								} else {
									setSelectedDashboard(dashboard.id);
								}
							}}
						>
							<ListItemText>
								{dashboard.name ??
									t('reporting-dashboard-copy_chart_menu-default_dashboard', {
										name: app.projects.current.name,
									})}
							</ListItemText>
						</MenuItem>
					);
				})}
			</NestedMenuItem>
			<MaxChartsDialog
				open={pendingChartAction.dialogOpen}
				chartsInDashboard={chartsOnDashboard(pendingChartAction.dashboardId)}
				dashboardName={pendingChartAction.dashboardName}
				dashboardId={pendingChartAction.dashboardId}
				onSubmit={() => {
					setSelectedDashboard(pendingChartAction.dashboardId);
				}}
				onClose={() => {
					setPendingChartAction({
						dialogOpen: false,
						dashboardId: null,
						dashboardName: null,
						chartId: null,
					});
				}}
			/>
		</>
	);
}
