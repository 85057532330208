import React, { useEffect } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { useAppContext } from '../AppContext';
import { ValidatedField } from '../Validation';

import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';

export default function OrganisationCard({
	onValidChange = () => {},
	clicked,
	disabled,
}) {
	const { t } = useTranslation();
	const {
		app: {
			organisations: { current },
		},
	} = useAppContext();

	function stateFromContext() {
		return {
			name: current.name,
			address_line1: current.address_line1 || '',
			address_line2: current.address_line2 || '',
			postal_code: current.postal_code || '',
			city: current.city || '',
			vat_number: current.vat_number || '',
		};
	}

	const [form, setForm] = useImmer(stateFromContext());
	function setField(name, value) {
		setForm(draft => {
			draft[name] = value;
		});
	}

	useEffect(() => {
		if (form.name && form.address_line1 && form.postal_code && form.city) {
			if (current.country_code !== 'nl' || form.vat_number) {
				onValidChange(true, form);
			} else {
				onValidChange(false, {});
			}
		}
	}, [form]);

	return (
		<Box>
			<Box mb={2}>
				<ValidatedField
					label={t`Organisation name`}
					fullWidth
					value={form.name}
					onChange={e => setField('name', e.currentTarget.value)}
					rules={[['required', true]]}
					showErrors={clicked}
					disabled={disabled}
				/>
			</Box>

			<Box mb={2}>
				<ValidatedField
					label={t`Address line 1`}
					fullWidth
					value={form.address_line1}
					onChange={e => setField('address_line1', e.currentTarget.value)}
					rules={[['required', true]]}
					showErrors={clicked}
					disabled={disabled}
				/>
			</Box>
			<Box mb={2}>
				<ValidatedField
					label={t`Address line 2`}
					fullWidth
					value={form.address_line2}
					onChange={e => setField('address_line2', e.currentTarget.value)}
					//rules={[ ['required', true] ]}
					//showErrors={clicked}
					disabled={disabled}
				/>
			</Box>
			<Box mb={2}>
				<Grid
					container
					spacing={3}
				>
					<Grid
						item
						xs={8}
					>
						<ValidatedField
							label={t`City`}
							fullWidth
							value={form.city}
							onChange={e => setField('city', e.currentTarget.value)}
							rules={[['required', true]]}
							showErrors={clicked}
							disabled={disabled}
						/>
					</Grid>
					<Grid
						item
						xs={4}
					>
						<ValidatedField
							label={t`Postal code`}
							fullWidth
							value={form.postal_code}
							onChange={e => setField('postal_code', e.currentTarget.value)}
							rules={[['required', true]]}
							showErrors={clicked}
							disabled={disabled}
						/>
					</Grid>
				</Grid>
			</Box>

			<Box mb={2}>
				<ValidatedField
					label={t`VAT number`}
					fullWidth
					value={form.vat_number}
					onChange={e => setField('vat_number', e.currentTarget.value)}
					{...(current.country_code === 'nl' && {
						rules: [['required', true]],
					})}
					showErrors={clicked}
					disabled={disabled}
				/>
			</Box>
		</Box>
	);
}
