import React, { useEffect } from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Tooltip from '@material-ui/core/Tooltip';
import { List, ListItem, ListItemText } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';
import { useLocation, useHistory } from 'react-router-dom';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { rnd, triggerEvent } from '@/utils';

import { AppBarActions, PageHeader, EmptyStateHandleHasFeedback } from '@/components/App';
import {
	ActionButton,
	ActionIconButton,
	KnowledgeBaseLink,
	SimpleDialog,
	Loader,
	Typography,
} from '@/components/Layout';
import DashboardChartCard from '@/components/Charts/DashboardChartCard';
import MaxChartsDialog from './MaxChartsDialog';
import DashboardQuickSelection from './DashboardQuickSelection';
import ChangesDialog from './ChangesDialog';
import { SelectionAppBar } from '@/components/SelectionToolbar';
import { useAppContext } from '@/components/AppContext';
import { useHasPermission, NoPermissionTooltip } from '@/components/Permission';
import useDashboard from './useDashboard.js';

const breakpoints = { lg: 481, xs: 480 };
const cols = { lg: 12, xs: 3 };

const ResponsiveGridLayout = WidthProvider(Responsive);

const useStyles = makeStyles(theme => ({
	close: {
		padding: theme.spacing(0.5),
	},
	gridLayout: {
		'& .react-grid-placeholder': {
			background: theme.palette.primary.main,
		},
	},
	gridItem: {
		'&.react-grid-item .react-resizable-handle': {
			visibility: 'hidden',
			pointerEvents: 'none',
			bottom: -7,
			right: -7,
			width: 14,
			height: 14,
			//border:'2px solid '+theme.palette.primary.light,
			background: theme.palette.primary.light,
			borderRadius: '100%',
			'&:after': {
				content: 'none',
			},
			transition: theme.transitions.create(['opacity', 'visibility'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			opacity: 0,
			'&.react-resizable-handle-e': {
				cursor: 'e-resize',
				right: -7,
			},
			'&.react-resizable-handle-s': {
				cursor: 's-resize',
				bottom: -7,
			},
			'&.react-resizable-handle-se': {
				bottom: -7,
				right: -7,
			},
		},
		'&.react-grid-item.allow--drag .react-resizable-handle': {
			opacity: 0.6,
			visibility: 'visible',
			pointerEvents: 'auto',
		},
		'&.react-grid-item.allow--drag:before': {
			content: '""',
			position: 'absolute',
			top: -2,
			right: -2,
			left: -2,
			bottom: -2,
			border: '1px solid ' + theme.palette.primary.light,
			opacity: 0.6,
			transition: theme.transitions.create(['opacity', 'border'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			zIndex: -1,
		},
		'&.react-grid-item.allow--drag:hover': {
			'&:before': {
				opacity: 1,
				border: '2px solid ' + theme.palette.primary.light,
			},
			'&.react-grid-item .react-resizable-handle': {
				opacity: 1,
			},
		},
	},
	fabIcon: {
		marginRight: theme.spacing(1),
	},
	emptyState: {
		whiteSpace: 'pre-line',
	},
	iconMargin: {
		marginLeft: theme.spacing(0.5),
		marginRight: theme.spacing(1),
	},
	fab: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
		zIndex: theme.zIndex.drawer + 1,
	},
	stickToAppBar: {
		position: 'absolute',
		width: '100%',
		marginLeft: theme.spacing(-3),
	},
	alert: {
		color: '#fff',
		marginLeft: theme.spacing(1),
	},
}));

function dashboardsFromCurrentProject(dashboards = [], projectId) {
	return (
		dashboards.length === 0 ||
		dashboards.every(dashboard => dashboard?.project_id == projectId)
	);
}

export default function DashboardGrid() {
	const { app } = useAppContext();
	const classes = useStyles();
	const hasEditPermission = useHasPermission();
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const theme = useTheme();

	const {
		dashboards,
		newCharts,
		layout,
		loading,
		currentDashboard,
		currentDashboardId,
		editMode,
		hasChangesToLayout,
		removedCharts,
		dashboardApi: { dispatch, maxChartsReached, chartsOnDashboard, saveDashboard },
	} = useDashboard();

	const dashboardsLoadingPostProjectSwitch =
		loading && !dashboardsFromCurrentProject(dashboards, app.projects.current.id);

	useEffect(() => {
		if (dashboards.length === 0 && !loading) {
			history.push('/reporting/list');
		}
	}, [dashboards, loading]);

	const [state, setState] = useImmer({
		resizedElement: 0,
		forceCacheRefresh: 0,
		quickLayoutAnchorEl: null,
		changesAgreeDialog: false,
		confirmLayoutDialog: false,
		maxChartsDialogOpen: false,
		confirmRemoveChart: {},
	});

	const quickLayoutOptions = [
		{
			value: 4,
			label: 'reporting-dashboard-quick_layout-three_columns',
		},
		{
			value: 6,
			label: 'reporting-dashboard-quick_layout-two_columns',
		},
		{
			value: 12,
			label: 'reporting-dashboard-quick_layout-single_column',
		},
	];

	//update url in editmode
	useEffect(() => {
		if (editMode && !location.pathname.includes('/edit')) {
			history.push(`/reporting/dashboard/${currentDashboardId}/edit`);
		} else if (!editMode && location.pathname.includes('/edit')) {
			history.push(`/reporting/dashboard/${currentDashboardId}`);
		}
	}, [editMode]);

	const forceCacheRefresh = () => {
		setState(draft => {
			draft.forceCacheRefresh++;
		});
	};

	const currentLayout = layout?.lg ?? [];

	return (
		<>
			<PageHeader
				documentTitle={
					currentDashboard?.name
						? currentDashboard.name
						: t('reporting-dashboard-pageheader-tab_title', {
								name: app.projects.current.name,
						  })
				}
				title={
					currentDashboard ? (
						<DashboardQuickSelection currentDashboard={currentDashboard} />
					) : (
						t`reporting-dashboard-pageheader-title`
					)
				}
				backTo={{
					pathname: '/reporting/dashboard/list',
					state: {
						from: 'list',
					},
				}}
				backTooltip={t`reporting-dashboard-pageheader-back_tooltip`}
			/>
			<AppBarActions>
				<>
					{editMode ? (
						<ActionButton
							size="small"
							variant="text"
							action="check"
							label={t`reporting-dashboard-button-layout_saved`}
							loading={loading}
							onClick={() => {
								saveDashboard();
							}}
						/>
					) : (
						<NoPermissionTooltip>
							<ActionButton
								size="small"
								variant="text"
								color="primary"
								action="edit"
								label={t`reporting-dashboard-button-edit_layout`}
								disabled={!hasEditPermission}
								onClick={() => {
									dispatch({
										type: 'set_edit_mode',
										payload: {
											editMode: !editMode,
										},
									});
								}}
								data-onboarding="edit-dashboard-button"
							/>
						</NoPermissionTooltip>
					)}
				</>

				<ActionIconButton
					tooltip={t`reporting-dashboard-tooltip-refresh`}
					onClick={forceCacheRefresh}
					action="refresh"
				/>
			</AppBarActions>

			{Object.keys(dashboards).length > 0 && currentLayout.length === 0 && !loading && (
				<>
					<EmptyStateHandleHasFeedback
						className={classes.emptyState}
						image={<img src={`/assets/img/r/emptystate/dashboard_no_charts.gif`} />}
						noSurveyTrackEvent="dashboard_emptystate_create_form"
						primaryNoSurvey={t`reporting-dashboard-emptystate_no_survey-title`}
						secondaryNoSurvey={t`reporting-dashboard-emptystate_no_survey-text`}
						actionNoSurvey={
							<NoPermissionTooltip>
								<ActionButton
									variant="contained"
									color="primary"
									dataTrackEvent="dashboard_emptystate_create_form"
									disabled={!hasEditPermission}
									link
									to="/data-collection/survey/edit/new"
								>
									{t`reporting-dashboard-emptystate_no_survey-button_create_form`}
								</ActionButton>
							</NoPermissionTooltip>
						}
						primaryNoFeedback={t`reporting-dashboard-emptystate_no_feedback-title`}
						secondaryNoFeedback={t`reporting-dashboard-emptystate_no_feedback-text`}
						actionNoFeedback={
							<KnowledgeBaseLink
								dataTrackEvent="dashboard_emptystate_knowledgebase"
								page="dashboard"
								variant="body1"
							>
								{t`reporting-dashboard-emptystate_no_feedback-link`}
							</KnowledgeBaseLink>
						}
						primary={t`reporting-dashboard-emptystate_default-title`}
						secondary={t`reporting-dashboard-emptystate_default-text`}
						action={
							<NoPermissionTooltip>
								<ActionButton
									variant="contained"
									color="primary"
									link
									to={'/reporting/chart/new'}
									disabled={!hasEditPermission}
								>
									{t`reporting-dashboard-emptystate_default-button`}
								</ActionButton>
							</NoPermissionTooltip>
						}
					/>
				</>
			)}

			{loading && (
				<Loader
					empty={
						Object.keys(dashboards).length === 0 || dashboardsLoadingPostProjectSwitch
					}
					appBar={
						Object.keys(dashboards).length > 0 && !dashboardsLoadingPostProjectSwitch
					}
				/>
			)}
			{!dashboardsLoadingPostProjectSwitch &&
				Object.keys(dashboards).length > 0 &&
				currentLayout.length > 0 && (
					<ResponsiveGridLayout
						className={classes.gridLayout}
						layouts={{ ...layout }}
						breakpoints={breakpoints}
						cols={cols}
						onLayoutChange={layout => {
							if (!state.quickLayoutAnchorEl) {
								dispatch({
									type: 'update_layout',
									payload: {
										layout: layout,
									},
								});
							}
						}}
						margin={[theme.spacing(3), theme.spacing(3)]}
						containerPadding={[0, 0]}
						isDraggable={true}
						isResizable={true}
						draggableHandle=".allow--drag"
						rowHeight={theme.spacing(8)}
						onResizeStop={(l, el) => {
							setState(draft => {
								draft.resizedElement = el.i;
							});
						}}
						measureBeforeMount
						isDroppable={true}
						droppingItem={{ w: 3, h: 4, i: '__dropping-elem__' }}
						resizeHandles={['se', 's', 'e']}
					>
						{currentLayout.map((chart, index) => {
							return (
								<div
									key={chart.i}
									className={`${classes.gridItem} ${editMode ? 'allow--drag' : ''}`}
								>
									<DashboardChartCard
										isNew={newCharts.includes(Number(chart.i))}
										chartId={chart.i}
										editMode={editMode}
										doResize={state.resizedElement == chart.i ? rnd() : ''}
										onRemove={chartDetails => {
											if (editMode) {
												dispatch({
													type: 'remove_from_layout',
													payload: {
														index: index,
														chart: {
															...chartDetails,
															i: chart.i,
														},
													},
												});
											} else {
												setState(draft => {
													draft.confirmRemoveChart = {
														index: index,
														chart: {
															...chartDetails,
															i: chart.i,
														},
													};
												});
											}
										}}
										onDoResize={() => {
											dispatch({
												type: 'set_edit_mode',
												payload: {
													editMode: !editMode,
												},
											});
										}}
										forceCacheRefresh={
											state.forceCacheRefresh !== 0 ? state.forceCacheRefresh : null
										}
									/>
								</div>
							);
						})}
					</ResponsiveGridLayout>
				)}

			<SelectionAppBar
				text={t`reporting-dashboard-edit_dashboard`}
				onClose={e => {
					if (hasChangesToLayout) {
						setState(draft => {
							draft.changesAgreeDialog = true;
						});
					} else {
						dispatch({
							type: 'set_edit_mode',
							payload: {
								editMode: !editMode,
							},
						});
					}
				}}
				show={editMode}
				color="default"
				fade
			>
				<ActionButton
					action="check"
					size="small"
					variant="text"
					label={t`reporting-dashboard-button-dashboard_saved`}
					loading={loading}
					data-onboarding="save-layout"
					data-track-event="dashboard_layout_edited"
					onClick={() => {
						if (removedCharts.length) {
							setState(draft => {
								draft.confirmLayoutDialog = true;
							});
						} else {
							saveDashboard();
						}
					}}
				/>

				<ActionIconButton
					tooltip={t`reporting-dashboard-tooltip-refresh2`}
					onClick={forceCacheRefresh}
					action="refresh"
				/>

				<Tooltip title={t`reporting-dashboard-tooltip-quick`}>
					<ActionIconButton
						onClick={e => {
							setState(draft => {
								draft.quickLayoutAnchorEl = e.target;
							});
						}}
					>
						<DashboardIcon />
					</ActionIconButton>
				</Tooltip>

				<Menu
					anchorEl={state.quickLayoutAnchorEl}
					open={Boolean(state.quickLayoutAnchorEl)}
					onClose={() => {
						setState(draft => {
							draft.quickLayoutAnchorEl = null;
						});
					}}
				>
					<ListSubheader>{t`reporting-dashboard-quick_layout-title`}</ListSubheader>
					{quickLayoutOptions.map(option => (
						<MenuItem
							key={`columns_${option.value}`}
							onClick={() => {
								dispatch({
									type: 'set_quick_layout',
									payload: {
										value: option.value,
									},
								});
								setTimeout(triggerEvent(window, 'resize'), 100);
							}}
						>
							{t(option.label)}
						</MenuItem>
					))}
				</Menu>
			</SelectionAppBar>

			<ChangesDialog
				open={Boolean(state.changesAgreeDialog)}
				onClose={e => {
					setState(draft => {
						draft.changesAgreeDialog = false;
					});
				}}
				discardChanges={e => {
					setState(draft => {
						draft.changesAgreeDialog = false;
					});
					dispatch({
						type: 'undo_layout_changes',
					});
				}}
				saveChanges={() => {
					setState(draft => {
						draft.changesAgreeDialog = false;
					});
					saveDashboard();
				}}
				removedCharts={removedCharts}
			/>

			<SimpleDialog
				open={Boolean(state.confirmLayoutDialog)}
				title={t`reporting-dashboard-save_layout_dialog-title`}
				cancel={t`reporting-dashboard-save_layout_dialog-dismiss`}
				submit={t`reporting-dashboard-save_layout_dialog-confirm`}
				onClose={(e, from) => {
					if (from.button) {
						dispatch({
							type: 'undo_layout_changes',
						});
					}
					setState(draft => {
						draft.confirmLayoutDialog = false;
					});
				}}
				onSubmit={() => {
					saveDashboard();
					setState(draft => {
						draft.confirmLayoutDialog = false;
					});
				}}
				text={
					<>
						<Typography>{t`reporting-dashboard-save_layout_dialog-content`}</Typography>
						<List>
							{removedCharts.map((chart, key) => {
								const chartTitle = chart?.title?.text ?? '';
								return (
									<ListItem key={key}>
										<ListItemText>
											<Typography fontWeight="bold">{chartTitle}</Typography>
										</ListItemText>
									</ListItem>
								);
							})}
						</List>

						<Typography>
							{t`reporting-dashboard-save_layout_dialog-confirmation`}
						</Typography>
					</>
				}
			/>

			<SimpleDialog
				open={Object.keys(state.confirmRemoveChart).length > 0}
				title={t`reporting-dashboard-remove_chart-title`}
				cancel={t`reporting-dashboard-remove_chart-cancel`}
				submit={t`reporting-dashboard-remove_chart-confirm`}
				onClose={() => {
					setState(draft => {
						draft.confirmRemoveChart = {};
					});
				}}
				onSubmit={() => {
					dispatch({
						type: 'remove_from_layout',
						payload: {
							index: state.confirmRemoveChart.index,
							chart: state.confirmRemoveChart.chart,
							forceSave: true,
						},
					});

					setState(draft => {
						draft.confirmRemoveChart = {};
					});
				}}
				text={<Typography>{t`reporting-dashboard-remove_chart-content`}</Typography>}
			/>

			<MaxChartsDialog
				open={state.maxChartsDialogOpen}
				chartsInDashboard={chartsOnDashboard(currentDashboardId)}
				submit={t`reporting-dashboard-dialog_max_charts-create`}
				dashboardName={
					currentDashboard?.name
						? currentDashboard.name
						: t('reporting-dashboard-dialog_max_charts-dashboard_name', {
								name: app.projects.current.name,
						  })
				}
				dashboardId={currentDashboardId}
				onSubmit={() => {
					history.push(`/reporting/chart/new`);
				}}
				onClose={() => {
					setState(draft => {
						draft.maxChartsDialogOpen = false;
					});
				}}
			/>

			<NoPermissionTooltip>
				<ActionButton
					variant="extended"
					color="primary"
					className={classes.fab}
					disabled={!hasEditPermission}
					onClick={() => {
						if (maxChartsReached(currentDashboardId)) {
							setState(draft => {
								draft.maxChartsDialogOpen = true;
							});
						} else {
							history.push(`/reporting/chart/new`);
						}
					}}
					fab
					data-test-element="add-chart-fab"
					data-onboarding="add-chart-button"
				>
					<AddIcon />
					{t`reporting-dashboard-button-create_new_chart`}
				</ActionButton>
			</NoPermissionTooltip>
		</>
	);
}
