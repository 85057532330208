import React, { useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExportIcon from '@material-ui/icons/GetApp';
import ZoomIcon from '@material-ui/icons/ZoomIn';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import produce from 'immer';

import ActiveSeriesIcon from './ActiveSeriesIcon';
import CopyChartMenu from './CopyChartMenu';

import LoadChartSeries from './LoadChartSeries';
import { triggerEvent } from '@/utils';
import { ActionIconButton, NestedMenuItem, SimpleDialog } from '@/components/Layout';
import { useEffectSkipInitialRender } from '@/hooks';
import { useDirectInsightChart } from '@/components/Charts';

const useStyles = makeStyles(theme => ({
	card: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	cardAction: {
		flexGrow: 1,
		minWidth: 0,
		height: '100%',
		position: 'static',
	},
	cardContent: {
		height: '100%',
		flexGrow: '1',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	cardHeader: {
		marginLeft: theme.spacing(7),
	},
	cardHeaderTitle: {
		fontWeight: theme.typography.fontWeightMedium,
	},
	zoomDialogContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: '30vh',
	},
	//fixes dragimage in chrome, though it's not perfect because the image offset is wrong, keep track of issue
	fixDragImage: {
		transform: 'translateZ(0)',
	},
	avatar: {
		width: theme.spacing(4),
		height: theme.spacing(4),
		position: 'absolute',
		top: theme.spacing(3),
		left: theme.spacing(3),
	},
}));

export default function DirectInsightsChartCard({
	doResize,
	chartSettings,
	periodFilter,
	forceCacheRefresh,
	...props
}) {
	const { t } = useTranslation();
	const classes = useStyles();
	const chartRef = useRef(null);
	const { enqueueSnackbar } = useSnackbar();
	const [expandDialogOpen, setExpandDialogOpen] = useState(false);
	const [mouse, setMouse] = useState({});
	const [chartMenuAnchor, setChartMenuAnchor] = useState(null);

	const { datasourceId, datafieldIds, defaultChartColor } = chartSettings;

	const { chartType, series, chart } = useDirectInsightChart({
		datasourceId: datasourceId,
		datafieldIds: datafieldIds,
		chartSettings: chartSettings,
		periodFilter: periodFilter,
	});

	useEffectSkipInitialRender(() => {
		setTimeout(() => {
			triggerEvent(window, 'resize');
		}, 300);
	}, [doResize]);

	function closeMenu() {
		setMouse({});
		setChartMenuAnchor(null);
	}

	function menuClick(fn) {
		closeMenu();
		fn();
	}

	function exportChart(type) {
		closeMenu();
		try {
			chartRef.current.chart.exportChartLocal({ type });
		} catch (e) {
			enqueueSnackbar(t`reporting-insights-export-failed`);
		}
	}

	return (
		<>
			<Card
				onContextMenu={e => {
					e.preventDefault();
					const { clientX, clientY } = e;
					setMouse({ x: clientX - 2, y: clientY - 4 });
				}}
				className={`${classes.card} ${props.onDragStart ? classes.fixDragImage : ''}`}
				data-onboarding="dashboard-chart-card"
				{...props}
			>
				<>
					<CardHeader
						action={
							<>
								<ActiveSeriesIcon
									forceColor={defaultChartColor}
									serie={series[0]}
									classes={classes.avatar}
								/>
								<ActionIconButton
									action="more"
									tooltip={t`reporting-dashboard-chart_card-tooltip_options`}
									data-test-element="editChart"
									onClick={e => {
										setChartMenuAnchor(e.target);
									}}
								/>
							</>
						}
						titleTypographyProps={{
							variant: 'subtitle1',
							className: classes.cardHeaderTitle,
						}}
						title={chart?.title?.text}
						subheader={chart?.subtitle?.text}
						subheaderTypographyProps={{ variant: 'caption' }}
						className={classes.cardHeader}
					/>

					<CardActionArea
						className={classes.cardAction}
						disableRipple
					>
						<CardContent className={classes.cardContent}>
							<LoadChartSeries
								backendBauerSeries={series}
								chart={chart}
								chartType={chartType}
								chartRef={node => (chartRef.current = node)}
								useTooltip
							/>
						</CardContent>
					</CardActionArea>
				</>
			</Card>

			{series?.length > 0 && (
				<SimpleDialog
					open={expandDialogOpen}
					onClose={() => setExpandDialogOpen(false)}
					title={chart?.title?.text}
					cancel={t`reporting-insights-zoom_dialog-close`}
					maxWidth="lg"
				>
					<div className={classes.zoomDialogContent}>
						<LoadChartSeries
							backendBauerSeries={series}
							chart={produce(chart, draft => {
								try {
									draft.chart.height = window.innerHeight * 0.6;
								} catch (e) {}
							})}
							chartType={chartType}
							fromDash
							useTooltip
						/>
					</div>
				</SimpleDialog>
			)}

			<Menu
				anchorEl={chartMenuAnchor}
				open={Boolean(chartMenuAnchor) || Boolean(mouse.x && mouse.y)}
				onClose={closeMenu}
				anchorReference={mouse.y && mouse.x ? 'anchorPosition' : null}
				anchorPosition={mouse.y && mouse.x ? { top: mouse.y, left: mouse.x } : undefined}
			>
				<MenuItem onClick={() => menuClick(() => setExpandDialogOpen(true))}>
					<ListItemIcon>
						<ZoomIcon />
					</ListItemIcon>
					<ListItemText>{t`reporting-insights-chart_menu-zoom`}</ListItemText>
				</MenuItem>

				<CopyChartMenu
					open={Boolean(chartMenuAnchor) || Boolean(mouse.x && mouse.y)}
					onClose={closeMenu}
					chart={chart}
					series={series}
					chartType={chartType}
				/>

				<NestedMenuItem
					leftIcon={<ExportIcon />}
					parentOpen={Boolean(chartMenuAnchor) || Boolean(mouse.x && mouse.y)}
					primary={t`Export`}
				>
					<MenuItem onClick={() => exportChart('image/png')}>
						<ListItemText>{t`reporting-insights-chart_menu-png`}</ListItemText>
					</MenuItem>

					<MenuItem onClick={() => exportChart('image/svg+xml')}>
						<ListItemText>{t`reporting-insights-chart_menu-svg`}</ListItemText>
					</MenuItem>

					<MenuItem onClick={() => exportChart('application/pdf')}>
						<ListItemText>{t`reporting-insights-chart_menu-pdf`}</ListItemText>
					</MenuItem>
				</NestedMenuItem>
			</Menu>
		</>
	);
}
