import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { makeStyles } from '@/styles';

import { PageHeader, AppBarLeftContent } from '@/components/App';
import { Tabs, Tab } from '@/components/Layout';
import { AiIcon } from '@/components/Icons';

import { FeedbackList } from '@/components/Feedback';
import { SmartRecaps } from '@/components/SmartRecaps';

import { FeatureToggle } from '@/components/FeatureToggle';

const useStyles = makeStyles(theme => ({
	tab: {
		minHeight: 0,
		'& svg': {
			marginBottom: `${0}!important`,
			marginRight: theme.spacing(1),
		},
	},
	tabWithIcon: {
		flexDirection: 'row',
		alignItems: 'flex-start',
	},
	sparkle: {
		'& path': {
			fill: 'inherit',
		},
	},
}));

export default function FeedbackResults() {
	const { t } = useTranslation();
	const history = useHistory();
	const { pathname } = useLocation();
	const classes = useStyles();

	return (
		<FeatureToggle
			feature="smart_recaps"
			fallback={
				<>
					<PageHeader title={t`reporting-feedback_inbox-header_title`} />
					<FeedbackList />
				</>
			}
		>
			<PageHeader
				title={t`reporting-feedback_inbox-header_title`}
				prominent
			/>
			<AppBarLeftContent tabs>
				<Tabs
					value={pathname.includes('smart-recaps') ? 'smart-recaps' : 'inbox'}
					onChange={(e, value) => history.push(`/reporting/${value}`)}
				>
					<Tab
						value="inbox"
						label={t`reporting-feedback_inbox-header-tab_overview`}
					/>
					<Tab
						classes={{
							root: classes.tab,
							wrapper: classes.tabWithIcon,
						}}
						value="smart-recaps"
						icon={<AiIcon className={classes.sparkle} />}
						label={t`reporting-feedback_inbox-header-tab_smart_recaps`}
					/>
				</Tabs>
			</AppBarLeftContent>
			{pathname.includes('inbox') && <FeedbackList />}
			{pathname.includes('smart-recaps') && <SmartRecaps />}
		</FeatureToggle>
	);
}
