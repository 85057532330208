import React from 'react';
import { useAppContext } from '../AppContext';

const LoggedInLayout = React.lazy(() => import('./LoggedInLayout'));
const LoggedOutLayout = React.lazy(() => import('./LoggedOutLayout'));

export default function AppContent() {
	const { app } = useAppContext();

	return app.auth.loggedIn ? <LoggedInLayout /> : <LoggedOutLayout />;
}
