import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { SimpleDialog, TextField, Typography, Box } from '@/components/Layout';
import DeploymentDiff from './DeploymentDiff';
import { useDeploymentStore } from './DeploymentStore';

export default function DeploymentPublishDialog({ open, onClose = () => {} }) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const currentDeployment = useDeploymentStore(store => store.current);
	const workingDraftName = useDeploymentStore(store => store.workingDraft.name);
	const workingDraftStatus = useDeploymentStore(store => store.workingDraft.published);
	const workingDraftRules = useDeploymentStore(store => store.workingDraft.rules);
	const setDescription = useDeploymentStore(store => store.actions.setDescription);
	const saveDeployment = useDeploymentStore(store => store.actions.saveDeployment);
	const saveDeploymentLoading = useDeploymentStore(store => store.saveDeploymentLoading);

	return (
		<SimpleDialog
			title={t`data_collection-deployment_editor-publish_dialog-title`}
			open={open}
			onClose={onClose}
			onSubmit={() => {
				saveDeployment(response => {
					if (response.code !== 200) {
						enqueueSnackbar(t`data_collection-deployments-snackbar-save_error`);
						return;
					}
					enqueueSnackbar(
						t`data_collection-deployment_editor-publish_dialog-save_snackbar`
					);
					onClose();
				});
			}}
			submit={t`data_collection-deployment_editor-publish_dialog-submit`}
			loading={saveDeploymentLoading}
		>
			<Typography
				mb={1}
			>{t`data_collection-deployment_editor-publish_dialog-content`}</Typography>

			<TextField
				label={t`data_collection-deployment_editor-publish_dialog-description_textfield-label`}
				variant="outlined"
				onChange={e => setDescription(e.target.value)}
				fullWidth
			/>

			<Box mt={3}>
				<DeploymentDiff
					newName={workingDraftName}
					oldName={currentDeployment?.name}
					newStatus={workingDraftStatus}
					oldStatus={currentDeployment?.published}
					newRules={workingDraftRules}
					oldRules={currentDeployment?.rules}
				/>
			</Box>
		</SimpleDialog>
	);
}
