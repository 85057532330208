import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import DeadlineIcon from '@material-ui/icons/Alarm';
import { useTranslation } from 'react-i18next';

import { actionDue, isExpired } from './actionUtils';
import { actionStatusDone } from './constants';
import { FormattedDate, Typography, Tooltip } from '../Layout';

const useStyles = makeStyles(theme => ({
	deadlineIcon: {
		verticalAlign: 'middle',
		marginRight: theme.spacing(0.5),
	},
	deadlineText: {
		verticalAlign: 'middle',
	},
}));

export default function ActionDeadline({
	deadline,
	variant = 'body2',
	color = 'textSecondary',
	...props
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<Fragment>
			<Tooltip
				title={
					<Fragment>
						{t`Deadline date`} <FormattedDate date={deadline} />
					</Fragment>
				}
			>
				<Box
					display="inline"
					{...props}
				>
					<DeadlineIcon
						color="action"
						fontSize="small"
						className={classes.deadlineIcon}
					/>
					<Typography
						variant={variant}
						color={color}
						display="inline"
						className={classes.deadlineText}
					>
						{`${isExpired(deadline) ? t`Overdue` : t`Due`} ${
							actionDue(deadline) === 0 ? t`today` : `${actionDue(deadline)} days`
						}`}
					</Typography>
				</Box>
			</Tooltip>
		</Fragment>
	);
}
