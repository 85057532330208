import React, { useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import { makeStyles } from '@/styles';
import { useTranslation, Trans } from 'react-i18next';

import { EFM } from '@/api';

import { useAppContext } from '@/components/AppContext';
import {
	Typography,
	Grid,
	ActionButton,
	Loader,
	InputAdornment,
	ActionIconButton,
} from '@/components/Layout';
import { useOnboardingContext } from '@/components/Onboarding';
import { ValidatedField } from '@/components/Validation';
import useCheckPassword from './useCheckPassword';

const useStyles = makeStyles(theme => ({
	paddingBottom: {
		paddingBottom: theme.spacing(2),
	},
}));

export default function OnboardingSetPassword({ initialUser, onPasswordSet = () => {} }) {
	const { t } = useTranslation();
	const { app } = useAppContext();
	const onboarding = useOnboardingContext();
	const classes = useStyles();

	const [password, setPassword] = useState('');
	const [passwordLoading, setPasswordLoading] = useState(false);
	const [passwordClicked, setPasswordClicked] = useState(false);

	let minLength = app.auth?.password?.min ?? 8;
	let maxLength = app.auth?.password?.max ?? 36;

	const [checkPassword, checkPasswordLoading] = useCheckPassword({ password: password });

	async function updatePassword() {
		setPasswordClicked(true);
		if (checkPassword.code !== 200) {
			return;
		}
		setPasswordLoading(true);
		const reauth = await EFM.post('/account/ajax/re-authenticate');

		if (reauth.token) {
			const updatedPasswordSuccess = await EFM.post('/account/ajax/update-password', {
				password: password,
				reauth_security: reauth.token,
			});

			if (updatedPasswordSuccess.code === 200 && initialUser) {
				onPasswordSet();
			} else if (updatedPasswordSuccess.code === 200 && !initialUser) {
				app.api.updateSessionData();
				onboarding.dispatch({
					type: 'set_new_user_tour_step',
					payload: {
						step: 'menu',
					},
				});
			}
			setPasswordLoading(false);
		}
	}

	return (
		<>
			<Grid item>
				<Typography variant="h4">
					{initialUser
						? t`onboarding-getting_started-step_security-title`
						: t`onboarding-extra_user-security-title`}
				</Typography>
			</Grid>

			<Grid item>
				<Typography
					variant="body1"
					className={classes.paddingBottom}
				>
					{initialUser ? (
						t`onboarding-getting_started-step_security-subtitle`
					) : (
						<Trans
							i18nKey={`onboarding-extra_user-security-subtitle`}
							components={{
								p1: <p />,
								p2: <p />,
								strong: <strong />,
							}}
						/>
					)}
				</Typography>

				<PasswordInput
					data-test-element="onboarding-password"
					variant="outlined"
					value={password}
					onChange={e => {
						const { value } = e.currentTarget;
						setPassword(value);
					}}
					onKeyDown={e => {
						if (e.key === 'Enter') {
							updatePassword();
						}
					}}
					label={
						initialUser
							? t`onboarding-getting_started-step_security-password_label`
							: t`onboarding-extra_user-security-password_label`
					}
					showErrors={
						(passwordClicked && password.length < minLength) ||
						(checkPassword.code !== 200 &&
							(passwordClicked || password.length > minLength))
					}
					rules={[
						['min', minLength],
						['max', maxLength],
						['custom', () => checkPassword.code !== 400],
					]}
					errorMessage={
						passwordClicked && password.length < minLength
							? t('password_error_min_length', { minlength: minLength })
							: checkPassword.code !== 200 &&
							  !checkPasswordLoading &&
							  checkPassword.message
							? t(checkPassword.message, {
									minlength: minLength,
									maxlength: maxLength,
							  })
							: t('errormessage_password_required')
					}
					success={
						(passwordClicked && password.length > minLength) ||
						(checkPassword.code === 200 && password.length >= minLength)
					}
					error={
						(passwordClicked && password.length < minLength) ||
						(checkPassword.code !== 200 &&
							(passwordClicked || password.length > minLength))
					}
					loading={checkPasswordLoading}
					helperText={t('onboarding-getting_started-step_security-password_helpertext', {
						count: minLength,
					})}
				/>
			</Grid>

			<Grid
				item
				xs={6}
			>
				<ActionButton
					data-test-element="onboarding-next-button"
					variant="contained"
					color="primary"
					data-track-event={
						initialUser
							? 'onboarding_account_password_changed'
							: 'extra_user_account_password_changed'
					}
					loading={checkPasswordLoading || passwordLoading}
					onClick={() => {
						updatePassword();
					}}
				>
					{initialUser
						? t`onboarding-getting_started-step_security-button_next`
						: t`onboarding-extra_user-security-button_next`}
				</ActionButton>
			</Grid>
		</>
	);
}

function PasswordInput(props) {
	const [show, setShow] = useState(false);
	const { t } = useTranslation();

	return (
		<ValidatedField
			fullWidth
			type={show ? 'text' : 'password'}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<ActionIconButton
							onClick={() => setShow(show => !show)}
							action={!show ? 'view' : 'hide'}
							tooltip={
								!show
									? t`iconbutton_general_show_password`
									: t`iconbutton_general_hide_password`
							}
						/>
						{props.loading ? (
							<Loader
								circular
								size={16}
							/>
						) : props.success ? (
							<CheckIcon color="secondary" />
						) : props.error ? (
							<CloseIcon color="error" />
						) : null}
					</InputAdornment>
				),
			}}
			{...props}
		/>
	);
}
