import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

import { actionStatusCompleted } from './constants';
import ActionProgress from './ActionProgress';
import ActionProgressLabel from './ActionProgressLabel';
import ActionDeadline from './ActionDeadline';
import { TagChip } from '../Tags';
import { UserAvatarGroup } from '../Users';
import { Typography, Grid, Chip } from '../Layout';
import { AjaxForm } from '../Ajax';

const useStyles = makeStyles(theme => ({
	progress: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		background: 'transparent',
	},
	deadlineIcon: {
		verticalAlign: 'middle',
		marginRight: theme.spacing(0.5),
	},
	deadlineText: {
		verticalAlign: 'middle',
	},
	progressRowSpace: {
		margin: theme.spacing(0.5, 0),
	},
	chipSpace: {
		'& > div': {
			marginRight: theme.spacing(0.5),
		},
	},
}));

export default function ActionSummary({ action, expanded, loadData = () => {} }) {
	const classes = useStyles();
	const { t } = useTranslation();

	const stack = [
		{
			user_id: action.owner_user_id,
			tooltip: true,
			tooltipTitle: ({ firstname, lastname }) =>
				`${firstname} ${lastname} - ${t('Owner')}`,
		},
		{
			user_id: action.agent_user_id,
			tooltip: true,
			tooltipTitle: ({ firstname, lastname }) =>
				`${firstname} ${lastname} - ${t('Assignee')}`,
		},
	];

	return (
		<Fragment>
			<Grid
				container
				direction="column"
			>
				<Grid
					item
					xs
				>
					<Grid
						container
						direction="row"
						alignItems="center"
					>
						<Grid
							item
							xs
						>
							<Typography
								variant={expanded ? 'h6' : 'subtitle2'}
								display="block"
							>
								{action.name}
							</Typography>
						</Grid>
						{!expanded && Number(action.status_id) !== actionStatusCompleted && (
							<Grid item>
								<ActionDeadline
									variant="caption"
									deadline={action.deadline}
								/>
							</Grid>
						)}
					</Grid>
				</Grid>

				<Grid
					item
					xs
					className={classes.progressRowSpace}
				>
					<ActionProgress status_id={action.status_id} />
				</Grid>

				<Grid
					item
					xs
					className={classes.rowSpace}
				>
					<Grid
						container
						alignItems="center"
					>
						<Grid
							item
							className={classes.chipSpace}
						>
							<ActionProgressLabel action={action} />
							<Chip
								label={t(action.action_type_name)}
								size="small"
							/>
						</Grid>
						<Grid
							item
							xs
							className={classes.chipSpace}
						>
							{action?.tags?.map(tag => (
								<AjaxForm
									url="/application/tags/delete-by-id"
									data={{
										ids: tag.id,
										actionId: action.id,
									}}
									onSuccess={loadData}
									key={'tag' + tag.id}
								>
									{({ postForm }) => (
										<TagChip
											id={tag.id}
											label={tag.name}
											small
											onDelete={postForm}
										/>
									)}
								</AjaxForm>
							))}
						</Grid>
						{!expanded && (
							<Grid item>
								<UserAvatarGroup
									stack={stack}
									size="small"
								/>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Fragment>
	);
}
