import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import CopyButton from './CopyButton';

const useStyles = makeStyles(theme => ({
	mono: {
		fontFamily: '"Ubuntu Mono", monospace',
	},
	bg: {
		backgroundColor: '#fff',
	},
	compact: {
		paddingRight: theme.spacing(1),
	},
}));

export default function CopyCodeTextField({
	value,
	message,
	mono,
	compact,
	dataTrackEvent = null,
	...props
}) {
	const classes = useStyles();

	return (
		<TextField
			inputProps={{ className: mono ? classes.mono : '' }}
			fullWidth
			value={value}
			readOnly
			variant="outlined"
			className={classes.bg}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<CopyButton
							value={value}
							dataTrackEvent={dataTrackEvent}
						/>
					</InputAdornment>
				),
				classes: {
					adornedEnd: compact ? classes.compact : '',
				},
			}}
			{...props}
		/>
	);
}
