import React, { useMemo, useEffect } from 'react';

import InputLabel from '@material-ui/core/InputLabel';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { useAppContext } from '@/components/AppContext';
import { useDataSourceContext, DataSourceAvatar } from '@/components/DataSources';

import {
	FormControl,
	ListItemAvatar,
	ListItemText,
	ListSubheader,
	FormHelperText,
	MenuItem,
	Select,
} from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	select: {
		display: 'flex',
		alignItems: 'center',
	},
	empty: {
		color: theme.palette.text.hint,
	},
	subheader: {
		backgroundColor: '#fff',
	},
}));

export default function DataSourceSelect({
	children,
	label,
	emptyLabel,
	renderValueTextOnly,
	withAllData,
	dataTestElement = null,
	ListItemAvatarProps = {},
	dataSourceTypes = ['forms', 'datasets', 'integrations'],
	customDataSourceItems = {},
	...props
}) {
	const { t } = useTranslation();
	const { datasource } = useDataSourceContext();
	const classes = useStyles();
	const { app } = useAppContext();

	useEffect(() => {
		datasource.api.loadSurveys();
	}, []);

	const dataSourceArray = dataSourceTypes.map(datasourceType => {
		return {
			label: t(`app_general-datasource_select-label_${datasourceType}`),
			arr: customDataSourceItems[datasourceType] ?? datasource[datasourceType].asArray,
		};
	});

	const options = useMemo(() => {
		return dataSourceArray.map(dataSourceType => {
			return dataSourceType.arr.length > 0
				? [
						<ListSubheader className={classes.subheader}>
							{dataSourceType.label}
						</ListSubheader>,
						...dataSourceType.arr.map(dataSourceItem => {
							const productInPackage = app.api.packageProductAllowed(
								dataSourceItem.campaign
							);
							return (
								<MenuItem
									value={dataSourceItem.id}
									data-test-element={dataTestElement ? dataTestElement + 'Option' : null}
									className={dataSourceItem.inactive ? classes.empty : ''}
									disabled={!productInPackage}
								>
									<ListItemAvatar {...ListItemAvatarProps}>
										<DataSourceAvatar {...dataSourceItem} />
									</ListItemAvatar>
									<ListItemText
										primary={dataSourceItem.name}
										secondary={
											!productInPackage
												? t(
														`app_general-datasource_select-product_not_allowed-${dataSourceItem.campaign}`
												  )
												: dataSourceItem.description
										}
									/>
								</MenuItem>
							);
						}),
				  ]
				: [];
		});
	}, [
		dataSourceArray,
		JSON.stringify(datasource.forms.byKey),
		JSON.stringify(datasource.datasets.byKey),
		JSON.stringify(datasource.integrations.byKey),
	]);

	return (
		<FormControl fullWidth>
			{label && <InputLabel>{label}</InputLabel>}
			<Select
				classes={{ select: classes.select }}
				data-test-element={dataTestElement}
				{...(renderValueTextOnly && {
					renderValue: value =>
						Array.isArray(value)
							? value.map(v => datasource.api.getField(v).system_var).join(', ')
							: datasource.api.getField(value).system_var,
				})}
				displayEmpty={Boolean(emptyLabel)}
				{...props}
			>
				{emptyLabel && (
					<MenuItem
						value=""
						data-test-element={dataTestElement ? dataTestElement + 'Option' : null}
					>
						<ListItemText
							primary={emptyLabel}
							// primaryTypographyProps={{
							// 	className:classes.empty
							// }}
						/>
					</MenuItem>
				)}
				{withAllData && (
					<MenuItem
						value={'ALL_DATA'}
						data-test-element={dataTestElement ? dataTestElement + 'Option' : null}
					>
						<ListItemAvatar {...ListItemAvatarProps}>
							<DataSourceAvatar allData />
						</ListItemAvatar>
						<ListItemText
							primary={t`app_general-datasource_select-all_data_primary`}
							secondary={t`app_general-datasource_select-all_data_secondary`}
						/>
					</MenuItem>
				)}
				{options}
			</Select>
			{props.helperText && (
				<FormHelperText error={props.error}>{props.helperText}</FormHelperText>
			)}
		</FormControl>
	);
}
