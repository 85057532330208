import React, { Fragment, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';

import get from 'lodash.get';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '../AppContext';
import {
	Grid,
	Card,
	CardHeader,
	CardContent,
	Typography,
	Loader,
	Alert,
	FormattedDate,
	ActionIconButton,
	SimpleDialog,
	ActionButton,
} from '../Layout';
import { useEFM } from '../Ajax';
import Amount from './Amount';
import TransferOwnerDialog from './TransferOwnerDialog';
import { useHasPermission } from '../Permission';

const useStyles = makeStyles(theme => ({
	dialogContent: {
		width: '100%',
		minHeight: '30vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	tableHead: {
		fontSize: theme.typography.body1.fontSize,
	},
	tableCell: {
		fontSize: theme.typography.body1.fontSize,
	},
	actionTableCell: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
}));

export default function HistoryCard() {
	const { t } = useTranslation();
	const { app } = useAppContext();
	const [showDetail, setShowDetail] = useState({});
	const [transferOwner, setTransferOwner] = useState(false);
	const classes = useStyles();
	const hasEditPermission = useHasPermission();

	const [history, historyLoading] = useEFM('/account/billing/history');
	const [details, detailsLoading] = useEFM(
		'/account/ajax/get-charge-details',
		{
			paid: showDetail.paid,
			id: showDetail.paid ? showDetail.invoice : showDetail.id,
		},
		Object.keys(showDetail).length === 0
	);

	return (
		<Fragment>
			<Card>
				<CardHeader title={t`Transactions`} />

				{historyLoading ? (
					<CardContent>
						<Loader circular />
					</CardContent>
				) : (
					<Fragment>
						{history.is_legacy ? (
							<CardContent>
								<Alert variant="default">
									{t`Sorry! Your payment history in unavailable. Please contact support@mopinion.com`}
								</Alert>
							</CardContent>
						) : (
							<TableContainer component="div">
								<Table>
									<TableHead>
										<TableRow>
											<TableCell className={classes.tableHead}>{t`Date`}</TableCell>
											<TableCell className={classes.tableHead}>{t`Status`}</TableCell>
											<TableCell className={classes.tableHead}>{t`Amount`}</TableCell>
											<TableCell></TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{Array.isArray(history.transactions) &&
										history.transactions.length > 0 ? (
											history.transactions.map(transaction => {
												return (
													<TableRow key={transaction.id}>
														<TableCell className={classes.tableCell}>
															<FormattedDate date={transaction.created} />
														</TableCell>
														<TableCell className={classes.tableCell}>
															{t(transaction.status)}
														</TableCell>
														<TableCell className={classes.tableCell}>
															<Amount
																amount={transaction.amount / 100}
																currency={transaction.currency}
															/>
														</TableCell>
														<TableCell
															align="right"
															className={classes.actionTableCell}
														>
															<ActionIconButton
																action="view"
																tooltip={t`View details`}
																onClick={() => setShowDetail({ ...transaction })}
															/>
															{transaction.invoice && (
																<ActionIconButton
																	action="download"
																	href={`/account/billing/download/${transaction.invoice}?react=1`}
																	target="_blank"
																	tooltip={t`Download invoice`}
																/>
															)}
														</TableCell>
													</TableRow>
												);
											})
										) : (
											<TableRow>
												<TableCell>{t`No transaction history`}</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</Fragment>
				)}
			</Card>

			<Dialog
				open={Object.keys(showDetail).length > 0}
				onClose={() => setShowDetail({})}
				maxWidth="sm"
				fullWidth
			>
				<DialogContent>
					<Typography variant="h6">
						{t`Your transaction on`}{' '}
						<FormattedDate
							date={showDetail.created}
							withTime
						/>
					</Typography>
					<Box
						width="100%"
						mt={4}
						mb={4}
					>
						{detailsLoading ? (
							<Box
								display="flex"
								width="100%"
								justifyContent="center"
								alignItems="center"
								clone
							>
								<Loader circular />
							</Box>
						) : (
							<Fragment>
								{get(details, 'lines.data', [])
									.filter(payment => payment.amount !== 0)
									.map(payment => {
										return (
											<Grid
												container
												alignItems="center"
												key={payment.id}
											>
												<Grid
													item
													xs
												>
													<Typography variant="body2">
														{payment.type === 'subscription'
															? `${t`Subscription to`} ${t(payment.plan.name)}`
															: t(payment.description)}
													</Typography>
												</Grid>
												<Grid item>
													<Typography variant="body2">
														<Amount
															amount={payment.amount / 100}
															currency={details.currency}
														/>
													</Typography>
												</Grid>
											</Grid>
										);
									})}
								<Box mt={2}>
									{details.subtotal && (
										<Grid
											container
											alignItems="center"
										>
											<Grid
												item
												xs
											>
												<Typography variant="body2">{t`Subtotal`}</Typography>
											</Grid>
											<Grid item>
												<Typography variant="body2">
													<Amount
														amount={details.subtotal / 100}
														currency={details.currency}
													/>
												</Typography>
											</Grid>
										</Grid>
									)}
									{details?.discount?.coupon && (
										<Grid
											container
											alignItems="center"
										>
											<Grid
												item
												xs
											>
												<Typography
													variant="subtitle2"
													color="secondary"
												>{`${t`Discount code`} ${
													details.discount.coupon.id
												}`}</Typography>
											</Grid>
											<Grid item>
												<Typography
													variant="subtitle2"
													color="secondary"
												>
													{details.discount.coupon.percent_off}
												</Typography>
											</Grid>
										</Grid>
									)}
									{Number(details.tax) !== 0 && (
										<Grid
											container
											alignItems="center"
										>
											<Grid
												item
												xs
											>
												<Typography variant="body2">{t`VAT`}</Typography>
											</Grid>
											<Grid item>
												<Typography variant="body2">
													<Amount
														amount={details.tax / 100}
														currency={details.currency}
													/>
												</Typography>
											</Grid>
										</Grid>
									)}
									{details.total && (
										<Grid
											container
											alignItems="center"
										>
											<Grid
												item
												xs
											>
												<Typography variant="subtitle2">{t`Total`}</Typography>
											</Grid>
											<Grid item>
												<Typography variant="subtitle2">
													<Amount
														amount={details.total / 100}
														currency={details.currency}
													/>
												</Typography>
											</Grid>
										</Grid>
									)}
								</Box>
							</Fragment>
						)}
					</Box>
				</DialogContent>
			</Dialog>
		</Fragment>
	);
}
