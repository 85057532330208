import React from 'react';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

import { useDataSourceContext } from '../DataSources';
import { ActionIconButton, Typography } from '../Layout';
import { DataFieldSelect } from '../Select';
import { PossibleValues } from '../AutoComplete';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	noWrap: {
		flexWrap: 'nowrap',
	},
	maxWidth: {
		maxWidth: 'calc(100% - 20px)',
	},
}));

export default function DataFieldConditions({
	conditionArray = [],
	operatorFn = () => {},
	surveyId,
	onDataFieldChange = () => {},
	onOperatorChange = () => {},
	onPossibleValueChange = () => {},
	onRemoveRow = () => {},
	DataFieldSelectProps = {},
	OperatorSelectProps = {},
	PossibleValuesProps = {},
	helperText,
	noWrap,
}) {
	const { t } = useTranslation();
	const { datasource } = useDataSourceContext();
	const classes = useStyles();

	return conditionArray.map((feedbackCondition, feedbackConditionIndex) => {
		const field = datasource.api.getField(feedbackCondition.datafield_id);
		const disableValue = ['filled', 'empty'].indexOf(feedbackCondition.operator) > -1;

		return (
			<Box
				mt={1}
				key={feedbackConditionIndex}
			>
				<Grid
					container
					className={noWrap ? classes.noWrap : ''}
				>
					<Grid
						item
						xs
						className={noWrap ? classes.maxWidth : ''}
					>
						<Grid
							container
							key={feedbackConditionIndex}
							alignItems={helperText ? 'top' : 'center'}
							spacing={1}
						>
							<Grid
								item
								xs={disableValue ? 6 : 4}
							>
								<DataFieldSelect
									fullWidth
									sourceId={surveyId}
									value={feedbackCondition.datafield_id}
									onChange={e => onDataFieldChange(feedbackConditionIndex, e)}
									renderValueTextOnly
									{...DataFieldSelectProps}
									dataTestElement="tagAddTagWhen"
								/>
							</Grid>

							<Grid
								item
								xs={disableValue ? 6 : 4}
							>
								<TextField
									select
									fullWidth
									value={feedbackCondition.operator}
									onChange={e => {
										onOperatorChange(feedbackConditionIndex, e);
										if (['filled', 'empty'].indexOf(e.target.value) > -1) {
											onPossibleValueChange(feedbackConditionIndex, '');
										}
									}}
									inputProps={{ 'data-test-element': 'tagOperatorSelect' }}
									{...OperatorSelectProps}
								>
									{operatorFn(field).map(operator => (
										<MenuItem
											key={operator}
											data-test-element="tagOperatorSelectOption"
											value={operator}
										>
											{t('operator.' + operator)}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							{!disableValue && (
								<Grid
									item
									xs={4}
								>
									<PossibleValues
										fullWidth
										datafieldId={feedbackCondition.datafield_id}
										//	selectedItem={feedbackCondition.value}
										value={feedbackCondition.value}
										onChange={e => onPossibleValueChange(feedbackConditionIndex, e)}
										renderChips
										helperText={helperText}
										dataTestElement="tagAddTagValue"
										{...PossibleValuesProps}
									/>
								</Grid>
							)}
						</Grid>
					</Grid>
					<Grid item>
						<ActionIconButton
							action="remove_list"
							tooltip={t`Remove from condition`}
							size="small"
							onClick={() => onRemoveRow(feedbackConditionIndex)}
						/>
					</Grid>
				</Grid>
			</Box>
		);
	});
}
