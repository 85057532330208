import React from 'react';

import Icon from '@material-ui/core/Icon';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { useAppContext } from '@/components/AppContext';
import { ActionButton } from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	ssoIcon: {
		overflow: 'visible',
		position: 'absolute',
		left: 0,
		top: 0,
		padding: '6px 8px',
	},
}));

export default function SsoLogin({ organisationUuid = '' }) {
	const { t } = useTranslation();
	const classes = useStyles();
	const { app } = useAppContext();
	const { directory } = app.auth.sso;
	const loginPath = `${app.auth.sso.login_path}?organisation=${organisationUuid}`;
	const ssoLoginUrl = () => {
		document.location.href = loginPath;
	};

	const directoryAssets = {
		azure: {
			icon: '/assets/img/r/ms-logo-icon.svg',
			name: 'Microsoft',
		},
		okta: {
			icon: '/assets/img/r/okta-logo-icon.svg',
			name: 'Okta',
		},
	};

	return (
		<ActionButton
			size="large"
			fullWidth
			variant="outlined"
			color="default"
			onClick={ssoLoginUrl}
			startIcon={
				<Icon className={classes.ssoIcon}>
					<img
						src={directoryAssets[directory].icon}
						height={18}
						width={18}
					/>
				</Icon>
			}
		>
			{t('login_sign_in_with', { name: directoryAssets[directory].name })}
		</ActionButton>
	);
}
