import { getSentimentColors } from '@/styles';
import { objectToArray } from '@/helpers';
import { removeArrayDuplicatesFromShallowClone } from '@/utils';

export function msiString(msi) {
	return msi < 10 ? `0${Math.round(msi)}` : Math.round(msi);
}

export function getRank(msi, perc) {
	return Math.round((Number(msi) + Number(perc)) / 2);
}

export function getPerc(part, total) {
	return Math.round((Number(part) / Number(total)) * 100);
}

export function msiColor(msi) {
	return getSentimentColors(msi);
}

function isStringifiedObject(str = '') {
	try {
		const parsed = JSON.parse(str);
		if (parsed && typeof parsed === 'object') {
			return true;
		}
	} catch (e) {
		return false;
	}
}

export function valueToFilteredArray(value = '') {
	//handle stringified object
	if (isStringifiedObject(value)) {
		try {
			const parsedString = JSON.parse(value);
			const arrayFromObject = objectToArray(parsedString);

			if (Array.isArray(arrayFromObject)) {
				const filteredArray = arrayFromObject.filter(value => typeof value === 'string');
				const cleanedArray = [...new Set(filteredArray)];

				return cleanedArray;
			}

			return [];
		} catch (e) {
			return [];
		}
	}

	//handle arrays and single values
	const valueToArray = value
		.replace(/[[|\]]/gi, '')
		.replace(/[;]/gi, ',')
		.split(',')
		.filter(value => value !== '');

	const cleanedArray = valueToArray.reduce((array, value) => {
		const cleanedValue = value
			.trim()
			.replace(/^("|')/g, '')
			.replace(/("|')$/g, '');

		if (cleanedValue && !isStringifiedObject(cleanedValue)) {
			array.push(cleanedValue);
		}
		return array;
	}, []);

	return removeArrayDuplicatesFromShallowClone(cleanedArray);
}
