import React from 'react';

import AddIcon from '@material-ui/icons/Add';

import { makeStyles, hexToRgb } from '@/styles';
import SelectionIndicator from './SelectionIndicator';
import Loader from './Loader';
import Card from './Card';
import CardContent from './CardContent';
import CardActionArea from './CardActionArea';
import CardActions from './CardActions';

const useStyles = makeStyles(theme => ({
	card: props => ({
		height: '100%',
		position: 'relative',
		opacity: props.disabled ? 0.5 : 1,
		transition: theme.transitions.create('box-shadow'),
		...(props.bordered &&
			props.selected && {
				boxShadow: theme.shadows[3].replace(
					'0,0,0',
					hexToRgb(theme.palette.primary.main).replace(/rgb\(|\)/gi, '')
				),
			}),
		...(props.center && {
			textAlign: 'center',
		}),
		...(props.bordered && {
			'&:hover': {
				boxShadow: theme.shadows[3].replace(
					'0,0,0',
					hexToRgb(theme.palette.primary.main).replace(/rgb\(|\)/gi, '')
				),
			},
			'&:hover $bordered': {
				border: props => `2px solid ${theme.palette.primary.main}`,
			},
			...(props.highlightInner && {
				'&:hover *': {
					color: theme.palette.primary.main,
				},
			}),
		}),
	}),
	action: {
		height: '100%',
	},
	bordered: {
		position: 'absolute',
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
		pointerEvents: 'none',
		border: props =>
			`2px solid ${props.selected ? theme.palette.primary.main : 'transparent'}`,
		borderRadius: theme.shape.borderRadius,
		transition: theme.transitions.create('border'),
	},
	add: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	loading: {
		position: 'absolute',
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
		background: 'rgba(255,255,255,.3)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

const SelectionCard = React.forwardRef(function SelectionCard(
	{
		children,
		outerContent,
		onClick = () => {},
		disabled,
		selected,
		content,
		className = '',
		CardActionAreaProps = {},
		CardContentProps = {},
		CardActionProps = {},
		add,
		actions,
		bordered = true,
		loading,
		highlightInner,
		dataTrackEvent,
		center,
		...props
	},
	ref
) {
	const classes = useStyles({ bordered, selected, highlightInner, center, disabled });

	return (
		<Card
			className={`${classes.card} ${className}`}
			ref={ref}
			{...props}
		>
			{loading && (
				<div className={classes.loading}>
					<Loader circular />
				</div>
			)}
			{selected && <SelectionIndicator blue={bordered} />}
			{bordered && <div className={classes.bordered} />}
			<CardActionArea
				onClick={onClick}
				className={classes.action}
				disabled={disabled}
				data-track-event={dataTrackEvent}
				{...CardActionAreaProps}
			>
				{add && (
					<div className={classes.add}>
						<AddIcon fontSize="large" />
					</div>
				)}
				{outerContent && outerContent}
				{children && <CardContent {...CardContentProps}>{children}</CardContent>}
				{actions && <CardActions {...CardActionProps}>{actions}</CardActions>}
			</CardActionArea>
		</Card>
	);
});

export default SelectionCard;
