import React, { useState, Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import LinkIcon from '@material-ui/icons/Link';
import UnlinkIcon from '@material-ui/icons/LinkOff';

import { ButtonAddList } from '../Layout';

import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import get from 'lodash.get';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
	input: {
		minWidth: 200,
	},
	firstInputSpace: {
		marginBottom: theme.spacing(2),
	},
}));

export default function LinkToggles({ currentState, onChange, config = {}, ...props }) {
	const classes = useStyles();
	const [popoverAnchor, setPopoverAnchor] = useState(null);
	const [useSurveyFunctions, setUseSurveyFunctions] = useState(false);
	const [linkState, setLinkState] = useState({
		title: (currentState.link && currentState.link.title) || currentState.selectionText,
		target: (currentState.link && currentState.link.target) || '',
		targetOption: '_blank',
	});
	const [linkError, setLinkError] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	function applyLink(e) {
		e.stopPropagation();
		if (linkState.title && linkState.target) {
			onChange('link', linkState.title, linkState.target, linkState.targetOption);
			setTimeout(() => setPopoverAnchor(null));
		}
	}

	useEffect(() => {
		setLinkState(state => ({
			...state,
			title: currentState.selectionText,
		}));
	}, [currentState.selectionText]);

	useEffect(() => {
		setLinkState(state => ({
			...state,
			title: currentState?.link?.title ?? '',
		}));
	}, [get(currentState, 'link.title', '')]);

	useEffect(() => {
		setLinkState(state => ({
			...state,
			target: currentState?.link?.target ?? '',
		}));
	}, [get(currentState, 'link.target', '')]);

	useEffect(() => {
		if (
			currentState.link &&
			['close_modal', 'prev_page', 'next_page', 'start', 'close'].indexOf(
				currentState.link.target
			) > -1
		) {
			setUseSurveyFunctions(true);
		} else {
			setUseSurveyFunctions(false);
		}
	}, [get(currentState, 'link.target', '')]);

	const { t } = useTranslation();

	const value =
		currentState.link && currentState.link.title && currentState.link.target
			? 'link'
			: '';

	return (
		<Fragment>
			<ToggleButtonGroup
				size="small"
				exclusive
				value={value}
			>
				<ToggleButton
					value="link"
					onClick={e => setPopoverAnchor(e.target)}
				>
					<Tooltip title={value === 'link' ? t`Edit link` : t`Add link`}>
						<LinkIcon />
					</Tooltip>
				</ToggleButton>

				<ToggleButton
					value="unlink"
					onClick={e => onChange('unlink')}
				>
					<Tooltip title={t`Remove link`}>
						<UnlinkIcon />
					</Tooltip>
				</ToggleButton>
			</ToggleButtonGroup>
			<Popover
				open={Boolean(popoverAnchor)}
				anchorEl={popoverAnchor}
				onClose={e => setPopoverAnchor(null)}
				onClick={e => e.stopPropagation()}
			>
				<Box p={2}>
					<Grid
						container
						direction="column"
						spacing={2}
					>
						<Grid item>
							<TextField
								label={t`Link title`}
								value={linkState.title}
								onChange={e => {
									const { value } = e.currentTarget;
									setLinkState(state => ({
										...state,
										title: value,
									}));
								}}
								fullWidth
								className={`${classes.input} ${classes.firstInputSpace}`}
							/>
						</Grid>
						{config.dropdownClassName.indexOf('fromLinkElementSurveyEditor') > -1 && (
							<Grid item>
								<FormControl fullWidth>
									<FormLabel focus={false}>{t`Link type`}</FormLabel>
									<RadioGroup
										value={useSurveyFunctions ? 'function' : 'link'}
										onChange={(e, value) =>
											setUseSurveyFunctions(value === 'function' ? true : false)
										}
									>
										<FormControlLabel
											value={'link'}
											control={<Radio />}
											label={t`External link`}
										/>
										<FormControlLabel
											value={'function'}
											control={<Radio />}
											label={t`Trigger form action`}
										/>
									</RadioGroup>
								</FormControl>
							</Grid>
						)}
						{config.dropdownClassName.indexOf('fromLinkElementSurveyEditor') > -1 &&
						useSurveyFunctions ? (
							<Grid item>
								<TextField
									select
									label={t`Form action`}
									value={linkState.target}
									onChange={e => {
										const { value } = e.target;
										setLinkState(state => ({
											...state,
											target: value,
										}));
									}}
									fullWidth
									className={classes.input}
								>
									{config.dropdownClassName.includes('conversationalSurveyEditor')
										? [
												<MenuItem value="close_modal">{t`Close form`}</MenuItem>,
												<MenuItem value="start">{t`Restart form`}</MenuItem>,
										  ]
										: config.dropdownClassName.indexOf('introThanksPageLinkEditor') === -1
										? [
												<MenuItem value="close_modal">{t`Close form`}</MenuItem>,
												<MenuItem value="prev_page">{t`Go to previous page`}</MenuItem>,
												<MenuItem value="next_page">{t`Go to next page`}</MenuItem>,
										  ]
										: [
												<MenuItem value="close">{t`Close form`}</MenuItem>,
												<MenuItem value="start">{t`Start survey`}</MenuItem>,
										  ]}
								</TextField>
							</Grid>
						) : (
							<Grid item>
								<TextField
									label={t`Link target`}
									value={linkState.target}
									onChange={e => {
										const { value } = e.currentTarget;
										setLinkState(state => ({
											...state,
											target: value,
										}));
									}}
									className={classes.input}
									fullWidth
								/>
							</Grid>
						)}
						<Grid
							item
							xs
						>
							<Grid container>
								<Grid
									item
									xs
								/>
								<Grid item>
									<ButtonAddList
										onClick={applyLink}
										label={t`Add`}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Popover>
		</Fragment>
	);
}
