import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

import { KnowledgeBaseLink, Grid, Typography, CollapsibleBox } from '@/components/Layout';
import { Explainer } from '@/components/Explainer';

const useStyles = makeStyles(theme => ({
	paragraph: {
		marginBottom: theme.spacing(3),
	},
	colorWhite: {
		color: '#fff',
	},
	collapseWrapper: {
		padding: theme.spacing(2, 0),
	},
	collapse: {
		width: '100%',
		alignItems: 'center',
		display: 'inline-flex',
		cursor: 'pointer',
		'& > svg': {
			marginLeft: 'auto',
		},
	},
}));

export default function PublishFormsExplainer() {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Explainer
			title={t`onboarding-getting_started-step_channel-explainer-title`}
			dataTrackEvent="onboarding_explainer_deployments"
			content={
				<>
					<Grid container>
						<Grid
							item
							className={classes.paragraph}
						>
							<Typography>
								{t`onboarding-getting_started-step_channel-explainer-text`}
							</Typography>
						</Grid>

						<Grid
							item
							className={classes.paragraph}
						>
							<img src={'/assets/img/r/onboarding/onboarding_flow_explainer.svg'} />
						</Grid>

						<Grid
							item
							className={classes.paragraph}
						>
							<Typography fontWeight="bold">
								{t`onboarding-getting_started-step_channel-explainer-collapse_text`}
							</Typography>
						</Grid>

						<Grid item>
							<CollapsibleBox
								label={
									<Typography>
										{t`onboarding-getting_started-step_channel-explainer-collapse_web-title`}
									</Typography>
								}
								startCollapsed
								mb={3}
							>
								<Typography>
									{t`onboarding-getting_started-step_channel-explainer-collapse_web-text`}
								</Typography>
							</CollapsibleBox>
							<CollapsibleBox
								label={
									<Typography>
										{t`onboarding-getting_started-step_channel-explainer-collapse_app-title`}
									</Typography>
								}
								startCollapsed
								mb={3}
							>
								<Typography>
									{t`onboarding-getting_started-step_channel-explainer-collapse_app-text`}
								</Typography>
							</CollapsibleBox>
							<CollapsibleBox
								label={
									<Typography>
										{t`onboarding-getting_started-step_channel-explainer-collapse_email-title`}
									</Typography>
								}
								startCollapsed
								mb={3}
							>
								<Typography>
									{t`onboarding-getting_started-step_channel-explainer-collapse_email-text`}
								</Typography>
							</CollapsibleBox>
						</Grid>

						<Grid>
							<Typography>
								{t`onboarding-getting_started-step_channel-explainer-more`}
							</Typography>
							<KnowledgeBaseLink
								dataTrackEvent="onboarding_knowledgebase_deployments"
								className={classes.colorWhite}
								page="deployments"
							>
								{t`onboarding-getting_started-step_channel-explainer-knowledgebase_link`}
							</KnowledgeBaseLink>
						</Grid>
					</Grid>
				</>
			}
		>
			{t`onboarding-getting_started-step_channel-explainer-link`}
		</Explainer>
	);
}
