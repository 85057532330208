import React, { forwardRef } from 'react';

import MuiLink from '@material-ui/core/Link';

import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import RenderConditional from './RenderConditional';
import Tooltip from './Tooltip';

const useStyles = makeStyles(theme => ({
	link: {
		cursor: 'pointer',
		textDecoration: 'underline',
		fontFamily: 'inherit',
		marginTop: props => (props.mt ? theme.spacing(props.mt) : null),
		marginBottom: props => (props.mb ? theme.spacing(props.mb) : null),
		textAlign: props => (props.alignLink ? props.alignLink : 'center'),
	},
	fontWeight: {
		fontWeight: props => {
			switch (props.fontWeight) {
				case 'regular':
					return theme.typography.fontWeightRegular;
				case 'bold':
					return theme.typography.fontWeightBold;
				case 'light':
					return theme.typography.fontWeightLight;
				case 'medium':
					return theme.typography.fontWeightMedium;
				default:
					return 'inherit';
			}
		},
	},
}));

export default forwardRef(function Link(
	{ className = '', children, to, tooltip, dataTrackEvent, ...props },
	ref
) {
	const classes = useStyles(props);
	const { t } = useTranslation();

	const LinkProps = {
		...(to && {
			component: RouterLink,
			to,
		}),
		...props,
	};

	return (
		<RenderConditional
			component={Tooltip}
			condition={Boolean(tooltip)}
			title={tooltip}
		>
			<MuiLink
				data-track-event={dataTrackEvent}
				className={`${classes.link} ${classes.fontWeight} ${className}`}
				underline="always"
				variant="inherit"
				{...LinkProps}
				ref={ref}
			>
				{children}
			</MuiLink>
		</RenderConditional>
	);
});
