import React from 'react';
import { useEFM } from '../Ajax';

export default function useStandardAnswers(id) {
	const [data, loading, error, loadData] = useEFM(
		`/application/email/${id ? 'get-' : ''}answer`,
		id ? { id } : {}
	);

	return [data, loading, error, loadData];
}
