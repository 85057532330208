import React, { useState, useEffect, Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

import { useDataSourceContext } from '@/components/DataSources';
import { AlertContextProvider } from './AlertContext';
import AlertCard from './AlertCard';
import AddEditAlertDialog from './AddEditAlertDialog';
import { useAppContext } from '@/components/AppContext';
import { PageHeader } from '@/components/App';
import { AddCard, EmptyState, ActionButton, Grid } from '@/components/Layout';
import { useMjolnir } from '@/components/Ajax';
import { useTimeTravel } from '@/hooks';
import {
	useHasPermission,
	NoPermissionTooltip,
	permissionModules,
	permissionSubjects,
} from '@/components/Permission';

const useStyles = makeStyles(theme => ({
	editorAreaWrap: {
		minWidth: '80%',
		maxWidth: '80%',
		minHeight: '85%',
		height: '100%',
		overflow: 'visible',
		display: 'flex',
		flexDirection: 'column',
	},
	editorAreaWrapSm: {
		maxWidth: '90%',
	},
	editorAreaWrapXs: {
		maxWidth: '95%',
	},
	editorArea: {
		position: 'relative',
		flexGrow: 1,
		borderRadius: theme.shape.borderRadius,
		border: `3px dashed ${theme.palette.grey[300]}`,
		padding: theme.spacing(1),
	},
	centerContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	emptyState: {
		whiteSpace: 'pre-line',
	},
}));

function reducer(state, action) {
	switch (action.type) {
		case 'init_config':
			state.rules = action.payload;
			return;

		case 'add_filled_rule':
			state.rules.push(action.payload.alert);
			return;

		case 'update_rule':
			const ruleIndex = state.rules.findIndex(
				rule => rule.alert_id === action.payload.alert.alert_id
			);
			state.rules[ruleIndex] = action.payload.alert;
			return;

		case 'move_rule':
			state.rules.splice(
				action.payload.destinationIndex,
				0,
				state.rules.splice(action.payload.sourceIndex, 1)[0]
			);
			return;

		case 'delete_rule':
			const index = state.rules.findIndex(rule => rule.alert_id === action.payload.id);
			state.rules.splice(index, 1);
			return;

		default:
			return;
	}
}

export default function AlertList(props) {
	const { app } = useAppContext();
	const { datasource } = useDataSourceContext();
	const { t } = useTranslation();
	const classes = useStyles();
	const [addOpen, setAddOpen] = useState(false);
	const hasEditPermission = useHasPermission();
	const hasSurveyEditPermission = useHasPermission({
		module: permissionModules.data_collection,
		subject: permissionSubjects.feedback_forms_build,
	});
	const [alertConfig, configLoading] = useMjolnir(
		`/api/1/alerts/${app.domain}/${app.projects.current.id}`
	);
	const { state, dispatch, doUndo, doRedo } = useTimeTravel(reducer, {
		rules: [],
	});

	useEffect(() => {
		if (alertConfig.alerts) {
			dispatch({
				type: 'init_config',
				payload: alertConfig.alerts,
			});
		}
	}, [alertConfig]);

	return (
		<Fragment>
			<PageHeader title={t`Alerts`} />

			<AlertContextProvider
				value={{
					state: {
						config: state.rules,
						configLoading,
					},
					api: {
						dispatch,
						doUndo,
						doRedo,
						//		saveConfig
					},
				}}
			>
				{state.rules.length > 0 && (
					<Grid
						container
						spacing={3}
					>
						<Grid
							item
							md={3}
							sm={4}
							xs={6}
						>
							<NoPermissionTooltip>
								<AddCard
									onClick={() => setAddOpen(true)}
									label={t`New alert`}
									maxReached={state.rules.length >= app.package.alerts}
									subject={t`alerts`}
									dataTestElement="alertsNewAlert"
									disabled={!hasEditPermission}
								/>
							</NoPermissionTooltip>
						</Grid>
						{state.rules.map(alert => (
							<Grid
								item
								md={3}
								sm={4}
								xs={6}
								key={alert.alert_id}
							>
								<AlertCard alert={alert} />
							</Grid>
						))}
					</Grid>
				)}
				{state.rules.length === 0 && datasource.forms.asArray.length === 0 && (
					<EmptyState
						className={classes.emptyState}
						image={<img src={`/assets/img/r/emptystate/email_alerts.gif`} />}
						primary={t`settings-email_alerts_emptystate_no_survey-title`}
						secondary={t`settings-email_alerts_emptystate_no_survey-text`}
						action={
							<NoPermissionTooltip>
								<ActionButton
									variant="contained"
									color="primary"
									dataTrackEvent="alerts_emptystate_create_form"
									link
									to="/data-collection/survey/edit/new"
									disabled={!hasSurveyEditPermission}
								>
									{t`settings-email_alerts_emptystate_no_survey-button_create_survey`}
								</ActionButton>
							</NoPermissionTooltip>
						}
					/>
				)}
				{state.rules.length === 0 && datasource.forms.asArray.length > 0 && (
					<EmptyState
						className={classes.emptyState}
						image={<img src={`/assets/img/r/emptystate/email_alerts.gif`} />}
						primary={t`settings-email_alerts_emptystate_default-title`}
						secondary={t`settings-email_alerts_emptystate_default-text`}
						action={
							<NoPermissionTooltip>
								<ActionButton
									variant="contained"
									color="primary"
									onClick={() => setAddOpen(true)}
									disabled={!hasEditPermission}
								>
									{t`settings-email_alerts_emptystate_default-button_create_alert`}
								</ActionButton>
							</NoPermissionTooltip>
						}
					/>
				)}

				<AddEditAlertDialog
					key="add_alert"
					open={addOpen}
					onClose={() => setAddOpen(false)}
				/>
			</AlertContextProvider>
		</Fragment>
	);
}
