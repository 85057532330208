import React from 'react';

import { useFeatureToggleContext } from './FeatureToggleContext';

export default function useFeatureToggle(feature) {
	const features = useFeatureToggleContext();
	try {
		return features.includes(feature);
	} catch (e) {
		return false;
	}
}
