import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';
import { useAjaxForm } from '@/components/Ajax';
import { ruleBase } from './deploymentUtils';
import { useOnboardingContext } from '@/components/Onboarding';

export default function useCreateNewDeployment({
	type,
	name,
	onSuccess,
	deployments = [],
	surveyKey,
}) {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const onboarding = useOnboardingContext();

	const [maxReached, setMaxReached] = useState(true);

	useEffect(() => {
		setMaxReached(deployments >= app.package.deployments);
	}, [app.package.deployments, deployments]);

	const deploymentName =
		name ??
		`${type === 'sdk' ? t`In-App Feedback Deployment` : t`Web Feedback Deployment`}`;

	const saveData = onboarding.trial?.current
		? {
				name: deploymentName,
				type,
				rules: [
					ruleBase({
						surveyKey,
						domain: app.domain,
						editorType: type,
						surveyType: type,
					}),
				],
		  }
		: {
				name: deploymentName,
				type,
		  };

	const { postForm, loading } = useAjaxForm({
		url: `/api/1/pastease/${app.domain}/${app.organisations.current.org_id}`,
		type: 'mjolnir',
		data: saveData,
		onSuccess,
	});

	function createNewDeployment() {
		if (!maxReached) {
			postForm();
		}
	}

	return {
		createNewDeployment,
		maxReached,
		createNewDeploymentLoading: loading,
	};
}
