import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { EFM } from '../../api';
import { useTranslation } from 'react-i18next';
import { ButtonWithLoader } from '../Layout';
import { AjaxForm } from '../Ajax';
import { useSnackbar } from 'notistack';

export default function AjaxFormDialog({
	url = '',
	data = {},
	onSuccess = () => {},
	valid,
	AjaxFormProps = {},
	open,
	onClose,
	title,
	render,
	content,
	text,
	submit,
	successMessage,
	dataTestElement = null,
	dataTrackEvent = null,
	dataTrackEventProps = {},
}) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const combinedAjaxFormProps = {
		url,
		data,
		onSuccess: r => {
			onSuccess(r);
			if (successMessage) {
				enqueueSnackbar(successMessage);
			}
		},
		valid,
		...AjaxFormProps,
	};

	return (
		<AjaxForm {...combinedAjaxFormProps}>
			{({ clicked, loading, postForm }) => (
				<Dialog
					open={Boolean(open)}
					onClose={onClose}
					maxWidth="sm"
					fullWidth
				>
					<DialogTitle>{title ? title : t('Delete items')}</DialogTitle>
					<DialogContent>
						{render && render({ clicked, loading, postForm })}
						{content && content}
						{!content && !render && (
							<DialogContentText>
								{text ? text : t('Delete selected items?')}
							</DialogContentText>
						)}
					</DialogContent>
					<DialogActions>
						<Button
							onClick={onClose}
							color="default"
						>
							{t('Cancel')}
						</Button>
						<ButtonWithLoader
							onClick={postForm}
							color="primary"
							variant="contained"
							loading={loading}
							data-test-element={dataTestElement}
							data-track-event={dataTrackEvent}
							data-track-event-props={JSON.stringify(dataTrackEventProps)}
						>
							{submit ? submit : t('Delete')}
						</ButtonWithLoader>
					</DialogActions>
				</Dialog>
			)}
		</AjaxForm>
	);
}
