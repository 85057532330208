import React from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ActionIconButton } from '../Layout';
import { useWebHookContext } from './WebHookContext';
import { useMjolnir } from '../Ajax';
import { TagsAutoComplete } from '../AutoComplete';

import { useTranslation } from 'react-i18next';

export default function JiraRow({
	auth_id,
	projectKey,
	issueType,
	parent_id,
	labels,
	onChangeProject,
	onChangeIssue,
	onChangeParentId,
	onChangeLabel,
	onRemoveRow,
	index,
}) {
	const { t } = useTranslation();
	const { state } = useWebHookContext();

	const auth = state.auths.find(auth => auth.id === auth_id) ?? {};
	const [data = []] = useMjolnir(
		`/jira-auth/projects?refresh-token=${auth.fields?.refresh_token}&add_issues=false`
	);
	const [projectData = {}] = useMjolnir(
		`/jira-auth/project/issues?refresh-token=${auth.fields?.refresh_token}&add_issues=false&project_key=${projectKey}`,
		{},
		!projectKey
	);
	const project = Array.isArray(data) ? data.find(proj => proj.key === projectKey) : {};

	const issueIsSubTask = projectData?.meta?.find(issue => {
		return typeof issue === 'object' && issue.subtask && issue.name === issueType;
	});
	const gridSize = issueIsSubTask ? 6 : 4;

	return (
		<Grid
			container
			spacing={1}
			alignItems="center"
		>
			<Grid
				item
				xs
			>
				<Grid
					container
					spacing={1}
				>
					<Grid
						item
						xs={gridSize}
					>
						<TextField
							select
							value={projectKey}
							onChange={onChangeProject}
							label={t`Project`}
							fullWidth
						>
							{Array.isArray(data)
								? data.map(item => (
										<MenuItem
											key={item.key}
											value={item.key}
										>
											{item.name}
										</MenuItem>
								  ))
								: []}
						</TextField>
					</Grid>

					<Grid
						item
						xs={gridSize}
					>
						<TextField
							select
							value={issueType}
							onChange={onChangeIssue}
							label={t`Issue type`}
							fullWidth
						>
							{projectData?.meta ? (
								projectData.meta.map(issue => {
									const value = typeof issue === 'object' ? issue.name : issue;
									return (
										<MenuItem
											key={value}
											value={value}
										>
											{value}
										</MenuItem>
									);
								})
							) : (
								<MenuItem
									value=""
									disabled
								>{t`Select a project`}</MenuItem>
							)}
						</TextField>
					</Grid>

					{issueIsSubTask && (
						<Grid
							item
							xs={gridSize}
						>
							<TextField
								label={t`Parent issue ID`}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">{projectKey} -</InputAdornment>
									),
								}}
								value={parent_id}
								onChange={onChangeParentId}
								fullWidth
								type="number"
							/>
						</Grid>
					)}

					<Grid
						item
						xs={gridSize}
					>
						<TagsAutoComplete
							label={t`Add labels to issue`}
							selectedItem={labels?.map(label => ({ label }))}
							onChange={onChangeLabel}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<ActionIconButton
					action="remove_list"
					onClick={onRemoveRow}
					size="small"
				/>
			</Grid>
		</Grid>
	);
}
