import React from 'react';

import { makeStyles } from '@/styles';

import { Box, Typography, Grid } from '@/components/Layout';
import UpgradeButton from './UpgradeButton';

const useStyles = makeStyles(theme => ({
	overlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		background: 'rgba(255,255,255,.80)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		padding: theme.spacing(1),
	},
	message: {
		marginBottom: theme.spacing(2),
	},
	alignOverlay: {
		textAlign: 'left',
		marginLeft: theme.spacing(4),
	},
	backgroundContrast: {
		background: 'rgba(247,247,247, .8)',
	},
}));

export default function UpgradeOverlay({
	children,
	needsUpgrade,
	message = '',
	explainer,
	product,
	inline,
	backgroundContrast,
	size = 'medium',
	...props
}) {
	const classes = useStyles();

	return !needsUpgrade ? (
		children
	) : (
		<Box
			position="relative"
			{...props}
		>
			{children}
			<div
				className={`${classes.overlay} ${
					backgroundContrast ? classes.backgroundContrast : ''
				}`}
			>
				{inline && (
					<Grid
						container
						alignItems="center"
					>
						<Grid
							item
							xs
							className={classes.alignOverlay}
						>
							<Typography variant={size === 'small' ? 'subtitle2' : 'subtitle'}>
								{message}
							</Typography>
							{explainer}
						</Grid>
						<Grid item>
							<UpgradeButton
								size={size}
								mr={size === 'small' ? 2 : 4}
								ml={size === 'small' ? 2 : 4}
								data-track-event={`upgrade_from_upgrade_overlay_${product}`}
							/>
						</Grid>
					</Grid>
				)}
				{!inline && (
					<>
						<Typography variant={size === 'small' ? 'subtitle2' : 'subtitle'}>
							{message}
						</Typography>
						{explainer}
						<UpgradeButton
							size={size}
							mt={3}
							data-track-event={`upgrade_from_upgrade_overlay_${product}`}
						/>
					</>
				)}
			</div>
		</Box>
	);
}
