import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { useImmer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { ValidatedField } from '../Validation';
import { Typography, Link, Grid, Alert, ActionButton } from '../Layout';
import { useAjaxForm } from '../Ajax';
import { loginMessages } from './constants';

const useStyles = makeStyles(theme => ({
	inputPanel: {
		maxWidth: 500,
	},
}));

export default function ForgotPassword(props) {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	useEffect(() => {
		document.title = `${t`forgot_password_document_title`} | Mopinion`;
	}, []);

	const [state, setState] = useImmer({
		email: '',
		emailValid: false,
	});

	const { postForm, loading, response, clicked, error } = useAjaxForm({
		url: '/auth/ajax/reset-password',
		data: {
			username: state.email,
		},
		valid: () => state.emailValid,
	});

	const errorStatusCode = response.error ?? error?.response?.data?.message;

	return (
		<div className={classes.inputPanel}>
			<form>
				<Grid
					container
					direction="row"
					spacing={3}
				>
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="h4"
							gutterBottom
						>
							{t`password_title`}
						</Typography>
						<Typography variant="body1">{t`password_subtitle`}</Typography>
					</Grid>

					{response.code === 200 && (
						<Grid
							item
							xs={12}
						>
							<Alert
								variant="filled"
								data-test-element="forgot-pass-requested-alert"
								close
							>
								{t`password_response`}
							</Alert>
						</Grid>
					)}

					{errorStatusCode && loginMessages[errorStatusCode] && (
						<Grid
							item
							xs={12}
						>
							<Alert
								variant="filled"
								severity={loginMessages[errorStatusCode]?.severity}
								title={t(loginMessages[errorStatusCode]?.title)}
							>
								{t(loginMessages[errorStatusCode]?.message)}
							</Alert>
						</Grid>
					)}

					<Grid
						item
						xs={12}
					>
						<ValidatedField
							variant="outlined"
							fullWidth
							label={t`forgot_password_inputlabel_email_address`}
							data-test-element="forgot-pass-email"
							value={state.email}
							showErrors={clicked}
							rules={[
								['required', true],
								['email', true],
							]}
							messages={{
								required: t`errormessage_email_required`,
								email: t`errormessage_email_invalid`,
							}}
							autoFocus
							onChange={e => {
								const { value } = e.currentTarget;
								setState(draft => {
									draft.email = value;
								});
							}}
							onValidChange={e => {
								setState(draft => {
									draft.emailValid = e;
								});
							}}
							onKeyPress={e => {
								if (e.key === 'Enter') {
									e.preventDefault();
									postForm();
								}
							}}
						/>
					</Grid>

					<Grid
						item
						xs={12}
					>
						<ActionButton
							fullWidth
							size="large"
							variant="contained"
							color="primary"
							data-test-element="forgot-pass-submit"
							onClick={() => {
								postForm();
							}}
							loading={loading}
						>
							{t`password_reset_password`}
						</ActionButton>
					</Grid>

					<Grid
						item
						xs={12}
					>
						<Tooltip
							title={t`password_login_tooltip`}
							placement="bottom"
						>
							<Link
								variant="body2"
								to="/login"
							>
								{t`password_login`}
							</Link>
						</Tooltip>
					</Grid>
				</Grid>
			</form>
		</div>
	);
}
