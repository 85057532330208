import React, { Component } from 'react';
import ChartEditor from '../../components/Charts/ChartEditor';

function DataExplorerPage() {
	return (
		<ChartEditor
			dataExplorer
			isPage={true}
		/>
	);
}
export default DataExplorerPage;
