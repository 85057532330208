import React from 'react';

import { useAjaxForm } from '../Ajax';

export default function useDeleteCharts({ chartIds, onSuccess = () => {} }) {
	const action = useAjaxForm({
		url: '/dashboard/ajax/delete-charts',
		data: {
			charts: chartIds,
		},
		onSuccess: r => {
			onSuccess(r);
		},
	});
	return action;
}
