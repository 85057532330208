import { useState, useEffect, useContext, useRef } from 'react';
import { AppContext } from '../AppContext';
import { EFM } from '../../api';
import { tryParse } from '../../utils';
import axios, { CancelToken } from 'axios';
import objectToFormData from 'object-to-formdata';
import { useSessionStorage } from '../../hooks';

const instance = axios.create({
	headers: {
		'X-Requested-With': 'XMLHttpRequest',
		'X-Requested-By-React': 'true',
	},
});

function useMjolnir(url, params = {}, wait) {
	const {
		app,
		app: { users, projects, organisations, date, tokens },
		cancelPending,
	} = useContext(AppContext);
	const [storageData, setStorageData] = useSessionStorage(
		projects.current.id + url + Object.values(params).join('-')
	);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [data, setData] = useState(storageData ? tryParse(storageData) : {});
	const [didInitialFetch, setDidInitialFetch] = useState(false);
	const cancel = useRef(() => {});

	const cancelCurrent = () => {
		try {
			cancel.current();
		} catch (e) {}
	};

	function loadData() {
		cancelCurrent();
		setLoading(true);
		instance
			.post(
				'/api/mjolnir',
				objectToFormData({
					method: 'get',
					path: url.startsWith('/') ? url : `/api/1/config/${url}`,
					token: tokens.csrf,
					...(params && { state: params }),
				}),
				{ cancelToken: new CancelToken(cancelToken => (cancel.current = cancelToken)) }
			)
			.then(r => {
				if (r.data.code === 204) {
					app.dispatch({
						type: 'set_logged_out',
						payload: r.data.logout_reason,
					});
					return;
				}

				setData(r.data);
				setStorageData(JSON.stringify(r.data));
				setDidInitialFetch(true);
			})
			.catch(e => setError(e))
			.finally(r => setLoading(false));
	}

	useEffect(() => {
		if (cancelPending) {
			cancelCurrent();
		}
	}, [cancelPending]);

	useEffect(() => {
		if (!wait) {
			loadData();
		}
		return () => {
			cancelCurrent();
		};
	}, [
		url,
		JSON.stringify(params),
		users.current.id,
		projects.current.id,
		organisations.current.org_id,
		date.fromDate,
		date.toDate,
		wait,
	]);

	return [data, loading, error, loadData, setData, didInitialFetch];
}

export default useMjolnir;
