import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getProgressColor } from './actionUtils';

const useStyles = makeStyles(theme => ({
	progress: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		background: 'transparent',
	},
	bar: {
		backgroundColor: props => getProgressColor(props.status_id, theme),
	},
	wrap: {
		display: 'flex',
		flexWrap: 'nowrap',
	},
	row: {
		width: 32,
		marginRight: theme.spacing(1),
		height: 4,
		borderRadius: 4,
	},
	assigned: {
		backgroundColor: props => getProgressColor(1, theme),
	},
	accepted: {
		backgroundColor: props => getProgressColor(2, theme),
	},
	done: {
		backgroundColor: props => getProgressColor(3, theme),
	},
	completed: {
		backgroundColor: props => getProgressColor(4, theme),
	},
	declined: {
		backgroundColor: props => getProgressColor(5, theme),
	},
}));
export default function ActionProgress({ status_id }) {
	const classes = useStyles({ status_id: Number(status_id) });
	const states = ['assigned', 'accepted', 'done', 'completed', 'declined'];
	return (
		<div className={classes.wrap}>
			{Number(status_id) === 5 ? (
				<Fragment>
					<div
						className={`${classes.row} ${classes['assigned']}`}
						key={'assigned'}
					/>
					<div
						className={`${classes.row} ${classes['declined']}`}
						key={'declined'}
					/>
				</Fragment>
			) : (
				states.map((state, index) => {
					return Number(status_id) >= index + 1 ? (
						<div
							className={`${classes.row} ${classes[state]}`}
							key={state}
						/>
					) : null;
				})
			)}
		</div>
	);
}
