import React, { useState, useEffect } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

function InlineToggles({ currentState, onChange, ...props }) {
	//const { bold, italic, underline } = currentState;
	const [selected, setSelected] = useState({});
	useEffect(() => {
		setSelected(currentState);
	}, [currentState]);

	function handleChange(event, selectedArray) {
		for (let k in selected) {
			if (selected[k] && !selectedArray.includes(k)) {
				onChange(k, false);
			} else if (!selected[k] && selectedArray.includes(k)) {
				onChange(k, true);
			}
		}
	}

	const { t } = useTranslation();
	const value = Object.keys(selected).filter(type => selected[type]);

	return (
		<ToggleButtonGroup
			size="small"
			value={value}
			onChange={handleChange}
		>
			<ToggleButton value="bold">
				<Tooltip title={t`Bold`}>
					<FormatBoldIcon />
				</Tooltip>
			</ToggleButton>

			<ToggleButton value="italic">
				<Tooltip title={t`Italic`}>
					<FormatItalicIcon />
				</Tooltip>
			</ToggleButton>

			<ToggleButton value="underline">
				<Tooltip title={t`Underline`}>
					<FormatUnderlinedIcon />
				</Tooltip>
			</ToggleButton>
		</ToggleButtonGroup>
	);
}

export default InlineToggles;
