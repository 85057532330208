import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { EFM } from '@/api';
import { SimpleDialog, Grid, TextField } from '@/components/Layout';
import { UserAutocomplete } from '@/components/Users';

const useStyles = makeStyles(theme => ({
	fixColumn: {
		'& > div': {
			width: '100%',
		},
	},
}));

export default function ActionReassignDialog({
	ids = [],
	onClose = () => {},
	onSuccess = () => {},
	...props
}) {
	const { t } = useTranslation();
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [finished, setFinished] = useState([]);
	const [form, setForm] = useState({
		agent_user_id: '',
		comment: '',
	});

	useEffect(() => {
		if (loading && finished.length >= ids.length) {
			setLoading(false);
			setFinished([]);
			setForm({
				agent_user_id: '',
				comment: '',
			});
			onSuccess();
		}
	}, [finished, ids]);

	function postForm() {
		setLoading(true);
		ids.forEach(id => {
			EFM.post(`/actions/ajax/reassign`, {
				id: id,
				agent_user_id: form.agent_user_id,
				comment: form.comment,
			}).then(r => {
				setFinished(prev => [id, ...prev]);
			});
		});
	}

	return (
		<SimpleDialog
			onClose={onClose}
			{...props}
			title={
				<>
					{t`Reassign`} {ids.length === 1 ? t`action` : t`actions`}
				</>
			}
			onSubmit={postForm}
			loading={loading}
			dataTrackEvent="action_action_reassigned"
			submit={t('Reassign')}
		>
			<Grid
				container
				spacing={3}
				direction="column"
				className={classes.fixColumn}
			>
				<Grid item>
					<UserAutocomplete
						autoFocus
						label={t`Reassign to`}
						value={form.agent_user_id}
						getByProp="id"
						onChange={(e, user) => {
							setForm(form => ({
								...form,
								agent_user_id: user?.id,
							}));
						}}
					/>
				</Grid>
				<Grid item>
					<TextField
						label={t`Comment`}
						placeholder={t`I reassigned this to you because...`}
						value={form.comment}
						onChange={e => {
							const { value } = e.currentTarget;
							setForm(form => ({
								...form,
								comment: value,
							}));
						}}
						fullWidth
					/>
				</Grid>
			</Grid>
		</SimpleDialog>
	);
}
