import React, { Component, Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { TransitionGroup } from 'react-transition-group';
import { withSnackbar } from 'notistack';

import produce from 'immer';
import get from 'lodash.get';
import { withTranslation } from 'react-i18next';

import {
	ActionButton,
	ActionIconButton,
	Card,
	CardHeader,
	Typography,
	Grid,
	FormattedDate,
} from '@/components/Layout';
import { withAppContext } from '@/components/AppContext';
import { EFM } from '@/api';
import { AddCommentBar } from '@/components/Conversations';
import { UserAvatarGroup } from '@/components/Users';
import { getHours, getMinutes, format, isSameDay } from '@/utils/date';

import Note from './Note';
import FollowersDialog from './FollowersDialog';

const styles = theme => ({
	postNoteAction: {
		padding: theme.spacing(0, 3, 1),
	},
	followers: {
		padding: theme.spacing(1, 3),
	},
	innerNote: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		'&:last-of-type': {
			paddingBottom: theme.spacing(3),
		},
	},
	borderedCard: {},
	buttonRow: {
		paddingTop: theme.spacing(1),
	},
});

class NotesCard extends Component {
	static defaultProps = {
		isElevated: true,
		notes: [],
	};

	state = {
		addedFollowers: [],
		notes: [...this.props.notes],
	};

	componentDidUpdate(prevProps) {
		if (prevProps.notes.length !== this.props.notes.length) {
			this.setState({
				notes: [...this.props.notes],
			});
		}
	}

	updateNote = (id, o = {}) => {
		const { notes } = this.state;
		let index = notes.findIndex(n => id == n.id);
		this.setState(
			produce(draft => {
				Object.assign(draft.notes[index], o);
			}),
			s => {
				if (o.save) {
					EFM.post('/application/notes/edit', {
						note_id: id,
						note: notes[index].text,
					}).then(r => {});
				}
			}
		);
	};

	removeNote = noteId => {
		const { notes } = this.state;
		let index = notes.findIndex(n => noteId == n.id);
		this.setState(
			produce(draft => {
				draft.notes.splice(index, 1);
			}),
			() => {
				EFM.post('/application/notes/delete', { note_id: noteId });
			}
		);
	};

	addNote = (note, fn = () => {}) => {
		const {
			app: { users },
			feedback_id,
		} = this.props;
		this.setState(
			produce(draft => {
				draft.notes.push({
					text: note.text,
					user_id: users.current.id,
					user_firstname: get(users, 'current.firstname', ''),
					user_lastname: get(users, 'current.lastname', ''),
					created: new Date(),
					id: Math.random(),
				});
			}),
			() => {
				this.setState({ newNote: '' });
				EFM.post('/application/notes/add', {
					note: note.text,
					feedback_id: feedback_id,
				}).then(r => {
					if (r.code === 200) {
						this.setState(
							produce(draft => {
								draft.notes[draft.notes.length - 1].id = r.note_id;
							})
						);
					}
				});
			}
		);
	};

	setReminder = (id, date) => {
		const { app, enqueueSnackbar, t } = this.props;

		EFM.post('/application/notes/remind', {
			note_id: id,
			date: format(date, app.date.format),
			hour: getHours(date),
			minutes: getMinutes(date),
			action: 'set',
		}).then(r => {
			if (r.code === 200) {
				this.updateNote(id, { reminder: format(date, app.date.format + ' HH:MM') });
				enqueueSnackbar(
					t(`You'll be reminded about this note at {{date}}`, {
						date: format(date, app.date.format),
					})
				);
			}
		});
	};

	removeReminder = id => {
		const { t, enqueueSnackbar } = this.props;
		EFM.post('/application/notes/remind', {
			note_id: id,
			action: 'remove',
		}).then(r => {
			if (r.code === 200) {
				this.updateNote(id, { reminder: null });
				enqueueSnackbar(t`Reminder removed`);
			}
		});
	};

	render() {
		const {
			isElevated,
			classes,
			app: { users },
			note_followers = [],
			feedback_id,
			dataTrackEvent,
			t,
		} = this.props;
		const {
			notes,
			newNote,
			textareaFocus,
			showFollowersDialog,
			addedFollowers = [],
		} = this.state;

		let cardProps = !isElevated ? { elevation: 0, className: classes.borderedCard } : {};
		const allFollowers = [...note_followers, ...addedFollowers];
		const followers = allFollowers.reduce((unique, currentFollower) => {
			if (!unique.some(obj => obj.user_id === currentFollower.user_id)) {
				unique.push(currentFollower);
			}
			return unique;
		}, []);

		return (
			<div>
				<Card
					{...cardProps}
					data-onboarding="notes-card"
				>
					<CardHeader
						titleTypographyProps={{ variant: 'subtitle2' }}
						title={t('Notes')}
					/>

					<div>
						<TransitionGroup>
							{notes.map((note, index) => {
								const showDate =
									index === 0 || isFromDifferentDay(note, notes[index - 1]);

								return (
									<Slide
										key={index}
										direction="up"
										mountOnEnter
										unmountOnExit
									>
										<div>
											{false && (
												<Typography
													display="block"
													align="center"
													variant="caption"
													color="textSecondary"
												>
													<FormattedDate date={note.edited ?? note.created} />
												</Typography>
											)}
											<Note
												{...note}
												updateNote={this.updateNote}
												removeNote={this.removeNote}
												index={index}
												allNotes={notes}
												setReminder={this.setReminder}
												removeReminder={this.removeReminder}
											/>
										</div>
									</Slide>
								);
							})}
						</TransitionGroup>
					</div>

					{
						//<Divider />
					}
					<div className={classes.postNoteAction}>
						<AddCommentBar
							onChange={e => this.setState({ newNote: e.target.value })}
							label={t`Add note`}
							value={newNote}
							onSave={() => this.addNote({ text: newNote })}
							actionBoxProps={{
								p: 0,
							}}
							dataTrackEvent={dataTrackEvent}
							leftBottomGridContent={
								<Fragment>
									{followers.length === 0 && (
										<ActionButton
											action="add"
											onClick={e => this.setState({ showFollowersDialog: true })}
											color="primary"
											title={t('Add followers')}
											size="small"
											variant="text"
										>
											{t(`Followers`)}
										</ActionButton>
									)}
									{followers.length > 0 && (
										<Fragment>
											<Typography
												align="right"
												variant="caption"
												color="textSecondary"
												className={classes.followerCaption}
											>
												{t('Followers')}
											</Typography>
											<Grid
												container
												spacing={0}
											>
												<UserAvatarGroup
													stack={followers}
													size="small"
													direction="rtl"
													tooltip
												/>
												<ActionIconButton
													action="add"
													onClick={e => this.setState({ showFollowersDialog: true })}
													title={t('Add followers')}
													size="small"
													edge="true"
												/>
											</Grid>
										</Fragment>
									)}
								</Fragment>
							}
						/>
					</div>
				</Card>

				<FollowersDialog
					feedback_id={feedback_id}
					open={Boolean(showFollowersDialog)}
					close={e => this.setState({ showFollowersDialog: false })}
					onAdd={ids =>
						this.setState(
							produce(draft => {
								draft.addedFollowers.push(...ids.map(id => ({ user_id: id })));
							})
						)
					}
				/>
			</div>
		);
	}
}

function ensureIsDate(date) {
	if (date instanceof Date) {
		return date;
	}
	return new Date(date);
}

function isFromDifferentDay(currentNote, previousNote) {
	try {
		return !isSameDay(
			ensureIsDate(currentNote?.created),
			ensureIsDate(previousNote?.created)
		);
	} catch (e) {
		return false;
	}
}

export default withAppContext(
	withSnackbar(withStyles(styles)(withTranslation()(NotesCard)))
);
