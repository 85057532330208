import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FormRoundIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			{props.selected ? (
				<g>
					<path d="M11,38c-4.962,0-9-4.037-9-9v-1H0v1c0,6.075,4.925,11,11,11h1v-2H11z" />
					<path d="M2,11c0-4.962,4.038-9,9-9h1V0h-1C4.925,0,0,4.925,0,11v1h2V11z" />
					<path d="M38,29c0,4.963-4.037,9-9,9h-1v2h1c6.075,0,11-4.925,11-11v-1h-2V29z" />
					<path d="M29,2c4.963,0,9,4.038,9,9v1h2v-1c0-6.075-4.925-11-11-11h-1v2H29z" />
				</g>
			) : (
				<g>
					<path d="M11,39C5.486,39,1,34.514,1,29v-1H0v1c0,6.075,4.925,11,11,11h1v-1H11z" />
					<path d="M39,29c0,5.514-4.486,10-10,10h-1v1h1c6.075,0,11-4.925,11-11v-1h-1V29z" />
					<path d="M29,1c5.514,0,10,4.486,10,10v1h1v-1c0-6.075-4.925-11-11-11h-1v1H29z" />
					<path d="M1,11C1,5.486,5.486,1,11,1h1V0h-1C4.925,0,0,4.925,0,11v1h1V11z" />
				</g>
			)}
		</SvgIcon>
	);
}
