import React from 'react';

import { EmptyState, ActionButton, UpgradeHelper } from '../../components/Layout';
import DomainDisabled from '@material-ui/icons/DomainDisabled';
import { useTranslation } from 'react-i18next';
export default function PackageExpiredPage() {
	const { t } = useTranslation();
	return (
		<EmptyState
			icon={DomainDisabled}
			primary={t`Account expired`}
			secondary={t`It seems your account is expired. Please purchase a subscription to use Mopinion Raspberry!`}
			action={
				<UpgradeHelper
					useButtons
					upgradeButtonText={t`Purchase subscription`}
					upgradeButtonProps={{
						action: 'renew',
						size: 'medium',
					}}
				/>
			}
		/>
	);
}
