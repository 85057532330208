import React, { useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useQuery } from '@/hooks';

/**
 * Handles initial redirect on page load and after logging in
 * Handles project switch from query string
 *
 * @param {Object} props - Configuration options.
 * @param {boolean} props.loggedIn - Indicates whether the user is logged in.
 * @param {boolean} props.allowChange - Specifies if changing the project is allowed.
 * @param {Function} props.changeProject - Callback function for changing the project.
 * @returns {void}
 */
export default function useRedirector({ loggedIn, allowChange, changeProject }) {
	const location = useLocation();
	const history = useHistory();
	const query = useQuery();
	const projectFromQuery = useRef(null);
	const redirectToAfterLogin = useRef(null);

	useEffect(() => {
		projectFromQuery.current = query.get('project_id');
		if (location.pathname !== '/login') {
			redirectToAfterLogin.current = location.pathname + location.search;
		}
	}, []);

	useEffect(() => {
		if (loggedIn && allowChange && projectFromQuery.current) {
			changeProject(projectFromQuery.current);
		}
	}, [loggedIn, allowChange]);

	useEffect(() => {
		if (loggedIn && redirectToAfterLogin.current) {
			history.push(redirectToAfterLogin.current);
			redirectToAfterLogin.current = null;
		}
	}, [loggedIn, history]);
}
