import React, { useState, useEffect, useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PreviewSidebar from './PreviewSidebar';
import PreviewContent from './PreviewContent';
import useGetSurveySettings from './useGetSurveySettings';
import { mapSurveySettings } from './previewUtils';

import { Grid, SimpleAppBar } from '@/components/Layout';
import { useHasPermission } from '@/components/Permission';

const useStyles = makeStyles(theme => ({
	displayFlex: {
		display: 'flex',
	},
	modalHeight: {
		marginTop: 0,
		height: 'calc(100vh - 64px)',
	},
	pageHeight: {
		marginTop: 0,
		height: '100vh',
	},
	hidden: {
		overflow: 'hidden',
	},
}));

export default function SurveyPreview({
	inDialog,
	surveyKeyFromProps,
	pendingChanges = false,
	surveySettingsFromProps,
	updateSurveySettings = () => {},
	handleClose = () => {},
}) {
	const { t } = useTranslation();
	const classes = useStyles();

	const { surveyKey: surveyKeyFromRoute } = useParams();

	const surveyKey = surveyKeyFromProps ?? surveyKeyFromRoute;
	const [data] = useGetSurveySettings({ surveyKey, wait: inDialog });

	const surveySettings = useMemo(() => {
		return (
			surveySettingsFromProps ?? mapSurveySettings(surveyKey, data.survey, data.blocks)
		);
	}, [surveySettingsFromProps, surveyKey, data]);

	const { surveyId, campaign, formLocked } = surveySettings;

	const hasEditPermission = useHasPermission({
		module: 'data_collection',
		subject: 'feedback_forms_build',
	});

	const [viewType, setViewType] = useState(campaign === 'sdk' ? 'mobile' : 'desktop');
	const [selectedLanguage, setSelectedLanguage] = useState('default');

	useEffect(() => {
		setViewType(campaign === 'sdk' ? 'mobile' : 'desktop');
	}, [campaign]);

	return (
		<>
			<Grid container>
				<Grid
					item
					xs={12}
					className={`${classes.hidden}`}
				>
					<SimpleAppBar
						title={t`data_collection-preview-title`}
						documentTitle={t`data_collection-preview-document_title`}
						withLogo={!surveyKeyFromProps ? true : false}
						backTo={!surveyKeyFromProps ? '/data-collection/survey' : null}
						actions={
							surveyKeyFromProps
								? [
										...(pendingChanges
											? [
													{
														action: () => {
															handleClose(true);
														},
														color: 'secondary',
														icon: 'save',
														text: t`data_collection-preview-button-save_and_close`,
														locked: !hasEditPermission,
													},
											  ]
											: []),
										{
											action: () => {
												handleClose(false);
											},
											text: t`data_collection-preview-button-close`,
										},
								  ]
								: [
										{
											to: `/data-collection/survey/edit/${surveyId}/${campaign}/detail`,
											icon: !hasEditPermission || formLocked ? 'locked' : 'edit',
											text:
												!hasEditPermission || formLocked
													? t`data_collection-preview-button-locked`
													: t`data_collection-preview-button-edit`,
											locked: !hasEditPermission || formLocked,
										},
								  ]
						}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					className={`${classes.displayFlex} ${classes.hidden} ${
						surveyKeyFromProps ? classes.modalHeight : classes.pageHeight
					}`}
				>
					<>
						<PreviewContent
							surveySettings={surveySettings}
							viewType={viewType}
							setViewType={setViewType}
							selectedLanguage={selectedLanguage}
						/>
						<PreviewSidebar
							surveyEditor={surveyKeyFromProps ? true : false}
							surveySettings={surveySettings}
							viewType={viewType}
							setViewType={setViewType}
							selectedLanguage={selectedLanguage}
							setSelectedLanguage={setSelectedLanguage}
							updateSurveySettings={updateSurveySettings}
						/>
					</>
				</Grid>
			</Grid>
		</>
	);
}
