import React, { useState, useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import FolderOpenSharpIcon from '@material-ui/icons/FolderOpenSharp';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';
import {
	InputAdornment,
	TextField,
	MenuItem,
	ListSubheader,
	Tooltip,
} from '@/components/Layout';
import {
	usePermissionContext,
	NoProjectPermissionTooltip,
} from '@/components/Permission';

const useStyles = makeStyles(theme => ({
	select: {
		fontSize: theme.typography.body2.fontSize,
	},
	subheader: {
		backgroundColor: '#fff',
		pointerEvents: 'none',
	},
}));

export default function ProjectFilter() {
	const {
		app,
		app: { projects, layout },
	} = useAppContext();

	const [tooltipOpen, setTooltipOpen] = useState(false);
	const handleTooltip = bool => {
		setTooltipOpen(bool);
	};
	const permission = usePermissionContext();
	const { t } = useTranslation();
	const classes = useStyles();

	const projectPermissions = useMemo(
		() =>
			projects.asArray.reduce(
				(acc, project) => {
					try {
						if (permission.projects?.includes(project.uuid)) {
							acc.allowed.push(project);
						} else {
							acc.notAllowed.push(project);
						}
					} catch (e) {}

					return acc;
				},
				{
					allowed: [],
					notAllowed: [],
				}
			),
		[projects, permission]
	);

	const handleChangeProject = e => {
		const projectId = e.target.value;
		const selectedProject = projectPermissions.allowed.find(
			project => project.id == projectId
		);
		if (selectedProject) {
			app.api.changeProject(selectedProject);
		}
	};

	return (
		<>
			<Tooltip
				open={tooltipOpen}
				title={t('Change report')}
			>
				<TextField
					select
					size="small"
					variant="outlined"
					value={projects.current.id}
					SelectProps={{
						startAdornment: (
							<InputAdornment position="start">
								<FolderOpenSharpIcon fontSize="small" />
							</InputAdornment>
						),
						className: classes.select,
						displayEmpty: true,
						onOpen: () => handleTooltip(false),
						onMouseEnter: () => handleTooltip(true),
						onMouseLeave: () => handleTooltip(false),
					}}
					onChange={handleChangeProject}
					disabled={layout.appLoading || layout.appLoadingFull}
				>
					<ListSubheader className={classes.subheader}>{t`Select report`}</ListSubheader>
					{projects.asArray.length === 0 ? (
						<MenuItem
							key={projects.current.id}
							value={projects.current.id}
						>
							{projects.current.name}
						</MenuItem>
					) : (
						[
							...projectPermissions.allowed.map(project => {
								return (
									<MenuItem
										key={project.id}
										value={project.id}
									>
										{project.name}
									</MenuItem>
								);
							}),
							...projectPermissions.notAllowed.map(project => {
								return (
									<NoProjectPermissionTooltip
										key={project.id}
										value={project.id}
										project={project}
										placement="left"
									>
										<MenuItem disabled>{project.name}</MenuItem>
									</NoProjectPermissionTooltip>
								);
							}),
						]
					)}
				</TextField>
			</Tooltip>
		</>
	);
}
