import React, { Fragment } from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import Search from '@material-ui/icons/Search';

import { useTranslation } from 'react-i18next';

import { useListFilterContext, FilterGroup } from '../FilteredLists';

function ScheduledListFilters() {
	const { setSingleFilter, setMultiFilter, filters } = useListFilterContext();

	const { t } = useTranslation();

	return (
		<Fragment>
			<div>
				<TextField
					variant="filled"
					fullWidth
					placeholder={t('Search')}
					label={t('Search')}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search />
							</InputAdornment>
						),
					}}
					onChange={e => setSingleFilter('search', e.target.value)}
					value={filters.search ?? ''}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Status')}
					filterKey={'schedule_status'}
					filters={[
						{ label: t('Active'), value: 'active' },
						{ label: t('Inactive'), value: 'inactive' },
					].map(filter => {
						if (filter.value === filters.schedule_status) {
							filter.selected = true;
						}
						filter.key = 'schedule_status' + filter.value;
						return filter;
					})}
					onChange={({ key, value }) => setSingleFilter(key, value)}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Range')}
					filterKey={'export_range'}
					filters={[
						{ value: 'Day', label: t('Daily') },
						{ value: 'Week', label: t('Weekly') },
						{ value: 'Month', label: t('Monthly') },
						// {value:'Quarter',label:t('Quarterly')},
						// {value:'Year',label:t('Yearly')}
					].map(status => {
						if ((filters.export_range || []).includes(status.value)) {
							status.selected = true;
						}
						return status;
					})}
					multiple
					onChange={({ key, value }) => setMultiFilter(key, value)}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Format')}
					filterKey={'export_format'}
					filters={[
						{ value: 'xlsx', label: 'Excel (.xlsx)' },
						{ value: 'csv', label: 'CSV (.csv)' },
						{ value: 'json', label: 'JSON (.json)' },
						{ value: 'html', label: 'HTML (.html)' },
					].map(status => {
						if ((filters.export_format || []).includes(status.value)) {
							status.selected = true;
						}
						return status;
					})}
					multiple
					onChange={({ key, value }) => setMultiFilter(key, value)}
				/>
			</div>
		</Fragment>
	);
}

export default ScheduledListFilters;
