import React, { useState, Fragment, useEffect } from 'react';

import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

import Search from '@material-ui/icons/Search';
import CodeIcon from '@material-ui/icons/Code';
//import StarIcon from '@material-ui/icons/Star';
import FeedbackIcon from '@material-ui/icons/Feedback';
import HintIcon from '@material-ui/icons/FindInPage';
import KBIcon from '@material-ui/icons/ContactSupport';
import BookDemoIcon from '@material-ui/icons/Bookmarks';

import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import {
	ActionIconButton,
	Typography,
	ContactDialog,
	MainMenuListItem,
	Grid,
	Link,
} from '@/components/Layout';
import { useOnboardingContext } from '@/components/Onboarding';
import { useEFM } from '@/components/Ajax';
import { removeArrayDuplicatesFromShallowClone } from '@/utils';

const atlassianBase = 'https://mopinion.atlassian.net/wiki';

const useStyles = makeStyles(theme => ({
	paper: {
		minWidth: 400,
	},
	headerPadding: {
		padding: theme.spacing(2, 2, 0),
	},
	spaceSubheader: {
		marginTop: theme.spacing(1),
	},
	zIndex: {
		zIndex: `${theme.zIndex.modal + 1}!important`,
	},
	footer: {
		marginTop: theme.spacing(2),
		padding: theme.spacing(1.5, 1),
		// backgroundColor:theme.palette.grey[50],
		borderTop: `1px solid ${theme.palette.divider}`,
	},
}));

function getKnowledgebaseLabel(pathname) {
	switch (true) {
		case pathname.indexOf('chart') > -1:
			return ['inapp_charts', 'inapp_charts_overview'];

		case pathname.indexOf('dashboard') > -1:
			return ['inapp_dashboard', 'inapp_dashboard_overview'];

		case pathname.indexOf('reporting/inbox') > -1:
			return ['inapp_inbox', 'inapp_inbox_overview'];

		case pathname.indexOf('customer') > -1:
			return ['inapp_customer', 'inapp_customer_overview'];

		case pathname.indexOf('analytics') > -1:
			return ['inapp_analytics', 'inapp_analytics_overview'];

		case pathname.indexOf('actions') > -1:
			return ['inapp_actions', 'inapp_actions_overview'];

		case pathname.indexOf('survey') > -1:
			for (let type of ['web', 'sdk', 'email']) {
				if (pathname.includes(type)) {
					if (pathname.includes('build')) {
						return [`inapp_survey_${type}_build`, 'inapp_survey_overview'];
					} else if (pathname.includes('design')) {
						return [`inapp_survey_${type}_design`, 'inapp_survey_overview'];
					} else if (pathname.includes('integration')) {
						return [`inapp_survey_${type}_integration`, 'inapp_survey_overview'];
					} else if (pathname.includes('deploy')) {
						return [`inapp_survey_${type}_deploy`, 'inapp_survey_overview'];
					}
				}
			}

			return ['inapp_survey', 'inapp_survey_overview'];

		case pathname.indexOf('dataset') > -1:
			return ['inapp_datasets', 'inapp_datasets_overview'];

		case pathname.indexOf('deployment') > -1:
			return ['inapp_deployment', 'inapp_deployment_overview'];

		case pathname.indexOf('users') > -1:
			return ['inapp_users', 'inapp_users_overview'];

		case pathname.indexOf('reports') > -1:
			return ['inapp_reports', 'inapp_reports_overview'];

		case pathname.indexOf('tags') > -1:
			return ['inapp_tags', 'inapp_tags_overview'];

		case pathname.indexOf('answers') > -1:
			return ['inapp_answers', 'inapp_answers_overview'];

		case pathname.indexOf('alerts') > -1:
			return ['inapp_alerts', 'inapp_alerts_overview'];

		case pathname.indexOf('integrations/api') > -1:
			return ['inapp_integrations_api', 'inapp_integrations_api_overview'];

		case pathname.indexOf('integrations/webhooks') > -1:
			return ['inapp_integrations_webhooks', 'inapp_integrations_webhooks_overview'];

		case pathname.indexOf('account/me') > -1:
			return ['inapp_account', 'inapp_account_overview'];

		case pathname.indexOf('account/my-organisation') > -1:
			return ['inapp_organisations', 'inapp_organisations_overview'];

		default:
			return ['', 'inapp_overview'];
	}
}

function getHintOptions(pathname, params = {}) {
	const hint = (label, step, dataTrackEvent) => ({ label, step, dataTrackEvent });

	const defaultHints = [hint('Main menu and structure', 'menu', 'help_menu_main')];

	const pageHints = () => {
		switch (true) {
			// case pathname.indexOf('chart') > -1:
			// 	return 'chart';
			case pathname.indexOf('reporting/dashboard/edit') > -1:
				return [
					hint(
						'Customizing your dashboard layout',
						'dashboard_layout',
						'help_dashboard_customize'
					),
				];

			case pathname.indexOf('reporting/dashboard') > -1 &&
				pathname.indexOf('reporting/dashboard/list') === -1:
				return [hint('Dashboard', 'dashboard', 'help_dashboard_overview')];

			// case pathname.indexOf('reporting/inbox') > -1:
			// 	return 'feedback';

			// case pathname.indexOf('customer') > -1:
			// 	return 'email';

			// case pathname.indexOf('analytics') > -1:
			// 	return 'analytics';

			// case pathname.indexOf('actions') > -1:
			// 	return 'actions';

			case pathname.indexOf('reporting/inbox') > -1:
				return [hint('Feedback inbox', 'feedback_inbox', 'help_feedback_inbox')];

			case pathname.indexOf('survey/edit') > -1:
				if (pathname.indexOf('detail') > -1) {
					if (pathname.indexOf('design') > -1) {
					} else if (pathname.indexOf('integration') > -1) {
					} else if (pathname.indexOf('deploy') > -1) {
					} else {
						//build step
						return [hint('Form builder', 'survey_editor', 'help_form_builder')];
					}
				}

				if (params.deploymentDrawer) {
					return [
						hint('Deployment manager', 'deployment_editor', 'help_deployment_manager'),
					];
				} else {
					return [];
				}

			// case pathname.indexOf('dataset') > -1:
			// 	return 'import';

			case pathname.indexOf('deployment/edit') > -1:
				return [
					hint('Deployment manager', 'deployment_editor', 'help_deployment_manager'),
				];

			// case pathname.indexOf('users') > -1:
			// 	return 'users';

			// case pathname.indexOf('reports') > -1:
			// 	return 'reports';

			// case pathname.indexOf('tags') > -1:
			// 	return 'tags';

			// case pathname.indexOf('answers') > -1:
			// 	return 'standard_answers';

			// case pathname.indexOf('alerts') > -1:
			// 	return 'alerts';

			// case pathname.indexOf('integrations/api') > -1:
			// 	return 'api';

			// case pathname.indexOf('integrations/webhooks') > -1:
			// 	return 'integrations';

			// case pathname.indexOf('account/me') > -1:
			// 	return 'account';

			// case pathname.indexOf('account/my-organisation') > -1:
			// 	return 'organisations';

			default:
				return [];
		}
	};

	return removeArrayDuplicatesFromShallowClone([
		...defaultHints,
		...pageHints(),
		//	...paramHints()
	]);
}

export default function HelpMenu({
	withText,
	withButtons = true,
	ButtonProps = {},
	anchor: anchorFromProps,
	...props
}) {
	const classes = useStyles();
	const [anchor, setAnchor] = useState(null);
	const { t } = useTranslation();
	const location = useLocation();
	const params = useParams();
	const [searchKB, setSearchKB] = useState([]);
	const onboarding = useOnboardingContext();
	const [bookDemo, setBookDemo] = useState(false);

	useEffect(() => {
		setSearchKB(getKnowledgebaseLabel(location.pathname));
	}, [location.pathname]);

	useEffect(() => {
		if (!withButtons) {
			setAnchor(anchorFromProps);
		}
	}, [anchorFromProps, withButtons]);

	const [{ overview = [], results = [] }, loading] = useEFM(
		'/integrations/ajax/search-knowledgebase',
		{ cql: `label=${searchKB[0]}`, overview: `label=${searchKB[1]}` }
	);

	const extraPages = [
		...(Array.isArray(overview) ? overview : []),
		...(Array.isArray(results) ? results : []),
	]
		.filter(
			(page, index, self) =>
				//remove duplicates
				self.findIndex(pageInSelf => pageInSelf.url === page.url) === index
		)
		.map(page => {
			return {
				label: page.title,
				//		icon:<StarIcon />,
				fn: () => window.open(`${atlassianBase}${page.url}`),
				extra: true,
				dataTrackEvent: `help_knowledgebase_${page.title.replace(/\/| /g, '_')}`,
			};
		});

	const hintOptions = getHintOptions(location.pathname, {
		...params,
		...onboarding.componentStates,
	});

	function helpFn(fn) {
		try {
			fn();
		} catch (e) {}
		setAnchor(null);
	}

	return (
		<Fragment>
			{withText ? (
				<MainMenuListItem
					data-onboarding="help-menu-button"
					action="help"
					onClick={e => setAnchor(e.currentTarget)}
					primary={t`Help`}
					dataTrackEvent="help_help_general"
				/>
			) : (
				<ActionIconButton
					action="help"
					toolip={t`Help & feedback`}
					data-onboarding="help-menu-button"
					onClick={e => setAnchor(e.currentTarget)}
					dataTrackEvent="help_help_general"
					{...ButtonProps}
				/>
			)}
			<Popover
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={e => setAnchor(null)}
				className={classes.zIndex}
				classes={{ paper: classes.paper }}
				{...props}
			>
				<div className={classes.headerPadding}>
					<Typography variant="subtitle">{t`Help & feedback`}</Typography>
				</div>
				<List>
					{hintOptions.length > 0 && (
						<Fragment>
							<ListSubheader>{t`View onpage hints`}</ListSubheader>
							{hintOptions.map(hint => (
								<ListItem
									dense
									button
									key={hint.step}
									data-track-event={hint.dataTrackEvent ?? null}
									onClick={() =>
										helpFn(() =>
											onboarding.dispatch({
												type: 'set_general_onboarding_step',
												payload: {
													step: hint.step,
												},
											})
										)
									}
								>
									<ListItemIcon>
										<HintIcon />
									</ListItemIcon>
									<ListItemText primary={t(hint.label)} />
								</ListItem>
							))}
						</Fragment>
					)}

					<ListSubheader
						className={classes.spaceSubheader}
					>{t`Support documentation`}</ListSubheader>
					{extraPages.map(supportPage => (
						<ListItem
							dense
							button
							onClick={() => helpFn(supportPage.fn)}
							key={supportPage.label}
							data-track-event={supportPage.dataTrackEvent ?? null}
						>
							<ListItemIcon>
								<KBIcon />
							</ListItemIcon>
							<ListItemText primary={supportPage.label} />
						</ListItem>
					))}
					<ListItem
						dense
						button
						data-track-event="help_knowledgebase_search"
						onClick={() =>
							helpFn(() => window.open(`${atlassianBase}/spaces/KB/overview`))
						}
					>
						<ListItemIcon>
							<KBIcon />
						</ListItemIcon>
						<ListItemText primary={t`Search Knowledge Base`} />
					</ListItem>
					<ListItem
						dense
						button
						data-track-event="help_documentation_developer_api"
						onClick={() =>
							helpFn(() => window.open('https://developer.mopinion.com/api/'))
						}
					>
						<ListItemIcon>
							<CodeIcon />
						</ListItemIcon>
						<ListItemText primary={t`Developer API documentation`} />
					</ListItem>

					<ListSubheader className={classes.spaceSubheader}>{t`Feedback`}</ListSubheader>
					<ListItem
						dense
						button
						data-track-event="help_feedback_give_feedback"
						onClick={() => helpFn(() => window.srv.openModal())}
					>
						<ListItemIcon>
							<FeedbackIcon />
						</ListItemIcon>
						<ListItemText primary={t`Give us feedback`} />
					</ListItem>

					{onboarding.isTrial && (
						<Fragment>
							<ListSubheader className={classes.spaceSubheader}>{t`Demo`}</ListSubheader>
							<ListItem
								dense
								button
								data-track-event="help_demo_request"
								onClick={() => helpFn(() => setBookDemo(true))}
							>
								<ListItemIcon>
									<BookDemoIcon />
								</ListItemIcon>
								<ListItemText primary={t`Book a demo`} />
							</ListItem>
						</Fragment>
					)}
				</List>

				<div className={classes.footer}>
					<Grid
						container
						spacing={3}
						justify="center"
					>
						<Grid item>
							<Link
								target="_blank"
								variant="caption"
								color="textSecondary"
								fontWeight="medium"
								data-track-event="help_security_vulnerability"
								href="https://mopinion.com/legal/policies/vulnerability-disclosure-policy-vdp/"
							>
								{t`Report a vulnerability`}
							</Link>
						</Grid>
						<Grid item>
							<Link
								target="_blank"
								variant="caption"
								color="textSecondary"
								fontWeight="medium"
								data-track-event="help_security_data"
								href="https://mopinion.com/legal/security-overview/data-security/"
							>
								{t`Data & Security`}
							</Link>
						</Grid>
						<Grid item>
							<Link
								target="_blank"
								variant="caption"
								color="textSecondary"
								fontWeight="medium"
								data-track-event="help_security_iso"
								href="https://mopinion.com/legal/security-overview/iso/"
							>
								{t`ISO 27001`}
							</Link>
						</Grid>
						<Grid item>
							<Link
								target="_blank"
								variant="caption"
								color="textSecondary"
								fontWeight="medium"
								data-track-event="help_security_privacy"
								href="https://mopinion.com/legal/policies/privacy-statements/"
							>
								{t`Privacy`}
							</Link>
						</Grid>
					</Grid>
				</div>
			</Popover>

			<ContactDialog
				subject={`${t`In-app demo request`} - ${t`I'd like to book a demo`}`}
				open={bookDemo}
				onClose={() => setBookDemo(false)}
				mailTo={'sales@mopinion.com'}
				cc={['marketing@mopinion.com']}
				title={t`Book a demo with our sales team`}
				validate={false}
			/>
		</Fragment>
	);
}
