import React, { useEffect, useState, Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';

import Box from '@material-ui/core/Box';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useAppContext } from '@/components/AppContext';
import {
	EditorOptionList,
	ActionIconButton,
	AjaxFormDialog,
	Typography,
	Grid,
	KnowledgeBaseLink,
} from '@/components/Layout';
import { useTagContext } from './TagContext';
import TagChip from './TagChip';
import { useAjaxForm } from '@/components/Ajax';
import { SelectionAppBar } from '@/components/SelectionToolbar';
import { FeedbackQuickViewDrawer } from '@/components/Feedback';
import {
	NoPermissionTooltip,
	permissionModules,
	permissionSubjects,
	useHasPermission,
} from '@/components/Permission';

const useStyles = makeStyles(theme => ({
	tags: {
		margin: theme.spacing(0.25),
		'&:focus': {
			backgroundColor: '#303031',
		},
	},
	selectedTag: {
		backgroundColor: theme.palette.primary.main,
		'&:focus': {
			backgroundColor: theme.palette.primary.main,
		},
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	header: {
		padding: theme.spacing(2),
		paddingBottom: theme.spacing(0),
	},
	noHoverState: {
		'& div:hover': {
			background: '#fff',
		},
	},
}));

export default function TagsInProjectManager() {
	const classes = useStyles();
	const { t } = useTranslation();
	const { app } = useAppContext();
	const { tags } = useTagContext();
	const { enqueueSnackbar } = useSnackbar();
	const [selectedTags, setSelectedTags] = useState([]);
	const [quickViewOpen, setQuickViewOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [useAI, setUseAI] = useState(false);
	const hasEditPermission = useHasPermission();
	const hasInboxViewPermission = useHasPermission({
		permission: 'view',
		module: permissionModules.reporting,
		subject: permissionSubjects.feedback_inbox,
	});

	useEffect(() => {
		setUseAI(Boolean(Number(app.projects.current.use_ai)));
	}, [app.projects.current.use_ai]);

	const { postForm } = useAjaxForm({
		url: '/application/ajax/update-ai-settings',
		data: {
			use_ai: Boolean(Number(app.projects.current.use_ai)) ? 0 : 1,
		},
		onSuccess: () => app.api.getProjects(),
	});

	function updateAiSettings() {
		setUseAI(!useAI);
		postForm();
	}

	function toggleSelectedTag(tag) {
		setSelectedTags(prevSelectedTags => {
			return prevSelectedTags.indexOf(tag) === -1
				? [...prevSelectedTags, tag]
				: prevSelectedTags.filter(prevTag => prevTag !== tag);
		});
	}
	return (
		<Fragment>
			<Grid
				container
				direction={'column'}
				spacing={3}
			>
				<Grid
					item
					xs={12}
					md={8}
				>
					<Card>
						<CardContent>
							<Typography
								variant="subtitle2"
								gutterBottom
							>{t`Tags in report`}</Typography>

							<Box mt={2}>
								{tags.tagList.map(tag => {
									return (
										<TagChip
											key={tag}
											onClick={() => toggleSelectedTag(tag)}
											label={tag}
											className={`${classes.tags} ${
												selectedTags.indexOf(tag) > -1 ? classes.selectedTag : ''
											}`}
										/>
									);
								})}
								{tags.tagList.length === 0 && (
									<Typography
										variant="body2"
										color="textSecondary"
									>{t`settings-tags-emptystate_default-no_tags`}</Typography>
								)}
							</Box>
						</CardContent>
						<CardActions>
							{selectedTags.length > 0 && (
								<Grid
									container
									direction="row"
									alignItems="center"
									spacing={1}
								>
									<Grid item>
										<ActionIconButton
											action="close"
											tooltip={t`Deselect tags`}
											onClick={() => setSelectedTags([])}
										/>
									</Grid>
									<Grid
										item
										xs
									>
										<Typography variant="h6">
											{t(`{{count}} selected {{tag}}`, {
												count: selectedTags.length,
												tag: selectedTags.length > 1 ? 'tags' : 'tag',
											})}
										</Typography>
									</Grid>
									<Grid item>
										<NoPermissionTooltip
											permission="view"
											module={permissionModules.reporting}
											subject={permissionSubjects.feedback_inbox}
										>
											<ActionIconButton
												action="inbox"
												tooltip={t`View feedback items`}
												onClick={() => setQuickViewOpen(true)}
												disabled={!hasInboxViewPermission}
											/>
										</NoPermissionTooltip>
										<NoPermissionTooltip>
											<ActionIconButton
												action="delete"
												tooltip={t`Delete selected tags`}
												onClick={() => setDeleteDialogOpen(true)}
												data-track-event="settings_tag_deleted"
												disabled={!hasEditPermission}
											/>
										</NoPermissionTooltip>
									</Grid>
								</Grid>
							)}
						</CardActions>
					</Card>
				</Grid>
				<Grid
					item
					xs={12}
					md={8}
				>
					<Paper>
						<Grid
							container
							direction="row"
							alignItems="center"
							className={classes.header}
						>
							<Grid
								item
								xs
							>
								<Typography
									variant="subtitle2"
									gutterBottom
								>{t`A.I. settings`}</Typography>
							</Grid>
							<Grid item>
								<Typography
									component="span"
									variant="subtitle2"
									gutterBottom
								>
									<KnowledgeBaseLink page="tags_mobot">
										{t('How can this help me?')}
									</KnowledgeBaseLink>
								</Typography>
							</Grid>
						</Grid>
						<EditorOptionList
							className={classes.noHoverState}
							options={[
								{
									primary: t`Use Artificial Intelligence to add smart tags to feedback items`,
									secondary: t`MoBot will scan your feedback and add tags to help you categorize your feedback`,
									onClick: e => updateAiSettings(),
									action: <Switch checked={useAI} />,
									lessenTextOpacity: !useAI,
									disabled: !hasEditPermission,
								},
							]}
						/>
					</Paper>
				</Grid>
			</Grid>

			<div>
				<SelectionAppBar
					text={t(`{{count}} selected {{tag}}`, {
						count: selectedTags.length,
						tag: selectedTags.length > 1 ? 'tags' : 'tag',
					})}
					onClose={() => setSelectedTags([])}
					show={selectedTags.length > 0}
					fade
				>
					<NoPermissionTooltip
						permission="view"
						module={permissionModules.reporting}
						subject={permissionSubjects.feedback_inbox}
					>
						<ActionIconButton
							action="inbox"
							tooltip={t`View feedback items`}
							onClick={() => setQuickViewOpen(true)}
							disabled={!hasInboxViewPermission}
						/>
					</NoPermissionTooltip>
					<NoPermissionTooltip>
						<ActionIconButton
							action="delete"
							tooltip={t`Delete selected tags`}
							onClick={() => setDeleteDialogOpen(true)}
							data-track-event="settings_tag_deleted"
							disabled={!hasEditPermission}
						/>
					</NoPermissionTooltip>
				</SelectionAppBar>

				<FeedbackQuickViewDrawer
					open={quickViewOpen}
					onClose={e => setQuickViewOpen(false)}
					FeedbackListProps={{
						filters: { tags: selectedTags },
					}}
				/>

				<AjaxFormDialog
					AjaxFormProps={{
						url: '/application/tags/delete',
						data: { tags: selectedTags },
						onSuccess: () => {
							tags.api.updateTags();
							setDeleteDialogOpen(false);
							setSelectedTags([]);
							enqueueSnackbar(
								`${t`Tags`} ${selectedTags.join(
									', '
								)} ${t`deleted from all feedback items and actions.`}`
							);
						},
					}}
					open={deleteDialogOpen}
					onClose={() => setDeleteDialogOpen(false)}
					title={t`Delete tags`}
					content={
						<Fragment>
							{t`Do you want to permanently delete tags:`}
							{selectedTags.map((tag, index) => (
								<TagChip
									key={`tag${index}`}
									label={tag}
									className={classes.tags}
								/>
							))}
							{t`from this project?`}
							<div>
								{t`Deleting a tag means deleting it from all feedback items and actions.`}
							</div>
						</Fragment>
					}
				/>
			</div>
		</Fragment>
	);
}
