import React, { useEffect, useRef } from 'react';

export default function useOneEffect({
	condition = () => {},
	effect = () => {},
	dependencies = [],
}) {
	const ran = useRef(false);
	useEffect(() => {
		if (ran.current) {
			return;
		}

		if (!condition()) {
			return;
		}

		effect();

		ran.current = true;
	}, [condition, effect, ...dependencies]);
}
