import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '../AppContext';
import { UserName, UserAvatar } from '../Users';
import { Typography } from '../Layout';
import ExportDates from './ExportDates';
import { exportOptions } from './constants';
import { formatISOdate, getProgressColor } from './exportUtils';
import { ExportStatusChip } from './ExportStatusChip';

const useStyles = makeStyles(theme => ({
	chipSpace: {
		'& > div': {
			margin: theme.spacing(0, 0.5, 0.5, 0),
		},
	},
	noWrapChip: {
		maxWidth: '100%',
		margin: theme.spacing(0.5, 0.25),
	},
	noWrapChipLabel: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: 'block',
	},
	activeChip: {
		backgroundColor: props =>
			props.enabled ? getProgressColor(4, theme) : theme.palette.error.light,
		color: props =>
			theme.palette.getContrastText(props =>
				props.enabled ? theme.palette.secondary.light : theme.palette.error.light
			),
	},
	chip: {
		margin: theme.spacing(0, 2, 0, 0.5),
	},
}));

export default function ExportSettings({ exportData, variant, chipSize = 'medium' }) {
	const { t } = useTranslation();
	const classes = useStyles(exportData);
	const { app } = useAppContext();

	const exportDetails = exportData.kwargs
		? exportData.kwargs
		: exportData.export
		? exportData.export
		: {};
	const exportFilters = exportDetails.filters ? exportDetails.filters : exportDetails;

	const format = exportOptions.find(option => option.value === exportDetails.format);

	return (
		<Grid
			container
			spacing={2}
			direction="column"
		>
			{variant === 'scheduled' && (
				<Fragment>
					<Grid
						item
						xs
					>
						<Typography
							variant="subtitle2"
							display="block"
						>{t`Status`}</Typography>
						<Chip
							size={chipSize}
							label={exportData.enabled ? t`Active` : t`Inactive`}
							className={classes.activeChip}
						/>
					</Grid>
					<Grid
						item
						xs
					>
						<Typography
							variant="subtitle2"
							display="block"
						>{t`Schedule`}</Typography>
						<ExportDates
							color="default"
							variant="body2"
							interval={exportData.beat}
						/>
					</Grid>
					{exportData.next_run_at && (
						<Grid
							item
							xs
						>
							<Typography
								variant="subtitle2"
								display="block"
							>{t`Next export`}</Typography>
							<ExportDates
								color="default"
								variant="body2"
								nextInterval={exportData.enabled ? exportData.next_run_at : 'inactive'}
							/>
						</Grid>
					)}
				</Fragment>
			)}
			{variant === 'recent' && (
				<Fragment>
					<Grid
						item
						xs
					>
						<Typography
							variant="subtitle2"
							display="block"
						>{t`Status`}</Typography>
						<ExportStatusChip
							size={chipSize}
							type={exportData.status}
						/>
					</Grid>
					<Grid
						item
						xs
					>
						<Typography
							variant="subtitle2"
							display="block"
						>{t`Export type`}</Typography>
						<Chip
							size={chipSize}
							label={
								exportData.from_schedule ? t`Exported from schedule` : t`Single export`
							}
						/>
					</Grid>
					<Grid
						item
						xs
					>
						<Typography
							variant="subtitle2"
							display="block"
						>{t`Available until`}</Typography>
						<ExportDates
							color="default"
							variant="body2"
							dateCreated={exportData.created}
							expiration={exportDetails.expiration_days}
						/>
					</Grid>
				</Fragment>
			)}
			{variant === 'template' && (
				<Grid
					item
					xs
				>
					<Typography
						variant="subtitle2"
						display="block"
					>{t`Template type`}</Typography>
					<Chip
						size={chipSize}
						label={`${
							exportData.template_access === 'private' ? t`My` : t`Public`
						} template`}
					/>
				</Grid>
			)}
			{variant === 'recent' && exportFilters.from_date && exportFilters.to_date && (
				<Grid
					item
					xs
				>
					<Typography
						variant="subtitle2"
						display="block"
					>{t`Export date range`}</Typography>
					<Typography variant="body2">
						{`${formatISOdate(
							exportFilters.from_date,
							app.date.format
						)} ${t`to`} ${formatISOdate(exportFilters.to_date, app.date.format)}`}
					</Typography>
				</Grid>
			)}
			{exportDetails.format === 'xlsx' && !exportFilters.survey_ids && (
				<Grid
					item
					xs
				>
					<Typography
						variant="subtitle2"
						display="block"
					>{t`Sheet settings`}</Typography>
					<Typography variant="body2">
						{exportDetails.split_into_sheets
							? t`Export data sources into separate sheets`
							: t`Export all data sources into a single sheet`}
					</Typography>
				</Grid>
			)}

			<Grid
				item
				xs
			>
				<Typography
					variant="subtitle2"
					display="block"
				>{t`Format`}</Typography>
				<Typography variant="body2">{format.label}</Typography>
			</Grid>

			<Grid
				item
				xs
			>
				<Typography
					variant="subtitle2"
					display="block"
				>
					{variant === 'recent'
						? t`Exported by`
						: variant === 'scheduled'
						? t`Send export to`
						: variant === 'template'
						? t`Created by`
						: 'Export will be sent to'}
				</Typography>
				{exportDetails?.notify_to ? (
					exportDetails.notify_to.map((user, index) => (
						<Box
							className={classes.marginBottom}
							key={index}
						>
							<UserAvatar
								size="xsmall"
								user_id={user}
							/>{' '}
							<UserName
								fontWeight={400}
								user_id={user}
							/>
						</Box>
					))
				) : (
					<Box className={classes.marginBottom}>
						<UserAvatar
							size="xsmall"
							user_id={exportDetails.user_id}
						/>{' '}
						<UserName
							fontWeight={400}
							user_id={exportDetails.user_id}
						/>
					</Box>
				)}
			</Grid>

			<Grid
				item
				xs
			>
				<Typography
					variant="subtitle2"
					display="block"
				>
					{variant === 'scheduled'
						? t`Schedule ID`
						: variant === 'template'
						? t`Template ID`
						: t`Export ID`}
				</Typography>
				<Typography variant="body2">{exportData.uuid}</Typography>
			</Grid>
		</Grid>
	);
}
