import React from 'react';

import { Route as RouterRoute } from 'react-router-dom';
import { ErrorBoundary } from '../Utility';

export default function Route({ path, children, ...props }) {
	return (
		<RouterRoute
			path={path}
			{...props}
		>
			<ErrorBoundary>{children}</ErrorBoundary>
		</RouterRoute>
	);
}
