import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
export default function OptionalDroppable({
	children,
	droppable,
	droppableId,
	isDropDisabled = false,
	...props
}) {
	return !droppable ? (
		children
	) : (
		<Droppable
			droppableId={droppableId}
			isDropDisabled={isDropDisabled}
		>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					{...provided.droppableProps}
				>
					{children}
				</div>
			)}
		</Droppable>
	);
}
