import React from 'react';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '../Layout';
import { copyToClipboard } from '../../utils';
import { useTimeoutState } from '../../hooks';

export default function CopyButton({
	value,
	successMessage,
	onSuccess = () => {},
	...props
}) {
	const [copied, setCopied] = useTimeoutState(false, 10000);
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	return (
		<ActionButton
			action={copied ? 'check' : 'copy'}
			label={copied ? t`copy_button-label_copied` : t`copy_button-label_copy`}
			color="primary"
			onClick={e =>
				copyToClipboard(value).then(() => {
					setCopied(true);
					if (successMessage) {
						enqueueSnackbar(successMessage);
					}
					onSuccess();
				})
			}
			{...props}
		/>
	);
}
