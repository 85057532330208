import React, { useState, useRef, useEffect } from 'react';
import { CancelToken } from '@/lib/axios';
import { useAppContext } from '../AppContext';
import { Mjolnir } from '../../api';

export default function useChartTemplates(datasourceId, datafields = []) {
	const { app } = useAppContext();
	const [data, setData] = useState([]);

	const finishedLoading = useRef([]);
	const cancel = useRef({});

	function cancelCurrent() {
		Object.values(cancel.current).forEach(cancelFn => {
			try {
				cancelFn();
			} catch (e) {}
		});
	}

	async function loadData() {
		if (!datasourceId || datafields.length === 0) return;

		cancelCurrent();

		//clear current templates
		setData([]);

		//reset loading
		finishedLoading.current = [];

		//Loop through the datafields and request data from mjolnir
		//We use for ... of here because forEach has issues with async
		for (const datafieldId of datafields) {
			const response = await Mjolnir.get(
				`templates/${app.domain}/${datasourceId}/${datafieldId}`,
				{},
				{
					cancelToken: new CancelToken(token => {
						cancel.current[datafieldId] = token;
					}),
				}
			);

			finishedLoading.current.push(datafieldId);

			if (response.templates) {
				setData(state => [...state, ...response.templates]);
			}
		}
	}

	useEffect(() => {
		loadData();

		return () => {
			cancelCurrent();
		};
	}, [datasourceId, JSON.stringify(datafields)]);

	return [data, finishedLoading.current.length === datafields.length, loadData];
}
