import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MatrixIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<path
				fill="currentColor"
				id="XMLID_662_"
				d="M34.7,15.4c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4
				C36.2,16,35.5,15.4,34.7,15.4z M34.7,18c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1
				C35.9,17.5,35.4,18,34.7,18z M34.7,21.8c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7
				C35.5,22.1,35.1,21.8,34.7,21.8z M34.7,21.1c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4
				C36.2,21.7,35.5,21.1,34.7,21.1z M34.7,23.6c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1
				C35.9,23.1,35.4,23.6,34.7,23.6z M34.7,32.7c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4
				C36.2,33.3,35.5,32.7,34.7,32.7z M34.7,35.2c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1
				C35.9,34.7,35.4,35.2,34.7,35.2z M34.7,26.7c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S35.5,26.7,34.7,26.7z
				 M34.7,29.3c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1S35.4,29.3,34.7,29.3z M26.2,15.4c-0.8,0-1.4,0.6-1.4,1.4
				c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C27.7,16,27,15.4,26.2,15.4z M26.2,18c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1
				s1.1,0.5,1.1,1.1C27.4,17.5,26.9,18,26.2,18z M26.2,21.1c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4
				C27.7,21.7,27,21.1,26.2,21.1z M26.2,23.6c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1
				C27.4,23.1,26.9,23.6,26.2,23.6z M26.2,27.5c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7S26.6,27.5,26.2,27.5z
				 M26.2,26.7c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S27,26.7,26.2,26.7z M26.2,29.3c-0.6,0-1.1-0.5-1.1-1.1
				s0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1S26.9,29.3,26.2,29.3z M26.2,32.7c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4
				C27.7,33.3,27,32.7,26.2,32.7z M26.2,35.2c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1
				C27.4,34.7,26.9,35.2,26.2,35.2z M17.7,15.4c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4
				C19.2,16,18.5,15.4,17.7,15.4z M17.7,18c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1C18.9,17.5,18.4,18,17.7,18z
				 M17.7,21.8c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7C18.5,22.1,18.1,21.8,17.7,21.8z M17.7,21.1
				c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C19.2,21.7,18.5,21.1,17.7,21.1z M17.7,23.6c-0.6,0-1.1-0.5-1.1-1.1
				c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1C18.9,23.1,18.4,23.6,17.7,23.6z M14,37.1h-2v-25h2V37.1z M29,13.1H12v-2h17V13.1z
				 M17.7,32.7c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C19.2,33.3,18.5,32.7,17.7,32.7z M17.7,35.2
				c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1C18.9,34.7,18.4,35.2,17.7,35.2z M17.7,26.7c-0.8,0-1.4,0.6-1.4,1.4
				s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S18.5,26.7,17.7,26.7z M17.7,29.3c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1
				S18.4,29.3,17.7,29.3z M2,16h7.2v1.7H2V16z M14.6,8l5.1-5.1l1.2,1.2l-5.1,5.1L14.6,8z M23.1,8l5.1-5.1l1.2,1.2l-5.1,5.1L23.1,8z
				 M31.6,8l5.1-5.1l1.2,1.2l-5.1,5.1L31.6,8z M2,21.6h7.2v1.7H2V21.6z M2,27.3h7.2V29H2V27.3z M2,33.2h7.2V35H2V33.2z M38,13.1h-6v-2
				h6V13.1z"
			/>
		</SvgIcon>
	);
}
