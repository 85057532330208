import React from 'react';

import { useTranslation, Trans } from 'react-i18next';

import { makeStyles } from '@/styles';
import { Alert, Link, ActionIconButton } from '@/components/Layout';
import { useSessionStorage } from '@/hooks';

const useStyles = makeStyles(theme => ({
	alert: {
		marginBottom: theme.spacing(2),
	},
	link: {
		color: '#ffffff',
	},
}));

export default function MultipleLanguageAlert() {
	const { t } = useTranslation();
	const classes = useStyles();

	const [infoBarDismissed, setInfoBarDismissed] = useSessionStorage(
		`/text_analytics_info`,
		false,
		true
	);

	if (infoBarDismissed) {
		return null;
	}

	return (
		<Alert
			variant="filled"
			severity="warning"
			className={classes.alert}
			action={
				<ActionIconButton
					action="close"
					size="small"
					color="inherit"
					onClick={() => {
						setInfoBarDismissed(true);
					}}
				/>
			}
		>
			<Trans
				i18nKey="reporting-text_analytics-infobar-text"
				components={{
					languageLink: (
						<Link
							className={classes.link}
							to="/reporting/analytics/language"
						/>
					),
				}}
			/>
		</Alert>
	);
}
