import React, { useState, useEffect, memo } from 'react';
import { SelectShowEmpty } from '../Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from '@material-ui/core/Tooltip';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormatSizeIcon from '@material-ui/icons/FormatSize';
import { useTranslation } from 'react-i18next';

function FontSizeSelect({ currentState, config, onChange, ...props }) {
	const { t } = useTranslation();

	return (
		<Tooltip title={t('Font size')}>
			<SelectShowEmpty
				onChange={e => onChange(e.target.value)}
				value={currentState.fontSize || ''}
				fullWidth={false}
				input={<OutlinedInput />}
			>
				<MenuItem
					disabled
					value=""
				>
					<FormatSizeIcon />
				</MenuItem>
				{config.options.map(value => (
					<MenuItem
						key={value}
						value={value}
					>
						{value}
					</MenuItem>
				))}
			</SelectShowEmpty>
		</Tooltip>
	);
}

export default memo(FontSizeSelect);
