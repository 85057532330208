import React from 'react';
import { FaIcon } from '../Icons';
import { faRobot } from '@fortawesome/free-solid-svg-icons';

export default function MoBotIcon(props) {
	return (
		<FaIcon
			icon={faRobot}
			{...props}
		/>
	);
}
