import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import useIsInPermissionGroup from './useIsInPermissionGroup';

export default function useHandleNoAccessRedirect({ groups = [], to }) {
	const history = useHistory();
	const hasAccessPermission = useIsInPermissionGroup(groups);
	useEffect(() => {
		if (!hasAccessPermission) {
			history.push(to);
		}
	}, [history, hasAccessPermission, to]);
}
