import React, { useRef, useEffect } from 'react';
import useDebounce from './useDebounce';

export default function useAutoSave({
	data,
	onSave,
	interval = 2000,
	saveOnUnmount = true,
	preventUpdate = false,
}) {
	const valueOnCleanup = useRef(data);
	const initialRender = useRef(true);
	const handleSave = useRef(onSave);

	const debouncedValueToSave = useDebounce(data, interval);

	useEffect(() => {
		if (preventUpdate) {
			return;
		}
		if (initialRender.current) {
			initialRender.current = false;
		} else {
			handleSave.current(debouncedValueToSave);
		}
	}, [debouncedValueToSave, preventUpdate]);

	useEffect(() => {
		valueOnCleanup.current = data;
	}, [data]);

	useEffect(() => {
		handleSave.current = onSave;
	}, [onSave]);

	useEffect(
		() => () => {
			if (saveOnUnmount) {
				handleSave.current(valueOnCleanup.current);
			}
		},
		[saveOnUnmount]
	);
}
