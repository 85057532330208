import React from 'react';
import TextAnalyticsContext from './TextAnalyticsContext';

export default function TextAnalyticsProvider({ value, children }) {
	return (
		<TextAnalyticsContext.Provider value={value}>
			{children}
		</TextAnalyticsContext.Provider>
	);
}
