import React, { Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';

import CalendarTodaySharpIcon from '@material-ui/icons/CalendarTodaySharp';

import { Translation } from 'react-i18next';

import { format, isValid, dateFilters, dateFilterReducer } from '@/utils/date';
import { withAppContext } from '@/components/AppContext';
import {
	InputAdornment,
	TextField,
	Grid,
	Tooltip,
	Typography,
	MenuItem,
} from '@/components/Layout';
import DateRangeFilterPicker from './DateRangeFilterPicker';

const styles = theme => ({
	select: {
		fontSize: theme.typography.body2.fontSize,
	},
	padding: {
		padding: theme.spacing(1),
	},
	actions: {
		padding: theme.spacing(1),
	},
	label: {
		fontWeight: 400,
	},
	button: {
		justifyContent: 'flex-start',
	},
	quickFilters: {
		//maxWidth:'100%',
		overflowX: 'scroll',
	},
	quickFiltersHeader: {
		marginBottom: theme.spacing(2),
	},
});

class DateRangeFilter extends React.Component {
	state = {
		anchor: null,
		fromDate: '',
		toDate: '',
		tempFromDate: '',
		tempToDate: '',
	};

	setFromDate = date => {
		const { app } = this.props;
		if (isValid(date)) {
			//this.props.app.setGlobal({fromDate:format(date, 'dd/MM/yyyy')})
			this.setState({ tempFromDate: format(date, app.date.format) });
		}
	};

	setToDate = date => {
		const { app } = this.props;
		if (isValid(date)) {
			//this.props.app.setGlobal({toDate:format(date, 'dd/MM/yyyy')})
			this.setState({ tempToDate: format(date, app.date.format) });
		}
	};

	changeDate = e => {
		const { tempFromDate, tempToDate, fromDate, toDate } = this.state;
		const { app } = this.props;
		this.setState({ anchor: null });
		app.api.changeDate(tempFromDate || fromDate, tempToDate || toDate);
	};

	componentDidMount() {
		const {
			app: { date },
		} = this.props;
		this.setState({
			fromDate: date.fromDate,
			toDate: date.toDate,
		});
	}

	componentDidUpdate() {
		const {
			app: { date },
		} = this.props;
		const { fromDate, toDate } = this.state;

		if (date.fromDate !== fromDate || date.toDate !== toDate) {
			this.setState({
				fromDate: date.fromDate,
				toDate: date.toDate,
				tempFromDate: '',
				tempToDate: '',
			});
		}
	}
	render() {
		const { anchor, fromDate, toDate, tempFromDate, tempToDate } = this.state;
		const {
			app: { date, layout },
			classes,
		} = this.props;

		return (
			<Translation>
				{t => (
					<Fragment>
						<Tooltip title={t('Change date range')}>
							<TextField
								select
								size="small"
								variant="outlined"
								value={`${fromDate} - ${toDate}`}
								onClick={e => this.setState({ anchor: e.currentTarget })}
								data-test-element="date-range-filter"
								disabled={layout.appLoading || layout.appLoadingFull}
								SelectProps={{
									startAdornment: (
										<InputAdornment position="start">
											<CalendarTodaySharpIcon fontSize="small" />
										</InputAdornment>
									),
									className: classes.select,
									displayEmpty: true,
									open: false,
								}}
							>
								<MenuItem
									value={`${fromDate} - ${toDate}`}
								>{`${fromDate} - ${toDate}`}</MenuItem>
							</TextField>
						</Tooltip>
						<Popover
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							anchorEl={anchor}
							open={Boolean(anchor)}
							onClose={this.changeDate}
						>
							<div className={classes.padding}>
								<Grid
									container
									direction="row"
									spacing={1}
									//wrap="nowrap"
								>
									<Grid item>
										<Grid
											container
											className={classes.quickFilters}
											wrap="wrap"
											direction="column"
										>
											<Typography
												variant="subtitle2"
												className={classes.quickFiltersHeader}
											>
												{t('Quick select')}
											</Typography>
											{dateFilters.map(option => {
												// return (
												// 	<Grid item key={option.label}>
												// 		<Chip
												// 			label={t(option.label)}
												// 			onClick={e => this.setState(state => quickFilterReducer(state,option.action,date.format), () => {this.changeDate()})}
												// 			variant="outlined"
												// 		//	style={{margin:4}}
												// 		/>
												// 	</Grid>
												// )
												return (
													<Grid
														item
														key={option.label}
													>
														<Button
															style={{ textTransform: 'none' }}
															color="default"
															data-test-element={`date-range-filter-quick-${option.action}`}
															onClick={e => {
																const { fromDate, toDate } = dateFilterReducer(
																	option.action,
																	date.format
																);
																this.setState(
																	{
																		tempFromDate: fromDate,
																		tempToDate: toDate,
																	},
																	() => this.changeDate()
																);
															}}
															fullWidth
															classes={{
																root: classes.button,
																label: classes.label,
															}}
														>
															{t(option.label)}
														</Button>
													</Grid>
												);
											})}
										</Grid>
									</Grid>

									<Grid
										item
										xs
									>
										<div>
											<DateRangeFilterPicker
												fromDate={tempFromDate || date.fromDate}
												toDate={tempToDate || date.toDate}
												onFromDateChange={this.setFromDate}
												onToDateChange={this.setToDate}
												format={date.format}
												separator={date.separator}
											/>
										</div>
									</Grid>
								</Grid>
							</div>

							<div>
								<Grid
									container
									className={classes.actions}
									justify="flex-end"
								>
									<Grid item>
										<Button
											variant="contained"
											color="primary"
											onClick={this.changeDate}
											data-test-element="date-range-filter-submit"
										>
											{t('Set range')}
										</Button>
									</Grid>
								</Grid>
							</div>
						</Popover>
					</Fragment>
				)}
			</Translation>
		);
	}
}

export default withAppContext(withStyles(styles)(DateRangeFilter));
