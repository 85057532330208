import React, { useState, useEffect, useMemo, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import PhoneIcon from '@material-ui/icons/Phone';
import BusinessIcon from '@material-ui/icons/Business';
import MailIcon from '@material-ui/icons/Mail';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import { useAppContext } from '@/components/AppContext';
import { PageHeader } from '@/components/App';
import {
	Card,
	CardHeader,
	CardContent,
	Chip,
	AccountHeader,
	Typography,
	Grid,
	EditableContent,
	Loader,
	EmptyState,
	Box,
} from '@/components/Layout';
import { NotificationItem } from '@/components/Notifications';
import { useEFM, useAjaxForm } from '@/components/Ajax';
import {
	useHasPermission,
	useGetPermissionGroups,
	PermissionGroupAutocomplete,
} from '@/components/Permission';
import { departments } from './constants';

const useStyles = makeStyles(theme => ({
	username: {
		marginBottom: theme.spacing(2),
	},
	list: {
		padding: theme.spacing(0, 2, 2),
	},
	accessGroupChip: {
		margin: theme.spacing(0.25),
	},
}));

export default function UserProfile() {
	const classes = useStyles();
	const { app } = useAppContext();
	const { userId } = useParams();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const user = useMemo(() => app.users.byKey[userId] ?? {}, [app.users, userId]);
	const [{ groups = [] }, permissionGroupsLoading, _err, loadPermissionGroups] =
		useGetPermissionGroups();
	//const userGroups = groups.filter(group => group?.members?.includes(user.uuid));
	const hasEditPermission = useHasPermission();
	const groupsDidChange = useRef(false);

	const editPermissionOrIsSelf = hasEditPermission || user.id === app.users.current.id;

	const [data, loading] = useEFM('/application/ajax/get-user-activity', {
		user_id: userId,
	});

	const [form, setForm] = useState({
		department: null,
		telephone: null,
	});
	const postValues = Object.keys(form).reduce((update, key) => {
		if (user[key] !== form[key] && form[key] !== null) {
			update[key] = form[key];
		}
		return update;
	}, {});

	const [userGroups, setUserGroups] = useState([]);
	useEffect(() => {
		setUserGroups(
			groups.filter(group => group?.members?.includes(user.uuid)).map(group => group.uuid)
		);
	}, [groups, user]);

	const currentUserGroups = groups
		.filter(group => group?.members?.includes(user.uuid))
		.map(group => group.uuid);

	const permissionGroupsChanged =
		groupsDidChange.current &&
		(!currentUserGroups.every(groupUuid => userGroups.includes(groupUuid)) ||
			currentUserGroups.length !== userGroups.length);

	function setFormValue(key, value) {
		setForm(prev => ({
			...prev,
			[key]: value,
		}));
	}

	const { postForm } = useAjaxForm({
		url: `/application/users/edit/${user.id}`,
		data: {
			...postValues,
			...(permissionGroupsChanged && {
				permission_groups: userGroups,
			}),
		},
		onSuccess: async r => {
			groupsDidChange.current = false;
			if (r.code === 200) {
				enqueueSnackbar(t`settings-users-profile-update_success`);
				await app.api.getUsers();
				loadPermissionGroups();
				setForm({
					department: null,
					telephone: null,
				});
			}
		},
	});

	useEffect(() => {
		if (permissionGroupsChanged) {
			postForm();
		}
	}, [permissionGroupsChanged]);

	function handlePost(key) {
		if (form[key] !== user[key]) {
			postForm();
		}
	}

	return (
		<div>
			<PageHeader
				title={t(`{{user}}'s profile`, { user: app.api.getUserName(userId) })}
				backTo="/application/users"
			/>
			<AccountHeader
				userId={user.id}
				align="left"
				withName
			/>

			<Typography
				className={classes.username}
				variant="h5"
			>
				{app.api.getUserName(userId)}
			</Typography>
			<Grid
				container
				spacing={3}
			>
				<Grid
					item
					sm={4}
				>
					<Card>
						<List>
							<ListSubheader>{t`About`}</ListSubheader>
							<ListItem>
								<ListItemIcon>
									<MailIcon />
								</ListItemIcon>
								<ListItemText
									primary={
										<EditableContent
											editable={false}
											showIconAlways={false}
											rules={{
												required: true,
												email: true,
											}}
											showErrors
											messages={{
												required: t`A phonenumber is required`,
												phone: t`This is not a valid phonenumber`,
											}}
											onClose={valid => {
												if (!valid) {
													setFormValue('email', user.email);
												}
											}}
											onEdit={() => {
												setFormValue('email', user.email ?? '');
											}}
											onChange={value => setFormValue('email', value)}
											value={form.email}
										>
											{form.email || user.email || t`Not set`}
										</EditableContent>
									}
									secondary={t`Email address`}
								/>
							</ListItem>
							{(user.telephone || editPermissionOrIsSelf) && (
								<ListItem>
									<ListItemIcon>
										<PhoneIcon />
									</ListItemIcon>
									<ListItemText
										primary={
											<EditableContent
												editable={hasEditPermission || user.id === app.users.current.id}
												showIconAlways={user.id === app.users.current.id}
												rules={[
													['required', true],
													['phone', true],
												]}
												showErrors
												messages={{
													required: t`A phonenumber is required`,
													phone: t`This is not a valid phonenumber`,
												}}
												onClose={valid => {
													if (valid) {
														handlePost('telephone');
													}
													if (!valid) {
														setFormValue('telephone', user.telephone);
													}
												}}
												onEdit={() => {
													setFormValue('telephone', user.telephone ?? '');
												}}
												onChange={value => setFormValue('telephone', value)}
												value={form.telephone}
											>
												{form.telephone || user.telephone || t`Not set`}
											</EditableContent>
										}
										secondary={t`Phone`}
									/>
								</ListItem>
							)}
							<ListItem>
								<ListItemIcon>
									<BusinessIcon />
								</ListItemIcon>
								<ListItemText
									primary={
										<EditableContent
											editable={editPermissionOrIsSelf}
											showIconAlways={editPermissionOrIsSelf}
											value={form.department}
											onEdit={() => {
												setFormValue('department', user.department ?? '');
											}}
											onClose={valid => {
												if (valid) {
													handlePost('department');
												}
											}}
											onChange={value => setFormValue('department', value)}
											select
											options={departments.map(department => ({
												label: t(`settings-users-departments-${department}`),
												value: department,
											}))}
										>
											{form.department || user.department
												? t(
														`settings-users-departments-${
															form.department || user.department
														}`
												  )
												: t`Not set`}
										</EditableContent>
									}
									secondary={t`Department`}
								/>
							</ListItem>
						</List>
					</Card>

					<Box mt={2}>
						<Card>
							<CardContent>
								<Typography
									variant="subtitle2"
									color="textSecondary"
									mb={2}
								>{t`settings-users-profile-access_groups`}</Typography>
								{permissionGroupsLoading && <Loader circular={userGroups.length === 0} />}
								{userGroups.map(groupUuid => {
									const group = groups.find(group => group.uuid === groupUuid);

									return (
										<Chip
											className={classes.accessGroupChip}
											key={group.uuid}
											label={group.name}
										/>
									);
								})}
							</CardContent>
						</Card>
					</Box>
				</Grid>
				<Grid
					item
					sm={8}
				>
					<Card>
						<CardHeader
							title={t`Latest activity`}
							titleTypographyProps={{
								variant: 'h6',
							}}
						/>
						<div className={classes.list}>
							{loading && (
								<Loader
									empty={!data?.activity?.length}
									offset={'0vh'}
								/>
							)}
							{data?.activity?.map(activity => {
								return (
									<NotificationItem
										item={activity}
										variant="activity"
									/>
								);
							})}
							{data?.code == 200 && data?.activity?.length === 0 && !loading && (
								<EmptyState
									//primary={t`No activity yet`}
									secondary={t`It seems this person hasn't had any activity yet`}
								/>
							)}
						</div>
					</Card>
				</Grid>
			</Grid>
		</div>
	);
}
