import React, { memo } from 'react';

import { useTranslation, Trans } from 'react-i18next';

import { makeStyles } from '@/styles';
import { IntroductionDialog } from '@/components/Onboarding';
import { Typography, Grid } from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	introImage: {
		alignSelf: 'center',
		maxWidth: '100%',
		marginBottom: theme.spacing(2),
	},
	ul: {
		fontSize: theme.typography.body1.fontSize,
		'& > li': {
			marginBottom: theme.spacing(1),
		},
	},
}));

export default memo(function SmartRecapsIntroductionDialog() {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<IntroductionDialog
			seenKey="seen_smartrecaps_opener"
			title={t`reporting-feedback_inbox-smart_recaps-intro_dialog-title`}
			cancel={t`reporting-feedback_inbox-smart_recaps-intro_dialog-button_cancel`}
			content={
				<>
					{/* <img
							src={`/assets/img/introduction/themes_introduction.gif`}
							className={classes.introImage}
						/> */}

					<Trans
						i18nKey="reporting-feedback_inbox-smart_recaps-intro_dialog-content"
						components={{
							paragraph: <Typography mb={2} />,
						}}
					/>
				</>
			}
		/>
	);
});
