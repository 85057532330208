import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MarginNormalIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			{
				<g>
					<g>
						<rect
							x="3.179"
							y="11.001"
							width="2"
							height="1"
						/>
						<path d="M30.894,12.001h-4.285v-1h4.285V12.001z M22.321,12.001h-4.285v-1h4.285V12.001z M13.75,12.001H9.465v-1h4.285V12.001z" />
						<rect
							x="35.179"
							y="11.001"
							width="2"
							height="1"
						/>
					</g>
					<g>
						<rect
							x="3.179"
							y="28.001"
							width="2"
							height="1"
						/>
						<path d="M30.894,29.001h-4.285v-1h4.285V29.001z M22.321,29.001h-4.285v-1h4.285V29.001z M13.75,29.001H9.465v-1h4.285V29.001z" />
						<rect
							x="35.179"
							y="28.001"
							width="2"
							height="1"
						/>
					</g>
					<polygon
						points="20.68,25.489 20.68,14.675 22.673,16.667 23.38,15.96 20.179,12.76 16.979,15.96 17.686,16.667 19.68,14.673 
						19.68,25.489 17.685,23.495 16.979,24.202 20.179,27.402 23.38,24.202 22.673,23.495 	"
					/>
				</g>
			}
		</SvgIcon>
	);
}
