import React, { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';
import { Alert, Box, ActionButton, Menu, MenuItem } from '../Layout';
import useGrantPermission from './useGrantPermission';
import useDenyPermission from './useDenyPermission';

export default function HandlePermissionRequestAlert({
	request = {},
	refreshRequests = () => {},
	addNewGroup = () => {},
}) {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const [groupUuid, setGroupUuid] = useState('');
	const [menuAnchor, setMenuAnchor] = useState(null);
	const didRequest = useRef(null);

	const handleSetGroup = uuid => {
		setGroupUuid(uuid);
		setMenuAnchor(null);
	};

	const grantPermission = useGrantPermission({
		permissionRequestUuid: request.uuid,
		groupUuid,
		onSuccess: () => refreshRequests(),
	});
	const denyPermission = useDenyPermission({
		permissionRequestUuid: request.uuid,
		onSuccess: () => refreshRequests(),
	});

	useEffect(() => {
		if (groupUuid && didRequest.current !== groupUuid) {
			grantPermission.postForm();
			didRequest.current = groupUuid;
		}
	}, [groupUuid, grantPermission]);

	const requestUser = app.api.getUserByUuid(request.user);
	const project = app.api.getProjectByUuid(request.project);

	const noGroupsInRequest = request.groups?.length === 0 || !request.groups;

	return (
		<Box mb={1}>
			<Alert
				severity="info"
				variant="filled"
				title={t(
					`settings-access_groups-handle_access_request_alert-title_${request.permission}`
				)}
				action={
					<>
						<ActionButton
							variant="outlined"
							onClick={denyPermission.postForm}
							color="inherit"
							loading={grantPermission.loading || denyPermission.loading}
							mr={1}
							data-track-event="access_groups_handle_access_request_declined"
						>
							{t`settings-access_groups-handle_access_request_alert-decline`}
						</ActionButton>
						{request.groups?.length > 0 && (
							<ActionButton
								variant="outlined"
								action="dropdown"
								onClick={e => setMenuAnchor(e.target)}
								color="inherit"
								loading={grantPermission.loading || denyPermission.loading}
							>
								{t`settings-access_groups-handle_access_request_alert-add_to`}
							</ActionButton>
						)}
						{noGroupsInRequest && (
							<ActionButton
								variant="outlined"
								onClick={addNewGroup}
								color="inherit"
								loading={grantPermission.loading || denyPermission.loading}
							>
								{t`settings-access_groups-handle_access_request_alert-create_new_group`}
							</ActionButton>
						)}

						<Menu
							open={Boolean(menuAnchor)}
							anchorEl={menuAnchor}
							onClose={() => setMenuAnchor(null)}
						>
							{request.groups?.map(group => (
								<MenuItem
									key={group.uuid}
									onClick={() => handleSetGroup(group.uuid)}
									data-track-event="access_groups_handle_access_request_granted"
									data-track-event-props={JSON.stringify(group)}
								>
									{group.name}
								</MenuItem>
							))}
						</Menu>
					</>
				}
			>
				{t(
					`settings-access_groups-handle_access_request_alert-content-${
						request.subject === 'access_project' ? 'project' : 'permission'
					}`,
					{
						user: `${requestUser.firstname} ${requestUser.lastname}`,
						permission: t(`settings-access_groups-access_types-${request.permission}`),
						subject: t(`settings-access_groups-access_subjects-${request.subject}`),
						report: project.name,
					}
				)}
				{noGroupsInRequest && (
					<Box mt={0.5}>
						{t`settings-access_groups-handle_access_request_alert-content-no_groups`}
					</Box>
				)}
			</Alert>
		</Box>
	);
}
