import React, { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useImmerReducer } from 'use-immer';

import { CancelToken } from '@/lib/axios';

import { useAppContext } from '@/components/AppContext';
import { EFM, Mjolnir } from '@/api';
import { makeKeyedObject } from '@/helpers';
import DataSourceContext from './DataSourceContext';
import { sortByActive, sortByGroupId, filterActive } from './dataSourceUtils';
import { differenceInSeconds } from '@/utils/date';

//these fields are normally ignored because all api's return unencoded data
const ignoreAbleCustomerFields = [15, 17, 18, 19, 20];

//some metaData has varType 1, which is actually used for category. Use niceType to force them in the correct list format
const metaDataWithCategoryVarType = ['Form trigger', 'Page title'];

//hide some fields from showing in the datasource list, but keep them in the datasourceProvider
const excludeFromDataSourceView = ['Survey', 'Role'];

function reducer(draft, action) {
	switch (action.type) {
		case 'set_loading_surveys':
			draft.stale.surveys = {
				time: new Date(),
				loading: true,
			};
			if (action.payload.clearPrevious) {
				draft.forms = {
					asArray: [],
					byKey: {},
				};
				draft.datasets = {
					asArray: [],
					byKey: {},
				};
				draft.integrations = {
					asArray: [],
					byKey: {},
				};
			}
			return;

		case 'set_surveys':
			draft.stale.surveys.loading = false;

			draft.forms = {
				asArray: action.payload.forms,
				byKey: makeKeyedObject(action.payload.forms),
			};
			draft.datasets = {
				asArray: action.payload.datasets,
				byKey: makeKeyedObject(action.payload.datasets),
			};
			draft.integrations = {
				asArray: action.payload.integrations,
				byKey: makeKeyedObject(action.payload.integrations),
			};

			draft.surveyCount =
				action.payload.forms.length +
				action.payload.datasets.length +
				action.payload.integrations.length;
			return;

		case 'update_survey':
			const surveyIndex = draft[action.payload.type].asArray.findIndex(
				survey => survey.id === action.payload.survey.id
			);
			draft[action.payload.type].asArray[surveyIndex] = action.payload.survey;
			draft[action.payload.type].byKey[action.payload.survey.id] = action.payload.survey;

			return;

		case 'set_survey_fields':
			//We have to normalize some stuff here to set the proper titles for checkbox, likert and matrix types
			//We have to do some additional work to find out where subdatafields (something else, and matrix values) are active as well
			const normalizedFields = action.payload.fields.map(field => {
				const {
					survey_block = {},
					id,
					title,
					system_var,
					system_var_simple,
					active,
					import_var,
				} = field;

				if (import_var === 'ALL DATA') {
					return {
						...field,
						title: 'All data',
						niceType: 'All data',
					};
				}

				let newTitle, newActive;
				//4 = checkbox, 11 = matrix, 12 = likert
				if (survey_block?.type && [4, 11, 12].indexOf(Number(survey_block.type)) > -1) {
					if (survey_block.type == 11) {
						const { matrixLabel, matrixType } = Object.values(
							survey_block.properties?.elements ?? {}
						).reduce(
							(returnObj, element) => {
								if (element.data_field?.value == id) {
									returnObj.matrixLabel = element.label;
									returnObj.matrixType = 'Value';
								} else if (element.data_field?.weight == id) {
									returnObj.matrixLabel = element.label;
									returnObj.matrixType = 'Weight';
								}
								return returnObj;
							},
							{ matrixLabel: '', matrixType: '' }
						);
						newTitle = `${action.payload.t(matrixType)} - ${matrixLabel} - ${
							survey_block.title
						}`;
					}

					if ([4, 12].includes(Number(survey_block.type))) {
						const label =
							Object.values(survey_block.properties?.elements ?? {}).find(
								element => element.data_field == id
							)?.label ?? system_var;
						newTitle = `${label} - ${survey_block.title}`;
					}
				}

				//check if a field is actually not active or if a more complex check is required
				if (!active) {
					if (survey_block?.properties?.elements_extra) {
						newActive = Object.values(survey_block.properties?.elements_extra).find(
							element => element.data_field == id
						);
					}

					if (survey_block?.type == 11) {
						newActive = Object.values(survey_block.properties?.elements ?? {}).find(
							element =>
								element.data_field?.value == id || element.data_field?.weight == id
						);
					}
				}

				//set a nice type
				//if extra input in import var -> single line
				//other wise grab from block type
				//otherwise get system_var_simple
				const niceType = !!import_var.match(/EXTRA.input/)
					? action.payload.t(`blocktype.extra_input`)
					: survey_block?.type
					? action.payload.t(`blocktype.${survey_block.type}`)
					: system_var_simple;

				return {
					...field,
					title: newTitle ?? title,
					active: newActive ?? active,
					niceType,
				};
			});

			draft.surveyfields[action.payload.survey_id] = {
				asArray: normalizedFields,
				byKey: makeKeyedObject(normalizedFields),
			};
			draft.stale[`fields-${action.payload.survey_id}`].loading = false;
			return;

		case 'set_loading_survey_fields':
			draft.stale[`fields-${action.payload}`] = {
				time: new Date(),
				loading: true,
			};
			return;

		case 'set_possible_values':
			draft.possiblevalues[action.payload.field_id] = action.payload.values;
			return;

		case 'set_block':
			draft.surveyblocks[action.payload.block.id] = action.payload.block;
			draft.stale[`blocks-${action.payload.block.id}`].loading = false;
			return;

		case 'set_loading_block':
			draft.stale[`blocks-${action.payload.blockId}`] = {
				time: new Date(),
				loading: true,
			};
			return;

		case 'set_var_types':
			draft.varTypes.idArray = action.payload.var_types;
			draft.varTypes.allDataArray = action.payload.all;
			draft.varTypes.listFormat.meta.types = action.payload.var_types.filter(
				varTypeId => {
					return Object.keys(draft.varTypes.listFormat).every(key => {
						return draft.varTypes.listFormat[key].types.indexOf(String(varTypeId)) == -1;
					});
				}
			);
			return;

		default:
			return;
	}
}

export default function DataSourceProvider({ children }) {
	const { t } = useTranslation();
	const { app } = useAppContext();

	const cancellers = useRef([]);

	const [state, dispatch] = useImmerReducer(reducer, {
		showInactive: false,
		surveyCount: 0,
		forms: {
			byKey: {},
			asArray: [],
		},
		datasets: {
			byKey: {},
			asArray: [],
		},
		integrations: {
			byKey: {},
			asArray: [],
		},
		stale: {},
		surveyfields: {},
		fields: {},
		varTypes: {
			idArray: [],
			allDataArray: [],
			listFormat: {
				scores: {
					label: t('Scores'),
					type: 'scores',
					types: ['4', '12', '16', '24', '25', '27', '30', '44'],
					fields: [],
				},
				category: {
					label: t('Categories'),
					type: 'category',
					types: ['1'],
					fields: [],
				},
				text: {
					label: t('Text and sentiment'),
					type: 'text',
					types: ['13', '31', '32', '38'],
					fields: [],
				},
				customer: {
					label: t('Customer data'),
					type: 'customer',
					types: ['6', '10', '14', '15', '17', '18', '19', '20', '22', '23', '29'],
					fields: [],
				},
				meta: {
					label: t('Meta data'),
					type: 'meta',
					types: [],
					fields: [],
				},
			},
		},
		possiblevalues: {},
		surveyblocks: {},
	});

	function isStale(key, seconds = 60) {
		if (!state.stale[key]) return true;
		const diff = differenceInSeconds(new Date(), state.stale[key].time);
		if (diff >= seconds) return true;
	}

	function getSource(id) {
		const { forms, datasets, integrations } = state;
		if (forms.byKey[id]) {
			return forms.byKey[id];
		} else if (datasets.byKey[id]) {
			return datasets.byKey[id];
		} else if (integrations.byKey[id]) {
			return integrations.byKey[id];
		}
		return {};
	}

	function getSourceByProp(prop, value) {
		const { forms, datasets, integrations } = state;

		const source = [...forms.asArray, ...datasets.asArray, ...integrations.asArray].find(
			source => source[prop] === value
		);

		return source ? source : { message: 'not found' };
	}
	function getField(id, withSource) {
		const { surveyfields } = state;
		const survey = Object.keys(surveyfields).find(survey => {
			return surveyfields[survey].byKey[id];
		});
		if (survey) {
			return !withSource
				? surveyfields[survey].byKey[id]
				: Object.assign({}, surveyfields[survey].byKey[id], {
						source: getSource(survey),
				  });
		} else {
			return !withSource
				? { title: '' }
				: Object.assign({ title: '' }, { source: getSource(survey) });
		}
	}
	function getFieldArray(
		surveyId,
		includeAllandTags = false,
		includeCustomerFields = false
	) {
		const { surveyfields, showInactive } = state;
		try {
			const fieldArray =
				!includeAllandTags || !includeCustomerFields
					? surveyfields[surveyId].asArray.filter(field => {
							if (
								!includeAllandTags &&
								['all data', 'tags'].includes(field.import_var.toLowerCase())
							) {
								return false;
							}

							if (
								!includeCustomerFields &&
								ignoreAbleCustomerFields.includes(Number(field.var_type))
							) {
								return false;
							}

							return true;
					  })
					: surveyfields[surveyId].asArray;

			return showInactive ? sortByActive(fieldArray) : filterActive(fieldArray);
		} catch (e) {
			return [];
		}
	}

	function getPossibleValue(id) {
		const { possiblevalues } = state;
		if (possiblevalues[id]) {
			return possiblevalues[id].map(value => value.replace(/\\_/g, '_'));
		} else {
			loadPossibleValues(id);
			return [];
		}
	}

	function getBlock(id) {
		const { surveyblocks } = state;
		if (surveyblocks[id]) {
			return surveyblocks[id];
		} else {
			loadBlock(id);
			return {};
		}
	}

	function getFieldsFormattedByVarType(
		surveyId,
		includeAllandTags = false,
		includeCustomerTypes = false
	) {
		const { surveyfields, showInactive } = state;
		const { listFormat } = state.varTypes;

		if (surveyfields[surveyId]) {
			let fields = [...surveyfields[surveyId].asArray];

			//find all var_types which are of a defined (non-meta) type
			const nonMetaFields = Object.values(listFormat).flatMap(formatObj => {
				if (formatObj.type === 'meta') return [];
				return formatObj.types;
			});

			return Object.keys(listFormat).reduce(
				(formattedFieldsObject, formatType, index) => {
					formattedFieldsObject[formatType] = { ...listFormat[formatType] };

					const fieldsForType = fields.filter(field => {
						if (
							!includeCustomerTypes &&
							ignoreAbleCustomerFields.includes(Number(field.var_type))
						) {
							return false;
						}

						if (
							!includeAllandTags &&
							['all data', 'tags'].includes(field.import_var.toLowerCase())
						) {
							return false;
						}

						if (excludeFromDataSourceView.includes(String(field.niceType))) {
							return false;
						}
						//if formatType is meta, check against previous nonMetaFields. if its not in there its a meta field
						return formatType === 'meta'
							? nonMetaFields.indexOf(String(field.var_type)) === -1 ||
									metaDataWithCategoryVarType.includes(String(field.niceType))
							: listFormat[formatType].types.indexOf(String(field.var_type)) > -1 &&
									metaDataWithCategoryVarType.includes(String(field.niceType)) === false;
					});

					formattedFieldsObject[formatType].fields = showInactive
						? sortByGroupId(sortByActive(fieldsForType))
						: sortByGroupId(filterActive(fieldsForType));

					return formattedFieldsObject;
				},
				{}
			);
		} else {
			return {};
		}
	}

	async function loadSurveys(seconds, clearPrevious = false) {
		if (isStale('surveys', seconds)) {
			dispatch({
				type: 'set_loading_surveys',
				payload: {
					clearPrevious,
				},
			});
			const response = await EFM.post(
				'/survey/ajax/get-surveys-from-project',
				{},
				{
					cancelToken: new CancelToken(cancelToken =>
						cancellers.current.push(cancelToken)
					),
				}
			);
			if (response.forms) {
				dispatch({ type: 'set_surveys', payload: response });
			}
		}
	}
	async function loadSurveyFields(surveyId, seconds) {
		if (isStale(`fields-${surveyId}`, seconds)) {
			dispatch({ type: 'set_loading_survey_fields', payload: surveyId });
			const response = await Mjolnir.get(
				`fields/${app.domain}/${app.projects.current.id}/${surveyId}/tags`,
				{},
				{
					cancelToken: new CancelToken(cancelToken =>
						cancellers.current.push(cancelToken)
					),
				}
			);
			if (response.fields) {
				dispatch({
					type: 'set_survey_fields',
					payload: Object.assign({ survey_id: surveyId, t: t }, response),
				});
			}
		}
	}
	async function loadPossibleValues(fieldId) {
		const response = await Mjolnir.get(
			`possiblevalues/${app.domain}/${fieldId}`,
			{},
			{
				cancelToken: new CancelToken(cancelToken => cancellers.current.push(cancelToken)),
			}
		);
		if (response.values) {
			dispatch({
				type: 'set_possible_values',
				payload: Object.assign({ field_id: fieldId }, response),
			});
		}
	}
	async function loadBlock(blockId, seconds) {
		if (blockId && isStale(`blocks-${blockId}`, seconds)) {
			dispatch({ type: 'set_loading_block', payload: { blockId } });
			const response = await EFM.post(
				'/survey/ajax/get-block',
				{ block_id: blockId },
				{
					cancelToken: new CancelToken(cancelToken =>
						cancellers.current.push(cancelToken)
					),
				}
			);
			if (response.block) {
				dispatch({ type: 'set_block', payload: response });
			}
		}
	}
	async function loadVarTypes() {
		const response = await EFM.post(
			'/survey/ajax/get-var-types',
			{},
			{
				cancelToken: new CancelToken(cancelToken => cancellers.current.push(cancelToken)),
			}
		);
		if (response.var_types) {
			dispatch({ type: 'set_var_types', payload: response });
		}
	}

	async function updateSurvey(surveyId, type = 'forms') {
		const response = await EFM.post(
			'/survey/ajax/get-survey',
			{ surveyId },
			{
				cancelToken: new CancelToken(cancelToken => cancellers.current.push(cancelToken)),
			}
		);
		if (response.survey) {
			dispatch({ type: 'update_survey', payload: { survey: response.survey, type } });
		}
		loadSurveyFields(surveyId, 0);
	}

	useEffect(() => {
		if (app.cancelPending) {
			cancellers.current?.forEach(cancel => {
				try {
					cancel();
				} catch (e) {}
			});
			cancellers.current = [];
		}
	}, [app.cancelPending]);

	useEffect(() => {
		if (!app.auth.loggedIn || !app.projects.current.id || app.cancelPending) return;

		loadSurveys(0, true);
		loadVarTypes();
	}, [app.projects.current.id, app.auth.loggedIn, app.cancelPending]);

	useEffect(() => {
		function shouldLoadSurveyFields(dataSource) {
			if (Array.isArray(dataSource.asArray)) {
				dataSource.asArray.forEach(source => {
					if (!state.surveyfields[source.id]) {
						loadSurveyFields(source.id, 0);
					}
				});
			}
		}
		[state.forms, state.datasets, state.integrations].forEach(x =>
			shouldLoadSurveyFields(x)
		);
	}, [state.forms, state.datasets, state.integrations]);

	return (
		<DataSourceContext.Provider
			value={{
				datasource: {
					...state,
					dispatch,
					api: {
						getSource,
						getSourceByProp,
						getField,
						getFieldArray,
						getFieldsFormattedByVarType,
						getPossibleValue,
						getBlock,
						loadSurveys,
						loadSurveyFields,
						updateSurvey,
						sortByActive,
					},
				},
			}}
		>
			{children}
		</DataSourceContext.Provider>
	);
}
