import React, { useState } from 'react';

import StatusIcon from '@material-ui/icons/FiberManualRecord';
import Pause from '@material-ui/icons/Pause';

import { Trans, useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { SimpleDialog, Chip, Link, Typography, Alert } from '@/components/Layout';
import { useDeploymentStore } from './DeploymentStore';

const useStyles = makeStyles(theme => ({
	icon: {
		color: props =>
			props.published ? theme.palette.secondary.main : theme.palette.text.secondary,
	},
}));

export default function DeploymentPublishSetting() {
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();
	const published = useDeploymentStore(store => store.workingDraft.published);
	const setPublished = useDeploymentStore(store => store.actions.setPublished);
	const loading = useDeploymentStore(store => store.loading);
	const classes = useStyles({ published });

	const translationKey = published ? 'active' : 'inactive';

	return (
		<>
			<Chip
				loading={loading}
				size="small"
				onClick={() => setOpen(true)}
				icon={
					<StatusIcon
						fontSize="small"
						className={classes.icon}
					/>
				}
				label={t(
					`data_collection-deployment_editor-deployment_publish_setting-${translationKey}`
				)}
				tooltip={t`data_collection-deployment_editor-deployment_publish_setting-tooltip`}
			/>

			<SimpleDialog
				title={t(
					`data_collection-deployment_editor-deployment_publish_setting-${translationKey}-dialog-title`
				)}
				open={open}
				onClose={() => setOpen(false)}
				onSubmit={() => {
					setPublished(!published);
					setOpen(false);
				}}
				submit={t(
					`data_collection-deployment_editor-deployment_publish_setting-${translationKey}-dialog-submit`
				)}
			>
				<Typography>
					<Trans
						i18nKey={`data_collection-deployment_editor-deployment_publish_setting-${translationKey}-dialog-content`}
						components={{
							strong: (
								<Typography
									fontWeight="medium"
									display="inline"
								/>
							),
						}}
					/>
				</Typography>
				<Alert
					severity="info"
					css={{ my: 2 }}
				>
					{t(`data_collection-deployment_editor-deployment_publish_setting-dialog-alert`)}
				</Alert>
			</SimpleDialog>
		</>
	);
}
