import React from 'react';

import { reverseDate } from '@/helpers';

import { useAppContext } from '@/components/AppContext';
import { useEFM } from '@/components/Ajax';

export default function useGetTrends({ wait, surveyId, onSuccess }) {
	const {
		app: { date },
	} = useAppContext();

	return useEFM(
		'/dashboard/ajax/smart-recaps',
		{
			path: '/trends',
			method: 'get',
			survey_id: surveyId,
			from_date: reverseDate(date.fromDate, date.separator, '-'),
			to_date: reverseDate(date.toDate, date.separator, '-'),
			//meta_summary: false, invalid for now, backend not working correctly
		},
		wait,
		false,
		null,
		{ onSuccess: onSuccess, disableCache: true }
	);
}
