import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';
import {
	useFilteredList,
	ListWithSidebar,
	ListFilterProvider,
	ListControls,
} from '@/components/FilteredLists';
import {
	EmptyState,
	ActionButton,
	constants as layoutConstants,
	Box,
} from '@/components/Layout';
import {
	useHasPermission,
	NoPermissionTooltip,
	useGetPermissionGroups,
} from '@/components/Permission';
import { UsersIcon } from '@/components/Icons';
import { usePagination, Pagination } from '@/components/Pagination';

import UserCard from './UserCard';
import UserAdditDialog from './UserAdditDialog';
import UserListFilters from './UserListFilters';
import {
	applySort,
	applySearchFilter,
	applyGroupsFilter,
	applyProjectsFilter,
} from './utils';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	input: {
		margin: theme.spacing(1),
	},
	textField: {
		width: '100%',
	},
	icon: {
		fontSize: theme.typography.pxToRem(64),
		color: theme.palette.primary.main,
	},
}));

export default function UserList() {
	const { t } = useTranslation();
	const { app } = useAppContext();
	const [{ groups = [] }, loading, _err, loadPermissionGroups] = useGetPermissionGroups();

	const [drawerOpen, setDrawerOpen] = useState(
		window.innerWidth > layoutConstants.filterDrawerBreakpoint
	);

	const hasEditPermission = useHasPermission();
	const filterState = useFilteredList({});
	const [addOpen, setAddOpen] = useState(false);

	const listAfterFilterSort = useMemo(() => {
		const listWithFiltersApplied = app.users.asArray.filter(user => {
			const searchPassed = applySearchFilter({
				search: filterState.filters.search,
				user,
			});

			const groupsPassed = applyGroupsFilter({
				groups: filterState.filters.groups?.map(groupUuid =>
					groups?.find(group => group?.uuid === groupUuid)
				),
				user,
			});

			const projectsPassed = applyProjectsFilter({
				projects: filterState.filters.projects,
				groups,
				user,
			});

			return searchPassed && groupsPassed && projectsPassed;
		});

		return applySort({
			list: listWithFiltersApplied,
			sort: filterState.sort,
			order: filterState.order,
		});
	}, [app.users.asArray, filterState, groups]);

	const { items, ...PaginationProps } = usePagination({
		items: listAfterFilterSort,
		base: '/application/users/list',
	});

	return (
		<ListFilterProvider
			{...filterState}
			singleFilters={['search']}
			multiFilters={['projects', 'groups']}
			filterLabels={{
				groups: {
					label: t`settings-users-list-filters-groups`,
					...groups.reduce((all, current) => {
						all[current.uuid] = current.name;
						return all;
					}, {}),
				},
				projects: {
					label: t`settings-users-list-filters-reports`,
					...app.projects.asArray.reduce((all, current) => {
						all[current.uuid] = current.name;
						return all;
					}, {}),
				},
			}}
		>
			<ListWithSidebar
				drawerContent={<UserListFilters groups={groups} />}
				open={drawerOpen}
				onClose={() => setDrawerOpen(false)}
				prominent={!app.layout.prominentHidden}
			>
				<ListControls
					sortOptions={[
						{ label: t('User creation: first to last'), value: 'id-asc' },
						{ label: t('User creation: last to first'), value: 'id-desc' },
						{ label: t('User name: A to Z'), value: 'fname-asc' },
						{ label: t('User name: Z to A'), value: 'fname-desc' },
					]}
					toggleFilterDrawer={() => setDrawerOpen(prev => !prev)}
				>
					<NoPermissionTooltip>
						<ActionButton
							disabled={
								!hasEditPermission || app.users.asArray.length >= app.package.users
							}
							onClick={e => setAddOpen(true)}
							data-onboarding="add-users-card"
							action="add"
							data-test-element="user-list-create-new"
						>
							{t`settings-users-list-add_user`}
						</ActionButton>
					</NoPermissionTooltip>
				</ListControls>

				{items.length === 0 && (
					<EmptyState
						icon={UsersIcon}
						primary={t`settings-users-list-no_users_filter_empty_state-primary`}
						secondary={t`settings-users-list-no_users_filter_empty_state-secondary`}
					/>
				)}
				{items.length > 0 && (
					<Box mt={1}>
						<Grid
							container
							spacing={2}
						>
							{items.map(user => (
								<Grid
									item
									xs={12}
									key={user.uuid}
								>
									<UserCard
										key={user.uuid}
										user={user}
										allGroups={groups}
									/>
								</Grid>
							))}
							<Grid
								item
								xs={12}
							>
								<Pagination
									{...PaginationProps}
									paper
								/>
							</Grid>
						</Grid>
					</Box>
				)}
			</ListWithSidebar>
			<UserAdditDialog
				additOpen={addOpen}
				setAddit={e => setAddOpen(e)}
				onSuccess={() => loadPermissionGroups()}
			/>
		</ListFilterProvider>
	);
}
