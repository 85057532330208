import React, { useState, useEffect, Fragment, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import { useTranslation } from 'react-i18next';
import get from 'lodash.get';
import set from 'lodash.set';
import { useImmer } from 'use-immer';

import { useSnackbar } from 'notistack';
import { DrawerWithAppBar } from '@/components/App';
import { useAppContext } from '@/components/AppContext';
import { DataSourceSelect } from '@/components/Select';
import { useTagsEditorContext } from './TagsEditorContext';
import { ruleBase, addBase } from './tagUtils';
import { useDataSourceContext, DataSourceAvatar } from '@/components/DataSources';
import {
	ActionIconButton,
	ActionButton,
	Typography,
	KnowledgeBaseLink,
} from '@/components/Layout';
import { ValidatedField } from '@/components/Validation';
import TagEditorDrawerConditions from './TagEditorDrawerConditions';

const useStyles = makeStyles(theme => ({
	stepper: {
		maxWidth: theme.spacing(50),
		padding: theme.spacing(1, 0),
	},
	marginLeft: {
		marginLeft: 'auto!important',
	},
	link: {
		marginLeft: theme.spacing(5),
	},
	footerPadding: {
		padding: theme.spacing(2),
	},
	indent: {
		paddingLeft: theme.spacing(2),
	},
	maxWidth: {
		maxWidth: '100%',
	},
}));

function getSteps() {
	return ['Select datasource', 'Add conditions'];
}

export default function TagEditorDrawer({
	open,
	onClose,
	onSubmit,
	conditionIndex,
	id,
	...props
}) {
	const classes = useStyles();

	const { app } = useAppContext();
	const { t } = useTranslation();
	const {
		state: { config },
		api,
	} = useTagsEditorContext();
	const { datasource } = useDataSourceContext();

	const [activeStep, setActiveStep] = useState(0);
	const [isUpdate, setIsUpdate] = useState(false);
	const steps = getSteps();
	const { enqueueSnackbar } = useSnackbar();
	const didRemoveCondition = useRef(false);

	//existing condition sets
	const [condition, setCondition] = useImmer({
		condition: ruleBase(),
		clicked: false,
		expanded: [],
		new: [],
	});

	const source = datasource.api.getSource(condition?.condition?.survey_id);

	const cardAction = useRef(null);

	useEffect(() => {
		didRemoveCondition.current = false;
		if (id && open) {
			setActiveStep(1);

			setCondition(draft => {
				const index = config.rules.findIndex(rule => rule.id === id);

				draft.condition = config.rules[index];
				draft.expanded = [];
				draft.new = [];
			});
		} else {
			setActiveStep(0);

			setCondition(draft => {
				draft.condition = ruleBase();
				draft.expanded = [];
				draft.clicked = false;
				draft.new = [];
			});
		}
	}, [id, open]);

	function getStepContent(step) {
		switch (step) {
			case 0:
				return "First things first, let's select a feedback form or data set to add tags to";
			case 1:
				return id
					? 'Add, edit or remove tag conditions for'
					: 'Well done! Now, the next step is to create conditions.';
			default:
				return 'Unknown step';
		}
	}

	function setClicked() {
		setCondition(draft => {
			draft.clicked = true;
		});
	}

	function setConditionValue(path, value) {
		setCondition(draft => {
			set(draft.condition, path, value);
		});
	}

	function addToArray(path, value) {
		setCondition(draft => {
			get(draft.condition, path).push(value);
		});
	}

	function removeFromArray(path, index, isConditionSet) {
		didRemoveCondition.current = true;

		const draftNewConditions = condition.new.reduce((conditions, current) => {
			if (current < index) {
				conditions.push(current);
			}
			if (current > index) {
				conditions.push(current - 1);
			}
			return conditions;
		}, []);

		const draftExpanded = condition.expanded.reduce((conditions, current) => {
			if (current < index) {
				conditions.push(current);
			}
			if (current > index) {
				conditions.push(current - 1);
			}
			return conditions;
		}, []);

		if (isConditionSet) {
			setCondition(draft => {
				draft.new = condition.condition.add.length > 1 ? draftNewConditions : [];
				draft.expanded = condition.condition.add.length > 1 ? draftExpanded : [];
			});
		}

		setCondition(draft => {
			get(draft.condition, path).splice(index, 1);
		});
	}

	function toggleExpand(index) {
		setCondition(draft => {
			if (draft.expanded.indexOf(index) > -1) {
				draft.expanded.splice(draft.expanded.indexOf(index), 1);
			} else {
				draft.expanded.push(index);
			}
		});
	}

	function handleSubmit() {
		setClicked();
		if (condition.condition.survey_id) {
			onClose();
			setIsUpdate(false);
			enqueueSnackbar(id ? t`Conditions updated` : t`Conditions saved`);

			api.dispatch({
				type: id || isUpdate ? 'update_rule' : 'add_filled_rule',
				payload: {
					rule: condition.condition,
					ruleId: condition.condition.id,
				},
			});

			//refresh dialog after submit..
			setCondition(draft => {
				draft.condition = ruleBase();
				draft.expanded = [];
				draft.new = [0];
			});

			if (typeof onSubmit === 'function') onSubmit();
		}
	}

	return (
		<DrawerWithAppBar
			ToolbarProps={{
				text:
					id || isUpdate ? t`Edit auto-tag conditions` : t`Create auto-tag conditions`,
			}}
			ToolbarContent={
				activeStep === 1 &&
				(condition.new.length > 0 || didRemoveCondition.current) && (
					<ActionButton
						onClick={() => handleSubmit()}
						color="primary"
						action="save"
						variant="contained"
						data-test-element="tagAddCondition"
						dataTrackEvent={
							id
								? 'settings_automated_tag_edited'
								: isUpdate
								? 'settings_automated_tag_added'
								: 'settings_automated_tag_created'
						}
					>
						{t`Save conditions`}
					</ActionButton>
				)
			}
			onClose={onClose}
			open={open}
			{...props}
		>
			<Grid
				container
				direction="column"
				spacing={2}
			>
				<Grid
					item
					className={classes.maxWidth}
				>
					<Card>
						<CardContent>
							<Grid
								container
								direction="column"
								spacing={3}
							>
								{!id && (
									<Grid item>
										<Stepper
											activeStep={activeStep}
											classes={{ root: classes.stepper }}
										>
											{steps.map((label, index) => {
												return (
													<Step key={label}>
														<StepButton
															onClick={() => {
																if (index < activeStep) {
																	setActiveStep(index);
																}
															}}
														>
															{t(label)}
														</StepButton>
													</Step>
												);
											})}
										</Stepper>
									</Grid>
								)}

								<Grid item>
									<Grid
										container
										spacing={2}
										alignItems="center"
									>
										<Grid
											item
											xs
										>
											<Typography variant="subtitle">
												{t(getStepContent(activeStep))}
											</Typography>
										</Grid>
										<Grid item>
											<KnowledgeBaseLink
												page="auto_tags"
												className={classes.link}
											>
												{t`More info`}
											</KnowledgeBaseLink>
										</Grid>
									</Grid>
								</Grid>

								{activeStep === 0 && (
									<Fragment>
										<Grid item>
											<ValidatedField
												component={DataSourceSelect}
												label={t`Select a data source`}
												value={condition.condition.survey_id}
												onChange={e => {
													const { value } = e.target;
													const existingSet = config.rules.find(
														config => config.survey_id == value
													);

													if (existingSet) {
														setIsUpdate(true);
														setCondition(draft => {
															const index = config.rules.findIndex(
																rule => rule.survey_id == value
															);

															draft.condition = config.rules[index];
															draft.expanded = [config.rules[index]?.add.length ?? 0];
															draft.new = [existingSet.add.length];
														});
														addToArray('add', addBase({ user_id: app.users.current.id }));
													} else {
														setIsUpdate(false);
														setConditionValue('survey_id', value);
														setConditionValue(
															'survey_key',
															datasource.api.getSource(value).survey_key
														);
														setConditionValue('add', [
															addBase({ user_id: app.users.current.id }),
														]);
														setCondition(draft => {
															draft.expanded = [0];
															draft.new = [0];
														});
													}
												}}
												validateValue={get(condition, 'condition.survey_id', null)}
												errorMessage={t`Please select a form or dataset`}
												rules={[['required', true]]}
												showErrors={condition.clicked}
												dataTestElement="tagDatasourceSelect"
											/>
										</Grid>
									</Fragment>
								)}

								{activeStep === 1 && (
									<Fragment>
										<Grid
											item
											ref={cardAction}
										>
											<Grid
												container
												alignItems="center"
												spacing={2}
											>
												<Grid item>
													<DataSourceAvatar
														survey_type={source.survey_type}
														survey_format={source?.survey_format ?? 'form'}
														campaign={source.campaign}
													/>
												</Grid>
												<Grid item>
													<Typography variant="body1">{source.name}</Typography>
												</Grid>
												{!id && (
													<Grid item>
														<ActionIconButton
															tooltip={t`Change datasource`}
															onClick={() => setActiveStep(lastStep => lastStep - 1)}
															action="edit"
														/>
													</Grid>
												)}
											</Grid>
										</Grid>

										<Grid
											item
											className={classes.maxWidth}
										>
											{activeStep === 1 && condition?.condition?.add && (
												<TagEditorDrawerConditions
													id={id}
													condition={condition}
													newConditions
													toggleExpand={toggleExpand}
													setCondition={setCondition}
													addToArray={addToArray}
													removeFromArray={removeFromArray}
													setConditionValue={setConditionValue}
													handleSubmit={handleSubmit}
												/>
											)}
										</Grid>
									</Fragment>
								)}
							</Grid>
						</CardContent>
						<CardActions classes={{ root: classes.footerPadding }}>
							{activeStep === 1 && (
								<ActionButton
									onClick={() => {
										toggleExpand(condition.condition.add.length);
										setCondition(draft => {
											draft.new = [...draft.new, condition.condition.add.length];
										});
										addToArray('add', addBase({ user_id: app.users.current.id }));
									}}
									color="primary"
									variant="outlined"
									size="medium"
									action="add_list"
								>
									{t`Add condition set`}
								</ActionButton>
							)}
							{(activeStep === 0 || condition.new.length > 0) && (
								<ActionButton
									onClick={() => {
										if (activeStep === 0) {
											setActiveStep(lastStep => lastStep + 1);
										} else {
											handleSubmit();
										}
									}}
									disabled={!condition?.condition?.survey_id}
									color="primary"
									action={activeStep === 1 ? 'save' : null}
									variant="contained"
									className={activeStep === 1 ? classes.marginLeft : ''}
									data-test-element="tagAddCondition"
									dataTrackEvent={
										id
											? 'settings_automated_tag_edited'
											: isUpdate
											? 'settings_automated_tag_added'
											: 'settings_automated_tag_created'
									}
								>
									{activeStep === 0
										? id
											? t`Edit conditions`
											: isUpdate
											? t`Add conditions`
											: t`Create conditions`
										: t`Save conditions`}
								</ActionButton>
							)}
						</CardActions>
					</Card>
				</Grid>

				{activeStep === 1 && condition?.condition?.add.length > condition.new.length && (
					<Grid item>
						<Typography
							variant="subtitle2"
							color="textSecondary"
							className={classes.indent}
						>{`${t`Existing condition sets`}`}</Typography>
					</Grid>
				)}

				{activeStep === 1 && condition?.condition?.add && (
					<Grid
						item
						className={classes.maxWidth}
					>
						<TagEditorDrawerConditions
							id={id}
							condition={condition}
							toggleExpand={toggleExpand}
							setCondition={setCondition}
							addToArray={addToArray}
							removeFromArray={removeFromArray}
							setConditionValue={setConditionValue}
							handleSubmit={handleSubmit}
						/>
					</Grid>
				)}
			</Grid>
		</DrawerWithAppBar>
	);
}
