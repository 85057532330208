import React from 'react';

import { useTranslation } from 'react-i18next';

import { useDataSourceContext } from '@/components/DataSources';
import { EmptyState, ActionButton } from '@/components/Layout';
import {
	useHasPermission,
	NoPermissionTooltip,
	permissionSubjects,
	permissionModules,
} from '@/components/Permission';

export default function EmptyStateHandleHasSurveys({
	primary,
	secondary,
	action,
	primaryNoSurvey,
	secondaryNoSurvey,
	actionNoSurvey = null,
	...props
}) {
	const { t } = useTranslation();
	const { datasource } = useDataSourceContext();
	const hasSurveyEditPermission = useHasPermission({
		module: permissionModules.data_collection,
		subject: permissionSubjects.feedback_forms_build,
	});

	const noSurveys = datasource.forms.asArray.length === 0;

	return (
		<EmptyState
			primary={noSurveys ? primaryNoSurvey : primary}
			secondary={noSurveys ? secondaryNoSurvey : secondary}
			action={
				noSurveys ? (
					<NoPermissionTooltip
						module={permissionModules.data_collection}
						subject={permissionSubjects.feedback_forms_build}
					>
						<ActionButton
							variant="contained"
							color="primary"
							dataTrackEvent="dashboard_emptystate_create_form"
							link
							to="/data-collection/survey/edit/new"
							disabled={!hasSurveyEditPermission}
						>
							{t`app_general-empty_state-no_survey_button`}
						</ActionButton>
					</NoPermissionTooltip>
				) : (
					action
				)
			}
			{...props}
		/>
	);
}
