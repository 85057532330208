import React from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { DrawerWithAppBar } from '@/components/App';
import { Chip, Box } from '@/components/Layout';
import { FeedbackList } from '@/components/Feedback';
import { DataFieldIcon } from '@/components/DataSources';

import { permissionSubjects, HasPermissionWithEmptyState } from '@/components/Permission';

const useStyles = makeStyles(theme => ({
	drawer: {
		maxWidth: 600,
	},
	buttonIcon: {
		marginRight: theme.spacing(1),
	},
	noWrapChip: {
		maxWidth: '100%',
		margin: theme.spacing(0.5, 0.25),
	},
	noWrapChipLabel: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: 'block',
	},
	msi: {
		fontWeight: 600,
	},
}));

export default function SmartRecapsQuickViewDrawer({
	chipLabel,
	surveyId,
	feedbackIds = [],
	mergeFeedback = [],
	...props
}) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<DrawerWithAppBar
			ToolbarProps={{
				text: t`reporting-text_analytics-quickview-title`,
			}}
			{...props}
		>
			<HasPermissionWithEmptyState
				permission="view"
				subject={permissionSubjects.feedback_inbox}
			>
				{chipLabel && (
					<Box>
						<Chip
							label={t(
								`reporting-feedback_inbox-smart_recaps-feedbacklist-chip-${chipLabel}`
							)}
							icon={<DataFieldIcon label="trend" />}
							classes={{ label: classes.noWrapChipLabel }}
							className={classes.noWrapChip}
						/>
					</Box>
				)}

				<FeedbackList
					noFilters
					noControls
					noPersist
					inDrawer
					surveyId={surveyId}
					feedbackIds={feedbackIds}
					mergeFeedback={mergeFeedback}
				/>
			</HasPermissionWithEmptyState>
		</DrawerWithAppBar>
	);
}
