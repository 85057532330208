import React from 'react';
import useFeatureToggle from './useFeatureToggle';

export default function FeatureToggle({ children, feature = '', fallback = null }) {
	const allowed = useFeatureToggle(feature);
	if (!allowed || !feature) {
		return fallback;
	}

	return children;
}
