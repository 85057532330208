import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ConversationalBotIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 100 100`}
			{...props}
		>
			<path
				fill="currentColor"
				d="M100.154,38.129c-0,-16.686 -13.617,-30.232 -30.388,-30.232c-0,-0 -39.225,-0 -39.225,-0c-2.508,-0 -4.945,0.303 -7.278,0.874c-13.261,3.248 -23.109,15.168 -23.109,29.358c-0,-0 -0,23.742 -0,23.742c-0,16.686 13.616,30.232 30.387,30.232c0,0 39.225,0 39.225,0c16.771,0 30.388,-13.546 30.388,-30.232c-0,0 -0,-23.742 -0,-23.742Zm-4.95,-0l0,23.742c0,13.968 -11.398,25.308 -25.438,25.308l-39.225,0c-14.039,0 -25.438,-11.34 -25.438,-25.308l0,-23.742c0,-9.876 5.698,-18.438 13.999,-22.607c3.44,-1.728 7.326,-2.701 11.439,-2.701l39.225,-0c14.04,-0 25.438,11.34 25.438,25.308Zm-32.911,27.713c-0,-0 -11.176,5.394 -24.427,-0.035c-1.263,-0.518 -2.71,0.082 -3.23,1.339c-0.521,1.256 0.082,2.697 1.345,3.214c15.456,6.333 28.493,-0.098 28.493,-0.098c1.226,-0.599 1.733,-2.075 1.131,-3.295c-0.602,-1.22 -2.086,-1.724 -3.312,-1.125Zm-31.103,-30.785c-5.007,0 -8.999,3.884 -8.999,8.574c-0,4.69 3.992,8.574 8.999,8.574c5.007,0 9,-3.884 9,-8.574c-0,-4.69 -3.993,-8.574 -9,-8.574Zm37.927,0c-5.007,0 -8.999,3.884 -8.999,8.574c-0,4.69 3.992,8.574 8.999,8.574c5.007,0 9,-3.884 9,-8.574c-0,-4.69 -3.993,-8.574 -9,-8.574Zm-37.927,4.924c2.196,0 4.05,1.593 4.05,3.65c0,2.057 -1.854,3.65 -4.05,3.65c-2.196,-0 -4.05,-1.593 -4.05,-3.65c0,-2.057 1.854,-3.65 4.05,-3.65Zm37.927,0c2.196,0 4.05,1.593 4.05,3.65c0,2.057 -1.854,3.65 -4.05,3.65c-2.196,-0 -4.05,-1.593 -4.05,-3.65c0,-2.057 1.854,-3.65 4.05,-3.65Z"
			/>
		</SvgIcon>
	);
}
