import React from 'react';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useAjaxForm } from '@/components/Ajax';
import { usePermissionContext } from './PermissionContext';
import { getErrorMessageTranslationKey } from './utils';

export default function useSavePermissionGroup({
	group = {},
	onSuccess = () => {},
	...props
}) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { updateCurrentPermissions } = usePermissionContext();
	const action = useAjaxForm({
		...props,
		url: group.uuid
			? '/application/permission/edit-group'
			: '/application/permission/add-group',
		data: {
			...group,
		},
		onSuccess: response => {
			if (response.code === 200) {
				updateCurrentPermissions();
				enqueueSnackbar(
					t('settings-access_groups-add_edit_dialog-success_message', {
						name: group.name,
					})
				);
				onSuccess();
			} else {
				enqueueSnackbar(t(getErrorMessageTranslationKey(response.message)));
			}
		},
		onError: response => {
			enqueueSnackbar(t(getErrorMessageTranslationKey(response.message)));
		},
	});

	return action;
}
