import React, { useContext, useState, useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import VirtualizedAutocomplete from './VirtualizedAutocomplete';
import { useExternalData } from '../Ajax';
import { compare } from '../../utils';

const useStyles = makeStyles(theme => ({
	option: {
		fontFamily: props =>
			`${props.family}, ${props.category === 'serif' ? 'serif' : 'sans-serif'}`,
	},
}));

function Option({ option, loadFont = () => {} }) {
	useEffect(() => {
		let mounted = true;
		if (option.family && mounted) {
			loadFont(option.family);
		}
		return function cleanup() {
			mounted = false;
		};
	}, [option]);
	const classes = useStyles(option);
	return <Typography className={classes.option}>{option.family}</Typography>;
}

function GoogleFontsAutoComplete({ loadFont, ...props }) {
	const categoryValues = {
		'sans-serif': 1,
		serif: 2,
		display: 3,
		handwriting: 4,
	};

	const [fonts, loading] = useExternalData(
		'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyC7l6i7hjaYpVSlbhnutTueTeHUcofdREI'
	);
	const options = useMemo(() => {
		return Array.isArray(fonts.items)
			? fonts.items
					.filter(family => categoryValues[family.category])
					.sort(
						(a, b) =>
							compare(categoryValues[a.category], categoryValues[b.category]) ||
							compare(a.family, b.family)
					)
			: [];
	}, [fonts]);

	return (
		<VirtualizedAutocomplete
			options={options}
			getOptionLabel={option => option.family}
			renderOption={option => (
				<Option
					option={option}
					loadFont={loadFont}
				/>
			)}
			{...props}
		/>
	);
}

export default GoogleFontsAutoComplete;
