import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { copyToClipboard } from '@/utils';
import Card from './Card';
import CardContent from './CardContent';
import Grid from './Grid';
import Typography from './Typography';
import Tooltip from './Tooltip';
import RenderConditional from './RenderConditional';

const useStyles = makeStyles(theme => ({
	actions: {},
	card: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		position: 'relative',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		height: '100%',
	},
	handleHover: {
		'& $actions': {
			opacity: 0,
			transition: theme.transitions.create('opacity'),
		},
		'&:hover $actions': {
			opacity: 1,
		},
	},
	cardSizeSmall: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: props => (props.alignItems ? props.alignItems : 'center'),
	},
	img: {
		backgroundSize: `auto calc(100% - ${theme.spacing(2)}px)`,
		backgroundColor: props => {
			if (!props.color) return null;

			return props.color === 'secondary'
				? theme.palette.secondary.light
				: props.color === 'primary'
				? theme.palette.primary.light
				: props.color;
		},
		width: '100%',
		height: 100,
	},
	imgSmall: {
		width: 80,
		minWidth: 48,
		minHeight: 80,
		height: '100%',
		backgroundSize: `calc(100% - ${theme.spacing(2)}px) auto`,
	},
	fill: {
		height: '100%',
		width: '100%',
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		flexDirection: 'column',
	},
	fillHeight: {
		height: '100%',
	},
	nopadding: {
		padding: '0!important',
	},
	content: {
		flexGrow: 1,
		flexShrink: 1,
	},
	fillWidth: {
		width: '100%',
	},
	maxWidth: {
		maxWidth: '100%',
	},
	columnCardActions: {
		flexDirection: 'column',
		alignItems: 'center',
	},
	subtitle2: {
		margin: theme.spacing(0.5, 0),
	},
	ellipsis: {
		minWidth: 0,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	copy: {
		cursor: 'copy',
	},
}));

function ConditionalCardActionArea({ onClick, children, ...props }) {
	const classes = useStyles();
	return typeof onClick === 'function' ? (
		<CardActionArea
			className={classes.fillHeight}
			onClick={onClick}
			{...props}
		>
			{children}
		</CardActionArea>
	) : (
		children
	);
}

const SystemElementCardBase = React.forwardRef(function SystemElementCardBase(
	{
		children,
		img = '',
		variant = 'card',
		size,
		onClick,
		actions,
		title,
		subtitle,
		subtitle2,
		outerContent,
		infoTitle,
		info,
		cardHeader,
		CardContentProps = {},
		InfoGridProps = {},
		CardMediaClassName = '',
		dataTestElement = '',
		className: classNameFromProps = '',
		rowInfoGridSize = {},
		showActionsOnHover,
		...props
	},
	ref
) {
	const { t } = useTranslation();
	const classes = useStyles(props);
	const { enqueueSnackbar } = useSnackbar();

	return (
		<Card
			className={`${variant === 'card' ? classes.card : ''} ${
				variant === 'row' ? classes.row : ''
			} ${size === 'small' ? classes.cardSizeSmall : ''} ${classNameFromProps} ${
				showActionsOnHover ? classes.handleHover : ''
			}`}
			ref={ref}
			{...props}
		>
			<ConditionalCardActionArea
				className={classes.fill}
				data-test-element={dataTestElement}
				onClick={onClick}
			>
				{cardHeader ? (
					cardHeader
				) : (
					<CardMedia
						image={img}
						className={`${classes.img} ${
							size === 'small' ? classes.imgSmall : ''
						} ${CardMediaClassName}`}
						data-onboarding="system-card-header"
					/>
				)}
				<CardContent
					className={classes.content}
					{...CardContentProps}
				>
					<Grid
						container
						alignItems="stretch"
						direction={variant === 'card' ? 'column' : 'row'}
						spacing={variant === 'card' ? 1 : 2}
					>
						<Grid
							item
							className={variant === 'card' ? `${classes.fillWidth}` : ''}
							{...rowInfoGridSize}
						>
							<Tooltip title={title}>
								<span>
									<Typography
										variant={size === 'small' ? 'subtitle1' : 'h6'}
										mb={variant === 'row' ? 1 : 0}
										className={classes.ellipsis}
									>
										{title}
									</Typography>
								</span>
							</Tooltip>
							<Typography
								color="textSecondary"
								variant={
									size === 'small' ? 'caption' : variant === 'row' ? 'body1' : 'body2'
								}
								noWrap={true}
							>
								{subtitle}
							</Typography>

							{subtitle2 && (
								<Typography
									className={classes.subtitle2}
									variant={'body2'}
									component="div"
								>
									{subtitle2}
								</Typography>
							)}
						</Grid>

						{info &&
							variant === 'row' &&
							info.map((item, index) => {
								return (
									<Grid
										key={item.label + item.value + index}
										item
										{...rowInfoGridSize}
										{...(item.size ?? {})}
									>
										<Typography
											mb={variant === 'row' ? 1 : 0}
											color={variant === 'row' ? 'textSecondary' : 'textPrimary'}
										>
											{item.label}
										</Typography>
										<div>{item.value}</div>
									</Grid>
								);
							})}

						{info && variant === 'card' && (
							<Grid
								item
								className={classes.maxWidth}
							>
								{infoTitle && <Typography variant="subtitle2">{infoTitle}</Typography>}
								<Grid
									container
									spacing={1}
									{...InfoGridProps}
								>
									{info.map((infoItem, index) => {
										return (
											<Grid
												key={infoItem.label + infoItem.value + 'info' + index}
												item
												direction="row"
												container
												alignItems="center"
												className={`${classes.infoRow} ${
													infoItem.copy ? classes.copy : ''
												}`}
												{...(infoItem.copy && {
													onClick: () => {
														copyToClipboard(infoItem.copy).then(() => {
															enqueueSnackbar(
																t('app_general-system_element_card-snackbary_copy', {
																	item: infoItem.copy,
																})
															);
														});
													},
												})}
											>
												<Grid
													item
													xs
												>
													<Typography
														component="div"
														variant="body2"
														color="textSecondary"
													>
														{infoItem.label}
													</Typography>
												</Grid>
												<Grid
													item
													className={`${
														typeof infoItem.value === 'string' ? classes.ellipsis : ''
													} 
														${
															variant === 'card' &&
															infoItem.label?.toLowerCase() === 'endpoint'
																? `${classes.fillWidth}`
																: ''
														}`}
												>
													<RenderConditional
														title={infoItem.value}
														condition={infoItem.label?.toLowerCase() === 'endpoint'}
													>
														<span>
															<Typography className={classes.ellipsis}>
																{infoItem.value}
															</Typography>
														</span>
													</RenderConditional>
												</Grid>
											</Grid>
										);
									})}
								</Grid>
							</Grid>
						)}

						{children && (
							<Grid
								item
								className={classes.nopadding}
							>
								{children}
							</Grid>
						)}
					</Grid>
				</CardContent>

				{outerContent}
			</ConditionalCardActionArea>
			{actions && (
				<CardActions
					className={`${classes.actions} ${
						size === 'small' ? classes.columnCardActions : ''
					}`}
				>
					{actions}
				</CardActions>
			)}
		</Card>
	);
});

export default SystemElementCardBase;
