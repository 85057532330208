import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { List, ListItem, ListItemText } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import get from 'lodash.get';
import { utils } from '@/components/Charts';
import { Typography } from '@/components/Layout';

function ChangesDialog({ open, onClose, discardChanges, removedCharts, saveChanges }) {
	const { t } = useTranslation();
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle>{t`reporting-dashboard-dialog_changes-title`}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{t`reporting-dashboard-dialog_changes-content`}
					{removedCharts.length > 0 && (
						<>
							<Typography>{t`reporting-dashboard-dialog_changes-charts_removed_list`}</Typography>
							<List>
								{removedCharts.map((chart, key) => {
									const chartTitle = get(chart, 'title.text', '');

									return (
										<ListItem key={key}>
											<ListItemText>
												<Typography fontWeight="bold">{chartTitle}</Typography>
											</ListItemText>
										</ListItem>
									);
								})}
							</List>
						</>
					)}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={discardChanges}>
					{t`reporting-dashboard-dialog_changes-cancel`}
				</Button>
				<Button
					onClick={saveChanges}
					color="primary"
					autoFocus
					variant="contained"
				>
					{t`reporting-dashboard-dialog_changes-submit`}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ChangesDialog;
