import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Skeleton from '@material-ui/lab/Skeleton';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '../AppContext';
import { ActionButton, Typography, Grid } from '../Layout';
import { useEFM } from '../Ajax';
import BillingCardBase from './BillingCardBase';
import CCIcon from './CCIcon';

const useStyles = makeStyles(theme => ({
	header: {
		marginBottom: theme.spacing(4),
	},
	row: {
		marginBottom: theme.spacing(2),
	},
	fullWidth: {
		width: '100%',
	},
	cardIcon: {
		width: 32,
	},
}));

function LabelValue({ label, value, loading, SkeletonProps = {} }) {
	return (
		<Fragment>
			<Typography variant="subtitle">{label}</Typography>
			<Typography variant="body1">
				{!loading ? (
					value
				) : (
					<Skeleton
						width="50%"
						{...SkeletonProps}
					/>
				)}
			</Typography>
		</Fragment>
	);
}

export default function PaymentDetailsOverviewCard({ onEdit = () => {}, disableAction }) {
	const { t } = useTranslation();
	const { app } = useAppContext();
	const classes = useStyles();
	const [{ card = {} }, loading] = useEFM('/account/billing/card');

	return (
		<BillingCardBase
			title={t`payment_details_overview_card-title`}
			action={
				<ActionButton
					onClick={onEdit}
					color="primary"
					disabled={disableAction}
				>
					{t`payment_details_overview_card-edit_label`}
				</ActionButton>
			}
		>
			<Fragment>
				<div className={classes.row}>
					<LabelValue
						label={t`payment_details_overview_card-card_holder_name`}
						value={card?.name}
						loading={loading}
					/>
				</div>

				<Grid
					container
					alignItems="center"
					spacing={6}
					className={classes.row}
				>
					<Grid item>
						<LabelValue
							label={t`payment_details_overview_card-card_number`}
							value={
								<Fragment>
									<CCIcon brand={card?.brand} /> •••• •••• •••• {card?.last4}
								</Fragment>
							}
							loading={loading}
							SkeletonProps={{
								width: 200,
							}}
						/>
					</Grid>

					<Grid item>
						<LabelValue
							label={t`payment_details_overview_card-card_expires`}
							value={`${card?.exp_month} / ${card?.exp_year}`}
							loading={loading}
							SkeletonProps={{
								width: 80,
							}}
						/>
					</Grid>
				</Grid>

				<div className={classes.row}>
					<LabelValue
						label={t`payment_details_overview_card-organisation_name`}
						value={app.organisations.current.name}
						loading={loading}
					/>
				</div>
				{app.organisations.current.vat_number && (
					<div className={classes.row}>
						<LabelValue
							label={t`payment_details_overview_card-organisation_vat`}
							value={app.organisations.current.vat_number}
							loading={loading}
						/>
					</div>
				)}

				<div className={classes.row}>
					<LabelValue
						label={t`payment_details_overview_card-organisation_address`}
						value={`${app.organisations.current.address_line1}, ${app.organisations.current.postal_code}, ${app.organisations.current.city}`}
						loading={loading}
					/>
				</div>
			</Fragment>
		</BillingCardBase>
	);
}
