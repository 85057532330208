import React from 'react';
import { ActionDetails } from '../../components/Actions';
import { useParams } from 'react-router-dom';

export default function ActionDetailPage() {
	const { actionId } = useParams();
	return (
		<ActionDetails
			isPage
			id={actionId}
		/>
	);
}
