import { useState, useEffect, useContext, useRef } from 'react';
import { axios, axiosInstance, CancelToken } from '@/lib/axios';
import objectToFormData from 'object-to-formdata';
import { useSessionStorage } from '../../hooks';

function parseStorageData(str) {
	try {
		const parsed = JSON.parse(str);
		return parsed && typeof parsed === 'object' ? parsed : {};
	} catch (e) {
		return {};
	}
}

function useExternalData(url, params = {}) {
	const [storageData, setStorageData] = useSessionStorage(url + JSON.stringify(params));
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [data, setData] = useState(parseStorageData(storageData));
	const cancel = useRef(() => {});

	function loadData() {
		setLoading(true);
		axios
			.get(url, objectToFormData(Object.assign({}, params)), {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				cancelToken: new CancelToken(cancelToken => (cancel.current = cancelToken)),
			})
			.then(r => {
				setLoading(false);
				setData(r.data);
				setStorageData(JSON.stringify(r.data));
			})
			.catch(e => {
				if (!axios.isCancel(e)) {
					setLoading(false);
					setError(e);
				}
			});
	}

	useEffect(() => {
		loadData();
		return () => cancel.current();
	}, [
		url,
		JSON.stringify(params),
		//, users.current.id, projects.current.id, organisations.current.id, date.fromDate, date.toDate
	]);

	return [data, loading, error, loadData];
}

export default useExternalData;
