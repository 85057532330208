import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';

const useStyles = makeStyles(theme => ({
	titleSpace: {
		marginBottom: theme.spacing(1),
	},
	spaceBottom: {
		marginBottom: theme.spacing(2),
	},
}));

export default function EditorOptionSectionCard({ children, title, header, actions }) {
	const classes = useStyles();
	return (
		<Fragment>
			{title && (
				<Typography
					variant="subtitle2"
					className={classes.titleSpace}
				>
					{title}
				</Typography>
			)}
			<Card className={classes.spaceBottom}>
				{header && <CardHeader {...header} />}
				{children}
				{actions && <CardActions>{actions}</CardActions>}
			</Card>
		</Fragment>
	);
}
