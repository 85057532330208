import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useImmerReducer } from 'use-immer';
import { useSnackbar } from 'notistack';

import { useAppContext } from '@/components/AppContext';
import { DrawerWithAppBar } from '@/components/App';
import {
	Card,
	CardContent,
	Typography,
	Stepper,
	Step,
	StepButton,
	StepLabel,
	Box,
	ActionButton,
	Chip,
	SimpleDialog,
	Alert,
	Link,
} from '@/components/Layout';
import { ValidatedField } from '@/components/Validation';
import { ProjectsAutocomplete } from '@/components/Projects';
import { UserAutocomplete } from '@/components/Users';
import { useTrackingEvents } from '@/components/Tracking';
import PermissionMatrix from './PermissionMatrix';
import {
	permissionGroupBase,
	defaultPermissions,
	nonEditablePermissionModules,
	nonEditablePermissionSubjects,
	permissionTypes,
	permissionGroupTypes,
} from './constants';
import useSavePermissionGroup from './useSavePermissionGroup';
import { isAdminOwnerGroup } from './utils';

const steps = ['name_reports', 'permissions', 'users'];

const useStyles = makeStyles(theme => ({
	stepper: {
		background: 'transparent',
		maxWidth: 640,
	},
	chip: {
		margin: theme.spacing(0.25),
	},
	clearAll: {
		marginLeft: theme.spacing(1),
	},
}));

function reducer(state, action) {
	const { type, payload } = action;

	switch (type) {
		case 'set_value':
			state[payload.property] = payload.value;
			return;

		case 'add_member':
			state.members.push(payload.value);
			return;

		case 'remove_member':
			state.members.splice(state.members.indexOf(payload.member), 1);
			return;

		case 'add_project':
			state.projects.push(payload.value);
			return;

		case 'remove_project':
			state.projects.splice(state.projects.indexOf(payload.project), 1);
			return;

		case 'set_rights':
			if (
				nonEditablePermissionModules.includes(payload.module) &&
				payload.permission === permissionTypes.edit
			) {
				return;
			}

			if (!state.rights[payload.module]) {
				state.rights[payload.module] = {};
			}

			//set all properties for a module
			if (!payload.subject) {
				Object.keys(defaultPermissions[payload.module] ?? {}).forEach(subject => {
					if (
						nonEditablePermissionSubjects.includes(subject) &&
						payload.permission === permissionTypes.edit
					) {
						return;
					}
					state.rights[payload.module][subject] = payload.permission;
				});
				return;
			}

			if (
				nonEditablePermissionSubjects.includes(payload.subject) &&
				payload.permission === permissionTypes.edit
			) {
				return;
			}

			if (!state.rights[payload.module][payload.subject]) {
				state.rights[payload.module][payload.subject] = {};
			}

			state.rights[payload.module][payload.subject] = payload.permission;

			return;

		case 'set_initial_state':
			Object.assign(state, payload);
			return;

		default:
			return;
	}
}

export default function PermissionGroupAddEditDrawer({
	group = {},
	onClose: onCloseFromProps = () => {},
	getPermissionGroups = () => {},
	hasOwnerGroup,
	...props
}) {
	const { app } = useAppContext();
	const [state, dispatch] = useImmerReducer(reducer, {
		...permissionGroupBase,
		...group,
	});
	const { enqueueSnackbar } = useSnackbar();
	const [removeSelfOpen, setRemoveSelfOpen] = useState(false);
	const [removeAll, setRemoveAll] = useState(null);
	const isAdminOwner = isAdminOwnerGroup(group);
	const nameValid = String(state.name).length > 0;
	const [activeStep, setActiveStep] = useState(isAdminOwner ? steps[2] : steps[0]);
	const { track } = useTrackingEvents();
	const trackName = str =>
		`access_groups_${group.uuid ? 'edit' : 'create'}_dialog_${str}`;

	useEffect(() => {
		if (Object.keys(group).length > 0) {
			dispatch({
				type: 'set_initial_state',
				payload: {
					...group,
				},
			});
		}
	}, [group]);

	const { t } = useTranslation();
	const classes = useStyles();

	const saveGroup = useSavePermissionGroup({
		valid: () => nameValid,
		group: {
			...state,
		},
		onSuccess: () => {
			track(
				trackName('_saved', {
					...state,
				})
			);
			onClose();
			setActiveStep(isAdminOwner ? steps[2] : steps[0]);
			getPermissionGroups();
			dispatch({
				type: 'set_initial_state',
				payload: {
					...permissionGroupBase,
				},
			});
		},
	});

	const onClose = () => {
		track(
			trackName('_closed', {
				...state,
			})
		);
		if (group.uuid) {
			dispatch({
				type: 'set_initial_state',
				payload: {
					...group,
				},
			});
		}
		onCloseFromProps();
	};

	const next = () => {
		if (steps.indexOf(activeStep) === 0 && !nameValid) {
			saveGroup.setClicked(true);
			return;
		}
		track(
			trackName('_next', {
				...state,
			})
		);
		setActiveStep(steps[steps.indexOf(activeStep) + 1]);
	};

	const back = () => {
		track(
			trackName('_back', {
				...state,
			})
		);
		setActiveStep(steps[steps.indexOf(activeStep) - 1]);
	};

	return (
		<>
			<DrawerWithAppBar
				ToolbarProps={{
					text: `${
						group.uuid
							? t`settings-access_groups-add_edit_dialog-title-edit`
							: t`settings-access_groups-add_edit_dialog-title-add`
					}${group.uuid ? ` - ${group.name}` : ''}`,
				}}
				onClose={onClose}
				{...props}
			>
				{!isAdminOwner && (
					<>
						<Stepper
							nonLinear
							classes={{
								root: classes.stepper,
							}}
						>
							{steps.map(step => (
								<Step
									active={step === activeStep}
									onClick={() => setActiveStep(step)}
									key={step}
									data-track-event={trackName(`stepper_${step}`)}
									data-track-event-props={JSON.stringify(state)}
								>
									<StepButton>
										<StepLabel error={false}>
											{t(`settings-access_groups-add_edit_dialog-steps-${step}`)}
										</StepLabel>
									</StepButton>
								</Step>
							))}
						</Stepper>
						{activeStep === steps[0] && (
							<>
								<Card>
									<CardContent>
										<Typography
											fontWeight="medium"
											mb={2}
										>{t`settings-access_groups-add_edit_dialog-name`}</Typography>
										<ValidatedField
											label={t`settings-access_groups-add_edit_dialog-name-label`}
											fullWidth
											value={state.name}
											onChange={e =>
												dispatch({
													type: 'set_value',
													payload: {
														property: 'name',
														value: e.target.value,
													},
												})
											}
											data-testid="permission-group-name"
											showErrors={saveGroup.clicked}
											rules={{
												required: true,
											}}
											messages={{
												required: t`settings-access_groups-add_edit_dialog-name-required`,
											}}
										/>
									</CardContent>
								</Card>
								<Box mt={3}>
									<Card>
										<CardContent>
											<Box mb={2}>
												<Typography
													display="inline"
													fontWeight="medium"
													tooltip={t`settings-access_groups-add_edit_dialog-reports-tooltip`}
												>
													{t`settings-access_groups-add_edit_dialog-reports_access`}
												</Typography>
											</Box>
											<ProjectsAutocomplete
												label={t`settings-access_groups-add_edit_dialog-reports-label`}
												multiple
												value={[]}
												onChange={(e, value) =>
													dispatch({
														type: 'add_project',
														payload: {
															value: value[0]?.uuid,
														},
													})
												}
												helperText={
													<Link
														onClick={() =>
															dispatch({
																type: 'set_value',
																payload: {
																	property: 'projects',
																	value: app.projects.asArray.map(
																		project => project.uuid
																	),
																},
															})
														}
													>
														{t`settings-access_groups-add_edit_dialog-add_all`}
													</Link>
												}
												exclude={state.projects}
											/>

											<Box mt={4}>
												<Typography
													mb={1}
													fontWeight="medium"
												>{t`settings-access_groups-add_edit_dialog-reports_in_group`}</Typography>
												{state.projects.map(projectUuid => (
													<Chip
														label={app.api.getProjectByUuid(projectUuid).name}
														onDelete={() =>
															dispatch({
																type: 'remove_project',
																payload: {
																	project: projectUuid,
																},
															})
														}
														className={classes.chip}
													/>
												))}
												{state.projects.length > 0 && (
													<Link
														onClick={() => setRemoveAll('projects')}
														variant="caption"
														className={classes.clearAll}
													>
														{t`settings-access_groups-add_edit_dialog-remove_all`}
													</Link>
												)}
												{state.projects.length === 0 && (
													<Typography
														color="textSecondary"
														variant="body2"
													>
														{t`settings-access_groups-add_edit_dialog-no_reports_in_group`}
													</Typography>
												)}
											</Box>
										</CardContent>
									</Card>
								</Box>

								<Box mt={3}>
									<ActionButton
										variant="contained"
										color="primary"
										onClick={next}
									>
										{t`settings-access_groups-add_edit_dialog-next`}
									</ActionButton>
								</Box>
							</>
						)}
						{activeStep === steps[1] && (
							<>
								<Card>
									<CardContent>
										<PermissionMatrix
											permissions={state.rights}
											onChange={payload =>
												dispatch({
													type: 'set_rights',
													payload,
												})
											}
										/>
									</CardContent>
								</Card>

								<Box mt={3}>
									<ActionButton
										variant="contained"
										color="primary"
										onClick={next}
									>
										{t`settings-access_groups-add_edit_dialog-next`}
									</ActionButton>
								</Box>
							</>
						)}
					</>
				)}

				{activeStep === steps[2] && (
					<>
						{isAdminOwnerGroup(group) && (
							<Box mb={2}>
								<Alert
									variant="outlined"
									severity="info"
									title={t(
										`settings-access_groups-add_edit_dialog-${group.type}_alert-title`
									)}
								>
									{t(
										`settings-access_groups-add_edit_dialog-${group.type}_alert-content`
									)}
								</Alert>
							</Box>
						)}
						<Card>
							<CardContent>
								<Box mb={2}>
									<Typography
										display="inline"
										fontWeight="medium"
										tooltip={t`settings-access_groups-add_edit_dialog-users-tooltip`}
									>
										{t`settings-access_groups-add_edit_dialog-users_access`}
									</Typography>
								</Box>
								<UserAutocomplete
									label={t`settings-access_groups-add_edit_dialog-users-label`}
									multiple
									value={[]}
									onChange={(e, value) =>
										dispatch({
											type: 'add_member',
											payload: {
												value: value[0]?.uuid,
											},
										})
									}
									helperText={
										<Link
											onClick={() =>
												dispatch({
													type: 'set_value',
													payload: {
														property: 'members',
														value: app.users.asArray.map(user => user.uuid),
													},
												})
											}
										>
											{t`settings-access_groups-add_edit_dialog-add_all`}
										</Link>
									}
									exclude={state.members}
								/>

								<Box mt={4}>
									<Typography
										mb={1}
										fontWeight="medium"
									>{t`settings-access_groups-add_edit_dialog-users_in_group`}</Typography>
									{state.members.map(userUuid => (
										<Chip
											key={userUuid}
											label={app.api.getUserName(app.api.getUserByUuid(userUuid).id)}
											onDelete={() => {
												if (
													permissionGroupTypes.owner === group.type &&
													state.members.length === 1
												) {
													return enqueueSnackbar(
														t`settings-access_groups-add_edit_dialog-owner_at_least_1_alert`
													);
												}

												if (
													!hasOwnerGroup &&
													permissionGroupTypes.admin === group.type &&
													state.members.length === 1
												) {
													return enqueueSnackbar(
														t`settings-access_groups-add_edit_dialog-admin_at_least_1_alert`
													);
												}

												if (userUuid === app.users.current.uuid) {
													return setRemoveSelfOpen(true);
												}

												dispatch({
													type: 'remove_member',
													payload: {
														member: userUuid,
													},
												});
											}}
											className={classes.chip}
										/>
									))}
									{state.members.length > 0 && !isAdminOwner && (
										<Link
											onClick={() => setRemoveAll('members')}
											variant="caption"
											className={classes.clearAll}
										>
											{t`settings-access_groups-add_edit_dialog-remove_all`}
										</Link>
									)}
									{state.members.length === 0 && (
										<Typography
											color="textSecondary"
											variant="body2"
										>
											{t`settings-access_groups-add_edit_dialog-no_users_in_group`}
										</Typography>
									)}
								</Box>
							</CardContent>
						</Card>

						<Box mt={3}>
							{!isAdminOwner && (
								<Box
									display="inline-flex"
									mr={2}
								>
									<ActionButton
										variant="text"
										onClick={back}
										loading={saveGroup.loading}
									>
										{t`settings-access_groups-add_edit_dialog-back`}
									</ActionButton>
								</Box>
							)}
							<ActionButton
								variant="contained"
								color="primary"
								onClick={saveGroup.postForm}
								loading={saveGroup.loading}
							>
								{t(
									`settings-access_groups-add_edit_dialog-submit-${
										state.uuid ? 'edit' : 'add'
									}`
								)}
							</ActionButton>
						</Box>
					</>
				)}
			</DrawerWithAppBar>
			<SimpleDialog
				open={removeSelfOpen}
				onClose={() => setRemoveSelfOpen(false)}
				title={t`settings-access_groups-remove_self_dialog-title`}
				submit={t`settings-access_groups-remove_self_dialog-submit`}
				onSubmit={() => {
					dispatch({
						type: 'remove_member',
						payload: {
							member: app.users.current.uuid,
						},
					});
					setRemoveSelfOpen(false);
				}}
			>
				<Typography>{t`settings-access_groups-remove_self_dialog-content`}</Typography>
			</SimpleDialog>

			<SimpleDialog
				open={Boolean(removeAll)}
				onClose={() => setRemoveAll(null)}
				title={t(`settings-access_groups-remove_all-${removeAll}-title`)}
				submit={t(`settings-access_groups-remove_all-${removeAll}-submit`)}
				onSubmit={() => {
					dispatch({
						type: 'set_value',
						payload: {
							property: removeAll,
							value: [],
						},
					});
					setRemoveAll(null);
				}}
			>
				<Typography>
					{t(`settings-access_groups-remove_all-${removeAll}-content`)}
				</Typography>
			</SimpleDialog>
		</>
	);
}
