export function simulateClick(selector) {
	let success = false;
	try {
		const el = document.querySelector(selector);
		if (el) {
			el.click();
			success = true;
		}
	} catch (e) {}

	return success;
}

export function parseUserName(fullName = '', type) {
	let nameObject = fullName.split(' ');

	const firstName = nameObject.shift() ?? '';
	const lastName = nameObject.pop() ?? '';

	if (type === 'firstname') {
		return firstName;
	}
	if (!nameObject.length) {
		return lastName;
	}
	return `${lastName}, ${nameObject.join(' ')}`;
}

export function capitalizeInput(value = '') {
	return value.charAt(0).toUpperCase() + value.slice(1);
}

export function organisationFromEmail(email = '') {
	const customerEmail = email.split('@');
	if (customerEmail.length < 2) {
		return capitalizeValue(email);
	}

	return capitalizeValue(customerEmail[1].split('.')[0]);
}

export function capitalizeValue(value = '') {
	return value.charAt(0).toUpperCase() + value.slice(1);
}

export function generateDummyDashboard(chartIds = [], manualLayout = []) {
	const cols = { lg: 12, xs: 3 };

	const layoutColumns = cols.lg;
	const gridItemHeight = 5;
	let columnsCount = 0;

	const dummyLayout = chartIds.map((chartId, i) => {
		const columnsPerGridItem = manualLayout[i] ?? 6;

		const chart = {};
		chart.w = columnsPerGridItem;
		chart.h = gridItemHeight;
		chart.x = (i * columnsPerGridItem) % layoutColumns;
		chart.y = Math.floor(columnsCount / 12) * gridItemHeight;
		chart.i = String(chartId);
		chart.moved = false;
		chart.static = false;

		columnsCount += columnsPerGridItem;

		return chart;
	});

	return JSON.stringify({ lg: dummyLayout });
}

export function onboardingSettings() {}
