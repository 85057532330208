import ShowChartIcon from '@material-ui/icons/ShowChart';
import ActionIcon from '@material-ui/icons/AssignmentTurnedIn';
import SurveyIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Settings';
import IntegrationsIcon from '@material-ui/icons/CallMerge';

import { permissionSubjects, permissionModules } from '../Permission';

export const menuItems = [
	{
		title: 'Reporting',
		basePath: '/reporting/',
		icon: <ShowChartIcon />,
		permissionModule: permissionModules.reporting,
		subItems: [
			{
				title: 'Dashboards',
				path: '/reporting/dashboard',
				permissionSubject: permissionSubjects.dashboard,
			},
			{
				title: 'Data explorer',
				path: '/reporting/data-explorer',
				permissionSubject: permissionSubjects.dashboard,
			},
			{
				title: 'Direct insights',
				path: '/reporting/direct-insights',
				permissionSubject: permissionSubjects.dashboard,
			},
			{
				title: 'Feedback inbox',
				path: '/reporting/inbox',
				permissionSubject: permissionSubjects.feedback_inbox,
			},
			{
				title: 'Feedback exports',
				path: '/reporting/exports',
				permissionSubject: permissionSubjects.exports,
			},
			{
				title: 'Text analytics',
				path: '/reporting/analytics',
				permissionSubject: permissionSubjects.text_analytics,
			},
		],
	},
	{
		title: 'Actions',
		basePath: '/actions/',
		icon: <ActionIcon />,
		permissionModule: permissionModules.actions,
		subItems: [
			{
				title: 'Manage actions',
				path: '/actions/inbox',
				permissionSubject: permissionSubjects.action_management,
			},
		],
	},
	{
		title: 'Data collection',
		basePath: '/data-collection/',
		icon: <SurveyIcon />,
		permissionModule: permissionModules.data_collection,
		subItems: [
			{
				title: 'Datasets',
				path: '/data-collection/dataset',
				permissionSubject: permissionSubjects.datasets,
			},
			{
				title: 'Feedback forms',
				path: '/data-collection/survey',
				permissionSubject: permissionSubjects.feedback_forms_build,
			},
			{
				title: 'Deployments',
				path: '/data-collection/deployment',
				permissionSubject: permissionSubjects.deployments,
			},
		],
	},
	{
		title: 'Settings',
		basePath: '/application/',
		icon: <SettingsIcon />,
		permissionModule: permissionModules.settings,
		subItems: [
			{
				title: 'User management',
				path: '/application/users',
				permissionSubject: permissionSubjects.user_management,
			},
			{
				title: 'Reports',
				path: '/application/reports',
				permissionSubject: permissionSubjects.reports,
			},
			{
				title: 'Tags',
				path: '/application/tags',
				permissionSubject: permissionSubjects.tag_management,
			},
			{
				title: 'Standard answers',
				path: '/application/standard-answers',
				permissionSubject: permissionSubjects.standard_answers,
			},
			{
				title: 'Email alerts',
				path: '/application/alerts',
				permissionSubject: permissionSubjects.alerts,
			},
			{
				title: 'Notifications',
				path: '/application/notifications',
			},
		],
	},
	{
		title: 'Integrations',
		basePath: '/integrations/',
		icon: <IntegrationsIcon />,
		permissionModule: permissionModules.integrations,
		subItems: [
			{
				title: 'Feedback API',
				path: '/integrations/api',
				permissionSubject: permissionSubjects.api,
			},
			{
				title: 'Webhooks & Apps',
				path: '/integrations/webhooks',
				permissionSubject: permissionSubjects.webhooks_apps,
			},
		],
	},
];
