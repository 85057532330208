import React, { createContext, useContext } from 'react';

const TagsEditorContext = createContext({});

export default TagsEditorContext;

export function useTagsEditorContext() {
	return useContext(TagsEditorContext);
}

export function TagsEditorProvider({ value, children }) {
	return (
		<TagsEditorContext.Provider value={value}>{children}</TagsEditorContext.Provider>
	);
}
