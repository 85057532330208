import React from 'react';

import { useAjaxForm } from '../Ajax';

export default function useCopyChart({ onSuccess = () => {} }) {
	const action = useAjaxForm({
		url: '/dashboard/ajax/copy-chart',
		onSuccess: response => {
			onSuccess(response);
		},
	});
	return action;
}
