import React, { forwardRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	wrapper: {
		whiteSpace: 'nowrap',
		width: '100%',
		position: 'relative',
	},
	wrapIcon: {
		position: 'relative',
	},
	logo: {
		width: 36,
		verticalAlign: 'middle',
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(1),
	},
	logoDrawer: {
		width: '100%',
		maxWidth: 100,
		verticalAlign: 'middle',
		marginRight: props => (props.inDrawer ? theme.spacing(2) : 0),
		marginLeft: props => (props.inDrawer ? theme.spacing(1) : 0),
	},
	logoDrawerText: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: 600,
		display: 'inline-flex',
	},
}));

const Logo = forwardRef(function Logo(
	{
		withText,
		onBg = 'dark',
		className = '',
		classes: classesFromProps = {},
		inDrawer = false,
	},
	ref
) {
	const classes = useStyles({ inDrawer });
	const { wrapper = '' } = classesFromProps;

	return withText ? (
		<div
			ref={ref}
			className={`${classes.wrapper} ${wrapper}`}
		>
			<img
				src={
					onBg === 'dark'
						? `/assets/img/logo/logo-text-white.svg`
						: '/assets/img/logo/logo-text.svg'
				}
				className={`${classes.logoDrawer} ${className}`}
			/>
		</div>
	) : (
		<div className={classes.wrapIcon}>
			<img
				src={`/assets/img/logo/logo-icon.svg`}
				className={`${classes.logo} ${className}`}
			/>
		</div>
	);
});

export default Logo;
