import React from 'react';
import { faAndroid } from '@fortawesome/free-brands-svg-icons';
import FaIcon from './FaIcon';

export default function AndroidIcon(props) {
	return (
		<FaIcon
			icon={faAndroid}
			{...props}
		/>
	);
}
