import React from 'react';

import Switch from '@material-ui/core/Switch';

import { makeStyles } from '@/styles';

import {
	Card,
	CardActions,
	Grid,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	Typography,
} from '@/components/Layout';

import { useSurveyEditorContext } from './SurveyEditorContext';
import { useSurveyDesignContext } from './SurveyDesignContext';
import get from 'lodash.get';
import { useHasPermission, permissionSubjects } from '@/components/Permission';

const useStyles = makeStyles(theme => ({
	list: {
		width: '100%',
		padding: 0,
	},
	spaceBottom: {
		marginBottom: theme.spacing(2),
	},
	titleSpace: {
		marginBottom: theme.spacing(1),
	},
	allowOverflow: {
		overflow: 'visible',
	},
}));

export default function SurveyEditorBuildList({
	title,
	subtitle,
	variant,
	options = [],
	exclusive = false,
	actions,
	module,
	subject = permissionSubjects.feedback_forms_build,
	allowOverflow,
}) {
	const { editor } = useSurveyEditorContext();
	//keep track of editor settings in widget
	const { design } = useSurveyDesignContext();
	const classes = useStyles();
	const activeBlock = editor.state.blocks[editor.state.activeBlockId] || {};
	const hasEditPermission = useHasPermission({
		module,
		subject,
	});

	return (
		<>
			<Typography
				variant="subtitle2"
				className={classes.titleSpace}
			>
				{title}
			</Typography>
			{subtitle && (
				<Typography
					variant="caption"
					color="textSecondary"
					display="block"
					className={classes.spaceBottom}
				>
					{subtitle}
				</Typography>
			)}

			<Card
				className={`${classes.spaceBottom} ${allowOverflow ? classes.allowOverflow : ''}`}
			>
				<List className={classes.list}>
					{options
						.filter(option => option.show)
						.map(option => {
							const {
								toggleOn = true,
								toggleOff = false,
								nested = 'properties',
								widget = false,
							} = option;
							const value = option.value
								? option.value
								: get(
										activeBlock,
										`${nested ? nested + '.' : ''}${option.name}`,
										toggleOff
								  );
							const checked =
								typeof option.customTestFn === 'function'
									? option.customTestFn()
									: Boolean(value) || value === toggleOn;
							const updateProps =
								typeof option.updateFn === 'function'
									? option.updateFn
									: () => {
											if (widget) {
												design.api.updateDesignState(
													`widget.${[option.name]}`,
													checked ? toggleOff : toggleOn
												);
											} else {
												editor.api.updateBlock(
													editor.state.activeBlockId,
													{ [option.name]: checked ? toggleOff : toggleOn },
													nested
												);
											}
									  };

							const ListItemProps = {
								divider: !option.disableDivider,
								key: option.name,
								...(option.toggle &&
									!option.disabled && {
										button: true,
										onClick: updateProps,
									}),
								...(option.toggle &&
									option.disabled &&
									option.allowDisabledEvent && {
										button: true,
										onClick: updateProps,
									}),
								...(option.ListItemProps && option.ListItemProps),
								...(!hasEditPermission && {
									disabled: true,
								}),
							};

							if (option.header)
								return <ListSubheader disableSticky>{option.label}</ListSubheader>;

							return (
								<ListItem {...ListItemProps}>
									<Grid
										container
										alignItems="center"
									>
										<Grid
											item
											xs
										>
											{option.content ? (
												option.content
											) : (
												<ListItemText
													primary={option.label}
													secondary={option.secondary}
												/>
											)}
										</Grid>

										{option.toggle && (
											<Grid item>
												<Switch
													disabled={!hasEditPermission || option.disabled || false}
													onClick={updateProps}
													checked={checked}
												/>
											</Grid>
										)}

										{option.other && <Grid item>{option.other}</Grid>}
									</Grid>
								</ListItem>
							);
						})}
				</List>
				{actions && <CardActions>{actions}</CardActions>}
			</Card>
		</>
	);
}
