import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

import { useAppContext } from '../AppContext';
import {
	EditorOptionList,
	EditorOptionExpansionPanel,
	ActionIconButton,
	ActionButton,
	EditorListRadioGroup,
	EmptyState,
} from '../Layout';
import { useTagsEditorContext } from './TagsEditorContext';
import { DataSourceList, useDataSourceContext } from '../DataSources';
import { DataFieldSelect } from '../Select';
import { TagsAutoComplete, PossibleValues } from '../AutoComplete';
import { operators } from './tagUtils';

import { useImmer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import get from 'lodash.get';

import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
	tabRow: {
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	tabRoot: {
		//minWidth:60
	},
	tab: {
		height: '100%',
	},
	tabPadding: {
		padding: theme.spacing(1),
	},
	alignTextWithInput: {
		verticalAlign: 'middle',
	},
	inputInText: {
		margin: theme.spacing(0, 1),
		width: 100,
	},
	selectInput: {
		width: 'auto',
	},
	datePickerInput: {
		width: 140,
	},
	numberInput: {
		width: 60,
	},
	addCondition: {
		margin: theme.spacing(2, 0),
	},
	thenMargin: {
		marginBottom: theme.spacing(2),
	},
}));
export default function TagsEditorSidebar({ ...props }) {
	const { app } = useAppContext();
	const {
		state,
		state: { config },
		api,
	} = useTagsEditorContext();
	const { t } = useTranslation();
	const { datasource } = useDataSourceContext();
	const [sidebarState, setSidebarState] = useImmer({
		addMenu: {},
		collapsed: {},
	});

	const classes = useStyles();
	const selectedRule = config.rules[state.selectedRuleIndex] || {};
	const surveyInRule = datasource.api.getSource(selectedRule.survey_id);

	function setOption(key, value, conditionIndex) {
		api.dispatch({
			type: 'set_rule_option',
			payload: {
				path: `[${state.selectedRuleIndex}].add[${conditionIndex}].${key}`,
				value: value,
			},
		});
	}
	function addFeedbackCondition(conditionIndex) {
		api.dispatch({
			type: 'add_feedback_condition',
			payload: {
				ruleIndex: state.selectedRuleIndex,
				conditionIndex,
			},
		});
	}
	function removeFeedbackCondition(index, conditionIndex) {
		api.dispatch({
			type: 'remove_feedback_condition',
			payload: {
				ruleIndex: state.selectedRuleIndex,
				conditionIndex,
				index,
			},
		});
	}
	function addCondition() {
		api.dispatch({
			type: 'add_condition',
			payload: {
				ruleIndex: state.selectedRuleIndex,
				condition: {
					user_id: app.users.current.id,
				},
			},
		});
	}
	function removeCondition(conditionIndex) {
		api.dispatch({
			type: 'delete_condition',
			payload: {
				ruleIndex: state.selectedRuleIndex,
				conditionIndex,
			},
		});
	}

	return (
		<Fragment>
			<Tabs
				value={state.currentTab}
				onChange={(e, value) =>
					api.dispatchSimple({
						type: 'set_tab',
						payload: value,
					})
				}
				centered
			>
				<Tab label={t`Data sources`} />
				<Tab label={t('Tag conditions')} />
				<Tab label={t('A.I. tagging')} />
			</Tabs>
			<div>
				{state.currentTab === 0 && (
					<div>
						<DataSourceList
							//withFields
							dragSource
							//selectedSurvey={get(series, '[0].survey_id')}
							//addIndicators={series.map(serie => ({id:String(serie.y), color:serie.color}) )}
							//	scaleInAnimation
							// DataFieldListProps={{
							// 	inset:true
							// }}
						/>
					</div>
				)}

				{state.currentTab === 1 && (
					<div className={`${classes.tab} ${classes.tabPadding}`}>
						{typeof state.selectedRuleIndex !== 'number' ? (
							<EmptyState
								primary={t`Select a data source`}
								secondary={t`Select a data source to setup conditions for automated tagging`}
							/>
						) : (
							<Fragment>
								<Typography variant="subtitle2">{`${t`Condition sets for`} ${
									surveyInRule.name
								}`}</Typography>
								<Typography
									variant="body2"
									color="textSecondary"
									gutterBottom
								>
									{t`Conditions will be checked on page load or when the URL changes. Only the first condition set where all conditions evaluate to true will be triggered.`}
								</Typography>

								<div>
									{get(selectedRule, 'add', []).map((conditionSet, conditionIndex) => {
										return (
											<EditorOptionExpansionPanel
												title={`${t`Condition set`} ${conditionIndex + 1}`}
												subtitle={`${t`Tags`} ${conditionSet.tags.join(', ')}`}
												summaryActions={
													<Fragment>
														<ActionIconButton
															action="remove_list"
															onClick={e => {
																e.stopPropagation();
																removeCondition(conditionIndex);
															}}
														/>
													</Fragment>
												}
												key={selectedRule.id + conditionIndex}
												disablePadding
												expanded={
													!Boolean(
														sidebarState.collapsed[`${selectedRule.id + conditionIndex}`]
													)
												}
												onChange={(e, expanded) => {
													setSidebarState(draft => {
														if (expanded) {
															delete draft.collapsed[
																`${selectedRule.id + conditionIndex}`
															];
														} else {
															draft.collapsed[
																`${selectedRule.id + conditionIndex}`
															] = true;
														}
													});
												}}
											>
												<EditorOptionList
													startDivider
													options={[
														{
															custom: (
																<Fragment>
																	<TagsAutoComplete
																		label={t`Tags to add`}
																		fullWidth
																		selectedItem={conditionSet.tags.map(tag => ({
																			label: tag,
																		}))}
																		onChange={tags =>
																			setOption(
																				'tags',
																				tags.map(tag => tag.label),
																				conditionIndex
																			)
																		}
																		className={`${classes.inputInText} ${classes.datePickerInput}`}
																	/>
																</Fragment>
															),
															divider: true,
														},
														{
															primary: t`Add tags to`,
															textGridItemProps: {
																xs: false,
															},
															actionGridItemProps: {
																xs: true,
															},
															action: (
																<EditorListRadioGroup
																	size="small"
																	grow
																	value={conditionSet.retroactive ? 'retroactive' : 'new'}
																	onChange={(e, value) =>
																		setOption(
																			'retroactive',
																			value === 'retroactive' ? true : false,
																			conditionIndex
																		)
																	}
																	options={[
																		{
																			label: t`New feedback only`,
																			value: 'new',
																			//  icon: <DeploymentIcon value="passive" />
																		},
																		{
																			label: t`Existing and new feedback`,
																			value: 'retroactive',
																			// icon: <DeploymentIcon value="proactive" />
																		},
																	]}
																/>
															),
															divider: true,
														},
														{
															wrap: true,
															custom: (
																<Box width="100%">
																	<Typography
																		variant="body2"
																		display="block"
																	>{t`When should tags be added?`}</Typography>
																	{get(conditionSet, 'conditions', []).map(
																		(feedbackCondition, feedbackConditionIndex) => {
																			const field = datasource.api.getField(
																				feedbackCondition.datafield_id
																			);

																			return (
																				<Grid container>
																					<Grid
																						item
																						xs
																					>
																						<Grid
																							container
																							key={feedbackConditionIndex}
																							alignItems="center"
																							spacing={1}
																						>
																							<Grid
																								item
																								xs={4}
																							>
																								<DataFieldSelect
																									fullWidth
																									sourceId={selectedRule.survey_id}
																									value={
																										feedbackCondition.datafield_id ?? ''
																									}
																									onChange={e => {
																										setOption(
																											`conditions[${feedbackConditionIndex}].datafield_id`,
																											e.target.value,
																											conditionIndex
																										);
																										setOption(
																											`conditions[${feedbackConditionIndex}].value`,
																											[],
																											conditionIndex
																										);
																									}}
																									renderValueTextOnly
																								/>
																							</Grid>

																							<Grid
																								item
																								xs={4}
																							>
																								<TextField
																									select
																									fullWidth
																									value={feedbackCondition.operator}
																									onChange={e =>
																										setOption(
																											`conditions[${feedbackConditionIndex}].operator`,
																											e.target.value,
																											conditionIndex
																										)
																									}
																								>
																									{operators(field.var_type).map(
																										operator => (
																											<MenuItem value={operator}>
																												{t('operator.' + operator)}
																											</MenuItem>
																										)
																									)}
																								</TextField>
																							</Grid>

																							<Grid
																								item
																								xs={4}
																							>
																								<PossibleValues
																									fullWidth
																									datafieldId={
																										feedbackCondition.datafield_id
																									}
																									//	selectedItem={feedbackCondition.value}
																									value={feedbackCondition.value}
																									onChange={valueArr =>
																										setOption(
																											`conditions[${feedbackConditionIndex}].value`,
																											valueArr.map(value =>
																												typeof value === 'object'
																													? value.value
																													: value
																											),
																											conditionIndex
																										)
																									}
																								/>
																							</Grid>
																						</Grid>
																					</Grid>
																					<Grid item>
																						<ActionIconButton
																							action="remove_list"
																							tooltip={t`Remove from condition`}
																							size="small"
																							onClick={() =>
																								removeFeedbackCondition(
																									feedbackConditionIndex,
																									conditionIndex
																								)
																							}
																						/>
																					</Grid>
																				</Grid>
																			);
																		}
																	)}

																	<ActionButton
																		action="add_list"
																		onClick={() => addFeedbackCondition(conditionIndex)}
																		label={t`And`}
																	/>
																</Box>
															),
														},
													]}
												/>
											</EditorOptionExpansionPanel>
										);
									})}
								</div>

								<Box mt={1}>
									<ActionButton
										action="add_list"
										onClick={() => addCondition()}
										label={t`Add condition`}
									/>
								</Box>
							</Fragment>
						)}
					</div>
				)}
			</div>
		</Fragment>
	);
}
