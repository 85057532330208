import React, { useContext, useEffect } from 'react';

import { CustomerDetail } from '../../components/Customer';
import { EFM } from '../../api/EFM';
import { AppContext } from '../../components/AppContext';
import { AjaxContent, useEFM } from '../../components/Ajax';
import produce from 'immer';
import { Loader } from '../../components/Layout';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

function CustomerDetailPage() {
	const { customerId } = useParams();
	return <CustomerDetail customerId={customerId} />;
}

// class CustomerDetailPage extends Component {

// 	componentDidMount() {
// 		const { app } = this.props;
// 		app.layout.reduce({type:'TITLE', payload:'Customer details'});
// 	}
// 	render() {
// 		return (
// 			<AjaxContent
// 				url={`/dashboard/customers/detail-json/${this.props.match.params.customerId}`}
// 			>
// 				{
// 					({data:{customer={}, feedback}}) =>
// 						Object.keys(customer).length ? <CustomerDetail customer={customer} feedback={feedback} /> : null
// 				}
// 			</AjaxContent>
// 		)

// 	}
// }

export default CustomerDetailPage;
