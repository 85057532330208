import React from 'react';

import Search from '@material-ui/icons/Search';

import { useTranslation } from 'react-i18next';

import { useListFilterContext, FilterGroup } from '@/components/FilteredLists';
import { TextField, InputAdornment } from '@/components/Layout';

export default function SurveyListFilters({ deployments = [] }) {
	const { setSingleFilter, setMultiFilter, filters } = useListFilterContext();

	const { t } = useTranslation();

	return (
		<>
			<div>
				<TextField
					variant="filled"
					fullWidth
					placeholder={t('Search')}
					label={t('Search')}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search />
							</InputAdornment>
						),
					}}
					onChange={e => setSingleFilter('search', e.target.value)}
					value={filters.search ?? ''}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Type')}
					filterKey={'survey_format'}
					filters={[
						{ label: t('Standard'), value: 'form' },
						{ label: t('Conversational'), value: 'conversational' },
					].map(filter => {
						if (filter.value === filters.survey_format) {
							filter.selected = true;
						}
						filter.key = 'survey_format' + filter.value;
						return filter;
					})}
					onChange={({ key, value }) => setSingleFilter(key, value)}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Channel')}
					filterKey={'channel'}
					filters={[
						{ label: t('Web'), value: 'web' },
						{ label: t('Email'), value: 'email' },
						{ label: t('In-app | native'), value: 'sdk_native' },
						{ label: t('In-app | webview'), value: 'sdk_webview' },
					].map(filter => {
						if ((filters.channel || []).indexOf(filter.value) > -1) {
							filter.selected = true;
						}
						filter.key = 'channel' + filter.value;
						return filter;
					})}
					multiple
					onChange={({ key, value }) => setMultiFilter(key, value)}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Display type')}
					filterKey={'display_type'}
					filters={[
						{ label: t('Modal'), value: 'modal' },
						{ label: t('Slide-in'), value: 'slide' },
						{ label: t('Embedded'), value: 'embed' },
					].map(filter => {
						if ((filters.display_type || []).indexOf(filter.value) > -1) {
							filter.selected = true;
						}
						filter.key = 'display_type' + filter.value;
						return filter;
					})}
					multiple
					onChange={({ key, value }) => setMultiFilter(key, value)}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('data_collection-survey_list-in_deployments_filter-label')}
					filterKey={'in_deployments'}
					filters={deployments.map(deployment => ({
						label: deployment.name,
						value: deployment.id,
						selected: filters.in_deployments?.includes(deployment.id),
					}))}
					multiple
					onChange={({ key, value }) => setMultiFilter(key, value)}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Editable')}
					filterKey={'editable'}
					filters={[
						{ label: t('Is editable'), value: 'yes' },
						{ label: t('Is locked'), value: 'no' },
					].map(filter => {
						if (filter.value === filters.editable) {
							filter.selected = true;
						}
						filter.key = 'editable' + filter.value;
						return filter;
					})}
					onChange={({ key, value }) => setSingleFilter(key, value)}
				/>
			</div>
		</>
	);
}
