import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { DataFieldIcon, useDataSourceContext } from '../DataSources';

const useStyles = makeStyles(theme => ({
	avatar: {
		backgroundColor: props => props.color,
	},
	icon: {
		color: props => {
			try {
				return theme.palette.getContrastText(props.color);
			} catch (e) {
				return '';
			}
		},
	},
}));

function ActiveSeriesChip({ serie, ...props }) {
	const classes = useStyles(serie);
	const { datasource } = useDataSourceContext();
	const field = datasource.api.getField(serie.y);
	return (
		<Chip
			avatar={
				<Avatar className={classes.avatar}>
					<DataFieldIcon
						{...field}
						className={classes.icon}
					/>
				</Avatar>
			}
			label={serie.name}
			{...props}
		/>
	);
}

export default ActiveSeriesChip;
