import React from 'react';

import { useTranslation, Trans } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';
import { Typography, Link } from '@/components/Layout';
import { UpgradeButton } from '@/components/Billing';

import Explainer from './Explainer';

const infoLinkUrls = {
	en: {
		web: 'https://mopinion.com/product/mopinion-for-websites/',
		email: 'https://mopinion.com/product/mopinion-for-email/',
		sdk: 'https://mopinion.com/product/mopinion-for-apps/',
	},
	nl: {
		web: 'https://mopinion.com/nl/product/mopinion-voor-websites/',
		email: 'https://mopinion.com/nl/product/mopinion-voor-email/',
		sdk: 'https://mopinion.com/nl/product/mopinion-voor-apps/',
	},
};

export default function AddToPackageExplainer({ product, ...props }) {
	const { t } = useTranslation();
	const { app } = useAppContext();

	const language = Number(app.language?.id) === 1 ? 'nl' : 'en';

	return (
		<Explainer
			title={t(`add_to_package_explainer-title_${product}`)}
			content={
				<Trans
					i18nKey={`add_to_package_explainer-content_${product}`}
					components={{
						p1: <Typography mb={2} />,
						p2: <Typography mb={2} />,
						p3: <Typography mb={2} />,
						moreInfoLink: (
							<Link
								color="inherit"
								component="a"
								target="_blank"
								href={infoLinkUrls[language]?.[product]}
								mb={3}
								display="block"
								alignLink="left"
							/>
						),
						upgradeButton: (
							<UpgradeButton
								color="inherit"
								data-track-event={`upgrade_from_explainer_${product}`}
								upgradeSubject={t(`add_to_package_explainer-subject_${product}`)}
							/>
						),
					}}
				/>
			}
			{...props}
		>
			{t(`add_to_package_explainer-label_${product}`)}
		</Explainer>
	);
}
