import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { SimpleDialog, Typography } from '@/components/Layout';

import useSaveOptInOut from './useSaveOptInOut';

export default function OptInOutDialog({
	open,
	smartRecapsProjectActive,
	onClose = () => {},
	loadOptInData = () => {},
}) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const optInOutTranslationKey = smartRecapsProjectActive ? 'out' : 'in';

	const { postForm, loading } = useSaveOptInOut({
		smartRecapsProjectActive,
		onSuccess: response => {
			if (response.code === 200) {
				enqueueSnackbar(
					t(
						`reporting-feedback_inbox-smart_recaps-optinout_dialog-success_message-${optInOutTranslationKey}`
					)
				);
			} else {
				enqueueSnackbar(
					t`reporting-feedback_inbox-smart_recaps-optinout_dialog-success_message-error`
				);
			}
			loadOptInData();
			onClose();
		},
	});

	return (
		<SimpleDialog
			open={open}
			title={t(
				`reporting-feedback_inbox-smart_recaps-opt_${optInOutTranslationKey}_dialog-title`
			)}
			submit={t(
				`reporting-feedback_inbox-smart_recaps-opt_${optInOutTranslationKey}_dialog-submit`
			)}
			cancel={t(
				`reporting-feedback_inbox-smart_recaps-opt_${optInOutTranslationKey}_dialog-cancel`
			)}
			onClose={onClose}
			loading={loading}
			onSubmit={() => postForm()}
			dataTrackEvent={`smartrecaps_opt_in_out_dialog_opt_${optInOutTranslationKey}`}
		>
			<Typography>
				{t(
					`reporting-feedback_inbox-smart_recaps-opt_${optInOutTranslationKey}_dialog-content`
				)}
			</Typography>
		</SimpleDialog>
	);
}
