import React from 'react';
import { makeStyles } from '@/styles';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MenuIcon from '@material-ui/icons/Menu';
import RefreshIcon from '@material-ui/icons/Refresh';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { Grid, Paper, ActionButton, Typography } from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	alignCenter: {
		textAlign: 'center',
	},
	alignRight: {
		textAlign: 'right',
	},
	iconFont: {
		fontSize: '60px',
	},
	dummyHeader: {
		margin: 0,
		padding: theme.spacing(1),
		background: theme.palette.grey[300],
	},
	dummyButton: {
		padding: props => (props.persistent ? 0 : 5),
		marginRight: 5,
		minWidth: 0,
	},
	dummyUrl: {
		background: theme.palette.grey[400],
		color: theme.palette.grey[800],
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
}));

export default function DummyGridHeader({ persistent }) {
	const classes = useStyles({ persistent });

	return (
		<Grid
			container
			alignItems="center"
			className={classes.dummyHeader}
		>
			<Grid
				item
				xs={1}
				className={`${classes.center} ${classes.iconFont}`}
			>
				<MoreHorizIcon
					color="disabled"
					fontSize="inherit"
					tabIndex="0"
				/>
			</Grid>
			<Grid
				item
				xs={2}
				className={classes.alignCenter}
			>
				<ActionButton
					variant="outlined"
					size="small"
					className={classes.dummyButton}
				>
					<ChevronLeftIcon />
				</ActionButton>
				<ActionButton
					variant="outlined"
					size="small"
					className={classes.dummyButton}
				>
					<ChevronRightIcon />
				</ActionButton>
			</Grid>
			<Grid
				item
				xs={7}
			>
				<Paper
					className={classes.dummyUrl}
					elevation={0}
					tabIndex="0"
				>
					<Grid container>
						<Grid
							item
							xs
						>
							<Typography variant="subtitle1">{'yourwebsite.com/example'}</Typography>
						</Grid>
						<Grid
							item
							xs={1}
							className={classes.alignRight}
						>
							<RefreshIcon />
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			<Grid
				item
				xs={2}
				className={classes.alignCenter}
			>
				<MenuIcon
					color="disabled"
					fontSize="large"
					tabIndex="0"
				/>
			</Grid>
		</Grid>
	);
}
