import React from 'react';

import useHasProjectPermission from './useHasProjectPermission';

export default function HasProjectPermission({
	children,
	projectId,
	projectUuid,
	project,
}) {
	const hasPermission = useHasProjectPermission({ projectId, projectUuid, project });
	return children(hasPermission);
}
