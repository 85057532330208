import React, { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '../AppContext';
import { MainMenuListItem, ActionIconButton } from '../Layout';

export default function WhatsNew({ mainMenuOpen, ...props }) {
	const { app } = useAppContext();
	const { t } = useTranslation();

	const didUpdateParams = useRef(false);

	useEffect(() => {
		if (didUpdateParams.current) {
			return;
		}

		const interval = setInterval(() => {
			if (window.Beamer) {
				try {
					didUpdateParams.current = true;
					window.Beamer.update({
						user_firstname: app.users.current.firstname,
						user_lastname: app.users.current.lastname,
					});
					clearInterval(interval);
				} catch (e) {}
			}
		}, 500);

		return () => clearInterval(interval);
	}, [app.users?.current?.firstname, app.users?.current?.firstname]);

	return mainMenuOpen ? (
		<MainMenuListItem
			action="whats_new"
			id="beamerButton"
			primary={!app.session.onboarding_trial ? t`app_general-beamer-tooltip` : ''}
			data-track-event="help_message_whats_new"
			disabled={app.session.onboarding_trial}
			{...props}
		/>
	) : (
		<ActionIconButton
			action="fire"
			id="beamerButton"
			tooltip={!app.session.onboarding_trial ? t`app_general-beamer-tooltip` : ''}
			data-track-event="help_message_whats_new"
			disabled={app.session.onboarding_trial}
			{...props}
		/>
	);
}
