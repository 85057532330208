import React, { useState } from 'react';

import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import EmailIcon from '@material-ui/icons/MailOutline';
import AddIcon from '@material-ui/icons/Add';
import ShareIcon from '@material-ui/icons/Share';
import CopyIcon from '@material-ui/icons/FileCopy';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { copyToClipboard } from '@/utils';
import { useAppContext } from '@/components/AppContext';
import {
	WEBHOOK_TYPES,
	useWebHookContext,
	WebHookAddItDialog,
} from '@/components/WebHook';
import { Menu, MenuItem, NestedMenuItem } from '@/components/Layout';
import {
	permissionSubjects,
	permissionModules,
	useHasPermission,
} from '@/components/Permission';

function MenuItems({
	hooks,
	allowShareEmail,
	setShareDialogOpen,
	setWebHookDialogOpen,
	closeMenu,
	setAddOpen,
	feedbackId,
}) {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const hasInboxEditPermission = useHasPermission();
	const hasWebhookEditPermission = useHasPermission({
		module: permissionModules.integrations,
		subject: permissionSubjects.webhooks_apps,
	});

	return (
		<>
			{feedbackId && (
				<MenuItem
					onClick={e => {
						e.stopPropagation();
						const shareURL = `${document.location.origin}/r/reporting/inbox/detail/${feedbackId}?project_id=${app.projects.current.id}`;
						copyToClipboard(shareURL).then(() => {
							enqueueSnackbar(t`reporting-share_menu-url_copied`);
						});
						closeMenu();
					}}
				>
					<ListItemIcon>
						<CopyIcon />
					</ListItemIcon>
					<ListItemText>{t`reporting-share_menu-copy_url`}</ListItemText>
				</MenuItem>
			)}
			{allowShareEmail && (
				<MenuItem
					disabled={!hasInboxEditPermission}
					onClick={e => {
						e.stopPropagation();
						setShareDialogOpen();
					}}
				>
					<ListItemIcon>
						<EmailIcon />
					</ListItemIcon>
					<ListItemText>{t('Share via email')}</ListItemText>
				</MenuItem>
			)}
			{allowShareEmail && hooks.length > 0 && <Divider />}
			{hooks.length > 0 && (
				<>
					{hooks.map((hook, index) => {
						const webhookType =
							WEBHOOK_TYPES.find(type => type.value === hook.webhook_type) ?? {};

						return (
							<MenuItem
								key={index}
								onClick={e => {
									e.stopPropagation();
									setWebHookDialogOpen(hook.webhook_id);
								}}
							>
								<ListItemIcon>
									{webhookType.icon ? (
										<img
											height="22"
											src={webhookType.icon}
										/>
									) : (
										<img
											height="22"
											src="/assets/img/r/webhook-icon.svg"
										/>
									)}
								</ListItemIcon>
								<ListItemText
									primary={hook.name}
									secondary={`${t`Share via`} ${webhookType.label ?? ''}`}
								/>
							</MenuItem>
						);
					})}
				</>
			)}
			<MenuItem
				disabled={!hasWebhookEditPermission}
				onClick={e => {
					e.stopPropagation();
					setAddOpen(true);
				}}
			>
				<ListItemIcon>
					<AddIcon />
				</ListItemIcon>
				<ListItemText
					primary={t`Add integration`}
					secondary={t`Add a new integration`}
				/>
			</MenuItem>
		</>
	);
}

export default function ShareMenu({
	nestedMenu = false,
	mouseY = null,
	mouseX = null,
	shareMenuAnchor,
	allowShareEmail = true,
	setShareDialogOpen = () => {},
	setWebHookDialogOpen = () => {},
	closeMenu = () => {},
	feedbackId,
}) {
	const { t } = useTranslation();
	const {
		state: { hooks },
	} = useWebHookContext();

	//In nested menus, opening a dialog without closing the menu gives focus issues.
	//Use fn newWebHook instead so we can open a dialog, but close the menu.

	const [addOpen, setAddOpen] = useState(false);
	const userWebHooks = (Array.isArray(hooks) ? hooks : []).filter(
		hook => hook.event_type === 'user_init'
	);

	return (
		<>
			{nestedMenu ? (
				<NestedMenuItem
					leftIcon={<ShareIcon />}
					forceOpen={addOpen}
					parentOpen={Boolean(shareMenuAnchor) || Boolean(mouseY && mouseY !== null)}
					primary={t`Share`}
				>
					<MenuItems
						allowShareEmail={allowShareEmail}
						feedbackId={feedbackId}
						hooks={userWebHooks}
						setShareDialogOpen={setShareDialogOpen}
						setWebHookDialogOpen={setWebHookDialogOpen}
						closeMenu={() => closeMenu()}
						setAddOpen={e => setAddOpen(e)}
					/>
				</NestedMenuItem>
			) : (
				<>
					<Menu
						anchorEl={shareMenuAnchor}
						open={Boolean(shareMenuAnchor)}
						onClose={() => closeMenu()}
						anchorPosition={
							mouseY !== null && mouseX !== null
								? { top: mouseY, left: mouseX }
								: undefined
						}
					>
						<MenuItems
							allowShareEmail={allowShareEmail}
							feedbackId={feedbackId}
							hooks={userWebHooks}
							setShareDialogOpen={setShareDialogOpen}
							setWebHookDialogOpen={setWebHookDialogOpen}
							closeMenu={closeMenu}
							setAddOpen={e => setAddOpen(e)}
						/>
					</Menu>
				</>
			)}
			<WebHookAddItDialog
				open={addOpen}
				onClose={() => setAddOpen(false)}
				preSelected="user_init"
			/>
		</>
	);
}
