import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Tooltip from '@material-ui/core/Tooltip';

import { useTranslation, Trans } from 'react-i18next';

import {
	ActionButton,
	Typography,
	RenderConditional,
	Link,
	ContactDialog,
} from '../Layout';
import Amount from './Amount';

const useStyles = makeStyles(theme => ({
	skeleton: {
		display: 'inline-block',
	},
}));

export default function BillingToggle({
	billing = '',
	updateBilling = () => {},
	saveWithYearly,
	currency = 'EUR',
	loading = false,
	disabled = false,
	showLabel = false,
	...props
}) {
	const [contact, setContact] = useState(false);
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<>
			<Box {...props}>
				<Box mb={1}>
					{showLabel && (
						<Typography
							variant="subtitle"
							gutterBottom
						>
							{t`billing_toggle-bill_per`}
						</Typography>
					)}
					<RenderConditional
						component={Tooltip}
						condition={disabled}
						interactive
						title={
							<Trans
								i18nKey="billing_toggle-cannot_change_billing_tooltip"
								components={{
									contactLink: (
										<Link
											variant="inherit"
											fontWeight="bold"
											color="inherit"
											onClick={() => setContact(true)}
										/>
									),
								}}
							/>
						}
					>
						<ButtonGroup
							disableElevation
							disabled={disabled}
						>
							<ActionButton
								color="primary"
								variant={billing === 'annual' ? 'outlined' : 'contained'}
								onClick={() => updateBilling('monthly')}
							>
								{t`billing_toggle-monthly_label`}
							</ActionButton>
							<ActionButton
								color="primary"
								variant={billing === 'annual' ? 'contained' : 'outlined'}
								onClick={() => updateBilling('annual')}
							>
								{t`billing_toggle-yearly_label`}
							</ActionButton>
						</ButtonGroup>
					</RenderConditional>
				</Box>
				{billing === 'monthly' && saveWithYearly !== 0 && (
					<Typography
						variant="body2"
						color="textSecondary"
					>
						<Trans
							i18nKey="billing_toggle-save_with_per_year_label"
							components={{
								strong: <strong />,
								amount: loading ? (
									<Skeleton
										width={32}
										className={classes.skeleton}
									/>
								) : (
									<Amount
										amount={saveWithYearly}
										currency={currency}
									/>
								),
							}}
						/>
					</Typography>
				)}
				{billing === 'annual' && (
					<Typography
						variant="body2"
						color="textSecondary"
					>
						<Trans
							i18nKey="billing_toggle-saving_yearly_billing"
							components={{
								strong: <strong />,
								amount: (
									<Amount
										amount={saveWithYearly}
										currency={currency}
									/>
								),
							}}
						/>
					</Typography>
				)}
			</Box>

			<ContactDialog
				subject={t`billing_toggle-contact_email_subject`}
				open={contact}
				onClose={() => setContact(false)}
				mailTo="support@mopinion.com"
				title={t`billing_toggle-contact_email_title`}
				dataTrackEvent="account_billing_contact_support"
			/>
		</>
	);
}
