import React from 'react';

import { useAppContext } from '@/components/AppContext';
import { useAjaxForm } from '@/components/Ajax';

import { prepareSaveChart } from './chartUtils';

export default function useSaveInsightsChart({
	chart = [],
	series = [],
	chartType,
	dashboardId,
	onSuccess = () => {},
}) {
	const { app } = useAppContext();

	const preparedChart = prepareSaveChart({
		...chart,
		series: [...series],
		type: chartType,
	});

	const saveChart = useAjaxForm({
		type: 'mjolnir',
		url: `chart/${app.domain}/${app.projects.current.id}`,
		data: preparedChart,
		onSuccess: response => {
			if (response.id) {
				addToDashboard.postForm({
					post: { chartId: response.id },
					extra: { chartId: response.id },
				});
			}
		},
	});

	const addToDashboard = useAjaxForm({
		url: '/dashboard/ajax/add-chart',
		data: {
			dashboardId: dashboardId,
		},
		onSuccess: (response, extra) => {
			onSuccess({ ...response, ...extra });
		},
	});

	return saveChart;
}
