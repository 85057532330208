import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { SimpleDialog, Typography } from '../Layout';
import UpgradeDialog from './UpgradeDialog';

export default function ProductNotInPlanDialog({ product, onClose, backTo, ...props }) {
	const { t } = useTranslation();
	const history = useHistory();
	const [upgradeOpen, setUpgradeOpen] = useState();
	return (
		<>
			<SimpleDialog
				title={t`app_general-product_not_in_plan_dialog-title`}
				onClose={() => {
					if (backTo) {
						history.push(backTo);
						return;
					}
					history.goBack();
				}}
				cancel={t(`app_general-product_not_in_plan_dialog-back`)}
				submit={t(`app_general-product_not_in_plan_dialog-add_to_plan`)}
				onSubmit={() => setUpgradeOpen(true)}
				dataTrackEvent="upgrade_from_product_not_in_plan_dialog"
				blurBackdrop
				{...props}
			>
				<Typography>
					{t(`app_general-product_not_in_plan_dialog-text-${product}`)}
				</Typography>
			</SimpleDialog>

			<UpgradeDialog
				open={upgradeOpen}
				onClose={() => setUpgradeOpen(false)}
			/>
		</>
	);
}
