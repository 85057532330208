import React from 'react';

import { isToday } from '@/utils/date';
import useEFM from './useEFM';

const getParams = ({ surveyKey, fromDate, toDate, url, type }) => {
	const params = {
		created_time: `${fromDate}|${toDate}`,
		...(url && {
			url,
		}),
		...(isToday(new Date(fromDate)) && {
			refreshCache: true,
		}),
	};

	switch (type) {
		case 'sessions':
			return {
				path: `${surveyKey}/count/distinct/session`,
				params,
			};
		case 'completes':
			return {
				path: `${surveyKey}/count`,
				params,
				nested: {
					eventName: 'Feedback sent',
				},
			};

		case 'shown':
			return {
				path: `${surveyKey}/count`,
				params,
				nested: {
					eventName: 'Form shown',
				},
			};

		case 'passive':
			return {
				path: `${surveyKey}/count`,
				params,
				nested: {
					eventName: 'Form shown',
					'eventProps.trigger_method': 'passive',
				},
			};

		case 'proactive':
			return {
				path: `${surveyKey}/count`,
				params,
				nested: {
					eventName: 'Form shown',
					'eventProps.trigger_method': 'proactive',
				},
			};

		case 'exit':
			return {
				path: `${surveyKey}/count`,
				params,
				nested: {
					eventName: 'Form shown',
					'eventProps.trigger_method': 'exit',
				},
			};

		default:
			return {};
	}
};

export default function useMetrics({
	surveyKey,
	fromDate,
	toDate,
	url,
	type,
	wait,
	poll,
}) {
	const responseParser = response => response.result ?? 0;

	const request = useEFM(
		'/api/metrics',
		{
			method: 'get',
			...getParams({
				surveyKey,
				fromDate,
				toDate,
				url,
				type,
			}),
		},
		wait,
		poll,
		responseParser,
		{ disableCache: true }
	);

	return request;
}
