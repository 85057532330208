import React, { Fragment, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

import { actionDue, isExpired } from './actionUtils';
import ActionSummary from './ActionSummary';
import { UserAvatar, AvatarStack, FormattedDate } from '../Layout';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeadlineIcon from '@material-ui/icons/Alarm';

const styles = theme => ({
	arrow: {
		margin: theme.spacing(0, 1),
	},
	deadlineContent: {
		display: 'flex',
		alignItems: 'center',
		lineHeight: 1.2,
		'& > *': {
			display: 'inline-flex',
			flexDirection: 'column',
		},
		'& strong': {
			fontSize: theme.typography.pxToRem(10),
		},
	},
	deadlineIcon: {
		verticalAlign: 'middle',
		marginRight: theme.spacing(0.5),
	},
	deadlineText: {
		verticalAlign: 'middle',
	},
});

function ActionProgressCard({ actions, isElevated, classes, ...props }) {
	const { t } = useTranslation();

	const cardProps = {
		...(!isElevated && {
			elevation: 0,
		}),
	};
	return (
		<Card {...cardProps}>
			<CardHeader
				titleTypographyProps={{ variant: 'subtitle2' }}
				title={t('Actions')}
			/>
			<List>
				{actions.map(action => {
					return (
						<Tooltip title={t`View action details`}>
							<ListItem
								key={action.actions_track_status_id}
								button
								component={Link}
								to={`/actions/inbox/detail/${action.id}`}
							>
								<ActionSummary action={action} />
							</ListItem>
						</Tooltip>
					);
				})}
				{actions.length === 0 && (
					<ListItem>
						<ListItemText primary={`No actions`} />
					</ListItem>
				)}
			</List>
		</Card>
	);
}

export default withStyles(styles)(ActionProgressCard);
