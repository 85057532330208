import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { UserAvatar } from '../Users';
import { ButtonWithLoader } from '../Layout';
import { useTranslation } from 'react-i18next';

export default function AddCommentBar({
	value = '',
	onChange = () => {},
	loading,
	onSave,
	leftBottomGridContent,
	dataTrackEvent,
	actionBoxProps = {},
	...props
}) {
	const [focus, setFocus] = useState(false);
	const { t } = useTranslation();
	return (
		<Box
			mt={2}
			{...props}
		>
			<Grid
				container
				alignItems="center"
				spacing={2}
			>
				<Grid item>
					<UserAvatar current />
				</Grid>
				<Grid
					item
					xs
				>
					<TextField
						multiline
						variant="standard"
						label={t`Add a comment`}
						placeholder={t`Start typing...`}
						value={value}
						onChange={onChange}
						fullWidth
						onFocus={e => setFocus(true)}
						onBlur={e => setFocus(false)}
						inputProps={{ 'data-test-element': 'feedbackNoteTextarea' }}
					/>
				</Grid>
			</Grid>

			<Box pt={1}>
				<Grid container>
					<Grid
						item
						xs
					>
						{leftBottomGridContent}
					</Grid>
					{typeof onSave === 'function' && value.length > 0 && (
						<Grid item>
							<ButtonWithLoader
								loading={loading}
								variant="contained"
								color="primary"
								onClick={onSave}
								data-test-element="saveNote"
								dataTrackEvent={dataTrackEvent}
							>
								{t`Save`}
							</ButtonWithLoader>
						</Grid>
					)}
				</Grid>
			</Box>
		</Box>
	);
}
