import React, { useState, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { sortByNum, sortByAlphabet } from '@/utils';
import { makeStyles } from '@/styles';

import { EmptyStateHandleHasFeedback, PageHeader } from '@/components/App';
import { Grid, constants } from '@/components/Layout';

import {
	ListWithSidebar,
	ListFilterProvider,
	ListControls,
	useFilteredList,
} from '@/components/FilteredLists';

import { useDataSourceContext } from '@/components/DataSources';
import { useAppContext } from '@/components/AppContext';
import { DynamicDashboardsExplainer } from '@/components/Explainer';
import { UpgradeOverlay } from '@/components/Billing';

import DirectInsightsFilter from './DirectInsightsFilter';
import DirectInsightsOverviewRow from './DirectInsightsOverviewRow';

const useStyles = makeStyles(theme => ({
	paper: {
		padding: theme.spacing(2),
	},
	emptyState: {
		whiteSpace: 'pre-line',
	},
	wrap: {
		flexGrow: 1,
		height: '100%',
		maxWidth: '100%',
	},
}));

export default function DirectInsightsOverview() {
	const { t } = useTranslation();
	const classes = useStyles();
	const {
		app: { api },
	} = useAppContext();
	const { datasource } = useDataSourceContext();

	const [filtersOpen, setFiltersOpen] = useState(
		window.innerWidth > constants.filterDrawerBreakpoint
	);

	const filterState = useFilteredList({
		filters: {},
		other: {},
	});
	const { filters, sort, order, other, hasFilters } = filterState;

	const filteredSurveys = useMemo(() => {
		const datasources = [...datasource.forms.asArray, ...datasource.datasets.asArray];

		const surveyConfig = datasources.filter(item => {
			const filterSearch = filters.search ? filters.search.toLowerCase() : false;

			const searchFilterPassed = filterSearch
				? (item?.name.toLowerCase() ?? '').includes(filterSearch)
				: true;

			const surveyFormatPassed =
				filters.survey_format && item.survey_format
					? filters.survey_format === item.survey_format
					: true;

			const channelFilterPassed = filters.channel
				? filters.channel.includes(item.campaign)
				: true;

			return searchFilterPassed && surveyFormatPassed && channelFilterPassed;
		});

		return sort === 'name'
			? sortByAlphabet(surveyConfig, 'name', order === 'desc')
			: sortByNum(surveyConfig, 'id', order === 'desc');
	}, [filters, sort, order, datasource.forms.asArray, datasource.datasets.asArray]);

	return (
		<>
			<PageHeader title={t`reporting-insights-overview-title`} />
			<ListFilterProvider
				{...filterState}
				singleFilters={['survey_format', 'search']}
				multiFilters={['channel', 'display_type']}
				filterLabels={{
					survey_format: {
						label: t('Type'),
						form: t('Standard'),
						conversational: t('Conversational'),
					},
					channel: {
						label: t('Channel'),
						web: t('Web'),
						email: t('Email'),
						sdk: t('In-app'),
					},
				}}
			>
				<ListWithSidebar
					drawerContent={<DirectInsightsFilter />}
					open={filtersOpen}
					onClose={() => setFiltersOpen(false)}
				>
					<ListControls
						sortOptions={[
							{
								label: t('reporting-insights-sort-first_last'),
								value: 'id-asc',
							},
							{
								label: t('reporting-insights-sort-last_first'),
								value: 'id-desc',
							},
							{
								label: t('reporting-insights-sort-a_z'),
								value: 'name-asc',
							},
							{
								label: t('reporting-insights-sort-z_a'),
								value: 'name-desc',
							},
						]}
						toggleFilterDrawer={() => setFiltersOpen(prev => !prev)}
						explainer={<DynamicDashboardsExplainer />}
					/>
					<>
						{filteredSurveys.length === 0 && (
							<EmptyStateHandleHasFeedback
								className={classes.emptyState}
								image={<img src={`/assets/img/r/emptystate/dashboard_no_charts.gif`} />}
								primaryNoSurvey={t`reporting-insights-emptystate_default-title`}
								secondaryNoSurvey={t`reporting-insights-emptystate_default-text`}
								primary={
									hasFilters
										? t`reporting-insights-emptystate_default-filter-title`
										: t`reporting-insights-emptystate_default-title`
								}
								secondary={
									hasFilters
										? t`reporting-insights-emptystate_default-filter-text`
										: t`reporting-insights-emptystate_default-text`
								}
							/>
						)}
						{filteredSurveys.length > 0 && (
							<Grid
								container
								direction="column"
								spacing={2}
							>
								{filteredSurveys.map((datasource, index) => {
									const productInPackage = api.packageProductAllowed(datasource.campaign);
									const daysLeftInProductTrial = api.packageProductTrialDaysLeft(
										datasource.campaign
									);
									return (
										<Grid
											item
											key={index}
											xs={12}
										>
											<UpgradeOverlay
												message={t(
													`reporting-insights-list-product_not_allowed-${datasource.campaign}`
												)}
												needsUpgrade={!productInPackage}
												product={datasource.campaign}
												inline
												backgroundContrast
											>
												<DirectInsightsOverviewRow
													datasource={datasource}
													daysLeftInProductTrial={daysLeftInProductTrial}
												/>
											</UpgradeOverlay>
										</Grid>
									);
								})}
							</Grid>
						)}
					</>
				</ListWithSidebar>
			</ListFilterProvider>
		</>
	);
}
