import React from 'react';

import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';

export default React.forwardRef(function ExpansionPanel(props, ref) {
	return (
		<MuiExpansionPanel
			{...props}
			ref={ref}
		/>
	);
});
