import React from 'react';
import Avatar from '@material-ui/core/Avatar';

export default function FileTypeAvatar({ type = '', ...props }) {
	const img =
		['xls', 'xlsx'].indexOf(type) > -1
			? '/assets/img/filetypes/xls.svg'
			: '/assets/img/filetypes/csv.svg';
	return (
		<Avatar
			src={img}
			{...props}
		/>
	);
}
