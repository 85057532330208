import { useState, useEffect, useRef } from 'react';

export default function useIsInViewport(element, rootMargin = '0px') {
	// State and setter for storing whether element is visible
	const [isIntersecting, setIntersecting] = useState(false);
	const [didIntersect, setDidIntersect] = useState(false);

	useEffect(() => {
		if (isIntersecting) {
			setDidIntersect(true);
		}
	}, [isIntersecting]);
	const didSetObserver = useRef(false);
	useEffect(() => {
		if (!element || didSetObserver.current) return;

		if (window.IntersectionObserver) {
			const observer = new IntersectionObserver(
				([entry]) => {
					// Update our state when observer callback fires
					setIntersecting(entry.isIntersecting);
				},
				{
					rootMargin,
				}
			);
			if (element) {
				didSetObserver.current = true;
				observer.observe(element);
			}
			return () => {
				observer.unobserve(element);
			};
		} else {
			//simple fallback for ie11 -> always behave as if element is in viewport
			setIntersecting(true);
		}
	}, [element]); // Empty array ensures that effect is only run on mount and unmount

	return {
		inViewport: isIntersecting,
		wasInViewport: didIntersect,
	};
}
