import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import { makeStyles } from '@/styles';
import { getCookie } from '@/utils';
import { useAppContext } from '@/components/AppContext';
import { useAjaxForm } from '@/components/Ajax';
import { Typography, Grid, Loader } from '@/components/Layout';
import { useTrackingEvents } from '@/components/Tracking';

const useStyles = makeStyles(theme => ({
	marginTop: {
		marginTop: theme.spacing(3),
	},
}));

export default function ValidateSignUp({ initialUser }) {
	const classes = useStyles();
	const { app } = useAppContext();
	const { t } = useTranslation();
	const { track } = useTrackingEvents();
	const { signUpToken } = useParams();
	const history = useHistory();

	useEffect(() => {
		document.title = `${t`login-validate_signup-document_title`} | Mopinion`;
	}, []);

	const { postForm, loading, response } = useAjaxForm({
		url: initialUser ? '/auth/ajax/verify' : '/auth/ajax/verify-extra-user',
		data: {
			signUpToken: signUpToken,
		},
		onSuccess: response => {
			if (response.logged_in) {
				if (initialUser) {
					track('trial_sign_up_validated', {
						track_cookie: getCookie('M_TRACK'),
						lang_cookie: getCookie('MOP_LANGUAGE'),
					});
				}
				app.api.startApp();
			}
		},
	});

	useEffect(() => {
		let timeout;
		if (response.error) {
			timeout = setTimeout(() => {
				history.push('/login');
			}, 5000);
		}

		return () => clearTimeout(timeout);
	}, [response, history]);

	useEffect(() => {
		if (signUpToken) {
			postForm();
		}
	}, [signUpToken]);

	return (
		<Grid
			container
			className={classes.marginTop}
			justify="center"
		>
			{loading && <Loader circular />}
			{!loading && response?.error && (
				<Grid
					item
					lg={4}
					md={6}
					sm={8}
					xs={12}
				>
					<Typography
						variant="h4"
						gutterBottom
					>
						{t`login-validate_signup-signup_error-title`}
					</Typography>
					<Typography
						variant="body1"
						gutterBottom
					>
						{t`login-validate_signup-signup_error-description`}
					</Typography>
				</Grid>
			)}
			{initialUser && !loading && !signUpToken && (
				<Grid
					item
					lg={3}
					md={6}
					sm={8}
					xs={12}
					data-test-element="validate-signup-grid"
				>
					<Typography
						variant="h4"
						gutterBottom
					>
						{t`login-validate_signup-signup_confirm-title`}
					</Typography>

					<Typography variant="body1">
						{t`login-validate_signup-signup_confirm-almost_done_message`}
					</Typography>
					<Typography variant="body1">
						{t`login-validate_signup-signup_confirm-access_link_message`} {'🎉'}
					</Typography>
				</Grid>
			)}
		</Grid>
	);
}
