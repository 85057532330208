import { useRef, useEffect } from 'react';
import { useAppContext } from '@/components/AppContext';

// popupWindow is a helper function to center the popup on a single- and dualmonitor setup
const popupWindow = (url, windowName) => {
	const w = 600;
	const h = 600;
	const y = window.top.outerHeight / 2 + window.top.screenY - h / 2;
	const x = window.top.outerWidth / 2 + window.top.screenX - w / 2;
	return window.open(
		url,
		windowName,
		`popup toolbar=no, location=no, directories=no, status=no, menubar=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
	);
};

const authUrl = (type, domain, project) => {
	switch (type) {
		case 'jira':
			return `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=zoXmKJUTX7DcIgcFx0gpE9yrk2JUvLc8&scope=offline_access%20read%3Ajira-user%20read%3Ame%20write%3Ajira-work%20read%3Ajira-work%20manage%3Ajira-project&redirect_uri=https://mjolnir-api.mopinion.com/jira-auth&state=${btoa(
				`${domain}|${project}`
			)}&response_type=code&prompt=consent`;

		case 'basecamp':
			return 'https://launchpad.37signals.com/authorization/new?type=web_server&client_id=5bb262e57d61ae0f5014ec37ad0cac0fd0dd76f8&redirect_uri=https://mjolnir-api.mopinion.com/basecamp-auth';

		default:
			return null;
	}
};

const OauthPopup = ({ type, token, onTokenReceived, onPopupIsVisible }) => {
	const { app } = useAppContext();
	const popup = useRef(null);

	const openPopup = () => {
		popup.current = popupWindow(
			authUrl(`${type}`, app.domain, app.projects.current.id),
			`${type}-auth`
		);
		onPopupIsVisible(true);
	};

	const closePopup = () => {
		popup.current?.close();
		onPopupIsVisible(false);
	};

	useEffect(() => {
		const handleMessage = event => {
			if (event.origin !== window.location.origin) {
				return;
			}
			if (event.data.type === 'auth') {
				onTokenReceived(event.data.token);
				closePopup();
			}
		};

		window.addEventListener('message', handleMessage);

		return () => {
			window.removeEventListener('message', handleMessage);
		};
	});

	useEffect(() => {
		if (!token) {
			openPopup();
		}

		const interval = setInterval(() => {
			if (!token && popup.current && popup.current.closed) {
				clearInterval(interval);
				closePopup();
			}
		}, 1000);

		return () => {
			clearInterval(interval);
			closePopup();
		};
	}, [token]);

	return null;
};

export default OauthPopup;
