import React, { useState, useEffect, Fragment, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import { useDataSourceContext } from '@/components/DataSources';
import { useImmerReducer } from 'use-immer';

import { useSnackbar } from 'notistack';

import { EFM } from '@/api';
import { triggerDownload } from '@/utils';

import { useEFM } from '@/components/Ajax';
import { useAppContext } from '@/components/AppContext';
import { PageHeader, AppBarLeftContent } from '@/components/App';
import { Tabs, Tab, constants } from '@/components/Layout';

import { ExportContextProvider } from './ExportContext';
import ExportTemplates from './ExportTemplates';
import ScheduledExports from './ScheduledExports';
import RecentExports from './RecentExports';

function reducer(state, action) {
	switch (action.type) {
		case 'init_recent_config':
			state.recent_exports = action.payload;
			state.exports_scheduled = action.payload.filter(exp => exp.status === 'SCHEDULED');
			state.exports_pending = action.payload.filter(exp => exp.status === 'PENDING');
			state.exports_started = action.payload.filter(exp => exp.status === 'STARTED');
			state.exports_finished = action.payload.filter(exp => exp.status === 'FINISHED');
			state.exports_failed = action.payload.filter(exp => exp.status === 'FAIL');

			return;

		case 'init_schedules_config':
			state.export_schedules = action.payload;
			return;

		case 'init_template_config':
			state.export_templates = action.payload;
			return;

		case 'set_tab':
			state.currentTab = action.payload;
			return;

		case 'config_loaded':
			state.initialLoaded = action.payload;
			return;

		case 'set_max_setups':
			state.maxSetupsReached = action.payload;
			return;

		default:
			return;
	}
}

export default function ExportManagement({ ...props }) {
	const { tab = 'recent', downloadUuid = null } = useParams();

	const { t } = useTranslation();
	const history = useHistory();
	const {
		app,
		app: { users },
	} = useAppContext();
	const { datasource } = useDataSourceContext();
	const { enqueueSnackbar } = useSnackbar();

	const [exportTasks, loading, error, loadData] = useEFM('/dashboard/ajax/fetch-exports');
	const [schedules, schedulesLoading, schedulesError, loadSchedulesData] = useEFM(
		'/dashboard/ajax/fetch-export-schedules'
	);
	const [templates, templatesLoading, templatesError, loadTemplatesData] = useEFM(
		'/dashboard/ajax/fetch-export-templates'
	);

	const autoRefresh = useRef(false);

	const [filtersOpen, setFiltersOpen] = useState(
		datasource.forms.asArray.length > 0 &&
			window.innerWidth > constants.filterDrawerBreakpoint
	);

	useEffect(() => {
		setFiltersOpen(
			datasource.forms.asArray.length > 0 &&
				window.innerWidth > constants.filterDrawerBreakpoint
		);
	}, [datasource.forms.asArray.length]);

	const maxSetups = app.package?.export_setups ?? 50;

	const [state, dispatch] = useImmerReducer(reducer, {
		export_schedules: [],
		export_templates: [],
		recent_exports: [],
		exports_scheduled: [],
		exports_pending: [],
		exports_started: [],
		exports_finished: [],
		exports_failed: [],
		maxSetupsReached: false,
		currentTab: 0,
		initialLoaded: false,
	});

	function startExportDownload(downloadUuid) {
		enqueueSnackbar(t`Download will start soon`);
		EFM.post('/reporting/ajax/download-export', { downloadUuid: downloadUuid }).then(
			r => {
				if (r.code === 200) {
					triggerDownload(r.downloadUri);
				}
			}
		);
	}

	useEffect(() => {
		if (tab) {
			dispatch({
				type: 'set_tab',
				payload: tab === 'templates' ? 2 : tab === 'scheduled' ? 1 : 0,
			});
		}
	}, [tab]);

	//Here we keep refreshing for the status of items, but only if we find an item in SCHEDULED, PENDING or STARTED
	useEffect(() => {
		if (
			state.exports_scheduled.length ||
			state.exports_pending.length ||
			state.exports_started.length
		) {
			autoRefresh.current = setInterval(() => {
				if (!loading) {
					loadData();
				}
			}, 5000);
		} else {
			clearInterval(autoRefresh.current);
		}
		return () => clearInterval(autoRefresh.current);
	}, [
		JSON.stringify(state.exports_scheduled),
		JSON.stringify(state.exports_pending),
		JSON.stringify(state.exports_started),
	]);

	useEffect(() => {
		if (exportTasks.code) {
			dispatch({
				type: 'init_recent_config',
				payload: exportTasks?.message?.task_results ?? [],
			});
			dispatch({
				type: 'config_loaded',
				payload: true,
			});
		}
	}, [exportTasks]);

	useEffect(() => {
		if (templates.code) {
			dispatch({
				type: 'init_template_config',
				payload: templates?.message?.templates
					? templates.message.templates.filter(
							template =>
								template.template_access === 'public' ||
								template.export.user_id == users.current.id
					  )
					: [],
			});
		}
	}, [templates]);

	useEffect(() => {
		if (schedules.code) {
			dispatch({
				type: 'init_schedules_config',
				payload: schedules?.message?.schedules ?? [],
			});
		}
	}, [schedules]);

	useEffect(() => {
		dispatch({
			type: 'set_max_setups',
			payload: state.export_schedules.length + state.export_templates.length >= maxSetups,
		});
	}, [state.export_schedules, state.export_templates]);

	useEffect(() => {
		if (downloadUuid) {
			startExportDownload(downloadUuid);
		}
	}, [downloadUuid]);

	return (
		<Fragment>
			<PageHeader
				title={t`Feedback exports`}
				prominent
			/>
			<AppBarLeftContent tabs>
				<Tabs
					value={state.currentTab}
					onChange={(e, value) =>
						history.push(
							`/reporting/exports/${
								value === 0 ? 'recent' : value === 1 ? 'scheduled' : 'templates'
							}`
						)
					}
				>
					<Tab label={t`Recent exports`} />
					<Tab label={t`Scheduled exports`} />
					<Tab label={t('Export templates')} />
				</Tabs>
			</AppBarLeftContent>
			<ExportContextProvider
				value={{
					state: {
						...state,
						loading,
						schedulesLoading,
						templatesLoading,
					},
					api: {
						loadData,
						loadSchedulesData,
						loadTemplatesData,
					},
				}}
			>
				{state.currentTab === 0 && (
					<RecentExports
						filtersOpen={filtersOpen}
						setFiltersOpen={setFiltersOpen}
						downloadUuid={downloadUuid}
						startExportDownload={startExportDownload}
					/>
				)}
				{state.currentTab === 1 && (
					<ScheduledExports
						filtersOpen={filtersOpen}
						setFiltersOpen={setFiltersOpen}
					/>
				)}
				{state.currentTab === 2 && (
					<ExportTemplates
						filtersOpen={filtersOpen}
						setFiltersOpen={setFiltersOpen}
					/>
				)}
			</ExportContextProvider>
		</Fragment>
	);
}
