import React, { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

import { useTheme, makeStyles } from '@/styles';
import { Typography, ActionButton, Box } from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	main: {
		padding: theme.spacing(1),
	},
	content: {
		padding: theme.spacing(2, 0),
	},
	tooltip: {
		borderRadius: theme.shape.borderRadius,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		boxShadown: theme.shadows[20],
		padding: theme.spacing(2),
		'& [data-tour-elem="controls"]': {
			justifyContent: 'flex-end',
		},
	},
	buttons: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	mask: {
		opacity: 0.5,
	},
	title: {
		marginBottom: theme.spacing(2),
	},
	tooltip: {
		backgroundColor: '#fff',
		boxShadow: theme.shadows[20],
		borderRadius: theme.shape.borderRadius,
		maxWidth: props => (props.size === 'large' ? 600 : 400),
	},
	tooltipContent: {
		padding: theme.spacing(3),
	},
	actions: {
		marginTop: theme.spacing(3),
		display: 'flex',
		justifyContent: 'space-between',
	},
	hideButton: {
		color: theme.palette.text.secondary,
	},
}));

function parseStep({ disableBeacon = true, ...step }, props) {
	return {
		...props,
		...step,
		disableBeacon,
	};
}

function Tooltip({
	continuous,
	index,
	step = {},
	backProps,
	closeProps,
	primaryProps,
	tooltipProps,
	isLastStep,
}) {
	const { t } = useTranslation();
	const classes = useStyles({ ...step });
	const doneAction = useRef(false);

	useEffect(() => {
		return () => {
			if (step.beforeUnmount) {
				step.beforeUnmount();
			}
		};
	}, [step.beforeUnmount]);

	useEffect(() => {
		if (typeof step.action === 'function' && !doneAction.current) {
			doneAction.current = true;
			step.action();
		}
	}, [step.action, doneAction.current]);

	return (
		<div
			{...tooltipProps}
			className={classes.tooltip}
		>
			{step.outerContent && step.outerContent}
			<div className={classes.tooltipContent}>
				{step.title && (
					<Typography
						component="div"
						variant="subtitle"
						className={classes.title}
					>
						{step.title}
					</Typography>
				)}
				{step.content && (
					<Typography
						component="div"
						variant="body2"
						color="textSecondary"
					>
						{step.content}
					</Typography>
				)}
				<div className={classes.actions}>
					<div>
						<ActionButton
							disableTextTransform
							variant="text"
							action="hide"
							fontWeight="regular"
							className={classes.grey}
							onClick={step.onClose}
							dataTrackEvent={
								step.dataTrackEvent ? `${step.dataTrackEvent}_cancel` : null
							}
						>
							{step.hideButtonLabel ?? t`Hide these tips`}
						</ActionButton>
					</div>
					<div>
						{index > 0 && step.showBack && (
							<ActionButton {...backProps}>{t`Back`}</ActionButton>
						)}
						{continuous && (
							<ActionButton
								{...primaryProps}
								color="primary"
								variant="contained"
								onClick={e => {
									primaryProps.onClick(e);
									if (step.onNextClick) step.onNextClick(e);
								}}
								dataTrackEvent={step.dataTrackEvent ? step.dataTrackEvent : null}
							>
								{step.nextButtonlabel ?? isLastStep ? t`Let's go` : t`Got it`}
							</ActionButton>
						)}
						{!continuous && (
							<ActionButton
								{...closeProps}
								color="primary"
								variant="contained"
							>
								{t`Close`}
							</ActionButton>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export function Row({ children, ...props }) {
	return (
		<Box
			mb={2}
			{...props}
		>
			{children}
		</Box>
	);
}

export default function Joyride({
	onClose,
	open,
	steps,
	disablePrev = true,
	updateFn,
	continuous = true,
	...props
}) {
	const theme = useTheme();
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<ReactJoyride
			continuous={continuous}
			steps={steps.map((step, index) =>
				parseStep(step, {
					index,
					onClose,
					isLast: index + 1 === steps.length,
					updateFn,
				})
			)}
			tooltipComponent={Tooltip}
			//isOpen={open}
			run={open}
			// onRequestClose={onClose}
			// //children={<ActionIconButton action="close" />}
			// className={classes.tooltip}
			// maskClassName={classes.mask}
			// showNavigation={false}
			// showNumber={false}
			// showCloseButton={false}
			// showButtons={false}
			// prevButton={disablePrev ? <div /> : <ActionButton color="inherit">{t`Back`}</ActionButton>}
			// nextButton={<ActionButton color="inherit">{t`Next`}</ActionButton>}
			// lastStepNextButton={<ActionButton color="inherit">{t`Let’s go!`}</ActionButton>}
			//	tooltipComponent={Tooltip}
			styles={{
				options: {
					//	arrowColor: theme.palette.primary.main,
					// backgroundColor: theme.palette.primary.main,
					beaconSize: 24,
					overlayColor: 'rgba(0, 0, 0, 0.5)',
					primaryColor: theme.palette.primary.main,
					spotlightShadow: theme.shadows[24],
					textColor: theme.palette.text.primary,
					zIndex: 10000,
				},
			}}
			floaterProps={{
				styles: {
					arrow: {
						spread: 24,
						length: 12,
					},
					floater: {
						filter: 'none',
					},
				},
			}}
			spotlightPadding={props.disableOverlay ? 0 : 10}
			callback={({ status }) => {
				if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
					onClose();
				}
			}}
			{...props}
		/>
	);
}
