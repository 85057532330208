import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';

import { useTranslation, Trans } from 'react-i18next';

import { useAppContext } from '../AppContext';
import { Typography, Grid, ActionButton, Link, FormattedDate } from '../Layout';
import { Explainer } from '../Explainer';
import BillingCardBase from './BillingCardBase';
import BillingToggle from './BillingToggle';
import Amount from './Amount';

const useStyles = makeStyles(theme => ({
	header: {
		marginBottom: theme.spacing(4),
	},
	summaryTitleProduct: {
		width: 80,
		marginRight: theme.spacing(1),
		display: 'inline-block',
	},
	row: {
		marginBottom: theme.spacing(1),
	},
	cardActions: {
		justifyContent: 'space-between',
	},
}));

export default function ConfirmCard({
	billing = '',
	updateBilling = () => {},
	onBack = () => {},
	saveWithYearly = 0,
	totalAmount = 0,
	agreeTerms = false,
	setAgreeTerms = () => {},
	onConfirm = () => {},
	currency = 'EUR',
	vat = 0,
	showErrors = false,
	loading = false,
	isPackageIncrease,
	isUpgrading,
}) {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<BillingCardBase
			title={t`confirm_card-title`}
			action={
				isUpgrading ? (
					<Explainer
						title={t`confirm_card-is_upgrading_explainer-title`}
						content={
							<Trans
								i18nKey="confirm_card-is_upgrading_explainer-content"
								components={{
									p1: <Typography mb={3} />,
									p2: <Typography mb={3} />,
									p3: <Typography />,
								}}
							/>
						}
					>{t`confirm_card-is_upgrading_explainer-label`}</Explainer>
				) : null
			}
		>
			<Grid
				container
				spacing={7}
				className={classes.row}
			>
				<Grid item>
					<Typography variant="subtitle">{t`confirm_card-total`}</Typography>
					<Typography variant="body1">
						<Amount
							amount={totalAmount}
							currency={currency}
						/>
					</Typography>
					{vat !== 0 && (
						<Box mt={1}>
							<Typography
								variant="caption"
								color="textSecondary"
							>
								{t('confirm_card-vat_label', { vat })}
							</Typography>
						</Box>
					)}
				</Grid>

				<Grid item>
					<Typography variant="subtitle">{t`confirm_card-upgrade_per`}</Typography>
					<Typography>
						<FormattedDate date={new Date()} />
					</Typography>
				</Grid>

				<Grid item>
					<BillingToggle
						billing={billing}
						updateBilling={updateBilling}
						saveWithYearly={saveWithYearly}
						currency={currency}
						showLabel
						disabled={app.package.payment_interval === 'annual' || !isPackageIncrease}
					/>
				</Grid>
			</Grid>

			<div className={classes.row}>
				<FormControlLabel
					control={
						<Checkbox
							checked={agreeTerms}
							onChange={e => setAgreeTerms(e.target.checked)}
							color="primary"
						/>
					}
					label={
						<Trans
							i18nKey="confirm_card-terms_label"
							components={{
								termsLink: (
									<Link
										target="_blank"
										href="https://mopinion.com/terms"
									/>
								),
							}}
						/>
					}
				/>

				{!agreeTerms && showErrors && (
					<Typography
						variant="body2"
						error
					>{t`confirm_card-accept_terms_error`}</Typography>
				)}
			</div>

			<Grid
				container
				alignItems="center"
			>
				<Grid
					item
					xs
				>
					<ActionButton onClick={onBack}>{t`confirm_card-back_button`}</ActionButton>
				</Grid>
				<Grid item>
					<ActionButton
						variant="contained"
						color="primary"
						onClick={onConfirm}
						size="large"
						loading={loading}
					>
						{t`confirm_card-confirm_button`}
					</ActionButton>
				</Grid>
			</Grid>
		</BillingCardBase>
	);
}
