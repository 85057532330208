import React, { useState, Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import CheckIcon from '@material-ui/icons/Done';
import StopIcon from '@material-ui/icons/Stop';
import EditIcon from '@material-ui/icons/Edit';
import TagsIcon from '@material-ui/icons/Label';
import ReassignIcon from '@material-ui/icons/CompareArrows';
import DeleteIcon from '@material-ui/icons/Delete';
import ZoomIcon from '@material-ui/icons/Search';
import NewWindowIcon from '@material-ui/icons/OpenInNew';

import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '../AppContext';
import {
	ActionIconButton,
	ActionButton,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
} from '../Layout';
import ActionSummary from './ActionSummary';
import ActionActionDialogs from './ActionActionDialogs';
import ActionDetails from './ActionDetails';
import useUpdateActionStatus from './useUpdateActionStatus';
import { useHasPermission } from '../Permission';

const useStyles = makeStyles(theme => ({
	clickBg: {
		backgroundColor: theme.palette.grey[200],
	},
	maxWidth: {
		maxWidth: '100%',
	},
}));

export default function ActionExpansionPanel({
	action,
	expanded,
	selected,
	onSelection = () => {},
	loadData = () => {},
	listLoading,
	toggleExpand = () => {},
}) {
	const classes = useStyles();
	const { app } = useAppContext();
	const [menuAnchor, setMenuAnchor] = useState(null);
	const [mouse, setMouse] = useState({});
	const [dialogOpen, setDialogOpen] = useState('');
	const [updateStatus, setUpdateStatus] = useState(null);
	const { enqueueSnackbar } = useSnackbar();
	const { postForm, loading } = useUpdateActionStatus({
		id: action.id,
		status_id: updateStatus,
		loadData,
	});
	const hasEditPermission = useHasPermission();

	useEffect(() => {
		if (updateStatus) {
			postForm();
		}
	}, [updateStatus]);

	const userIsOwner = app.users.current.id == action.owner_user_id;
	const userIsAssigned = app.users.current.id == action.agent_user_id;

	function menuClick(e, type, status) {
		e.stopPropagation();
		if (type) {
			setDialogOpen(type);
		}
		if (status) {
			setUpdateStatus(status);
			setMenuAnchor(null);
		}
	}

	const { t } = useTranslation();
	const PanelProps = {
		className: classes.maxWidth,
		expanded: expanded,
		onChange: toggleExpand,
		...(expanded && { elevation: 10 }),
	};

	return (
		<ExpansionPanel {...PanelProps}>
			<ExpansionPanelSummary
				classes={{
					root: `${classes.maxWidth} ${
						expanded || selected || menuAnchor || (mouse.x && mouse.y)
							? classes.clickBg
							: ''
					}`,
					content: classes.maxWidth,
				}}
				disableRipple={false}
				onContextMenu={e => {
					e.preventDefault();
					const { clientX, clientY } = e;
					setMouse({
						x: clientX - 2,
						y: clientY - 4,
					});
				}}
			>
				<Grid
					container
					alignItems="center"
				>
					<Grid item>
						<Checkbox
							checked={selected || false}
							onClick={e => e.stopPropagation()}
							onChange={onSelection}
						/>
					</Grid>
					<Grid
						item
						xs
					>
						<ActionSummary
							expanded={expanded}
							action={action}
							variant="list"
						/>
					</Grid>
					{expanded && userIsAssigned && action.status_id == 1 && (
						<Grid item>
							<ActionButton
								loading={loading}
								onClick={e => menuClick(e, false, 2)}
								action="check"
								variant="outlined"
								color="primary"
								label={t`Mark accepted`}
							/>
						</Grid>
					)}
					{expanded && userIsAssigned && action.status_id == 2 && (
						<Grid item>
							<ActionButton
								loading={loading}
								onClick={e => menuClick(e, false, 3)}
								action="check"
								variant="outlined"
								label={t`Mark done`}
							/>
						</Grid>
					)}
					{expanded && userIsOwner && action.status_id == 3 && (
						<Grid item>
							<ActionButton
								loading={loading}
								onClick={e => menuClick(e, false, 4)}
								action="check"
								variant="outlined"
								color="secondary"
								label={t`Mark complete`}
							/>
						</Grid>
					)}
					<Grid item>
						<ActionIconButton
							loading={loading}
							action="more"
							onClick={e => {
								e.stopPropagation();
								setMenuAnchor(e.target);
							}}
							data-test-element="actionEditOptions"
						/>

						<Menu
							anchorEl={menuAnchor}
							open={Boolean(menuAnchor) || Boolean(mouse.x && mouse.y)}
							anchorReference={mouse.y ? 'anchorPosition' : null}
							anchorPosition={
								mouse.y && mouse.x ? { top: mouse.y, left: mouse.x } : undefined
							}
							onClose={e => {
								e.stopPropagation();
								setMenuAnchor(null);
								setMouse({});
							}}
						>
							{userIsAssigned && action.status_id == 1 && (
								<MenuItem
									onClick={e => menuClick(e, false, 2)}
									data-test-element="actionMarkAccepted"
									data-track-event="action_action_accepted"
								>
									<ListItemIcon>
										<CheckIcon />
									</ListItemIcon>
									{t`Mark accepted`}
								</MenuItem>
							)}
							{userIsAssigned && action.status_id == 2 && (
								<MenuItem
									onClick={e => menuClick(e, false, 3)}
									data-test-element="actionMarkDone"
									data-track-event="action_action_done"
								>
									<ListItemIcon>
										<CheckIcon />
									</ListItemIcon>
									{t`Mark done`}
								</MenuItem>
							)}
							{userIsOwner && action.status_id == 3 && (
								<MenuItem
									onClick={e => menuClick(e, false, 4)}
									data-test-element="actionMarkComplete"
									data-track-event="action_action_completed"
								>
									<ListItemIcon>
										<CheckIcon />
									</ListItemIcon>
									{t`Mark completed`}
								</MenuItem>
							)}
							{userIsAssigned && action.status_id != 4 && action.status_id != 5 && (
								<MenuItem
									onClick={e => menuClick(e, false, 5)}
									data-test-element="actionMarkDecline"
									data-track-event="action_action_declined"
								>
									<ListItemIcon>
										<StopIcon />
									</ListItemIcon>
									{t`Mark declined`}
								</MenuItem>
							)}
							<MenuItem
								onClick={e => menuClick(e, 'edit')}
								data-test-element="actionEdit"
								disabled={!hasEditPermission}
							>
								<ListItemIcon>
									<EditIcon />
								</ListItemIcon>
								{t`Edit`}
							</MenuItem>
							<MenuItem
								onClick={e => menuClick(e, 'reassign')}
								data-test-element="actionReAssign"
								disabled={!hasEditPermission}
							>
								<ListItemIcon>
									<ReassignIcon />
								</ListItemIcon>
								{t`Reassign`}
							</MenuItem>

							<MenuItem
								onClick={e => menuClick(e, 'tags')}
								data-test-element="actionAddTags"
								disabled={!hasEditPermission}
							>
								<ListItemIcon>
									<TagsIcon />
								</ListItemIcon>
								{t`Add tags`}
							</MenuItem>

							<MenuItem
								component={Link}
								to={`/actions/inbox/detail/${action.id}`}
								data-test-element="actionDetailView"
							>
								<ListItemIcon>
									<ZoomIcon />
								</ListItemIcon>
								{t`Detail view`}
							</MenuItem>

							<MenuItem
								component={Link}
								to={`/actions/inbox/detail/${action.id}`}
								target="_blank"
								data-test-element="actionLinkTo"
							>
								<ListItemIcon>
									<NewWindowIcon />
								</ListItemIcon>
								{t`Detail view in new window`}
							</MenuItem>

							<MenuItem
								onClick={e => menuClick(e, 'delete')}
								data-test-element="actionDelete"
								disabled={!hasEditPermission}
							>
								<ListItemIcon>
									<DeleteIcon />
								</ListItemIcon>
								{t`Delete`}
							</MenuItem>
						</Menu>
					</Grid>
				</Grid>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>
				{expanded && (
					<ActionDetails
						id={action.id}
						listLoading={listLoading}
					/>
				)}
			</ExpansionPanelDetails>

			<ActionActionDialogs
				open={dialogOpen}
				onClose={() => {
					setMenuAnchor(null);
					setDialogOpen('');
				}}
				ids={[action.id]}
				loadData={() => loadData()}
			/>
		</ExpansionPanel>
	);
}
