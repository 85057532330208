import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function TextAreaIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<path
				fill="currentColor"
				id="XMLID_1_"
				d="M36,7.8v24.5H4V7.8H36 M36,5.8H4c-1.1,0-2,0.9-2,2v24.5c0,1.1,0.9,2,2,2h32c1.1,0,2-0.9,2-2V7.8
				C38,6.7,37.1,5.8,36,5.8L36,5.8z M6,9.8v1.9h3.2v7.6h1.9v-7.6h3.2V9.8H6z M18,12.9h-5.7v1.9h1.9v4.4h1.9v-4.4H18V12.9z"
			/>
		</SvgIcon>
	);
}
