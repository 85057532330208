import React from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { tryParse } from '@/utils';
import {
	OptionalDraggable,
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	ListItemSecondaryAction,
	ActionIconButton,
	Chip,
} from '@/components/Layout';
import { useAppContext } from '@/components/AppContext';
import { useHasProjectPermission } from '@/components/Permission';
import DataSourceAvatar from './DataSourceAvatar';
import { UpgradeOverlay, ProductTrialChip } from '@/components/Billing';

const useStyles = makeStyles(theme => ({
	listBg: {
		background: '#fff',
		opacity: props => (props.hasProjectPermission ? 1 : 0.7),
	},
	isDragging: {
		boxShadow: theme.shadows[5],
	},
	selectedText: {
		fontWeight: theme.typography.fontWeightMedium,
	},
}));

function determineDisplayType({ survey_type, modal, trigger = '' }) {
	if (survey_type !== 'builder') {
		return null;
	}

	if (!modal) {
		return 'embed';
	}
	const parsed = tryParse(trigger);
	return parsed?.slider?.type || 'modal';
}

function determineSdkType({ campaign, survey_format, sdk_type }) {
	if (campaign === 'sdk') {
		return sdk_type === 'webview' || survey_format === 'conversational'
			? '-webview'
			: '-native';
	}
	return '';
}

export default function DataSourceItem({
	survey_type = 'builder',
	dataSource = {},
	index,
	title = '',
	fields = [],
	isToggle,
	isOpen,
	children,
	dragSource,
	selected,
	survey_format,
	campaign,
	disabled,
	...props
}) {
	const { t } = useTranslation();
	const hasProjectPermission = useHasProjectPermission({
		projectId: dataSource.project_id,
	});
	const classes = useStyles({ hasProjectPermission });
	const displayType = determineDisplayType({
		survey_type,
		...dataSource,
	});
	const { app } = useAppContext();

	const sdkType = determineSdkType(dataSource);

	const productInPackage = app.api.packageProductAllowed(dataSource.campaign);
	const daysLeftInProductTrial = app.api.packageProductTrialDaysLeft(dataSource.campaign);

	return (
		<UpgradeOverlay
			message={t(
				`app_general-datasource_item-product_not_allowed-${dataSource.campaign}`
			)}
			size="small"
			inline
			needsUpgrade={!productInPackage}
			product={dataSource.campaign}
			backgroundContrast
		>
			<div>
				<OptionalDraggable
					draggable={dragSource && hasProjectPermission}
					draggableId={dataSource.survey_key}
					index={index}
					cancelTransform
				>
					{({ snapshot }) => (
						<List
							className={`${classes.listBg} ${
								snapshot.isDragging ? classes.isDragging : ''
							}`}
							{...props}
						>
							<ListItem
								selected={isOpen}
								button
								data-test-element="datasource-item"
								disabled={!productInPackage}
							>
								{daysLeftInProductTrial > 0 && (
									<ProductTrialChip
										daysLeftInProductTrial={daysLeftInProductTrial}
										position="right"
										offsetTop={2.5}
									/>
								)}
								<ListItemAvatar>
									<DataSourceAvatar
										survey_format={survey_format}
										survey_type={survey_type}
										campaign={campaign}
									/>
								</ListItemAvatar>
								<ListItemText
									primary={title ? title : null}
									secondary={
										<>
											{displayType &&
												t(
													`app_general-datasource_item-display_type${sdkType}-${displayType}`
												)}
											{selected && (
												<Chip
													size="small"
													label={t`Added to chart`}
												/>
											)}
										</>
									}
									classes={{
										primary: `${isOpen ? classes.selectedText : ''}`,
									}}
								/>
								{isToggle && (
									<ListItemSecondaryAction>
										<ActionIconButton action={isOpen ? 'expand_less' : 'expand_more'} />
									</ListItemSecondaryAction>
								)}
							</ListItem>
						</List>
					)}
				</OptionalDraggable>

				{children}
			</div>
		</UpgradeOverlay>
	);
}
