import React, { useState, useEffect, Fragment } from 'react';

import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { ActionButton, Typography } from '../Layout';
import { useAppContext } from '../AppContext';
import { useListFilterContext } from '../FilteredLists';
import { AjaxForm } from '../Ajax';
import { ValidatedField } from '../Validation';
import { useHasPermission, NoPermissionTooltip } from '../Permission';

export default function SaveCustomView({
	customViewList,
	updateFilters = () => {},
	...props
}) {
	const {
		app: { users },
	} = useAppContext();
	const { customView, customFilters, setCustomView } = useListFilterContext();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const hasEditPermission = useHasPermission();

	const [customViewName, setCustomViewName] = useState('');

	useEffect(() => {
		try {
			setCustomViewName(
				customView
					? customViewList.find(view => customView === view.id)?.name
					: customView
			);
		} catch (e) {}
	}, [customView]);

	return (
		<AjaxForm
			valid={() => hasEditPermission && customViewName?.length > 0}
			url={customView ? '/filters/update-filter' : '/filters/add-filter'}
			data={{
				name: customViewName,
				...(customView && { id: customView }),
				user_id: users.current.id,
				description: JSON.stringify({
					modified: new Date(),
				}),
				rules: [...customFilters.filter(view => view.identifier && view.value)],
			}}
			onSuccess={r => {
				enqueueSnackbar(customView ? t`View updated` : t`View saved`);
				if (!customView && r.insert_id) {
					setCustomView(r.insert_id);
				}
				updateFilters();
			}}
		>
			{({ loading, postForm, clicked }) => (
				<Fragment>
					<Typography
						variant="subtitle1"
						display="block"
					>
						{customView ? t`Update custom view` : t`Save custom view`}
					</Typography>
					<Typography
						variant="body2"
						color="textSecondary"
					>
						{customView
							? t`Update the selected view with your new filters`
							: t`Save the current filters as custom view`}
					</Typography>

					<Box mt={2}>
						<ValidatedField
							component={TextField}
							label={t`View name`}
							placeholder={t('Add a name for your view')}
							fullWidth
							value={customViewName}
							onChange={e => setCustomViewName(e.target.value)}
							rules={[['required', true]]}
							showErrors={clicked}
							errorMessage={t`Please name your view`}
							disabled={!hasEditPermission}
						/>
					</Box>
					<Box mt={2}>
						<NoPermissionTooltip>
							<ActionButton
								action="save"
								label={t`Save`}
								loading={loading}
								onClick={postForm}
								color="secondary"
								disabled={!hasEditPermission}
							/>
						</NoPermissionTooltip>
					</Box>
				</Fragment>
			)}
		</AjaxForm>
	);
}
