import React, { useEffect, useState } from 'react';

import { useAppContext } from '../AppContext';
import { useLocalStorage } from '../../hooks';
import { isValid, isBefore } from '../../utils/date';

export default function ShowMaxTimes({
	children,
	name,
	onShow = () => {},
	showUntil,
	max = 1,
	...props
}) {
	if (!name) {
		throw new Error('ShowMaxTimes requires a name to set a localStorage property.');
	}

	const { app } = useAppContext();
	const [didShow, setDidShow] = useLocalStorage(
		`${app.users.current.uuid ?? app.users.current.id}_${
			app.organisations.current.uuid ?? app.organisations.current.org_id
		}_${name}_did_show`,
		0
	);
	const [show, setShow] = useState(false);

	useEffect(() => {
		if (!show && didShow < max) {
			if (isValid(showUntil)) {
				if (!isBefore(new Date(), showUntil)) {
					return;
				}
			}

			setDidShow(shows => shows + 1);
			setShow(true);
			onShow();
		}
	}, [show, didShow, max, showUntil]);

	return show ? children : null;
}
