import React from 'react';

import { faGoogle } from '@fortawesome/free-brands-svg-icons';

import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

import FaIcon from './FaIcon';
import CodeIcon from '@material-ui/icons/Code';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

export default function SocialIcon({ icon, ...props }) {
	switch (icon) {
		case 'sdk':
			return <PhoneIphoneIcon {...props} />;
		case 'email':
			return <MailOutlineIcon {...props} />;
		case 'code':
			return <CodeIcon {...props} />;
		case 'support':
			return <LiveHelpIcon {...props} />;
		case 'google':
			return (
				<FaIcon
					icon={faGoogle}
					{...props}
				/>
			);
		default:
			return null;
	}
}
