import React, { useState } from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Snackbar from '@material-ui/core/Snackbar';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import useGetTextAnalyticsLanguages from './useGetTextAnalyticsLanguages';
import ExclusionListDrawer from './ExclusionListDrawer';
import { sortByAlphabet } from '@/utils';

import {
	Grid,
	Card,
	CardContent,
	Typography,
	List,
	ActionIconButton,
	Loader,
} from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	gridContainer: {
		'& > div:not(:last-child)': {
			marginBottom: theme.spacing(2),
		},
	},
	emptyState: {
		whiteSpace: 'pre-line',
	},
	titleMargin: {
		marginBottom: theme.spacing(2),
	},
	fullWidth: {
		width: '100%',
	},
	marginTop: {
		marginTop: theme.spacing(2),
	},
	snackbar: {
		maxWidth: 400,
	},
}));

export default function TextAnalyticsLanguageList() {
	const classes = useStyles();
	const { t } = useTranslation();

	const [languages, languagesLoading] = useGetTextAnalyticsLanguages();
	const [editLanguage, setEditLanguage] = useState(null);

	const mappedLanguages = Object.keys(languages ?? {}).map(language => {
		return {
			value: languages[language],
			label: language,
			translatedLabel: t(`reporting-text_analytics-${language}`),
		};
	});

	return (
		<>
			<Card>
				<CardContent>
					<Grid
						container
						spacing={3}
						direction="column"
					>
						<Grid
							item
							xs={12}
						>
							<Typography
								component="p"
								className={classes.titleMargin}
								variant="subtitle1"
								fontWeight="medium"
							>
								{t`reporting-text_analytics-exclusion_list-title`}
							</Typography>
							<Typography
								component="p"
								variant="body1"
								gutterBottom
							>
								{t`reporting-text_analytics-exclusion_list-content`}
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<div>
								{languagesLoading && (
									<Loader
										circular
										size={16}
									/>
								)}
								{!languagesLoading && (
									<List>
										{sortByAlphabet(mappedLanguages, 'translatedLabel').map(language => {
											return <TextAnalyticsLanguageListItem language={language} />;
										})}
									</List>
								)}
							</div>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</>
	);
}

function TextAnalyticsLanguageListItem({ language }) {
	const [drawerOpen, setDrawerOpen] = useState(false);
	return (
		<>
			<ListItem
				key={language.value}
				button
				onClick={() => setDrawerOpen(true)}
			>
				<ListItemText primary={language.translatedLabel} />
				<ListItemSecondaryAction>
					<ActionIconButton
						action="to"
						// onClick={() => {
						// 	setEditLanguage(language.value);
						// }}
					/>
				</ListItemSecondaryAction>
			</ListItem>
			<ExclusionListDrawer
				language={language.label}
				languageKey={language.value}
				open={drawerOpen}
				onClose={() => setDrawerOpen(false)}
			/>
		</>
	);
}
