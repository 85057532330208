import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { ValidatedField } from '../Validation';
import { useExternalData } from '../Ajax';
import { Typography } from '../Layout';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';

export default function TrelloFlow({
	username,
	apiKey = '',
	token = '',
	idBoard = '',
	nameBoard = '',
	idList = '',
	nameList = '',
	setBoard = () => {},
	setList = () => {},
	clicked,
}) {
	const { t } = useTranslation();
	const [boards, loadingBoards] = useExternalData(
		`https://api.trello.com/1/members/${username}/boards?key=${apiKey}&token=${token}`
	);
	const [lists, loadingLists] = useExternalData(
		`https://api.trello.com/1/boards/${idBoard}/lists/?key=${apiKey}&token=${token}&fields=name,id&filter=open&cards=none`
	);

	return (
		<Fragment>
			<Typography variant="subtitle">{t`Select list endpoints`}</Typography>
			<Typography
				color="textSecondary"
				gutterBottom
			>
				{t`Select the board and list to which the feedback will be sent`}
			</Typography>
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					xs={6}
				>
					<ValidatedField
						label={t`Trello boards`}
						value={idBoard}
						select
						fullWidth
						onChange={e => {
							const board = boards.find(_board => {
								return _board.id === e.target.value;
							});
							setBoard(e.target.value, board.name);
						}}
						errorMessage={t`Select a board`}
						rules={[['required', true]]}
						showErrors={clicked}
					>
						{(Array.isArray(boards) ? boards : []).map(board => {
							return <MenuItem value={board.id}>{board.name}</MenuItem>;
						})}
					</ValidatedField>
				</Grid>
				<Grid
					item
					xs={6}
				>
					<ValidatedField
						label={t`Trello list`}
						value={idList}
						select
						fullWidth
						onChange={e => {
							const list = lists.find(_list => {
								return _list.id === e.target.value;
							});
							setList(e.target.value, list.name);
						}}
						errorMessage={t`Select a list`}
						rules={[['required', true]]}
						showErrors={clicked}
					>
						{(Array.isArray(lists) ? lists : []).map(list => {
							return <MenuItem value={list.id}>{list.name}</MenuItem>;
						})}
					</ValidatedField>
				</Grid>
			</Grid>
		</Fragment>
	);
}
