//when surpassed, notice user in view about export time
export const bigExport = 20000;

//current limit of items in 1 export
export const exportLimit = 500000;

export const exportOptions = [
	{ value: 'xlsx', label: 'Excel (.xlsx)' },
	{ value: 'csv', label: 'CSV (.csv)' },
	{ value: 'json', label: 'JSON (.json)' },
	{ value: 'html', label: 'HTML (.html)' },
];

export const statusTypes = [
	{
		type: 'SCHEDULED',
		class: 'scheduled',
		label: 'Export scheduled',
		step: '(step 1/4)',
	},
	{
		type: 'PENDING',
		class: 'pending',
		label: 'Export waiting in queue',
		step: '(step 2/4)',
	},
	{
		type: 'STARTED',
		class: 'started',
		label: 'Export is being generated',
		step: '(step 3/4)',
	},
	{
		type: 'FAIL',
		class: 'failed',
		label: 'Something went wrong in this export',
		step: '',
	},
	{
		type: 'FINISHED',
		class: 'finished',
		label: 'Export finished',
		step: '',
	},
	{
		type: 'RETRY',
		class: 'retry',
		label: 'We are retrying to export this data',
		step: '',
	},
	{
		type: 'REVOKED',
		class: 'revoked',
		label: 'This export is revoked',
		step: '',
	},
];
