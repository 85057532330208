import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';

import { StatusIcon, FormattedDate } from '../Layout';

import SurveyIcon from '@material-ui/icons/Assignment';

import { Link, withRouter } from 'react-router-dom';

const ListItemWithDetailLink = withRouter(({ match, customerId, children }) => {
	return (
		<ListItem
			button
			component={Link}
			to={`/reporting/customers/detail/${customerId}`}
			data-test-element="customerRow"
		>
			{children}
		</ListItem>
	);
});

const styles = theme => ({
	secondary: {
		display: 'inline-flex',
		alignItems: 'center',
	},
	date: {
		marginLeft: theme.spacing(0.5),
	},
	margin: {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
});

function CustomerListItem({
	id,
	firstname = '',
	lastname = '',
	full_name = '',
	created = '',
	email = '',
	survey_count,
	classes,
	...props
}) {
	const [customerFirstName = '', customerLastName = ''] = full_name.split(' ');
	return (
		<ListItemWithDetailLink customerId={id}>
			<ListItemAvatar>
				<Avatar>{customerFirstName.charAt(0) + customerLastName.charAt(0)}</Avatar>
			</ListItemAvatar>
			<ListItemText
				primary={
					// <Grid container>
					// 	<Grid item xs>{full_name}</Grid>
					// </Grid>
					full_name
				}
				primaryTypographyProps={{ variant: 'subtitle2' }}
				secondary={email}
				secondaryTypographyProps={{ variant: 'body2' }}
			/>
			<div className={classes.secondary}>
				<StatusIcon
					variant="survey"
					count={survey_count}
					className={classes.margin}
				/>

				<Typography
					variant="caption"
					color="textSecondary"
					className={classes.date}
				>
					<FormattedDate date={created} />
				</Typography>
			</div>
		</ListItemWithDetailLink>
	);
}

export default withStyles(styles)(CustomerListItem);
