import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useDataSourceContext } from '@/components/DataSources';
import { PageHeader, EmptyStateHandleHasSurveys } from '@/components/App';
import CustomerListItem from './CustomerListItem';
import { Pagination } from '@/components/Pagination';
import {
	useFilteredList,
	ListWithSidebar,
	ListFilterProvider,
	ListControls,
} from '@/components/FilteredLists';
import { Loader, constants, ActionButton } from '@/components/Layout';
import CustomerListFilters from './CustomerListFilters';
import { useEFM } from '@/components/Ajax';

const useStyles = makeStyles({
	circularProgress: {
		textAlign: 'center',
		marginTop: '10vh',
	},
	spaceEmpty: {
		marginTop: '20vh',
	},
	emptyState: {
		whiteSpace: 'pre-line',
	},
});

export default function CustomerList() {
	const { t } = useTranslation();
	const { datasource } = useDataSourceContext();
	const { pageId } = useParams();
	const [filtersOpen, setFiltersOpen] = useState(
		window.innerWidth > constants.filterDrawerBreakpoint
	);
	const classes = useStyles();
	const filterState = useFilteredList({
		filters: {
			survey_count: '',
			search: '',
		},
	});

	const { filters, other, sort, order, hasFilters, setSingleFilter } = filterState;

	const [{ customers = [], pager = {} }, loading] = useEFM(
		`/reporting/customers/list${pageId ? '/page/' + pageId : ''}`,
		{
			filters: {
				...filters,
			},
			...other,
			sort,
			order,
		}
	);

	return (
		<>
			<PageHeader title={t`Customer overview`} />
			<>
				<ListFilterProvider
					{...filterState}
					singleFilters={['survey_count', 'search']}
					filterLabels={{
						survey_count: {
							label: t('Feedback'),
							'>': t('With feedback'),
							'=': t('Without feedback'),
						},
					}}
				>
					<ListWithSidebar
						drawerContent={<CustomerListFilters />}
						open={filtersOpen}
						onClose={() => setFiltersOpen(false)}
					>
						<ListControls
							sortOptions={[
								{ label: t('Firstname: A to Z'), value: 'fname-asc' },
								{ label: t('Firstname: Z to A'), value: 'fname-desc' },
								{ label: t('Email: A to Z'), value: 'account-asc' },
								{ label: t('Email: Z to A'), value: 'account-desc' },
								{ label: t('Customer creation: last to first'), value: 'id-desc' },
								{ label: t('Customer creation: first to last'), value: 'id-asc' },
							]}
							toggleFilterDrawer={() => setFiltersOpen(prev => !prev)}
						/>

						{loading && (
							<Loader
								sticky
								empty={!customers || customers.length === 0}
							/>
						)}
						{customers && customers.length > 0 && (
							<Paper>
								<List>
									{customers.map(customer => (
										<CustomerListItem
											{...customer}
											key={customer.id}
										/>
									))}
								</List>
								<Pagination
									{...pager}
									base="/reporting/customers"
								/>
							</Paper>
						)}
						{(!customers || customers.length === 0) && !loading && (
							<EmptyStateHandleHasSurveys
								className={classes.emptyState}
								image={<img src={`/assets/img/r/emptystate/customers.gif`} />}
								primary={
									hasFilters
										? t`reporting-customers-emptystate_filter-title`
										: t`reporting-customers-emptystate_default-title`
								}
								secondary={
									hasFilters
										? t`reporting-customers-emptystate_filter-text`
										: t`reporting-customers-emptystate_default-text`
								}
								primaryNoSurvey={t`reporting-customers-emptystate_no_survey-title`}
								secondaryNoSurvey={t`reporting-customers-emptystate_no_survey-text`}
								action={
									datasource.forms.asArray.length === 0 && !hasFilters ? (
										<ActionButton
											variant="contained"
											dataTrackEvent="customer_emptystate_create_form"
											color="primary"
											link
											to="/data-collection/survey/edit/new"
										>
											{t`reporting-customers-emptystate_no_survey-button`}
										</ActionButton>
									) : null
								}
							/>
						)}
					</ListWithSidebar>
				</ListFilterProvider>
			</>
		</>
	);
}
