import React, { useEffect } from 'react';

import Box from '@material-ui/core/Box';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { AddCard, ActionButton, EmptyState, Grid } from '@/components/Layout';
import { PageHeader } from '@/components/App';
import { useAppContext } from '@/components/AppContext';
import { useAjaxForm } from '@/components/Ajax';
import DataSetCard from './DataSetCard';
import { useDataSourceContext } from '@/components/DataSources';
import { NoPermissionTooltip, useHasPermission } from '@/components/Permission';

export default function DataSetList(props) {
	const hasEditPermission = useHasPermission();
	const { t } = useTranslation();
	const { app } = useAppContext();
	const { datasource } = useDataSourceContext();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		datasource.api.loadSurveys(0);
	}, []);

	const {
		postForm: addDemoData,
		loading,
		clicked,
	} = useAjaxForm({
		url: '/survey/import/create-demo-set',
		onSuccess: r => {
			if (r.code === 200) {
				enqueueSnackbar(t`Demo dataset added`);
				datasource.api.loadSurveys(0);
			}
		},
	});

	return (
		<>
			<PageHeader title={t`Datasets`} />
			{datasource.datasets.asArray.length > 0 && (
				<Grid
					container
					spacing={3}
				>
					<Grid
						item
						xs={6}
						sm={4}
						md={3}
					>
						<NoPermissionTooltip>
							<AddCard
								label={t`New dataset`}
								onClick={() => history.push('/data-collection/dataset/import/new')}
								maxReached={
									[
										...datasource.forms.asArray,
										...datasource.datasets.asArray,
										...datasource.integrations.asArray,
									].length >= app.package.surveys
								}
								subject={t`datasets`}
								dataTestElement="newDataset"
								disabled={!hasEditPermission}
							/>
						</NoPermissionTooltip>
					</Grid>
					{datasource.datasets.asArray.map(dataset => {
						return (
							<Grid
								item
								xs={6}
								sm={4}
								md={3}
								key={dataset.id}
							>
								<DataSetCard {...dataset} />
							</Grid>
						);
					})}
				</Grid>
			)}
			{datasource.datasets.asArray.length === 0 && (
				<EmptyState
					image={<img src={`/assets/img/r/emptystate/dataset.gif`} />}
					primary={t`data_collection-datasets-emptystate_default-title`}
					secondary={t`data_collection-datasets-emptystate_default-text`}
					action={
						<Box textAlign="center">
							<NoPermissionTooltip>
								<ActionButton
									color="primary"
									variant="contained"
									disabled={
										!hasEditPermission ||
										[
											...datasource.forms.asArray,
											...datasource.datasets.asArray,
											...datasource.integrations.asArray,
										].length >= app.package.surveys
									}
									onClick={() => history.push('/data-collection/dataset/import/new')}
									loading={loading || clicked}
								>
									{t`data_collection-datasets-emptystate_default-button-add_data`}
								</ActionButton>
							</NoPermissionTooltip>
							<Box mt={1}>
								<NoPermissionTooltip>
									<ActionButton
										variant="text"
										color="default"
										label={t`data_collection-datasets-emptystate_default-button-add_demo_data`}
										onClick={addDemoData}
										loading={loading || clicked}
										disabled={!hasEditPermission}
									/>
								</NoPermissionTooltip>
							</Box>
						</Box>
					}
				/>
			)}
		</>
	);
}
