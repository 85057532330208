import React from 'react';

import { makeStyles } from '@/styles';

import InfoIcon from '@material-ui/icons/Info';

import { useTranslation } from 'react-i18next';

import Link from './Link';

const useStyles = makeStyles(theme => ({
	info: {
		verticalAlign: 'middle',
		marginLeft: theme.spacing(0.5),
	},
}));

export default function InfoLink({ children, tooltip, ...props }) {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<Link
			component="button"
			tooltip={tooltip}
			{...props}
		>
			{children ?? t`app_general-more_info`}
			<InfoIcon
				fontSize="small"
				className={classes.info}
			/>
		</Link>
	);
}
