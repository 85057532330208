import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { Typography, Link, SimpleDialog } from '../Layout';
import DeploymentCode from './DeploymentCode';

import { useTranslation, Trans } from 'react-i18next';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
	description: {
		marginBottom: theme.spacing(2),
	},
}));

export default function DeploymentCodeDialog({ open, onClose, variant = 'web', id }) {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<SimpleDialog
			title={t(`deployment_code_dialog-title_${variant}`)}
			open={open}
			onClose={onClose}
		>
			<Typography className={classes.description}>
				<Trans
					i18nKey={`deployment_code_dialog-content_${variant}`}
					components={{
						sdkLink: (
							<Link
								component="a"
								href="https://mopinion.com/product/integrations/#SDK"
								target="_blank"
							/>
						),
					}}
				/>
			</Typography>

			<DeploymentCode
				variant={variant}
				id={id}
			/>
		</SimpleDialog>
	);
}
