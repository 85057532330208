import React from 'react';
import { makeStyles } from '@/styles';

import { Grid } from '@/components/Layout';

import OnboardingSetPassword from './OnboardingSetPassword';

const useStyles = makeStyles(theme => ({
	margin: {
		marginTop: theme.spacing(10),
	},
}));

export default function ExtraUserOnboarding() {
	const classes = useStyles();

	return (
		<Grid
			container
			direction="column"
			spacing={4}
			justify="center"
			justifyContent="center"
			className={classes.margin}
		>
			<OnboardingSetPassword />
		</Grid>
	);
}
