import React, { useState, useEffect } from 'react';

import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@/styles';
import { useAppContext } from '@/components/AppContext';

import {
	Grid,
	Card,
	CardActionArea,
	CardContent,
	Typography,
	ActionButton,
	Loader,
} from '@/components/Layout';

import {
	DeploymentCodeDialog,
	useCreateNewDeployment,
	useLoadDeployments,
} from '@/components/Deployment';

import PublishFormsExplainer from './PublishFormsExplainer';
import { useSaveAddDashboard } from '@/components/Dashboards';

import { useDashboardContext } from '@/components/Dashboards';
import useOnboardingContext from './useOnboardingContext';
import useSaveOnboarding from './useSaveOnboarding';
import usePostMailDeploymentCode from './usePostMailDeploymentCode';
import { channelCardOptions } from './constants';

const useStyles = makeStyles(theme => ({
	card: {
		marginBottom: theme.spacing(2),
	},
	goal: {
		cursor: 'pointer',
		padding: theme.spacing(1),
		'& svg': {
			color: theme.palette.grey[600],
		},
	},
	marginBottomSdk: {
		marginBottom: theme.spacing(5),
	},
	italic: {
		fontStyle: 'italic',
	},
	dense: {
		padding: theme.spacing(1),
		'& img': {
			marginTop: theme.spacing(0.4),
		},
	},
	paddingRight: {
		paddingRight: theme.spacing(1),
	},
	paddingTop: {
		paddingTop: '0!important',
	},
}));

export default function OnboardingChooseChannel() {
	const { t } = useTranslation();
	const history = useHistory();
	const { app } = useAppContext();
	const { loadDashboards } = useDashboardContext();

	const onboarding = useOnboardingContext();
	const [deployments, loading, err, loadDeployments] = useLoadDeployments();

	const trialStartPoint = '/data-collection/survey/list';

	const [channel, setChannel] = useState(null);

	const onboardingTrialData = {
		channel: channel,
		onboarding_step: -1,
		trial_step: 1, //we start at 1, the finished initial_onboarding flow was 0
		trial_url: trialStartPoint,
	};

	//create new web deployment
	const {
		createNewDeployment: createNewWebDeployment,
		createNewDeploymentLoading: createNewWebDeploymentLoading,
	} = useCreateNewDeployment({
		type: 'web',
		onSuccess: response => {
			loadDeployments();
			addDefaultDashboard();
			if (Number(response.code) === 200) {
				mailDeploymentCode({
					post: {
						deploymentId: response.deployment_id,
						channel: 'web',
					},
				});
			}
		},
	});

	//create new sdk deployment
	const {
		createNewDeployment: createNewSdkDeployment,
		createNewDeploymentLoading: createNewSdkDeploymentLoading,
	} = useCreateNewDeployment({
		type: 'sdk',
		onSuccess: response => {
			loadDeployments();
			addDefaultDashboard();
			if (Number(response.code) === 200) {
				mailDeploymentCode({
					post: {
						deploymentId: response.deployment_id,
						channel: 'sdk',
					},
				});
			}
		},
	});

	const { postForm: addDefaultDashboard, loading: addDefaultDashboardLoading } =
		useSaveAddDashboard({
			name: t`initial_user_onboarding_default_dashboard`,
			onSuccess: response => {
				loadDashboards();
			},
		});

	//update goal and final step of trial flow
	const { postForm: finishInitialOnboarding, loading: finishInitialOnboardingLoading } =
		useSaveOnboarding({
			data: onboardingTrialData,
			onSuccess: response => {
				onboarding.dispatch({
					type: 'start_onboarding_trial',
					payload: onboardingTrialData,
				});
				app.api.updateSessionData();
				history.push(trialStartPoint);
			},
		});

	//mail the deployment to the user
	const { postForm: mailDeploymentCode, loading: mailDeploymentCodeLoading } =
		usePostMailDeploymentCode();

	useEffect(() => {
		//if a deployment is already created, force channel to prevent creating a new one
		if (deployments.deployments?.length > 0) {
			setChannel(deployments.deployments[0].type);
		}
	}, [deployments.deployments]);

	return (
		<>
			{!channel && (
				<>
					<ChannelOptions
						deployments={deployments?.deployments ?? []}
						setChannel={setChannel}
						loading={loading}
						createNewWebDeployment={createNewWebDeployment}
						createNewSdkDeployment={createNewSdkDeployment}
					/>
					<Grid item>
						<PublishFormsExplainer />
					</Grid>
				</>
			)}
			{channel && (
				<Channel
					channel={channel}
					deployments={deployments?.deployments ?? []}
					loading={loading || createNewWebDeploymentLoading}
					finishInitialOnboarding={finishInitialOnboarding}
					finishInitialOnboardingLoading={finishInitialOnboardingLoading}
					addDefaultDashboard={addDefaultDashboard}
				/>
			)}
		</>
	);
}

function ChannelOptions({
	loading,
	setChannel = () => {},
	createNewWebDeployment = () => {},
	createNewSdkDeployment = () => {},
	addDefaultDashboard = () => {},
}) {
	const classes = useStyles();
	const { t } = useTranslation();

	function updateChannel(channel) {
		setChannel(channel);
		if (channel === 'web') {
			createNewWebDeployment();
		}
		if (channel === 'sdk') {
			createNewSdkDeployment();
		}
		if (channel === 'email') {
			//dashboards for other channels are created in deployment response
			addDefaultDashboard();
		}
	}

	return (
		<>
			<Grid item>
				<Typography
					variant="h5"
					gutterBottom
				>
					{t`onboarding-getting_started-step_channel-title`}
				</Typography>
				<Typography variant="body1">
					{t`onboarding-getting_started-step_channel-subtitle`}
				</Typography>
			</Grid>

			<Grid item>
				<Typography
					variant="subtitle"
					fontWeight="bold"
				>
					{t`onboarding-getting_started-step_channel-collect-title`}
				</Typography>
				<Typography
					variant="body1"
					className={classes.italic}
				>
					{t`onboarding-getting_started-step_channel-collect-text`}
				</Typography>
			</Grid>

			<Grid item>
				{channelCardOptions.map(option => {
					return (
						<Card
							key={option.type}
							elevation={4}
							className={classes.card}
							data-track-event={`onboarding_choose_channel_${option.type}`}
							data-test-element={`onboarding_choose_channel_${option.type}`}
							onClick={() => {
								if (!loading) {
									updateChannel(option.type);
								}
							}}
						>
							<CardActionArea
								className={classes.goal}
								data-track-event={`onboarding_choose_channel_${option.type}`}
							>
								<CardContent className={classes.dense}>
									<Grid
										container
										alignItems="center"
									>
										<Grid
											item
											className={classes.paddingRight}
										>
											<img src={option.image} />
										</Grid>
										<Grid item>
											<Typography variant="subtitle2">{t(option.title)}</Typography>
										</Grid>
									</Grid>
								</CardContent>
							</CardActionArea>
						</Card>
					);
				})}
			</Grid>
		</>
	);
}

function Channel({
	channel,
	deployments,
	loading,
	finishInitialOnboardingLoading,
	finishInitialOnboarding = () => {},
}) {
	const { t } = useTranslation();

	const [codeDialogOpen, setCodeDialogOpen] = useState(false);

	return (
		<>
			<Grid item>
				<Typography variant="h5">
					{t(`onboarding-getting_started-step_channel-${channel}-title`)}
				</Typography>
			</Grid>

			<Grid item>
				<Typography variant="body1">
					<Trans
						i18nKey={`onboarding-getting_started-step_channel-${channel}-text`}
						components={{
							p1: <p />,
							p2: <p />,
							strong: <strong />,
							italic: <i />,
						}}
					/>
				</Typography>
			</Grid>

			{channel === 'email' && <ChannelContentEmail />}
			{channel === 'sdk' && <ChannelContentSdk />}
			{channel === 'web' && (
				<ChannelContentWeb
					loading={loading}
					setCodeDialogOpen={setCodeDialogOpen}
				/>
			)}

			<Grid item>
				<ActionButton
					data-test-element={`onboarding_finish_initial_onboarding_${channel}`}
					variant="contained"
					color="primary"
					data-track-event={`onboarding_finish_initial_onboarding_${channel}`}
					disabled={loading}
					loading={finishInitialOnboardingLoading}
					onClick={() => {
						finishInitialOnboarding();
					}}
				>
					{t(
						`onboarding-getting_started-step_channel-${channel}-button_finish_onboarding`
					)}
				</ActionButton>
			</Grid>

			<DeploymentCodeDialog
				variant={channel}
				open={deployments.length && codeDialogOpen}
				onClose={() => setCodeDialogOpen(false)}
				id={deployments[0]?.id}
			/>
		</>
	);
}

function ChannelContentWeb({ loading, setCodeDialogOpen = () => {} }) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<>
			<Grid item>
				<Card
					elevation={4}
					className={classes.card}
					onClick={() => {
						if (!loading) {
							setCodeDialogOpen(true);
						}
					}}
				>
					<CardActionArea className={classes.goal}>
						<CardContent
							className={classes.dense}
							data-track-event={`onboarding-channel-web-view_deployment`}
						>
							<Grid
								container
								alignItems="center"
							>
								<Grid
									item
									className={classes.paddingRight}
								>
									{loading && (
										<Loader
											circular
											size={16}
										/>
									)}
									{!loading && (
										<img
											src={'/assets/img/r/onboarding/onboarding_flow_deployment.svg'}
										/>
									)}
								</Grid>
								<Grid item>
									<Typography variant="subtitle2">
										{loading
											? t`onboarding-getting_started-step_channel-web-open_deployment_info_loading`
											: t`onboarding-getting_started-step_channel-web-open_deployment_info`}
									</Typography>
								</Grid>
							</Grid>
						</CardContent>
					</CardActionArea>
				</Card>
			</Grid>

			<Grid item>
				<Typography
					variant="body1"
					className={classes.italic}
				>
					{t`onboarding-getting_started-step_channel-web-install_title`}
				</Typography>
				<Typography variant="body1">
					{t`onboarding-getting_started-step_channel-web-install_text`}
				</Typography>
			</Grid>
		</>
	);
}

export function ChannelContentSdk() {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Grid
			item
			className={classes.paddingTop}
		>
			<Typography
				variant="body1"
				className={classes.italic}
			>
				{t`onboarding-getting_started-step_channel-sdk-install_title`}
			</Typography>
			<Typography variant="body1">
				{t`onboarding-getting_started-step_channel-sdk-install_text`}
			</Typography>
		</Grid>
	);
}

function ChannelContentEmail() {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Grid
			item
			className={classes.paddingTop}
		>
			<Typography
				variant="body1"
				className={classes.italic}
			>
				{t`onboarding-getting_started-step_channel-email-install_title`}
			</Typography>
			<Typography variant="body1">
				{t`onboarding-getting_started-step_channel-email-install_text`}
			</Typography>
		</Grid>
	);
}
