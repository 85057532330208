import 'core-js/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { setAutoFreeze } from 'immer';

import * as Sentry from '@sentry/react';
import { Router } from 'react-router-dom';

import './index.css';
import { App } from './components/App';
import { unregister } from './registerServiceWorker';
import history from './history';
setAutoFreeze(false); // Disable automatic freezing

function getEnv() {
	switch (document.location.hostname) {
		case 'localhost':
		case 'dev.mopinion.com':
			return 'local';

		case 'beta.mopinion.com':
			return 'beta';

		case 'accept.mopinion.com':
			return 'accept';

		default:
			return 'production';
	}
}

function getVersion() {
	try {
		return process.env.REACT_APP_VERSION;
	} catch (e) {
		return 'env_missing';
	}
}

const env = getEnv();

if (env !== 'local') {
	Sentry.init({
		dsn: 'https://6dd59ce8ff584439acb4c7ead85c9dc2@o541229.ingest.sentry.io/5659897',
		release: `raspberry@${getVersion()}`,
		integrations: [
			Sentry.reactRouterV5BrowserTracingIntegration({
				history,
			}),
		],
		tracesSampleRate: 0.4,
		environment: env,
	});
}

ReactDOM.render(
	<Router
		basename="/r"
		history={history}
	>
		<App />
	</Router>,
	document.getElementById('root')
);

unregister();
