import React, { createContext, useContext } from 'react';

const ExportContext = createContext();

export default ExportContext;

export function useExportContext() {
	return useContext(ExportContext);
}

export function ExportContextProvider({ value, children }) {
	return <ExportContext.Provider value={value}>{children}</ExportContext.Provider>;
}
