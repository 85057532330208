import React, { useState, useEffect } from 'react';

import DeleteIcon from '@material-ui/icons/Delete';
import UploadIcon from '@material-ui/icons/CloudUpload';

import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';

import { useDropzone } from 'react-dropzone';
import { useTranslation, Trans } from 'react-i18next';
import { axiosInstance } from '@/lib/axios';
import { formatBytes } from '@/utils';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@/styles';

import RenderConditional from './RenderConditional';
import ButtonWithLoader from './ButtonWithLoader';

import {
	Box,
	Card,
	Zoom,
	Typography,
	Link,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListItemSecondaryAction,
} from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	dropzone: {
		borderWidth: 2,
		borderStyle: 'dashed',
		borderColor: theme.palette.grey[200],
		borderRadius: theme.shape.borderRadius,
		padding: theme.spacing(2),
		width: '100%',
		minHeight: 80,
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	img: {
		maxWidth: props => props.imgMaxWidth,
		height: 'auto',
		marginBottom: theme.spacing(1),
	},
	buttonWrap: {
		textAlign: 'center',
		margin: theme.spacing(1, 0),
	},
	iconMargin: {
		marginRight: theme.spacing(0.5),
	},
	link: {
		cursor: 'pointer',
	},
}));

export default function Dropzone({
	title,
	placeholder = '',
	multiple = false,
	accept = 'image/*',
	url,
	headers = {},
	className = '',
	onUpload = () => {},
	onError = () => {},
	isImageUploader,
	DropzoneProps = {},
	loading,
	filesInCard = false,
	imgMaxWidth = 80,
	hint,
	currentImage,
	...props
}) {
	const classes = useStyles({ imgMaxWidth });
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [files, setFiles] = useState([]);
	const [lastUploadedFile, setLastUploadedFile] = useState('');

	const showUploadButton =
		!currentImage ||
		lastUploadedFile.includes('blob:') ||
		(currentImage && lastUploadedFile !== currentImage);

	const [uploading, setUploading] = useState(false);
	const { getRootProps, getInputProps } = useDropzone({
		multiple,
		accept,
		onDrop: acceptedFiles => {
			setFiles(
				acceptedFiles.map(file => {
					if (accept === 'image/*') {
						Object.assign(file, { preview: URL.createObjectURL(file) });
					}
					setLastUploadedFile(file.preview);
					return file;
				})
			);
		},
		onDropRejected: rejectedFiles => {
			enqueueSnackbar(
				t`data_collection-feedback_forms-editor-design-sidebar_options-logo-drag_drop-snackbar_error`
			);
		},
	});

	useEffect(
		() => () => {
			// Make sure to revoke the data uris to avoid memory leaks
			files.forEach(file => file.preview && URL.revokeObjectURL(file.preview));
		},
		[files]
	);

	function getFileIcon(file) {
		const ext = file.name.split('.').pop();
		return ext === 'xls' || ext === 'xlsx'
			? '/assets/img/filetypes/xls.svg'
			: ext === 'csv'
			? '/assets/img/filetypes/csv.svg'
			: '';
	}

	function removeFile(name) {
		setFiles(state => state.filter(file => file.name !== name));
	}

	function uploadFiles() {
		const uploaded = [];

		files.forEach((file, fileIndex) => {
			const data = new FormData();
			data.set('file', file);
			setUploading(true);
			axiosInstance
				.post(url, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'X-Requested-With': 'XMLHttpRequest',
						'X-Requested-By-React': 'true',
						...headers,
					},
				})
				.then(r => {
					uploaded.push(fileIndex);
					if (uploaded.length === files.length) setUploading(false);
					onUpload(r.data);
					setLastUploadedFile(r.data.full_url);
				})
				.catch(e => {
					uploaded.push(fileIndex);
					if (uploaded.length === files.length) setUploading(false);
					onError(e);
				});
		});
	}

	return (
		<div className={className}>
			<RenderConditional
				component={Box}
				condition={filesInCard && files.length > 0}
				mb={2}
			>
				<RenderConditional
					component={Card}
					condition={filesInCard && files.length > 0}
				>
					{files.length > 0 && showUploadButton && (
						<>
							<List>
								{files.map(file => (
									<ListItem key={file.name}>
										{file.preview ? (
											<ListItemAvatar>
												<Avatar src={file.preview} />
											</ListItemAvatar>
										) : (
											<ListItemAvatar>
												<Avatar src={getFileIcon(file)} />
											</ListItemAvatar>
										)}
										<ListItemText
											primary={file.name}
											secondary={formatBytes(file.size)}
										/>
										<ListItemSecondaryAction>
											<IconButton
												edge="end"
												onClick={e => removeFile(file.name)}
											>
												<DeleteIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								))}
							</List>
						</>
					)}
					<Zoom
						in={files.length > 0 && showUploadButton}
						mountOnEnter
						unmountOnExit
					>
						<div className={classes.buttonWrap}>
							<ButtonWithLoader
								component={Fab}
								variant="extended"
								size="medium"
								color="secondary"
								onClick={uploadFiles}
								loading={uploading}
								data-test-element="datasetUploadButton"
								data-track-event="dataset_dataset_uploaded"
								data-onboarding="upload-dataset"
							>
								<UploadIcon className={classes.iconMargin} />
								{t`data_collection-feedback_forms-editor-design-sidebar_options-logo-drag_drop-button_upload`}
							</ButtonWithLoader>
						</div>
					</Zoom>
				</RenderConditional>
			</RenderConditional>
			<div
				{...getRootProps({
					...DropzoneProps,
					className: `${classes.dropzone} ${
						DropzoneProps.className ? DropzoneProps.className : ''
					}`,
				})}
				data-onboarding="dropzone"
			>
				<input
					data-test-element="datasetUploadInput"
					{...getInputProps()}
				/>
				{placeholder && (
					<img
						src={placeholder}
						className={classes.img}
					/>
				)}
				{hint && hint}
				{!hint && (
					<Typography
						variant="subtitle1"
						color="textSecondary"
					>
						<Trans
							i18nKey="data_collection-feedback_forms-editor-design-sidebar_options-logo-drag_drop-text"
							components={{
								browseLink: <Link className={classes.link} />,
							}}
						/>
					</Typography>
				)}
			</div>
		</div>
	);
}
