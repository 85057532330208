import React, { Component } from 'react';

//import { CustomerDetail } from '../../components/Customer';
import { EFM } from '../../api/EFM';

class MapPage extends Component {
	state = { customer: {} };

	componentDidMount() {}
	render() {
		return <div />;
	}
}

export default MapPage;
