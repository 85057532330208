import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useAppContext } from '../AppContext';

const useStyles = makeStyles(theme => ({
	progress: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		zIndex: theme.zIndex.drawer + 50,
	},
}));

export default function AppLoader(props) {
	const classes = useStyles();
	const {
		app: { layout },
	} = useAppContext();
	return layout.appLoading ? <LinearProgress className={classes.progress} /> : null;
}
