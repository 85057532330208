import React, { Component, Suspense } from 'react';
import { withStyles } from '@material-ui/core/styles';
import InlineToggles from './InlineToggles';
import ListToggles from './ListToggles';
import LinkToggles from './LinkToggles';
import CustomVariables from './CustomVariables';
import FontSizeSelect from './FontSizeSelect';

const WYSIWGEditor = React.lazy(() => import('./WYSIWGEditor'));

const styles = theme => ({
	editor: {
		'& .public-DraftEditorPlaceholder-root': {
			position: 'absolute',
			color: theme.palette.grey[500],
			fontSize: theme.typography.pxToRem(14),
		},
		width: '100%',
		minHeight: 80,
		padding: theme.spacing(1) + 'px 0px',
		position: 'relative',
		'&:before': {
			left: 0,
			right: 0,
			bottom: 0,
			content: "''",
			position: 'absolute',
			transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
			pointerEvents: 'none',
		},
		'&:hover:not($editorFocus):before': {
			borderBottom: '2px solid rgba(0, 0, 0, 0.87)',
		},
		'&:after': {
			left: 0,
			right: 0,
			bottom: 0,
			content: "''",
			position: 'absolute',
			transform: 'scaleX(0)',
			transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
			borderBottom: `2px solid ${theme.palette.primary.dark}`,
			pointerEvents: 'none',
		},
	},
	editorFocus: {
		'&:after': {
			transform: 'scaleX(1)',
		},
	},
	error: {
		'&:after': {
			borderBottomColor: theme.palette.error.main,
			transform: 'scaleX(1)',
		},
	},
	toolbar: {
		'& > *': {
			display: 'inline-flex',
			margin: theme.spacing(1),
		},
	},
});

class Editor extends Component {
	state = {};
	focus = () => this.setState({ focus: true });
	blur = () => this.setState({ focus: false });
	parseTarget = link => {
		// if (['close_modal', 'prev_page', 'next_page', 'start', 'close'].some(fn => fn === link)) {
		// 	return link;
		// } else {
		// 	return link.match(/^(https?):\/\//) ? link : `https://${link}`;
		// }

		//always return input
		return link;
	};

	render() {
		const {
			editorState,
			onChange,
			classes,
			error,
			surveyId,
			options = ['inline', 'list', 'link'],
			placeholder,
			fromLinkElementSurveyEditor,
			introThanksPageLinkEditor,
			className = '',
			editorClassName = '',
			fieldVars,
			user,
			conversationalSurveyEditor,
		} = this.props;
		const { focus } = this.state;

		return (
			<Suspense fallback={<div />}>
				<WYSIWGEditor
					editorState={editorState}
					onEditorStateChange={onChange}
					wrapperClassName={className}
					toolbarClassName={classes.toolbar}
					editorClassName={`${classes.editor} ${focus ? classes.editorFocus : ''} ${
						error ? classes.error : ''
					} ${editorClassName}`}
					onFocus={this.focus}
					onBlur={this.blur}
					toolbar={{
						//options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
						options: options,
						// fontSize: {
						// 	component:FontSizeSelect,
						// 	options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]
						// },
						inline: {
							component: InlineToggles,
						},
						list: {
							component: ListToggles,
							options: ['unordered', 'ordered'],
						},
						link: {
							component: LinkToggles,
							//we have to abuse one of these options adding survey options (link element survey builder) since we cant add custom options
							dropdownClassName: `${
								fromLinkElementSurveyEditor ? 'fromLinkElementSurveyEditor' : ''
							} ${introThanksPageLinkEditor ? 'introThanksPageLinkEditor' : ''} ${
								conversationalSurveyEditor ? 'conversationalSurveyEditor' : ''
							}`,
							linkCallback: ({ title, target, targetOption }) => {
								return {
									title,
									target: this.parseTarget(target),
									targetOption: '_blank',
								};
							},
						},
					}}
					toolbarCustomButtons={
						!fromLinkElementSurveyEditor && [
							<CustomVariables
								surveyId={surveyId}
								fieldVars={fieldVars}
								user={user}
							/>,
						]
					}
					//options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history']}
					//{...this.props}
					placeholder={placeholder}
				/>
			</Suspense>
		);
	}
}

export default withStyles(styles)(Editor);
