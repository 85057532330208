import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import MailIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';

import { useTranslation } from 'react-i18next';
import { copyToClipboard } from '../../utils';
import { getInitials } from '../../helpers';
import { FormattedDate, RenderConditional } from '../Layout';
import { ruleTests } from '../Validation';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	avatarLarge: {
		width: 60,
		height: 60,
	},
	avatarMedium: {
		width: 40,
		height: 40,
	},
	ellipsis: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	pointer: {
		cursor: 'pointer',
	},
}));

function isPhone(var_type) {
	return parseInt(var_type, 10) === 10;
}

function isEmail(var_type) {
	return [6, 14].includes(parseInt(var_type, 10));
}

export default function CustomerCard({
	isElevated = true,
	allowContact = true,
	customer = {},
	link,
	avatarSize = 'medium',
}) {
	const { t } = useTranslation();
	const classes = useStyles();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const {
		id,
		firstname,
		lastname,
		full_name = '',
		email,
		details = {},
		created,
	} = customer;
	const [customerFirstName = '', customerLastName = ''] = full_name.split(' ');
	const CardProps = {
		...(!isElevated && {
			elevation: 0,
			square: true,
			'data-onboarding': 'customer-card',
		}),
	};

	//filter and sort contact details -> only show phone(10) and email(14) customer id if email (6)
	const detailsArr = Object.values(details)
		.filter(
			detail =>
				(isEmail(detail.var_type) && ruleTests.email(detail.field_label)) ||
				(isPhone(detail.var_type) && ruleTests.phone(detail.field_label))
		)
		.sort((a, b) => {
			if (isPhone(a.var_type)) return -1;
			if (isEmail(a.var_type)) return 1;
			return 0;
		});

	function copy(str) {
		copyToClipboard(str).then(() => enqueueSnackbar(str + ' ' + t`copied to clipboard`));
	}

	return (
		<Card {...CardProps}>
			<CardHeader
				title={full_name !== 'unknown' ? full_name : email}
				titleTypographyProps={{ variant: 'h6' }}
				subheader={<FormattedDate date={created} />}
				avatar={
					<Avatar
						className={
							avatarSize === 'large' ? classes.avatarLarge : classes.avatarMedium
						}
					>
						{full_name !== 'unknown'
							? getInitials(customerFirstName, customerLastName)
							: getInitials(email)}
					</Avatar>
				}
				className={link ? classes.pointer : ''}
				onClick={() => {
					if (link) history.push(`/reporting/customers/detail/${id}`);
				}}
				data-test-element="feedbackItemCustomer"
			/>

			{detailsArr.length > 0 && (
				<List>
					{detailsArr.map((contactDetail = {}) => {
						const { var_type, field_label } = contactDetail;
						return (
							<Tooltip
								title={
									<Fragment>
										<div>{field_label}</div> <strong>{t('Click to copy')}</strong>
									</Fragment>
								}
							>
								<ListItem
									button
									onClick={() => copy(field_label)}
								>
									<ListItemIcon>
										{isEmail(var_type) ? <MailIcon /> : <PhoneIcon />}
									</ListItemIcon>
									<ListItemText
										primary={contactDetail.field_label}
										primaryTypographyProps={{
											className: classes.ellipsis,
											display: 'block',
										}}
										secondary={isEmail(var_type) ? t('Email') : t('Phone')}
									/>
								</ListItem>
							</Tooltip>
						);
					})}
				</List>
			)}
		</Card>
	);
}
