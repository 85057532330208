import React, { useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import SelectionToolbar from './SelectionToolbar';
import { AppContext } from '../AppContext';
import { drawerWidth, drawerWidthClosed } from '../Layout/constants';
import Fade from '@material-ui/core/Fade';

const styles = theme => ({
	appBar: {
		width: `calc(100% - ${drawerWidthClosed}px)`,
		backgroundColor: '#fff',
	},
	disableInset: {
		width: '100%',
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: drawerWidth,
	},
});

function BarContent({
	isPage,
	text = '',
	onClose,
	show,
	children,
	classes,
	withLogo,
	disableInset,
	style = {},
	AppBarProps = {},
	inDrawer,
	...props
}) {
	const {
		app: { layout },
	} = useContext(AppContext);
	const AppBarPropsExtended = {
		color: 'default',
		position: 'fixed',
		elevation: 0,
		...AppBarProps,
		className: `${classes.appBar} ${disableInset ? classes.disableInset : ''} ${
			AppBarProps.className ? AppBarProps.className : ''
		} ${
			layout.persistentDrawer && layout.mainMenuOpen && !isPage && !disableInset
				? classes.appBarShift
				: ''
		}`,
	};
	return (
		<AppBar {...AppBarPropsExtended}>
			<SelectionToolbar
				text={text}
				onClose={onClose}
				withLogo={withLogo}
				{...props}
			>
				{children}
			</SelectionToolbar>
		</AppBar>
	);
}

function SelectionAppBar({ fade, show, ...props }) {
	return fade ? (
		<Fade
			in={Boolean(show)}
			unmountOnExit
			mountOnEnter
		>
			<BarContent {...props}>{props.children}</BarContent>
		</Fade>
	) : show ? (
		<BarContent {...props}>{props.children}</BarContent>
	) : null;
}

export default withStyles(styles)(SelectionAppBar);
