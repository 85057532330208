import React from 'react';

import Box from '@material-ui/core/Box';

import { useTranslation, Trans } from 'react-i18next';

import Explainer from './Explainer';
import { CollapsibleBox, Typography, KnowledgeBaseLink } from '@/components/Layout';
import { useAppContext } from '@/components/AppContext';

export default function DeploymentExplainer() {
	const { app } = useAppContext();
	const { t } = useTranslation();
	return (
		<Explainer
			fontWeight="regular"
			title={t`deployment_explainer-title`}
			content={
				<>
					<Box mb={3}>
						<Typography>{t`deployment_explainer-content-description`}</Typography>
					</Box>
					<CollapsibleBox
						label={t`deployment_explainer-content-web_title`}
						startCollapsed={!app.package.allow_web}
						mb={3}
					>
						{t`deployment_explainer-content-web_content`}
					</CollapsibleBox>
					<CollapsibleBox
						label={t`deployment_explainer-content-email_title`}
						startCollapsed={!app.package.allow_email}
						mb={3}
					>
						{t`deployment_explainer-content-email_content`}
					</CollapsibleBox>
					<CollapsibleBox
						label={t`deployment_explainer-content-app_title`}
						startCollapsed={!app.package.allow_app}
						mb={3}
					>
						{t`deployment_explainer-content-app_content`}
					</CollapsibleBox>

					<Box>
						<Typography>
							<Trans
								i18nKey="deployment_explainer-content-knowledge_base_link"
								components={{
									knowledgeBaseLink: (
										<KnowledgeBaseLink
											dataTrackEvent="new_survey_explainer_knowledgebase_deployments"
											color="inherit"
											page="deployments"
											verticalAlign="baseline"
										>
											{t`deployment_explainer-content-knowledge_base_link`}
										</KnowledgeBaseLink>
									),
								}}
							/>
						</Typography>
					</Box>
				</>
			}
		>
			{t`deployment_explainer-label`}
		</Explainer>
	);
}
