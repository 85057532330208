import red from '@material-ui/core/colors/red';
import pink from '@material-ui/core/colors/pink';
import purple from '@material-ui/core/colors/purple';
import deepPurple from '@material-ui/core/colors/deepPurple';
import indigo from '@material-ui/core/colors/indigo';
import blue from '@material-ui/core/colors/blue';
import lightBlue from '@material-ui/core/colors/lightBlue';
import cyan from '@material-ui/core/colors/cyan';
import teal from '@material-ui/core/colors/teal';
import green from '@material-ui/core/colors/green';
import lightGreen from '@material-ui/core/colors/lightGreen';
import lime from '@material-ui/core/colors/lime';
import yellow from '@material-ui/core/colors/yellow';
import amber from '@material-ui/core/colors/amber';
import orange from '@material-ui/core/colors/orange';
import deepOrange from '@material-ui/core/colors/deepOrange';
import brown from '@material-ui/core/colors/brown';
import grey from '@material-ui/core/colors/grey';
import blueGrey from '@material-ui/core/colors/blueGrey';

import { getRandomIndexFromArray } from '@/utils';

const range = [400, 500, 600, 700, 800, 900];
const colors = [
	green,
	blue,
	deepOrange,
	indigo,
	blueGrey,
	deepPurple,
	red,
	lightGreen,
	lightBlue,
	teal,
	orange,
	grey,
	pink,
	amber,
	cyan,
	brown,
	lime,
	yellow,
	purple,
];

const chartSentimentColorSerie = {
	positive: '#0B5D1E',
	neutral: '#FF6B35',
	negative: '#DA2C38',
};

const chartColorSeries = {
	'#880E4F': ['#880E4F', '#942661', '#A03E72', '#AC5684', '#B86E95', '#C487A7'],
	'#322649': ['#322649', '#382A52', '#51446B', '#65597C', '#786D8C', '#8B829D'],
	'#01579B': ['#01579B', '#1A68A5', '#3479AF', '#4D89B9', '#679AC3', '#80ABCD'],
	'#00BCD4': ['#00BCD4', '#33C9DD', '#4DD0E1', '#66D7E5', '#80DEEA', '#99E4EE'],
	'#00838F': ['#00838F', '#1A8F9A', '#339CA5', '#4DA8B1', '#66B5BC', '#80C1C7'],
	'#80CBC4': ['#80CBC4', '#99D5D0', '#B3E0DC', '#CCEAE7', '#D9EFED', '#F2FAF9'],
	'#FFAB00': ['#FFAB00', '#FFBC33', '#FFC44D', '#FFCD66', '#FFDD99', '#FFE6B3'],
	'#D84315': ['#D84315', '#DC562C', '#E06944', '#E47B5B', '#E88E73', '#ECA18A'],
	'#806D40': ['#806D40', '#8D7C53', '#998A66', '#A69979', '#B3A78C', '#C0B6A0'],
	'#40531B': ['#40531B', '#536432', '#667549', '#79875F', '#8C9876', '#A0A98D'],
	'#5C6784': ['#5C6784', '#6C7690', '#7D859D', '#8D95A9', '#9DA4B5', '#AEB3C2'],
};

export function getDefaultChartColors() {
	return colors.map(color => color[500]);
}

export function getRandomColor() {
	let colorRange = colors[getRandomIndexFromArray(colors)];
	return colorRange[range[getRandomIndexFromArray(range)]];
	//return colors[getRandomIndexFromArray(colors)][getRandomIndexFromArray(range)];
}

export function getSentimentColors(sentiment) {
	const rounded = Math.round(sentiment);

	if (sentiment > 90) {
		return green[500];
	} else if (sentiment > 80) {
		return green[400];
	} else if (sentiment > 70) {
		return lightGreen[500];
	} else if (sentiment > 60) {
		return lime[500];
	} else if (sentiment > 50) {
		return yellow[500];
	} else if (sentiment > 40) {
		return amber[500];
	} else if (sentiment > 30) {
		return orange[500];
	} else if (sentiment > 20) {
		return deepOrange[500];
	} else {
		return red[500];
	}
}

export function getDirectInsightColors() {
	return Object.keys(chartColorSeries);
}

export function getChartSentimentColor(sentiment) {
	return chartSentimentColorSerie[sentiment];
}

export function getDirectInsightSubColors(mainColor) {
	if (!mainColor) {
		const colorRange = getDirectInsightColors();
		return chartColorSeries[colorRange[Math.floor(Math.random() * colorRange.length)]];
	}
	return chartColorSeries[mainColor];
}
