import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles, getSentimentColors } from '@/styles';
import { DrawerWithAppBar } from '@/components/App';
import { Loader, Chip, Box } from '@/components/Layout';
import { FeedbackList } from '@/components/Feedback';
import TextAnalyticsContext from './TextAnalyticsContext';
import { useOfficeDrones } from '@/components/Ajax';

import { permissionSubjects, HasPermissionWithEmptyState } from '@/components/Permission';

const useStyles = makeStyles(theme => ({
	drawer: {
		maxWidth: 600,
	},
	buttonIcon: {
		marginRight: theme.spacing(1),
	},
	noWrapChip: {
		maxWidth: '100%',
		margin: theme.spacing(0.5, 0.25),
	},
	noWrapChipLabel: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: 'block',
	},
	msi: {
		fontWeight: 600,
	},
}));

function TextAnalyticsFeedbackQuickViewDrawer({
	requestPath,
	word,
	cluster_id,
	limit = 30,
	date,
	MSI,
	...props
}) {
	const classes = useStyles();

	function highLightWords(comment = '', msi) {
		if (!word) return comment;

		return comment.split(new RegExp(word, 'i')).reduce((prev, current, i) => {
			if (!i) {
				return [<span>{current}</span>];
			}
			return prev.concat(
				<span
					className={classes.msi}
					style={{ color: getSentimentColors(msi) }}
				>
					{word}
				</span>,
				current
			);
		}, []);
	}

	const { t } = useTranslation();
	const {
		textAnalytics: { project_id, groups },
	} = useContext(TextAnalyticsContext);

	const request = {
		...(word && !groups && { word }),
		...(cluster_id && groups && { group: cluster_id }),
		...(date && { date }),
		...(MSI && { MSI }),
		limit,
	};

	const [{ ids = [], merge = {} }, loading] = useOfficeDrones(
		requestPath,
		{
			project_id,
			redis: 1,
			...request,
		},
		{
			wait: !props.open,
			responseParser: r => {
				const items = requestPath === '/tasks' ? r.tasks : r.items;

				return Array.isArray(items)
					? items.reduce(
							(all, item) => {
								all.ids.push(String(item.uid));
								all.merge[String(item.uid)] = {
									comment: highLightWords(item.content, item.MSI),
									feedback_id: item.uid,
									MSI: Math.round(item.MSI),
								};
								return all;
							},
							{ ids: [], merge: {} }
					  )
					: { ids: [], merge: {} };
			},
		}
	);

	return (
		<DrawerWithAppBar
			ToolbarProps={{
				text: t`reporting-text_analytics-quickview-title`,
			}}
			{...props}
		>
			<HasPermissionWithEmptyState
				permission="view"
				subject={permissionSubjects.feedback_inbox}
			>
				<Box>
					{word && !groups && (
						<Chip
							label={t('reporting-text_analytics-quickview-chip_word', { word: word })}
							classes={{ label: classes.noWrapChipLabel }}
							className={classes.noWrapChip}
						/>
					)}
					{word && Boolean(groups) && (
						<Chip
							label={t('reporting-text_analytics-quickview-chip_group', { group: word })}
							classes={{ label: classes.noWrapChipLabel }}
							className={classes.noWrapChip}
						/>
					)}
					{MSI && (
						<Chip
							label={t('reporting-text_analytics-quickview-chip_sentiment', {
								sentiment: MSI,
							})}
							classes={{ label: classes.noWrapChipLabel }}
							className={classes.noWrapChip}
						/>
					)}
					{date && (
						<Chip
							label={t('reporting-text_analytics-quickview-chip_date', { date: date })}
							classes={{ label: classes.noWrapChipLabel }}
							className={classes.noWrapChip}
						/>
					)}
				</Box>

				{loading && <Loader empty />}

				{!loading && (
					<FeedbackList
						noFilters
						noControls
						noPersist
						inDrawer
						feedbackIds={ids}
						mergeFeedback={merge}
					/>
				)}
			</HasPermissionWithEmptyState>
		</DrawerWithAppBar>
	);
}

export default TextAnalyticsFeedbackQuickViewDrawer;
