import React from 'react';

import { useEFM } from '@/components/Ajax';
import { useDebounce } from '@/hooks';

export default function useCheckPassword({ password }) {
	const debouncedPassword = useDebounce(password, 300);
	return useEFM(
		'/account/ajax/check-password',
		{ password: debouncedPassword },
		debouncedPassword.length < 3,
		false,
		false,
		{ disableCache: true }
	);
}
