import React, { useEffect, useReducer, useRef } from 'react';

import { rnd } from '@/utils';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { EFM } from '@/api';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppContext } from '@/components/AppContext';
import { useDataSourceContext } from '@/components/DataSources';

import useSaveOnboarding from './useSaveOnboarding';
import useAddDemoFeedback from './useAddDemoFeedback';
import useReducerWithMiddleWare from '@/hooks/useReducerWithMiddleWare';

function reducer(state, action) {
	switch (action.type) {
		case 'set_initial_trial_data':
			return {
				...state,
				channel: action.payload.channel,
				initial_user_onboarding_step: action.payload.initial_user_onboarding_step,
				trialStep: action.payload.trialStep,
				url: action.payload.url,
				trialInitialized: true,
				isReturningOnboarding: action.payload.initial_user_onboarding_step > 0,
			};

		case 'set_signup_step':
			return {
				...state,
				initial_user_onboarding_step: action.payload.step,
			};

		case 'set_trial_step':
			return {
				...state,
				trialMenuOpen: action.payload.trialMenuOpen ?? false,
				trialStep: action.payload.step ?? state.trialStep,
				url: action.payload.url ?? state.url,
				from: action.payload.from ?? null,
			};

		case 'set_general_onboarding_step':
			return {
				...state,
				showTour: action.payload.step,
			};

		case 'set_new_user_tour_step':
			return {
				...state,
				newUserTour: action.payload.step,
			};

		case 'start_onboarding_trial':
			return {
				...state,
				initial_user_onboarding_step: action.payload.onboarding_step,
				channel: action.payload.channel,
				trialStep: action.payload.trial_step,
				url: action.payload.trial_url,
			};

		case 'use_onboarding_template':
			return {
				...state,
				useTemplate: action.payload,
				trialMenuOpen: action.payload,
			};

		case 'update_tour':
			return {
				...state,
				update: rnd(),
			};

		case 'toggle_trial_menu':
			return {
				...state,
				trialMenuOpen: action.payload.open ?? false,
				isReturningTrial: action.payload.isReturningTrial ?? false,
				...(action.payload.messageShown && { messageShown: action.payload.messageShown }),
			};

		case 'set_id_for_demodata':
			return {
				...state,
				surveyId: action.payload.surveyId,
			};

		default:
			return state;
	}
}

const initialState = {
	initial_user_onboarding_step: '',
	isReturningOnboarding: false,
	channel: '',
	trialStep: -1,
	trialMenuOpen: false,
	isReturningTrial: false,
	messageShown: false,
	url: '',
	useTemplate: false,
	trialInitialized: false,
	deployed: false,
	showTour: '',
	newUserTour: '',
	surveyId: null,
	from: null,
};

export default function useOnboarding() {
	const { app } = useAppContext();
	const { datasource } = useDataSourceContext();
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const { enqueueSnackbar } = useSnackbar();

	const surveyHasBlocks = Object.keys(datasource.surveyfields).length > 0;

	async function getOnboardingData() {
		const response = await EFM.post('/application/ajax/get-onboarding');
		if (response.code === 200) {
			dispatch({ type: 'set_initial_trial_data', payload: response.data });
		} else {
			enqueueSnackbar(t`onboarding-getting_started-load_onboarding_error`);
		}
	}

	const { postForm: saveOnboarding } = useSaveOnboarding({
		onSuccess: response => {
			if (response.code === 200) {
				app.api.updateSessionData();
			}
		},
	});

	const handleSave = action => {
		if (action.type === 'set_trial_step') {
			saveOnboarding({
				post: {
					...(action.payload.step && { trial_step: action.payload.step }),
					...(action.payload.url && { trial_url: action.payload.url }),
				},
			});
		}
	};

	const [onboardingState, dispatch] = useReducerWithMiddleWare(reducer, initialState, {
		afterware: [handleSave],
	});

	const { postForm: addDemoFeedback } = useAddDemoFeedback({
		data: {
			channel: onboardingState.channel,
		},
		onSuccess: response => {
			if (response.code !== 200) {
				enqueueSnackbar(t`onboarding-getting_started-create_demodata_error`);
			}
		},
	});

	useEffect(() => {
		if (app.auth.loggedIn && app.package.trial && !onboardingState.trialInitialized) {
			getOnboardingData();
		}
	}, [app.auth.loggedIn, app.package.trial, onboardingState.trialInitialized]);

	//open the trial dialog on the following pages:
	useEffect(() => {
		if (app.session.onboarding_trial) {
			if (
				//deployment editor, survey list page, deployemnt list page
				[
					'/data-collection/survey/list',
					'/data-collection/deployment/list',
					'/reporting/inbox',
				].includes(location.pathname) ||
				location.pathname.includes('/data-collection/deployment/edit') ||
				onboardingState.trialStep === 5
			) {
				dispatch({
					type: 'toggle_trial_menu',
					payload: {
						open: true,
					},
				});
			}
		}
	}, [
		app.session.onboarding_trial,
		location.pathname,
		onboardingState.messageShown,
		surveyHasBlocks,
		onboardingState.trialStep,
	]);

	//force route to current onboarding route
	useEffect(() => {
		if (app.session.onboarding_trial && onboardingState.url) {
			history.push(onboardingState.url);
		}
	}, [app.session.onboarding_trial, onboardingState.url, history]);

	//add demo data
	useEffect(() => {
		if (onboardingState.surveyId) {
			addDemoFeedback({ post: { surveyId: onboardingState.surveyId } });
		}
	}, [onboardingState.surveyId]);

	//if templates somehow fail to load, continue trial with empty form instead
	useEffect(() => {
		if (onboardingState.useTemplate) {
			const pendingTemplate = setTimeout(() => {
				if (onboardingState.useTemplate) {
					enqueueSnackbar(t`onboarding-getting_started-use_survey_template_error`);
					dispatch({
						type: 'toggle_trial_menu',
						payload: {
							open: false,
						},
					});
				}
			}, 5000);
			return () => clearTimeout(pendingTemplate);
		}
	}, [onboardingState.useTemplate]);

	return {
		...onboardingState,
		dispatch,
	};
}
