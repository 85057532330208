import React from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import { AppBarLeftContent, PageHeader } from '@/components/App';
import { Box, Chip } from '@/components/Layout';
import { PermissionGroupList } from '@/components/Permission';
import UserList from './UserList';
import { useAppContext } from '@/components/AppContext';
import { CheckIcon } from '@/components/Icons';

export default function UserManagement() {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const { tab = 'list' } = useParams();
	const history = useHistory();

	return (
		<>
			<PageHeader
				documentTitle={t`settings-user_management-page_header-title`}
				title={
					<Box
						display="flex"
						alignItems="center"
					>
						<Box mr={2}>{t`settings-user_management-page_header-title`}</Box>
						{app.auth.options?.includes('sso') && (
							<Chip
								size="small"
								label={t`settings-user_management-page_header-sso_enabled`}
								color="secondary"
								icon={<CheckIcon />}
							/>
						)}
					</Box>
				}
				prominent
			/>
			<AppBarLeftContent tabs>
				<Tabs
					value={tab}
					onChange={(e, value) => {
						history.push(`/application/users/${value}`);
					}}
				>
					<Tab
						value="list"
						label={t`settings-user_management-tabs-list`}
					/>
					<Tab
						value="access"
						label={t`settings-user_management-tabs-access`}
					/>
				</Tabs>
			</AppBarLeftContent>
			{tab === 'list' && <UserList />}
			{tab === 'access' && <PermissionGroupList />}
		</>
	);
}
