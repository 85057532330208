export const products = ['web', 'email', 'app'];

export const subProducts = {
	web: ['web_domains', 'web_pageviews'],
	email: ['email_brands', 'email_emails_sent'],
	app: ['app_apps', 'app_sessions'],
};

export const enterpriseServices = [
	'sso',
	'custom_domain',
	'sla',
	'white_label',
	'personal_guidance',
];

export const registerDomainProducts = ['web_domains', 'email_brands', 'app_apps'];

export const perMonthProducts = ['web_pageviews', 'email_emails_sent', 'app_sessions'];

export const nudgeEnterprisePoints = {
	annual: 12000,
	monthly: 1250,
	web_pageviews: 10000000,
	email_emails_sent: 500000,
	app_sessions: 3500000,
};

export const forceEnterprisePoints = {
	annual: 18000,
	monthly: 1875,
};
