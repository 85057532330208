import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { useImmer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import InputAdornment from '@material-ui/core/InputAdornment';

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import { ValidatedField } from '../Validation';
import {
	Typography,
	Link,
	Grid,
	Alert,
	Loader,
	ActionButton,
	ActionIconButton,
} from '../Layout';
import { useEFM, useAjaxForm } from '../Ajax';
import { useAppContext } from '../AppContext';

import { useParams, useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	inputPanel: {
		maxWidth: 500,
	},
}));

export default function ForgotPassword(props) {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const {
		app,
		app: { auth },
	} = useAppContext();

	const { t } = useTranslation();
	const history = useHistory();

	const { token } = useParams();

	const [state, setState] = useImmer({
		password: '',
		confirmPassword: '',
		passwordValid: false,
		confirmPasswordValid: false,
		clicked: false,
	});

	const [checkPassword, checkPasswordLoading] = useEFM(
		'/account/ajax/check-password',
		{ password: state.password },
		state.password.length < 3,
		false,
		false,
		{ disableCache: true }
	);

	const { postForm, loading } = useAjaxForm({
		url: '/auth/ajax/reset-login',
		data: {
			passwordToken: token,
			new_password: state.password,
			conf_password: state.confirmPassword,
		},
		valid: () => checkPassword.code == 200 && state.confirmPasswordValid,
		onSuccess: r => {
			if (r.code === 200) {
				document.location.href = '/auth/ajax/logout?reset=true';
			}
		},
		onError: () => {
			enqueueSnackbar(`errormessage_general_something_wrong`);
		},
	});

	useEffect(() => {
		if (!token) {
			history.push('/login');
		}
	}, []);

	return (
		<div className={classes.inputPanel}>
			<form>
				<Grid
					container
					direction="row"
					spacing={3}
				>
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="h4"
							gutterBottom
						>
							{t`password_reset_title`}
						</Typography>
					</Grid>

					{state.emailRequested && (
						<Grid
							item
							xs={12}
						>
							<Alert
								onClose={() =>
									setState(draft => {
										draft.emailRequested = false;
									})
								}
							>
								{t`password_response`}
							</Alert>
						</Grid>
					)}

					<Grid
						item
						xs={12}
					>
						<PasswordInput
							variant="outlined"
							value={state.password}
							onChange={e => {
								const { value } = e.currentTarget;
								setState(draft => {
									draft.password = value;
								});
							}}
							label={t`password_new_password`}
							showErrors={!state.passwordValid && state.clicked}
							rules={[
								['min', 12],
								['max', 36],
								['custom', () => checkPassword.code !== 400],
							]}
							errorMessage={
								checkPassword.code != 200 && !checkPasswordLoading
									? t(checkPassword.message)
									: ''
							}
							success={checkPassword.code == 200 ? true : false}
							error={checkPassword.code != 200 && state.clicked}
							loading={checkPasswordLoading}
						/>
					</Grid>

					<Grid
						item
						xs={12}
					>
						<PasswordInput
							variant="outlined"
							value={state.confirmPassword}
							onChange={e => {
								const { value } = e.currentTarget;
								setState(draft => {
									draft.confirmPassword = value;
								});
							}}
							label={t`password_confirm_password`}
							showErrors={!state.confirmPasswordValid && state.clicked}
							rules={[['custom', () => state.confirmPassword === state.password]]}
							errorMessage={t`errormessage_password_mismatch`}
							success={state.confirmPassword === state.password && state.clicked}
							error={state.confirmPassword !== state.password && state.clicked}
							onValidChange={e => {
								setState(draft => {
									draft.confirmPasswordValid = e;
								});
							}}
						/>
					</Grid>

					<Grid
						item
						xs={12}
					>
						<ActionButton
							fullWidth
							size="large"
							variant="contained"
							color="primary"
							onClick={() => {
								setState(draft => {
									draft.clicked = true;
								});
								postForm();
							}}
							onKeyPress={e => {
								if (e.key === 'Enter') {
									setState(draft => {
										draft.clicked = true;
									});
									postForm();
								}
							}}
							loading={loading}
						>
							{t`password_reset_password`}
						</ActionButton>
					</Grid>

					<Grid
						item
						xs={12}
					>
						<Tooltip
							title={t`password_login_tooltip`}
							placement="bottom"
						>
							<Link
								variant="body2"
								to="/login"
							>
								{t`password_login`}
							</Link>
						</Tooltip>
					</Grid>
				</Grid>
			</form>
		</div>
	);
}

function PasswordInput(props) {
	const [show, setShow] = useState(false);
	const { t } = useTranslation();

	return (
		<ValidatedField
			fullWidth
			type={show ? 'text' : 'password'}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<ActionIconButton
							onClick={() => setShow(show => !show)}
							action={!show ? 'view' : 'hide'}
							tooltip={
								!show
									? t`iconbutton_general_show_password`
									: t`iconbutton_general_hide_password`
							}
						/>
						{props.loading ? (
							<Loader
								circular
								size={16}
							/>
						) : props.success ? (
							<CheckIcon color="secondary" />
						) : props.error ? (
							<CloseIcon color="error" />
						) : null}
					</InputAdornment>
				),
			}}
			{...props}
		/>
	);
}
