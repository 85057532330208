import React, { useMemo } from 'react';

import { useBackendBauer } from '../Ajax';
import { useDataSourceContext } from '../DataSources';
import { format } from '../../utils/date';

export default function useNumberOfCompletes(surveyId, notInViewport) {
	const { datasource } = useDataSourceContext();

	const completedFieldId = useMemo(() => {
		const fieldArr = datasource.api.getFieldArray(surveyId);

		const field = fieldArr.find(field => field.import_var.toLowerCase() === 'role');
		return field ? field.id : fieldArr.length === 0 ? null : 'not_found';
	}, [surveyId, datasource.api.getFieldArray(surveyId).length]);

	const [data, loading] = useBackendBauer({
		series: [
			{
				custom: {
					survey_id: surveyId,
				},
				x: 0,
				y: completedFieldId,
				avg: 16,
			},
		],
		customFromDate: '2010-1-1',
		customToDate: format(new Date(), 'yyyy-MM-dd'),
		wait: completedFieldId === null || notInViewport,
	});

	return [data.n ?? 0, loading !== 0];
}
