"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function matchQueries(rules) {
    var entries = [];
    for (var _i = 0, _a = Object.keys(rules); _i < _a.length; _i++) {
        var className = _a[_i];
        var rule = rules[className];
        entries.push({
            minWidth: rule.minWidth != null ? rule.minWidth : 0,
            maxWidth: rule.maxWidth != null ? rule.maxWidth : Infinity,
            minHeight: rule.minHeight != null ? rule.minHeight : 0,
            maxHeight: rule.maxHeight != null ? rule.maxHeight : Infinity,
            className: className,
        });
    }
    return function (_a) {
        var height = _a.height, width = _a.width;
        var classNameMap = {};
        for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
            var _b = entries_1[_i], className = _b.className, minWidth = _b.minWidth, maxWidth = _b.maxWidth, minHeight = _b.minHeight, maxHeight = _b.maxHeight;
            if (height != null && width != null) {
                classNameMap[className] = (minWidth <= width && width <= maxWidth &&
                    minHeight <= height && height <= maxHeight);
            }
            else if (height == null && width != null) {
                classNameMap[className] = minWidth <= width && width <= maxWidth;
            }
            else if (height != null && width == null) {
                classNameMap[className] = minHeight <= height && height <= maxHeight;
            }
            else {
                classNameMap[className] = true;
            }
        }
        return classNameMap;
    };
}
exports.default = matchQueries;
