import React, { memo } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { IntroductionDialog } from '@/components/Onboarding';
import { Typography, KnowledgeBaseLink, Box } from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	introImage: {
		maxWidth: '90%',
		alignSelf: 'center',
	},
}));

export default memo(function PermissionGroupIntroductionDialog() {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<IntroductionDialog
			seenKey="seen_access_groups_opener"
			title={t`settings-access_groups-introduction-title`}
			cancel={t`settings-access_groups-introduction-close`}
			content={
				<Box
					display="flex"
					flexDirection="column"
				>
					<Trans
						i18nKey="settings-access_groups-introduction-content"
						components={{
							p1: <Typography mb={2} />,
							p2: <Typography mb={3} />,
							strong: (
								<Typography
									fontWeight="medium"
									display="inline"
								/>
							),
							img: (
								<img
									className={classes.introImage}
									src="/assets/img/introduction/access_group_introduction.svg"
								/>
							),
							p3: <Typography mt={3} />,
							kbLink: (
								<KnowledgeBaseLink
									variant="body1"
									page="access_groups"
								/>
							),
						}}
					/>
				</Box>
			}
		/>
	);
});
