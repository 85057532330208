import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import { useTranslation } from 'react-i18next';

import { Typography, Paper } from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	arrow: {
		margin: theme.spacing(0.5),
	},
	center: {
		textAlign: 'center',
	},
	paper: {
		padding: theme.spacing(1),
		margin: theme.spacing(2),
		position: 'relative',
	},
	'@keyframes bounceDown': {
		'0%': {
			transform: 'translate(0px, 0px)',
		},
		'10%': {
			transform: 'translate(0px, 6px)',
		},
		'20%': {
			transform: 'translate(0px, 1px)',
		},
		'30%': {
			transform: 'translate(0px, 3px)',
		},
		'40%': {
			transform: 'translate(0px, 0px)',
		},
	},
	'@keyframes bounceUp': {
		'0%': {
			transform: 'translate(0px, 0px)',
		},
		'10%': {
			transform: 'translate(0px, -6px)',
		},
		'20%': {
			transform: 'translate(0px, -1px)',
		},
		'30%': {
			transform: 'translate(0px, -3px)',
		},
		'40%': {
			transform: 'translate(0px, 0px)',
		},
	},
	'@keyframes bounceRight': {
		'0%': {
			transform: 'translate(0px, 0px)',
		},
		'10%': {
			transform: 'translate(6px, 0px)',
		},
		'20%': {
			transform: 'translate(1px, 0px)',
		},
		'30%': {
			transform: 'translate(3px, 0px)',
		},
		'40%': {
			transform: 'translate(0px, 0px)',
		},
	},
	'@keyframes bounceLeft': {
		'0%': {
			transform: 'translate(0px, 0px)',
		},
		'10%': {
			transform: 'translate(-6px, 0px)',
		},
		'20%': {
			transform: 'translate(-1px, 0px)',
		},
		'30%': {
			transform: 'translate(-3px, 0px)',
		},
		'40%': {
			transform: 'translate(0px, 0px)',
		},
	},
	bounce: {
		animationName: props =>
			props.placement === 'left' ? '$shakeHorizontal' : '$shakeVertical',
		animationDuration: '1.5s',
		animationIterationCount: 'infinite',
		animationTimingFunction: 'linear',
		transformOrigin: '50% 100%',
	},
	bounceDown: {
		animationName: '$bounceDown',
	},
	bounceUp: {
		animationName: '$bounceUp',
	},
	bounceRight: {
		animationName: '$bounceRight',
	},
	bounceLeft: {
		animationName: '$bounceLeft',
	},
	pointer: {
		position: 'absolute',
		height: 10,
		width: 10,
		background: '#ffffff',
		transform: 'rotate(45deg)',
		borderRadius: 2,
		top: props =>
			props.placement === 'bottom'
				? -5
				: props.placement === 'top'
				? 'auto'
				: 'calc(50% - 5px)',
		bottom: props =>
			props.placement === 'top'
				? -5
				: props.placement === 'bottom'
				? 'auto'
				: 'calc(50% - 5px)',
		left: props =>
			props.placement === 'right'
				? -5
				: props.placement === 'left'
				? 'auto'
				: 'calc(50% - 5px)',
		right: props =>
			props.placement === 'left'
				? -5
				: props.placement === 'right'
				? 'auto'
				: 'calc(50% - 5px)',
		border: '1px solid',
		borderBottomColor: props =>
			props.placement === 'top' || props.placement === 'right' ? '#ddd' : '#fff',
		borderLeftColor: props =>
			props.placement === 'bottom' || props.placement === 'right' ? '#ddd' : '#fff',
		borderRightColor: props =>
			props.placement === 'top' || props.placement === 'left' ? '#ddd' : '#fff',
		borderTopColor: props =>
			props.placement === 'bottom' || props.placement === 'left' ? '#ddd' : '#fff',
	},
}));

export default function AttentionArrow({ placement = 'bottom', anchor, text, ...props }) {
	const classes = useStyles({ placement });
	const [anchorEl, setAnchorEl] = useState(null);
	const { t } = useTranslation();

	useEffect(() => {
		setAnchorEl(anchor);
	}, [anchor]);

	const open = Boolean(anchorEl);

	return (
		<Popper
			open={open}
			anchorEl={anchorEl}
			placement={placement}
			transition
		>
			<Paper
				className={`${classes.paper} 
				${classes.bounce} 
				${
					placement === 'bottom'
						? classes.bounceDown
						: placement === 'top'
						? classes.bounceUp
						: placement === 'left'
						? classes.bounceLeft
						: classes.bounceRight
				}`}
			>
				<Typography variant="caption">
					{text ? text : t`app_general-clickhelper-text`}
				</Typography>
				<div className={classes.pointer}></div>
			</Paper>
		</Popper>
	);
}
