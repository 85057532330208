import React, { useState, useContext } from 'react';

import { createStore, useStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { Mjolnir, EFM } from '@/api';
import { useAppContext } from '@/components/AppContext';
import { debounce } from '@/utils';

import { ruleBase, conditionBase, parseDeploymentResponse } from './deploymentUtils';

const DeploymentStoreContext = React.createContext(null);

const createDeploymentStore = ({
	domain,
	organisationId,
	deploymentId,
	userUuid,
	editorType,
}) => {
	return createStore(
		immer(
			(set, get) => ({
				loading: false,
				surveysLoading: false,
				current: {
					name: '',
					description: '',
					rules: [],
				},
				draft: {
					name: '',
					description: '',
					rules: [],
				},
				versions: [],
				workingDraft: {
					name: '',
					description: '',
					rules: [],
				},
				surveysByKey: {},
				surveysByProject: [],
				actions: {
					setVersion(version) {
						set(state => {
							if (typeof version === 'number') {
								const _version = state.versions.find(
									prevVersion => prevVersion.revision === version
								);
								if (_version?.deployment) {
									state.workingDraft = _version.deployment;
								}
								return;
							}

							state.workingDraft = state[version];
						});
					},

					//deployment editing actions
					handleDraftChange(fn) {
						const handleChange = () => {
							set(state => {
								state.pendingDraftChanges = true;
								state.workingDraft.revision = 'draft';
								state._pendingChange = null;
							});
							fn();
							get().actions.saveDraft(() => {
								set(state => {
									state.draft = state.workingDraft;
								});
							});
						};
						if (get().workingDraft.revision === 'draft' || !get().draft?.rules?.length) {
							handleChange();
						} else {
							set(state => {
								state._pendingChange = handleChange;
							});
						}
					},

					acceptChange() {
						get()._pendingChange();
					},

					rejectChange() {
						set(state => {
							state._pendingChange = null;
						});
					},

					setDescription(value) {
						get().actions.handleDraftChange(() => {
							set(state => {
								state.workingDraft.description = value;
							});
						});
					},

					addRule({ destinationIndex, rule = {} }) {
						get().actions.handleDraftChange(() => {
							const newRuleDestinationIndex =
								typeof destinationIndex === 'number'
									? destinationIndex
									: get().workingDraft.rules.length;
							const newRule = ruleBase({ ...rule });

							set(state => {
								state.workingDraft.rules.splice(newRuleDestinationIndex, 0, newRule);
							});
						});
					},

					moveRule({ destinationIndex, sourceIndex }) {
						get().actions.handleDraftChange(() => {
							set(state => {
								state.workingDraft.rules.splice(
									destinationIndex,
									0,
									state.workingDraft.rules.splice(sourceIndex, 1)[0]
								);
							});
						});
					},

					deleteRule({ index }) {
						get().actions.handleDraftChange(() => {
							set(state => {
								state.workingDraft.rules.splice(index, 1);
							});
						});
					},

					setConditionOption({ ruleIndex, conditionIndex, condition, value }) {
						get().actions.handleDraftChange(() => {
							set(state => {
								state.workingDraft.rules[ruleIndex].if[conditionIndex][condition] = value;
							});
						});
					},

					setNestedConditionOption({
						ruleIndex,
						conditionIndex,
						condition,
						nested,
						nested2,
						nested3,
						value,
					}) {
						get().actions.handleDraftChange(() => {
							set(state => {
								if (typeof nested3 !== 'undefined') {
									state.workingDraft.rules[ruleIndex].if[conditionIndex][condition][
										nested
									][nested2][nested3] = value;
									return;
								}

								if (typeof nested2 !== 'undefined') {
									state.workingDraft.rules[ruleIndex].if[conditionIndex][condition][
										nested
									][nested2] = value;
									return;
								}
								state.workingDraft.rules[ruleIndex].if[conditionIndex][condition][
									nested
								] = value;
							});
						});
					},

					setRenderDiv({ ruleIndex, value }) {
						get().actions.handleDraftChange(() => {
							set(state => {
								state.workingDraft.rules[ruleIndex].then[0].args[2] = value;
							});
						});
					},

					setRenderOption({ ruleIndex, option, value }) {
						get().actions.handleDraftChange(() => {
							set(state => {
								try {
									state.workingDraft.rules[ruleIndex].then[0][option] = value;
								} catch (e) {}
							});
						});
					},

					setMobileConditionOption({
						ruleIndex,
						conditionIndex,
						condition,
						nested,
						value,
					}) {
						get().actions.handleDraftChange(() => {
							set(state => {
								if (typeof nested !== 'undefined') {
									state.workingDraft.rules[ruleIndex].if[conditionIndex].mobile[
										condition
									][nested] = value;
									return;
								}
								state.workingDraft.rules[ruleIndex].if[conditionIndex].mobile[condition] =
									value;
							});
						});
					},

					setNestedMobileConditionOption({
						ruleIndex,
						conditionIndex,
						condition,
						nested,
						nested2,
						nested3,
						value,
					}) {
						get().actions.handleDraftChange(() => {
							set(state => {
								if (typeof nested3 !== 'undefined') {
									state.workingDraft.rules[ruleIndex].if[conditionIndex].mobile[
										condition
									][nested][nested2][nested3] = value;
									return;
								}
								if (typeof nested2 !== 'undefined') {
									state.workingDraft.rules[ruleIndex].if[conditionIndex].mobile[
										condition
									][nested][nested2] = value;
									return;
								}
								state.workingDraft.rules[ruleIndex].if[conditionIndex].mobile[condition][
									nested
								] = value;
							});
						});
					},

					addToConditionArray({
						ruleIndex,
						conditionIndex,
						condition,
						conditionOrIndex,
						value,
					}) {
						get().actions.handleDraftChange(() => {
							set(state => {
								if (typeof conditionOrIndex !== 'undefined') {
									state.workingDraft.rules[ruleIndex].if[conditionIndex][condition][
										conditionOrIndex
									].push(value);
									return;
								}

								if (
									!Array.isArray(
										state.workingDraft.rules[ruleIndex].if[conditionIndex][condition]
									)
								) {
									state.workingDraft.rules[ruleIndex].if[conditionIndex][condition] = [];
								}
								state.workingDraft.rules[ruleIndex].if[conditionIndex][condition].push(
									value
								);
							});
						});
					},

					removeFromConditionArray({
						ruleIndex,
						conditionIndex,
						condition,
						index,
						orIndex,
					}) {
						get().actions.handleDraftChange(() => {
							set(state => {
								if (typeof orIndex !== 'undefined') {
									state.workingDraft.rules[ruleIndex].if[conditionIndex][condition][
										orIndex
									].splice(index, 1);
									return;
								}
								state.workingDraft.rules[ruleIndex].if[conditionIndex][condition].splice(
									index,
									1
								);
							});
						});
					},

					addCondition({ ruleIndex }) {
						get().actions.handleDraftChange(() => {
							set(state => {
								state.workingDraft.rules[ruleIndex].if.push(conditionBase(editorType));
							});
						});
					},

					removeCondition({ ruleIndex, index }) {
						get().actions.handleDraftChange(() => {
							set(state => {
								state.workingDraft.rules[ruleIndex].if.splice(index, 1);
							});
						});
					},

					setName(value) {
						get().actions.handleDraftChange(() => {
							set(state => {
								state.workingDraft.name = value;
							});
						});
					},

					setPublished(published, save) {
						get().actions.handleDraftChange(() => {
							set(state => {
								state.workingDraft.published = Boolean(published);
							});
							if (save) {
								get().actions.saveDeployment();
							}
						});
					},
					//end deployment editing actions

					async getSurveys() {
						set(state => {
							state.surveysLoading = true;
						});
						try {
							const response = await EFM.post(
								'/survey/ajax/get-surveys-from-organisation'
							);
							if (response.code === 200) {
								set(state => {
									state.surveysByProject = response.surveys;
									state.surveysByKey = response.surveys.reduce(
										(objectBySurveyKey, currentProject) => {
											[
												...currentProject.web_surveys,
												...currentProject.sdk_surveys,
											].forEach(survey => {
												objectBySurveyKey[survey.survey_key] = survey;
											});

											return objectBySurveyKey;
										},
										{}
									);
								});
							}
						} catch (e) {}
						set(state => {
							state.surveysLoading = false;
						});
					},
					async getCurrent() {
						set(state => {
							state.loading = true;
						});
						try {
							const response = await Mjolnir.get(
								`/api/1/pastease/${domain}/${organisationId}/${deploymentId}`
							);
							if (Number(response.code) === 200) {
								set(state => {
									const deployment = parseDeploymentResponse(response?.deployment);
									state.current = deployment;
									state.workingDraft = deployment;
								});
							}
						} catch (e) {}
						set(state => {
							state.loading = false;
						});
					},
					async getDraft() {
						try {
							const response = await Mjolnir.get(
								`/api/1/pastease/${domain}/${organisationId}/${deploymentId}/draft`
							);
							set(state => {
								state.draft = response?.draft?.deployment ?? {};
								state.draft.revision = 'draft';
							});
						} catch (e) {}
					},
					async getVersions() {
						try {
							const response = await Mjolnir.get(
								`/api/1/pastease/${domain}/${organisationId}/${deploymentId}/versions`
							);

							set(state => {
								state.versions = response.versions;
							});
						} catch (e) {}
					},
					saveDraft: debounce(async (onSuccess = () => {}) => {
						if (get().workingDraft.revision !== 'draft') {
							return;
						}

						try {
							set(state => {
								state.saveDraftLoading = true;
							});
							await Mjolnir.post(
								`/api/1/pastease/${domain}/${organisationId}/${deploymentId}/draft`,
								{
									org_id: organisationId,
									type: get().workingDraft.type,
									user_uuid: userUuid,

									name: get().workingDraft.name,
									rules: get().workingDraft.rules,
									published: get().workingDraft.published,
								}
							);
							onSuccess();
							set(state => {
								state.pendingDraftChanges = false;
								state.draft.rules = state.workingDraft.rules;
								state.draft.name = state.workingDraft.name;
								state.draft.published = state.workingDraft.published;
								state.workingDraft.lastSaved = new Date();
							});
						} catch (e) {}
						set(state => {
							state.saveDraftLoading = false;
						});
					}, 5000),
					async saveDeployment(onSuccess = () => {}) {
						set(state => {
							state.saveDeploymentLoading = true;
							state.workingDraft.revision = 'saved_draft';
						});
						try {
							const response = await Mjolnir.post(
								`/api/1/pastease/${domain}/${organisationId}/${deploymentId}`,
								{
									org_id: organisationId,
									type: get().workingDraft.type,
									permission_groups: get().workingDraft.permission_groups,
									user_uuid: userUuid,

									name: get().workingDraft.name,
									rules: get().workingDraft.rules,
									published: get().workingDraft.published,
									description: get().workingDraft.description,
								}
							);
							get().actions.getCurrent();
							get().actions.getDraft();
							get().actions.getVersions();
							onSuccess(response);
						} catch (e) {
							set(state => {
								state.workingDraft.revision = 'draft';
							});
						}
						set(state => {
							state.saveDeploymentLoading = false;
						});
					},
				},
			}),
			{ name: 'deployment-store' }
		)
	);
};

export function DeploymentStoreProvider({ children, deploymentId, editorType }) {
	const { app } = useAppContext();
	const [store] = useState(() =>
		createDeploymentStore({
			domain: app.domain,
			organisationId: Number(app.organisations.current.org_id),
			userUuid: app.users.current.uuid,
			deploymentId,
			editorType,
		})
	);

	return (
		<DeploymentStoreContext.Provider value={store}>
			{children}
		</DeploymentStoreContext.Provider>
	);
}

export function useDeploymentStore(selector) {
	const store = useContext(DeploymentStoreContext);
	if (!store) {
		throw new Error('Missing DeploymentStoreProvider');
	}
	return useStore(store, selector);
}
