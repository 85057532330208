import React, { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
//import ChatBot from 'react-simple-chatbot';
import { AppContext } from '../AppContext';
import { useImmer } from 'use-immer';
import Message from './Message';
import { TransitionGroup } from 'react-transition-group';
import Grow from '@material-ui/core/Grow';
import { UserAvatar, UserName } from '../Users';

const useStyles = makeStyles(theme => ({
	chips: {
		margin: theme.spacing(0.25),
	},
}));

function Conversation({
	conversation = [],
	leftMessageProps = {},
	rightMessageProps = {},
}) {
	const [show, setShow] = useImmer([]);
	const [selections, setSelections] = useImmer({});
	const classes = useStyles();
	const { app } = useContext(AppContext);

	function showItem(id) {
		setShow(draft => {
			draft.push(id);
		});
	}

	function hideItem(id) {
		setShow(draft => {
			draft.splice(draft.indexOf(id), 1);
		});
	}

	useEffect(() => {
		function getShow() {
			conversation.forEach((part, index) => {
				// if (part.trigger) {
				// 	setShow(draft => {
				// 		draft.push(part.trigger);
				// 	})
				// }

				if (part.start) {
					setShow(draft => {
						if (draft.indexOf(part.id) === -1) {
							draft.push(part.id);
						}
					});
				}
			});
		}
		getShow();
	}, [conversation]);

	const messages = conversation.filter(message => show.some(id => id == message.id));
	return (
		<TransitionGroup>
			{messages.map((message, messageIndex) => {
				const messageProps = {
					key: message.id,
					index: messageIndex,
					useTyping: message.user === 'MoBot' && messageIndex + 1 === messages.length,
					...(message.typingTimeout && { typingTimeout: message.typingTimeout }),
					...message,
					...(!message.name && message.user === 'self' && { name: <UserName /> }),
					...(!message.avatar &&
						message.user === 'self' && { avatar: <UserAvatar current /> }),
					allMessages: [...messages],
					showBubble:
						(message.type === 'buttons' &&
							typeof selections[message.id] !== 'undefined') ||
						message.type !== 'buttons',
					...(message.statusMessage
						? {
								statusMessage: message.statusMessage,
						  }
						: {
								isRight:
									message.user === 'self' ||
									String(app.users.current.id) === String(message.user),
						  }),
					onEntered: () => {
						if (message.trigger) {
							showItem(message.trigger);
						}
					},
				};

				switch (message.type) {
					case 'message':
						return <Message {...messageProps}>{message.message}</Message>;

					case 'buttons':
						return (
							<Message {...messageProps}>
								<>
									{typeof selections[message.id] !== 'undefined' ? (
										<Grow
											in
											timeout={100}
										>
											<span>
												{message.buttons[selections[message.id]].selectedLabel ||
													message.buttons[selections[message.id]].label}
											</span>
										</Grow>
									) : (
										message.buttons.map((button, index) => {
											const chipProps = {
												onClick: e => {
													if (button.trigger) {
														setShow(draft => {
															draft.push(button.trigger);
														});
													}
													if (typeof button.effect === 'function') {
														button.effect();
													}

													setSelections(draft => {
														draft[message.id] = index;
													});
												},
												label: button.label,
												className: classes.chips,
											};
											return <Chip {...chipProps} />;
										})
									)}
								</>
							</Message>
						);

					default:
						return null;
				}
			})}
		</TransitionGroup>
	);
}

export default Conversation;
