import React from 'react';
import DataSourceContext from './DataSourceContext';

const withDataSources = Component => props =>
	(
		<DataSourceContext.Consumer>
			{value => (
				<Component
					{...props}
					{...value}
				/>
			)}
		</DataSourceContext.Consumer>
	);

export default withDataSources;
