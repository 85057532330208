import React, { useState, forwardRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '../AppContext';
import { RenderConditional } from '../Layout';
import { useHasPermission, permissionTypes } from '../Permission';
import { menuItems } from './constants';

const useStyles = makeStyles(theme => ({
	paddedNavListOpen: {
		padding: theme.spacing(1),
	},
	paddedNavListClosed: {
		padding: theme.spacing(1),
	},
	closedMenuPaper: {
		backgroundColor: theme.palette.mopinion.main,
		color: '#fff',
		marginLeft: theme.spacing(1),
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
	},
	parentIcon: {
		color: 'inherit',
	},
	parentIconClosed: {
		minWidth: 0,
	},
	topLevelItem: {
		margin: theme.spacing(0.5, 0),
	},
	collapseList: {
		marginBottom: theme.spacing(0.5),
	},
	listItemOpen: {
		borderRadius: theme.shape.borderRadius,
	},
	listItemClosed: {
		justifyContent: 'center',
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		borderRadius: theme.shape.borderRadius,
		margin: theme.spacing(0.5, 0),
		textAlign: 'center',
	},
	listItemHover: {
		'&:hover': {
			backgroundColor: theme.palette.darkMode.action.hover,
		},
	},
	listItemSelected: {
		backgroundColor: `${theme.palette.darkMode.action.selected}!important`,
	},
	noViewPermission: {
		color: theme.palette.darkMode.text.hint,
	},
}));

function NavItem({
	menuExpanded,
	subItems = [],
	permissionModule,
	icon,
	title,
	basePath,
	...props
}) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [anchor, setAnchor] = useState(null);

	const closeMenu = e => {
		e.stopPropagation();
		setAnchor(null);
	};

	return (
		<>
			<ParentNavItem
				onClick={e => {
					if (menuExpanded) {
						setOpen(open => !open);
					} else {
						setAnchor(e.currentTarget);
					}
				}}
				basePath={basePath}
				open={menuExpanded ? open : Boolean(anchor)}
				icon={icon}
				title={title}
				menuExpanded={menuExpanded}
				showToggle={menuExpanded && subItems.length > 0}
				data-test-element="parent-navitem"
			/>

			{menuExpanded && subItems.length > 0 && (
				<Collapse
					in={open}
					timeout="auto"
				>
					<List
						component="div"
						disablePadding
						onClick={e => e.stopPropagation()}
						className={classes.collapseList}
					>
						{subItems.map(subItem => (
							<SubNavItem
								permissionModule={permissionModule}
								key={subItem.title}
								title={subItem.title}
								path={subItem.path}
								permissionSubject={subItem.permissionSubject}
							/>
						))}
					</List>
					<Divider light />
				</Collapse>
			)}

			{!menuExpanded && subItems.length > 0 && (
				<Menu
					open={Boolean(anchor)}
					anchorEl={anchor}
					onClose={e => {
						e.stopPropagation();
						setAnchor(null);
					}}
					classes={{
						paper: classes.closedMenuPaper,
					}}
					PaperProps={{
						elevation: 2,
					}}
					getContentAnchorEl={null}
					anchorOrigin={{
						vertical: 'center',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'center',
						horizontal: 'left',
					}}
				>
					{subItems.map(subItem => (
						<SubNavMenuItem
							permissionModule={permissionModule}
							key={subItem.title}
							title={subItem.title}
							path={subItem.path}
							permissionSubject={subItem.permissionSubject}
							onClick={closeMenu}
						/>
					))}
				</Menu>
			)}
		</>
	);
}

function ParentNavItem({
	onClick,
	icon,
	title,
	basePath,
	open,
	menuExpanded,
	showToggle,
}) {
	const { t } = useTranslation();
	const classes = useStyles();
	const location = useLocation();
	const {
		app: { session },
	} = useAppContext();

	return (
		<ListItem
			className={`${classes.topLevelItem} ${
				menuExpanded ? classes.listItemOpen : classes.listItemClosed
			}`}
			disabled={session.onboarding_trial}
			button
			classes={{
				root: classes.listItemHover,
				selected: classes.listItemSelected,
			}}
			onClick={onClick}
			selected={location.pathname.includes(basePath) || (!menuExpanded && open)}
			data-test-element="parent-navitem"
			data-base-path={basePath}
			data-collapse={open ? 'open' : 'closed'}
		>
			<ListItemIcon
				className={`${classes.parentIcon} ${
					menuExpanded ? '' : classes.parentIconClosed
				}`}
			>
				{icon}
			</ListItemIcon>
			{menuExpanded && (
				<ListItemText
					primary={t(title)}
					primaryTypographyProps={{
						variant: open ? 'subtitle2' : 'body2',
					}}
				/>
			)}

			{!menuExpanded && (
				<RenderConditional
					component={Tooltip}
					title={t(title)}
					placement="right"
					condition={!open}
				>
					<Box
						position="absolute"
						top={0}
						bottom={0}
						left={0}
						right={0}
					/>
				</RenderConditional>
			)}

			{showToggle && <>{open ? <ExpandLess /> : <ExpandMore />}</>}
		</ListItem>
	);
}

const SubNavItem = forwardRef(function SubNavItem(
	{ title, path, permissionSubject, permissionModule, menuExpanded },
	ref
) {
	const { t } = useTranslation();
	const classes = useStyles();
	const location = useLocation();
	const hasViewPermission = useHasPermission({
		permission: permissionTypes.view,
		module: permissionModule,
		subject: permissionSubject,
	});

	return (
		<ListItem
			data-test-element="subnav-item"
			button
			key={title}
			component={Link}
			to={path}
			selected={location.pathname.includes(path)}
			className={`${classes.listItemOpen} ${
				permissionSubject && !hasViewPermission ? classes.noViewPermission : ''
			}`}
			classes={{
				selected: classes.listItemSelected,
				root: classes.listItemHover,
			}}
			ref={ref}
		>
			<ListItemText
				primary={t(title)}
				primaryTypographyProps={{ variant: 'body2' }}
			/>
		</ListItem>
	);
});

const SubNavMenuItem = forwardRef(function SubNavMenuItem(
	{ title, path, permissionSubject, permissionModule, onClick },
	ref
) {
	const { t } = useTranslation();
	const location = useLocation();
	const classes = useStyles();
	const hasViewPermission = useHasPermission({
		permission: permissionTypes.view,
		module: permissionModule,
		subject: permissionSubject,
	});

	return (
		<MenuItem
			ref={ref}
			key={title}
			component={Link}
			to={path}
			onClick={onClick}
			className={!hasViewPermission && permissionSubject ? classes.noViewPermission : ''}
			classes={{
				root: classes.listItemHover,
				selected: classes.listItemSelected,
			}}
			selected={location.pathname.includes(path)}
		>
			{t(title)}
		</MenuItem>
	);
});

export default function MenuItems({ menuExpanded, ...props }) {
	const classes = useStyles();
	return (
		<List
			className={menuExpanded ? classes.paddedNavListOpen : classes.paddedNavListClosed}
		>
			{menuItems.map(item => (
				<NavItem
					key={item.title}
					basePath={item.basePath}
					title={item.title}
					icon={item.icon}
					subItems={item.subItems}
					menuExpanded={menuExpanded}
					permissionModule={item.permissionModule}
				/>
			))}
		</List>
	);
}
