import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import { SimpleDialog, Alert } from '../Layout';
import { useAjaxForm } from '../Ajax';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../AppContext';

import { useHistory } from 'react-router-dom';

export default function DeleteAccountDialog({ open, onClose, ...props }) {
	const { t } = useTranslation();
	const [cancelValue, setCancelValue] = useState('');
	const history = useHistory();
	const { app } = useAppContext();

	const { postForm, loading } = useAjaxForm({
		url: '/account/cancel',
		onSuccess: r => {
			if (r.cancelled) {
				document.location.href = '/auth/ajax/logout?cancelled=true';
			}
		},
	});
	return (
		<SimpleDialog
			open={open}
			onClose={onClose}
			title={t`Cancel account`}
			submit={t`Cancel my account`}
			disabled={cancelValue !== t`Cancel my account`}
			onSubmit={postForm}
			loading={loading}
			dataTrackEvent="account_billing_cancelled"
		>
			<Box mb={1}>
				<Alert
					severity="warning"
					title={t`Caution`}
				>{t`This action cannot be undone and we won't provide you with a refund`}</Alert>
			</Box>

			<Typography
				gutterBottom
			>{t`You are about to cancel your Mopinion account. We will delete your account, along with all other user accounts associated with your organisation.`}</Typography>

			<TextField
				label={t`Type "Cancel my account" here if you wish to continue`}
				value={cancelValue}
				onChange={e => setCancelValue(e.currentTarget.value)}
				fullWidth
			/>
		</SimpleDialog>
	);
}
