import React from 'react';

import { useEFM } from '@/components/Ajax';

export default function useGetProjectRefreshExpiration(surveyId) {
	return useEFM(
		'/api/allow-od-project-refresh',
		{ surveyId: surveyId },
		false,
		false,
		false,
		{ disableCache: true }
	);
}
