import React, { useContext, memo, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Tooltip from '@material-ui/core/Tooltip';

import ListFilterContext from './ListFilterContext';
import { DataSourceContext } from '../DataSources';

import {
	DataFieldAutoComplete,
	PossibleValueAutoComplete,
	PossibleValueSelect,
	TagsAutoComplete,
} from '../AutoComplete';
import { useTranslation } from 'react-i18next';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import { varTypesUsingContains } from './constants';

const styles = theme => ({
	row: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	card: {
		border: `1px solid ${theme.palette.divider}`,
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		flexShrink: 0,
	},
	operatorWrap: {
		textAlign: 'center',
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		whiteSpace: 'nowrap',
	},
	gridContainerWidth: {
		maxWidth: 'calc(100% - 44px)',
	},
	gridWidth: {
		maxWidth: '100%',
		width: '100%',
	},
});

function CustomFilter({
	identifier,
	value,
	type,
	index,
	classes,
	surveyId,
	DataFieldAutoCompleteProps = {},
	PossibleValueProps = {},
	PossibleValueSelectProps = {},
	PossibleValueAutoCompleteProps = {},
	TagsAutoCompleteProps = {},
	forceUseSurvey,
	onDelete,
	filtersFromProps,
	...props
}) {
	const { t } = useTranslation();
	const { setCustomFilter, customFilters = [] } = useContext(ListFilterContext);
	const { datasource } = useContext(DataSourceContext);
	const datafield = datasource.api.getField(identifier);

	const handleDelete =
		typeof onDelete === 'function'
			? onDelete
			: e => setCustomFilter({ action: 'remove', index: index });

	const useContains = varTypesUsingContains.indexOf(String(datafield.var_type)) > -1;
	const hasAbsoluteValues =
		(!useContains && datafield.block_id) ||
		(!useContains && ['12', '16', '24', '27'].indexOf(String(datafield.var_type)) > -1);

	//filtersFromProps are passed from the exportDialog filters (page 2)
	const whichFilters = filtersFromProps ?? customFilters;

	//surveyId is not saved in rules, so we need to fetch it based on identifier
	//surveyId can also actually be integrationId or datasetId
	const filterWithIdentifiers = whichFilters
		.filter(f => f.identifier)
		.map(filter => filter.identifier);
	const useSurveyId = filterWithIdentifiers.length >= 1;

	const survey = useSurveyId
		? datasource.api.getField(whichFilters.find(f => f.identifier).identifier, true)
				.source
		: '';
	const foundSurveyId =
		forceUseSurvey && surveyId ? surveyId : useSurveyId ? survey.id : '';

	const DataFieldAutoCompletePropsExtended = {
		...((useSurveyId || forceUseSurvey) && { fromSurveyId: foundSurveyId }),
		value: identifier,
		placeholder: t('Select a field'),
		onChange: e =>
			setCustomFilter({
				action: 'update',
				index: index,
				custom: { identifier: e.value, value: [], surveyId: e.surveyId },
			}),
		noWrap: true,
		...DataFieldAutoCompleteProps,
	};

	const PossibleValueSelectPropsExtended = {
		datafieldId: identifier,
		value: value,
		onChange: e =>
			setCustomFilter({
				action: 'update',
				index: index,
				custom: { value: e, operator: 'e' },
			}),
		returnKey: 'value',
		...PossibleValueProps,
		...PossibleValueSelectProps,
	};

	const PossibleValueAutoCompletePropsExtended = {
		placeholder: t`Select a value`,
		datafieldId: identifier,
		value: value,
		onChange: e =>
			setCustomFilter({
				action: 'update',
				index: index,
				custom: { value: e, operator: useContains ? 'contains' : 'e' },
			}),
		...PossibleValueProps,
		...PossibleValueAutoCompleteProps,
	};

	return (
		<Card
			elevation={0}
			className={classes.card}
		>
			<CardContent>
				<Grid
					direction="row"
					wrap="nowrap"
					container
				>
					<Grid
						item
						xs
						className={classes.gridContainerWidth}
					>
						<Grid
							container
							direction="column"
							spacing={1}
						>
							<Grid
								item
								xs
								className={classes.gridWidth}
							>
								<DataFieldAutoComplete {...DataFieldAutoCompletePropsExtended} />
							</Grid>
							<Grid
								item
								xs
								className={classes.operatorWrap}
							>
								<Typography
									variant="subtitle2"
									color="textSecondary"
								>
									{useContains ? t('contains') : t('is exactly')}
								</Typography>
							</Grid>
							<Grid
								item
								xs
								className={classes.gridWidth}
							>
								{identifier === 'tags' ? (
									<TagsAutoComplete
										skipMax
										{...TagsAutoCompleteProps}
									/>
								) : hasAbsoluteValues ? (
									<PossibleValueSelect {...PossibleValueSelectPropsExtended} />
								) : (
									<PossibleValueAutoComplete
										{...PossibleValueAutoCompletePropsExtended}
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<IconButton onClick={handleDelete}>
							<Tooltip title={t`Remove filter`}>
								<RemoveIcon fontSize="small" />
							</Tooltip>
						</IconButton>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

export default withStyles(styles)(memo(CustomFilter));
