import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ChatIcon from '@material-ui/icons/Chat';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useEFM } from '@/components/Ajax';
import CustomerCard from './CustomerCard';
import { PageHeader } from '@/components/App';
import { Loader, EmptyState } from '@/components/Layout';
import { FeedbackList } from '@/components/Feedback';

const useStyles = makeStyles(theme => ({
	leftColumnFromExp: {
		borderRight: '1px solid ' + theme.palette.divider,
		'& > *:not(:last-child)': {
			borderBottom: '1px solid ' + theme.palette.divider,
		},
	},
	rightColumnFromExp: {
		overflow: 'hidden',
		'& > *:not(:last-child)': {
			borderBottom: '1px solid ' + theme.palette.divider,
		},
	},
	leftColumn: {
		'& > *:not(:last-child)': {
			marginBottom: theme.spacing(3),
		},
	},
	rightColumn: {
		'& > *:not(:last-child)': {
			marginBottom: theme.spacing(3),
		},
	},
	emptyIcon: {
		color: theme.palette.text.secondary,
		fontSize: theme.typography.pxToRem(64),
	},
	emptyState: {
		minHeight: '50vh',
	},
}));

export default function CustomerDetail(props) {
	const { customerId, pageId } = useParams();
	const [data, loading, error, loadData] = useEFM(
		`/dashboard/customers/detail/${customerId}${
			pageId ? `/feedbacks/page/${pageId}` : ''
		}`
	);
	const classes = useStyles();
	const { t } = useTranslation();
	const [state, setState] = useState({
		prevExpanded: null,
		expanded: null,
	});

	function toggleExpand(id) {
		setState(prev => ({
			prevExpanded: prev.expanded,
			expanded: id,
		}));
	}

	return (
		<>
			<PageHeader
				title={`${t`Customer`} ${data.customer?.full_name ?? ''}`}
				onBack={history => history.goBack()}
			/>
			{loading ? (
				<Loader empty />
			) : (
				<Grid
					container
					spacing={3}
				>
					<Grid
						item
						md={4}
						sm={12}
					>
						<CustomerCard
							avatarSize="large"
							customer={data.customer}
						/>
					</Grid>
					<Grid
						item
						md={8}
						sm={12}
						container
						direction="column"
					>
						<Typography variant="h6">{t`Feedback by customer`}</Typography>
						{data.feedback &&
						data.feedback.filter(fb => fb.feedback_active == 1).length === 0 ? (
							<Grid item>
								<EmptyState
									image={
										<ChatIcon
											size="large"
											className={classes.emptyIcon}
										/>
									}
									primary={t`reporting-customers-emptystate_detail-title`}
									secondary={t`reporting-customers-emptystate_detail-text`}
									className={classes.emptyState}
								/>
							</Grid>
						) : (
							<FeedbackList
								feedback={
									data.feedback ? data.feedback.filter(fb => fb.feedback_active == 1) : []
								}
								loadFeedback={loadData}
								noControls
							/>
						)}
					</Grid>
				</Grid>
			)}
		</>
	);
}
