import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	'@keyframes blink': {
		'50%': {
			opacity: 1,
		},
	},
	indicator: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'noWrap',
		'& > span': {
			width: theme.spacing(1),
			height: theme.spacing(1),
			backgroundColor: theme.palette.action.active,
			borderRadius: '100%',
			display: 'inline-flex',
			opacity: 0.4,
			animationName: '$blink',
			animationDuration: '1s',
			animationIterationCount: 'infinite',
			margin: theme.spacing(0.25),
			'&:nth-of-type(1)': {
				animationDelay: 0.5,
			},
			'&:nth-of-type(2)': {
				animationDelay: 1,
			},
			'&:nth-of-type(3)': {
				animationDelay: 1.5,
			},
		},
	},
}));

function Typing() {
	const classes = useStyles();
	return (
		<div className={classes.indicator}>
			<span />
			<span />
			<span />
		</div>
	);
}

export default Typing;
