import React, { Fragment, useState, useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	ListSubheader,
} from '@/components/Layout';

import MuiLink from '@material-ui/core/Link';

import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useTranslation } from 'react-i18next';

import { DataFieldIcon } from '../DataSources';
import { RenderConditional } from '../Layout';
import { anonymizedSurveyElementOriginalBlockName, sortFeedback } from './feedbackUtils';

const useStyles = makeStyles(theme => ({
	breakWord: {
		wordBreak: 'break-word',
	},
	selectedHTML: {
		whiteSpace: 'normal',
		overflow: 'hidden',
		overflowY: 'auto',
		maxHeight: 140,
	},
	bold: {
		fontWeight: 'bold',
	},
	expand: {
		verticalAlign: 'middle',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	collapse: {
		fontWeight: theme.typography.fontWeightMedium,
		cursor: 'pointer',
	},
	sourceLang: {
		fontStyle: 'italic',
		color: theme.palette.text.primary,
	},
	marginBottom: {
		marginBottom: `${theme.spacing(1.5)}px`,
	},
}));

function Link({ value }) {
	const classes = useStyles();
	return (
		<MuiLink
			href={value}
			target="_blank"
			rel="noopener"
			className={classes.breakWord}
		>
			{value}
		</MuiLink>
	);
}

function Value({ value = '' }) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();

	const collapse = value?.length > 500;

	return (
		<Fragment>
			<RenderConditional
				condition={collapse}
				component={Collapse}
				collapsedHeight={100}
				in={open}
			>
				<span className={classes.breakWord}>{value}</span>
			</RenderConditional>
			{collapse && (
				<MuiLink
					color="textSecondary"
					variant="caption"
					onClick={() => setOpen(prev => !prev)}
					className={classes.collapse}
				>
					{open ? t`Show less` : t`Show more`}
					<ExpandMoreIcon
						fontSize="small"
						className={`${classes.expand} ${open ? classes.expandOpen : ''}`}
					/>
				</MuiLink>
			)}
		</Fragment>
	);
}

function Code({ value }) {
	const classes = useStyles();
	return <pre className={classes.selectedHTML}>{value}</pre>;
}

function Matrix({ matrix }) {
	const { value, weight } = matrix;

	return (
		<Fragment>
			<div>
				<Value value={value} />
			</div>
			<div>
				<Value value={weight} />
			</div>
		</Fragment>
	);
}

function Wrap({ value = '', var_type, question_type, label = '' }) {
	if (label.match(/contentsquare session/gi)) {
		return <Link value={value} />;
	}

	switch (question_type) {
		case 'website_data':
			if (String(value).startsWith('http')) {
				return <Link value={value} />;
			} else {
				return <Value value={value} />;
			}

		case 'matrix':
			return <Matrix matrix={value} />;
	}

	switch (Number(var_type)) {
		case 40:
			return <Link value={value} />;
		case 42:
			return <Code value={value} />;
		default:
			return <Value value={value} />;
	}
}

function Source({ block, source, translatedFeedback }) {
	const { t } = useTranslation();
	const classes = useStyles();

	const [expanded, setExpanded] = useState(false);
	const translatedTo =
		translatedFeedback && JSON.parse(translatedFeedback)?.name
			? JSON.parse(translatedFeedback).name
			: false;

	return (
		<Fragment>
			<Typography className={classes.marginBottom}>{block.label}</Typography>

			<Collapse
				in={expanded}
				timeout="auto"
				unmountOnExit
			>
				<Typography
					className={`${classes.sourceLang} ${classes.marginBottom}`}
					variant="body2"
				>
					{<Wrap {...source} />}
				</Typography>
			</Collapse>

			<Typography variant="caption">
				{translatedTo ? (
					<Fragment>
						{t`This feedback is translated to `}{' '}
						<span className={classes.bold}>{translatedTo}.</span>
					</Fragment>
				) : (
					t`This feedback is translated.`
				)}
			</Typography>

			<Typography
				display="block"
				variant="caption"
				className={classes.collapse}
				onClick={() => setExpanded(!expanded)}
			>
				{expanded ? t`Hide source` : t`View source`}
				<ExpandMoreIcon
					fontSize="small"
					className={`${classes.expand} ${expanded ? classes.expandOpen : ''}`}
				/>
			</Typography>
		</Fragment>
	);
}

export default function FeedbackSummary({
	feedback_details = [],
	websiteData = [],
	dataFieldOrder = [],
	translatedFeedback = false,
}) {
	const { t } = useTranslation();

	const sortedFeedback = sortFeedback(dataFieldOrder, feedback_details);
	return (
		<>
			<List dense>
				<ListSubheader color="inherit">
					{t`reporting-feedback_inbox-feedback_summary_title`}
				</ListSubheader>
				{sortedFeedback.map(block => {
					const forceName = anonymizedSurveyElementOriginalBlockName(block);
					const parentBlock = block.parent_id
						? Object.values(feedback_details).find(
								detailItem => detailItem.data_field == block.parent_id
						  )
						: null;
					return parentBlock ? (
						<ListItem
							alignItems="flex-start"
							key={`details_${block.id}`}
						>
							<ListItemIcon>
								<DataFieldIcon
									forceIcon="translateIcon"
									{...block}
								/>
							</ListItemIcon>
							<ListItemText
								primary={<Wrap {...block} />}
								secondaryTypographyProps={{ component: 'span' }}
								secondary={
									<Source
										block={block}
										source={parentBlock}
										translatedFeedback={translatedFeedback}
									/>
								}
							/>
						</ListItem>
					) : block.var_type !== 48 ? (
						<ListItem
							alignItems="flex-start"
							key={`details_${block.id}`}
						>
							<ListItemIcon>
								<DataFieldIcon
									blockName={forceName}
									{...block}
								/>
							</ListItemIcon>
							<ListItemText
								primary={<Wrap {...block} />}
								secondary={block.label}
							/>
						</ListItem>
					) : null;
				})}
			</List>
			{websiteData.length > 0 && (
				<>
					<List dense>
						{websiteData.map(block => {
							return (
								<ListItem
									alignItems="flex-start"
									key={`details_${block.id}`}
								>
									<ListItemIcon>
										<DataFieldIcon
											blockName={block.question_type}
											{...block}
										/>
									</ListItemIcon>
									<ListItemText
										primary={<Wrap {...block} />}
										secondary={block.label}
									/>
								</ListItem>
							);
						})}
					</List>
				</>
			)}
		</>
	);
}
