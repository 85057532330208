import React, { useState, useEffect } from 'react';

import useOnboardingContext from './useOnboardingContext';
import {
	MenuTour,
	DeploymentEditorTour,
	SurveyEditorTour,
	FeedbackInboxTour,
	DashboardTour,
	DashboardLayoutTour,
} from './GeneralTours';

export default function HelpMenuTour(props) {
	const onboarding = useOnboardingContext();
	const [start, setStart] = useState(false);

	useEffect(() => {
		if (onboarding.showTour) {
			setStart(true);
		}
	}, [onboarding.showTour]);

	const TourProps = {
		open: start,
		update: onboarding.update,
		rounded: 4,
		updateDelay: 400,
		disableOverlay: true,
		updateFn: () => {
			onboarding.dispatch({
				type: 'update_tour',
				payload: true,
			});
		},
		onClose: () => {
			setStart(false);
			onboarding.dispatch({
				type: 'set_general_onboarding_step',
				payload: {
					step: '',
				},
			});
		},
		key: onboarding.showTour,
		previewPosition: onboarding.previewPosition,
	};

	switch (onboarding.showTour) {
		case 'menu':
			return <MenuTour {...TourProps} />;

		case 'survey_editor':
			return <SurveyEditorTour {...TourProps} />;

		case 'deployment_editor':
			return <DeploymentEditorTour {...TourProps} />;

		case 'feedback_inbox':
			return <FeedbackInboxTour {...TourProps} />;

		case 'dashboard':
			return <DashboardTour {...TourProps} />;

		case 'dashboard_layout':
			return <DashboardLayoutTour {...TourProps} />;

		default:
			return null;
	}
}
