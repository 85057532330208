import React, { useState, Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';

import { useTranslation } from 'react-i18next';
import get from 'lodash.get';

import { useAppContext } from '../AppContext';
import { DataSourceCheckboxList } from '../DataSources';
import { DataSourceSelect } from '../Select';
import { FilterGroup } from '../FilteredLists';
import ExportDatePicker from './ExportDatePicker';
import { formatMonthNumber } from './exportUtils';

const useStyles = makeStyles(theme => ({
	fillWidth: {
		width: '100%',
	},
	padding: {
		padding: theme.spacing(1),
	},
	paddingRight: {
		paddingRight: theme.spacing(1),
	},
	removePaddingLeft: {
		paddingLeft: 0,
	},
	removePaddingBottom: {
		paddingBottom: 0,
	},
	marginLeft: {
		marginLeft: theme.spacing(1),
	},
	picker: {
		marginTop: theme.spacing(1),
	},
	italic: {
		formStyle: 'italic',
	},
	hideDefault: {
		display: 'none',
	},
	helper: {
		paddingLeft: theme.spacing(1),
		paddingBottom: theme.spacing(3),
	},
}));

function prepareFieldArray(array) {
	const excludedImportVars = ['ALL DATA', 'Role', 'Survey', 'Tags', 'Customer ID'];
	if (!Array.isArray(array)) {
		return [];
	}

	return array
		.filter(field => excludedImportVars.includes(field.import_var) === false)
		.map(field => field.id);
}

export default function FeedbackExportFilters({
	exportConfig,
	setExportConfig,
	setSingleFilter = () => {},
	dataSource,
	templates = [],
}) {
	const { t } = useTranslation();
	const {
		app,
		app: { date },
	} = useAppContext();
	const { forms, surveyfields } = dataSource;
	const {
		other: { survey_id },
	} = exportConfig;

	const currentLanguage = app.language ?? '2';

	const [dateAnchor, setDateAnchor] = useState(null);

	const classes = useStyles();

	const { other } = exportConfig;

	const allDataFields = !Object.keys(surveyfields).length
		? []
		: survey_id
		? prepareFieldArray(surveyfields[survey_id]?.asArray)
		: Object.values(forms.asArray).flatMap(form => {
				return surveyfields[form.id]
					? prepareFieldArray(surveyfields[form.id]?.asArray)
					: [];
		  });

	function updateDatafields(key, value, add) {
		if (add) {
			setExportConfig(draft => {
				get(draft, key, []).push(value);
			});
		} else {
			const newArray = value ? exportConfig[key].filter(item => item !== value) : [];
			setExportConfig(draft => {
				draft[key] = newArray;
			});
		}
	}

	function selectAll(activeOnly) {
		if (activeOnly) {
			const activeDatafields = survey_id
				? surveyfields[survey_id].asArray
						.filter(field => field.active && field.id !== 0)
						.map(field => field.id)
				: Object.values(forms.asArray).flatMap(form => {
						return surveyfields[form.id].asArray
							.filter(field => field.active)
							.map(field => field.id);
				  });

			setExportConfig(draft => {
				draft.datafield_ids = activeDatafields;
			});
		} else {
			setExportConfig(draft => {
				draft.datafield_ids = allDataFields;
			});
		}
	}

	function deSelectAll() {
		setExportConfig(draft => {
			draft.datafield_ids = [];
		});
	}

	function setScheduledDate(value, interval) {
		if (interval === 'time') {
			setExportConfig(draft => {
				draft.interval_time = value;
			});
		} else if (interval === 'day') {
			setExportConfig(draft => {
				draft.interval_day = value;
			});
		} else {
			setExportConfig(draft => {
				draft.interval_period = value;
				draft.interval_day = value === 'Week' ? 'MON' : value === 'Month' ? 1 : '-';
				draft.interval_time = ['Day', 'Week', 'Month'].includes(value) ? '06:00' : '-';
			});
		}
	}

	return (
		<Grid
			container
			spacing={3}
		>
			{exportConfig.variant === 'single' && !exportConfig.maxSetupsReached && (
				<Grid
					item
					xs={12}
				>
					<Typography
						variant="body1"
						color="textSecondary"
						gutterBottom
					>{t`Select a date range or schedule the export`}</Typography>
					<FormControl component="fieldset">
						<RadioGroup
							aria-label="template_acces"
							name="template-acces"
							value={exportConfig.make_schedule ? 'schedule' : 'single'}
							onChange={e => {
								setExportConfig(draft => {
									draft.make_schedule = e.target.value === 'schedule' ? true : false;
									draft.save_as_template =
										e.target.value === 'schedule' ? false : draft.save_as_template;
									draft.run_immediately = true;
								});
							}}
						>
							<FormControlLabel
								value="single"
								control={<Radio />}
								label={t`Select a date range`}
							/>
							<FormControlLabel
								value="schedule"
								control={<Radio />}
								label={t`Make a schedule`}
							/>
						</RadioGroup>
					</FormControl>
				</Grid>
			)}
			{!exportConfig.use_template &&
				(exportConfig.variant === 'scheduled' || exportConfig.make_schedule) && (
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="body1"
							color="textSecondary"
							gutterBottom
						>{t`Export schedule`}</Typography>
						<Grid
							container
							spacing={2}
						>
							<Grid
								item
								xs={6}
							>
								<TextField
									id="scheduled-daterange"
									select
									fullWidth
									label={t`Schedule`}
									value={exportConfig.interval_period}
									onChange={e => setScheduledDate(e.target.value)}
								>
									<MenuItem value={'Day'}>{t`Daily`}</MenuItem>
									<MenuItem value={'Week'}>{t`Weekly`}</MenuItem>
									<MenuItem value={'Month'}>{t`Monthly`}</MenuItem>
									{/* <MenuItem value={'Quarter'}>{t`Quarterly`}</MenuItem>
								<MenuItem value={'Year'}>{t`Yearly`}</MenuItem> */}
								</TextField>
							</Grid>

							<Grid
								item
								xs={3}
							>
								{exportConfig.interval_period === 'Week' && (
									<TextField
										id="scheduled-weekday"
										select
										fullWidth
										label={t`On `}
										value={exportConfig.interval_day}
										onChange={e => setScheduledDate(e.target.value, 'day')}
									>
										<MenuItem value={'MON'}>{t`Monday`}</MenuItem>
										<MenuItem value={'TUE'}>{t`Tuesday`}</MenuItem>
										<MenuItem value={'WED'}>{t`Wednesday`}</MenuItem>
										<MenuItem value={'THU'}>{t`Thursday`}</MenuItem>
										<MenuItem value={'FRI'}>{t`Friday`}</MenuItem>
										<MenuItem value={'SAT'}>{t`Saturday`}</MenuItem>
										<MenuItem value={'SUN'}>{t`Sunday`}</MenuItem>
									</TextField>
								)}
								{exportConfig.interval_period === 'Month' && (
									<TextField
										id="scheduled-month"
										select
										fullWidth
										label={t`On `}
										value={exportConfig.interval_day}
										onChange={e => setScheduledDate(e.target.value, 'day')}
									>
										{[...Array(29).keys()].slice(1).map((day, index) => (
											<MenuItem
												value={day}
												key={index}
											>
												{formatMonthNumber(day, currentLanguage)}
											</MenuItem>
										))}
										<MenuItem value={'L'}>{t`Last day`}</MenuItem>
									</TextField>
								)}
								{exportConfig.interval_period === 'Day' && (
									<TextField
										id="scheduled-disabled"
										select
										disabled
										fullWidth
										label={t`On `}
										value={'-'}
									>
										<MenuItem value={'-'}>-</MenuItem>
									</TextField>
								)}
							</Grid>
							<Grid
								item
								xs={3}
							>
								{['Day', 'Week', 'Month'].includes(exportConfig.interval_period) ? (
									<TextField
										id="scheduled-month"
										select
										fullWidth
										label={t`At`}
										value={exportConfig.interval_time}
										onChange={e => setScheduledDate(e.target.value, 'time')}
									>
										{[...Array(24).keys()].map(time => (
											<MenuItem value={time < 10 ? `0${time}:00` : `${time}:00`}>
												{time < 10 ? `0${time}:00` : `${time}:00`}
											</MenuItem>
										))}
									</TextField>
								) : (
									<TextField
										id="scheduled-disabled"
										select
										disabled
										fullWidth
										label={t`At`}
										value={'-'}
									>
										<MenuItem value={'-'}>-</MenuItem>
									</TextField>
								)}
							</Grid>
							{exportConfig.interval_period === 'Day' ? (
								<Typography
									variant="caption"
									color="textSecondary"
									className={classes.helper}
								>
									{t`The scheduled export will contain the feedback data from the past 24 hours`}
								</Typography>
							) : exportConfig.interval_period === 'Week' ? (
								<Typography
									variant="caption"
									color="textSecondary"
									className={classes.helper}
								>
									{t`The scheduled export will contain the feedback data from the past 7 days`}
								</Typography>
							) : (
								<Typography
									variant="caption"
									color="textSecondary"
									className={classes.helper}
								>
									{t`The scheduled export will contain the feedback data from the past 30/31 days`}
								</Typography>
							)}
						</Grid>
						<Grid
							item
							xs={12}
						>
							<FormControl component="fieldset">
								<FormControlLabel
									control={
										<Checkbox
											checked={exportConfig.enabled}
											onChange={e =>
												setExportConfig(draft => {
													draft.enabled = e.target.checked;
												})
											}
											name="schedule_enable"
										/>
									}
									label={t`Set the export schedule active`}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={exportConfig.run_immediately}
											onChange={e =>
												setExportConfig(draft => {
													draft.run_immediately = e.target.checked;
												})
											}
											name="run_export_immediately"
										/>
									}
									label={t`Send me an export directly after adding the export schedule`}
								/>
							</FormControl>
						</Grid>
					</Grid>
				)}
			{(exportConfig.variant === 'single' ||
				exportConfig.variant === 'quick' ||
				exportConfig.maxSetupsReached) &&
				!exportConfig.make_schedule &&
				!exportConfig.use_template && (
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="body1"
							color="textSecondary"
						>{t`Export date range`}</Typography>
						<Grid
							container
							justify="space-between"
							alignItems="center"
							spacing={2}
						>
							<Grid
								item
								xs={6}
							>
								<TextField
									fullWidth
									label={t`From date`}
									value={exportConfig.from_date ?? date.fromDate}
									onChange={e => setDateAnchor(e.currentTarget)}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton onClick={e => setDateAnchor(e.currentTarget)}>
													<InsertInvitationIcon />
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid
								item
								xs={6}
							>
								<TextField
									fullWidth
									label={t`To date`}
									id="export-to"
									value={exportConfig.to_date ?? date.toDate}
									onChange={e => setDateAnchor(e.currentTarget)}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton onClick={e => setDateAnchor(e.currentTarget)}>
													<InsertInvitationIcon />
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<ExportDatePicker
								anchor={dateAnchor}
								resetAnchor={() => setDateAnchor(null)}
								fromDate={exportConfig.from_date}
								toDate={exportConfig.to_date}
								setQuickSelect={select => {
									setExportConfig(draft => {
										draft.date_quick_select = select;
									});
								}}
								setFromDate={date => {
									setExportConfig(draft => {
										draft.from_date = date;
									});
								}}
								setToDate={date => {
									setExportConfig(draft => {
										draft.to_date = date;
									});
								}}
							/>
						</Grid>
					</Grid>
				)}

			<Grid
				item
				xs={12}
			>
				<FilterGroup
					noCollapse
					customFilterName={
						<Typography
							variant="body1"
							color="textSecondary"
						>
							{t('Feedback source')}
						</Typography>
					}
				>
					<DataSourceSelect
						value={other.survey_id ?? ''}
						emptyLabel={t`All sources`}
						onChange={e => {
							const newId = e.target.value;
							setSingleFilter('survey_id', newId, 'other');
							setExportConfig(draft => {
								draft.survey_ids = newId ? [newId] : [];
								draft.datafield_selection = 'alldatafields';
							});
							deSelectAll();
						}}
					/>
				</FilterGroup>
			</Grid>

			{exportConfig.variant === 'quick' &&
				exportConfig.format === 'xlsx' &&
				exportConfig.survey_ids.length !== 1 && (
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="body1"
							color="textSecondary"
							gutterBottom
						>{t`Export sheet settings`}</Typography>
						<FormControl component="fieldset">
							<RadioGroup
								aria-label="export_sheets"
								name="export_sheets"
								value={exportConfig.split_into_sheets ? 'sheets' : 'single'}
								onChange={e => {
									setExportConfig(draft => {
										draft.split_into_sheets = e.target.value === 'sheets' ? true : false;
									});
								}}
							>
								<FormControlLabel
									value="single"
									control={<Radio />}
									label={t`Export all data sources into a single sheet`}
								/>
								<FormControlLabel
									value="sheets"
									control={<Radio />}
									label={t`Export data sources into separate sheets`}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
				)}

			<Grid
				item
				xs={12}
				className={classes.fillWidth}
			>
				<Typography
					variant="body1"
					color="textSecondary"
					gutterBottom
				>{t`What data should be included in your export?`}</Typography>
				<FormControl component="fieldset">
					<RadioGroup
						aria-label="datafieldSetting"
						name="datafieldSetting"
						value={exportConfig.datafield_selection}
						onChange={e => {
							setExportConfig(draft => {
								draft.datafield_selection = e.target.value;
							});
							if (e.target.value === 'alldatafields') {
								deSelectAll();
							} else if (e.target.value === 'activedatafields') {
								selectAll(true);
							} else {
								deSelectAll();
							}
						}}
					>
						<FormControlLabel
							value="alldatafields"
							control={<Radio />}
							label={t`All data sources and fields including historical data`}
						/>
						<FormControlLabel
							value="activedatafields"
							control={<Radio />}
							label={t`All data sources without historical data (only active fields)`}
						/>
						<FormControlLabel
							value="custom"
							control={<Radio />}
							label={t`A custom selection`}
						/>
					</RadioGroup>
				</FormControl>
			</Grid>
			{exportConfig.datafield_selection !== 'alldatafields' && (
				<Fragment>
					<Grid
						item
						xs={12}
						className={`${classes.fillWidth}`}
					>
						<List
							disablePadding
							dense
						>
							<ListItem className={classes.removePaddingLeft}>
								<Typography
									variant="body1"
									color="textSecondary"
								>
									{exportConfig.datafield_selection === 'activedatafields'
										? t('The following active fields will be included in the export')
										: t('Which fields do you want to add to your export?')}
								</Typography>
								<ListItemSecondaryAction>
									<Tooltip
										title={
											exportConfig.datafield_ids.length > 0 &&
											exportConfig.datafield_ids.length < allDataFields.length
												? t`Deselect all`
												: t`Select all`
										}
										aria-label={t`select all`}
									>
										<Checkbox
											checked={exportConfig.datafield_ids.length > 0}
											onClick={e => {
												if (exportConfig.datafield_ids.length > 0) {
													deSelectAll();
												} else {
													selectAll();
												}
											}}
											indeterminate={
												exportConfig.datafield_ids.length > 0 &&
												exportConfig.datafield_ids.length < allDataFields.length
											}
										/>
									</Tooltip>
								</ListItemSecondaryAction>
							</ListItem>
						</List>

						<DataSourceCheckboxList
							config={exportConfig}
							updateDatafields={updateDatafields}
							surveyId={survey_id ? survey_id : null}
						/>
					</Grid>
				</Fragment>
			)}
		</Grid>
	);
}
