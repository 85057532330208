import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function GcrIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<path
				id="XMLID_503_"
				d="M15,20.3c-2.6,0-4.7,2.1-4.7,4.7s2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7S17.6,20.3,15,20.3z M25.6,23.8
				c-0.5-4.9-4.5-8.8-9.4-9.4V12h-2.4v2.4C8.9,15,5,18.9,4.4,23.8H2v2.4h2.4c0.5,4.9,4.5,8.8,9.4,9.4V38h2.4v-2.4
				c4.9-0.5,8.8-4.5,9.4-9.4H28v-2.4H25.6z M15,33.3c-4.6,0-8.3-3.7-8.3-8.3s3.7-8.3,8.3-8.3s8.3,3.7,8.3,8.3S19.6,33.3,15,33.3z
				 M26.9,6.3c0,1.5-0.3,2.6-1,3.4c-0.7,0.8-1.6,1.2-2.8,1.2c-1.1,0-2-0.4-2.7-1.2s-1-1.9-1-3.4c0-1.5,0.3-2.6,1-3.4
				c0.7-0.8,1.6-1.2,2.7-1.2c1.2,0,2.1,0.4,2.8,1.2C26.6,3.8,26.9,4.9,26.9,6.3z M22.4,6.3c0,0.8,0.1,1.3,0.2,1.7
				c0.1,0.3,0.3,0.5,0.6,0.5s0.4-0.2,0.6-0.5s0.2-0.9,0.2-1.7c0-0.8-0.1-1.3-0.2-1.7s-0.3-0.5-0.6-0.5c-0.3,0-0.5,0.2-0.6,0.5
				C22.5,5,22.4,5.6,22.4,6.3z M34.2,1.9l-8.1,14.6h-3l8.1-14.6H34.2z M38,12.1c0,1.5-0.3,2.6-1,3.4c-0.7,0.8-1.6,1.2-2.8,1.2
				c-1.1,0-2-0.4-2.7-1.2s-1-1.9-1-3.3c0-1.5,0.3-2.6,1-3.4c0.7-0.8,1.6-1.2,2.7-1.2c1.2,0,2.1,0.4,2.8,1.2C37.7,9.6,38,10.7,38,12.1z
				 M33.5,12.1c0,0.8,0.1,1.3,0.2,1.7c0.1,0.3,0.3,0.5,0.6,0.5c0.3,0,0.5-0.2,0.6-0.6c0.1-0.4,0.2-0.9,0.2-1.6c0-0.7-0.1-1.2-0.2-1.6
				c-0.1-0.4-0.3-0.6-0.6-0.6c-0.3,0-0.5,0.2-0.6,0.5C33.6,10.8,33.5,11.4,33.5,12.1z"
			/>
		</SvgIcon>
	);
}
