import React from 'react';

import { useTranslation } from 'react-i18next';

import { Typography, Box, Grid } from '@/components/Layout';

import { useDeploymentStore } from './DeploymentStore';
import { diffDeployRules } from './deploymentUtils';

export default function DeploymentDiff({
	newName,
	oldName,
	newStatus,
	oldStatus,
	newRules,
	oldRules,
}) {
	const surveysByKey = useDeploymentStore(store => store.surveysByKey);
	const { t } = useTranslation();

	const { added = [], removed = [], changed = [] } = diffDeployRules(newRules, oldRules);

	const nameChanged = newName !== oldName;
	const statusChanged = newStatus !== oldStatus;

	const noChange =
		!nameChanged &&
		!statusChanged &&
		[added, removed, changed].every(diff => diff.length === 0);

	const getSurveyName = key =>
		surveysByKey?.[key]?.name ??
		t`data_collection-deployment_editor-deployment_diff-survey_not_found`;

	return (
		<Box>
			<Typography
				variant="caption"
				color="textSecondary"
			>
				{noChange
					? t`data_collection-deployment_editor-deployment_diff-no_diff`
					: t`data_collection-deployment_editor-deployment_diff-summary`}
			</Typography>

			{nameChanged && (
				<DiffRow
					name={t`data_collection-deployment_editor-deployment_diff-name_changed`}
				/>
			)}

			{statusChanged && (
				<DiffRow
					name={t(
						`data_collection-deployment_editor-deployment_diff-status_changed-${
							!oldStatus && newStatus ? 'published' : 'paused'
						}`
					)}
				/>
			)}

			{added.map(key => (
				<DiffRow
					key={key}
					name={getSurveyName(key)}
					change={t`data_collection-deployment_editor-deployment_diff-added`}
				/>
			))}
			{changed.map(key => (
				<DiffRow
					key={key}
					name={getSurveyName(key)}
					change={t`data_collection-deployment_editor-deployment_diff-editted`}
				/>
			))}
			{removed.map(key => (
				<DiffRow
					key={key}
					name={getSurveyName(key)}
					change={t`data_collection-deployment_editor-deployment_diff-removed`}
				/>
			))}
		</Box>
	);
}

function DiffRow({ name, change }) {
	return (
		<Grid
			container
			spacing={1}
		>
			<Grid
				item
				xs={8}
			>
				{name}
			</Grid>
			<Grid
				item
				xs={4}
			>
				{change}
			</Grid>
		</Grid>
	);
}
