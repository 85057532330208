import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ButtonFloatPreviewIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<path
				d="M40,40v-4h-1v4H40z M36,40v-1h-4v1H36z M28,40v-1h-4v1H28z M20,40v-1h-4v1H20z M12,40v-1H8v1
				H12z M4,40v-1H0v1H4z M1,36v-4H0v4H1z M40,32v-4h-1v4H40z M1.002,27.83V23h-1v4.83H1.002z M40,24.375V23h-1v1.375H40z
				 M29.623,33.295c0,2.035,1.65,3.686,3.687,3.688c2.037,0,3.688-1.65,3.689-3.688v-0.002c0-2.037-1.652-3.688-3.689-3.688
				C31.273,29.605,29.622,31.258,29.623,33.295z"
			/>
		</SvgIcon>
	);
}
