import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { PageHeader } from '../App';
import { useAppContext } from '../AppContext';
import { useDataSourceContext } from '../DataSources';
import { Dropzone, Alert } from '../Layout';
import AnalyzeData from './AnalyzeData';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
	dropzone: {
		minHeight: '60vh',
		borderColor: `${theme.palette.grey[400]}!important`,
	},
	ul: {
		margin: 0,
		paddingLeft: 20,
	},
}));

export default function ImportDataSet({ ...props }) {
	const { t } = useTranslation();
	const { app } = useAppContext();
	const { datasource } = useDataSourceContext();
	const { datasetId } = useParams();
	//const [data, setData] = useState({"status":"OK","fullUrl":"https://s3-eu-west-1.amazonaws.com/efm/dataloader/cache/1502/cc0df695a3040ecf15bcaf337d8851d3","fileData":{"path":"efm/dataloader/cache/1502/cc0df695a3040ecf15bcaf337d8851d3","name":"minipass-benchmarks.xlsx"}});
	//const [data, setData] = useState({"status":"OK","fullUrl":"https://s3-eu-west-1.amazonaws.com/efm/dataloader/cache/1502/37fe5f058cf70bbb26d0a988241e980e","fileData":{"path":"efm/dataloader/cache/1502/37fe5f058cf70bbb26d0a988241e980e","name":"dummydata.xlsx"}});
	const [data, setData] = useState({});
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();

	return (
		<Fragment>
			{Object.keys(data).length === 0 && (
				<PageHeader
					title={
						datasetId === 'new'
							? t`Import data`
							: `${t`Add data to`} ${datasource.api.getSource(datasetId).name}`
					}
					backTo="/data-collection/dataset"
					backTooltip={t`To datasets`}
				/>
			)}
			{Object.keys(data).length === 0 ? (
				<div className={classes.wrap}>
					<Dropzone
						DropzoneProps={{
							className: classes.dropzone,
						}}
						imgMaxWidth="100%"
						filesInCard
						placeholder="/assets/img/r/dataloader.svg"
						accept=".xlsx, .xls, .csv, text/csv"
						url="/survey/ajax/upload-data"
						headers={{
							Token: app.tokens.csrf,
						}}
						onUpload={r => {
							if (r.status === 'OK') {
								setData(r);
							}
						}}
						onError={e => {
							enqueueSnackbar(
								e?.response?.data?.message ??
									t`Something went wrong while uploading your data`
							);
						}}
					/>
					<Box>
						<Grid
							container
							alignItems="center"
						>
							<Grid
								item
								xs={8}
							>
								<Alert variant="default">
									<div>
										{t`When uploading an .xls or .xlsx file consisting of multiple worksheets make sure that the first worksheet contains the data that needs to be uploaded`}
									</div>
									<div>
										{t`You are allowed to upload a maximum of 500 items per hour`}
									</div>
								</Alert>
							</Grid>
							<Grid
								item
								xs={4}
							>
								<Typography
									variant="body2"
									color="textSecondary"
									display="block"
									align="right"
								>
									{t`Possible file formats`}
								</Typography>
								<Typography
									variant="body2"
									color="textSecondary"
									display="block"
									align="right"
								>
									{t`.xls, .xlsx, .csv`}
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</div>
			) : (
				<AnalyzeData
					data={data}
					onBack={() => setData({})}
				/>
			)}
		</Fragment>
	);
}
