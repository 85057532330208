import React, { useMemo, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ActionIconButton } from '../Layout';
import { useWebHookContext } from './WebHookContext';
import { useEFM } from '../Ajax';

import { useTranslation } from 'react-i18next';

function findProjectValues(project_id, accounts) {
	let projectValues = {
		project_id,
	};

	accounts.forEach(account => {
		const foundProject = account.projects.find(proj => proj.project_id == project_id);

		if (foundProject) {
			projectValues.todo_set_id = foundProject.todo_set_id;
			projectValues.endpoint = account.href;
			projectValues.project_name = foundProject.name;
		}
	});
	return projectValues;
}

export default function BasecampRow({
	auth_id,
	endpoint,
	project_id,
	todo_set_id,
	todo_list_id,
	assignee,
	onChangeProject,
	onChangeTodoList,
	onChangeAssignee,
	onRemoveRow,
	index,
}) {
	const { t } = useTranslation();
	const { state } = useWebHookContext();

	const auth = state.auths.find(auth => auth.id === auth_id) ?? {};

	const [{ access_token = '' }, accessTokenLoading] = useEFM(
		`/integrations/ajax/basecamp-auth`,
		{ refresh_token: auth.fields?.refresh_token, type: 'refresh' }
	);
	const [data = {}] = useEFM(
		`/integrations/ajax/basecamp-projects`,
		{ access_token },
		!access_token
	);
	const [todoLists = [], todoListsLoading] = useEFM(
		'/integrations/ajax/basecamp-data?type=todo',
		{ access_token, url: endpoint, project_id, todo_set_id },
		!access_token || !endpoint
	);
	const gridSize = 4;

	return (
		<Grid
			container
			spacing={1}
			alignItems="center"
		>
			<Grid
				item
				xs
			>
				<Grid
					container
					spacing={1}
				>
					<Grid
						item
						xs={gridSize}
					>
						<TextField
							select
							value={project_id}
							onChange={e => {
								const project = findProjectValues(e.target.value, data.accounts);
								onChangeProject(project);
							}}
							label={t`Project`}
							fullWidth
						>
							{Array.isArray(data.accounts)
								? data.accounts.flatMap(account => {
										return [
											<ListSubheader key={account.name}>{account.name}</ListSubheader>,
											...account.projects.map(proj => (
												<MenuItem
													key={proj.project_id}
													value={proj.project_id}
												>
													{proj.name}
												</MenuItem>
											)),
										];
								  })
								: []}
						</TextField>
					</Grid>

					<Grid
						item
						xs={gridSize}
					>
						<TextField
							select
							value={todo_list_id}
							onChange={e =>
								onChangeTodoList(todoLists.find(todo => todo.id == e.target.value) ?? {})
							}
							label={t`Todo list`}
							fullWidth
						>
							{Array.isArray(todoLists) ? (
								todoLists.map(todo => {
									return (
										<MenuItem
											key={todo.id}
											value={todo.id}
										>
											{todo.name}
										</MenuItem>
									);
								})
							) : (
								<MenuItem
									value=""
									disabled
								>{t`Select a project`}</MenuItem>
							)}
						</TextField>
					</Grid>

					<Grid
						item
						xs={gridSize}
					>
						<BasecampUserAutocomplete
							access_token={access_token}
							endpoint={endpoint}
							project_id={project_id}
							assignee={assignee}
							onChangeAssignee={onChangeAssignee}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<ActionIconButton
					action="remove_list"
					onClick={onRemoveRow}
					size="small"
				/>
			</Grid>
		</Grid>
	);
}

function BasecampUserAutocomplete({
	access_token,
	endpoint,
	project_id,
	assignee,
	onChangeAssignee,
}) {
	const [hadFocus, setHadFocus] = useState(false);
	const { t } = useTranslation();

	const waitLoading = assignee ? false : !hadFocus || !endpoint || !project_id;
	const [users, usersLoading] = useEFM(
		'/integrations/ajax/basecamp-data?type=assignee',
		{ access_token, url: endpoint, project_id },
		waitLoading
	);

	const value = Array.isArray(users)
		? users.find(user => user.id === assignee)
		: { id: 0, name: '' };

	return (
		<Autocomplete
			fullWidth
			options={Array.isArray(users) ? users : []}
			getOptionLabel={option => option.name}
			onChange={(e, value) => onChangeAssignee(value)}
			getOptionSelected={(option, value) => option?.id === value?.id}
			loading={usersLoading}
			value={value}
			renderInput={params => (
				<TextField
					{...params}
					label={t`Assignee`}
					onFocus={() => setHadFocus(true)}
				/>
			)}
		/>
	);
}
