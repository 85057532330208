import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

function CloseSnackbarButton({ closeKey, ...props }) {
	const { closeSnackbar } = useSnackbar();
	return (
		<IconButton
			aria-label="Close"
			color="inherit"
			data-test-element="dateRangeClose"
			onClick={e => closeSnackbar(closeKey)}
		>
			<CloseIcon />
		</IconButton>
	);
}

export default CloseSnackbarButton;
