import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAppContext } from '../AppContext';
import { UserAvatar } from '../Users';

const useStyles = makeStyles(theme => ({
	header: {
		height: 140,
		background: '#ccc',
		width: 'calc(100% + 48px)',
		marginLeft: -24,
		marginTop: -24,
		padding: 24,
		marginBottom: 24,
	},
	avatar: {
		marginTop: -68,
		padding: 2,
		width: 84,
		height: 84,
		margin: props => (props.align === 'center' ? 'auto' : ''),
		borderRadius: '50%',
		background: '#eeeeee',
		marginBottom: 24,
	},
}));

function AccountHeader({ userId, align = 'center', withName }) {
	const { app } = useAppContext();
	const classes = useStyles({ align });

	const { color, id, firstname = '', lastname = '' } = app.users.byKey[userId] ?? {};

	const hexToRGB = (hex = '', leftOpacity = 1, rightOpacity = 1) => {
		if (hex.indexOf('#') === 0) {
			hex = hex.substr(1);
		}
		hex = '0x' + hex;
		let r = (hex >> 16) & 0xff;
		let g = (hex >> 8) & 0xff;
		let b = hex & 0xff;
		return `linear-gradient( 217deg, rgba(${r}, ${g}, ${b}, ${leftOpacity}), rgba(${r}, ${g}, ${b}, ${rightOpacity}))`;
	};

	const headerColor = {
		background: hexToRGB(color ?? '#dadada', '0.4', '0.8'),
	};

	return (
		<div className={classes.center}>
			<div
				className={classes.header}
				style={headerColor}
			/>
			<div>
				<div className={classes.avatar}>
					<UserAvatar
						size="xlarge"
						user_id={id}
					/>
				</div>
			</div>
		</div>
	);
}

export default AccountHeader;
