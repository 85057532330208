import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

import { ActionButton } from '../Layout';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

import { useHistory } from 'react-router-dom';

import { AjaxForm } from '../Ajax';

import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
	fillWidth: {
		width: '100%',
	},
	marginTop: {
		marginTop: theme.spacing(2),
	},
	bold: {
		fontWeight: 600,
	},
	info: {
		backgroundColor: theme.palette.primary.main,
	},
	paddingTop: {
		paddingTop: 0,
	},
}));

function SocialDialog({ action, type, email, setAction, setSocial, storeInSession }) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const history = useHistory();

	const combinedAjaxFormProps = {
		url: '/account/ajax/update-social',
		data: {
			action: action,
			type: type,
			email: email,
		},
		onSuccess: r => {
			setAction(false);
			enqueueSnackbar(
				action === 'unlink'
					? t`Social account succesfully removed`
					: t`Social account succesfully linked`
			);
			if (action === 'unlink') {
				setSocial(type, false);
			} else if (storeInSession) {
				sessionStorage.setItem('socialGoogleEmail', storeInSession);
			}
		},
		onFail: r => {
			enqueueSnackbar(`Something went wrong`);
		},
	};

	return (
		<AjaxForm {...combinedAjaxFormProps}>
			{({ clicked, loading, postForm }) => (
				<Dialog
					open={action ? true : false}
					onClose={() => setAction(false)}
					maxWidth="sm"
					fullWidth
				>
					<DialogTitle>
						{action === 'unlink'
							? `${t`Unlink account`}`
							: `${t`Confirm link`} ${type} ${t`account`}`}
					</DialogTitle>
					<DialogContent>
						{action === 'unlink'
							? t('Are you sure you want to unlink your {{account_type}} account?', {
									account_type: type,
							  })
							: t('Do you want to link {{account_email}} to your account?', {
									account_email: email,
							  })}
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								setAction(false);
								setSocial(type, false);
								history.push('/account/me');
							}}
							color="default"
						>
							{t('Cancel')}
						</Button>
						<ActionButton
							action={'dialog_submit'}
							loading={loading}
							onClick={() => {
								postForm();
								history.push('/account/me');
							}}
						>
							{t`Confirm`}
						</ActionButton>
					</DialogActions>
				</Dialog>
			)}
		</AjaxForm>
	);
}

export default SocialDialog;
