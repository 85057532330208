import React from 'react';
import { useAppContext } from '@/components/AppContext';
import { useAjaxForm } from '@/components/Ajax';

export default function useSaveAlert({ alert, onSuccess = () => {}, ...props }) {
	const { app } = useAppContext();

	const { alert_id, ...alertProps } = alert;

	const { postForm, loading, clicked } = useAjaxForm({
		url: `/api/1/alerts/${app.domain}/${app.projects.current.id}${
			alert.alert_id && !props.doUndo ? `/${alert.alert_id}` : ''
		}`,
		type: 'mjolnir',
		data: {
			...alertProps,
			...(!alertProps.organisation_id && {
				organisation_id: app.organisations.current.org_id,
			}),
			...(alert_id && !props.doUndo && { alert_id }),
		},
		onSuccess: r => {
			onSuccess(r);
		},
		...props,
	});
	return [postForm, loading, clicked];
}
