import React, { Fragment, useState } from 'react';
import Grid from '@material-ui/core/Grid';

import { useEFM } from '../Ajax';
import AuthCard from './AuthCard';
import { useWebHookContext } from './WebHookContext';
import NewAuthPopover from './NewAuthPopover';

export default function AuthList({
	type,
	selectedId,
	onSelect = () => {},
	onAdd = () => {},
	target,
}) {
	const { state, api } = useWebHookContext();
	const [open, setOpen] = useState(false);
	return (
		<Fragment>
			<Grid
				container
				spacing={1}
			>
				<Grid
					item
					xs={4}
				>
					<AuthCard
						add
						onClick={() => setOpen(true)}
					/>
				</Grid>
				{state.auths
					.filter(auth => auth.type == type)
					.map(auth => {
						return (
							<Grid
								item
								xs={4}
								key={auth.id}
							>
								<AuthCard
									{...auth}
									key={auth.id}
									selected={selectedId && selectedId === auth.id}
									onClick={() => onSelect(auth)}
								/>
							</Grid>
						);
					})}
			</Grid>
			<NewAuthPopover
				open={open}
				type={type}
				target={target}
				onClose={() => setOpen(false)}
				loadAuth={api.loadAuth}
			/>
		</Fragment>
	);
}
