import React, { forwardRef } from 'react';
import MuiChip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@/styles';
const useStyles = makeStyles(theme => ({
	loader: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));

export default forwardRef(function Chip({ loading, label, ...props }, ref) {
	const classes = useStyles();
	return (
		<MuiChip
			ref={ref}
			disabled={loading}
			label={
				<>
					{loading && (
						<CircularProgress
							size={24}
							className={classes.loader}
						/>
					)}
					{label}
				</>
			}
			{...props}
		/>
	);
});
