import React, { useState, useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation, Trans } from 'react-i18next';

import {
	useFilteredList,
	ListWithSidebar,
	ListFilterProvider,
	ListControls,
} from '@/components/FilteredLists';
import {
	ActionButton,
	Box,
	Loader,
	EmptyState,
	Typography,
	KnowledgeBaseLink,
	constants as layoutConstants,
} from '@/components/Layout';
import { useAppContext } from '@/components/AppContext';
import { AccessGroupIcon } from '@/components/Icons';
import { Explainer } from '@/components/Explainer';
import { sortByNum } from '@/utils';

import PermissionGroupAddEditDrawer from './PermissionGroupAddEditDrawer';
import PermissionGroupListFilters from './PermissionGroupListFilters';
import PermissionGroupCard from './PermissionGroupCard';
import useGetPermissionGroups from './useGetPermissionGroups';
import useHasPermission from './useHasPermission';
import NoPermissionTooltip from './NoPermissionTooltip';
import PendingPermissionRequests from './PendingPermissionRequests';
import {
	applySearchFilter,
	applyPermissionFilter,
	applyMemberFilter,
	isAdminOwnerGroup,
} from './utils';
import PermissionGroupIntroductionDialog from './PermissionGroupIntroductionDialog';
import { permissionGroupTypes } from './constants';

const useStyles = makeStyles(theme => ({
	offset: {
		marginTop: '20vh',
	},
	explainerImg: {
		width: '100%',
	},
	italic: {
		fontStyle: 'italic',
	},
	introImage: {
		maxWidth: '90%',
		alignSelf: 'center',
	},
}));

export default function PermissionGroupList() {
	const classes = useStyles();
	const { app } = useAppContext();
	const [{ groups = [] }, loading, _err, getPermissionGroups] = useGetPermissionGroups();
	const { t } = useTranslation();
	const [drawerOpen, setDrawerOpen] = useState(
		window.innerWidth > layoutConstants.filterDrawerBreakpoint
	);
	const [addDialogOpen, setAddDialogOpen] = useState(false);
	const hasEditPermission = useHasPermission();
	const filterState = useFilteredList({});

	const filteredGroups = useMemo(() => {
		const filtersApplied = groups?.filter(group => {
			const searchPassed = applySearchFilter({
				search: filterState.filters.search,
				group,
			});

			const permissionPassed = applyPermissionFilter({
				permission: filterState.filters.permission,
				subject: filterState.filters.subject,
				group,
			});

			const projectsPassed = filterState.filters.projects
				? filterState.filters.projects?.some(
						projectUuid =>
							group.projects?.includes(projectUuid) || isAdminOwnerGroup(group)
				  )
				: true;

			const membersPassed = applyMemberFilter({
				members: filterState.filters.members,
				group,
			});

			return searchPassed && permissionPassed && projectsPassed && membersPassed;
		});

		return sortByNum(filtersApplied, 'created', filterState.order === 'desc');
	}, [filterState, groups]);

	const hasOwnerGroup = useMemo(() => {
		return groups.find(group => group.type === permissionGroupTypes.owner);
	}, [groups]);

	return (
		<ListFilterProvider
			singleFilters={['search']}
			multiFilters={['projects', 'members']}
			filterLabels={{
				projects: {
					label: t`settings-access_groups-list-filters-reports`,
					...app.projects.asArray.reduce((all, project) => {
						all[project.uuid] = project.name;
						return all;
					}, {}),
				},
				members: {
					label: t`settings-access_groups-list-filters-users`,
					...app.users.asArray.reduce((all, user) => {
						all[user.uuid] = `${user.firstname} ${user.lastname}`;
						return all;
					}, {}),
				},
			}}
			computedFilters={
				filterState.filters.permission && filterState.filters.subject
					? [
							{
								label: t`settings-access_groups-list-filters-rights`,
								value: `${t(
									'settings-access_groups-access_types-' + filterState.filters.permission
								)} ${t(
									'settings-access_groups-access_subjects-' + filterState.filters.subject
								)}`,
								filters: ['permission', 'subject'],
							},
					  ]
					: []
			}
			{...filterState}
		>
			<ListWithSidebar
				drawerContent={<PermissionGroupListFilters />}
				open={drawerOpen}
				onClose={() => setDrawerOpen(false)}
				prominent={!app.layout.prominentHidden}
			>
				{hasEditPermission && (
					<PendingPermissionRequests
						getPermissionGroups={getPermissionGroups}
						addNewGroup={() => setAddDialogOpen(true)}
					/>
				)}
				<ListControls
					sortOptions={[
						{
							label: t('settings-access_groups-list-sort-create_asc'),
							value: 'created-asc',
						},
						{
							label: t('settings-access_groups-list-sort-create_desc'),
							value: 'created-desc',
						},
					]}
					toggleFilterDrawer={() => setDrawerOpen(prev => !prev)}
				>
					<NoPermissionTooltip>
						<ActionButton
							action="add"
							onClick={() => setAddDialogOpen(true)}
							disabled={!hasEditPermission}
							mr={1}
						>
							{t`settings-access_groups-list-add_button`}
						</ActionButton>
					</NoPermissionTooltip>

					<Explainer
						title={t(`settings-access_groups-list-explainer-title`)}
						content={
							<Trans
								i18nKey={`settings-access_groups-list-explainer-content`}
								components={{
									p1: <Typography mb={2} />,
									p2: (
										<Typography
											mb={2}
											fontWeight="medium"
										/>
									),
									p3: (
										<Typography
											mt={3}
											mb={1}
											fontWeight="medium"
										/>
									),
									p4: <Typography mb={2} />,
									p5: <Typography mt={2} />,
									ol: <ol />,
									li1: <li />,
									li2: <li />,
									li3: <li />,
									li4: <li />,
									note: <Typography className={classes.italic} />,
									img: (
										<img
											className={classes.explainerImg}
											src="/assets/img/explainer/access_group_explainer.svg"
										/>
									),
									kbLink: (
										<KnowledgeBaseLink
											page="access_groups"
											color="inherit"
										/>
									),
								}}
							/>
						}
					>{t`settings-access_groups-list-explainer-label`}</Explainer>
				</ListControls>
				<Box
					mt={1}
					height="100%"
				>
					{loading && <Loader empty={groups.length === 0} />}
					{!loading && groups.length === 0 && (
						<EmptyState
							icon={AccessGroupIcon}
							primary={t`settings-access_groups-list-no_groups_filter_empty_state-primary`}
							secondary={t`settings-access_groups-list-no_groups_filter_empty_state-secondary`}
							className={classes.offset}
						/>
					)}
					{!loading && groups.length > 0 && filteredGroups.length === 0 && (
						<EmptyState
							icon={AccessGroupIcon}
							primary={t`settings-access_groups-list-no_groups_filter_empty_state-primary`}
							secondary={t`settings-access_groups-list-no_groups_filter_empty_state-secondary`}
							className={classes.offset}
						/>
					)}
					{filteredGroups.map(group => (
						<PermissionGroupCard
							key={group.uuid}
							group={group}
							getPermissionGroups={getPermissionGroups}
							hasOwnerGroup={hasOwnerGroup}
						/>
					))}
				</Box>
			</ListWithSidebar>

			<PermissionGroupAddEditDrawer
				open={addDialogOpen}
				onClose={() => setAddDialogOpen(false)}
				getPermissionGroups={getPermissionGroups}
			/>

			<PermissionGroupIntroductionDialog />
		</ListFilterProvider>
	);
}
