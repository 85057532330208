import { permissionGroupTypes } from '../constants';

const translatedErrorMessages = [];

export function getErrorMessageTranslationKey(message) {
	const key = 'settings-access_groups-errors-';

	if (translatedErrorMessages.includes(message)) {
		return `${key}${message}`;
	}

	return `${key}fail`;
}

export function applySearchFilter({ search, group = {} }) {
	if (!search) {
		return true;
	}

	if (new RegExp(search, 'ig').test(group.name)) {
		return true;
	}

	return false;
}

export function applyPermissionFilter({ permission, subject, group = {} }) {
	if (!permission || !subject) {
		return true;
	}

	for (const module in group.rights) {
		const moduleRights = group.rights[module];
		if (moduleRights[subject] && moduleRights[subject] === permission) {
			return true;
		}
	}
	return false;
}

export function applyMemberFilter({ members = [], group = {} }) {
	if (!members || members.length === 0) {
		return true;
	}
	return group.members?.some(uuid => members.includes(uuid)) ?? false;
}

export function isAdminOwnerGroup(group = {}) {
	return [permissionGroupTypes.admin, permissionGroupTypes.owner].includes(group.type);
}
