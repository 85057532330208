import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import { useTranslation } from 'react-i18next';

import { InfoLink, Typography, ActionIconButton } from '../Layout';

const useStyles = makeStyles(theme => ({
	drawerPaper: {
		maxWidth: 480,
		background: theme.palette.primary.dark,
		height: '100%',
	},
	drawerContent: {
		color: '#fff',
		padding: theme.spacing(4),
	},
	drawerTitle: {
		paddingBottom: theme.spacing(3),
	},
	link: {
		verticalAlign: 'bottom',
		textDecoration: 'underline',
		fontWeight: 400,
	},
	pointer: {
		cursor: 'pointer',
	},
	info: {
		verticalAlign: 'middle',
		marginLeft: theme.spacing(0.5),
	},
	close: {
		color: 'inherit',
		marginRight: '-12px',
	},
	textAlignRight: {
		textAlign: 'right',
	},
}));

export function ExplainerDrawer({ title, content, open, onClose = () => {} }) {
	const classes = useStyles();
	const [drawerOpen, setDrawerOpen] = useState(false);

	function close() {
		setDrawerOpen(false);
		onClose();
	}

	return (
		<Drawer
			anchor="right"
			open={open ?? drawerOpen}
			onClose={close}
			classes={{ paper: classes.drawerPaper }}
		>
			<div className={classes.drawerContent}>
				<div className={classes.textAlignRight}>
					<ActionIconButton
						action="close"
						className={classes.close}
						onClick={close}
					/>
				</div>
				{title && (
					<div className={classes.drawerTitle}>
						<Typography variant="h6">{title}</Typography>
					</div>
				)}
				<Typography
					variant="body1"
					component="div"
				>
					{typeof content === 'function' ? content({ close }) : content}
				</Typography>
			</div>
		</Drawer>
	);
}

export default function Explainer({
	title,
	content,
	tooltip,
	children,
	forceClose,
	...props
}) {
	const { t } = useTranslation();
	const [drawerOpen, setDrawerOpen] = useState(false);

	useEffect(() => {
		if (forceClose) {
			setDrawerOpen(false);
		}
	}, [forceClose]);

	return (
		<>
			{content && (
				<ExplainerDrawer
					open={drawerOpen}
					onClose={() => setDrawerOpen(false)}
					title={title}
					content={content}
				/>
			)}

			<InfoLink
				tooltip={tooltip ?? t`explainer-read_more_tooltip`}
				onClick={() => content && setDrawerOpen(true)}
				{...props}
			>
				{children}
			</InfoLink>
		</>
	);
}
