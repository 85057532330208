import React from 'react';

import { useAjaxForm } from '@/components/Ajax';

export default function usePostStartChannelTrial(props) {
	return useAjaxForm({
		url: '/application/ajax/start-product-trial',
		...props,
	});
}
