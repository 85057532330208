import React from 'react';

import DomainIcon from '@material-ui/icons/Domain';
import PageviewsIcon from '@material-ui/icons/Pageview';
import BrandIcon from '@material-ui/icons/BrandingWatermark';
import EmailsSentIcon from '@material-ui/icons/Mail';
import AppIcon from '@material-ui/icons/Smartphone';
import SessionIcon from '@material-ui/icons/TouchApp';

const icons = {
	web_domains: DomainIcon,
	web_pageviews: PageviewsIcon,
	email_brands: BrandIcon,
	email_emails_sent: EmailsSentIcon,
	app_apps: AppIcon,
	app_sessions: SessionIcon,
};

export default function ProductIcon({ product = '', ...props }) {
	const Icon = icons[product];

	if (!Icon) {
		return null;
	}

	return <Icon {...props} />;
}
