import { sortByNum, sortByAlphabet } from '@/utils';
import { isAdminOwnerGroup } from '@/components/Permission';

export function applySort({ list = [], order, sort }) {
	if (list.length === 0) return list;

	if (sort === 'fname') {
		return sortByAlphabet(list, 'name', order === 'desc');
	}
	//default sort by id and asc if not set
	return sortByNum(list, 'id', order === 'desc');
}

export function applySearchFilter({ search, user = {} }) {
	if (!search) {
		return true;
	}

	if (
		[
			user.firstname,
			user.lastname,
			`${user.firstname} ${user.lastname}`,
			user.email,
			user.department,
			user.function_title,
			user.uuid,
			user.id,
		].some(str => str && new RegExp(search, 'ig').test(str))
	) {
		return true;
	}

	return false;
}

export function applyGroupsFilter({ groups = [], user = {} }) {
	if (groups.length === 0) {
		return true;
	}

	return groups.some(group => group?.members?.includes(user.uuid));
}

export function applyProjectsFilter({ projects = [], groups = [], user = {} }) {
	if (projects.length === 0) {
		return true;
	}

	return projects.some(projectUuid =>
		groups?.some(
			group =>
				(group?.projects?.includes(projectUuid) || isAdminOwnerGroup(group)) &&
				group?.members?.includes(user.uuid)
		)
	);
}
