import React, { useReducer, useRef, useEffect, useCallback } from 'react';

export default function useReducerWithMiddleware(
	reducer,
	initialState,
	{ middleware = [], afterware = [] } = {}
) {
	const [state, dispatch] = useReducer(reducer, initialState);

	const aRef = useRef();

	const dispatchWithMiddleware = useCallback(
		action => {
			middleware.forEach(middlewareFn =>
				middlewareFn(action, state, dispatchWithMiddleware)
			);

			aRef.current = action;

			dispatch(action);
		},
		[middleware, dispatch, state]
	);

	useEffect(() => {
		if (!aRef.current) return;

		afterware.forEach(afterwareFn =>
			afterwareFn(aRef.current, state, dispatchWithMiddleware)
		);

		aRef.current = null;
	}, [afterware, state, dispatchWithMiddleware]);

	return [state, dispatchWithMiddleware];
}
