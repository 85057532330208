import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default class SimpleSnackbar extends Component {
	//static defaultProps = {open:true}
	state = { open: false };

	componentDidMount() {
		this.setState({ open: this.props.open });
	}
	componentDidUpdate(prevProps) {
		if (this.props.open !== this.state.open && prevProps.open !== this.props.open) {
			this.setState({ open: this.props.open });
		}
	}

	render() {
		const { open, onClose } = this.state;
		const { message = '', action = [] } = this.props;

		let id = message.substring(0, 10);

		const props = {
			anchorOrigin: {
				vertical: 'bottom',
				horizontal: 'left',
			},
			open: open,
			autoHideDuration: 6000,
			onClose: e => {
				this.setState({ open: false });
				typeof onClose === 'function' && onClose(e);
			},
			ContentProps: {
				'aria-describedby': id,
			},
			message: <span id={id}>{message}</span>,
			action: [
				...action,
				<IconButton
					key="close-simple-snack"
					aria-label="Close"
					color="inherit"
					//className={classes.close}
					onClick={e => this.setState({ open: false })}
				>
					<CloseIcon />
				</IconButton>,
			],
		};

		return <Snackbar {...props} />;
	}
}
