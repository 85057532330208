import React from 'react';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useAjaxForm } from '../Ajax';
import { getErrorMessageTranslationKey } from './utils';

export default function useDenyPermission({
	permissionRequestUuid = '',
	onSuccess = () => {},
}) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	return useAjaxForm({
		url: `/application/permission/deny-access/${permissionRequestUuid}`,
		onSuccess: response => {
			if (response.code === 200) {
				enqueueSnackbar(t('settings-access_groups-access_denied-success_message'));
				onSuccess();
			} else {
				enqueueSnackbar(t(getErrorMessageTranslationKey(response.message)));
			}
		},
		onError: response => {
			enqueueSnackbar(t(getErrorMessageTranslationKey(response.message)));
		},
	});
}
