import React, { Fragment, useState, memo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useAppContext } from '@/components/AppContext';
import { UserAvatarGroup } from '@/components/Users';
import {
	Grid,
	Typography,
	Chip,
	Box,
	SystemElementCardBase,
	ActionIconButton,
	SimpleDialog,
	FormattedDate,
	ActionButton,
} from '@/components/Layout';
import { useDataSourceContext } from '@/components/DataSources';
import AddEditAlertDialog from './AddEditAlertDialog';
import useSaveAlert from './useSaveAlert';
import useDeleteAlert from './useDeleteAlert';
import { useAlertContext } from './AlertContext';
import { useHasPermission, NoPermissionTooltip } from '@/components/Permission';

const useStyles = makeStyles(theme => ({
	divider: {
		margin: theme.spacing(2, 0),
	},
	activeChip: {
		backgroundColor: props =>
			props.active ? theme.palette.secondary.light : theme.palette.error.light,
		marginLeft: theme.spacing(1),
	},
	removed: {
		position: 'absolute',
		backgroundColor: 'rgba(255,255,255,.9)',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		borderRadius: 5,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		zIndex: 10,
	},
	alignMiddleInline: {
		verticalAlign: 'middle',
	},
	relative: {
		position: 'relative',
	},
}));

function AlertCard({ alert }) {
	const classes = useStyles(alert);
	const { app } = useAppContext();
	const { state, api } = useAlertContext();
	const { datasource } = useDataSourceContext();
	const { t } = useTranslation();
	const source = datasource.api.getSource(alert.survey_id);
	const [editOpen, setEditOpen] = useState(false);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [saveAlert, loadingSaveAlert] = useSaveAlert({ alert: alert, doUndo: true });
	const [deleteAlert, loadingDeleteAlert] = useDeleteAlert({
		id: alert.alert_id,
		onSuccess: () => {
			setDeleteOpen(false);
			api.dispatch({
				type: 'delete_rule',
				payload: {
					id: alert.alert_id,
				},
			});
			const key = enqueueSnackbar(`${t`Alert`} ${alert.name} ${t`deleted`}`, {
				action: (
					<ActionButton
						color="secondary"
						onClick={() => {
							api.doUndo();
							saveAlert();
							closeSnackbar(key);
						}}
					>
						{t`Undo`}
					</ActionButton>
				),
			});
		},
	});
	const hasEditPermission = useHasPermission();
	const filtered_recipients = alert.recipient_ids
		? alert.recipient_ids.filter(id => app.users.inProject.indexOf(Number(id)) > -1)
		: [];

	return (
		<div className={classes.relative}>
			{Object.keys(source).length === 0 && !alert.all_project_data && (
				<div className={classes.removed}>
					<div>
						<div>
							<Typography
								variant="subtitle2"
								display="inline"
								className={classes.alignMiddleInline}
								color="textSecondary"
							>{t`Deleted form`}</Typography>
						</div>
						<Typography
							variant="caption"
							color="textSecondary"
						>
							{t`The feedback form for this alert was deleted from this report.`}
						</Typography>
						<Box mt={1}>
							<NoPermissionTooltip>
								<ActionButton
									onClick={() => setDeleteOpen(true)}
									action="delete"
									label={t`Remove alert`}
									disabled={!hasEditPermission}
								/>
							</NoPermissionTooltip>
						</Box>
					</div>
				</div>
			)}
			<SystemElementCardBase
				img={'/assets/img/r/email-alert.svg'}
				title={alert.name}
				subtitle={
					alert.all_project_data
						? t`For all data in report`
						: `${t`For`} ${source.survey_type === 'builder' ? t`form` : t`dataset`} ${
								source.name
						  }`
				}
				subtitle2={
					<Grid
						container
						alignItems="center"
					>
						<Grid
							item
							xs
						>
							<FormattedDate date={alert.date} />
						</Grid>
						<Grid item>
							<Chip
								label={alert.active ? t`Active` : t`Inactive`}
								className={classes.activeChip}
								size="small"
							/>
						</Grid>
					</Grid>
				}
				actions={
					<Grid
						container
						alignItems="center"
					>
						<Grid
							item
							xs
						>
							<Fragment>
								<NoPermissionTooltip>
									<ActionIconButton
										tooltip={t`Edit email alert`}
										action="edit"
										onClick={() => setEditOpen(true)}
										data-test-element="alertEditAlert"
										disabled={!hasEditPermission}
									/>
								</NoPermissionTooltip>
								<NoPermissionTooltip>
									<ActionIconButton
										tooltip={t`Delete email alert`}
										action="delete"
										onClick={() => setDeleteOpen(true)}
										data-test-element="alertDeleteAlert"
										disabled={!hasEditPermission}
									/>
								</NoPermissionTooltip>
							</Fragment>
						</Grid>

						<Grid item>
							<UserAvatarGroup
								size="small"
								stack={[
									{
										id: alert.alert_id + 'created',
										user_id: alert.created_user_id,
										tooltip: true,
										tooltipTitle: ({ firstname, lastname }) =>
											`${t`Created by`} ${firstname} ${lastname}`,
									},
									...(filtered_recipients.length
										? filtered_recipients.map(userId => {
												return {
													id: userId + 'assigned',
													user_id: userId,
													tooltip: true,
													tooltipTitle: ({ firstname, lastname }) =>
														`${t`For`} ${firstname} ${lastname}`,
												};
										  })
										: !filtered_recipients.length && alert.alert_user_id
										? [
												{
													id: alert.alert_id + 'assigned',
													user_id: alert.alert_user_id,
													tooltip: true,
													tooltipTitle: ({ firstname, lastname }) =>
														`${t`For`} ${firstname} ${lastname}`,
												},
										  ]
										: []),
								]}
							/>
						</Grid>
					</Grid>
				}
			>
				{alert.all_project_data ? (
					<Fragment>
						<Typography variant="subtitle2">{t`Send email notification when`}</Typography>
						<Typography variant="body2">{t`A new feedback item is added to this report`}</Typography>
					</Fragment>
				) : alert.all_data ? (
					<Fragment>
						<Typography variant="subtitle2">{t`Send email notification when`}</Typography>
						<Typography variant="body2">
							{t`A new feedback item is added to`} {source.name}
						</Typography>
					</Fragment>
				) : (
					<Fragment>
						<Typography variant="subtitle2">{t`Send email notification when`}</Typography>

						<Grid container>
							{alert.alert.map((data, conditionIndex) => {
								const { system_var } = datasource.api.getField(data.datafieldid);
								return (
									<Grid
										item
										xs
										key={'condition' + conditionIndex}
									>
										<Typography variant="body2">
											{system_var} {t(`operator.${data.condition}`)} {data.target}
										</Typography>
									</Grid>
								);
							})}
						</Grid>
					</Fragment>
				)}
			</SystemElementCardBase>

			<AddEditAlertDialog
				alert_id={alert.alert_id}
				key={alert.alert_id + 'addedit'}
				open={editOpen}
				onClose={() => setEditOpen(false)}
			/>

			<SimpleDialog
				open={deleteOpen}
				onClose={() => setDeleteOpen(false)}
				title={t`Delete email alert`}
				text={`${t`Are you sure you want to delete email alert`} ${alert.name}?`}
				submit={t`Delete alert`}
				loading={loadingDeleteAlert}
				onSubmit={() => {
					deleteAlert();
				}}
				dataTestElement="alertDeleteAlertConfirm"
				dataTrackEvent="settings_alert_deleted"
			/>
		</div>
	);
}

export default memo(AlertCard);
