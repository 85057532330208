import React from 'react';

import { useAppContext } from '@/components/AppContext';
import { ErrorBoundary } from '@/components/Utility';

import HelpMenuTour from './HelpMenuTour';
import InitialUserOnboardingTrial from './InitialUserOnboardingTrial';
import NewUserOnboardingTrial from './NewUserOnboardingTrial';
import useOnboardingContext from './useOnboardingContext';

export default function Onboarding({}) {
	const {
		app: { session },
	} = useAppContext();

	const onboarding = useOnboardingContext();

	return (
		<ErrorBoundary returnNullOnError>
			<HelpMenuTour />

			{onboarding.trialInitialized && session.onboarding_trial && (
				<InitialUserOnboardingTrial color="inherit" />
			)}

			{onboarding.trialInitialized && session.extra_user_trial && (
				<NewUserOnboardingTrial />
			)}
		</ErrorBoundary>
	);
}
