import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { useAppContext } from '@/components/AppContext';
import { ActionIconButton, Typography, Grid, Portal } from '@/components/Layout';
import {
	usePermissionModuleContext,
	useHasPermission,
	NoPermissionChip,
	permissionTypes,
} from '@/components/Permission';

export default function PageHeader({
	children,
	title,
	documentTitle,
	backTo,
	dataTestElement = null,
	backTooltip,
	onBack,
	prominent,
	container,
	tooltip,
	showViewPermissionIndicator = true,
	module,
	subject,
	...props
}) {
	const { app } = useAppContext();
	const history = useHistory();
	const moduleContext = usePermissionModuleContext();
	const editPermission = useHasPermission({ module, subject });
	const viewPermission = useHasPermission({
		module,
		subject,
		permission: permissionTypes.view,
	});

	const backFn =
		typeof onBack === 'function'
			? () => onBack(history)
			: backTo
			? () => history.push(backTo)
			: null;

	useEffect(() => {
		document.title = `${documentTitle ? documentTitle : title} | Mopinion`;
	}, [title, documentTitle]);
	useEffect(() => {
		if (prominent) {
			app.dispatch({
				type: 'set_prominent_app_bar',
				payload: prominent,
			});
		}

		return () => {
			app.dispatch({
				type: 'set_prominent_app_bar',
				payload: false,
			});
		};
	}, [prominent]);

	useEffect(() => {
		function scroll(e) {
			if (window.scrollY > 48 && !app.layout.prominentHidden) {
				app.dispatch({
					type: 'set_prominent_hidden',
					payload: true,
				});
			} else if (window.scrollY < 48 && app.layout.prominentHidden) {
				app.dispatch({
					type: 'set_prominent_hidden',
					payload: false,
				});
			}
		}
		if (prominent) {
			scroll();
			window.addEventListener('scroll', scroll);
		}

		return () => window.removeEventListener('scroll', scroll);
	}, [prominent, app.layout]);
	return (
		<Portal container={container ? container : app.layout.pageHeaderRef.current}>
			<Grid
				container
				alignItems="center"
			>
				{backFn && (
					<Grid item>
						<ActionIconButton
							tooltip={backTooltip}
							action="back"
							onClick={backFn}
							data-test-element={dataTestElement}
						/>
					</Grid>
				)}
				<Grid
					item
					xs
				>
					<Typography
						variant="h6"
						display="inline"
						component="div"
						tooltip={tooltip}
					>
						{title}
					</Typography>
					{moduleContext && !editPermission && showViewPermissionIndicator && (
						<NoPermissionChip
							module={module}
							subject={subject}
							permission={
								!editPermission && !viewPermission
									? permissionTypes.view
									: permissionTypes.edit
							}
						/>
					)}
				</Grid>
			</Grid>
		</Portal>
	);
}
