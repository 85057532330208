import React, { useContext, memo } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { useTranslation } from 'react-i18next';

import ListFilterContext from './ListFilterContext';
import CustomFilter from './CustomFilter';
import { ButtonAddList } from '../Layout';

function CustomFilterDialog({ open, onClose, ...props }) {
	const { t } = useTranslation();
	const { customFilters, customView, setCustomFilter } = useContext(ListFilterContext);

	return (
		<Dialog
			open={open}
			maxWidth={'md'}
			fullWidth={true}
			onClose={onClose}
		>
			<DialogTitle>{t('Custom filters')}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{t('Select datafields and values to filter on')}
				</DialogContentText>
				<div>
					{customFilters.map((filter, index) => (
						<CustomFilter
							{...filter}
							index={index}
							key={JSON.stringify(filter) + index}
						/>
					))}
				</div>
				<div>
					<ButtonAddList
						onClick={e =>
							setCustomFilter({
								action: 'add',
								custom: {
									type: 'datafield',
									identifier: '',
									value: [],
								},
							})
						}
						label={t`Add filter`}
					/>
					{!customView && <Button>{t('Save custom view')}</Button>}
				</div>
			</DialogContent>

			<DialogActions>
				<Button
					onClick={onClose}
					color="primary"
				>
					{t('OK')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default memo(CustomFilterDialog);
