import React, { useState } from 'react';

import ActionButton from './ActionButton';
import PaperPopper from './PaperPopper';
import MenuList from './MenuList';
import MenuItem from './MenuItem';

export default function SplitButton({
	options = [],
	initialSelectedIndex = 0,
	toggleSelected,
	...props
}) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(initialSelectedIndex);

	const handleMenuItemClick = (event, index) => {
		setAnchorEl(null);
		options[index]?.onClick(event);
		if (toggleSelected) {
			setSelectedIndex(index);
		}
	};

	return (
		<>
			<ActionButton
				onClick={options[selectedIndex]?.onClick}
				onClickMore={options.length > 1 ? e => setAnchorEl(e.currentTarget) : undefined}
				{...props}
			>
				{options[selectedIndex]?.label}
			</ActionButton>
			{options.length > 1 && (
				<PaperPopper
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
					disablePortal
					onClickAway={() => setAnchorEl(null)}
					placement="bottom-end"
				>
					<MenuList>
						{options.map((option, index) =>
							index !== selectedIndex ? (
								<MenuItem
									key={option.label}
									onClick={e => handleMenuItemClick(e, index)}
									data-track-event={option.dataTrackEvent}
								>
									{option.label}
								</MenuItem>
							) : null
						)}
					</MenuList>
				</PaperPopper>
			)}
		</>
	);
}
