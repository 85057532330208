import React, { useState, useEffect, Fragment } from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useImmer } from 'use-immer';

import { useAppContext } from '@/components/AppContext';
import { useDataSourceContext } from '@/components/DataSources';
import { PageHeader, AppBarLeftContent } from '@/components/App';

import TextAnalyticsProvider from './TextAnalyticsProvider';
import TextAnalyticsDashboard from './TextAnalyticsDashboard';
import TextAnalyticsLanguageConfig from './TextAnalyticsLanguageConfig';
import TextAnalyticsExclusionList from './TextAnalyticsExclusionList';

import useGetTextAnalyticsInfo from './useGetTextAnalyticsInfo';
import useGetTextAnalyticsWords from './useGetTextAnalyticsWords';
import useGetTextAnalyticGroups from './useGetTextAnalyticsGroups';
import useGetTextAnalyticsTop from './useGetTextAnalyticsTop';

import useGetTextAnalyticsProjectLanguages from './useGetTextAnalyticsProjectLanguages';

export default function TextAnalyticsManagement({ ...props }) {
	const { tab = 'dashboard' } = useParams();
	const { t } = useTranslation();
	const history = useHistory();
	const {
		app: {
			projects: {
				current: { id },
			},
		},
	} = useAppContext();
	const {
		datasource: { forms, datasets },
	} = useDataSourceContext();

	const [languagePerForm, languagePerFormLoading, languagePerFormError, loadLanguages] =
		useGetTextAnalyticsProjectLanguages(id);

	//survey_id 'undefined' is 'ALL DATA' TAproject
	const languagePerActiveForm = languagePerForm.results
		? languagePerForm.results.filter(project => {
				if (
					typeof project.survey_id === 'undefined' ||
					forms.asArray.find(form => Number(form.id) === Number(project.survey_id)) ||
					datasets.asArray.find(
						dataset => Number(dataset.id) === Number(project.survey_id)
					)
				) {
					return project;
				}
		  })
		: [];

	//use to show language alert, if length > 1
	const languagesInProject = languagePerActiveForm.reduce((languages, obj) => {
		if (languages.includes(obj.language) === false) {
			languages.push(obj.language);
		}
		return languages;
	}, []);

	const [currentTab, setCurrentTab] = useState(0);

	const [state, setState] = useImmer({
		project_id: '', //TA project id
		groups: 0,
		N: 0,
		survey_id: null,
	});

	const [data, loading] = useGetTextAnalyticsWords({
		params: {
			groups: state.groups,
			redis: 1,
			...(state.survey_id && {
				survey_id: state.survey_id,
			}),
		},
	});

	const [distData, distLoading] = useGetTextAnalyticGroups({
		params: {
			project_id: state.project_id,
			redis: 1,
			cutoff: '33,66',
			groups: state.groups,
			...(state.survey_id && {
				survey_id: state.survey_id,
			}),
		},
		settings: {
			wait: !state.project_id,
		},
	});

	const [topPosData, topPosLoading] = useGetTextAnalyticsTop({
		params: {
			project_id: state.project_id,
			redis: 1,
			order: 'pos',
			groups: state.groups,
			...(state.survey_id && {
				survey_id: state.survey_id,
			}),
		},
		settings: {
			wait: !state.project_id,
		},
	});

	const [topNegData, topNegLoading] = useGetTextAnalyticsTop({
		params: {
			project_id: state.project_id,
			redis: 1,
			order: 'neg',
			groups: state.groups,
			...(state.survey_id && {
				survey_id: state.survey_id,
			}),
		},
		settings: {
			wait: !state.project_id,
		},
	});

	const [topCountData, topCountLoading] = useGetTextAnalyticsTop({
		params: {
			project_id: state.project_id,
			redis: 1,
			order: 'count',
			groups: state.groups,
			...(state.survey_id && {
				survey_id: state.survey_id,
			}),
		},
		settings: {
			wait: !state.project_id,
		},
	});

	const [infoData, infoDataLoading] = useGetTextAnalyticsInfo({
		params: {},
		settings: {
			wait: !state.project_id,
		},
	});

	useEffect(() => {
		if (data.project_id) {
			setState(draft => {
				draft.project_id = data.project_id;
				draft.N = data.n;
				draft.noProject = false;
			});
		} else if (data.code == 302) {
			setState(draft => {
				draft.project_id = '';
				draft.N = 0;
				draft.noProject = true;
			});
		}
	}, [data]);

	useEffect(() => {
		setCurrentTab(tab === 'exclude' ? 2 : tab === 'language' ? 1 : 0);
	}, [tab]);

	return (
		<Fragment>
			<PageHeader
				title={t`reporting-text_analytics-management-title`}
				prominent
			/>
			<AppBarLeftContent tabs>
				<Tabs
					value={currentTab}
					onChange={(e, value) =>
						history.push(
							`/reporting/analytics/${
								value === 2 ? 'exclude' : value === 1 ? 'language' : 'dashboard'
							}`
						)
					}
				>
					<Tab label={t`reporting-text_analytics-management-tab_dashboard`} />
					<Tab label={t`reporting-text_analytics-management-tab_language`} />
					<Tab label={t`reporting-text_analytics-management-tab_exclusion`} />
				</Tabs>
			</AppBarLeftContent>
			<TextAnalyticsProvider
				value={{
					textAnalytics: {
						...state,
						loading,
						...data,
						distData,
						distLoading,
						pos: {
							data: topPosData,
							loading: topPosLoading,
						},
						neg: {
							data: topNegData,
							loading: topNegLoading,
						},
						count: {
							data: topCountData,
							loading: topCountLoading,
						},
						languagePerActiveForm: languagePerActiveForm,
						lastUpdated: infoData?.projects?.[0]?.last_run_time ?? null,
						status: infoData?.projects?.[0]?.status,
						api: {
							toggleGroup: force =>
								setState(draft => {
									draft.groups = typeof force !== 'undefined' ? force : !draft.groups;
								}),
							setDataSourceFilter: id => {
								setState(draft => {
									draft.survey_id = id === 'ALL_DATA' ? null : id;
								});
							},
							loadLanguages: loadLanguages,
						},
					},
				}}
			>
				{currentTab === 0 && (
					<TextAnalyticsDashboard languagesInProject={languagesInProject.length} />
				)}
				{currentTab === 1 && <TextAnalyticsLanguageConfig />}
				{currentTab === 2 && <TextAnalyticsExclusionList />}
			</TextAnalyticsProvider>
		</Fragment>
	);
}
