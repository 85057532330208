import React, { useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';

import useSaveDashboard from './useSaveDashboard';
import useDeleteCharts from './useDeleteCharts';
import { useDashboardContext } from './DashboardContextProvider';
import { getGrid } from './dashboardUtils';

const defaultLayout = { lg: [] };
const maxChartsOnDashboard = 15;

function reducer(draft, action) {
	switch (action.type) {
		case 'set_layout':
			try {
				const layout = getGrid(action.payload);
				draft.layout = layout?.layout ? layout.layout : defaultLayout;
				draft.prevLayout = layout?.layout ? layout.layout : defaultLayout;
				draft.forceSave = layout.legacyConverted;
			} catch (e) {}
			return;

		case 'undo_layout_changes':
			try {
				draft.layout = draft.prevLayout;
				draft.removedCharts = [];
				draft.hasChangesToLayout = false;
				draft.editMode = action?.payload?.editMode ?? false;
			} catch (e) {}
			return;

		case 'update_layout':
			draft.layout.lg = action.payload.layout;
			draft.hasChangesToLayout = true;
			return;

		case 'remove_from_layout':
			draft.layout.lg.splice(action.payload.index, 1);
			draft.removedCharts.push(action.payload.chart);
			draft.forceSave = action.payload.forceSave ?? false;
			return;

		case 'reset_removed_charts':
			draft.removedCharts = [];
			return;

		case 'set_quick_layout':
			const layoutColumns = 12;
			const gridItemHeight = 5;
			const columnsPerGridItem = Number(action.payload.value);
			const widthPerc = columnsPerGridItem / layoutColumns;

			try {
				draft.layout.lg.reduce(
					(layout, chart, index) => {
						const count = index + 1;

						chart.w = columnsPerGridItem;
						chart.x = (index * columnsPerGridItem) % layoutColumns;
						chart.h = gridItemHeight;
						chart.y = Math.floor(count * widthPerc) * gridItemHeight;

						layout.lg.push(chart);
						return layout;
					},
					{ lg: [] }
				);
				draft.hasChangesToLayout = true;
			} catch (e) {}
			return;

		case 'set_edit_mode':
			draft.editMode = action.payload.editMode;
			if (action.payload.editMode) {
				draft.prevLayout = draft.layout;
			} else {
				draft.prevLayout = defaultLayout;
			}
			return;

		case 'set_has_changes':
			draft.hasChangesToLayout = action.payload.hasChangesToLayout;
			draft.forceSave = action.payload.hasChangesToLayout;
			return;

		case 'set_new_dashboard':
			draft.newDashboard = action.payload.id;
			return;

		default:
			return;
	}
}

export default function useDashboard() {
	const { app } = useAppContext();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const { dashboardId } = useParams();
	const history = useHistory();

	const {
		dashboards,
		currentDashboardId,
		setCurrentDashboardId,
		loading,
		loadDashboards,
		newCharts,
		updateNewCharts,
		maxChartsMessage,
	} = useDashboardContext();

	const [state, dispatch] = useImmerReducer(reducer, {
		layout: defaultLayout,
		prevLayout: defaultLayout,
		editMode: false,
		hasChangesToLayout: false,
		removedCharts: [],
		forceSave: false,
		newDashboard: 0,
	});

	const currentDashboard = dashboards.find(
		dashboard => dashboard.id == state.newDashboard || dashboard.id == currentDashboardId
	);

	const { postForm: saveDashboard } = useSaveDashboard({
		id: currentDashboardId,
		layout: state.layout,
		onSuccess: () => {
			if (state.removedCharts.length) {
				enqueueSnackbar(
					!state.editMode
						? t`reporting-dashboard-snackbar-dashboard_saved_chart_deleted`
						: t`reporting-dashboard-snackbar-dashboard_saved`
				);
				deleteCharts.postForm();
			} else if (!state.forceSave) {
				enqueueSnackbar(t`reporting-dashboard-snackbar-dashboard_saved`);
			}
			dispatch({
				type: 'set_edit_mode',
				payload: {
					editMode: false,
				},
			});
			dispatch({
				type: 'set_has_changes',
				payload: {
					hasChangesToLayout: false,
					forceSave: false,
				},
			});
			loadDashboards();
		},
	});

	const deleteCharts = useDeleteCharts({
		chartIds: state.removedCharts.map(chart => chart.i),
		onSuccess: () => {
			dispatch({
				type: 'reset_removed_charts',
			});
		},
	});

	useEffect(() => {
		if (Number(dashboardId)) {
			setCurrentDashboardId(dashboardId);
		}
	}, [dashboardId]);

	useEffect(() => {
		if (!loading && dashboards.length && currentDashboard) {
			dispatch({
				type: 'set_layout',
				payload: currentDashboard,
			});
			if (state.newDashboard) {
				history.push(`/reporting/dashboard/${state.newDashboard}`);
				dispatch({
					type: 'set_new_dashboard',
					payload: {
						id: 0,
					},
				});
			}
		}
	}, [loading, dashboards, currentDashboard]);

	useEffect(() => {
		if (state.forceSave) {
			saveDashboard();
		}
	}, [state.forceSave]);

	useEffect(() => {
		if (
			Object.keys(dashboards).length > 0 &&
			!loading &&
			dashboardId &&
			app.projects.current.id &&
			dashboards.some(dashboard => dashboard.id == dashboardId) === false
		) {
			history.push(`/reporting/dashboard/list`);
		}
	}, [loading, dashboards, app.projects.current.id, dashboardId]);

	const maxChartsReached = dashboardId => {
		if (maxChartsMessage.includes(dashboardId)) {
			return false;
		}
		return chartsOnDashboard(dashboardId) >= maxChartsOnDashboard;
	};

	const chartsOnDashboard = dashboardId => {
		let numberOfCharts = 0;
		const dashboard = dashboards.find(dashboard => dashboard.id == dashboardId);

		if (!dashboard) return 0;

		try {
			const layout = JSON.parse(dashboard.react_layout);
			const chartsInLayout = layout.lg.map(chart => chart.i);
			numberOfCharts = chartsInLayout.length;
		} catch (e) {}
		return numberOfCharts;
	};

	return {
		...state,
		dashboards,
		loading,
		currentDashboard,
		currentDashboardId,
		newCharts,
		dashboardApi: {
			dispatch,
			saveDashboard,
			loadDashboards,
			maxChartsReached,
			chartsOnDashboard,
			updateNewCharts,
		},
	};
}
