import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';

import Collapse from '@material-ui/core/Collapse';
import { DataSourceItem, DataFieldItem } from '../DataSources';

import { EmptyState } from '../Layout';

import Tooltip from '@material-ui/core/Tooltip';

import Grid from '@material-ui/core/Grid';

import { AutoComplete } from '../AutoComplete';

const useStyles = makeStyles(theme => ({
	fillWidth: {
		width: '100%',
	},
	background: {
		background: '#bfffff',
	},
	spacing: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
}));

function AnonymizeList({
	surveylist,
	isAnonymized,
	updateAnonymized,
	urlParams,
	updateUrlParams,
}) {
	let staticVars = [6, 10, 15, 17, 19, 20];
	let staticSimpleVar = [
		'email',
		'phone',
		'phone2',
		'name',
		'firstname',
		'lastname',
		'title',
	];

	let optionalVars = [13, 29];
	let OptionalSimpleVar = ['input', 'textarea'];

	const { t } = useTranslation();
	const classes = useStyles();

	const [open, setOpen] = useImmer({});

	function toggleOpen(id) {
		setOpen(draft => {
			draft[id] = !!!draft[id];
		});
	}

	return (
		<Grid
			container
			className={classes.fillWidth}
		>
			<Grid
				item
				className={classes.fillWidth}
			>
				<Typography variant={'subtitle2'}>
					{surveylist.surveys.length ? (
						t('Which survey fields would you like to anonymize?')
					) : (
						<EmptyState
							size="small"
							primary={t('We found no surveys in this report')}
						/>
					)}
				</Typography>
			</Grid>
			<Grid
				item
				className={classes.fillWidth}
			>
				{Array.isArray(surveylist.surveys) &&
					surveylist.surveys.map((survey, index) => {
						const surveyUrlParams = Array.isArray(urlParams?.[survey.id]?.params)
							? urlParams?.[survey.id]?.params
							: [];

						return (
							<DataSourceItem
								key={index}
								title={survey.name}
								onClick={e => toggleOpen(survey.id)}
								isToggle
								isOpen={open[survey.id]}
								index={index}
							>
								<Collapse in={open[survey.id]}>
									<Grid>
										{surveylist.surveyfields[survey.id] &&
										!surveylist.surveyfields[survey.id].filter(field => {
											return (
												(staticVars.indexOf(field.var_type) > -1 &&
													staticSimpleVar.indexOf(field.system_var_simple) > -1) ||
												optionalVars.indexOf(field.var_type) > -1 ||
												OptionalSimpleVar.indexOf(field.system_var_simple) > -1 ||
												field.import_var.toLowerCase().match('website_data') !== null
											);
										}).length ? (
											<EmptyState
												size="small"
												primary={t(
													'No fields available for anonymization in this survey.'
												)}
											/>
										) : null}

										{surveylist.surveyfields[survey.id] &&
											surveylist.surveyfields[survey.id].map((field, index) => {
												if (
													staticVars.indexOf(field.var_type) > -1 &&
													staticSimpleVar.indexOf(field.system_var_simple) > -1
												) {
													return (
														<Tooltip
															key={field.id + field.system_var_simple}
															title={t('Anonymization of this element is standard')}
															aria-label={t('Anonymization of this element is standard')}
														>
															<div>
																<DataFieldItem
																	title={field.system_var}
																	type={field.system_var_simple}
																	id={field.id}
																	index={index}
																	sourceId={field.id}
																	field={field}
																	button
																	action={
																		<Checkbox
																			checked={true}
																			disabled
																		/>
																	}
																/>
															</div>
														</Tooltip>
													);
												}
											})}

										{surveylist.surveyfields[survey.id] &&
											surveylist.surveyfields[survey.id].map((field, index) => {
												if (
													optionalVars.indexOf(field.var_type) > -1 ||
													OptionalSimpleVar.indexOf(field.system_var_simple) > -1 ||
													field.import_var.toLowerCase().match('website_data') !== null
												) {
													return (
														<DataFieldItem
															key={field.id + field.system_var_simple}
															title={field.system_var}
															type={field.system_var_simple}
															id={field.id}
															onSelect={e =>
																updateAnonymized({ id: survey.id, field: field })
															}
															index={index}
															sourceId={field.id}
															field={field}
															button
															action={
																<Checkbox
																	checked={isAnonymized[field.id] == 29 ? true : false}
																	onClick={e =>
																		updateAnonymized({ id: survey.id, field: field })
																	}
																/>
															}
														/>
													);
												}
											})}
									</Grid>
									<Grid className={classes.spacing}>
										<AutoComplete
											multiple
											label={t(
												'Would you like to anonymize url parameters for this survey?'
											)}
											helperText={
												<Typography variant={'caption'}>
													{t('example:')} https://yourwebsite.com?
													<span className={classes.background}>customerID</span>=51965
												</Typography>
											}
											selectedItem={surveyUrlParams.map(field => {
												return { label: field, value: field };
											})}
											options={surveylist.possibles?.[survey.id]?.map(field => {
												return { label: field, value: field };
											})}
											onChange={e => updateUrlParams({ id: survey.id, params: e })}
										/>
									</Grid>
								</Collapse>
							</DataSourceItem>
						);
					})}
			</Grid>
		</Grid>
	);
}

export default AnonymizeList;
