import React, { useState } from 'react';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import { makeStyles } from '@/styles';

import { useTranslation } from 'react-i18next';

import { DrawerWithAppBar } from '@/components/App';
import {
	Typography,
	Loader,
	Grid,
	Chip,
	TextField,
	Menu,
	MenuItem,
	Card,
	ActionIconButton,
	Box,
	Divider,
} from '@/components/Layout';
import { useMetrics } from '@/components/Ajax';
import { dateFilterReducer, dateFilters } from '@/utils/date';

const useStyles = makeStyles(theme => ({
	drawer: {
		maxWidth: 600,
	},
	grid: {
		padding: theme.spacing(3),
	},
	card: {
		height: '100%',
		padding: theme.spacing(3),
	},
	icon: {
		fontSize: theme.typography.pxToRem(20),
	},
	value: {
		margin: theme.spacing(2, 1),
		fontWeight: theme.typography.fontWeightBold,
		textAlign: 'center',
	},
}));

function calc(a, b, fn, append = '') {
	const fallback = '-';
	try {
		if (Number(a) === 0 && Number(b) === 0) {
			return 0;
		}

		const value = fn(Number(a), Number(b));

		if (isNaN(value) || value < 0) {
			return fallback;
		}

		return value + append;
	} catch (e) {
		return fallback;
	}
}

export default function SurveyMetricsDrawer({ name, survey_key, isSdk, ...props }) {
	const { t } = useTranslation();
	const classes = useStyles();
	const [date, setDate] = useState('today');
	const [url, setUrl] = useState('');
	const [anchor, setAnchor] = useState(null);

	const { fromDate, toDate } = dateFilterReducer(date, 'yyyy-MM-dd', true);

	const completes = useMetrics({
		surveyKey: survey_key,
		type: 'completes',
		fromDate,
		toDate,
		url,
		wait: !props.open,
	});

	const shown = useMetrics({
		surveyKey: survey_key,
		type: 'shown',
		fromDate,
		toDate,
		url,
		wait: !props.open,
	});

	const metrics = [
		{
			label: t`data_collection-metrics_drawer-shown-label`,
			value: shown[0],
			loading: shown[1],
		},
		{
			label: t`data_collection-metrics_drawer-completed-label`,
			value: completes[0],
			loading: completes[1],
			tooltip: t`data_collection-metrics_drawer-completed-tooltip`,
		},
		{
			label: t`data_collection-metrics_drawer-closed_before_complete-label`,
			value: calc(shown[0], completes[0], (a, b) => a - b),
			loading: shown[1] || completes[1],
		},
		{
			label: t`data_collection-metrics_drawer-response_rate-label`,
			value: calc(
				completes[0],
				shown[0],
				(a, b) => {
					return Math.round((a / b) * 100);
				},
				'%'
			),
			loading: completes[1] || shown[1],
			tooltip: t`data_collection-metrics_drawer-response_rate-tooltip`,
		},
	];

	const refresh = () => {
		[completes, shown].forEach(request => {
			try {
				//refresh data
				request[3]({ extraParams: { refreshCache: true } });
			} catch (e) {}
		});
	};

	return (
		<DrawerWithAppBar
			size="medium"
			ToolbarProps={{
				text: t('Metrics - {{form}}', { form: name }),
			}}
			ToolbarContent={
				<>
					<ActionIconButton
						action="refresh"
						tooltip={t`data_collection-metrics_drawer-refresh_tooltip`}
						onClick={refresh}
					/>

					<TextField
						label={t`Filter on URL`}
						variant="filled"
						placeholder={t`Search URLs or part of a URL`}
						onChange={e => setUrl(e.target.value)}
						value={url}
						size="small"
					/>

					<Chip
						icon={<CalendarTodayIcon fontSize="small" />}
						label={t(dateFilters.find(({ action }) => action === date)?.label)}
						onClick={e => setAnchor(e.currentTarget)}
					/>

					<Menu
						open={Boolean(anchor)}
						anchorEl={anchor}
						onClose={() => setAnchor(null)}
					>
						{dateFilters
							.filter(({ action }) => !action.includes('year'))
							.map(({ action, label }) => (
								<MenuItem
									key={action}
									onClick={() => {
										setDate(action);
										setAnchor(null);
									}}
								>
									{t(label)}
								</MenuItem>
							))}
					</Menu>
				</>
			}
			{...props}
		>
			<Grid
				container
				spacing={5}
				className={classes.grid}
			>
				{metrics.map(metric => {
					return (
						<Grid
							item
							key={metric.label}
							lg={3}
							md={6}
							sm={6}
							xs={6}
						>
							<MetricsCard {...metric} />
						</Grid>
					);
				})}
			</Grid>
		</DrawerWithAppBar>
	);
}

function MetricsCard({ label, tooltip, value, sublabel, sublabel2, value2, loading }) {
	const classes = useStyles();
	return (
		<Card className={classes.card}>
			<Box mb={2}>
				<Typography
					variant="body2"
					color="textSecondary"
					display="inline"
					tooltip={tooltip}
				>
					{label}
				</Typography>
			</Box>
			{loading && (
				<Box
					display="flex"
					justifyContent="center"
				>
					<Loader
						className={classes.value}
						circular
						size={32}
					/>
				</Box>
			)}
			{!loading && (
				<Box
					display="flex"
					flexWrap="nowrap"
					justifyContent="space-around"
				>
					<Box textAlign="center">
						<Typography
							className={classes.value}
							variant="h4"
						>
							{['string', 'number'].indexOf(typeof value) > -1 ? value : '-'}
						</Typography>

						{sublabel && <Typography variant="caption">{sublabel}</Typography>}
					</Box>

					{typeof value2 !== 'undefined' && (
						<>
							<Divider
								orientation="vertical"
								flexItem
							/>
							<Box textAlign="center">
								<Typography
									className={classes.value}
									variant="h4"
								>
									{['string', 'number'].indexOf(typeof value2) > -1 ? value2 : '-'}
								</Typography>

								{sublabel2 && <Typography variant="caption">{sublabel2}</Typography>}
							</Box>
						</>
					)}
				</Box>
			)}
		</Card>
	);
}
