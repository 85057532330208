import React, { useContext } from 'react';
import AutoComplete from './AutoComplete';
import { AppContext } from '../AppContext';
import { useTranslation } from 'react-i18next';

/**
 * @deprecated use Users/UsersAutocomplete
 */
function UserAutoComplete({
	onChange = () => {},
	selectedItem = [],
	autocompleteLabel,
	onlyVerifiedUsers,
	userLevels = [],
	multiple = false,
	...props
}) {
	const {
		app: { users },
	} = useContext(AppContext);
	const { t } = useTranslation();

	const selectedItemsFound = [];

	let userArray = users.inProject.map(id => {
		const user = users.byKey[id];
		const userObject = user
			? {
					email: user.email,
					firstname: user.firstname,
					lastname: user.lastname,
					telephone: user.telephone,
					label: autocompleteLabel
						? user[autocompleteLabel]
						: user.firstname + ' ' + user.lastname,
					color: user.color,
					id: user.id,
					level: user.level,
					search: `${user.email} ${user.firstname} ${user.lastname}`,
			  }
			: {};

		return userObject;
	});

	if (selectedItem.length > 0) {
		selectedItem.forEach(selectedItemOption => {
			const [key, value] = Object.entries(selectedItemOption)[0];
			const userItem = userArray.find(user => user[key] === value);

			if (userItem) {
				selectedItemsFound.push(userItem);
			}
		});
	}

	function getOptions() {
		let options = userArray;
		if (onlyVerifiedUsers) {
			options = options.filter(user => Boolean(user.color));
		}
		if (userLevels.length > 0) {
			options = options.filter(user => userLevels.indexOf(user.level) !== -1);
		}
		return options;
	}

	const AutoCompleteProps = {
		...props,
		multiple: multiple,
		options: getOptions(),
		isUserSearch: true,
		disabled: users.inProject.length === 0,
		helperText:
			users.inProject.length === 0
				? t`Uh-oh, we couldn't find any users in this project. Please contact support for more information`
				: '',
		onChange: onChange,
		...(selectedItemsFound.length > 0 && { selectedItem: selectedItemsFound }),
	};

	return <AutoComplete {...AutoCompleteProps} />;
}

export default UserAutoComplete;
