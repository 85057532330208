import React, { useEffect } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';

import { Switch } from 'react-router-dom';

import { makeStyles } from '@/styles';
import AppMenuBar from './AppMenuBar';
import Route from './Route';
import AppLoader from './AppLoader';
import {
	constants as layoutConstants,
	MopinionLoader,
	Loader,
} from '@/components/Layout';
import LoggedInContent from './LoggedInContent';
import LoggedOutDialog from './LoggedOutDialog';
import { useAppContext } from '@/components/AppContext';
import { debounce } from '@/utils';
import {
	OnboardingLayout,
	InitialUserOnboarding,
	ExtraUserOnboarding,
	Onboarding,
} from '@/components/Onboarding';
import { SurveyPreviewPage } from '@/pages/data-collection';
import { PermissionContextProvider } from '@/components/Permission';
import { FeatureToggleContextProvider } from '@/components/FeatureToggle';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		minHeight: '100vh',
	},
	content: {
		flex: 1,
		width: `calc(100% - ${layoutConstants.drawerWidthClosed}px)`,
		marginLeft: layoutConstants.drawerWidthClosed,
		backgroundColor: theme.palette.background.default,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		...theme.mixins.mainPadding,
	},
	contentShift: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: layoutConstants.drawerWidth,
		width: `calc(100% - ${layoutConstants.drawerWidth}px)`,
	},
	appBarSpacer: {
		transition: theme.transitions.create('height'),
	},
	appbarSpacerNormal: {
		...theme.mixins.toolbarNormal('height'),
	},
	appBarSpacerProminent: {
		...theme.mixins.toolbarProminent('height'),
	},
}));

export default function LoggedInLayout() {
	const { app } = useAppContext();
	const classes = useStyles({ ...app.layout });

	const drawerType = debounce(e => {
		app.dispatch({ type: 'persistent_drawer', payload: window.innerWidth >= 1200 });
	}, 100);

	useEffect(() => {
		window.addEventListener('resize', drawerType);
		return () => window.removeEventListener('resize', drawerType);
	}, []);

	if (app.loading) {
		return <MopinionLoader />;
	}

	return (
		<PermissionContextProvider>
			<FeatureToggleContextProvider>
				<CssBaseline />
				<div
					className={classes.root}
					data-test-element="logged-in-root"
				>
					<Switch>
						{app.session.initial_onboarding && (
							<Route path="*">
								<OnboardingLayout>
									<InitialUserOnboarding />
								</OnboardingLayout>
							</Route>
						)}
						{app.session.extra_user_onboarding && (
							<Route path="*">
								<OnboardingLayout>
									<ExtraUserOnboarding />
								</OnboardingLayout>
							</Route>
						)}
						<Route path="/data-collection/survey/preview/:surveyKey?">
							<SurveyPreviewPage />
						</Route>

						<Route path="*">
							<AppMenuBar>
								<main
									className={`${classes.content} ${
										!app.session.onboarding_trial &&
										app.layout.mainMenuOpen &&
										app.layout.persistentDrawer
											? classes.contentShift
											: ''
									}`}
								>
									<div
										className={`
												${classes.appBarSpacer} 
												${app.layout.appBarProminent ? classes.appBarSpacerProminent : classes.appbarSpacerNormal}
											`}
									/>
									<AppLoader />
									<Onboarding />
									{app.layout.appLoadingFull ? (
										<Loader
											empty
											offset="40vh"
										/>
									) : (
										<LoggedInContent />
									)}
								</main>
							</AppMenuBar>
						</Route>
					</Switch>
				</div>
				<LoggedOutDialog />
			</FeatureToggleContextProvider>
		</PermissionContextProvider>
	);
}
