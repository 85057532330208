import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import { AppBarGlobals } from '../App';
import { Logo } from '../Layout';

import history from '../../history';

const styles = theme => ({
	grow: {
		flexGrow: 1,
	},
	children: {
		display: 'flex',
		alignItems: 'center',
		'& > *': {
			marginLeft: theme.spacing(1),
			display: 'flex',
			alignItems: 'center',
		},
	},
	logo: {
		width: '36px',
		verticalAlign: 'middle',
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(1),
	},
});

function SelectionToolbar({
	onClose,
	text = '1',
	children,
	classes,
	withLogo,
	back,
	showFilters = false,
	hideGlobals = true,
	hideFilters = [],
}) {
	const clickFn =
		typeof onClose === 'function'
			? onClose
			: back
			? () => {
					history.goBack();
			  }
			: () => {};

	return (
		<Toolbar>
			<IconButton
				//color="inherit"
				aria-label="Back"
				onClick={clickFn}
			>
				<CloseIcon />
			</IconButton>

			{withLogo && <Logo />}
			{
				// <IconButton
				//   color="inherit"
				//   aria-label="Back"
				//   onClick={clickFn}
				// >
				//   <ArrowBack />
				// </IconButton>
			}
			<Typography
				variant="h6"
				color="inherit"
				className={classes.grow}
			>
				{text}
			</Typography>

			<div className={classes.children}>
				{children}
				{!hideGlobals && (
					<AppBarGlobals
						showFilters={showFilters}
						hideFilters={hideFilters}
					/>
				)}
			</div>
		</Toolbar>
	);
}

export default withStyles(styles)(SelectionToolbar);
