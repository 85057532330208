import React, { useContext, Fragment, useState, useMemo, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import AutoComplete from './AutoComplete';
import Avatar from '@material-ui/core/Avatar';
import { useExternalData } from '../Ajax';
import { compare } from '../../utils';
import translations from '../../constants/translations.json';

function GoogleTranslateAutoComplete({ onChange, selectedItem = [], ...props }) {
	const options = translations.map(country => {
		const countryObj = {
			label: country.name,
			name: country.name,
			language: country.language,
		};

		return countryObj;
	});

	const AutoCompleteProps = {
		...props,
		options,
		onChange: language => onChange(language),
		selectedItem: selectedItem,
		disableCustomEntry: true,
	};

	return (
		<Fragment>
			<AutoComplete {...AutoCompleteProps} />
		</Fragment>
	);
}

export default GoogleTranslateAutoComplete;
