import React, { useState, Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import { Card, CardContent } from '@material-ui/core';
import {
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	ListItemSecondaryAction,
} from '@material-ui/core';

import { SocialIcon } from '../Icons';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import Tooltip from '@material-ui/core/Tooltip';

import { UpgradeDialog } from '../Billing';

import { useParams } from 'react-router-dom';

import SocialDialog from './SocialDialog';

const useStyles = makeStyles(theme => ({
	input: {
		height: theme.spacing(8.5),
	},
	margin: {
		marginRight: theme.spacing(2),
	},
}));

function SocialCard({ user, socialSettings }) {
	const { t } = useTranslation();
	const { google_email } = user;
	const { upgrade_for_social, social_google } = socialSettings;
	const [openUpgrade, setOpenUpgrade] = useState(false);

	const { socialGoogleEmail } = useParams();

	const [social, setSocial] = useState({
		Google: google_email,
	});

	const [type, setType] = useState(false);
	const [action, setAction] = useState(false);

	useEffect(() => {
		if (
			socialGoogleEmail &&
			sessionStorage.getItem('socialGoogleEmail') !== socialGoogleEmail
		) {
			updateSocial('Google', socialGoogleEmail);
			setType('Google');
			setAction('update');
		}
	}, []);

	function updateSocial(type, value) {
		setSocial({ ...social, [type]: value });
	}

	return (
		<Fragment>
			<Card>
				<CardContent>
					<Typography
						variant="subtitle1"
						component="h2"
						gutterBottom
					>
						{t`Social accounts`}
					</Typography>
					<List>
						{Object.keys(social).map((type, key) => {
							return (
								<ListItem key={key}>
									<ListItemIcon>
										<SocialIcon
											icon={type.toLowerCase()}
											fontSize="small"
										/>
									</ListItemIcon>
									<ListItemText
										primary={`${type} ${t`email`}`}
										secondary={
											!action && social[type] ? social[type] : t`No account linked`
										}
										primaryTypographyProps={{ variant: 'caption' }}
										secondaryTypographyProps={{ variant: 'body1' }}
									/>
									<ListItemSecondaryAction>
										{social[type] ? (
											<Fragment>
												<Tooltip title={t('Re-link account')}>
													<IconButton
														component="a"
														href={type === 'Google' ? social_google : '#'}
													>
														<LinkIcon />
													</IconButton>
												</Tooltip>
												<Tooltip title={t('Unlink account')}>
													<IconButton
														onClick={() => {
															setAction('unlink');
															setType(type);
														}}
													>
														<LinkOffIcon />
													</IconButton>
												</Tooltip>
											</Fragment>
										) : upgrade_for_social ? (
											<Button
												variant="contained"
												color="primary"
												component="a"
												onClick={() => setOpenUpgrade(true)}
												rel="noopener"
											>{t`Upgrade package`}</Button>
										) : (
											<Button
												variant="contained"
												color="primary"
												component="a"
												href={type === 'Google' ? social_google : '#'}
												rel="noopener"
											>
												{t`Link Account`}
											</Button>
										)}
									</ListItemSecondaryAction>
								</ListItem>
							);
						})}
					</List>
				</CardContent>
			</Card>
			{upgrade_for_social && (
				<Typography
					color="textSecondary"
					variant="caption"
				>{t`Social accounts are available for the Enterprise package`}</Typography>
			)}

			<SocialDialog
				action={action}
				type={type}
				email={social[type]}
				setAction={e => setAction(e)}
				setSocial={(type, value) => updateSocial(type, value)}
				storeInSession={socialGoogleEmail}
			/>
			<UpgradeDialog
				open={openUpgrade}
				onClose={() => setOpenUpgrade(false)}
			/>
		</Fragment>
	);
}

export default SocialCard;
