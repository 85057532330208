import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MarginWideIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			{
				<g>
					<g>
						<rect
							x="3.179"
							y="9.001"
							width="2"
							height="1"
						/>
						<path d="M30.894,10.001h-4.285v-1h4.285V10.001z M22.321,10.001h-4.285v-1h4.285V10.001z M13.75,10.001H9.465v-1h4.285V10.001z" />
						<rect
							x="35.179"
							y="9.001"
							width="2"
							height="1"
						/>
					</g>
					<g>
						<rect
							x="3.179"
							y="30.001"
							width="2"
							height="1"
						/>
						<path d="M30.894,31.001h-4.285v-1h4.285V31.001z M22.321,31.001h-4.285v-1h4.285V31.001z M13.75,31.001H9.465v-1h4.285V31.001z" />
						<rect
							x="35.179"
							y="30.001"
							width="2"
							height="1"
						/>
					</g>
					<polygon
						points="20.68,27.489 20.68,12.675 22.673,14.667 23.38,13.96 20.179,10.76 16.979,13.96 17.686,14.667 19.68,12.673 
						19.68,27.489 17.685,25.495 16.979,26.202 20.179,29.402 23.38,26.202 22.673,25.495 	"
					/>
				</g>
			}
		</SvgIcon>
	);
}
