import React from 'react';

import { useTranslation, Trans } from 'react-i18next';

import { makeStyles } from '@/styles';
import { Typography, Link } from '@/components/Layout';

import Explainer from './Explainer';

const useStyles = makeStyles(theme => ({
	link: {
		color: '#fff',
	},
}));

export default function SdkFormTypeExplainer() {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Explainer
			fontWeight="regular"
			title={t`data_collection-feedback_forms-editor-sdk_formtype_explainer-title`}
			content={
				<Trans
					i18nKey="data_collection-feedback_forms-editor-sdk_formtype_explainer-content"
					components={{
						psmall: <Typography mb="2" />,
						plarge: <Typography mb="4" />,
						italic: <i />,
						header: (
							<Typography
								fontWeight="medium"
								variant="subtitle"
							/>
						),
						helperLink: (
							<Link
								className={classes.link}
								href="https://mopinion.atlassian.net/wiki/spaces/KB/pages/209780740/Building+Feedback+Forms"
								variant="body1"
								target="_blank"
								rel="noopener"
							/>
						),
					}}
				/>
			}
		>
			{t`data_collection-feedback_forms-editor-sdk_formtype_explainer-label`}
		</Explainer>
	);
}
