import React, { Fragment, PureComponent } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import Slide from '@material-ui/core/Slide';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { withRouter } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import produce from 'immer';
import set from 'lodash.set';
import get from 'lodash.get';
import { withSnackbar } from 'notistack';

import { DataSourceList } from '@/components/DataSources';
import { Mjolnir } from '@/api';
import { withAppContext } from '@/components/AppContext';
import LoadChartSeries from './LoadChartSeries';
import ChartEditorSeries from './ChartEditorSeries';
import ChartEditorOptions from './ChartEditorOptions';
import ChartEditorChartTypes from './ChartEditorChartTypes';
import ChartEditorTemplates from './ChartEditorTemplates';
import {
	formatChart,
	serieBase,
	rnd,
	mapFilterArrayToString,
	prepareSaveChart,
	parseSeriesFromServer,
} from './chartUtils';
import { defaultDataExplorerChartOptions } from './defaultChartOptions';
import { builderDrawerWidth } from '@/components/Layout/constants';
import { strComp, debounce, tryParse } from '@/utils';
import { withDataSources } from '@/components/DataSources';
import ActiveSeriesChip from './ActiveSeriesChip';
import { Persist } from '@/components/Persist';
import { getRandomColor } from '@/styles';
import { HasPermission, NoPermissionTooltip } from '@/components/Permission';
import { AppBarActions, PageHeader, EmptyStateHandleHasSurveys } from '@/components/App';
import {
	Loader,
	EditableContent,
	ActionButton,
	SimpleDialog,
	Alert,
	KnowledgeBaseLink,
	Typography,
	ActionIconButton,
} from '@/components/Layout';
import {
	withDashboardContext,
	DashboardQuickSelection,
	DashboardAssignChart,
} from '@/components/Dashboards';

import { EFM } from '@/api';
import { xIsSameAsYSerieValue } from './constants';
import { withTranslation, Trans } from 'react-i18next';
import { AjaxForm } from '@/components/Ajax';

const styles = theme => ({
	wrap: {
		...theme.mixins.cancelMainPadding,
		display: 'flex',
		height: '100%',
	},
	chartAreaWrap: {
		...theme.mixins.mainPadding,
		flexGrow: 1,
		minWidth: 0,
	},
	chartAreaMargin: {
		marginRight: builderDrawerWidth,
	},
	drawerPaper: {
		width: builderDrawerWidth,
		position: 'fixed',
		zIndex: theme.zIndex.appBar - 1,
		bottom: 0,
		...JSON.parse(JSON.stringify(theme.mixins.toolbar).replace(/minHeight/g, 'top')),
		height: 'auto',
		overflowX: 'hidden',
		overflowY: 'auto',
	},
	appBar: {
		background: '#303031',
	},
	isDraggingOverHighlight: {
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		background: theme.palette.action.hover,
	},
	tabRoot: {
		minWidth: 60,
	},
	fillHeight: {
		height: '100%',
	},
	surveyDataSwitch: {
		height: '100%',
		textAlign: 'center',
	},
	editButtonIcon: {
		fontSize: theme.typography.pxToRem(14),
	},
	searchIconLarge: {
		fontSize: theme.typography.pxToRem(48),
		marginBottom: theme.spacing(2),
	},
	switchMessageBtns: {
		margin: theme.spacing(0.5),
	},
	fillAndCenter: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: 'calc(100% - 56px)',
	},
	fillWidth: {
		width: '100%',
	},
	selectRouteHeader: {
		marginBottom: theme.spacing(5),
	},
	selectCards: {
		//	height:250
	},
	chartCard: {
		//		height:'100%',
		position: 'relative',
		marginBottom: theme.spacing(3),
	},
	fabIcon: {
		marginRight: theme.spacing(1),
	},
	fab: {
		position: 'fixed',
		bottom: theme.spacing(3),
		right: theme.spacing(3),
		zIndex: theme.zIndex.appBar,
	},
	chartDiv: {
		minHeight: 400,
		height: '65vh',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
	},
	activeSerieChip: {
		margin: theme.spacing(0.25),
	},
	buttonWrap: {
		textAlign: 'center',
	},
	snackbar: {
		top: theme.spacing(10),
	},
	alert: {
		color: '#fff',
		marginLeft: theme.spacing(1),
	},
});

function allowedToCompareY(var_type) {
	if (!var_type) return null;

	switch (var_type) {
		case 36:
		case 37:
		case 39:
			return [36, 37, 39];
		default:
			return [var_type];
	}
}

class ChartEditor extends PureComponent {
	static defaultProps = {};

	state = {
		chart: {},
		series: [],
		serieRefs: [],
		chartType: 1,
		toDashboard: false,
		tabValue: 0,
		currentSurvey: '',
		dataFieldAnchor: null,
		unsavedChanges: false,
		dashboard: null,
		selectedDashboardId: null,
	};

	chartDiv = React.createRef();
	innerDrawerRef = React.createRef();
	chartEditorScrollerChild = React.createRef();

	componentDidCatch(error, info) {
		console.log(error, info);
	}

	removeSerie = serieIndex => {
		this.setState(
			produce(draft => {
				draft.series.splice(serieIndex, 1);
			})
		);

		//purposely separate state change here to seperate the logic when we're refactoring to hooks
		this.setState({ unsavedChanges: true });
	};

	removeAllSeries = () => {
		this.setState(
			produce(draft => {
				draft.series = [];
			})
		);

		//purposely separate state change here to seperate the logic when we're refactoring to hooks
		this.setState({ unsavedChanges: true });
	};

	getInitialSerieSettings = (field, color) => {
		const { datasource } = this.props;
		const allFields = datasource.api.getFieldArray(field.surveyId);

		//1=input,2=textarea,3=radio,5=select, 13=category, 16=gcr
		//in this case -> offset against self
		if ([1, 2, 3, 5, 13, 16].includes(Number(field.survey_block?.type))) {
			return {
				x: field.id,
			};
		}

		//checkbox
		if (Number(field.survey_block?.type) === 4) {
			return {
				x: 0,
				color,
			};
		}

		//11=matrix, 12=likert
		if ([11, 12].includes(Number(field.survey_block?.type))) {
			return {
				x: xIsSameAsYSerieValue,
				color,
			};
		}

		const dateField = allFields.find(fieldObj => strComp(fieldObj.var_type, 7)) || {};
		return dateField.id
			? {
					x: dateField.id,
					date_group: 'month',
					custom: {
						date_format: '*b',
						date_group: 'month',
					},
			  }
			: {
					x: field.id,
			  };
	};

	handleAddSerie = (field = {}) => {
		if (field.mergedFields) {
			this.setState({ multipleSeriePerQuestionAlert: field.niceType.toLowerCase() });
			const color = getRandomColor();
			field.mergedFields.forEach(field => {
				if (field.active) {
					this.addSerie(field, color);
				}
			});
			return;
		}
		this.addSerie(field);
	};

	addSerie = (df, color) => {
		const { datasource, enqueueSnackbar, t } = this.props;
		const { series } = this.state;

		if (
			get(series, '[0].survey_id', false) &&
			strComp(get(series, '[0].survey_id', ''), df.surveyId)
		) {
			this.setState(
				produce(draft => {
					draft.series.push(serieBase(df, { ...series[0] }));
					draft.chart.chart.seriesData = draft.series;
				})
			);
		} else if (series.length === 0) {
			this.setState(
				produce(draft => {
					draft.series.push(serieBase(df, this.getInitialSerieSettings(df, color)));
					draft.chart.chart.seriesData = draft.series;
				})
			);
			//1=input,2=textarea,3=radio,5=select,11= matrix,12=likert,13=category, 16=gcr
			if ([1, 2, 3, 4, 5, 11, 12, 13, 16].includes(Number(df.survey_block?.type))) {
				this.updateChartType('column');
			}
		} else {
			enqueueSnackbar(t`reporting-chart_editor-snackbar-source_multiple`);

			//we must find a matching x value to current value
			const currentXField = datasource.api.getField(series[0].x);

			const newXField =
				datasource.api
					.getFieldArray(df.surveyId)
					.find(
						field =>
							field.var_type == currentXField?.var_type &&
							field.block_type == currentXField.block_type
					) ??
				datasource.api
					.getFieldArray(df.surveyId)
					.find(field => field.var_type == currentXField?.var_type);

			if (newXField || series[0].x === xIsSameAsYSerieValue) {
				this.setState(
					produce(draft => {
						draft.series.push(
							serieBase(df, {
								...series[0],
								x:
									series[0].x === xIsSameAsYSerieValue
										? xIsSameAsYSerieValue
										: newXField.id,
							})
						);

						draft.chart.chart.seriesData = draft.series;
					})
				);
			} else {
				const survey = datasource.api.getSource(df.id);
				enqueueSnackbar(
					t(`reporting-chart_editor-snackbar-match`, { surveyName: survey.name })
				);
			}

			//	this.setState({changedSurveyId:true, tempNewSeries:serieBase(df, this.getSeriesInitialDateSettings(df))});
		}

		//purposely separate state change here to seperate the logic when we're refactoring to hooks
		this.setState({ unsavedChanges: true });
	};

	updateSerie = ({ obj = {}, custom = {}, index, reload = true }) => {
		this.setState(
			produce(draft => {
				Object.assign(draft.series[index], obj);
				Object.assign(draft.series[index].custom, custom);
				draft.chart.chart.seriesData = draft.series;
			})
		);

		//purposely separate state change here to seperate the logic when we're refactoring to hooks
		this.setState({ unsavedChanges: true });
	};

	orderSeries = (fromIndex, toIndex) => {
		this.setState(
			produce(draft => {
				const serieToMove = draft.series[fromIndex];
				draft.series.splice(fromIndex, 1);
				draft.series.splice(toIndex, 0, serieToMove);
				draft.chart.chart.seriesData = draft.series;
			})
		);
	};

	debouncedReloadChartWithNewOptions = debounce(() => {
		this.setState(
			produce(draft => {
				draft.shouldReload = rnd();
			})
		);
	}, 300);

	startWithNewSeries = () => {
		this.setState(
			produce(draft => {
				draft.series = [draft.tempNewSeries];
				draft.tempNewSeries = {};
				draft.changedSurveyId = false;
				draft.chart.chart.seriesData = draft.series;
			})
		);

		//purposely separate state change here to seperate the logic when we're refactoring to hooks
		this.setState({ unsavedChanges: true });
	};

	cancelStartNewSeries = () => {
		this.setState(
			produce(draft => {
				draft.tempNewSeries = {};
				draft.changedSurveyId = false;
			})
		);
	};

	addExtraFilter = index => {
		this.setState(
			produce(draft => {
				draft.series[index].filter_key.push('');
				draft.series[index].filter_value.push([]);
				draft.chart.chart.seriesData = draft.series;
			})
		);

		//purposely separate state change here to seperate the logic when we're refactoring to hooks
		this.setState({ unsavedChanges: true });
	};

	removeExtraFilter = (index, filterIndex, isTags) => {
		this.setState(
			produce(draft => {
				draft.series[index].filter_key.splice(filterIndex, 1);
				draft.series[index].filter_value.splice(filterIndex, 1);

				draft.series[index].custom.extra_filter = mapFilterArrayToString(
					draft.series[index].filter_key,
					draft.series[index].filter_value
				);

				if (isTags) {
					draft.series[index].custom.filter_tags = '';
				}
				draft.chart.chart.seriesData = draft.series;
			})
		);

		//purposely separate state change here to seperate the logic when we're refactoring to hooks
		this.setState({ unsavedChanges: true });
	};

	updateExtraFilter = (index, filterIndex, { filter_key, filter_value, tags }) => {
		this.setState(
			produce(draft => {
				if (typeof filter_key !== 'undefined') {
					draft.series[index].filter_key[filterIndex] = filter_key;
				} else if (typeof filter_value !== 'undefined') {
					draft.series[index].filter_value[filterIndex] = filter_value;
				} else if (tags) {
					draft.series[index].filter_value[filterIndex] = [];
					draft.series[index].custom.filter_tags = tags;
				}

				draft.series[index].custom.extra_filter = mapFilterArrayToString(
					draft.series[index].filter_key,
					draft.series[index].filter_value
				);
				draft.chart.chart.seriesData = draft.series;
			})
		);

		//purposely separate state change here to seperate the logic when we're refactoring to hooks
		this.setState({ unsavedChanges: true });
	};

	updateChartOptions = (path, value, convertEmptyToNull = false, reload) => {
		this.setState(
			produce(draft => {
				set(draft.chart, path, value === '' && convertEmptyToNull ? null : value);
				draft.chart = formatChart(draft.chart);
			}),
			() => {
				if (reload) this.debouncedReloadChartWithNewOptions();
			}
		);

		//purposely separate state change here to seperate the logic when we're refactoring to hooks
		this.setState({ unsavedChanges: true });
	};

	updateChartType = type => {
		const willBeBarType = ['bar', 'column', 'pie'].includes(type);
		const wasBarType = ['bar', 'column', 'pie'].includes(
			get(this.state, 'chart.chart.type')
		);
		const wasLineType = ['line', 'area', 'areaspline'].includes(
			get(this.state, 'chart.chart.type')
		);
		const wasScore = this.state.chartType == 2;

		let needsReload = false;
		this.setState(
			produce(draft => {
				if ((!wasScore && type === 'score') || (wasScore && type !== 'score')) {
					needsReload = true;
				}

				set(draft.chart, 'chart.type', type);
				draft.chartType = type !== 'score' ? 1 : 2;
				draft.chart = formatChart(draft.chart);
			}),
			e => {
				if (needsReload) {
					this.debouncedReloadChartWithNewOptions();
				}

				// if (willBeBarType) {

				// 	this.state.series.forEach((serie,index) => {
				// 		this.updateSerie({index, obj: {x:}})
				// 	})
				// }
			}
		);

		//purposely separate state change here to seperate the logic when we're refactoring to hooks
		this.setState({ unsavedChanges: true });
	};

	updateChartTitles = (value, type) => {
		this.setState(
			produce(draft => {
				draft.chart[type].text = value;
			})
		);

		//purposely separate state change here to seperate the logic when we're refactoring to hooks
		this.setState({ unsavedChanges: true });
	};

	scrollToSerie = index => {
		this.setState({ tabValue: 1 }, () => {
			try {
				const scroller = this.chartEditorScrollerChild.current.parentNode;
				const y = this.chartEditorScrollerChild.current.querySelector(
					`[data-serie-card="${index}"]`
				).offsetTop;
				scroller.scrollTo({ top: y, behavior: 'smooth' });
			} catch (e) {}
		});
	};

	loadChart = () => {
		const {
			chartId,
			app: { domain, projects, tokens },
			dataExplorer,
			datasource,
		} = this.props;

		Mjolnir.get(`chart/${domain}/${projects.current.id}/${chartId}`).then(r => {
			if (r.highchart && typeof r.highchart === 'object' && r.data) {
				this.setState(
					produce(draft => {
						draft.chart = formatChart(r.highchart);
						draft.series = parseSeriesFromServer(r.data.series);
						draft.chart.chart.seriesData = draft.series;
						draft.chartType = r.data.type;
					})
				);
			}
		});
	};

	componentDidMount() {
		const {
			chartId,
			app: { domain, projects, tokens },
			dataExplorer,
			datasource,
			history,
		} = this.props;

		datasource.api.loadSurveys();

		if (chartId && chartId !== 'new') {
			//get chart config
			this.loadChart();
		} else if (dataExplorer || chartId === 'new') {
			this.setState(
				produce(draft => {
					if (!draft.hasPersistedData) {
						draft.chart = formatChart(Object.assign({}, defaultDataExplorerChartOptions));
						draft.chartType = 1;
					}
				})
			);
		}
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.chartId &&
			prevProps.chartId !== this.props.chartId &&
			this.props.chartId !== 'new'
		) {
			this.loadChart();
		}
		if (
			prevProps?.app?.projects?.current?.uuid !== this.props?.app?.projects?.current?.uuid
		) {
			this.setState(
				produce(draft => {
					draft.unsavedChanges = false;
					draft.chart = formatChart(Object.assign({}, defaultDataExplorerChartOptions));
					draft.chartType = 1;
					draft.series = [];
					draft.serieRefs = [];
					draft.dataFieldAnchor = null;
				})
			);
		}
	}

	appBarTitle = () => {
		const { dataExplorer, chartId, t } = this.props;
		const { selectedRouteType } = this.state;

		if (dataExplorer) return t('Data explorer');
		if (chartId === 'new') {
			if (!selectedRouteType) {
				return t('Create a chart');
			} else if (selectedRouteType === 'template') {
				return t('Create from template');
			} else {
				return t('Build chart');
			}
		}

		return t('Edit chart');
	};

	backButton = discardChanges => {
		const { unsavedChanges, selectedRouteType } = this.state;
		const { chartId, history, currentDashboardId, dataExplorer } = this.props;
		if (dataExplorer) {
			return null;
		}

		if (discardChanges || !unsavedChanges) {
			return history.push(`/reporting/dashboard/${currentDashboardId}`);
		}

		if (unsavedChanges) {
			this.setState({ showUnsavedChangesDialog: true });
		}
	};

	isCheckboxChart = () => {};

	getCustomMatrixOrLikertFieldIfChartFromSingleBlock = () => {
		const { datasource } = this.props;
		const { series } = this.state;

		const baseField = datasource.api.getField(series[0]?.y);
		const isSingleField =
			series.length > 0 &&
			series.length ===
				series.filter(serie => {
					const field = datasource.api.getField(serie.y);
					//11=matrix, 12=likert
					return (
						[11, 12].includes(Number(field?.survey_block?.type)) &&
						baseField?.survey_block?.id === field?.survey_block?.id
					);
				}).length;

		return isSingleField;
	};

	render() {
		const {
			chart,
			series,
			chartType,
			tabValue,
			isLoading,
			changedSurveyId,
			shouldReload,
			selectedRouteType,
			backFn,
			unsavedChanges,
			showUnsavedChangesDialog,
			multipleSeriePerQuestionAlert,
			selectedDashboardId,
		} = this.state;

		const {
			classes,
			dataExplorer,
			isPage,
			chartId,
			theme,
			t,
			app,
			dashboards,
			currentDashboardId,
			loadDashboards,
			updateNewCharts,
			history,
			datasource,
			enqueueSnackbar,
			closeSnackbar,
		} = this.props;

		const appBarProps = {
			text: this.appBarTitle(),
			hideFilters: ['project', 'organisation'],
			showFilters:
				chartId !== 'new' || (selectedRouteType && selectedRouteType !== 'template'),
			onClose: typeof backFn === 'function' ? backFn : '',
		};

		const currentYField = datasource.api.getField(series[0]?.y);

		const surveysInSeries = [...new Set(series.map(serie => serie.survey_id))];

		return (
			<HasPermission>
				{hasEditPermission => (
					<Fragment>
						<Fragment>
							{hasEditPermission && chartId === 'new' && !selectedRouteType ? (
								<div className={classes.fillAndCenter}>
									<PageHeader
										documentTitle={t`reporting-create_chart_flow-pageheader-create_chart`}
										title={
											<>
												<DashboardQuickSelection readOnly />
												<span> / </span>
												{t`reporting-create_chart_flow-pageheader-create_chart`}
											</>
										}
										onBack={() => this.backButton()}
										backTooltip={t`reporting-create_chart_flow-pageheader-tooltip_to_dashboard`}
									/>

									<div className={classes.fillWidth}>
										<Typography
											variant="h6"
											align="center"
											// className={classes.selectRouteHeader}
										>{t`reporting-create_chart_flow-chart_type_selection-create_chart_title`}</Typography>
										<Typography
											variant="subtitle1"
											color="textSecondary"
											align="center"
											className={classes.selectRouteHeader}
										>
											{t`reporting-create_chart_flow-chart_type_selection-create_chart_subtitle`}
										</Typography>

										<Grid
											container
											spacing={3}
											justify="center"
											alignItems="center"
										>
											<Grid
												item
												xs={4}
											>
												<Slide
													in
													direction="up"
													timeout={theme.transitions.duration.enteringScreen * 1.5}
												>
													<Card className={classes.selectCards}>
														<CardActionArea
															className={classes.fillHeight}
															onClick={() =>
																this.setState({
																	selectedRouteType: 'template',
																	backFn: () => {
																		this.setState({ selectedRouteType: '' });
																	},
																})
															}
														>
															<div style={{ padding: 24 }}>
																<CardMedia
																	image="/assets/img/r/chart-templates.svg"
																	style={{ height: 100, backgroundSize: 'contain' }}
																/>
															</div>
															<CardContent>
																<Typography
																	gutterBottom
																	variant="subtitle2"
																>
																	{t`reporting-create_chart_flow-chart_type_selection-card_template_title`}
																</Typography>

																<Typography color="textSecondary">
																	{t`reporting-create_chart_flow-chart_type_selection-card_template_subtitle`}
																</Typography>
															</CardContent>
														</CardActionArea>
													</Card>
												</Slide>
											</Grid>
											<Grid
												item
												xs={4}
											>
												<Slide
													in
													direction="up"
													timeout={theme.transitions.duration.enteringScreen * 2}
												>
													<Card className={classes.selectCards}>
														<CardActionArea
															className={classes.fillHeight}
															data-track-event="dashboard_chart_chart_from_scratch"
															data-test-element="create-chart-from-scratch"
															onClick={() =>
																this.setState({
																	selectedRouteType: 'editor',
																	backFn: () => {
																		this.setState({ selectedRouteType: '' });
																	},
																})
															}
														>
															<div style={{ padding: 24 }}>
																<CardMedia
																	image="/assets/img/r/chart-from-scratch.svg"
																	style={{ height: 100, backgroundSize: 'contain' }}
																/>
															</div>
															<CardContent>
																<Typography
																	gutterBottom
																	variant="subtitle2"
																>
																	{t`reporting-create_chart_flow-chart_type_selection-card_from_scratch_title`}
																</Typography>

																<Typography color="textSecondary">
																	{t`reporting-create_chart_flow-chart_type_selection-card_from_scratch_subtitle`}
																</Typography>
															</CardContent>
														</CardActionArea>
													</Card>
												</Slide>
											</Grid>
										</Grid>
									</div>
								</div>
							) : selectedRouteType === 'template' ? (
								<div className={classes.fillAndCenter}>
									<ChartEditorTemplates
										resetSelectedRoute={() => this.setState({ selectedRouteType: '' })}
									/>
								</div>
							) : (
								<div
									className={classes.wrap}
									data-test-element={
										chartId === 'new' || dataExplorer
											? 'new-chart-wra['
											: 'edit-chart-wrap'
									}
								>
									<PageHeader
										documentTitle={
											dataExplorer
												? t`reporting-chart_editor-pageheader-explorer`
												: chartId === 'new'
												? t`reporting-chart_editor-pageheader-new`
												: t`reporting-chart_editor-pageheader-edit`
										}
										title={
											dataExplorer ? (
												t`reporting-chart_editor-pageheader-explorer`
											) : (
												<>
													<DashboardQuickSelection readOnly />
													<span> / </span>
													{chartId === 'new'
														? t`reporting-chart_editor-pageheader-new`
														: t`reporting-chart_editor-pageheader-edit`}
												</>
											)
										}
										onBack={
											!dataExplorer ? () => this.backButton(!hasEditPermission) : null
										}
										backTooltip={t`reporting-chart_editor-pageheader-tooltip_to_dashboard`}
										dataTestElement="editChartBack"
									/>
									<AppBarActions>
										<AjaxForm
											type="mjolnir"
											url={`chart/${app.domain}/${app.projects.current.id}${
												chartId !== 'new' && chartId ? '/' + chartId : ''
											}`}
											data={prepareSaveChart({
												...chart,
												series: [...series],
												type: chartType,
											})}
											onSuccess={response => {
												if (chartId === 'new' || dataExplorer) {
													const action = snackbarId => (
														<>
															<ActionButton
																onClick={() => {
																	closeSnackbar(snackbarId);
																	setTimeout(() => {
																		window.scrollTo({
																			top: document.body.scrollHeight,
																			behavior: 'smooth',
																		});
																	}, 1000);
																	history.push(
																		`/reporting/dashboard/${selectedDashboardId}`
																	);
																}}
																dataTrackEvent="dashboard_chart_explorer_go_to_dashboard"
																action="nav_to"
																className={classes.alert}
															>
																{t('view on dashboard {{name}}', {
																	name: dashboards.find(
																		dashboard => dashboard.id == selectedDashboardId
																	)?.name,
																})}
															</ActionButton>
															<ActionIconButton
																onClick={() => closeSnackbar(snackbarId)}
																action="close"
																className={classes.alert}
															/>
														</>
													);
													enqueueSnackbar(t`reporting-chart_editor-snackbar-edit`, {
														action,
													});

													EFM.post('/dashboard/ajax/add-chart', {
														dashboardId: selectedDashboardId,
														chartId: response.id,
													}).then(response => {
														if (response.code === 200) {
															loadDashboards();
														}
													});
													updateNewCharts(response.id);
													this.props.history.push('/reporting/chart/' + response.id);
												} else {
													this.props.enqueueSnackbar(
														t`reporting-chart_editor-snackbar-save`
													);
												}

												this.setState({ unsavedChanges: false });
											}}
										>
											{({ clicked, loading, postForm }) => (
												<div className={classes.buttonWrap}>
													{unsavedChanges || chartId === 'new' || dataExplorer ? (
														<Fragment>
															<NoPermissionTooltip>
																{dataExplorer ? (
																	<DashboardAssignChart
																		onChange={e => {
																			this.setState({
																				selectedDashboardId: e,
																				unsavedChanges: false,
																			});
																			postForm();
																		}}
																		disabled={
																			dashboards.length === 0 ||
																			series.length === 0 ||
																			loading
																		}
																		title={t`add to`}
																	/>
																) : (
																	<ActionButton
																		color="secondary"
																		variant="contained"
																		action="save"
																		label={t`reporting-chart_editor-pageheader-button_save_chart`}
																		dataTrackEvent={
																			dataExplorer || chartId === 'new'
																				? 'dashboard_chart_created'
																				: 'dashboard_chart_saved'
																		}
																		loading={loading}
																		onClick={e => {
																			this.setState({
																				selectedDashboardId: currentDashboardId,
																			});
																			postForm();
																		}}
																		disabled={!hasEditPermission}
																		data-test-element="save-new-chart"
																	/>
																)}
															</NoPermissionTooltip>
														</Fragment>
													) : (
														<Fragment>
															<ActionButton
																color="primary"
																variant="contained"
																action="save"
																size="medium"
																disabled
																label={t`reporting-chart_editor-pageheader-button_chart_saved`}
																loading={loading}
															/>
														</Fragment>
													)}
												</div>
											)}
										</AjaxForm>
									</AppBarActions>
									{dataExplorer && (
										<Persist
											name="data-explorer"
											data={{ state: this.state }}
											onMount={data => {
												if (typeof data.state === 'object' && data.state.unsavedChanges) {
													this.setState(
														produce(draft => {
															draft.hasPersistedData = true;
															Object.keys(data.state).forEach(key => {
																draft[key] =
																	key === 'chart'
																		? formatChart(data.state[key])
																		: data.state[key];
															});
														})
													);
												}
											}}
										/>
									)}
									<DragDropContext
										onDragEnd={e => {
											const { source, destination = {} } = e;
											if (destination && destination.droppableId === 'chart-area') {
												const field = tryParse(e.draggableId);
												this.handleAddSerie(field);
											}
										}}
									>
										{datasource.forms.asArray.length > 0 && (
											<Drawer
												variant="permanent"
												anchor="right"
												classes={{
													paper: classes.drawerPaper,
												}}
											>
												<div ref={this.innerDrawerRef}>
													<Tabs
														value={tabValue}
														onChange={(e, value) => this.setState({ tabValue: value })}
														variant="fullWidth"
													>
														<Tab
															label={t`reporting-chart_editor-tab_data-tab_label`}
															classes={{
																root: classes.tabRoot,
															}}
														/>
														<Tab
															label={t`reporting-chart_editor-tab_series-tab_label`}
															classes={{
																root: classes.tabRoot,
															}}
														/>
														<Tab
															label={t`reporting-chart_editor-tab_type-tab_label`}
															classes={{
																root: classes.tabRoot,
															}}
														/>
														<Tab
															label={t`reporting-chart_editor-tab_options-tab_label`}
															classes={{
																root: classes.tabRoot,
															}}
														/>
													</Tabs>
												</div>
												<SwipeableViews
													axis="x"
													index={tabValue}
													onChangeIndex={index => this.setState({ tabValue: index })}
													containerStyle={{
														maxHeight: '100%',
													}}
												>
													<div>
														<DataSourceList
															withFields
															dragFields
															selectedSurveys={surveysInSeries}
															addIndicators={series.map(serie => ({
																id: String(serie.y),
																color: serie.color,
															}))}
															scaleInAnimation
															DataFieldListProps={{
																inset: true,
																onSelect: (field, e) => {
																	if (
																		!allowedToCompareY(currentYField?.var_type) ||
																		allowedToCompareY(currentYField?.var_type).indexOf(
																			field.var_type
																		) > -1
																	) {
																		const { target } = e;
																		this.setState({
																			dataFieldAnchor: target,
																			menuDataField: field,
																		});
																	} else {
																		enqueueSnackbar(
																			t`reporting-chart_editor-snackbar-compare`
																		);
																	}
																},
																hideVarTypes: [5, 7],
																allowedVarTypes: allowedToCompareY(
																	currentYField?.var_type
																),
															}}
														/>
														<Menu
															open={Boolean(this.state.dataFieldAnchor)}
															anchorEl={this.state.dataFieldAnchor}
															onClose={() =>
																this.setState({
																	dataFieldAnchor: null,
																	menuDataField: null,
																})
															}
														>
															{!this.state.menuDataField?.mergedFields && (
																<MenuItem
																	onClick={() => {
																		this.handleAddSerie(this.state.menuDataField);
																		this.setState({
																			dataFieldAnchor: null,
																			menuDataField: null,
																		});
																	}}
																>
																	{t(
																		'reporting-chart_editor-tab_data-merged_fields_add_to_chart',
																		{
																			field:
																				this.state.menuDataField?.title ??
																				this.state.menuDataField?.system_var ??
																				'',
																		}
																	)}
																</MenuItem>
															)}
															{this.state.menuDataField?.mergedFields && [
																<MenuItem
																	onClick={() => {
																		this.handleAddSerie(this.state.menuDataField);
																		this.setState({
																			dataFieldAnchor: null,
																			menuDataField: null,
																		});
																	}}
																>
																	{t`reporting-chart_editor-tab_data-merged_fields_add_all_active`}
																</MenuItem>,
																...[...this.state.menuDataField.mergedFields]
																	.sort((a, b) => b.active - a.active)
																	.map(field => (
																		<MenuItem
																			key={`merged-field-${field.id}`}
																			onClick={() => {
																				this.handleAddSerie(field);
																				this.setState({
																					dataFieldAnchor: null,
																					menuDataField: null,
																				});
																			}}
																		>
																			<ListItemText
																				primary={t(
																					'reporting-chart_editor-tab_data-merged_fields_add_single_field',
																					{ field: field.title, active: field.active }
																				)}
																				secondary={
																					!field.active
																						? t`reporting-chart_editor-tab_data-merged_fields_field_not_active`
																						: null
																				}
																				primaryTypographyProps={
																					!field.active
																						? {
																								color: 'textSecondary',
																						  }
																						: null
																				}
																			/>
																		</MenuItem>
																	)),
															]}
														</Menu>
													</div>
													<div
														className={classes.fillHeight}
														ref={this.chartEditorScrollerChild}
													>
														<ChartEditorSeries
															series={series}
															onChange={this.updateSerie}
															onUpdateFilter={this.updateExtraFilter}
															onAddFilter={this.addExtraFilter}
															onRemoveFilter={this.removeExtraFilter}
															orderSeries={this.orderSeries}
															removeSerie={this.removeSerie}
															drawerRef={this.innerDrawerRef}
															openDataTab={() => this.setState({ tabValue: 0 })}
															chartType={chartType}
														/>
													</div>
													<div>
														<ChartEditorChartTypes
															setChartType={this.updateChartType}
															currentChartType={
																chartType == 2
																	? 'score'
																	: get(chart, 'chart.type', 'line')
															}
														/>
													</div>
													<div>
														<ChartEditorOptions
															series={series}
															options={chart}
															onChange={this.updateChartOptions}
														/>
													</div>
												</SwipeableViews>
											</Drawer>
										)}
										<div
											className={`${classes.chartAreaWrap} ${
												datasource.forms.asArray.length > 0 ? classes.chartAreaMargin : ''
											}`}
										>
											{multipleSeriePerQuestionAlert && (
												<Alert
													close
													onClose={() =>
														this.setState({ multipleSeriePerQuestionAlert: null })
													}
													key={multipleSeriePerQuestionAlert}
													title={t(
														'reporting-chart_editor-alert-multiple_serie_per_question_title',
														{ type: multipleSeriePerQuestionAlert }
													)}
													variant="filled"
													severity="info"
													css={{
														mb: 1,
													}}
												>
													<Trans
														i18nKey={`reporting-chart_editor-alert-multiple_serie_per_question_content`}
														values={{
															type: multipleSeriePerQuestionAlert,
														}}
														components={{
															p1: (
																<Typography
																	mt={1}
																	mb={2}
																/>
															),
															kbLink: (
																<KnowledgeBaseLink
																	page="chart_editor_multiple_series"
																	color="inherit"
																/>
															),
														}}
													/>
												</Alert>
											)}
											<div>
												{series.length > 0 && (
													<ActionButton
														disableTextTransform
														label={t`reporting-chart_editor-button-delete`}
														variant="text"
														color="default"
														size="small"
														onClick={this.removeAllSeries}
													/>
												)}

												{series.map((serie, i) => {
													return (
														<ActiveSeriesChip
															serie={serie}
															onDelete={() => this.removeSerie(i)}
															onClick={() => this.scrollToSerie(i)}
															key={serie.y + i}
															className={classes.activeSerieChip}
														/>
													);
												})}
											</div>
											<Droppable droppableId="chart-area">
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.droppableProps}
														className={classes.fillHeight}
													>
														<Card className={classes.chartCard}>
															<CardHeader
																titleTypographyProps={{ variant: 'h6' }}
																title={
																	<EditableContent
																		value={get(chart, 'title.text', '')}
																		disabled={datasource.forms.asArray.length === 0}
																		onChange={value =>
																			this.updateChartTitles(value, 'title')
																		}
																		fullWidth
																	>
																		{chart.title && chart.title.text}
																	</EditableContent>
																}
																subheader={
																	<EditableContent
																		value={get(chart, 'subtitle.text', '')}
																		disabled={datasource.forms.asArray.length === 0}
																		onChange={value =>
																			this.updateChartTitles(value, 'subtitle')
																		}
																		fullWidth
																	>
																		{chart.subtitle && chart.subtitle.text}
																	</EditableContent>
																}
															/>
															<CardContent>
																<div
																	ref={this.chartDiv}
																	className={classes.chartDiv}
																>
																	{series.length === 0 && !isLoading && (
																		<EmptyStateHandleHasSurveys
																			image={
																				<img
																					src={`/assets/img/r/emptystate/data_explorer.gif`}
																				/>
																			}
																			primaryNoSurvey={t`reporting-chart_editor-emptystate_no_survey-title`}
																			secondaryNoSurvey={t`reporting-chart_editor-emptystate_no_survey-text`}
																			primary={t`reporting-chart_editor-emptystate_default-title`}
																			secondary={
																				dataExplorer
																					? t`reporting-chart_editor-emptystate_default-text_explorer`
																					: t`reporting-chart_editor-emptystate_default-text_editor`
																			}
																		/>
																	)}
																	{series.length === 0 && isLoading && <Loader empty />}
																	{series.length > 0 &&
																		!changedSurveyId &&
																		!isLoading && (
																			<Fragment>
																				<LoadChartSeries
																					chart={chart}
																					backendBauerSeries={series}
																					//isLoading={e => this.setState({isLoading:true})}
																					//onLoad={e => this.setState({isLoading:false})}
																					shouldReload={shouldReload}
																					chartType={chartType}
																					useTooltip
																					fromChartEditor
																				/>
																			</Fragment>
																		)}
																	{series.length > 0 && changedSurveyId && !isLoading && (
																		<Grid
																			container
																			justify="center"
																			alignItems="center"
																			className={classes.surveyDataSwitch}
																		>
																			<Grid item>
																				<p>{t`reporting-chart_editor-warning-title`}</p>
																				<Button
																					className={classes.switchMessageBtns}
																					variant="outlined"
																					onClick={this.startWithNewSeries}
																				>
																					{t`reporting-chart_editor-warning-continue_with_data`}
																				</Button>
																				<Button
																					className={classes.switchMessageBtns}
																					variant="outlined"
																					onClick={this.cancelStartNewSeries}
																				>
																					{t`reporting-chart_editor-warning-keep_chart`}
																				</Button>
																			</Grid>
																		</Grid>
																	)}
																	{snapshot.isDraggingOver && (
																		<div className={classes.isDraggingOverHighlight} />
																	)}
																</div>
															</CardContent>
														</Card>
														{provided.placeholder}
													</div>
												)}
											</Droppable>
										</div>
									</DragDropContext>
								</div>
							)}
						</Fragment>
						<SimpleDialog
							open={showUnsavedChangesDialog}
							onClose={() => this.setState({ showUnsavedChangesDialog: false })}
							title={t`reporting-chart_editor-dialog_discard-title`}
							submit={t`reporting-chart_editor-dialog_discard-submit`}
							onSubmit={() => this.backButton(true)}
						>
							{t`reporting-chart_editor-dialog_discard-body`}
						</SimpleDialog>
					</Fragment>
				)}
			</HasPermission>
		);
	}
}

export default withAppContext(
	withDashboardContext(
		withStyles(styles, { withTheme: true })(
			withDataSources(withTranslation()(withSnackbar(withRouter(ChartEditor))))
		)
	)
);
