import React, { memo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

import { useListFilterContext } from './ListFilterContext';
import { useDataSourceContext, DataFieldIcon } from '@/components/DataSources';
import { Grid, ActionButton, Chip } from '@/components/Layout';
import { ucFirst } from '@/utils';

const useStyles = makeStyles(theme => ({
	noWrapChip: {
		maxWidth: '100%',
		margin: theme.spacing(0.5, 0.25),
	},
	noWrapChipLabel: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: 'block',
	},
	filterTitle: {
		maxWidth: 300,
	},
}));

function CurrentFilters(props) {
	const classes = useStyles();
	const { t } = useTranslation();

	const {
		filters,
		other,
		setSingleFilter,
		setMultiFilter,
		otherFilters = [],
		singleFilters = [],
		multiFilters = [],
		filterLabels,
		customFilters = [],
		setCustomFilter,
		clearAllFilters,
		computedFilters = [],
	} = useListFilterContext();
	const { datasource } = useDataSourceContext();

	const customCloud = Array.isArray(customFilters)
		? customFilters
				.filter(filter => filter.identifier && filter.value && filter.value.length > 0)
				.map((filter, index) => {
					const { source, ...field } = datasource.api.getField(filter.identifier, true);
					let value = filter.value.join(', ');
					let label = field.system_var || '...';
					return {
						label: `${label}: ${value}`,
						icon: <DataFieldIcon {...field} />,
						onDelete: e =>
							setCustomFilter({
								action: 'remove',
								index: index,
							}),
						key: label + value + index,
					};
				})
		: [];

	const otherCloud = otherFilters
		.filter(key => other[key])
		.map(key => {
			let value = filterLabels[key] ? filterLabels[key][other[key]] : other[key];
			let label = filterLabels[key] ? filterLabels[key].label : ucFirst(key);
			return {
				label: `${label}: ${value}`,
				onDelete: e => setSingleFilter(key, '', true),
				key: label + value,
			};
		});

	const singleCloud = singleFilters
		.filter(key => filters[key])
		.map(key => {
			let value = filterLabels[key] ? filterLabels[key][filters[key]] : filters[key];
			let label = filterLabels[key] ? filterLabels[key].label : ucFirst(key);
			return {
				label: `${label}: ${value}`,
				onDelete: e => setSingleFilter(key, ''),
				key: label + value,
			};
		});

	const multiCloud = multiFilters
		.filter(key => filters[key])
		.map(key => {
			let value = filters[key]
				.map(filterValue => {
					return filterLabels[key] && filterLabels[key][filterValue]
						? filterLabels[key][filterValue]
						: filterValue;
				})
				.join(', ');

			let label =
				filterLabels[key] && filterLabels[key].label
					? filterLabels[key].label
					: ucFirst(key);
			return {
				label: `${label}: ${value}`,
				onDelete: e => setMultiFilter(key, ''),
				key: label + value,
			};
		});

	const computedCloud = computedFilters.map(({ label, value, filters }) => {
		return {
			label: `${ucFirst(label)}: ${value}`,
			onDelete: e => filters.forEach(filter => setSingleFilter(filter, '')),
			key: label + value,
		};
	});

	if (
		!other.survey_id &&
		customCloud.length === 0 &&
		singleCloud.length === 0 &&
		multiCloud.length === 0 &&
		computedCloud.length === 0
	)
		return null;

	return (
		<Grid
			item
			container
			direction="row"
			alignItems="center"
		>
			<ActionButton
				disableTextTransform
				label={t`Clear filters`}
				variant="text"
				color="default"
				size="small"
				onClick={clearAllFilters}
			/>
			{other.survey_id && (
				<Chip
					label={t('Feedback source: {{source}}', {
						source: datasource.api.getSource(other.survey_id).name,
					})}
					icon={<DataFieldIcon label="survey" />}
					onDelete={e => setSingleFilter('survey_id', '', true)}
					classes={{ label: classes.noWrapChipLabel }}
					className={classes.noWrapChip}
				/>
			)}
			{[
				...otherCloud,
				...singleCloud,
				...multiCloud,
				...customCloud,
				...computedCloud,
			].map(obj => (
				<Chip
					{...obj}
					data-test-element="feedbackActiveTag"
					classes={{ label: classes.noWrapChipLabel }}
					className={classes.noWrapChip}
				/>
			))}
		</Grid>
	);
}
export default memo(CurrentFilters);
