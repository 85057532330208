import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { SimpleDialog, Typography } from '../Layout';
import useRemovePermissionGroup from './useRemovePermissionGroup';

export default function PermissionGroupDeleteDialog({
	group = {},
	getPermissionGroups = () => {},
	onClose = () => {},
	...props
}) {
	const { t } = useTranslation();
	const removeGroup = useRemovePermissionGroup({
		uuid: group.uuid,
		onSuccess: () => {
			getPermissionGroups();
			onClose();
		},
	});
	return (
		<SimpleDialog
			title={t`settings-access_groups-delete_dialog-title`}
			submit={t`settings-access_groups-delete_dialog-submit`}
			loading={removeGroup.loading}
			onSubmit={removeGroup.postForm}
			onClose={onClose}
			dataTrackEvent="access_groups_delete_dialog_delete"
			{...props}
		>
			<Typography>
				<Trans
					i18nKey="settings-access_groups-delete_dialog-content"
					values={{
						name: group.name,
					}}
					components={{
						boldGroup: (
							<Typography
								display="inline"
								fontWeight="medium"
							/>
						),
						boldSure: (
							<Typography
								display="block"
								mt={2}
								fontWeight="medium"
							/>
						),
					}}
				/>
			</Typography>
		</SimpleDialog>
	);
}
