import React from 'react';

import { usePermissionContext } from './PermissionContext';

export default function useIsInPermissionGroup(groupUuids = []) {
	const { groups = [], owner, admin } = usePermissionContext();

	if (groupUuids.length === 0 || owner || admin) {
		return true;
	}

	return groups.some(group => groupUuids.includes(group));
}
