import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PauseIcon from '@material-ui/icons/Pause';
import GetAppIcon from '@material-ui/icons/GetApp';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useExportContext } from './ExportContext';
import {
	Grid,
	Menu,
	MenuItem,
	ActionIconButton,
	ActionButton,
	AjaxFormDialog,
	Alert,
	Typography,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
} from '../Layout';
import ExportSummary from './ExportSummary';
import ExportFilters from './ExportFilters';
import ExportSettings from './ExportSettings';
import { useHasPermission, NoPermissionTooltip } from '../Permission';

const useStyles = makeStyles(theme => ({
	clickBg: {
		backgroundColor: theme.palette.grey[200],
	},
	maxWidth: {
		maxWidth: '100%',
	},
}));

export default function ExportExpansionPanel({
	exportData,
	expanded,
	variant = 'recent',
	setDialogOpen,
	setDialogData,
	setWithUuid,
	selected,
	maxSetupsReached,
	toggleExpand = () => {},
	startExportDownload = () => {},
}) {
	const classes = useStyles();
	const [menuAnchor, setMenuAnchor] = useState(null);
	const [mouse, setMouse] = useState({});
	const [panelDialogOpen, setPanelDialogOpen] = useState('');
	const { enqueueSnackbar } = useSnackbar();
	const hasEditPermission = useHasPermission();

	const {
		api: { loadSchedulesData, loadTemplatesData },
	} = useExportContext();

	function startDownload(e, exportData) {
		e.stopPropagation();
		startExportDownload(exportData.uuid);
	}

	function menuClick(e, type, withUuid = false) {
		e.stopPropagation();
		if (
			[
				'template',
				'use_template',
				'scheduled',
				'run_schedule',
				'change_schedule_status',
			].includes(type)
		) {
			if (type === 'template' && maxSetupsReached && !withUuid) {
				enqueueSnackbar(t`You reached the maximum number of templates`);
			} else if (type === 'scheduled' && maxSetupsReached && !withUuid) {
				enqueueSnackbar(t`You reached the maximum number of schedules`);
			} else {
				setWithUuid(withUuid);
				setDialogData(exportData);
				setDialogOpen(type);
			}
		} else if (type) {
			setPanelDialogOpen(type);
		} else {
			setPanelDialogOpen('');
		}
	}

	const { t } = useTranslation();

	const PanelProps = {
		className: classes.maxWidth,
		expanded: expanded,
		onChange: toggleExpand,
		...(expanded && { elevation: 10 }),
	};

	return (
		<ExpansionPanel {...PanelProps}>
			<ExpansionPanelSummary
				classes={{
					root: `${classes.maxWidth} ${
						expanded || selected || menuAnchor || (mouse.x && mouse.y)
							? classes.clickBg
							: ''
					}`,
					content: classes.maxWidth,
				}}
				disableRipple={false}
				onContextMenu={e => {
					e.preventDefault();
					const { clientX, clientY } = e;
					setMouse({
						x: clientX - 2,
						y: clientY - 4,
					});
				}}
			>
				<Grid
					container
					alignItems="center"
				>
					<Grid
						item
						xs
					>
						<ExportSummary
							expanded={expanded}
							exportData={exportData}
							variant={variant}
						/>
					</Grid>
					{expanded && (
						<Grid item>
							{variant === 'scheduled' && (
								<NoPermissionTooltip>
									<ActionButton
										onClick={e => menuClick(e, 'scheduled', true)}
										action="edit"
										variant="outlined"
										dataTrackEvent="export_run_schedule"
										color="primary"
										label={t`Edit schedule`}
										disabled={!hasEditPermission}
									/>
								</NoPermissionTooltip>
							)}
							{variant === 'template' && (
								<ActionButton
									onClick={e => menuClick(e, 'use_template', true)}
									action="download"
									variant="outlined"
									color="primary"
									label={t`Export template`}
								/>
							)}
							{exportData.status === 'FINISHED' && exportData.result?.export_url && (
								<ActionButton
									onClick={e => startDownload(e, exportData)}
									action="download"
									rel="noopener"
									link
									dataTrackEvent="export_download_recent"
									variant="outlined"
									color="primary"
									label={t`Download export`}
								/>
							)}
							{exportData.status === 'FINISHED' && !exportData.result?.export_url && (
								<Alert
									severity="info"
									variant="default"
								>
									{t`No feedback found for this export`}
								</Alert>
							)}
						</Grid>
					)}
					<Grid item>
						<ActionIconButton
							action="more"
							onClick={e => {
								e.stopPropagation();
								setMenuAnchor(e.target);
							}}
							disabled={
								variant === 'recent' &&
								!exportData?.result?.export_url &&
								exportData.from_schedule
							}
							data-test-element="exportEditOptions"
						/>
						<Menu
							anchorEl={menuAnchor}
							open={Boolean(menuAnchor) || Boolean(mouse.x && mouse.y)}
							anchorReference={mouse.y ? 'anchorPosition' : null}
							anchorPosition={
								mouse.y && mouse.x ? { top: mouse.y, left: mouse.x } : undefined
							}
							onClose={e => {
								e.stopPropagation();
								setMenuAnchor(null);
								setMouse({});
							}}
						>
							{variant === 'scheduled' && (
								<div>
									<MenuItem
										onClick={e => menuClick(e, 'scheduled', true)}
										data-test-element="exportEditSchedule"
										data-track-event="export_edit_schedule"
										disabled={!hasEditPermission}
									>
										<ListItemIcon>
											<EditIcon />
										</ListItemIcon>
										{t`Edit schedule`}
									</MenuItem>

									<MenuItem
										onClick={e => menuClick(e, 'change_schedule_status', true)}
										data-test-element="exportDownloadFromSchedule"
										data-track-event="export_status_schedule"
										disabled={!hasEditPermission}
									>
										<ListItemIcon>
											{exportData.enabled ? <PauseIcon /> : <ScheduleIcon />}
										</ListItemIcon>
										{exportData.enabled ? t`Pause schedule` : t`Activate schedule`}
									</MenuItem>

									{/* <MenuItem
										onClick={e => menuClick(e,'run_schedule',true)}
										data-test-element="exportDownloadFromSchedule"
										data-track-event="exportDownloadFromSchedule"
									>
										<ListItemIcon>
											<AssignmentReturnedIcon />
										</ListItemIcon>
										{t`Run schedule now`}
									</MenuItem> */}

									<MenuItem
										onClick={e => menuClick(e, 'delete', true)}
										data-test-element="exportDeleteSchedule"
										data-track-event="export_delete_schedule"
										disabled={!hasEditPermission}
									>
										<ListItemIcon>
											<DeleteIcon />
										</ListItemIcon>
										{t`Delete`}
									</MenuItem>
								</div>
							)}
							{variant === 'recent' && (
								<div>
									{exportData?.result?.export_url && (
										<MenuItem
											onClick={e => startDownload(e, exportData)}
											rel="noopener"
											data-test-element="exportDownloadHistory"
											data-track-event="export_download_recent"
										>
											<ListItemIcon>
												<GetAppIcon />
											</ListItemIcon>
											{t`Download export`}
										</MenuItem>
									)}
									{!exportData.from_schedule && (
										<MenuItem
											onClick={e => menuClick(e, 'scheduled', false)}
											data-test-element="exportSaveSchedule"
											data-track-event="export_recent_to_schedule"
											disabled={!hasEditPermission}
										>
											<ListItemIcon>
												<ScheduleIcon />
											</ListItemIcon>
											{t`Schedule this export`}
										</MenuItem>
									)}
								</div>
							)}
							{variant === 'template' && (
								<div>
									<MenuItem
										onClick={e => menuClick(e, 'template', true)}
										data-test-element="exportEditTemplate"
										data-track-event="export_edit_template"
										disabled={!hasEditPermission}
									>
										<ListItemIcon>
											<EditIcon />
										</ListItemIcon>
										{t`Edit template`}
									</MenuItem>

									<MenuItem
										onClick={e => menuClick(e, 'use_template', true)}
										data-test-element="exportSaveSchedule"
										data-track-event="export_run_template"
									>
										<ListItemIcon>
											<EventNoteIcon />
										</ListItemIcon>
										{t`Export template`}
									</MenuItem>

									<MenuItem
										onClick={e => menuClick(e, 'scheduled', false)}
										data-test-element="exportSaveSchedule"
										data-track-event="export_template_to_schedule"
										disabled={!hasEditPermission}
									>
										<ListItemIcon>
											<ScheduleIcon />
										</ListItemIcon>
										{t`Schedule this template`}
									</MenuItem>

									<MenuItem
										onClick={e => menuClick(e, 'delete', true)}
										data-test-element="exportDeleteTemplate"
										data-track-event="export_delete_template"
										disabled={!hasEditPermission}
									>
										<ListItemIcon>
											<DeleteIcon />
										</ListItemIcon>
										{t`Delete template`}
									</MenuItem>
								</div>
							)}
						</Menu>
					</Grid>
				</Grid>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>
				{expanded && (
					<Grid
						container
						spacing={8}
					>
						<Grid
							item
							xs={8}
						>
							<ExportFilters exportData={exportData} />
						</Grid>
						<Grid
							item
							xs={4}
						>
							<ExportSettings
								exportData={exportData}
								variant={variant}
							/>
						</Grid>
					</Grid>
				)}
			</ExpansionPanelDetails>

			<AjaxFormDialog
				AjaxFormProps={{
					url:
						variant === 'template'
							? '/dashboard/ajax/delete-export-template'
							: '/dashboard/ajax/delete-scheduled-export',
					data: { uuid: exportData.uuid },
					onSuccess: e => {
						if (variant === 'template') {
							loadTemplatesData();
							enqueueSnackbar(`Template deleted`);
						} else {
							//schedules
							loadSchedulesData();
							enqueueSnackbar(`Schedule deleted`);
						}
						setPanelDialogOpen('');
					},
				}}
				open={panelDialogOpen === 'delete'}
				onClose={e => menuClick(e)}
				title={variant === 'template' ? t`Delete template` : t`Delete schedule`}
				content={
					<Grid
						container
						direction="column"
						spacing={1}
					>
						<Grid item>
							<Typography
								variant="body1"
								component="span"
							>
								{variant === 'template'
									? t`Do you want to delete template `
									: t`Do you want to delete schedule `}
							</Typography>
							<Typography
								variant="body1"
								fontWeight="bold"
								component="span"
							>
								"
								{variant === 'template' || variant === 'scheduled'
									? exportData.name
									: exportData?.export?.export_name
									? exportData.export.export_name
									: ''}
								"?
							</Typography>
						</Grid>
						<Grid item>
							<strong>{t`You won't be able to undo this.`}</strong>
						</Grid>
					</Grid>
				}
			/>
		</ExpansionPanel>
	);
}
