import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import SurveyIcon from '@material-ui/icons/Assignment';
import EmailIcon from '@material-ui/icons/MailOutline';
import NoteIcon from '@material-ui/icons/Note';
import ScreenshotIcon from '@material-ui/icons/PhotoCamera';
import ActionIcon from '@material-ui/icons/AssignmentTurnedIn';
import LabelIcon from '@material-ui/icons/Label';

import { RenderConditional } from '../Layout';
const useStyles = makeStyles(theme => ({
	badge: {
		minWidth: 16,
		height: 16,
		backgroundColor: theme.palette.grey['400'],
	},
}));

function StatusIcon({
	variant = '',
	count,
	BadgeProps = {},
	IconProps = {},
	tooltip,
	...props
}) {
	const classes = useStyles();

	function getVariant() {
		switch (variant) {
			case 'survey':
				return SurveyIcon;

			case 'email':
				return EmailIcon;

			case 'note':
				return NoteIcon;

			case 'screenshot':
				return ScreenshotIcon;

			case 'action':
				return ActionIcon;

			case 'tags':
				return LabelIcon;

			default:
				return null;
		}
	}

	const Icon = getVariant();

	const CombinedBadgeProps = {
		color: 'default',
		badgeContent: count,
		classes: {
			badge: classes.badge,
		},
		...props,
		...BadgeProps,
	};

	return Icon ? (
		<RenderConditional
			component={Tooltip}
			condition={tooltip}
			title={tooltip}
		>
			<Badge {...CombinedBadgeProps}>
				<Icon
					fontSize="small"
					{...IconProps}
				/>
			</Badge>
		</RenderConditional>
	) : null;
}

export default React.memo(StatusIcon);
