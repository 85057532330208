import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Card, CardContent, Grid, Typography } from '../Layout';

const useStyles = makeStyles(theme => ({
	cardContent: {
		padding: theme.spacing(4, 3, 4, 3),
		'&:last-child': {
			paddingBottom: theme.spacing(4),
		},
	},
	header: {
		marginBottom: theme.spacing(4),
	},
}));

export default function BillingCardBase({
	children,
	title,
	action,
	CardContentProps = {},
	...props
}) {
	const classes = useStyles();
	return (
		<Card {...props}>
			<CardContent
				className={classes.cardContent}
				{...CardContentProps}
			>
				{title && (
					<Grid
						container
						className={classes.header}
					>
						<Grid
							item
							xs
						>
							<Typography variant="h5">{title}</Typography>
						</Grid>
						{action && <Grid item>{action}</Grid>}
					</Grid>
				)}
				{children}
			</CardContent>
		</Card>
	);
}
