import React, { useState, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
	Typography,
	Box,
	RadioGroup,
	Radio,
	Collapse,
	ActionButton,
	Menu,
	MenuItem,
	RenderConditional,
} from '@/components/Layout';
import { ExpandMoreIcon, ExpandLessIcon } from '@/components/Icons';
import { removeArrayDuplicatesFromShallowClone, ucFirst } from '@/utils';
import {
	defaultPermissions,
	permissionTypes,
	nonEditablePermissionSubjects,
	nonEditablePermissionModules,
	requiresAtLeastViewOnLinkedSubject,
} from './constants';

const useStyles = makeStyles(theme => ({
	radioGroup: {
		justifyContent: 'space-around',
	},
	matrixHead: {
		width: '33.3%',
		textAlign: 'center',
	},
	matrixRadio: {
		width: '33.3%',
		opacity: props => (props.hasMultiplePermissions ? 0.5 : 1),
	},
	moduleLabel: {
		cursor: 'pointer',
	},
	selectAll: {
		minWidth: 120,
	},
}));

function Row({ label, children }) {
	return (
		<Box
			display="flex"
			justifyContent="space-between"
			alignItems="center"
		>
			<Box>{label}</Box>
			<Box width="50%">{children}</Box>
		</Box>
	);
}

function MatrixRow({ label, onChange, value, uniquePermissions = [], disabled }) {
	const hasMultiplePermissions = uniquePermissions?.length > 1;
	const { t } = useTranslation();
	const classes = useStyles({ hasMultiplePermissions });
	return (
		<Row label={label}>
			<RadioGroup
				value={value ?? ''}
				onChange={onChange}
				className={classes.radioGroup}
				row
			>
				<Box
					width="33.33%"
					textAlign="center"
				>
					<RenderConditional
						condition={disabled}
						wrapDisabled={disabled}
						title={t`settings-access_groups-add_edit_dialog-uneditable_subject`}
					>
						<Radio
							value={permissionTypes.edit}
							className={classes.matrixRadio}
							disabled={disabled}
							checked={
								hasMultiplePermissions && uniquePermissions.includes(permissionTypes.edit)
									? true
									: undefined
							}
						/>
					</RenderConditional>
				</Box>
				<Box
					width="33.33%"
					textAlign="center"
				>
					<Radio
						value={permissionTypes.view}
						className={classes.matrixRadio}
						checked={
							hasMultiplePermissions && uniquePermissions.includes(permissionTypes.view)
								? true
								: undefined
						}
					/>
				</Box>
				<Box
					width="33.33%"
					textAlign="center"
				>
					<Radio
						value={permissionTypes.no_access}
						className={classes.matrixRadio}
						checked={
							hasMultiplePermissions &&
							uniquePermissions.includes(permissionTypes.no_access)
								? true
								: undefined
						}
					/>
				</Box>
			</RadioGroup>
		</Row>
	);
}

export default function PermissionMatrix({ onChange, permissions = {} }) {
	const { t } = useTranslation();
	const [open, setOpen] = useState({});
	const [selectAllAnchor, setSelectAllAnchor] = useState(null);
	const didSetInitialOpen = useRef({});
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const onSelectAllValue = value => {
		Object.keys(defaultPermissions).forEach(module => {
			onChange({
				permission: value,
				module,
			});
			setSelectAllAnchor(null);
		});
	};

	return (
		<>
			<Row
				label={
					<>
						<Typography
							fontWeight="medium"
							tooltip={t`settings-access_groups-add_edit_dialog-matrix-tooltip`}
						>
							{t`settings-access_groups-add_edit_dialog-matrix-title`}
						</Typography>
					</>
				}
			>
				<Box
					display="flex"
					justifyContent="flex-end"
					mb={1}
				>
					<ActionButton
						action="dropdown"
						disableTextTransform
						size="small"
						fontWeight="regular"
						onClick={e => setSelectAllAnchor(e.currentTarget)}
					>
						{t`settings-access_groups-add_edit_dialog-matrix-select_all`}
					</ActionButton>
					<Menu
						open={Boolean(selectAllAnchor)}
						anchorEl={selectAllAnchor}
						onClose={() => setSelectAllAnchor(null)}
					>
						<MenuItem onClick={() => onSelectAllValue(permissionTypes.edit)}>
							{t`settings-access_groups-add_edit_dialog-matrix-edit_access`}
						</MenuItem>
						<MenuItem onClick={() => onSelectAllValue(permissionTypes.view)}>
							{t`settings-access_groups-add_edit_dialog-matrix-view_access`}
						</MenuItem>
						<MenuItem onClick={() => onSelectAllValue(permissionTypes.no_access)}>
							{t`settings-access_groups-add_edit_dialog-matrix-no_access`}
						</MenuItem>
					</Menu>
				</Box>
			</Row>
			<Row>
				<Box display="flex">
					<Typography
						variant="subtitle2"
						className={classes.matrixHead}
					>{t`settings-access_groups-add_edit_dialog-matrix-edit_access`}</Typography>
					<Typography
						variant="subtitle2"
						className={classes.matrixHead}
					>{t`settings-access_groups-add_edit_dialog-matrix-view_access`}</Typography>
					<Typography
						variant="subtitle2"
						className={classes.matrixHead}
					>{t`settings-access_groups-add_edit_dialog-matrix-no_access`}</Typography>
				</Box>
			</Row>
			{Object.keys(defaultPermissions).map((module, index, self) => {
				const subjects = Object.keys(defaultPermissions[module]);
				const showCollapse = subjects.length > 1;
				const toggleOpen = () => {
					setOpen(prev => ({
						...prev,
						[module]: !prev[module],
					}));
				};

				const permissionsInsideModule = removeArrayDuplicatesFromShallowClone(
					Object.values(permissions[module] ?? {})
				);

				const allSubjectsSameValue =
					Object.keys(permissions[module] ?? {}).length === subjects.length &&
					permissionsInsideModule.length === 1;

				//expand module if not all values are the same
				//prevent forcing it open on every render
				if (
					!allSubjectsSameValue &&
					!open[module] &&
					!didSetInitialOpen.current[module]
				) {
					didSetInitialOpen.current[module] = true;
					toggleOpen();
				}

				const moduleValue = allSubjectsSameValue ? permissionsInsideModule[0] : '';

				return (
					<Box
						key={module}
						mb={index !== self.length ? 1 : 0}
					>
						<MatrixRow
							label={
								<Box
									onClick={toggleOpen}
									display="flex"
									align="center"
									className={classes.moduleLabel}
								>
									<Typography
										{...(nonEditablePermissionModules.includes(module) && {
											className: classes.disabledRowLabel,
											tooltip: t`settings-access_groups-add_edit_dialog-uneditable_subject`,
										})}
									>
										{t(`settings-access_groups-add_edit_dialog-matrix-${module}`)}
									</Typography>

									{showCollapse && (
										<>{open[module] ? <ExpandLessIcon /> : <ExpandMoreIcon />}</>
									)}
								</Box>
							}
							value={moduleValue}
							uniquePermissions={permissionsInsideModule}
							onChange={event => {
								onChange({
									permission: event.target.value,
									module,
								});
							}}
							disabled={nonEditablePermissionModules.includes(module)}
						/>
						{showCollapse &&
							subjects.map(subject => (
								<Collapse
									in={open[module]}
									key={subject}
								>
									<MatrixRow
										label={
											<Typography
												{...(nonEditablePermissionSubjects.includes(subject) && {
													className: classes.disabledRowLabel,
													tooltip: t`settings-access_groups-add_edit_dialog-uneditable_subject`,
												})}
												ml={1}
											>
												{t(`settings-access_groups-add_edit_dialog-matrix-${subject}`)}
											</Typography>
										}
										subject
										value={permissions[module]?.[subject]}
										disabled={nonEditablePermissionSubjects.includes(subject)}
										onChange={event => {
											const linkedSubject = requiresAtLeastViewOnLinkedSubject[subject];

											//set linkedSubject at least to view if subject is set to edit
											if (
												linkedSubject &&
												event.target.value === permissionTypes.edit &&
												permissions[module]?.[linkedSubject] === permissionTypes.no_access
											) {
												enqueueSnackbar(
													t(
														'settings-access_groups-add_edit_dialog-matrix-linked_subject_set',
														{
															subject: t(
																`settings-access_groups-access_subjects-${subject}`
															),
															linkedSubject: t(
																`settings-access_groups-access_subjects-${linkedSubject}`
															),
														}
													),
													{
														autoHideDuration: 10000,
													}
												);
												onChange({
													permission: permissionTypes.view,
													module,
													subject: linkedSubject,
												});
											}

											//prevent setting no_access rights if linkedSubject is on edit
											if (
												linkedSubject &&
												event.target.value === permissionTypes.no_access &&
												permissions[module]?.[linkedSubject] === permissionTypes.edit
											) {
												return enqueueSnackbar(
													t(
														'settings-access_groups-add_edit_dialog-matrix-requires_linked_subject',
														{
															linkedSubject: ucFirst(
																t(
																	`settings-access_groups-access_subjects-${linkedSubject}`
																)
															),
															subject: t(
																`settings-access_groups-access_subjects-${subject}`
															),
														}
													),
													{
														autoHideDuration: 10000,
													}
												);
											}

											onChange({
												permission: event.target.value,
												module,
												subject,
											});
										}}
									/>
								</Collapse>
							))}
					</Box>
				);
			})}
		</>
	);
}
