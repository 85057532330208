export const departments = [
	'user_experience',
	'customer_experience',
	'product',
	'marketing',
	'development',
	'sales',
	'management',
	'other',
];
