import React from 'react';

import { useAjaxForm } from '../Ajax';

export default function useSetTextAnalyticsLanguage({ onSuccess = () => {} }) {
	return useAjaxForm({
		url: '/api/officedrones',
		data: {
			path: '/textanalytics/project/languages',
			method: 'post',
		},
		onSuccess: response => {
			onSuccess(response);
		},
	});
}
