import React from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import Search from '@material-ui/icons/Search';

import { useTranslation } from 'react-i18next';

import { useListFilterContext, FilterGroup } from '../FilteredLists';

export default function SurveyListFilters() {
	const { setSingleFilter, setMultiFilter, filters } = useListFilterContext();

	const { t } = useTranslation();

	return (
		<>
			<div>
				<TextField
					variant="filled"
					fullWidth
					placeholder={t`reporting-insights-filter-search_placeholder`}
					label={t`reporting-insights-filter-search_label`}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search />
							</InputAdornment>
						),
					}}
					onChange={e => setSingleFilter('search', e.target.value)}
					value={filters.search ?? ''}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t`reporting-insights-filter-survey_type`}
					filterKey={'survey_format'}
					filters={[
						{ label: t`reporting-insights-filter-survey_type_standard`, value: 'form' },
						{
							label: t`reporting-insights-filter-survey_type_conversational`,
							value: 'conversational',
						},
					].map(filter => {
						if (filter.value === filters.survey_format) {
							filter.selected = true;
						}
						filter.key = 'survey_format' + filter.value;
						return filter;
					})}
					onChange={({ key, value }) => setSingleFilter(key, value)}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t`reporting-insights-filter-channel`}
					filterKey={'channel'}
					filters={[
						{ label: t`reporting-insights-filter-channel_web`, value: 'web' },
						{ label: t`reporting-insights-filter-channel_email`, value: 'email' },
						{ label: t`reporting-insights-filter-channel_app`, value: 'sdk' },
					].map(filter => {
						if ((filters.channel || []).indexOf(filter.value) > -1) {
							filter.selected = true;
						}
						filter.key = 'channel' + filter.value;
						return filter;
					})}
					multiple
					onChange={({ key, value }) => setMultiFilter(key, value)}
				/>
			</div>
		</>
	);
}
