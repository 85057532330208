import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ContentsquareIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 22 23`}
			{...props}
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M10.3609 0.0587198C2.49415 0.851978 -2.28638 9.30262 1.10732 16.4166C4.93334 24.4367 15.9374 25.3206 20.9625 18.0114C21.3716 17.4163 21.9743 16.3107 21.9422 16.2142C21.9307 16.1796 20.8791 15.1092 19.6055 13.8354L17.2898 11.5196L19.6449 9.15569L22 6.79182L21.6741 6.18935C19.3855 1.95894 15.0132 -0.410431 10.3609 0.0587198ZM13.0705 1.74725C15.8662 2.22401 18.3165 3.83381 19.8019 6.16971L20.0111 6.49888L18.0776 8.43552L16.1441 10.3722L13.8195 8.02807L11.495 5.68397L8.58157 8.60205L5.66815 11.5201L8.57294 14.4299L11.4778 17.3398L13.8248 14.9896L16.1718 12.6393L18.0934 14.5646L20.0151 16.4899L19.8692 16.7391C16.192 23.0239 6.63102 22.8632 2.94445 16.4546C-1.18258 9.28028 4.95661 0.363578 13.0705 1.74725ZM13.2803 9.74391L15.0259 11.4929L13.2517 13.2693L11.4776 15.0456L9.71837 13.2829L7.95919 11.5203L9.71837 9.75758C10.6859 8.78808 11.4904 7.99489 11.5062 7.99489C11.5219 7.99489 12.3203 8.78194 13.2803 9.74391Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
