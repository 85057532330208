import React from 'react';

import { useTranslation } from 'react-i18next';

import { SearchIcon } from '../Icons';
import InputAdornment from './InputAdornment';
import TextField from './TextField';

export default function SearchField(props) {
	const { t } = useTranslation();
	return (
		<TextField
			variant="filled"
			label={t`app_general-search_field-label`}
			placeholder={t`app_general-search_field-placeholder`}
			fullWidth
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon />
					</InputAdornment>
				),
			}}
			{...props}
		/>
	);
}
