import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import ColorPickerWithPopper from './ColorPickerWithPopper';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	chip: {
		backgroundColor: props => (props.color ? props.color : ''),
		color: props => (props.color ? theme.palette.getContrastText(props.color) : ''),
		'&:hover': {
			backgroundColor: props => (props.color ? props.color : ''),
		},
	},
}));

export default function ColorPickerChip({
	className = '',
	onChange,
	onOpen = () => {},
	onClose = () => {},
	color,
	...props
}) {
	const { t } = useTranslation();
	const [anchor, setAnchor] = useState(null);
	const classes = useStyles({ color });
	return (
		<>
			<Tooltip
				title={
					<>
						<div>{color ? color : t`Default`}</div>
						<div>
							<strong>{t`Click to change color`}</strong>
						</div>
					</>
				}
			>
				<Chip
					onClick={e => {
						setAnchor(e.target);
						onOpen();
					}}
					className={`${classes.chip} ${className}`}
					{...props}
				/>
			</Tooltip>
			<ColorPickerWithPopper
				anchorEl={anchor}
				open={Boolean(anchor)}
				color={color}
				onChange={onChange}
				onClose={e => {
					setAnchor(null);
					onClose();
				}}
			/>
		</>
	);
}
