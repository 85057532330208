import React from 'react';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useAjaxForm } from '@/components/Ajax';
import { usePermissionContext } from './PermissionContext';
import { getErrorMessageTranslationKey } from './utils';

export default function useRemovePermissionGroup({ uuid = '', onSuccess = () => {} }) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { updateCurrentPermissions } = usePermissionContext();
	const action = useAjaxForm({
		url: `/application/permission/remove-group/${uuid}`,
		onSuccess: response => {
			if (response.code === 200) {
				updateCurrentPermissions();
				onSuccess();
				enqueueSnackbar(t`settings-access_groups-delete_dialog-snackbar-success`);
			} else {
				enqueueSnackbar(t(getErrorMessageTranslationKey(response.message)));
			}
		},
		onError: response => {
			enqueueSnackbar(t(getErrorMessageTranslationKey(response.message)));
		},
	});

	return action;
}
