import React from 'react';

import { useAjaxForm } from '../Ajax';

export default function useSaveDashboard({ id, layout, onSuccess = () => {} }) {
	const action = useAjaxForm({
		url: '/dashboard/ajax/save-grid',
		data: {
			react_layout: JSON.stringify(layout),
			id: Number(id),
		},
		onSuccess: r => {
			onSuccess(r);
		},
	});
	return action;
}
