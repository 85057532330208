import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

import DataSourceContext from './DataSourceContext';
import DataFieldIcon from './DataFieldIcon';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	noWrapChip: {
		maxWidth: '100%',
	},
	noWrapChipLabel: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: 'block',
	},
});

function DataFieldChip({ field, className = '', ...props }) {
	const { t } = useTranslation();
	const classes = useStyles();

	const type = field.survey_block?.type
		? t(`blocktype.${field.survey_block?.type}`)
		: field.system_var_simple
		? field.system_var_simple
		: '';
	return (
		<Tooltip title={`${type}: ${field.title || field.system_var}`}>
			<Chip
				icon={
					<DataFieldIcon
						{...(props.size === 'small' && {
							fontSize: 'small',
						})}
						{...field}
					/>
				}
				label={field.title || field.system_var}
				className={`${classes.noWrapChip} ${className}`}
				classes={{
					label: classes.noWrapChipLabel,
				}}
				{...props}
			/>
		</Tooltip>
	);
}
export default DataFieldChip;
