import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { UserAvatarGroup } from '@/components/Users';
import {
	Card,
	CardContent,
	Grid,
	Typography,
	ActionIconButton,
	FormattedDate,
} from '@/components/Layout';
import PermissionGroupAddEditDrawer from './PermissionGroupAddEditDrawer';
import PermissionGroupDeleteDialog from './PermissionGroupDeleteDialog';
import useHasPermission from './useHasPermission';
import NoPermissionTooltip from './NoPermissionTooltip';
import { permissionGroupTypes } from './constants';
import { useIsOwner } from './useIsAdminOwner';

const useStyles = makeStyles(theme => ({
	actions: {
		opacity: 0,
		transition: theme.transitions.create('opacity'),
	},
	card: {
		marginBottom: theme.spacing(3),
		'&:hover $actions': {
			opacity: 1,
		},
	},
}));

export default function PermissionGroupCard({
	group = {},
	getPermissionGroups,
	hasOwnerGroup,
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	const [editOpen, setEditOpen] = useState(false);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const hasEditPermission = useHasPermission();
	const isOwner = useIsOwner();

	const allowedToEdit =
		(hasEditPermission && group.type !== permissionGroupTypes.owner) || isOwner;

	return (
		<>
			<Card className={classes.card}>
				<CardContent>
					<Grid
						container
						spacing={2}
						alignItems="center"
					>
						<Grid
							item
							xs
						>
							<Grid
								container
								spacing={2}
							>
								<Grid
									item
									xs={4}
								>
									<Typography variant="subtitle">{group.name}</Typography>
								</Grid>

								<Grid
									item
									xs={2}
								>
									<Typography
										color="textSecondary"
										mb={1}
									>
										{t`settings-access_groups-list_item-created`}
									</Typography>
									<Typography>
										<FormattedDate
											date={group.created}
											unixTimestamp
										/>
									</Typography>
								</Grid>

								<Grid
									item
									xs={2}
								>
									<Typography
										color="textSecondary"
										mb={1}
									>
										{t`settings-access_groups-list_item-reports`}
									</Typography>
									<Typography>
										{['owner', 'admin'].includes(group.type)
											? t`settings-access_groups-list_item-all_reports`
											: group.projects?.length}
									</Typography>
								</Grid>

								<Grid
									item
									xs={4}
								>
									<Typography
										color="textSecondary"
										mb={1}
									>
										{t`settings-access_groups-list_item-users`}
									</Typography>
									<UserAvatarGroup
										tooltip
										stack={group.members.map(uuid => ({ uuid }))}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							className={classes.actions}
						>
							<NoPermissionTooltip>
								<ActionIconButton
									action="edit"
									onClick={() => setEditOpen(true)}
									disabled={!allowedToEdit}
									tooltip={t`settings-access_groups-list_item-edit`}
									data-track-event="access_groups_card_edit_group"
								/>
							</NoPermissionTooltip>
							<NoPermissionTooltip>
								<ActionIconButton
									action="delete"
									onClick={() => setDeleteOpen(true)}
									disabled={
										!hasEditPermission ||
										[permissionGroupTypes.admin, permissionGroupTypes.owner].includes(
											group.type
										)
									}
									tooltip={t`settings-access_groups-list_item-delete`}
									data-track-event="access_groups_card_delete_group"
								/>
							</NoPermissionTooltip>
						</Grid>
					</Grid>
				</CardContent>
			</Card>

			<PermissionGroupAddEditDrawer
				open={editOpen}
				onClose={() => setEditOpen(false)}
				group={group}
				getPermissionGroups={getPermissionGroups}
				hasOwnerGroup={hasOwnerGroup}
			/>
			<PermissionGroupDeleteDialog
				open={deleteOpen}
				onClose={() => setDeleteOpen(false)}
				group={group}
				getPermissionGroups={getPermissionGroups}
			/>
		</>
	);
}
