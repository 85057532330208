import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function DropdownIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<path
				fill="currentColor"
				id="XMLID_11_"
				d="M36,13.1v13.9H4V13.1H36 M36,11.1H4c-1.1,0-2,0.9-2,2v13.9c0,1.1,0.9,2,2,2h32c1.1,0,2-0.9,2-2V13.1
				C38,12,37.1,11.1,36,11.1L36,11.1z M26.2,18.4l3.2,3.2l3.2-3.2H26.2z"
			/>
		</SvgIcon>
	);
}
