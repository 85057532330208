import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';

import { SimpleDialog } from '../Layout';
import { TagsAutoComplete } from '../AutoComplete';
import { useTagContext } from './TagContext';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useAjaxForm } from '../Ajax';

export default function AddTagsDialog({
	open,
	onSuccess = () => {},
	onClose = () => {},
	feedback_ids,
	action_ids,
	dataTrackEvent = null,
	...props
}) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { tags } = useTagContext();
	const [addTags, setAddTags] = useState([]);

	const { postForm, loading } = useAjaxForm({
		url: '/application/tags/add',
		data: {
			tags: addTags.join(','),
			...(feedback_ids && { feedback_ids: feedback_ids.join(',') }),
			...(action_ids && { action_ids: action_ids.join(',') }),
		},
		onSuccess: r => {
			if (r.code === 200) {
				onSuccess(addTags, feedback_ids ? feedback_ids : action_ids);
				enqueueSnackbar(t`Tags added`);
				tags.api.updateTags();
			}
			onClose();
		},
	});

	return (
		<SimpleDialog
			title={t`Add tags`}
			onSubmit={postForm}
			submit={t`Add tags`}
			open={Boolean(open)}
			onClose={onClose}
			loading={loading}
			disabled={!addTags.length}
			dataTrackEvent={dataTrackEvent}
		>
			<Typography
				variant="subtitle1"
				gutterBottom
			>
				{t('Add tags to selected items')}
			</Typography>

			<TagsAutoComplete
				label={t`Type to add tags`}
				//placeholder={t`Type to add tags`}
				onChange={e => setAddTags(e.map(tag => tag.label))}
				selectedItem={addTags.map(tag => ({ label: tag }))}
				autoFocus
				multiple
			/>
		</SimpleDialog>
	);
}
