import React, { useState, useEffect } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Grow from '@material-ui/core/Grow';
import { DateTimePicker } from '@material-ui/pickers';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';
import { UserAvatar, UserName } from '@/components/Users';
import {
	FormattedDate,
	Typography,
	Grid,
	TextField,
	Menu,
	MenuItem,
	ListItemText,
	ActionIconButton,
	ActionButton,
} from '@/components/Layout';
import { isToday } from '@/utils/date';

const styles = theme => ({
	noteContainer: {
		padding: theme.spacing(1, 2),
	},
	noteContainerWhenSameUser: {
		paddingTop: 0,
		marginTop: -8,
	},
	bubbleContainer: {
		marginTop: theme.spacing(1),
	},
	bubble: {
		display: 'inline-block',
		backgroundColor: theme.palette.grey[100],
		borderRadius: theme.spacing(1),
		borderTopLeftRadius: 0,
		padding: theme.spacing(1, 2),
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		marginTop: 0,
	},
	bubbleRight: {
		borderTopLeftRadius: theme.spacing(1),
		borderTopRightRadius: 0,
	},
	buttonMargin: {
		marginTop: theme.spacing(2),
	},
	userNameContainer: {
		paddingLeft: theme.spacing(1),
	},
	userNameContainerRight: {
		paddingRight: theme.spacing(1),
	},
	userName: {
		marginRight: theme.spacing(1),
	},
	userNameRight: {
		marginLeft: theme.spacing(1),
	},
	dateWhenSameUser: {
		marginLeft: theme.spacing(1),
	},
	dateWhenSameUserRight: {
		marginRight: theme.spacing(1),
	},
});

const Note = React.forwardRef(function Note(
	{
		user_id,
		edit,
		text,
		id,
		user_firstname,
		user_lastname,
		edited,
		created,
		reminder,
		removeNote,
		updateNote,
		setReminder = () => {},
		removeReminder = () => {},
		classes,
		theme,
		index,
		allNotes = [],
		...props
	},
	ref
) {
	const { t } = useTranslation();
	const [anchor, setAnchor] = useState(null);
	const [tempReminder, setTempReminder] = useState(reminder);
	const [pickerOpen, setPickerOpen] = useState(false);

	useEffect(() => {
		if (tempReminder === null && reminder) {
			//remove reminder
			removeReminder(id);
		} else if (tempReminder !== reminder) {
			//save reminder
			setReminder(id, tempReminder);
		}
	}, [tempReminder]);

	const { app } = useAppContext();
	const isCurrentUser = app.users.current.id === Number(user_id);
	const previousNoteWasSameUser =
		Number(allNotes[index - 1]?.user_id) === Number(user_id);

	const makeConsumableDate = dateString => {
		const [date, time] = dateString.split(' ');
		return [...date.split(app.date.separator).reverse(), ...time.split(':')];
	};

	return (
		<div ref={ref}>
			<Grid
				container
				className={`${classes.noteContainer} ${
					previousNoteWasSameUser ? classes.noteContainerWhenSameUser : ''
				}`}
				dir={isCurrentUser ? 'rtl' : 'ltr'}
			>
				<Grid
					container
					item
					alignItems="center"
				>
					{!previousNoteWasSameUser && (
						<Grid item>
							<UserAvatar
								user_id={user_id}
								size="small"
							/>
						</Grid>
					)}
					<Grid
						item
						xs
						className={`${
							!isCurrentUser ? classes.userNameContainer : classes.userNameContainerRight
						}`}
					>
						{!previousNoteWasSameUser && (
							<UserName
								user_id={user_id}
								className={!isCurrentUser ? classes.userName : classes.userNameRight}
								style={{ display: 'inline-block' }}
								variant="subtitle2"
							/>
						)}
						<Typography
							className={
								previousNoteWasSameUser && !isCurrentUser
									? classes.dateWhenSameUser
									: classes.dateWhenSameUserRight
							}
							variant="caption"
							style={{ display: 'inline-block' }}
							color="textSecondary"
							dir="ltr"
						>
							<FormattedDate
								withTime
								date={edited ? edited : created}
							/>
						</Typography>
					</Grid>
				</Grid>

				<Grid
					container
					item
					className={!previousNoteWasSameUser ? classes.bubbleContainer : ''}
				>
					<Grid
						item
						xs
					>
						<Grow
							in={true}
							style={{ transitionDelay: theme.transitions.duration.enteringScreen }}
						>
							<Typography
								component="div"
								className={`${classes.bubble} ${
									isCurrentUser ? classes.bubbleRight : ''
								}`}
								dir="ltr"
							>
								{!edit ? (
									text
								) : (
									<Grow in>
										<Grid
											container
											spacing={1}
											wrap="nowrap"
										>
											<Grid
												item
												xs
											>
												<TextField
													fullWidth
													value={text}
													onChange={e => updateNote(id, { text: e.target.value })}
													label={t('Edit note')}
													multiline
												/>

												<ActionButton
													data-track-event="inbox_feedback_edit_note"
													variant="contained"
													color="primary"
													onClick={e => updateNote(id, { edit: false, save: true })}
													className={classes.buttonMargin}
												>
													{t('Save')}
												</ActionButton>

												<ActionButton
													color="primary"
													onClick={e => updateNote(id, { edit: false, save: false })}
													className={classes.buttonMargin}
												>
													{t('Cancel')}
												</ActionButton>
											</Grid>
										</Grid>
									</Grow>
								)}
							</Typography>
						</Grow>
					</Grid>

					{!edit && isCurrentUser && (
						<Grid item>
							<ActionIconButton
								onClick={e => setAnchor(e.currentTarget)}
								action="more_vert"
							/>
						</Grid>
					)}
				</Grid>
			</Grid>

			<div style={{ display: 'none' }}>
				<DateTimePicker
					open={pickerOpen}
					ampm={false}
					value={reminder ? new Date(makeConsumableDate(reminder)) : tempReminder}
					onChange={setTempReminder}
					onClose={() => {
						setPickerOpen(false);
						setAnchor(null);
					}}
					disablePast={true}
					clearable
					clearLabel={t('Remove')}
					okLabel={t('Add')}
					cancelLabel={t('Cancel')}
				/>
			</div>

			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={e => setAnchor(null)}
			>
				{isToday(new Date(created)) && (
					<MenuItem
						onClick={() => {
							updateNote(id, { edit: true });
							setAnchor(null);
						}}
					>
						{t('Edit')}
					</MenuItem>
				)}
				<MenuItem
					data-track-event="inbox_feedback_note_delete"
					onClick={e => removeNote(id)}
				>
					{t('Delete')}
				</MenuItem>
			</Menu>
		</div>
	);
});

export default withStyles(styles, { withTheme: true })(Note);
