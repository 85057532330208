import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation, Trans } from 'react-i18next';

import { Typography, ActionButton } from '../Layout';
import BillingCardBase from './BillingCardBase';

const useStyles = makeStyles(theme => ({
	header: {
		marginBottom: theme.spacing(4),
	},
	summaryTitleProduct: {
		width: 80,
		marginRight: theme.spacing(1),
		display: 'inline-block',
	},
	row: {
		marginBottom: theme.spacing(0.5),
	},
}));

export default function SelectedPackageCard({
	selectedPackage = {},
	onEdit = () => {},
	disableAction,
}) {
	const { t } = useTranslation();
	const classes = useStyles();
	const products = [
		...new Set(Object.keys(selectedPackage).map(key => key.split('_')[0])),
	].reverse();

	return (
		<BillingCardBase
			title={t`selected_package_card-title`}
			action={
				<ActionButton
					onClick={onEdit}
					color="primary"
					disabled={disableAction}
				>
					{t`selected_package_card-edit_label`}
				</ActionButton>
			}
		>
			{products.map(productName => {
				const packagesWithValues = Object.keys(selectedPackage)
					.filter(key => key.includes(productName) && selectedPackage[key] > 0)
					.map(key => [key, selectedPackage[key]]);

				if (packagesWithValues.length === 0) return null;

				return (
					<div
						key={productName}
						className={classes.row}
					>
						<Typography
							fontWeight="medium"
							variant="body1"
							className={classes.summaryTitleProduct}
						>
							{t(`selected_package_card-title_${productName}`)}
						</Typography>

						{packagesWithValues.map(([key, value], index, arr) => {
							return (
								<Typography
									display="inline"
									key={key}
									variant="body1"
								>
									<Trans
										i18nKey={`selected_package_card-list_${key}`}
										values={{
											[key]: value.toLocaleString(),
										}}
										count={value}
									/>
									{arr.length !== index + 1 && <span>, </span>}
								</Typography>
							);
						})}
					</div>
				);
			})}
		</BillingCardBase>
	);
}
