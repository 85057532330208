import React from 'react';

import { usePermissionContext } from './PermissionContext';

export default function useIsAdminOwner() {
	const { owner = false, admin = false } = usePermissionContext();
	return owner || admin;
}

export function useIsOwner() {
	const { owner = false } = usePermissionContext();
	return owner;
}

export function useIsAdmin() {
	const { admin = false } = usePermissionContext();
	return admin;
}
