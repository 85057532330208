import React, { useState, Fragment, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { TextField, FormHelperText } from '../Layout';
import { validate } from './validate';

function getMessage(messages = {}, message = '', failedRule = '') {
	if (messages[failedRule]) {
		return messages[failedRule];
	}

	return message;
}

function ValidatedField({
	component,
	rules,
	showErrors,
	showErrorsAfterBlur,
	onBlur = () => {},
	onValidChange = () => {},
	errorMessage,
	messages,
	seperateError,
	validateValue,
	dataTestElement = null,
	...props
}) {
	const [hasBeenBlurred, setBeenBlurred] = useState(false);
	const { t } = useTranslation();

	const testValue = validateValue ? validateValue : props.value ? props.value : '';
	const { valid, failedRule } = validate(testValue, rules);

	const message = getMessage(
		messages,
		errorMessage ?? t('This field is required'),
		failedRule
	);

	const ComponentProps = {
		onBlur: (...args) => {
			setBeenBlurred(true);
			onBlur(...args);
		},
		...props,
	};
	const RenderComponent = component ? component : TextField;

	if (showErrors || (showErrorsAfterBlur && hasBeenBlurred)) {
		ComponentProps.error = !valid;
		ComponentProps.helperText = !valid ? message : '';
	}

	if (RenderComponent !== TextField) {
		ComponentProps.dataTestElement = dataTestElement;
	}

	useEffect(() => {
		onValidChange(valid);
	}, [valid]);

	return (
		<Fragment>
			<RenderComponent
				inputProps={{ 'data-test-element': dataTestElement }}
				{...ComponentProps}
			/>
			{showErrors && seperateError && !valid && (
				<FormHelperText error>{message}</FormHelperText>
			)}
		</Fragment>
	);
}

export default ValidatedField;
