import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import { useTranslation } from 'react-i18next';

import { useTimeout } from '../../hooks';
import { Typography } from '../Layout';
import { getRemainingDaysPerc } from './billingUtils';

const useStyles = makeStyles(theme => ({
	progressWrap: {
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		width: props => props.width ?? 80,
	},
	bgProgress: {
		color: theme.palette.grey[300],
		position: 'absolute',
		top: 0,
	},
	frontProgress: {
		zIndex: 1,
	},
	daysWrap: {
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		'& > *': {
			lineHeight: 1,
		},
	},
}));

export default function RemainingDaysProgress({
	start,
	end,
	totalRemaining,
	width = 80,
	primaryTypographyProps = {},
	secondaryTypographyProps = {},
	...props
}) {
	const classes = useStyles({ width });
	const { t } = useTranslation();
	const [perc, setPerc] = useState(0);

	useTimeout(() => {
		setPerc(getRemainingDaysPerc(start, end));
	}, 500);

	return (
		<Box
			width={width}
			display="flex"
			justifyContent="center"
			position="relative"
			{...props}
		>
			<CircularProgress
				size={width}
				variant="static"
				value={perc}
				thickness={5}
				className={classes.frontProgress}
			/>
			<CircularProgress
				size={width}
				variant="static"
				value={100}
				thickness={5}
				className={classes.bgProgress}
			/>

			<div className={classes.daysWrap}>
				<Typography
					variant="h6"
					align="center"
					color="primary"
					{...primaryTypographyProps}
				>
					{totalRemaining}
				</Typography>
				<Typography
					variant="body2"
					color="textSecondary"
					{...secondaryTypographyProps}
				>
					{t`days`}
				</Typography>
			</div>
		</Box>
	);
}
