import React, { Fragment, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useTranslation } from 'react-i18next';

import { Typography, Link } from '../Layout';
import { useDataSourceContext, DataFieldIcon } from '../DataSources';

const useStyles = makeStyles(theme => ({
	chipSpace: {
		'& > div': {
			margin: theme.spacing(0, 0.5, 0.5, 0),
		},
	},
	noWrapChip: {
		maxWidth: '100%',
		margin: theme.spacing(0.5, 0.25),
	},
	noWrapChipLabel: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: 'block',
	},
	italic: {
		fontStyle: 'italic',
	},
	chipsCollapsed: {
		height: 80,
		overflow: 'hidden',
	},
	expand: {
		verticalAlign: 'middle',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
}));

export default function ExportFilters({ exportData, chipSize = 'medium' }) {
	const classes = useStyles();
	const { t } = useTranslation();

	const { datasource } = useDataSourceContext();

	const exportDetails = exportData.kwargs
		? exportData.kwargs
		: exportData.export
		? exportData.export
		: {};
	const exportFilters = exportDetails.filters ? exportDetails.filters : exportDetails;

	const sortLabel =
		exportDetails?.sorting?.order_by === 'customer_lastname'
			? exportDetails?.sorting?.ascending
				? t`Customer name: Z to A`
				: t`Customer name: A to Z`
			: exportDetails?.sorting?.ascending
			? t`Date: old to new`
			: t`Date: new to old`;

	const [collapsed, setCollapsed] = useState(true);

	const surveys =
		exportFilters?.survey_ids?.map(id => {
			return {
				type: datasource.api.getSource(id).survey_type,
				name: datasource.api.getSource(id).name,
				description: datasource.api.getSource(id).description,
			};
		}) ?? [];

	const customFields =
		exportFilters?.data_field_ids?.map(id => datasource.api.getField(id, true)) ?? [];

	const customFieldsPerSurvey = customFields.reduce((survey, customField) => {
		let key = customField['source']['name'];
		if (!survey[key]) {
			survey[key] = [];
		}
		survey[key].push(customField);
		return survey;
	}, {});

	return (
		<Grid
			container
			spacing={2}
			direction="column"
		>
			<Grid
				item
				xs
				className={classes.chipSpace}
			>
				<Typography
					variant="subtitle2"
					display="block"
				>{t`Data source`}</Typography>
				{surveys.length ? (
					surveys.map(survey => (
						<Chip
							size={chipSize}
							icon={<DataFieldIcon label="survey" />}
							key={survey.name}
							label={`${t`Feedback source`}: ${survey.name}`}
							classes={{ label: classes.noWrapChipLabel }}
							className={classes.noWrapChip}
						/>
					))
				) : (
					<Chip
						size={chipSize}
						icon={<DataFieldIcon label="survey" />}
						label={`${t`Feedback source`}: ${t`all data sources`}`}
						classes={{ label: classes.noWrapChipLabel }}
						className={classes.noWrapChip}
					/>
				)}
			</Grid>

			<Grid
				item
				xs
			>
				<Typography
					variant="subtitle2"
					display="block"
				>{t`Sort export results by`}</Typography>
				<Typography variant="body2">{sortLabel}</Typography>
			</Grid>

			<Grid
				item
				xs
				className={classes.chipSpace}
			>
				<Typography
					variant="subtitle2"
					display="block"
				>{t`Custom filters`}</Typography>
				{exportFilters?.data_field_query?.length ? (
					exportFilters.data_field_query.map((datafield, index) => {
						const { source, ...field } = datasource.api.getField(
							datafield.data_field_id,
							true
						);

						let value = datafield.values.join(', ');
						let label = field.title || '...';

						return (
							<Chip
								key={index}
								size={chipSize}
								icon={<DataFieldIcon {...field} />}
								label={`${label}: ${value}`}
								classes={{ label: classes.noWrapChipLabel }}
								className={classes.noWrapChip}
							/>
						);
					})
				) : (
					<Typography
						variant="caption"
						display="block"
					>{t`No custom filters`}</Typography>
				)}
			</Grid>
			<Grid
				item
				xs
				className={classes.chipSpace}
			>
				<Typography
					variant="subtitle2"
					display="block"
				>{t`Default filters`}</Typography>
				{typeof exportFilters.with_email_conversations === 'boolean' && (
					<Chip
						size={chipSize}
						label={`${t`Sent email replies`}: ${
							exportFilters.with_email_conversations
								? t`Feedback with sent email replies`
								: t`Feedback without sent email replies`
						}`}
						classes={{ label: classes.noWrapChipLabel }}
						className={classes.noWrapChip}
					/>
				)}
				{typeof exportFilters.with_comments === 'boolean' && (
					<Chip
						size={chipSize}
						label={`${t`Open Comment`}: ${
							exportFilters.with_comments
								? t`Feedback with comments`
								: t`Feedback without comments`
						}`}
						classes={{ label: classes.noWrapChipLabel }}
						className={classes.noWrapChip}
					/>
				)}
				{typeof exportFilters.actions === 'boolean' && (
					<Chip
						size={chipSize}
						label={`${t`Actions`}: ${
							exportFilters.actions ? t`With actions` : t`Without actions`
						}`}
						classes={{ label: classes.noWrapChipLabel }}
						className={classes.noWrapChip}
					/>
				)}
				{exportFilters.search && (
					<Chip
						size={chipSize}
						label={`${t`Search`}: ${exportFilters.search}`}
						classes={{ label: classes.noWrapChipLabel }}
						className={classes.noWrapChip}
					/>
				)}
				{typeof exportFilters.with_email_conversations !== 'boolean' &&
					typeof exportFilters.with_comments !== 'boolean' &&
					typeof exportFilters.actions !== 'boolean' &&
					!exportFilters.search && (
						<Typography
							variant="caption"
							display="block"
						>{t`No default filters`}</Typography>
					)}
			</Grid>
			<Grid
				item
				xs
				className={classes.chipSpace}
			>
				<Typography
					variant="subtitle2"
					display="block"
				>{t`Metadata filters`}</Typography>
				{exportFilters?.devices?.length > 0 && (
					<Chip
						size={chipSize}
						label={`${t`Devices`}: ${exportFilters.devices.join(', ')}`}
						classes={{ label: classes.noWrapChipLabel }}
						className={classes.noWrapChip}
					/>
				)}
				{exportFilters?.browsers?.length > 0 && (
					<Chip
						size={chipSize}
						label={`${t`Browsers`}: ${exportFilters.browsers.join(', ')}`}
						classes={{ label: classes.noWrapChipLabel }}
						className={classes.noWrapChip}
					/>
				)}
				{exportFilters?.os?.length > 0 && (
					<Chip
						size={chipSize}
						label={`${t`Operating system`}: ${exportFilters.os.join(', ')}`}
						classes={{ label: classes.noWrapChipLabel }}
						className={classes.noWrapChip}
					/>
				)}
				{!exportFilters?.browsers?.length > 0 &&
					!exportFilters?.os?.length > 0 &&
					!exportFilters?.devices?.length > 0 && (
						<Typography
							variant="caption"
							display="block"
						>{t`No metadata filters`}</Typography>
					)}
			</Grid>
			<Grid
				item
				xs
				className={classes.chipSpace}
			>
				<Typography
					variant="subtitle2"
					display="block"
				>{t`Tags`}</Typography>
				{exportFilters?.tag_names?.length ? (
					exportFilters.tag_names.map(tagName => {
						return (
							<Chip
								size={chipSize}
								label={tagName}
							/>
						);
					})
				) : (
					<Typography
						variant="caption"
						display="block"
					>{t`No tagfilters`}</Typography>
				)}
			</Grid>

			{Object.keys(customFieldsPerSurvey).length > 1 && (
				<Grid
					item
					xs
					className={classes.chipSpace}
				>
					<Typography
						variant="subtitle2"
						display="block"
					>{t`Custom fields`}</Typography>
					<Grid
						className={
							collapsed && customFields.length >= 15 ? classes.chipsCollapsed : ''
						}
					>
						{Object.keys(customFieldsPerSurvey).map(survey => (
							<Fragment>
								<Typography
									variant="body2"
									className={classes.italic}
									display="block"
								>
									{survey}
								</Typography>
								{customFieldsPerSurvey[survey].map(field => (
									<Chip
										size={chipSize}
										icon={<DataFieldIcon {...field} />}
										label={field.title}
										classes={{ label: classes.noWrapChipLabel }}
										className={classes.noWrapChip}
									/>
								))}
							</Fragment>
						))}
					</Grid>
					<Grid
						item
						xs
					>
						{customFields.length > 6 && (
							<Link
								color="textSecondary"
								variant="caption"
								fontWeight="medium"
								onClick={() => {
									setCollapsed(!collapsed);
								}}
							>
								{collapsed ? t`Show all datafields` : t`Show less datafields`}
								<ExpandMoreIcon
									fontSize="small"
									className={`${classes.expand} ${!collapsed ? classes.expandOpen : ''}`}
								/>
							</Link>
						)}
					</Grid>
				</Grid>
			)}
			{Object.keys(customFieldsPerSurvey).length < 2 && customFields.length > 0 && (
				<Grid
					item
					xs
					className={classes.chipSpace}
				>
					<Typography
						variant="subtitle2"
						display="block"
					>{t`Custom datafield selection`}</Typography>
					<Grid
						className={
							collapsed && customFields.length >= 6 ? classes.chipsCollapsed : ''
						}
					>
						{customFields.map(field => (
							<Chip
								size={chipSize}
								icon={<DataFieldIcon {...field} />}
								label={field.title}
								classes={{ label: classes.noWrapChipLabel }}
								className={classes.noWrapChip}
							/>
						))}
					</Grid>
					<Grid
						item
						xs
					>
						{customFields.length > 15 && (
							<Link
								color="textSecondary"
								variant="caption"
								onClick={() => setCollapsed(!collapsed)}
								fontWeight="medium"
							>
								{collapsed ? t`Show all datafields` : t`Show less datafields`}
								<ExpandMoreIcon
									fontSize="small"
									className={`${classes.expand} ${!collapsed ? classes.expandOpen : ''}`}
								/>
							</Link>
						)}
					</Grid>
				</Grid>
			)}
		</Grid>
	);
}
