import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@/styles';

import { Logo, Grid } from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: '#ffffff!important',
		},
	},
	root: {
		display: 'flex',
		minHeight: '100vh',
		width: '100%',
	},
	inner: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		padding: theme.spacing(6, 2, 4),
		marginTop: theme.spacing(5),
	},
	inputPanel: {
		maxWidth: 500,
	},
	center: {
		textAlign: 'center',
	},
	logo: {
		maxWidth: 160,
		margin: theme.spacing(4),
		margin: 'auto',
	},
}));

export default function OnboardingLayout({ children }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();

	useEffect(() => {
		document.title = `${t`initial_user_onboarding_document_title`} | Mopinion`;
		history.push('/getting-started');
	}, []);

	return (
		<>
			<div className={classes.root}>
				<div className={classes.inner}>
					<div className={classes.inputPanel}>
						<Grid
							container
							direction="column"
							spacing={4}
							justify="center"
							justifyContent="center"
						>
							<Grid
								item
								className={classes.center}
							>
								<Logo
									withText
									raspberry={false}
									className={classes.logo}
									onBg="light"
								/>
							</Grid>
						</Grid>
						{children}
					</div>
				</div>
			</div>
		</>
	);
}
