import React, { useState, Fragment } from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import { useTranslation } from 'react-i18next';

import { Loader, EmptyState, Box } from '@/components/Layout';
import { HighChartsWordCloud } from '@/components/WordCloud';
import { msiColor } from './textAnalyticsUtils';
import TextAnalyticsFeedbackQuickViewDrawer from './TextAnalyticsFeedbackQuickViewDrawer';
import { useTextAnalyticsContext } from './TextAnalyticsContext';

function mapWords(words = []) {
	return words.map(word => {
		return {
			name: word.words[0][0],
			weight: word.words[0][1],
			cluster_id: word.cluster_id,
			color: msiColor(word.MSI),
			fontWeight: 400,
			style: {
				fontWeight: 400,
			},
			pointData: {
				...word,
			},
		};
	});
}

function TextAnalyticsWordCloudCard() {
	const {
		textAnalytics: { group, words, loading },
	} = useTextAnalyticsContext();
	const { t } = useTranslation();
	const [pointData, setPointData] = useState({});

	return (
		<Fragment>
			<Box
				maxWidth="100%"
				clone
			>
				<Card>
					{loading && <Loader />}
					<CardHeader
						titleTypographyProps={{ variant: 'h6' }}
						title={`${t('reporting-text_analytics-card-toplist-frequent')} ${t(
							group
								? 'reporting-text_analytics-card-toplist-groups'
								: 'reporting-text_analytics-card-toplist-words'
						)}`}
					/>

					<CardContent>
						{words && words.length > 0 ? (
							<HighChartsWordCloud
								words={mapWords(words)}
								onClick={(point, event) => {
									setPointData(point);
								}}
							/>
						) : (
							<EmptyState secondary={t`reporting-text_analytics-no_data`} />
						)}
					</CardContent>
				</Card>
			</Box>

			<TextAnalyticsFeedbackQuickViewDrawer
				requestPath="/dash/items"
				word={pointData.name}
				cluster_id={pointData.cluster_id}
				open={Object.keys(pointData).length > 0}
				onClose={() => setPointData({})}
			/>
		</Fragment>
	);
}

export default TextAnalyticsWordCloudCard;
