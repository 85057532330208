import { axiosInstance } from '@/lib/axios';
import objectToFormData from 'object-to-formdata';

export const EFM = {
	pending: {},
	handlePending(path, data) {
		if (this.pending[path]) {
			if (JSON.stringify(this.pending[path].data) === JSON.stringify(data)) {
				return false;
			} else {
				this.cancelPending(path);
				this.pending[path] = {
					data: data,
					cancel: () => {},
					count: 0,
				};
				return true;
			}
		} else {
			this.pending[path] = {
				data: data,
				cancel: () => {},
				count: 0,
			};
			return true;
		}
	},
	cancelPending(path) {
		try {
			if (this.pending[path]) {
				delete this.pending[path];
			}
		} catch (e) {}
	},
	init(token) {
		this.csrf = token;
	},
	get(url = '', data = {}, config = {}) {
		return axiosInstance.get(url, data, config).then(r => r.data);
	},
	post(url = '', data = {}, config = {}) {
		data.token = this.csrf;
		//if (
		this.handlePending(url, data);
		//) return;
		return axiosInstance
			.post(url, objectToFormData(data, { indices: true }), config)
			.then(r => {
				delete this.pending[url];
				return r.data;
			});
	},
};
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
	// dev code
	window.___EFM = EFM;
}
