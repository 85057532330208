import React, { useEffect, useState } from 'react';

import { makeStyles } from '@/styles';

import {
	List,
	ListItem,
	Card,
	Tabs,
	Tab,
	ListItemWithRightContent,
	Typography,
} from '@/components/Layout';
import { useHasPermission, permissionSubjects } from '@/components/Permission';

const useStyles = makeStyles(theme => ({
	list: {
		width: '100%',
		padding: 0,
	},
	titleSpace: {
		marginBottom: theme.spacing(1),
	},
	spaceBottom: {
		marginBottom: theme.spacing(2),
	},
	minTabWidth: {
		'& button': {
			minWidth: 120,
		},
	},
	card: {
		overflow: 'visible',
	},
}));

function OptionList({ options }) {
	const classes = useStyles();
	const hasEditPermission = useHasPermission({
		subject: permissionSubjects.feedback_forms_design,
	});
	return (
		<List className={classes.list}>
			{options.map((option, index) =>
				!option.hide && option.disableLabel ? (
					<ListItem
						key={index}
						divider={option.divider ?? true}
						{...(!hasEditPermission && {
							button: true,
							disabled: true,
						})}
					>
						{option.control}
					</ListItem>
				) : !option.hide ? (
					<ListItemWithRightContent
						key={index}
						divider={option.divider ?? true}
						primary={option.label}
						secondary={option.secondary}
						action={option.control}
						ListItemProps={option.ListItemProps}
						textGridItemProps={option.textGridItemProps}
						actionGridItemProps={option.actionGridItemProps}
						GridProps={option.GridProps}
						{...(!hasEditPermission && {
							button: true,
							disabled: true,
						})}
					/>
				) : null
			)}
		</List>
	);
}

export default function SurveyDesignList({
	title,
	subtitle,
	tabs,
	smallTabs,
	options = [],
	spaceBottom = true,
	viewType,
}) {
	const [tabActive, setTabActive] = useState(0);
	const classes = useStyles();

	const handleChange = (event, newValue) => {
		setTabActive(newValue);
	};

	useEffect(() => {
		setTabActive(viewType === 'mobile' ? 1 : 0);
	}, [viewType]);

	return (
		<>
			<Typography
				variant="subtitle2"
				className={!subtitle ? classes.titleSpace : ''}
			>
				{title}
			</Typography>
			{subtitle && (
				<Typography
					variant="caption"
					color="textSecondary"
					display="block"
					className={classes.spaceBottom}
				>
					{subtitle}
				</Typography>
			)}

			<Card className={`${classes.card} ${spaceBottom ? classes.spaceBottom : ''}`}>
				{tabs && (
					<Tabs
						className={smallTabs ? classes.minTabWidth : ''}
						value={tabActive}
						onChange={handleChange}
						centered
					>
						{tabs.map((tab, index) => (
							<Tab
								key={'tab' + index}
								label={tab}
							></Tab>
						))}
					</Tabs>
				)}
				{tabs ? (
					options.map((option, index) => (
						<div
							hidden={index !== tabActive}
							key={'page' + index}
						>
							<OptionList options={option} />
						</div>
					))
				) : (
					<OptionList options={options} />
				)}
			</Card>
		</>
	);
}
