import React, { memo } from 'react';

import Avatar from '@material-ui/core/Avatar';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { useAppContext } from '@/components/AppContext';
import { Tooltip, Skeleton } from '@/components/Layout';
import { getInitials } from '@/helpers';

const sizes = {
	xsmall: {
		height: 24,
		width: 24,
	},
	small: {
		height: 30,
		width: 30,
	},
	large: {
		width: 60,
		height: 60,
	},
	xlarge: {
		width: 80,
		height: 80,
	},
};

const useStyles = makeStyles(theme => ({
	avatar: {
		display: 'inline-flex',
		backgroundColor: props => props.color,
		color: '#fff',
	},
	xsmall: {
		...sizes.xsmall,
		fontSize: theme.typography.pxToRem(12),
	},
	small: {
		...sizes.small,
		fontSize: theme.typography.pxToRem(14),
	},
	large: {
		...sizes.large,
	},
	xlarge: {
		...sizes.xlarge,
		fontSize: theme.typography.pxToRem(28),
	},
}));

function WithTooltip({ tooltip, tooltipTitle, children }) {
	return tooltip ? <Tooltip title={tooltipTitle}>{children}</Tooltip> : children;
}
function getTitleWithUser(user, titleFn = () => {}) {
	const title = titleFn(user);
	return typeof title === 'string' ? title : user.firstname + ' ' + user.lastname;
}

function UserAvatar({
	user_id,
	uuid,
	current,
	size = 'medium',
	className,
	tooltip,
	tooltipTitle = () => {},
	...props
}) {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const user = user_id
		? app.api.getUserById(user_id)
		: uuid
		? app.api.getUserByUuid(uuid)
		: user_id === undefined && uuid === undefined
		? app.api.getCurrentUser()
		: null;

	const color = user?.color ?? '#eee';
	const firstname = user?.firstname ?? t`app_general-user_deleted-firstname`;
	const lastname = user?.lastname ?? t`app_general-user_deleted-lastname`;

	const classes = useStyles({ color });
	const mergedClasses = `${classes.avatar} ${
		classes[size] ? classes[size] : ''
	} ${className}`;
	const title = getTitleWithUser({ firstname, lastname }, tooltipTitle);

	if (app.users.asArray.length === 0) {
		return (
			<Skeleton
				variant="circle"
				height={40}
				width={40}
				{...(sizes[size] ?? {})}
				{...props}
			/>
		);
	}

	return (
		<WithTooltip
			tooltip={tooltip}
			tooltipTitle={title}
		>
			<Avatar
				className={mergedClasses}
				data-test-element="accountSettingsAvatar"
				{...props}
			>
				{getInitials(firstname, lastname)}
			</Avatar>
		</WithTooltip>
	);
}

export default memo(UserAvatar);
