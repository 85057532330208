import React, { Fragment, useRef, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import { MoBotPopover, MoBotIcon, MoBotAvatar } from '../MoBot';

import { useTranslation } from 'react-i18next';
import { EFM } from '../../api';
import Zoom from '@material-ui/core/Zoom';
import { tryParse } from '../../utils';

const style = theme => ({
	tag: {
		background: '#303031',
		color: '#fff',
		'& svg': {
			color: 'rgba(255, 255, 255, 0.26)',
		},
	},
	small: {
		height: 24,
	},
});

function TagChip({
	classes,
	tagClass = '',
	className = '',
	small,
	ai,
	meta,
	onClick,
	onDelete,
	id,
	deleted,
	disablePopover,
	...props
}) {
	const chipRef = useRef(null);
	const [showMoBot, setShowMoBot] = useState(false);
	const [updated, setUpdated] = useState('');
	const tagMeta =
		typeof meta === 'string'
			? tryParse(meta)
			: meta && typeof meta === 'object'
			? meta
			: {};

	const needsMoBotAction = !updated && tagMeta.AI && !tagMeta.approved;

	const chipProps = {
		ref: chipRef,
		...(small && {
			size: 'small',
		}),
		className: `${!needsMoBotAction ? classes.tag : ''} ${
			needsMoBotAction ? classes.ai : ''
		} ${tagClass} ${className}`,
		...(tagMeta.AI && { icon: <MoBotIcon /> }),
		//	...(updated === 'deleted' && {style:{display:'none'}}),
		...(typeof onClick === 'function' && {
			onClick: e => {
				e.stopPropagation();
				onClick(e);
				if (needsMoBotAction) {
					setShowMoBot(true);
				}
			},
		}),
		...(needsMoBotAction && { color: 'primary' }),
		...(typeof onClick !== 'function' &&
			needsMoBotAction && {
				onClick: e => {
					e.stopPropagation();
					setShowMoBot(true);
				},
			}),
		ref: chipRef,
		...(typeof onDelete === 'function' && {
			onDelete: e => {
				onDelete(e);
				setUpdated('deleted');
			},
		}),
		...props,
	};

	async function updateTag(id, approved) {
		const data = {
			id,
			meta: JSON.stringify({
				...tagMeta,
				...{ approved },
			}),
			deleted: approved ? 0 : 1,
		};
		return await EFM.post('/application/tags/update', data);
	}

	const { t } = useTranslation();

	if (deleted && Number(deleted) === 1) return null;

	const autoTagProbability = Math.round(Number(tagMeta?.probability ?? 0) * 100);

	return (
		<Fragment>
			<Zoom in={updated !== 'deleted'}>
				<Chip {...chipProps} />
			</Zoom>
			{tagMeta.AI && !tagMeta.approved && !disablePopover && (
				<MoBotPopover
					open={showMoBot}
					anchorEl={chipRef}
					onClose={e => {
						e.stopPropagation();
						setShowMoBot(false);
					}}
					conversation={[
						{
							id: '0',
							message:
								autoTagProbability <= 75
									? t('settings-tags-manage_tags-mobot-tag_added_confirm_low', {
											probability: autoTagProbability,
									  })
									: t('settings-tags-manage_tags-mobot-tag_added_confirm_high', {
											probability: autoTagProbability,
									  }),
							trigger: '1',
							type: 'message',
							start: true,
							name: 'MoBot',
							user: 'MoBot',
							typingTimeout: 2000,
							avatar: <MoBotAvatar />,
						},
						{
							id: '1',
							type: 'buttons',
							buttons: [
								{
									value: 1,
									label: t`settings-tags-manage_tags-mobot-button_approve`,
									selectedLabel: t`settings-tags-manage_tags-mobot-button_approved`,
									trigger: '2',
									effect: () => updateTag(id, true).then(r => setUpdated('approved')),
								},
								{
									value: 0,
									label: t`settings-tags-manage_tags-mobot-button_decline`,
									selectedLabel: t`settings-tags-manage_tags-mobot-button_declined`,
									trigger: '3',
									effect: () => updateTag(id, false).then(r => setUpdated('deleted')),
								},
							],
							user: 'self',
						},
						{
							id: '2',
							message: t`Great. I'll save the tag.`,
							type: 'message',
							name: 'MoBot',
							user: 'MoBot',
							avatar: <MoBotAvatar />,
							trigger: '4',
						},
						{
							id: '3',
							message: t`Okay I'll remove the tag. I promise to do better next time!`,
							type: 'message',
							name: 'MoBot',
							user: 'MoBot',
							avatar: <MoBotAvatar />,
							trigger: '5',
						},
						{
							id: '4',
							message: t`I've saved the tag`,
							type: 'message',
							//typingAwait:async function update() { return  updateTag(id,true) },
							name: 'MoBot',
							user: 'MoBot',
							avatar: <MoBotAvatar />,
							sideEffect: () => setTimeout(() => setShowMoBot(false), 2000),
						},
						{
							id: '5',
							message: t`Done. I've removed the tag`,
							type: 'message',
							//typingAwait:async function update() { return updateTag(id,false) },
							name: 'MoBot',
							user: 'MoBot',
							avatar: <MoBotAvatar />,
							sideEffect: () => setTimeout(() => setShowMoBot(false), 2000),
						},
					]}
				/>
			)}
		</Fragment>
	);
}

export default withStyles(style)(TagChip);
