import React from 'react';
import { useAjaxForm } from '@/components/Ajax';

export default function useAddDefaultDashboard({ name, onSuccess = () => {} }) {
	return useAjaxForm({
		url: '/dashboard/ajax/add-dashboard',
		data: {
			id: 0,
			name: name,
		},
		onSuccess: response => {
			onSuccess(response);
		},
	});
}
