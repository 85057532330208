import React from 'react';

import { useAppContext } from '@/components/AppContext';
import { useAjaxForm } from '../Ajax';

export default function useSetExclusionList({
	language,
	list_value = [],
	onSuccess = () => {},
}) {
	const { app } = useAppContext();

	return useAjaxForm({
		url: '/textanalytics/exclusion/',
		type: 'officeDrones',
		method: 'post',
		data: {
			language: language,
			project_id: app.projects.current.id,
			domain: app.domain,
			list_value: list_value.toString(),
		},
		onSuccess: response => {
			onSuccess(response);
		},
	});
}
