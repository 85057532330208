import React from 'react';

import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

export default React.forwardRef(function ExpansionPanelDetails(props, ref) {
	return (
		<MuiExpansionPanelDetails
			{...props}
			ref={ref}
		/>
	);
});
