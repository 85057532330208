import React from 'react';

import { Switch, Redirect } from 'react-router-dom';

import { useAppContext } from '@/components/AppContext';
import {
	permissionModules,
	permissionSubjects,
	PermissionProtectedRoute,
} from '@/components/Permission';
import {
	DashboardOverviewPage,
	DashboardPage,
	DirectInsightsOverviewPage,
	DirectInsightsPage,
	FeedbackResults,
	FeedbackDetailPage,
	ChartEditorPage,
	FeedbackExportsPage,
	CustomerDetailPage,
	TextAnalyticsPage,
} from '@/pages/reporting';
import { ActionDetailPage, ActionListPage } from '@/pages/actions';
import {
	UserListPage,
	UserProfilePage,
	ProjectsPage,
	TagsPage,
	AlertsPage,
	StandardAnswersPage,
	PackageExpiredPage,
	NotificationSettingsPage,
} from '@/pages/application';
import { FeedbackAPIListPage, WebHooksListPage } from '@/pages/integrations';
import {
	DataSetListPage,
	ImportDataSetPage,
	SurveyListPage,
	SurveyEditorPage,
	DeploymentListPage,
	DeploymentEditorPage,
} from '@/pages/data-collection';
import {
	MyAccountPage,
	MyOrganisationPage,
	BillingPage,
	PlansOverviewPage,
} from '@/pages/account';
import Route from './Route';

export default function LoggedInContent() {
	const { app } = useAppContext();

	//if a package is no longer valid user needs to upgrade first
	if (!app.package.valid || new Date(app.package.period.end) < new Date()) {
		return (
			<Route path="*">
				<PackageExpiredPage />
			</Route>
		);
	}

	//only allow the user to view his own account page if he hasnt setup multifactor auth yet but the organisation has force_mfa
	if (app.users.current.mfa_enabled !== 'yes' && app.enforceMfa) {
		return (
			<Route path="*">
				<MyAccountPage />
			</Route>
		);
	}

	return (
		<>
			<Switch>
				{/* Reporting routes */}
				<PermissionProtectedRoute
					path="/reporting/dashboard/list"
					module={permissionModules.reporting}
					subject={permissionSubjects.dashboard}
				>
					<DashboardOverviewPage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path="/reporting/dashboard/:dashboardId"
					module={permissionModules.reporting}
					subject={permissionSubjects.dashboard}
				>
					<DashboardPage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path="/reporting/chart/:chartId"
					module={permissionModules.reporting}
					subject={permissionSubjects.dashboard}
				>
					<ChartEditorPage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path="/reporting/data-explorer"
					module={permissionModules.reporting}
					subject={permissionSubjects.dashboard}
				>
					<ChartEditorPage dataExplorer />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path="/reporting/direct-insights/:datasourceId"
					module={permissionModules.reporting}
					subject={permissionSubjects.dashboard}
				>
					<DirectInsightsPage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path="/reporting/direct-insights"
					module={permissionModules.reporting}
					subject={permissionSubjects.dashboard}
				>
					<DirectInsightsOverviewPage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path="/reporting/inbox/detail/:feedbackId"
					module={permissionModules.reporting}
					subject={permissionSubjects.feedback_inbox}
				>
					<FeedbackDetailPage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path={[
						'/reporting/inbox/:pageId?',
						'/reporting/smart-recaps/:surveyUuidParam?',
					]}
					module={permissionModules.reporting}
					subject={permissionSubjects.feedback_inbox}
				>
					<FeedbackResults />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path="/reporting/customers/detail/:customerId/:pageId?"
					module={permissionModules.reporting}
					subject={permissionSubjects.feedback_inbox}
				>
					<CustomerDetailPage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path={[
						'/reporting/exports/download/:downloadUuid?',
						'/reporting/exports/:tab?',
					]}
					module={permissionModules.reporting}
					subject={permissionSubjects.exports}
				>
					<FeedbackExportsPage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path="/reporting/analytics/:tab?"
					module={permissionModules.reporting}
					subject={permissionSubjects.text_analytics}
				>
					<TextAnalyticsPage />
				</PermissionProtectedRoute>

				{/* Action routes */}

				<PermissionProtectedRoute
					path="/actions/inbox/detail/:actionId?"
					module={permissionModules.actions}
					subject={permissionSubjects.action_management}
				>
					<ActionDetailPage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path="/actions/inbox/:pageId?"
					module={permissionModules.actions}
					subject={permissionSubjects.action_management}
				>
					<ActionListPage />
				</PermissionProtectedRoute>

				{/* Data collection routes */}
				<PermissionProtectedRoute
					permission="view"
					path="/data-collection/survey/edit/:surveyId/:editorType?/:editorFlow?/:detailStep?/:deploymentId?"
					module={permissionModules.data_collection}
					subject={[
						permissionSubjects.feedback_forms_build,
						permissionSubjects.feedback_forms_design,
					]}
				>
					<SurveyEditorPage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path={[
						'/data-collection/survey/list/page/:pageId?',
						'/data-collection/survey/:list?',
					]}
					module={permissionModules.data_collection}
					subject={[
						permissionSubjects.feedback_forms_build,
						permissionSubjects.feedback_forms_design,
					]}
				>
					<SurveyListPage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					permission="view"
					path="/data-collection/deployment/edit/:deploymentId/:editorType?"
					module={permissionModules.data_collection}
					subject={permissionSubjects.deployments}
				>
					<DeploymentEditorPage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path="/data-collection/deployment/:list?"
					module={permissionModules.data_collection}
					subject={permissionSubjects.deployments}
				>
					<DeploymentListPage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					permission="edit"
					path="/data-collection/dataset/import/:datasetId?"
					module={permissionModules.data_collection}
					subject={permissionSubjects.datasets}
				>
					<ImportDataSetPage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path="/data-collection/dataset/:list?"
					module={permissionModules.data_collection}
					subject={permissionSubjects.datasets}
				>
					<DataSetListPage />
				</PermissionProtectedRoute>

				{/* Settings routes */}
				<PermissionProtectedRoute
					path="/application/reports"
					module={permissionModules.settings}
					subject={permissionSubjects.reports}
				>
					<ProjectsPage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path="/application/users/profile/:userId?"
					module={permissionModules.settings}
					subject={permissionSubjects.user_management}
				>
					<UserProfilePage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path="/application/users/:tab?/:pageId?"
					module={permissionModules.settings}
					subject={permissionSubjects.user_management}
				>
					<UserListPage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path="/application/tags/:tab?"
					module={permissionModules.settings}
					subject={permissionSubjects.tag_management}
				>
					<TagsPage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path="/application/standard-answers"
					module={permissionModules.settings}
					subject={permissionSubjects.standard_answers}
				>
					<StandardAnswersPage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path="/application/alerts"
					module={permissionModules.settings}
					subject={permissionSubjects.alerts}
				>
					<AlertsPage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path={['/account/my-organisation', '/account/organisation']}
					module={permissionModules.billing}
					subject={permissionSubjects.billing_management}
				>
					<MyOrganisationPage />
				</PermissionProtectedRoute>

				{app.package.trial && (
					<PermissionProtectedRoute
						path="/account/billing/plans"
						module={permissionModules.billing}
						subject={permissionSubjects.billing_management}
					>
						<PlansOverviewPage />
					</PermissionProtectedRoute>
				)}

				<PermissionProtectedRoute
					path="/account/billing/:upgradeStatus?"
					module={permissionModules.billing}
					subject={permissionSubjects.billing_management}
				>
					<BillingPage />
				</PermissionProtectedRoute>

				<Route path="/application/notifications">
					<NotificationSettingsPage />
				</Route>

				{/* Integrations routes */}
				<PermissionProtectedRoute
					path="/integrations/webhooks"
					module={permissionModules.integrations}
					subject={permissionSubjects.webhooks_apps}
				>
					<WebHooksListPage />
				</PermissionProtectedRoute>

				<PermissionProtectedRoute
					path="/integrations/api"
					module={permissionModules.integrations}
					subject={permissionSubjects.api}
				>
					<FeedbackAPIListPage />
				</PermissionProtectedRoute>

				<Route path="/account/me/:socialGoogleEmail?">
					<MyAccountPage />
				</Route>

				{/* Catch all route */}
				<Route path="/">
					<Redirect to="/reporting/dashboard/list" />
				</Route>
			</Switch>
		</>
	);
}
