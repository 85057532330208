import React, { useState, Fragment, useMemo, memo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useAppContext } from '../AppContext';
import {
	ActionIconButton,
	FormattedDate,
	AjaxFormDialog,
	SystemElementCardBase,
	CardContent,
	Chip,
	Typography,
	Tooltip,
} from '../Layout';
import UserAdditDialog from './UserAdditDialog';
import UserAvatar from './UserAvatar';
import { useHasPermission, NoPermissionTooltip } from '../Permission';

const useStyles = makeStyles(theme => ({
	img: {
		backgroundSize: `auto calc(100% - ${theme.spacing(2)}px)`,
		backgroundColor: theme.palette.primary.light,
		width: '100%',
		height: 100,
	},
	imgXs: {
		width: 40,
		height: 40,
	},
	fillHeight: {
		height: '100%',
	},
	CardContent: {
		flexGrow: 1,
	},
	relative: {
		position: 'relative',
	},
	center: {
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
	},
	accessGroupChip: {
		margin: theme.spacing(0.25),
	},
}));

export default memo(function UserCard({ user = {}, allGroups = [], ...props }) {
	const { firstname, lastname, created, email, id } = user;
	const { t } = useTranslation();
	const classes = useStyles();
	const { app } = useAppContext();
	const { enqueueSnackbar } = useSnackbar();
	const [deleteOpen, setDelete] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const hasEditPermission = useHasPermission();

	const groups = useMemo(
		() => allGroups.filter(group => group?.members?.includes(user.uuid)),

		[allGroups, user.uuid]
	);

	const uniqueProjects = useMemo(
		() => [...new Set(groups.flatMap(group => group.projects))].length,
		[groups]
	);

	const groupsChips = groups.slice(0, 3);
	const remainingGroups = groups.slice(3);

	return (
		<Fragment>
			<SystemElementCardBase
				showActionsOnHover
				className={classes.relative}
				title={firstname + ' ' + lastname}
				subtitle={email}
				variant="row"
				rowInfoGridSize={{ md: 4, sm: 12 }}
				cardHeader={
					<CardContent className={classes.center}>
						<UserAvatar
							size="large"
							user_id={id}
						/>
					</CardContent>
				}
				info={
					props.size !== 'small'
						? [
								{
									label: t`settings-users-card-created`,
									value: (
										<Typography>
											<FormattedDate date={created} />
										</Typography>
									),
									size: {
										sm: 6,
										md: 2,
									},
								},
								{
									label: t`settings-users-card-reports`,
									value: uniqueProjects,
									size: {
										sm: 6,
										md: 2,
									},
								},
								{
									label: t`settings-users-card-access_groups`,
									value: (
										<>
											{groupsChips.map(group => (
												<Chip
													label={group.name}
													size="small"
													className={classes.accessGroupChip}
													key={group.uuid}
												/>
											))}
											{remainingGroups.length > 0 && (
												<Tooltip
													title={remainingGroups.map(group => group.name).join(', ')}
												>
													<Chip
														label={`+${remainingGroups.length}`}
														size="small"
													/>
												</Tooltip>
											)}
										</>
									),
									size: {
										md: 4,
										sm: 12,
									},
								},
						  ]
						: null
				}
				actions={
					<Fragment>
						<ActionIconButton
							action="view"
							link
							to={`/application/users/profile/${id}`}
							tooltip={t`View`}
						/>

						<NoPermissionTooltip>
							<ActionIconButton
								onClick={() => setDelete(true)}
								tooltip={t`Delete`}
								action="delete"
								disabled={!hasEditPermission || user.uuid === app.users.current.uuid}
								data-test-element="user-card-delete-button"
							/>
						</NoPermissionTooltip>
					</Fragment>
				}
				{...props}
			/>

			<UserAdditDialog
				user={user}
				additOpen={editOpen}
				setAddit={e => setEditOpen(e)}
			/>
			<AjaxFormDialog
				AjaxFormProps={{
					url: '/application/users/delete',
					data: { id },
					onSuccess: () => {
						app.api.getUsers();
						enqueueSnackbar(`User deleted`);
					},
				}}
				open={deleteOpen}
				onClose={() => setDelete(false)}
				title={t`Delete user`}
				dataTrackEvent={'settings_user_deleted'}
				dataTestElement="user-delete-dialog-submit-button"
				content={
					<Fragment>
						{t`Do you want to permanently delete this user?`}{' '}
						<strong>{t`You won't be able to undo this.`}</strong>
					</Fragment>
				}
			/>
		</Fragment>
	);
});
