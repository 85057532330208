import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ButtonRightIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<path
				id="XMLID_4_"
				d="M1,36H0v-4h1V36z M8,0H4v1h4V0z M16,0h-4v1h4V0z M8,40h4v-1H8V40z M1,16H0v4h1V16z M1,0H0v4h1V0z M1,24H0v4h1
	V24z M1,8H0v4h1V8z M16,40h4v-1h-4V40z M0,40h4v-1H0V40z M39,13h-4c-0.5,0-1,0.5-1,1v12c0,0.5,0.5,1,1,1h5v-3v-4v-4v-3v-1h-1V13z
	 M39,32h1v-4h-1V32z M39,8h1V4h-1V8z M36,0v1h4V0H36z M32,0h-4v1h4V0z M24,40h4v-1h-4V40z M39,40h1v-4h-1V40z M24,0h-4v1h4V0z
	 M32,40h4v-1h-4V40z"
			/>
		</SvgIcon>
	);
}
