import React, { useEffect, useState } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Stepper from '@material-ui/core/Stepper';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { useAppContext } from '@/components/AppContext';
import { ActionIconButton, Typography, Grid, Step, StepLabel } from '@/components/Layout';
import useOnboardingContext from './useOnboardingContext';
import { capitalizeValue } from './onboardingUtils';

import OnboardingAccountInfo from './OnboardingAccountInfo';
import OnboardingSetPassword from './OnboardingSetPassword';
import OnboardingChooseChannel from './OnboardingChooseChannel';

const useStyles = makeStyles(theme => ({
	snackbar: {
		maxWidth: 500,
		paddingRight: theme.spacing(3),
	},
	close: {
		position: 'absolute',
		top: 0,
		right: 20,
	},
	goal: {
		cursor: 'pointer',
		padding: theme.spacing(3, 2),
		'& svg': {
			color: theme.palette.grey[600],
		},
	},
	center: {
		textAlign: 'center',
	},
}));

export default function InitialUserOnboarding() {
	const classes = useStyles();
	const { t } = useTranslation();

	const {
		app: { users },
	} = useAppContext();
	const onboarding = useOnboardingContext();

	const [activeStep, setActiveStep] = useState(onboarding.initial_user_onboarding_step);

	const steps = ['Details', 'Password', 'Channel'];
	const [transition, setTransition] = useState(undefined);
	const [returnMessage, setReturnMessage] = useState(false);

	useEffect(() => {
		//reroute to correct step after page load
		if (onboarding.initial_user_onboarding_step !== activeStep) {
			setActiveStep(Number(onboarding.initial_user_onboarding_step));

			setTimeout(() => {
				setTransition(() => TransitionUp);
			}, 1000);
		}
	}, [onboarding.initial_user_onboarding_step, activeStep]);

	useEffect(() => {
		if (onboarding.isReturningOnboarding) {
			setReturnMessage(true);
		}
	}, [onboarding.isReturningOnboarding]);

	function TransitionUp(props) {
		return (
			<Slide
				{...props}
				direction="up"
			/>
		);
	}

	function setStep(step) {
		const pageNr =
			step === 'previous' ? activeStep - 1 : step === 'next' ? activeStep + 1 : step;
		setActiveStep(pageNr);
		onboarding.dispatch({
			type: 'set_signup_step',
			payload: {
				step: pageNr,
			},
		});
	}

	return (
		<Grid
			container
			direction="column"
			spacing={4}
			justify="center"
			justifyContent="center"
		>
			<Grid
				item
				className={classes.center}
			>
				<Stepper activeStep={activeStep}>
					{steps.map((label, index) => {
						return (
							<Step
								key={label}
								className={classes.stepper}
							>
								<StepLabel></StepLabel>
							</Step>
						);
					})}
				</Stepper>
			</Grid>
			{activeStep === 0 && (
				<OnboardingAccountInfo onAccountInfoUpdated={() => setStep('next')} />
			)}
			{activeStep === 1 && (
				<OnboardingSetPassword
					onPasswordSet={() => setStep('next')}
					initialUser
				/>
			)}
			{activeStep === 2 && <OnboardingChooseChannel />}

			<Snackbar
				className={classes.snackbar}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				key={transition ? transition.name : ''}
				open={returnMessage}
				TransitionComponent={transition}
				autoHideDuration={10000}
				onClose={() => setReturnMessage(false)}
				message={
					<Grid
						container
						spacing={2}
						direction="column"
					>
						<Grid item>
							<Typography
								variant="body1"
								gutterBottom
							>
								{t('onboarding-getting_started-return_message-title', {
									name: capitalizeValue(users.current.firstname),
								})}
							</Typography>
							<Typography variant="body1">{t`onboarding-getting_started-return_message-subtitle`}</Typography>
							<Typography variant="body2">{t`onboarding-getting_started-return_message-text`}</Typography>
						</Grid>
					</Grid>
				}
				action={
					<ActionIconButton
						aria-label="close"
						action="close"
						color="inherit"
						className={classes.close}
						onClick={() => setReturnMessage(false)}
					/>
				}
			/>
		</Grid>
	);
}
