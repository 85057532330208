import React from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { useAppContext } from '@/components/AppContext';
import { Typography, Box, Skeleton } from '@/components/Layout';
import useGetSummary from './useGetSummary';

const useStyles = makeStyles(theme => ({
	tooltip: {
		color: theme.palette.grey['900'],
		background: theme.palette.getContrastText(theme.palette.primary.dark),
	},
}));

export default function RecapSummary({
	data: dataFromProps,
	loading: loadingFromProps,
	smartRecapsProjectActive,
	surveyId,
	...props
}) {
	const { t } = useTranslation();
	const classes = useStyles();

	const {
		app: { date },
	} = useAppContext();

	const [data, loading, error, loadData] = useGetSummary({
		wait: !surveyId || dataFromProps || !smartRecapsProjectActive,
		surveyId,
	});

	const whichData = dataFromProps?.response?.results ?? data?.response?.results ?? [];

	const smAndUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
	const mdAndUp = useMediaQuery(theme => theme.breakpoints.up('md'));
	const boxWidth = mdAndUp ? '50%' : smAndUp ? '75%' : '100%';

	return (
		<>
			{(loading || loadingFromProps) && (
				<Box width={boxWidth}>
					<Skeleton
						variant="text"
						width="90%"
					/>
					<Skeleton
						variant="text"
						width="70%"
					/>
					<Skeleton
						variant="text"
						width="80%"
					/>
				</Box>
			)}
			{!loading && !loadingFromProps && whichData.length > 0 && (
				<Box width={boxWidth}>
					<Typography
						variant="caption"
						component="div"
						mb={1}
					>
						{t('reporting-feedback_inbox-smart_recaps-summary-date', {
							fromDate: date.fromDate,
							toDate: date.toDate,
						})}
					</Typography>
					<Typography>
						{whichData[0]?.summary?.[0] ??
							t`reporting-feedback_inbox-smart_recaps-summary-no_summary`}
					</Typography>
				</Box>
			)}
		</>
	);
}
