import { axiosInstance } from '@/lib/axios';
import objectToFormData from 'object-to-formdata';

export const Mjolnir = {
	pending: {},
	handlePending(path, data) {
		if (this.pending[path]) {
			return false;
		} else {
			this.pending[path] = {
				path: path,
				cancel: () => {},
				count: 0,
			};
			return true;
		}
	},
	cancelPending(path) {
		try {
			// this.pending[path].cancel();
			delete this.pending[path];
		} catch (e) {}
	},
	init(csrf) {
		this.token = csrf;
	},
	data(p = '', method, data) {
		return {
			method: method,
			path: p.startsWith('/') ? p : `/api/1/config/${p}`,
			token: this.token,
			state: data ? JSON.stringify(data) : '',
		};
	},
	get(p, params = {}, config = {}) {
		if (!this.handlePending(p)) {
			return {};
		}

		return axiosInstance
			.post('/api/mjolnir', objectToFormData(this.data(p, 'get')), config)
			.then(r => {
				delete this.pending[p];
				return r.data;
			})
			.catch(error => error);
	},
	post(p, postData = {}, config = {}) {
		return axiosInstance
			.post('/api/mjolnir', objectToFormData(this.data(p, 'post', postData)), config)
			.then(r => r.data)
			.catch(e => e);
	},
	delete(p, postData, config = {}) {
		return axiosInstance
			.post('/api/mjolnir', objectToFormData(this.data(p, 'delete', postData)), config)
			.then(r => r.data)
			.catch(e => e);
	},
};

window.__MJ = Mjolnir;
