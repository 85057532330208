import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

export default function LocaleProvider({ children, locale }) {
	return (
		<MuiPickersUtilsProvider
			utils={DateFnsUtils}
			locale={locale}
		>
			{children}
		</MuiPickersUtilsProvider>
	);
}
