import React from 'react';

import { useTranslation } from 'react-i18next';

import { TextField, MenuItem } from '@/components/Layout';
import { departments } from './constants';

export default function DepartmentSelect(props) {
	const { t } = useTranslation();
	return (
		<TextField
			select
			fullWidth
			{...props}
		>
			{departments.map(department => (
				<MenuItem value={department}>
					{t(`settings-users-departments-${department}`)}
				</MenuItem>
			))}
		</TextField>
	);
}
