import React from 'react';

import { useImmer } from 'use-immer';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { useAppContext } from '@/components/AppContext';
import {
	OptionalDroppable,
	RenderConditional,
	CollapsibleBox,
	Typography,
} from '@/components/Layout';
import { HasProjectPermission } from '@/components/Permission';
import DataSourceItem from './DataSourceItem';
import { useDataSourceContext } from './DataSourceContext';
import DataFieldList from './DataFieldList';

const useStyles = makeStyles(theme => ({
	title: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(2),
		marginBottom: theme.spacing(1),
	},
	subtitle: {
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(2),
	},
	dataSourceItem: {
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	divWrapper: {
		borderTop: `1px solid ${theme.palette.divider}`,
	},
	collapseBox: {
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	collapseButton: {
		width: '100%!important',
		borderRadius: 0,
	},
	collapseLabel: {
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(2),
		marginBottom: theme.spacing(1),
	},
}));

export default function DataSourceList({
	dataSources,
	onSelectDataSource = () => {},
	withFields,
	dragSource,
	dragFields,
	selectedSurveys = [],
	selectedDataFields = [],
	addIndicators = [],
	collapsible,
	scaleInAnimation,
	DataFieldListProps = {},
	...props
}) {
	const classes = useStyles();
	const {
		datasource: { forms, datasets, integrations },
	} = useDataSourceContext();
	const [open, setOpen] = useImmer({});
	const { t } = useTranslation();
	const { app } = useAppContext();

	function toggleOpen(id) {
		setOpen(draft => {
			draft[id] = !!!draft[id];
		});
	}

	const dataSourceArray = dataSources
		? dataSources
		: [
				{
					name: t('app_general-datasource_list-data_source_datasource-forms'),
					arr: forms.asArray,
				},
				{
					name: t('app_general-datasource_list-data_source_datasource-datasets'),
					arr: datasets.asArray,
				},
				{
					name: t('app_general-datasource_list-data_source_datasource-integrations'),
					arr: integrations.asArray,
				},
		  ];

	return (
		<>
			{dataSourceArray
				.filter(obj => obj.arr && obj.arr.length)
				.map((obj, index) => {
					return (
						<HasProjectPermission
							key={obj.arr[0]?.project_id + index}
							projectId={obj.arr[0]?.project_id}
						>
							{hasProjectPermission => (
								<RenderConditional
									component={CollapsibleBox}
									condition={collapsible || !hasProjectPermission}
									disabled={!hasProjectPermission}
									startCollapsed={true}
									data-test-element="datasource-list-collapse"
									label={
										obj.name ? (
											<Typography
												variant="subtitle2"
												className={classes.collapseLabel}
											>
												{obj.name}
											</Typography>
										) : (
											<Typography
												variant="body2"
												color="textSecondary"
												className={classes.collapseLabel}
											>
												{obj.subtitle}
											</Typography>
										)
									}
									ActionButtonProps={{ classes: classes.collapseButton }}
									className={classes.collapseBox}
								>
									<OptionalDroppable
										key={'drop' + obj.name}
										droppable={dragSource}
										droppableId={obj.name || obj.subtitle}
										isDropDisabled={true}
									>
										<div key={obj.name || obj.subtitle}>
											{!collapsible && obj.name && (
												<Typography
													variant="subtitle2"
													className={classes.title}
												>
													{obj.name}
												</Typography>
											)}
											{!collapsible && obj.subtitle && (
												<Typography
													variant="body2"
													color="textSecondary"
													className={classes.subtitle}
												>
													{obj.subtitle}
												</Typography>
											)}
											{obj.arr.map(dataSource => {
												const productInPackage = app.api.packageProductAllowed(
													dataSource.campaign
												);

												return (
													<div key={dataSource.id}>
														<DataSourceItem
															{...dataSource}
															title={dataSource.name}
															disabled={!productInPackage}
															onClick={e => {
																if (withFields) toggleOpen(dataSource.id);
																onSelectDataSource(dataSource, e);
															}}
															isToggle={withFields}
															isOpen={open[dataSource.id]}
															dragSource={dragSource}
															index={index}
															dataSource={dataSource}
															selected={selectedSurveys.find(id => id == dataSource.id)}
														>
															{withFields && (
																<DataFieldList
																	open={open[dataSource.id]}
																	sourceId={dataSource.id}
																	dragFields={dragFields}
																	selectedDataFields={selectedDataFields}
																	scaleInAnimation={scaleInAnimation}
																	isDraggable={dragFields}
																	droppableId={'survey-' + dataSource.id}
																	addIndicators={addIndicators}
																	{...DataFieldListProps}
																/>
															)}
														</DataSourceItem>
													</div>
												);
											})}
										</div>
									</OptionalDroppable>
								</RenderConditional>
							)}
						</HasProjectPermission>
					);
				})}
		</>
	);
}
