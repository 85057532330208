import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TagChip from './TagChip';
import Button from '@material-ui/core/Button';
import MuiLink from '@material-ui/core/Link';
import Collapse from '@material-ui/core/Collapse';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useTranslation, Trans } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useAppContext } from '../AppContext';
import { useDataSourceContext, DataSourceAvatar } from '../DataSources';
import { useTagsEditorContext } from './TagsEditorContext';
import { ActionIconButton, SimpleDialog, Typography } from '../Layout';
import { useListFilterContext } from '../FilteredLists';
import { parseConditionValues } from './tagUtils';
import { useHasPermission, NoPermissionTooltip } from '../Permission';

const useStyles = makeStyles(theme => ({
	align: {
		verticalAlign: 'middle',
	},
	contentRight: {
		textAlign: 'right',
	},
	contentCenter: {
		textAlign: 'center',
	},
	fillWidth: {
		width: '100%',
	},
	reducePadding: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	spacing: {
		margin: theme.spacing(2),
	},
	spaceRight: {
		marginRight: theme.spacing(0.25),
	},
	spaceBottom: {
		marginBottom: theme.spacing(1),
	},
	conditionSpacing: {
		marginBottom: theme.spacing(0.1),
	},
	conditionSetSpacing: {
		marginBottom: theme.spacing(2),
	},
	spaceChildren: {
		'& > *': {
			margin: theme.spacing(0.25),
		},
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	collapse: {
		fontWeight: theme.typography.fontWeightMedium,
		cursor: 'pointer',
		'& svg': {
			verticalAlign: 'bottom',
		},
	},
	noPadding: {
		padding: 0,
		'&:last-child': {
			paddingBottom: 0,
		},
	},
	usePadding: {
		padding: theme.spacing(3, 2),
	},
	ellipseMultipleLines: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	upperCase: {
		textTransform: 'uppercase',
	},
}));

export default function TagConditionListCard({
	setEditId = () => {},
	survey_id,
	add,
	id,
}) {
	const { t } = useTranslation();
	const { app } = useAppContext();
	const { state, api } = useTagsEditorContext();
	const { datasource } = useDataSourceContext();
	const source = datasource.api.getSource(survey_id);
	const campaign = source.campaign;
	const classes = useStyles({ campaign });
	const hasEditPermission = useHasPermission();
	const { filters } = useListFilterContext();

	const [deleteOpen, setDeleteOpen] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const ruleIndex = state.config.rules.findIndex(rule => rule.id === id);

	const [collapse, setCollapse] = useState(false);
	const [rowCollapse, setRowCollapse] = useState(false);

	function getFormDescription() {
		if (source.survey_type === 'import') {
			return 'dataset';
		}
		const format =
			source?.survey_format === 'conversational' ? 'Conversational' : 'Standard';
		const campaign = source.campaign === 'sdk' ? 'in-app' : source.campaign;

		return `${format} ${campaign} form`;
	}

	return (
		<Fragment>
			<Card>
				<CardContent className={classes.noPadding}>
					<Grid
						container
						direction="row"
						className
					>
						<Grid
							item
							className={classes.usePadding}
						>
							<DataSourceAvatar
								survey_type={source.survey_type}
								survey_format={source?.survey_format ?? 'form'}
								campaign={source.campaign}
							/>
						</Grid>
						<Grid
							item
							xs
							className={classes.usePadding}
						>
							<Grid
								container
								direction="row"
							>
								<Grid
									item
									xs={10}
									className={classes.spaceBottom}
								>
									<Typography variant="h6">{source.name ?? 'undefined'}</Typography>
									<Typography
										variant="body1"
										gutterBottom
									>
										{t(getFormDescription())}
									</Typography>
								</Grid>
								<Grid
									item
									xs={2}
									className={classes.contentRight}
								>
									<NoPermissionTooltip>
										<ActionIconButton
											tooltip={t`Edit auto-tag conditions`}
											action="edit"
											onClick={() => setEditId(id)}
											disabled={!hasEditPermission}
										/>
									</NoPermissionTooltip>
									<NoPermissionTooltip>
										<ActionIconButton
											tooltip={t`Delete auto-tag conditions`}
											action="delete"
											onClick={() => setDeleteOpen(true)}
											disabled={!hasEditPermission}
										/>
									</NoPermissionTooltip>
								</Grid>

								<Grid
									item
									xs={12}
								>
									{add.map((conditionSet, index) => {
										const conditionsLength = conditionSet.conditions.filter(
											condition => condition.datafield_id
										).length;

										return (
											<Collapse
												key={`conditionset-${index}`}
												in={index ? collapse === id || filters?.tags?.length : true}
											>
												<Grid
													container
													className={classes.conditionSetSpacing}
												>
													<Grid
														item
														xs={4}
													>
														<Typography
															variant="caption"
															color="textSecondary"
															display="block"
														>
															{conditionSet.tags.length > 1 ? t`Add tags` : t`Add tag`}
														</Typography>
														<span className={classes.spaceChildren}>
															{conditionSet.tags.map(tag => (
																<TagChip
																	key={tag}
																	small
																	label={tag}
																/>
															))}
														</span>
													</Grid>

													<Grid
														item
														xs={8}
													>
														{conditionSet.conditions
															.filter(condition => condition.datafield_id)
															.map((condition, index) => {
																const field = datasource.api.getField(
																	condition.datafield_id
																);

																const conditionValues = parseConditionValues(
																	field,
																	condition.value,
																	`<uppercase>${t`or`}</uppercase>`
																);

																return (
																	<Collapse
																		key={`condition-${index}`}
																		in={index > 2 ? rowCollapse === id : true}
																	>
																		<Grid
																			container
																			spacing={1}
																			className={classes.conditionSpacing}
																		>
																			<Grid
																				item
																				xs={6}
																			>
																				<Typography
																					variant="caption"
																					color="textSecondary"
																					display="block"
																				>
																					{index === 0 ? t`when` : t`and when`}
																				</Typography>
																				<Typography
																					variant="body2"
																					display="block"
																					className={classes.ellipseMultipleLines}
																				>
																					{field.system_var ?? t`loading...`}
																				</Typography>
																			</Grid>
																			<Grid
																				item
																				xs={6}
																			>
																				<Typography
																					variant="caption"
																					color="textSecondary"
																					display="block"
																				>
																					{t('operator.' + condition.operator)}
																				</Typography>
																				<Typography
																					variant="body2"
																					display="block"
																					className={classes.ellipseMultipleLines}
																				>
																					<Trans
																						i18nKey={conditionValues}
																						components={{
																							uppercase: (
																								<Typography
																									className={classes.upperCase}
																									variant="caption"
																									color="textSecondary"
																									fontWeight="bold"
																									component="span"
																								/>
																							),
																						}}
																					/>
																				</Typography>
																			</Grid>
																		</Grid>
																	</Collapse>
																);
															})}
														{conditionsLength > 2 && (
															<Grid
																container
																alignItems="center"
															>
																<Grid item>
																	<MuiLink
																		variant="body2"
																		onClick={() => {
																			setRowCollapse(rowCollapse !== id ? id : false);
																		}}
																		className={classes.collapse}
																	>
																		{rowCollapse !== id
																			? t('Show all {{number}} conditions', {
																					number: conditionsLength,
																			  })
																			: t`Show less conditions`}
																		<ExpandMoreIcon
																			fontSize="small"
																			className={`${classes.expand} ${
																				rowCollapse === id ? classes.expandOpen : ''
																			}`}
																		/>
																	</MuiLink>
																</Grid>
															</Grid>
														)}
													</Grid>
												</Grid>
											</Collapse>
										);
									})}
									{add.length > 1 && !filters?.tags?.length > 0 && (
										<Grid
											container
											alignItems="center"
										>
											<Grid item>
												<MuiLink
													variant="body2"
													onClick={() => {
														setCollapse(collapse !== id ? id : false);
													}}
													className={classes.collapse}
												>
													{collapse !== id
														? t('Show all {{number}} condition sets', {
																number: add.length,
														  })
														: t`Show less condition sets`}
													<ExpandMoreIcon
														fontSize="small"
														className={`${classes.expand} ${
															collapse === id ? classes.expandOpen : ''
														}`}
													/>
												</MuiLink>
											</Grid>
										</Grid>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
			</Card>

			<SimpleDialog
				open={deleteOpen}
				onClose={() => setDeleteOpen(false)}
				title={t`Delete auto-tag conditions`}
				text={
					Object.keys(source).length === 0
						? t`Are you sure you want to delete this automated tag configuration?`
						: `${t`Are you sure you want to delete this automated tag configuration`} ${t`for`} ${
								source.survey_type === 'builder' ? t`feedback form` : t`dataset`
						  } ${source.name}?`
				}
				submit={t`Delete setup`}
				dataTrackEvent="settings_automated_tag_deleted"
				onSubmit={() => {
					api.dispatch({
						type: 'delete_rule',
						payload: {
							index: ruleIndex,
						},
					});

					const key = enqueueSnackbar(t`Automated tag setup deleted`, {
						action: (
							<Button
								color="secondary"
								onClick={() => {
									api.doUndo();
									closeSnackbar(key);
								}}
							>
								{t`Undo`}
							</Button>
						),
					});
				}}
			/>
		</Fragment>
	);
}
