import React, { useState, useEffect, useRef } from 'react';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { DrawerWithAppBar } from '@/components/App';
import { ValidatedField } from '@/components/Validation';
import {
	ActionButton,
	TextField,
	MenuItem,
	Paper,
	Typography,
	List,
	ListItemWithRightContent,
	ActionIconButton,
	Loader,
	SimpleDialog,
	Chip,
	Box,
	InputAdornment,
} from '@/components/Layout';
import { removeArrayDuplicatesFromShallowClone } from '@/utils';
import useGetExclusionList from './useGetExclusionList';
import useSetExclusionList from './useSetExclusionList';

import { valueToFilteredArray } from './textAnalyticsUtils';

const useStyles = makeStyles(theme => ({
	addButton: {
		bottom: theme.spacing(0.4),
	},
	paper: {
		padding: theme.spacing(2),
	},
	paddingBottom: {
		paddingBottom: theme.spacing(4),
	},
	sort: {
		minWidth: 120,
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(2),
	},
	wordError: {
		color: theme.palette.error.main,
	},
	chip: {
		marginLeft: theme.spacing(1),
	},
	minHeight: {
		minHeight: '30vh',
	},
	list: {
		width: '100%',
	},
}));

function sortByAlphabet(array, desc) {
	if (!Array.isArray(array)) {
		return [];
	}

	const asc = [...array].sort((a, b) => {
		return String(a).toLocaleLowerCase().localeCompare(String(b).toLocaleLowerCase());
	});

	return desc ? asc.reverse() : asc;
}

const sortOptions = [
	{
		label: `reporting-text_analytics-exclusion_list_drawer-sort-a_z`,
		value: 'created-asc',
	},
	{
		label: `reporting-text_analytics-exclusion_list_drawer-sort-z_a`,
		value: 'created-desc',
	},
];

export default function ExclusionListDrawer({
	language,
	languageKey,
	open,
	onClose = () => {},
	...props
}) {
	const { t } = useTranslation();
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const [state, setState] = useState({
		inputValue: '',
		sortOrder: 'created-asc',
		listIsUpdated: false,
		confirmationDialog: false,
		collectionErrorDialog: false,
	});

	const [exclusionList, loading, err, loadExclusionList, setExclusionList] =
		useGetExclusionList(languageKey, true);

	useEffect(() => {
		if (open) {
			loadExclusionList();
		}
	}, [open]);
	const prevExclusionList = useRef(null);
	useEffect(() => {
		if (Array.isArray(exclusionList) && !Array.isArray(prevExclusionList.current)) {
			prevExclusionList.current = exclusionList;
		}
	}, [exclusionList]);

	const { postForm: saveExclusionList, loading: saveLoading } = useSetExclusionList({
		language: languageKey,
		list_value: exclusionList,
		onSuccess: response => {
			if (response.code === 200 && response?.response?.reply) {
				enqueueSnackbar(
					<div>
						<Typography
							fontWeight="bold"
							variant="subtitle2"
							gutterBottom
						>
							{t`reporting-text_analytics-exclusion_list-list_updated_title`}
						</Typography>
						<Typography variant="body2">
							{t`reporting-text_analytics-exclusion_list-list_updated_message`}
						</Typography>
					</div>
				);
				reset();
				onClose();
			} else if (response.code === 200 && !response?.response?.reply) {
				setState(prevState => ({
					...prevState,
					collectionErrorDialog: true,
				}));
			} else {
				enqueueSnackbar(t`reporting-text_analytics-exclusion_list-update_error_message`);
			}
		},
	});

	function reset() {
		setState(prevState => ({
			...prevState,
			inputValue: '',
			listIsUpdated: false,
			confirmationDialog: false,
		}));
		prevExclusionList.current = null;
	}

	function addToArray(value = '') {
		setExclusionList(state =>
			removeArrayDuplicatesFromShallowClone(state, valueToFilteredArray(value))
		);
		setState(prevState => ({
			...prevState,
			listIsUpdated: true,
			inputValue: '',
			showErrors: false,
		}));
	}

	function removeFromArray(value) {
		setExclusionList(prevState => {
			return prevState.filter(id => id !== value);
		});
		setState(prevState => ({
			...prevState,
			listIsUpdated: true,
		}));
	}

	const sortedExclusionList = sortByAlphabet(
		exclusionList,
		state.sortOrder === 'created-desc'
	);

	return (
		<DrawerWithAppBar
			ToolbarProps={{
				text: t(`reporting-text_analytics-exclusion_list_drawer-title`, {
					language: t(`reporting-text_analytics-${language}`),
				}),
			}}
			ToolbarContent={
				<ActionButton
					variant="contained"
					color="primary"
					action="save"
					disabled={!state.listIsUpdated}
					loading={saveLoading}
					onClick={saveExclusionList}
					data-track-event="text_analytics-exclusion_list-save"
				>
					{t`reporting-text_analytics-exclusion_list_drawer-button_save`}
				</ActionButton>
			}
			onClose={() => {
				if (state.listIsUpdated) {
					setState(prevState => ({
						...prevState,
						confirmationDialog: true,
					}));
				} else {
					onClose();
				}
			}}
			open={open}
			{...props}
		>
			<Paper className={classes.paper}>
				<Box
					mb={2}
					display="flex"
					justifyContent="center"
					flexWrap="nowrap"
				>
					<ValidatedField
						disabled={loading}
						label={t`reporting-text_analytics-exclusion_list_drawer-input-label`}
						fullWidth
						onChange={e => {
							const { value } = e.currentTarget;
							setState(prevState => ({
								...prevState,
								inputValue: value,
								showErrors: true,
							}));
						}}
						value={state.inputValue}
						rules={{
							required: true,
						}}
						messages={{
							required: t`reporting-text_analytics-exclusion_list_drawer-input-error_empty`,
						}}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								addToArray(state.inputValue);
							}
						}}
						showErrors={state.showErrors}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<ActionButton
										variant="contained"
										color="primary"
										size="small"
										disabled={loading}
										className={classes.addButton}
										data-track-event="text_analytics-exclusion_list-add_word"
										onClick={() => {
											addToArray(state.inputValue);
										}}
									>
										{t`reporting-text_analytics-exclusion_list_drawer-input-add`}
									</ActionButton>
								</InputAdornment>
							),
						}}
					/>

					<TextField
						className={classes.sort}
						select
						value={state.sortOrder}
						onChange={e => {
							setState(prevState => ({
								...prevState,
								sortOrder: e.target.value,
							}));
						}}
						classes={{
							root: classes.alignMiddle,
						}}
					>
						{sortOptions.map(option => (
							<MenuItem
								key={option.value}
								value={option.value}
							>
								{t(option.label)}
							</MenuItem>
						))}
					</TextField>
				</Box>
				{loading && (
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						className={classes.minHeight}
					>
						<Loader circular />
					</Box>
				)}
				{!loading && (
					<>
						{sortedExclusionList.length === 0 && (
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								className={classes.minHeight}
							>
								<Typography color="TextSecondary">
									{t`reporting-text_analytics-exclusion_list_drawer-empty_state`}
								</Typography>
							</Box>
						)}
						{sortedExclusionList.length > 0 && (
							<List
								disablePadding
								className={classes.minHeight}
							>
								{sortedExclusionList.map(item => {
									return (
										<ListItemWithRightContent
											className={item === state.inputValue ? classes.wordError : ''}
											primary={
												<>
													{item}
													{!prevExclusionList.current?.includes(item) && (
														<Chip
															size="small"
															color="primary"
															className={classes.chip}
															label={t`reporting-text_analytics-exclusion_list_drawer-chip_new`}
														/>
													)}
												</>
											}
											action={
												<ActionIconButton
													action="delete"
													data-track-event="text_analytics-exclusion_list-remove_word"
													onClick={() => removeFromArray(item)}
												/>
											}
										/>
									);
								})}
							</List>
						)}
					</>
				)}
			</Paper>
			<SimpleDialog
				open={state.confirmationDialog}
				loading={saveLoading}
				onClose={(e, from) => {
					setState(prevState => ({
						...prevState,
						confirmationDialog: false,
					}));
					if (from.button) {
						reset();
						onClose();
					}
				}}
				title={t`reporting-text_analytics-exclusion_list-dialog_dismiss-title`}
				text={t`reporting-text_analytics-exclusion_list-dialog_dismiss-text`}
				cancel={t`reporting-text_analytics-exclusion_list-dialog_dismiss-cancel`}
				submit={t`reporting-text_analytics-exclusion_list-dialog_dismiss-save`}
				onSubmit={() => {
					saveExclusionList();
				}}
			/>
			<SimpleDialog
				open={state.collectionErrorDialog}
				onClose={() => {
					setState(prevState => ({
						...prevState,
						collectionErrorDialog: false,
					}));
				}}
				title={t`reporting-text_analytics-exclusion_list-dialog_error_incorrect_set-title`}
				text={t`reporting-text_analytics-exclusion_list-dialog_error_incorrect_set-text`}
				submit={t`reporting-text_analytics-exclusion_list-dialog_error_incorrect_set-save`}
				onSubmit={() => {
					setState(prevState => ({
						...prevState,
						collectionErrorDialog: false,
					}));
				}}
			/>
		</DrawerWithAppBar>
	);
}
