import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FormSemiRoundedIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			{props.selected ? (
				<g>
					<path d="M36,2c1.103,0,2,0.897,2,2v8h2V4c0-2.209-1.791-4-4-4h-8v2H36z" />
					<path d="M2,4c0-1.103,0.897-2,2-2h8V0H4C1.791,0,0,1.791,0,4v8h2V4z" />
					<path d="M38,36c0,1.103-0.897,2-2,2h-8v2h8c2.209,0,4-1.791,4-4v-8h-2V36z" />
					<path d="M4,38c-1.103,0-2-0.897-2-2v-8H0v8c0,2.209,1.791,4,4,4h8v-2H4z" />
				</g>
			) : (
				<g>
					<path d="M39,36c0,1.654-1.346,3-3,3h-8v1h8c2.209,0,4-1.791,4-4v-8h-1V36z" />
					<path d="M4,39c-1.654,0-3-1.346-3-3v-8H0v8c0,2.209,1.791,4,4,4h8v-1H4z" />
					<path d="M36,1c1.654,0,3,1.346,3,3v8h1V4c0-2.209-1.791-4-4-4h-8v1H36z" />
					<path d="M1,4c0-1.654,1.346-3,3-3h8V0H4C1.791,0,0,1.791,0,4v8h1V4z" />
				</g>
			)}
		</SvgIcon>
	);
}
