import React, { Fragment, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { KeyboardDatePicker } from '@material-ui/pickers';

import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';

import { useTranslation, Trans } from 'react-i18next';

import { useAppContext } from '../AppContext';
import { Typography, Alert, Loader } from '../Layout';
import { ValidatedField } from '../Validation';
import ExportFilters from './ExportFilters';
import ExportSettings from './ExportSettings';
import ExportDatePicker from './ExportDatePicker';
import { exportOptions } from './constants';
import { formatISOdate } from './exportUtils';
import { makeDateObject } from '../../helpers';

import { isValid, format } from '../../utils/date';

const useStyles = makeStyles(theme => ({
	grid: {
		'& > div': {
			width: '100%',
		},
	},
	stepper: {
		paddingLeft: 0,
		paddingRight: 0,
		paddingBottom: 0,
	},
	marginRight: {
		marginRight: theme.spacing(2),
	},
	error: {
		color: theme.palette.error.main,
	},
	wrap: {
		flexGrow: 1,
		minHeight: 175,
		height: '100%',
		maxWidth: '100%',
	},
	menuDefault: {
		display: 'none',
	},
	adjust: {
		marginTop: 5,
	},
}));

export default function FeedbackQuickExport({
	exportConfig,
	setExportConfig,
	templatesLoading,
	...props
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	const {
		app,
		app: { date },
	} = useAppContext();

	const [dateAnchor, setDateAnchor] = useState(null);
	const [templateAnchor, setTemplateAnchor] = React.useState(null);

	const templateOpen = Boolean(templateAnchor);

	return (
		<Grid
			container
			spacing={3}
		>
			{exportConfig.variant === 'use_template' && (
				<Fragment>
					<Grid
						item
						xs
					>
						{templatesLoading ? (
							<Grid
								container
								direction="row"
								spacing={2}
							>
								<Grid item>
									<Loader
										circular
										size={20}
									/>
								</Grid>
								<Grid
									item
									xs
								>
									<Typography color="textSecondary">{t`Fetching templates...`}</Typography>
								</Grid>
							</Grid>
						) : (
							<ValidatedField
								select
								fullWidth
								disabled={exportConfig.templates.length ? false : true}
								value={exportConfig.template_selected}
								name={'template-selection'}
								rules={[['required', true]]}
								showErrors={exportConfig.clicked}
								errorMessage={t`Please pick a template`}
								label={
									exportConfig.templates.length
										? t`Select a template`
										: t`No templates available`
								}
								onChange={e => {
									setExportConfig(draft => {
										draft.template_selected = e.target.value;
									});
								}}
							>
								<MenuItem
									className={classes.menuDefault}
									value={''}
								>
									{exportConfig.templates.length
										? t`Select a template`
										: t`No templates available`}
								</MenuItem>
								{exportConfig.templates.length > 0 &&
									exportConfig.templates.map(template => (
										<MenuItem
											key={template.uuid}
											value={template.uuid}
										>
											{template.name}
										</MenuItem>
									))}
							</ValidatedField>
						)}
					</Grid>
					{exportConfig.templates.length === 0 && (
						<Grid
							item
							xs={12}
						>
							<Alert severity="info">
								{t`You don't have any templates yet. You can save your filters and export settings in a template to make future exports easier.`}
							</Alert>
							<Button
								aria-describedby="start-advanced-flow"
								data-track-event="export_template_new_from_inbox"
								color="primary"
								onClick={() => {
									setExportConfig(draft => {
										draft.dialog_status = 'content';
										draft.variant = 'template';
										draft.save_as_template = true;
									});
								}}
							>
								{t`Make a template`}
							</Button>
						</Grid>
					)}
					{exportConfig.template_selected && (
						<Grid
							item
							xs={12}
						>
							<Button
								aria-describedby="template-popover"
								color="primary"
								onClick={e => setTemplateAnchor(e.currentTarget)}
							>
								{t`Show template settings`}
							</Button>
							<Popover
								id="template-popover"
								open={templateOpen}
								anchorEl={templateAnchor}
								onClose={() => setTemplateAnchor(null)}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'center',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'center',
								}}
							>
								<Box p={2}>
									<Grid
										container
										spacing={3}
									>
										<Grid
											item
											xs={6}
										>
											<ExportFilters
												exportData={exportConfig.templates.find(
													template => template.uuid === exportConfig.template_selected
												)}
												chipSize="small"
												variant="template"
											/>
										</Grid>
										<Grid
											item
											xs={6}
										>
											<ExportSettings
												exportData={exportConfig.templates.find(
													template => template.uuid === exportConfig.template_selected
												)}
												chipSize="small"
												variant="template"
											/>
										</Grid>
									</Grid>
								</Box>
							</Popover>
						</Grid>
					)}
					{exportConfig.variant !== 'use_template' ||
						(exportConfig.templates.length > 0 && (
							<Grid
								item
								xs={12}
							>
								<Typography
									variant="body1"
									color="textSecondary"
								>{t`Export date range`}</Typography>
								<Grid
									container
									justify="space-between"
									alignItems="center"
									spacing={2}
								>
									<Grid
										item
										xs={6}
									>
										<FormControl fullWidth>
											<InputLabel>{t`From date`}</InputLabel>
											<Input
												id="export-from"
												type="text"
												value={exportConfig.from_date ?? date.fromDate}
												onChange={e => setDateAnchor(e.currentTarget)}
												endAdornment={
													<InputAdornment position="end">
														<IconButton onClick={e => setDateAnchor(e.currentTarget)}>
															<InsertInvitationIcon />
														</IconButton>
													</InputAdornment>
												}
											/>
										</FormControl>
									</Grid>
									<Grid
										item
										xs={6}
									>
										<FormControl fullWidth>
											<InputLabel>{t`To date`}</InputLabel>
											<Input
												id="export-to"
												type="text"
												value={exportConfig.to_date ?? date.toDate}
												onChange={e => setDateAnchor(e.currentTarget)}
												endAdornment={
													<InputAdornment position="end">
														<IconButton onClick={e => setDateAnchor(e.currentTarget)}>
															<InsertInvitationIcon />
														</IconButton>
													</InputAdornment>
												}
											/>
										</FormControl>
									</Grid>
									<ExportDatePicker
										anchor={dateAnchor}
										resetAnchor={() => setDateAnchor(null)}
										fromDate={exportConfig.from_date}
										toDate={exportConfig.to_date}
										setFromDate={date => {
											setExportConfig(draft => {
												draft.from_date = date;
											});
										}}
										setToDate={date => {
											setExportConfig(draft => {
												draft.to_date = date;
											});
										}}
									/>
								</Grid>
							</Grid>
						))}
				</Fragment>
			)}
			{((exportConfig.variant !== 'run_schedule' &&
				exportConfig.variant !== 'change_schedule_status' &&
				exportConfig.variant !== 'use_template') ||
				(exportConfig.variant === 'use_template' &&
					exportConfig.templates.length > 0)) && (
				<Grid
					item
					xs={12}
				>
					<ValidatedField
						component={TextField}
						id="export-name"
						fullWidth
						label={t`Export name`}
						value={
							exportConfig.variant === 'use_template' && !exportConfig.template_selected
								? ''
								: exportConfig.export_name
						}
						rules={[['required', true]]}
						showErrors={exportConfig.clicked}
						errorMessage={t`Please fill in an export name`}
						onChange={e => {
							const exportName = e.currentTarget.value;
							setExportConfig(draft => {
								draft.export_name = exportName;
							});
						}}
					/>
				</Grid>
			)}
			{exportConfig.variant === 'quick' && (
				<Fragment>
					<Grid
						item
						xs={12}
					>
						<FormControl
							className={classes.formControl}
							fullWidth
						>
							<InputLabel>{t`Export as`}</InputLabel>
							<Select
								labelId="export-options"
								value={exportConfig.format}
								onChange={e => {
									setExportConfig(draft => {
										draft.format = e.target.value;
									});
								}}
							>
								{exportOptions.map((option, index) => (
									<MenuItem
										value={option.value}
										key={index}
									>
										{option.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid
						item
						xs={12}
					>
						<Typography
							variant="body1"
							color="textSecondary"
							gutterBottom
						>{t`Column names`}</Typography>
						<FormControl component="fieldset">
							<RadioGroup
								aria-label="rename_labels"
								name="rename_labels"
								value={
									exportConfig.prettify_labels
										? 'pretty_question_titles'
										: exportConfig.rename_labels
										? 'question_titles'
										: 'data_fields'
								}
								onChange={e => {
									setExportConfig(draft => {
										draft.rename_labels = e.target.value === 'data_fields' ? false : true;
										draft.prettify_labels =
											e.target.value === 'pretty_question_titles' ? true : false;
									});
								}}
							>
								<FormControlLabel
									value="question_titles"
									control={<Radio />}
									label={t`Use question titles as column name`}
								/>
								<FormControlLabel
									value="pretty_question_titles"
									control={<Radio />}
									label={t`Use form id, question titles and datafield id combined as column name`}
								/>
								<FormControlLabel
									value="data_fields"
									control={<Radio />}
									label={t`Use datafield name as column name`}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>

					{exportConfig.format === 'xlsx' && exportConfig.survey_ids.length !== 1 && (
						<Grid
							item
							xs={12}
						>
							<Typography
								variant="body1"
								color="textSecondary"
								gutterBottom
							>{t`Export sheet settings`}</Typography>
							<FormControl component="fieldset">
								<RadioGroup
									aria-label="export_sheets"
									name="export_sheets"
									value={exportConfig.split_into_sheets ? 'sheets' : 'single'}
									onChange={e => {
										setExportConfig(draft => {
											draft.split_into_sheets =
												e.target.value === 'sheets' ? true : false;
										});
									}}
								>
									<FormControlLabel
										value="single"
										control={<Radio />}
										label={t`Export all data sources into a single sheet`}
									/>
									<FormControlLabel
										value="sheets"
										control={<Radio />}
										label={t`Export data sources into separate sheets`}
									/>
								</RadioGroup>
							</FormControl>
						</Grid>
					)}
				</Fragment>
			)}
			{exportConfig.variant === 'run_schedule' && (
				<Fragment>
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="body1"
							color="textSecondary"
							gutterBottom
						>{t`Your export will contain data from the last scheduled export date until today.`}</Typography>
						{exportConfig.last_run_at ? (
							<Fragment>
								<Typography
									variant="body1"
									color="textSecondary"
									component="span"
								>{t`Daterange: `}</Typography>
								<strong> {formatISOdate(exportConfig.last_run_at)} </strong>
								<Typography
									variant="body1"
									color="textSecondary"
									component="span"
								>{t`to`}</Typography>
								<strong> {formatISOdate(new Date())} </strong>
							</Fragment>
						) : (
							<Grid
								container
								justify="space-between"
								alignItems="center"
								spacing={2}
							>
								<Grid
									item
									xs={12}
								>
									<Alert severity="info">
										{t`This schedule has no last exported date yet. Please selecte a 'from' date manually`}
									</Alert>
								</Grid>
								<Grid
									item
									xs={6}
								>
									<KeyboardDatePicker
										disableToolbar
										format={app.date.format}
										margin="normal"
										variant="inline"
										label={t`From date`}
										value={makeDateObject(
											exportConfig.from_date,
											app.date.separator ?? '/'
										)}
										onChange={date => {
											setExportConfig(draft => {
												if (isValid(date)) {
													draft.from_date = format(date, app.date.format);
												}
											});
										}}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
									/>
								</Grid>
								<Grid
									item
									xs={6}
								>
									<TextField
										className={classes.adjust}
										disabled
										fullWidth
										id="fixed-to-date"
										label={t`To date`}
										value={t`Today`}
									/>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Fragment>
			)}
			{exportConfig.variant === 'change_schedule_status' && (
				<Grid
					item
					xs={12}
				>
					<Typography
						variant="body1"
						color="textSecondary"
						component="span"
					>
						{exportConfig.enabled
							? t`Do you want to activate this scheduled export?`
							: t`Do you want to pause this scheduled export?`}
					</Typography>
				</Grid>
			)}
		</Grid>
	);
}
