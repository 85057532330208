import React, { Suspense } from 'react';

import Grow from '@material-ui/core/Grow';

import { I18nextProvider } from 'react-i18next';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, makeStyles, theme } from '@/styles';

import i18n from '@/i18n';

import AppContent from './AppContent';
import { AppContextProvider } from '@/components/AppContext';
import useLocale from './useLocale';
import LocaleProvider from './LocaleProvider';
import { TagsProvider } from '@/components/Tags';
import { DataSourceProvider } from '@/components/DataSources';
import { OnboardingProvider } from '@/components/Onboarding';
import { TrackingProvider } from '@/components/Tracking';
import { DashboardContextProvider } from '@/components/Dashboards';
import { Confetti, CloseSnackbarButton, MopinionLoader } from '@/components/Layout';
import { ErrorBoundary } from '@/components/Utility';

const useStyles = makeStyles(theme => ({
	success: {
		backgroundColor: props => props.palette.grey['A400'],
	},
	icon: {
		fontSize: theme.typography.pxToRem(24),
		marginRight: theme.spacing(1),
	},
	info: {
		background: '#2B81E5',
		marginTop: theme.spacing(7),
		'& button': {
			color: '#fff',
		},
	},
}));

export default function App(props) {
	const classes = useStyles(theme);
	const [locale, setLocale, localeObj] = useLocale();

	return (
		<ErrorBoundary>
			<ThemeProvider>
				<Suspense fallback={<MopinionLoader />}>
					<SnackbarProvider
						maxSnack={3}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						iconVariant={{
							success: (
								<div className={classes.icon}>
									{'🎉'} <Confetti />
								</div>
							),
						}}
						classes={{
							variantSuccess: classes.success,
							variantInfo: classes.info,
						}}
						preventDuplicate
						action={key => <CloseSnackbarButton closeKey={key} />}
						TransitionComponent={props => <Grow {...props} />}
					>
						<I18nextProvider i18n={i18n}>
							<AppContextProvider
								locale={locale}
								setLocale={setLocale}
							>
								<TrackingProvider>
									<TagsProvider>
										<DataSourceProvider>
											<LocaleProvider locale={localeObj}>
												<DashboardContextProvider>
													<OnboardingProvider>
														<Suspense fallback={<MopinionLoader />}>
															<AppContent />
														</Suspense>
													</OnboardingProvider>
												</DashboardContextProvider>
											</LocaleProvider>
										</DataSourceProvider>
									</TagsProvider>
								</TrackingProvider>
							</AppContextProvider>
						</I18nextProvider>
					</SnackbarProvider>
				</Suspense>
			</ThemeProvider>
		</ErrorBoundary>
	);
}
