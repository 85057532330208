import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useAppContext } from '../AppContext';

import { WEBHOOK_TYPES, useWebHookContext } from '../WebHook';

import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import { useTranslation } from 'react-i18next';

import { SimpleDialog } from '../Layout';

import { useAjaxForm, useEFM } from '../Ajax';

const useStyles = makeStyles(theme => ({
	secondary: {
		color: theme.palette.grey[600],
	},
}));

export default function FeedbackWebHookDialog({
	open,
	onClose = () => {},
	selectedHook = false,
	feedback_ids = [],
	onSuccess = () => {},
}) {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const {
		state: { hooks },
	} = useWebHookContext();

	const classes = useStyles();

	const hook =
		selectedHook && Array.isArray(hooks)
			? hooks.find(hook => hook.webhook_id == selectedHook)
			: false;
	const webhookType = hook
		? WEBHOOK_TYPES.find(type => type.value === hook.webhook_type)
		: {};

	const { postForm, loading, clicked } = useAjaxForm({
		url: '/webhook/userinit',
		type: 'mjolnir',
		data: {
			domain: app.domain,
			project_id: app.projects.current.id,
			data: {
				feedback_ids: feedback_ids,
				webhook_id: hook ? hook.webhook_id : 0,
			},
		},
		successMessage: hook
			? t('Feedback items sent to {{integration}}', { integration: hook.name })
			: '',
		onSuccess: r => {
			onClose();
			onSuccess(r);
			feedback_ids.forEach(id => {
				//check whether or not a feedback id was sent to the queue if so add to sessionStorage so we can add it to the feedback details right away
				const didSend =
					!r.ids_sent || (Array.isArray(r.ids_sent) && r.ids_sent.indexOf(id) > -1);
				if (didSend) {
					try {
						//mimic the response from the server

						const sentHookObj = {
							action_id: hook.webhook_id,
							created: new Date(),
							domain: app.domain,
							endpoints: [],
							event_type: 'feedback.user_init',
							feedback_id: id,
							label: hook.webhook_type,
							webhook_id: hook.webhook_id,
							webhook_type: hook.webhook_type,
							processing: true,
						};

						sessionStorage.setItem(`sent_webhook_${id}`, JSON.stringify(sentHookObj));
					} catch (e) {}
				}
			});
		},
	});

	return hook ? (
		<SimpleDialog
			maxWidth="sm"
			open={open}
			onClose={onClose}
			submit={t`Send integration`}
			dataTrackEvent="inbox_feedback_shared_webhook"
			title={t`Send feedback item to integration`}
			content={
				<Fragment>
					<Typography
						variant="body1"
						gutterBottom
					>
						{feedback_ids.length === 1
							? t`Send the feedback details from the selected feedback item to`
							: t`Send the feedback details from the selected feedback items to`}
					</Typography>
					<List>
						<ListItem>
							<ListItemAvatar>
								{webhookType.icon ? (
									<img
										height="32"
										src={webhookType.icon}
									/>
								) : (
									<img
										height="32"
										src="/assets/img/r/webhook-icon.svg"
									/>
								)}
							</ListItemAvatar>
							<ListItemText
								primary={hook.name}
								secondary={webhookType.label ?? ''}
							/>
						</ListItem>
					</List>
				</Fragment>
			}
			loading={loading}
			onSubmit={postForm}
		></SimpleDialog>
	) : null;
}
