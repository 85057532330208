import React from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';

import { AvailableSdks, SurveyEditorBuildList } from '@/components/Survey';

import { Grid, Typography, Slider } from '@/components/Layout';
import { useHasPermission, permissionSubjects } from '@/components/Permission';

const useStyles = makeStyles(theme => ({
	slider: {
		color: theme.palette.secondary.main,
	},
}));

export function ButtonTypeSdk({ surveySettings, updateSurveySettings = () => {} }) {
	const { t } = useTranslation();
	const classes = useStyles();

	const hasEditPermission = useHasPermission({
		subject: permissionSubjects.feedback_forms_build,
	});

	return (
		<SurveyEditorBuildList
			allowOverflow
			title={t`Slide-in settings`}
			options={[
				{
					name: 'max_form_height',
					disabled: !hasEditPermission,
					show: true,
					disableDivider: true,
					content: (
						<Grid
							container
							alignItems="center"
						>
							<Grid
								item
								xs
							>
								<Typography variant="body1">
									{t`Maximum height`} <AvailableSdks sdks={['ios', 'android']} />
								</Typography>
							</Grid>
							<Grid
								item
								xs={8}
							>
								<Slider
									disabled={!hasEditPermission}
									value={surveySettings?.slidePercentage}
									step={10}
									min={20}
									max={100}
									valueLabelDisplay="auto"
									valueLabelFormat={value => `${value}%`}
									onChange={(e, value) => updateSurveySettings('slidePercentage', value)}
									className={classes.slider}
								/>
							</Grid>
						</Grid>
					),
				},
			]}
		/>
	);
}
