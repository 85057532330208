import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';

import { Calendar } from '@material-ui/pickers';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import { format, isValid, isBefore, isAfter } from '../../utils/date';
import { reverseDate, makeDateObject } from '../../helpers';

import { debounce } from '../../utils';

const styles = theme => ({
	header: {
		backgroundColor: theme.palette.primary.main,
		padding: 16,
	},

	rootInput: {
		backgroundColor: 'rgba(255, 255, 255, 0.09)',
		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.13)',
		},
		'&$focused': {
			backgroundColor: 'rgba(255, 255, 255, 0.09)',
		},
		'&$disabled': {
			backgroundColor: 'rgba(255, 255, 255, 0.12)',
		},
	},
	underlineInput: {
		'&:before': {
			borderBottom: '1px solid rgba(255, 255, 255, 0.7)',
		},
	},
});

const orderDate = (date = '') => {
	// let d = date.split('/');
	// [d[0],d[1]] = [d[1],d[0]];
	// return d.join('/');
	return date.split('-').reverse().join('-');
};

/* eslint-disable no-unused-vars */
class DateRangeFilterPicker extends Component {
	static defaultProps = {
		onFromDateChange: () => {},
		onToDateChange: () => {},
	};

	constructor(props) {
		super(props);
		this.state = {
			fromDateInput: format(new Date(), props.format),
			toDateInput: format(new Date(), props.format),
		};
	}

	componentDidMount() {
		this.setState({
			fromDateInput: this.props.fromDate,
			toDateInput: this.props.toDate,
		});
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.fromDate !== this.props.fromDate ||
			prevProps.toDate !== this.props.toDate
		) {
			this.setState({
				fromDateInput: this.props.fromDate,
				toDateInput: this.props.toDate,
			});
		}
	}

	fromDateChange = date => {
		this.props.onFromDateChange(date);
	};

	toDateChange = date => {
		this.props.onToDateChange(date);
	};

	handleInputChange = (e, type) => {
		let { value } = e.currentTarget;
		this.setState(
			{
				[type]: value,
			},
			() => this.updateIfValidInput(type)
		);
	};

	updateIfValidInput = debounce(type => {
		const currentTempValue = this.state[type];
		const isCorrectlyTypedValue = currentTempValue
			.split(this.props.separator)
			.every((value, index) => {
				if (index === 0) {
					return (
						value.length > 0 &&
						value.length < 3 &&
						Number(value) !== 0 &&
						Number(value) < 32
					);
				} else if (index === 1) {
					return (
						value.length > 0 &&
						value.length < 3 &&
						Number(value) !== 0 &&
						Number(value) < 13
					);
				} else if (index === 2) {
					return value.length === 4;
				}
			});

		const dateValue = makeDateObject(currentTempValue, this.props.separator);
		//check for a valid date or a weird date
		if (
			isCorrectlyTypedValue &&
			isValid(dateValue) &&
			isAfter(dateValue, new Date(1998, 1, 1)) &&
			isBefore(dateValue, new Date())
		) {
			if (type === 'fromDateInput') {
				this.props.onFromDateChange(dateValue);
			} else {
				this.props.onToDateChange(dateValue);
			}
		}
	}, 425);

	render() {
		const { fromDateInput, toDateInput } = this.state;
		const { classes, fromDate, toDate, separator } = this.props;

		return (
			<Grid
				container
				direction="row"
				spacing={1}
			>
				<Grid item>
					<TextField
						fullWidth
						label="From date"
						variant="filled"
						inputProps={{ 'data-test-element': 'date-range-filter-from-date' }}
						value={fromDateInput}
						onChange={e => this.handleInputChange(e, 'fromDateInput')}
					/>

					<div style={{ overflow: 'hidden' }}>
						<Calendar
							date={makeDateObject(fromDate, separator)}
							onChange={this.fromDateChange}
							leftArrowIcon={<ChevronLeft />}
							rightArrowIcon={<ChevronRight />}
							allowKeyboardControl={false}
						/>
					</div>
				</Grid>

				<Grid item>
					<TextField
						fullWidth
						label="To date"
						variant="filled"
						inputProps={{ 'data-test-element': 'date-range-filter-to-date' }}
						value={toDateInput}
						onChange={e => this.handleInputChange(e, 'toDateInput')}
					/>

					<div style={{ overflow: 'hidden' }}>
						<Calendar
							date={makeDateObject(toDate, separator)}
							onChange={this.toDateChange}
							leftArrowIcon={<ChevronLeft />}
							rightArrowIcon={<ChevronRight />}
							allowKeyboardControl={false}
						/>
					</div>
				</Grid>
			</Grid>
		);
	}
}
export default DateRangeFilterPicker;
