import React from 'react';

import { useOfficeDrones } from '@/components/Ajax';
import { useAppContext } from '@/components/AppContext';

// get list of languages set for each feedback form
export default function useGetExclusionList(language, wait) {
	const {
		app: { domain, projects },
	} = useAppContext();

	return useOfficeDrones(
		`/textanalytics/exclusion`,
		{
			domain: domain,
			project_id: projects.current.id,
			language: language,
		},
		{
			wait: !language || wait,
			responseParser: response => {
				const arr = response?.value?.split(',') ?? [];
				return arr.filter(Boolean);
			},
		},
		'get'
	);
}
