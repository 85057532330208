import React from 'react';

import { useOfficeDrones } from '@/components/Ajax';
import { useAppContext } from '@/components/AppContext';

// get list of languages set for each feedback form
export default function useGetTextAnalyticsProjectLanguages(project_id = null) {
	const {
		app: { domain },
	} = useAppContext();
	return useOfficeDrones(
		`/textanalytics/${project_id}/languages`,
		{ domain: domain },
		{},
		'get'
	);
}
