import React from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';

import { displayOptions } from '@/components/Survey';

import { Grid, Typography, SelectionCard } from '@/components/Layout';
import { useHasPermission, permissionSubjects } from '@/components/Permission';

const useStyles = makeStyles(theme => ({
	displayCardOuter: {
		padding: theme.spacing(1),
		verticalAlign: 'top',
	},
}));

export function DisplayTypeWeb({ surveySettings, updateSurveySettings = () => {} }) {
	const { t } = useTranslation();
	const classes = useStyles();

	const hasEditPermission = useHasPermission({
		subject: permissionSubjects.feedback_forms_build,
	});

	return (
		<Grid
			container
			spacing={1}
			alignItems="flex-start"
		>
			<Grid
				item
				xs={12}
			>
				<Typography
					variant="subtitle2"
					component="div"
				>{t`data_collection-preview-display_type-title`}</Typography>
			</Grid>
			{displayOptions.map(option => {
				return (
					<Grid
						item
						xs={4}
						key={option.value}
					>
						<SelectionCard
							disabled={!hasEditPermission}
							selected={surveySettings?.formType === option.value}
							onClick={() => {
								updateSurveySettings(option.value);
							}}
							outerContent={
								<div className={classes.displayCardOuter}>
									<img src={option.img} />
									<Typography variant="subtitle2">{option.label}</Typography>
								</div>
							}
						/>
					</Grid>
				);
			})}
		</Grid>
	);
}
