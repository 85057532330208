import { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { CancelToken } from '@/lib/axios';
import { useAppContext } from '../AppContext';
import { BackendBauer } from '../../api';
import { reverseDate } from '../../helpers';
import { chartConstants } from '../Charts';
import { removeArrayDuplicatesFromShallowClone } from '../../utils';
import { useDataSourceContext } from '@/components/DataSources';

//remove duplicates from categories and limit number of categories if set
function parseCats(categories, limit) {
	const parsedLimit = parseInt(limit, 10);
	if (!isNaN(parsedLimit)) {
		const noDuups = removeArrayDuplicatesFromShallowClone(categories);
		return noDuups.slice(0, parsedLimit);
	}

	return removeArrayDuplicatesFromShallowClone(categories);
}

function mergeAndAddStackedDataPoints(dataPoints) {
	return dataPoints.reduce((newDataPoints, currentDataPoint) => {
		const existingCategoryIndex = newDataPoints.findIndex(
			point => point.name === currentDataPoint.name
		);
		if (existingCategoryIndex !== -1) {
			newDataPoints[existingCategoryIndex].y =
				newDataPoints[existingCategoryIndex].y + currentDataPoint.y;
		} else {
			newDataPoints.push({ ...currentDataPoint });
		}

		return newDataPoints;
	}, []);
}

//sort datapoints inside a serie
function sortSerieData(dataPoints, sort, stack) {
	const sorted = dataPoints.sort((a, b) => a.y - b.y);
	return sort === 'asc' ? sorted : sorted.reverse();
}

function sortSerieDataByDate(dataPoints) {
	const sorted = dataPoints.sort((a, b) => {
		if (a.raw_date && b.raw_date) {
			return new Date(a.raw_date) - new Date(b.raw_date);
		}
		//dont sort if datapoints dont contain 'raw_date' property
		return 0;
	});

	return sorted;
}

function sortCategories(cats) {
	const sorted = cats.sort((a, b) => Number(a) - Number(b));
	return sorted;
}

function useBackendBauer({
	series,
	customFromDate,
	customToDate,
	wait,
	chartType = 1,
	chart = {},
}) {
	const {
		app: { users, projects, organisations, date, tokens, domain },
		cancelPending,
	} = useAppContext();
	const { datasource } = useDataSourceContext();
	const { t } = useTranslation();

	const [error, setError] = useState(null);
	const [data, setData] = useState({ series: [], categories: [] });

	const getSerieX = (serie = {}) => {
		//single score chart type will always use 'tags' field as x, ( which is equal to 'all' in database)
		if (chartType === 2) {
			return (
				datasource.api
					.getFieldArray(serie.custom?.survey_id)
					.find(field => field.import_var?.toLowerCase() === 'tags')?.id ?? 0
			);
		}
		return serie.x === chartConstants.xIsSameAsYSerieValue ? serie.y : serie.x;
	};

	//the number of loading series, if 0 loading is complete
	const loading = data.series.filter(serie => serie.loading).length;
	const cancel = useRef({});

	const { customStackingOptions = '' } = chart?.plotOptions?.series ?? {};
	const { stacking = '' } = chart?.plotOptions?.series ?? {};

	useEffect(() => {
		//Check if the loadData function has been called initially
		//Then check if all series have been loaded
		if (
			series.length > 1 &&
			data.series.filter(serie => !serie.loading).length === series.length &&
			chartType != 2
		) {
			//Check if we need to sort the series data or limit the categories
			const sort = series[0]?.custom?.order;
			const limit = series[0]?.custom?.limit;
			const dateOnXAxis = series[0]?.custom?.date_group;

			setData(state => {
				//Lets sort the categories
				let categories = [];

				//Get all combined data points from series
				const allDataPoints = state.series.flatMap(serie => {
					return Array.isArray(serie.data) ? [...serie.data] : [];
				});

				const appliedDataPoints = stacking
					? mergeAndAddStackedDataPoints(allDataPoints)
					: allDataPoints;

				const isNumeric = appliedDataPoints.every(
					point => !isNaN(parseFloat(point.name))
				);

				if (sort) {
					//grab the category names from point.name and apply sort
					categories = parseCats(
						sortSerieData(appliedDataPoints, sort).map(point => point.name),
						limit
					);
				} else if (dateOnXAxis) {
					categories = parseCats(
						sortSerieDataByDate(appliedDataPoints).map(point => point.name),
						limit
					);
				} else if (isNumeric) {
					categories = sortCategories(
						parseCats(appliedDataPoints.map(point => point.name))
					);
				} else {
					categories = parseCats(
						appliedDataPoints.map(point => point.name),
						limit
					);
				}

				const parsedSeries = state.series.map(serie => {
					//The data inside this serie is less than the total of categories, this might result in wrongly placed serie data
					//Therefore we assign 0 values to non existing categories inside a serie
					//We must create a data array based on the category var we just created to properly keep sort and limit as set
					const data = categories.map(cat => {
						const categoryFoundInData =
							serie.data.find(serieData => serieData.name === cat) ?? null;

						return categoryFoundInData
							? {
									...categoryFoundInData,
									//sometimes backendbauer returns y:null instead of y:0 -> fix that
									y: categoryFoundInData.y ? categoryFoundInData.y : 0,
							  }
							: {
									name: cat,
									y: 0,
									n: 0,
									count: 0,
							  };
					});

					return {
						...serie,
						data,
					};
				});

				return {
					categories,
					series: parsedSeries,
					n: state.n,
				};
			});
		}
	}, [
		data.series.filter(serie => !serie.loading).length,
		data.series.length,
		chartType,
		series,
		stacking,
	]);

	//Function to cancel all currently pending requests
	function cancelCurrent() {
		Object.values(cancel.current).forEach(cancelReq => {
			try {
				cancelReq();
			} catch (e) {}
		});
	}

	//Load the data from backendbauer
	async function loadData(forceRefresh) {
		cancelCurrent();
		//Initialize series based on the initial series object
		setData({
			series: series.map(({ name }) => ({
				name: `${name} (${t`loading`})`,
				loading: true,
				color: '#eee',
			})),
			categories: [],
		});

		//Loop through the series and request data from backendbauer
		//We use for ... of here because forEach has issues with async
		for (const [serieIndex, serie] of series.entries()) {
			const response = await BackendBauer.get(
				{
					...serie,
					x: getSerieX(serie),
					custom: {
						...serie.custom,
						stack:
							customStackingOptions === 'stack_all' ? '' : serie.custom?.survey_id ?? '',
					},
				},
				{
					domain: domain,
					fromDate: customFromDate ?? reverseDate(date.fromDate, date.separator, '-'),
					toDate: customToDate ?? reverseDate(date.toDate, date.separator, '-'),
					cacheRefresh: forceRefresh,
					serieIndex: serieIndex,
					chart_type: chartType,
				},
				{
					cancelToken: new CancelToken(token => {
						cancel.current[serieIndex] = token;
					}),
				}
			);

			if (response.series && response.categories) {
				setData(state => {
					return {
						n: response?.response?.n ?? null,
						//Remove possible duplicates from xAxis categories, these can occur with multiple series
						categories: removeArrayDuplicatesFromShallowClone(
							state.categories,
							response.categories
						),
						series: state.series.map((serie, i) => {
							//reassign the index from the response from the server
							if (i === serieIndex) {
								return response.series;
							}

							//return the already existing serie in state
							return serie;
						}),
					};
				});
			}
		}
	}

	useEffect(() => {
		if (cancelPending) {
			cancelCurrent();
		}
	}, [cancelPending]);

	useEffect(() => {
		if (!wait) {
			loadData();
		}
		return () => {
			cancelCurrent();
		};
	}, [
		JSON.stringify(series),
		users.current.id,
		projects.current.id,
		organisations.current.org_id,
		date.fromDate,
		date.toDate,
		customFromDate,
		customToDate,
		wait,
	]);

	return [data, loading, error, loadData];
}

export default useBackendBauer;
