import React, { createContext, useContext, useEffect } from 'react';

import { MopinionLoader } from '@/components/Layout';
import { useTrackingEvents } from '@/components/Tracking';
import { setGlobalSessionData } from '@/utils';
import useGetCurrentPermissions from './useGetCurrentPermissions';

export const PermissionContext = createContext({});

export function PermissionContextProvider({ children, ...props }) {
	const [{ permissions = {} }, loading, error, updateCurrentPermissions] =
		useGetCurrentPermissions();
	useEffect(() => {
		setGlobalSessionData({
			owner: permissions.owner,
			admin: permissions.admin,
		});
	}, [permissions]);
	const { setPeople } = useTrackingEvents();
	useEffect(() => {
		setPeople({
			owner: permissions.owner,
			admin: permissions.admin,
		});
	}, [permissions, setPeople]);
	return (
		<PermissionContext.Provider
			value={{
				...permissions,
				permissions: permissions.rights,
				projects: permissions.projects,
				updateCurrentPermissions,
			}}
		>
			{loading && Object.keys(permissions).length === 0 ? <MopinionLoader /> : children}
		</PermissionContext.Provider>
	);
}

export function usePermissionContext() {
	return useContext(PermissionContext);
}
