import React from 'react';

import ChartEditor from '@/components/Charts/ChartEditor';
import { useParams } from 'react-router-dom';

function ChartEditorPage({ dataExplorer }) {
	const { chartId } = useParams();

	if (!chartId && !dataExplorer) return null;
	return (
		<ChartEditor
			dataExplorer={dataExplorer}
			chartId={chartId}
			isPage
		/>
	);
}

export default ChartEditorPage;
