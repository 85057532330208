import React, { useState } from 'react';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';
import { useAjaxForm } from '@/components/Ajax';
import { ValidatedField } from '@/components/Validation';
import { SimpleDialog } from '@/components/Layout';

const maxTitleLength = 120;

export default function DashboardAdditDialog({
	dashboard = {},
	open,
	onClose = () => {},
	loadDashboards = () => {},
	setNewDashboard = () => {},
}) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { app } = useAppContext();

	const [state, setState] = useState({
		name: dashboard.name,
	});

	const { postForm, loading, clicked } = useAjaxForm({
		url: dashboard.id
			? '/dashboard/ajax/edit-dashboard'
			: '/dashboard/ajax/add-dashboard',
		data: {
			id: dashboard.id ?? 0,
			name: state.name,
		},
		valid: () => state.name.length > 0 && state.name.length <= maxTitleLength,
		onSuccess: response => {
			enqueueSnackbar(
				dashboard.id
					? t`reporting-dashboard_overview-add_dashboard_dialog-snackbar_editted_dashboard`
					: t`reporting-dashboard_overview-add_dashboard_dialog-snackbar_new_dashboard`
			);
			if (!dashboard.id && response.id) {
				setNewDashboard(response.id);
			}
			loadDashboards();
			onClose();
		},
	});

	return (
		<SimpleDialog
			title={
				dashboard.id
					? t`reporting-dashboard_overview-add_dashboard_dialog-edit_title`
					: t`reporting-dashboard_overview-add_dashboard_dialog-add_title`
			}
			open={open}
			loading={loading}
			submit={
				dashboard.id
					? t`reporting-dashboard_overview-add_dashboard_dialog-button_edit`
					: t`reporting-dashboard_overview-add_dashboard_dialog-button_add`
			}
			onSubmit={() => {
				postForm();
			}}
			onClose={() => {
				onClose();
			}}
			dataTrackEvent={
				dashboard.id
					? 'dashboard_overview_edit_dashboard'
					: 'dashboard_overview_add_dashboard'
			}
			dataTestElement="create-dashboard-submit"
		>
			<ValidatedField
				fullWidth
				label={
					dashboard.id
						? t`reporting-dashboard_overview-add_dashboard_dialog-edit_inputlabel`
						: t`reporting-dashboard_overview-add_dashboard_dialog-add_inputlabel`
				}
				value={
					state.name
						? state.name
						: dashboard.id && state.name !== ''
						? `${app.projects.current.name} dashboard`
						: ''
				}
				validateValue={state.name}
				rules={[
					['required', true],
					['max', maxTitleLength],
				]}
				showErrors={clicked}
				errorMessage={t`reporting-dashboard_overview-add_dashboard_dialog-input_name_invalid`}
				messages={{
					required: t`reporting-dashboard_overview-add_dashboard_dialog-input_name_invalid`,
					max: t(
						'reporting-dashboard_overview-add_dashboard_dialog-input_name_max_length',
						{ maxlength: maxTitleLength }
					),
				}}
				onChange={e => {
					const { value } = e.currentTarget;
					setState(prev => ({
						...prev,
						name: value,
					}));
				}}
				data-test-element="create-dashboard-name"
			/>
		</SimpleDialog>
	);
}
