import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { Grid, Typography, ActionButton } from '../Layout';

const useStyles = makeStyles(theme => ({
	fontWeight: {
		fontWeight: theme.typography.fontWeightRegular,
	},
}));

export default function DeploymentIntstructions({ title, helpItems, ...props }) {
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<div>
			<Typography
				variant="subtitle"
				gutterBottom
			>
				{title ?? t`deployment_instructions-help_title`}
			</Typography>
			{helpItems.map(item => (
				<ActionButton
					className={classes.fontWeight}
					key={item.href}
					dataTrackEvent={item.trackEvent ? item.trackEvent : 'knowledgebase_event'}
					action="knowledge_base"
					disableTextTransform
					ButtonProps={{
						component: 'a',
					}}
					target="_blank"
					href={item.href}
				>
					{item.label}
				</ActionButton>
			))}
		</div>
	);
}
