import React from 'react';

import { useAjaxForm } from '@/components/Ajax';

export default function useSaveOptInOut({ smartRecapsProjectActive, onSuccess }) {
	const action = useAjaxForm({
		url: '/dashboard/ajax/smart-recaps',
		data: {
			method: 'post',
			path: '/trends/opt-out',
			optOut: smartRecapsProjectActive,
		},
		onSuccess,
	});
	return action;
}
