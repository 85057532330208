import React, { useRef, useEffect, useState } from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';

import { ValidatedField } from '@/components/Validation';
import ActionIconButton from './ActionIconButton';
import InputAdornment from './InputAdornment';
import MenuItem from './MenuItem';

const useStyles = makeStyles(theme => ({
	inherit: {
		fontSize: 'inherit',
		fontStyle: 'inherit',
		fontWeight: 'inherit',
		color: 'inherit',
		lineHeight: 'inherit',
	},
	wrap: {
		display: props => props.display,
		width: props => (props.fullWidth ? '100%' : 'auto'),
		transition: theme.transitions.create('backgroundColor'),
		borderTopLeftRadius: theme.shape.borderRadius,
		borderTopRightRadius: theme.shape.borderRadius,
		'&:hover': {
			backgroundColor: props =>
				!props.disableHighlight && props.editable ? 'rgba(0,0,0,.09)' : 'transparent',
		},
	},
	selectPadding: {
		'& div[role="button"]': {
			paddingLeft: theme.spacing(1),
		},
	},
	edit: {
		backgroundColor: props =>
			!props.disableHighlight && props.editable ? 'rgba(0,0,0,.09)' : 'transparent',
	},
	editIconButton: {
		verticalAlign: 'middle',
		marginLeft: theme.spacing(0.5),
	},
	select: {
		'& div:focus': {
			backgroundColor: 'inherit',
		},
		'& svg': {
			right: 60,
		},
	},
}));

export default function EditableContent({
	children,
	editable = true,
	disableHighlight,
	options = [],
	IconButtonTooltip,
	onKeyDown,
	disabled,
	onChange = () => {},
	onEdit = () => {},
	display = 'block',
	showIconAlways,
	cancelOnBlur,
	dataTestElement = null,
	dataTrackEvent,
	editTooltip,
	onClose = () => {},
	...props
}) {
	const { t } = useTranslation();

	const inputRef = useRef(null);
	const wasEditted = useRef(false);
	const validRef = useRef(true);

	const [edit, setEdit] = useState(false);
	const [hover, setHover] = useState(false);
	const [initial, setInitial] = useState('');

	const classes = useStyles({ disableHighlight, display, editable, ...props });
	const tooltip = IconButtonTooltip ? IconButtonTooltip : t`Update value`;

	useEffect(() => {
		if (edit && !wasEditted.current) {
			setInitial(props.value);
			if (inputRef?.current) {
				setTimeout(() => inputRef?.current?.focus());
			}

			wasEditted.current = true;
			onEdit();
		}

		if (!edit && wasEditted.current) {
			onClose(validRef.current);
			wasEditted.current = false;
		}
	}, [edit, wasEditted.current, validRef.current]);

	return (
		<ClickAwayListener
			mouseEvent="onMouseDown"
			touchEvent="onTouchStart"
			onClickAway={() => {
				if (edit) {
					if (cancelOnBlur) {
						onChange(initial);
					}
					setEdit(false);
				}
			}}
		>
			<div
				className={`${classes.wrap} ${edit ? classes.edit : ''}`}
				onClick={() => {
					if (!edit && editable) setEdit(true);
				}}
				onMouseOver={e => setHover(true)}
				onMouseOut={e => setHover(false)}
			>
				{edit && !disabled ? (
					<ValidatedField
						onKeyDown={e => {
							if (typeof onKeyDown === 'function') onKeyDown(e);
							if (e.key === 'Enter') {
								setEdit(false);
							}
						}}
						select={options.length > 0}
						inputRef={inputRef}
						onValidChange={valid => (validRef.current = valid)}
						onChange={(e, valid) => {
							validRef.current = valid;
							onChange(
								options.length > 0 ? e.target.value : e.currentTarget.value,
								e,
								valid
							);
						}}
						className={`${options.length || props.select ? classes.select : ''} ${
							classes.selectPadding
						}`}
						fullWidth
						InputProps={{
							className: classes.inherit,
							endAdornment: (
								<InputAdornment position="end">
									<ActionIconButton
										onClick={() => {
											setEdit(false);
										}}
										action="check"
										tooltip={tooltip}
										size="small"
										data-test-element={
											dataTestElement ? dataTestElement + 'SaveButton' : null
										}
										dataTrackEvent={`${dataTrackEvent}-confirm`}
									/>
									<ActionIconButton
										onClick={() => {
											setEdit(false);
											onChange(initial);
										}}
										action="close"
										tooltip={t`Cancel changes`}
										size="small"
										data-test-element={
											dataTestElement ? dataTestElement + 'CancelButton' : null
										}
									/>
								</InputAdornment>
							),
						}}
						{...props}
					>
						{options.length > 0
							? options.map((option, key) => {
									return (
										<MenuItem
											key={key}
											value={option.value}
										>
											{option.label}
										</MenuItem>
									);
							  })
							: null}
					</ValidatedField>
				) : (
					<>
						{children}
						<Grow in={editable && (hover || showIconAlways)}>
							<ActionIconButton
								onClick={() => {
									setEdit(true);
								}}
								action="edit"
								disabled={disabled}
								tooltip={editTooltip ? editTooltip : t`Edit`}
								size="small"
								className={classes.editIconButton}
								IconProps={{
									fontSize: 'small',
								}}
								dataTrackEvent={`${dataTrackEvent}-edit`}
								data-test-element={
									dataTestElement ? dataTestElement + 'EditButton' : null
								}
							/>
						</Grow>
					</>
				)}
			</div>
		</ClickAwayListener>
	);
}
