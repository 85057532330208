import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';
import { FilterGroup, useListFilterContext } from '@/components/FilteredLists';
import { SearchField } from '@/components/Layout';
import { UserAutocomplete } from '@/components/Users';
import { useTrackingEvents } from '@/components/Tracking';
import PermissionGroupRightsFilter from './PermissionGroupRightsFilter';

export default function PermissionGroupListFilters() {
	const { t } = useTranslation();
	const { app } = useAppContext();
	const { filters, setSingleFilter, setMultiFilter } = useListFilterContext();
	const { track } = useTrackingEvents();

	const setRightsFilter = useCallback(
		({ permission, subject }) => {
			track('access_groups_list_filter_section_permission', {
				permission,
				subject,
			});
			setSingleFilter('permission', permission);
			setSingleFilter('subject', subject);
		},
		[setSingleFilter]
	);

	return (
		<>
			<div>
				<SearchField
					value={filters.search ?? ''}
					onChange={event => setMultiFilter('search', event.target.value)}
					onBlur={() =>
						track('access_groups_list_filter_search', {
							search: filters.search,
						})
					}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('settings-access_groups-list-filters-reports')}
					filterKey="projects"
					filters={app.projects.asArray.map(project => ({
						label: project.name,
						value: project.uuid,
						selected: filters.projects?.includes(project.uuid),
					}))}
					multiple
					onChange={({ key, value }) => {
						setMultiFilter(key, value);
						track('access_groups_list_filter_projects', {
							report: value,
						});
					}}
				/>
			</div>

			<div>
				<FilterGroup filterName={t('settings-access_groups-list-filters-rights')}>
					<PermissionGroupRightsFilter
						value={filters.rights}
						onChange={setRightsFilter}
					/>
				</FilterGroup>
			</div>

			<div>
				<FilterGroup
					filterName={t('settings-access_groups-list-filters-users')}
					multiple
				>
					<UserAutocomplete
						multiple
						value={filters.members ?? []}
						onChange={(e, value) => {
							setMultiFilter(
								'members',
								value.map(user => user.uuid)
							);
							track('access_groups_list_filter_users', {
								report: value,
							});
						}}
					/>
				</FilterGroup>
			</div>
		</>
	);
}
