import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import {
	ListWithSidebar,
	ListFilterProvider,
	useFilteredList,
} from '@/components/FilteredLists';

import { makeStyles } from '@/styles';
import { useAppContext } from '@/components/AppContext';
import { EmptyStateHandleHasSurveys } from '@/components/App';
import {
	Loader,
	Grid,
	constants,
	ActionIconButton,
	Box,
	Alert,
} from '@/components/Layout';
import TextAnalyticsWordCloudCard from './TextAnalyticsWordCloudCard';
import TextAnalyticsSentimentDistributionCard from './TextAnalyticsSentimentDistributionCard';
import TextAnalyticsTopListCard from './TextAnalyticsTopListCard';
import TextAnalyticsFilters from './TextAnalyticsFilters';
import { useTextAnalyticsContext } from './TextAnalyticsContext';
import MultipleLanguageAlert from './MultipleLanguageAlert';

const useStyles = makeStyles(theme => ({
	gridContainer: {
		'& > div:not(:last-child)': {
			marginBottom: theme.spacing(2),
		},
	},
	emptyState: {
		whiteSpace: 'pre-line',
	},
}));

export default function TextAnalyticsDashboard({ languagesInProject }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const {
		app,
		app: { layout },
	} = useAppContext();

	const { textAnalytics } = useTextAnalyticsContext();

	const filterState = useFilteredList({
		filters: {
			word_group: '0',
		},
		other: {},
	});

	const [filtersOpen, setFiltersOpen] = useState(
		window.innerWidth > constants.filterDrawerBreakpoint
	);

	useEffect(() => {
		app.dispatch({ type: 'set_title', payload: t('Text Analytics') });
		app.dispatch({ type: 'set_hide_filters', payload: ['date'] });
		return () => app.dispatch({ type: 'set_hide_filters', payload: [] });
	}, []);

	return (
		<>
			<ListFilterProvider
				{...filterState}
				singleFilters={['word_group']}
				filterLabels={{}}
			>
				<ListWithSidebar
					drawerContent={<TextAnalyticsFilters />}
					prominent={!layout.prominentHidden}
					onClose={() => {
						setFiltersOpen(!filtersOpen);
					}}
					//stayVisibleCollapsed
					open={filtersOpen}
				>
					<Box
						display="flex"
						justifyContent="flex-end"
					>
						<ActionIconButton
							action="filters"
							onClick={() => setFiltersOpen(prev => !prev)}
						/>
					</Box>
					{textAnalytics.loading && <Loader empty />}
					{!textAnalytics.loading && textAnalytics.status === 'IN_PROGRESS' && (
						<Alert
							variant="filled"
							severity="info"
							title={t`reporting-text_analytics-dashboard-in_progress_alert-title`}
							close
							css={{ mb: 1 }}
						>
							{t`reporting-text_analytics-dashboard-in_progress_alert-content`}
						</Alert>
					)}
					{!textAnalytics.loading &&
						languagesInProject > 1 &&
						textAnalytics.project_id &&
						isNaN(filterState.other?.survey_id) && <MultipleLanguageAlert />}
					{!textAnalytics.loading && textAnalytics.project_id && (
						<>
							<div className={classes.gridContainer}>
								<Grid
									container
									spacing={3}
								>
									<Grid
										item
										lg={6}
										md={6}
										sm={12}
										xs={12}
									>
										<TextAnalyticsWordCloudCard />
									</Grid>
									<Grid
										item
										lg={6}
										md={6}
										sm={12}
										xs={12}
									>
										<TextAnalyticsSentimentDistributionCard />
									</Grid>
								</Grid>

								<Grid
									container
									spacing={3}
								>
									<Grid
										item
										lg={6}
										md={6}
										sm={12}
										xs={12}
									>
										<TextAnalyticsTopListCard
											type="pos"
											title={t`reporting-text_analytics-card-toplist-positive`}
										/>
									</Grid>
									<Grid
										item
										lg={6}
										md={6}
										sm={12}
										xs={12}
									>
										<TextAnalyticsTopListCard
											type="neg"
											title={t`reporting-text_analytics-card-toplist-negative`}
										/>
									</Grid>
								</Grid>

								<Grid
									container
									spacing={3}
								>
									<Grid
										item
										xs={12}
									>
										<TextAnalyticsTopListCard
											type="count"
											title={t`reporting-text_analytics-card-toplist-most_words`}
										/>
									</Grid>
								</Grid>
							</div>
						</>
					)}
					{!textAnalytics.loading && !textAnalytics.project_id && (
						<EmptyStateHandleHasSurveys
							className={classes.emptyState}
							image={<img src={`/assets/img/r/emptystate/text_analytics.gif`} />}
							primary={t`reporting-text_analytics-emptystate_default-title`}
							primaryNoSurvey={t`reporting-text_analytics-emptystate_no_survey-title`}
							secondary={t`reporting-text_analytics-emptystate_default-text`}
							secondaryNoSurvey={t`reporting-text_analytics-emptystate_no_survey-text`}
						/>
					)}
				</ListWithSidebar>
			</ListFilterProvider>
		</>
	);
}
