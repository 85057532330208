import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import AutoComplete from './AutoComplete';
import { useTranslation } from 'react-i18next';
import countries from '../../constants/countries.json';

const useStyles = makeStyles(theme => ({
	fillHeight: {
		height: '100%',
	},
}));

export default function CountryAutoComplete({ onChange = () => {}, value, ...props }) {
	const classes = useStyles();
	const { t } = useTranslation();
	let selectedItem = {};
	const countryArray = Object.keys(countries).map(countryCode => {
		const country = countries[countryCode];
		const label =
			country.name === country.native
				? country.name
				: `${country.name} (${country.native})`;

		const countryObject = {
			avatar: (
				<Avatar>
					<img
						className={classes.fillHeight}
						src={`/assets/img/countryflags/${countryCode.toLowerCase()}.svg`}
					/>
				</Avatar>
			),
			countryCode: countryCode.toLowerCase(),
			phone: country.phone,
			label,
		};

		if (value === label) {
			Object.assign(selectedItem, countryObject);
		}

		return countryObject;
	});

	if (Object.keys(selectedItem).length === 0) {
		Object.assign(selectedItem, { label: value });
	}

	const AutoCompleteProps = {
		...props,
		options: countryArray,
		SuggestionProps: {
			isCountrySearch: true,
		},
		onChange,
		...(Object.keys(selectedItem).length > 0 && { selectedItem: [selectedItem] }),
	};

	return <AutoComplete {...AutoCompleteProps} />;
}
