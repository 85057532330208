import React from 'react';
import { renderToString } from 'react-dom/server';

import { useTranslation } from 'react-i18next';

import { ThemeProvider, makeStyles } from '@/styles';
import { Typography } from '@/components/Layout';
import { RenderChart } from '@/components/Charts';

const useStyles = makeStyles({
	wrapperDiv: {
		height: '100%',
		width: '100%',
		'& > div': {
			height: '100%',
			width: '100%',
		},
	},
});

export default function HighChartsWordCloud({ words, onClick = () => {}, ...props }) {
	const { t } = useTranslation();
	const classes = useStyles();
	const options = {
		plotOptions: {
			series: {
				cursor: 'pointer',
				point: {
					events: {
						click: function (event) {
							onClick(this, event);
						},
					},
				},
			},
		},
		tooltip: {
			formatter: function () {
				return renderToString(
					<ThemeProvider>
						<div>
							<Typography
								display="block"
								variant="subtitle2"
							>
								{this.point.name}
							</Typography>
							<Typography
								display="block"
								variant="caption"
							>
								{t`Sentiment: ` + Math.round(this.point.pointData.MSI)}
							</Typography>
							<Typography
								display="block"
								variant="caption"
							>
								{t`Mentions: ` + this.point.weight}
							</Typography>
						</div>
					</ThemeProvider>
				);
			},
		},
		series: [
			{
				rotation: {
					from: 0,
					to: 0,
					orientations: 2,
				},
				type: 'wordcloud',
				data: words,
			},
		],
		title: {
			style: { display: 'none' },
		},
		subtitle: {
			style: { display: 'none' },
		},
	};

	return (
		<div className={classes.wrapperDiv}>
			<RenderChart options={options} />
		</div>
	);
}
