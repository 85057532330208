import React, { useState, useEffect, useMemo } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Popper from '@material-ui/core/Popper';

import AssignmentIcon from '@material-ui/icons/Assignment';
import AssessmentIcon from '@material-ui/icons/Assessment';

import { reverseDate } from '@/helpers';
import { makeStyles } from '@/styles';
import { useTranslation, Trans } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';
import { EmptyStateHandleHasSurveys } from '@/components/App';
import { useDataSourceContext } from '@/components/DataSources';
import { useHasPermission, NoPermissionTooltip } from '@/components/Permission';
import OptInOutDialog from './OptInOutDialog';

import {
	Alert,
	ContactDialog,
	FormattedDate,
	Typography,
	Loader,
	EmptyState,
	ActionButton,
	Paper,
	Box,
	CollapsibleBox,
	RenderConditional,
} from '@/components/Layout';
import MsiDistBar from './MsiDistBar';
import SmartRecapsQuickViewDrawer from './SmartRecapsQuickViewDrawer';
import useGetTrends from './useGetTrends';

const useStyles = makeStyles(theme => ({
	headerMargin: {
		marginTop: theme.spacing(1),
	},
	msiWidth: {
		width: props => `${props.maxWidth}%`,
		maxWidth: '100%',
	},
	summaryMargin: {
		marginBottom: theme.spacing(1),
	},
	listPadding: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	divider: {
		borderBottom: theme.palette.divider,
	},
	emptystate: {
		alignSelf: 'center',
	},
	row: {
		display: 'flex-row',
	},
	popoverPaper: {
		borderRadius: theme.shape.borderRadius,
		'&:before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			height: 14,
			width: 14,
			background: '#ffffff',
			transform: 'rotate(45deg)',
			top: -1,
			right: 'calc(50% - 5px)',
			boxShadow: theme.shadows[1],
		},
	},
	popoverBox: {
		borderRadius: theme.shape.borderRadius,
		position: 'relative',
		background: '#ffffff',
		padding: theme.spacing(2),
		pointerEvents: 'initial',
	},
	ellipsis: {
		overflow: 'hidden',
		display: '-webkit-box',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical',
	},
}));

function calculateTopicDimensions(topics = []) {
	const sortedTopics = [...topics].sort((a, b) => {
		return a.items.length > b.items.length ? -1 : b.items.length < a.items.length ? 1 : 0;
	});

	//const minPercentage = 30;
	const maxItems = sortedTopics?.[0]?.items?.length ?? 0;

	return sortedTopics.reduce((acc, topic, index) => {
		const topicWidth = Math.floor(topic.items.length / (maxItems / 100));
		//const msiTotalWidth = topicWidth >= minPercentage ? topicWidth : minPercentage;

		acc[index] = { ...topic, msiTotalWidth: topicWidth };

		return acc;
	}, []);
}

export default function RecapTopics({
	smartRecapsProjectActive,
	selectedSurvey,
	optedInData,
	optedInLoading,
	loadOptInData = () => {},
}) {
	const { t } = useTranslation();
	const classes = useStyles();
	const { datasource } = useDataSourceContext();

	const {
		app: { date },
	} = useAppContext();

	const [data, loading, error, loadData] = useGetTrends({
		path: '/trends',
		wait:
			!selectedSurvey?.id ||
			Number(selectedSurvey?.id) === 0 ||
			!smartRecapsProjectActive,
		surveyId: selectedSurvey?.id,
	});

	const [anchorEl, setAnchorEl] = useState(null);
	const [focused, setFocused] = useState(false);
	const [inboxDrawerOpen, setInboxDrawerOpen] = useState(false);
	const [contactDialogOpen, setContactDialogOpen] = useState(false);
	const [optOutDialogOpen, setOptOutDialogOpen] = useState(false);
	const hasEditPermission = useHasPermission();

	//use for quick view inbox
	const [selectedFeedbackIds, setSelectedFeedbackIds] = useState({
		sentiment: '',
		ids: [],
		itemsWithMsi: [],
	});

	/* calculate a dynamic width per topic, based on max & min items in collection */
	const topics = useMemo(() => {
		return calculateTopicDimensions(data.response?.results ?? []);
	}, [data.response]);

	const dateNow = reverseDate(date.fromDate, date.separator, '-');

	const showDateAlert = optedInData?.smart_recaps_start_date
		? new Date(optedInData.smart_recaps_start_date).getTime() >
		  new Date(dateNow).getTime()
		: false;

	useEffect(() => {
		if (!focused) {
			const timer = setTimeout(() => {
				setAnchorEl(null);
			}, 1000);

			return () => {
				clearTimeout(timer);
			};
		}
	}, [anchorEl, focused]);

	if (optedInLoading || loading || datasource.stale.surveys?.loading) {
		return (
			<Box width="100%">
				<Loader
					circular
					empty
				/>
			</Box>
		);
	}

	if (datasource.forms.asArray.length === 0) {
		return (
			<EmptyStateHandleHasSurveys
				className={classes.emptyState}
				icon={AssignmentIcon}
				primary={t`reporting-feedback_inbox-smart_recaps-emptystate-no_project-title`}
				secondary={t`reporting-feedback_inbox-smart_recaps-emptystate-no_project-content`}
				primaryNoSurvey={t`reporting-feedback_inbox-smart_recaps-emptystate-no_surveys-title`}
				secondaryNoSurvey={t`reporting-feedback_inbox-smart_recaps-emptystate-no_surveys-content`}
				action={
					datasource.forms.asArray.length === 0 ? (
						<ActionButton
							variant="contained"
							dataTrackEvent="customer_emptystate_create_form"
							color="primary"
							link
							to="/data-collection/survey/edit/new"
						>
							{t`reporting-customers-emptystate_no_survey-button`}
						</ActionButton>
					) : null
				}
			/>
		);
	}

	if (optedInData?.response?.code === 501) {
		return (
			<>
				<EmptyState
					className={classes.emptystate}
					primary={t`reporting-feedback_inbox-smart_recaps-emptystate-error-title`}
					secondary={t`reporting-feedback_inbox-smart_recaps-emptystate-error-content`}
					icon={AssessmentIcon}
					action={
						<ActionButton
							variant="contained"
							color="primary"
							dataTrackEvent="smartrecaps_button_project_error"
							onClick={() => setContactDialogOpen(true)}
						>
							{t`reporting-feedback_inbox-smart_recaps-emptystate-error-action`}
						</ActionButton>
					}
				/>
				<ContactDialog
					open={contactDialogOpen}
					onClose={() => setContactDialogOpen(false)}
					mailTo="support@mopinion.com"
					subject={t`reporting-feedback_inbox-smart_recaps-emptystate-error-contact_subject`}
				/>
			</>
		);
	}

	if (!smartRecapsProjectActive) {
		return (
			<>
				<EmptyState
					className={classes.emptystate}
					primary={t`reporting-feedback_inbox-smart_recaps-emptystate-turned_off-title`}
					secondary={t`reporting-feedback_inbox-smart_recaps-emptystate-turned_off-content`}
					icon={AssessmentIcon}
					action={
						<NoPermissionTooltip>
							<ActionButton
								variant="contained"
								color="primary"
								dataTrackEvent="smartrecap_explainer_button_smartrecaps_opt_in"
								onClick={() => setOptOutDialogOpen(true)}
								disabled={!hasEditPermission}
							>
								{t`reporting-feedback_inbox-smart_recaps-emptystate-turned_off-button_turn_on`}
							</ActionButton>
						</NoPermissionTooltip>
					}
				/>
				<OptInOutDialog
					open={optOutDialogOpen}
					onClose={() => setOptOutDialogOpen(false)}
					smartRecapsProjectActive={smartRecapsProjectActive}
					loadOptInData={loadOptInData}
				/>
			</>
		);
	}

	//no open text fields in form
	if (data.response?.code === 602) {
		return (
			<EmptyState
				className={classes.emptystate}
				primary={t(
					'reporting-feedback_inbox-smart_recaps-emptystate-no_open_fields-title',
					{ name: selectedSurvey.name }
				)}
				secondary={t`reporting-feedback_inbox-smart_recaps-emptystate-no_open_fields-content`}
				icon={AssessmentIcon}
			/>
		);
	}

	//not enough items in form yet (min: 25)
	if (data.response?.code === 603) {
		return (
			<EmptyState
				className={classes.emptystate}
				primary={t(
					'reporting-feedback_inbox-smart_recaps-emptystate-no_feedback-title-title',
					{ name: selectedSurvey.name }
				)}
				secondary={t`reporting-feedback_inbox-smart_recaps-emptystate-no_feedback-content`}
				icon={AssessmentIcon}
			/>
		);
	}

	//recaps not executed yet for this form
	if (data.response?.code === 604 || data.response?.code === 501 || !data.response) {
		return (
			<EmptyState
				className={classes.emptystate}
				primary={t('reporting-feedback_inbox-smart_recaps-emptystate-no_recap-title', {
					name: selectedSurvey.name,
				})}
				secondary={t`reporting-feedback_inbox-smart_recaps-emptystate-no_recap-content`}
				icon={AssessmentIcon}
			/>
		);
	}

	//no issues found for this form for this date
	if (data.response?.code === 605) {
		return (
			<EmptyState
				className={classes.emptystate}
				primary={t('reporting-feedback_inbox-smart_recaps-emptystate-no_issues-title', {
					name: selectedSurvey.name,
				})}
				secondary={t`reporting-feedback_inbox-smart_recaps-emptystate-no_issues-content`}
				icon={AssessmentIcon}
			/>
		);
	}

	return (
		<div className={classes.row}>
			{smartRecapsProjectActive && showDateAlert && (
				<DateAlert startDate={optedInData.smart_recaps_start_date} />
			)}
			<Typography
				variant="h6"
				fontWeight="medium"
			>
				{t`reporting-feedback_inbox-smart_recaps-topics-title`}
			</Typography>

			{topics.map((topic, index) => {
				return (
					<div
						key={topic.topic}
						className={`${classes.listPadding} ${
							index !== topics.length - 1 ? classes.divider : ''
						}`}
					>
						<TopicRow
							topic={topic}
							index={index}
							setAnchorEl={setAnchorEl}
							setFocused={setFocused}
							setSelectedFeedbackIds={setSelectedFeedbackIds}
						/>
					</div>
				);
			})}

			<Popper
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
			>
				<Paper
					className={classes.popoverPaper}
					disablePortal
					onMouseOver={() => {
						setFocused(true);
					}}
					onMouseLeave={() => {
						setFocused(false);
						setAnchorEl(null);
					}}
					elevation={3}
				>
					<Box className={classes.popoverBox}>
						<Typography variant="subtitle2">
							{t(
								`reporting-feedback_inbox-smart_recaps-topics-tooltip_title-${selectedFeedbackIds.sentiment}`
							)}
						</Typography>

						<Typography
							gutterBottom
							variant="body2"
						>
							{t('reporting-feedback_inbox-smart_recaps-topics-tooltip_content', {
								count: selectedFeedbackIds?.ids.length ?? 0,
							})}
						</Typography>

						<ActionButton
							variant="contained"
							color="primary"
							onClick={() => setInboxDrawerOpen(true)}
							mt={1}
							dataTrackEvent="smartrecaps_topic_tooltip_button_show_feedback"
						>
							{t`reporting-feedback_inbox-smart_recaps-topics-tooltip_button`}
						</ActionButton>
					</Box>
				</Paper>
			</Popper>

			<SmartRecapsQuickViewDrawer
				open={inboxDrawerOpen}
				onClose={e => {
					setInboxDrawerOpen(false);
					setSelectedFeedbackIds({
						sentiment: '',
						ids: [],
						itemsWithMsi: [],
					});
				}}
				chipLabel={selectedFeedbackIds.sentiment}
				surveyId={selectedFeedbackIds.id}
				feedbackIds={selectedFeedbackIds.ids}
				mergeFeedback={selectedFeedbackIds.itemsWithMsi}
			/>
		</div>
	);
}

function TopicRow({ topic, index, setAnchorEl, setFocused, setSelectedFeedbackIds }) {
	const classes = useStyles({ maxWidth: topic.msiTotalWidth });

	const smAndUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
	const mdAndUp = useMediaQuery(theme => theme.breakpoints.up('md'));
	const lgAndUp = useMediaQuery(theme => theme.breakpoints.up('lg'));
	const boxWidth = mdAndUp ? '50%' : smAndUp ? '75%' : '100%';

	//we only show the last summary
	const summaryBreakPoint = lgAndUp ? 150 : mdAndUp ? 100 : 75;
	const summary = topic.summary[topic.summary.length - 1] ?? '';
	const showSummary = summary.length > summaryBreakPoint;

	const [showCollapsedSummary, setShowCollapsedSummary] = useState(showSummary);

	return (
		<div>
			<Box
				width={boxWidth}
				className={classes.summaryMargin}
			>
				<Typography
					variant="subtitle1"
					fontWeight="medium"
					gutterBottom
				>
					{topic.topic}
				</Typography>
				<RenderConditional
					component={CollapsibleBox}
					collapseButtonVariant="link"
					collapseButtonPosition="end"
					condition={showSummary}
					label=""
					startCollapsed
					minClosedHeight={48}
					exposeCollapsed={e => {
						setShowCollapsedSummary(e);
					}}
				>
					<Box
						className={showCollapsedSummary ? classes.ellipsis : ''}
						mt={1}
						height={showCollapsedSummary ? '48px' : 'auto'}
					>
						{topic.summary.length > 0 && (
							<Typography
								variant="body2"
								gutterBottom
							>
								{summary}
							</Typography>
						)}
					</Box>
				</RenderConditional>
			</Box>
			<div className={classes.msiWidth}>
				<MsiDistBar
					topic={topic}
					setFocused={setFocused}
					setAnchorEl={setAnchorEl}
					setSelectedFeedbackIds={setSelectedFeedbackIds}
				/>
			</div>
		</div>
	);
}

function DateAlert({ startDate }) {
	const { t } = useTranslation();

	return (
		<Alert
			title={t`reporting-feedback_inbox-smart_recaps-alert_date_error-title`}
			severity="warning"
			close
		>
			<Trans
				i18nKey="reporting-feedback_inbox-smart_recaps-alert_date_error-content"
				components={{
					date: <FormattedDate date={startDate} />,
				}}
			></Trans>
		</Alert>
	);
}
