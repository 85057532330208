import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import { getProgressColor } from './actionUtils';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles(theme => ({
	bg: {
		backgroundColor: props => getProgressColor(props.status_id, theme),
		color: props =>
			theme.palette.getContrastText(getProgressColor(props.status_id, theme)),
	},
}));
export default function ActionProgressLabel({ action, ...props }) {
	const classes = useStyles({ status_id: Number(action.status_id) });
	const { t } = useTranslation();
	return (
		<Box
			{...props}
			clone
		>
			<Chip
				size="small"
				className={classes.bg}
				label={t(action.action_status_name)}
			/>
		</Box>
	);
}
