import React, { PureComponent, Fragment } from 'react';

import Downshift from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';

import Chip from '@material-ui/core/Chip';
import Fade from '@material-ui/core/Fade';
//import {TagChip} from '../Tags';
import { UserAvatar } from '../Users';
import produce from 'immer';
import get from 'lodash.get';
import Suggestion from './Suggestion';

const styles = theme => ({
	root: {
		flexGrow: 1,
		height: 250,
	},
	container: {
		flexGrow: 1,
		position: 'relative',
	},
	paper: {
		maxHeight: 300,
		overflowY: 'scroll',
		overflowX: 'hidden',
	},
	chip: {
		margin: theme.spacing(0.5, 0.25),
	},
	inputRoot: {
		flexWrap: 'wrap',
	},
	noWrap: {
		flexWrap: 'nowrap',
		maxWidth: '100%',
	},
	inputInput: {
		width: 'auto',
		flexGrow: 1,
	},
	divider: {
		height: theme.spacing(2),
	},
	popper: {
		zIndex: 1401,
	},
	noWrapChip: {
		maxWidth: '100%',
	},
	noWrapChipLabel: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: 'block',
	},
	optGroupChild: {
		paddingLeft: theme.spacing(3),
	},
	optGroup: {
		fontWeight: theme.typography.fontWeightMedium,
		opacity: 1,
	},
	tagChip: {
		background: theme.palette.mopinion.main,
		color: '#fff',
		'& svg': {
			color: 'rgba(255, 255, 255, 0.26)',
		},
	},
	separator: {
		margin: theme.spacing(0, 0.5),
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.text.secondary,
		textTransform: 'uppercase',
		fontSize: theme.typography.pxToRem(11),
	},
	filledInputWrap: {
		paddingTop: 21,
	},
	filledInput: {
		paddingTop: 8,
	},
});

function getSuggestions(value, options, disableTyping, isDataFieldPicker) {
	try {
		const trimmedValue = value.trim();
		const inputValue = trimmedValue.toLowerCase();
		const inputLength = inputValue.length;

		if (inputLength > 0 && !disableTyping)
			options = [{ label: trimmedValue, key: 'typing' }, ...options];

		if (inputLength === 0) return options;

		if (!isDataFieldPicker) {
			return options.filter(suggestion => {
				const search = suggestion.search ? suggestion.search : suggestion.label;
				const keep = search.toLowerCase().indexOf(inputValue) > -1;

				return keep;
			});
		} else {
			return [...options]
				.reverse()
				.reduce((allSuggestions, suggestion, index) => {
					const search = suggestion.search ? suggestion.search : suggestion.label;
					let keep = search.toLowerCase().indexOf(inputValue) > -1;

					if (suggestion.isSurvey) {
						if (allSuggestions.find(sug => sug.surveyId === suggestion.surveyId)) {
							keep = true;
						}
					}

					if (suggestion.isCategory) {
						if (
							allSuggestions.find(
								sug =>
									sug.surveyId === suggestion.surveyId &&
									sug.category === suggestion.category
							)
						) {
							keep = true;
						} else {
							keep = false;
						}
					}

					if (keep) {
						allSuggestions.push(suggestion);
					}
					return allSuggestions;
				}, [])
				.reverse();
		}
	} catch (e) {
		return options;
	}
}

function popperWidth(popperNode, isDataFieldPicker) {
	if (!popperNode) return null;

	if (isDataFieldPicker) {
		return popperNode.clientWidth < 300 ? 300 : popperNode.clientWidth;
	} else {
		return popperNode.clientWidth;
	}
}

function onChangeValue(multiple, selectedItem) {
	if (multiple) return selectedItem;

	const r = selectedItem[0] ? selectedItem[0] : {};
	return r;
}

function returnObjectForCustomValues(param) {
	return typeof param === 'string' ? { label: param } : param;
}

class AutoComplete extends PureComponent {
	static defaultProps = {
		options: [],
		selectedItem: [],
		placeholder: '',
		label: '',
		onChange: () => {},
		onBlur: () => {},
		isValidTag: () => true,
	};

	state = {
		inputValue: '',
		selectedItem: [],
	};

	popperNode = null;

	componentDidMount() {
		this.updateFromProps();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.selectedItem !== this.props.selectedItem) {
			this.updateFromProps(true);
		}
	}

	updateFromProps = fromDidUpdate => {
		const { selectedItem, renderChips, multiple } = this.props;
		if (selectedItem.length || fromDidUpdate) {
			this.setState({
				selectedItem: selectedItem,
				inputValue:
					renderChips || multiple
						? this.state.inputValue
						: get(selectedItem, '[0].label', ''),
			});
		}
	};

	handleKeyDown = event => {
		const { disableCustomEntry, multiple, onChange = () => {} } = this.props;
		const { inputValue } = this.state;

		if (
			this.state.selectedItem.length &&
			!inputValue.length &&
			event.key === 'Backspace'
		) {
			this.setState(
				prevState => ({
					...prevState,
					selectedItem: prevState.selectedItem.slice(
						0,
						prevState.selectedItem.length - 1
					),
				}),
				() => {
					onChange(onChangeValue(multiple, this.state.selectedItem));
				}
			);
		}

		if (inputValue.length && event.key === 'Tab' && !disableCustomEntry) {
			event.preventDefault();
			this.handleChange(inputValue);
		}
	};

	handleInputChange = event => {
		const { onInputChange = () => {}, isValidTag } = this.props;
		this.setState({ inputValue: event.target.value }, () => {
			onInputChange(this.state.inputValue, isValidTag(this.state.inputValue));
		});
	};

	handleChange = item => {
		const { multiple, onChange, renderChips, isValidTag } = this.props;

		//handle issues with esc key
		if (!item) return;

		const valid = isValidTag(item?.label ?? item);
		if (!valid) {
			onChange(multiple ? this.state.selectedItem : this.state.selectedItem[0]);
			return;
		}

		this.setState(
			produce(draft => {
				//if single autocomplete that shows the actual label as input value render label
				draft.inputValue = multiple || renderChips ? '' : item.label;

				if (multiple) {
					const index = draft.selectedItem.findIndex(el => el.label === item.label);
					if (index === -1) {
						draft.selectedItem.push(returnObjectForCustomValues(item));
					}
				} else {
					draft.selectedItem = Array.isArray(item)
						? item
						: [returnObjectForCustomValues(item)];
				}
			}),
			() => {
				onChange(multiple ? this.state.selectedItem : this.state.selectedItem[0]);
			}
		);
	};

	handleDelete = ({ label }) => {
		const { multiple, onChange } = this.props;
		const { selectedItem } = this.state;

		this.setState(
			produce(draft => {
				const index = draft.selectedItem.findIndex(item => item.label === label);
				draft.selectedItem.splice(index, 1);
			}),
			() => {
				onChange(onChangeValue(multiple, this.state.selectedItem));
			}
		);
	};

	render() {
		const {
			multiple,
			classes,
			options,
			placeholder,
			label,
			autoFocus = false,
			isTags,
			startOpen,
			isUserSearch,
			disableCustomEntry,
			disabled,
			noWrap,
			isDataFieldPicker,
			renderChips,
			error,
			helperText,
			dataTestElement,
			SuggestionProps = {},
			variant = 'standard',
			onBlur = onBlur,
			chipSeparator,
		} = this.props;

		const { inputValue, selectedItem, popperNode } = this.state;

		const chipClasses = {
			classes: {
				// label: makeClass({[classes.noWrapChipLabel]: noWrap})
				label: classes.noWrapChipLabel,
			},
			//className: makeClass({[classes.noWrapChip]: noWrap})
			className: `${classes.noWrapChip} ${isTags ? classes.tagChip : ''}`,
		};

		const addInputAdornmentChips = multiple || renderChips;

		return (
			<Downshift
				inputValue={inputValue ?? ''}
				onChange={this.handleChange}
				selectedItem={selectedItem}
				defaultHighlightedIndex={0}
				defaultIsOpen={startOpen}
				itemToString={item => item.label}
			>
				{({
					getInputProps,
					getItemProps,
					isOpen,
					inputValue,
					selectedItem,
					highlightedIndex,
					getMenuProps,
					openMenu,
				}) => (
					<div className={classes.container}>
						<TextField
							// classes={{
							//   root:makeClass({[classes.noWrap]:noWrap})
							// }}
							variant={variant}
							error={error}
							helperText={helperText}
							fullWidth
							autoFocus={autoFocus}
							disabled={disabled}
							inputProps={{ 'data-test-element': dataTestElement }}
							InputProps={{
								inputRef: node => this.setState({ popperNode: node }),
								classes: {
									root: `${classes.inputRoot} ${noWrap ? classes.noWrap : ''} ${
										variant === 'filled' ? classes.filledInputWrap : ''
									}`,
									input: `${classes.inputInput} ${
										variant === 'filled' ? classes.filledInput : ''
									}`,
								},
								...getInputProps({
									...(addInputAdornmentChips && {
										startAdornment: selectedItem.map((item, index) => {
											const chipProps = {
												key: item.label,
												tabIndex: -1,
												label: item.label,
												...(isTags && { tagClass: classes.chip }),
												...chipClasses,
												onDelete: e => this.handleDelete(item),
												variant: variant === 'filled' ? 'outlined' : 'default',
												...(isUserSearch && { avatar: <UserAvatar user_id={item.id} /> }),
											};

											return chipSeparator && index + 1 !== selectedItem.length ? (
												<>
													<Chip {...chipProps} />
													<span className={classes.separator}>{chipSeparator}</span>
												</>
											) : (
												<Chip {...chipProps} />
											);
										}),
									}),
									onChange: this.handleInputChange,
									onKeyDown: this.handleKeyDown,
									onFocus: openMenu,
									placeholder: placeholder,
									onBlur: e => {
										const { value } = e.target;
										if (
											!disableCustomEntry &&
											value.length > 0 &&
											this.state.selectedItem.length === 0
										) {
											this.handleChange(value);
										}
										onBlur(e);
									},
									onPaste: e => {
										e.preventDefault();
										const pasteValue = e.clipboardData.getData('Text');
										if (!disableCustomEntry) {
											String(pasteValue)
												.split(',')
												.forEach(v => this.handleChange(v.trim()));
										}
									},
								}),
							}}
							label={label}
							InputLabelProps={{
								...(selectedItem.length === 0 && !isOpen && { shrink: false }),
							}}
						/>

						<Popper
							open={isOpen}
							anchorEl={popperNode}
							transition
							className={classes.popper}
							placement="bottom-start"
							modifiers={{
								flip: {
									enabled: true,
								},
							}}
						>
							{({ TransitionProps }) => (
								<Fade
									{...TransitionProps}
									timeout={350}
								>
									<Paper
										elevation={8}
										square
										className={classes.paper}
										style={{
											marginTop: 8,
											width: popperWidth(popperNode, isDataFieldPicker),
										}}
									>
										{getSuggestions(
											inputValue,
											options,
											disableCustomEntry,
											isDataFieldPicker
										).map((suggestion, index) => {
											const allSuggestionProps = {
												suggestion,
												index,
												itemProps: getItemProps({ item: suggestion }),
												highlightedIndex,
												selectedItem,
												isUserSearch,
												key:
													suggestion.key === 'typing'
														? suggestion.key
														: Object.values(suggestion)
																.map(v => {
																	if (typeof v === 'string') {
																		return v;
																	}
																	return '';
																})
																.join(' '),
												isDataFieldPicker,
												popperNode,
												'data-test-element': dataTestElement,
												...SuggestionProps,
											};
											return <Suggestion {...allSuggestionProps} />;
										})}
									</Paper>
								</Fade>
							)}
						</Popper>
					</div>
				)}
			</Downshift>
		);
	}
}

export default withStyles(styles)(AutoComplete);
