import React from 'react';
import Portal from '@material-ui/core/Portal';
import { useAppContext } from '../AppContext';

export default function AppBarActions({ container, children }) {
	const { app } = useAppContext();

	return (
		<Portal container={container ? container : app.layout.appBarActionsRef.current}>
			{children}
		</Portal>
	);
}
