import React, { useState } from 'react';

import Avatar from '@material-ui/core/Avatar';

import { useTranslation, Trans } from 'react-i18next';

import { makeStyles } from '@/styles';
import { useAppContext } from '@/components/AppContext';
import { surveyTypes } from '@/components/Survey';
import { AddToPackageExplainer } from '@/components/Explainer';
import UpgradeDialog from '@/components/Billing/UpgradeDialog';

import {
	ActionButton,
	Box,
	Card,
	CardContent,
	CardHeader,
	CardActions,
	Typography,
} from '@/components/Layout';

import UpsellDialog from './UpsellDialog';
import UpgradeButton from './UpgradeButton';

import {
	trialbanner_web_en,
	trialbanner_web_nl,
	trialbanner_email_en,
	trialbanner_email_nl,
	trialbanner_sdk_en,
	trialbanner_sdk_nl,
} from '@/img';

const useStyles = makeStyles(theme => ({
	actionMargin: {
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	relative: {
		position: 'relative',
		height: '100%',
	},
	banner: {
		position: 'absolute',
		top: 0,
		right: 0,
		width: 112,
	},
	alignRight: {
		marginLeft: 'auto!important',
	},
	trialChip: {
		position: 'absolute',
		top: 10,
		left: 10,
		zIndex: '1000',
	},
	block: {
		display: 'block!important',
	},
	contentHeight: {
		minHeight: 135,
	},
}));

export default function UpsellCard({ product, ...props }) {
	const { app } = useAppContext();

	const daysLeftInProductTrial = app.api.packageProductTrialDaysLeft(product);

	/* no value -> can request channel trial */
	if (daysLeftInProductTrial === null) {
		return (
			<RequestProductTrial
				product={product}
				{...props}
			/>
		);
	}

	/* channel trial finished */
	return (
		<AddToPlanOverlay
			product={product}
			{...props}
		/>
	);
}

export function RequestProductTrial({ product, size, title }) {
	const { app } = useAppContext();
	const classes = useStyles();
	const { t } = useTranslation();
	const [upgrade, setUpgrade] = useState(false);
	const [upsell, setUpsell] = useState(false);

	const trialBannerMap = {
		web_en: trialbanner_web_en,
		web_nl: trialbanner_web_nl,
		email_en: trialbanner_email_en,
		email_nl: trialbanner_email_nl,
		sdk_en: trialbanner_sdk_en,
		sdk_nl: trialbanner_sdk_nl,
	};

	return (
		<>
			<Card className={classes.relative}>
				<div className={classes.banner}>
					<img
						src={
							trialBannerMap[`${product}_${Number(app.language?.id) === 1 ? 'nl' : 'en'}`]
						}
					/>
				</div>
				<CardHeader
					avatar={
						product && surveyTypes ? (
							<Avatar src={surveyTypes.find(type => type.type === product)?.img} />
						) : null
					}
					title={
						<Typography
							variant={size === 'small' ? 'subtitle2' : 'subtitle1'}
							fontWeight="medium"
						>
							{title}
						</Typography>
					}
					disableTypography
				/>
				<CardContent className={classes.contentHeight}>
					<Box className={classes.relative}>
						<Typography>
							<Trans
								i18nKey={`data_collection-feedback_forms-editor-channel_trial-content_${product}`}
								components={{
									p1: (
										<Typography
											fontWeight="medium"
											gutterBottom
										/>
									),
									p2: <Typography gutterBottom />,
								}}
							/>
						</Typography>
						<AddToPackageExplainer product={product} />
					</Box>
				</CardContent>
				<CardActions
					className={`${classes.actionMargin} ${size === 'small' ? classes.block : ''}`}
				>
					<ActionButton
						variant="outlined"
						color="primary"
						onClick={() => setUpsell(true)}
						fullWidth={size === 'small'}
					>
						{t`data_collection-feedback_forms-editor-channel_trial-button_start_trial`}
					</ActionButton>
				</CardActions>
			</Card>
			<UpsellDialog
				open={upsell}
				product={product}
				onClose={() => setUpsell(false)}
			/>
			<UpgradeDialog
				open={upgrade}
				onClose={() => setUpgrade(false)}
			/>
		</>
	);
}

export function AddToPlanOverlay({ product, size }) {
	const { app } = useAppContext();
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<>
			<Card className={classes.relative}>
				<CardHeader
					avatar={
						product && surveyTypes ? (
							<Avatar src={surveyTypes.find(type => type.type === product)?.img} />
						) : null
					}
					title={
						<Typography
							variant={size === 'small' ? 'subtitle2' : 'subtitle1'}
							fontWeight="medium"
						>
							{t(`new_survey_flow-upgrade_message-title_${product}`)}
						</Typography>
					}
					disableTypography
				/>
				<CardContent className={classes.contentHeight}>
					<Box
						mb={0.5}
						className={classes.relative}
					>
						<Trans
							i18nKey={`new_survey_flow-upgrade_message-label_${product}`}
							components={{
								p1: (
									<Typography
										fontWeight="medium"
										gutterBottom
									/>
								),
								p2: <Typography gutterBottom />,
							}}
						/>
						<AddToPackageExplainer
							product={product}
							alignLink="left"
						/>
					</Box>
				</CardContent>
				<CardActions className={classes.actionMargin}>
					<UpgradeButton
						size={size}
						upgradeSubject={t(`add_to_package_explainer-subject_${product}`)}
						label={
							app.package.is_stripe_customer && app.package.tiered_pricing
								? t`app_general-upgrade_overlay-button_contact-stripe`
								: t`app_general-upgrade_overlay-button_contact-enterprise`
						}
						dataTrackEvent={`upgrade_${product}_after_product_trial_${
							app.package.is_stripe_customer && app.package.tiered_pricing
								? 'stripe'
								: 'enterprise'
						}`}
					/>
				</CardActions>
			</Card>
		</>
	);
}
