import React from 'react';

export function mapLegacyGridtoGrid(layout = {}) {
	const layoutArr = Object.values(layout.row);
	return layoutArr.reduce((accumulate, currentArray, i) => {
		const row = currentArray.chartid
			.map((chart_id, x) => {
				if (Number(chart_id) !== 0) {
					return {
						i: chart_id,
						key: chart_id,
						w: Number(currentArray.span[x]),
						h: Math.round(Number(currentArray.rowheight[0] / 64)),
						x: currentArray.span.reduce((cul, cur, b) => {
							if (b < x) {
								cul = Number(cur) + cul;
							}
							return cul;
						}, 0),
						y: layoutArr.reduce((cul, cur, b) => {
							if (b < i) {
								cul = Math.round(Number(cur.rowheight[0] / 64) * 2) + cul;
							}
							return cul;
						}, 0),
					};
				}
			})
			.filter(Boolean);

		if (row.length) {
			accumulate = [...accumulate, ...row];
		}
		return accumulate;
	}, []);
}

export function getGrid(dashboard) {
	let layout = { lg: [] };

	if (!dashboard) {
		return layout;
	}

	if (dashboard.react_layout) {
		layout = JSON.parse(dashboard.react_layout);
	} else {
		//is old grid
		try {
			layout = { lg: mapLegacyGridtoGrid(JSON.parse(dashboard.layout)) };
		} catch (e) {}
	}

	if (!layout.lg) {
		layout.lg = layout[Object.keys(layout)[0]];
		delete layout.md;
		delete layout.sm;
	}

	return { layout: layout, legacyConverted: dashboard.layout && !dashboard.react_layout };
}

export function getResponsiveGridSize(getCols) {
	const windowWidth = window.innerWidth;
	const breakpoints = { lg: 481, xs: 480 };
	const cols = { lg: 12, xs: 3 };

	let point;
	for (let k in breakpoints) {
		if (windowWidth > breakpoints[k]) {
			point = k;
			break;
		}
	}
	return getCols
		? cols[point]
		: {
				breakpoint: point,
				width: breakpoints[point],
		  };
}

export function deepCloneObject(obj) {
	if (typeof obj !== 'object' || Array.isArray(obj)) {
		return obj;
	}
	return JSON.parse(JSON.stringify(obj));
}
