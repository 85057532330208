import React, { Fragment, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useAppContext } from '../AppContext';
import {
	SystemElementCardBase,
	ActionIconButton,
	AjaxFormDialog,
	HTML,
	Typography,
	Box,
} from '../Layout';
import AddEditStandardAnswerDialog from './AddEditStandardAnswerDialog';
import { useHasPermission, NoPermissionTooltip } from '../Permission';

export default function StandardAnswerCard({ answer, loadData }) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { app } = useAppContext();
	const [editOpen, setEditOpen] = useState(false);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const hasEditPermission = useHasPermission();
	return (
		<Fragment>
			<SystemElementCardBase
				img={'/assets/img/r/standard-answer.svg'}
				title={answer.name}
				actions={
					<>
						<NoPermissionTooltip>
							<ActionIconButton
								action="edit"
								tooltip={t`Edit answer`}
								onClick={() => setEditOpen(true)}
								disabled={!hasEditPermission}
							/>
						</NoPermissionTooltip>
						<NoPermissionTooltip>
							<ActionIconButton
								action="delete"
								tooltip={t`Delete answer`}
								onClick={() => setDeleteOpen(true)}
								disabled={!hasEditPermission}
							/>
						</NoPermissionTooltip>
					</>
				}
			>
				{answer.subject && (
					<Box mb={1}>
						<Typography
							variant="subtitle2"
							color="textSecondary"
						>{t`Subject`}</Typography>
						<Typography variant="body2">{answer.subject}</Typography>
					</Box>
				)}
				{answer.answer && (
					<Fragment>
						<Typography
							variant="subtitle2"
							color="textSecondary"
						>{t`Message`}</Typography>
						<Typography
							variant="body2"
							component="div"
						>
							<HTML html={answer.answer} />
						</Typography>
					</Fragment>
				)}
			</SystemElementCardBase>

			<AddEditStandardAnswerDialog
				answer={answer}
				open={editOpen}
				loadData={loadData}
				onClose={() => setEditOpen(false)}
			/>
			<AjaxFormDialog
				open={deleteOpen}
				url="/application/email/delete"
				data={{
					id: answer.id,
				}}
				title={t`Delete answer`}
				text={t`Permanently delete answer?`}
				dataTrackEvent="settings_answer_deleted"
				onClose={() => setDeleteOpen(false)}
				onSuccess={() => {
					enqueueSnackbar(t`Answer deleted`);
					loadData();
					setDeleteOpen(false);
				}}
			/>
		</Fragment>
	);
}
