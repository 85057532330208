import React, { Component, Fragment, useState, useEffect } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Portal from '@material-ui/core/Portal';
import Box from '@material-ui/core/Box';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DragHandle from '@material-ui/icons/DragHandle';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterIcon from '@material-ui/icons/FilterList';
import CalendarToday from '@material-ui/icons/CalendarToday';

import produce from 'immer';
import get from 'lodash.get';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { withSnackbar } from 'notistack';
import { useTranslation, withTranslation } from 'react-i18next';

import { SelectShowEmpty, DataFieldSelect } from '@/components/Select';
import ActiveSeriesChip from './ActiveSeriesChip';
import {
	CustomFilterSidebarChip,
	CustomFilter,
	FilterDrawer,
	filterUtils,
} from '@/components/FilteredLists';
import { withAppContext } from '@/components/AppContext';
import { CalcIcon, PercIcon } from '@/components/Icons';
import { withDataSources, DataFieldIcon } from '@/components/DataSources';
import { strComp } from '@/utils';
import { usePrevious } from '@/hooks';
import {
	PossibleValueAutoComplete,
	PossibleValueSelect,
} from '@/components/AutoComplete';
import {
	EmptyState,
	ActionButton,
	EditableContent,
	ListItemWithRightContent,
	EditorListRadioGroup,
	Alert,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	ColorPicker,
} from '@/components/Layout';
import { AjaxContent, useMjolnir } from '@/components/Ajax';
import { xIsSameAsYSerieValue } from './constants';
import { fieldIsCheckboxOptionButNotExtra } from './chartUtils';

const styles = theme => ({
	dividerNotFull: {
		margin: theme.spacing(0, 2),
	},
	headerList: {
		width: '100%',
		padding: 0,
	},
	dividerSpacing: {
		margin: theme.spacing(1, 0),
	},
	wrapperDiv: {
		//height:'100%'
		flexGrow: 1,
		padding: theme.spacing(1),
	},
	indicator: {
		position: 'absolute',
		left: 0,
		top: 0,
		bottom: 0,
		width: 3,
		zIndex: 1,
	},
	seriesBlock: {
		// borderTop: '1px solid '+theme.palette.divider,
		// borderBottom: '1px solid '+theme.palette.divider,
		marginBottom: theme.spacing(3),
	},
	nested: {
		paddingLeft: theme.spacing(5),
		flexDirection: 'column',
	},
	popper: {
		zIndex: theme.zIndex.modal + 1,
	},
	colorPicker: {
		boxShadow: 'none!important',
	},
	alignCenter: {
		textAlign: 'center',
	},
	titleSpacing: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	expSummaryInnerGrid: {
		padding: '0!important',
	},
	expDetailsRoot: {
		padding: 0,
		flexDirection: 'column',
	},
	seriesList: {
		width: '100%',
	},
	badgeBg: {
		backgroundColor: theme.palette.grey['400'],
	},
	chip: {
		margin: theme.spacing(0.25),
	},
	summary: {
		// flexDirection:'column'
		padding: 0,
		margin: '0!important',
	},
	colorIndicatorBubble: {
		width: 20,
		height: 20,
		borderRadius: '100%',
		boxShadow: theme.shadows[1],
		transition: theme.transitions.create('boxShadow'),
		'&:hover': {
			boxShadow: theme.shadows[3],
		},
	},
	greyBadge: {
		backgroundColor: theme.palette.common.active,
	},
	flexWrap: {
		flexWrap: 'wrap',
	},
	addFilterButtonDrawer: {
		alignSelf: 'flex-start',
	},
});

const sortDataOptions = [
	{ label: 'Unsorted', value: '' },
	{ label: 'Ascending', value: 'asc' },
	{ label: 'Descending', value: 'desc' },
];

const limitOptions = [
	{ label: 'No limit', value: '' },
	{ label: '0', value: 0 },
	{ label: 1, value: 1 },
	{ label: 2, value: 2 },
	{ label: 3, value: 3 },
	{ label: 4, value: 4 },
	{ label: 5, value: 5 },
	{ label: 10, value: 10 },
	{ label: 15, value: 15 },
	{ label: 20, value: 20 },
];

const decimalOptions = [
	{ label: 'reporting-chart_editor-decimal_whole', value: 0 },
	{ label: 'reporting-chart_editor-decimal_one_decimal', value: 1 },
	{ label: 'reporting-chart_editor-decimal_two_decimal', value: 2 },
];

const datePeriodOptions = [
	{
		label: 'reporting-chart_editor-date_period_day',
		value: 'day',
		icon: <CalendarToday />,
		checkedIcon: <CalendarToday />,
		days: 1,
	},
	{
		label: 'reporting-chart_editor-date_period_month',
		value: 'week',
		icon: <CalendarToday />,
		checkedIcon: <CalendarToday />,
		days: 7,
	},
	{
		label: 'reporting-chart_editor-date_period_year',
		value: 'month',
		icon: <CalendarToday />,
		checkedIcon: <CalendarToday />,
		days: 31,
	},
];

const dateFormatOptions = [
	{
		label: 'reporting-chart_editor-date_format_auto',
		value: '',
	},
	{
		label: 'reporting-chart_editor-date_format_month_name',
		value: '*b',
	},
	{
		label: 'reporting-chart_editor-date_format_week_number',
		value: 'Week *v',
	},
	{
		label: 'reporting-chart_editor-date_format_yyyy_mm',
		value: '*Y-*m',
	},
	{
		label: 'reporting-chart_editor-date_format_mm_yyyy',
		value: '*m-*Y',
	},
	{
		label: 'reporting-chart_editor-date_format_mm_yyyy_slash',
		value: '*m/*Y',
	},
];

function MultipleCalcsAlert({ calcs, updateCalc }) {
	const { t } = useTranslation();
	const [data] = useMjolnir(`calc`);
	const [show, setShow] = useState(true);

	const prevCalcs = usePrevious(JSON.stringify(calcs));
	const currentCalcs = JSON.stringify(calcs);

	useEffect(() => {
		if (currentCalcs !== prevCalcs) {
			setShow(true);
		}
	}, [currentCalcs, prevCalcs]);

	if (!show) return null;

	return (
		<Alert
			severity="info"
			onClose={() => setShow(false)}
		>
			{t`reporting-chart_editor-tab_series-series_alert`}

			<Box mt={1}>
				{calcs.map(calc => {
					const label = data.fields?.find(field => field.id == calc)?.label;
					return (
						<Box
							clone
							m={0.25}
						>
							<Chip
								variant="outlined"
								label={label}
								onClick={() => updateCalc(calc)}
							/>
						</Box>
					);
				})}
			</Box>
		</Alert>
	);
}

function SeriesListItem({ ...props }) {
	const { t } = useTranslation();
	return (
		<ListItem>
			<SelectShowEmpty
				label={t(props.label)}
				value={String(props.value) || ''}
				onChange={props.onChange}
			>
				{props.children}
			</SelectShowEmpty>
		</ListItem>
	);
}

function ActiveDataSourceListItem({ field, source, ...props }) {
	const { t } = useTranslation();

	const title = field.title ?? field.system_var;
	return (
		<ListItem>
			<ListItemIcon>
				<DataFieldIcon {...field} />
			</ListItemIcon>
			<ListItemText
				primary={title}
				secondary={t('reporting-chart_editor-tab_series-sourcelist', {
					source: source.name,
				})}
			/>
		</ListItem>
	);
}

class ChartEditorSeries extends Component {
	static defaultProps = {
		series: [],
		onChange: () => {},
		onUpdateFilter: () => {},
		onAddFilter: () => {},
		onRemoveFilter: () => {},
		dataFields: {},
	};

	state = {
		serieState: {},
	};

	refs = {};

	surveysInSeries = () => {
		const { series, datasource } = this.props;

		return series.reduce((surveys, currentSerie) => {
			const { survey_id } = currentSerie;

			if (!surveys[survey_id]) {
				const surveyFields = datasource.api.getFieldArray(survey_id, true);

				surveys[survey_id] = {
					date: surveyFields.find(field => field.var_type == 7)?.id,
					tags: surveyFields.find(field => field.import_var === 'Tags')?.id,
				};
			}

			return surveys;
		}, {});
	};

	isDateField = (xValue, surveysInSeries = {}) => {
		return Boolean(
			xValue && Object.values(surveysInSeries).find(survey => survey.date == xValue)
		);
	};

	setSerieState = (index, obj = {}, fn = () => {}) => {
		this.setState(
			produce(draft => {
				if (typeof draft.serieState['serie' + index] !== 'object')
					draft.serieState['serie' + index] = {};
				Object.assign(draft.serieState['serie' + index], obj);
			}),
			fn()
		);
	};

	handleChange = ({ index, obj = {}, custom = {} }) => {
		this.props.onChange({ index, obj, custom });
	};

	handleChangeAll = ({ obj = {}, custom = {} }) => {
		const { series, datasource } = this.props;

		if (obj.x) {
			const surveyId = get(series, '[0].survey_id', 0);
			const allFields = datasource.api.getFieldArray(surveyId, true);
			const dateFieldId = allFields.find(field => strComp(field.var_type, 7));
			const tagsField = allFields.find(field => field.import_var === 'Tags');

			if (strComp(obj.x, get(dateFieldId, 'id'))) {
				obj.date_group = 'month';
				Object.assign(custom, {
					date_group: 'month',
					date_format: '*b',
				});
			} else {
				obj.date_group = '';
				Object.assign(custom, {
					date_group: '',
					date_format: '',
				});
			}

			custom.x_tags = strComp(obj.x, get(tagsField, 'id')) ? 'true' : '';
		}

		this.props.series.forEach((serie, index) => {
			this.props.onChange({ index, obj, custom });
		});
	};

	handleChangeX = (x, surveyId) => {
		const { series, datasource, onChange, t, enqueueSnackbar } = this.props;

		const surveysInSeries = this.surveysInSeries();
		const { source, ...xField } = datasource.api.getField(x, true);

		series.forEach((serie, index) => {
			if (xField?.var_type == 7) {
				//xfield is a date type
				if (surveysInSeries[serie.survey_id].date == x) {
					//were setting the x for a serie which contains data from the survey  the user selection
					onChange({
						index,
						obj: { x, date_group: 'month' },
						custom: { date_group: 'month', date_format: '*b', x_tags: '' },
					});
				} else {
					//we have to find a corresponding field in the datasource
					const dateFieldForCurrentSurvey = datasource.api
						.getFieldArray(serie.survey_id)
						.find(field => field.var_type == 7);
					if (dateFieldForCurrentSurvey) {
						onChange({
							index,
							obj: { x: dateFieldForCurrentSurvey.id, date_group: 'month' },
							custom: { date_group: 'month', date_format: '*b', x_tags: '' },
						});
					} else {
						enqueueSnackbar(
							t(
								`No date field found in {{surveyName}}, try selecting a different datasource or field`,
								{ surveyName: datasource.api.getSource(serie.survey_id).name }
							)
						);
						onChange({
							index,
							obj: { x: undefined, date_group: 'month' },
							custom: { date_group: 'month', date_format: '*b', x_tags: '' },
						});
					}
				}
			} else if (xField?.import_var?.toLowerCase() === 'tags') {
				//xfield is a tags type
				if (source.id == serie.survey_id) {
					onChange({
						index,
						obj: { x },
						custom: { date_group: '', date_format: '', x_tags: 'true' },
					});
				} else {
					//we must get the tags field from this survey
					onChange({
						index,
						obj: { x: surveysInSeries[serie.survey_id]?.tags },
						custom: { date_group: '', date_format: '', x_tags: 'true' },
					});
				}
			} else if (Object.keys(surveysInSeries).length > 1) {
				//there are more than 1 datasources in the survey

				//check if x is coming from the current survey
				if (source.id == serie.survey_id) {
					//if so; we can safely change the field
					onChange({
						index,
						obj: { x },
						custom: { date_group: '', date_format: '', x_tags: '' },
					});
				} else {
					if (x === xIsSameAsYSerieValue) {
						onChange({
							index,
							obj: { x: xIsSameAsYSerieValue },
							custom: { date_group: '', date_format: '', x_tags: '' },
						});
					}

					//if x is coming from a different survey than the one we're updating right now
					//we must first check if the current selected field in this series matches the new xfield var_type
					//if so we dont have to do anything here
					const currentXField = datasource.api.getField(serie.x);

					if (currentXField.var_type != xField.var_type) {
						//if it doesnt match -> we have to find a matching var_type inside the surveys fields
						//first we try to find a field in which both block type and var_type match, otherwise we fall back to just trying to find a matching var_type
						const matchingField =
							datasource.api
								.getFieldArray(serie.survey_id)
								.find(
									field =>
										field.var_type == xField.var_type &&
										field.block_type == field.block_type
								) ??
							datasource.api
								.getFieldArray(serie.survey_id)
								.find(field => field.var_type == xField.var_type);

						if (matchingField) {
							onChange({
								index,
								obj: { x: matchingField.id },
								custom: { date_group: '', date_format: '', x_tags: '' },
							});
						} else {
							enqueueSnackbar(
								t(
									`No matching field found in {{surveyName}}, try selecting a different datasource or field`,
									{ surveyName: datasource.api.getSource(serie.survey_id).name }
								)
							);
							onChange({
								index,
								obj: { x: undefined },
								custom: { date_group: '', date_format: '', x_tags: '' },
							});
						}
					}
				}
			} else {
				//we can just do a simple update because there is only 1 datasource in the survey
				onChange({
					index,
					obj: { x },
					custom: { date_group: '', date_format: '', x_tags: '' },
				});
			}
		});
	};

	render() {
		const { series, classes, datasource, orderSeries, removeSerie, app, chartType, t } =
			this.props;
		const { serieState } = this.state;

		const surveysInSeries = this.surveysInSeries();

		const uniqueCalcs = [...new Set(series.map(serie => Number(serie.average)))];

		if (series.length === 0) {
			return (
				<EmptyState
					primary={t`reporting-chart_editor-emptystate_series-title`}
					secondary={t`reporting-chart_editor-emptystate_series-subtitle`}
					action={
						<Button
							variant="contained"
							color="primary"
							onClick={this.props.openDataTab}
						>
							{t`reporting-chart_editor-emptystate_series-button`}
						</Button>
					}
				/>
			);
		}

		return (
			<div
				ref={this.div}
				className={classes.wrapperDiv}
			>
				<Typography
					variant="subtitle2"
					className={classes.titleSpacing}
				>{t`reporting-chart_editor-tab_series-series_title`}</Typography>
				<DragDropContext
					onDragEnd={({ destination, source }) => {
						if (!destination) return;

						if (
							destination.droppableId === source.droppableId &&
							source.index !== destination.index
						) {
							orderSeries(source.index, destination.index);
						}
					}}
				>
					<Droppable droppableId="series-area">
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								className={classes.fillHeight}
							>
								{uniqueCalcs.length > 1 && (
									<MultipleCalcsAlert
										calcs={uniqueCalcs}
										updateCalc={calc => this.handleChangeAll({ obj: { average: calc } })}
									/>
								)}
								{series.map((serie, index) => {
									const currentState = serieState['serie' + index] || {};
									const {
										isCollapsed,
										colorPickerAnchor,
										editSerieName,
										updatedSerieName,
									} = currentState;
									const { source, ...field } = datasource.api.getField(serie.y, true);

									const useContains =
										['6', '13', '14', '35', '36', '37'].indexOf(String(field.var_type)) >
										-1;
									const hasAbsoluteValues =
										(!useContains && field.block_id) ||
										(!useContains &&
											['12', '16', '24', '27'].indexOf(String(field.var_type)) > -1);

									return (
										<AjaxContent
											url={`calc/${app.domain}/${serie.y}`}
											defaultData={{
												fields: [],
											}}
											api="mjolnir"
											key={'serie-' + index}
										>
											{({ data: { fields: calcs } }) => (
												<Fragment>
													<Draggable
														key={serie.y + index}
														draggableId={serie.y + index}
														index={index}
													>
														{(provided, snapshot) => (
															<Portal disablePortal={!snapshot.isDragging}>
																<ExpansionPanel
																	{...provided.draggableProps}
																	ref={provided.innerRef}
																	expanded={Boolean(!isCollapsed)}
																	data-serie-card={index}
																>
																	<ExpansionPanelSummary
																		classes={{
																			content: classes.summary,
																			expanded: classes.summary,
																			root: classes.summary,
																		}}
																	>
																		<div
																			className={classes.indicator}
																			style={{ backgroundColor: serie.color }}
																		/>
																		<List className={classes.headerList}>
																			<ListItem>
																				<Grid container>
																					<Grid
																						item
																						xs
																					/>

																					<Grid item>
																						<Tooltip title={t('Remove serie')}>
																							<IconButton
																								onClick={e => removeSerie(index)}
																							>
																								<DeleteIcon fontSize="small" />
																							</IconButton>
																						</Tooltip>

																						<Tooltip title={t('Drag to reorder')}>
																							<IconButton
																								component="div"
																								{...provided.dragHandleProps}
																							>
																								<DragHandle fontSize="small" />
																							</IconButton>
																						</Tooltip>
																						<Tooltip
																							title={
																								isCollapsed
																									? t('Expand serie')
																									: t('Collapse serie')
																							}
																						>
																							<IconButton
																								onClick={e =>
																									this.setSerieState(index, {
																										isCollapsed: !isCollapsed,
																									})
																								}
																							>
																								{!isCollapsed ? (
																									<ExpandLess fontSize="small" />
																								) : (
																									<ExpandMore fontSize="small" />
																								)}
																							</IconButton>
																						</Tooltip>
																					</Grid>
																				</Grid>
																			</ListItem>

																			<ListItem>
																				<ListItemIcon>
																					<Tooltip title={t`Change serie color`}>
																						<div
																							className={classes.colorIndicatorBubble}
																							style={{
																								background: serie.color,
																							}}
																							onClick={e =>
																								this.setSerieState(index, {
																									colorPickerAnchor: e.target,
																								})
																							}
																						/>
																					</Tooltip>
																				</ListItemIcon>
																				<Popper
																					open={Boolean(colorPickerAnchor)}
																					anchorEl={colorPickerAnchor}
																					transition
																					className={classes.popper}
																				>
																					{({ TransitionProps }) => (
																						<Fade
																							{...TransitionProps}
																							timeout={350}
																						>
																							<ClickAwayListener
																								onClickAway={e =>
																									this.setSerieState(index, {
																										colorPickerAnchor: null,
																									})
																								}
																							>
																								<Paper elevation={8}>
																									<ColorPicker
																										color={serie.color}
																										onChangeComplete={color =>
																											this.handleChange({
																												index,
																												obj: { color: color.hex },
																											})
																										}
																										disableAlpha
																										className={classes.colorPicker}
																									/>
																									<Button
																										onClick={e =>
																											this.setSerieState(index, {
																												colorPickerAnchor: null,
																											})
																										}
																									>
																										OK
																									</Button>
																								</Paper>
																							</ClickAwayListener>
																						</Fade>
																					)}
																				</Popper>
																				<ListItemText
																					primary={
																						<EditableContent
																							onChange={value =>
																								this.setSerieState(index, {
																									updatedSerieName: value,
																								})
																							}
																							onClose={() => {
																								if (updatedSerieName)
																									this.handleChange({
																										index,
																										obj: { name: updatedSerieName },
																									});
																							}}
																							value={updatedSerieName ?? serie.name}
																							fullWidth
																						>
																							{serie.name}
																						</EditableContent>
																					}
																					primaryTypographyProps={{
																						variant: 'subtitle1',
																					}}
																				/>
																			</ListItem>
																		</List>
																	</ExpansionPanelSummary>

																	<ExpansionPanelDetails
																		classes={{
																			root: classes.expDetailsRoot,
																		}}
																	>
																		<div
																			className={classes.indicator}
																			style={{ background: serie.color }}
																		/>

																		<List className={classes.seriesList}>
																			<ActiveDataSourceListItem
																				field={field}
																				source={source}
																			/>

																			<ListItem alignItems="flex-start">
																				<ListItemIcon>
																					<CalcIcon />
																				</ListItemIcon>
																				<ListItemText
																					primary={t`Data calculation`}
																					secondary={calcs
																						.filter(option => {
																							if (
																								fieldIsCheckboxOptionButNotExtra(field)
																							) {
																								//calc id 3 = percentage
																								//this calc is useless for checkbox options
																								return option.id !== 3;
																							}

																							return true;
																						})
																						.map(option => {
																							const selected = strComp(
																								serie.average,
																								option.id
																							);
																							return (
																								<Chip
																									key={'calc' + option.id}
																									onClick={() =>
																										this.handleChange({
																											index,
																											obj: { average: String(option.id) },
																										})
																									}
																									color={selected ? 'primary' : 'default'}
																									variant={
																										selected ? 'default' : 'outlined'
																									}
																									label={option.label}
																									className={classes.chip}
																								/>
																							);
																						})}
																					secondaryTypographyProps={{
																						component: 'div',
																					}}
																				/>
																			</ListItem>

																			{serie.average == 3 && hasAbsoluteValues ? (
																				<ListItem>
																					<ListItemIcon />
																					<PossibleValueSelect
																						label={t`Calculate percentage of values`}
																						datafieldId={serie.y}
																						value={serie.perc_cats}
																						onChange={e =>
																							this.handleChange({
																								index,
																								obj: { perc_cats: e },
																							})
																						}
																						returnKey={'value'}
																					/>
																				</ListItem>
																			) : serie.average == 3 && !hasAbsoluteValues ? (
																				<ListItem>
																					<ListItemIcon />
																					<PossibleValueAutoComplete
																						label={t`Calculate percentage of values`}
																						datafieldId={serie.y}
																						value={serie.perc_cats}
																						onChange={e =>
																							this.handleChange({
																								index,
																								obj: { perc_cats: e },
																							})
																						}
																					/>
																				</ListItem>
																			) : null}
																			<ListItem
																				button
																				onClick={e =>
																					this.handleChange({
																						index,
																						custom: { perc: !serie.custom.perc ? 1 : 0 },
																					})
																				}
																			>
																				<ListItemIcon>
																					<PercIcon />
																				</ListItemIcon>
																				<ListItemText
																					primary={t`reporting-chart_editor-tab_series-percentage_toggle`}
																					secondary={t`reporting-chart_editor-tab_series-percentage_toggle_secondary`}
																				/>
																				<ListItemSecondaryAction>
																					<Switch
																						checked={Boolean(serie.custom.perc)}
																						onChange={e =>
																							this.handleChange({
																								index,
																								custom: {
																									perc: e.target.checked ? 1 : 0,
																								},
																							})
																						}
																						value="perc"
																						color="secondary"
																					/>
																				</ListItemSecondaryAction>
																			</ListItem>

																			<ListItem>
																				<ListItemIcon>
																					<FilterIcon />
																				</ListItemIcon>
																				<ListItemText primary={t('Serie filters')} />
																				<ListItemSecondaryAction>
																					<ActionButton
																						action="add_list"
																						onClick={e => {
																							this.props.onAddFilter(index);
																							this.setSerieState(index, {
																								filtersOpen: true,
																							});
																						}}
																						label={t`Add`}
																					/>
																				</ListItemSecondaryAction>
																			</ListItem>

																			{Array.isArray(serie.filter_key) &&
																			Array.isArray(serie.filter_value) &&
																			serie.filter_key.length ? (
																				<ListItem className={classes.flexWrap}>
																					<ListItemIcon />
																					{serie.filter_key.map((key, filterIndex) => {
																						const identifier =
																							serie.filter_key[filterIndex];
																						const value =
																							identifier !== 'tags'
																								? serie.filter_value[filterIndex]
																								: serie.custom.filter_tags;
																						return (
																							<CustomFilterSidebarChip
																								filter={{
																									identifier,
																									value,
																								}}
																								customLabel={
																									identifier === 'tags' ? t`Tags` : null
																								}
																								onDelete={() =>
																									this.props.onRemoveFilter(
																										index,
																										filterIndex,
																										identifier === 'tags'
																									)
																								}
																								color="default"
																								key={'extra-filter' + index + filterIndex}
																								tooltip={t`Click to edit filter`}
																								onClick={() =>
																									this.setSerieState(index, {
																										filtersOpen: true,
																									})
																								}
																							/>
																						);
																					})}
																				</ListItem>
																			) : null}
																		</List>
																	</ExpansionPanelDetails>
																</ExpansionPanel>
															</Portal>
														)}
													</Draggable>

													<FilterDrawer
														open={currentState.filtersOpen}
														PortalProps={{
															use: true,
															container: this.props.drawerRef.current,
														}}
														title={t`Serie filters`}
														onClose={() =>
															this.setSerieState(index, { filtersOpen: false })
														}
													>
														<Box>
															<Typography
																variant="subtitle1"
																color="textSecondary"
																display="inline"
															>
																{t(
																	'Select datafields and values to filter on for data serie'
																)}
															</Typography>
															<ActiveSeriesChip serie={serie} />
														</Box>
														{Array.isArray(serie.filter_key) &&
															Array.isArray(serie.filter_value) &&
															serie.filter_key.map((filterKey, filterIndex) => {
																const filterField = datasource.api.getField(filterKey);
																const filterValues =
																	serie.filter_value[filterIndex]?.map(value =>
																		isNaN(value) ? value : Number(value)
																	) ?? [];

																return (
																	<Fragment key={'custom-filter-' + index + filterIndex}>
																		<CustomFilter
																			identifier={filterKey}
																			value={filterValues}
																			surveyId={serie.survey_id}
																			onDelete={() =>
																				this.props.onRemoveFilter(index, filterIndex)
																			}
																			DataFieldAutoCompleteProps={{
																				// forceUseSurvey:true
																				includeAllandTags: true,
																				onChange: e => {
																					this.props.onUpdateFilter(index, filterIndex, {
																						filter_key: e?.value || '',
																					});
																				},
																			}}
																			PossibleValueSelectProps={{
																				onChange: e =>
																					this.props.onUpdateFilter(index, filterIndex, {
																						filter_value: e,
																					}),
																			}}
																			PossibleValueAutoCompleteProps={{
																				onChange: e => {
																					this.props.onUpdateFilter(index, filterIndex, {
																						filter_value: e.map((v, i) => {
																							if (
																								filterUtils.useContains(
																									filterField.var_type
																								)
																							) {
																								return `_${v}_`;
																							}
																							return v;
																						}),
																					});
																				},
																				value: filterValues.map(v => {
																					//We need to map the value here because default a _ is added to make the filter a contains search
																					if (
																						typeof v === 'string' &&
																						v.startsWith('_') &&
																						v.endsWith('_')
																					) {
																						v = v.substr(1).slice(0, -1);
																					}
																					return v;
																				}),
																			}}
																			TagsAutoCompleteProps={{
																				onChange: e => {
																					const tags = e.map(tag => tag.label);
																					this.props.onUpdateFilter(index, filterIndex, {
																						tags,
																					});
																				},
																				selectedItem: Array.isArray(
																					serie.custom.filter_tags
																				)
																					? serie.custom.filter_tags.map(tag => ({
																							label: tag,
																					  }))
																					: typeof serie.custom.filter_tags === 'string'
																					? serie.custom.filter_tags
																							.split(',')
																							.filter(Boolean)
																							.map(tag => ({ label: tag }))
																					: [],
																			}}
																			forceUseSurvey
																		/>
																		{filterIndex + 1 !== serie.filter_key.length && (
																			<Typography
																				variant="subtitle2"
																				color="textSecondary"
																			>{t`and`}</Typography>
																		)}
																	</Fragment>
																);
															})}

														<ActionButton
															action="add_list"
															onClick={() => this.props.onAddFilter(index)}
															className={classes.addFilterButtonDrawer}
														>
															{t`Add`}
														</ActionButton>
													</FilterDrawer>
												</Fragment>
											)}
										</AjaxContent>
									);
								})}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				{chartType != 2 && (
					<Fragment>
						<Typography
							variant="subtitle2"
							className={classes.titleSpacing}
						>
							{t('Group against')}
						</Typography>
						<Card className={classes.seriesBlock}>
							<List>
								{Object.keys(surveysInSeries).map((surveyId, i, self) => {
									return (
										<ListItem key={surveyId}>
											<DataFieldSelect
												label={
													Object.keys(self).length === 1
														? t('Field for categories')
														: t('Field for categories for datasource {{source}}', {
																source: datasource.api.getSource(surveyId).name,
														  })
												}
												value={series.find(serie => serie.survey_id == surveyId)?.x ?? ''}
												onChange={e => this.handleChangeX(e.target.value, surveyId)}
												sourceId={surveyId}
												includeAllandTags
												addXisSameAsYOption
											/>
										</ListItem>
									);
								})}

								{Object.keys(surveysInSeries).length > 1 && (
									<ListItem>
										<Alert severity="info">
											{t`You're using multiple datasources inside your chart. When selecting a field to group your data to, a matching datafield from the other datasources will automatically be selected.`}
										</Alert>
									</ListItem>
								)}

								{this.isDateField(series[0]?.x, surveysInSeries) && (
									<Fragment>
										<ListItemWithRightContent
											primary={t`Group data by`}
											action={
												<EditorListRadioGroup
													size="small"
													value={get(series, '[0].date_group', '')}
													onChange={(e, value) => {
														const update = {
															date_group: value,
														};
														this.handleChangeAll({ obj: update, custom: update });
													}}
													options={datePeriodOptions.map(period => {
														period.icon = (
															<Badge
																classes={{ badge: classes.greyBadge }}
																badgeContent={period.days}
															>
																<CalendarToday />
															</Badge>
														);
														period.checkedIcon = (
															<Badge
																color="secondary"
																badgeContent={period.days}
															>
																<CalendarToday />
															</Badge>
														);
														return period;
													})}
												/>
											}
										/>

										<SeriesListItem
											label={t('Date formatting')}
											value={get(series, '[0].custom.date_format', '')}
											onChange={e =>
												this.handleChangeAll({ custom: { date_format: e.target.value } })
											}
										>
											{dateFormatOptions.map(option => (
												<MenuItem
													key={'dateformat-' + option.value}
													value={String(option.value)}
												>
													{t(option.label)}
												</MenuItem>
											))}
										</SeriesListItem>
									</Fragment>
								)}
							</List>
						</Card>
					</Fragment>
				)}
				<Typography
					variant="subtitle2"
					className={classes.titleSpacing}
				>
					{t('Data options')}
				</Typography>
				<Card className={classes.seriesBlock}>
					<List>
						<SeriesListItem
							label={t('Round values to')}
							value={get(series, '[0].custom.decimal', 0)}
							onChange={e =>
								this.handleChangeAll({ custom: { decimal: e.target.value } })
							}
						>
							{decimalOptions.map(option => (
								<MenuItem
									key={'decimal-' + option.label}
									value={String(option.value)}
								>
									{t(option.label)}
								</MenuItem>
							))}
						</SeriesListItem>

						<SeriesListItem
							label={t('Maximum results')}
							value={get(series, '[0].custom.limit') || ''}
							onChange={e => this.handleChangeAll({ custom: { limit: e.target.value } })}
						>
							{limitOptions.map(option => (
								<MenuItem
									key={'limit-' + option.label}
									value={String(option.value)}
								>
									{t(option.label)}
								</MenuItem>
							))}
						</SeriesListItem>

						<SeriesListItem
							label={t('Sort results')}
							value={get(series, '[0].custom.order') || ''}
							onChange={e => this.handleChangeAll({ custom: { order: e.target.value } })}
						>
							{sortDataOptions.map(option => (
								<MenuItem
									key={'sort-' + option.label}
									value={String(option.value)}
								>
									{t(option.label)}
								</MenuItem>
							))}
						</SeriesListItem>
					</List>
				</Card>
			</div>
		);
	}
}

export default withAppContext(
	withStyles(styles)(withDataSources(withSnackbar(withTranslation()(ChartEditorSeries))))
);
