import React from 'react';

import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

export default React.forwardRef(function ExpansionPanelSummary(props, ref) {
	return (
		<MuiExpansionPanelSummary
			{...props}
			ref={ref}
		/>
	);
});
