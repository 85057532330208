import React from 'react';

import UserAvatar from './UserAvatar';
import { AvatarGroup } from '../Layout';

export default function UserAvatarGroup({
	stack = [],
	className,
	children,
	size,
	max = 4,
	...props
}) {
	return (
		<AvatarGroup max={4}>
			{stack.map((avatar, i) => {
				const AvatarProps = {
					...avatar,
					...props,
					size,
					key: avatar.id || i,
				};

				return <UserAvatar {...AvatarProps} />;
			})}

			{children}
		</AvatarGroup>
	);
}
