import React, { Fragment, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';

import { ValidatedField } from '../Validation';
import { useEFM } from '../Ajax';
import { Loader, Typography } from '../Layout';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';

export default function AsanaFlow({
	clicked,
	apiKey,
	refreshToken,
	workspaceId = '',
	projectId = '',
	setRefreshToken = () => {},
	setWorkspace = () => {},
	setProject = () => {},
}) {
	const { t } = useTranslation();
	const [auth, loadingAuth] = useEFM(
		refreshToken ? '/integrations/ajax/refresh-asana' : '/integrations/ajax/auth-asana',
		{ key: refreshToken ? refreshToken : apiKey }
	);

	useEffect(() => {
		if (auth.refresh_token) {
			setRefreshToken(auth.refresh_token);
		}
	}, [auth]);

	const [workspaceData, loadingWorkspaces] = useEFM('/integrations/ajax/request-asana', {
		key: auth.access_token,
		resource: 'users/me',
	});

	const [projectData, loadingProjects] = useEFM('/integrations/ajax/request-asana', {
		key: auth.access_token,
		resource: 'projects',
		qs: `?${new URLSearchParams({
			opt_fields: 'id,name',
			limit: 99,
			workspace: workspaceId,
		}).toString()}`,
	});

	return (
		<Fragment>
			<Typography variant="subtitle">{t`Select board endpoints`}</Typography>
			<Typography
				color="textSecondary"
				gutterBottom
			>
				{t`Select the workspace and board to which the feedback will be sent`}
			</Typography>
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					xs={6}
				>
					<ValidatedField
						label={t`Asana workspace`}
						value={workspaceId}
						select
						fullWidth
						onChange={e => {
							const workspace = workspaceData.data.workspaces.find(ws => {
								return ws.gid === e.target.value;
							});
							setWorkspace(e.target.value, workspace.name);
						}}
						SelectProps={{
							...(loadingWorkspaces && {
								renderValue: () => (
									<Box
										display="flex"
										justifyContent="flex-end"
									>
										<Loader
											circular
											size={16}
										/>
									</Box>
								),
							}),
						}}
						errorMessage={t`Select a workspace`}
						rules={[['required', true]]}
						showErrors={clicked}
					>
						{get(workspaceData, 'data.workspaces', []).map(ws => {
							return <MenuItem value={ws.gid}>{ws.name}</MenuItem>;
						})}
					</ValidatedField>
				</Grid>
				<Grid
					item
					xs={6}
				>
					<ValidatedField
						label={t`Asana board`}
						value={projectId}
						select
						fullWidth
						onChange={e => {
							const project = projectData.data.find(proj => {
								return proj.gid === e.target.value;
							});
							setProject(e.target.value, project.name);
						}}
						SelectProps={{
							...(loadingProjects && {
								renderValue: () => (
									<Box
										display="flex"
										justifyContent="flex-end"
									>
										<Loader
											circular
											size={16}
										/>
									</Box>
								),
							}),
						}}
						errorMessage={t`Select a board`}
						rules={[['required', true]]}
						showErrors={clicked}
					>
						{(Array.isArray(projectData.data) ? projectData.data : []).map(proj => {
							return <MenuItem value={proj.gid}>{proj.name}</MenuItem>;
						})}
					</ValidatedField>
				</Grid>
			</Grid>
		</Fragment>
	);
}
