import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';

import { Trans, useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';

import {
	Card,
	CardContent,
	Grid,
	CopyButton,
	Typography,
	ActionButton,
	Link,
} from '../Layout';
import { makeDeploymentCode } from './deploymentUtils';
import DeploymentInstructions from './DeploymentInstructions';
import { useAppContext } from '../AppContext';
import { AllowedDomainsDialog, ProductIcon, UpgradeDialog } from '../Billing';
import { useIsAdminOwner } from '@/components/Permission';

const useStyles = makeStyles(theme => ({
	root: {
		'& > div:not(:last-child)': {
			marginBottom: theme.spacing(2),
		},
	},
	mono: {
		fontFamily: '"Ubuntu Mono", monospace',
		color: theme.palette.text.secondary,
	},
	spaceTop: {
		marginTop: theme.spacing(2),
	},
	appStoreImg: {
		display: 'block',
		width: 118,
		marginLeft: 8,
	},
	playImg: {
		width: 135,
	},
	codeWrap: {
		maxHeight: 240,
		overflowY: 'auto',
	},
	flexer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		justifyContent: 'space-between',
		height: '100%',
	},
	codeColor: {
		color: theme.palette.text.secondary,
	},
	collapse: {
		position: 'relative',
	},
	fader: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
		height: 32,
		backgroundImage:
			'linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0.75) 35%,rgba(255,255,255,0) 100%)',
	},
	expandMoreLessButton: {
		color: theme.palette.text.secondary,
	},
	addDomainsLink: {
		marginTop: theme.spacing(1),
	},
	domainListItemIcon: {
		minWidth: 32,
	},
	help: {
		marginTop: theme.spacing(2),
	},
}));

export default function DeploymentCode({ variant = 'web', id }) {
	const [showDeploymentSnippet, setShowDeploymentSnippet] = useState(false);
	const [showAllowedDomainsDialog, setShowAllowedDomainsDialog] = useState(false);
	const [openUpgrade, setOpenUpgrade] = useState(false);
	const { t } = useTranslation();
	const classes = useStyles();
	const { app } = useAppContext();
	const isAdminOwner = useIsAdminOwner();

	const domains =
		app.package.allowed_domains?.filter(
			domain =>
				domain.value &&
				((variant === 'sdk' && domain.type === 'app_apps') ||
					(variant === 'web' && domain.type === 'web_domains'))
		) ?? [];

	return (
		<>
			<div className={classes.root}>
				<Card
					variant="outlined"
					data-onboarding={variant === 'web' ? 'deploy-web' : 'deploy-app'}
				>
					<CardContent>
						<Grid container>
							<Grid
								item
								xs
							>
								<Typography
									variant="subtitle"
									gutterBottom
								>
									{t(`deployment_code-integration-title_${variant}`)}
								</Typography>
								<Typography
									color="textSecondary"
									font="mono"
								>
									{id}
								</Typography>
							</Grid>
							<Grid item>
								<CopyButton value={id} />
							</Grid>
						</Grid>
					</CardContent>
				</Card>

				{variant === 'web' && (
					<Card variant="outlined">
						<CardContent>
							<Grid
								container
								wrap="nowrap"
							>
								<Grid
									item
									xs
								>
									<Typography
										variant="subtitle"
										gutterBottom
									>
										{t(`deployment_code-html-title_${variant}`)}
									</Typography>
									<Collapse
										collapsedHeight={32}
										in={showDeploymentSnippet}
										className={classes.collapse}
									>
										<Typography
											color="textSecondary"
											font="mono"
											wordBreak="break-word"
										>
											{makeDeploymentCode(id)}
										</Typography>
										{!showDeploymentSnippet && <div className={classes.fader} />}
									</Collapse>

									<ActionButton
										action={showDeploymentSnippet ? 'expand_less' : 'expand_more'}
										onClick={() => setShowDeploymentSnippet(prev => !prev)}
										disableTextTransform
										className={classes.expandMoreLessButton}
									>
										{showDeploymentSnippet
											? t('deployment_code-html-hide')
											: t('deployment_code-html-show')}
									</ActionButton>
								</Grid>
								<Grid item>
									<CopyButton
										value={makeDeploymentCode(id)}
										dataTrackEvent={
											variant === 'web'
												? 'deployment_web_integration_id_copied'
												: 'deployment_app_integration_id_copied'
										}
									/>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				)}

				{variant === 'sdk' && (
					<Card variant="outlined">
						<CardContent>
							<Typography
								variant="subtitle2"
								gutterBottom
							>{t`Scan the QR code to test your deployment in the Mopinion App`}</Typography>
							<Grid
								container
								spacing={2}
								data-onboarding="deploy-app-test"
							>
								<Grid item>
									<QRCode
										value={id}
										size={100}
									/>
								</Grid>
								<Grid item>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://itunes.apple.com/us/app/mopinion-forms/id1376756796?mt=8"
									>
										<img
											alt="Get it on Apple app store"
											src="/assets/img/r/Download_on_the_App_Store_Badge.svg"
											className={classes.appStoreImg}
										/>
									</a>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://play.google.com/store/apps/details?id=com.mopinion.mopinion&hl=nl"
									>
										<img
											alt="Get it on Google Play"
											src="https://play.google.com/intl/en_gb/badges/images/generic/en_badge_web_generic.png"
											className={classes.playImg}
										/>
									</a>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				)}

				{!app.package.trial && app.package.tiered_pricing && (
					<>
						<div>
							<Typography
								variant="subtitle"
								gutterBottom
							>
								{t`deployment_code-domains-title`}
							</Typography>
							{domains.length !== 0 && <AllowedDomains domains={domains} />}
							{domains.length === 0 && (
								<Typography>
									<Trans
										i18nKey={`deployment_code-domains-no_domains_${variant}`}
										components={{
											addDomainLink: (
												<ActionButton
													onClick={() => setShowAllowedDomainsDialog(true)}
													color="primary"
													action="add_list"
													variant="text"
													disabled={!isAdminOwner}
													tooltip={
														!isAdminOwner
															? t`data_collection-deployment_code_dialog-owner_only_tooltip`
															: null
													}
													disableTextTransform
												/>
											),
										}}
									/>
								</Typography>
							)}
							{domains.length !== 0 && (
								<Typography className={classes.addDomainsLink}>
									<Trans
										i18nKey={`deployment_code-domains-add_${variant}`}
										components={{
											addDomainLink: (
												<ActionButton
													action="add_list"
													color="primary"
													variant="text"
													onClick={() => setShowAllowedDomainsDialog(true)}
													disabled={!isAdminOwner}
													tooltip={
														!isAdminOwner
															? t`data_collection-deployment_code_dialog-owner_only_tooltip`
															: null
													}
													disableTransform
												/>
											),
										}}
									/>
								</Typography>
							)}
						</div>
						<Divider />
					</>
				)}

				{variant === 'web' && (
					<div className={classes.help}>
						<DeploymentInstructions
							helpItems={[
								{
									label: t`Deploy Mopinion with Google Tagmanager`,
									href: 'https://mopinion.atlassian.net/wiki/spaces/KB/pages/503873804/How+to+install+Mopinion+with+Google+Tag+Manager',
									trackEvent: 'deployment_knowledgebase_google_tag_manager',
								},
								{
									label: t`Deploy Mopinion using the JavaScript snippet`,
									href: 'https://mopinion.atlassian.net/wiki/spaces/KB/pages/503873575/How+do+I+implement+the+Mopinion+script',
									trackEvent: 'deployment_knowledgebase_javascript',
								},
							]}
							data-onboarding="deploy-help-web"
							dataTrackEvent=""
						/>
					</div>
				)}

				{variant === 'sdk' && (
					<div className={classes.help}>
						<DeploymentInstructions
							helpItems={[
								{
									label: t`Mopinion iOS (Native Swift)`,
									href: 'https://github.com/Mopinion-com/mopinion-sdk-ios',
									trackEvent: 'deployment_sdk_ios',
								},
								{
									label: t`Mopinion iOS Webview SDK`,
									href: 'https://github.com/mopinion/mopinion-sdk-ios-web',
									trackEvent: 'deployment_sdk_lightweight_ios',
								},
								{
									label: t`Mopinion Android SDK (Native Kotlin)`,
									href: 'https://github.com/Mopinion-com/mopinion-sdk-android',
									trackEvent: 'deployment_sdk_android',
								},
								{
									label: t`Mopinion Android Webview SDK`,
									href: 'https://github.com/Mopinion-com/mopinion-sdk-android-web',
									trackEvent: 'deployment_sdk_lightweight_android',
								},
								{
									label: t`Mopinion React Native SDK`,
									href: 'https://www.npmjs.com/package/mopinion-react-native-sdk',
									trackEvent: 'deployment_sdk_native',
								},
								{
									label: t`Mopinion Flutter Plugin`,
									href: 'https://pub.dev/packages/mopinion_flutter_integration_plugin',
									trackEvent: 'deployment_flutter_plugin',
								},
								{
									label: t`Mopinion Cordova SDK`,
									href: 'https://github.com/mopinion/mopinionsdk-cordova-plugin',
									trackEvent: 'deployment_sdk_cordova',
								},
							]}
							data-onboarding="deploy-help-app"
						/>
					</div>
				)}
			</div>

			<AllowedDomainsDialog
				open={showAllowedDomainsDialog}
				onClose={() => setShowAllowedDomainsDialog(false)}
				product={variant === 'web' ? 'web_domains' : 'app_apps'}
			/>

			<UpgradeDialog
				open={openUpgrade}
				onClose={() => setOpenUpgrade(false)}
			/>
		</>
	);
}
function splitArray(arr) {
	const mid = Math.ceil(arr.length / 2);
	const firstHalf = arr.slice(0, mid);
	const secondHalf = arr.slice(mid);
	return [firstHalf, secondHalf];
}

function AllowedDomains({ domains = [] }) {
	const [firstHalf, secondHalf] = splitArray(domains);
	return (
		<>
			{domains.length <= 4 && <DomainsList domains={domains} />}
			{domains.length >= 5 && (
				<>
					<Grid container>
						<Grid
							item
							sm={6}
							xs={12}
						>
							<DomainsList domains={firstHalf} />
						</Grid>
						<Grid
							item
							sm={6}
							xs={12}
						>
							<DomainsList domains={secondHalf} />
						</Grid>
					</Grid>
				</>
			)}
		</>
	);
}

function DomainsList({ domains }) {
	const classes = useStyles();

	return (
		<List
			dense
			disablePadding
		>
			{domains.map(domain => (
				<ListItem
					key={domain}
					disableGutters
				>
					<ListItemIcon className={classes.domainListItemIcon}>
						<ProductIcon product={domain.type} />
					</ListItemIcon>
					<ListItemText primary={domain.value} />
				</ListItem>
			))}
		</List>
	);
}
