import React, { useState } from 'react';

import { useTranslation, Trans } from 'react-i18next';

import {
	SimpleDialog,
	Typography,
	ActionButton,
	InputAdornment,
	Alert,
	Box,
} from '@/components/Layout';
import { ValidatedField } from '@/components/Validation';

export default function DeploymentAssistantDialog({ open, onClose, token, name }) {
	const { t } = useTranslation();
	const [url, setUrl] = useState('https://');
	const [valid, setValid] = useState(false);

	const connectUrl = `${url}?mopinion-assistant=${token}`;

	const handleConnect = () => {
		window.open(connectUrl);
	};

	return (
		<SimpleDialog
			open={open}
			onClose={onClose}
			title={t(`data_collection-deployment_assistant_dialog-title`, { name })}
			cancel={t('data_collection-deployment_assistant_dialog-close')}
		>
			<Typography mb={3}>
				<Trans
					i18nKey="data_collection-deployment_assistant_dialog-text"
					components={{
						strong: (
							<Box
								display="inline"
								fontWeight="fontWeightMedium"
							/>
						),
					}}
					values={{
						name,
					}}
				/>
			</Typography>
			<ValidatedField
				autoFocus
				variant="outlined"
				label={t`data_collection-deployment_assistant_dialog-textfield_label`}
				fullWidth
				value={url}
				onChange={e => setUrl(e.target.value)}
				onKeyDown={e => {
					if (e.key === 'Enter') {
						handleConnect();
					}
				}}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<ActionButton
								variant="contained"
								action="new_window"
								onClick={handleConnect}
								disabled={!valid}
								dataTrackEvent="deployment_assistant_dialog_lets_test_button"
								data-track-event-props={JSON.stringify({
									connectUrl: url,
								})}
							>
								{t`data_collection-deployment_assistant_dialog-connect_button`}
							</ActionButton>
						</InputAdornment>
					),
				}}
				showErrorsAfterBlur
				rules={{
					url: true,
				}}
				messages={{
					url: t`data_collection-deployment_assistant_dialog-valid_url_message`,
				}}
				onValidChange={valid => setValid(valid)}
			/>
			<Alert
				title={t`data_collection-deployment_assistant_dialog-alert-title`}
				variant="default"
			>
				{t`data_collection-deployment_assistant_dialog-alert-content`}
			</Alert>
		</SimpleDialog>
	);
}
