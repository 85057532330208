import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Autocomplete, Box, Chip, Typography } from '../Layout';
import { useAppContext } from '../AppContext';
import UserAvatar from './UserAvatar';

function getName({ firstname = '', lastname = '' } = {}) {
	return `${firstname} ${lastname}`;
}

export default function UsersAutocomplete({
	label,
	value,
	multiple,
	getByProp = 'uuid',
	exclude = [],
	...props
}) {
	const { app } = useAppContext();
	const { t } = useTranslation();

	const selectedUsers = multiple
		? value.map(value => app.api.getUserByProp({ prop: getByProp, value }))
		: app.api.getUserByProp({ prop: getByProp, value });

	const options = useMemo(() => {
		return app.users.asArray.filter(user => exclude.indexOf(user.uuid) === -1);
	}, [exclude, app.users.asArray]);

	return (
		<Autocomplete
			value={selectedUsers}
			options={options}
			multiple={multiple}
			getOptionLabel={option => getName(option)}
			{...(multiple && {
				renderTags: (tagValue, getTagProps) => {
					return tagValue.map((option, index) => (
						<Chip
							avatar={<UserAvatar uuid={option.uuid} />}
							label={getName(option)}
							{...getTagProps({ index })}
						/>
					));
				},
			})}
			renderOption={option => (
				<Box
					display="flex"
					alignItems="center"
				>
					<UserAvatar uuid={option.uuid} />
					<Box
						display="inline-block"
						ml={1}
					>
						<Typography display="block">{getName(option)}</Typography>
						<Typography
							display="block"
							variant="body2"
							color="textSecondary"
						>
							{option.email}
						</Typography>
					</Box>
				</Box>
			)}
			label={label ?? t`settings-users_autocomplete-label`}
			{...props}
		/>
	);
}
