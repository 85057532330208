import React from 'react';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useAjaxForm } from '@/components/Ajax';
import { getStatusMessage } from './actionUtils';

export default function useUpdateActionStatus({
	status_id,
	id,
	loadData = () => {},
	...props
}) {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	return useAjaxForm({
		url: `/actions/ajax/update-status`,
		data: {
			id,
			status_id,
		},
		onSuccess: r => {
			enqueueSnackbar(getStatusMessage(status_id));
			loadData();
		},
		...props,
	});
}
