import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ButtonFloatBottomLeftIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<path
				id="XMLID_4_"
				d="M1,40v-4H0v4H1z M8,40v-1H4v1H8z M16,40v-1h-4v1H16z M24,40v-1h-4v1H24z M32,40v-1h-4v1H32z M40,40v-1h-4v1
				H40z M40,36v-4h-1v4H40z M1,32v-4H0v4H1z M39.998,27.83v-4h-1v4H39.998z M1,24v-4H0v4H1z M39.998,19.83v-4h-1v4H39.998z M1,16v-4H0
				v4H1z M40,12V8h-1v4H40z M6.689,29.604c-2.037,0-3.688,1.65-3.689,3.688c0,0.001,0,0.002,0,0.002
				c0.002,2.037,1.653,3.688,3.689,3.688c2.037-0.001,3.688-1.651,3.688-3.688C10.378,31.257,8.727,29.605,6.689,29.604z M1,8V4H0v4H1z
				 M40,4V0h-1v4H40z M4,1V0H0v1H4z M12,1V0H8v1H12z M20,1V0h-4v1H20z M28,1V0h-4v1H28z M36,1V0h-4v1H36z"
			/>
		</SvgIcon>
	);
}
