import React, { Fragment, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { useSnackbar } from 'notistack';

import { useAppContext } from '../AppContext';
import {
	Grid,
	Card,
	CardContent,
	CardHeader,
	Typography,
	Loader,
	Alert,
	ActionButton,
	SimpleDialog,
} from '../Layout';
import { useEFM, useAjaxForm } from '../Ajax';
import { useTranslation } from 'react-i18next';
import CCIcon from './CCIcon';
import Stripe from './Stripe';
import CreditCardForm from './CreditCardForm';
import { useHasPermission, NoPermissionTooltip } from '../Permission';

const useStyles = makeStyles(theme => ({}));

export default function CreditCardCard() {
	const { t } = useTranslation();
	const { app } = useAppContext();
	const classes = useStyles();
	const [changeCard, setChangeCard] = useState(false);
	const [cardToken, setCardToken] = useState('');
	const [{ card = {}, stripe_public, is_legacy }, manageDataLoading, error, loadData] =
		useEFM('/account/billing/card');
	const { enqueueSnackbar } = useSnackbar();
	const hasEditPermission = useHasPermission();

	const { postForm: postUpdateCardForm, loading: postUpdateCardFormLoading } =
		useAjaxForm({
			url: '/account/billing/update-card',
			data: {
				stripeToken: cardToken,
			},
			onSuccess: () => {
				loadData();
				enqueueSnackbar(t`Card information updated`);
				setChangeCard(false);
			},
		});

	return (
		<Fragment>
			<Card>
				<CardHeader
					title={t`Card information`}
					titleTypographyProps={{
						variant: 'h6',
					}}
					action={
						hasEditPermission && (
							<ActionButton
								color="primary"
								onClick={() => setChangeCard(true)}
							>
								{t`Change card`}
							</ActionButton>
						)
					}
				/>

				{manageDataLoading && (
					<CardContent>
						<Loader circular />
					</CardContent>
				)}
				{!manageDataLoading && (
					<Fragment>
						<CardContent>
							{is_legacy && (
								<Alert variant="default">
									{t`Sorry! Your payment history in unavailable. Please contact support@mopinion.com`}
								</Alert>
							)}
							{!is_legacy && (
								<Fragment>
									<Grid
										container
										alignItems="center"
										spacing={2}
									>
										<Grid item>
											<CCIcon
												width={48}
												brand={card?.brand}
											/>
										</Grid>

										<Grid
											item
											xs
										>
											<Typography variant="subtitle">{card?.name}</Typography>
											<Box
												display="flex"
												justifyContent="space-between"
											>
												<Typography
													variant="body1"
													color="textSecondary"
												>
													•••• •••• •••• {card?.last4}
												</Typography>

												<Typography>
													{card?.exp_month} / {card?.exp_year}
												</Typography>
											</Box>
										</Grid>
									</Grid>
								</Fragment>
							)}
						</CardContent>
					</Fragment>
				)}
			</Card>

			<SimpleDialog
				open={changeCard}
				onClose={() => setChangeCard(false)}
				title={t`Update card information`}
				loading={postUpdateCardFormLoading}
				disabled={!cardToken}
				submit={t`Update card`}
				onSubmit={postUpdateCardForm}
			>
				<Stripe>
					<CreditCardForm onCardChange={({ token }) => setCardToken(token)} />
				</Stripe>
			</SimpleDialog>
		</Fragment>
	);
}
