import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';
import { SimpleDialog, Grid } from '@/components/Layout';
import { useAjaxForm } from '@/components/Ajax';
import { useIsInPermissionGroup } from '@/components/Permission';
import DeploymentAccess from './DeploymentAccess';

export default function DeploymentAccessDialog({
	open,
	onClose = () => {},
	id,
	name,
	permissionGroups: permissionGroupsFromProps = [],
}) {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const [permissionGroups, setPermissionGroups] = useState([
		...permissionGroupsFromProps,
	]);
	const hasAccessPermission = useIsInPermissionGroup(permissionGroups);

	const didChange = () => {
		if (permissionGroupsFromProps.length !== permissionGroups.length) {
			return true;
		}
		if (permissionGroups.some(uuid => !permissionGroupsFromProps.includes(uuid))) {
			return true;
		}
		return false;
	};

	const { postForm, clicked, loading } = useAjaxForm({
		url: `/api/1/pastease/${app.domain}/${app.organisations.current.org_id}/${id}`,
		type: 'mjolnir',
		data: {
			name,
			permission_groups: permissionGroups,
		},
		onSuccess: response => {
			onClose();
		},
	});

	return (
		<SimpleDialog
			title={t`data_collection-deployment_access_dialog-title`}
			open={open}
			onClose={onClose}
			dataTrackEvent="deployment_access_dialog-update_access"
			dataTestElement="update-access-deployment-submit"
			submit={t`data_collection-deployment_access_dialog-submit`}
			loading={loading}
			onSubmit={postForm}
			disabled={!didChange() || !hasAccessPermission}
		>
			<Grid
				container
				direction="column"
				spacing={2}
			>
				<Grid
					item
					xs
				>
					<DeploymentAccess
						initialPermissionGroups={permissionGroupsFromProps}
						permissionGroups={permissionGroups}
						onChange={groups => setPermissionGroups(groups)}
						hasAccessPermission={useIsInPermissionGroup}
					/>
				</Grid>
			</Grid>
		</SimpleDialog>
	);
}
