import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	loader: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));

const ButtonWithLoader = forwardRef(function ButtonWithLoader(
	{
		loading,
		disabled,
		children,
		onClick,
		fab,
		component,
		ButtonProps = {},
		dataTrackEvent,
		buttonAsLink,
		...props
	},
	ref
) {
	const classes = useStyles();
	const ButtonPropsExtended = {
		...props,
		...(buttonAsLink && { component: Link }),
		...(dataTrackEvent && { 'data-track-event': dataTrackEvent }),
		disabled: disabled || loading,
		onClick: e => {
			if (!loading) {
				onClick(e);
			}
		},
		...ButtonProps,
		ref: ref,
	};
	const ButtonComponent = fab ? Fab : component ? component : Button;

	return (
		<ButtonComponent {...ButtonPropsExtended}>
			{loading && (
				<CircularProgress
					size={24}
					className={classes.loader}
				/>
			)}
			{children}
		</ButtonComponent>
	);
});

export default ButtonWithLoader;
