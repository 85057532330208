import React, { useRef, useState, useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';

import { CSSTransition, Transition } from 'react-transition-group';

import { useTimeout } from '../../hooks';
const confettiColors = ['#7d32f5', '#f6e434', '#63fdf1', '#e672da', '#295dfe', '#6e57ff'];
const useStyles = makeStyles(theme => ({
	wrap: {
		position: 'relative',
	},
	confettiDot: props => ({
		position: 'absolute',
		display: 'block',
		width: 4,
		height: 4,
		top: '50%',
		left: '50%',
		margin: '-2px 0 0 -2px',
		backgroundColor: props.color,
		transform: `translate(0,0)`,
		opacity: 0,
		transition: 'all 1s',
		zIndex: 1500,
	}),
	animate: {
		transform: props => `translate(${props.x}px, ${props.y}px) scale(${props.scale})`,
		animation: '$opacity 1.5s forwards',
	},
	'@keyframes opacity': {
		'50%': {
			opacity: 1,
		},
		opacity: {
			opacity: 0,
		},
	},
}));

function random(min, max, round = true) {
	const rnd = Math.random() * (max - min + 1) + min;
	return round ? Math.floor(rnd) : rnd;
}

function Dot() {
	const [anim, setAnim] = useState('');

	const { x, y, scale } = useMemo(() => {
		const x = random(-30, 480);
		const y = random(-360, 30);
		const scale = random(0.6, 1, false);

		return { x, y, scale };
	}, []);

	const classes = useStyles({
		color: confettiColors[random(0, 5)],
		x,
		y,
		scale,
	});

	useEffect(() => {
		setAnim(classes.animate);
	}, [classes.animate, setAnim]);

	return <i className={`${classes.confettiDot} ${anim}`} />;
}

export default function Confetti({ amount = 120 }) {
	const classes = useStyles();
	return (
		<div className={classes.wrap}>
			{[...Array(amount)].map((conf, i) => (
				<Dot key={'conf' + i} />
			))}
		</div>
	);
}
