import React from 'react';

import ListSubheader from '@material-ui/core/ListSubheader';
import Select from '@material-ui/core/Select';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@/styles';
import { useAppContext } from '@/components/AppContext';
import useDashboard from './useDashboard';
import { MenuItem, Typography } from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	subheader: {
		backgroundColor: '#fff',
		textAlign: 'left',
	},
	buttonMore: {
		marginLeft: theme.spacing(1),
	},
	title: {
		fontSize: '1.1607142857142858rem',
		fontWeight: 600,
	},
	cursor: {
		cursor: 'pointer',
	},
}));

export default function DashboardQuickSelection({ readOnly }) {
	const { t } = useTranslation();
	const classes = useStyles();
	const history = useHistory();
	const { app } = useAppContext();
	const { enqueueSnackbar } = useSnackbar();
	const { dashboards, currentDashboard } = useDashboard();

	return (
		<>
			{readOnly && (
				<Typography
					variant="h6"
					display="inline"
					component="div"
					className={classes.cursor}
					onClick={() => {
						history.push(`/reporting/dashboard/${currentDashboard.id}`);
					}}
				>
					{currentDashboard?.name
						? currentDashboard.name
						: t('reporting-dashboard-dashboard_quick_selection-readonly', {
								name: app.projects.current.name,
						  })}
				</Typography>
			)}
			{!readOnly && (
				<Select
					value={currentDashboard?.id}
					className={classes.title}
					data-test-element="dashboard-quick-select"
				>
					<ListSubheader
						className={classes.subheader}
					>{t`reporting-dashboard-dashboard_quick_selection-subheader`}</ListSubheader>
					{dashboards.map((dashboard, index) => {
						return (
							<MenuItem
								data-test-element="dashboard-quick-select-item"
								key={index}
								value={dashboard.id}
								onClick={() => {
									if (dashboard.id !== currentDashboard.id) {
										history.push(`/reporting/dashboard/${dashboard.id}`);

										enqueueSnackbar(
											t(
												'reporting-dashboard-dashboard_quick_selection-snackbar_switched',
												{
													dashboard: dashboard.name
														? dashboard.name
														: t(
																'reporting-dashboard-dashboard_quick_selection-dashboard',
																{ name: app.projects.current.name }
														  ),
												}
											)
										);
									}
								}}
							>
								{dashboard.name
									? dashboard.name
									: t('reporting-dashboard-dashboard_quick_selection-dashboard', {
											name: app.projects.current.name,
									  })}
							</MenuItem>
						);
					})}
				</Select>
			)}
		</>
	);
}
