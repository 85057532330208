import React, { useState } from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { useTranslation } from 'react-i18next';
import { useContainerQuery } from 'react-container-query';

import { makeStyles } from '@/styles';
import { useTextAnalyticsContext } from './TextAnalyticsContext';
import TextAnalyticsFeedbackQuickViewDrawer from './TextAnalyticsFeedbackQuickViewDrawer';
import { Loader, EmptyState, Typography, Tooltip, Box } from '@/components/Layout';
import { getPerc } from './textAnalyticsUtils';
import { ContextBubble } from '@/components/Feedback';

const useStyles = makeStyles(theme => ({
	row: {
		cursor: 'pointer',
	},
	progress: {
		height: 16,
		borderRadius: theme.shape.borderRadius,
	},
	smallTd: {
		width: 120,
	},
	mediumTd: {
		width: 200,
	},
	rankingChip: {
		backgroundColor: theme.palette.mopinion.main,
		color: '#fff',
	},
	progressLabel: {
		fontWeight: theme.typography.fontWeightMedium,
	},
	tooltip: {
		position: 'absolute',
		top: theme.spacing(2),
		right: theme.spacing(2),
		cursor: 'pointer',
	},
	relative: {
		position: 'relative',
	},
}));

const containerQuery = {
	small: {
		maxWidth: 800,
	},
};

export default function TextAnalyticsTopListCard({ title, type }) {
	const classes = useStyles();
	const [selected, setSelected] = useState({ word: '', cluster_id: '' });
	const {
		textAnalytics,
		textAnalytics: { N },
	} = useTextAnalyticsContext();
	const [params, containerRef] = useContainerQuery(containerQuery);

	const { data = {}, loading } = textAnalytics[type] || {};
	const { t } = useTranslation();
	return (
		<>
			<Card className={classes.relative}>
				{loading && <Loader />}
				{['pos', 'neg'].includes(type) && (
					<div className={classes.tooltip}>
						<Tooltip
							title={
								type === 'pos'
									? t`reporting-text_analytics-card-tooltip_pos`
									: type === 'neg'
									? t`reporting-text_analytics-card-tooltip_neg`
									: ''
							}
						>
							<HelpOutlineIcon
								fontSize="small"
								color="action"
							/>
						</Tooltip>
					</div>
				)}
				<CardHeader
					titleTypographyProps={{ variant: 'h6' }}
					title={title}
				/>
				<div ref={containerRef}>
					{(!data.list || data.list?.length === 0) && (
						<Box
							p={2}
							pb={3}
						>
							<EmptyState secondary={t`reporting-text_analytics-no_data`} />
						</Box>
					)}
					{data.list?.length > 0 && (
						<Table className={classes.table}>
							<TableHead>
								<TableRow>
									<TableCell></TableCell>
									<TableCell align="right">{t`reporting-text_analytics-card-volume`}</TableCell>
									<TableCell
										className={params.small ? classes.smallTd : classes.mediumTd}
										align="right"
									>{t`Sentiment`}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data.list.map(row => {
									return (
										<TableRow
											className={classes.row}
											key={row.word}
											onClick={() =>
												setSelected({ word: row.word, cluster_id: row.cluster_id })
											}
											hover
										>
											<TableCell
												component="th"
												scope="row"
											>
												{row.word}
											</TableCell>

											<TableCell align="right">
												<Box
													textAlign="left"
													position="relative"
												>
													<Typography
														variant="caption"
														className={classes.progressLabel}
														fontWeight="bold"
														style={{ left: `${getPerc(row.n, N)}%` }}
													>
														{t('reporting-text_analytics-card-mentions', {
															count: row.n,
														})}
													</Typography>
													<Box width="100%">
														<LinearProgress
															className={classes.progress}
															variant="determinate"
															value={getPerc(row.n, N) > 100 ? 100 : getPerc(row.n, N)}
														/>
													</Box>
												</Box>
											</TableCell>

											<TableCell align="right">
												<ContextBubble
													MSI={Math.round(row.MSI)}
													showLabel
													label={t('reporting-text_analytics-card-sentiment_index', {
														msi: Math.round(row.MSI),
													})}
													size="large"
												>
													{Math.round(row.MSI)}
												</ContextBubble>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					)}
				</div>
			</Card>

			<TextAnalyticsFeedbackQuickViewDrawer
				requestPath="/dash/items"
				word={selected.word}
				cluster_id={selected.cluster_id}
				open={Boolean(selected.word)}
				onClose={() => setSelected({ word: '', cluster_id: '' })}
			/>
		</>
	);
}
