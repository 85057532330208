import React, { Component, Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Badge from '@material-ui/core/Badge';
import Checkbox from '@material-ui/core/Checkbox';

import { withSnackbar } from 'notistack';

import { EditorListRadioGroup, ListItemWithRightContent } from '../Layout';
import StackIcon from '@material-ui/icons/Layers';
import NoStackIcon from '@material-ui/icons/LayersClear';

import StackDatasourcesIcon from '@material-ui/icons/ViewModule';
import StackAllIcon from '@material-ui/icons/HorizontalSplit';

import { PercIcon } from '../Icons';

import { SelectShowEmpty } from '../Select';

import { parseLabelFormat } from './chartUtils';

import get from 'lodash.get';
import { Translation } from 'react-i18next';
//import set from 'lodash.set';

const styles = theme => ({
	wrapperDiv: {
		//height:'100%'
		flexGrow: 1,
		padding: 8,
	},
	optionsBlock: {
		//borderTop: '1px solid '+theme.palette.divider,
		//borderBottom: '1px solid '+theme.palette.divider,
		marginBottom: theme.spacing(3),
	},
	titleSpacing: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
});

const stackOptions = [
	{
		label: "Don't stack",
		value: '',
		icon: <NoStackIcon />,
		checkedIcon: <NoStackIcon />,
	},
	{ label: 'Stack', value: 'normal', icon: <StackIcon />, checkedIcon: <StackIcon /> },
	{
		label: 'Percentage',
		value: 'percent',
		icon: (
			<Badge badgeContent={<PercIcon style={{ width: 12 }} />}>
				<StackIcon />
			</Badge>
		),
		checkedIcon: (
			<Badge badgeContent={<PercIcon style={{ width: 12 }} />}>
				<StackIcon />
			</Badge>
		),
	},
];

const minMaxScaleOptions = [
	{ label: 'Auto', value: '' },
	{ label: '-100', value: '-100' },
	{ label: '-50', value: '-50' },
	{ label: '-10', value: '-10' },
	{ label: '0', value: '0' },
	{ label: '10', value: '10' },
	{ label: '50', value: '50' },
	{ label: '100', value: '100' },
];

const labelFormatOptions = [
	{ label: 'Show value', value: 'show_value' },
	{ label: 'Show X name and value', value: 'show_x_name' },
	{ label: 'Show series name and value', value: 'show_serie_name' },
	{ label: 'Show point name and value', value: 'show_point_name' },
	{ label: 'Show percentage', value: 'show_value_percent' },
	{ label: 'Show X name and percentage', value: 'show_x_name_percent' },
	{ label: 'Show series name and percentage', value: 'show_serie_name_percent' },
	{ label: 'Show point name and percentage', value: 'show_point_name_percent' },
];

class ChartEditorOptions extends Component {
	state = {};

	handleChange = (path, value, convertEmptyToNull, reload) => {
		this.props.onChange(path, value, convertEmptyToNull, reload);
	};

	componentDidCatch(error, info) {
		console.log(error, info);
	}
	componentDidUpdate(props) {
		// console.log('SI',props);
	}

	render() {
		const { options, classes, series } = this.props;

		const datasourcesInSeries = [...new Set(series.filter(serie => serie.survey_id))];
		const stackingValue = options?.plotOptions?.series?.stacking
			? String(options.plotOptions.series.stacking).replace('age', '')
			: '';
		const dataLabelFormatValue = parseLabelFormat(
			options?.plotOptions?.series?.dataLabels?.formatter ?? ''
		);

		return (
			<Translation>
				{t => (
					<div
						ref={this.div}
						className={classes.wrapperDiv}
					>
						<Typography
							variant="subtitle2"
							className={classes.titleSpacing}
						>
							{t('Chart')}
						</Typography>
						<Card className={classes.optionsBlock}>
							<List>
								<ListItem
									button
									onClick={e =>
										this.handleChange('legend.enabled', !get(options, 'legend.enabled'))
									}
								>
									<ListItemText primary={t('Show legend')} />
									<ListItemSecondaryAction>
										<Switch
											checked={Boolean(get(options, 'legend.enabled'))}
											onChange={e =>
												this.handleChange('legend.enabled', e.target.checked)
											}
											value="showLegend"
											color="secondary"
										/>
									</ListItemSecondaryAction>
								</ListItem>
								<ListItem
									button
									onClick={e =>
										this.handleChange(
											'plotOptions.series.colorByPoint',
											!get(options, 'plotOptions.series.colorByPoint')
										)
									}
								>
									<ListItemText primary={t('Color by point')} />
									<ListItemSecondaryAction>
										<Switch
											checked={Boolean(get(options, 'plotOptions.series.colorByPoint'))}
											onChange={e =>
												this.handleChange(
													'plotOptions.series.colorByPoint',
													e.target.checked
												)
											}
											value="colorByPoint"
											color="secondary"
										/>
									</ListItemSecondaryAction>
								</ListItem>
								<ListItemWithRightContent
									primary={t`Stack series`}
									action={
										<EditorListRadioGroup
											size="small"
											//.replace 'age' from 'percentage' values -> previous mistake
											value={stackingValue}
											onChange={(e, value) =>
												this.handleChange('plotOptions.series.stacking', value)
											}
											options={stackOptions}
										/>
									}
								/>
								{datasourcesInSeries.length > 1 &&
									options?.plotOptions?.series.stacking && (
										<ListItemWithRightContent
											primary={t`Stack groups`}
											action={
												<EditorListRadioGroup
													size="small"
													//.replace 'age' from 'percentage' values -> previous mistake
													value={options?.plotOptions?.series.customStacking ?? ''}
													onChange={(e, value) =>
														this.handleChange(
															'plotOptions.series.customStacking',
															value,
															false,
															true
														)
													}
													options={[
														{
															label: t`Stack datasources`,
															value: '',
															icon: <StackDatasourcesIcon />,
														},
														{
															label: t`Stack all`,
															value: 'stack_all',
															icon: <StackAllIcon />,
														},
													]}
												/>
											}
										/>
									)}
							</List>
						</Card>

						<Typography
							variant="subtitle2"
							className={classes.titleSpacing}
						>
							Axis
						</Typography>
						<Card className={classes.optionsBlock}>
							<List
							//dense
							//className={classes.optionsBlock}
							>
								<ListItem
									button
									onClick={e =>
										this.handleChange(
											'xAxis.labels.enabled',
											!get(options, 'xAxis.labels.enabled')
										)
									}
								>
									<ListItemText primary={t('Show X-axis label')} />
									<ListItemSecondaryAction>
										<Switch
											checked={Boolean(get(options, 'xAxis.labels.enabled', ''))}
											onChange={e =>
												this.handleChange('xAxis.labels.enabled', e.target.checked)
											}
											value="showXLabel"
											color="secondary"
										/>
									</ListItemSecondaryAction>
								</ListItem>
								<ListItem
									button
									onClick={e =>
										this.handleChange(
											'yAxis.labels.enabled',
											!get(options, 'yAxis.labels.enabled')
										)
									}
								>
									<ListItemText primary={t('Show Y-axis label')} />
									<ListItemSecondaryAction>
										<Switch
											checked={Boolean(get(options, 'yAxis.labels.enabled', ''))}
											onChange={e =>
												this.handleChange('yAxis.labels.enabled', e.target.checked)
											}
											value="showYLabel"
											color="secondary"
										/>
									</ListItemSecondaryAction>
								</ListItem>
								<ListItem>
									<Grid
										container
										spacing={1}
									>
										<Grid
											item
											xs
										>
											<TextField
												label={t`Minimum scale`}
												type="number"
												value={options?.yAxis?.min ?? ''}
												placeholder={t`Enter a value`}
												onChange={e =>
													this.handleChange('yAxis.min', e.target.value, true)
												}
												fullWidth
											/>
											<FormControlLabel
												control={
													<Checkbox
														checked={!options?.yAxis?.min && options?.yAxis?.min !== 0}
														onChange={() => {
															const update =
																!options?.yAxis?.min && options?.yAxis?.min !== 0
																	? 0
																	: '';
															this.handleChange('yAxis.min', update, true);
														}}
													/>
												}
												label={t`Auto`}
											/>
										</Grid>
										<Grid
											item
											xs
										>
											<TextField
												label={t`Maximum scale`}
												type="number"
												value={options?.yAxis?.max ?? ''}
												placeholder={t`Enter a value`}
												onChange={e =>
													this.handleChange('yAxis.max', e.target.value, true)
												}
												fullWidth
											/>
											<FormControlLabel
												control={
													<Checkbox
														checked={!options?.yAxis?.max && options?.yAxis?.max !== 0}
														onChange={() => {
															const update =
																!options?.yAxis?.max && options?.yAxis?.max !== 0
																	? 100
																	: '';
															this.handleChange('yAxis.max', update, true);
														}}
													/>
												}
												label={t`Auto`}
											/>
										</Grid>
									</Grid>
								</ListItem>
							</List>
						</Card>

						<Typography
							variant="subtitle2"
							className={classes.titleSpacing}
						>
							{t('Datalabels')}
						</Typography>
						<Card className={classes.optionsBlock}>
							<List
							//dense
							//className={classes.optionsBlock}
							>
								<ListItem
									button
									onClick={e =>
										this.handleChange(
											'plotOptions.series.dataLabels.enabled',
											!get(options, 'plotOptions.series.dataLabels.enabled')
										)
									}
								>
									<ListItemText primary={t('Show datalabels')} />
									<ListItemSecondaryAction>
										<Switch
											checked={Boolean(
												get(options, 'plotOptions.series.dataLabels.enabled')
											)}
											onChange={e =>
												this.handleChange(
													'plotOptions.series.dataLabels.enabled',
													e.target.checked
												)
											}
											value="dataLabelsEnabled"
											color="secondary"
										/>
									</ListItemSecondaryAction>
								</ListItem>
								<ListItem
									button
									onClick={e =>
										this.handleChange(
											'plotOptions.series.dataLabels.inside',
											!get(options, 'plotOptions.series.dataLabels.inside')
										)
									}
								>
									<ListItemText primary={t('Place datalabels in chart')} />
									<ListItemSecondaryAction>
										<Switch
											checked={Boolean(
												get(options, 'plotOptions.series.dataLabels.inside')
											)}
											onChange={e =>
												this.handleChange(
													'plotOptions.series.dataLabels.inside',
													e.target.checked
												)
											}
											value="dataLabelsInChart"
											color="secondary"
										/>
									</ListItemSecondaryAction>
								</ListItem>

								<ListItem>
									<SelectShowEmpty
										label={t('Label format')}
										value={dataLabelFormatValue}
										onChange={e =>
											this.handleChange(
												'plotOptions.series.dataLabels.formatter',
												e.target.value
											)
										}
									>
										<MenuItem value="">{t('Auto')}</MenuItem>
										{labelFormatOptions.map(option => (
											<MenuItem
												key={'label-format-' + option.value}
												value={option.value}
											>
												{t(option.label)}
											</MenuItem>
										))}
									</SelectShowEmpty>
								</ListItem>
							</List>
						</Card>
					</div>
				)}
			</Translation>
		);
	}
}

export default withStyles(styles)(withSnackbar(ChartEditorOptions));
