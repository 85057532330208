import React from 'react';

export default function useTimeout(callback, delay = 0) {
	const timeoutRef = React.useRef();
	const callbackRef = React.useRef(callback);

	// Remember the latest callback:
	//
	// Without this, if you change the callback, when setTimeout kicks in, it
	// will still call your old callback.
	//
	// If you add `callback` to useEffect's deps, it will work fine but the
	// timeout will be reset.

	React.useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	function cancel() {
		window.clearTimeout(timeoutRef.current);
	}

	// Set up the timeout:

	React.useEffect(() => {
		timeoutRef.current = window.setTimeout(() => callbackRef.current(), delay);
		// Clear timeout if the components is unmounted or the delay changes:
		return () => {
			cancel();
		};
	}, [delay, cancel]);

	return cancel;
}
