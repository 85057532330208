import React from 'react';

import { reverseDate } from '@/helpers';

import { useAppContext } from '@/components/AppContext';
import { useEFM } from '@/components/Ajax';

export default function useGetActiveSmartRecapsProjects({ wait, from, to, onSuccess }) {
	const {
		app: { date },
	} = useAppContext();

	return useEFM(
		'/dashboard/ajax/smart-recaps',
		{
			path: '/trends/forms-with-recaps',
			method: 'get',
			from_date: reverseDate(from, date.separator, '-'),
			to_date: reverseDate(to, date.separator, '-'),
		},
		wait,
		false,
		null,
		{ onSuccess: onSuccess, disableCache: true }
	);
}
