import React, { useEffect } from 'react';
import useEFM from './useEFM';
import useMjolnir from './useMjolnir';
import useOfficeDrones from './useOfficeDrones';

const hookMap = {
	efm: useEFM,
	mjolnir: useMjolnir,
	officedrones: useOfficeDrones,
};

function AjaxContent({
	url,
	params = {},
	children,
	api = 'efm',
	usePropData,
	defaultData = {},
	load = true,
	onDataChange,
	onLoadingChange,
}) {
	const [data, loading, error, loadData] = hookMap[api](url, params, !load);

	useEffect(() => {
		if (typeof onDataChange === 'function') {
			onDataChange(data);
		}
	}, [JSON.stringify(data)]);

	useEffect(() => {
		if (typeof onLoadingChange === 'function') {
			onLoadingChange(loading);
		}
	}, [loading]);

	if (usePropData && typeof usePropData === 'object' && Object.keys(usePropData).length) {
		return children({ data: usePropData, loading: false });
	}

	return typeof children === 'function'
		? children({ data: { ...defaultData, ...data }, loading, error, loadData })
		: null;
}

export default AjaxContent;
