import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Grid, TextField, MenuItem, ActionIconButton } from '@/components/Layout';
import { useListFilterContext } from '@/components/FilteredLists';
import { ucFirst } from '@/utils';
import { permissionTypes, permissionSubjects } from './constants';

export default function PermissionGroupRightsFilter({ onChange = () => {} }) {
	const [permission, setPermission] = useState('');
	const [subject, setSubject] = useState('');
	const { t } = useTranslation();
	const { filters } = useListFilterContext();

	if (permission && subject && !filters.permission && !filters.subject) {
		setPermission('');
		setSubject('');
	}

	useEffect(() => {
		onChange({
			permission,
			subject,
		});
	}, [permission, subject]);

	return (
		<Grid
			container
			spacing={1}
			alignItems="center"
		>
			<Grid
				item
				xs={4}
			>
				<TextField
					label={t`settings-access_groups-rights_filter-type_label`}
					value={permission}
					onChange={e => setPermission(e.target.value)}
					select
					fullWidth
					size="small"
				>
					{Object.values(permissionTypes).map(type => (
						<MenuItem
							key={type}
							value={type}
						>
							{ucFirst(t(`settings-access_groups-access_types-${type}`))}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			<Grid
				item
				xs={6}
			>
				<TextField
					label={t`settings-access_groups-rights_filter-subject_label`}
					value={subject}
					onChange={e => setSubject(e.target.value)}
					select
					fullWidth
					size="small"
				>
					{Object.values(permissionSubjects).map(subject => (
						<MenuItem
							key={subject}
							value={subject}
						>
							{ucFirst(t(`settings-access_groups-access_subjects-${subject}`))}
						</MenuItem>
					))}
				</TextField>
			</Grid>

			<Grid
				item
				xs={1}
			>
				<ActionIconButton
					action="close"
					onClick={() => {
						setPermission('');
						setSubject('');
					}}
					size="small"
				/>
			</Grid>
		</Grid>
	);
}
