import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useImmer } from 'use-immer';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import { useAppContext } from '@/components/AppContext';
import {
	Alert,
	Grid,
	InputAdornment,
	Link,
	SimpleDialog,
	Typography,
} from '@/components/Layout';
import { EFM } from '@/api';
import { ValidatedField } from '@/components/Validation';

import CountryPopper from './CountryPopper';
import countries from '../../constants/countries.json';

const useStyles = makeStyles(theme => ({
	fillWidth: {
		width: '100%',
	},
	marginTop: {
		marginTop: theme.spacing(3),
	},
	marginTopSmall: {
		marginTop: theme.spacing(1),
	},
	bold: {
		fontWeight: 600,
	},
	info: {
		backgroundColor: theme.palette.primary.main,
	},
	stepper: {
		padding: `0 0 ${theme.spacing(4)}px 0`,
		width: '100%',
	},
}));

function RegisterMfaDialog({ open, mfa_id, force_registration, onClose = () => {} }) {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const classes = useStyles();

	const [resendVerification, setResendVerification] = useState(false);
	const [verificationResent, setVerificationResent] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [tokenSent, setTokenSent] = useState(false);

	const [state, setState] = useImmer({
		dialCode: '',
		phonenumber: '',
		masked_phonenumber: '',
		token_verification: '',
		mfa_id: mfa_id ? mfa_id : '',
		anchorEl: null,
		language: {
			countryCode: '',
			label: '',
		},
	});

	const steps = [
		{
			label: 'my_account-security_settings-register_mfa_dialog-step_1_title',
			action: requestMfaToken,
		},
		{
			label: 'my_account-security_settings-register_mfa_dialog-step_2_title',
			action: verifyMfaToken,
		},
		{
			label: 'my_account-security_settings-register_mfa_dialog-step_3_title',
			action: reset,
		},
	];

	const onLastStep = activeStep === steps.length - 1;
	const activeStepPage = `page_${activeStep + 1}`;

	//page_1: enter phone number
	//page_2: enter validate key
	const [pageValid, setpageValid] = useImmer({
		page_1: {
			clicked: false,
			valid: false,
			tokenError: false,
			errorMessage: '',
		},
		page_2: {
			clicked: false,
			valid: false,
			tokenError: false,
			errorMessage: '',
		},
		page_3: {
			valid: true,
		},
	});

	useEffect(() => {
		if (state.dialCode) {
			return;
		}

		EFM.post('/account/ajax/get-country').then(response => {
			const countryCode = response.country ?? 'NL'; //fallback to NL
			const country = countries[countryCode];

			if (country) {
				setState(draft => {
					draft.dialCode = country.phone;
					draft.language.countryCode = countryCode.toLowerCase();
					draft.language.label = country.name;
				});
			}
		});
	}, [state.dialCode]);

	function updateImmer(value, target) {
		setState(draft => {
			draft[target] = value;
		});
	}

	function updatePageValid(page, child, value) {
		setpageValid(draft => {
			draft[page][child] = value;
		});
	}

	function updateLanguage(e) {
		setState(draft => {
			draft.anchorEl = e.anchorEl ? e.anchorEl : null;
			draft.dialCode = e.dialCode ? e.dialCode : state.dialCode;
			draft.language.countryCode = e.countryCode
				? e.countryCode
				: state.language.countryCode;
			draft.language.label = e.label ? e.label : state.language.label;
		});
	}

	function handlePrevious() {
		if (activeStep <= 0) {
			reset();
			return;
		}
		setActiveStep(activeStep - 1);
	}

	function handleNext() {
		updatePageValid(activeStepPage, 'clicked', true);
		if (!pageValid[activeStepPage].valid) {
			return;
		}

		// run step (ajax) function
		steps[activeStep].action();
	}

	function reset() {
		updatePageValid('page_1', 'clicked', false);
		updatePageValid('page_1', 'valid', false);
		updatePageValid('page_1', 'tokenError', false);
		updatePageValid('page_1', 'errorMessage', '');

		updatePageValid('page_2', 'clicked', false);
		updatePageValid('page_2', 'valid', false);
		updatePageValid('page_2', 'tokenError', false);
		updatePageValid('page_2', 'errorMessage', '');

		setState(draft => {
			draft.phonenumber = '';
			draft.token_verification = '';
		});

		setTokenSent(false);
		setActiveStep(0);
		onClose();
	}

	function requestMfaToken(force = false) {
		if (tokenSent && !force) {
			setActiveStep(1);
			return;
		}

		EFM.post('/account/ajax/register-mfa', {
			phone: filterPhonePrefix(),
			country_code: state.dialCode,
		})
			.then(response => {
				if (!response.token_sent) {
					throw new Error('Failed to send the code');
				}

				setState(draft => {
					draft.mfa_id = response.identifier;
					draft.masked_phonenumber = response.nr;
				});

				setTokenSent(true);
				setActiveStep(1);
				setVerificationTimer(20000); //20sec
			})
			.catch(error => {
				updatePageValid(
					activeStepPage,
					'errorMessage',
					t('my_account-security_settings-register_mfa_dialog-code_not_sent')
				);
				updatePageValid(activeStepPage, 'tokenError', true);
				updatePageValid(activeStepPage, 'valid', false);
				setActiveStep(0);
				setTokenSent(false);
			});
	}

	function verifyMfaToken() {
		if (!tokenSent) {
			return;
		}

		EFM.post('/account/ajax/verify-mfa-token', {
			mfa: state.token_verification,
			mfa_id: state.mfa_id,
		})
			.then(response => {
				if (response.success !== 'true') {
					throw new Error('Failed to verify the token');
				}

				setActiveStep(2);
				app.api.getUsers();
			})
			.catch(error => {
				setActiveStep(1);
				updatePageValid(activeStepPage, 'errorMessage', 'Failed to verify the token');
				updatePageValid(activeStepPage, 'tokenError', true);
				updatePageValid(activeStepPage, 'valid', false);
			});
	}

	function filterPhonePrefix() {
		if (state.phonenumber.charAt(0) === '0') {
			return state.phonenumber.replace('0', state.dialCode);
		}
		return state.dialCode + state.phonenumber;
	}

	function setVerificationTimer(timer = 5000) {
		setTimeout(() => {
			setVerificationResent(false);
			setResendVerification(true);
		}, timer);
	}

	return (
		<SimpleDialog
			open={open}
			onClose={onClose}
			onBack={handlePrevious}
			onSubmit={handleNext}
			onKeyPress={e => {
				if (e.key === 'Enter') {
					handleNext();
				}
			}}
			title={t('my_account-security_settings-register_mfa_dialog-title')}
			cancel={
				activeStep > 0
					? t('my_account-security_settings-register_mfa_dialog-back')
					: t('my_account-security_settings-register_mfa_dialog-cancel')
			}
			submit={
				onLastStep
					? t('my_account-security_settings-register_mfa_dialog-done')
					: t('my_account-security_settings-register_mfa_dialog-continue')
			}
			fullWidth
		>
			<Stepper
				className={classes.stepper}
				activeStep={activeStep}
			>
				{steps.map(step => {
					return (
						<Step key={step.label}>
							<StepLabel>{t(step.label)}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
			{activeStep === 0 && (
				<Grid container>
					{force_registration && (
						<Alert severity="info">
							{t('my_account-security_settings-mfa_required_by_organisation_alert')}
						</Alert>
					)}
					<Grid
						item
						xs={2}
						className={classes.fillWidth}
					>
						<CountryPopper
							countryLanguage={state}
							updateLanguage={e => {
								updateLanguage(e);
							}}
						/>
					</Grid>
					<Grid
						item
						xs={10}
						className={classes.fillWidth}
					>
						<ValidatedField
							id="current-phonenumber"
							type="tel"
							fullWidth
							label={t(
								'my_account-security_settings-register_mfa_dialog-input_phonenumber'
							)}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">(+{state.dialCode})</InputAdornment>
								),
							}}
							value={state.phonenumber}
							validateValue={
								pageValid.page_1.tokenError ? '' : `${state.dialCode}${state.phonenumber}`
							}
							rules={[
								['phone_alt', true],
								['custom', () => Boolean(state.dialCode)],
								['custom', () => Boolean(!pageValid.page_1.tokenError)],
							]}
							showErrors={pageValid.page_1.clicked || pageValid.page_1.tokenError}
							errorMessage={
								pageValid.page_1.errorMessage !== ''
									? `${pageValid.page_1.errorMessage}`
									: pageValid.page_1.tokenError
									? t('my_account-security_settings-register_mfa_dialog-general_error')
									: !state.dialCode
									? t('my_account-security_settings-register_mfa_dialog-select_country')
									: t(
											'my_account-security_settings-register_mfa_dialog-phonenumber_incorrect'
									  )
							}
							onValidChange={e => {
								updatePageValid('page_1', 'valid', e);
							}}
							onChange={e => {
								updateImmer(e.currentTarget.value, 'phonenumber');
								updatePageValid('page_1', 'tokenError', false);
							}}
						/>
					</Grid>
				</Grid>
			)}
			{activeStep === 1 && (
				<Grid
					container
					direction="column"
				>
					<Grid
						item
						xs={12}
						className={classes.fillWidth}
					>
						<Typography
							variant="body1"
							display="inline"
						>
							<Trans
								i18nKey="my_account-security_settings-register_mfa_dialog-code_sent_to"
								components={{
									p1: (
										<Typography
											variant="body1"
											className={classes.bold}
											display="inline"
										/>
									),
									masked_number: state.masked_phonenumber,
								}}
							/>
						</Typography>
						<ValidatedField
							id="current-validation"
							fullWidth
							label={t('my_account-security_settings-register_mfa_dialog-input_code')}
							value={state.token_verification}
							validateValue={pageValid.page_2.tokenError ? '' : state.token_verification}
							rules={[
								['mfa_token', true],
								['custom', () => Boolean(!pageValid.page_2.tokenError)],
							]}
							showErrors={pageValid.page_2.clicked || pageValid.page_2.tokenError}
							errorMessage={t(
								'my_account-security_settings-register_mfa_dialog-code_incorrect'
							)}
							onValidChange={e => {
								updatePageValid('page_2', 'valid', e);
							}}
							onChange={e => {
								updateImmer(e.currentTarget.value, 'token_verification');
								updatePageValid('page_2', 'tokenError', false);
							}}
						/>
					</Grid>
					{resendVerification && (
						<Grid
							container
							direction="column"
							className={classes.marginTop}
						>
							<Grid
								item
								xs={12}
								className={classes.fillWidth}
							>
								<Typography variant="subtitle2">
									{t(
										'my_account-security_settings-register_mfa_dialog-code_not_received_title'
									)}
								</Typography>
								<Typography variant="body2">
									{t(
										'my_account-security_settings-register_mfa_dialog-code_not_received_body'
									)}
								</Typography>
							</Grid>
							{!verificationResent && (
								<div className={classes.marginTopSmall}>
									<Button
										color="primary"
										onClick={e => {
											handlePrevious();
											updatePageValid('page_2', 'tokenError', false);
										}}
									>
										{t(
											'my_account-security_settings-register_mfa_dialog-check_phonenumber'
										)}
									</Button>
									<Button
										color="primary"
										onClick={e => {
											setVerificationResent(true);
											requestMfaToken(true);
											updatePageValid('page_2', 'tokenError', false);
										}}
									>
										{t('my_account-security_settings-register_mfa_dialog-resend_code')}
									</Button>
								</div>
							)}
							{verificationResent && (
								<div className={classes.marginTopSmall}>
									<Typography variant="body1">
										{t('my_account-security_settings-register_mfa_dialog-code_resent')}
									</Typography>
								</div>
							)}
						</Grid>
					)}
				</Grid>
			)}
			{onLastStep && (
				<Grid
					container
					direction="column"
				>
					<Grid
						item
						xs={12}
						className={classes.fillWidth}
					>
						<Typography variant="body1">
							{t(
								'my_account-security_settings-register_mfa_dialog-registration_complete'
							)}
						</Typography>
						<Typography
							variant="body1"
							className={classes.marginTop}
						>
							<Trans
								i18nKey="my_account-security_settings-register_mfa_dialog-mfa_usage"
								components={{
									authyLink: (
										<Link
											href="https://authy.com/download/"
											variant="body1"
											target="_blank"
											rel="noopener"
										/>
									),
								}}
							/>
						</Typography>
					</Grid>
				</Grid>
			)}
		</SimpleDialog>
	);
}

export default RegisterMfaDialog;
