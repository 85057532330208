import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
	circle: props => ({
		position: 'absolute',
		top: theme.spacing(1),
		right: theme.spacing(1),
		zIndex: 1,
		verticalAlign: 'middle',
		...(!props.blue && {
			boxShadow: theme.shadows[4],
			borderRadius: '100%',
			width: 24,
			height: 24,
			backgroundColor: '#fff',
		}),
	}),
	icon: {
		color: props =>
			props.blue ? theme.palette.primary.main : theme.palette.secondary.main,
	},
}));

export default function SelectionIndicator({ show = true, blue, ...props }) {
	const classes = useStyles({ blue });
	return (
		<Grow in={show}>
			<div className={classes.circle}>
				{blue ? (
					<CheckCircleIcon className={classes.icon} />
				) : (
					<CheckIcon className={classes.icon} />
				)}
			</div>
		</Grow>
	);
}
