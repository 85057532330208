import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { channelNames, eventNames } from './constants';
import { useAppContext } from '../AppContext';
import { useEFM, useAjaxForm } from '../Ajax';
import { isToday } from '../../utils/date';
import { useLocalStorage } from '../../hooks';
import { EFM } from '../../api';

export default function useNotifications({ menuOpen = false, paginateBy = 10 } = {}) {
	const { app } = useAppContext();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const [data = {}, loading, error, loadData, setData] = useEFM(
		'/application/notifications/get',
		{ removed: false, page: 1, paginate_by: paginateBy },
		menuOpen,
		60000
	);
	useEffect(() => {
		if (paginateBy !== 10) loadData();
	}, [paginateBy]);

	const notifications = Array.isArray(data.response?.notifications)
		? data.response.notifications
		: [];

	const unread = notifications.filter(note => !note.seen && !note.removed);
	const read = notifications.filter(note => note.seen && !note.removed);

	const today = notifications.filter(
		note => isToday(new Date(note.created)) && !note.removed
	);
	const older = notifications.filter(
		note => !isToday(new Date(note.created)) && !note.removed
	);

	const [showBadge, setShowBadge] = useLocalStorage(
		`notifications-show-badge-${app.users.current.id}`,
		false
	);
	const [badgeValue, setBadgeValue] = useLocalStorage(
		`notifications-badge-${app.users.current.id}`,
		0
	);

	useEffect(() => {
		//if badge value -> add to document title
		if (showBadge && badgeValue !== 0) {
			document.title = `(${badgeValue > 9 ? `9+` : badgeValue}) ${document.title.replace(
				/ *\([^)]*\) */g,
				''
			)}`;
		} else {
			//remove everything between and including parentheses (notifications)
			document.title = document.title.replace(/ *\([^)]*\) */g, '');
		}
	}, [showBadge, badgeValue]);

	function markAsRead(uuids = [], seen = true) {
		EFM.post('/application/notifications/seen', {
			notification_uuids: uuids,
			...(!seen && { unseen: 1 }),
		});

		setData(prev => ({
			...prev,
			response: {
				...prev.response,
				notifications: prev.response.notifications.map(item => {
					return {
						...item,
						...(uuids.includes(item.uuid) && {
							seen,
						}),
					};
				}),
			},
		}));
	}

	function markAllAsRead() {
		markAsRead(unread.map(note => note.uuid));
	}

	function clear(uuids) {
		EFM.post('/application/notifications/delete', { notification_uuids: uuids });

		setData(prev => ({
			...prev,
			response: {
				...prev.response,
				notifications: prev.response.notifications.map(item => ({
					...item,
					...(uuids.includes(item.uuid) && {
						removed: true,
					}),
				})),
			},
		}));
	}

	function clearAll() {
		clear(notifications.map(note => note.uuid));
	}

	function unsubscribeFromEvent(event) {
		EFM.post('/application/ajax/unsubscribe-from-event', { event }).then(r => {
			if (r.code == 200) {
				const niceName = channelNames[event] ?? event;
				enqueueSnackbar(
					t(`You'll no longer receive notifications {{eventName}}`, {
						eventName: niceName,
					}).toLowerCase()
				);
			}
		});
	}

	function unsubscribeFromUser(user_id) {
		EFM.post('/application/ajax/unsubscribe-from-user', { user_id }).then(r => {
			if (r.code === 200) {
				const user = app.users.byKey[user_id];
				enqueueSnackbar(
					t(`You'll no longer receive notifications from {{username}}`, {
						username: `${user?.firstname} ${user?.lastname}`,
					})
				);
			}
		});
	}

	useEffect(() => {
		if (unread.length !== badgeValue) {
			setBadgeValue(unread.length);
			setShowBadge(true);
		}
	}, [unread.length, badgeValue]);

	useEffect(() => {
		if (menuOpen) {
			setShowBadge(false);
		}
	}, [menuOpen]);

	return {
		all: notifications,
		unread,
		read,
		today,
		older,
		badgeValue,
		showBadge,
		markAsRead,
		markAllAsRead,
		clear,
		clearAll,
		unsubscribeFromEvent,
		unsubscribeFromUser,
		loadData,
		loading,
	};
}
