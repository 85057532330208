import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';

import get from 'lodash.get';
import set from 'lodash.set';

import { tryParse } from '../utils';

i18n.use(HttpApi).init({
	lng: 'en',
	fallbackLng: 'en',
	ns: ['translation'],
	defaultNS: ['translation'],
	keySeparator: '-',
	nsSeparator: ':ns:',
	pluralSeparator: '*',
	contextSeparator: ':context:',
	interpolation: {
		escapeValue: false,
	},
	saveMissing: true,
	missingKeyHandler: (lng, ns, key, fallbackValue) => {
		if (!get(window, `___MISSING_TRANSLATIONS.keys.${lng}`)) {
			set(window, `___MISSING_TRANSLATIONS.keys.${lng}`, {});
		}
		window.___MISSING_TRANSLATIONS.keys[lng][key] = key;
	},
	backend: {
		loadPath: '/application/ajax/translation?language={{lng}}&namespace={{ns}}',
		customHeaders: {
			'X-Requested-With': 'XMLHttpRequest',
			'X-Requested-By-React': 'true',
		},
		parse: data => {
			const parsed = tryParse(data);

			if (parsed.code === 200 && Object.keys(parsed?.translation ?? {}).length > 0) {
				return parsed.translation;
			}

			return {};
		},
	},
});

export default i18n;

//Define global for tracking missing translations
window.___MISSING_TRANSLATIONS = {
	copy: lang => {
		const el = document.createElement('textarea');
		const obj = lang
			? window.___MISSING_TRANSLATIONS.keys[lang]
			: window.___MISSING_TRANSLATIONS.keys;
		el.value = JSON.stringify(obj, null, '\t');
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
	},
};
//end define global
