import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleDialog } from '@/components/Layout';
import { useEFM, useAjaxForm } from '@/components/Ajax';
import { ValidatedField } from '@/components/Validation';

function ReauthDialog({ title, open, onClose = () => {}, onSave = () => {} }) {
	const { t } = useTranslation();
	const [authCode, setAuthCode] = useState('');
	const [valid, setValid] = useState(false);

	useEFM('/account/ajax/start-reauthenticate', {}, !open);

	const {
		postForm: validateCode,
		loading: validateCodeLoading,
		clicked,
	} = useAjaxForm({
		url: '/account/ajax/validate-auth-code',
		valid: () => valid,
		data: { auth_code: authCode },
		onSuccess: function (response) {
			if (response.code_valid) {
				onSave();
				onClose();
			}
		},
	});

	return (
		<SimpleDialog
			open={open}
			onClose={onClose}
			onSubmit={validateCode}
			title={title ?? t('my_account-security_settings-reauth_dialog-default_title')}
			cancel={t('my_account-security_settings-reauth_dialog-cancel')}
			submit={t('my_account-security_settings-reauth_dialog-continue')}
			maxWidth="sm"
			fullWidth
			loading={validateCodeLoading}
		>
			<ValidatedField
				id="auth-code"
				type="text"
				fullWidth
				label={t('my_account-security_settings-reauth_dialog-input_placeholder')}
				value={authCode}
				validateValue={authCode}
				rules={{ required: true }}
				showErrors={clicked}
				errorMessage={t('my_account-security_settings-reauth_dialog-code_incorrect')}
				onValidChange={valid => setValid(valid)}
				onChange={e => {
					setAuthCode(e.currentTarget.value);
				}}
				onKeyPress={e => {
					if (e.key === 'Enter') {
						validateCode();
					}
				}}
				onPaste={e => {
					const pastedText = e.clipboardData.getData('Text');
					validateCode({
						post: {
							auth_code: pastedText,
						},
					});
				}}
			/>
		</SimpleDialog>
	);
}

export default ReauthDialog;
