import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NotFoundIcon from '@material-ui/icons/NotListedLocation';

const useStyles = makeStyles(theme => {
	return {
		wrap: {
			flexGrow: 1,
			height: '100%',
			maxWidth: '100%',
		},
		icon: {
			color: theme.palette.text.secondary,
			fontSize: props =>
				props.size === 'small'
					? theme.typography.pxToRem(32)
					: theme.typography.pxToRem(64),
		},
		image: {
			'& img': {
				maxWidth: 200,
				maxHeight: 200,
			},
		},
	};
});

export default function EmptyState({
	size,
	image,
	notFound,
	icon,
	iconProps = {},
	primary,
	secondary,
	action,
	spacing = 3,
	className,
	primaryTypographyProps = {},
	secondaryTypographyProps = {},
}) {
	const classes = useStyles({ size });

	const IconComponent = notFound ? NotFoundIcon : icon ? icon : null;

	return (
		<Grid
			container
			direction="column"
			justify="center"
			alignItems="center"
			wrap="nowrap"
			className={`${classes.wrap} ${className ? className : ''}`}
			spacing={spacing}
		>
			{image && (
				<Grid
					item
					className={classes.image}
				>
					{image}
				</Grid>
			)}
			{IconComponent && (
				<Grid item>
					<IconComponent
						className={classes.icon}
						{...iconProps}
					/>
				</Grid>
			)}
			<Grid item>
				{primary && (
					<Typography
						variant={size === 'small' ? 'subtitle1' : 'h6'}
						display="block"
						align="center"
						{...primaryTypographyProps}
					>
						{primary}
					</Typography>
				)}
				{secondary && (
					<Typography
						variant={size === 'small' ? 'body2' : 'subtitle1'}
						display="block"
						color="textSecondary"
						align="center"
						{...secondaryTypographyProps}
					>
						{secondary}
					</Typography>
				)}
			</Grid>
			{action && <Grid item>{action}</Grid>}
		</Grid>
	);
}
