import React from 'react';

import { useAjaxForm } from '@/components/Ajax';

export default function useAddActionComment({
	comment,
	id,
	loadData = () => {},
	...props
}) {
	return useAjaxForm({
		url: `/actions/ajax/comment`,
		data: {
			id,
			comment,
		},
		onSuccess: () => {
			loadData();
		},
		...props,
	});
}
