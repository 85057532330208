import React from 'react';

import { useAppContext } from '@/components/AppContext';

export default function useNotificationUser({ user_id, type, request }) {
	const { app } = useAppContext();

	if (type.includes('access_request') === false) {
		return app.api.getUserById(user_id);
	}

	if (
		[
			'access_request.granted',
			'access_request.denied',
			'project_access_request.granted',
			'project_access_request.denied',
		].includes(type)
	) {
		return app.api.getUserByUuid(request.closed_by);
	}

	return app.api.getUserByUuid(request.user);
}
