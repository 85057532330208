import React, { useEffect, useState, useRef } from 'react';

import FormGroup from '@material-ui/core/FormGroup';

import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';
import set from 'lodash.set';
import get from 'lodash.get';
import { useSnackbar } from 'notistack';
import ObjectID from 'bson-objectid';

import { makeStyles } from '@/styles';

import { useWebHookContext } from './WebHookContext';
import { useSaveHook } from './WebHookHooks';
import AsanaFlow from './AsanaFlow';
import TrelloFlow from './TrelloFlow';
import JiraRow from './JiraRow';
import BasecampRow from './BasecampRow';
import {
	ASANA_ENDPOINT,
	ASANA_API_TOKEN_URL,
	TRELLO_ENDPOINT,
	SLACK_ENDPOINT,
	JIRA_ENDPOINT,
	SALESFORCE_ENDPOINTS,
} from './constants';

import { useAppContext } from '@/components/AppContext';
import { DataSourceSelect, DataFieldSelect } from '@/components/Select';
import {
	Alert,
	ActionButton,
	ActionIconButton,
	Box,
	Checkbox,
	FormLabel,
	FormControlLabel,
	Grid,
	InputAdornment,
	Typography,
	TextField,
	Link,
	SimpleDialog,
	DataFieldConditions,
	SelectionCard,
	Step,
	Stepper,
	StepLabel,
	StepButton,
	Radio,
	RadioGroup,
} from '@/components/Layout';
import { ValidatedField, ruleTests } from '@/components/Validation';
import AuthList from './AuthList';

import { operators } from '@/helpers';
import { WEBHOOK_TYPES } from './constants';

const useStyles = makeStyles(theme => ({
	grid: {
		'& > div': {
			width: '100%',
		},
	},
	stepper: {
		paddingLeft: props => (props.type ? theme.spacing(4) : 0),
		paddingRight: props => (props.type ? theme.spacing(4) : 0),
		paddingBottom: 0,
	},
	margin: {
		marginBottom: theme.spacing(2),
	},
	error: {
		color: theme.palette.error.main,
	},
}));

function conditionBase() {
	return {
		datafieldid: '',
		condition: 'e',
		target: '',
	};
}

function paramBase() {
	return {
		datafield: '',
		label: '',
		var_type: '',
	};
}

function HTMLformBase() {
	return {
		key: '',
		value: '',
	};
}

function asanaBase({ apiKey = '', refreshToken = '' } = {}) {
	return {
		apiKey,
		workspaceId: '',
		workspaceName: '',
		projectId: '',
		projectName: '',
		refreshToken,
	};
}

function trelloBase({ username = '', key = '', token = '' } = {}) {
	return {
		username,
		key,
		token,
		idBoard: '',
		nameBoard: '',
		idList: '',
		nameList: '',
	};
}

function credBase(type, auth_id = '') {
	switch (type) {
		case 'jira':
			return {
				projectKey: '',
				auth_id,
				userId: '',
				issueType: '',
				parent_id: '',
				labels: [],
			};

		case 'basecamp':
			return {
				auth_id,
				project_id: '',
				todo_set_id: '',
				todo_list_id: '',
				assignee: '',
			};
		default:
			return {};
	}
}

function endpoint(type) {
	switch (type) {
		case 'jira':
			return JIRA_ENDPOINT;
		case 'basecamp':
			return '';
		default:
			return '';
	}
}

function getSteps(typeFromProps, event_type) {
	return [
		...(!typeFromProps ? [{ label: 'Select type', value: 'type' }] : []),
		{ label: 'Connect API', value: 'api' },
		{ label: event_type === 'user_init' ? 'Enter name' : 'Select data', value: 'data' },
	];
}

function getStepValue(typeFromProps, index, key = 'value') {
	return getSteps(typeFromProps)[index][key];
}

function getStepIndex(typeFromProps, value) {
	return getSteps(typeFromProps).findIndex(step => step.value === value);
}

function isValidOrgId(value, oi) {
	return value.match(/00D/g) !== null;
}

export default function WebHookAddItDialog({
	webhook_id,
	open,
	onClose = () => {},
	preSelected,
	survey_id = null,
	type,
	...props
}) {
	const classes = useStyles({ type });
	const { app } = useAppContext();
	const { state, api } = useWebHookContext();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const dialogRef = useRef(null);

	const [hookState, setHookState] = useImmer({
		hook: {
			name: '',
			webhook_type: 'general',
			event_type: preSelected ? preSelected : 'created',
			conditions: [],
			active: true,
			webhook_id: ObjectID().toString(),
			parameters: {
				all: true,
				aliasses: [],
			},
			endpoint: [''],
			trello_apicreds: [],
			asana_apicreds: [],

			extra_fields: [], //salesforce & htmlform
			prefilled_fields: [], // salesforce & htmlform
			feedback_field: '', // salesforce & htmlform
			org_id: '', // salesforce
			sf_form: 'web-to-case', // salesforce ( startvalue )

			surveyActive: true,

			domain: app.domain,
			project_id: app.projects.current.id,
			survey_id,

			all_data: true,
			all_project_data: preSelected === 'user_init',

			api_creds: [],
		},
		clicked: false,
	});

	useEffect(() => {
		if (
			hookState.hook.event_type === 'user_init' &&
			['salesforce-form', 'html-form'].indexOf(hookState.hook.webhook_type) > -1
		) {
			setValue('webhook_type', 'general');
			enqueueSnackbar(
				t(
					`{{type}} integrations are not supported for manual integrations at this time`,
					{
						type:
							hookState.hook.webhook_type === 'salesforce-form'
								? `Salesforce`
								: 'HTLM form',
					}
				)
			);
		}
	}, [hookState.hook.event_type, hookState.hook.webhook_type]);

	useEffect(() => {
		if (webhook_id) {
			const item = state.hooks.find(item => item.webhook_id === webhook_id);
			if (item) {
				setHookState(draft => {
					draft.hook = {
						...item,
						conditions: item.conditions ? item.conditions : [],
					};
				});
			}
		}
	}, [webhook_id]);

	useEffect(() => {
		if (type && !webhook_id) {
			setValue('webhook_type', type);
			setTypeState(type);
		}
	}, [type, webhook_id]);

	useEffect(() => {
		if (survey_id) {
			setValue('survey_id', survey_id);
		}
	}, [survey_id]);

	useEffect(() => {
		if (!webhook_id && ['salesforce-form', 'html-form'].indexOf(hook.webhook_type) > -1) {
			setValue('extra_fields', [HTMLformBase()]);
			setValue('prefilled_fields', [HTMLformBase()]);
		}
	}, [webhook_id, hookState.hook.survey_id, hookState.hook.webhook_type]);

	useEffect(() => {
		setValue('project_id', app.projects.current.id);
	}, [app.projects.current.id]);

	const [saveHook, loadingSave] = useSaveHook({
		hook: hookState.hook,
	});

	const { hook, clicked } = hookState;

	const [newAuth, setNewAuth] = useState(false);
	const [activeStep, setActiveStep] = useState(
		webhook_id ? getStepIndex(type, 'data') : 0
	);

	useEffect(() => {
		if (!webhook_id && open) {
			setActiveStep(getStepIndex(type, 'type'));
		}

		if (type && open && !webhook_id) {
			setActiveStep(getStepIndex(type, 'api'));
		}

		if (type && open && webhook_id) {
			setActiveStep(getStepIndex(type, 'data'));
		}
	}, [open, webhook_id, type]);

	function setValue(key, value) {
		setHookState(draft => {
			set(draft.hook, key, value);
		});
	}
	function removeArrayIndex(key, index) {
		setHookState(draft => {
			draft.hook[key].splice(index, 1);
		});
	}
	function addToArray(key, value) {
		setHookState(draft => {
			get(draft.hook, key, []).push(value);
		});
	}
	function setClicked() {
		setHookState(draft => {
			draft.clicked = true;
		});
	}

	function setTypeState(setType) {
		switch (setType) {
			case 'asana':
				setValue('endpoint', [ASANA_ENDPOINT]);
				setValue('asana_apicreds', [asanaBase()]);
				break;

			case 'trello':
				setValue('endpoint', [TRELLO_ENDPOINT]);
				setValue('trello_apicreds', [trelloBase()]);
				break;

			case 'jira':
				setValue('endpoint', [JIRA_ENDPOINT]);
				setValue('api_creds', [credBase('jira')]);
				break;

			case 'basecamp':
				setValue('endpoint', ['']);
				setValue('api_creds', [credBase('basecamp')]);
				break;

			case 'salesforce-form':
				setValue('endpoint', [SALESFORCE_ENDPOINTS[0]]);
				break;

			default:
				setValue('endpoint', ['']);
				setValue('api_creds', []);
				setValue('trello_apicreds', []);
				setValue('asana_apicreds', []);
				break;
		}
	}

	// function setStep(s) {
	// 	switch (s) {
	// 		case 'next':
	// 		case 'prev':
	// 			if (!step[activeStep - 1].show) {
	// 				return activeStep - 1;
	// 			}

	// 			i
	// 		default:
	// 			setActiveStep(step => step + 1);
	// 			return
	// 	}
	// }

	function isValid() {
		return getSteps(type).every((step, index) => pageValid(index));
	}

	function pageValid(which) {
		switch (getStepValue(type, which)) {
			case 'type':
				return Boolean(hook.webhook_type);

			case 'api':
				const endpointValid =
					hook.endpoint.filter(point =>
						hook.webhook_type === 'slack'
							? point.length > 0
							: ruleTests['urlHttps'](point)
					).length === hook.endpoint.length;

				const asanaValid =
					hook.webhook_type === 'asana'
						? hook.asana_apicreds.filter(cred => {
								return cred.workspaceId && cred.projectId;
						  }).length === hook.asana_apicreds.length
						: true;

				const trelloValid =
					hook.webhook_type === 'trello'
						? hook.trello_apicreds.filter(cred => {
								return cred.idBoard && cred.idList;
						  }).length === hook.trello_apicreds.length
						: true;

				const jiraValid =
					hook.webhook_type === 'jira'
						? hook.api_creds.filter(cred => {
								return cred?.projectKey && cred?.issueType;
						  }).length === hook.api_creds.length
						: true;

				const basecampValid =
					hook.webhook_type === 'basecamp'
						? hook.api_creds.filter(cred => {
								return cred?.project_id && cred?.todo_list_id;
						  }).length === hook.api_creds.length
						: true;

				const salesforceValid =
					hook.webhook_type === 'salesforce-form' ? isValidOrgId(hook.org_id) : true;

				return (
					endpointValid &&
					asanaValid &&
					trelloValid &&
					jiraValid &&
					basecampValid &&
					salesforceValid
				);

			case 'data':
				const emailValid =
					hook.webhook_type === 'salesforce-form' && hook.sf_form === 'web-to-lead'
						? hook.extra_fields.find(list => list.key.match(/email/gi) !== null)
						: true;

				return emailValid && hook.name && (hook.survey_id || hook.all_project_data);
		}

		//return typeof which !== 'undefined' ? valid[which] : valid;
	}

	function hookLabel(type) {
		return WEBHOOK_TYPES.find(webhook => webhook.value === type)?.label;
	}

	return (
		<SimpleDialog
			PaperProps={{ ref: dialogRef }}
			title={
				<>
					<div>
						{webhook_id
							? `${t`Edit`} ${hookLabel(hook.webhook_type)} ${t`integration`}`
							: getStepValue(type, activeStep) !== 'type'
							? `${t`New`} ${hookLabel(hook.webhook_type)} ${t`integration`}`
							: t`New integration`}
					</div>
					<Stepper
						activeStep={activeStep}
						nonLinear
						classes={{ root: classes.stepper }}
					>
						{getSteps(type, hook.event_type).map(({ label }, index) => {
							return (
								<Step key={label}>
									<StepButton
										completed={Boolean(index !== activeStep && pageValid(index))}
										onClick={() => setActiveStep(index)}
									>
										<StepLabel error={clicked && !pageValid(index)}>{label}</StepLabel>
									</StepButton>
								</Step>
							);
						})}
					</Stepper>
				</>
			}
			submit={
				getStepValue(type, activeStep) !== 'data'
					? t`Next`
					: webhook_id
					? t`Edit integration`
					: t`Create integration`
			}
			open={open}
			onClose={onClose}
			maxWidth="sm"
			loading={loadingSave}
			dataTestElement={
				getStepValue(type, activeStep) !== 'data'
					? 'apiNewNext'
					: webhook_id
					? 'apiEditWebhook'
					: 'apiCreateWebhook'
			}
			dataTrackEvent={
				webhook_id ? 'integrations_webhook_edited' : 'integrations_webhook_created'
			}
			onSubmit={() => {
				if (getStepValue(type, activeStep) === 'data') {
					setClicked();
					if (isValid()) {
						saveHook();
						api.dispatch({
							type: webhook_id ? 'update_rule' : 'add_filled_rule',
							payload: { webhook: hook },
						});
						onClose({ refreshComponent: true });
						enqueueSnackbar(
							webhook_id
								? `${t`Integration`} ${hook.name} updated`
								: `${t`Integration`} ${hook.name} created`
						);
					}
				} else {
					setActiveStep(step => step + 1);
				}
			}}
			{...props}
		>
			<Grid
				container
				spacing={3}
				className={classes.grid}
				direction="column"
			>
				{getStepValue(type, activeStep) === 'type' && (
					<>
						<Grid
							item
							xs
						>
							<Typography variant="subtitle">{t`Integration type`}</Typography>
							<Typography
								color="textSecondary"
								gutterBottom
							>
								{t`Specify what type of integration you want to create and setup API endpoints.`}
							</Typography>
							<Grid
								container
								spacing={2}
								direction="row"
							>
								{WEBHOOK_TYPES
									//filter unavaible hooks for userinit
									.filter(type => {
										if (type.hideUserInit) {
											return hook.event_type !== 'user_init';
										} else {
											return true;
										}
									})
									.map(type => {
										return (
											<Grid
												item
												xs={4}
												key={type.value}
											>
												<SelectionCard
													selected={type.value === hook.webhook_type}
													onClick={() => {
														setValue('webhook_type', type.value);
														setTypeState(type.value);
													}}
													outerContent={
														<Box
															p={2}
															display="flex"
															alignItems="center"
															justifyContent="center"
														>
															<Box
																clone
																maxWidth="100%"
																maxHeight={40}
															>
																<img src={type.img} />
															</Box>
														</Box>
													}
												/>
											</Grid>
										);
									})}
							</Grid>
						</Grid>
						<Grid
							item
							xs
						>
							<FormGroup>
								<FormLabel>{t`Run integration`}</FormLabel>
								<RadioGroup
									//row
									value={hook.event_type}
									onChange={e => {
										setValue('event_type', e.target.value);
										setValue('survey_id', null);
										if (e.target.value === 'user_init') {
											setValue('all_project_data', true);
											setValue('active', true);
										} else {
											setValue('all_project_data', false);
										}
									}}
								>
									<FormControlLabel
										value="created"
										control={<Radio />}
										label={t`Automatically for every new feedback item`}
									/>
									<FormControlLabel
										value="user_init"
										control={<Radio />}
										label={t`Manually from the feedback inbox`}
									/>
								</RadioGroup>
							</FormGroup>
						</Grid>
					</>
				)}

				{getStepValue(type, activeStep) === 'api' && (
					<>
						{['jira', 'basecamp'].indexOf(hook.webhook_type) > -1 && (
							<Grid
								item
								xs
							>
								<Box mb={1}>
									<Typography variant="subtitle">
										{t`Connect with`} {hookLabel(hook.webhook_type)}
									</Typography>
									<Typography
										color="textSecondary"
										gutterBottom
									>
										{t`Select an existing connection or create a new one`}
									</Typography>
								</Box>

								<AuthList
									type={hook.webhook_type}
									selectedId={hook.api_creds[0]?.auth_id}
									onSelect={auth => {
										hook.api_creds.forEach((cred, index) => {
											setValue(`api_creds[${index}].auth_id`, auth.id);
											setValue(`api_creds[${index}].userId`, auth.userId);
										});
									}}
									target={dialogRef}
								/>

								{hook.api_creds[0]?.auth_id && (
									<Box mt={2}>
										{hook.webhook_type === 'jira' && (
											<Box mb={1}>
												<Typography variant="subtitle">{t`Select project endpoints`}</Typography>
												<Typography color="textSecondary">{t`Select the project and issue type, optionally add labels to the created issues`}</Typography>
											</Box>
										)}

										{hook.webhook_type === 'basecamp' && (
											<Box mb={1}>
												<Typography variant="subtitle">{t`Select project endpoints`}</Typography>
												<Typography color="textSecondary">{t`Select the project and todo list, optionally add an assigned user to created todo's`}</Typography>
											</Box>
										)}

										{hook.api_creds.map((cred, index) => {
											if (hook.webhook_type === 'jira') {
												return (
													<JiraRow
														key={'jira' + index}
														index={index}
														{...cred}
														onChangeProject={e =>
															setValue(`api_creds[${index}].projectKey`, e.target.value)
														}
														onChangeIssue={e =>
															setValue(`api_creds[${index}].issueType`, e.target.value)
														}
														onChangeParentId={e =>
															setValue(
																`api_creds[${index}].parent_id`,
																e.currentTarget.value
															)
														}
														onChangeLabel={labels =>
															setValue(
																`api_creds[${index}].labels`,
																labels.map(label => label.label)
															)
														}
														onRemoveRow={() => {
															if (index !== 0) {
																removeArrayIndex('endpoint', index);
																removeArrayIndex('api_creds', index);
															} else {
																setValue(`api_creds[${index}].projectKey`, '');
																setValue(`api_creds[${index}].issueType`, '');
																setValue(`api_creds[${index}].parent_id`, '');
																setValue(`api_creds[${index}].labels`, []);
															}
														}}
													/>
												);
											}

											if (hook.webhook_type === 'basecamp') {
												return (
													<BasecampRow
														key={'basecamp' + index}
														index={index}
														{...cred}
														endpoint={hook.endpoint[index]}
														onChangeProject={({
															project_id,
															todo_set_id,
															endpoint,
															project_name,
														}) => {
															setValue(`endpoint[${index}]`, endpoint);
															setValue(`api_creds[${index}].project_id`, project_id);
															setValue(`api_creds[${index}].project_name`, project_name);
															setValue(`api_creds[${index}].todo_set_id`, todo_set_id);
														}}
														onChangeTodoList={todo => {
															setValue(`api_creds[${index}].todo_list_id`, todo.id);
															setValue(`api_creds[${index}].todo_list_name`, todo.name);
														}}
														onChangeAssignee={user => {
															setValue(`api_creds[${index}].assignee`, user?.id ?? '');
															setValue(
																`api_creds[${index}].assignee_name`,
																user?.name ?? ''
															);
														}}
														onRemoveRow={() => {
															if (index !== 0) {
																removeArrayIndex('endpoint', index);
																removeArrayIndex('api_creds', index);
															} else {
																setValue(`endpoint[${index}]`, '');
																setValue(`api_creds[${index}].project_id`, '');
																setValue(`api_creds[${index}].project_name`, '');
																setValue(`api_creds[${index}].todo_set_id`, '');
																setValue(`api_creds[${index}].todo_list_id`, '');
																setValue(`api_creds[${index}].todo_list_name`, '');
																setValue(`api_creds[${index}].assignee`, '');
																setValue(`api_creds[${index}].assignee_name`, '');
															}
														}}
													/>
												);
											}
										})}

										<Box mt={1}>
											<ActionButton
												action="add_list"
												onClick={() => {
													addToArray(
														'api_creds',
														credBase(hook.webhook_type, hook.api_creds[0]?.auth_id)
													);
													addToArray('endpoint', endpoint(hook.webhook_type));
												}}
											>
												{t`Add endpoint`}
											</ActionButton>
										</Box>
									</Box>
								)}
							</Grid>
						)}

						{['general', 'slack', 'rocketchat', 'googlechat', 'ms_teams'].indexOf(
							hook.webhook_type
						) > -1 && (
							<Grid
								item
								xs
							>
								<>
									<Typography variant="subtitle">{t`API Endpoints`}</Typography>
									<Typography
										color="textSecondary"
										gutterBottom
									>
										{t`Specifiy where the integration should point. You can add multiple URLs.`}
									</Typography>
									{hook.endpoint.map((endpointPath, endpointIndex) => {
										return (
											<Grid
												container
												key={'endpoint' + endpointIndex}
											>
												<Grid
													item
													xs
												>
													<ValidatedField
														fullWidth
														value={endpointPath.replace(SLACK_ENDPOINT, '')}
														InputProps={{
															...(hook.webhook_type === 'slack' && {
																startAdornment: (
																	<InputAdornment position="start">
																		{SLACK_ENDPOINT}
																	</InputAdornment>
																),
															}),
														}}
														placeholder={
															hook.webhook_type !== 'slack'
																? t`https://your-api-url.com/endpoint`
																: t`end-point`
														}
														onChange={e => {
															const endpoint = `${
																hook.webhook_type === 'slack' ? SLACK_ENDPOINT : ''
															}${e.currentTarget.value}`;
															setValue(`endpoint[${endpointIndex}]`, endpoint);
														}}
														rules={
															hook.webhook_type !== 'slack'
																? [['urlHttps', true]]
																: [['required', 'true']]
														}
														errorMessage={
															hook.webhook_type !== 'slack'
																? t`Please use a valid URL, only URLs starting with https:// are supported`
																: `Please enter an endpoint`
														}
														showErrors={clicked}
														dataTestElement="apiNewEndpoint"
													/>
												</Grid>
												<Grid item>
													<ActionIconButton
														tooltip={t`Remove endpoint`}
														action="remove_list"
														onClick={() => {
															if (hookState.hook?.endpoint.length === 1) {
																setValue('endpoint[0]', '');
															} else {
																removeArrayIndex('endpoint', endpointIndex);
															}
														}}
													/>
												</Grid>
											</Grid>
										);
									})}

									<ActionButton
										action="add_list"
										label={t`Endpoint`}
										onClick={() => addToArray('endpoint', '')}
									/>
								</>
							</Grid>
						)}

						{hook.webhook_type === 'trello' && (
							<Grid
								item
								xs
							>
								<>
									{get(hook, 'trello_apicreds[0].username') &&
									get(hook, 'trello_apicreds[0].key') &&
									get(hook, 'trello_apicreds[0].token') ? (
										<Alert>{t`Trello connection successfully established`}</Alert>
									) : (
										<>
											<Typography variant="subtitle">{t`Connect Trello`}</Typography>
											<ol>
												<li>
													<Typography variant="body1">
														{t`Make sure you're logged in to your Trello account and enter your`}{' '}
														<Link
															href="https://trello.com/your/account"
															target="_blank"
														>{t`Trello username`}</Link>{' '}
														{t`and`}{' '}
														<Link
															href="https://trello.com/app-key"
															target="_blank"
														>{t`Trello API key`}</Link>
														.
													</Typography>
												</li>
												<li>
													<Typography variant="body1">{t`Click 'Connect to Trello', a new window will open where you can authorize the connection and grab the API token.`}</Typography>
												</li>
												<li>
													<Typography variant="body1">{t`Enter the API token and select to which board and list the integration should point.`}</Typography>
												</li>
												<li>
													<Typography variant="body1">{t`Save your integration.`}</Typography>
												</li>
											</ol>
										</>
									)}

									{hook.trello_apicreds.map((trelloCred, trelloCredIndex) => {
										return (
											<div key={'trello' + trelloCredIndex}>
												{!trelloCred.token && (
													<>
														<Grid
															container
															spacing={2}
														>
															<Grid
																item
																xs={6}
															>
																<ValidatedField
																	fullWidth
																	label={t`Username`}
																	value={trelloCred.username}
																	onChange={e =>
																		setValue(
																			`trello_apicreds[${trelloCredIndex}].username`,
																			e.currentTarget.value
																		)
																	}
																	InputProps={{
																		startAdornment: <InputAdornment>@</InputAdornment>,
																	}}
																	errorMessage={t`Please insert your Trello username`}
																	rules={[['required', true]]}
																	showErrors={clicked}
																/>
															</Grid>
															<Grid
																item
																xs={6}
															>
																<ValidatedField
																	fullWidth
																	label={t`API key`}
																	value={trelloCred.key}
																	onChange={e =>
																		setValue(
																			`trello_apicreds[${trelloCredIndex}].key`,
																			e.currentTarget.value
																		)
																	}
																	errorMessage={t`Please insert your Trello API key`}
																	rules={[['required', true]]}
																	showErrors={clicked}
																	// InputProps={{
																	// 	endAdornment:<InputAdornment>
																	// 		<ActionButton
																	// 			action="connect"
																	// 			label={t`Connect`}
																	// 			size="small"
																	// 			onClick={() => {
																	// 				if (trelloCred.username && trelloCred.key) {
																	// 					window.open(`https://trello.com/1/authorize?expiration=never&name=MopinionWebhook&scope=read,write&response_type=token&key=${trelloCred.key}`)
																	// 				}
																	// 			}}
																	// 		/>
																	// 	</InputAdornment>
																	// }}
																/>
															</Grid>
														</Grid>
														<Box mt={1}>
															<ActionButton
																action="connect"
																label={t`Connect to Trello`}
																//size="small"
																onClick={() => {
																	if (trelloCred.username && trelloCred.key) {
																		window.open(
																			`https://trello.com/1/authorize?expiration=never&name=MopinionWebhook&scope=read,write&response_type=token&key=${trelloCred.key}`
																		);
																	}
																}}
															/>
														</Box>
													</>
												)}

												{trelloCred.username && trelloCred.key && !trelloCred.token && (
													<ValidatedField
														label={t`API token`}
														fullWidth
														value={trelloCred.token}
														onChange={e =>
															setValue(
																`trello_apicreds[${trelloCredIndex}].token`,
																e.currentTarget.value
															)
														}
														errorMessage={t`Please insert your Trello API token`}
														rules={[['required', true]]}
														showErrors={clicked}
													/>
												)}

												{trelloCred.token && (
													<TrelloFlow
														clicked={clicked}
														{...trelloCred}
														setBoard={(id, name) => {
															setValue(`trello_apicreds[${trelloCredIndex}].idBoard`, id);
															setValue(
																`trello_apicreds[${trelloCredIndex}].nameBoard`,
																name
															);
														}}
														setList={(id, name) => {
															setValue(`trello_apicreds[${trelloCredIndex}].idList`, id);
															setValue(
																`trello_apicreds[${trelloCredIndex}].nameList`,
																name
															);
														}}
														apiKey={trelloCred.key}
													/>
												)}
											</div>
										);
									})}
									{get(hook, 'trello_apicreds[0].username') &&
										get(hook, 'trello_apicreds[0].key') &&
										get(hook, 'trello_apicreds[0].token') && (
											<ActionButton
												action="add_list"
												label={t`Add list`}
												onClick={() => {
													addToArray('endpoint', TRELLO_ENDPOINT);
													addToArray(
														'trello_apicreds',
														trelloBase({
															username: get(hook, 'trello_apicreds[0].username', ''),
															key: get(hook, 'trello_apicreds[0].key', ''),
															token: get(hook, 'trello_apicreds[0].token', ''),
														})
													);
												}}
											/>
										)}
								</>
							</Grid>
						)}

						{hook.webhook_type === 'asana' && (
							<Grid
								item
								xs
							>
								<>
									{get(hook, 'asana_apicreds[0].refreshToken') &&
									get(hook, 'asana_apicreds[0].apiKey') ? (
										<Alert>{t`Asana connection successfully established`}</Alert>
									) : (
										<>
											<Typography variant="subtitle">{t`Connect Asana`}</Typography>

											<ol>
												<li>
													<Typography variant="body1">{t`Make sure you're logged in to your Asana account and click 'Get API token'.`}</Typography>
												</li>
												<li>
													<Typography variant="body1">{t`Enter your API token and select to which workspace and board the integration should point.`}</Typography>
												</li>
												<li>
													<Typography variant="body1">{t`Save your integration.`}</Typography>
												</li>
											</ol>
										</>
									)}

									{hook.asana_apicreds.map((asanaCred, asanaCredIndex) => {
										return (
											<div key={'asana' + asanaCredIndex}>
												{!asanaCred.refreshToken && (
													<>
														<ActionButton
															color="primary"
															variant="contained"
															action="link"
															label={t`Get API token`}
															onClick={() => {
																window.open(ASANA_API_TOKEN_URL);
															}}
														/>
														<ValidatedField
															label={t`API token`}
															fullWidth
															value={asanaCred.apiKey}
															onChange={e =>
																setValue(
																	`asana_apicreds[${asanaCredIndex}].apiKey`,
																	e.currentTarget.value
																)
															}
															errorMessage={t`Please insert your Asana API key`}
															rules={[['required', true]]}
															showErrors={clicked}
														/>
													</>
												)}
												{asanaCred.apiKey && (
													<AsanaFlow
														clicked={clicked}
														{...asanaCred}
														setRefreshToken={token =>
															setValue(
																`asana_apicreds[${asanaCredIndex}].refreshToken`,
																token
															)
														}
														setWorkspace={(id, name) => {
															setValue(
																`asana_apicreds[${asanaCredIndex}].workspaceId`,
																id
															);
															setValue(
																`asana_apicreds[${asanaCredIndex}].workspaceName`,
																name
															);
														}}
														setProject={(id, name) => {
															setValue(`asana_apicreds[${asanaCredIndex}].projectId`, id);
															setValue(
																`asana_apicreds[${asanaCredIndex}].projectName`,
																name
															);
														}}
													/>
												)}
											</div>
										);
									})}

									{get(hook, 'asana_apicreds[0].refreshToken') &&
										get(hook, 'asana_apicreds[0].apiKey') && (
											<ActionButton
												action="add_list"
												label={t`Add board`}
												onClick={() => {
													addToArray('endpoint', ASANA_ENDPOINT);
													addToArray(
														'asana_apicreds',
														asanaBase({
															refreshToken: get(
																hook,
																'asana_apicreds[0].refreshToken',
																''
															),
															apiKey: get(hook, 'asana_apicreds[0].apiKey', ''),
														})
													);
												}}
											/>
										)}
								</>
							</Grid>
						)}

						{['salesforce-form', 'html-form'].indexOf(hook.webhook_type) > -1 && (
							<Grid
								item
								xs
							>
								<>
									<Typography variant="subtitle">
										{hook.webhook_type === 'salesforce-form'
											? t`Connect Salesforce`
											: t`Connect an HTML form`}
									</Typography>
									{hook.webhook_type === 'salesforce-form' && (
										<>
											<Typography
												variant="body1"
												color="textSecondary"
												className={classes.margin}
											>
												{t`Select a form type and add your organisation ID. Optionally add a field where to store your data`}
											</Typography>

											<FormGroup className={classes.margin}>
												<FormLabel>{t`Form type`}</FormLabel>
												<RadioGroup
													aria-label="Salesforce formtype"
													name="sf-form"
													value={hook.sf_form}
													onChange={e => {
														setValue('sf_form', e.target.value);
													}}
													row
												>
													<FormControlLabel
														value="web-to-case"
														control={<Radio />}
														label="Web-to-Case"
														onClick={() => {
															setValue('endpoint', [SALESFORCE_ENDPOINTS[0]]);
															setValue('sf_form', 'web-to-case');
														}}
													/>
													<FormControlLabel
														value="web-to-lead"
														control={<Radio />}
														label="Web-to-Lead"
														onClick={() => {
															setValue('endpoint', [SALESFORCE_ENDPOINTS[1]]);
															setValue('sf_form', 'web-to-lead');
														}}
													/>
												</RadioGroup>
											</FormGroup>
										</>
									)}

									<div className={classes.margin}>
										<ValidatedField
											fullWidth
											value={hook.endpoint[0]}
											placeholder={t`https://form-endpoint.com/endpoint`}
											onChange={e => {
												setValue('endpoint', [e.currentTarget.value]);
											}}
											rules={[['urlHttps', true]]}
											errorMessage={`Please enter a valid URL, only URLs starting with https:// are supported`}
											showErrors={clicked}
											dataTestElement="apiNewEndpoint"
											label={t`Specifiy the HTML form action URL`}
										/>
									</div>

									{hook.webhook_type === 'salesforce-form' && (
										<>
											<div className={classes.margin}>
												<ValidatedField
													fullWidth
													label={t(
														'Salesforce organisation ID (input name "{{orgid}}")',
														{ orgid: hook.sf_form === 'web-to-case' ? 'orgid' : 'oid' }
													)}
													value={hook.org_id}
													placeholder={t`00DXXXXXXXXXXXX`}
													onChange={e => {
														setValue('org_id', e.currentTarget.value);
													}}
													rules={[['custom', v => isValidOrgId(v)]]}
													errorMessage={t`Please enter a valid Salesforce organisation ID`}
													showErrors={clicked}
												/>
											</div>

											<div className={classes.margin}>
												<TextField
													fullWidth
													label={t`Select a field where data will be stored (optional)`}
													value={hook.feedback_field}
													placeholder={t`For example: feedback_field`}
													onChange={e => {
														setValue('feedback_field', e.currentTarget.value);
													}}
												/>
											</div>
										</>
									)}
								</>
							</Grid>
						)}
					</>
				)}
				{getStepValue(type, activeStep) === 'data' && (
					<>
						{hook.event_type === 'created' && (
							<>
								<Grid
									item
									xs
								>
									<Typography variant="subtitle">{t`Integration data`}</Typography>
									<Typography
										color="textSecondary"
										gutterBottom
									>
										{t`Setup what data the integration should contain. Integrations are ran for every new feedback item in your selected datasource.`}
									</Typography>
									{!survey_id && (
										<ValidatedField
											component={DataSourceSelect}
											label={t`Select a data source`}
											value={
												hook.survey_id
													? hook.survey_id
													: hook.all_project_data
													? 'ALL_DATA'
													: ''
											}
											withAllData={
												['salesforce-form', 'html-form'].indexOf(hook.webhook_type) === -1
											}
											onChange={e => {
												const { value } = e.target;

												if (value === 'ALL_DATA') {
													setValue('survey_id', null);
													setValue('all_project_data', true);
												} else {
													setValue('survey_id', e.target.value);
													setValue('all_project_data', false);
												}
											}}
											validateValue={hook.survey_id || hook.all_project_data}
											errorMessage={t`Please select a form or dataset`}
											rules={[['required', true]]}
											showErrors={clicked}
										/>
									)}
								</Grid>

								{hook.survey_id &&
									['salesforce-form', 'html-form'].indexOf(hook.webhook_type) > -1 && (
										<>
											<Grid
												item
												xs
											>
												<Box mt={1}>
													<FormLabel>
														{hook.webhook_type == 'salesforce-form'
															? t`Connect datafields to your Salesforce form`
															: t`Connect datafields to your HTML form`}
													</FormLabel>
													{hook.extra_fields &&
														hook.extra_fields.map((param, paramIndex) => {
															return (
																<Grid
																	container
																	key={'param' + paramIndex}
																	spacing={2}
																	alignItems="center"
																>
																	<Grid
																		item
																		xs={6}
																	>
																		<DataFieldSelect
																			value={hook.extra_fields[paramIndex].value}
																			renderValueTextOnly
																			sourceId={hook.survey_id}
																			onChange={e => {
																				setValue(
																					`extra_fields[${paramIndex}].value`,
																					e.target.value
																				);
																			}}
																		/>
																	</Grid>
																	<Grid
																		item
																		xs={6}
																	>
																		<TextField
																			fullWidth
																			value={hook.extra_fields[paramIndex].key}
																			placeholder={t`Form input name`}
																			onChange={e =>
																				setValue(
																					`extra_fields[${paramIndex}].key`,
																					e.currentTarget.value
																				)
																			}
																		/>
																	</Grid>
																</Grid>
															);
														})}
													{hook.webhook_type == 'salesforce-form' &&
														hook.sf_form === 'web-to-lead' && (
															<Typography
																display="block"
																variant="caption"
																color="textSecondary"
																className={
																	clicked && !pageValid(getStepIndex(type, 'data'))
																		? classes.error
																		: ''
																}
																gutterBottom
															>
																{t`Must at least include an email field`}
															</Typography>
														)}
													<ActionButton
														action="add_list"
														onClick={() => addToArray('extra_fields', HTMLformBase())}
														label={t`Field`}
													/>
												</Box>
											</Grid>

											<Grid
												item
												xs
											>
												<Box mt={1}>
													<FormLabel>
														{hook.webhook_type == 'salesforce-form'
															? t`Link custom values to your Salesforce form`
															: t`Connect custom values to your HTML form`}
													</FormLabel>
													{hook.prefilled_fields &&
														hook.prefilled_fields.map((param, paramIndex) => {
															return (
																<Grid
																	container
																	key={'param' + paramIndex}
																	spacing={2}
																	alignItems="center"
																>
																	<Grid
																		item
																		xs={6}
																	>
																		<TextField
																			value={hook.prefilled_fields[paramIndex].value}
																			placeholder={t`Value to send`}
																			fullWidth
																			onChange={e =>
																				setValue(
																					`prefilled_fields[${paramIndex}].value`,
																					e.currentTarget.value
																				)
																			}
																		/>
																	</Grid>
																	<Grid
																		item
																		xs={6}
																	>
																		<TextField
																			value={hook.prefilled_fields[paramIndex].key}
																			placeholder={t`Form input name`}
																			fullWidth
																			onChange={e =>
																				setValue(
																					`prefilled_fields[${paramIndex}].key`,
																					e.currentTarget.value
																				)
																			}
																		/>
																	</Grid>
																</Grid>
															);
														})}
													<Typography
														display="block"
														variant="caption"
														color="textSecondary"
														gutterBottom
													>
														{t`Use to categorize data, for example: Low NPS customer`}
													</Typography>
													<ActionButton
														action="add_list"
														onClick={() => addToArray('prefilled_fields', HTMLformBase())}
														label={t`Field`}
													/>
												</Box>
											</Grid>
										</>
									)}

								{!hook.all_project_data &&
									hook.survey_id &&
									['salesforce-form', 'html-form'].indexOf(hook.webhook_type) < 0 && (
										<Grid
											item
											xs
										>
											<FormGroup>
												<FormLabel>{t`What data should be sent?`}</FormLabel>
												<RadioGroup
													//row
													value={
														hook.parameters.all && hook.parameters.aliasses?.length === 0
															? 'all'
															: hook.parameters.all &&
															  hook.parameters.aliasses?.length > 0
															? 'aliasses'
															: 'only_datafields'
													}
													onChange={e => {
														const { value } = e.target;

														switch (value) {
															case 'all':
																setValue('parameters.all', true);
																setValue('parameters.aliasses', []);
																return;
															case 'aliasses':
																setValue('parameters.all', true);
																if (hook.parameters.aliasses?.length === 0) {
																	setValue('parameters.aliasses', [paramBase()]);
																}
																return;
															case 'only_datafields':
																setValue('parameters.all', false);
																if (hook.parameters.aliasses?.length === 0) {
																	setValue('parameters.aliasses', [paramBase()]);
																}
																return;
															default:
																return;
														}
													}}
												>
													<FormControlLabel
														value="all"
														control={<Radio />}
														label={t`Send all data inside feedback item`}
													/>
													<FormControlLabel
														value="aliasses"
														control={<Radio />}
														label={t`Send all data inside feedback item with aliasses`}
													/>
													<FormControlLabel
														value="only_datafields"
														control={<Radio />}
														label={t`Send only specific datafields`}
													/>
												</RadioGroup>
											</FormGroup>

											{hook.parameters.aliasses?.length > 0 && (
												<Box mt={1}>
													<FormLabel>
														{hook.parameters.all
															? t`Rename datafields`
															: t`Datafields to send`}
													</FormLabel>
													{hook.parameters.aliasses.map((param, paramIndex) => {
														return (
															<Grid
																container
																key={'param' + paramIndex}
																spacing={2}
																alignItems="center"
															>
																<Grid
																	item
																	xs={6}
																>
																	<DataFieldSelect
																		value={hook.parameters.aliasses[paramIndex].datafield}
																		renderValueTextOnly
																		sourceId={hook.survey_id}
																		onChange={e => {
																			setValue(
																				`parameters.aliasses[${paramIndex}].datafield`,
																				e.target.value
																			);
																		}}
																	/>
																</Grid>
																<Grid
																	item
																	xs={6}
																>
																	<TextField
																		placeholder={t`Specify alias (optional)`}
																		fullWidth
																		onChange={e =>
																			setValue(
																				`parameters.aliasses[${paramIndex}].label`,
																				e.currentTarget.value
																			)
																		}
																		value={hook.parameters.aliasses[paramIndex].label}
																	/>
																</Grid>
															</Grid>
														);
													})}
													<ActionButton
														action="add_list"
														onClick={() => addToArray('parameters.aliasses', paramBase())}
														label={hook.parameters.all ? t`Alias` : t`Field`}
													/>
												</Box>
											)}
										</Grid>
									)}

								{!hook.all_project_data && hook.survey_id && (
									<Grid item>
										<FormGroup>
											<FormLabel>{t`Send data`}</FormLabel>
											<RadioGroup
												//row
												value={hook.all_data ? 'all' : 'specific'}
												onChange={e => {
													setValue('all_data', e.target.value === 'all');
													setValue(
														'conditions',
														e.target.value === 'all' ? [] : [conditionBase()]
													);
												}}
											>
												<FormControlLabel
													value="all"
													control={<Radio />}
													label={t`For all new feedback items`}
												/>
												<FormControlLabel
													value="specific"
													control={<Radio />}
													label={t`When an item contains specific values`}
												/>
											</RadioGroup>
										</FormGroup>

										{!hook.all_data && (
											<Box mt={1}>
												<Box>
													<FormLabel>{t`Send when`}</FormLabel>
													<DataFieldConditions
														conditionArray={hook.conditions.map(condition => ({
															datafield_id: condition.datafieldid,
															operator: condition.condition,
															value: condition.target,
														}))}
														surveyId={hook.survey_id}
														operatorFn={operators}
														onDataFieldChange={(feedbackConditionIndex, e) => {
															setValue(
																`conditions[${feedbackConditionIndex}].datafieldid`,
																e.target.value
															);
															setValue(
																`conditions[${feedbackConditionIndex}].target`,
																''
															);
														}}
														onOperatorChange={(feedbackConditionIndex, e) => {
															setValue(
																`conditions[${feedbackConditionIndex}].condition`,
																e.target.value
															);
														}}
														onPossibleValueChange={(feedbackConditionIndex, value) => {
															setValue(
																`conditions[${feedbackConditionIndex}].target`,
																String(value)
															);
														}}
														PossibleValuesProps={{
															multiple: false,
														}}
														onRemoveRow={feedbackConditionIndex =>
															removeArrayIndex('conditions', feedbackConditionIndex)
														}
													/>
												</Box>
												<Box
													mt={1}
													clone
												>
													<ActionButton
														onClick={() => addToArray('conditions', conditionBase())}
														action="add_list"
														label={t`And`}
													/>
												</Box>
											</Box>
										)}
									</Grid>
								)}
							</>
						)}

						<Grid
							item
							xs
						>
							<ValidatedField
								component={TextField}
								label={t`Integration name`}
								placeholder={t`For example: Add issue into Bugs board`}
								fullWidth
								onChange={e => setValue('name', e.currentTarget.value)}
								value={hook.name}
								validateValue={hook.name}
								errorMessage={t`Please name your integration`}
								rules={[['required', true]]}
								showErrors={clicked}
							/>
						</Grid>

						{hook.event_type !== 'user_init' && (
							<Grid
								item
								xs
							>
								<div>
									<FormControlLabel
										control={<Checkbox />}
										checked={hook.active}
										label={t`Active`}
										onChange={e => setValue('active', e.target.checked)}
									/>
								</div>
							</Grid>
						)}
					</>
				)}
			</Grid>
		</SimpleDialog>
	);
}
