import React, { useEffect, useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import { makeStyles } from '@/styles';
import { useAppContext } from '@/components/AppContext';
import { useAjaxForm } from '@/components/Ajax';
import { ValidatedField } from '@/components/Validation';
import { Typography, Link, Grid, Alert, ActionButton } from '@/components/Layout';
import { loginMessages } from './constants';

const useStyles = makeStyles(theme => ({
	inputPanel: {
		maxWidth: 500,
	},
	alignRight: {
		textAlign: 'right',
	},
}));

export default function LoginMfa(props) {
	const classes = useStyles();
	const { app } = useAppContext();
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const pasted = useRef(false);

	const requestToken = useAjaxForm({
		url: '/auth/ajax/login-mfa',
	});

	useEffect(() => {
		if (!location.state?.redirected) {
			history.push('/login');
		}
	}, [location.state?.redirected, history]);

	//send token
	useEffect(() => {
		setTimeout(requestToken.postForm);
	}, []);

	const [mfa, setMfa] = useState('');
	const [valid, setValid] = useState(false);

	const { postForm, loading, response, clicked } = useAjaxForm({
		url: '/auth/ajax/login-mfa',
		valid: () => pasted.current || valid,
		data: {
			mfa,
		},
		onSuccess: response => {
			if (response.error === 'not_required') {
				history.push('/login', { suspended: true });
			}
			if (response.error === 'not_required') {
				history.push('/login');
			} else if (response.error === 'mfa_not_set') {
				history.push('/account/me');
			} else if (response.logged_in) {
				app.api.startApp();
			}
		},
	});

	return (
		<>
			<div className={classes.inputPanel}>
				<Grid
					container
					direction="row"
					spacing={3}
				>
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="h4"
							gutterBottom
						>
							{t('login_mfa_title_{{name}}', { name: location.state?.firstname ?? '' })}
						</Typography>
					</Grid>

					<Grid
						item
						xs={12}
					>
						<Typography variant="body1">{t`login_mfa_token`}</Typography>
					</Grid>

					{response.error && (
						<Grid
							item
							xs={12}
						>
							<Alert
								variant="filled"
								severity={loginMessages[response.error]?.severity}
								title={t(loginMessages[response.error]?.title)}
							>
								{t(loginMessages[response.error]?.message)}
							</Alert>
						</Grid>
					)}

					<Grid
						item
						xs={12}
					>
						<ValidatedField
							variant="outlined"
							fullWidth
							autoFocus
							label={t`login_mfa_tokeninput`}
							value={mfa}
							showErrors={clicked}
							rules={{
								required: true,
							}}
							messages={{
								required: t`login_mfa_errormessage`,
							}}
							onValidChange={valid => setValid(valid)}
							onChange={e => {
								const { value } = e.currentTarget;
								setMfa(value);
							}}
							onKeyPress={e => {
								if (e.key === 'Enter') {
									postForm();
								}
							}}
							onPaste={e => {
								const pastedText = e.clipboardData.getData('Text');
								pasted.current = true;
								postForm({
									post: {
										mfa: pastedText,
									},
								});
							}}
						/>
					</Grid>

					<Grid
						item
						xs={12}
					>
						<ActionButton
							size="large"
							fullWidth
							variant="contained"
							color="primary"
							onClick={postForm}
							loading={loading}
						>
							{t`login_mfa_verify_token`}
						</ActionButton>
					</Grid>

					<Grid
						item
						xs={6}
					>
						<Link
							variant="body2"
							href="/auth/ajax/logout"
						>
							{t('login_mfa_logout_{{name}}', {
								name: `${location.state?.firstname ?? ''} ${
									location.state?.lastname ?? ''
								}`,
							})}
						</Link>
					</Grid>

					<Grid
						item
						xs={6}
						className={classes.alignRight}
					>
						<Typography variant="body2">
							{`${t`login_mfa_no_token`} `}
							<Link
								variant="body2"
								target="_blank"
								href="https://authy.com/download/"
								rel="noopener"
							>
								{t`login_mfa_no_token_link`}
							</Link>
						</Typography>
						<Link
							variant="body2"
							onClick={requestToken.postForm}
							disabled={loading || requestToken.loading}
						>
							{t`login_mfa_text_message`}
						</Link>
						{/* currently not supported but authy api -> migrate to verify first
							<Typography variant="body2">
								{`${t`text_general_or`} `}
								<Link
									variant="body2"
									onClick={resendMfaPhone}
									disabled={loading || resendMfaLoading}
								>
									{t`login_mfa_phone`}
								</Link>
						</Typography> */}
					</Grid>
				</Grid>
			</div>
		</>
	);
}
