import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
	Grid,
	SystemElementCardBase,
	ActionIconButton,
	SimpleDialog,
	CopyCodeTextField,
	Typography,
	Box,
	Alert,
} from '@/components/Layout';

import {
	PermissionGroupAutocomplete,
	useHasPermission,
	NoPermissionTooltip,
} from '@/components/Permission';

import { ValidatedField } from '@/components/Validation';
import { useAjaxForm } from '@/components/Ajax';

const useStyles = makeStyles(theme => ({
	mono: {
		fontFamily: '"Ubuntu Mono", monospace',
		color: theme.palette.text.secondary,
		minWidth: 0,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		padding: theme.spacing(1.8),
	},
	codeColor: {
		color: theme.palette.text.secondary,
	},
}));

export default function ApiCard({ integration, loadData }) {
	const hasEditPermission = useHasPermission();
	const classes = useStyles();
	const { t } = useTranslation();
	const [dialogOpen, setDialogOpen] = useState('');
	const [name, setName] = useState(integration.name);
	const [groups, setGroups] = useState(integration?.permission_groups);
	const { enqueueSnackbar } = useSnackbar();

	// todo: make hook
	const { postForm, loading, clicked, response } = useAjaxForm({
		url: `/integrations/api/${dialogOpen}`,
		data: {
			name,
			id: integration.id,
			public_key: integration.public_key,
			groups,
		},
		valid: () => name,
		onSuccess: r => {
			if (r.code === 403) {
				enqueueSnackbar(t`You need to upgrade your package to add more integrations`);
			} else {
				setDialogOpen('');
				loadData();
				enqueueSnackbar(
					`${t`Integration`} ${name} ${dialogOpen === 'edit' ? t`updated` : t`removed`}`
				);
			}
		},
		onFail: r => {
			enqueueSnackbar(t`Something went wrong`);
		},
	});

	return (
		<Fragment>
			<SystemElementCardBase
				img={'/assets/img/r/api-integration.svg'}
				title={integration.name}
				actions={
					<Grid
						container
						alignItems="center"
					>
						<Grid
							item
							xs
						>
							<NoPermissionTooltip>
								<ActionIconButton
									tooltip={t`Edit name`}
									action="edit"
									onClick={() => setDialogOpen('edit')}
									disabled={!hasEditPermission}
								/>
							</NoPermissionTooltip>
							<NoPermissionTooltip>
								<ActionIconButton
									tooltip={t`Delete API integration`}
									action="delete"
									onClick={() => setDialogOpen('delete')}
									disabled={!hasEditPermission}
								/>
							</NoPermissionTooltip>
						</Grid>
					</Grid>
				}
			>
				<Grid
					container
					spacing={1}
				>
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="subtitle2"
							className={classes.codeColor}
						>{t`Public key`}</Typography>
						<CopyCodeTextField
							inputProps={{ className: classes.mono }}
							message={`${integration.public_key} ${t`copied to clipboard`}`}
							value={integration.public_key}
							className={classes.codeColor}
							compact
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="subtitle2"
							className={classes.codeColor}
						>{t`Private key`}</Typography>
						<CopyCodeTextField
							inputProps={{ className: classes.mono }}
							message={`${integration.private_key} ${t`copied to clipboard`}`}
							value={integration.private_key}
							className={classes.codeColor}
							compact
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="subtitle2"
							className={classes.codeColor}
						>{t`Token`}</Typography>
						<CopyCodeTextField
							inputProps={{ className: classes.mono }}
							message={`${integration.signature_token} ${t`copied to clipboard`}`}
							value={integration.signature_token}
							className={classes.codeColor}
							compact
						/>
					</Grid>
				</Grid>
			</SystemElementCardBase>

			<SimpleDialog
				open={dialogOpen}
				onClose={() => setDialogOpen('')}
				title={
					dialogOpen === 'edit'
						? t`integration-feedback_api-add_edit_dialog-title`
						: t`integration-feedback_api-delete_dialog-title`
				}
				submit={
					dialogOpen === 'edit'
						? t`integration-feedback_api-add_edit_dialog-submit`
						: t`integration-feedback_api-delete_dialog-title`
				}
				onSubmit={postForm}
				loading={loading}
				dataTestElement={
					dialogOpen === 'edit' ? 'apiEditIntegration' : 'apiDeleteIntegration'
				}
				dataTrackEvent={
					dialogOpen === 'edit' ? 'integrations_api_edited' : 'integrations_api_deleted'
				}
				text={
					dialogOpen === 'delete'
						? t('integration-feedback_api-delete_dialog-text', { name: integration.name })
						: null
				}
			>
				<Box mb={2}>
					{dialogOpen === 'edit' && (
						<ValidatedField
							label={t`integration-feedback_api-add_edit_dialog-label`}
							placeholder={t`integration-feedback_api-add_edit_dialog-placeholder`}
							value={name}
							onChange={e => setName(e.target.value)}
							fullWidth
							showErrors={clicked}
							rules={[['required', true]]}
							dataTestElement={dialogOpen === 'edit' ? 'apiEditName' : 'apiDeleteName'}
						/>
					)}
				</Box>
				{dialogOpen === 'edit' && (
					<>
						<PermissionGroupAutocomplete
							label={t`integration-feedback_api-add_edit_dialog-access_groups-label`}
							multiple
							value={groups}
							onChange={(e, value) => setGroups(value.map(group => group.uuid))}
						/>
						<Alert variant="default">
							{t`integration-feedback_api-add_edit_dialog-access_groups-alert`}
						</Alert>
					</>
				)}
			</SimpleDialog>
		</Fragment>
	);
}
