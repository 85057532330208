import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

export default function SelectShowEmpty({ fullWidth = true, children, label, ...props }) {
	return (
		<FormControl fullWidth={fullWidth}>
			{label && <InputLabel shrink>{label}</InputLabel>}
			<Select
				{...props}
				displayEmpty
			>
				{children}
			</Select>
		</FormControl>
	);
}
