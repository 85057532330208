//NOT USED

import React, { Component, Fragment } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import SortIcon from '@material-ui/icons/Sort';
import FilterIcon from '@material-ui/icons/FilterList';
import Tooltip from '@material-ui/core/Tooltip';

import Chip from '@material-ui/core/Chip';
import { EFM } from '../../api';
import { FeedbackListContext } from './FeedbackListContext';
import { SimpleSnackbar } from '../Snackbar';
import { ucFirst } from '../../utils';
const styles = {
	alignMiddle: {
		verticalAlign: 'middle',
	},
	buttonMargin: {
		marginLeft: 8,
	},
};

class FeedbackListControls extends Component {
	static defaultProps = {
		feedback: [],
		uncheckSelectAll: () => {},
	};

	static contextType = FeedbackListContext;

	state = {
		sort: 'feedback_created',
		order: 'desc',
		sortValue: 'feedback_created-desc',
		selectAllChecked: false,
	};

	setSort = e => {
		let [sort, order] = e.target.value.split('-');
		this.setState(
			state => {
				return { sort: sort, order: order, sortValue: e.target.value };
			},
			() => {
				this.context.setSort(sort, order);
			}
		);
	};

	handleSelectAllCheckbox = e => {
		this.setState({ selectAllChecked: e.target.checked }, () => {
			this.props.toggleSelectAll(this.state.selectAllChecked);
		});
	};

	uncheckSelectAll = () => {
		this.setState({ selectAllChecked: false });
	};

	exportFeedback = () => {
		EFM.post('/dashboard/inbox/export', { query_type: 'sql' }).then(r => {
			if (r.code === 200) {
				this.setState({ showExportMessage: true, exportMessage: r.message });
			} else {
				this.setState({
					showExportMessage: true,
					exportMessage: 'Something went wrong while exporting',
				});
			}
		});
	};

	currentFilters = () => {
		const { filters, setSingleFilter, setMultiFilter } = this.context;

		let cloud = ['actions', 'email', 'comment', 'tags', 'browser', 'os']
			.filter(key => filters[key])
			.map(key => {
				let value = filters[key];
				let label = ucFirst(key);
				if (value === '=' || value === '>') {
					return {
						label: `${label}: ${value === '=' ? 'Without ' + key : 'With ' + key}`,
						onDelete: e => setSingleFilter(key, ''),
						key: label + value,
					};
				} else {
					return {
						label: `${label}: ${value}`,
						onDelete: e => setMultiFilter(key, ''),
						key: label + value,
					};
				}
			});

		if (cloud.length === 0) return null;

		return (
			<Grid
				item
				container
				direction="row"
			>
				{cloud.map(obj => (
					<Chip {...obj} />
				))}
			</Grid>
		);
	};

	render() {
		const { feedback, classes } = this.props;
		const { sortValue, selectAllChecked, exportMessage, showExportMessage } = this.state;

		return (
			<Fragment>
				<Grid
					container
					direction="column"
				>
					<Grid
						item
						container
						direction="row"
						alignItems="center"
					>
						<Grid
							item
							xs
						>
							<Tooltip title="Select all">
								<Checkbox
									onChange={e => this.handleSelectAllCheckbox(e)}
									checked={selectAllChecked}
								/>
							</Tooltip>
							<Button
								color="default"
								variant="outlined"
								size="small"
								className={classes.buttonMargin}
								onClick={this.exportFeedback}
							>
								Export as .xls
							</Button>
						</Grid>
						<Grid item>
							<TextField
								select
								value={sortValue}
								onChange={this.setSort}
								// InputProps={{
								//   startAdornment: <InputAdornment position="start"><SortIcon /></InputAdornment>
								// }}
								classes={{
									root: classes.alignMiddle,
								}}
							>
								<MenuItem value={'feedback_created-desc'}>Date: new to old</MenuItem>
								<MenuItem value={'feedback_created-asc'}>Date: old to new</MenuItem>
								<MenuItem value={'customer_name-desc'}>Customer name: A to Z</MenuItem>
								<MenuItem value={'customer_name-asc'}>Customer name: Z to A</MenuItem>
							</TextField>

							<Tooltip title="Toggle filters">
								<IconButton
									onClick={e => this.props.toggleFilterDrawer()}
									className={classes.buttonMargin}
								>
									<FilterIcon />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
					{this.currentFilters()}
				</Grid>

				<SimpleSnackbar
					message={exportMessage}
					open={showExportMessage}
				/>
			</Fragment>
		);
	}
}

export default withStyles(styles, { withTheme: true })(FeedbackListControls);
