import React, { useMemo } from 'react';

import produce from 'immer';

import { useAppContext } from '../AppContext';
import { useMjolnir } from '../Ajax';
import { formatChart, parseSeriesFromServer } from './chartUtils';

export default function useChart(chartId) {
	const { app } = useAppContext();
	const [data, loading, error, loadData] = useMjolnir(
		`chart/${app.domain}/${app.projects.current.id}/${chartId}`
	);

	const series = useMemo(() => {
		return parseSeriesFromServer(data?.data?.series ?? []);
	}, [data?.data?.series]);

	const chart = useMemo(() => {
		return produce(data.highchart ?? {}, draft => {
			formatChart(draft);
			if (draft.chart) {
				draft.chart.seriesData = series;
			}
		});
	}, [data?.highchart, series]);

	return {
		chart,
		chartType: data?.data?.type ?? 1,
		series,
		loading,
		loadChart: loadData,
	};
}
