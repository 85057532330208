import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import {
	ActionButton,
	ContactDialog,
	Typography,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemSecondaryAction,
} from '@/components/Layout';
import { PreviewIcon } from '@/components/Icons';

const useStyles = makeStyles(theme => ({
	titleSpace: {
		marginBottom: theme.spacing(1),
	},
}));

export function InfoList({ list }) {
	const [contactReason, setContactReason] = useState('');

	return (
		<>
			<List>
				{list.map((item, index) => {
					return (
						<ListItem
							key={index}
							alignItems="center"
						>
							{item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
							{item.primary && (
								<ListItemText
									primary={item.primary}
									secondary={item.secondary}
								/>
							)}
							{item.action && (
								<ListItemSecondaryAction>{item.action}</ListItemSecondaryAction>
							)}
						</ListItem>
					);
				})}
			</List>
			<ContactDialog
				subject={contactReason}
				open={Boolean(contactReason)}
				onClose={() => setContactReason('')}
			/>
		</>
	);
}

export function SdkList() {
	const { t } = useTranslation();
	const classes = useStyles();

	const [contactReason, setContactReason] = useState('');

	return (
		<>
			<InfoList
				list={[
					{
						primary: (
							<Typography
								variant="subtitle2"
								className={classes.titleSpace}
							>{t`data_collection-preview-list_sdk-add_mopinion-title`}</Typography>
						),
						secondary: t`data_collection-preview-list_sdk-add_mopinion-text`,
					},
					{
						action: (
							<ActionButton
								color="primary"
								link
								to="/data-collection/deployment"
							>
								{t`data_collection-preview-list_sdk-add_mopinion-link`}
							</ActionButton>
						),
					},
					{
						icon: <PreviewIcon icon="sdk" />,
						primary: t`data_collection-preview-list_sdk-choose_sdk-title`,
						secondary: t`data_collection-preview-list_sdk-choose_sdk-text`,
					},
					{
						action: (
							<ActionButton
								color="primary"
								target="_blank"
								href="https://mopinion.atlassian.net/wiki/spaces/KB/pages/544833862/Where+do+I+find+all+available+SDKs"
							>
								{t`data_collection-preview-list_sdk-choose_sdk-link`}
							</ActionButton>
						),
					},
					{
						icon: <PreviewIcon icon="support" />,
						primary: t`data_collection-preview-list_sdk-ask_support-title`,
						secondary: t`data_collection-preview-list_sdk-ask_support-text`,
					},
					{
						action: (
							<ActionButton
								color="primary"
								onClick={() =>
									setContactReason(
										t`data_collection-preview-list_sdk-ask_support-contact`
									)
								}
							>
								{t`data_collection-preview-list_sdk-ask_support-link`}
							</ActionButton>
						),
					},
				]}
			/>
			<ContactDialog
				subject={contactReason}
				open={Boolean(contactReason)}
				onClose={() => setContactReason('')}
			/>
		</>
	);
}

export function EmailListSnippet() {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<InfoList
			list={[
				{
					primary: (
						<Typography
							variant="subtitle2"
							className={classes.titleSpace}
						>{t`data_collection-preview-list_email_deploy-title`}</Typography>
					),
					secondary: t`data_collection-preview-list_email_deploy-text`,
				},
			]}
		/>
	);
}

export function EmailList() {
	const { t } = useTranslation();

	const [contactReason, setContactReason] = useState('');

	return (
		<>
			<InfoList
				list={[
					{
						icon: <PreviewIcon icon="email" />,
						primary: t`data_collection-preview-list_email_use_snippet_title`,
						secondary: t`data_collection-preview-list_email_use_snippet_text`,
					},
					{
						action: (
							<ActionButton
								color="primary"
								target="_blank"
								href="https://mopinion.atlassian.net/wiki/spaces/KB/pages/503808240/How+to+setup+feedback+in+an+email"
							>
								{t`data_collection-preview-list_email_use_snippet_link`}
							</ActionButton>
						),
					},
					{
						icon: <PreviewIcon icon="support" />,
						primary: t`data_collection-preview-list_email_ask_support_title`,
						secondary: t`data_collection-preview-list_email_ask_support_text`,
					},
					{
						action: (
							<ActionButton
								color="primary"
								onClick={() =>
									setContactReason(
										t`data_collection-preview-list_email_ask_support_contact`
									)
								}
							>
								{t`data_collection-preview-list_email_ask_support_title`}
							</ActionButton>
						),
					},
				]}
			/>
			<ContactDialog
				subject={contactReason}
				open={Boolean(contactReason)}
				onClose={() => setContactReason('')}
			/>
		</>
	);
}

export function WebList() {
	const { t } = useTranslation();
	const classes = useStyles();

	const [contactReason, setContactReason] = useState('');

	return (
		<>
			<InfoList
				list={[
					{
						primary: (
							<Typography
								variant="subtitle2"
								className={classes.titleSpace}
							>{t`data_collection-preview-list_web_add_mopinion-title`}</Typography>
						),
						secondary: t`data_collection-preview-list_web_add_mopinion-text`,
					},
					{
						action: (
							<ActionButton
								color="primary"
								link
								to="/data-collection/deployment"
							>
								{t`data_collection-preview-list_web_add_mopinion-link`}
							</ActionButton>
						),
					},
					{
						icon: <PreviewIcon icon="google" />,
						primary: t`data_collection-preview-list_web_gtm-title`,
						secondary: t`data_collection-preview-list_web_gtm-text`,
					},
					{
						action: (
							<ActionButton
								color="primary"
								target="_blank"
								href="https://mopinion.atlassian.net/wiki/spaces/KB/pages/503873804/How+to+install+Mopinion+with+Google+Tag+Manager"
							>
								{t`data_collection-preview-list_web_gtm-link`}
							</ActionButton>
						),
					},
					{
						icon: <PreviewIcon icon="code" />,
						primary: t`data_collection-preview-list_web_paste_html-title`,
						secondary: t`data_collection-preview-list_web_paste_html-text`,
					},
					{
						action: (
							<ActionButton
								color="primary"
								target="_blank"
								href="https://mopinion.atlassian.net/wiki/spaces/KB/pages/503873575/How+do+I+implement+the+Mopinion+script"
							>
								{t`data_collection-preview-list_web_paste_html-link`}
							</ActionButton>
						),
					},
					{
						icon: <PreviewIcon icon="support" />,
						primary: t`data_collection-preview-list_web_ask_support-title`,
						secondary: t`data_collection-preview-list_web_ask_support-text`,
					},
					{
						action: (
							<ActionButton
								color="primary"
								onClick={() =>
									setContactReason(
										t`data_collection-preview-list_web_ask_support-contact`
									)
								}
							>
								{t`data_collection-preview-list_web_ask_support-link`}
							</ActionButton>
						),
					},
				]}
			/>
			<ContactDialog
				subject={contactReason}
				open={Boolean(contactReason)}
				onClose={() => setContactReason('')}
			/>
		</>
	);
}
