import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import RenderConditional from './RenderConditional';

const useStyles = makeStyles(theme => ({
	overlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(255,255,255,.3)',
		zIndex: 100,
	},
	empty: {
		textAlign: 'center',
		marginTop: props => props.offset ?? '20vh',
	},
	sticky: {
		position: 'sticky',
		...JSON.parse(JSON.stringify(theme.mixins.toolbar).replace(/minHeight/g, 'top')),
		zIndex: 10,
	},
	absolute: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
	},
	appBar: {
		position: 'fixed',
		...JSON.parse(JSON.stringify(theme.mixins.toolbar).replace(/minHeight/g, 'top')),
		left: 0,
		right: 0,
		marginTop: '1px',
		zIndex: 10,
	},
}));

export default function Loader({
	empty,
	sticky,
	circular,
	overlay,
	absolute,
	offset,
	appBar,
	...props
}) {
	const classes = useStyles({ offset });

	return (
		<RenderConditional
			component="div"
			condition={overlay}
			className={classes.overlay}
		>
			{empty ? (
				<div className={classes.empty}>
					<CircularProgress {...props} />
				</div>
			) : circular ? (
				<CircularProgress {...props} />
			) : (
				<LinearProgress
					className={`${sticky ? classes.sticky : ''} ${
						absolute ? classes.absolute : ''
					} ${appBar ? classes.appBar : ''}`}
					{...props}
				/>
			)}
		</RenderConditional>
	);
}
