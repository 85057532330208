import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { SelectionCard } from '../Layout';
import { WEBHOOK_TYPES } from './constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	card: {
		minHeight: 72,
	},
	img: {
		maxWidth: '100%',
		maxHeight: 16,
		marginBottom: theme.spacing(1),
	},
}));

export default function AuthCard(props) {
	const { type, name, email, add, ...rest } = props;

	const hook = WEBHOOK_TYPES.find(hook => hook.value === type) ?? {};
	const { t } = useTranslation();
	const classes = useStyles();

	if (add)
		return (
			<SelectionCard
				{...props}
				className={classes.card}
			/>
		);

	return (
		<SelectionCard
			{...rest}
			className={classes.card}
		>
			<img
				src={hook.img}
				className={classes.img}
			/>
			<Typography variant="subtitle2">{name}</Typography>
			<Typography
				variant="body2"
				color="textSecondary"
			>
				{hook.label} {t`connection`}
			</Typography>
		</SelectionCard>
	);
}
