import React, { useMemo, useEffect } from 'react';

import { useParams, useHistory } from 'react-router-dom';

function chunkArray(array, size) {
	return array.length > size
		? [array.slice(0, size), ...chunkArray(array.slice(size), size)]
		: [array];
}

function getPage(pages = [], pageId) {
	try {
		return pages[pageId - 1] ?? [];
	} catch (e) {
		return [];
	}
}

function getFirstItemNumber(pages = [], pageId) {
	let count = 0;
	for (let page of pages.slice(0, pageId - 1)) {
		count += page.length;
	}
	return count + 1;
}

function getLastItemNumber(pages = [], pageId) {
	let count = 0;
	for (let page of pages.slice(0, pageId)) {
		count += page.length;
	}
	return count;
}

export default function usePagination({
	pageId: pageIdFromProps,
	items = [],
	itemsPerPage = 50,
	base = '',
}) {
	const { pageId: pageIdFromParams = 1 } = useParams();
	const history = useHistory();
	const pageId = Number(pageIdFromProps ?? pageIdFromParams);

	const pagesArray = useMemo(() => {
		return chunkArray(items, itemsPerPage);
	}, [items, itemsPerPage]);

	useEffect(() => {
		if (pageId > pagesArray.length && !pageIdFromProps) {
			history.push(`${base}/${pagesArray.length}`);
		}
	}, [pagesArray, pageId, pageIdFromProps, base, history]);

	return {
		items: getPage(pagesArray, pageId),
		pageCount: pagesArray.length,
		firstItemNumber: getFirstItemNumber(pagesArray, pageId),
		lastItemNumber: getLastItemNumber(pagesArray, pageId),
		totalItemCount: items.length,
		base,
	};
}
