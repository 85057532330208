import React, { useState, useEffect } from 'react';
//isnt in packages?
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';

import { ActionIconButton } from '@/components/Layout';
import { filterDrawerWidth, filterDrawerBreakpoint } from '@/components/Layout/constants';
import { useTranslation } from 'react-i18next';

const filterDrawerMini = 60;

const useStyles = makeStyles(theme => ({
	filterDrawer: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		'@media (max-width: 960px)': {
			width: '100%',
		},
		minHeight: 'calc(100% - 64px)',
	},
	filterDrawerShift: {
		width: `calc(100% - ${filterDrawerWidth}px)`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	filterDrawerShiftStayVisibleCollapsed: {
		width: `calc(100% - ${filterDrawerMini}px)`,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	drawerOpen: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflowX: 'hidden',
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: `${filterDrawerMini}px!important`,
	},
	drawerPaper: {
		width: filterDrawerWidth,
		zIndex: theme.zIndex.appBar - 1,
	},
	persistentDrawerPaper: {
		...JSON.parse(JSON.stringify(theme.mixins.toolbar).replace(/minHeight/g, 'top')),
		height: `calc(100% - 64px)`,
	},
	filterGroupWrap: {
		padding: theme.spacing(1),
		'& > div:not(:last-child)': {
			marginBottom: theme.spacing(2),
		},
		'& > div': {
			maxWidth: '100%',
		},
	},
	closeWrap: {
		position: 'relative',
	},
	closeButton: {
		position: 'absolute',
		top: -8,
		right: 0,
	},
	filterGroups: {
		maxWidth: '100%',
	},
	disableScroll: {
		overflowY: 'hidden',
	},
	prominent: {
		paddingTop: theme.spacing(8),
	},
	textRight: {
		textAlign: 'right',
	},
}));

function Sheet({
	children,
	disableScroll,
	persistent,
	prominent,
	stayVisibleCollapsed,
	header,
	open,
	DrawerProps = {},
	onClose = () => {},
}) {
	const { t } = useTranslation();
	const classes = useStyles();

	const { PaperProps = {}, ...otherDrawerProps } = DrawerProps;

	return (
		<Drawer
			variant={
				stayVisibleCollapsed && window.innerWidth > filterDrawerBreakpoint
					? 'permanent'
					: persistent
					? 'persistent'
					: 'temporary'
			}
			anchor="right"
			classes={{
				paper: `${classes.drawerPaper} 
								${persistent ? classes.persistentDrawerPaper : ''}
								${disableScroll ? classes.disableScroll : ''} 
								${prominent && persistent ? classes.prominent : ''}
								${
									stayVisibleCollapsed && window.innerWidth > filterDrawerBreakpoint
										? open
											? classes.drawerOpen
											: classes.drawerClose
										: ''
								}`,
			}}
			open={open}
			onClose={onClose}
			PaperProps={{
				...PaperProps,
				'data-onboarding': 'list-with-sidebar-drawer',
			}}
			{...otherDrawerProps}
		>
			<div className={classes.filterGroupWrap}>
				<div className={classes.filterGroups}>
					<div className={classes.closeWrap}>
						{(!stayVisibleCollapsed || (stayVisibleCollapsed && open)) && (
							<>
								<Typography variant="h6">
									{header ? header : t`app_general-filtered_list-sidebar-header`}
								</Typography>
								<ActionIconButton
									className={classes.closeButton}
									action="close"
									onClick={onClose}
								/>
							</>
						)}
						{stayVisibleCollapsed && !open && (
							<ActionIconButton
								action="filters"
								tooltip={t`app_general-filtered_list-sidebar-toggle_filters`}
								onClick={onClose}
							/>
						)}
					</div>
				</div>
				{(!stayVisibleCollapsed || (stayVisibleCollapsed && open)) && children}
			</div>
		</Drawer>
	);
}

export default function ListWithSideBar({
	open,
	onClose,
	gridProps = {},
	DrawerProps = {},
	drawerContent = {},
	prominent,
	stayVisibleCollapsed,
	noDrawer = false,
	children,
}) {
	const classes = useStyles();
	const { t } = useTranslation();

	const [persistent, setPersistent] = useState(true);
	const [disableScroll, setDisableScroll] = useState(false);

	const drawerType = e => {
		if (window.innerWidth > filterDrawerBreakpoint && !noDrawer) {
			setPersistent(true);
		} else if (window.innerWidth < filterDrawerBreakpoint || noDrawer) {
			setPersistent(false);
		}
	};

	const toggleScroll = set => {
		setDisableScroll(set);
	};

	useEffect(() => {
		window.addEventListener('resize', drawerType);
		return function cleanup() {
			window.removeEventListener('resize', drawerType);
		};
	}, []);

	return (
		<Grid
			container
			wrap="nowrap"
			direction="column"
			className={`${classes.filterDrawer} 
				 ${persistent && open && !noDrawer ? classes.filterDrawerShift : ''}
				 ${
						stayVisibleCollapsed &&
						window.innerWidth > filterDrawerBreakpoint &&
						!open &&
						classes.filterDrawerShiftStayVisibleCollapsed
					}
			`}
			{...gridProps}
		>
			{stayVisibleCollapsed && window.innerWidth < filterDrawerBreakpoint && (
				<Grid
					item
					container
					direction="row"
					alignItems="center"
					className={classes.textRight}
				>
					<Grid
						item
						xs
					>
						<ActionIconButton
							action="filters"
							tooltip={t`app_general-filtered_list-sidebar-toggle_filters`}
							onClick={e => onClose()}
						/>
					</Grid>
				</Grid>
			)}
			{children}
			{!noDrawer ? (
				<Sheet
					disableScroll={disableScroll}
					persistent={persistent}
					prominent={prominent}
					open={open}
					onClose={e => onClose()}
					DrawerProps={DrawerProps}
					stayVisibleCollapsed={stayVisibleCollapsed}
				>
					{React.Children.map(drawerContent, child => {
						return React.cloneElement(child, {
							toggleScroll: toggleScroll,
						});
					})}
				</Sheet>
			) : null}
		</Grid>
	);
}
