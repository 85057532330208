import React, { useState, useEffect } from 'react';
import { create } from 'jss';
import { jssPreset, StylesProvider, makeStyles } from '@material-ui/core/styles';

import Portal from '@material-ui/core/Portal';

const useStyles = makeStyles(theme => ({
	iframeWrapper: {
		width: '100%',
		height: '100%',
	},
	iframeWrapperMobile: {
		position: 'absolute',
		width: 360,
		height: props => (props.campaign === 'sdk' ? 685 : 640),
		left: 'calc(50% - 179px)',
		border: 0,
		margin: 'auto',
		marginTop: props => (props.campaign === 'sdk' ? 90 : 115),
		marginBottom: 100,
	},
	desktop: {
		width: '100%',
		height: props => (props.formType === 'embed' ? '100%' : 'calc(100% - 0px)'),
		border: 0,
		margin: 'auto',
		backgroundColor: props =>
			props.campaign === 'sdk' ? theme.palette.background.default : 'transparent',
	},
	mobile: {
		position: 'absolute',
		width: 360,
		height: props => (props.campaign === 'sdk' ? 685 : 640),
		left: 'calc(50% - 180px)',
		border: 0,
	},
	sdk: {
		position: 'absolute',
		width: 360,
		height: props =>
			props.slideHeight && props.formType === 'slide' ? `${props.slideHeight}%` : '100%',
		minHeight: props =>
			props.slideHeight && props.formType === 'slide' ? `${props.slideHeight}%` : '100%',

		left: 'calc(50% - 180px)',
		border: 0,
		bottom: 0,
	},
}));

const INITIAL_HTML_DOCUMENT = '<!DOCTYPE html><html><head></head><body></body></html>';

export default function PreviewIframe({
	children,
	campaign,
	iFrame,
	formType,
	viewType,
	slideHeight,
}) {
	const [state, setState] = useState({
		ready: false,
	});

	const classes = useStyles({ campaign, formType, slideHeight });

	const iframeWindow = iFrame?.current?.contentWindow;
	const iframeBody = iframeWindow?.document?.body;

	useEffect(() => {
		if (iframeWindow?.document) {
			try {
				iframeWindow.document.open('text/htmlreplace');
				iframeWindow.document.write(INITIAL_HTML_DOCUMENT);
				iframeWindow.document.close();
			} catch (e) {}
		}
	}, [iframeWindow]);

	useEffect(() => {
		if (iframeWindow?.document?.head) {
			setState({
				ready: true,
				jss: create({
					plugins: [...jssPreset().plugins],
					insertionPoint: iframeWindow?.document.head,
				}),
				sheetsManager: new Map(),
			});
		}
	}, [iframeWindow]);

	return (
		<div
			className={
				viewType === 'mobile' || campaign === 'sdk'
					? classes.iframeWrapperMobile
					: classes.iframeWrapper
			}
		>
			<iframe
				title={'preview-iframe'}
				ref={iFrame}
				id="wrapper-iframe"
				className={campaign === 'sdk' ? classes.sdk : classes[viewType]}
				srcDoc={INITIAL_HTML_DOCUMENT}
			>
				{state.ready && (
					<StylesProvider
						jss={state.jss}
						sheetsManager={state.sheetsManager}
					>
						<Portal container={iframeBody}>{children}</Portal>
					</StylesProvider>
				)}
			</iframe>
		</div>
	);
}
