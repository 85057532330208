import usLocale from 'date-fns/locale/en-US';
import gbLocale from 'date-fns/locale/en-GB';
import nlLocale from 'date-fns/locale/nl';
import frLocale from 'date-fns/locale/fr';
import deLocale from 'date-fns/locale/nl';
import ruLocale from 'date-fns/locale/ru';
import brLocale from 'date-fns/locale/pt-BR';
import cnLocale from 'date-fns/locale/zh-CN';
import jpLocale from 'date-fns/locale/ja';

import dateFnsFormat from 'date-fns/format';
import dateFnsFormatDistance from 'date-fns/formatDistance';
import dateFnsDifferenceInDays from 'date-fns/differenceInDays';
import dateFnsDifferenceInSeconds from 'date-fns/differenceInSeconds';
import dateFnsIsValid from 'date-fns/isValid';
import dateFnsIsBefore from 'date-fns/isBefore';
import dateFnsIsAfter from 'date-fns/isAfter';
import dateFnsIsToday from 'date-fns/isToday';

import dateFnsGetHours from 'date-fns/getHours';
import dateFnsGetMinutes from 'date-fns/getMinutes';
import dateFnsGetDay from 'date-fns/getDay';
import dateFnsSubDays from 'date-fns/subDays';
import dateFnsSubWeeks from 'date-fns/subWeeks';
import dateFnsSubMonths from 'date-fns/subMonths';
import dateFnsSubYears from 'date-fns/subYears';

import dateFnsStartOfWeek from 'date-fns/startOfWeek';
import dateFnsStartOfMonth from 'date-fns/startOfMonth';
import dateFnsStartOfYear from 'date-fns/startOfYear';
import dateFnsEndOfWeek from 'date-fns/endOfWeek';
import dateFnsEndOfMonth from 'date-fns/endOfMonth';
import dateFnsEndOfYear from 'date-fns/endOfYear';

import dateFnsGetDaysInMonth from 'date-fns/getDaysInMonth';
import dateFnsGetDaysInYear from 'date-fns/getDaysInYear';

import dateFnsAddDays from 'date-fns/addDays';

import parseISO from 'date-fns/parseISO';
import isSameDay from 'date-fns/isSameDay';

export { parseISO, isSameDay };

const localeMap = {
	gb: gbLocale,
	nl: nlLocale,
	us: usLocale,
	fr: frLocale,
	de: deLocale,
	br: brLocale,
	cn: cnLocale,
	jp: jpLocale,
	ru: ruLocale,
};

let locale = gbLocale;
let usedLocale = '';

export function setDateLocale(countryCode, language) {
	if (localeMap[countryCode]) {
		locale = localeMap[countryCode];
	} else {
		locale = localeMap.nl;
	}
	usedLocale = countryCode;
}

export function getDateLocale(locale) {
	return localeMap[locale];
}

export function format(date, format, options = {}) {
	return dateFnsFormat(date, format, { ...options, locale });
}

export function formatDistance(date, baseDate, options = {}) {
	const allowedLocales = ['us', 'gb', 'nl'];
	const distanceLocale =
		allowedLocales.indexOf(usedLocale) !== -1 ? locale : localeMap['us'];

	return dateFnsFormatDistance(date, baseDate, {
		...options,
		locale: distanceLocale,
	});
}

export function differenceInDays(dateLeft, dateRight) {
	return dateFnsDifferenceInDays(dateLeft, dateRight);
}

export function differenceInSeconds(dateLeft, dateRight) {
	return dateFnsDifferenceInSeconds(dateLeft, dateRight);
}

export function isValid(date) {
	return dateFnsIsValid(date);
}

export function getMinutes(date) {
	return dateFnsGetMinutes(date);
}

export function getHours(date) {
	return dateFnsGetHours(date);
}

export function getDay(date) {
	return dateFnsGetDay(date);
}

export function subDays(date, amount) {
	return dateFnsSubDays(date, amount);
}

export function subWeeks(date, amount) {
	return dateFnsSubWeeks(date, amount);
}

export function subMonths(date, amount) {
	return dateFnsSubMonths(date, amount);
}

export function subYears(date, amount) {
	return dateFnsSubYears(date, amount);
}

export function startOfWeek(date, options = {}) {
	return dateFnsStartOfWeek(date, { ...options, locale });
}

export function startOfMonth(date, options = {}) {
	return dateFnsStartOfMonth(date, { ...options, locale });
}

export function startOfYear(date, options = {}) {
	return dateFnsStartOfYear(date, { ...options, locale });
}

export function endOfWeek(date, options = {}) {
	return dateFnsEndOfWeek(date, { ...options, locale });
}

export function endOfMonth(date, options = {}) {
	return dateFnsEndOfMonth(date, { ...options, locale });
}

export function endOfYear(date, options = {}) {
	return dateFnsEndOfYear(date, { ...options, locale });
}

export function getDaysInMonth(date) {
	return dateFnsGetDaysInMonth(date);
}

export function getDaysInYear(date) {
	return dateFnsGetDaysInYear(date);
}

export function isBefore(date, dateToCompare) {
	return dateFnsIsBefore(date, dateToCompare);
}

export function isAfter(date, dateToCompare) {
	return dateFnsIsAfter(date, dateToCompare);
}

export function isToday(date) {
	return dateFnsIsToday(date);
}

export function addDays(date, amount) {
	return dateFnsAddDays(date, amount);
}

export const dateFilters = [
	{ label: 'Today', action: 'today', exportLabel: 'The current day of the export' },
	{
		label: 'This week',
		action: 'this_week',
		exportLabel: 'The current week of the export',
	},
	{
		label: 'This month',
		action: 'this_month',
		exportLabel: 'The current month of the export',
	},
	{
		label: 'This year',
		action: 'this_year',
		exportLabel: 'The current year of the export',
	},
	{
		label: 'Previous week',
		action: 'prev_week',
		exportLabel: 'The week before the export date',
	},
	{
		label: 'Previous month',
		action: 'prev_month',
		exportLabel: 'The month before the export date',
	},
	{
		label: 'Previous year',
		action: 'prev_year',
		exportLabel: 'The year before the export date',
	},
	{
		label: 'Last 7 days',
		action: 'last_7',
		exportLabel: 'The last 7 days before the export date',
	},
	{
		label: 'Last 30 days',
		action: 'last_30',
		exportLabel: 'The last 30 days before the export date',
	},
	{
		label: 'Last 90 days',
		action: 'last_90',
		exportLabel: 'The last 90 days before the export date',
	},
];

export function dateFilterReducer(type, dateFormat, offset) {
	const today = offset
		? format(addDays(new Date(), 1), dateFormat)
		: format(new Date(), dateFormat);

	switch (type) {
		//this
		case 'today':
			return {
				toDate: today,
				fromDate: format(new Date(), dateFormat),
			};

		case 'this_week':
			return {
				toDate: today,
				fromDate: format(startOfWeek(new Date()), dateFormat),
			};

		case 'this_month':
			return {
				toDate: today,
				fromDate: format(startOfMonth(new Date()), dateFormat),
			};

		case 'this_year':
			return {
				toDate: today,
				fromDate: format(startOfYear(new Date()), dateFormat),
			};

		//previous
		case 'prev_week':
			return {
				toDate: format(endOfWeek(subWeeks(new Date(), 1)), dateFormat),
				fromDate: format(startOfWeek(subWeeks(new Date(), 1)), dateFormat),
			};

		case 'prev_month':
			return {
				toDate: format(endOfMonth(subMonths(new Date(), 1)), dateFormat),
				fromDate: format(startOfMonth(subMonths(new Date(), 1)), dateFormat),
			};

		case 'prev_year':
			return {
				toDate: format(endOfYear(subYears(new Date(), 1)), dateFormat),
				fromDate: format(startOfYear(subYears(new Date(), 1)), dateFormat),
			};

		//days
		case 'last_7':
			return {
				toDate: today,
				fromDate: format(subDays(new Date(), 7), dateFormat),
			};

		case 'last_30':
			return {
				toDate: today,
				fromDate: format(subDays(new Date(), 30), dateFormat),
			};

		case 'last_90':
			return {
				toDate: today,
				fromDate: format(subDays(new Date(), 90), dateFormat),
			};
	}
}
