import React from 'react';

import { EFM, Mjolnir } from '../../api';

class AjaxForm extends React.Component {
	static defaultProps = {
		valid: () => true,
		url: '',
		data: {},
		onSuccess: () => {},
		onError: () => {},
		type: 'efm',
		method: 'post',
	};

	state = {
		loading: false,
	};

	postForm = options => {
		this.setState({ clicked: true });
		if (this.props.valid()) {
			this.setState({ loading: true }, () => {
				if (this.props.type === 'efm') {
					EFM.post(this.props.url, this.props.data)
						.then(r => {
							this.setState({ ...r, loading: false }, () =>
								this.props.onSuccess(r, options)
							);
							if (this.props.resetClickedAfterSubmit) {
								this.setState({ clicked: false });
							}
						})
						.catch(e => {
							this.setState({ loading: false }, () => this.props.onError(e));
						});
				} else if (this.props.type === 'mjolnir') {
					Mjolnir[this.props.method](this.props.url, this.props.data)
						.then(r => {
							this.setState({ ...r, loading: false }, () =>
								this.props.onSuccess(r, options)
							);
							if (this.props.resetClickedAfterSubmit) {
								this.setState({ clicked: false });
							}
						})
						.catch(e => {
							this.setState({ loading: false }, () => this.props.onError(e));
						});
				}
			});
		}
	};

	render() {
		return this.props.children({
			...this.state,
			postForm: this.postForm,
		});
	}
}

export default AjaxForm;
