import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';
import {
	Typography,
	Box,
	ActionButton,
	ActionIconButton,
	ContactDialog,
} from '@/components/Layout';
import { useLocalStorage } from '@/hooks';

export default function SsoPromo() {
	const { t } = useTranslation();
	const { app } = useAppContext();
	const [contactOpen, setContactOpen] = useState(false);
	const [hide, setHide] = useLocalStorage(
		`hide-sso-promo-${app.users.current.id}`,
		false
	);

	if (hide) {
		return null;
	}

	return (
		<>
			<Box
				borderTop={1}
				borderColor="divider"
				p={2}
				position="absolute"
				left={0}
				right={0}
				bottom={0}
				bgcolor="common.white"
			>
				<Box
					position="absolute"
					right={8}
					top={8}
				>
					<ActionIconButton
						action="close"
						size="small"
						IconProps={{
							fontSize: 'small',
						}}
						onClick={() => setHide(true)}
						tooltip={t`settings-user_management-sso_promo-close`}
					/>
				</Box>
				<Typography
					variant="subtitle"
					mb={1}
				>{t`settings-user_management-sso_promo-title`}</Typography>
				<Typography mb={2}>{t`settings-user_management-sso_promo-content`}</Typography>
				<ActionButton
					variant="outlined"
					color="primary"
					size="small"
					onClick={() => setContactOpen(true)}
					data-track-event="sso_promo_contact_cta"
				>
					{t`settings-user_management-sso_promo-cta`}
				</ActionButton>
			</Box>

			<ContactDialog
				open={contactOpen}
				onClose={() => setContactOpen(false)}
				onSuccess={() => setContactOpen(false)}
				mailTo="success@mopinion.com"
				title={t`settings-user_management-sso_promo-content_dialog-title`}
				text={t`settings-user_management-sso_promo-content_dialog-content`}
				subject={t`settings-user_management-sso_promo-content_dialog-subject`}
				data-track-event="sso_promo_contact_request"
			/>
		</>
	);
}
