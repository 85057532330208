import React, { Component } from 'react';

import { SurveyEditor } from '../../components/Survey';
import { useParams } from 'react-router-dom';
function SurveyEditorPage() {
	const { surveyId } = useParams();
	return <SurveyEditor surveyId={surveyId} />;
}

export default SurveyEditorPage;
