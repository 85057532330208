import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MarginDenseIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			{
				<g>
					<g>
						<rect
							x="3.179"
							y="13.001"
							width="2"
							height="1"
						/>
						<path d="M30.894,14.001h-4.285v-1h4.285V14.001z M22.321,14.001h-4.285v-1h4.285V14.001z M13.75,14.001H9.465v-1h4.285V14.001z" />
						<rect
							x="35.179"
							y="13.001"
							width="2"
							height="1"
						/>
					</g>
					<g>
						<rect
							x="3.179"
							y="26.001"
							width="2"
							height="1"
						/>
						<path d="M30.894,27.001h-4.285v-1h4.285V27.001z M22.321,27.001h-4.285v-1h4.285V27.001z M13.75,27.001H9.465v-1h4.285V27.001z" />
						<rect
							x="35.179"
							y="26.001"
							width="2"
							height="1"
						/>
					</g>
					<polygon
						points="20.68,23.489 20.68,16.675 22.673,18.667 23.38,17.96 20.179,14.76 16.979,17.96 17.686,18.667 19.68,16.673 
						19.68,23.489 17.685,21.495 16.979,22.202 20.179,25.402 23.38,22.202 22.673,21.495 	"
					/>
				</g>
			}
		</SvgIcon>
	);
}
