export function sortByGroupId(arr = []) {
	return [...arr].sort((a, b) => b.group_id - a.group_id);
}

export function sortByActive(arr = []) {
	return [...arr].sort((a, b) => b.active - a.active);
}

export function filterActive(arr = []) {
	return arr.filter(field => field.active);
}

export function isMergeField(field = {}) {
	return (
		[4, 11, 12].includes(Number(field?.survey_block?.type)) &&
		!/extra.input/gi.test(field?.import_var)
	);
}
