import { useEffect } from 'react';

import useTrackingEvents from './useTrackingEvents';

export default function useVideoTracking() {
	const { track, timeEvent } = useTrackingEvents();
	let player;

	//load youtube iframe api so we can catch iframe events
	useEffect(() => {
		if (!window.YT) {
			const tag = document.createElement('script');
			tag.id = 'youtube-iframe-api';
			tag.src = 'https://www.youtube.com/iframe_api';

			window.onYouTubeIframeAPIReady = loadVideo;

			const firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
		} else {
			loadVideo();
		}
	}, []);

	const loadVideo = () => {
		try {
			// the Player object is created uniquely based on the id in props
			player = new window.YT.Player(`onboarding-video-iframe`, {
				events: {
					onReady: onPlayerReady,
					onStateChange: onPlayerStateChange,
				},
			});
		} catch (e) {}
	};

	function getVideoInfo() {
		//get some information about the video
		const videoInfo = player.getVideoData();
		const videoProps = {
			'video quality': player.getPlaybackQuality(),
			'video length': player.getDuration(),
			'video url': player.getVideoUrl(),
			'video title': videoInfo.title,
			'video id': videoInfo.video_id,
			'video author': videoInfo.video_id,
		};
		return videoProps;
	}

	function onPlayerReady() {
		//at this point the 'player' has finished loading
		const videoInfo = getVideoInfo();
		track('onboarding_introvideo_loaded', videoInfo);
		//start the 'video started' timer
		timeEvent('onboarding_introvideo_started');
	}

	function onPlayerStateChange(e) {
		trackPlayerChanges(e.data);
	}

	function trackPlayerChanges(playerStatus) {
		const videoInfo = getVideoInfo();

		if (playerStatus == -1) {
			//playback hasn't started, but the player is loaded
			track('onboarding_introvideo_loaded', videoInfo);
			timeEvent('onboarding_introvideo_buffered'); //start the 'video buffered' timer
		} else if (playerStatus == 0) {
			// playback has ended
			track('onboarding_introvideo_finished', videoInfo);
		} else if (playerStatus == 1) {
			// video is playing
			track('onboarding_introvideo_playback', videoInfo);
			timeEvent('onboarding_introvideo_buffered'); //re-start the 'video buffered' timer
			timeEvent('onboarding_introvideo_finished'); //start the 'video finish!' timer
		} else if (playerStatus == 2) {
			// video is paused
			videoInfo.$duration = player.getCurrentTime(); //get the current time when the video is paused
			track('onboarding_introvideo_paused', videoInfo);
		} else if (playerStatus == 3) {
			// video is buffering
			track('onboarding_introvideo_buffered', videoInfo);
		}
	}
}
