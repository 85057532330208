import React from 'react';

import { useTheme } from '@material-ui/core/styles';

import { useAppContext } from '../AppContext';

import { generateEmailTemplateString, getBlock, splitWidget } from './surveyUtils';
import { useSurveyEditorContext } from './SurveyEditorContext';
import { useSurveyDesignContext } from './SurveyDesignContext';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function getSnippetBlock({ blocks = {}, map = [] }) {
	const hasSelectedBlock = Object.values(blocks).find(
		block => block?.properties?.preselectScore
	);
	if (hasSelectedBlock) return hasSelectedBlock;

	return { typeName: 'feedback_button' };
}

function getFeedbackButtonColor(design, theme) {
	if (
		design?.feedbackButton &&
		(design.feedbackButton?.color?.inactive ||
			design.feedbackButton?.backgroundColor?.inactive ||
			design.feedbackButton?.color?.hover ||
			design.feedbackButton?.backgroundColor?.hover)
	) {
		return {
			colorInactive: design.feedbackButton?.color?.inactive,
			backgroundInactive: design.feedbackButton?.backgroundColor?.inactive,
			colorHover: design.feedbackButton?.color?.hover,
			backgroundHover: design.feedbackButton?.backgroundColor?.hover,
		};
	}

	try {
		if (theme.palette.survey[design.currentTheme.name]) {
			return {
				colorInactive: '#fff',
				backgroundInactive: theme.palette.survey[design.currentTheme.name]?.primary?.main,
				colorHover: '#fff',
				backgroundHover: theme.palette.survey[design.currentTheme.name]?.primary?.main,
			};
		}
	} catch (e) {}

	return {
		colorInactive: '#fff',
		backgroundInactive: '#bbb',
		colorHover: '#fff',
		backgroundHover: '#aaa',
	};
}

export function useEmailSnippet(block) {
	const { app } = useAppContext();
	const { editor } = useSurveyEditorContext();
	const { design } = useSurveyDesignContext();
	const { t } = useTranslation();
	const { editorFlow, detailStep } = useParams();
	const theme = useTheme();

	const snippetFromBlock = block ?? getSnippetBlock(editor.state);
	const feedbackButtonColor = block ?? getFeedbackButtonColor(design, theme);

	//Do we need to split the widget HTML into css & html?
	const useTabs = splitWidget(snippetFromBlock ?? {}, design);

	const feedbackButtonText = editor?.text?.btnOpenText ?? 'Feedback';

	return {
		css: generateEmailTemplateString(
			editor.state,
			design,
			app.domain,
			snippetFromBlock,
			{ cssOnly: true },
			feedbackButtonColor,
			feedbackButtonText
		),
		html: generateEmailTemplateString(
			editor.state,
			design,
			app.domain,
			snippetFromBlock,
			{ htmlOnly: true },
			feedbackButtonColor,
			feedbackButtonText
		),
		preview: generateEmailTemplateString(
			editor.state,
			design,
			app.domain,
			snippetFromBlock,
			{ disableLinks: true, htmlOnly: !useTabs },
			feedbackButtonColor,
			feedbackButtonText
		),
		full: generateEmailTemplateString(
			editor.state,
			design,
			app.domain,
			snippetFromBlock,
			{},
			feedbackButtonColor,
			feedbackButtonText
		),
		useTabs: useTabs,
	};
}

export default function useEmailSnippetWithoutContext(editor, design, block) {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const theme = useTheme();

	const snippetFromBlock = block ?? getSnippetBlock(editor);
	const feedbackButtonColor = block ?? getFeedbackButtonColor(design, theme);

	//Do we need to split the widget HTML into css & html?
	const useTabs = splitWidget(snippetFromBlock ?? {}, design);

	const feedbackButtonText = editor?.text?.btnOpenText ?? 'Feedback';

	return {
		css: generateEmailTemplateString(
			editor,
			design,
			app.domain,
			snippetFromBlock,
			{ cssOnly: true },
			feedbackButtonColor,
			feedbackButtonText
		),
		html: generateEmailTemplateString(
			editor,
			design,
			app.domain,
			snippetFromBlock,
			{ htmlOnly: true },
			feedbackButtonColor,
			feedbackButtonText
		),
		preview: generateEmailTemplateString(
			editor,
			design,
			app.domain,
			snippetFromBlock,
			{ disableLinks: true, htmlOnly: !useTabs },
			feedbackButtonColor,
			feedbackButtonText
		),
		full: generateEmailTemplateString(
			editor,
			design,
			app.domain,
			snippetFromBlock,
			{},
			feedbackButtonColor,
			feedbackButtonText
		),
		useTabs: useTabs,
	};
}
