import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ButtonLeftIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<path
				id="XMLID_4_"
				d="M39,32h1v4h-1V32z M32,1h4V0l-4,0V1z M24,1h4V0l-4,0V1z M32,39h-4v1h4V39z M39,20h1v-4h-1V20z M39,4h1V0l-1,0
				V4z M39,28h1v-4h-1V28z M39,12h1V8h-1V12z M24,39h-4v1h4V39z M40,39h-4v1h4V39z M1,12H0v1v3v4l0,4l0,3h5c0.5,0,1-0.5,1-1V14
				c0-0.5-0.5-1-1-1H1V12z M1,28H0l0,4h1V28z M1,4H0v4h1V4z M0,0v1h4V0H0z M8,1h4V0H8V1z M16,39h-4v1h4V39z M1,36H0l0,4h1V36z M16,1h4
				V0l-4,0V1z M8,39H4v1h4V39z"
			/>
		</SvgIcon>
	);
}
