import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

import DataSourceAvatar from './DataSourceAvatar';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	noWrapChip: {
		maxWidth: '100%',
	},
	noWrapChipLabel: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: 'block',
	},
});

function DataSourceChip({ source, className = '', ...props }) {
	const { t } = useTranslation();
	const classes = useStyles();

	function type() {
		switch (source.survey_type) {
			case 'builder':
				return t`Feedback form`;
			case 'dataset':
				return t`Dataset`;
			case 'integration':
				return t`Integration`;
		}
	}

	return (
		<Tooltip title={`${type()}: ${source.name}`}>
			<Chip
				avatar={
					<DataSourceAvatar
						survey_type={source.survey_type}
						survey_format={source.survey_format}
						campaign={source.campaign}
					/>
				}
				label={source.name}
				className={`${classes.noWrapChip} ${className}`}
				classes={{
					label: classes.noWrapChipLabel,
				}}
				{...props}
			/>
		</Tooltip>
	);
}
export default DataSourceChip;
