import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

import { useListFilterContext, CurrentFilters } from './';
import { ActionIconButton } from '@/components/Layout';

const useStyles = makeStyles({
	alignMiddle: {
		verticalAlign: 'middle',
	},
	buttonMargin: {
		marginLeft: 8,
	},
	paddingBottom: {
		paddingBottom: 12,
	},
});

export default function ListControls({
	sortOptions = [],
	withSelectAll,
	children,
	noFilters,
	selectAllChecked,
	onSelectAllChange,
	toggleFilterDrawer,
	onRefresh,
	explainer,
}) {
	const classes = useStyles();
	const { sort, order, setSort, setOrder } = useListFilterContext();
	const { t } = useTranslation();

	function setSortOrder(e) {
		const [sort, order] = e.target.value.split('-');
		setSort(sort);
		setOrder(order);
	}

	const sortValue = sort && order ? `${sort}-${order}` : sortOptions[0]?.value;

	return (
		<Fragment>
			<Grid
				container
				direction="column"
			>
				<Grid
					item
					container
					direction="row"
					alignItems="center"
				>
					<Grid
						item
						xs
					>
						{withSelectAll && (
							<Tooltip title={t('Select all')}>
								<Checkbox
									onChange={onSelectAllChange}
									checked={selectAllChecked}
									data-onboarding="list-controls-actions"
									data-test-element="list_controls_feedback_checkbox_select_all"
								/>
							</Tooltip>
						)}
						{onRefresh && (
							<ActionIconButton
								tooltip={t`Refresh`}
								action="refresh"
								onClick={onRefresh}
							/>
						)}
						{explainer}
						{children}
					</Grid>
					<Grid
						item
						data-onboarding="list-controls-sort"
					>
						<Tooltip title={t('Sorting')}>
							<TextField
								className={noFilters ? classes.paddingBottom : null}
								select
								value={sortValue}
								onChange={setSortOrder}
								classes={{
									root: classes.alignMiddle,
								}}
							>
								{sortOptions.map(option => (
									<MenuItem
										key={option.value}
										value={option.value}
									>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</Tooltip>
						{!noFilters && (
							<ActionIconButton
								action="filters"
								tooltip={t`Toggle filters`}
								className={classes.buttonMargin}
								onClick={toggleFilterDrawer}
							/>
						)}
					</Grid>
				</Grid>
				<CurrentFilters />
			</Grid>
		</Fragment>
	);
}
