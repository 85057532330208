import React, { useState, useRef, useEffect } from 'react';
import { EFM, Mjolnir, OfficeDrones } from '@/api';
import { axios, CancelToken } from '@/lib/axios';
import { useSnackbar } from 'notistack';
export default function useAjaxForm({
	valid = () => true,
	url = '',
	data = {},
	onSuccess = () => {},
	onError = () => {},
	type = 'efm',
	config = {},
	method = 'post',
	successMessage,
}) {
	const [loading, setLoading] = useState(false);
	const [clicked, setClicked] = useState(false);
	const [response, setResponse] = useState({});
	const [error, setError] = useState({});
	const cancel = useRef(() => {});
	const { enqueueSnackbar } = useSnackbar();
	//const isPosting = useRef(false);

	useEffect(() => {
		return () => {
			try {
				cancel.current();
			} catch (e) {}
		};
	}, []);

	function postForm({ post = {}, extra = {} } = {}) {
		setClicked(true);
		const api = type === 'efm' ? EFM : type === 'officeDrones' ? OfficeDrones : Mjolnir;
		if (valid()) {
			//dont parallel post but use last request if fired several times
			try {
				cancel.current();
			} catch (e) {}

			setLoading(true);
			api[method](
				`${url}`,
				{
					...data,
					...post,
				},
				{
					cancelToken: new CancelToken(cancelToken => (cancel.current = cancelToken)),
					...config,
				}
			)
				.then(r => {
					setLoading(false);
					setResponse({ ...r });
					setError({});
					onSuccess(r, extra);
					setClicked(false);

					if (successMessage) {
						enqueueSnackbar(successMessage);
					}
				})
				.catch(e => {
					if (!axios.isCancel(e)) {
						setLoading(false);
						onError(e);
						setResponse({});
						setError(e);
					}
				});
		}
	}

	return {
		postForm,
		clicked,
		response,
		loading,
		cancelPost: cancel.current,
		setClicked,
		error,
	};
}
