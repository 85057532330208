import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import RenderConditional from './RenderConditional';
import { ClickAwayListener } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	popper: {
		zIndex: theme.zIndex.tooltip,
	},
}));

export default function PaperPopper({
	children,
	open,
	anchorEl,
	placement,
	elevation = 8,
	animation = 'fade',
	onClickAway,
	...props
}) {
	const Animation = animation === 'fade' ? Fade : Grow;
	const classes = useStyles();
	return (
		<Popper
			open={open}
			anchorEl={anchorEl}
			placement={placement}
			transition
			className={classes.popper}
			{...props}
		>
			{({ TransitionProps }) => (
				<Animation
					{...TransitionProps}
					timeout={350}
				>
					<Paper elevation={elevation}>
						<RenderConditional
							component={ClickAwayListener}
							condition={onClickAway}
							onClickAway={onClickAway}
						>
							{children}
						</RenderConditional>
					</Paper>
				</Animation>
			)}
		</Popper>
	);
}
