import React, { Fragment, useState } from 'react';
import { useEFM } from '../Ajax';

import { makeStyles } from '@material-ui/core/styles';
import { WEBHOOK_TYPES, useWebHookContext } from '../WebHook';

import { FormattedDate } from '../Layout';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

import Badge from '@material-ui/core/Badge';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles(theme => ({
	dialogSubject: {
		marginBottom: theme.spacing(2),
	},
	ellipsis: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	borderedCard: {},
	sent: {
		display: 'flex',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		alignItems: 'center',
	},
	chip: {
		margin: theme.spacing(0.5, 0),
	},
}));

export default function FeedbackDetailsWebhookCard({ isElevated, webhooks = [] }) {
	const { t } = useTranslation();
	const classes = useStyles();
	const {
		state: { hooks },
	} = useWebHookContext();

	let cardProps = !isElevated ? { elevation: 0, className: classes.borderedCard } : {};

	const filteredHooks = hooks
		? webhooks.reduce((acc, current) => {
				const activeHook = hooks.find(hook => hook.webhook_id === current.action_id);

				if (acc[current.action_id]) {
					//dont add optimistic updates if the same action id already exists
					if (!current.processing) {
						acc[current.action_id].push({
							...current,
							name: activeHook ? activeHook.name : t`This integration has been deleted`,
						});
					}
				} else {
					acc[current.action_id] = [
						{
							...current,
							name: activeHook ? activeHook.name : t`This integration has been deleted`,
						},
					];
				}

				return acc;
		  }, {})
		: {};

	return (
		<Fragment>
			<Card {...cardProps}>
				<CardHeader
					title={t('Sent integrations')}
					titleTypographyProps={{ variant: 'subtitle2' }}
				/>
				<List>
					{Object.values(filteredHooks).map(collection => {
						const stack = collection.length;
						const hook = collection[collection.length - 1];

						const webhookType =
							WEBHOOK_TYPES.find(type => type.value === hook.webhook_type) ?? {};

						return (
							<ListItem key={hook.webhook_id}>
								<ListItemAvatar>
									<Badge
										badgeContent={stack > 1 ? stack : null}
										color="primary"
									>
										{webhookType.icon ? (
											<img
												height="32"
												src={webhookType.icon}
											/>
										) : (
											<img
												height="32"
												src="/assets/img/r/webhook-icon.svg"
											/>
										)}
									</Badge>
								</ListItemAvatar>
								<ListItemText
									primary={
										<Grid container>
											<Grid
												item
												xs
												className={classes.ellipsis}
											>
												{hook.name}
											</Grid>
											<Grid item>
												<Typography
													variant="caption"
													color="textSecondary"
												>
													{<FormattedDate date={hook.created} />}
												</Typography>
											</Grid>
										</Grid>
									}
									primaryTypographyProps={{
										noWrap: true,
									}}
									secondary={
										<div className={classes.sent}>
											{hook.processing
												? t('Sending to {{integration}}', {
														integration: webhookType.label ?? '',
												  })
												: t('Sent to {{integration}}', {
														integration: webhookType.label ?? '',
												  })}

											<Chip
												size="small"
												label={
													hook.event_type === 'feedback.user_init'
														? t`Sent manually`
														: t`Sent for new feedback`
												}
												className={classes.chip}
											/>
										</div>
									}
								/>
							</ListItem>
						);
					})}
				</List>
			</Card>
		</Fragment>
	);
}
