import { differenceInDays } from '../../utils/date';

export function parseAmount({ amount, currency, locale, currencyOptions = {} }) {
	try {
		return Number(amount).toLocaleString(locale, {
			style: 'currency',
			currency,
			...currencyOptions,
		});
	} catch (e) {
		return `${Number(amount).isInteger ? amount : Number(amount).toFixed(2)} ${currency}`;
	}
}

//find stripe tier up_to and corresponding flat_amount
export function tierPrice(value, stripeTiers) {
	//tier_up_to is the upper limit of the tier, if tier_up_to is null, it means the tier is unlimited
	const stripeTier = stripeTiers.find(tier => tier.up_to >= value || tier.up_to === null);
	return stripeTier?.amount || stripeTier?.flat_amount || 0;
}

//find index of tier and calculate the price for the stacked amount of tiers
export function calculateTierPrice(value, tiers = [], stripeTiers = []) {
	const tierIndex = tiers.indexOf(value);

	if (!value || value === 0 || tierIndex === -1) {
		return 0;
	}
	//iterate over all tiers to calculate the total price
	return [...Array(tierIndex + 1)].reduce((total, _, index) => {
		const tierValue = tiers[index];
		return total + tierPrice(tierValue, stripeTiers);
	}, 0);
}

//calculate the total price based on the selected tiers
export function calculatePriceTotal({ state = {}, billing = '', plan = {} }) {
	return Object.keys(state).reduce((total, tierKey) => {
		const selectedTierValue = state[tierKey];
		const tierTiers = plan[tierKey]?.tiers;
		//get the corresponding tiers (price per tier step) set in stripe based on selected billing interval
		const stripeTiers = plan[tierKey]?.[billing]?.tiers ?? [];

		const tierPrice = calculateTierPrice(selectedTierValue, tierTiers, stripeTiers);
		return total + tierPrice;
	}, 0);
}

const stripeErrors = [
	'invalid_request_error',
	'card_declined',
	'expired_card',
	'incorrect_number',
	'card_not_supported',
	'incorrect_cvc',
	'token_already_used',
];

export function handleStripeErrors(error) {
	if (stripeErrors.includes(error.type)) {
		return 'stripe_error-' + error.message;
	}
	return 'stripe_error-unknown';
}

export function getRemainingDaysPerc(start, end) {
	const totalDays = differenceInDays(new Date(end), new Date(start));
	const daysLeft = differenceInDays(new Date(end), new Date());
	return ((totalDays - daysLeft) / totalDays) * 100;
}

export function isPackageIncrease(state, initialState) {
	let upgrading = false;
	for (let tier in initialState) {
		if (state[tier] > initialState[tier]) {
			upgrading = true;
			break;
		}
	}
	return upgrading;
}

export function isIntervalIncrease(currentInterval, newInterval) {
	return currentInterval === 'monthly' && newInterval === 'annual';
}
