import React from 'react';

import StepConnector from '@material-ui/core/StepConnector';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { Stepper, Step, StepLabel, Typography } from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	connector: {
		marginTop: theme.spacing(1),
		'& span': {
			minHeight: theme.spacing(2),
		},
	},
	stepper: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	step: {
		border: `1px solid ${theme.palette.primary.main}`,
		borderRadius: '50%',
		boxSizing: 'border-box',
		color: `#fff!important`,
		'& text': {
			fontSize: '.8rem',
			fontWeight: 600,
			fill: theme.palette.primary.main,
		},
	},
	active: {
		border: 'none',
		color: `${theme.palette.primary.main}!important`,
		'& text': {
			fill: `#fff`,
		},
	},
	completed: {
		border: 'none',
		color: `${theme.palette.primary.main}!important`,
	},
}));

const steps = [
	'onboarding-trial_menu-step0',
	'onboarding-trial_menu-step1',
	'onboarding-trial_menu-step2',
];

export default function TrialMenuStepper({ variant = 'horizontal', activeStep = 1 }) {
	if (variant === 'vertical') {
		return <VerticalStepper activeStep={activeStep} />;
	}
	return <HorizontalStepper activeStep={activeStep} />;
}

function VerticalStepper({ activeStep }) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Stepper
			activeStep={activeStep}
			orientation="vertical"
			classes={{
				root: classes.stepper,
			}}
			connector={<StepConnector className={classes.connector} />}
		>
			{steps.map((label, index) => (
				<Step key={label}>
					<StepLabel
						StepIconProps={{
							classes: {
								root: classes.step,
								active: classes.active,
								completed: classes.completed,
							},
						}}
					>
						<Typography
							variant="body2"
							fontWeight={index === activeStep ? 'medium' : 'regular'}
						>
							{t(label)}
						</Typography>
					</StepLabel>
				</Step>
			))}
		</Stepper>
	);
}

function HorizontalStepper({ activeStep }) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.root}>
			<Stepper
				activeStep={activeStep}
				classes={{
					root: classes.stepper,
				}}
			>
				{steps.map((label, index) => (
					<Step key={label}>
						<StepLabel
							StepIconProps={{
								classes: {
									root: classes.step,
									active: classes.active,
									completed: classes.completed,
								},
							}}
						>
							<Typography
								variant="body2"
								fontWeight={index === activeStep ? 'medium' : 'regular'}
							>
								{t(label)}
							</Typography>
						</StepLabel>
					</Step>
				))}
			</Stepper>
		</div>
	);
}
