import React from 'react';
import ListFilterContext from './ListFilterContext';

export default function ListFilterProvider(props) {
	return (
		<ListFilterContext.Provider value={{ ...props }}>
			{props.children}
		</ListFilterContext.Provider>
	);
}
