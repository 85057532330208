import React, { Fragment, useState, useMemo, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Chip from '@material-ui/core/Chip';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Grow from '@material-ui/core/Grow';

import { useTranslation } from 'react-i18next';
import produce from 'immer';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as deepmerge from 'deepmerge';

import {
	useDataSourceContext,
	DataSourceCard,
	DataFieldIcon,
	DataFieldChip,
} from '@/components/DataSources';
import { useAppContext } from '@/components/AppContext';

import useChartTemplates from './useChartTemplates';
import RenderChart from './RenderChart';
import LoadChartSeries from './LoadChartSeries';
import { defaultSimpleChartOptions } from './defaultChartOptions';
import { chartWidget, formatChart, prepareSaveChart } from './chartUtils';
import useAddChart from './useAddChart';

import { DashboardQuickSelection } from '@/components/Dashboards';
import { useDashboard } from '@/components/Dashboards';
import { AjaxForm } from '@/components/Ajax';
import { PageHeader, AppBarLeftContent } from '@/components/App';
import { ActionButton, Loader } from '@/components/Layout';
import { UpgradeOverlay, ProductTrialChip } from '@/components/Billing';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '100%',
	},
	fillHeight: {
		height: '100%',
	},
	spaceSmall: {
		marginBottom: theme.spacing(1),
	},
	spaceMid: {
		marginBottom: theme.spacing(2),
	},
	spaceLarge: {
		marginBottom: theme.spacing(5),
	},
	stepper: {
		background: 'transparent',
		marginBottom: 0,
	},
	selectedCard: {
		backgroundColor: theme.palette.action.selected,
	},
	isCompleted: {
		cursor: 'pointer',
	},
	stepLabel: {
		'& > span': {
			//	color:'rgba(255,255,255,0.54)'
		},
	},
	activeStepLabel: {
		//]	color:'rgba(255,255,255,0.87)!important'
	},
	activeStepBubble: {
		color: `${theme.palette.secondary.main}!important`,
	},
	filterWrap: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		marginBottom: theme.spacing(3),
		'& > div': {
			margin: theme.spacing(0.25),
		},
	},
	expander: {
		marginLeft: theme.spacing(2),
	},
	maxWidth: {
		maxWidth: '100%',
	},
	cardActionArea: {
		height: '100%',
		width: '100%',
		maxWidth: '100%',
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},
	cardContent: {
		width: '100%',
		maxWidth: '100%',
	},
	emptyState: {
		marginTop: '20vh',
		whiteSpace: 'pre-line',
	},
}));

const steps = [
	'reporting-create_chart_flow-steps-datasource',
	'reporting-create_chart_flow-steps-template',
	'reporting-create_chart_flow-steps-chart',
];

export default function ChartEditorTemplates({
	resetSelectedRoute = () => {},
	...props
}) {
	const { app } = useAppContext();
	const { datasource } = useDataSourceContext();
	const classes = useStyles();

	const {
		currentDashboardId,
		dashboardApi: { loadDashboards, updateNewCharts },
	} = useDashboard();

	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	const [activeStep, setActiveStep] = useState(0);
	const [selectedSource, setSelectedSource] = useState('');
	const [selectedDataField, setSelectedDataField] = useState('featured');
	const [selectedTemplate, setSelectedTemplate] = useState('');
	const [showDataFieldFilters, setShowDataFieldFilters] = useState(false);
	const [newChart, setNewChart] = useState(0);
	const [selectedRedirect, setSelectedRedirect] = useState('');

	const currentSource = datasource.api.getSource(selectedSource);
	const currentFields = datasource.api.getFieldArray(selectedSource);

	const datafieldsForTemplates = useMemo(() => {
		return selectedDataField === 'featured'
			? currentFields
					.filter(field => {
						//if field is a score type
						return (
							[4, 12, 16, 24, 25, 27, 30, 44].indexOf(Number(field.var_type)) > -1 ||
							(field.var_type == 1 &&
								[3, 4, 5, 13].indexOf(Number(field.survey_block?.type)) > -1)
						);
					})
					.map(field => field.id)
			: [selectedDataField];
	}, [selectedDataField, currentFields]);

	const { postForm: addChart, loading: loadingAddChart } = useAddChart({
		dashboardId: currentDashboardId,
		chartId: newChart,
		onSuccess: response => {
			if (response.code === 200) {
				//push to array in dashboardProvider to trigger 'NEW' label
				updateNewCharts(newChart);

				loadDashboards();

				if (selectedRedirect === 'dash') {
					enqueueSnackbar(
						t`reporting-create_chart_flow-step_save_chart-snackbar_chart_saved_added`
					);
					newChart && history.push(`/reporting/dashboard/${currentDashboardId}`);

					setTimeout(() => {
						window.scrollTo({
							top: document.body.scrollHeight,
							behavior: 'smooth',
						});
					}, 1000);
				} else if (selectedRedirect === 'detail') {
					enqueueSnackbar(
						t`reporting-create_chart_flow-step_save_chart-snackbar_chart_saved`
					);
					resetSelectedRoute();
					newChart && history.push(`/reporting/chart/${newChart}`);
				}
			}
		},
	});

	const [templates, loading] = useChartTemplates(selectedSource, datafieldsForTemplates);

	function getTemplateDataFields(series) {
		return series.reduce(
			(fields, serie) => {
				if (!fields.x.find(field => field.id == serie.x)) {
					fields.x.push(datasource.api.getField(serie.x));
				}

				if (!fields.y.find(field => field.id == serie.y)) {
					fields.y.push(datasource.api.getField(serie.y));
				}

				return fields;
			},
			{ x: [], y: [] }
		);
	}

	useEffect(() => {
		if (newChart) {
			addChart();
		}
	}, [newChart]);

	return (
		<div className={classes.root}>
			<Fragment>
				<PageHeader
					documentTitle={t`reporting-create_chart_flow-pageheader-chart_template`}
					title={
						<>
							<DashboardQuickSelection readOnly />
							<span> / </span>
							{t`reporting-create_chart_flow-pageheader-chart_template`}
						</>
					}
					onBack={() => history.push(`/reporting/dashboard/${currentDashboardId}`)}
					backTooltip={t`reporting-create_chart_flow-pageheader-tooltip_to_dashboard`}
					prominent
				/>
				<AppBarLeftContent tabs>
					<Stepper
						activeStep={activeStep}
						className={classes.stepper}
					>
						{steps.map((step, index) => {
							const stepProps = {
								active: activeStep === index,
								completed: activeStep > index,
								className: activeStep > index ? classes.isCompleted : '',
								key: index,
							};

							return (
								<Step {...stepProps}>
									<StepButton onClick={() => activeStep > index && setActiveStep(index)}>
										<StepLabel
											className={classes.stepLabel}
											classes={{
												labelContainer: classes.stepLabel,
												active: classes.activeStepLabel,
												completed: classes.activeStepLabel,
											}}
											StepIconProps={{
												classes: {
													//root:design.classes.progressDots,
													active: classes.activeStepBubble,
													completed: classes.activeStepBubble,
												},
											}}
										>
											{t(step)}
										</StepLabel>
									</StepButton>
								</Step>
							);
						})}
					</Stepper>
				</AppBarLeftContent>
				<Box flexGrow={1}>
					{activeStep === 0 && (
						<Fragment>
							<Typography
								variant="h6"
								align="center"
							>{t`reporting-create_chart_flow-step_select_datasource-select_source_title`}</Typography>
							<Typography
								variant="subtitle1"
								align="center"
								color="textSecondary"
								className={classes.spaceLarge}
							>
								{t`reporting-create_chart_flow-step_select_datasource-select_source_subtitle`}
							</Typography>
							{[
								{
									name: t`reporting-create_chart_flow-forms`,
									arr: datasource.forms.asArray,
								},
								{
									name: t`reporting-create_chart_flow-datasets`,
									arr: datasource.datasets.asArray,
								},
								{
									name: t`reporting-create_chart_flow-integrations`,
									arr: datasource.integrations.asArray,
								},
							]
								.filter(obj => obj.arr.length)
								.map((obj, index) => {
									return (
										<div
											key={`${obj.name}_${index}`}
											className={classes.spaceLarge}
										>
											<Typography
												variant="subtitle2"
												className={classes.spaceSmall}
											>
												{obj.name}
											</Typography>
											<Grid
												container
												spacing={3}
											>
												{obj.arr.map(dataSource => {
													const productInPackage = app.api.packageProductAllowed(
														dataSource.campaign
													);
													const daysLeftInProductTrial =
														app.api.packageProductTrialDaysLeft(dataSource.campaign);
													return (
														<Grid
															item
															xs={6}
															sm={4}
															md={3}
															lg={3}
															key={dataSource.id}
														>
															<UpgradeOverlay
																message={t(
																	`reporting-create_chart_flow-product_not_allowed-${dataSource.campaign}`
																)}
																size="small"
																needsUpgrade={!productInPackage}
																product={dataSource.campaign}
															>
																<DataSourceCard
																	{...dataSource}
																	title={dataSource.name}
																	className={`${classes.fillHeight} ${
																		dataSource.id === selectedSource
																			? classes.selectedCard
																			: ''
																	}`}
																	onClick={e => {
																		setActiveStep(1);
																		setSelectedSource(dataSource.id);
																	}}
																	dataTestElement="buttonNewChartForm"
																>
																	{daysLeftInProductTrial > 0 && (
																		<ProductTrialChip
																			daysLeftInProductTrial={daysLeftInProductTrial}
																		/>
																	)}
																</DataSourceCard>
															</UpgradeOverlay>
														</Grid>
													);
												})}
											</Grid>
										</div>
									);
								})}
						</Fragment>
					)}
					{activeStep === 1 && (
						<Fragment>
							<Typography
								variant="h6"
								align="center"
							>{t`reporting-create_chart_flow-step_select_template-select_template_title`}</Typography>
							<Typography
								variant="subtitle1"
								color="textSecondary"
								align="center"
								className={classes.spaceLarge}
							>
								{t`reporting-create_chart_flow-step_select_template-select_template_subtitle`}
							</Typography>

							<div
								className={classes.filterWrap}
								data-onboarding="chart-template-filters"
							>
								<Chip
									label={t`reporting-create_chart_flow-step_select_template-button_relevance`}
									onClick={() => setSelectedDataField('featured')}
									variant={selectedDataField === 'featured' ? 'default' : 'outlined'}
									color={selectedDataField === 'featured' ? 'primary' : 'default'}
								/>
								<ActionButton
									action={showDataFieldFilters ? 'expand_less' : 'expand_more'}
									label={
										showDataFieldFilters
											? t`reporting-create_chart_flow-step_select_template-button_hide_fields`
											: t`reporting-create_chart_flow-step_select_template-button_show_fields`
									}
									disableTextTransform
									onClick={() => {
										if (showDataFieldFilters) {
											setSelectedDataField('featured');
										}
										setShowDataFieldFilters(show => !show);
									}}
									className={classes.expander}
								/>

								<div className={classes.filterWrap}>
									{currentFields.map((field, i) => {
										return (
											<Tooltip title={field.system_var}>
												<Grow
													mountOnEnter
													unmountOnExit
													in={showDataFieldFilters}
												>
													<Chip
														key={field.id}
														icon={<DataFieldIcon {...field} />}
														label={
															field.system_var.length > 30
																? field.system_var.substring(0, 27) + '...'
																: field.system_var
														}
														onClick={() => setSelectedDataField(field.id)}
														variant={
															field.id == selectedDataField ? 'default' : 'outlined'
														}
														color={field.id == selectedDataField ? 'primary' : 'default'}
													/>
												</Grow>
											</Tooltip>
										);
									})}
								</div>
							</div>

							<Grid
								container
								spacing={3}
							>
								{Array.isArray(templates) &&
									templates.length > 0 &&
									templates
										.filter((template, index, self) => {
											//only allow one of each 'number of surveys' type chart in featured
											if (template.name.match(/number of surveys/gi)) {
												return self.findIndex(obj => obj.name == template.name) === index;
											}
											return true;
										})
										.map(template => {
											let options = deepmerge(
												template.highchart,
												defaultSimpleChartOptions
											);
											options.series = template.default_series;
											options.chart.height = 150;

											if (template.type === 2) {
												options = chartWidget(
													options,
													options.series,
													{},
													template.default_series[0]
												);
											}

											const { x, y } = getTemplateDataFields(template.highchart.series);

											return (
												<Grid
													item
													xs={6}
													sm={4}
													md={3}
													key={template._id + JSON.stringify(x, y)}
												>
													<Card
														className={`${classes.fillHeight} ${
															selectedTemplate === template._id
																? classes.selectedCard
																: ''
														}`}
													>
														<CardActionArea
															className={classes.cardActionArea}
															onClick={e => {
																setActiveStep(2);
																setSelectedTemplate(template);
															}}
														>
															<CardHeader
																titleTypographyProps={{ variant: 'subtitle2' }}
																title={template.name}
																subheaderTypographyProps={{ variant: 'caption' }}
																subheader={
																	<div>
																		{y.map(field => (
																			<DataFieldChip
																				field={field}
																				size="small"
																			/>
																		))}
																		{template.type != 2 && (
																			<Fragment>
																				<div>
																					{t`reporting-create_chart_flow-grouped`}
																				</div>
																				{x.map(field => (
																					<DataFieldChip
																						field={field}
																						size="small"
																					/>
																				))}
																			</Fragment>
																		)}
																	</div>
																}
																classes={{
																	content: classes.maxWidth,
																	root: classes.maxWidth,
																}}
															/>
															<CardContent className={classes.cardContent}>
																<RenderChart
																	options={options}
																	makeWidget
																	chartType={template.type}
																/>
															</CardContent>
														</CardActionArea>
													</Card>
												</Grid>
											);
										})}
								{Array.isArray(templates) && templates.length === 0 && (
									<Grid
										item
										xs
									>
										<Loader empty />
									</Grid>
								)}
							</Grid>
						</Fragment>
					)}
					{activeStep === 2 && (
						<Fragment>
							<Typography
								variant="h6"
								align="center"
							>{t`reporting-create_chart_flow-step_save_chart-chart_title`}</Typography>
							<Typography
								variant="subtitle1"
								color="textSecondary"
								align="center"
								className={classes.spaceMid}
							>
								{t`reporting-create_chart_flow-step_save_chart-chart_subtitle`}
							</Typography>
							<Container maxWidth="md">
								<Box
									align="center"
									mb={2}
								>
									<AjaxForm
										type="mjolnir"
										url={`chart/${app.domain}/${app.projects.current.id}`}
										data={prepareSaveChart({
											...selectedTemplate.highchart,
											type: selectedTemplate.type,
										})}
										onSuccess={(response, options) => {
											setNewChart(response.id);
											setSelectedRedirect(options.extra.to);
										}}
									>
										{({ postForm, loading }) => (
											<Fragment>
												<ActionButton
													variant="extended"
													color="secondary"
													loading={loading || loadingAddChart}
													onClick={() => postForm({ extra: { to: 'dash' } })}
													dataTrackEvent="dashboard_chart_template_saved_dashboard"
													fab
												>
													{t`reporting-create_chart_flow-step_save_chart-button_save_dashboard`}
												</ActionButton>
												<ActionButton
													onClick={() => postForm({ extra: { to: 'detail' } })}
													loading={loading || loadingAddChart}
													dataTrackEvent="dashboard_chart_template_saved_edit"
												>
													{t`reporting-create_chart_flow-step_save_chart-button_save_and_edit`}
												</ActionButton>
											</Fragment>
										)}
									</AjaxForm>
								</Box>
							</Container>
							<Container maxWidth="md">
								<Card className={`${classes.fillHeight}`}>
									<CardHeader
										titleTypographyProps={{ variant: 'h6' }}
										title={selectedTemplate.name}
										subheader={selectedTemplate.highchart.subtitle.text}
									/>
									<CardContent>
										<LoadChartSeries
											backendBauerSeries={selectedTemplate.highchart.series}
											chart={produce(selectedTemplate.highchart, draft =>
												formatChart(draft)
											)}
											chartType={selectedTemplate.type}
											useTooltip
										/>
									</CardContent>
								</Card>
							</Container>
						</Fragment>
					)}
				</Box>
			</Fragment>
		</div>
	);
}
