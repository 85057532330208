import React, { useState, useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import TagIcon from '@material-ui/icons/LocalOffer';

import { useTagsEditorContext } from './TagsEditorContext';
import { useAppContext } from '@/components/AppContext';
import {
	EmptyState,
	Loader,
	ActionButton,
	constants,
	ShowMaxTimes,
	Alert,
} from '@/components/Layout';

import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';

import { useDataSourceContext } from '@/components/DataSources';
import { sortByNum, sortByAlphabet } from '@/utils';
import {
	ListWithSidebar,
	ListFilterProvider,
	ListControls,
	useFilteredList,
} from '@/components/FilteredLists';
import TagConditionListCard from './TagConditionListCard';
import TagEditorDrawer from './TagEditorDrawer';
import TagListFilters from './TagListFilters';
import {
	useHasPermission,
	NoPermissionTooltip,
	permissionModules,
	permissionSubjects,
} from '@/components/Permission';

const useStyles = makeStyles(theme => ({
	alignBottom: {
		verticalAlign: 'bottom',
	},
	noMargin: {
		marginTop: 0,
	},
	emptyState: {
		whiteSpace: 'pre-line',
	},
}));

export default function TagsEditorCards(props) {
	const {
		app,
		app: { layout, users },
	} = useAppContext();
	const classes = useStyles();

	const {
		state,
		state: { config },
		api,
		api: { dispatch, dispatchSimple },
	} = useTagsEditorContext();
	const { t } = useTranslation();
	const hasEditPermission = useHasPermission();
	const hasSurveyEditPermission = useHasPermission({
		module: permissionModules.data_collection,
		subject: permissionSubjects.feedback_forms_build,
	});

	const { datasource } = useDataSourceContext();
	const [filtersOpen, setFiltersOpen] = useState(
		window.innerWidth > constants.filterDrawerBreakpoint
	);
	const [editId, setEditId] = useState(0);
	const [drawerOpen, setDrawerOpen] = useState(false);

	const filteredConfig = config.rules.filter(item => {
		const source = datasource.api.getSource(item.survey_id);
		return Object.keys(source).length > 0;
	});

	const reducedTags = filteredConfig.reduce((acc, obj) => {
		obj.add.forEach(el => {
			acc.push(...el.tags);
		});
		return acc;
	}, []);

	const filteredTags = {
		tagList: [...new Set(reducedTags)],
		tagCount: reducedTags.reduce((acc, obj) => {
			if (!acc[obj]) {
				acc[obj] = 0;
			}
			acc[obj]++;
			return acc;
		}, {}),
	};

	const filterState = useFilteredList({
		filters: {},
		other: {},
	});

	useEffect(() => {
		if (editId) {
			setDrawerOpen(true);
		}
	}, [editId]);

	const { filters, other, sort, order } = filterState;

	const filteredTagConfig = useMemo(() => {
		const tagConfigs =
			filteredConfig.length > 0
				? filteredConfig.filter(item => {
						const source = datasource.api.getSource(item.survey_id);

						const filterSearch = filters.search ? filters.search.toLowerCase() : false;
						const user = item?.add?.find(config => config.user_id)?.user_id ?? '';
						const tagList = item.add.flatMap(config => config.tags);

						const searchFilterPassed = filterSearch
							? (source.name ? source.name.toLowerCase() : '').includes(filterSearch)
							: true;

						const formFilterPassed = other.survey_id
							? other.survey_id == item.survey_id
							: true;

						const ownerPassed = filters.owner
							? (filters.owner === 'me' && users.current.id == user) ||
							  (filters.owner === 'other' && users.current.id != user)
							: true;

						const tagsFilterPassed =
							filters.tags?.length > 0
								? item.add
										.flatMap(add => add.tags)
										.find(tag => filters.tags.includes(tag))
								: true;

						const channelFilterPassed = filters.channel
							? filters.channel.indexOf(source.campaign) > -1
							: true;

						const typeFilterPassed = filters.survey_format
							? filters.survey_format.indexOf(source.survey_format) > -1
							: true;

						return (
							searchFilterPassed &&
							formFilterPassed &&
							ownerPassed &&
							tagsFilterPassed &&
							channelFilterPassed &&
							typeFilterPassed
						);
				  })
				: filteredConfig;

		return sort === 'name'
			? sortByAlphabet(tagConfigs, 'name', order === 'desc')
			: sortByNum(tagConfigs, 'timestamp', order === 'asc');
	}, [filters, other, sort, order, filteredConfig]);

	return (
		<>
			{state.configLoading && <Loader empty />}
			{!state.configLoading &&
				config.rules.length === 0 &&
				datasource.forms.asArray.length === 0 && (
					<EmptyState
						className={classes.emptyState}
						image={<img src={`/assets/img/r/emptystate/tags.gif`} />}
						primary={t`settings-tags-emptystate_no_survey-title`}
						secondary={t`settings-tags-emptystate_no_survey-text`}
						action={
							<NoPermissionTooltip>
								<ActionButton
									className={classes.emptyState}
									variant="contained"
									color="primary"
									dataTrackEvent="tags_emptystate_create_form"
									link
									to="/data-collection/survey/edit/new"
									disabled={!hasSurveyEditPermission}
								>
									{t`settings-tags-emptystate_no_survey-button_create_survey`}
								</ActionButton>
							</NoPermissionTooltip>
						}
					/>
				)}
			{!state.configLoading &&
				config.rules.length === 0 &&
				datasource.forms.asArray.length > 0 && (
					<EmptyState
						className={classes.emptyState}
						image={<img src={`/assets/img/r/emptystate/tags.gif`} />}
						primary={t`settings-tags-emptystate_default-title`}
						secondary={t`settings-tags-emptystate_default-text`}
						action={
							<NoPermissionTooltip>
								<ActionButton
									variant="contained"
									color="primary"
									onClick={() => setDrawerOpen(true)}
									disabled={!hasEditPermission}
								>
									{t`settings-tags-emptystate_default-button_create_tags`}
								</ActionButton>
							</NoPermissionTooltip>
						}
					/>
				)}
			{!state.configLoading && config.rules.length !== 0 && (
				<ListFilterProvider
					{...filterState}
					singleFilters={['owner', 'survey_format', 'export_data', 'search']}
					multiFilters={['tags', 'channel']}
					filterLabels={{
						tags: {
							label: t`Tags`,
						},
						owner: {
							label: t('Owner'),
							me: t('My auto-tags'),
							other: t('Auto-tags by others'),
						},
						channel: {
							label: t('Channel'),
							web: t('Web'),
							email: t('Email'),
							sdk: t('In-app'),
						},
						survey_format: {
							label: t('Type'),
							form: t('Standard'),
							conversational: t('Conversational'),
						},
					}}
				>
					<ListWithSidebar
						drawerContent={<TagListFilters filteredTags={filteredTags} />}
						open={filtersOpen}
						prominent={!layout.prominentHidden}
						onClose={() => setFiltersOpen(false)}
					>
						<ShowMaxTimes
							showUntil={new Date('2022-02-18')}
							max={1}
							name="autoTagListView"
							onShow={() => mixpanel.track('tag_list_listview_change_message')}
						>
							<Alert
								title={t`You may have noticed something different...`}
								variant="filled"
								severity="info"
								css={{ mb: 2 }}
								close
							>
								{t`We've made some changes here, now your auto-tag conditions are grouped together by feedback source, and we've added a brand new filter sidebar.`}
							</Alert>
						</ShowMaxTimes>
						<ListControls
							//onRefresh={loadData}
							sortOptions={[
								{ label: t('Created: new to old'), value: 'created-asc' },
								{ label: t('Created: old to new'), value: 'created-desc' },
								{ label: t('Tag name: A to Z'), value: 'name-desc' },
								{ label: t('Tag name: Z to A'), value: 'name-asc' },
							]}
							toggleFilterDrawer={() => setFiltersOpen(prev => !prev)}
						>
							<Grid
								container
								spacing={2}
							>
								<Grid item>
									<NoPermissionTooltip>
										<ActionButton
											action="add"
											onClick={() => setDrawerOpen(true)}
											variant="contained"
											color="primary"
											disabled={!hasEditPermission}
										>
											{t('Create auto-tags')}
										</ActionButton>
									</NoPermissionTooltip>
								</Grid>
								{/* <Grid item>
									<Typography component="span">
										<Explainer
											tooltip={t`Tagging helps you manage and keep track of your feedback.`}
										>
											{t('What are auto-tags?')}
										</Explainer>
									</Typography>
								</Grid> */}
							</Grid>
						</ListControls>
						{filteredTagConfig.length > 0 && (
							<Grid
								container
								direction="column"
								spacing={2}
								wrap="nowrap"
								className={classes.noMargin}
							>
								{filteredTagConfig.map(rule => {
									return (
										<Grid
											item
											xs={12}
											key={rule.id}
										>
											<TagConditionListCard
												setEditId={setEditId}
												{...rule}
											/>
										</Grid>
									);
								})}
							</Grid>
						)}
						{filteredTagConfig.length === 0 && (
							<EmptyState
								icon={TagIcon}
								primary={t`settings-tags-emptystate_filter-title`}
								secondary={t`settings-tags-emptystate_filter-text`}
							/>
						)}
					</ListWithSidebar>
				</ListFilterProvider>
			)}

			<TagEditorDrawer
				open={drawerOpen}
				onClose={() => {
					setEditId(0);
					setDrawerOpen(false);
				}}
				id={editId}
			/>
		</>
	);
}
