import React, { Component, Suspense } from 'react';

import InsertChartOutlined from '@material-ui/icons/InsertChartOutlined';

import { withTranslation } from 'react-i18next';
import get from 'lodash.get';
import { withStyles } from '@/styles';

import { FeedbackQuickViewDrawer } from '@/components/Feedback';
import { EmptyState } from '@/components/Layout';
import { tryParse } from '@/utils';

const styles = theme => ({
	wrapperDiv: {
		// display:'flex',
		// flexGrow:1
		height: '100%',
		width: '100%',
		'& > div': {
			height: '100%',
			width: '100%',
		},
	},
});

const HighchartsChart = React.lazy(() =>
	import(/* webpackChunkName: "highcharts" */ './HighchartsChart')
);

class RenderChart extends Component {
	static defaultProps = {
		options: {},
		chartRef: () => {},
	};

	state = {
		quickViewOpen: false,
	};

	div = React.createRef();
	tooltipButton = React.createRef();

	setRef = node => {
		this.chart = node;
		this.props.chartRef(node);
	};

	componentDidCatch(a, b) {
		console.log(a, b);
	}

	componentDidMount() {
		if (this.props.useTooltip) {
			this.div.current && this.div.current.addEventListener('click', this.clickTooltip);
		}
	}

	componentWillUnmount() {
		if (this.props.useTooltip) {
			this.div.current &&
				this.div.current.removeEventListener('click', this.clickTooltip);
		}
	}

	hasData = () => {
		const { options, loading } = this.props;
		const getArrayLength = path =>
			Array.isArray(get(options, path)) ? get(options, path).length : false;

		//If the chart is still (partly) loading we dont want to show a 'no data' message yet
		if (loading !== 0) return true;

		return getArrayLength('series') &&
			getArrayLength('series') === getArrayLength('chart.seriesData')
			? options.series.filter(serie => serie?.data?.length).length > 0
			: true;
	};

	clickTooltip = e => {
		const { target } = e;
		const element = target.matches('.quick-view-feedback-button')
			? target
			: target.closest('.quick-view-feedback-button')
			? target.closest('.quick-view-feedback-button')
			: null;
		if (element) {
			let filters = tryParse(element.getAttribute('data-filters'), {});
			let tagFilters = tryParse(element.getAttribute('data-tags'), {});
			let surveyId = element.getAttribute('data-survey-id');

			this.setState({
				quickViewOpen: true,
				currentTooltipFilters: filters,
				currentTooltipTags: tagFilters,
				currentTooltipSurveyId: surveyId,
			});
		}
	};

	render() {
		const { options, classes, chartRef, chartType, t, useTooltip, fromChartEditor } =
			this.props;
		const {
			quickViewOpen,
			currentTooltipFilters,
			currentTooltipTags,
			currentTooltipSurveyId,
		} = this.state;

		if (!this.hasData()) {
			return (
				<EmptyState
					size={fromChartEditor ? 'medium' : 'small'}
					spacing={2}
					icon={InsertChartOutlined}
					primary={t`reporting-dashboard-emptystate_no_chart-title`}
					secondary={t`reporting-dashboard-emptystate_no_chart-text_alt`}
				/>
			);
		}

		return (
			<>
				<div
					ref={this.div}
					className={classes.wrapperDiv}
				>
					{chartType === 2 || options.series.length ? (
						<Suspense fallback={<div />}>
							<HighchartsChart
								options={options}
								ref={this.setRef}
							/>
						</Suspense>
					) : null}
				</div>
				{useTooltip ? (
					<>
						<FeedbackQuickViewDrawer
							open={quickViewOpen}
							onClose={e => this.setState({ quickViewOpen: false })}
							customFilters={currentTooltipFilters}
							{...(Array.isArray(currentTooltipTags) &&
								currentTooltipTags.length > 0 && {
									FeedbackListProps: {
										filters: {
											tags: currentTooltipTags,
										},
									},
								})}
							{...(currentTooltipSurveyId && {
								surveyId: currentTooltipSurveyId,
							})}
						/>
					</>
				) : null}
			</>
		);
	}
}

export default withStyles(styles)(withTranslation()(RenderChart));

// import React, { Suspense, useEffect, useRef, useState } from 'react';

// import InsertChartOutlined from '@material-ui/icons/InsertChartOutlined';

// import { useTranslation } from 'react-i18next';

// import get from 'lodash.get';
// import { makeStyles } from '@material-ui/core/styles';

// import { FeedbackQuickViewDrawer } from '@/components/Feedback';
// import { EmptyState } from '@/components/Layout';
// import { tryParse } from '@/utils';

// const useStyles = makeStyles(theme => ({
// 	wrapperDiv: {
// 		height: '100%',
// 		width: '100%',
// 		'& > div': {
// 			height: '100%',
// 			width: '100%',
// 		},
// 	},
// }));

// export default function RenderChart({
// 	loading,
// 	options,
// 	chartType,
// 	useTooltip,
// 	fromChartEditor,
// 	chartRef = () => {},
// }) {
// 	const classes = useStyles();
// 	const { t } = useTranslation();

// 	const div = useRef(null);

// 	const [chart, setChart] = useState(null);

// 	const [state, setState] = useState({
// 		quickViewOpen: false,
// 		currentTooltipFilters: [],
// 		currentTooltipTags: [],
// 		currentTooltipSurveyId: null,
// 	})

// 	const HighchartsChart = React.lazy(() =>
// 		import(/* webpackChunkName: "highcharts" */ './HighchartsChart')
// 	);

// 	const setRef = node => {
// 		//setChart(node);
// 	  chartRef(node);
// 	  console.log('setting ref!');
// 	};

// 	const clickTooltip = e => {
// 		const { target } = e;
// 		const element = target.matches('.quick-view-feedback-button')
// 			? target
// 			: target.closest('.quick-view-feedback-button')
// 			? target.closest('.quick-view-feedback-button')
// 			: null;
// 		if (element) {
// 			let filters = tryParse(element.getAttribute('data-filters'), {});
// 			let tagFilters = tryParse(element.getAttribute('data-tags'), {});
// 			let surveyId = element.getAttribute('data-survey-id');

// 			setState({
// 				quickViewOpen: true,
// 				currentTooltipFilters: filters,
// 				currentTooltipTags: tagFilters,
// 				currentTooltipSurveyId: surveyId,
// 			});
// 		}
// 	};

// 	const hasData = () => {
// 		const getArrayLength = path =>
// 			Array.isArray(get(options, path)) ? get(options, path).length : false;

// 		//If the chart is still (partly) loading we dont want to show a 'no data' message yet
// 		if (loading !== 0) return true;

// 		return getArrayLength('series') &&
// 			getArrayLength('series') === getArrayLength('chart.seriesData')
// 			? options.series.filter(serie => serie?.data?.length).length > 0
// 			: true;
// 	};

// 	useEffect(() => {
// 		const currentDiv = div.current;
// 		if (useTooltip) {
// 			currentDiv && currentDiv.addEventListener('click', clickTooltip);
// 		}
// 		return () => {
// 			if (useTooltip) {
// 				currentDiv.removeEventListener('click', clickTooltip);
// 			}
// 	  };
// 	}, []);

// 	if (!hasData()) {
// 		return (
// 			<EmptyState
// 				size={fromChartEditor ? 'medium' : 'small'}
// 				spacing={2}
// 				icon={InsertChartOutlined}
// 				primary={t`reporting-dashboard-emptystate_no_chart-title`}
// 				secondary={t`reporting-dashboard-emptystate_no_chart-text_alt`}
// 			/>
// 		);
// 	}

// 	return (
// 		<>
// 			<div
// 				ref={div}
// 				className={classes.wrapperDiv}
// 			>
// 				{chartType === 2 || options.series.length ? (
// 					<Suspense fallback={<div />}>
// 						<HighchartsChart
// 							options={options}
// 							ref={chartRef}
// 						/>
// 					</Suspense>
// 				) : null}
// 			</div>
// 			{useTooltip ? (
// 				<>
// 					<FeedbackQuickViewDrawer
// 						open={state.quickViewOpen}
// 						onClose={e => setState({ ...state, quickViewOpen: false })}
// 						customFilters={state.currentTooltipFilters}
// 						{...(Array.isArray(state.currentTooltipTags) &&
// 							state.currentTooltipTags.length > 0 && {
// 								FeedbackListProps: {
// 									filters: {
// 										tags: state.currentTooltipTags,
// 									},
// 								},
// 							})}
// 						{...(state.currentTooltipSurveyId && {
// 							surveyId: state.currentTooltipSurveyId,
// 						})}
// 					/>
// 				</>
// 			) : null}
// 		</>
// 	);

// }
