import React, { useEffect, useMemo } from 'react';

import get from 'lodash.get';
import set from 'lodash.set';
import unset from 'lodash.unset';

import { useAppContext } from '@/components/AppContext';
import { useEFM, useMjolnir, useAjaxForm } from '@/components/Ajax';
import TagContext from './TagContext';
import { useTimeTravel } from '@/hooks';
import { rnd } from '@/utils';
import { ruleBase, addBase, conditionBase } from './tagUtils';

export default function TagsProvider({ children }) {
	const { app } = useAppContext();
	const [{ tags = [], tag_count, restricted }, loading, error, loadData] = useEFM(
		'/application/tags/all-tags',
		{},
		!app.auth.loggedIn
	);
	const [automatedTagConfig, configLoading, configError, loadConfig, , didFetchTags] =
		useMjolnir(
			`/api/1/tags/${app.domain}/${app.projects.current.id}`,
			{},
			!app.auth.loggedIn || !app.projects.current.id
		);

	const initialState = {
		domain: app.domain,
		project_id: app.projects.current.id,
		org_id: app.organisations.current.org_id,
		rules: [],
	};
	const { state, dispatch, timeline, doUndo, doRedo, doReset } = useTimeTravel(
		automatedTagsReducer,
		initialState
	);

	useEffect(() => {
		if (!configLoading) {
			if (automatedTagConfig.tags) {
				dispatch({
					type: 'init_config',
					payload: automatedTagConfig.tags,
					timelineProps: {
						skipTimeline: true,
					},
				});
			} else {
				dispatch({
					type: 'init_config',
					payload: initialState,
					timelineProps: {
						skipTimeline: true,
					},
				});
			}
		}
	}, [automatedTagConfig.tags, configLoading]);

	const tagsFromAutomatedTagConfig = useMemo(() => {
		return (
			state.config?.tags?.rules.flatMap(rule => {
				return rule.add.flatMap(add => {
					return add.tags;
				});
			}) ?? []
		);
	}, [state.config]);

	const {
		postForm: saveConfig,
		loading: loadingSaveConfig,
		onSuccess,
	} = useAjaxForm({
		url: `/api/1/tags/${app.domain}/${app.projects.current.id}`,
		data: state,
		type: 'mjolnir',
		onSuccess: response => {
			//enqueueSnackbar(t`Configuration saved and published`);
		},
	});
	useEffect(() => {
		if (timeline.past.length > 0 && didFetchTags) {
			saveConfig();
		}
	}, [JSON.stringify(state), timeline.past.length, didFetchTags]);

	return (
		<TagContext.Provider
			value={{
				tags: {
					tagList: tags,
					tagCount: tag_count,
					restricted,
					automated: {
						config: state,
						loading: configLoading,
						load: loadConfig,
						tags: tagsFromAutomatedTagConfig,
						saveConfig,
						dispatch,
						doRedo,
						doUndo,
					},
					uniqueTags: [...new Set([...tags, ...tagsFromAutomatedTagConfig])],
					api: {
						updateTags: () => loadData(),
						saveTags: (tags, type) => this.saveTags(tags, type),
					},
				},
			}}
		>
			{children}
		</TagContext.Provider>
	);
}

//automated tags reducer
function automatedTagsReducer(state, action) {
	switch (action.type) {
		case 'init_config':
			Object.assign(state, {
				...action.payload,
			});

			const rulesPerSurvey = {};
			const usedIds = [];

			if (Array.isArray(state.rules)) {
				//group conditions by survey in object
				for (const rule of state.rules) {
					if (rule?.survey_id) {
						if (!rulesPerSurvey[rule.survey_id]) {
							rulesPerSurvey[rule.survey_id] = {
								add: [],
								survey_id: rule.survey_id,
								survey_key: rule.survey_key,
								id: rule.id,
							};
						}
						rulesPerSurvey[rule.survey_id].add.push(...rule.add);
					}
				}
			}

			state.rules = Object.values(rulesPerSurvey).map(rule => {
				//fix possible duplicate ids by checking if a same id exists in the config, if it does, update it
				if (!rule.id || usedIds.includes(rule.id)) rule.id = rnd();
				usedIds.push(rule.id);

				return rule;
			});

			return;

		case 'add_rule':
			const newRuleDestinationIndex =
				typeof action.payload.destinationIndex === 'number'
					? action.payload.destinationIndex
					: state.rules.length;
			const newRule = ruleBase({ ...action.payload.rule });
			state.rules.splice(newRuleDestinationIndex, 0, newRule);
			return;

		case 'add_filled_rule':
			state.rules.push(action.payload.rule);
			return;

		case 'update_rule':
			const ruleIndex = state.rules.findIndex(rule => rule.id === action.payload.ruleId);
			state.rules[ruleIndex] = action.payload.rule;
			return;

		case 'move_rule':
			state.rules.splice(
				action.payload.destinationIndex,
				0,
				state.rules.splice(action.payload.sourceIndex, 1)[0]
			);
			return;

		case 'delete_rule':
			state.rules.splice(action.payload.index, 1);
			return;

		case 'set_rule_option':
			set(state.rules, action.payload.path, action.payload.value);
			return;

		case 'unset_rule_option':
			unset(state.rules, action.payload.path);
			return;

		case 'add_to_rule_array':
			get(state.rules, action.payload.path).push(action.payload.value);
			return;

		case 'remove_from_rule_array':
			get(state.rules, action.payload.path).splice(action.payload.index, 1);
			return;

		case 'add_feedback_condition':
			state.rules[action.payload.ruleIndex].add[
				action.payload.conditionIndex
			].conditions.push(conditionBase());
			return;

		case 'remove_feedback_condition':
			state.rules[action.payload.selectedRuleIndex].add[
				action.payload.conditionIndex
			].conditions.splice(action.payload.index, 1);
			return;

		case 'add_condition':
			state.rules[action.payload.ruleIndex].add.push(
				addBase({ ...action.payload.condition })
			);
			return;

		case 'delete_condition':
			state.rules[action.payload.ruleIndex].add.splice(action.payload.conditionIndex, 1);
			return;

		default:
			return;
	}
}
