import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';

import { useTranslation } from 'react-i18next';

import { ActionButton, ContactDialog, Typography } from '../Layout';
import { useSessionStorage } from '../../hooks';

const useStyles = makeStyles(theme => ({
	snackbar: {
		backgroundColor: '#fff',
		color: theme.palette.text.primary,
		[theme.breakpoints.up('sm')]: {
			maxWidth: 480,
		},
	},
	title: {
		marginBottom: theme.spacing(2),
	},
	action: {
		marginTop: theme.spacing(3),
		display: 'flex',
		justifyContent: 'flex-end',
		'& > button:first-child': {
			marginRight: theme.spacing(1),
		},
	},
}));

export default function ContactEnterpriseSnackbar({ show }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const [dismissed, setDismissed] = useSessionStorage('contact-enterprise', false);
	const [contact, setContact] = useState(false);

	function handleContact() {
		setContact(true);
		setDismissed(true);
	}

	return (
		<>
			<Snackbar
				open={show && !dismissed}
				ContentProps={{
					classes: {
						root: classes.snackbar,
					},
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				message={
					<>
						<Typography
							variant="h6"
							className={classes.title}
						>{t`contact_enterprise_snackbar-title`}</Typography>
						<Typography>{t`contact_enterprise_snackbar-content`}</Typography>

						<div className={classes.action}>
							<ActionButton
								onClick={() => setDismissed(true)}
							>{t`contact_enterprise_snackbar-action-no`}</ActionButton>
							<ActionButton
								variant="contained"
								color="primary"
								onClick={handleContact}
							>{t`contact_enterprise_snackbar-action-yes`}</ActionButton>
						</div>
					</>
				}
			/>

			<ContactDialog
				open={contact}
				onClose={() => setContact(false)}
				mailTo="sales@mopinion.com"
				subject={t`contact_enterprise_dialog-subject`}
			/>
		</>
	);
}
