import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SectionBreakIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<path
				fill="currentColor"
				id="XMLID_43_"
				d="M2,26.4h36v3.1H2V26.4z M2,32.7h22.5v3.1H2V32.7z M2,4.2v3.2h5.4v12.9h3.2V7.4H16V4.2H2z M22.5,9.6h-9.7v3.2
				H16v7.6h3.2v-7.6h3.2V9.6z"
			/>
		</SvgIcon>
	);
}
