import React, { useEffect } from 'react';

import Toolbar from '@material-ui/core/Toolbar';

import { makeStyles } from '@material-ui/core/styles';
import { useAppContext } from '@/components/AppContext';
import {
	ActionButton,
	ActionIconButton,
	Logo,
	Typography,
	AppBar,
} from '@/components/Layout';

import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		'& a:not(:last-child)': {
			marginRight: theme.spacing(1),
		},
		'& button:not(:last-child)': {
			marginRight: theme.spacing(1),
		},
	},
	padding: {
		paddingRight: theme.spacing(1),
	},
	appBarBg: {
		backgroundColor: '#ffffff',
		color: theme.palette.text.primary,
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	logoWrapper: {
		maxWidth: '100%',
	},
	grow: {
		flexGrow: 1,
	},
}));

export default function SimpleAppBar({
	title,
	documentTitle,
	withLogo,
	actions,
	backTooltip,
	onBack,
	backTo,
}) {
	const {
		app: { layout },
	} = useAppContext();
	const classes = useStyles({ ...layout });
	const history = useHistory();
	const backFn =
		typeof onBack === 'function'
			? () => onBack(history)
			: backTo
			? () => history.push(backTo)
			: null;

	useEffect(() => {
		document.title = `${documentTitle ? documentTitle : title} | Mopinion`;
	}, [title, documentTitle]);

	return (
		<AppBar
			position="absolute"
			className={`${classes.root} ${classes.appBarBg}`}
			elevation={0}
		>
			<Toolbar disableGutters={false}>
				{withLogo && (
					<span className={classes.logoWrapper}>
						<Logo />
					</span>
				)}
				{backFn && (
					<span className={classes.padding}>
						<ActionIconButton
							tooltip={backTooltip}
							action="back"
							onClick={backFn}
						/>
					</span>
				)}

				<Typography
					className={classes.grow}
					variant="h6"
					display="inline"
					component="div"
				>
					{title}
				</Typography>

				{actions &&
					actions.map((action, i) => {
						return (
							<ActionButton
								key={i}
								disabled={action.locked ?? false}
								variant="contained"
								color={action.color ? action.color : 'primary'}
								{...(action.icon && {
									action: action.icon,
								})}
								{...(action.to && {
									link: true,
									to: action.to,
								})}
								{...(action.action && {
									onClick: action.action,
								})}
							>
								{action.text}
							</ActionButton>
						);
					})}
			</Toolbar>
		</AppBar>
	);
}
