import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';

import Popover from '@material-ui/core/Popover';
import { CountryAutoComplete } from '../AutoComplete';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(theme => ({
	fillHeight: {
		height: '100%',
	},
	popover: {
		padding: theme.spacing(2),
		minWidth: 300,
	},
	translationChips: {
		margin: theme.spacing(0.25),
		marginTop: theme.spacing(2.5),
	},
}));

function CountryPopper({ updateLanguage = {}, countryLanguage, ...props }) {
	const classes = useStyles();
	const { t } = useTranslation();

	const countryCode = countryLanguage.language.countryCode;
	const dialCode = countryLanguage.dialCode;
	const label = countryLanguage.language.label;
	const anchorEl = countryLanguage.anchorEl;

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<Fragment>
			<Chip
				aria-describedby={id}
				className={classes.translationChips}
				onClick={e => {
					updateLanguage({ anchorEl: e.currentTarget });
				}}
				variant="default"
				label={countryCode.toUpperCase()}
				{...(countryCode && {
					avatar: (
						<Avatar
							alt={label}
							src={`/assets/img/countryflags/${countryCode}.svg`}
						/>
					),
				})}
				{...props}
			/>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={e => {
					updateLanguage({ anchorEl: null });
				}}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'center',
				}}
				classes={{
					paper: classes.popover,
				}}
			>
				<CountryAutoComplete
					open={open}
					autoFocus
					disableCustomEntry
					label={t`Please select your country`}
					onChange={lang => {
						updateLanguage({
							label: lang.label,
							countryCode: lang.countryCode,
							dialCode: lang.phone,
						});
					}}
					value={label}
				/>
			</Popover>
		</Fragment>
	);
}

export default CountryPopper;
