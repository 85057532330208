import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FormRoundedIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			{props.selected ? (
				<g>
					<path d="M33,2c2.757,0,5,2.243,5,5v5h2V7c0-3.866-3.134-7-7-7h-5v2H33z" />
					<path d="M7,38c-2.757,0-5-2.243-5-5v-5H0v5c0,3.866,3.134,7,7,7h5v-2H7z" />
					<path d="M38,33c0,2.757-2.243,5-5,5h-5v2h5c3.866,0,7-3.134,7-7v-5h-2V33z" />
					<path d="M2,7c0-2.757,2.243-5,5-5h5V0H7C3.134,0,0,3.134,0,7v5h2V7z" />
				</g>
			) : (
				<g>
					<path d="M33,0h-5v1h5c3.309,0,6,2.691,6,6v5h1V7C40,3.134,36.866,0,33,0z" />
					<path d="M39,33c0,3.309-2.691,6-6,6h-5v1h5c3.866,0,7-3.135,7-7v-5h-1V33z" />
					<path d="M0,7v5h1V7c0-3.309,2.691-6,6-6h5V0H7C3.134,0,0,3.134,0,7z" />
					<path d="M1,33v-5H0v5c0,3.865,3.134,7,7,7h5v-1H7C3.691,39,1,36.309,1,33z" />
				</g>
			)}
		</SvgIcon>
	);
}
