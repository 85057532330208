import React, { useState, useEffect, Fragment } from 'react';

import Icon from '@material-ui/core/Icon';

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import { useImmer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@/styles';
import { useQuery } from '@/hooks';
import { useAppContext } from '@/components/AppContext';
import { useAjaxForm } from '@/components/Ajax';
import { ValidatedField } from '@/components/Validation';
import {
	InputAdornment,
	Tooltip,
	TextField,
	Grid,
	ActionIconButton,
	Typography,
	Link,
	Loader,
	Alert,
	ActionButton,
	Box,
} from '@/components/Layout';
import { useTrackingEvents } from '@/components/Tracking';

import { loginMessages } from './constants';
import Sso from './Sso';

const useStyles = makeStyles(theme => ({
	inputPanel: {
		maxWidth: 500,
	},
	alignRight: {
		textAlign: 'right',
	},
	googleIcon: {
		overflow: 'visible',
		position: 'absolute',
		left: 0,
		top: 0,
		padding: '6px 8px',
	},
	microsoftIcon: {
		overflow: 'visible',
		position: 'absolute',
		left: 0,
		top: 0,
		padding: '6px 8px',
	},
}));

export default function Login(props) {
	const classes = useStyles();
	const {
		app,
		app: { auth },
	} = useAppContext();
	const { t, i18n } = useTranslation();
	const query = useQuery();
	const trackingEvents = useTrackingEvents();
	const history = useHistory();

	const allowOption = key => auth.options?.includes(key);

	useEffect(() => {
		document.title = `${t`login_document_title`} | Mopinion`;
	}, []);

	let reset = query.get('reset');
	let cancelled = query.get('cancelled');
	let suspended = query.get('suspended');
	let security = query.get('security_logout');
	let social_account_not_verified = query.get('social_account_not_verified');
	let social_user_not_found = query.get('social_user_not_found');
	let sso_error = query.get('sso_error');
	let track = query.get('track');

	let errorFromUrl = reset
		? 'reset_password'
		: cancelled
		? 'cancelled_account'
		: suspended
		? 'blocked_temporary'
		: security
		? 'security_logout'
		: social_account_not_verified
		? 'social_account_not_verified'
		: social_user_not_found
		? 'social_user_not_found'
		: sso_error
		? 'sso_error'
		: '';

	const [state, setState] = useImmer({
		user: '',
		password: '',
		as_user: '',
		clicked: false,
		userValid: false,
		passwordValid: false,
		forbidden: false,
	});

	const { postForm, loading, response } = useAjaxForm({
		url: '/auth/ajax/login',
		data: {
			username: state.user,
			password: state.password,
			...(state.as_user && {
				as_user: state.as_user,
			}),
		},
		onSuccess: response => {
			if (response.force_mfa) {
				trackingEvents.track('mfa_force_register');
				app.api.startApp();
			}
			if (response.require_mfa) {
				trackingEvents.track('mfa_login');
				const firstname = response.firstname ?? '';
				const lastname = response.lastname ?? '';
				history.push('/login-mfa', { firstname, lastname, redirected: true });
			} else if (response.logged_in) {
				trackingEvents.track('login');
				app.api.startApp();
			} //403 not allowed
			else if (!response.code) {
				setState(draft => {
					draft.forbidden = true;
				});
			}
		},
	});

	useEffect(() => {
		if (track === 'home_header_nl') {
			i18n.changeLanguage('nl');
		}
	}, [track]);

	const googleLogin = () => {
		if (app.auth.social?.social_google_login) {
			document.location.href = app.auth.social.social_google_login;
		}
	};

	return (
		<Fragment>
			<div className={classes.inputPanel}>
				<form>
					<Grid
						container
						direction="row"
						spacing={3}
					>
						<Grid
							item
							xs={12}
						>
							<Typography
								variant="h4"
								gutterBottom
							>
								{t`login_title`}
							</Typography>
						</Grid>
						{(errorFromUrl || state.forbidden || response.error) && (
							<Grid
								item
								xs={12}
							>
								<Alert
									variant="filled"
									severity={
										loginMessages[
											errorFromUrl
												? errorFromUrl
												: state.forbidden
												? 'forbidden'
												: response.error
										]?.severity
									}
									title={t(
										loginMessages[
											errorFromUrl
												? errorFromUrl
												: state.forbidden
												? 'forbidden'
												: response.error
										]?.title
									)}
								>
									{t(
										loginMessages[
											errorFromUrl
												? errorFromUrl
												: state.forbidden
												? 'forbidden'
												: response.error
										]?.message
									)}
								</Alert>
							</Grid>
						)}

						{allowOption('user_creds') && (
							<>
								{allowOption('impersonation') && (
									<Grid
										item
										xs={12}
									>
										<ValidatedField
											variant="outlined"
											component={TextField}
											id={'onboarding-impersonate'}
											fullWidth
											label={t`login_impersonate`}
											value={state.as_user}
											onChange={e => {
												const { value } = e.currentTarget;
												setState(draft => {
													draft.as_user = value;
												});
											}}
										/>
									</Grid>
								)}

								<Grid
									item
									xs={12}
								>
									<ValidatedField
										data-test-element="login-email"
										variant="outlined"
										fullWidth
										label={t`login_inputlabel_email_address`}
										value={state.user}
										showErrors={state.clicked}
										autoFocus
										rules={[
											['required', true],
											['email', true],
										]}
										messages={{
											required: t`errormessage_email_required`,
											email: t`errormessage_email_invalid`,
										}}
										onValidChange={e => {
											setState(draft => {
												draft.userValid = e;
											});
										}}
										onChange={e => {
											const { value } = e.currentTarget;
											setState(draft => {
												draft.user = value;
											});
										}}
										onKeyPress={e => {
											if (e.key === 'Enter') {
												postForm();
											}
										}}
									/>
								</Grid>

								<Grid
									item
									xs={12}
								>
									<PasswordInput
										data-test-element="login-password"
										variant="outlined"
										value={state.password}
										onChange={e => {
											const { value } = e.currentTarget;
											setState(draft => {
												draft.password = value;
											});
										}}
										label={t`Password`}
										rules={[['required', true]]}
										messages={{
											required: t`errormessage_password_required`,
										}}
										showErrors={state.clicked}
										onValidChange={e => {
											setState(draft => {
												draft.passwordValid = e;
											});
										}}
										onKeyPress={e => {
											if (e.key === 'Enter') {
												postForm();
											}
										}}
									/>
								</Grid>
							</>
						)}

						{allowOption('user_creds') && (
							<Grid
								item
								xs={12}
							>
								<ActionButton
									size="large"
									fullWidth
									variant="contained"
									color="primary"
									onClick={() => {
										if (state.userValid && state.passwordValid) {
											postForm();
										}
										setState(draft => {
											draft.clicked = true;
										});
									}}
									loading={loading}
									data-test-element="login-submit"
								>
									{t`login_sign_in`}
								</ActionButton>
							</Grid>
						)}

						{allowOption('social') && (
							<Grid
								item
								xs={12}
							>
								<ActionButton
									size="large"
									fullWidth
									variant="outlined"
									color="default"
									onClick={googleLogin}
									startIcon={
										<Icon className={classes.googleIcon}>
											<img
												src={'/assets/img/r/google-icon.svg'}
												height={18}
												width={18}
											/>
										</Icon>
									}
								>
									{t`login_sign_in_social_google`}
								</ActionButton>
							</Grid>
						)}

						{allowOption('sso') && (
							<Grid
								item
								xs={12}
							>
								<Sso ssoLoginOnly={!allowOption('user_creds')} />
							</Grid>
						)}

						{allowOption('user_creds') && (
							<>
								<Grid
									item
									xs={6}
								>
									<Link
										variant="body2"
										to="/forgot-password"
										data-test-element="login-forgot-pass"
									>
										{t`forgot_password`}
									</Link>
								</Grid>

								{allowOption('signup') && (
									<Grid
										item
										xs={6}
										className={classes.alignRight}
									>
										<Tooltip
											placement="bottom"
											title={t`login_signup_tooltip`}
										>
											<Link
												variant="body2"
												to="/sign-up"
												data-test-element="login-signup"
											>
												{t`login_signup`}
											</Link>
										</Tooltip>
									</Grid>
								)}
							</>
						)}
					</Grid>
				</form>
			</div>
		</Fragment>
	);
}

function PasswordInput(props) {
	const [show, setShow] = useState(false);
	const { t } = useTranslation();

	return (
		<ValidatedField
			fullWidth
			type={show ? 'text' : 'password'}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<ActionIconButton
							onClick={() => setShow(show => !show)}
							action={!show ? 'view' : 'hide'}
							tooltip={!show ? t`inputlabel_password_show` : t`inputlabel_password_hide`}
						/>
						{props.loading ? (
							<Loader
								circular
								size={16}
							/>
						) : props.success ? (
							<CheckIcon color="secondary" />
						) : props.error ? (
							<CloseIcon color="error" />
						) : null}
					</InputAdornment>
				),
			}}
			{...props}
		/>
	);
}
