import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function FaIcon({ icon, children, ...props }) {
	const [width, height, aliases, unicode, svgPathData] = icon.icon;

	return (
		<SvgIcon
			viewBox={`0 0 ${width} ${height}`}
			{...props}
		>
			<path
				fill="currentColor"
				d={svgPathData}
			/>
		</SvgIcon>
	);
}

export default FaIcon;
