import React from 'react';

import {
	faFacebookF,
	faTwitter,
	faLinkedinIn,
	faGoogle,
	faMicrosoft,
} from '@fortawesome/free-brands-svg-icons';

import MailIcon from '@material-ui/icons/Email';

import FaIcon from './FaIcon';

export default function SocialIcon({ icon, ...props }) {
	switch (icon) {
		case 'email':
			return <MailIcon {...props} />;
		case 'facebook':
			return (
				<FaIcon
					icon={faFacebookF}
					{...props}
				/>
			);
		case 'twitter':
			return (
				<FaIcon
					icon={faTwitter}
					{...props}
				/>
			);
		case 'linkedin':
			return (
				<FaIcon
					icon={faLinkedinIn}
					{...props}
				/>
			);
		case 'google':
			return (
				<FaIcon
					icon={faGoogle}
					{...props}
				/>
			);
		case 'microsoft':
			return (
				<FaIcon
					icon={faMicrosoft}
					{...props}
				/>
			);
		default:
			return null;
	}
}
