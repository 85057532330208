import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';

import { CheckIcon } from '@/components/Icons';
import { Tooltip, Link, RenderConditional, Loader } from '@/components/Layout';
import useHasProjectPermission from './useHasProjectPermission';
import useSendProjectPermissionRequest from './useSendProjectPermissionRequest';

const useStyles = makeStyles(theme => ({
	successIcon: {
		fontSize: theme.typography.pxToRem(16),
		marginRight: theme.spacing(1),
		verticalAlign: 'middle',
	},
}));

//@todo: add functionality for requesting permission -> endpoint? modal?
export default function NoProjectPermissionTooltip({
	children,
	title,
	project,
	projectId,
	projectUuid,
	...props
}) {
	const classes = useStyles();
	const hasPermission = useHasProjectPermission({
		project,
		projectId,
		projectUuid,
	});

	const { postForm, response, loading } = useSendProjectPermissionRequest({
		project,
	});

	return (
		<>
			<RenderConditional
				component={Tooltip}
				condition={!hasPermission}
				interactive
				wrapDisabled
				title={
					loading ? (
						<Loader
							circular
							color="inherit"
							size={16}
						/>
					) : (
						title ?? (
							//default
							<>
								{response.code === 200 && <CheckIcon className={classes.successIcon} />}
								<Trans
									i18nKey={
										response.code === 200
											? `app_general-no_project_permission_tooltip-requested`
											: `app_general-no_project_permission_tooltip-default`
									}
									components={{
										requestLink: (
											<Link
												color="inherit"
												display="block"
												onClick={postForm}
												data-track-event="access_groups_request_project_access_via_tooltip"
												data-track-event-props={JSON.stringify({
													project,
												})}
											/>
										),
									}}
								/>
							</>
						)
					)
				}
				{...props}
			>
				{children}
			</RenderConditional>
		</>
	);
}
