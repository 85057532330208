import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import { ValidatedField } from '../Validation';
import { CardNumberElement } from '@stripe/react-stripe-js';
import {
	StripeTextFieldNumber,
	StripeTextFieldExpiry,
	StripeTextFieldCVC,
} from './StripeFields';
import { useAjaxForm } from '../Ajax';

import { useTranslation } from 'react-i18next';
import { useStripe, useElements } from '@stripe/react-stripe-js';

export default function CreditCardForm({
	stripeKey,
	showErrors,
	onCardChange = () => {},
	disabled,
	...props
}) {
	const { t } = useTranslation();
	const [name, setName] = useState('');

	const [cardValid, setCardValid] = useState({
		number: false,
		expiry: false,
		cvc: false,
	});

	const stripe = useStripe();
	const elements = useElements();

	useEffect(() => {
		if (name && Object.values(cardValid).every(Boolean)) {
			stripe.createToken(elements.getElement(CardNumberElement), { name }).then(obj => {
				onCardChange({ token: obj.token.id, name });
			});
		} else {
			onCardChange({ token: '', name: '' });
		}
	}, [name, cardValid]);

	return (
		<Box>
			<Box mb={2}>
				<ValidatedField
					label={t`Cardholder name`}
					placeholder={t`John Doe`}
					InputLabelProps={{
						shrink: true,
					}}
					fullWidth
					value={name}
					onChange={e => setName(e.currentTarget.value)}
					showErrors={showErrors}
					rules={[['required', true]]}
					errorMessage={t`Please enter the cardholder name`}
					disabled={disabled}
				/>
			</Box>
			<Box>
				<Grid
					container
					spacing={1}
				>
					<Grid
						item
						xs
					>
						<StripeTextFieldNumber
							label={t`Card number`}
							onChange={e => setCardValid(prev => ({ ...prev, number: e.complete }))}
							errorMessage={t`Please enter a credit card`}
							error={!cardValid.number && showErrors}
							disabled={disabled}
						/>
					</Grid>

					<Grid item>
						<StripeTextFieldExpiry
							label={t`Expires`}
							onChange={e => setCardValid(prev => ({ ...prev, expiry: e.complete }))}
							error={!cardValid.expiry && showErrors}
							disabled={disabled}
						/>
					</Grid>

					<Grid item>
						<StripeTextFieldCVC
							label={t`CVC`}
							onChange={e => setCardValid(prev => ({ ...prev, cvc: e.complete }))}
							error={!cardValid.cvc && showErrors}
							disabled={disabled}
						/>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}
