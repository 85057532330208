import React from 'react';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';

import { SurveyDesignList } from '@/components/Survey';

import { buttonTypeOptions, buttonPositionOptions } from './constants';
import { EditorListRadioGroup } from '@/components/Layout';
import { useHasPermission, permissionSubjects } from '@/components/Permission';

import {
	ButtonRightIcon,
	ButtonLeftIcon,
	ButtonBottomRightIcon,
	ButtonBottomLeftIcon,
	ButtonFloatRightIcon,
	ButtonFloatLeftIcon,
	ButtonFloatBottomRightIcon,
	ButtonFloatBottomLeftIcon,
} from '@/components/Icons';

export function ButtonTypeWeb({
	viewType,
	surveySettings,
	updateSurveySettings = () => {},
}) {
	const { t } = useTranslation();
	const { app } = useAppContext();

	const { buttonSettings, buttonSettingsMobile } = surveySettings;

	const hasEditPermission = useHasPermission({
		subject: permissionSubjects.feedback_forms_build,
	});

	const smallDrawer = !app.layout.persistentDrawer;

	return (
		<SurveyDesignList
			spaceBottom={false}
			title={t`data_collection-preview-button_type-title`}
			tabs={[
				t`data_collection-preview-button_type-tab-desktop`,
				t`data_collection-preview-button_type-tab-mobile`,
			]}
			viewType={viewType}
			smallTabs={smallDrawer}
			options={[
				[
					{
						label: !smallDrawer
							? t`data_collection-preview-button_type-label_desktop`
							: false,
						disableLabel: smallDrawer,
						control: (
							<EditorListRadioGroup
								disabled={!hasEditPermission}
								value={buttonSettings.style}
								smallDrawer={smallDrawer}
								size={smallDrawer ? 'small' : false}
								title={
									smallDrawer
										? t`data_collection-preview-button_type-radiogroup_title_desktop`
										: ''
								}
								onChange={e => {
									const { value } = e.target;
									updateSurveySettings('buttonStyleWeb', value);
								}}
								largeIcons
								options={buttonTypeOptions.map(option => {
									return {
										...option,
										...(option.label && { label: t(option.label) }),
									};
								})}
							/>
						),
					},
					{
						label: !smallDrawer
							? t`data_collection-preview-button_type-label_position`
							: false,
						disableLabel: smallDrawer,
						control: (
							<EditorListRadioGroup
								disabled={!hasEditPermission}
								smallDrawer={smallDrawer}
								value={buttonSettings.position}
								size="small"
								title={
									smallDrawer
										? t`data_collection-preview-button_type-radiogroup_title_position`
										: ''
								}
								onChange={e => {
									const { value } = e.target;
									updateSurveySettings('position', value);
								}}
								options={buttonPositionOptions.map(option => {
									return {
										...option,
										...(option.label && { label: t(option.label) }),
										icon: (
											<PositionIcon
												position={option.value}
												style={buttonSettings.style}
											/>
										),
										checkedIcon: (
											<PositionIcon
												position={option.value}
												style={buttonSettings.style}
											/>
										),
									};
								})}
							/>
						),
					},
				],
				[
					{
						label: !smallDrawer
							? t`data_collection-preview-button_type-label_mobile`
							: false,
						disableLabel: smallDrawer,
						control: (
							<EditorListRadioGroup
								smallDrawer={smallDrawer}
								value={buttonSettingsMobile.style}
								title={
									smallDrawer
										? t`data_collection-preview-button_type-radiogroup_title_mobile`
										: false
								}
								size={smallDrawer ? 'small' : false}
								onChange={e => {
									const { value } = e.target;
									updateSurveySettings('buttonStyleMobile', value);
								}}
								largeIcons
								options={buttonTypeOptions.map(option => {
									return {
										...option,
										...(option.label && { label: t(option.label) }),
									};
								})}
							/>
						),
					},
					{
						label: !smallDrawer
							? t`data_collection-preview-button_type-label_position`
							: false,
						disableLabel: smallDrawer,
						control: (
							<EditorListRadioGroup
								smallDrawer={smallDrawer}
								value={buttonSettingsMobile.position}
								size="small"
								title={
									smallDrawer
										? t`data_collection-preview-button_type-radiogroup_title_position`
										: false
								}
								onChange={e => {
									const { value } = e.target;
									updateSurveySettings('position_mobile', value);
								}}
								options={buttonPositionOptions.map(option => {
									return {
										...option,
										...(option.label && { label: t(option.label) }),
										icon: (
											<PositionIcon
												position={option.value}
												style={buttonSettingsMobile.style}
											/>
										),
										checkedIcon: (
											<PositionIcon
												position={option.value}
												style={buttonSettingsMobile.style}
											/>
										),
									};
								})}
							/>
						),
					},
				],
			]}
		/>
	);
}

function PositionIcon({ position, style }) {
	switch (position) {
		case 'right':
			return style === 'float' ? <ButtonFloatRightIcon /> : <ButtonRightIcon />;
		case 'bottom-right':
			return style === 'float' ? (
				<ButtonFloatBottomRightIcon />
			) : (
				<ButtonBottomRightIcon />
			);
		case 'bottom-left':
			return style === 'float' ? <ButtonFloatBottomLeftIcon /> : <ButtonBottomLeftIcon />;
		case 'left':
			return style === 'float' ? <ButtonFloatLeftIcon /> : <ButtonLeftIcon />;
		default:
			return null;
	}
}
