import React, { createContext, useContext, useEffect, useState } from 'react';

import { useAppContext } from '@/components/AppContext';
import { useSessionStorage, usePrevious } from '@/hooks';
import useGetDashboards from './useGetDashboards';

const DashboardContext = createContext({});

//only used in chart editor for class components
export { DashboardContext };

export function DashboardContextProvider({ children, ...props }) {
	const { app } = useAppContext();
	const [newCharts, setNewCharts] = useState([]);
	const [dashboards, loading, error, loadDashboards] = useGetDashboards();

	const [currentDashboardId, setCurrentDashboardId] = useSessionStorage(
		`/reporting/current-dashboard`,
		undefined,
		false
	);
	const previousProject = app.projects.current.id;

	useEffect(() => {
		if (previousProject && app.projects.current.id !== previousProject) {
			setCurrentDashboardId(undefined);
		}
	}, [app.projects.current.id]);

	const [maxChartsMessage, setMaxChartsMessage] = useSessionStorage(
		`/reporting/max-charts-message`,
		[],
		false
	);

	const updateMaxChartsMessage = dashboardId => {
		setMaxChartsMessage([...maxChartsMessage, dashboardId]);
	};

	const updateNewCharts = chartId => {
		setNewCharts([...newCharts, chartId]);
	};

	return (
		<DashboardContext.Provider
			value={{
				dashboards: dashboards?.dashboards ?? [],
				currentDashboardId,
				setCurrentDashboardId,
				loadDashboards,
				loading,
				newCharts,
				updateNewCharts,
				maxChartsMessage,
				updateMaxChartsMessage,
			}}
		>
			{children}
		</DashboardContext.Provider>
	);
}

export function useDashboardContext() {
	return useContext(DashboardContext);
}
