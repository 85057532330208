import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import useOnboardingContext from './useOnboardingContext';
import { useTranslation, Trans } from 'react-i18next';

import TrialMenuStepper from './TrialMenuStepper';
import {
	ActionButton,
	Typography,
	CardContent,
	CardActions,
	Grid,
} from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	cardActions: {
		padding: theme.spacing(2),
	},
	marginLeft: {
		marginLeft: 'auto',
	},
	marginRight: {
		marginRight: 'auto',
	},
	italic: {
		fontStyle: 'italic',
	},
	image: {
		'& img': {
			marginTop: theme.spacing(1),
			maxWidth: 150,
		},
	},
	panelFinishedForm: {
		width: 400,
	},
}));

export default function TrialFlowSurvey() {
	const onboarding = useOnboardingContext();
	const { url, isReturningTrial } = onboarding;

	if (isReturningTrial) {
		return <WelcomeBack />;
	}
	if (url.includes('email/detail/publish')) {
		return <EmailSnippetContent />;
	}
	if (url.includes('survey/edit')) {
		return <EditFormContent />;
	}
	return <FinishedFormContent />;
}

function WelcomeBack() {
	const onboarding = useOnboardingContext();
	const { t } = useTranslation();
	const { trialStep } = onboarding;
	const classes = useStyles();

	return (
		<>
			<CardContent>
				<TrialMenuStepper activeStep={trialStep} />
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						className={classes.image}
					>
						<img src={`/assets/img/r/onboarding/formbuilder_trial_dialogue.gif`} />
					</Grid>
					<Grid
						item
						xs
					>
						<Trans
							i18nKey="onboarding-trial_menu-step1-welcome_back-title"
							components={{
								h6: (
									<Typography
										variant="h6"
										gutterBottom
									/>
								),
								p: <Typography variant="body1" />,
							}}
						/>
					</Grid>
				</Grid>
			</CardContent>

			<CardActions className={classes.cardActions}>
				<ActionButton
					variant="contained"
					color="primary"
					className={classes.marginLeft}
					onClick={() => {
						onboarding.dispatch({
							type: 'toggle_trial_menu',
							payload: {
								open: false,
							},
						});
					}}
					data-track-event="onboarding_trial_step_survey_welcome_back"
					data-test-element="onboarding_trial_step_survey_welcome_back_button"
				>
					{t`onboarding-trial_menu-step1-welcome_back-button_continue`}
				</ActionButton>
			</CardActions>
		</>
	);
}

function EditFormContent() {
	const onboarding = useOnboardingContext();
	const { t } = useTranslation();
	const { trialStep } = onboarding;
	const classes = useStyles();

	return (
		<>
			<CardContent className={classes.panelEditForm}>
				<TrialMenuStepper activeStep={trialStep - 1} />

				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						className={classes.image}
					>
						<img src={`/assets/img/r/onboarding/formbuilder_trial_dialogue.gif`} />
					</Grid>
					<Grid
						item
						xs
					>
						<Trans
							i18nKey="onboarding-trial_menu-step1-edit-text"
							components={{
								h6: (
									<Typography
										variant="h6"
										gutterBottom
									/>
								),
								p1: (
									<Typography
										variant="body1"
										italic
										paragraph
									/>
								),
								p2: <Typography variant="body1" />,
								semibold: (
									<Typography
										fontWeight="medium"
										component="span"
									/>
								),
							}}
						/>
					</Grid>
				</Grid>
			</CardContent>

			<CardActions className={classes.cardActions}>
				<ActionButton
					className={classes.marginRight}
					color="primary"
					loading={onboarding.useTemplate}
					onClick={() => {
						onboarding.dispatch({
							type: 'use_onboarding_template',
							payload: true,
						});
					}}
					data-test-element="onboarding_trial_step_survey_suprise_form"
					data-track-event="onboarding_trial_step_survey_suprise_form"
				>
					{t`onboarding-trial_menu-step1-edit-button_surprise`}
				</ActionButton>
				<ActionButton
					variant="contained"
					color="primary"
					className={classes.marginLeft}
					onClick={() => {
						onboarding.dispatch({
							type: 'toggle_trial_menu',
							payload: {
								open: false,
							},
						});
					}}
					data-track-event="onboarding_trial_step_survey_start_button"
				>
					{t`onboarding-trial_menu-step1-edit-button_start`}
				</ActionButton>
			</CardActions>
		</>
	);
}

function EmailSnippetContent() {
	const onboarding = useOnboardingContext();
	const { t } = useTranslation();
	const { trialStep } = onboarding;
	const classes = useStyles();

	return (
		<>
			<CardContent>
				<TrialMenuStepper activeStep={trialStep} />

				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						xs
					>
						<Trans
							i18nKey="onboarding-trial_menu-step1-publish-text"
							components={{
								h6: (
									<Typography
										variant="h6"
										gutterBottom
									/>
								),
								p1: (
									<Typography
										variant="body1"
										paragraph
									/>
								),
								p2: (
									<Typography
										variant="body1"
										italic
									/>
								),
								p3: <Typography variant="body1" />,
							}}
						/>
					</Grid>
				</Grid>
			</CardContent>

			<CardActions className={classes.cardActions}>
				<ActionButton
					variant="contained"
					color="primary"
					className={classes.marginLeft}
					onClick={() => {
						onboarding.dispatch({
							type: 'toggle_trial_menu',
							payload: {
								open: false,
							},
						});
					}}
					data-track-event="onboarding_trial_step_survey_email_snippet"
				>
					{t`onboarding-trial_menu-step1-publish-button_start`}
				</ActionButton>
			</CardActions>
		</>
	);
}

function FinishedFormContent() {
	const onboarding = useOnboardingContext();
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<>
			<CardContent className={classes.panelFinishedForm}>
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						xs
					>
						<Trans
							i18nKey={
								onboarding.channel === 'email'
									? 'onboarding-trial_menu-step1-list-text_email'
									: 'onboarding-trial_menu-step1-list-text'
							}
							components={{
								h6: (
									<Typography
										variant="h6"
										gutterBottom
									/>
								),
								p: <Typography variant="body1" />,
							}}
						/>
					</Grid>
				</Grid>
			</CardContent>

			<CardActions className={classes.cardActions}>
				<ActionButton
					variant="contained"
					color="primary"
					className={classes.marginLeft}
					data-test-element="onboarding_trial_step_to_deployments"
					onClick={() => {
						if (onboarding.channel === 'email') {
							onboarding.dispatch({
								type: 'set_trial_step',
								payload: {
									step: 4,
									url: '/reporting/inbox',
								},
							});
						}
						if (onboarding.channel !== 'email') {
							onboarding.dispatch({
								type: 'set_trial_step',
								payload: {
									step: 3,
									url: '/data-collection/deployment/list',
								},
							});
						}
					}}
					data-track-event="onboarding_trial_step_survey_completed"
				>
					{onboarding.channel === 'email'
						? t`onboarding-trial_menu-step1-list-button_start_email`
						: t`onboarding-trial_menu-step1-list-button_start`}
				</ActionButton>
			</CardActions>
		</>
	);
}
