import { format } from '../../utils/date';

export function formatISOdate(isoDate, dateFormat = 'dd/MM/yyyy') {
	return format(new Date(isoDate), dateFormat);
}

export function getProgressColor(status_id, theme) {
	switch (Number(status_id)) {
		case 1:
			return theme.palette.grey[400];
		case 2:
			return theme.palette.primary.light;
		case 3:
			return theme.palette.secondary.light;
		case 4:
			return theme.palette.secondary.dark;
		case 5:
			return theme.palette.error.light;

		default:
			return theme.palette.grey[400];
	}
}

export function formatDayName(day) {
	switch (day) {
		case 'MON':
			return 'monday';
		case 'TUE':
			return 'tuesday';
		case 'WED':
			return 'wednesday';
		case 'THU':
			return 'thursday';
		case 'FRI':
			return 'friday';
		case 'SAT':
			return 'saturday';
		case 'SUN':
			return 'sunday';

		default:
			return day;
	}
}

export function formatMonthNumber(day) {
	const dummyDate = new Date(new Date().getFullYear(), new Date().getMonth(), day);
	return format(dummyDate, 'do');
}

export function exportBase(fromDate, toDate) {
	//variant: quick -> new export from inbox
	//         use_template -> quick flow with template
	//         run_schedule -> run saved schedule by setting data, and set run_immediately true
	//         change_schedule_status -> update schedule by setting export data and enabled status
	//         single -> new export from feedback exports page
	//         scheduled -> new schedule from feedback export page
	//         template -> new template from export page

	//dialog_status: intro -> on into page ( quick export or use template)
	//               content -> in detailed flow
	//               thanks -> on thanks pageValid

	return {
		uuid: '',
		dialog_status: 'content',
		exportSize: 0,
		feedback_exist: true,
		clicked: false,
		export_name: '',
		notify_to: [],
		datafield_selection: 'alldatafields',
		datafield_ids: [],
		survey_ids: [],
		feedback_ids: [],
		from_date: fromDate,
		to_date: toDate,
		split_into_sheets: false,
		format: 'xlsx',
		data_field_query: [],
		filters: {},
		rename_labels: true,
		prettify_labels: false,
		other: {},
		sort: 'feedback_created',
		order: 'desc',
		view: null,
		customView: 0,
		customFilters: null,
		custom: [],
		interval_period: 'Day',
		interval_day: '-',
		interval_time: '06:00',
		run_immediately: false,
		enabled: true, //schedule enabled
		variant: 'single',
		make_schedule: false,
		schedule_name: '',
		schedule_description: '',
		templates: [],
		save_as_template: false,
		template_selected: '',
		template_name: '',
		template_description: '',
		template_access: 'public',
		next_run_at: null,
		last_run_at: null,
		maxSetupsReached: false,
	};
}
