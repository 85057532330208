import React, { Fragment, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { useMjolnir, useAjaxForm, useEFM } from '../Ajax';
import { useAppContext } from '../AppContext';
import { Alert, ActionButton, Loader } from '../Layout';
import OauthPopup from './OauthPopup';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import ObjectID from 'bson-objectid';

function getFields(type) {
	switch (type) {
		case 'jira':
			return {
				refresh_token: '',
				userId: '',
			};

		case 'basecamp':
			return {
				refresh_token: '',
			};
	}
}

function getPos(el) {
	if (el) {
		return {
			top: el.getBoundingClientRect().top + 24,
			left: el.getBoundingClientRect().left + 24,
		};
	}

	return {};
}

const useStyles = makeStyles(theme => ({
	popover: {
		width: props => (props.target ? props.target.getBoundingClientRect().width - 48 : 0),
		height: props =>
			props.target ? props.target.getBoundingClientRect().height - 48 : 0,
		display: 'flex',
		flexDirection: 'column',
	},
}));

export default function NewAuthPopover({
	type,
	open,
	target,
	loadAuth,
	onClose,
	...props
}) {
	const classes = useStyles({ target: target.current });
	const { app } = useAppContext();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [token, setToken] = useState('');
	const [name, setName] = useState('');
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [fields, setFields] = useState(getFields(type));
	const [id, setId] = useState(ObjectID().toString());

	const [jiraData, jiraDataLoading] = useMjolnir(
		`/jira-auth/refresh?auth-code=${token}`,
		{},
		!token || type !== 'jira'
	);

	const alertStateJira = jiraData?.refresh_token
		? {
				severity: 'success',
				title: t`webhooks_apps-webhook_addit_dialog-oauth_alert-jira-success-title`,
				message: t`webhooks_apps-webhook_addit_dialog-oauth_alert-jira-success-message`,
		  }
		: jiraData?.error
		? {
				severity: 'error',
				title: t`webhooks_apps-webhook_addit_dialog-oauth_alert-jira-error-title`,
				message: t`webhooks_apps-webhook_addit_dialog-oauth_alert-jira-error-message`,
		  }
		: !isPopupOpen && !token
		? {
				severity: 'error',
				title: t`webhooks_apps-webhook_addit_dialog-oauth_alert-jira-issue-title`,
				message: t`webhooks_apps-webhook_addit_dialog-oauth_alert-jira-issue-message`,
		  }
		: {
				severity: 'info',
				title: t`webhooks_apps-webhook_addit_dialog-oauth_alert-jira-validating-title`,
				message: t`webhooks_apps-webhook_addit_dialog-oauth_alert-jira-validating-message`,
				loading: true,
		  };

	const [basecampData, basecampDataLoading] = useEFM(
		`/integrations/ajax/basecamp-auth`,
		{ code: token, type: 'auth' },
		!token || type !== 'basecamp'
	);

	const alertStateBasecamp = basecampData?.refresh_token
		? {
				severity: 'success',
				title: t`webhooks_apps-webhook_addit_dialog-oauth_alert-basecamp-success-title`,
				message: t`webhooks_apps-webhook_addit_dialog-oauth_alert-basecamp-success-message`,
		  }
		: basecampData?.error
		? {
				severity: 'error',
				title: t`webhooks_apps-webhook_addit_dialog-oauth_alert-basecamp-error-title`,
				message: t`webhooks_apps-webhook_addit_dialog-oauth_alert-basecamp-error-message`,
		  }
		: !isPopupOpen && !token
		? {
				severity: 'error',
				title: t`webhooks_apps-webhook_addit_dialog-oauth_alert-basecamp-issue-title`,
				message: t`webhooks_apps-webhook_addit_dialog-oauth_alert-basecamp-issue-message`,
		  }
		: {
				severity: 'info',
				title: t`webhooks_apps-webhook_addit_dialog-oauth_alert-basecamp-validating-title`,
				message: t`webhooks_apps-webhook_addit_dialog-oauth_alert-basecamp-validating-message`,
				loading: true,
		  };

	const handleTokenReceived = receivedToken => {
		setToken(receivedToken);
	};

	const handlePopupVisibilty = isPopupOpen => {
		setIsPopupOpen(isPopupOpen);
	};

	const { postForm, loading } = useAjaxForm({
		valid: () => name && type && (jiraData?.refresh_token || basecampData?.refresh_token),
		url: '/integrations/ajax/save-auth',
		data: {
			auth: {
				id,
				name,
				type,
				email: app.users.current.email,
				fields: {
					...(type === 'jira' && {
						refresh_token: jiraData.refresh_token,
						userId: jiraData.user_id,
					}),
					...(type === 'basecamp' && {
						refresh_token: basecampData.refresh_token,
					}),
				},
			},
		},
		onSuccess: r => {
			if (r.code === 200) {
				enqueueSnackbar(t`Connection saved`);
				onClose();
				loadAuth();
			}
		},
	});

	function update(key, value) {
		setFields(prev => ({
			...prev,
			[key]: value,
		}));
	}

	return (
		<Popover
			anchorReference="anchorPosition"
			anchorPosition={getPos(target.current)}
			open={open}
			classes={{
				paper: classes.popover,
			}}
			onClose={onClose}
			{...props}
		>
			{open && (
				<OauthPopup
					type={type}
					onTokenReceived={handleTokenReceived}
					onPopupIsVisible={handlePopupVisibilty}
				/>
			)}
			{type === 'jira' && (
				<Fragment>
					<DialogTitle>{t`Create Jira connection`}</DialogTitle>
					<DialogContent>
						{open && (
							<Fragment>
								<Box mb={2}>
									<Alert
										severity={alertStateJira.severity}
										title={alertStateJira.title}
										icon={
											alertStateJira.loading ? (
												<Loader
													circular
													size={16}
												/>
											) : null
										}
									>
										{alertStateJira.message}
									</Alert>
								</Box>

								{jiraData?.refresh_token ? (
									<Box mb={2}>
										<TextField
											label={t`Connection name`}
											onChange={e => setName(e.target.value)}
											value={name}
											fullWidth
										/>
									</Box>
								) : null}
							</Fragment>
						)}
					</DialogContent>
					<DialogActions>
						<ActionButton onClick={onClose}>{t`Cancel`}</ActionButton>
						<ActionButton
							variant="contained"
							color="primary"
							loading={loading}
							disabled={!name || !token || !jiraData?.refresh_token || !jiraData?.user_id}
							onClick={postForm}
						>
							{t`Save connection`}
						</ActionButton>
					</DialogActions>
				</Fragment>
			)}
			{type === 'basecamp' && (
				<Fragment>
					<DialogTitle>{t`Create Basecamp connection`}</DialogTitle>
					<DialogContent>
						{open && (
							<Fragment>
								<Box mb={2}>
									<Alert
										severity={alertStateBasecamp.severity}
										title={alertStateBasecamp.title}
										icon={
											alertStateBasecamp.loading ? (
												<Loader
													circular
													size={16}
												/>
											) : null
										}
									>
										{alertStateBasecamp.message}
									</Alert>
								</Box>
								{basecampData?.refresh_token ? (
									<Box mb={2}>
										<TextField
											label={t`Connection name`}
											onChange={e => setName(e.target.value)}
											value={name}
											fullWidth
										/>
									</Box>
								) : null}
							</Fragment>
						)}
					</DialogContent>
					<DialogActions>
						<ActionButton onClick={onClose}>{t`Cancel`}</ActionButton>
						<ActionButton
							variant="contained"
							color="primary"
							loading={loading}
							disabled={!name || !token || !basecampData?.refresh_token}
							onClick={postForm}
						>
							{t`Save connection`}
						</ActionButton>
					</DialogActions>
				</Fragment>
			)}
		</Popover>
	);
}
