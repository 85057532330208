import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
	SimpleDialog,
	CollapsibleBox,
	Box,
	FormattedDate,
	Typography,
	Divider,
	ActionButton,
	Chip,
} from '@/components/Layout';
import { UserAvatar, UserName } from '@/components/Users';
import { useDeploymentStore } from './DeploymentStore';
import DeploymentDiff from './DeploymentDiff';

export default function DeploymentVersionDialog({ open, onClose }) {
	const { t } = useTranslation();
	const workingDraft = useDeploymentStore(store => store.workingDraft);
	const current = useDeploymentStore(store => store.current);
	const draft = useDeploymentStore(store => store.draft);
	const versions = useDeploymentStore(store => store.versions);
	const setVersion = useDeploymentStore(store => store.actions.setVersion);

	const mappableVersions = !Array.isArray(versions)
		? []
		: versions.length >= 10
		? versions.slice(0, -1)
		: versions;

	return (
		<SimpleDialog
			open={open}
			onClose={onClose}
			title={t`data_collection-deployment_version_dialog-title`}
			cancel={t`data_collection-deployment_version_dialog-close`}
		>
			{current && (
				<>
					<Typography
						variant="subtitle2"
						color="textSecondary"
					>{t`data_collection-deployment_version_dialog-current-subtitle`}</Typography>
					<DeploymentVersionListItem
						name={current.name}
						published={current.published}
						userUuid={current.user_uuid}
						datetime={current.datetime}
						description={current.description}
						rules={current.rules}
						selected={workingDraft.revision === current.revision}
						type="current"
						onSelect={() => setVersion('current')}
						previous={versions?.[0]?.deployment}
					/>
				</>
			)}

			{draft?.rules?.length > 0 && (
				<>
					<Typography
						variant="subtitle2"
						color="textSecondary"
					>{t`data_collection-deployment_version_dialog-draft-subtitle`}</Typography>
					<DeploymentVersionListItem
						name={draft.name}
						published={draft.published}
						userUuid={draft.user_uuid}
						datetime={draft.datetime}
						rules={draft.rules}
						type="draft"
						selected={workingDraft.revision === 'draft'}
						onSelect={() => setVersion('draft')}
						previous={current}
					/>
				</>
			)}
			{mappableVersions.length > 0 && (
				<>
					<Typography
						variant="subtitle2"
						color="textSecondary"
					>{t`data_collection-deployment_version_dialog-version-subtitle`}</Typography>
					{mappableVersions.map(({ type, deployment = {} }, index) => {
						const { datetime, description, user_uuid, rules, revision, published, name } =
							deployment;

						return (
							<DeploymentVersionListItem
								name={name}
								published={published}
								key={datetime}
								userUuid={user_uuid}
								description={description}
								datetime={datetime}
								rules={rules}
								type={type}
								revision={revision}
								divider={index !== versions.length - 1}
								selected={workingDraft.revision === revision}
								onSelect={() => setVersion(revision)}
								previous={versions?.[index + 1]?.deployment}
							/>
						);
					})}
				</>
			)}
		</SimpleDialog>
	);
}

function DeploymentVersionListItem({
	userUuid,
	datetime,
	description,
	published,
	name,
	rules,
	type,
	revision,
	divider,
	isActive,
	selected,
	onSelect = () => {},
	previous,
}) {
	const { t } = useTranslation();
	return (
		<>
			<CollapsibleBox
				pt={1}
				pb={1}
				startCollapsed={type !== 'current' && !isActive}
				label={
					<Box
						display="flex"
						flexGrow={1}
						justifyContent="space-between"
					>
						<div>
							<Trans
								i18nKey={`data_collection-deployment_version_dialog-${type}_version_title`}
								components={{
									date: <FormattedDate date={datetime} />,
								}}
							/>
						</div>
						{selected && (
							<Chip
								color="primary"
								label={t`data_collection-deployment_version_dialog-selected_chip-label`}
								size="small"
							/>
						)}
					</Box>
				}
				{...((type === 'current' || type === 'draft') && {
					// border: 1,
					// borderRadius: 'borderRadius',
					// borderColor: 'divider',
					pb: 1.5,
					mb: 3,
				})}
			>
				<Box>
					<Box
						display="flex"
						alignItems="center"
					>
						<UserAvatar
							size="small"
							uuid={userUuid}
						/>
						<Typography
							ml={1}
							variant="body2"
						>
							<Trans
								i18nKey={`data_collection-deployment_version_dialog-${
									type === 'draft' ? 'modified_by' : 'published_by'
								}`}
								components={{
									username: <UserName uuid={userUuid} />,
								}}
							/>
						</Typography>
					</Box>
					{description && (
						<Box mt={1}>
							<Typography
								variant="caption"
								color="textSecondary"
							>{t`data_collection-deployment_version_dialog-version_item-description`}</Typography>
							<Typography variant="body2">{description}</Typography>
						</Box>
					)}

					<Box mt={2}>
						<DeploymentDiff
							newName={name}
							oldName={previous?.name}
							newStatus={published}
							oldStatus={previous?.published}
							newRules={rules}
							oldRules={previous?.rules}
						/>
					</Box>

					{!selected && (
						<Box
							mt={3}
							mb={1}
						>
							<ActionButton
								variant="outlined"
								color="primary"
								size="small"
								onClick={onSelect}
							>
								{t`data_collection-deployment_version_dialog-select_version_button-label`}
							</ActionButton>
						</Box>
					)}
				</Box>
			</CollapsibleBox>
			{divider && <Divider />}
		</>
	);
}
