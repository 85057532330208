import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormGroup from '@material-ui/core/FormGroup';

import Box from '@material-ui/core/Box';

import { useAlertContext } from './AlertContext';

import useSaveAlert from './useSaveAlert';

import { useAppContext } from '@/components/AppContext';
import { DataSourceSelect, DataFieldSelect } from '@/components/Select';
import { SimpleDialog, DataFieldConditions, ActionButton } from '@/components/Layout';
import { ValidatedField } from '@/components/Validation';
import { UserAutoComplete } from '@/components/AutoComplete';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';
import set from 'lodash.set';
import { operators } from '@/helpers';
import { format } from '@/utils/date';
import { useSnackbar } from 'notistack';
import { removeArrayDuplicatesFromShallowClone } from '@/utils';

const useStyles = makeStyles(theme => ({
	grid: {
		'& > div': {
			width: '100%',
		},
	},
}));

function conditionBase() {
	return {
		datafieldid: '',
		condition: 'e',
		target: '',
	};
}

export default function AddEditAlertDialog({
	alert_id,
	onClose = () => {},
	open,
	...props
}) {
	const classes = useStyles();
	const { app } = useAppContext();
	const { state, api } = useAlertContext();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const [alertState, setAlertState] = useImmer({
		alert: {
			name: '',
			alert: [conditionBase()],
			customer_highlights: false,
			active: true,
			alert_id: '',
			extra_highlights: [],
			send_extra_highlights: false,
			date: format(new Date(), 'yyyy-MM-dd'),
			domain: app.domain,
			project_id: app.projects.current.id,
			organisation_id: app.organisations.current.org_id,
			created_user_id: app.users.current.id,
			survey_id: null,
			recipient_ids: [],
			all_data: true,
			all_project_data: false,
			plain_html: false,
			customer_details: false,
			survey_highlights: false,
		},
		clicked: false,
	});

	useEffect(() => {
		if (alert_id) {
			const item = state.config.find(item => item.alert_id === alert_id);

			if (item) {
				setAlertState(draft => {
					draft.alert = item;
				});

				setAlertState(draft => {
					draft.recipient_ids = removeArrayDuplicatesFromShallowClone([
						...(item.recipient_ids ?? []),
						...(item.alert_user_id ? [item.alert_user_id] : []),
					]).filter(id => app.users.inProject.includes(Number(id)));
				});
			}
		}
	}, [open]);

	const [saveAlert, loadingSaveAlert] = useSaveAlert({
		alert: { ...alertState.alert },
		onSuccess: r => {
			onClose();
			enqueueSnackbar(
				alert_id
					? `${t`Alert`} ${alert.name} updated`
					: `${t`Alert`} ${alert.name} created`
			);

			api.dispatch({
				type: alert_id ? 'update_rule' : 'add_filled_rule',
				payload: {
					alert: {
						...alert,
						...(!alert_id && {
							alert_id: r.alert_id,
						}),
					},
				},
			});
		},
	});

	const { alert, clicked } = alertState;

	function setValue(key, value) {
		setAlertState(draft => {
			set(draft.alert, key, value);
		});
	}
	function removeArrayIndex(key, index) {
		setAlertState(draft => {
			draft.alert[key].splice(index, 1);
		});
	}
	function addToArray(key, value) {
		setAlertState(draft => {
			draft.alert[key].push(value);
		});
	}
	function removeFromArray(key, value) {
		const filteredArray = alert[key].filter(id => id !== value);
		setAlertState(draft => {
			draft.alert[key] = filteredArray;
		});
	}
	function setClicked() {
		setAlertState(draft => {
			draft.clicked = true;
		});
	}
	return (
		<SimpleDialog
			title={alert_id ? t`Edit email alert` : t`New email alert`}
			submit={alert_id ? t`Edit alert` : t`Create alert`}
			onClose={onClose}
			loading={loadingSaveAlert}
			onSubmit={() => {
				setClicked();
				if (
					alert.name &&
					alert.recipient_ids.length &&
					(alert.survey_id || alert.all_project_data)
				) {
					saveAlert();
				}
			}}
			open={open}
			dataTestElement="alertCreateAlert"
			dataTrackEvent={alert_id ? 'settings_alert_edited' : 'settings_alert_created'}
			{...props}
		>
			<Grid
				container
				spacing={3}
				className={classes.grid}
				direction="column"
			>
				<Grid
					item
					xs
				>
					<ValidatedField
						component={TextField}
						label={t`Name`}
						fullWidth
						onChange={e => setValue('name', e.currentTarget.value)}
						value={alert.name}
						validateValue={alert.name}
						errorMessage={t`Please name your alert`}
						rules={[['required', true]]}
						showErrors={clicked}
						dataTestElement="alertAddName"
					/>
				</Grid>

				<Grid
					item
					xs
				>
					<ValidatedField
						component={DataSourceSelect}
						label={t`Select a data source`}
						value={
							alert.survey_id ? alert.survey_id : alert.all_project_data ? 'ALL_DATA' : ''
						}
						withAllData
						onChange={e => {
							const { value } = e.target;

							if (value === 'ALL_DATA') {
								setValue('survey_id', null);
								setValue('extra_highlights', []);
								setValue('all_project_data', true);
							} else {
								setValue('survey_id', e.target.value);
								setValue('extra_highlights', []);
								setValue('all_project_data', false);
							}
							//set empty conditions if the datasource changes
							setValue('alert', [conditionBase()]);
						}}
						validateValue={alert.survey_id || alert.all_project_data}
						errorMessage={t`Please select a form or dataset`}
						rules={[['required', true]]}
						showErrors={clicked}
						dataTestElement="alertDatasourceList"
					/>
				</Grid>

				<Grid
					item
					xs
				>
					<ValidatedField
						component={UserAutoComplete}
						multiple
						onlyVerifiedUsers
						label={t`Send alert to`}
						placeholder={t`Search users`}
						selectedItem={
							alert.recipient_ids
								? alert.recipient_ids.map(user_id => ({ id: Number(user_id) }))
								: []
						}
						onChange={userArray =>
							setValue(
								'recipient_ids',
								userArray.map(user => user.id)
							)
						}
						renderChips
						validateValue={alert.recipient_ids}
						rules={[
							[
								'custom',
								value => {
									return value ? value.length > 0 : false;
								},
							],
						]}
						errorMessage={t`Please assign your alert to a user`}
						showErrors={clicked}
					/>
				</Grid>

				{!alert.all_project_data && alert.survey_id && (
					<Grid
						item
						xs
					>
						<DataFieldSelect
							label={t`Add feedback values to email`}
							multiple
							renderChips
							value={
								alert.extra_highlights
									? alert.extra_highlights
											.filter(highlight => highlight.datafieldid)
											.map(highlight => highlight.datafieldid)
									: []
							}
							onChange={e => {
								setValue('send_extra_highlights', e.target.value.length > 0);
								setValue(
									'extra_highlights',
									e.target.value.map(id => ({ datafieldid: id }))
								);
							}}
							sourceId={alert.survey_id}
						/>
					</Grid>
				)}

				{!alert.all_project_data && alert.survey_id && (
					<Grid item>
						<FormGroup>
							<FormLabel>{t`Send alert`}</FormLabel>
							<RadioGroup
								//row
								value={alert.all_data ? 'all' : 'specific'}
								onChange={e => setValue('all_data', e.target.value === 'all')}
							>
								<FormControlLabel
									value="all"
									control={<Radio />}
									label={t`For all new feedback items`}
								/>
								<FormControlLabel
									value="specific"
									control={<Radio />}
									label={t`When an item contains specific values`}
								/>
							</RadioGroup>
						</FormGroup>

						{!alert.all_data && (
							<Box mt={1}>
								<FormGroup>
									<FormLabel>{t`Send when`}</FormLabel>
									<DataFieldConditions
										conditionArray={alert.alert.map(condition => ({
											datafield_id: condition.datafieldid,
											operator: condition.condition,
											value: condition.target,
										}))}
										surveyId={alert.survey_id}
										operatorFn={operators}
										PossibleValuesProps={{
											multiple: false,
										}}
										onDataFieldChange={(feedbackConditionIndex, e) => {
											setValue(
												`alert[${feedbackConditionIndex}].datafieldid`,
												e.target.value
											);
											setValue(`alert[${feedbackConditionIndex}].target`, '');
										}}
										onOperatorChange={(feedbackConditionIndex, e) => {
											setValue(
												`alert[${feedbackConditionIndex}].condition`,
												e.target.value
											);
										}}
										onPossibleValueChange={(feedbackConditionIndex, value) => {
											setValue(`alert[${feedbackConditionIndex}].target`, value);
										}}
										onRemoveRow={feedbackConditionIndex =>
											removeArrayIndex('alert', feedbackConditionIndex)
										}
									/>
								</FormGroup>
								<Box
									mt={1}
									clone
								>
									<ActionButton
										onClick={() => addToArray('alert', conditionBase())}
										action="add_list"
										label={t`And`}
									/>
								</Box>
							</Box>
						)}
					</Grid>
				)}

				<Grid
					item
					xs
				>
					<div>
						<FormControlLabel
							control={<Checkbox />}
							checked={alert.active}
							label={t`Active`}
							onChange={e => setValue('active', e.target.checked)}
						/>
					</div>
					<div>
						<FormControlLabel
							control={<Checkbox />}
							checked={alert.customer_details}
							label={t`Add customer details to email`}
							onChange={e => setValue('customer_details', e.target.checked)}
						/>
					</div>
					<div>
						<FormControlLabel
							control={<Checkbox />}
							checked={alert.survey_highlights}
							label={t`Add feedback highlights to email`}
							onChange={e => setValue('survey_highlights', e.target.checked)}
						/>
					</div>
					<div>
						<FormControlLabel
							control={<Checkbox />}
							checked={alert.plain_html}
							label={t`Send email as plain text`}
							onChange={e => setValue('plain_html', e.target.checked)}
						/>
					</div>
				</Grid>
			</Grid>
		</SimpleDialog>
	);
}
