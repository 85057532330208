import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@/styles';

import { useDataSourceContext } from '@/components/DataSources';
import { DataSourceSelect } from '@/components/Select';
import { Position } from 'react-flow-renderer';

const useStyles = makeStyles(theme => ({
	avatar: {
		minWidth: theme.spacing(4),
		'& div': {
			height: theme.spacing(3),
			width: theme.spacing(3),
		},
	},
	formSelection: {
		position: 'absolute',
		'& div:first-of-type': {
			paddingTop: 0,
			paddingBottom: 2,
		},
		'& p:first-of-type': {
			display: 'none',
		},
	},
}));

export default function DirectInsightsQuickSelection({ selectedDatasource }) {
	const { t } = useTranslation();
	const classes = useStyles();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const { datasource } = useDataSourceContext();

	return (
		<DataSourceSelect
			dense
			className={classes.formSelection}
			dataSourceTypes={['forms', 'datasets']}
			value={selectedDatasource.id.toString()}
			ListItemAvatarProps={{
				className: classes.avatar,
			}}
			onChange={e => {
				if (!e.target.value) {
					return;
				}
				history.push(`/reporting/direct-insights/${e.target.value}`);
				enqueueSnackbar(
					t('reporting-insights-dashboard_quick_selection-snackbar_switched', {
						source: datasource.api.getSource(e.target.value)?.name ?? '',
					})
				);
			}}
		/>
	);
}
