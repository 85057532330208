import React from 'react';
import { useAppContext } from '@/components/AppContext';
import { useAjaxForm } from '@/components/Ajax';

export default function useDeleteAlert({ id, onSuccess = () => {}, ...props }) {
	const { app } = useAppContext();
	const { postForm, loading, clicked } = useAjaxForm({
		url: `/api/1/alerts/${app.domain}/${app.projects.current.id}/${id}`,
		method: 'delete',
		type: 'mjolnir',
		//	data: {},
		onSuccess,
		...props,
	});

	return [postForm, loading, clicked];
}
