import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import CheckIcon from '@material-ui/icons/Check';

import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useImmer } from 'use-immer';

import { ValidatedField } from '@/components/Validation';
import { useAjaxForm, useEFM } from '@/components/Ajax';
import { Typography, Link, Grid, Alert, ActionButton } from '@/components/Layout';
import BrandingSingle from './BrandingSingle';
import { ErrorBoundary } from '@/components/Utility';
import { useAppContext } from '@/components/AppContext';
import { useQuery } from '@/hooks';
import { getCookie } from '@/utils';
import { useTrackingEvents, analyticsEvent } from '@/components/Tracking';

const useStyles = makeStyles(theme => ({
	inputPanel: {
		maxWidth: 500,
	},
	alignCenter: {
		textAlign: 'center',
	},
	alignRight: {
		textAlign: 'right',
	},
	noSidePadding: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	signupTitle: {
		marginBottom: theme.spacing(7),
	},
}));

export default function SignUp(props) {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const {
		app,
		app: { auth },
	} = useAppContext();
	const history = useHistory();
	const query = useQuery();
	const trackingEvents = useTrackingEvents();

	useEffect(() => {
		document.title = `${t`login-signup-start_trial`} | Mopinion`;
	}, []);

	const [ipInfo] = useEFM('/account/ajax/get-country');
	const [browserLang, browserLocale] = navigator.language?.toLowerCase().split('-');

	const country = ipInfo?.country
		? ipInfo.country.toLowerCase()
		: browserLocale === 'nl'
		? 'nl'
		: 'gb';

	let track = query.get('track');

	const [state, setState] = useImmer({
		email: '',
		emailValid: false,
		clicked: false,
		password: '',
	});

	const {
		postForm: fetchEmail,
		loading: emailLoading,
		response: emailResponse,
	} = useAjaxForm({
		url: '/account/ajax/check-allowed',
		data: { email: state.email },
	});

	const { postForm, loading, response, clicked } = useAjaxForm({
		url: '/account/ajax/sign-up',
		data: {
			email: state.email,
			language: app?.language?.id ?? 2,
			countryCode: country,
		},
		valid: () => state.emailValid && emailResponse.message === 1,
		onSuccess: response => {
			if (response.code === 200) {
				trackingEvents.track('trial_sign_up', {
					track_cookie: getCookie('M_TRACK'),
					lang_cookie: getCookie('MOP_LANG'),
					email: state.email,
				});
				if (document.location.hostname === 'app.mopinion.com') {
					analyticsEvent('event', 'trial_sign_up', {
						track_cookie: getCookie('M_TRACK'),
						lang_cookie: getCookie('MOP_LANG'),
					});

					analyticsEvent('event', 'trial_sign_up', {
						track_cookie: getCookie('M_TRACK'),
						lang_cookie: getCookie('MOP_LANG'),
						send_to: 'G-KKSXKL5K5Y',
					});
				}
				history.push('/validate-sign-up');
			}
		},
	});

	useEffect(() => {
		setState(draft => {
			draft.emailValid = emailResponse.message ? true : false;
		});
	}, [emailResponse]);

	useEffect(() => {
		if (state.emailValid || state.email.length > 5) {
			fetchEmail();
		}
	}, [state.email]);

	useEffect(() => {
		if (track === 'home_header_nl') {
			i18n.changeLanguage('nl');
		}
	}, [track]);

	return (
		<>
			<div className={classes.inputPanel}>
				<form>
					<Grid
						container
						direction="row"
						spacing={3}
					>
						<Grid
							item
							xs={12}
							className={`${classes.alignCenter} ${classes.signupTitle}`}
						>
							<Typography
								variant="h6"
								gutterBottom
							>
								{t`login-signup-logo_subtitle`}
							</Typography>
						</Grid>

						<Grid
							item
							xs={12}
						>
							<Typography
								variant="h4"
								gutterBottom
							>
								{t`login-signup-main_title`}
							</Typography>
							<CheckList
								items={[
									'login-signup-checklist-complex_data',
									'login-signup-checklist-decision',
									'login-signup-checkelist-improve_chanel',
								]}
							/>
						</Grid>

						<ErrorBoundary>
							{Object.keys(response).length > 0 && (
								<Grid
									item
									xs={12}
								>
									<Alert severity="error">{t(response.message)}</Alert>
								</Grid>
							)}
						</ErrorBoundary>

						<Grid
							item
							xs={12}
						>
							<ValidatedField
								variant="outlined"
								fullWidth
								label={t`login-signup-inputlabel-email_addres`}
								value={state.email}
								data-test-element="signup-email"
								rules={[
									['required', true],
									['email', true],
									['custom', () => emailResponse.message === 1],
								]}
								messages={{
									required: t`errormessage_email_trial`,
									email: t`errormessage_email_invalid`,
									custom: emailResponse.business
										? t`errormessage_email_business`
										: t`errormessage_email_not_allowed`,
								}}
								showErrors={state.clicked}
								autoFocus
								required
								onValidChange={e =>
									setState(draft => {
										draft.emailValid = e;
									})
								}
								helperText={t`login-signup-helper-get_started`}
								onChange={e => {
									const { value } = e.currentTarget;
									setState(draft => {
										draft.email = value;
									});
								}}
								onKeyDown={e => {
									if (e.key === 'Enter') {
										e.preventDefault();
										postForm();
									}
								}}
							/>
						</Grid>

						<Grid
							item
							xs={12}
						>
							<ActionButton
								size="large"
								fullWidth
								variant="contained"
								color="primary"
								data-test-element="signup-submit"
								data-track-event="account_signup_create"
								onClick={() => {
									if (state.emailValid) {
										postForm();
									}
									setState(draft => {
										draft.clicked = true;
									});
								}}
								loading={loading}
							>
								{t`login-signup-button-start_trial`}
							</ActionButton>
						</Grid>

						<Grid
							item
							xs={12}
							className={classes.alignRight}
						>
							<Link
								variant="body2"
								to="/login"
								data-test-element="signup-login"
							>
								{t`login-signup-button-back_to_login`}
							</Link>
						</Grid>
					</Grid>
				</form>
			</div>

			<BrandingSingle maxWidth={500} />
		</>
	);
}

function CheckList({ items }) {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<List disablePadding>
			{items.map((item, index) => (
				<ListItem
					key={index}
					button={false}
					className={classes.noSidePadding}
				>
					<ListItemIcon>
						<CheckIcon
							color="primary"
							fontSize="large"
						/>
					</ListItemIcon>
					<ListItemText
						primary={
							<Trans
								i18nKey={item}
								components={{
									bold: <strong />,
								}}
							/>
						}
					/>
				</ListItem>
			))}
		</List>
	);
}
