import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	icon: {
		width: props => props.width ?? 32,
		verticalAlign: 'middle',
	},
}));

const hasCardIcon = [
	'visa',
	'mastercard',
	'americanexpress',
	'discover',
	'jcb',
	'dinersclub',
	'diners',
	'amex',
];

export default function CCIcon({ brand = '', width }) {
	const classes = useStyles({ width });
	const formattedBrand = brand.replace(/ /g, '').toLowerCase();
	const src = `/assets/img/cards/${
		hasCardIcon.indexOf(formattedBrand) > -1 ? formattedBrand : 'unknown'
	}.svg`;

	return (
		<img
			src={src}
			className={classes.icon}
		/>
	);
}
