import React from 'react';
import Tooltip from './Tooltip';

export default function RenderConditional({
	component,
	children,
	condition,
	componentProps = {},
	...props
}) {
	const RenderComponent = component ? component : Tooltip;

	return condition ? (
		<RenderComponent
			{...props}
			{...componentProps}
		>
			{typeof children === 'function' ? children(props) : children}
		</RenderComponent>
	) : typeof children === 'function' ? (
		children(props)
	) : (
		children
	);
}
