import React, { Fragment } from 'react';
import ActionAddDialog from './ActionAddDialog';
import ActionReassignDialog from './ActionReassignDialog';
import { AddTagsDialog } from '../Tags';
import { AjaxFormDialog } from '../Layout';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function ActionActionDialogs({
	ids = [],
	open = '',
	onClose = () => {},
	loadData = () => {},
	onDelete = () => {},
}) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	function onSuccess() {
		loadData();
		onClose();
	}

	return (
		<Fragment>
			<ActionAddDialog
				open={open === 'add' || open === 'edit'}
				id={ids[0]}
				onClose={onClose}
				onSuccess={onSuccess}
			/>
			<ActionReassignDialog
				open={open === 'reassign'}
				ids={ids}
				onClose={onClose}
				onSuccess={onSuccess}
			/>
			<AddTagsDialog
				open={open === 'tags'}
				onClose={onClose}
				action_ids={ids}
				onSuccess={onSuccess}
				dataTrackEvent="action_action_tags_added"
			/>
			<AjaxFormDialog
				AjaxFormProps={{
					url: '/actions/inbox/delete',
					data: { ids: ids },
					onSuccess: () => {
						loadData();
						enqueueSnackbar(`Actions deleted`);
						onClose();
						onDelete();
					},
				}}
				open={open === 'delete'}
				onClose={onClose}
				title={t`Delete actions`}
				content={
					<Fragment>
						{t`Do you want to permanently delete the selected`}{' '}
						{ids.length > 0 ? t`actions` : t`action`}?{' '}
						<strong>{t`You won't be able to undo this.`}</strong>
					</Fragment>
				}
			/>
		</Fragment>
	);
}
