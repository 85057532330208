import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Box from '@material-ui/core/Box';
import Typing from './Typing';
import get from 'lodash.get';

import { FormattedDate } from '../Layout';

const useStyles = makeStyles(theme => ({
	messageContainer: {
		marginTop: theme.spacing(1),
	},
	bubble: {
		backgroundColor: theme.palette.grey[100],
		borderRadius: theme.spacing(1),
		borderTopLeftRadius: 0,
		padding: theme.spacing(1, 2),
		maxWidth: 'calc(100% - 56px)',
	},
	bubbleRight: {
		borderTopLeftRadius: theme.spacing(1),
		borderTopRightRadius: 0,
	},
	statusMessageGrid: {
		display: 'flex',
	},
	statusMessage: {
		borderRadius: theme.spacing(1),
		padding: theme.spacing(0.5, 1),
		maxWidth: '90%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	statusMessageTypography: {
		fontSize: theme.typography.pxToRem(11),
		//fontWeight:600
	},
	avatar: {
		margin: theme.spacing(0, 1),
	},
	hide: {
		visibility: 'hidden',
	},
	time: {
		fontSize: theme.typography.pxToRem(10),
	},
	timeSpacer: {
		margin: theme.spacing(0, 1),
	},
}));

const Message = React.forwardRef(function Message(
	{
		message,
		index,
		show = true,
		useTyping,
		typingAwait,
		typingTimeout = 1000,
		position,
		avatar,
		name,
		user,
		time,
		allMessages,
		type,
		showBubble,
		children,
		isRight,
		sideEffect,
		onEntered = () => {},
		statusMessage,
	},
	ref
) {
	const [doneTyping, setDoneTyping] = useState(false);
	useEffect(() => {
		// async function typing() {
		// 	try {
		// 		await typingAwait;
		// 		setDoneTyping(true);
		// 	} catch(e) {}
		// }

		// if (typingAwait) {
		// 	typing();
		// } else

		if (useTyping) {
			const timer = setTimeout(() => {
				setDoneTyping(true);
			}, typingTimeout);
			return () => clearTimeout(timer);
		}
	}, [
		useTyping,
		//, typingAwait
	]);

	useEffect(() => {
		if (typeof sideEffect === 'function') {
			sideEffect();
		}
	}, [sideEffect]);

	const classes = useStyles();
	const messageFromSameUser = get(allMessages, `[${index - 1}].user`, 'none') === user;

	const nameContent = name && !messageFromSameUser && (
		<Typography
			variant="subtitle2"
			display="inline"
		>
			{name}
		</Typography>
	);
	const timeContent = time && !statusMessage && (
		<Typography
			color="textSecondary"
			variant="caption"
			display="inline"
			className={`${classes.time} ${classes.timeSpacer}`}
			align={isRight ? 'right' : 'left'}
		>
			<FormattedDate
				date={time}
				withTime
			/>
		</Typography>
	);

	return (
		<Slide
			direction="up"
			style={{ transitionDelay: 300 * index }}
			in={show}
			onEntered={onEntered}
		>
			<div ref={ref}>
				<Grid
					container
					className={!messageFromSameUser ? classes.messageContainer : ''}
					wrap="nowrap"
					direction="row"
				>
					<Grid
						container
						item
						dir={isRight ? 'rtl' : 'ltr'}
					>
						{avatar && (
							<Grid
								item
								className={`${classes.avatar} ${messageFromSameUser ? classes.hide : ''}`}
							>
								{avatar}
							</Grid>
						)}
						<Grid
							item
							xs
							className={statusMessage ? classes.statusMessageGrid : ''}
						>
							<Box
								display="block"
								dir="ltr"
								textAlign={isRight ? 'right' : 'left'}
							>
								{isRight ? [timeContent, nameContent] : [nameContent, timeContent]}
							</Box>

							<Grow
								in={true}
								style={{ transitionDelay: 225 }}
							>
								<Box
									display="inline-block"
									className={
										showBubble
											? `${classes.bubble} ${isRight ? classes.bubbleRight : ''} ${
													statusMessage ? classes.statusMessage : ''
											  }`
											: ''
									}
									mt={0.5}
								>
									{useTyping && !doneTyping ? (
										<Typing />
									) : (
										<Typography
											display="block"
											className={statusMessage ? classes.statusMessageTypography : ''}
											dir="ltr"
										>
											{children}
										</Typography>
									)}
									{time && statusMessage && (
										<Typography
											color="textSecondary"
											variant="caption"
											display="block"
											align="right"
											className={classes.time}
										>
											<FormattedDate
												date={time}
												withTime
											/>
										</Typography>
									)}
								</Box>
							</Grow>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</Slide>
	);
});

export default Message;
