import React from 'react';

import AddIcon from '@material-ui/icons/Add';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { makeStyles } from '@/styles';

import {
	Alert,
	Box,
	Card,
	CardContent,
	CardActionArea,
	CardMedia,
	Grid,
	Typography,
	UpgradeHelper,
} from '@/components/Layout';

import Loader from './Loader';

const useStyles = makeStyles(theme => ({
	flex: {
		display: 'flex!important',
		padding: theme.spacing(1),
	},
	icon: {
		fontSize: theme.typography.pxToRem(64),
		color: props =>
			props.disabled ? theme.palette.action.disabled : theme.palette.primary.main,
	},
	text: {
		color: props => (props.disabled ? theme.palette.action.disabled : null),
	},
	bottomCenter: {
		position: 'absolute',
		bottom: 8,
		left: '50%',
		transform: 'translateX(-50%)',
	},
	margin: {
		margin: theme.spacing(1),
		marginTop: theme.spacing(2),
	},
	img: {
		backgroundSize: `auto calc(100% - ${theme.spacing(2)}px)!important`,
		backgroundColor: props => {
			if (!props.color) return null;

			return props.color === 'secondary'
				? theme.palette.secondary.light
				: props.color === 'primary'
				? theme.palette.primary.light
				: props.color;
		},
		width: '100%',
		height: 100,
	},
	footerAlert: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		width: '100%',
	},
}));

export default function AddCard({
	onClick = () => {},
	CardActionAreaProps = {},
	TypographyProps = {},
	primary,
	secondary,
	label,
	text,
	icon,
	dataTestElement = null,
	dataTrackEvent = null,
	href,
	to,
	maxReached,
	loading,
	CardMediaClassName = '',
	subject = '',
	IconComponent,
	disabled,
	alert,
	...props
}) {
	const { t } = useTranslation();
	const classes = useStyles({ disabled });
	const { className: cardActionAreaClassName } = CardActionAreaProps;

	const CardActionAreaPropsExtended = {
		...CardActionAreaProps,
		className: `${classes.flex} ${
			cardActionAreaClassName ? cardActionAreaClassName : ''
		}`,
		...{
			...(href && {
				component: 'a',
				href,
			}),
			...(to && {
				component: Link,
				to,
			}),
			onClick,
		},
		...(loading && { onClick: () => {} }),
		disabled,
	};

	const makeTitle = you => {
		const part1 = you
			? t`You reached the maximum amount of`
			: t`I reached the maximum amount of`;
		const part2 = you ? t`in your package` : t`in my package`;

		const title = subject
			? `${part1} ${subject} ${part2}`
			: t`You reached the limit in your package`;

		return t(title);
	};

	const Icon = IconComponent ?? AddIcon;

	return (
		<Box
			width="100%"
			height="100%"
			minHeight={240}
			clone
		>
			<Card
				{...props}
				className={classes.relative}
			>
				{maxReached && (
					<Box
						width="100%"
						height="100%"
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						textAlign="center"
						clone
					>
						<Grid
							container
							spacing={0}
						>
							<CardMedia
								className={`${classes.img} ${CardMediaClassName}`}
								image="/assets/img/r/upgrade.svg"
							/>
							<CardContent>
								{loading ? (
									<Loader circular />
								) : (
									<Grid
										item
										xs={12}
									>
										<Typography
											align="center"
											variant="body2"
											{...TypographyProps}
										>
											{makeTitle(true)}
										</Typography>
										<UpgradeHelper
											useStandardText
											useButtons
											subject={makeTitle(false)}
											dataTrackEvent={dataTrackEvent}
										/>
									</Grid>
								)}
							</CardContent>
						</Grid>
					</Box>
				)}
				{!maxReached && (
					<Box
						width="100%"
						height="100%"
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						textAlign="center"
						clone
						data-test-element={dataTestElement}
						data-track-event={dataTrackEvent}
					>
						<CardActionArea {...CardActionAreaPropsExtended}>
							{loading ? (
								<Loader circular />
							) : icon ? (
								icon
							) : (
								<Icon className={classes.icon} />
							)}
							<>
								<Typography
									align="center"
									variant="h6"
									className={classes.text}
									{...TypographyProps}
								>
									{primary || label || text}
								</Typography>
								<Typography
									align="center"
									variant="subtitle1"
									color="textSecondary"
									className={classes.text}
								>
									{secondary}
								</Typography>
							</>
							{alert && (
								<Alert
									color="info"
									className={classes.footerAlert}
								>
									{alert}
								</Alert>
							)}
						</CardActionArea>
					</Box>
				)}
			</Card>
		</Box>
	);
}
