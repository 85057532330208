export const loginMessages = {
	default: {
		title: 'errormessage_unknown_error',
		message: 'errormessage_unknown_error_message',
		severity: 'error',
	},
	unknown: {
		title: 'errormessage_unknown_error',
		message: 'errormessage_unknown_error_message',
		severity: 'error',
	},
	forbidden: {
		title: 'errormessage_unknown_error',
		message: 'errormessage_unknown_error_message',
		severity: 'error',
	},
	invalid: {
		title: 'errormessage_credentials',
		message: 'errormessage_credentials_message',
		severity: 'error',
	},
	required: {
		title: 'errormessage_general_required_all',
		message: 'errormessage_general_required_all_message',
		severity: 'error',
	},
	required_project: {
		title: 'errormessage_error',
		message: 'signup_error_project',
		severity: 'error',
	},
	account_not_verified: {
		title: 'signup_error_verify',
		message: 'signup_error_verify_message',
		severity: 'error',
	},
	signup_not_completed: {
		title: 'signup_error_signup',
		message: 'signup_error_signup_message',
		severity: 'warning',
	},
	account_expired: {
		title: 'login_error_expired',
		message: 'login_error_expired_message',
		severity: 'error',
	},
	package_not_found: {
		title: 'login_error_package',
		message: 'login_error_package_message',
		severity: 'error',
	},
	impersonating_not_allowed: {
		title: 'errormessage_not_allowed',
		message: 'login_error_impersonate',
		severity: 'warning',
	},
	target_user_not_found: {
		title: 'errormessage_user_not_found',
		message: 'errormessage_user_not_found_message',
		severity: 'info',
	},
	blocked_temporary: {
		title: 'login_error_suspended',
		message: 'login_error_suspended_message',
		extra: 'login_error_suspended_extra',
		severity: 'error',
	},
	blocked_permanently: {
		title: 'login_error_locked',
		message: 'login_error_locked_message',
		extra: 'login_error_locked_extra',
		severity: 'error',
	},
	social_account_not_verified: {
		title: 'signup_error_verify',
		message: 'login_error_social',
		severity: 'warning',
	},
	social_user_not_found: {
		title: 'errormessage_user_not_found',
		message: 'login_error_social_exist',
		severity: 'error',
	},
	sso_error: {
		title: 'login_error_general',
		message: 'login_error_sso',
		severity: 'error',
	},
	csrf: {
		title: 'login_error_general',
		message: 'login_error_general_message',
		severity: 'error',
	},
	security_logout: {
		title: 'login_error_security',
		message: 'login_error_security_message',
		severity: 'warning',
	},
	business_email: {
		title: 'errormessage_email_not_allowed',
		message: 'errormessage_email_business',
		severity: 'warning',
	},
	verification_failed: {
		title: 'login_error_validation',
		message: 'login_error_validation_message',
		severity: 'error',
	},
	reset_password: {
		title: 'login_reset_password_success',
		message: 'login_reset_password_login',
		severity: 'success',
	},
	cancelled_account: {
		title: 'login_account_cancelled',
		message: 'login_account_cancelled_message',
		severity: 'success',
	},
	inactivity: {
		title: 'login_session_inactive',
		message: 'login_session_inactive_message',
		severity: 'info',
	},
	not_allowed: {
		title: 'forgot_password_not_allowed_title',
		message: 'forgot_password_not_allowed_message',
		severity: 'warning',
	},
};

export const branding = {
	travel: [
		{ url: '/assets/img/customers/logo_tui.png', alt: 'logo tui' },
		{
			url: '/assets/img/customers/logo_expedia.png',
			width: '100px',
			alt: 'logo expedia',
		},
		{ url: '/assets/img/customers/logo_klm.png', alt: 'logo klm' },
		{ url: '/assets/img/customers/logo_travix.png', alt: 'logo travix' },
	],
	retail: [
		{ url: '/assets/img/customers/logo_hema.svg', alt: 'logo hema' },
		{ url: '/assets/img/customers/logo_conrad.png', width: '100px', alt: 'logo conrad' },
		{ url: '/assets/img/customers/logo_bijenkorf.svg', alt: 'logo bijenkorf' },
		{ url: '/assets/img/customers/logo_topshop.png', alt: 'logo topshop' },
	],
	insurance: [
		{ url: '/assets/img/customers/logo_achmea.png', alt: 'logo kpn' },
		{ url: '/assets/img/customers/logo_allianz.svg', width: '100px', alt: 'logo ahold' },
		{ url: '/assets/img/customers/logo_robeco.png', alt: 'logo tomtom' },
	],
	default: [
		{ url: '/assets/img/customers/logo_kpn.svg', alt: 'logo kpn' },
		{ url: '/assets/img/customers/logo_ahold.png', width: '100px', alt: 'logo ahold' },
		{ url: '/assets/img/customers/logo_tomtom.png', alt: 'logo tomtom' },
		{ url: '/assets/img/customers/logo_paypal.png', alt: 'logo paypal' },
	],
};

export const brandingSingle = {
	default: {
		url: '/assets/img/customers/logo_hema_squared.svg',
		alt: 'logo hema',
		title: 'login-signup-promotext-hema_title',
		description: 'login-signup-promotext-hema_description',
	},
};
