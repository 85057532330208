import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ButtonPreviewIcon(props) {
	return (
		<SvgIcon
			viewBox={`0 0 40 40`}
			{...props}
		>
			<g>
				<rect
					x="16"
					y="39"
					width="4"
					height="1"
				/>
				<rect
					y="39"
					width="4"
					height="1"
				/>
				<rect
					x="0.002"
					y="23"
					width="1"
					height="4.83"
				/>
				<rect
					y="32"
					width="1"
					height="4"
				/>
				<rect
					x="8"
					y="39"
					width="4"
					height="1"
				/>
				<path
					d="M35.128,34.867H24.861c-0.428,0-0.854,0.427-0.854,0.855V40h11.977v-4.277
					C35.982,35.294,35.557,34.867,35.128,34.867z"
				/>
				<rect
					x="39"
					y="23"
					width="1"
					height="1.375"
				/>
				<rect
					x="39"
					y="28"
					width="1"
					height="4"
				/>
				<rect
					x="39"
					y="36"
					width="1"
					height="4"
				/>
			</g>
		</SvgIcon>
	);
}
