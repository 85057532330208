import React, { useState } from 'react';

import { useTranslation, Trans } from 'react-i18next';

import { PageHeader } from '@/components/App';
import { useAppContext } from '@/components/AppContext';
import {
	Card,
	CardHeader,
	CardContent,
	Grid,
	Typography,
	ContactDialog,
	Chip,
	Box,
	Link,
} from '@/components/Layout';
import { Explainer } from '@/components/Explainer';
import PlanCard from './PlanCard';
import UpgradeDialog from './UpgradeDialog';
import { useHasPermission } from '@/components/Permission';
import DeleteAccountDialog from './DeleteAccountDialog';

export default function PlansOverview() {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const [upgrade, setUpgrade] = useState(false);
	const [contact, setContact] = useState(false);
	const [deleteAccount, setDeleteAccount] = useState(false);
	const hasEditPermission = useHasPermission();

	return (
		<>
			<PageHeader title={t`plans_overview-page_title`} />
			<Box
				mt={2}
				mb={5}
			>
				<Card>
					<CardHeader
						title={
							<Box
								display="inline-flex"
								alignItems="center"
							>
								<Box
									display="inline-block"
									mr={1}
								>
									{t`plans_overview-title`}
								</Box>
								{app.package.trial && (
									<Chip
										label={t(`plans_overview-chip_label`, {
											remaining: app.package.period.totalRemaining - 1,
										})}
									/>
								)}
							</Box>
						}
						alignCenter
						action={
							<Explainer
								title={t('plans_overview-explainer-title')}
								content={
									<>
										<Typography
											gutterBottom
										>{t`plans_overview-explainer-content1`}</Typography>
										<Typography>{t`plans_overview-explainer-content2`}</Typography>
									</>
								}
							>
								{t`plans_overview-explainer-link`}
							</Explainer>
						}
					/>
					<CardContent>
						<Box
							clone
							maxWidth={960}
						>
							<Typography>{t`plans_overview-description`}</Typography>
						</Box>
					</CardContent>
				</Card>
			</Box>

			<Grid
				container
				spacing={6}
				justify="center"
			>
				<Grid
					item
					xs={12}
					sm={6}
					md={5}
					lg={4}
				>
					<PlanCard
						title={t`plan_card-standard-title`}
						plan="standard"
						ButtonProps={{
							label: t`plan_card-standard-button`,
							onClick: () => setUpgrade(true),
							variant: 'contained',
						}}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					md={5}
					lg={4}
				>
					<PlanCard
						title={t`plan_card-enterprise-title`}
						plan="enterprise"
						ButtonProps={{
							label: t`plan_card-enterprise-button`,
							variant: 'contained',
							onClick: () => setContact(true),
						}}
					/>
				</Grid>
			</Grid>

			{app.package.trial && hasEditPermission && (
				<Box mt={4}>
					<Typography>
						<Trans
							i18nKey="billing_overview-cancel_account"
							components={{
								cancelLink: <Link onClick={() => setDeleteAccount(true)} />,
							}}
						/>
					</Typography>
				</Box>
			)}

			<UpgradeDialog
				open={upgrade}
				onClose={() => setUpgrade(false)}
			/>
			<ContactDialog
				open={contact}
				onClose={() => setContact(false)}
				title={t`plans_overview-contact_dialog-title`}
				text={t`plans_overview-contact_dialog-text`}
				mailTo="sales@mopinion.com"
				subject={t`contact_enterprise_dialog-subject`}
			/>
			<DeleteAccountDialog
				open={deleteAccount}
				onClose={() => setDeleteAccount(false)}
			/>
		</>
	);
}
