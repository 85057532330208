import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import Typography from './Typography';
import Grid from './Grid';
import SimpleDialog from './SimpleDialog';
import { useAjaxForm } from '../Ajax';
import { useAppContext } from '../AppContext';
import { ValidatedField } from '../Validation';
import { useSessionStorage } from '../../hooks';

const useStyles = makeStyles(theme => ({
	fillWidth: {
		width: '100%',
	},
	thanksPage: {},
	thanksPageImg: {
		width: 80,
		marginBottom: theme.spacing(3),
	},
}));

function ContactDialog({
	open,
	onClose,
	subject = '',
	mailTo = 'support@mopinion.com',
	url = '/account/ajax/contact',
	title,
	cc,
	validate = false,
	dataTrackEvent = '',
	text,
	successMessage,
	contactOwner,
	onSuccess = () => {},
}) {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const [didSend, setDidSend] = useSessionStorage(subject, false);
	const [message, setMessage] = useState('');

	const { postForm, loading, clicked, response } = useAjaxForm({
		url: contactOwner ? `/account/ajax/contact-owner` : url,
		data: {
			mailTo,
			url: document.location.href,
			subject,
			message,
			...(cc && { cc }),
		},
		valid: () => !validate || message.length > 5,
		onSuccess: response => {
			if (response.success) {
				setDidSend(true);
				onSuccess();
			}
		},
		onFail: () => {
			enqueueSnackbar(t`contact_dialog-fail_snackbar`);
		},
	});

	return (
		<SimpleDialog
			open={Boolean(open)}
			onClose={onClose}
			title={
				!didSend
					? title ?? t`contact_dialog-default_title`
					: t`contact_dialog-default_thanks_title`
			}
			cancel={t`contact_dialog-cancel_button`}
			submit={!didSend ? t`contact_dialog-submit_button` : null}
			onSubmit={postForm}
			loading={loading}
			dataTrackEvent={dataTrackEvent}
		>
			{!didSend && (
				<Grid
					container
					direction="column"
					spacing={3}
				>
					{text && (
						<Grid
							item
							xs={12}
						>
							<Typography>{text}</Typography>
						</Grid>
					)}
					{!contactOwner && (
						<>
							<Grid
								item
								xs={12}
								className={classes.fillWidth}
							>
								<TextField
									fullWidth
									label={t`contact_dialog-from`}
									value={app.users.current.email}
									disabled
								/>
							</Grid>
							<Grid
								item
								xs={12}
								className={classes.fillWidth}
							>
								<TextField
									fullWidth
									label={t`contact_dialog-to`}
									value={mailTo}
									disabled
								/>
							</Grid>
							<Grid
								item
								xs={12}
								className={classes.fillWidth}
							>
								<TextField
									fullWidth
									disabled
									label={t`contact_dialog-subject`}
									value={subject}
								/>
							</Grid>
						</>
					)}
					<Grid
						item
						xs={12}
						className={classes.fillWidth}
					>
						<ValidatedField
							label={
								contactOwner
									? t`contact_dialog-message-owner-label`
									: t`contact_dialog-message-label`
							}
							value={message}
							onChange={e => setMessage(e.currentTarget.value)}
							fullWidth
							multiline
							rows={4}
							showErrors={validate && clicked}
							rules={[
								['required', true],
								['min', 5],
							]}
							messages={{
								required: t`contact_dialog-error-required`,
								min: t`contact_dialog-error-min`,
							}}
							placeholder={
								contactOwner
									? t`contact_dialog-message-owner-placeholder`
									: t`contact_dialog-message-placeholder`
							}
							autoFocus
						/>
					</Grid>
				</Grid>
			)}
			{didSend && (
				<div className={classes.thanksPage}>
					<Typography variant="body1">
						{successMessage ?? t(`contact_dialog-success_message`)}
					</Typography>
				</div>
			)}
		</SimpleDialog>
	);
}

export default ContactDialog;
