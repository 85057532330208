import React, { useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExportIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ZoomIcon from '@material-ui/icons/ZoomIn';
import ResizeIcon from '@material-ui/icons/AspectRatio';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import produce from 'immer';
import get from 'lodash.get';

import LoadChartSeries from './LoadChartSeries';
import { triggerEvent } from '@/utils';
import useChart from './useChart';
import {
	ActionIconButton,
	NestedMenuItem,
	SimpleDialog,
	Chip,
} from '@/components/Layout';
import CopyChartMenu from './CopyChartMenu';
import { useEffectSkipInitialRender, useIsInViewport } from '@/hooks';
import { useHasPermission } from '@/components/Permission';
import { ErrorBoundary } from '@/components/Utility';

const useStyles = makeStyles(theme => ({
	card: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	cardAction: {
		flexGrow: 1,
		minWidth: 0,
		height: '100%',
		position: 'static',
	},
	cardContent: {
		height: '100%',
		flexGrow: '1',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	cardHeader: {
		fontWeight: theme.typography.fontWeightMedium,
	},
	zoomDialogContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: '30vh',
	},
	//fixes dragimage in chrome, though it's not perfect because the image offset is wrong, keep track of issue
	fixDragImage: {
		transform: 'translateZ(0)',
	},
}));

export default function DashboardChartCard({
	editMode,
	doResize,
	isNew,
	chartId,
	forceCacheRefresh,
	onDoResize = () => {},
	onAdd = () => {},
	onRemove = () => {},
	...props
}) {
	const cardRef = useRef(null);
	const { t } = useTranslation();
	const classes = useStyles();
	const chartRef = useRef(null);
	const { enqueueSnackbar } = useSnackbar();
	const [expandDialogOpen, setExpandDialogOpen] = useState(false);
	const [mouse, setMouse] = useState({});
	const [chartMenuAnchor, setChartMenuAnchor] = useState(null);
	const { chartType, series, chart } = useChart(chartId);
	const hasEditPermission = useHasPermission();
	const { wasInViewport } = useIsInViewport(cardRef.current);

	useEffectSkipInitialRender(() => {
		setTimeout(() => {
			triggerEvent(window, 'resize');
		}, 300);
	}, [doResize]);

	function closeMenu() {
		setMouse({});
		setChartMenuAnchor(null);
	}

	function menuClick(fn) {
		closeMenu();
		fn();
	}

	function exportChart(type) {
		closeMenu();
		try {
			chartRef.current.chart.exportChartLocal({ type });
		} catch (e) {
			enqueueSnackbar(t`Export failed. Try a different filetype.`);
		}
	}

	return (
		<ErrorBoundary returnNullOnError>
			<Card
				onContextMenu={e => {
					e.preventDefault();
					const { clientX, clientY } = e;
					setMouse({ x: clientX - 2, y: clientY - 4 });
				}}
				className={`${classes.card} ${props.onDragStart ? classes.fixDragImage : ''}`}
				data-onboarding="dashboard-chart-card"
				ref={cardRef}
				{...props}
			>
				<>
					<CardHeader
						action={
							<>
								{isNew && (
									<Chip
										label={t`reporting-dashboard-chart_card-label_new`}
										color="primary"
										size="small"
									/>
								)}
								<ActionIconButton
									action={editMode ? 'delete' : 'more'}
									tooltip={
										editMode
											? t`reporting-dashboard-chart_card-tooltip_remove`
											: t`reporting-dashboard-chart_card-tooltip_options`
									}
									data-test-element="editChart"
									onClick={e => {
										if (editMode) {
											onRemove(chart);
										} else {
											setChartMenuAnchor(e.target);
										}
									}}
								/>
							</>
						}
						titleTypographyProps={{ variant: 'subtitle1', className: classes.cardHeader }}
						title={get(chart, 'title.text', '')}
						subheader={get(chart, 'subtitle.text', '')}
						subheaderTypographyProps={{ variant: 'caption' }}
					/>

					<CardActionArea
						className={classes.cardAction}
						disableRipple
					>
						<CardContent className={classes.cardContent}>
							<LoadChartSeries
								chartId={chartId}
								backendBauerSeries={series}
								chart={chart}
								chartRef={node => (chartRef.current = node)}
								chartType={chartType}
								forceCacheRefresh={forceCacheRefresh}
								useTooltip
								wait={!wasInViewport}
							/>
						</CardContent>
					</CardActionArea>
				</>
			</Card>

			{series.length > 0 && (
				<SimpleDialog
					open={expandDialogOpen}
					onClose={() => setExpandDialogOpen(false)}
					title={get(chart, 'title.text', '')}
					cancel={t`Close`}
					maxWidth="lg"
				>
					<div className={classes.zoomDialogContent}>
						<LoadChartSeries
							backendBauerSeries={series}
							chart={produce(chart, draft => {
								try {
									draft.chart.height = window.innerHeight * 0.6;
								} catch (e) {}
							})}
							chartType={chartType}
							fromDash
							useTooltip
						/>
					</div>
				</SimpleDialog>
			)}

			<Menu
				anchorEl={chartMenuAnchor}
				open={Boolean(chartMenuAnchor) || Boolean(mouse.x && mouse.y)}
				onClose={closeMenu}
				anchorReference={mouse.y && mouse.x ? 'anchorPosition' : null}
				anchorPosition={mouse.y && mouse.x ? { top: mouse.y, left: mouse.x } : undefined}
			>
				<MenuItem
					component={Link}
					to={`/reporting/chart/${chartId}`}
				>
					<ListItemIcon>
						<EditIcon />
					</ListItemIcon>
					<ListItemText>{hasEditPermission ? t`Edit chart` : t`View chart`}</ListItemText>
				</MenuItem>
				<NestedMenuItem
					leftIcon={<ExportIcon />}
					parentOpen={Boolean(chartMenuAnchor) || Boolean(mouse.x && mouse.y)}
					primary={t`Export`}
				>
					<MenuItem onClick={() => exportChart('image/png')}>
						<ListItemText>{t`As PNG`}</ListItemText>
					</MenuItem>

					<MenuItem onClick={() => exportChart('image/svg+xml')}>
						<ListItemText>{t`As SVG`}</ListItemText>
					</MenuItem>

					<MenuItem onClick={() => exportChart('application/pdf')}>
						<ListItemText>{t`As PDF`}</ListItemText>
					</MenuItem>
				</NestedMenuItem>

				<MenuItem
					onClick={() => menuClick(onDoResize)}
					disabled={!hasEditPermission}
				>
					<ListItemIcon>
						<ResizeIcon />
					</ListItemIcon>
					<ListItemText>{t`Resize`}</ListItemText>
				</MenuItem>

				<MenuItem onClick={() => menuClick(() => setExpandDialogOpen(true))}>
					<ListItemIcon>
						<ZoomIcon />
					</ListItemIcon>
					<ListItemText>{t('Zoom')}</ListItemText>
				</MenuItem>

				<CopyChartMenu
					open={Boolean(chartMenuAnchor) || Boolean(mouse.x && mouse.y)}
					onClose={closeMenu}
					chart={chart}
					chartId={chartId}
				/>

				<MenuItem
					onClick={() => onRemove(chart)}
					disabled={!hasEditPermission}
					data-track-event={'dashboard_chart_deleted'}
				>
					<ListItemIcon>
						<DeleteIcon />
					</ListItemIcon>
					<ListItemText>{t`reporting-dashboard-chart_card-options_delete_chart`}</ListItemText>
				</MenuItem>
			</Menu>
		</ErrorBoundary>
	);
}
