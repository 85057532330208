export const channels = ['snitch', 'export', 'payment'];

export const channelNames = {
	snitch: {
		primary: 'Receive in-app notifications',
		secondary: 'Get notifications about changes inside your organisation',
	},
	export: {
		primary: 'Export notifications',
		secondary: 'Recieve notifications about feedback exports',
	},
	payment: {
		primary: 'Payment notifications',
		secondary: 'Recieve notifications about events relating to billing',
	},
};

export const channelEvents = {
	snitch: [
		'add_survey',
		'remove_survey',
		'add_user',
		'add_note',
		'efm_action',
		'create_chart',
		'delete_chart',
		'access_request.added', // todo admins only
		'access_request.granted',
		'access_request.denied',
		'project_access_request.added', // todo admins only
		'project_access_request.granted',
		'project_access_request.denied',
	],
};

export const eventNames = {
	add_survey: 'When a new feedback form is created',
	remove_survey: 'When a feedback form is deleted',
	add_user: 'When a new user is added',
	add_note: 'When a note is added to a feedback item',
	efm_action: 'When an action is created or updated',
	create_chart: 'When a chart is created',
	delete_chart: 'When a chart is deleted',
	export_create: 'When an export is created',
	'access_request.added': 'When access is requested',
	'access_request.granted': 'When access is granted',
	'access_request.denied': 'When access is declined',
	'project_access_request.added': 'When project access is requested',
	'project_access_request.granted': 'When project access is granted',
	'project_access_request.denied': 'When project access is declined',
};

export const allowedNotificationEvents = [
	'add_survey',
	'remove_survey',
	'add_user',
	'add_note',
	'efm_action',
	'create_chart',
	'export_create',
	'login',
	'save_survey',
	'edit_deployment',
	'deployment_deleted',
	'deleted_feedback',
	'efm_email',
	'add_dataset',
	'update_dataset',
	'access_request.added',
	'access_request.granted',
	'access_request.denied',
	'project_access_request.added',
	'project_access_request.granted',
	'project_access_request.denied',
];

export const projectIrrelevantEvents = [
	'stripe.invoice.payment_succeeded',
	'stripe.invoice.payment_failed',
	'stripe.invoice.payment_succeeded',

	'project_access_request.added',
	'project_access_request.granted',
	'project_access_request.denied',
];
