import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { useLocation } from 'react-router-dom';

import LoggedOutContent from './LoggedOutContent';
import { Logo, MopinionLoader } from '../Layout';
import { useAppContext } from '../AppContext';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: '#ffffff',
		},
	},
	root: {
		display: 'flex',
		minHeight: '100vh',
	},
	inner: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: theme.spacing(6, 2, 4),
	},
	logo: {
		maxWidth: 180,
		minWidth: 180,
		marginBottom: props => (props.logoHasTitle ? theme.spacing(2) : theme.spacing(10)),
	},
	logoWrap: {
		width: 'auto',
	},
}));

export default function LoggedOutLayout(props) {
	const { app } = useAppContext();
	const location = useLocation();

	const logoHasTitle = location.pathname.includes('/sign-up');
	const classes = useStyles({ logoHasTitle });

	if (app.auth.loading) {
		return <MopinionLoader />;
	}

	return (
		<Fragment>
			<CssBaseline />
			<div className={classes.root}>
				<div className={classes.inner}>
					<Logo
						withText
						raspberry={false}
						classes={{ wrapper: classes.logoWrap }}
						className={classes.logo}
						onBg="light"
					/>
					<LoggedOutContent />
				</div>
			</div>
		</Fragment>
	);
}
