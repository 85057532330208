import { lazy } from 'react';
import SurveyList from './SurveyList';
import SurveyDesignList from './SurveyDesignList';
import AvailableSdks from './AvailableSdks';
import SurveyEditorBuildList from './SurveyEditorBuildList';
import { displayOptions, surveyTypes } from './constants';

const SurveyEditor = lazy(() => import('./SurveyEditor'));
export {
	SurveyList,
	SurveyEditor,
	SurveyDesignList,
	AvailableSdks,
	SurveyEditorBuildList,
	displayOptions,
	surveyTypes,
};
