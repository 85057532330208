import React from 'react';

import { makeStyles } from '@/styles';

import { Grid } from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	msiWrapper: {
		width: '100%',
	},
	msiBar: {
		height: theme.spacing(2),
		cursor: 'pointer',
		display: 'inline-flex',
	},
	negative: {
		background: theme.palette.sentiment.negative,
		borderRight: props => (props.neu === 0 && props.pos === 0 ? 0 : '2px solid #fff'),
		width: props =>
			props.neu === 0 && props.pos === 0 ? `${props.neg}%` : `calc(${props.neg}% - 2px)`,
	},
	neutral: {
		background: theme.palette.sentiment.neutral,
		borderRight: props => (props.pos !== 0 ? '2px solid #fff' : 0),
		width: props =>
			props.neu === 0
				? 0
				: props.pos === 0
				? `${props.neu}%`
				: `calc(${props.neu}% - 2px)`,
	},
	positive: {
		background: theme.palette.sentiment.positive,
		width: props => `${props.pos}%`,
	},
}));

function calcPercentage(msi) {
	const { neg, neu, pos } = msi;
	const total = neg + neu + pos;

	if (neg === 0) {
		return {
			neg: 0,
			neu: Math.floor((100 / total) * neu),
			pos: 100 - Math.floor((100 / total) * neu), //compensate the possible missing % due to math.floor
		};
	}

	if (neu === 0) {
		return {
			neg: Math.floor((100 / total) * neg),
			neu: 0,
			pos: 100 - Math.floor((100 / total) * neg), //compensate the possible missing % due to math.floor
		};
	}

	if (pos === 0) {
		return {
			neg: Math.floor((100 / total) * neg),
			neu: 100 - Math.floor((100 / total) * neg), //compensate the possible missing % due to math.floor
			pos: 0,
		};
	}

	return {
		neg: Math.floor((100 / total) * neg),
		neu: Math.floor((100 / total) * neu),
		pos: 100 - Math.floor((100 / total) * neg) - Math.floor((100 / total) * neu), //compensate the possible missing % due to math.floor
	};
}

function getMsiSentimentItems(items) {
	return items.reduce(
		(all, item) => {
			all.ids.push(String(item.uid));
			all.merge[String(item.uid)] = {
				feedback_id: item.uid,
				MSI: Math.round(item.MSI),
			};
			return all;
		},
		{ ids: [], merge: {} }
	);
}

export default function MsiDistBar({
	topic,
	setAnchorEl = () => {},
	setFocused = () => {},
	setSelectedFeedbackIds = () => {},
}) {
	const { MSI_dist, MSI_dist_items } = topic;
	const { neg, neu, pos } = MSI_dist;

	const msiPercentage = calcPercentage(MSI_dist);
	const classes = useStyles(msiPercentage);

	const msiNegatives = getMsiSentimentItems(MSI_dist_items.neg);
	const msiNeutrals = getMsiSentimentItems(MSI_dist_items.neu);
	const msiPositives = getMsiSentimentItems(MSI_dist_items.pos);

	return (
		<div className={classes.msiWrapper}>
			{neg > 0 && (
				<div
					className={`${classes.msiBar} ${classes.negative}`}
					onMouseOver={e => {
						setFocused(true);
						setAnchorEl(e.currentTarget);
						setSelectedFeedbackIds({
							sentiment: 'negative',
							ids: msiNegatives.ids,
							itemsWithMsi: msiNegatives.merge,
						});
					}}
					onMouseOut={() => setFocused(false)}
				/>
			)}
			{neu > 0 && (
				<div
					className={`${classes.msiBar} ${classes.neutral}`}
					onMouseOver={e => {
						setFocused(true);
						setAnchorEl(e.currentTarget);
						setSelectedFeedbackIds({
							sentiment: 'neutral',
							ids: msiNeutrals.ids,
							itemsWithMsi: msiNeutrals.merge,
						});
					}}
					onMouseOut={() => setFocused(false)}
				/>
			)}
			{pos > 0 && (
				<div
					className={`${classes.msiBar} ${classes.positive}`}
					onMouseOver={e => {
						setFocused(true);
						setAnchorEl(e.currentTarget);
						setSelectedFeedbackIds({
							sentiment: 'positive',
							ids: msiPositives.ids,
							itemsWithMsi: msiPositives.merge,
						});
					}}
					onMouseOut={() => setFocused(false)}
				/>
			)}
		</div>
	);
}
