import React from 'react';
import { useAjaxForm } from '@/components/Ajax';

export function useSaveHook({ hook, onSuccess, ...props }) {
	const { postForm, loading, clicked } = useAjaxForm({
		url: '/integrations/ajax/save-webhooks',
		data: { hooks: JSON.stringify([hook]) },
		onSuccess: () => {
			onSuccess();
		},
		...props,
	});
	return [postForm, loading, clicked];
}

export function useDeleteHook({ id, onSuccess, ...props }) {
	const { postForm, loading, clicked } = useAjaxForm({
		url: '/integrations/ajax/delete-webhook',
		data: { id },
		onSuccess,
		...props,
	});
	return [postForm, loading, clicked];
}
