import React, { Component } from 'react';

import { withAppContext } from '@/components/AppContext';
import { TextAnalyticsManagement } from '@/components/TextAnalytics';

class TextAnalyticsPage extends Component {
	state = { customer: {} };

	componentDidMount() {
		const { app } = this.props;

		//		app.layout.reduce({type:'TITLE', payload:'Text analytics'});
		//		app.setGlobal(produce(draft => {draft.layout.title = 'Text analytics'}));
	}
	render() {
		return <TextAnalyticsManagement />;
	}
}

export default withAppContext(TextAnalyticsPage);
