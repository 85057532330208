import React, { useMemo } from 'react';

import { getDirectInsightColors, getDirectInsightSubColors } from '@/styles';
import { useDataSourceContext } from '@/components/DataSources';
import { blockToCharts, customChartSettings } from './constants';
import useSortDatafields from './useSortDatafields';
import { findBlockTypeOrSystemVar } from './directInsightsUtils';

const defaultColors = getDirectInsightColors();

export default function useDirectInsightsGrid({ datasourceId }) {
	const { datasource } = useDataSourceContext();

	const source = datasource.api.getSource(datasourceId);

	const sortedDataSource = useSortDatafields({
		datasourceId: datasourceId,
		type: source.survey_type ?? 'builder', // builder/import,
		campaign: source.campaign ?? 'web',
	});

	return useMemo(() => {
		return buildLayout(
			sortedDataSource.reduce((charts, datafield, index) => {
				const blockType = findBlockTypeOrSystemVar(datafield);

				if (!blockType) return charts;

				const colorIndex =
					index - Math.floor(index / defaultColors.length) * defaultColors.length;

				findChartsForBlockType(blockType).forEach(type => {
					//Checkbox is special, make array of datafields based on group_id, to cram them in one chart
					if (Number(blockType) === 4) {
						const elements = datafield?.survey_block?.properties?.elements ?? {};
						const elementsExtra =
							datafield?.survey_block?.properties?.elements_extra?.[1] ?? {};

						const activeDatafields = [...Object.values(elements), elementsExtra]
							.filter(el => el.data_field)
							.map(el => el.data_field);

						charts.push({
							type: type,
							defaultChartColor: defaultColors[colorIndex],
							defaultSubColorSet: getDirectInsightSubColors(defaultColors[colorIndex]),
							datasourceId: datasourceId,
							datafieldIds: activeDatafields,
							numberOfChartsForBlock: blockToCharts[blockType].length,
							...(customChartSettings[blockType] && {
								...customChartSettings[blockType],
							}),
						});

						return charts;
					}

					charts.push({
						type: type,
						defaultChartColor: defaultColors[colorIndex],
						datasourceId: datasourceId,
						datafieldIds: [datafield.id],
						numberOfChartsForBlock: blockToCharts[blockType].length,
						...(customChartSettings[blockType] && {
							...customChartSettings[blockType],
						}),
					});
				});

				return charts;
			}, [])
		);
	}, [datasourceId, sortedDataSource, datasource.surveyfields]);
}

function findChartsForBlockType(blockType) {
	return blockToCharts[blockType] ?? [];
}

function buildLayout(dashboardLayout = []) {
	const chartsWidth = [];

	const layout = dashboardLayout.map((chart, index) => {
		const lastChart = dashboardLayout[index - 1];
		const nextChart = dashboardLayout[index + 1];

		const chartPositionInRow = chartsWidth.reduce((a, b) => a + b, 0) % 12;

		const chartSize = chart.type.includes('score')
			? 4
			: (lastChart &&
					lastChart.type.includes('score') &&
					lastChart.datafieldIds[0] === chart.datafieldIds[0]) ||
			  (nextChart &&
					nextChart.type.includes('score') &&
					nextChart.datafieldIds[0] === chart.datafieldIds[0])
			? 8
			: chartPositionInRow === 0 &&
			  chart.numberOfChartsForBlock === 1 &&
			  nextChart?.numberOfChartsForBlock > 1
			? 12
			: 6;

		const currentX =
			chartSize <= 12 - chartPositionInRow
				? chartsWidth.reduce((a, b) => a + b, 0) % 12
				: 0;

		const currentY = Math.floor(chartsWidth.reduce((a, b) => a + b, 0) / 12);
		chartsWidth.push(chartSize);

		return {
			i: `${chart.datafieldIds[0]}_${index}`,
			chartSettings: chart,
			x: currentX,
			y: currentY,
			w: chartSize,
			h: 4,
		};
	});

	return { lg: layout };
}
