import { useDataSourceContext } from '@/components/DataSources';
import { tryParse } from '@/utils';

function useDataFieldOrder(surveyId) {
	const { datasource } = useDataSourceContext();

	if (!surveyId) {
		return [];
	}
	const layout = tryParse(datasource.forms.byKey[surveyId]?.map)?.layout ?? [];
	const order = layout.flatMap(blockId => {
		const block = datasource.api.getBlock(blockId);
		let allFields = [];

		if (Number(block?.data_field)) {
			allFields = allFields.concat(Number(block.data_field));
		}

		if (block.properties?.elements) {
			allFields = allFields.concat(
				Object.values(block.properties.elements).flatMap(element => {
					if (element.data_field?.value && element.data_field?.weight) {
						// Matrix elements have two data_field variables per element
						return [element.data_field.value, element.data_field.weight];
					}
					if (element.data_field) {
						return element.data_field; // checkboxes and radiobuttons
					}
					return [];
				})
			);
		}

		if (block.properties?.elements_extra) {
			allFields = allFields.concat(
				Object.values(block.properties.elements_extra).flatMap(element => {
					if (element.data_field) {
						return element.data_field; // checkboxes and radiobuttons extra input
					}
					return [];
				})
			);
		}
		return allFields;
	});
	return order;
}

export default useDataFieldOrder;
