import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import { useTranslation } from 'react-i18next';

import { getProgressColor } from './exportUtils';
import { statusTypes } from './constants';

const useStyles = makeStyles(theme => ({
	chip: {
		margin: theme.spacing(0, 2, 0.5, 0),
	},
	scheduled: {
		backgroundColor: props => getProgressColor(1, theme),
		color: props => theme.palette.getContrastText(getProgressColor(1, theme)),
	},
	retry: {
		backgroundColor: props => getProgressColor(0, theme),
		color: props => theme.palette.getContrastText(getProgressColor(0, theme)),
	},
	failed: {
		backgroundColor: props => getProgressColor(5, theme),
		color: props => theme.palette.getContrastText(getProgressColor(5, theme)),
	},
	revoked: {
		backgroundColor: props => getProgressColor(0, theme),
		color: props => theme.palette.getContrastText(getProgressColor(0, theme)),
	},
	pending: {
		backgroundColor: props => getProgressColor(2, theme),
		color: props => theme.palette.getContrastText(getProgressColor(2, theme)),
	},
	started: {
		backgroundColor: props => getProgressColor(3, theme),
		color: props => theme.palette.getContrastText(getProgressColor(3, theme)),
	},
	finished: {
		backgroundColor: props => getProgressColor(4, theme),
		color: props => theme.palette.getContrastText(getProgressColor(4, theme)),
	},
}));

export function ExportStatusChip({ type, size = 'medium' }) {
	const classes = useStyles();
	const { t } = useTranslation();

	const chipStatus = statusTypes.find(status => status.type === type);

	return (
		<Chip
			size={size}
			label={`${t(chipStatus.label)} ${t(chipStatus.step)}`}
			className={`${classes[chipStatus.class]} ${classes.chip}`}
		/>
	);
}
