import { useState, useEffect, useContext, useRef } from 'react';
import { AppContext } from '../AppContext';
import { axios, axiosInstance, CancelToken } from '@/lib/axios';
import objectToFormData from 'object-to-formdata';
import { useSessionStorage } from '../../hooks';

function parseStorageData(str) {
	try {
		const parsed = JSON.parse(str);
		return parsed && typeof parsed === 'object' ? parsed : {};
	} catch (e) {
		return {};
	}
}

function useOfficeDrones(url, params = {}, settings = {}, method = 'get') {
	const {
		app: { users, projects, organisations, date, tokens },
	} = useContext(AppContext);
	const [storageData, setStorageData] = useSessionStorage(
		projects.current.id + document.location.pathname + url
	);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [data, setData] = useState(parseStorageData(storageData));
	const cancel = useRef(() => {});
	function loadData() {
		setLoading(true);
		axiosInstance
			.post(
				'/api/officedrones',
				objectToFormData(
					Object.assign(
						{},
						{ data: params },
						{ path: url, token: tokens.csrf },
						{ method: method }
					)
				),
				{ cancelToken: new CancelToken(cancelToken => (cancel.current = cancelToken)) }
			)
			.then(r => {
				setLoading(false);

				const responseData = r.data?.response ?? r.data;
				const data = settings.responseParser
					? settings.responseParser(responseData)
					: responseData;

				setData(data);
				setStorageData(data);
			})
			.catch(e => {
				if (!axios.isCancel(e)) {
					setLoading(false);
					setError(e);
				}
			});
		// .finally(r => {

		// })
	}

	useEffect(() => {
		if (!settings.wait) {
			loadData();
		}
		return () => cancel.current();
	}, [
		url,
		JSON.stringify(params),
		users.current.id,
		projects.current.id,
		organisations.current.org_id,
		date.fromDate,
		date.toDate,
		settings.wait,
	]);

	return [data, loading, error, loadData, setData];
}

export default useOfficeDrones;
