import React, { useMemo } from 'react';

import { useDataSourceContext } from '@/components/DataSources';
import { tryParse } from '@/utils';
import { findBlockTypeOrSystemVar } from './directInsightsUtils';

const metaDataWeb = [35, 45]; //url, device
const metaDataMobile = [36]; //OS

export default function useSortDataSource({ datasourceId, type, campaign }) {
	const { datasource } = useDataSourceContext();

	const sortedData = useMemo(() => {
		const datasourceArray = datasource.surveyfields?.[datasourceId]?.asArray ?? [];

		if (!datasource || !datasourceArray.length) return [];

		//if type === builder: array contains survey_block id's ( array of active block in form in correct order )
		//else => array contains datafield id's, since imports have no particular form order
		const formLayout =
			type === 'builder' && datasource?.forms?.byKey?.[datasourceId]
				? tryParse(datasource.forms.byKey[datasourceId].map)?.layout
				: datasource.surveyfields[datasourceId]
				? datasourceArray.map(field => field.id)
				: [];

		//Always pin to top
		const pinnedDatafields = datasourceArray.filter(
			datafield => datafield.system_var === 'Survey'
		);

		const questionDatafields = formLayout.reduce((surveyBlocks, surveyBlockId) => {
			const datafield = datasourceArray.find(source => {
				//support for checkboxes only ( not likerts/matrixes )
				if (source.survey_block?.type == '4') {
					return source.survey_block.id === surveyBlockId;
				}
				return type === 'builder'
					? source.block_id === surveyBlockId
					: source.id === surveyBlockId;
			});
			const blockType = findBlockTypeOrSystemVar(datafield);

			if (blockType && datafield.active) {
				surveyBlocks.push(datafield);
			}

			return surveyBlocks;
		}, []);

		const metaDatafields = datasourceArray.filter(datafield => {
			if (campaign === 'sdk') {
				return metaDataMobile.includes(datafield?.var_type);
			}
			return metaDataWeb.includes(datafield?.var_type);
		});

		return [...pinnedDatafields, ...questionDatafields, ...metaDatafields];
	}, [datasource, datasourceId, type, campaign]);

	return sortedData;
}
