import React from 'react';

import { Typography, Grid } from '../Layout';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { brandingSingle } from './constants';
import { constants } from '../Layout';

const useStyles = makeStyles(theme => ({
	img: {
		maxHeight: 120,
		marginRight: theme.spacing(2),
	},
	fixed: {
		position: 'fixed',
		width: '100%',
		bottom: 0,
		left: 0,
		marginTop: 85,
	},
	brandingWrap: {
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(5),
	},
	maxWidth: {
		maxWidth: props => props.maxWidth,
	},
	branding: {
		marginTop: theme.spacing(1),
		'& img': {
			height: 60,
			opacity: 0.5,
			'-webkit-filter': 'grayscale(100%)',
			filter: 'grayscale(100%)',
			transition: '.3s ease-in-out;',
		},
		'& img:hover': {
			filter: 'grayscale(0)',
			opacity: 100,
		},
	},
}));

export default function Branding({ type = 'default', maxWidth = '100%', asFooter }) {
	const classes = useStyles({ maxWidth });
	const { t } = useTranslation();
	const branding = brandingSingle[type];

	const forceRelative = window.innerWidth < constants.loginBrandingFooter;

	return (
		<Grid
			container
			direction="row"
			justify="center"
			justifyContent="center"
			alignItems="flex-start"
			className={asFooter && !forceRelative ? classes.fixed : ''}
		>
			<Grid
				item
				xs={6}
				className={maxWidth ? classes.maxWidth : ''}
			>
				<Grid
					container
					direction="row"
					justify="flex-start"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={2}
					className={classes.brandingWrap}
				>
					<Grid
						item
						className={classes.branding}
					>
						{
							<img
								height={30}
								src={branding.url}
								alt={branding.alt}
							/>
						}
					</Grid>
					<Grid
						item
						xs
					>
						<Typography
							variant="h6"
							gutterBottom
						>
							{t(branding.title)}
						</Typography>
						<Typography variant="body1">"{t(branding.description)}"</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
