import React, { Fragment, useState } from 'react';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { makeStyles } from '@/styles';
import { renderToString } from 'react-dom/server';
import { useTranslation, Trans } from 'react-i18next';

import { useTheme, ThemeProvider } from '@/styles';
import {
	Loader,
	EmptyState,
	Card,
	CardHeader,
	CardContent,
	Typography,
	Box,
	Tooltip,
} from '@/components/Layout';
import { RenderChart } from '@/components/Charts';
import { useTextAnalyticsContext } from './TextAnalyticsContext';
import TextAnalyticsFeedbackQuickViewDrawer from './TextAnalyticsFeedbackQuickViewDrawer';
import { msiColor } from './textAnalyticsUtils';
//n1 = neg

const useStyles = makeStyles(theme => ({
	tooltip: {
		position: 'absolute',
		top: theme.spacing(2),
		right: theme.spacing(2),
		cursor: 'pointer',
	},
	relative: {
		position: 'relative',
	},
}));

export default function TextAnalyticsSentimentDistributionCard({ onClick = () => {} }) {
	const classes = useStyles();
	const [pointData, setPointData] = useState({});
	const {
		textAnalytics: { distData, distLoading },
	} = useTextAnalyticsContext();
	//const [ data, loading ] = useOfficeDrones('/dash/groups', {project_id, redis:1,cutoff:'33,66', groups});
	const { t } = useTranslation();
	const { palette, typography } = useTheme();

	function makeChartOptions({ scores = {} }) {
		const data = [
			{
				name: t`reporting-text_analytics-card-chart_option_positive`,
				y: scores.n3,
				color: msiColor(100),
				perc: scores.perc3,
				id: 'pos',
				MSI: '>66',
			},
			{
				name: t`reporting-text_analytics-card-chart_option_neutral`,
				y: scores.n2,
				color: msiColor(50),
				perc: scores.perc2,
				id: 'neut',
				MSI: '>33<66',
			},
			{
				name: t`reporting-text_analytics-card-chart_option_negative`,
				y: scores.n1,
				color: msiColor(1),
				perc: scores.perc1,
				id: 'neg',
				MSI: '<33',
			},
		];

		return {
			chart: {
				type: 'pie',
				events: {
					render(chartContext) {
						try {
							chartContext.target.resize.destroy();
						} catch (e) {}
						try {
							if (scores.total) {
								//Create label
								chartContext.target.resize =
									this.renderer &&
									this.renderer
										.label(
											t('reporting-text_analytics-card-items_total', {
												score: scores.total,
											})
										)
										.css({
											color: palette.text.primary,
											fontFamily: typography.subtitle2.fontFamily,
											fontWeight: typography.subtitle2.fontWeight,
											fontSize: typography.subtitle2.fontSize,
											textAlign: 'center',
										})
										.add();

								//Center label
								const bb = chartContext.target.resize.getBBox();
								const x = this.plotLeft + this.plotWidth * 0.5 - bb.width * 0.5;
								const y = this.plotTop + this.plotHeight * 0.5 - bb.height * 0.5;
								chartContext.target.resize.attr({ x: x, y: y });
							}
						} catch (e) {}
					},
				},
			},
			tooltip: {
				formatter: function () {
					return renderToString(
						<ThemeProvider>
							<div>
								<Typography
									display="block"
									variant="subtitle2"
								>
									{this.point.name}
								</Typography>
								<Typography
									display="block"
									variant="caption"
								>
									{t('reporting-text_analytics-card-items', { point: this.point.y })}
								</Typography>
								<Typography
									display="block"
									variant="caption"
								>
									{t('reporting-text_analytics-card-percentage', {
										point: Math.round(this.point.perc),
									})}
								</Typography>
							</div>
						</ThemeProvider>
					);
				},
			},
			plotOptions: {
				pie: {
					innerSize: '50%',
				},
				series: {
					cursor: 'pointer',
					point: {
						events: {
							click: function (event) {
								setPointData(this);
							},
						},
					},
				},
			},
			series: [
				{
					name: 'Sentiment',
					dataLabels: {
						distance: 30,
						formatter: function () {
							return this.point.y
								? t('reporting-text_analytics-card-sentiment', {
										name: this.point.name,
										point: this.point.y,
								  })
								: '';
						},
					},
					data: data,
				},
			],
			annotations: [
				{
					labels: data.map((value, i) => {
						return !value.perc || Math.round(value.perc) === 0
							? ''
							: {
									point: value.id,
									text: Math.round(value.perc) + '%',
									style: {
										fontSize: '12px',
										fontWeight: 600,
									},
							  };
					}),
					labelOptions: {
						backgroundColor: 'transparent',
						border: 0,
						borderColor: 'transparent',
						color: '#fff',
						fontWeight: 'bold',
						fontSize: '14px',
						padding: 0,
					},
				},
			],
		};
	}

	return (
		<Fragment>
			<Box
				maxWidth="100%"
				clone
			>
				<Card className={classes.relative}>
					{distLoading && <Loader />}
					<div className={classes.tooltip}>
						<Tooltip title={t`reporting-text_analytics-card-tooltip_sentiment`}>
							<HelpOutlineIcon
								fontSize="small"
								color="action"
							/>
						</Tooltip>
					</div>
					<CardHeader
						titleTypographyProps={{ variant: 'h6' }}
						title={t`reporting-text_analytics-card-sentiment_distribution`}
					/>

					<CardContent>
						{[distData.n1, distData.n2, distData.n3].every(n => n !== 0) ? (
							<RenderChart options={makeChartOptions(distData)} />
						) : (
							<EmptyState secondary={t`reporting-text_analytics-no_data`} />
						)}
					</CardContent>
				</Card>
			</Box>
			<TextAnalyticsFeedbackQuickViewDrawer
				requestPath="/tasks"
				MSI={pointData.MSI}
				open={Object.keys(pointData).length > 0}
				onClose={() => setPointData({})}
			/>
		</Fragment>
	);
}
