import React, { useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import { ActionButton } from '../Layout';
import { rangeArray, copyToClipboard } from '../../utils';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useTimeoutState } from '../../hooks';

const useStyles = makeStyles(theme => ({
	wrapper: {
		overflow: 'hidden',
		position: 'relative',
		border: 'none',
		zIndex: 0,
	},
	textarea: props => ({
		display: 'block',
		position: 'relative',
		zIndex: 1,
		width: 'calc(100% - 40px)',
		//minHeight:150,
		// maxHeight:'100%',
		marginLeft: 40,
		padding: theme.spacing(0, 1),
		fontSize: 14,
		lineHeight: '24px',
		fontFamily: '"Ubuntu Mono", monospace',
		outline: 'none',
		border: 'none',
		resize: 'none',
		// whiteSpace:'pre',
		// wordWrap:'normal',
		// whiteSpace:'normal',
		background: 'transparent',
		// overflowX:'auto!important',
	}),
	numberWrapper: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: 40,
	},
	number: {
		fontFamily: '"Ubuntu Mono", monospace',
		fontSize: 14,
		lineHeight: '24px',
		background: theme.palette.grey[100],
		paddingLeft: theme.spacing(0.5),
	},
	buttonWrap: {
		position: 'absolute',
		top: theme.spacing(0),
		right: theme.spacing(0),
		zIndex: 10,
		display: 'flex',
		flexDirection: 'column',
		'& > *:not(:first-child)': {
			marginTop: theme.spacing(0.5),
		},
	},
	iconMargin: {
		marginRight: theme.spacing(0.5),
	},
}));

export default function CodeTextArea({
	value,
	onChange = () => {},
	onKeyDown = () => {},
	onCopy,
	copyMessage,
	copy,
	extraButtons,
	className = '',
	dataTrackEvent,
	...props
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	const textAreaRef = useRef(null);
	const { enqueueSnackbar } = useSnackbar();
	const [offset, setOffset] = useState(0);

	const [copied, setCopied] = useTimeoutState(false, 10000);
	const lines = rangeArray(1, 1000);

	return (
		<div className={classes.wrapper}>
			<div
				className={classes.numberWrapper}
				style={{ transform: `translateY(-${offset}px)` }}
			>
				{lines.map(lineNumber => (
					<div
						key={lineNumber}
						className={classes.number}
					>
						{lineNumber}
					</div>
				))}
			</div>
			{(copy || typeof onCopy === 'function' || copyMessage) && (
				<div className={classes.buttonWrap}>
					<ActionButton
						action={copied ? 'check' : 'copy'}
						color="primary"
						variant="contained"
						dataTrackEvent={dataTrackEvent}
						onClick={e => {
							copyToClipboard(value).then(() => {
								setCopied(true);
								if (copyMessage) enqueueSnackbar(copyMessage);
								if (typeof onCopy === 'function') onCopy();
							});
						}}
					>
						{copied ? t`Copied` : t`Copy`}
					</ActionButton>

					{extraButtons}
				</div>
			)}
			<TextareaAutosize
				ref={textAreaRef}
				className={`${classes.textarea} ${className}`}
				onChange={onChange}
				onScroll={e => setOffset(e.target.scrollTop)}
				onKeyDown={e => {
					const { value, selectionStart, selectionEnd } = e.target;
					if (e.key === 'Tab' && !props.readonly) {
						e.preventDefault();
						e.target.value =
							value.slice(0, selectionStart) + '  ' + value.slice(selectionEnd);
						e.target.setSelectionRange(selectionStart + 2, selectionStart + 2);
					}
					onKeyDown(e);
				}}
				value={value}
				rowsMin={2}
				{...props}
			/>
		</div>
	);
}
