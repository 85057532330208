import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Backdrop from '@material-ui/core/Backdrop';

import useOnboardingContext from './useOnboardingContext';

import { Card } from '@/components/Layout';

import TrialFlowWelcome from './TrialFlowWelcome';
import TrialFlowSurvey from './TrialFlowSurvey';
import TrialFlowDeployment from './TrialFlowDeployment';
import TrialFlowFeedbackInbox from './TrialFlowFeedbackInbox';

const useStyles = makeStyles(theme => ({
	content: {
		padding: theme.spacing(2),
	},
	popover: {
		padding: theme.spacing(2),
		height: '100%',
	},
	fullWidth: {
		width: '100%',
	},
	trialPopper: {
		maxWidth: 620,
		marginTop: 50,
	},
	card: {
		border: 'none',
	},
	cardActions: {
		padding: theme.spacing(2),
	},
	marginLeft: {
		marginLeft: 'auto',
	},
	marginRight: {
		marginRight: 'auto',
	},
	italic: {
		fontStyle: 'italic',
	},
	backdrop: {
		zIndex: 1300,
	},
	image: {
		'& img': {
			marginTop: theme.spacing(2),
		},
	},
}));

function Delay({ time, open, children }) {
	const [show, setShow] = useState(false);
	useEffect(() => {
		if (open) {
			const timeout = setTimeout(() => {
				setShow(true);
			}, time);
			return () => {
				clearTimeout(timeout);
			};
		} else {
			setShow(false);
		}
	}, [time, open]);

	return show && open ? children : null;
}

export default function InitialUserOnboardingTrial({ delay, ...props }) {
	const classes = useStyles();

	const onboarding = useOnboardingContext();
	const { trialMenuOpen, trialStep } = onboarding;

	return (
		<Delay
			time={trialMenuOpen ? (trialStep === 5 ? 5000 : 1000) : 0}
			open={trialMenuOpen && trialStep > -1}
		>
			<Backdrop
				open={trialMenuOpen && trialStep > -1}
				className={classes.backdrop}
			>
				<Popover
					open={trialMenuOpen}
					classes={{ paper: classes.trialPopper }}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					marginThreshold={30}
					PaperProps={{
						'data-onboarding': 'trial-checklist',
					}}
				>
					<Card
						className={classes.card}
						variant="outlined"
						data-test-element="initial-user-onboarding-dialog"
					>
						{trialStep === 1 && <TrialFlowWelcome />}

						{trialStep === 2 && <TrialFlowSurvey />}

						{trialStep === 3 && <TrialFlowDeployment />}
						{[4, 5].includes(trialStep) && <TrialFlowFeedbackInbox />}
					</Card>
				</Popover>
			</Backdrop>
		</Delay>
	);
}
