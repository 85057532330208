import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import Fade from '@material-ui/core/Fade';

import { Box, Card, CardContent, Grid, Typography } from '@/components/Layout';

import { ContextBubble } from './';

import { sortFeedback } from './feedbackUtils';

const styles = theme => ({
	grow: {
		display: 'flex',
		flexGrow: 1,
		height: '100%',
	},
	dense: {
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(1),
		paddingBottom: 0,
		paddingLeft: 0,
		'&:last-child': {
			paddingBottom: 0,
		},
	},
	text: {
		//marginLeft:theme.spacing(1),
		//textAlign:'center',
		fontSize: theme.typography.pxToRem(12),
		fontWeight: 600,
	},
	screenshot: {
		maxWidth: '100%',
		maxHeight: '60vh',
	},
	screenshotHTMLWrapper: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		background: 'rgba(0,0,0,.3)',
		color: '#fff',
		zIndex: 999,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		overflowY: 'auto',
	},
	screenshotHTML: {
		maxWidth: '80%',
		whiteSpace: 'pre-wrap',
		fontWeight: 'bold',
	},
	isRelative: {
		position: 'relative',
	},
});

const gridSize = (size, length) => {
	const sizeMap = {
		xs: {
			1: 12,
			2: 6,
			3: 6,
			4: 6,
			5: 6,
			6: 6,
		},
		lg: {
			2: 6,
			3: 4,
			4: 6,
			5: 4,
			6: 4,
		},
	};

	return sizeMap[size][length] ? sizeMap[size][length] : true;
};

function FeedbackHighlights({
	image_vars = {},
	highlights = [],
	dataFieldOrder = [],
	classes,
	isElevated,
	isPage = false,
	survey_id,
	hasScreenshot,
}) {
	const { t } = useTranslation();
	const [hoverImage, setHoverImage] = useState(false);
	const sortedHighlights = sortFeedback(dataFieldOrder, highlights);

	const listHighLights = () => {
		const cardProps = isElevated ? {} : { elevation: 0 };

		return sortedHighlights.map(highlightBlock => {
			let bubbleProps = {
				...highlightBlock,
				isXl: true,
				gcr: highlightBlock.var_type == 30,
				thumbs: highlightBlock.var_type == 44,
				ces: highlightBlock.var_type == 24 || highlightBlock.var_type == 16,
				survey_id: survey_id,
			};

			return (
				<Grid
					item
					xs={gridSize('xs', sortedHighlights.length)}
					lg={gridSize('lg', sortedHighlights.length)}
					key={highlightBlock.id}
				>
					<div className={classes.grow}>
						<Card
							classes={{ root: classes.grow }}
							style={isPage ? { alignItems: 'center' } : { alignItems: 'flex-start' }}
							{...cardProps}
						>
							<CardContent>
								<Grid
									container
									spacing={2}
									direction="row"
									wrap="nowrap"
									alignItems="flex-start"
								>
									<Grid item>
										<ContextBubble {...bubbleProps}>
											{!bubbleProps.gcr && !bubbleProps.thumbs && highlightBlock.score}
										</ContextBubble>
									</Grid>
									<Grid
										item
										xs
										container
										direction="column"
									>
										<Typography variant="subtitle2">{highlightBlock.label}</Typography>
										<Typography
											variant="caption"
											color="textSecondary"
										>
											{highlightBlock.question_type}
										</Typography>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</div>
				</Grid>
			);
		});
	};

	const showImages = () => {
		if (!image_vars['40'] || !image_vars['40'].value) return null;

		return (
			<Grid
				item
				container
				xs
				className={classes.isRelative}
				onMouseEnter={() => setHoverImage(true)}
				onMouseLeave={() => setHoverImage(false)}
				justify="center"
			>
				<Grid item>
					<img
						src={image_vars['40'].value}
						className={classes.screenshot}
					/>
				</Grid>
				{image_vars['42'] ? (
					<Fade in={hoverImage}>
						<div className={classes.screenshotHTMLWrapper}>
							<pre className={classes.screenshotHTML}>{image_vars['42'].value}</pre>
						</div>
					</Fade>
				) : null}
			</Grid>
		);
	};

	if (sortedHighlights.length === 0 && !hasScreenshot) return null;

	return (
		<>
			{showImages()}
			{!isPage && (
				<Box p={2}>
					<Typography variant="subtitle2">{t`Scores`}</Typography>
				</Box>
			)}
			<Grid
				container
				direction="row"
				justify="space-between"
				alignItems="stretch"
				spacing={isPage ? 2 : 0}
				wrap="wrap"
			>
				{listHighLights()}
			</Grid>
		</>
	);
}

export default withStyles(styles, { withTheme: true })(FeedbackHighlights);
