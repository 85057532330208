import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { useTagsEditorContext } from './TagsEditorContext';
import TagsEditorSidebar from './TagsEditorSidebar';
import TagsEditorItem from './TagsEditorItem';
import { useAppContext } from '../AppContext';
import { LayoutWithSidebar, constants, EmptyState, Loader } from '../Layout';

import { useDataSourceContext } from '../DataSources';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { ContainerQuery } from 'react-container-query';

const useStyles = makeStyles(theme => ({
	editorAreaWrap: {
		minWidth: '80%',
		maxWidth: '80%',
		minHeight: '85%',
		height: '100%',
		overflow: 'visible',
		display: 'flex',
		flexDirection: 'column',
	},
	editorAreaWrapSm: {
		maxWidth: '90%',
	},
	editorAreaWrapXs: {
		maxWidth: '95%',
	},
	editorArea: {
		position: 'relative',
		flexGrow: 1,
		borderRadius: theme.shape.borderRadius,
		border: `3px dashed ${theme.palette.grey[300]}`,
		padding: theme.spacing(1),
	},
	centerContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

export default function TagsEditor({
	AppBarActionsContainer,
	forceDeploymentId,
	forceEditorType,
	type,
	...props
}) {
	//return null;
	const { app } = useAppContext();
	const {
		state,
		state: { config },
		api,
		api: { dispatch, dispatchSimple },
	} = useTagsEditorContext();
	const { t } = useTranslation();
	const classes = useStyles();
	const { datasource } = useDataSourceContext();
	const { enqueueSnackbar } = useSnackbar();

	function onDragEnd(result) {
		const { source, destination, draggableId } = result;

		if (!destination) {
			return;
		}

		if (destination.droppableId === 'editor-area') {
			if (source.droppableId === destination.droppableId) {
				dispatch({
					type: 'move_rule',
					payload: {
						destinationIndex: destination.index,
						sourceIndex: source.index,
					},
				});

				if (
					typeof destination.index === 'number' &&
					source.index === state.selectedRuleIndex
				) {
					dispatchSimple({
						type: 'set_selected',
						payload: {
							index: destination.index,
							id: state.selectedRule,
						},
					});
				}
			} else if (source.droppableId !== destination.droppableId) {
				const survey = datasource.api.getSourceByProp('survey_key', draggableId);

				dispatch({
					type: 'add_rule',
					payload: {
						rule: {
							survey_key: survey.survey_key,
							survey_id: survey.id,
							user_id: app.users.current.id,
						},
						destinationIndex: destination.index,
					},
				});
			}
		}
	}

	function selectRule(id, index) {
		dispatchSimple({
			type: 'set_selected',
			payload: {
				id: state.selectedRule !== id ? id : null,
				index: state.selectedRule !== id ? index : null,
			},
		});
	}

	return (
		<Fragment>
			<DragDropContext onDragEnd={onDragEnd}>
				<ContainerQuery
					query={{
						sm: { maxWidth: 900 },
					}}
				>
					{topMatches => (
						<LayoutWithSidebar
							editor
							drawerOpen
							persistentDrawer
							drawerWidth={
								topMatches.sm
									? constants.deploymentEditorDrawerWidthSm
									: constants.deploymentEditorDrawerWidth
							}
							drawerContent={<TagsEditorSidebar />}
							prominent={app.layout.appBarProminent && !app.layout.prominentHidden}
						>
							<ContainerQuery
								query={{
									xs: { maxWidth: 400 },
									sm: { maxWidth: 600 },
								}}
							>
								{matches => (
									<div
										className={`${classes.editorAreaWrap} ${
											matches.sm ? classes.editorAreaWrapSm : ''
										} ${matches.xs ? classes.editorAreaWrapXs : ''}`}
									>
										<Typography variant="h6">{t`Auto-tags configuration`}</Typography>
										<Typography
											variant="subtitle1"
											color="textSecondary"
										>
											{t`Conditions will be checked for feedback items and if they match your specified tags will be applied.`}
										</Typography>
										<Droppable droppableId="editor-area">
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.droppableProps}
													className={`${classes.editorArea} ${
														matches.small ? classes.editorAreaSmall : ''
													} ${config.rules.length === 0 ? classes.centerContent : ''}`}
												>
													{config.rules.length === 0 &&
														!snapshot.isDragging &&
														!snapshot.isDraggingOver &&
														!state.loading && (
															<Box
																height="100%"
																width="100%"
																position="absolute"
																top={0}
																left={0}
																right={0}
																bottom={0}
															>
																<EmptyState
																	primary={t`Create your automated tag configuration`}
																	secondary={t`Add datasources and set conditions for automated tagging of feedback items.`}
																/>
															</Box>
														)}
													{state.loading && <Loader empty={config.rules.length === 0} />}
													{config.rules.map((rule, index) => {
														const form = datasource.api.getSource(rule.survey_id);

														return (
															<TagsEditorItem
																onClick={e => selectRule(rule.id, index)}
																key={rule.id}
																form={form}
																rule={rule}
																index={index}
																selected={state.selectedRule === rule.id}
																onDelete={() => {
																	dispatch({
																		type: 'delete_rule',
																		payload: {
																			index,
																			ruleId: rule.id,
																		},
																	});
																	enqueueSnackbar(
																		`${
																			form ? form.name : t`(Deleted form)`
																		} ${t`removed from tag configuration`}`
																	);
																}}
															/>
														);
													})}

													{provided.placeholder}
												</div>
											)}
										</Droppable>
									</div>
								)}
							</ContainerQuery>
						</LayoutWithSidebar>
					)}
				</ContainerQuery>
			</DragDropContext>
		</Fragment>
	);
}
