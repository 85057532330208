export function analyticsEvent() {
	try {
		if (window.dataLayer) {
			window.dataLayer.push(arguments);
			return;
		}

		if (window.gtag) {
			window.gtag(arguments);
		}
	} catch (e) {}
}
