import React from 'react';

import { useAjaxForm } from '../Ajax';

export default function useAddChart({ dashboardId, chartId, onSuccess = () => {} }) {
	const action = useAjaxForm({
		url: '/dashboard/ajax/add-chart',
		data: {
			dashboardId: dashboardId,
			chartId: chartId,
		},
		onSuccess: response => {
			onSuccess(response);
		},
	});
	return action;
}
