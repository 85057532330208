import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTranslation } from 'react-i18next';

import ButtonWithLoader from './ButtonWithLoader';

const useStyles = makeStyles(theme => ({
	blur: {
		backdropFilter: 'blur(5px)',
	},
}));

export default function SimpleDialog({
	children,
	open,
	onClose,
	title,
	content,
	text,
	cancel,
	submit,
	onSubmit,
	component,
	onBack,
	to,
	loading,
	DialogContentProps = {},
	dataTestElement = null,
	dataTrackEvent = null,
	disabled = false,
	maxWidth = 'sm',
	blurBackdrop,
	...props
}) {
	const classes = useStyles();
	const { t } = useTranslation();

	const useAsLinkProps = {
		...(to && { to: to, buttonAsLink: true }),
	};

	return (
		<Dialog
			open={Boolean(open)}
			onClose={onClose}
			maxWidth={maxWidth}
			fullWidth
			BackdropProps={{
				className: blurBackdrop ? classes.blur : null,
			}}
			{...props}
		>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent {...DialogContentProps}>
				{content && content}
				{children && children}
				{text && <DialogContentText>{text}</DialogContentText>}
			</DialogContent>
			<DialogActions>
				{onClose && (
					<Button
						onClick={e => (onBack ? onBack(e) : onClose(e, { button: true }))}
						color="default"
						data-track-event={dataTrackEvent ? `${dataTrackEvent}_cancel` : null}
					>
						{cancel || t('Cancel')}
					</Button>
				)}
				{submit && (
					<ButtonWithLoader
						disabled={disabled}
						loading={loading}
						onClick={onSubmit}
						color="primary"
						variant="contained"
						data-test-element={dataTestElement}
						dataTrackEvent={dataTrackEvent}
						{...useAsLinkProps}
					>
						{submit}
					</ButtonWithLoader>
				)}
			</DialogActions>
		</Dialog>
	);
}
