import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
//import ChatBot from 'react-simple-chatbot';
import { MoBotAvatar } from './';
import { useImmer } from 'use-immer';
import { Message } from '../Conversations';

function MoBotChat({ conversation = [] }) {
	const [conv, setConv] = useImmer([]);
	const [show, setShow] = useImmer([]);
	const [selections, setSelections] = useImmer({});

	function getShow() {
		conversation.forEach((part, index) => {
			if (part.trigger) {
				setShow(draft => {
					draft.push(part.trigger);
				});
			}

			if (part.start) {
				setShow(draft => {
					draft.push(part.id);
				});
			}
		});
	}

	useEffect(() => {
		getShow();
	}, [conversation]);

	const messages = conversation.filter(message => show.find(id => id == message.id));

	return messages.map((message, messageIndex) => {
		const messageProps = {
			key: message.id,
			index: messageIndex,
			useTyping: messageIndex + 1 === messages.length,
		};
		switch (message.type) {
			case 'message':
				return <Message {...messageProps}>{message.message}</Message>;

			case 'buttons':
				if (typeof selections[message.id] !== 'undefined') {
					messageProps.key = message.id + 'selected';
					return (
						<Message {...messageProps}>
							{message.buttons[selections[message.id]].label}
						</Message>
					);
				}

				return (
					<Message {...messageProps}>
						<React.Fragment>
							{message.buttons.map((button, index) => {
								const chipProps = {
									onClick: e => {
										if (button.trigger) {
											setShow(draft => {
												draft.push(button.trigger);
											});
										}
										if (typeof button.effect === 'function') {
											button.effect();
										}

										setSelections(draft => {
											draft[message.id] = index;
										});
									},
									label: button.label,
								};
								return <Chip {...chipProps} />;
							})}
						</React.Fragment>
					</Message>
				);
		}
	});
}

export default MoBotChat;
