//field that are not anonymized YET( but are already var 29)
//requires survey block object
export function anonymizedSurveyElementOriginalBlockName(block) {
	return block.var_type === 29 && block.value !== '***ANONYMISED***'
		? block.question_type === 'textarea'
			? 'textarea'
			: block.question_type === 'input'
			? 'input'
			: block.question_type === 'website_data'
			? 'website_data'
			: false
		: false;
}

export function sortFeedback(dataFieldOrder, feedback) {
	if (!Array.isArray(feedback)) {
		return [];
	}
	const result = [...feedback];

	result.sort((a, b) => {
		const indexA = dataFieldOrder.indexOf(Number(a.data_field));
		const indexB = dataFieldOrder.indexOf(Number(b.data_field));
		return indexA - indexB;
	});
	return result;
}
