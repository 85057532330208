import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { SimpleDialog } from '../Layout';
import Grid from '@material-ui/core/Grid';
import { UserAutoComplete } from '../AutoComplete';
import { Translation } from 'react-i18next';
import produce from 'immer';
import get from 'lodash.get';
import { withSnackbar } from 'notistack';
import { testValidObject, ValidatedField } from '../Validation';
import { withAppContext } from '../AppContext';
import { AjaxForm } from '../Ajax';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({});

class TransferOwnerDialog extends React.PureComponent {
	static defaultProps = {
		close: () => {},
		onClose: () => {},
	};

	state = {
		loading: false,
		form: {
			target_user: false,
		},
		valid: {
			target_user: false,
		},
	};

	componentDidMount() {
		const { app } = this.props;
		this.setValue({ key: 'target_user', value: get(app, 'package.owner') });
	}

	handleClose = () => {
		this.props.close();
		this.props.onClose();
	};

	setValue = ({ key, value }) => {
		this.setState(
			produce(draft => {
				draft.form[key] = value;
			})
		);
	};
	setValid = ({ key, value }) => {
		this.setState(
			produce(draft => {
				draft.valid[key] = value;
			})
		);
	};

	render() {
		const { classes, open, app, enqueueSnackbar, onSuccess = () => {} } = this.props;
		const { form, valid } = this.state;
		return (
			<Translation>
				{t => (
					<AjaxForm
						url={`/application/ajax/change-account-owner`}
						data={{
							...form,
						}}
						valid={() => testValidObject(valid)}
						onSuccess={r => {
							if (r.code === 200) {
								onSuccess(r);
								enqueueSnackbar(t('transfer_account_owner_dialog-success'));
							}
							this.handleClose();
						}}
					>
						{({ loading, clicked, postForm }) => (
							<SimpleDialog
								open={Boolean(open)}
								onClose={this.handleClose}
								title={t`transfer_account_owner_dialog-title`}
								cancel={t`Cancel`}
								submit={t`Change`}
								onSubmit={postForm}
								loading={loading}
								dataTrackEvent={'account_change_owner'}
							>
								<Grid
									container
									direction="column"
									spacing={3}
									className={classes.fixColumn}
								>
									<Grid item>
										<Typography
											gutterBottom
										>{t`transfer_account_owner_dialog-content`}</Typography>
									</Grid>
									<Grid
										item
										xs
									>
										<ValidatedField
											component={UserAutoComplete}
											label={t('Select a user')}
											placeholder={t('Search for users')}
											onlyVerifiedUsers
											userLevels={['6']}
											renderChips
											multiple={false}
											selectedItem={[{ id: form.target_user }]}
											onChange={user => {
												this.setValue({ key: 'target_user', value: get(user, 'id', '') });
											}}
											disableCustomEntry
											validateValue={form.target_user}
											onValidChange={valid =>
												this.setValid({ key: 'target_user', value: valid })
											}
											rules={[['required', true]]}
											showErrors={clicked}
											dataTestElement="targetUser"
										/>
									</Grid>
								</Grid>
							</SimpleDialog>
						)}
					</AjaxForm>
				)}
			</Translation>
		);
	}
}

export default withStyles(styles)(withAppContext(withSnackbar(TransferOwnerDialog)));
