import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useAppContext } from '@/components/AppContext';
import useOnboardingContext from './useOnboardingContext';
import { useTranslation } from 'react-i18next';

import TrialMenuStepper from './TrialMenuStepper';
import { ActionButton, Typography, CardContent, CardActions } from '@/components/Layout';

import { capitalizeInput } from './onboardingUtils';

const useStyles = makeStyles(theme => ({
	cardActions: {
		padding: theme.spacing(2),
	},
	marginLeft: {
		marginLeft: 'auto',
	},
	marginRight: {
		marginRight: 'auto',
	},
	italic: {
		fontStyle: 'italic',
	},
	image: {
		'& img': {
			marginTop: theme.spacing(2),
		},
	},
}));

export default function TrialFlowWelcome() {
	const onboarding = useOnboardingContext();
	const { t } = useTranslation();

	const { trialStep } = onboarding;
	const classes = useStyles();

	const {
		app: {
			users: { current },
		},
	} = useAppContext();

	return (
		<>
			<CardContent>
				<Typography
					variant="h6"
					gutterBottom
				>
					{t('onboarding-trial_menu-step0-title', {
						name: capitalizeInput(current.firstname) ?? '',
					})}
				</Typography>

				<Typography variant="body1">
					{t('onboarding-trial_menu-step0-subtitle')}
				</Typography>

				<TrialMenuStepper
					variant="vertical"
					activeStep={trialStep}
				/>

				<Typography
					variant="body2"
					className={classes.italic}
				>
					{t`onboarding-trial_menu-step0-text`}
				</Typography>
			</CardContent>

			<CardActions className={classes.cardActions}>
				<ActionButton
					className={classes.marginLeft}
					variant="contained"
					color="primary"
					onClick={() => {
						onboarding.dispatch({
							type: 'toggle_trial_menu',
							payload: {
								open: false,
								nudgeButton: true,
							},
						});
					}}
					data-track-event="onboarding_trial_step_welcome_button"
					data-test-element="onboarding_trial_step_welcome_button"
				>
					{t`onboarding-trial_menu-step0-button_start`}
				</ActionButton>
			</CardActions>
		</>
	);
}
