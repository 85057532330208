import React, { Fragment, useState, useMemo } from 'react';

import Grid from '@material-ui/core/Grid';

import EventNoteIcon from '@material-ui/icons/EventNote';

import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';
import { useSnackbar } from 'notistack';

import { useAppContext } from '@/components/AppContext';
import { Loader, ActionButton } from '@/components/Layout';
import { exportOptions } from './constants';
import { useExportContext } from './ExportContext';
import {
	ListWithSidebar,
	ListFilterProvider,
	ListControls,
	useFilteredList,
} from '@/components/FilteredLists';
import { sortByNum, sortByAlphabet } from '@/utils';
import FeedbackExportDialog from './FeedbackExportDialog';
import ExportExpansionPanel from './ExportExpansionPanel';
import TemplateListFilters from './TemplateListFilters';
import { EmptyStateHandleHasSurveys } from '@/components/App';
import { useHasPermission, NoPermissionTooltip } from '@/components/Permission';

export default function ExportTemplates({ filtersOpen, setFiltersOpen }) {
	const {
		app,
		app: { layout },
	} = useAppContext();
	const hasEditPermission = useHasPermission();
	const {
		state,
		state: { initialLoaded, templatesLoading, maxSetupsReached, export_templates },
		api: { loadTemplatesData },
	} = useExportContext();
	const { t } = useTranslation();

	const { enqueueSnackbar } = useSnackbar();

	const [historyState, setHistoryState] = useImmer({
		selected: [],
		filtersOpen: true,
		showExportDialog: false,
		expanded: '',
	});

	const filterState = useFilteredList({
		filters: {},
		other: {},
	});

	const { filters, sort, order } = filterState;

	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogData, setDialogData] = useState({});
	const [withUuid, setWithUuid] = useState(false);

	const filtered_templates = useMemo(() => {
		const export_item =
			export_templates.length > 0
				? export_templates.filter(item => {
						const templateStatusPassed = filters.template_type
							? filters.template_type === item.template_access
							: true;

						const formatFilterPassed =
							filters.export_format && item?.export?.format
								? filters.export_format.includes(item.export.format)
								: true;

						const searchFilterPassed = filters.search
							? item.name.includes(filters.search)
							: true;

						return templateStatusPassed && formatFilterPassed && searchFilterPassed;
				  })
				: export_templates;

		return sort === 'fname'
			? sortByAlphabet(export_item, 'name', order === 'desc')
			: sortByNum(export_item, 'timestamp', order === 'desc');
	}, [filters, sort, order, export_templates]);

	function toggleExpand(id, expanded) {
		setHistoryState(draft => {
			if (expanded && draft.expanded !== id) {
				draft.expanded = id;
			}

			if (!expanded && draft.expanded === id) {
				draft.expanded = '';
			}
		});
	}

	if (state.configLoading) {
		return <Loader empty />;
	}

	return (
		<Fragment>
			<ListFilterProvider
				{...filterState}
				singleFilters={['template_type', 'search']}
				multiFilters={['export_format']}
				filterLabels={{
					template_type: {
						label: t('Type'),
						private: t('My templates'),
						public: t('Public templates'),
					},
					export_format: exportOptions.reduce(
						(options, option) => {
							options[option.value] = option.label;
							return options;
						},
						{ label: 'Export format' }
					),
				}}
			>
				<ListWithSidebar
					drawerContent={<TemplateListFilters />}
					open={filtersOpen}
					prominent={!layout.prominentHidden}
					onClose={() => setFiltersOpen(false)}
				>
					<ListControls
						onRefresh={loadTemplatesData}
						sortOptions={[
							{ label: t('Created: new to old'), value: 'created-asc' },
							{ label: t('Created: old to new'), value: 'created-desc' },
						]}
						toggleFilterDrawer={() => setFiltersOpen(prev => !prev)}
					>
						<NoPermissionTooltip>
							<ActionButton
								action="export"
								onClick={() => {
									if (maxSetupsReached) {
										enqueueSnackbar(t`You reached the maximum amount of templates`);
									} else {
										setDialogOpen('template');
									}
								}}
								dataTrackEvent="export_new_export"
								disabled={!hasEditPermission}
							>
								{t('New export template')}
							</ActionButton>
						</NoPermissionTooltip>
					</ListControls>

					{!initialLoaded && templatesLoading && <Loader empty />}
					{initialLoaded && filtered_templates.length > 0 && (
						<Grid
							container
							direction="column"
							spacing={2}
						>
							<Grid item>
								{filtered_templates.map(template => (
									<ExportExpansionPanel
										key={template.uuid}
										exportData={template}
										variant="template"
										expanded={historyState.expanded === template.uuid}
										toggleExpand={(e, expanded) => toggleExpand(template.uuid, expanded)}
										setDialogOpen={setDialogOpen}
										setDialogData={setDialogData}
										setWithUuid={setWithUuid}
										maxSetupsReached={maxSetupsReached}
									/>
								))}
							</Grid>
						</Grid>
					)}
					{initialLoaded && filtered_templates.length === 0 && (
						<EmptyStateHandleHasSurveys
							image={<img src={`/assets/img/r/emptystate/feedback_exports.gif`} />}
							primaryNoSurvey={t`reporting-feedback_exports-template-emptystate_no_survey-title`}
							secondaryNoSurvey={t`reporting-feedback_exports-template-emptystate_no_survey-text`}
							primary={
								export_templates.length === 0
									? t`reporting-feedback_exports-template-emptystate-title`
									: t`reporting-feedback_exports-template-emptystate_filter-title`
							}
							secondary={
								export_templates.length === 0
									? t`reporting-feedback_exports-template-emptystate-text`
									: t`reporting-feedback_exports-template-emptystate_filter-text`
							}
							action={
								export_templates.length === 0 && (
									<NoPermissionTooltip>
										<ActionButton
											onClick={() => setDialogOpen('template')}
											variant="contained"
											color="primary"
											dataTrackEvent="export_add_template"
											disabled={!hasEditPermission}
										>
											{t`reporting-feedback_exports-template-emptystate_button`}
										</ActionButton>
									</NoPermissionTooltip>
								)
							}
						/>
					)}
				</ListWithSidebar>

				<FeedbackExportDialog
					open={dialogOpen}
					onClose={reload => {
						setDialogOpen(false);
						setDialogData({});
						if (reload) {
							loadTemplatesData();
						}
					}}
					variant={dialogOpen}
					exportData={dialogData}
					templatesFromConfig={export_templates}
					withUuid={withUuid}
				/>
			</ListFilterProvider>
		</Fragment>
	);
}
