import React from 'react';

import HasPermission from './HasPermission';
import NoPermissionEmptyState from './NoPermissionEmptyState';
import { permissionTypes } from './constants';

export default function HasPermissionWithEmptyState({
	children,
	permission = permissionTypes.edit,
	module,
	subject,
	EmptyStateProps = {},
	...props
}) {
	return (
		<HasPermission
			permission={permission}
			module={module}
			subject={subject}
		>
			{hasPermission =>
				hasPermission ? (
					children
				) : (
					<NoPermissionEmptyState
						permission={permission}
						{...EmptyStateProps}
					/>
				)
			}
		</HasPermission>
	);
}
