import React, { forwardRef } from 'react';
import MuiTooltip from '@material-ui/core/Tooltip';

export default function Tooltip({ children, wrapDisabled, ...props }, ref) {
	return (
		<MuiTooltip {...props}>
			{wrapDisabled ? <span>{children}</span> : children}
		</MuiTooltip>
	);
}
