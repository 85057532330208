import React, { useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useAppContext } from '../AppContext';
import ProjectAdditDialog from './ProjectAdditDialog';
import {
	AjaxFormDialog,
	SystemElementCardBase,
	Grid,
	ActionIconButton,
	FormattedDate,
} from '../Layout';
import { NoPermissionTooltip, useHasPermission } from '../Permission';

const useStyles = makeStyles(theme => ({
	img: {
		backgroundSize: `auto calc(100% - ${theme.spacing(2)}px)`,
		backgroundColor: theme.palette.primary.light,
		width: '100%',
		height: 100,
	},
	imgXs: {
		width: 40,
		height: 40,
	},
	fillHeight: {
		height: '100%',
	},
	CardContent: {
		flexGrow: 1,
	},
	card: {
		position: 'relative',
		height: '100%',
	},
	chip: {
		position: 'absolute',
		top: theme.spacing(1),
		right: theme.spacing(1),
	},
}));

function ProjectsCard({ changeProject, ...props }) {
	const { name, description, created, anon_days, id } = props.project;
	const { t } = useTranslation();
	const classes = useStyles();
	const { app } = useAppContext();
	const { enqueueSnackbar } = useSnackbar();
	const [deleteOpen, setDelete] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const hasEditPermission = useHasPermission();

	return (
		<Fragment>
			<SystemElementCardBase
				className={classes.card}
				img="/assets/img/r/report.svg"
				title={name}
				subtitle={description}
				info={
					props.size !== 'small'
						? [
								{
									label: t`Created`,
									value: <FormattedDate date={created} />,
								},
								{
									label: t`project_card-anon_label`,
									value:
										anon_days == 0
											? t(`project_card-anon_value_do_not_anon`)
											: t(`project_card-anon_value`, { count: Number(anon_days) }),
								},
						  ]
						: null
				}
				actions={
					<Fragment>
						<Grid
							container
							direction={props.size === 'small' ? 'column' : 'row'}
						>
							<Grid
								item
								xs
							>
								<NoPermissionTooltip>
									<ActionIconButton
										onClick={() => setEditOpen(true)}
										title={t`Edit report`}
										action="edit"
										data-test-element="reportEdit"
										disabled={!hasEditPermission}
									/>
								</NoPermissionTooltip>

								{!props.current && (
									<ActionIconButton
										onClick={() => changeProject()}
										data-test-element="reportSetActive"
										tooltip={t`Set active`}
										action="set_active"
									/>
								)}
							</Grid>
							{!props.current && props.size !== 'small' && (
								<Grid item>
									<NoPermissionTooltip>
										<ActionIconButton
											action="delete"
											onClick={() => setDelete(true)}
											data-test-element="projects-card-delete-button"
											disabled={!hasEditPermission}
										/>
									</NoPermissionTooltip>
								</Grid>
							)}
						</Grid>
					</Fragment>
				}
				children={
					props.current && (
						<Chip
							color="primary"
							label={t`Current`}
							className={classes.chip}
						/>
					)
				}
				{...props}
			/>

			<ProjectAdditDialog
				project={props.project}
				additOpen={editOpen}
				setAddit={e => setEditOpen(e)}
				anonymise
			/>
			<AjaxFormDialog
				AjaxFormProps={{
					url: '/application/projects/delete',
					data: { id },
					onSuccess: () => {
						app.api.getProjects();
						enqueueSnackbar(`Report deleted`);
					},
				}}
				dataTrackEvent="settings_report_deleted"
				dataTestElement="projects-delete-submit-button"
				open={deleteOpen}
				onClose={() => setDelete(false)}
				title={t`Delete report`}
				content={
					<Fragment>
						{t`Do you want to permanently delete this report?`}{' '}
						<strong>{t`You won't be able to undo this.`}</strong>
					</Fragment>
				}
			/>
		</Fragment>
	);
}

export default ProjectsCard;
