import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import ThumbsUp from '@material-ui/icons/ThumbUp';
import ThumbsDown from '@material-ui/icons/ThumbDown';
import ThumbsUpDown from '@material-ui/icons/ThumbsUpDown';

import getBrowserOsIcon from './getBrowserOsIcon';

import { useDataSourceContext } from '../DataSources';
import { scoreDetractorsPromoters } from './constants';

const sizes = {
	xl: '40px',
	large: '32px',
	medium: '24px',
};

const useStyles = makeStyles(theme => ({
	bubble: {
		borderRadius: '50%',
		height: props => sizes[props.size] ?? '24px',
		width: props => sizes[props.size] ?? '24px',
		textAlign: 'center',
		fontSize: props => (props.size === 'xl' ? '1rem' : '.8rem'),
		fontWeight: 700,
		overflow: 'hidden',
		background: 'rgba(0,0,0,.2)',
		color: 'rgba(0,0,0,.8)',
		flex: '0 0 24px',
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	high: {
		background: theme.palette.scores.high,
		color: '#fff',
	},
	medium: {
		background: theme.palette.scores.medium,
		color: '#fff',
	},
	low: {
		background: theme.palette.scores.low,
		color: '#fff',
	},
	msi: {
		background: ({ MSI }) => theme.palette.mopinion.getSentimentColors(MSI),
	},
	xl: {
		height: '40px',
		width: '40px',
		fontSize: '1rem',
	},
	iconFontSizeSmall: {
		fontSize: '.8rem',
	},
	iconFontSizeLarge: {
		fontSize: '1rem',
	},
	ranking: {
		backgroundColor: theme.palette.mopinion.main,
		color: '#fff',
	},
}));

const iconMap = (value, props) => {
	const map = {
		yes: <ThumbsUp {...props} />,
		no: <ThumbsDown {...props} />,
		partly: <ThumbsUpDown {...props} />,
		partially: <ThumbsUpDown {...props} />,
		positive: <ThumbsUp {...props} />,
		negative: <ThumbsDown {...props} />,
	};

	return map[value];
};

function cesLabel(value, rev) {
	const labels = ['--', '-', '0', '+', '++'];
	const index = Number(value) - 1;
	return rev ? labels.reverse()[index] : labels[index];
}

//'dirty fix' for ajusting the color of top/bottom for dynamic ratings
function useEvaluateScoreRange(bottom, top, surveyId, label) {
	const {
		datasource: { surveyfields },
	} = useDataSourceContext();

	const sourceFromScore = surveyfields[surveyId]
		? surveyfields[surveyId].asArray?.filter(obj => obj.title === label)
		: [];
	const scale = sourceFromScore.length
		? sourceFromScore[0]?.survey_block?.properties?.scale
		: 10;

	return getDynamicRange({ sourceFromScore, scale, top, bottom });
}

function getDynamicRange({ sourceFromScore = [], scale = 10, top = [], bottom = [] }) {
	const numScale = typeof scale === 'number' ? scale : Number(scale);

	if (
		sourceFromScore.length === 1 &&
		sourceFromScore[0].var_type === 4 &&
		scoreDetractorsPromoters[numScale]
	) {
		return {
			top: scoreDetractorsPromoters[numScale].top,
			bottom: scoreDetractorsPromoters[numScale].bottom,
		};
	}

	return {
		top,
		bottom,
	};
}

const ContextBubble = ({
	children,
	className = '',
	top = [],
	bottom = [],
	ces,
	gcr,
	thumbs,
	value,
	isXl,
	size,
	browser,
	os,
	score,
	MSI,
	ranking,
	label = '',
	var_type,
	showLabel = false,
	survey_id,
	...props
}) => {
	const computedSize = size ?? isXl ? 'xl' : 'medium';

	const classes = useStyles({ MSI, size: computedSize });

	const dynamicRange = useEvaluateScoreRange(bottom, top, survey_id, label);

	const getClass = () => {
		let int = Number(score ?? value);

		if (browser || os) return '';

		if (gcr) {
			if (score === 'yes' || value === 'yes') {
				return 'high';
			} else if (score === 'no' || value === 'no') {
				return 'low';
			} else {
				return 'medium';
			}
		}

		if (thumbs) {
			if (score === 'negative' || value === 'negative') {
				return 'low';
			} else {
				return 'high';
			}
		}

		if (!top || (!top.length && !bottom) || !bottom.length) return '';

		if (dynamicRange.top.indexOf(int) > -1) {
			return 'high';
		} else if (dynamicRange.bottom.indexOf(int) > -1) {
			return 'low';
		} else {
			return 'medium';
		}
	};

	return (
		<Tooltip title={showLabel ? label : value}>
			<div
				className={`${classes.bubble} ${classes[getClass()]} ${
					typeof MSI !== 'undefined' ? classes.msi : ''
				} ${ranking ? classes.ranking : ''}`}
			>
				{[16, 24, 27].indexOf(Number(var_type)) > -1
					? cesLabel(children, var_type == 24)
					: children}
				{gcr || thumbs
					? iconMap(score || value, {
							className:
								computedSize === 'xl'
									? classes.iconFontSizeLarge
									: classes.iconFontSizeSmall,
					  })
					: null}
				{browser || os
					? getBrowserOsIcon(value, {
							className:
								computedSize === 'xl'
									? classes.iconFontSizeLarge
									: classes.iconFontSizeSmall,
					  })
					: null}
			</div>
		</Tooltip>
	);
};

export default memo(ContextBubble);
