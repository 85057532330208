import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import { useAppContext } from '../AppContext';

import ProjectsCard from './ProjectsCard';
import ProjectAdditDialog from './ProjectAdditDialog';

import {
	useFilteredList,
	ListWithSidebar,
	ListFilterProvider,
	ListControls,
} from '../FilteredLists';

import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';
import { PageHeader } from '../App';
import { AddCard } from '../Layout';
import { sortByNum, sortByAlphabet } from '../../utils';
import { useHasPermission, NoPermissionTooltip } from '../Permission';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	input: {
		margin: theme.spacing(1),
	},
	textField: {
		width: '100%',
	},
	icon: {
		fontSize: theme.typography.pxToRem(64),
		color: theme.palette.primary.main,
	},
}));

function ProjectList() {
	const { t } = useTranslation();
	const { app } = useAppContext();
	const [state, setState] = useImmer({
		drawerOpen: false,
		editProject: {},
		editProjectDialogOpen: false,
		additProject: {
			name: '',
			description: '',
			language: '',
			utc_timezone: '',
			anonymized: false,
			anonDays: 0,
		},
	});
	const hasEditPermission = useHasPermission();
	const filterState = useFilteredList({
		filters: {},
	});

	const { sort, order } = filterState;

	const [addOpen, setAddOpen] = useState(false);

	const classes = useStyles();
	const listAfterFilterSort = applySort(app.projects.asArray);

	function toggleDrawer(force) {
		setState(draft => {
			draft.drawerOpen = typeof force !== 'undefined' ? force : !draft.drawerOpen;
		});
	}

	function applySort(array) {
		if (array.length === 0) return array;

		if (sort === 'fname') {
			return sortByAlphabet(array, 'name', order === 'desc');
		} else {
			//default sort by id and asc if not set
			return sortByNum(array, 'id', order === 'desc');
		}
	}

	return (
		<ListFilterProvider
			{...filterState}
			outerFilters={{
				search: '',
			}}
		>
			<PageHeader title={t`Reports`} />
			<ListWithSidebar
				drawerContent={<div />}
				open={state.drawerOpen}
				onClose={toggleDrawer}
			>
				<ListControls
					sortOptions={[
						{ label: t('Report creation: first to last'), value: 'id-asc' },
						{ label: t('Report creation: last to first'), value: 'id-desc' },
						{ label: t('Report name: A to Z'), value: 'fname-asc' },
						{ label: t('Report name: Z to A'), value: 'fname-desc' },
					]}
					//toggleFilterDrawer={toggleDrawer}
					noFilters
				/>

				<Grid
					container
					spacing={3}
				>
					<Grid
						item
						xs={6}
						md={4}
						lg={3}
					>
						<NoPermissionTooltip>
							<AddCard
								text={t`New report`}
								onClick={e => {
									setAddOpen(true);
								}}
								maxReached={app.projects.asArray.length >= app.package.projects}
								dataTrackEvent="settings_report_created"
								subject={t`reports`}
								disabled={!hasEditPermission}
								data-test-element="projects-create-new"
							/>
						</NoPermissionTooltip>
					</Grid>
					{listAfterFilterSort.map(project => {
						return (
							<Grid
								item
								xs={6}
								md={4}
								lg={3}
								key={project.id}
							>
								<ProjectsCard
									project={project}
									current={app.projects.current.id === project.id ? 'true' : null}
									changeProject={() => app.api.changeProject(project)}
								/>
							</Grid>
						);
					})}
				</Grid>
			</ListWithSidebar>
			<ProjectAdditDialog
				project={false}
				additOpen={addOpen}
				setAddit={e => setAddOpen(e)}
			/>
		</ListFilterProvider>
	);
}

export default ProjectList;
