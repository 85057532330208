import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BusinessSharpIcon from '@material-ui/icons/BusinessSharp';

import {
	TextField,
	Tooltip,
	MenuItem,
	ListSubheader,
	InputAdornment,
} from '@/components/Layout';

import { useAppContext } from '../AppContext';
import { VirtualizedAutocomplete } from '../AutoComplete';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	select: {
		fontSize: theme.typography.body2.fontSize,
	},
	subheader: {
		backgroundColor: '#fff',
		pointerEvents: 'none',
	},
}));

export default function OrganisationFilterFilter() {
	const {
		app,
		app: { organisations, layout },
	} = useAppContext();
	const { t } = useTranslation();
	const classes = useStyles();

	const [tooltipOpen, setTooltipOpen] = useState(false);
	const handleTooltip = bool => {
		setTooltipOpen(bool);
	};
	const showAutocomplete = organisations.asArray.length > 50;
	if (organisations.asArray.length <= 1) return null;

	return showAutocomplete ? (
		<VirtualizedAutocomplete
			options={organisations.asArray}
			getOptionLabel={option => option.name}
			variant="outlined"
			size="small"
			disableClearable
			disabled={layout.appLoading || layout.appLoadingFull}
			value={organisations.current}
			onChange={(e, organisation) => {
				if (organisation) {
					app.api.changeOrganisation(organisation);
				}
			}}
			renderInput={params => (
				<TextField
					{...params}
					variant="outlined"
					fullWidth={false}
					InputProps={{
						...params.InputProps,
						className: classes.select,
						startAdornment: (
							<InputAdornment position="start">
								<BusinessSharpIcon fontSize="small" />
							</InputAdornment>
						),
					}}
				/>
			)}
		/>
	) : (
		<>
			<Tooltip
				open={tooltipOpen}
				title={t('Change organisation')}
			>
				<TextField
					select
					size="small"
					variant="outlined"
					value={organisations.current}
					disabled={layout.appLoading || layout.appLoadingFull}
					SelectProps={{
						startAdornment: (
							<InputAdornment position="start">
								<BusinessSharpIcon fontSize="small" />
							</InputAdornment>
						),
						className: classes.select,
						renderValue: () => <>{organisations.current.name}</>,
						onOpen: () => handleTooltip(false),
						onMouseEnter: () => handleTooltip(true),
						onMouseLeave: () => handleTooltip(false),
					}}
					onChange={(e, value) => {
						const organisation = value.props.value;
						if (organisation) {
							app.api.changeOrganisation(organisation);
						}
					}}
				>
					<ListSubheader
						className={classes.subheader}
					>{t`Select organisation`}</ListSubheader>
					{organisations.asArray.map(org => {
						return (
							<MenuItem
								key={org.org_id + org.name + org.created}
								value={org}
								disabled={org.org_id === organisations.current.org_id}
							>
								{org.name}
							</MenuItem>
						);
					})}
				</TextField>
			</Tooltip>
		</>
	);
}
