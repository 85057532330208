import React, { Fragment, useState } from 'react';

import Chip from '@material-ui/core/Chip';

import MoreVertIcon from '@material-ui/icons/MoreVert';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import CustomFilterPopover from './CustomFilterPopover';
import { AjaxForm } from '../Ajax';

export default function CustomViewChip({
	filter,
	viewOnly,
	updateFilters = () => {},
	onApply = () => {},
	onEdit = () => {},
	...props
}) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [anchor, setAnchor] = useState(null);

	return (
		<Fragment key={filter.name + filter.id}>
			<Chip
				deleteIcon={<MoreVertIcon fontSize="small" />}
				onDelete={e => setAnchor(e.target)}
				{...props}
			/>
			<AjaxForm
				url="/filters/delete-filter"
				data={{ id: filter.id }}
				onSuccess={() => {
					enqueueSnackbar(`${filter.name} ${t('deleted')}`);
					updateFilters();
				}}
			>
				{({ postForm, loading }) => (
					<CustomFilterPopover
						anchor={anchor}
						onClose={e => {
							setAnchor(null);
						}}
						filter={filter}
						viewOnly={viewOnly}
						onApply={() => {
							onApply();
							setAnchor(null);
						}}
						onEdit={() => {
							onEdit();
							setAnchor(null);
						}}
						onDelete={() => {
							postForm();
							setAnchor(null);
						}}
					/>
				)}
			</AjaxForm>
		</Fragment>
	);
}
