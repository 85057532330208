import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import RenderConditional from './RenderConditional';

const useStyles = makeStyles(theme => ({
	root: {
		wordBreak: props => (props.wordBreak ? props.wordBreak : 'normal'),
		marginTop: props => (props.mt ? theme.spacing(props.mt) : null),
		marginBottom: props => (props.mb ? theme.spacing(props.mb) : null),
		marginLeft: props => (props.ml ? theme.spacing(props.ml) : null),
		marginRight: props => (props.mr ? theme.spacing(props.mr) : null),
	},
	medium: {
		fontWeight: theme.typography.fontWeightMedium,
	},
	fontWeight: ({ fontWeight }) => {
		if (fontWeight) {
			const exists =
				theme.typography[
					`fontWeight${fontWeight.charAt(0).toUpperCase() + fontWeight.substring(1)}`
				];
			if (exists) {
				return {
					fontWeight: exists,
				};
			}
		}
		return null;
	},
	color: ({ color }) => {
		if (color === 'textHint') {
			return {
				color: theme.palette.text.hint,
			};
		}
		return null;
	},
	ellipsis: {
		display: 'block',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	tooltip: {
		cursor: 'pointer',
		borderBottom: '2px dotted',
		borderColor: 'inherit',
	},
	errorIcon: {
		marginRight: theme.spacing(1),
		verticalAlign: 'middle',
	},
	mono: {
		fontFamily: '"Ubuntu Mono", monospace',
	},
	italic: {
		fontStyle: 'italic',
	},
}));

export default function Typography({
	children,
	ellipsis,
	tooltip,
	TooltipProps = {},
	error,
	font,
	...props
}) {
	const { variant, className = '', fontWeight, italic, ...rest } = props;
	const classes = useStyles(props);

	const TypographyProps = {
		variant: variant === 'subtitle' ? 'subtitle1' : variant,
		className: `${classes.root} ${variant === 'subtitle' ? classes.medium : ''} ${
			fontWeight ? classes.fontWeight : ''
		} ${classes.color} ${ellipsis ? classes.ellipsis : ''} ${italic ? classes.italic : ''}
		${font === 'mono' ? classes.mono : ''} ${tooltip ? classes.tooltip : ''} ${className}`,
		...(error && {
			color: 'error',
		}),
		...rest,
	};

	return (
		<RenderConditional
			title={tooltip}
			condition={Boolean(tooltip)}
			{...TooltipProps}
		>
			<MuiTypography {...TypographyProps}>
				{error && <ErrorOutlineIcon className={classes.errorIcon} />}
				{children}
			</MuiTypography>
		</RenderConditional>
	);
}
